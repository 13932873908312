import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { DISTANCE_RACER_URL } from 'src/constants';

import { action, request, t } from 'utils';

import { toastStore } from 'stores';

import { SEND_ATTACH_TO_PROFILE } from '../constants';

import { closeForm } from '../actions';

class AttachToProfileService {
  @request({ action: SEND_ATTACH_TO_PROFILE })
  async sendAttachToProfileRequest(params: AnyObject, id: number): Promise<any> {
    const path = generatePath(DISTANCE_RACER_URL, { id });
    return axios.patch(path, params);
  }

  @action({ action: SEND_ATTACH_TO_PROFILE })
  async sendAttachToProfile(params: AnyObject, racerId: number): Promise<any> {
    const [status, response] = await this.sendAttachToProfileRequest(params, racerId);
    closeForm();
    if (status) {
      toastStore.show(t.staticAsString('startlist.attachToProfileResponseOk'));
      return response.data.data.user_id;
    } else {
      toastStore.show(response.data.errors);
      return null;
    }
  }
}

export const attachToProfileService = new AttachToProfileService();

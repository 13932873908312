import * as React from 'react';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { ROUTES } from 'src/constants';

import { NavToolbar } from 'components/NavToolbar';

import { t } from 'utils';

type Props = {
  raceId: number;
  raceName: string;
  distanceName: string;
};

class Breadcrumbs extends React.PureComponent<Props> {
  navItems = (): Array<NavToolbarItemType> => {
    const { raceId, raceName, distanceName } = this.props;

    return [
      { key: shortid(), path: ROUTES.racesRoute, label: t.staticAsString('races.detail.nav.races') as any },
      { key: shortid(), path: generatePath(ROUTES.raceRoute, { id: raceId }), label: raceName },
      { key: shortid(), label: distanceName },
    ];
  };
  render() {
    return <NavToolbar items={this.navItems()} />;
  }
}

export { Breadcrumbs };

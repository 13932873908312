import { uniqBy } from 'lodash';
import { action, observable, makeObservable } from 'mobx';
import { BibDistances } from 'modules/SupportTaskManager/Tasks/utils';

export const getParams = (text: string | null): FiltersType => {
  const params = {
    with: 'classes',
    page: 1,
    limit: 10,
  };

  if (text === null) {
    return params;
  }

  return {
    ...params,
    search: `name:${text}`,
    searchFields: `name:like`,
  };
};

export class Distances {
  @observable distances: Array<BibDistances> = [];

  @observable params: AnyObject = getParams(null);

  @observable hasMore: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action
  setDistances(data: Array<BibDistances>): void {
    this.distances = data;
  }

  @action
  appendDistances(data: Array<BibDistances>): void {
    this.distances = uniqBy([...this.distances, ...data], 'value');
  }

  @action
  setParams(params: Object): void {
    this.params = {
      ...this.params,
      ...params,
    };
  }

  @action
  setHasMore(hasMore: boolean = true): void {
    this.hasMore = hasMore;
  }

  @action
  clear(): void {
    this.distances = [];
    this.params = getParams(null);
    this.hasMore = true;
  }
}

const distancesStore = new Distances();
export { distancesStore };

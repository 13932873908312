import { keys, includes } from 'lodash';

import { ENVIRONMENTS } from 'src/constants';

import { app } from './app';

const envs = keys(ENVIRONMENTS);
const ENV_HTML_ELEMENT = 'app-environment';
const FORCED_END = 'forced-env';

class AppEnvironmentControl {
  currentEnv(): nil | Environment {
    const value = document.getElementById(ENV_HTML_ELEMENT);

    if (!value) {
      return null;
    }

    const env: nil | Environment = value.dataset.environment as any;
    const forcedEnv = this.forcedEnv();

    return forcedEnv || env;
  }

  currentEnvWithDefaultDev(): Environment {
    return this.currentEnv() || 'development';
  }

  currentEnvWithStub(): Environment {
    return this.currentEnv() || 'production';
  }

  setCurrentEnvForApp() {
    const value = this.currentEnv();
    if (!value) {
      return;
    }

    app.assign('environment', value);
  }

  isEnv(value: Environment): boolean {
    return this.currentEnv() === value;
  }

  forcedEnv(): nil | Environment {
    const forcedEnv: nil | Environment = localStorage.getItem(FORCED_END) as any;

    if (!this.isValidEnv(forcedEnv)) {
      return null;
    }

    return forcedEnv;
  }

  isValidEnv(value: any): boolean {
    return includes(envs, value);
  }
}

const appEnvControl = new AppEnvironmentControl();

export { AppEnvironmentControl, appEnvControl };

import { observer } from 'mobx-react';
import * as React from 'react';

import { SORT_BY } from 'src/constants';

import { SortingContext } from 'hocs';

import { OrderableLabel } from './OrderableLabel';
import { PlainLabel } from './PlainLabel';

type Props = {
  label: React.ReactNode;
  name: string;
  dynamicSortOptions?: Object;
  isSearchEnabled: boolean;
};

// NOTE, Sorting
// Subscribed for sorting context
@observer
class Label extends React.Component<Props> {
  static contextType = SortingContext;
  declare context: SortContextProps | any;

  isSortingEnabled = (): boolean => {
    const { name } = this.props;
    const { sort } = this.context;
    if (!sort) {
      return false;
    }

    const descriptor = sort.filterDescriptors[name];

    if (!descriptor) {
      return false;
    }

    return descriptor.isSortingEnabled();
  };

  isActive = (): boolean => {
    const { sort } = this.context;
    const { name, dynamicSortOptions } = this.props;

    if (!sort) {
      return false;
    }

    return sort.store.isActive(name, dynamicSortOptions);
  };

  orderVal = (): SortTypes => {
    const { sort } = this.context;
    const isActive = this.isActive();

    if (!sort || !isActive) {
      return SORT_BY.asc;
    }

    return sort.store.value?.sortBy || SORT_BY.asc;
  };

  onChange = () => {
    const { sort } = this.context;
    const { name, dynamicSortOptions } = this.props;

    if (!sort) {
      return;
    }

    sort.service.apply(() => {}, name, dynamicSortOptions);
  };

  render() {
    const { label } = this.props;
    const isSortingEnabled = this.isSortingEnabled();
    const isSearchEnabled = this.props.isSearchEnabled;

    if (!isSortingEnabled) {
      return <PlainLabel displaySearchIcon={isSearchEnabled}>{label}</PlainLabel>;
    }

    return (
      <OrderableLabel value={this.orderVal()} active={this.isActive()} onChange={this.onChange} displaySearchIcon={isSearchEnabled}>
        {label}
      </OrderableLabel>
    );
  }
}

export { Label };

import * as React from 'react';

import { beforeCloseModal } from 'src/modules/Distances/components/shared/Steps/GPX/actions';
import { ActiveButton } from 'src/modules/Distances/components/shared/Steps/GPX/components/ManageCheckPointsModal/styled';

import { t } from 'utils';

export const ButtonClose = () => {
  return <ActiveButton onClick={() => beforeCloseModal()}>{t.staticAsString('racers.squads.close')}</ActiveButton>;
};

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, TablePagination, TextField, Typography } from '@mui/material';
import { debounce, isEmpty, omit } from 'lodash';
import { Observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';

import { Delayed } from 'components/Delayed';

import { t } from 'utils/t';

import { progressStore } from 'stores';

import { LOAD_CAMPAIGN_LINKS } from '../../constants';

function DefaultColumnFilter({ column: { filterValue, setFilter, id } }) {
  const handleSetFilter = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setFilter(e.target.value || undefined);
  return (
    <Box p={0.5} mb={1.5}>
      <TextField fullWidth variant='outlined' size='small' value={filterValue || ''} onChange={handleSetFilter} />
    </Box>
  );
}

const LinksTable = ({ fetchLinks, tableMeta, tableColumns, initialSizeOfPage, tableData, pageSizeOptions }): JSX.Element => {
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => tableData, [tableData]);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const buildTBody = () => {
    if (!progressStore.isLoading(LOAD_CAMPAIGN_LINKS) && rows.length === 0) {
      return (
        <tr>
          <td className='cell' colSpan={5}>
            <Delayed waitBeforeShow={500}>
              <Typography style={{ marginBottom: 20 }} variant='h5'>
                {t.staticAsString('reconciliation.transactions.noDataAvailable')}
              </Typography>
            </Delayed>
          </td>
        </tr>
      );
    }

    return rows.map((row) => {
      prepareRow(row);
      return (
        <tr className='row' {...row.getRowProps()}>
          {row.cells.map((cell) => {
            return (
              <td className='cell' {...cell.getCellProps()}>
                {cell.render('Cell')}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  const {
    getTableProps,
    state: { sortBy, filters, pageIndex, pageSize },
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    setPageSize,
    pageOptions,
    gotoPage,
  } = useTable(
    {
      columns,
      defaultColumn,
      data,
      initialState: {
        pageSize: 30,
      },
      manualFilters: true,
      manualSortBy: true,
      manualPagination: true,
      pageCount: tableMeta.pagination?.total ?? 0,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const debouncedCallback = useCallback(
    debounce((props) => fetchLinks(props), 500),
    [],
  );
  useEffect(() => {
    return debouncedCallback({ pageIndex, pageSize, filters, sortBy });
  }, [fetchLinks, pageIndex, pageSize, filters, sortBy]);
  return (
    <Observer
      render={() => {
        return (
          <>
            {progressStore.isLoading(LOAD_CAMPAIGN_LINKS) && (
              <div style={{ backgroundColor: 'white', height: '100%', width: '100%', zIndex: 10, opacity: '0.5', position: 'absolute' }} />
            )}
            <div className='standart-table simple' style={{ overflow: 'auto' }}>
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        return (
                          <th {...column.getHeaderProps()}>
                            <div {...omit(column.getHeaderProps(column.getSortByToggleProps()), 'title')}>
                              <div style={{ cursor: 'pointer', display: 'flex', height: 28 }}>
                                {column.render('Header')}
                                <span>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <ArrowDownwardIcon style={{ marginLeft: 10 }} />
                                    ) : (
                                      <ArrowUpwardIcon style={{ marginLeft: 10 }} />
                                    )
                                  ) : (
                                    <div style={{ width: 24, height: 24, marginLeft: 10 }} />
                                  )}
                                </span>
                              </div>
                            </div>
                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>{buildTBody()}</tbody>
              </table>
            </div>
            <TablePagination
              sx={{ position: 'sticky', bottom: 0, right: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
              component='div'
              count={pageOptions.length}
              page={pageIndex}
              rowsPerPageOptions={pageSizeOptions}
              onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
                if (progressStore.isLoading(LOAD_CAMPAIGN_LINKS)) return;
                gotoPage(newPage);
              }}
              rowsPerPage={pageSize}
              onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                setPageSize(Number(event.target.value))
              }
            />
          </>
        );
      }}
    />
  );
};
export { LinksTable };

import shortId from 'shortid';

import { t } from 'utils';

import { OPTIONS_ACTIONS } from '../constants';

export const optionsAction = (): SelectOption[] => {
  let actions: SelectOption[] = [];

  OPTIONS_ACTIONS.forEach((item) => {
    actions.push({
      key: shortId(),
      label: t.staticAsString(`supportTaskManager.${item}.name` as TranslationLockedKeys),
      value: item,
    });
  });

  return actions;
};

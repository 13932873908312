export const TEXT_EDITOR_TOOLBAR = `
undo redo |
bold italic underline strikethrough |
formatselect |
numlist bullist |
fullscreen |
image media link |
blockquote |
code`;

export const TEXT_EDITOR_PLUGINS = `autolink fullscreen image link media lists imagetools quickbars code`;
export const TEXT_EDITOR_MENU = 'edit';
export const TEXT_EDITOR_QUICK_MENU = 'bold italic | quicklink h2 h3 blockquote quickimage';

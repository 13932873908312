import { computed, action, makeObservable } from 'mobx';

import { Distance as DistanceModel } from 'models';

import { Loadable } from './loadable';

class HelperDistances extends Loadable<DistanceType, FiltersType> {
  declare resourceParams: FiltersType;

  declare filters: FiltersType;

  constructor() {
    super();

    makeObservable(this);
    this.resourceParams = {
      limit: 10,
      with: 'waves;custom_fields.values;editor_settings;prices;registration_fields;classes;checkpoints',
    };
    this.filters = {
      limit: 10,
    };
  }

  @computed
  get modelSelected(): nil | DistanceModel {
    if (this.selected) {
      return new DistanceModel(this.selected);
    } else {
      return null;
    }
  }

  @computed
  get modelValues(): Array<DistanceModel> {
    return this.values.map((distance) => new DistanceModel(distance));
  }

  valuesForSelect(): Array<SelectOption> {
    return this.modelValues.map(({ value }) => ({
      key: value.id,
      value: value.id,
      label: value.name || '',
    }));
  }

  @action
  cleanSelected() {
    this.selected = null;
  }

  findModelValue(id: number): nil | DistanceModel {
    const { modelValues } = this;
    return modelValues.find((el) => el.value.id === id);
  }
}

const helperDistancesStore = new HelperDistances();
export { HelperDistances, helperDistancesStore };

import React from 'react';

import { Icon } from 'components/Icon';

import { formatCurrency } from '../utils/formatCurrency';

type Props = {
  value: number;
  currency: string;
};

export default function RefundDisplay({ value, currency }: Props) {
  return (
    <span style={{ color: '#BD0000', display: 'flex', alignItems: 'center' }}>
      —{formatCurrency(value, currency)} <Icon style={{ marginLeft: 11 }} value='refund' />
    </span>
  );
}

import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { resultsStubStore } from '../../stores';

const isResultsPresented: IComputedValue<boolean> = computed((): boolean => {
  return !!resultsStubStore.retrieveActiveStore().paginationMeta.total;
});

export { isResultsPresented };

import { pick } from 'lodash';

import { t } from 'utils';

import { sessionStore } from 'stores';

const getRaceValidationSchema = (isCalendarRace = false) => ({
  name: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('races.new.errors.name'),
    },
    length: {
      minimum: 3,
      maximum: 255,
    },
  },

  race_url: {
    url: {
      message: () => t.staticAsString('races.new.errors.invalid_url'),
    },
    ...(isCalendarRace
      ? {
          presence: {
            allowEmpty: false,
            message: () => t.staticAsString('shared.errors.presence'),
          },
        }
      : {}),
  },

  race_date: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('races.new.errors.race_date'),
    },
  },
  race_end_date: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('races.new.errors.race_date'),
    },
  },

  sport_id: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('races.new.errors.sport_type_id'),
    },
  },
  organizer_emails: {
    emailArray: {
      // Extended validation, check utils/customValidators.ts
    },
  },
  organizer_name: {
    length: {
      maximum: 255,
    },
  },
  organizer_description: {
    length: {
      maximum: 500,
    },
  },
  organizer_website: {
    url: {
      message: () => t.staticAsString('races.new.errors.invalid_url'),
    },
  },
  organizer_instagram: {
    url: {
      message: () => t.staticAsString('races.new.errors.invalid_url'),
    },
  },
  organizer_facebook: {
    url: {
      message: () => t.staticAsString('races.new.errors.invalid_url'),
    },
  },
  organizer_linkedin: {
    url: {
      message: () => t.staticAsString('races.new.errors.invalid_url'),
    },
  },

  ...(sessionStore.isAdmin
    ? {
        organizer_id: {
          presence: {
            allowEmpty: false,
            message: () => t.staticAsString('races.new.errors.organizer_id'),
          },
        },
      }
    : {}),
});
const getFieldValidationSchema = (fieldName, isCalendarRace = false) => pick(getRaceValidationSchema(isCalendarRace), fieldName);

export { getRaceValidationSchema, getFieldValidationSchema };

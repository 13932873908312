import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { Observer } from 'mobx-react';
import { SynchronizationActions } from 'modules/Synchronisation/Table/actions/list';
import { generateSynchronizationColumns } from 'modules/Synchronisation/Table/components/Table/Columns';
import { sortMapToBackend, SynchronisationFiltersMappers } from 'modules/Synchronisation/Table/mappers/filters.mapper';
import { SynchronizationsStore } from 'modules/Synchronisation/Table/stores/synchronizations.store';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { withProgressSpinner } from 'hocs';

import { t } from 'utils';

import { CreateSyncModal, SynchronizationsTable } from '../components';

import { SynchronizationsConstants } from '../constants/synchronizations.constants';

interface Props {}

export const SynchronisationTableComponent = ({}: Props): ReactElement => {
  const [openSyncModal, setOpenSyncModal] = useState(false);

  const initSort = useMemo(
    () => [
      {
        id: 'created_at',
        desc: true,
      },
    ],
    [],
  );

  useEffect(() => {
    const init = async () => {
      await SynchronizationActions.init();
    };
    init();
    return () => {
      SynchronizationActions.unmount();
    };
  }, []);

  const fetchData = useCallback(({ pageIndex = 0, pageSize, filters, sortBy }) => {
    const { orderBy, sortedBy } = sortMapToBackend(sortBy);

    SynchronizationActions.load({
      page: pageIndex + 1,
      limit: pageSize,
      orderBy,
      sortedBy,
      ...SynchronisationFiltersMappers.toBackend(filters),
    });
  }, []);

  const handleCreateSync = () => {
    setOpenSyncModal(true);
  };

  return (
    <div style={{ padding: 0, backgroundColor: 'white', position: 'relative' }} className='content main-container-list races-list'>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
        <h1 style={{ padding: '36px 24px' }} className='title'>
          {t.staticAsString('sync.list.header')}
        </h1>
        <Button
          onClick={handleCreateSync}
          variant='contained'
          className='add-button'
          sx={{
            mr: 3,
            height: '48px',
            paddingBottom: '16px',
            paddingTop: '16px',
            fontWeight: 500,
            letterSpacing: '1.25px',
            lineHeight: '16px',
          }}
          startIcon={<AddIcon className='icon' />}
        >
          {t.staticAsString('sync.list.create')}
        </Button>
      </Box>

      <Observer
        render={() => {
          return (
            <SynchronizationsTable
              tableData={SynchronizationsStore.synchronizations.value?.data ?? []}
              tableMeta={SynchronizationsStore.synchronizations.value?.meta ?? {}}
              initialSizeOfPage={30}
              pageSizeOptions={[30, 50, 100, 300]}
              tableColumns={generateSynchronizationColumns()}
              fetchData={fetchData}
              initSort={initSort}
            />
          );
        }}
      />
      <CreateSyncModal open={openSyncModal} onClose={() => setOpenSyncModal(false)} />
    </div>
  );
};

export const SynchronisationTableWrapper = withProgressSpinner(SynchronizationsConstants.SynchronizationsInit)(
  SynchronisationTableComponent,
);

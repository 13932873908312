export const geocodeByAddress = (address: string): Promise<google.maps.GeocoderResult[]> => {
  const geocoder = new (window as any).google.maps.Geocoder();
  const OK = (window as any).google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ address }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};

export const getLatLng = (result: google.maps.GeocoderResult): Promise<google.maps.LatLngLiteral> => {
  return new Promise((resolve, reject) => {
    try {
      const latLng = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      };
      resolve(latLng);
    } catch (e) {
      reject(e);
    }
  });
};

import isArray from 'lodash/isArray';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import shortid from 'shortid';

import { t } from 'utils';

import { errorsStore } from 'stores';

import { ContextValue, FormContext } from '../FormWrapper';

type Props = {
  Component: any; // TODO, describe proper input component interface based om BaseInputProps,
  settings: AnyObject;
  name: string;
};

type State = {
  inputId: nil | string;
  lodashName: string[];
};

// Standartized input wrapper
@observer
class InputWrapper extends React.Component<Props, State> {
  static defaultProps = {
    settings: {} as any,
  };
  static contextType = FormContext;

  onlyNumberRegexp = /^\d*$/;

  state = (() => {
    const { settings, name } = this.props;
    const { id } = settings;
    const lodashName = name.split('.');

    if (!!id) {
      return { inputId: null, lodashName };
    }

    return { inputId: shortid(), lodashName };
  })();

  // @ts-ignore
  context: ContextValue;

  componentDidMount() {
    const { lodashName } = this.state;
    this.context.form && this.context.form.registerField(lodashName);
  }

  inputProps = () => {
    const { name, settings } = this.props;
    const { inputId } = this.state;

    return { name, ...settings, id: settings.id || inputId };
  };

  generateAutoComplete = (): Object => {
    const disableAutofill = this.context.form?.disableAutofill;

    if (!disableAutofill) {
      return {};
    }

    const autoComplete = 'new-password';
    return { autoComplete };
  };

  value = (): any => {
    const { name } = this.props;
    return this.context.form && this.context.form.fetch(name); // TODO
  };

  error = (): string => {
    const { name } = this.props;
    const formName = this.context.form && this.context.form.id;

    if (!formName || !name) {
      return '';
    }

    const errors = errorsStore.get(formName, name);
    if (!isArray(errors)) {
      return '';
    }

    return t(errors[0]) as any;
  };

  onChange = (
    changes: {
      value: any;
    },
    e: React.SyntheticEvent,
  ) => {
    const { name } = this.props;
    const { lodashName } = this.state;
    if (this.props.settings.type === 'number' && !this.onlyNumberRegexp.test(changes.value.toString())) {
      return;
    }

    if (this.context.form) {
      this.context.form.onChange({ ...changes, name: lodashName });
      // Custom onChange event listener listener
      this.props.settings?.additional?.onInputChange?.({ ...changes, name: lodashName }, e);
    }

    if (this.context.form?.cleanErrorOnChange) {
      errorsStore.clean(this.context.form.id, name);
    }
  };

  render() {
    const { Component } = this.props;
    const value = this.value();
    const error = this.error();
    const autoCompleteOptions = this.generateAutoComplete();

    return <Component onChange={this.onChange} value={value} error={error} {...autoCompleteOptions} {...this.inputProps()} />;
  }
}

export { InputWrapper };

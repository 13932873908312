import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { emphasize } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';
import Select from 'react-select';

import * as components from './Components';
import { Label as InputLabel } from './Label';
import { selectStore } from './store';

/*
 * @Deprecated
 *
 * This component is deprecated since we have AutocompleteSelect
 * path (components/Fields/AutocompleteSelect)
 * */

const styles: any = (theme: any) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    marginTop: theme.spacing(1),
  },
  divider: {
    height: theme.spacing(2),
  },
});

type Props = {
  classes?: {
    [K in string]: string;
  };
  name: string;
  onChange: React.ChangeEventHandler;
  onMenuOpen?: any;
  onMenuClose?: any;
  value: any;
  errors: Array<string>;
  type?: string;
  id?: string;
  label?: string;
  className?: string;
  options?: Array<SelectOption>;
  theme: any;
  reservePlaceForHelperText?: boolean;
};

class SelectFieldWithSearchComponent extends React.Component<Props> {
  static defaultProps = {
    reservePlaceForHelperText: true,
  };

  handleChange = (option: any): void => {
    const { onChange, name } = this.props;

    const currentValue = this.selectedValue();
    if (!currentValue?.value && !option.value) return;

    const event = {
      target: {
        name,
        value: option.value,
      },
      currentTarget: {
        name,
        value: option.value,
      },
    } as any;
    onChange(event);
  };

  selectedValue = () => {
    const { value, options } = this.props;
    return options?.find((val) => val.value === value);
  };

  componentDidMount() {
    this.setDefaultValue();
  }

  componentDidUpdate(prevProps: Props) {
    const { options } = this.props;

    if (prevProps?.options?.length === 0 && !!(options && options.length > 0)) {
      this.setDefaultValue();
    }
  }

  setDefaultValue() {
    const { options, value } = this.props;
    const defaultValue = options?.find((el) => !!el.default);

    if (!defaultValue || !!value) {
      return;
    }

    this.handleChange(defaultValue);
  }

  onFocus = (id: string) => (): void => {
    selectStore.setFocus(id, true);
  };

  onBlur = (id: string) => (): void => {
    selectStore.setFocus(id, false);
  };

  render() {
    const {
      value,
      name,
      errors = [],
      id = '',
      label = '',
      options = [],
      classes = {},
      theme,
      className,
      onMenuOpen,
      onMenuClose,
      reservePlaceForHelperText,
    } = this.props;

    const isErr = errors.length > 0;
    const formName = id ? `${id}_${name}` : name;
    const errorMessage = errors.join(' ');
    const helperText = errorMessage || (reservePlaceForHelperText && ' ');

    const selectStyles = {
      input: (base: any) => ({
        ...base,
        color: theme.palette.text.primary,
      }),
      heigth: 53,
    };

    const Control = components['Control'];

    const ControlComponent = (props: any) => {
      return <Control name={name} id={id} formName={formName} selectValue={value || ''} className={className} {...props} />;
    };

    return (
      <div className={classNames(classes.root, 'select-with-search', className)}>
        <FormControl className={classNames('form-control', { error: isErr })} error={isErr} aria-describedby='name-error-text' fullWidth>
          <InputLabel formName={formName} emptyValue={!value}>
            {label}
          </InputLabel>
          <Select
            minMenuHeight={200}
            classes={classes}
            className='force-height'
            styles={selectStyles}
            options={options}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            onFocus={this.onFocus(formName)}
            onBlur={this.onBlur(formName)}
            components={{ ...components, Control: ControlComponent }}
            value={this.selectedValue() || null}
            onChange={this.handleChange}
            placeholder={''}
          />
          <FormHelperText className='errors' id={`${formName}_error`}>
            {helperText || ' '}
          </FormHelperText>
        </FormControl>
      </div>
    );
  }
}

const DecoratedSelectFieldWithSearch = withStyles(styles, { withTheme: true })(SelectFieldWithSearchComponent);

export { DecoratedSelectFieldWithSearch as SelectWithSearch };

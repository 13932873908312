import { cloneDeep, has, isEmpty, isObject, omit, isString } from 'lodash';

import { countriesStore, sessionStore } from 'src/stores';

import { objectsDifference } from 'utils/difference';

import { SystemUserFormPageService } from '../services/systemUserFormPage.service';

import { systemUserRolesStore, organizersStore, systemUserFormStore } from '../stores';

import type { SystemUser as SystemUserType, Role, SystemUserForm } from '../types';

export const omitEmptyFields = (data: SystemUserType): SystemUserType => {
  const omitKeys: Array<string> = Object.keys(data).map((key) => {
    if (!data[key]) {
      return key;
    }

    return '';
  });

  return omit(data, omitKeys) as any;
};

export const mapToPost = (value: SystemUserType): SystemUserType => {
  return Object.entries(value).reduce((acc, cur: any) => {
    let [key, val] = cur;
    if (val) {
      acc[key] = val;
      if (['country_id', 'created_by'].includes(key)) {
        acc[key] = val?.value;
      }
    }

    return acc;
  }, {}) as any;
};

export const mapFrom = (value: SystemUserType): SystemUserType => {
  const copy: SystemUserType = cloneDeep(value);
  const countries = countriesStore.valuesForCountryDropdown();
  copy.country_id = countries.find((country) => country.value === copy.country_id) || {};

  const org = (organizersStore.value || []).find((org) => org.id === copy.created_by);

  copy.created_by =
    copy.created_by === sessionStore.user?.user_id
      ? getCurrentUserData()
      : {
          key: org?.id,
          label: getName(org),
          value: org?.id,
        };

  return value;
};

const NULLABLE_FIELD = ['address', 'city', 'country_id', 'post_code', 'phone'];

export const includeDeletedFields = (data: SystemUserType, deletedFields: string[]): any => {
  return deletedFields.reduce((acc, field) => {
    if (!NULLABLE_FIELD.includes(field)) {
      return acc;
    }

    return { ...acc, [field]: null };
  }, data);
};

export const getName = (org: AnyObject = {}) => {
  const { firstname = '', lastname = '', username = '', full_name = '' } = org;

  const fullname = full_name || `${firstname || ''} ${lastname || ''}`.trim();

  if (fullname) {
    return username ? `${fullname} (${username})` : fullname;
  }
  return username;
};

export const getCurrentUserData = () => {
  return {
    key: sessionStore.user?.user_id,
    value: sessionStore.user?.user_id,
    label: getName(sessionStore.user || {}),
  };
};

export const mapSystemUser = (data: AnyObject) => {
  const user = cloneDeep(data);
  !user.role ? (user.role = user.roles[0]?.name) : null;
  isObject(user.created_by) ? (user.created_by = (user.created_by as AnyObject).key) : null;
  return user;
};

export const getChangesOfUser = (newData: SystemUserType, oldData: AnyObject) => {
  let changedData = objectsDifference(newData, oldData);
  changedData.updated_at = newData.updated_at;
  changedData.id = newData.id;
  return changedData;
};

/**
 * @description
 * created_by field is not necessary when creating organizer
 */
function isSystemUserNeedCreator(role: string | nil) {
  return role && ['medical', 'assistant'].includes(role);
}

const formToValidRole = (value: string) => {
  switch (value) {
    case 'Medical Assistant':
      return 'medical';
    case 'Timing Assistant':
      return 'assistant';
    case 'Organizer':
      return 'organizer';
    case 'Admin':
      return 'admin';
    default:
      return '';
  }
};
export const validRoleFromBackend = (value: string) => {
  switch (value) {
    case 'medical':
      return 'Medical Assistant';
    case 'assistant':
      return 'Timing Assistant';
    case 'organizer':
      return 'Organizer';
    case 'admin':
      return 'Admin';
    default:
      return '';
  }
};
const predefineRole = (systemUserForm: SystemUserForm) => {
  const roles = cloneDeep(systemUserForm)?.roles as Array<any>;

  if (!sessionStore.isAdmin && (systemUserForm.id === sessionStore.user?.user_id || systemUserForm.id === sessionStore.user?.organizer_id))
    return validRoleFromBackend(roles[0]?.name);

  const userRole = systemUserRolesStore.value?.find((role) => role.name === roles[0]?.name) as Role;
  return userRole.title || '';
};
const predefineCreateBy = (systemUserForm: SystemUserForm) => {
  const user = cloneDeep(systemUserForm);
  const role = user.roles[0]?.name;

  if (!sessionStore.isAdmin) return systemUserForm.created_by;

  const org = ([...(organizersStore.value as Array<OrganizerType>), { ...sessionStore.user, id: sessionStore.user?.user_id }] || []).find(
    (org) => org.id === user.created_by,
  );

  const forSelect =
    user.created_by === sessionStore.user?.user_id
      ? getCurrentUserData()
      : {
          key: org?.id,
          label: getName(org),
          value: org?.id,
        };

  return ['medical', 'assistant'].includes(role) ? forSelect : systemUserForm.created_by;
};

const setCreateById = (value: AnyObject) => {
  const user = cloneDeep(value);
  const idByRole = sessionStore.user?.organizer_id || sessionStore.user?.user_id;

  return !has(user, 'created_by') || !Boolean(user?.created_by)
    ? idByRole
    : isObject(user.created_by)
    ? (user.created_by as AnyObject).value
    : user.created_by;
};

const isOwnProfile = () => {
  if ((!Boolean(sessionStore.user?.user_id) || !Boolean(sessionStore.user?.organizer_id)) && !Boolean(systemUserFormStore.selected?.id))
    return false;

  return (
    sessionStore.user?.user_id === systemUserFormStore.selected?.id || sessionStore.user?.organizer_id === systemUserFormStore.selected?.id
  );
};

const deleteCurrency = async (userId: number, currencies: Array<any>, payments: Array<any>) => {
  let actions: Array<Promise<void>> = [];
  const active = new Set(currencies.map(({ value }) => value));
  const filteredPayments = payments.filter(({ currency_id }) => !active.has(currency_id));

  if (isEmpty(filteredPayments)) return;

  filteredPayments.forEach((payment) => {
    if (has(payment, 'id') && payment?.id) {
      actions.push(SystemUserFormPageService.deleteSystemUserCurrency(userId, payment.currency_id));
    }
  });

  return await Promise.all(actions);
};

const setCommission = (value) => (isEmpty(value) && isString(value) ? null : Number(value) * 100);

export {
  isSystemUserNeedCreator,
  formToValidRole,
  predefineRole,
  predefineCreateBy,
  setCreateById,
  isOwnProfile,
  deleteCurrency,
  setCommission,
};

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const yearContainerClasses = makeStyles((theme: Theme) => ({
  root: {
    '& .navigation-name': {
      lineHeight: '1.2',
    },
    '& .navigation-button': {
      '&  svg > path': {
        stroke: theme.palette.grey7 + '!important',
      },
      '&:hover': {
        cursor: 'pointer',
        '& svg > path': {
          stroke: theme.palette.white2 + '!important',
          '&.circle': {
            fill: theme.palette.grey7 + '!important',
            stroke: theme.palette.grey7 + '!important',
          },
        },
      },
    },
  },
}));

type useSingleWrapperProps = {
  containerHeight: number;
};

export const singleWrapperClasses = makeStyles<Theme, useSingleWrapperProps>((theme: Theme) => ({
  root: {
    width: '375px',

    '& .CalendarDay': {
      boxSizing: 'content-box',
      padding: 0,
      width: '44px !important',
      height: '44px !important',
      borderRadius: '5px',
      border: `2px solid #fff`,
      fontSize: '20px',
      fontWeight: '500',
      color: theme.palette.grey1,
    },

    '& .DayPicker_weekHeader_li': {
      color: '#C3C6C8',
      fontWeight: '700',
      fontSize: '20px',
      width: '46px !important',
    },
    '& .DayPicker': {
      width: '375px !important',

      '& .DayPicker_weekHeader, .CalendarMonth': {
        padding: '0 20px !important;',
      },

      '& .DayPicker_weekHeader ': {
        paddingTop: '28px !important',

        '& .DayPicker_weekHeader ': {
          fontSize: 'inherit',
        },
      },
      '& > div > div, .DayPicker_transitionContainer': {
        width: '375px !important',
      },
      '& .navigate-left': {
        position: 'absolute',
        top: '51px',
        left: '20px',

        '& svg > path': {
          stroke: theme.palette.grey1,
        },

        '&:hover': {
          '& svg > path': {
            stroke: theme.palette.white2,
            '&.circle': {
              fill: theme.palette.grey1,
              stroke: theme.palette.grey1,
            },
          },
        },
      },
      '& .navigate-right': {
        position: 'absolute',
        top: '51px',
        right: '20px',

        '& svg > path': {
          stroke: theme.palette.grey1,
        },

        '&:hover': {
          '& svg > path': {
            stroke: theme.palette.white2,
            '&.circle': {
              fill: theme.palette.grey1,
              stroke: theme.palette.grey1,
            },
          },
        },
      },

      '& .DayPicker_transitionContainer': {
        height: ({ containerHeight }) => `${containerHeight}px !important`,
        paddingTop: '36px !important',
        '& @media (min-width: ${RESOLUTIONS.medium}px)': {
          paddingTop: '52px !important',
        },
      },
    },

    '& .CalendarMonth_caption': {
      paddingTop: '16px',
      paddingBottom: '0',
    },
    '& .month-header': {
      flexDirection: 'column',
      alignItems: 'center',

      '& .change-year': {
        position: 'absolute',
        top: '-28px',
        left: '126px',
        width: '108px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'self-start',
      },
    },

    '& .CalendarDay__today:not(.CalendarDay__outside)': {
      position: 'relative',
      background: 'none',

      '&:after ': {
        content: '""',
        position: 'absolute',
        bottom: '1px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '5px',
        height: '5px',
        borderRadius: '50%',
        backgroundColor: theme.palette.grey1,
      },
    },

    '& .CalendarDay__today.CalendarDay__selected_span': {
      backgroundColor: theme.palette.main,
    },
    '& .CalendarDay__today.CalendarDay__selected_start': {
      backgroundColor: theme.palette.main,
    },
    '& .CalendarDay__today.CalendarDay__hovered_span': {
      backgroundColor: theme.palette.main,
    },

    '& .CalendarMonth_table': {
      marginTop: '44px',
    },

    '& .CalendarDay__outside': {
      background: 'none',
      color: '#C3C6C8',
    },
  },
}));

export const rangeWrapperClasses = makeStyles((theme: Theme) => ({
  root: {
    width: '842px',

    '& .change-year': {
      position: 'absolute',
      left: '342px',
      top: '32px',
      width: '142px',
      display: 'flex',
      justifyContent: 'space-between',
    },

    '& .CalendarDay': {
      boxSizing: 'content-box',
      padding: '0',
      width: '44px !important',
      height: '44px !important',

      border: `2px solid #fff`,
      borderRadius: '5px',
      color: theme.palette.grey1,
      fontWeight: '500',
      fontSize: '20px',
    },

    '& .CalendarDay__outside': {
      background: 'none',
      color: '#C3C6C8',
    },
    '& .CalendarDay__today:not(.CalendarDay__outside)': {
      position: 'relative',
      background: 'none',

      '&:after ': {
        content: '""',
        position: 'absolute',
        bottom: '1px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '5px',
        height: '5px',
        borderRadius: '50%',
        backgroundColor: theme.palette.grey1,
      },
    },

    '& .CalendarDay__today.CalendarDay__selected_span': {
      backgroundColor: theme.palette.main,
    },
    '& .CalendarDay__today.CalendarDay__selected_start': {
      backgroundColor: theme.palette.main,
    },
    '& .CalendarDay__today.CalendarDay__hovered_span': {
      backgroundColor: theme.palette.main,
    },

    '& .DayPicker_weekHeader_li': {
      color: '#C3C6C8',
      fontWeight: '700',
      fontSize: '20px',
      width: '46px !important',
    },
    '& .DayPicker': {
      // borderRadius: '24px',

      '& .CalendarMonth_caption': {
        marginTop: '70px',
        paddingBottom: '60px',
        paddingTop: '14px',
      },

      '& .DayPicker_weekHeader': {
        marginTop: '66px',
      },

      '& .DayPicker_weekHeaders .DayPicker_weekHeader:last-child': {
        paddingLeft: '156px !important',
      },

      '& .DayPicker_weekHeader, .CalendarMonth': {
        padding: '0 44px !important',
      },

      '& > div > div, .DayPicker_transitionContainer': {
        width: '842px !important',
      },

      '& .navigate-left': {
        position: 'absolute',
        top: '85px',
        left: '55px',

        '& svg > path': {
          stroke: theme.palette.grey1,
        },

        '&:hover': {
          '& svg > path': {
            stroke: theme.palette.white2,
            '&.circle': {
              fill: theme.palette.grey1,
              stroke: theme.palette.grey1,
            },
          },
        },
      },

      '& .navigate-right': {
        position: 'absolute',
        top: '85px',
        right: '55px',

        '& svg > path': {
          stroke: theme.palette.grey1,
        },

        '&:hover': {
          '& svg > path': {
            stroke: theme.palette.white2,
            '&.circle': {
              fill: theme.palette.grey1,
              stroke: theme.palette.grey1,
            },
          },
        },
      },

      '& .DayPicker_transitionContainer': {
        borderRadius: '24px',
        height: '452px !important',
      },
    },
  },
}));

export const singleMonthRangeWrapperClasses = makeStyles((theme: Theme) => ({
  root: {
    width: '423px',

    '& .CalendarDay': {
      boxSizing: 'content-box',
      padding: '0',
      width: '44px !important',
      height: '44px !important',

      border: `4px solid #fff`,
      borderRadius: '5px',
      color: theme.palette.grey1,
      fontWeight: '500',
      fontSize: '20px',
    },

    '& .CalendarDay__today:not(.CalendarDay__outside)': {
      position: 'relative',
      background: 'none',
      '&:after': {
        content: '',
        position: 'absolute',
        bottom: '1px',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '5px',
        height: '5px',
        borderRadius: '50%',
        backgroundColor: theme.palette.grey1,
      },
    },

    '& .DayPicker_weekHeader_li': {
      color: '#C3C6C8',
      fontWeight: '700',
      fontSize: '20px',
      width: '48px !important',
    },
    '& .DayPicker': {
      width: '425px !important',

      '& .DayPicker_weekHeader, .CalendarMonth': {
        padding: '0 32px !important',
      },

      '& .DayPicker_weekHeader': {
        paddingTop: '56px !important',
      },

      '& > div > div, .DayPicker_transitionContainer': {
        width: '425px !important',
        minHeight: '375px !important',
        paddingBottom: '30px',
      },
      '& .DayPicker_weekHeader_li small': {
        fontSize: 'inherit',
      },

      '& .DayPicker_transitionContainer': {
        // height: ${(props) => props.containerHeight + 'px'} !important;
        paddingTop: '36px !important',

        '@media (min-width: ${RESOLUTIONS.medium}px)': {
          paddingTop: '52px !important',
        },
      },

      '& .navigate-left': {
        position: 'absolute',
        top: '72px',
        left: '20px',

        '& svg > path': {
          stroke: theme.palette.grey1,
        },

        '&:hover': {
          '& svg > path': {
            stroke: theme.palette.white2,
            '&.circle': {
              fill: theme.palette.grey1,
              stroke: theme.palette.grey1,
            },
          },
        },
      },
      '& .navigate-right': {
        position: 'absolute',
        top: '72px',
        right: '20px;',

        '& svg > path': {
          stroke: theme.palette.grey1,
        },

        '&:hover': {
          '& svg > path': {
            stroke: theme.palette.white2,
            '&.circle': {
              fill: theme.palette.grey1,
              stroke: theme.palette.grey1,
            },
          },
        },
      },

      '& .CalendarMonth_caption': {
        paddingTop: '36px',
      },
    },

    '& .month-header': {
      flexDirection: 'column',
      alignItems: 'center',
    },

    '& .DayPicker_weekHeader + .DayPicker_weekHeader': {
      background: 'red',
      left: '410px !important',
    },

    '& .CalendarMonth_table': {
      marginTop: '44px',
    },

    '& .CalendarMonth_caption': {
      paddingTop: '16px',
      paddingBottom: '0',
    },

    '& .CalendarDay__today.CalendarDay__selected_span': {
      backgroundColor: theme.palette.main,
    },
    '& .CalendarDay__today.CalendarDay__selected_start': {
      backgroundColor: theme.palette.main,
    },
    '& .CalendarDay__today.CalendarDay__hovered_span': {
      backgroundColor: theme.palette.main,
    },

    '& .CalendarDay__outside': {
      background: 'none',
      color: '#C3C6C8',
    },

    '& .change-year': {
      position: 'absolute',
      top: '-28px',
      width: '142px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      left: '132px',
    },
  },
}));

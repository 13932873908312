import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Collapse, Tooltip } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { Observer } from 'mobx-react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { Icon } from 'components/Icon';

import { match } from 'utils/index';
import { t } from 'utils/t';

import { sessionStore, sidebarStore } from 'stores';

type MenuEntry = {
  title: string;
  icon: string;
  path: string;
  items?: MenuEntry[];
};

const {
  sportTypesRoute,
  transactionsRoute,
  commissionsRoute,
  racesRoute,
  reportsRoute,
  couponsRoute,
  systemUsersRoute,
  customersRoute,
  racersRoute,
  supportManagerTasksRoute,
  sync,
  smartLinks,
  campaignLinks,
} = ROUTES;

const drawerWidth = 233;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const [collapsibleOpenEntry, setCollapsibleEntry] = useState('');
  const handleClick = (entry: string) => {
    if (entry === collapsibleOpenEntry) {
      return setCollapsibleEntry('');
    }
    return setCollapsibleEntry(entry);
  };

  const ADMIN_MENU: MenuEntry[] = [
    { title: t.staticAsString('sidebar.races'), path: racesRoute, icon: 'races' },
    {
      title: 'Reconciliation',
      icon: 'orders',
      path: '',
      items: [
        { title: t.staticAsString('sidebar.transactions'), icon: 'transaction', path: transactionsRoute },
        { title: 'Commissions', icon: 'commissions', path: commissionsRoute },
        { title: 'Reports', icon: 'report', path: reportsRoute },
      ],
    },
    { title: t.staticAsString('sidebar.categories'), path: sportTypesRoute, icon: 'sport-type' },
    { title: t.staticAsString('sidebar.customers'), path: customersRoute, icon: 'customers' },
    { title: t.staticAsString('sidebar.promotions'), path: couponsRoute, icon: 'promo' },
    { title: t.staticAsString('sidebar.systemUsers'), path: systemUsersRoute, icon: 'organizers' },
    { title: t.staticAsString('sidebar.racers'), path: racersRoute, icon: 'racers' },
    { title: t.staticAsString('sidebar.supportManager'), path: supportManagerTasksRoute, icon: 'support-manager' },
    { title: t.staticAsString('sidebar.sync'), path: sync, icon: 'sync' },
    { title: t.staticAsString('sidebar.smart_links'), path: smartLinks, icon: 'smart_links' },
    { title: 'Campaign Links', path: campaignLinks, icon: 'smart_links' },
  ];
  const ORGANIZER_MENU: MenuEntry[] = [
    { title: t.staticAsString('sidebar.races'), path: racesRoute, icon: 'races' },
    { title: t.staticAsString('sidebar.dashboard'), icon: 'dashboard', path: '/dashboard' },
    { title: t.staticAsString('sidebar.transactions'), icon: 'orders', path: '/payments' },
    { title: t.staticAsString('sidebar.promotions'), path: couponsRoute, icon: 'promo' },
    { title: t.staticAsString('sidebar.raceAssistants'), path: systemUsersRoute, icon: 'organizers' },
    { title: t.staticAsString('sidebar.racers'), path: racersRoute, icon: 'racers' },
    { title: t.staticAsString('sidebar.smart_links'), path: smartLinks, icon: 'smart_links' },
    { title: 'Campaign Links', path: campaignLinks, icon: 'smart_links' },
    { title: 'Reports', icon: 'report', path: reportsRoute },
  ];

  let menu = ORGANIZER_MENU;
  if (sessionStore.isAdmin) {
    menu = ADMIN_MENU;
  }

  return (
    <Observer
      render={() => {
        const isActive = (path: string) => path && match(location?.pathname as string, { path });
        const isExpanded = !sidebarStore.isCollapsed;
        return (
          <Drawer
            PaperProps={{
              sx: { position: 'fixed', top: '80px', backgroundColor: '#262E35' },
            }}
            variant='permanent'
            open={isExpanded}
          >
            <List disablePadding className='drawer'>
              {menu.map((item) => {
                if (item.items) {
                  return (
                    <ListItem key={item.path} disablePadding sx={{ display: 'block' }}>
                      <Tooltip key={item.path} title={isExpanded ? '' : item.title} placement='right'>
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: isExpanded ? 'initial' : 'center',
                            px: 2.5,
                            background: isActive(item.path) ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                            '&:hover': {
                              background: 'rgba(255, 255, 255, 0.1)',
                            },
                          }}
                          onClick={() => handleClick('Reconciliation')}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: isExpanded ? 3 : 'auto',
                              justifyContent: 'center',
                            }}
                          >
                            <Icon value={item.icon} style={{ marginTop: '8px' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={item.title}
                            primaryTypographyProps={{
                              sx: {
                                fontSize: 12,
                                fontWeight: 400,
                              },
                            }}
                            sx={{
                              textTransform: 'uppercase',
                              color: 'white',
                              opacity: isExpanded ? 1 : 0,
                            }}
                          />
                          {isExpanded &&
                            (collapsibleOpenEntry === item.title ? (
                              <ExpandLess sx={{ color: 'white' }} />
                            ) : (
                              <ExpandMore sx={{ color: 'white' }} />
                            ))}
                        </ListItemButton>
                      </Tooltip>
                      <Collapse in={collapsibleOpenEntry === item.title} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                          {item.items.map((subItem) => {
                            return (
                              <Tooltip key={subItem.path} title={isExpanded ? '' : subItem.title} placement='right'>
                                <ListItemButton
                                  component={NavLink}
                                  to={subItem.path}
                                  key={subItem.path}
                                  sx={{
                                    transition: 'all 0.1s ease',
                                    justifyContent: isExpanded ? 'initial' : 'center',
                                    pl: isExpanded ? 5 : 2.5,
                                    background: isActive(subItem.path) ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                                    '&:hover': {
                                      background: 'rgba(255, 255, 255, 0.1)',
                                    },
                                  }}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: 0,
                                      mr: isExpanded ? 3 : 'auto',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Icon style={{ marginTop: '8px' }} value={subItem.icon} />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={subItem.title}
                                    primaryTypographyProps={{
                                      sx: {
                                        fontSize: 12,
                                        fontWeight: 400,
                                      },
                                    }}
                                    sx={{
                                      textTransform: 'uppercase',
                                      color: 'white',
                                      opacity: isExpanded ? 1 : 0,
                                    }}
                                  />
                                </ListItemButton>
                              </Tooltip>
                            );
                          })}
                        </List>
                      </Collapse>
                    </ListItem>
                  );
                }
                return (
                  <Tooltip key={item.path} title={isExpanded ? '' : item.title} placement='right'>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                      <ListItemButton
                        component={NavLink}
                        to={item.path}
                        sx={{
                          background: isActive(item.path) ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                          '&:hover': {
                            background: 'rgba(255, 255, 255, 0.1)',
                          },
                          justifyContent: isExpanded ? 'initial' : 'center',
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: isExpanded ? 3 : 'auto',
                            justifyContent: 'center',
                          }}
                        >
                          <Icon className='sidebar-svg-fill' style={{ marginTop: '8px' }} value={item.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary={item.title}
                          primaryTypographyProps={{
                            sx: {
                              fontSize: 12,
                              fontWeight: 400,
                            },
                          }}
                          sx={{
                            textTransform: 'uppercase',
                            color: 'white',
                            opacity: isExpanded ? 1 : 0,
                            whiteSpace: 'pre-wrap',
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Tooltip>
                );
              })}
            </List>
          </Drawer>
        );
      }}
    />
  );
};

export { Sidebar };

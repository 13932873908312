import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

import { googleTranslate } from 'actions';

import { TranslationField } from './TranslationField';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '5%',
    display: 'flex',
    border: '1px solid red',
    width: 1000,
    flexFlow: 'row',
    flexWrap: 'wrap',
  },
  flex: {
    padding: '5%',
    flex: '1 1 300px',
  },
  button: {
    width: 200,
    height: 100,
  },
}));

export const TranslationFieldContainer = () => {
  const classes = useStyles();

  const [name, setName] = React.useState('Composed TextField');
  const [label, setLabel] = React.useState('Label for next field');
  const [prefilled, setPrefilled] = React.useState('');

  const handleChangeLabel = (event) => {
    setLabel(event.target.value);
  };
  const handleChange = (event) => {
    setName(event.target.value);
  };
  const handleChangePrefilled = (event) => {
    setPrefilled(event.target.value);
  };

  const handleClick = async () => {
    const result = await googleTranslate({ q: 'hello world', from: 'en', to: 'es' });
  };

  const handleTouchStart = () => {};
  const handleTouchEnd = () => {};

  return (
    <div className={classes.root}>
      <Button className={classes.button} onClick={handleClick} onMouseEnter={handleTouchEnd} onMouseLeave={handleTouchStart}>
        Translate
      </Button>
      <div className={classes.flex}>
        <TranslationField name='1' label='' onChange={() => {}} value={''} hasCheckmark={false} />
      </div>
      <div className={classes.flex}>
        <TranslationField name='2' label='Test test label' onChange={() => {}} value={''} hasCheckmark={false} />
      </div>
      <div className={classes.flex}>
        <TranslationField name='3' label='Test test label' onChange={() => {}} value={'Some value'} hasCheckmark={true} />
      </div>
      <div className={classes.flex}>
        <TranslationField
          name='4'
          label='Test test label for prefilled'
          onChange={() => {}}
          value={'Some value'}
          prefilledValue={'Prefilled label here'}
          hasCheckmark={true}
        />
      </div>
      <div className={classes.flex}>
        <TranslationField
          name='5'
          label='Long Test test label Long Test test labelLong Test test labelLong Test test labelLong Test test labelLong Test test label'
          onChange={() => {}}
          value={''}
          hasCheckmark={true}
        />
      </div>
      <div className={classes.flex}>
        <TranslationField
          name='6'
          label='Long Test test label Long Test test labelLong Test test labelLong Test test labelLong Test test labelLong Test test label'
          onChange={() => {}}
          value={'Some value'}
          hasCheckmark={true}
        />
      </div>
      <div className={classes.flex}>
        Playgroung
        <TranslationField name='7' label='Enter label' onChange={handleChangeLabel} value={label} hasCheckmark={false} />
        <TranslationField name='8' label='Enter prefilled' onChange={handleChangePrefilled} value={prefilled} hasCheckmark={false} />
        <TranslationField
          name='9'
          label={label}
          onChange={handleChange}
          value={name}
          prefilledValue={prefilled}
          hasCheckmark={name.length > 5}
        />
      </div>
    </div>
  );
};

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { t } from 'utils';

import { Race } from 'models';

import { onClickIntegration } from '../../actions';

import { integrationExistStore } from '../../stores';

type Props = {
  onDelete: React.MouseEventHandler;
  onHideStartList: React.MouseEventHandler;
  onToggleResults: React.MouseEventHandler;
  onDuplicate(): void;
  race: nil | Race;
};

const classes = {
  toolbar: {
    menu: ['menu-card'],
    item: ['menu-card-item'],
    delete: ['menu-card-delete'],
  },
};

export const Settings: React.FC<Props> = observer((props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { onDelete, onDuplicate, onToggleResults, onHideStartList, race } = props;

  if (!race) return null;

  return (
    <>
      <IconButton
        className='icon-button-settings'
        aria-label='Settings'
        aria-controls='long-menu'
        aria-haspopup='true'
        size='medium'
        data-test-id='RaceSettings'
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ fontSize: 32 }} />
      </IconButton>
      <Menu
        id='settings-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem className={classNames(...classes.toolbar.item)} onClick={onDuplicate}>
          <p>{t.staticAsString('races.detail.duplication.title')}</p>
        </MenuItem>

        {!race.value.race_url
          ? ((
              <MenuItem onClick={onHideStartList} className={classNames(...classes.toolbar.item)}>
                <p>{t(race.value.show_startlist ? 'shared.hideStartListTab' : 'shared.showStartListTab')}</p>
              </MenuItem>
            ) as React.ReactElement)
          : (null as any)}

        {!race.value.race_url ? (
          <MenuItem onClick={onToggleResults} className={classNames(...classes.toolbar.item)}>
            <p>{t(race.value.show_results ? 'shared.hideResultTab' : 'shared.showResultTab')}</p>
          </MenuItem>
        ) : null}

        <MenuItem onClick={onClickIntegration} className={classNames(...classes.toolbar.item)}>
          <p>{t(integrationExistStore.value ? 'races.action.integrations' : 'races.action.addIntegration')}</p>
        </MenuItem>

        <MenuItem onClick={onDelete} className={classNames(...classes.toolbar.delete)}>
          <p>{t.staticAsString('races.action.delete')}</p>
        </MenuItem>
      </Menu>
    </>
  );
});

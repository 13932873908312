import axios from 'axios';
import { DashboardActions } from 'modules/Dashboard/actions/init';
import { resetDashboardStores } from 'modules/Dashboard/actions/resert-dashboard.action';

import { LOGIN, LOGOUT, REGISTRATION, REGISTRATION_URL, SESSIONS_URL } from 'src/constants';

import { request, action, history } from 'utils';

import { sessionStore } from 'stores';

type Payload = {
  email: string;
  password: string;
  phone: string;
  firstname: string;
  lastname: string;
  country_id: number;
};

class AuthService {
  @action({ action: LOGIN })
  async login(email: string, password: string) {
    const [responseStatus, response] = await this.loginRequest(email, password);
    if (responseStatus) {
      const user = response.data.data;
      const token = user ? user.token : null;
      if (user && token) await sessionStore.login(user, token);
    }
    return responseStatus;
  }

  @request({ action: LOGIN, root: 'errors' })
  loginRequest(email: string, password: string): any {
    return axios.post(SESSIONS_URL, { email, password });
  }

  @action({ action: REGISTRATION })
  async register(payload: Payload) {
    const [status, response] = await this.registerRequest(payload);

    if (status) {
      const user = response.data.data;
      const token = user ? user.token : null;

      if (user && token) {
        sessionStore.login(user, token);
        history.push('/');
      }
    }
    return status;
  }

  @request({ action: REGISTRATION, root: 'errors' })
  registerRequest(payload: Payload): any {
    return axios.post(REGISTRATION_URL, payload);
  }

  @action({ action: LOGOUT })
  async logout() {
    if (sessionStore.isLogged) {
      resetDashboardStores();
      DashboardActions.deinit();
      await this.logoutRequest();
      await sessionStore.logout();
    }
  }

  @request({ action: LOGOUT, root: 'errors' })
  logoutRequest(): any {
    return axios.delete(SESSIONS_URL);
  }
}

export { AuthService };

import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeactivateIcon from '@mui/icons-material/NoEncryption';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as React from 'react';

import { AWAITING } from 'src/constants';

import { Icon } from 'components/Icon';

import { t } from 'utils';

type Props = {
  isAwaiting: boolean;
  isRefundProtectPaid: boolean;
  isAttachToProfile: boolean;
  teamStatus: string;
  handleCopy: Function;
  handleResend: Function;
  handleAttachToProfile: Function;
  handleDelete: Function;
  handleDeactivateRefundProtect: Function;
  isSuccesfulEmail: boolean;
};

type PopupStateType = {
  close: AnyFunction;
};

export class Controls extends React.Component<Props> {
  handleCopy = (popupState: PopupStateType) => async (e: React.SyntheticEvent) => {
    const { handleCopy } = this.props;

    popupState.close();
    await handleCopy(e);
  };

  handleResend = (popupState: PopupStateType) => () => {
    const { handleResend } = this.props;

    handleResend();
    popupState.close();
  };

  handleAttachToProfile = (popupState: PopupStateType) => () => {
    const { handleAttachToProfile } = this.props;

    handleAttachToProfile();
    popupState.close();
  };

  handleDelete = (popupState: PopupStateType) => () => {
    const { handleDelete } = this.props;

    handleDelete();
    popupState.close();
  };

  handleDeactivateRefundProtect = (popupState: PopupStateType) => () => {
    this.props.handleDeactivateRefundProtect();
    popupState.close();
  };

  render() {
    const { isAwaiting, teamStatus, isRefundProtectPaid, isAttachToProfile } = this.props;

    const isTeamAwaiting = teamStatus === AWAITING;

    const fullItemList = isAwaiting || isTeamAwaiting;

    return (
      <PopupState variant='popover' popupId='demo-popup-popover'>
        {(popupState: PopupStateType) => (
          <div className='controls-block'>
            <MoreVertIcon className='primary-icon' {...bindTrigger(popupState)} />
            <Menu
              {...bindMenu(popupState)}
              className='controls-menu'
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {fullItemList && (
                <MenuItem className='control-item' onClick={this.handleCopy(popupState)}>
                  <LinkIcon />
                  <span className='text'>{t.staticAsString('startlist.controls.copyLink')}</span>
                </MenuItem>
              )}
              <MenuItem className='control-item' onClick={this.handleResend(popupState)}>
                <Icon value='resend' />
                <span className='text'>
                  {t.staticAsString(`startlist.controls.resend${this.props.isSuccesfulEmail ? '.succesfulEmail' : ''}`)}
                </span>
              </MenuItem>
              {isAttachToProfile && (
                <MenuItem className='control-item' onClick={this.handleAttachToProfile(popupState)}>
                  <PersonAddIcon />
                  <span className='text'>{t.staticAsString('startlist.controls.attachToProfile')}</span>
                </MenuItem>
              )}
              {isRefundProtectPaid &&
                false && ( // TODO add functionality to remove RP later
                  <MenuItem className='control-item' onClick={this.handleDeactivateRefundProtect(popupState)}>
                    <DeactivateIcon />
                    <span className='text'>{t.staticAsString('startlist.controls.deactivateRefund')}</span>
                  </MenuItem>
                )}
              <MenuItem className='control-item secondary' onClick={this.handleDelete(popupState)}>
                <DeleteIcon />
                <span className='text'>{t.staticAsString('startlist.controls.delete')}</span>
              </MenuItem>
            </Menu>
          </div>
        )}
      </PopupState>
    );
  }
}

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { SYSTEM_USER_URL, SYSTEM_USERS_URL } from 'src/constants';
import { progressStore } from 'src/stores';

import { SystemUsersConstants } from '../constants/systemUsers.constants';

import { SystemUsersFilters } from '../types/filters.type';
import { SystemUsersResponse } from '../types/systemUsers-response.type';

export namespace SystemUsersService {
  export async function load(filters?: SystemUsersFilters) {
    progressStore.log(SystemUsersConstants.SystemUsersForm, 'progress');
    try {
      const data = await axios.get<SystemUsersResponse>(SYSTEM_USERS_URL, {
        params: {
          ...filters,
          with: 'roles',
          per_page: 50,
        },
      });
      return data.data;
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(SystemUsersConstants.SystemUsersForm, 'completed');
    }
  }

  export async function deleteValue(userId: number) {
    progressStore.log(SystemUsersConstants.SystemUsersForm, 'progress');
    try {
      const url = generatePath(SYSTEM_USER_URL, { id: userId });
      const response = await axios.delete(url);
      return response?.status === 202;
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(SystemUsersConstants.SystemUsersForm, 'completed');
    }
  }
}

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACE_DISTANCES_URL, RACE_DISTANCE_URL } from 'src/constants';

import { request, action } from 'utils';

import { LOAD_DISTANCES, LOAD_DISTANCE, DELETE_DISTANCE } from '../constants';

import { DistanceStore } from '../stores';

class DistancesService {
  store: DistanceStore;

  constructor(store: DistanceStore) {
    this.store = store;
  }

  @request({ action: LOAD_DISTANCES })
  loadResourcesRequest(raceId: number, params: FiltersType): any {
    const path = generatePath(RACE_DISTANCES_URL, { raceId });
    return axios.get(path, {
      params,
    });
  }

  @action({ action: LOAD_DISTANCES })
  async loadResources(raceId: number) {
    const params = this.store.filters;
    const [status, response] = await this.loadResourcesRequest(raceId, params);

    if (status) {
      const values = response.data.data;
      this.store.addValues(values, 1, params);

      const paginationMeta = response.data?.meta?.pagination;

      if (paginationMeta) {
        this.store.addPaginationMeta(paginationMeta);
      }
    }
  }

  @request({ action: LOAD_DISTANCE })
  loadResourceRequest(id: number, params: FiltersType): any {
    const path = generatePath(RACE_DISTANCE_URL, { id });
    return axios.get(path, {
      params,
    });
  }

  @action({ action: LOAD_DISTANCE })
  async loadResource(id: number) {
    const params = this.store.resourceParams;
    const [status, response] = await this.loadResourceRequest(id, params);

    if (status) {
      await this.store.addSelectedValue(response.data.data);
    }
  }

  @request({ action: DELETE_DISTANCE })
  async deleteDistanceRequest(id: number): Promise<any> {
    const url = generatePath(RACE_DISTANCE_URL, { id });
    return axios.delete(url);
  }

  @action({ action: DELETE_DISTANCE })
  async deleteDistance(distanceId: number): Promise<any> {
    const [status] = await this.deleteDistanceRequest(distanceId);
    return status;
  }
}

export { DistancesService };

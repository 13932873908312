import { createContext } from 'react';

import { mount, unmount, exportData } from '../actions';

import { exportService, racersService } from '../services';

import { racersStore } from '../stores';

export type RacersContextType = {
  results: {
    actions: {
      mount: typeof mount;
      unmount: typeof unmount;
      exportData: Function;
    };
    services: {
      exportService: typeof exportService;
      racersService: typeof racersService;
    };
    stores: {
      racersStore: typeof racersStore;
    };
  };
};

const racersContextData: RacersContextType = {
  results: {
    actions: {
      mount,
      unmount,
      exportData,
    },
    services: {
      exportService,
      racersService,
    },
    stores: {
      racersStore,
    },
  },
};
const RacersContext = createContext<RacersContextType>(racersContextData);

export { RacersContext, racersContextData };

import { Collapse } from '@mui/material';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { ActionButton, Participants } from 'modules/RaceDetails/components';
import { handleDeleteImageExternal } from 'modules/Races/actions';
import editRaceService from 'modules/Races/services/editRace';
import * as React from 'react';
import { useEffect } from 'react';
import { generatePath } from 'react-router-dom';

import { CONFIRM_POPUP_TYPES, DEFAULT_RACE_IMAGE, RACE_IMAGE_ASPECT_RATIO, ROUTES } from 'src/constants';

import { Show } from 'components/Condition';
import { Form, ImageFieldWithCrop, InputWrapper } from 'components/Form';
import { Icon } from 'components/Icon';

import { history, t } from 'utils';

import { Race } from 'models';

import { confirmationModalStore, form } from 'stores';

import { RejectDataType } from '../../types';
import { RaceStatusTools } from './RaceStatusTools';
import { RejectionDialog } from './RejectionDialog';
import { Settings } from './SettingsMenu';

type Props = {
  race: nil | Race;
  rejectRace: Function;
  approveRace: React.MouseEventHandler;
  onDelete: React.MouseEventHandler;
  onHideStartList: React.MouseEventHandler;
  onToggleResults: React.MouseEventHandler;
  rejectData: RejectDataType;
  onDuplicate(): void;
  onPreviewLink(): void;
  isWebHostReady: boolean;
};

export const Toolbar = ({
  race,
  rejectRace,
  approveRace,
  rejectData,
  onDelete,
  onDuplicate,
  onToggleResults,
  onHideStartList,
  onPreviewLink,
  isWebHostReady,
}: Props) => {
  if (!race) {
    return null;
  }

  const [open, setOpen] = React.useState(false);
  const [tooltip, setTooltip] = React.useState(false);

  const imageDefault = race.value.image || DEFAULT_RACE_IMAGE;
  const closeDialog = () => setOpen(false);
  const openDialog = () => setOpen(true);

  const handleConfirmDeleteImage = () => {
    confirmationModalStore.openModal({
      title: t.staticAsString('races.confirmPopup.mainTitle'),
      body: t.staticAsString('races.confirmPopup.imageBody'),
      type: CONFIRM_POPUP_TYPES.confirm,
      onConfirm: () => handleDeleteImageExternal(race.value.id, 'race-details-image'),
    });
  };

  useEffect(() => {
    form.merge('race-details-image', { image: race.value.image });
  }, []);

  const openTooltip = () => {
    setTooltip(true);
    openDialog();
  };
  const openRejectionForm = () => {
    setTooltip(false);
    openDialog();
  };

  const getFormattedDate = (momentFormat: string) => {
    const raceDate = race.momentRaceDate();
    const raceEndDate = race.momentRaceEndDate();

    const oneDayRace = race.isOneDayRace();
    // model return or moment instance either undefined
    const startDateFormatted = `${raceDate?.format(momentFormat) || ''}`;
    const endDateFormatted = `${raceEndDate?.format(momentFormat) || ''}`;

    const start = raceDate ? `${startDateFormatted}` : '';
    const end = !oneDayRace ? ` - ${endDateFormatted}` : '';

    return `${start}${end}`;
  };

  const isPublished = !!race.value.published;

  const onSubmit = (reason: any) => {
    rejectRace(reason);
    closeDialog();
  };

  const onEdit = () => {
    if (!race) return;
    const id = race.value?.id;

    const raceEditUrl = generatePath(ROUTES.raceEditRoute, { id });
    history.push(raceEditUrl);
  };

  const liveUpdate = () => {
    const data = form.fetch('race-details-image') as any;
    editRaceService.uploadRaceImage({ raceId: race.value.id, value: data.image as Blob });
  };

  const body = t.staticAsString('races.detail.rejectReason.info', rejectData) as any;

  return (
    <React.Fragment>
      <RejectionDialog
        open={open}
        body={body}
        isTooltip={tooltip}
        onSubmit={onSubmit}
        closeDialog={closeDialog}
        reason={rejectData.rejectReason}
      />
      <div className='toolbar'>
        <div className='main-info'>
          <div className='info'>
            <p className='title'>{race.value.name}</p>
            <div className='toolbox'>
              <Collapse
                orientation='horizontal'
                in={isPublished && isWebHostReady}
                sx={{
                  '& .MuiCollapse-wrapperInner': {
                    display: 'flex',
                  },
                }}
              >
                <ActionButton
                  className='action-button'
                  onClick={onPreviewLink}
                  variant='text'
                  endIcon={
                    <Icon
                      className={classNames('icon', {
                        publish: true,
                        active: true,
                      })}
                      value='preview'
                    />
                  }
                >
                  {t.staticAsString('races.detail.preview')}
                </ActionButton>
              </Collapse>

              <Button
                className='action-button'
                onClick={onEdit}
                variant='contained'
                endIcon={<Icon value='edit-outlined' className='icon' />}
              >
                {t.staticAsString('races.action.edit')}
              </Button>
              <Settings
                race={race}
                onDelete={onDelete}
                onDuplicate={onDuplicate}
                onToggleResults={onToggleResults}
                onHideStartList={onHideStartList}
              />
            </div>
          </div>
          <div className='location-info'>
            <div className='item'>
              {' '}
              <Icon className='location-icon' value='calendar-alt' />
              <span className='date'>{getFormattedDate('DD MMM YYYY')}</span>
            </div>

            <Show if={race.isAnyLocation()}>
              <div className='item'>
                <Icon className='location-icon' value='location-outline' />
                {race.fullLocation()}
              </div>
            </Show>
            <div className='item'>
              <Participants />
            </div>
          </div>
          <RaceStatusTools
            onReject={openRejectionForm}
            onApprove={approveRace}
            onReasonTooltip={openTooltip}
            approveStatus={race.approveStatus()}
          />
        </div>
        <div className='race-image'>
          {/* <AspectRatioImage ratio={RACE_IMAGE_ASPECT_RATIO.ratio} src={imageDefault} /> */}
          <Form id='race-details-image' className='distance-form'>
            <InputWrapper
              settings={{
                additional: {
                  liveUpdate,
                  handleDeleteImage: handleConfirmDeleteImage,
                },
                view: { fullWidth: true, variant: 'outlined' },
                label: 'Image',
              }}
              Component={ImageFieldWithCrop}
              name='image'
            />
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

import { systemUsersService } from 'modules/SystemUsers/services';

import { helperRacesStore, sessionStore } from 'stores';

import { medicalAssistants } from '../stores';

export const loadMedical = async (forcedOrganizerId?: number | nil) => {
  const params = medicalAssistants.resourceParams;

  const loggedOrganizerId = helperRacesStore.selected || {};
  let search = params.search;

  if (sessionStore.isAdmin && loggedOrganizerId) {
    search = `${params.search};created_by:${loggedOrganizerId}`;
  }

  if (forcedOrganizerId) {
    search = `${params.search};created_by:${forcedOrganizerId}`;
  }

  const [status, { data }] = await systemUsersService.loadSystemUsers({
    ...params,
    search,
  });

  if (!status) return;

  medicalAssistants.addValues(data);
};

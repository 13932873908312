export const format = 'yyyy-mm-dd';
export const beFormat = 'date';
export const placeholder = 'YYYY-MM-DD';
export const ddmmyyyyPlaceholder = 'DD-MM-YYYY';
export const mask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/, // year
  '-',
  /\d/,
  /\d/, // month
  '-',
  /\d/,
  /\d/, // day
];

export const ddmmyyyyMask = [
  /\d/,
  /\d/, // day
  '-',
  /\d/,
  /\d/, // month
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/, // year
];
export const placeholderChar = '_';

import * as React from 'react';
import { FC, useEffect, useRef } from 'react';

import useIntersectionObserver from 'utils/hooks/useIntersectionObserver';

import { errorsStore, squadsStore } from 'stores';

import { Input } from '../../../Fields/Input';

type Props = {
  field: string;
  item: RacerType;
  isEdit: string | null;
  distance: DistanceType;
  saveSquad: (...args: any[]) => void;
  onChange: (name: string, value: any, changedValue: any) => void;
  isLastItem?: boolean;
  onVisible?: Function;
};

export const SquadField: FC<Props> = (props) => {
  const { isLastItem = false, onVisible } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible) {
      onVisible && onVisible();
    }
  }, [isVisible]);

  const onChange = (field: string, value: any) => {
    const { onChange } = props;
    onChange(field, value, value);
  };

  const { distance, field, isEdit, item, saveSquad } = props;
  const value = (item as AnyObject) || '';

  return (
    <div {...(isLastItem ? { ref } : {})}>
      {isEdit === field ? (
        <Input
          onChange={onChange}
          item={item}
          field={field}
          value={value.label}
          saveRacer={saveSquad}
          distance={distance}
          errorsStore={errorsStore}
          racersField={{}}
        />
      ) : (
        squadsStore.formatValue(value.value)
      )}
    </div>
  );
};

import * as React from 'react';

import { t } from 'utils';

const generateOptions = (): SelectOption[] => [
  { label: t.staticAsString('shared.genders.male'), value: 1, key: 1 },
  { label: t.staticAsString('shared.genders.female'), value: 2, key: 2 },
  { label: t.staticAsString('shared.genders.other'), value: 3, key: 3 },
];

const findOption = (value: genderValue): nil | SelectOption => {
  const options = generateOptions();
  return options.find((option) => option.value === value);
};

const formatValue = (value: genderValue): string | React.ReactNode => {
  switch (value) {
    case 1:
      return t.staticAsString('shared.genders.male');
    case 2:
      return t.staticAsString('shared.genders.female');
    case 3:
      return t.staticAsString('shared.genders.other');
    default:
      return '';
  }
};

const gender = {
  options: generateOptions,
  format: formatValue,
  find: findOption,
};

export { gender };

import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

export const latestValidPath = (): Object => {
  if (!history.location) {
    return ROUTES.rootRoute;
  }

  const isNotStubUrl = !matchPath(history.location.pathname, ROUTES.serviceUnavailable);

  if (isNotStubUrl) {
    return history.location;
  }

  return ROUTES.rootRoute;
};

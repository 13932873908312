import Dialog from '@mui/material/Dialog';
import withStyles from '@mui/styles/withStyles';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { closeModal } from 'src/modules/Distances/components/shared/Steps/GPX/actions';
import { openCheckPointsModalStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';

import { BodyModal, ModalTop } from '.';

const DialogStyled = withStyles({
  root: {
    '& .MuiPaper-root': {
      borderRadius: '0',
    },
  },
  paper: {
    width: 1280,
    height: 750,
  },
})(Dialog);

export const ManageCheckPointsModal = () => {
  return (
    <Observer>
      {() => {
        const open: boolean = Boolean(openCheckPointsModalStore.value);

        return (
          <DialogStyled open={open} onClose={closeModal} maxWidth='xl'>
            <ModalTop />
            <BodyModal />
          </DialogStyled>
        );
      }}
    </Observer>
  );
};

import { MoreVert } from '@mui/icons-material';
import { Box, Fade, IconButton, Menu, MenuItem } from '@mui/material';
import { deleteSynchronization, reSynchronization, toggleSynchronizationStatus } from 'modules/Synchronisation/Table/actions/list/controls';
import { SynchronizationsConstants } from 'modules/Synchronisation/Table/constants/synchronizations.constants';
import { sortMapToBackend, SynchronisationFiltersMappers } from 'modules/Synchronisation/Table/mappers/filters.mapper';
import { Synchronization, SynchronizationTypeEnum } from 'modules/Synchronisation/Table/types/synchronization.type';
import React, { useState } from 'react';
import { ColumnWithLooseAccessor } from 'react-table';

import { t, time } from 'src/utils';

import { confirmationModalStore } from 'stores';

import { DateFilter, NumberRangeFilter, SelectColumnFilter } from '../Filters';

export const COLUMNS = ['id', 'race_id', 'race_name', 'type', 'status', 'created_at', 'created_by', 'action'] as const;

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<Synchronization> & AnyObject } = {
  id: {
    id: 'id',
    width: '1%',
    minWidth: 80,
    Header: t.static('sync.table.id'),
    accessor: (synchronization) => synchronization.id,
    filter: 'between',
    Filter: NumberRangeFilter,
  },

  race_id: {
    id: 'race_id',
    width: '1%',
    minWidth: 80,
    Header: t.static('payments.list.table.race_id'),
    accessor: (synchronization) => synchronization.race_id,
    filter: 'between',
    Filter: NumberRangeFilter,
  },
  race_name: {
    id: 'race.translations.name',
    sortable: false,
    disableSortBy: true,
    width: '1%',
    minWidth: 186,
    Header: t.static('payments.list.table.race_title'),
    accessor: (synchronization) => synchronization.race.name,
  },
  type: {
    id: 'type',
    width: '1%',
    minWidth: 133,
    Header: t.static('sync.table.type'),
    accessor: (synchronization) => SynchronizationsConstants.SynchronizationType[synchronization.type],
    Filter: SelectColumnFilter,
    filter: 'equals',
    selectProps: {
      options: () => [
        {
          key: SynchronizationTypeEnum.RaceResult,
          value: SynchronizationTypeEnum.RaceResult,
          label: SynchronizationsConstants.SynchronizationType[SynchronizationTypeEnum.RaceResult],
        },
        {
          key: SynchronizationTypeEnum.EQTiming,
          value: SynchronizationTypeEnum.EQTiming,
          label: SynchronizationsConstants.SynchronizationType[SynchronizationTypeEnum.EQTiming],
        },
      ],
    },
  },

  status: {
    id: 'is_enabled',
    width: '1%',
    minWidth: 80,
    Header: t.static('payments.list.table.status'),
    accessor: (synchronization) => synchronization.is_enabled,
    Filter: SelectColumnFilter,
    filter: 'equals',
    selectProps: {
      options: () => [
        {
          key: 0,
          value: 0,
          label: t.staticAsString('categories.inactive'),
        },
        {
          key: 1,
          value: 1,
          label: t.staticAsString('categories.active'),
        },
      ],
    },
    Cell: ({ row: { original } }) => {
      let text = '';
      let color = '';
      let bg = '';
      switch (original.is_enabled) {
        case true: {
          text = t.staticAsString('categories.active');
          color = '#66BB6A';
          bg = 'rgba(102, 187, 106, 0.12)';
          break;
        }
        case false: {
          text = t.staticAsString('categories.inactive');
          color = '#FF8A65';
          bg = 'rgba(255, 138, 101, 0.12)';
          break;
        }
      }

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            fontSize: 12,
            textTransform: 'uppercase',
            width: 77,
            padding: '9px 18px',
            color,
            backgroundColor: bg,
          }}
        >
          {text}
        </Box>
      );
    },
  },

  created_at: {
    id: 'created_at',
    width: '1%',
    minWidth: 120,
    Header: t.static('sync.table.created_at'),
    accessor: (synchronization) => time.datetime(synchronization.created_at).parse().format('shortDateWithTime'),
    Filter: DateFilter,
    sortDescFirst: true,
  },

  created_by: {
    id: 'createdBy.full_name',
    width: '1%',
    minWidth: 120,
    Header: t.static('systemUsers.form.created_by'),
    accessor: (synchronization) => synchronization.createdBy.full_name,
  },

  action: {
    id: 'action',
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    width: '0.0000000001%',
    minWidth: 35,
    Header: t.static('systemUsers.list.table.action'),
    Cell: (props) => {
      const {
        row: { original },
        state: { pageIndex, pageSize, filters, sortBy },
      } = props;

      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
      const open = Boolean(anchorEl);

      const { orderBy, sortedBy } = sortMapToBackend(sortBy);
      const requestFilters = {
        page: pageIndex + 1,
        limit: pageSize,
        orderBy,
        sortedBy,
        ...SynchronisationFiltersMappers.toBackend(filters),
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

      const handleToggleStatus = (id: number, isEnabled: boolean) => {
        confirmationModalStore.openModal({
          title: t.staticAsString(isEnabled ? 'sync.confirmPopup.title.deactivate' : 'sync.confirmPopup.title.activate'),
          body: t.staticAsString(isEnabled ? 'sync.confirmPopup.body.deactivate' : 'sync.confirmPopup.body.activate'),
          type: 'confirm',
          onConfirm: () => toggleSynchronizationStatus(id, isEnabled, requestFilters),
        });
        setAnchorEl(null);
      };

      const handleReSync = (id: number) => {
        confirmationModalStore.openModal({
          title: t.staticAsString('sync.action.resync.title'),
          body: t.staticAsString('sync.confirmPopup.body.reSync'),
          type: 'confirm',
          onConfirm: () => reSynchronization(id, requestFilters),
        });
        setAnchorEl(null);
      };

      const handleDelete = (id: number) => {
        confirmationModalStore.openModal({
          title: t.staticAsString('systemUsers.confirmPopup.mainTitle'),
          body: t.staticAsString('sync.action.delete.text'),
          type: 'delete',
          onConfirm: () => deleteSynchronization(id, requestFilters),
        });
        setAnchorEl(null);
      };

      const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
      return (
        <>
          <IconButton onClick={handleClick} style={{ display: 'flex', alignItems: 'center' }}>
            <MoreVert sx={{ cursor: 'pointer' }} />
          </IconButton>
          <Menu
            id='fade-menu'
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            sx={{
              '& .MuiMenu-list': {
                width: 200,
              },
            }}
          >
            <MenuItem onClick={() => handleToggleStatus(original.id, original.is_enabled)}>
              {t.staticAsString(original.is_enabled ? 'sync.action.deactivate.title' : 'sync.action.activate.title')}
            </MenuItem>
            <MenuItem onClick={() => handleReSync(original.id)}>{t.staticAsString('sync.action.resync.title')}</MenuItem>
            <MenuItem sx={{ color: '#CE5659' }} onClick={() => handleDelete(original.id)}>
              {t.staticAsString('shared.delete')}
            </MenuItem>
          </Menu>
        </>
      );
    },
  },
};

export function generateSynchronizationColumns() {
  return COLUMNS.map((c) => RenderColumns[c]);
}

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACE_DISTANCES_URL } from 'src/constants';

import { action, request } from 'utils';

import { GET_RACE_DISTANCES } from '../constants';

import { mapBibDistances } from '../utils';

import { distancesStore } from '../stores';

class BibDistancesService {
  @request({ action: GET_RACE_DISTANCES })
  private async raceDistancesRequest(raceId: number, params: AnyObject): Promise<any> {
    const url = generatePath(RACE_DISTANCES_URL, { raceId });

    return axios.get(url, { params });
  }

  @action({ action: GET_RACE_DISTANCES })
  async getDistances(raceId: number): Promise<any> {
    const params = distancesStore.params;
    const [status, response] = await this.raceDistancesRequest(raceId, params);

    if (status) {
      distancesStore.appendDistances(mapBibDistances(response.data.data));
      distancesStore.setHasMore(response.data.meta.pagination.total_pages > distancesStore.params.page);
      distancesStore.setParams({ page: distancesStore.params.page + 1 });
    }
  }
}

export const bibDistancesService = new BibDistancesService();

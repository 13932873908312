import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';

import { SYSTEM_USER_URL } from 'src/constants';
import { progressStore } from 'src/stores';

import { SystemUsersConstants } from '../constants/loadable.constants';

export namespace SystemUserViewService {
  export async function load<T, R = AxiosResponse<T>>(id: number): Promise<R> {
    progressStore.log(SystemUsersConstants.SystemUserProfileView, 'progress');
    try {
      const url = generatePath(SYSTEM_USER_URL, { id });
      const response = await axios.get<R>(url, {
        params: {
          with: 'roles;activeCommissions',
          // include: 'payment_methods',
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(SystemUsersConstants.SystemUserProfileView, 'completed');
    }
  }
}

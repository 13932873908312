import React, { useCallback } from 'react';

import { CommissionsActions } from '../actions/lifecycle';

import { CommissionFiltersMappers } from '../mappers/filters.mapper';

export default function useFetchCommisions() {
  const fetchCommissions = useCallback(({ pageIndex = 0, pageSize, filters, sortBy }) => {
    let orderBy = 'created_at';
    let sortedBy = 'DESC';
    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      orderBy = id.includes('.') ? id.split('.').join('|') : id;
      sortedBy = desc ? 'DESC' : 'ASC';
    }
    CommissionsActions.load({ page: pageIndex + 1, limit: pageSize, orderBy, sortedBy, ...CommissionFiltersMappers.toBackend(filters) });
  }, []);
  return fetchCommissions;
}

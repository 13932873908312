import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SyncIcon from '@mui/icons-material/Sync';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';
import classnames from 'classnames';
import { Observer } from 'mobx-react';
import React, { ReactElement } from 'react';

import { RACE } from 'src/constants/actions';

import { t } from 'utils';

import { progressStore } from 'stores';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
}));
type Props = {
  action?: string;
  hideBeforeFirstActionLoad?: boolean;
  className?: string;
};

export default function SavingIndicator({ action = `UPDATE_${RACE}`, hideBeforeFirstActionLoad = false, className }: Props): ReactElement {
  const classes = useStyles();
  return (
    <div className={classnames(classes.container, className)}>
      <Observer
        render={() => {
          if (hideBeforeFirstActionLoad && !progressStore.isActionWasLogged(action)) {
            return <></>;
          }

          if (progressStore.isLoading(action)) {
            return (
              <>
                <SyncIcon className={cn('rotate-360', classes.icon)} color='disabled' />{' '}
                <Typography color='textSecondary' variant='body2'>
                  {t('shared.liveUpdate.saving')}
                </Typography>
              </>
            );
          }
          return (
            <>
              <CheckCircleOutlineIcon className={classes.icon} color='disabled' />{' '}
              <Typography color='textSecondary' variant='body2'>
                {t('shared.liveUpdate.saved')}&nbsp;&nbsp;&nbsp;&nbsp;
              </Typography>
            </>
          );
        }}
      />
    </div>
  );
}

import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Switch } from 'components/Fields';

import { t } from 'utils';

import { TermsSwitcherProps } from '../../types';

const TermsSwitcher: FC<TermsSwitcherProps> = (props) => {
  const { inputProps, nameLink, path } = props;

  return (
    <div>
      <div>
        <Switch {...inputProps} />
      </div>
      <div className='registration-terms-content'>
        <Link to={path} target={'_blank'}>
          <span className='registration-terms--text'>{t.staticAsString('registration.agreements.text')}</span>&nbsp;
          <span className='registration-terms--link'>{nameLink}</span>
        </Link>
      </div>
    </div>
  );
};

export { TermsSwitcher };

import { TableCell } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';
import shortid from 'shortid';

import { htmlSanitizer } from 'utils';

import { TASKS_COLUMNS } from '../../../constants';

import { TaskTable as TaskModel } from '../../../models';

import { RowType } from '../';
import { TasksContext } from '../../../context';
import { FIELD_SHOW } from './show';

const { MAIN_COLUMNS } = TASKS_COLUMNS;

function TasksCellsComponent({ value }: RowType) {
  return (
    <TasksContext.Consumer>
      {() => {
        return MAIN_COLUMNS.map((column) => {
          const generateShowFun = FIELD_SHOW[column as string];

          const show = generateShowFun && generateShowFun(new TaskModel(value));
          const renderedValue = show();

          const rowData = () => {
            return { __html: htmlSanitizer(renderedValue) || '' };
          };

          return (
            <TableCell key={shortid()} className={classNames('editable-cell cell')}>
              <div dangerouslySetInnerHTML={rowData()} />
            </TableCell>
          );
        });
      }}
    </TasksContext.Consumer>
  );
}

const TasksCells = React.memo<RowType>(TasksCellsComponent);

// @ts-ignore
export { TasksCells };

import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '8rem',
      overflow: 'scroll',
      height: '100%',
    },
    title: {
      color: 'rgb(38, 46, 53)',
      fontSize: 14,
      fontWeight: 500,
      textAlign: 'center',
      textTransform: 'uppercase',
      letterSpacing: '0.25px',
      marginBottom: 16,
    },
    subtitle: {
      maxWidth: 412,
      margin: '0 auto',
      paddingBottom: 57,
      color: 'rgb(38, 46, 53)',
      fontSize: 14,
      fontWeight: 'normal',
      textAlign: 'center',
      letterSpacing: '0px',
      lineHeight: '24px',
    },
    sectionTitle: {
      color: 'rgb(38, 46, 53)',
      fontSize: 15,
      fontWeight: 500,
      letterSpacing: '0px',
      marginBottom: 18,
    },
    selectInputWrapper: {
      marginBottom: 22,
      maxWidth: 441,
      width: '100%',
    },
    selectInput: {
      width: '100%',
      borderRadius: 4,
    },
    languagesListWrapper: {
      border: '1px solid rgba(38, 46, 53, 0.12)',
      marginBottom: 24,
    },
    languagesList: {
      maxWidth: 441,
      width: '100%',
    },
    listItem: {
      maxHeight: 67,
      borderBottom: '1px solid rgba(38, 46, 53, 0.12)',
      '&:last-child': {
        borderBottom: 'none',
      },
    },
    languageTitleContainer: {
      height: 67,
      display: 'flex',
      alignItems: 'center',
    },
    languageTitle: {
      marginLeft: 10,
      width: '100%',
      display: 'flex',
    },
  }),
);

import { observer, inject } from 'mobx-react';
import * as React from 'react';

import { FilterAutocompleteWithSearch } from 'components/Filter/DefaultFilters';

import { t } from 'utils';

import { SportTypeAuxiliary } from 'stores';

import { FilterProps } from './index';

type Props = {
  value: nil | string | number;
  sportTypesAuxiliaryStore: SportTypeAuxiliary;
} & FilterProps;

@inject('sportTypesAuxiliaryStore')
@observer
class SportType extends React.Component<Props> {
  options = () => {
    return this.props.sportTypesAuxiliaryStore.modelValues.map((sportType) => {
      return {
        label: sportType.title() || '',
        value: `${sportType.value.id}`,
        key: `sport_type_${sportType.value.id}`,
      };
    });
  };

  render() {
    const { props } = this;
    return <FilterAutocompleteWithSearch {...(props as any)} label={t.staticAsString('races.filters.sport_id')} options={this.options()} />;
  }
}

export { SportType };

import { teamFields } from '../../../../Filters';

const MAIN_COLUMNS = [
  teamFields['bib_number'],
  teamFields['name'],
  teamFields['class_id'],
  teamFields['wave_id'],
  teamFields['added_at'],
  teamFields['added_by'],
];

const PAYMENT_COLUMNS = [
  teamFields['owner.order.coupon_codes'],
  teamFields['owner.order.id'],
  teamFields['owner.order.created_at'],
  teamFields['owner.order.state'],
  teamFields['owner.order.amount'],
];

const COLUMN_WIDTH: {
  [K in string]: number;
} = {
  action: 50,
  [teamFields['bib_number']]: 140,
};

export { MAIN_COLUMNS, PAYMENT_COLUMNS, COLUMN_WIDTH };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { ORDER_PAYMENT, PAYMENT_TRANSACTIONS, PAYMENT_URL } from 'src/constants';

import { action, request } from 'utils';

import { LOAD_PAYMENT_DETAILS, LOAD_TRANSACTIONS } from '../constants';

import { detailsStore } from '../stores';

export class DetailsService {
  @request({ action: LOAD_PAYMENT_DETAILS })
  async loadDetailsRequest(id: number): Promise<any> {
    const url = generatePath(PAYMENT_URL, { id });
    return axios.get(url);
  }

  @action({ action: LOAD_PAYMENT_DETAILS })
  async loadDetails(id: number): Promise<void> {
    const [status, response] = await this.loadDetailsRequest(id);

    if (status) {
      detailsStore.setOrderDetails(response.data.data);
    }
  }

  @request({ action: LOAD_TRANSACTIONS })
  async loadTransactionsRequest(id: number): Promise<any> {
    const url = generatePath(PAYMENT_TRANSACTIONS, { id });
    return axios.get(url);
  }

  @action({ action: LOAD_TRANSACTIONS })
  async loadTransactions(id: number): Promise<void> {
    const [status, response] = await this.loadTransactionsRequest(id);

    if (status) {
      detailsStore.setTransactions(response.data.data);
    }
  }

  @request({ action: LOAD_TRANSACTIONS })
  async loadOrderPaymentRequest(id: number): Promise<any> {
    const url = generatePath(ORDER_PAYMENT, { id });
    return axios.get(url);
  }

  @action({ action: LOAD_TRANSACTIONS })
  async loadOrderPayment(id: number): Promise<void> {
    const [status, response] = await this.loadOrderPaymentRequest(id);

    if (status) {
      detailsStore.setPayment(response.data.data);
    }
  }
}

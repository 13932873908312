import MaterialTooltip from '@mui/material/Tooltip';
import * as React from 'react';

import { Icon } from 'components/Icon';

import { centeredIconStyles, toolTipStyles } from '../styles';

type Props = {
  message: string;
};

export const ManageDistanceTooltip = ({ message }: Props) => {
  const classes = centeredIconStyles();
  const toolTipClasses = toolTipStyles();

  return (
    <MaterialTooltip title={message} classes={toolTipClasses} placement='right'>
      <div>
        <Icon className={classes.centered} value='info' />
      </div>
    </MaterialTooltip>
  );
};

import { makeStyles } from '@mui/styles';

export const toolTipStyles = makeStyles({
  root: {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    lineHeight: '17px',
    fontWeight: 400,
  },

  link: {
    '&:hover': {
      cursor: 'pointer',
    },
  },

  separator: {
    width: '1px',
    height: '17px',
    backgroundColor: 'white',
    opacity: 0.64,
  },

  icon: {
    fontSize: '18px',

    '&:hover': {
      cursor: 'pointer',
    },
  },
});

import { BooleanStore } from 'stores';

import { Distance } from './distances';
import { FieldRegister } from './fieldRegister';
import { ImportErrors } from './importErrors';
import { RacersStore, TeamsStore, ResultableWrapper } from './newResult';
import { Race } from './races';
import { ResultsStub } from './resultsStub';
import { SingleResults } from './singleResults';
import { TeamResults } from './teamResults';

const distanceStore = new Distance();
const racesStore = new Race();
const singleResultsStore = new SingleResults();
const teamResultsStore = new TeamResults();
const resultsStubStore = new ResultsStub();
const fieldRegisterStore = new FieldRegister();
const importErrors = new ImportErrors();
const racersStore = new RacersStore();
const teamsStore = new TeamsStore();
const resultableWrapperStore = new ResultableWrapper(racersStore, teamsStore);
const newResultModal = new BooleanStore();

export {
  distanceStore,
  racesStore,
  singleResultsStore,
  teamResultsStore,
  resultsStubStore,
  fieldRegisterStore,
  importErrors,
  racersStore,
  teamsStore,
  resultableWrapperStore,
  newResultModal,
};

export * from './singleResults';
export * from './teamResults';
export * from './resultsStub';
export * from './races';
export * from './newResult';
export * from './distances';
export * from './fieldRegister';
export * from './storeOptions';
export * from './importErrors';

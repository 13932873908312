import AddIcon from '@mui/icons-material/Add';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import IconInfoOutlined from '@mui/icons-material/InfoOutlined';
import { InputAdornment, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import * as React from 'react';

import { t } from 'utils';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    <InputAdornment sx={{ cursor: 'pointer' }} position='end'>
      <IconInfoOutlined color='disabled' sx={{ fontSize: 18 }} />
    </InputAdornment>
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 14,
  },
}));

type Props = {
  onAddNewRaceIdRace: (e: React.SyntheticEvent) => void;
  onAddNewCalendarRace: (e: React.SyntheticEvent) => void;
};

export const AddRaceMenu = ({ onAddNewRaceIdRace, onAddNewCalendarRace }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id='add-button'
        onClick={handleClick}
        variant='contained'
        className='add-button'
        aria-controls={open ? 'add-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <AddIcon className='icon' />
        {t.staticAsString('races.list.new_race')}
      </Button>
      <Menu
        id='add-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'add-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onAddNewCalendarRace}>
          <ListItemIcon>
            <CalendarMonthOutlinedIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>{t.staticAsString('races.new.calendarRace')}</ListItemText>
          <StyledTooltip title={t.staticAsString('races.new.calendarRaceInfo')} />
        </MenuItem>
        <MenuItem onClick={onAddNewRaceIdRace}>
          <ListItemIcon>
            <DirectionsRunOutlinedIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>{t.staticAsString('races.new.defaultRace')}</ListItemText>
          <StyledTooltip title={t.staticAsString('races.new.defaultRaceInfo')} />
        </MenuItem>
      </Menu>
    </div>
  );
};

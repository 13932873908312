import { systemUsersService } from 'modules/SystemUsers/services';

import { helperRacesStore } from 'stores';

import { timingAssistants } from '../stores';

export const loadTiming = async () => {
  const params = timingAssistants.resourceParams;
  const { organizer_id } = helperRacesStore.selected || {};

  const [status, { data }] = await systemUsersService.loadSystemUsers({
    ...params,
    search: [params.search, `created_by:${organizer_id}`].join(';'),
  });

  if (!status) return;

  timingAssistants.addValues(data);
};

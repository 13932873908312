import { cloneDeep } from 'lodash';

import { t } from 'utils';

import { ALL_DISTANCES_VALUE } from '../constants';

export const mapCouponRaceDistances = (data: AnyObject[]): AnyObject[] => {
  const copy = cloneDeep(data);

  let newData = copy.map((item) => {
    return {
      key: item.id,
      value: item.id,
      label: `${item.name}(${item.id})`,
    };
  });

  if (newData.length > 0) {
    newData.unshift({
      key: ALL_DISTANCES_VALUE,
      value: ALL_DISTANCES_VALUE,
      label: t.staticAsString('supportTaskManager.allDistances'),
    });
  }

  return newData;
};

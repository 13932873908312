import { t } from 'utils';

const base = {
  email: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    email: true,
  },
  full_name: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 3,
      maximum: 50,
    },
  },
  password: {
    length: {
      minimum: 6,
      maximum: 255,
    },
  },
  confirm_password: {
    equality: 'password',
  },
  phone: {
    phoneNumber: {
      allowEmpty: true,
    },
  },
  role: {
    presence: {
      allowEmpty: false,
    },
  },
  address: {
    length: {
      maximum: 50,
    },
  },
  city: {
    length: {
      maximum: 50,
    },
  },
  post_code: {
    length: {
      maximum: 10,
    },
  },
};

const updateSystemUser = {
  ...base,
};

const createSystemUser = (objForValidate: AnyObject) => {
  let constraints = base;
  let passwordAdditionalConstraints = {};

  if (objForValidate.password) {
    passwordAdditionalConstraints = {
      password: {
        presence: {
          allowEmpty: true,
          message: () => t.staticAsString('shared.errors.presence'),
        },
        length: {
          minimum: 6,
          maximum: 255,
        },
      },
    };
  }

  return {
    ...constraints,
    ...passwordAdditionalConstraints,
  };
};

export { base, createSystemUser, updateSystemUser };

import { Card, LinearProgress, CardHeader, CardContent, CardActions, Button, Typography } from '@mui/material';
import * as React from 'react';

import { Show } from 'components/Condition';

import { t } from 'utils';

import { LoadingFinished, LoadingError, LoadingRetry, LoadingCancelled } from './components';
import { buttonStyles, linearProgressStyles, headerStyles, subheaderStyles, cardStyles, actionsStyles, contentStyles } from './styles';

type Props = {
  progress: number;
  timeLeft: number;
  fileName: string;
  upload: boolean;
  isFinished: boolean;
  isCancelled: boolean;
  isError: boolean;
  noConnection: boolean;
  onRetry: AnyFunction;
  onCancel: AnyFunction;
};

export const ProgressCard = ({
  progress,
  timeLeft,
  onCancel,
  upload,
  isFinished,
  fileName,
  noConnection,
  isCancelled,
  onRetry,
  isError,
}: Props) => {
  const classes = cardStyles();
  const headerClasses = headerStyles();
  const subheaderClasses = subheaderStyles();
  const actionsClasses = actionsStyles();
  const contentClasses = contentStyles();
  const linearProgressClasses = linearProgressStyles();
  const buttonClasses = buttonStyles();

  const progressStr = t.staticAsString('shared.loadPopup.progressLeft', {
    value: 100 - Math.floor(progress),
  });
  const remainingTime = t.staticAsString('shared.loadPopup.remainingTime', {
    time: Math.round(timeLeft),
  });

  return (
    <React.Fragment>
      <Show if={isFinished}>
        <LoadingFinished />
      </Show>
      <Show if={noConnection}>
        <LoadingRetry onRetry={onRetry} />
      </Show>
      <Show if={isCancelled}>
        <LoadingCancelled />
      </Show>
      <Show if={isError}>
        <LoadingError />
      </Show>
      <Show if={!isFinished && !isError && !isCancelled && !noConnection}>
        <Card className={classes.root} raised>
          <CardHeader
            classes={headerClasses}
            title={
              upload
                ? t.staticAsString('shared.loadPopup.upload.title', { name: fileName })
                : t.staticAsString('shared.loadPopup.download.title', { name: fileName })
            }
          />
          <CardContent classes={contentClasses}>
            <Typography gutterBottom variant='h5' component='h2' classes={subheaderClasses}>
              {progressStr} ・ {remainingTime}
            </Typography>
          </CardContent>
          <LinearProgress classes={linearProgressClasses} variant='determinate' value={progress} />
          <CardActions classes={actionsClasses}>
            <Button size='small' color='primary' classes={buttonClasses} onClick={onCancel}>
              {isFinished || isError
                ? t.staticAsString('shared.confirmPopup.btnAction.ok')
                : t.staticAsString('shared.loadPopup.btnAction.cancel')}
            </Button>
          </CardActions>
        </Card>
      </Show>
    </React.Fragment>
  );
};

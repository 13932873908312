import { Observer } from 'mobx-react';
import { couponStore } from 'modules/Coupons/stores';

import { Show } from 'components/Condition';

import { CouponDescription } from './CouponDescription';
import { Header } from './Header';
import { Toolbar } from './Toolbar';
import { Transactions } from './Transactions';

export const CouponDetails = () => {
  return (
    <Observer>
      {() => {
        const couponData = couponStore.value;
        return !couponData ? null : (
          <div className='coupons-details'>
            <div className='top-section'>
              <Toolbar />
              <Header couponData={couponData} />
            </div>
            <Transactions couponData={couponData} />
            <Show if={Boolean(couponData.description)}>
              <CouponDescription couponDescription={couponData.description} />
            </Show>
          </div>
        );
      }}
    </Observer>
  );
};

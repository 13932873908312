export const REGISTRATION = 'REGISTRATION';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const ORGANIZER_EMAIL_VERIFICATION = 'ORGANIZER_EMAIL_VERIFICATION';
export const SPORT_TYPE = 'SPORT_TYPE';
export const SPORT_TYPE_AUXILIARY = 'SPORT_TYPE_AUXILIARY';
export const RACE = 'RACE';
export const HELPER_ORGANIZER = 'HELPER_ORGANIZER';
export const ADMIN = 'admin';
export const ORGANIZER = 'organizer';
export const SYSTEM_USER = 'SYSTEM_USER';
export const IMAGE_UPLOAD = 'IMAGE_UPLOAD';
export const DISTANCE = 'DISTANCE';
export const DISTANCES = 'DISTANCES';
export const HELPER_RACE = 'HELPER_RACE';
export const COUPON = 'COUPON';
export const HELPER_DISTANCES = 'HELPER_DISTANCES';
export const RACER = 'RACER';
export const COUNTRY = 'COUNTRY';
export const TEAM = 'TEAM';
export const HELPER_DISTANCE = 'HELPER_DISTANCE';
export const START_LIST = 'START_LIST';
export const PAYMENT = 'PAYMENT';
export const CUSTOMER = 'CUSTOMER';
export const CURRENCY = 'CURRENCY';
export const INIT_TRANSLATIONS = 'INIT_TRANSLATIONS';
export const SQUAD = 'SQUAD';
export const REFUND = 'REFUND';

export const RESEND_LINK = 'RESEND_LINK';

// The most used cases
export const PERSIST_DISTANCE = `PERSIST_${DISTANCE}`;
export const UPSELL_RACES_LOAD = 'UPSELL_RACES_LOAD';

import { cloneDeep } from 'lodash';
import moment from 'moment';

import { BACKEND_DATE_FORMATE } from 'src/constants';

import { CopyRacersForm } from '../types';

export const mapToFormCopyRacers = (data: AnyObject): AnyObject => {
  const form: CopyRacersForm = cloneDeep(data) as CopyRacersForm;

  return {
    source_distance_id: form.sourceDistance?.value,
    target_distance_id: form.targetDistance?.value,
    start_at: moment(form.dateRange?.startDate).format(BACKEND_DATE_FORMATE),
    end_at: moment(form.dateRange?.endDate).format(BACKEND_DATE_FORMATE),
  };
};

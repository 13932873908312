import { Box } from '@mui/material';
import React from 'react';

import { RACE_STATUSES_INT } from 'src/constants';

import { Show } from 'components/Condition';

import { sessionStore } from 'stores';

import { RaceStatusChip } from './RaceStatusChip';
import { ApproveBtn, RejectBtn, Tooltip } from './buttons';

type Props = {
  onReasonTooltip: React.MouseEventHandler;
  onReject: React.MouseEventHandler;
  onApprove: React.MouseEventHandler;
  approveStatus: number | nil;
};

export const RaceStatusTools = ({ onReject, onReasonTooltip, onApprove, approveStatus }: Props) => {
  const isAwaiting = approveStatus === RACE_STATUSES_INT.awaiting;
  const isRejected = approveStatus === RACE_STATUSES_INT.reject;
  const isApproved = approveStatus === RACE_STATUSES_INT.approve;

  const adminTools = () => {
    return (
      <div className='draft-tools'>
        <Show if={isAwaiting}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <ApproveBtn onClick={onApprove} />
            <RejectBtn onClick={onReject} />
          </Box>
        </Show>

        <Show if={isRejected || isApproved}>
          <RaceStatusChip approveStatus={approveStatus} />
        </Show>

        <Show if={isRejected}>
          <Tooltip onClick={onReasonTooltip} />
        </Show>
      </div>
    );
  };

  const organizerTools = () => {
    return (
      <div className='draft-tools'>
        <RaceStatusChip approveStatus={approveStatus} />

        <Show if={isRejected}>
          <Tooltip onClick={onReasonTooltip} />
        </Show>
      </div>
    );
  };

  return sessionStore.isAdmin ? adminTools() : organizerTools();
};

import { cloneDeep } from 'lodash';

import { TaskForm } from '../types';

export const mapToFormGenerateBib = (data: AnyObject): AnyObject => {
  const form: TaskForm = cloneDeep(data) as TaskForm;
  const classes = getClasses(form);

  const distance_id = form.bibDistances?.value;

  if (classes) {
    return {
      distance_id,
      classes,
    };
  }

  return {
    distance_id,
    bib: form.bibStarting,
  };
};

const getClasses = (form: TaskForm) => {
  if (!form.bibStartingDistance) {
    let data: any = [];

    Object.keys(form.bibDistanceClassStarting).forEach(function (key) {
      data.push({
        class_id: parseInt(key),
        bib: form.bibDistanceClassStarting[key],
      });
    });

    return data;
  }

  return null;
};

import axios from 'axios';
import { get, remove } from 'lodash';
import { isCalendarRaceType } from 'modules/Races/utils/isCalendarRaceType';
import { generatePath } from 'react-router-dom';

import { DISTANCE_MODES, DISTANCE_STEPS, PERSIST_DISTANCE as createAction, RACES_URL, ROUTES } from 'src/constants';

import { action, history, prepareFile, request, t } from 'utils';

import { Race } from 'models';

import { filesService } from 'services';

import { toastStore } from 'stores';

import { DATA_NORMALIZER_FOR_BACKEND } from '../components/shared/Steps';

import { catchErrors } from '../utils/errors';

import { State as UserInputData } from '../shared/stateHelpers';
import { stateRelations } from '../shared/stateHelpers';

type UserInputDataKeys = keyof UserInputData;
class NewDistance {
  @request({ action: createAction })
  createRequest(url: string, data: Object): any {
    return axios.post(url, data);
  }
  // Create logic
  @action({ action: createAction })
  async create(data: Object, url: string): Promise<any> {
    const [isOk, response] = await this.createRequest(url, data);
    if (isOk) {
      const value: DistanceType = response.data as any;

      toastStore.show(t.staticAsString('distances.new.successCreate', { name: value.name, id: value.id }));

      if (isCalendarRaceType.get()) {
        history.push(`${ROUTES.racesRoute}/${value.race_parent_id}`);
      } else {
        history.push(`${generatePath(ROUTES.distanceRoute, { raceId: value.race_parent_id, id: value.id })}?step=confirmationEmail`);
      }
    } else {
      catchErrors(response.data.errors);
    }
  }
  // Logic for user input data management
  async submitForDistanceCreate(data: UserInputData, race: RaceType): Promise<any> {
    const userInput = { ...data };
    const raceModel = new Race(race);
    let dataToSend: AnyObject = { ...userInput.distance };
    const isEmptyCustomFields = !(userInput.custom_fields || []).some((form) => !form._delete);
    const relationKeys = Object.keys(stateRelations);
    const defaultLocale = race!.pref_lang_code;
    relationKeys.forEach((relation) => {
      dataToSend = {
        ...dataToSend,
        ...{
          [stateRelations[relation]]: this.formatData(relation as UserInputDataKeys, data),
        },
      };
    });
    const withoutPricesStep = !dataToSend.editor_settings.tab_options.some((option: string) => option === DISTANCE_STEPS.prices);
    if (!dataToSend.vat_percents || withoutPricesStep) {
      delete dataToSend.vat_percents;
    }
    if (isEmptyCustomFields) {
      remove(dataToSend.editor_settings.tab_options, (option) => option === DISTANCE_STEPS.custom_fields);
    } else {
      dataToSend.custom_fields.forEach((cf: CustomFieldType) => {
        if (cf.helper_text === '') {
          delete cf['helper_text'];
        }
      });
    }

    if (raceModel.value.selected_lang_codes?.length > 1) {
      dataToSend.editor_settings.tab_options.push('translations');
    }

    for (const cf of dataToSend.custom_fields) {
      if (cf.image?.size) {
        const submitData = prepareFile(cf.image);
        const resp = await filesService.upload(submitData);

        if (resp.isOk) {
          cf.image_uuid = resp.url;
          delete cf['image'];
        }
      }
    }

    // Clear email content ----
    if (!get(dataToSend, 'email_content', 'content')) {
      delete dataToSend.email_content.content;
    }

    if (dataToSend.distance_mode !== DISTANCE_MODES.CUMULATIVE) {
      delete dataToSend.goal;
    }
    if (dataToSend.distance_mode === DISTANCE_MODES.CLASSIC) {
      delete dataToSend.ends_at;
    }
    if (dataToSend.distance_mode !== DISTANCE_MODES.CLASSIC) {
      delete dataToSend.medical_assistants;
    }

    if (!dataToSend.location) {
      dataToSend.location = raceModel.location();
    }

    dataToSend[defaultLocale] = {
      ...dataToSend[defaultLocale],
      description: dataToSend.description,
    };

    delete dataToSend.description;

    const raceId = (race && race.id) || '';
    const url = `${RACES_URL}/${raceId}/distances`;

    if (dataToSend.min_members_quantity) dataToSend.min_members_quantity = Number(dataToSend.min_members_quantity);
    if (dataToSend.max_members_quantity) dataToSend.max_members_quantity = Number(dataToSend.max_members_quantity);
    dataToSend.race_qty = Number(dataToSend.race_qty) || null;
    if (isCalendarRaceType.get() && withoutPricesStep) {
      dataToSend.editor_settings.tab_options.push(DISTANCE_STEPS.prices);
    }

    return await this.create(dataToSend, url);
  }

  formatData(relation: UserInputDataKeys, data: UserInputData): any {
    const normalize = DATA_NORMALIZER_FOR_BACKEND[relation] || DATA_NORMALIZER_FOR_BACKEND.default;
    return normalize((data as AnyObject)[relation]);
  }
}
export { NewDistance };
export default new NewDistance();

import {
  REFUND_DISTANCE_JOB,
  REFUND_JOB,
  REFUND_ORDER_JOB,
  REFUND_PAYMENT_JOB,
  REFUND_RACE_JOB,
} from 'modules/SupportTaskManager/Tasks/utils';
import * as React from 'react';

export const CREDIT_DISTANCE = 'creditDistance';
export const CREDIT_SINGLE = 'creditSingle';
export const MERGE = 'merge';
export const COUPON = 'coupon';
export const BIB_NUMBERS = 'bibNumbers';

export const OPTIONS_ACTIONS = [MERGE, CREDIT_SINGLE, CREDIT_DISTANCE, COUPON, BIB_NUMBERS];
export const REFUND_JOBS = [REFUND_PAYMENT_JOB, REFUND_DISTANCE_JOB, REFUND_JOB, REFUND_ORDER_JOB, REFUND_RACE_JOB];

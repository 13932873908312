import { useEffect } from 'react';

import { Spinner } from 'components/Spinner';

import { history } from 'utils/history';

import { sessionStore } from 'stores';

export default function DeleteRedirector() {
  const id = sessionStore.user?.user_id;
  useEffect(() => {
    history.push(`/system-users/${id}/edit`);
  }, []);
  return <Spinner type='cubesSpinner' />;
}

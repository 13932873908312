import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { DISTANCE_RESULTS_EXPORT_URL } from 'src/constants';

import { request, action } from 'utils';

import { EXPORT_RESULTS } from '../constants';

class Export {
  @request({ action: EXPORT_RESULTS })
  exportResultsRequest(distanceId: number, params: Object = {}): any {
    const url = generatePath(DISTANCE_RESULTS_EXPORT_URL, { distanceId });
    return axios.get(url, { params });
  }

  @action({ action: EXPORT_RESULTS })
  async exportResults(distanceId: number, params: FiltersType): Promise<any> {
    const [status, response] = await this.exportResultsRequest(distanceId, params);

    return [status, response];
  }
}

export { Export, Export as ExportService };

import moment from 'moment';
import { helper } from 'racese-react-phone-input-2';
import validate from 'validate.js';

import { commonDateFormats } from 'utils';
import { t, time } from 'utils';

validate.validators.blobSvg = function (value: { type: string; name: string }, option: any, key: any, attributes: any) {
  if (!value) {
    return;
  }

  if (typeof value !== 'object' || !value.type || !value.name) {
    return;
  }

  if (/.*\.svg$/.test(value.name) && /image\/svg(?:\+xml)?$/.test(value.type)) {
    return;
  } else {
    return [t.staticAsString('shared.errorIcon')];
  }
};

validate.validators.blobImage = function (value: { type: string; name: string }, option: any, key: any, attributes: any) {
  if (!value) {
    return;
  }

  if (typeof value !== 'object' || !value.type || !value.name) {
    return;
  }

  if (/.*\.(svg|png|jpg|jpeg|SVG|PNG|JPG|JPEG)$/.test(value.name) && /^image/.test(value.type)) {
    return;
  } else {
    return [t.staticAsString('shared.errorImage')];
  }
};

validate.validators.rangeDateTime = (val: any, options: any, key: string, attributes: Object) => {
  if (!val) {
    return;
  }

  const value = time.workDate(moment(val));
  const from = time.workDate(moment(options.earlierOrEqualTo || NaN));
  const to = time.workDate(moment(options.laterOrEqualTo || NaN));

  const fromStr = from.formatAsString('shortDate');
  const toStr = to.formatAsString('shortDate');

  const fromField = validate.prettify(options.fromField || '');
  const toField = validate.prettify(options.toField || '');
  const name = validate.capitalize(validate.prettify(options.name || key));

  const messages = { fromField, toField, name };

  if (from.val.isValid()) {
    if (value.val.isBefore(from.val, 'day')) {
      return (
        t.staticAsString(options.fromOverlapMessage, { from: fromStr, ...messages }) ||
        t.staticAsString('shared.errors.dateBefore', { from: fromStr, ...messages })
      );
    }
  }

  if (to.val.isValid()) {
    if (value.val.isAfter(to.val, 'day')) {
      return (
        t.staticAsString(options.toOverlapMessage, { to: toStr, ...messages }) ||
        t.staticAsString('shared.errors.dateAfter', { to: toStr, ...messages })
      );
    }
  }
};

validate.validators.phoneNumber = (value: any, option: any, key: string, attributes: Object) => {
  if (!value || !value.phone) {
    if (!option.allowEmpty) {
      return t.staticAsString('validation.required');
    } else {
      return;
    }
  }

  if (!value.countryData?.dialCode) {
    return t.staticAsString('validation.invalidFormat');
  } else {
    const phoneProfixCode = value.phone.replace(/\D/g, '').substring(0, value.countryData.dialCode.length);

    if (phoneProfixCode !== value.countryData.dialCode) {
      return t.staticAsString('validation.invalidFormat');
    }
  }

  if (!helper.isValidLength(value.phone)) {
    return t.staticAsString('validation.invalidFormat');
  }
};

validate.validators.emailArray = (value?: string[]) => {
  const PATTERN =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
  if (Array.isArray(value) && value.length > 0) {
    const isEveryValid = value.every((email) => PATTERN.test(email));
    if (isEveryValid) return;
    return t.staticAsString('validation.emailFormat');
  }
  // return null;
  // Email field is not required, uncomment this to perform validation
  //   else if (Array.isArray(value) && value.length === 0) {
  //     return t.staticAsString('validation.emailRequired');
  //   }
};

validate.extend(validate.validators.datetime, {
  parse: function (
    value: string | number | nil | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | undefined,
    options: any,
  ) {
    return +moment.utc(value as any);
  },

  format: function (
    value: string | number | nil | moment.Moment | Date | (string | number)[] | moment.MomentInputObject | undefined,
    options: { dateOnly: any },
  ) {
    const format = options.dateOnly ? commonDateFormats.shortDate() : commonDateFormats.shortDateWithTime();
    return moment.utc(value as any).format(format);
  },
});

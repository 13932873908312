import { isEmpty } from 'lodash';

import { CONFIRM_POPUP_TYPES, ROUTES } from 'src/constants';
import { confirmationModalStore, errorsStore, form, toastStore, progressStore } from 'src/stores';
import { history, validate, Validator, t } from 'src/utils';

import { RACE_RESULTS_FORM_ID, RR_MAPPING_LOAD, SAVE_SYNC_STEP, SETTINGS_STEP } from '../constants';

import { rrService } from '../services';

import { stepStore } from '../stores';

import { getMappingValidation, mapFormMappings, RACE_RESULTS_TYPE, syncMap, syncStore, syncService } from '../../Shared';
import { SynchronizationsService } from '../../Table/services/synchronizations.service';
import { isValidCF, isValidDistances, settingsStepSchema } from '../validation';

export const handleCancel = () => {
  history.push(ROUTES.sync);
};

export const changeStep = async () => {
  errorsStore.clear(RACE_RESULTS_FORM_ID);
  const data = form.fetch<AnyObject>(RACE_RESULTS_FORM_ID);

  if (!validate(RACE_RESULTS_FORM_ID, data, settingsStepSchema() as AnyObject)) return;

  const isValid = await rrService.createSync(syncMap(data, RACE_RESULTS_TYPE));

  if (isValid) {
    stepStore.set(SAVE_SYNC_STEP);
  }
};

export const backToSettings = () => {
  confirmationModalStore.openModal({
    title: t.staticAsString('shared.exportConfirm.title'),
    body: t.staticAsString('sync.you_will_lose_all_data'),
    type: 'confirm',
    onConfirm: () => {
      stepStore.set(SETTINGS_STEP);
      form.onChange(RACE_RESULTS_FORM_ID, 'distances', []);
      form.onChange(RACE_RESULTS_FORM_ID, 'custom_fields', []);
    },
  });
};

const sendSaveRequest = async (data) => {
  const validator = new Validator(data, getMappingValidation(), '', () => false);
  const errors = validator.errors;

  !!errors && errorsStore.add(RACE_RESULTS_FORM_ID, errors);

  if (errors) {
    progressStore.log(RR_MAPPING_LOAD, 'completed');
    return;
  }

  if (isEmpty(data.custom_fields)) {
    data.custom_fields = undefined;
  }

  const [isOk, response] = await rrService.createMapping(data, Number(syncStore.value?.id));

  if (!isOk) {
    const errorsBE = response.data.errors || {};
    const firstError = ((Object.values(errorsBE) || [])[0] || []) as string[];

    firstError[0] && toastStore.show(firstError[0]);
    progressStore.log(RR_MAPPING_LOAD, 'completed');
    return;
  }

  await syncService.enableSync(Number(syncStore.value?.id));
  await SynchronizationsService.publishSynchronization(Number(syncStore.value?.id));

  history.push(ROUTES.sync);
  progressStore.log(RR_MAPPING_LOAD, 'completed');
};

export const saveSync = async () => {
  progressStore.log(RR_MAPPING_LOAD, 'progress');
  errorsStore.clear(RACE_RESULTS_FORM_ID);

  const formData = form.fetchWithoutObservable<AnyObject>(RACE_RESULTS_FORM_ID);
  const data = mapFormMappings(formData, false);

  if (!isValidDistances(data.distances)) {
    confirmationModalStore.openModal({
      title: t.staticAsString('coupons.warningPopUp.title'),
      body: t.staticAsString('sync.warning_required_fields'),
      type: 'info',
      onConfirm: () => {},
    });
    progressStore.log(RR_MAPPING_LOAD, 'completed');
    return;
  }

  if (!isValidCF(data.custom_fields)) {
    confirmationModalStore.openModal({
      title: t.staticAsString('coupons.warningPopUp.title'),
      body: t.staticAsString('sync.warningCustomFields'),
      type: CONFIRM_POPUP_TYPES.confirm,
      onConfirm: () => {
        sendSaveRequest(data);
      },
    });
  } else {
    sendSaveRequest(data);
  }
  progressStore.log(RR_MAPPING_LOAD, 'completed');
};

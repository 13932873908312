/*

 * New result form, team autocomplete
*/
import { observable, action, computed, makeObservable } from 'mobx';

import { Team } from 'models';

import { teamResultsStore } from '..';

class Teams {
  @observable
  values: TeamType[] = [];

  static defaultFilters = {
    // TODO This should be fixed when we will add select with pagination
    limit: 5000,
    doesntHave: 'result',
    has: 'racers',
    searchJoin: 'AND',
  };

  constructor() {
    makeObservable(this);
  }

  @action
  addValues(values: TeamType[]) {
    this.values = values;
  }

  find(id: number) {
    return this.modelValues.find((el) => el.value.id === id);
  }

  @action
  cleanValues() {
    this.values = [];
  }

  @action
  deleteTeam(id: number) {
    this.values = this.values.filter((team) => team.id !== id);
  }

  @computed
  get modelValues(): Team[] {
    return this.values.map((team) => new Team(team));
  }

  @computed
  get selectOptions(): SelectOption[] {
    const filteredTeamsIds = teamResultsStore.values.map((result) => result?.team?.id);
    return this.modelValues
      .filter((team) => !filteredTeamsIds.includes(team.value.id))
      .map((team) => {
        const compareData = this.formatTeamForSearch(team.value);
        return { ...team.forSelect(this.formatTeam), compareData };
      });
  }

  private formatTeam = (team: TeamType) => {
    if (!team.bib_number) {
      return team.name;
    }

    return `${team.name} (BIB: ${team.bib_number || ''})`;
  };

  private formatTeamForSearch = (team: TeamType) => {
    return `${team.name} ${team.bib_number || ''}`;
  };
}

export { Teams, Teams as TeamsStore };

import { Dialog, DialogContent } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import { isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { LOAD_COUPONS } from 'modules/Coupons/constants';
import { openCouponForm } from 'modules/CouponsForm/actions/control.action';
import * as React from 'react';

import { CONFIRM_POPUP_TYPES } from 'src/constants';

import { loadable, withProgressSpinner } from 'hocs';

import { Show } from 'components/Condition';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { ExportToolbar } from 'components/ExportToolbar';
import { Table } from 'components/Table';
import { TableEmptyListNotification } from 'components/TableEmptyListNotification';

import { t } from 'utils';

import { Coupon as CouponStore, Locale as LocalStore, Session as SessionStore, sessionStore } from 'stores';

import { couponsUnmount, exportData } from '../../actions';

import loadService from '../../services/load';

import { FilterLabelToolbar } from './FilterLabelToolbar';
import { Item } from './Item';
import { CouponForm } from './New';
import { Pagination } from './Pagination';
import { THead } from './THead';
import { Toolbar } from './Toolbar';

type Props = {
  couponsStore: CouponStore;
  localeStore: LocalStore;
  sessionStore: SessionStore;
};

const EXPORT_TYPES = ['csv', 'xlsx'];

type State = {
  isModalOpen: boolean;
  editCouponId: number | null;
  focusedId: number | null;
  onConfirm: Function | null;
  confirmTitle: string;
  confirmBody: string;
  confirmType: string;
};

@withProgressSpinner([LOAD_COUPONS])
@loadable({ service: loadService })
@inject('couponsStore', 'localeStore', 'sessionStore')
@observer
class List extends React.Component<Props, State> {
  static defaultProps = {
    racesStore: null as any,
    localeStore: null as any,
    sessionStore: null as any,
  };

  state = {
    isModalOpen: false,
    editCouponId: null,
    focusedId: null,
    onConfirm: null,
    confirmTitle: '',
    confirmBody: '',
    confirmType: '',
  };

  componentWillUnmount() {
    couponsUnmount();
  }

  showActions = (id: number) => {
    this.setState({ ...this.state, focusedId: id });
  };

  items = (): Array<React.ReactNode> => {
    const { couponsStore } = this.props;
    return (couponsStore.modelValues || []).map((item) => (
      <Item key={item.value.id} item={item} editCoupon={this.editCoupon} deleteCoupon={this.deleteCoupon} />
    ));
  };

  editCoupon = (coupon: CouponType): void => {
    const {
      couponsStore: { page: currentPage },
    } = this.props;

    const isShowConfirm = coupon.qty - coupon.completed_orders_count < 0;
    openCouponForm(coupon.id, isShowConfirm, () => loadService.loadResources({}, currentPage));
  };

  deleteCoupon = (couponId: number): void => {
    const { couponsStore } = this.props;

    const coupon = couponsStore.values.find((item) => item.id === couponId);

    if (!coupon) {
      return;
    }

    this.setState({
      onConfirm: () => {
        loadService.deleteValue(couponId);
        this._clearPopup();
      },
      confirmTitle: t.staticAsString('coupons.confirmPopup.mainTitle') as any,
      confirmBody: t.staticAsString('coupons.list.delete.confirm', { couponName: coupon.name }) as any,
      confirmType: CONFIRM_POPUP_TYPES.confirm,
    });
  };

  createCoupon = (): void => {
    openCouponForm(0, false, () => loadService.loadResources());
  };

  onClose = () => {
    this.setState({
      ...this.state,
      isModalOpen: false,
      editCouponId: null,
    });
  };

  onExport = async (e: React.SyntheticEvent<any>, type: string): Promise<any> => {
    exportData(type);
  };

  _clearPopup = () => {
    if (this.state.onConfirm) {
      this.setState({
        onConfirm: null,
      });
    }
  };

  render() {
    const { couponsStore, sessionStore } = this.props;
    const { isModalOpen, editCouponId, onConfirm, confirmBody, confirmTitle, confirmType } = this.state;

    const couponsList = couponsStore.modelValues;
    const organizerVerified = sessionStore.isOrganizerVerified;

    return (
      <div className='content main-container-list coupons-list'>
        <ConfirmationModal
          open={!!onConfirm}
          onConfirm={onConfirm || (() => {})}
          onClose={this._clearPopup}
          title={confirmTitle}
          body={confirmBody}
          type={confirmType}
        />
        <Dialog open={isModalOpen} onClose={this.onClose}>
          <DialogContent>
            <CouponForm onClose={this.onClose} couponId={editCouponId} />
          </DialogContent>
        </Dialog>
        <div className='toolbar-container'>
          <Toolbar onAddNewCoupon={this.createCoupon} />
          <ExportToolbar types={EXPORT_TYPES} onExport={this.onExport} totalEntries={couponsStore.paginationMeta.total || 0} />
          <FilterLabelToolbar />
        </div>
        <div className='component-list'>
          <Show if={!isEmpty(couponsList)}>
            <Table className='table-list'>
              <THead />
              <TableBody>{this.items()}</TableBody>
            </Table>
          </Show>
          <Show if={isEmpty(couponsList)}>
            <TableEmptyListNotification text={t.staticAsString('coupons.noCreatedCoupons' as TranslationLockedKeys)} />
          </Show>
          <Pagination racesStore={couponsStore} switchedOnTop={organizerVerified} />
        </div>
      </div>
    );
  }
}

export { List };

import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { observer, inject } from 'mobx-react';
import * as React from 'react';

import { LabelForFilter } from 'components/Filter/ChipFilterLabel';
import { ClearAllChipLabel } from 'components/Filter/ChipFilterLabel/ClearAll';

import { defaultListFilters } from 'utils';

import { countriesStore, Customer as CustomerStore } from 'stores';

import { loadService } from '../../../services/load';

type Props = {
  customersStore: CustomerStore;
};

type State = {
  selectedFilter: nil | string;
  anchorEl: nil | HTMLElement;
};

const COLUMNS = ['id', 'full_name', 'email', 'telephone', 'post_code', 'country_id', 'created_at'];

@inject('customersStore')
@observer
class FilterLabelToolbar extends React.Component<Props, State> {
  state = {
    selectedFilter: null,
    anchorEl: null,
  };

  static defaultProps = {
    intl: null as any,
    customersStore: null as any,
  };

  clearFilters = (): void => {
    loadService.clearFilters();
  };

  deleteFilters = (...names: Array<string>) => {
    loadService.deleteFilters(() => {}, ...names);
  };

  processFilterValue = (name: string, value: any): any => {
    switch (name) {
      case 'created_at':
        return defaultListFilters.dateRange.persistance.prettify('', value, { dateFormat: 'datetime' });
      case 'country_id':
        return countriesStore.findModelValue(value)?.name();
      default:
        return value;
    }
  };

  _renderLabels = (): Array<React.ReactNode> => {
    const { customersStore } = this.props;
    const searchData = customersStore.searchDataModel.search;

    return COLUMNS.map((param) => {
      const processedValue = this.processFilterValue(param, searchData[param]);
      return <LabelForFilter onClear={this.deleteFilters} key={param} name={param} className='filter-item' value={processedValue} />;
    });
  };

  _renderClearAll = (): React.ReactNode => {
    const { customersStore } = this.props;
    const searchData = customersStore.searchDataModel.search;

    return <ClearAllChipLabel onClear={this.clearFilters} key={'clear_all'} values={searchData} className='filter-item' />;
  };

  _isFiltersEmpty = () => {
    const { customersStore } = this.props;
    const searchData = customersStore.searchDataModel.search;

    return isEmpty(searchData);
  };

  render() {
    return (
      <div className={classNames('filters-toolbar', { empty: this._isFiltersEmpty() })}>
        {[...this._renderLabels(), this._renderClearAll()]}
      </div>
    );
  }
}

export { FilterLabelToolbar };

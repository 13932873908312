import { t } from 'utils';

function formatShortLocale(value: string): string {
  return value.toUpperCase();
}

function formatLongLocale(value: string): string {
  return t.staticAsString(`locales.label.${value}` as TranslationLockedKeys) as any;
}

function formatLongWithShortLocale(value: string) {
  return `${formatLongLocale(value)} (${formatShortLocale(value)})`;
}

export { formatShortLocale, formatLongLocale, formatLongWithShortLocale };

import * as React from 'react';
import { withRouter, Route } from 'react-router-dom';

import { ROUTES, SPORT_TYPE } from 'src/constants';

import { loadable, withProgressSpinner } from 'hocs';

import { loadService } from '../services/load';

import { EditSportType } from './EditSportType';
import { List } from './List';
import { NewSportType } from './NewSportType';

const { sportTypesRoute, sportTypeRoute } = ROUTES;

@withProgressSpinner([`UPDATE_${SPORT_TYPE}_INDEX`, `UPDATE_${SPORT_TYPE}`, `CREATE_${SPORT_TYPE}`, `DELETE_${SPORT_TYPE}`])
@withRouter
@loadable({ service: loadService, loadResourcesOnResourceLoad: true })
class SportTypes extends React.Component<RouterProps> {
  render() {
    const selectedId = this.props.match?.params?.id;
    return (
      <div className='content sport-types'>
        <List sportTypeRouterId={selectedId} />
        <Route component={NewSportType} path={sportTypesRoute} exact />
        <Route component={EditSportType} path={sportTypeRoute} />
      </div>
    );
  }
}

export { SportTypes };

import { isAllCheckpointsAdded } from 'modules/Distances/components/shared/Steps/GPX/utils';

import { closeModal } from 'src/modules/Distances/components/shared/Steps/GPX/actions/closeModal';

import { t } from 'utils';

import { toastStore } from 'stores';

export const beforeCloseModal = () => {
  if (isAllCheckpointsAdded()) {
    closeModal();
  } else {
    const text = t.staticAsString('distances.steps.helperText.gpx.popup.validation.body');
    toastStore.show(text, null, 5000, 'error');
  }
};

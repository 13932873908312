import * as React from 'react';
import styled from 'styled-components';

import { Icon } from 'src/components/Icon';
import {
  WrapperOneCheckPoint,
  CheckPointName,
  CoordinateName,
  CoordinateValue,
  WrapperCheckPointCoordinate,
  WrapperCheckPointName,
} from 'src/modules/Distances/components/shared/Steps/GPX/components/ManageCheckPointsModal/styled';
import { CheckPoint } from 'src/modules/Distances/components/shared/Steps/GPX/types';

import { Show } from 'components/Condition';

import { t } from 'utils';

type Props = {
  checkpoint: CheckPoint;
  onRemove: Function;
};

export const LeftWrapper = styled.div`
  width: 50%;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: -8px 0 0 0;
  display: inline-block;
`;

export const OneCheckPointReady = (props: Props) => {
  const { checkpoint, onRemove } = props;

  return (
    <WrapperOneCheckPoint>
      <WrapperCheckPointName>
        <CheckPointName>{checkpoint.name}</CheckPointName>
        <Show if={checkpoint.showCloseButton}>
          <StyledIcon value='CrossGray' onClick={() => onRemove(checkpoint)} />
        </Show>
      </WrapperCheckPointName>

      <WrapperCheckPointCoordinate>
        <LeftWrapper>
          <CoordinateName>{t.staticAsString('distances.steps.helperText.gpx.lat')}</CoordinateName>
          <CoordinateValue>{checkpoint.coordinate.lat}</CoordinateValue>
        </LeftWrapper>
        <div>
          <CoordinateName>{t.staticAsString('distances.steps.helperText.gpx.lng')}</CoordinateName>
          <CoordinateValue>{checkpoint.coordinate.lng}</CoordinateValue>
        </div>
      </WrapperCheckPointCoordinate>
    </WrapperOneCheckPoint>
  );
};

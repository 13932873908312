import { styled } from '@mui/styles';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { Button } from 'src/components/core';
import { t } from 'src/utils';

import { handleBack } from '../actions';

import { taskStore } from '../stores';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  padding: '15px 0',
});

const Title = styled('h1')({
  fontSize: '20px',
});

export const Header = () => {
  return (
    <Observer>
      {() => (
        <Wrapper>
          <Title>
            {t('supportTaskManager.taskId')}: {taskStore.value?.id}
          </Title>
          <Button type='apply' onClick={handleBack}>
            {t('shared.back')}
          </Button>
        </Wrapper>
      )}
    </Observer>
  );
};

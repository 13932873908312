import { ReceiptSettingsType } from '../types';

export const mapOrganizertoReceipt = (organizer: OrganizerType) => {
  return {
    company_name: organizer.company,
    vat_number: organizer.organization_number,
    address: organizer.address,
    post_code: organizer.post_code,
    city: organizer.city,
    country_id: organizer.country_id,
  };
};

export const mapReceiptToOrganizer = (receipt: ReceiptSettingsType) => {
  return {
    company: receipt.company_name,
    organization_number: receipt.vat_number,
    address: receipt.address,
    post_code: receipt.post_code,
    city: receipt.city,
    country_id: receipt.country_id,
  };
};

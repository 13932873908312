import DialogActions from '@mui/material/DialogActions';
import { withStyles } from '@mui/styles';
import * as React from 'react';

import { ButtonClose, TitleModal } from '.';

const DialogActionsStyled = withStyles({
  root: {
    padding: '22px 24px',
    background: '#262E35',
  },
})(DialogActions);

export const ModalTop = () => {
  return (
    <DialogActionsStyled>
      <TitleModal />
      <ButtonClose />
    </DialogActionsStyled>
  );
};

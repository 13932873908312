import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACERS_RESULT_URL } from 'src/constants';

import { request, action, t } from 'utils';

import { toastStore } from 'stores';

import { NEW_RESULT_FORM_ID } from '../constants';

class RacersNewResult {
  @request({ action: NEW_RESULT_FORM_ID })
  request(id: number, body: AnyObject): Promise<any> {
    const url = generatePath(RACERS_RESULT_URL, { id });
    const response = axios.post(url, body);

    return response;
  }

  @action({ action: NEW_RESULT_FORM_ID })
  async create<T>(id: number, data: AnyObject): Promise<'conflict' | 'success' | 'error'> {
    const [status, response] = await this.request(id, data);

    if (response.status === 409) {
      return 'conflict';
    }
    if (!status) {
      toastStore.show(`Error: ${response.data}` || t.staticAsString('errorPage.title'));
    }

    return status ? 'success' : 'error';
  }
}

export { RacersNewResult, RacersNewResult as RacersNewResultService };

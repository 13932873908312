import { reorderUtil } from 'utils';
import { t } from 'utils';

import { toastStore } from 'stores';

import { distancesService } from '../services';

import { distancesStore } from '../stores';

export const dragEndAction = async (result: AnyObject) => {
  const distances = reorderUtil.changePosition([...distancesStore.values], result.source.index, result.destination.index);

  distancesStore.setDistances(distances);
  const status = await distancesService.updateDistanceIndex(result.draggableId, result.destination.index);

  if (!status) {
    toastStore.show(t.staticAsString('distances.canNotMove'));
  }
};

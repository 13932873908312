type FilesEventTarget = EventTarget & { files: File[] };

async function selectFiles(accept?: string): Promise<File[]> {
  return new Promise((res) => {
    const input = document.createElement('input');
    input.type = 'file';

    if (accept) {
      input.accept = accept;
    }

    input.onchange = (e: Event) => {
      const target = e.target as FilesEventTarget;
      res(target?.files || []);
    };

    input.click();
  });
}

async function selectFile(accept?: string): Promise<nil | File> {
  const files = await selectFiles(accept);
  return files[0];
}

export { selectFiles, selectFile };

import { helperRacesStore, helperOrganizersStore, progressStore, errorsStore } from 'src/stores';

import { sportTypeLoadServiceAuxiliary, helperRaceService, loadCurrencyService, loadCountryService } from 'services';

import { LOAD_FORM, RACE_FORM_ID } from '../constants';

import { getRaceId } from '../utils';

import { organizerStore } from '../stores';

import { loadEditorSettings } from './editorSettings';
import { loadAvailableLocales } from './loadAvailableLocales';
import { loadOrganizers } from './loadOrganizers';
import { loadOrganizer } from './organizer';
import { loadReceiptSettings } from './receiptSettings';

async function reloadRace() {
  const raceId = getRaceId();
  if (!raceId) {
    return;
  }

  helperRaceService.loadResource(raceId);
}

async function mountUpdateForm(initState: Function) {
  const raceId = getRaceId();

  if (!raceId) {
    return;
  }

  progressStore.log(LOAD_FORM, 'progress');

  await Promise.all([
    helperRaceService.loadResource(raceId),
    loadCountryService.loadResources(),
    loadCurrencyService.loadResources(),
    sportTypeLoadServiceAuxiliary.loadResources(),
    loadOrganizers(),
    loadReceiptSettings(),
    loadAvailableLocales(),
    loadEditorSettings(),
    loadOrganizer(),
  ]);

  await initState();

  progressStore.log(LOAD_FORM, 'completed');
}

async function mountNewForm() {
  helperRacesStore.selected = null;
  progressStore.log(LOAD_FORM, 'progress');
  await Promise.all([
    loadCountryService.loadResources(),
    loadCurrencyService.loadResources(),
    sportTypeLoadServiceAuxiliary.loadResources(),
    loadOrganizers(),
    loadAvailableLocales(),
    loadOrganizer(),
  ]);
  progressStore.log(LOAD_FORM, 'completed');
}

function unmount() {
  helperRacesStore.cleanSelected();
  helperOrganizersStore.clearAll();
  errorsStore.clear(RACE_FORM_ID);
  errorsStore.clearError(RACE_FORM_ID);
  organizerStore.clear();
}

const mount = {
  update: mountUpdateForm,
  new: mountNewForm,
};

export { mount, unmount, reloadRace };

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { PAYMENT_TRANSACTION_TYPES } from 'modules/Payments/types';
import moment from 'moment';
import * as React from 'react';

import { formatter, time } from 'utils';

type Props = {
  item: AnyObject;
  currency: string;
  distanceTitle: string;
};

const Row = (props: Props) => {
  const { currency, distanceTitle } = props;
  const { created, payment_info, provider_transaction_id, uuid, amount, description, payeeReference, type } = props.item || {};
  const date = time.datetime(moment(created)).parse().format('shortDate');
  const formattedAmount = formatter.integer(+(amount / 100).toFixed(2));

  return (
    <TableRow className='table-row'>
      <TableCell className='table-cell'>{provider_transaction_id || payeeReference || uuid}</TableCell>
      <TableCell className='table-cell cell-name' title={payment_info?.distance?.name || description}>
        {distanceTitle || description}
      </TableCell>
      <TableCell className='table-cell'>{date}</TableCell>
      <TableCell className='table-cell'>{`${formattedAmount} ${currency}`}</TableCell>
      <TableCell className='table-cell cell-type'>{PAYMENT_TRANSACTION_TYPES[type]}</TableCell>
    </TableRow>
  );
};

export { Row as TransactionsRow };

import { helperDistancesStore } from 'stores';

import { registrationFieldsTools } from '../utils';

import { getFieldValidation } from './racer';

const MAXIMUM_TEXT_DEFAULT = 255;

const memberValidation: AnyObject = {
  firstname: {
    presence: {
      allowEmpty: false,
    },
    length: {
      maximum: MAXIMUM_TEXT_DEFAULT,
    },
  },

  lastname: {
    presence: {
      allowEmpty: false,
    },
    length: {
      maximum: MAXIMUM_TEXT_DEFAULT,
    },
  },
  email: {
    presence: {
      allowEmpty: false,
    },
    email: true,
  },
  gender: {
    presence: {
      allowEmpty: false,
    },
  },
  team_id: {
    presence: {
      allowEmpty: false,
    },
  },
};

export const textValidation = {
  presence: {
    allowEmpty: false,
  },
};

const disciplineId = {
  discipline_id: {
    presence: {
      allowEmpty: false,
    },
  },
};

const member = (_object: RacerType) => {
  const distance = helperDistancesStore.selected;

  let validationData = memberValidation;

  if (Number(distance?.disciplines?.length) > 0) {
    validationData = { ...validationData, ...disciplineId };
  }

  return validationData;
};

export { member, memberValidation };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACERS_RESULT_URL, TEAMS_RESULT_URL, DISTANCE_TYPE } from 'src/constants';

import { action, request } from 'utils';

import { progressStore, errorsStore } from 'stores';

import { UPDATE_RESULT } from '../constants';

import { distanceStore, teamResultsStore, singleResultsStore, resultsStubStore, fieldRegisterStore } from '../stores';

import { storeData } from '../tools';
import { fieldUpdateOptions } from '../types';

class UpdateResult {
  storeData = storeData.bind(this);

  get store(): ILoadable<ResultType, FiltersType> {
    const distanceType = this.distanceType();

    switch (distanceType) {
      case DISTANCE_TYPE.single:
        return singleResultsStore;
      case DISTANCE_TYPE.team:
        return teamResultsStore;
      default:
        return resultsStubStore;
    }
  }

  distanceType(): nil | distanceType {
    const distanceModel = distanceStore.modelSelected;

    if (!distanceModel) {
      return;
    }

    return distanceModel.value.type;
  }

  @request({ action: UPDATE_RESULT, writeError: false })
  updateRacerRequest(id: number, data: Object): any {
    const url = generatePath(RACERS_RESULT_URL, { id });
    return axios.patch(url, data);
  }

  @request({ action: UPDATE_RESULT, writeError: false })
  updateTeamRequest(id: number, data: Object): any {
    const url = generatePath(TEAMS_RESULT_URL, { id });
    return axios.patch(url, data);
  }

  updateRequest(entity: 'racer' | 'team', id: number, data: Object): any {
    switch (entity) {
      case 'racer':
        return this.updateRacerRequest(id, data);
      case 'team':
        return this.updateTeamRequest(id, data);
      default:
        return null;
    }
  }

  @action({ action: UPDATE_RESULT })
  async update(entity: 'racer' | 'team', id: number, value: Object, updateOptions: fieldUpdateOptions): Promise<boolean> {
    const startRequestTimestamp = new Date();
    const { fieldId, storeOptions } = updateOptions;
    progressStore.log(fieldId, 'progress');

    const [status, response] = await this.updateRequest(entity, id, value);

    if (status) {
      await this.storeData(response.data.data, storeOptions);
    }

    if (!status && response.status === 422) {
      errorsStore.add(fieldId, response.data.errors);
    }

    if (!status && response.status === 400) {
      errorsStore.add(fieldId, { [id]: [response.data.message] });
    }

    await progressStore.logWithPromise(fieldId, 'completed', { minRequestTime: 400, requestStartTime: startRequestTimestamp });

    if (!!status) {
      fieldRegisterStore.deactivateField(fieldId);
    }

    return status;
  }
}

export { UpdateResult };

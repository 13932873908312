import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as React from 'react';

import { t } from 'utils';

type Props = {
  handleEdit: Function;
  handleDelete: Function;
};

type PopupStateType = {
  close: AnyFunction;
};

export class Controls extends React.Component<Props> {
  handleEdit = (popupState: PopupStateType) => () => {
    const { handleEdit } = this.props;

    handleEdit();
    popupState.close();
  };

  handleDelete = (popupState: PopupStateType) => () => {
    const { handleDelete } = this.props;

    handleDelete();
    popupState.close();
  };

  render() {
    return (
      <PopupState variant='popover' popupId='demo-popup-popover'>
        {(popupState: PopupStateType) => (
          <div className='controls-block'>
            <MoreVertIcon className='primary-icon' {...bindTrigger(popupState)} />
            <Menu
              {...bindMenu(popupState)}
              className='controls-menu'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: -50,
                horizontal: 175,
              }}
            >
              <MenuItem className='control-item' onClick={this.handleEdit(popupState)}>
                <span className='squads text'>{t.staticAsString('squads.controls.edit')}</span>
              </MenuItem>
              <MenuItem className='control-item secondary' onClick={this.handleDelete(popupState)}>
                <span className='squads text'>{t.staticAsString('squads.controls.delete')}</span>
              </MenuItem>
            </Menu>
          </div>
        )}
      </PopupState>
    );
  }
}

import { t } from 'utils';

export const attachToProfileValidation: AnyObject = {
  targetEmail: {
    presence: {
      allowEmpty: false,
      message: t.staticAsString('validation.required' as TranslationLockedKeys),
    },
  },
};

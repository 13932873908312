import { ArrowForward } from '@mui/icons-material';
import { Card, Box, Typography, LinearProgress } from '@mui/material';
import { emptyData } from 'modules/Dashboard/common/empty-data';
import { dashboardTextStyles } from 'modules/Dashboard/common/text.styles';
import { DASHBOARD_STATES } from 'modules/Dashboard/constants/dashboard.constants';
import { DashboardStore } from 'modules/Dashboard/stores/dashboard.store';
import { dashboardRacesStore, DashboardRacesStore } from 'modules/Dashboard/stores/races.store';
import { Collection } from 'modules/Dashboard/types/collections.type';
import { formatCurrency } from 'modules/Reconciliation/shared/utils/formatCurrency';

import { Icon } from 'components/Icon';

import { history } from 'utils/history';
import { t } from 'utils/t';

import { progressStore } from 'stores';

import CardAreaChart from './AreaChart';

type Props = {
  collection: Collection;
};

const DashboardCard = ({ collection }: Props) => {
  const textStyles = dashboardTextStyles;

  const handleNavigate = () => {
    if (collection === 'coupons') {
      return history.push('/coupons');
    }
    return history.push(`/dashboard/${collection}`);
  };

  const cardLoader = () => {
    return (
      <Box sx={{ height: 36, width: '100%', pt: 1 }}>
        <LinearProgress />
      </Box>
    );
  };

  const getCollection = () => {
    switch (collection) {
      case 'payments':
        return {
          title: t('dashboard.payments'),
          icon: <Icon value='expand' />,
          data: DashboardStore.registrations.value?.data ?? emptyData,
          total: DashboardStore.registrations.value?.total ?? 0,
          color: '#F9D026',
        };
      case 'turnover':
        return {
          title: t('dashboard.turnover'),
          icon: <Icon value='expand' />,
          data: DashboardStore.turnovers.value?.data ?? emptyData,
          total: formatCurrency(DashboardStore.turnovers.value?.total ?? 0, dashboardRacesStore.selectedRace?.currency?.iso_code ?? 'EUR'),
          color: '#7CCBA5',
        };
      case 'coupons':
        return {
          title: t('dashboard.coupons'),
          icon: <ArrowForward onClick={handleNavigate} />,
          data: DashboardStore.coupons.value?.data ?? emptyData,
          total: DashboardStore.coupons.value?.total ?? 0,
          color: '#5DA6FC',
        };
      default:
        return {
          title: '',
          icon: '',
          data: emptyData,
          total: 0,
          color: 'black',
        };
    }
  };

  const data = getCollection();

  return (
    <Card onClick={handleNavigate} sx={{ cursor: 'pointer', height: collection === 'coupons' ? 192 : 'auto' }}>
      <Box p={3}>
        <Box sx={{ height: 35 }} display='flex' justifyContent='space-between'>
          <Typography sx={textStyles.cardTitle}>{data.title}</Typography>
          {/* <Icon value='expand' /> */}
          {data.icon}
        </Box>
        <Box mt={3} display='flex' justifyContent='space-between'>
          <Box sx={{ width: collection === 'coupons' ? '100px' : 'auto' }} display='flex' flexDirection='column' alignItems='start'>
            {progressStore.isLoading(DASHBOARD_STATES.LOADING) ? (
              cardLoader()
            ) : (
              <Typography sx={textStyles.metricTitle}>{data?.total ?? 0}</Typography>
            )}
            {collection !== 'coupons' && (
              <Box mt={2} display='flex' alignItems='center' sx={textStyles.explanationTitle}>
                <Icon style={{ marginTop: 5 }} value='circleUp' />{' '}
                <b style={{ color: 'black', fontWeight: 700, paddingRight: 4, paddingLeft: 9 }}>100%</b> {t('dashboard.vsLastYear')}
              </Box>
            )}
          </Box>
          <Box sx={{ width: 150, height: 80 }}>
            <CardAreaChart width={150} height={80} id={collection} data={data?.data!} color={data.color} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default DashboardCard;

import * as React from 'react';

export const NavigateLeft = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.001 22.4971C17.8 22.4971 22.501 17.7961 22.501 11.9971C22.501 6.19808 17.8 1.49707 12.001 1.49707C6.20199 1.49707 1.50098 6.19808 1.50098 11.9971C1.50098 17.7961 6.20199 22.4971 12.001 22.4971Z'
      stroke='#656B6F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='circle'
    />
    <path
      d='M11.251 15.7471L7.50098 11.9971L11.251 8.24707'
      stroke='#656B6F'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path d='M7.50098 11.9971H16.501' stroke='#656B6F' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

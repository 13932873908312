import { errorsStore } from 'stores';

import { SUPPORT_TASK_MANAGER_FORM } from '../constants';

import { distancesService } from '../services';

export const loadRefundDistances = async (text: string | null = null) => {
  errorsStore.clear(SUPPORT_TASK_MANAGER_FORM);
  await distancesService.getRefundDistances(getParams(text));
};

const getParams = (text: string | null): FiltersType => {
  const params = {
    with: 'race.currency',
  };

  if (text === null) {
    return params;
  }

  return {
    ...params,
    search: `id:${text}`,
    searchFields: `id:like`,
    searchJoin: 'AND',
  };
};

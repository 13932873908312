import { COUNTRY, COUNTRIES_URL } from 'src/constants';

import { request, action } from 'utils';

import { countriesStore } from 'stores';
import { Country as CountryStore } from 'stores';

import { LoadableService } from './loadable';

class LoadCountryService extends LoadableService<CountryType, FiltersType, CountryStore> {
  constructor(store: CountryStore) {
    super({ store, resourcesUrl: COUNTRIES_URL, resource: COUNTRY, resourcesRoot: 'data' });
  }

  @request({ action: `LOAD_${COUNTRY}S` })
  async loadResourcesRequest(params: FiltersType): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @action({ action: `LOAD_${COUNTRY}S` })
  async loadResources(params: FiltersType = {}, pageNum: number = 1): Promise<any> {
    if (!this.store.values.length) {
      return super.loadResources(params, pageNum);
    }
  }
}

const loadCountryService = new LoadCountryService(countriesStore);
export { loadCountryService, LoadCountryService };

import { INIT_TRANSLATIONS } from 'src/constants';

import { messages as messagesUtil } from 'utils';

import { locale } from 'actions/locales';
import { messages } from 'actions/messages';

import progressStore from 'stores/progress';

let isInitialized = false;

async function init() {
  messagesUtil.init();

  if (!messagesUtil.exists()) {
    progressStore.log(INIT_TRANSLATIONS, 'progress');
  }

  const defaultLocale = locale.fetchDefault();

  if (await messages.set(defaultLocale)) {
    isInitialized = true;
    progressStore.log(INIT_TRANSLATIONS, 'completed');
    return;
  } else {
    messages.fallback();
  }

  await messages.load(defaultLocale);
  isInitialized = true;
  progressStore.log(INIT_TRANSLATIONS, 'completed');
}

function isInitCompleted() {
  return isInitialized;
}

export { init, isInitCompleted };

import * as React from 'react';
import styled from 'styled-components';

import { fontFamilyRoboto } from 'src/components/Form/Fields/FileUploader/style';

import { t } from 'utils';

const Title = styled.div`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  flex: 1;
`;

export const TitleModal = () => <Title>{t.staticAsString('distances.steps.helperText.gpx.modalTitle')}</Title>;

import { toJS } from 'mobx';
import moment from 'moment';

import { BACKEND_DATETIME_FORMATE } from 'src/constants/mixed';

import { form } from 'stores';

import { RACE_FORM_ID } from '../constants';

function formatData(fieldName: string): AnyObject {
  const formData = form.fetch<RaceFormData>(RACE_FORM_ID);
  const value: any = toJS(formData[fieldName]);

  const defaultLocale = formData?.languagesData?.defaultLocale || 'en';
  switch (fieldName) {
    case 'race_date':
      return {
        [fieldName]: moment(value).startOf('day').format(BACKEND_DATETIME_FORMATE),
      };
    case 'race_end_date':
      return {
        [fieldName]: moment(value).endOf('day').format(BACKEND_DATETIME_FORMATE),
      };
    case 'location':
      return { location: { ...value, _delete: !Boolean(value) } };
    case 'company_name':
    case 'vat_number':
    case 'address':
    case 'post_code':
    case 'city':
      return { [fieldName]: value || null };
    case 'country_id':
      return { [fieldName]: value?.value ?? null };
    case 'currency_id':
      return { currency_id: value };
    case 'sport_id':
    case 'organizer_id':
      return { [fieldName]: value.key };
    case 'languagesData':
      return {
        pref_lang_code: value.defaultLocale,
        selected_lang_codes: [...new Set([...value.selectedLanguages, value.defaultLocale])],
      };
    case 'name':
      return {
        [defaultLocale]: {
          name: value,
        },
      };
    case 'description':
      return {
        [defaultLocale]: {
          description: value,
        },
      };
    case 'country_id':
      return { [fieldName]: value.value };

    case 'organizer_name':
      return { ['contacts']: { ['name']: value } };
    case 'organizer_website':
      return { ['contacts']: { ['website']: value } };
    case 'organizer_description':
      return { ['contacts']: { ['description']: value } };
    case 'organizer_instagram':
      return { ['contacts']: { ['instagram']: value } };
    case 'organizer_facebook':
      return { ['contacts']: { ['facebook']: value } };
    case 'organizer_linkedin':
      return { ['contacts']: { ['linkedin']: value } };
    case 'organizer_emails':
      return { ['contacts']: { ['emails']: value } };

    default:
      return { [fieldName]: value };
  }
}

export { formatData };

import { keys, isNil } from 'lodash';
import Xregexp from 'xregexp';

import { _addErrors } from './_addErrors';

function pattern(withAnchors: boolean) {
  return Xregexp(`${withAnchors ? '^' : ''}goal\\.(?<fieldName>\\w+)${withAnchors ? '$' : ''}`);
}

/**
 * @description
 * Catch and add to errorsStore, BE errors errors, specifically:
 * goal.goal_type, goal.goal_length
 */
function catchErrorsForGoal(errors: RawError | nil) {
  if (!errors) {
    return;
  }

  const errorKeys = keys(errors);

  const mappedErrors = errorKeys.reduce((processedErrors, errorKey) => {
    const match = Xregexp.exec(errorKey, pattern(true));

    if (!match) {
      return processedErrors;
    }

    let { fieldName } = match;
    const parsedErrorIndex = parseInt(match.errorInde);

    if (isNil(parsedErrorIndex)) {
      return processedErrors;
    }

    const newErrorKey = `goal.${fieldName}`;

    const oldErrors = errors[errorKey] || [];
    const newErrors = oldErrors.map((error) => Xregexp.replace(error, pattern(false), '$<fieldName>'));

    processedErrors[newErrorKey] = newErrors;

    return processedErrors;
  }, {});

  return mappedErrors;
}

export { catchErrorsForGoal };

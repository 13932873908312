import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

import { progressStore } from 'stores';

import { INIT_INTEGRATIONS } from '../constants';

import { integrationsService, raceService } from '../services';

import { raceStore, integrationsStore, paginationStore } from '../stores';

export const mount = async () => {
  progressStore.log(INIT_INTEGRATIONS, 'progress');
  const match = matchPath(history.location.pathname, {
    path: ROUTES.integrations,
  });
  const { id } = match?.params || {};
  if (!id) {
    history.push(ROUTES.notFound);
    return;
  }

  await raceService.getRace(Number(id));
  await integrationsService.getIntegrations(Number(id));

  progressStore.log(INIT_INTEGRATIONS, 'completed');
};

export const unmount = () => {
  raceStore.clear();
  integrationsStore.clear();
  paginationStore.clear();
};

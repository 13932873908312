import classNames from 'classnames';
import * as React from 'react';

type Props = {
  onClick: (value: string) => void;
  value: string;
  flag: string;
  selected: string;
};

const LocaleIcon = ({ onClick, value, flag, selected }: Props) => {
  return (
    <span
      onClick={() => onClick(value)}
      className={classNames('item', 'flag-icon', `flag-icon-${flag}`, {
        active: value === selected,
      })}
    ></span>
  );
};

export { LocaleIcon };

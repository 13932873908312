import { makeObservable, override } from 'mobx';

import { DummyStore } from 'stores';

export class CheckpointStore<T> extends DummyStore<T> {
  constructor(value: T) {
    super(value);

    makeObservable(this);
  }

  @override
  set(value: T) {
    this.value = (value as any).sort((a, b) => a.index - b.index);
  }
}

import { t } from 'utils';

import { Racer as RacerType } from '../types';

function isReady(racer: RacerType): boolean {
  return racer.status === 'paid';
}

function isAwaiting(racer: RacerType): boolean {
  return racer.status === 'payment_required';
}

function formatStatus(racer: RacerType): string {
  if (isAwaiting(racer)) {
    return t.staticAsString('racers.statuses.awaiting') as any;
  }

  if (isReady(racer)) {
    return t.staticAsString('racers.statuses.ready') as any;
  }

  return '';
}

const racerStatus = {
  isReady,
  isAwaiting,
  formatStatus,
};

export { racerStatus };

import { DummyStore } from 'stores';

export const confirmStore = new DummyStore<{
  open: boolean;
  title: string;
  content: string;
}>({
  open: false,
  title: '',
  content: '',
});

import TableCell from '@mui/material/TableCell';
import { withStyles } from '@mui/styles';
import * as React from 'react';

import { t } from 'utils';

import { INTEGRATION_TABLE_HEAD } from '../../constants';

type Props = {};

const StyledTableCell = withStyles((theme) => ({
  root: {
    color: theme.palette.labelGrey,
  },
}))(TableCell);

export class IntegrationsTableHead extends React.Component<Props> {
  render(): React.ReactNode[] {
    return INTEGRATION_TABLE_HEAD.map((head) => {
      return (
        <StyledTableCell style={head.style} key={`table-head:${head.name}`}>
          {t.staticAsString(`integrations.list.table.${head.name}` as TranslationLockedKeys)}
        </StyledTableCell>
      );
    });
  }
}

import { uniqBy } from 'lodash';
import { action, observable, makeObservable } from 'mobx';
import moment from 'moment';

import { BACKEND_DATE_FORMATE } from 'src/constants';

export const getRacesParams = (text: string | null, withAdditionalParams?: boolean): FiltersType => {
  const search = `approve_status:3;published:1;status:finished;race_end_date:${moment().format(BACKEND_DATE_FORMATE)}`;
  const searchFields = 'approve_status:=;published:=;status:!=;race_end_date:>=';

  const params = {
    searchJoin: 'AND',
    with: 'currency',
    page: 1,
    limit: 10,
    search,
    searchFields,
  };

  if (text === null) {
    return params;
  }

  return {
    ...params,
    search: withAdditionalParams ? `${search};name:${text}` : `name:${text}`,
    searchFields: withAdditionalParams ? `${searchFields};name:like` : `name:like`,
  };
};

export class Races {
  @observable races: Array<AnyObject> = [];

  @observable params: AnyObject = getRacesParams(null);

  @observable hasMore: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action
  setRaces(data: Array<AnyObject>): void {
    this.races = uniqBy([...this.races, ...data], 'value');
  }

  @action
  setParams(params: Object): void {
    this.params = {
      ...this.params,
      ...params,
    };
  }

  @action
  setHasMore(hasMore: boolean = true): void {
    this.hasMore = hasMore;
  }

  @action
  clear(): void {
    this.races = [];
    this.params = getRacesParams(null);
    this.hasMore = true;
  }
}

const racesStore = new Races();
export { racesStore };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { DISTANCE_URL, SAVE_CHECK_POINT_URL } from 'src/constants';
import {
  GET_DISTANCE_CHECK_POINTS,
  SAVE_CHECK_POINT_COORDINATES,
  DELETE_CHECK_POINT_COORDINATES,
  VALIDATE_CHECK_POINT_COORDINATES,
} from 'src/modules/Distances/components/shared/Steps/GPX/constants';
import { checkPointStore, gpxRouteLoadingStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { CheckPoint, Coordinate } from 'src/modules/Distances/components/shared/Steps/GPX/types';
import {
  setMarkersInStore,
  mapFromCheckPoints,
  setPointsDefaultCoordinates,
  setAndReturnCheckPointCoordinates,
  setMarker,
  removeCheckPointCoordinates,
  removeMarker,
} from 'src/modules/Distances/components/shared/Steps/GPX/utils';

import { action, request, t } from 'utils';

import { toastStore } from 'stores';

class CheckPointService {
  @request({ action: GET_DISTANCE_CHECK_POINTS })
  private async loadCheckPointsRequest(distanceId: number, params: any): Promise<any> {
    const path = generatePath(DISTANCE_URL, { distanceId });
    return axios.get(path, { params });
  }

  @request({ action: SAVE_CHECK_POINT_COORDINATES })
  private async saveCheckPointCoordinatesRequest(checkpointId: number, coordinate: Coordinate): Promise<any> {
    const path = generatePath(SAVE_CHECK_POINT_URL, { checkpointId });
    return axios.post(path, coordinate);
  }

  @request({ action: DELETE_CHECK_POINT_COORDINATES })
  private async deleteCheckPointCoordinatesRequest(checkpointId: number): Promise<any> {
    const path = generatePath(SAVE_CHECK_POINT_URL, { checkpointId });
    return axios.delete(path);
  }

  @action({ action: GET_DISTANCE_CHECK_POINTS })
  async loadCheckPoints(distanceId: number): Promise<any> {
    const queryParams = {
      with: 'checkpoints.coordinates',
    };

    const [status, response] = await this.loadCheckPointsRequest(distanceId, queryParams);
    gpxRouteLoadingStore.off();

    if (status) {
      const checkpoints = mapFromCheckPoints(response.data.data);

      if (checkpoints && checkpoints.length > 0) {
        checkPointStore.set(checkpoints);
        setPointsDefaultCoordinates();
        setMarkersInStore();
      } else {
        const tabName = t.staticAsString('distances.steps.tabOptionsForm.checkpoints');
        toastStore.show(t.staticAsString('distances.steps.helperText.gpx.noCheckPoints', { tabName }), null, 30000, 'error');
      }
    }
  }

  @action({ action: SAVE_CHECK_POINT_COORDINATES })
  async saveCheckPointCoordinates(checkpoint: CheckPoint): Promise<any> {
    await this.saveCheckPointCoordinatesRequest(checkpoint.id, checkpoint.coordinate);
  }

  @action({ action: VALIDATE_CHECK_POINT_COORDINATES })
  async validateCheckPointCoordinates(checkpoint: CheckPoint): Promise<any> {
    const [status, response] = await this.saveCheckPointCoordinatesRequest(checkpoint.id, checkpoint.coordinate);

    if (status) {
      const checkPoint = setAndReturnCheckPointCoordinates(checkpoint.coordinate);

      if (checkPoint) {
        setMarker(checkPoint);
      }
    } else {
      const errors = response.data.errors;
      let text = t.staticAsString('supportTaskManager.error');

      if (errors) {
        text = errors.index ? response.data.errors.index.join(' ') : '';

        if (errors.lat) {
          text = errors.lat.join(' ');
        } else {
          if (errors.lng) {
            text = errors.lng.join(' ');
          }
        }
      }

      toastStore.show(text, null, 5000, 'error');

      removeCheckPointCoordinates(checkpoint.id);
      removeMarker(Number(checkpoint.index));
    }
  }

  @action({ action: DELETE_CHECK_POINT_COORDINATES })
  async deleteCheckPointCoordinates(checkpoint: CheckPoint): Promise<any> {
    await this.deleteCheckPointCoordinatesRequest(checkpoint.id);
  }
}

export const checkPointService = new CheckPointService();

import { generateMaskedInput } from '../mask';
import { mask, placeholderChar, format, placeholder } from './constants';
import { createAutoCorrectTimeDurationPipe } from './tools';

const timeDurationMask = generateMaskedInput({
  mask,
  pipe: createAutoCorrectTimeDurationPipe(format),
  placeholderChar,
  guide: true,
  keepCharPositions: true,
  placeholder,
});

export * from './tools';
export { timeDurationMask };

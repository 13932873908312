import { ROUTES } from 'src/constants';
import { errorsStore, form, toastStore, confirmationModalStore, progressStore } from 'src/stores';
import { history, validate, Validator, t } from 'src/utils';

import { EQ_FORM_ID, SAVE_SYNC_STEP, SETTINGS_STEP, EQ_MAPPING_LOAD } from '../constants';

import { eqService } from '../services';

import { stepStore } from '../stores';

import { syncMap, EQ_TIMING_TYPE, mapFormMappings, getMappingValidation, syncStore, syncService } from '../../Shared';
import { SynchronizationsService } from '../../Table/services/synchronizations.service';
import { canSave } from '../validation';
import { settingsStepSchema } from '../validation';

export const handleCancel = () => {
  history.push(ROUTES.sync);
};

export const changeStep = async () => {
  errorsStore.clear(EQ_FORM_ID);
  const data = form.fetch<AnyObject>(EQ_FORM_ID);

  if (!validate(EQ_FORM_ID, data, settingsStepSchema() as AnyObject)) return;

  const valid = await eqService.createSync(syncMap(data, EQ_TIMING_TYPE));
  if (!valid) return;

  stepStore.set(SAVE_SYNC_STEP);
};

export const saveStartSync = async () => {
  progressStore.log(EQ_MAPPING_LOAD, 'progress');
  errorsStore.clear(EQ_FORM_ID);
  const formData = form.fetchWithoutObservable<AnyObject>(EQ_FORM_ID);
  const data = mapFormMappings(formData, true);
  const validator = new Validator(data, getMappingValidation(), '', () => false);
  const errors = validator.errors;
  !!errors && errorsStore.add(EQ_FORM_ID, errors);
  if (errors) {
    progressStore.log(EQ_MAPPING_LOAD, 'completed');
    return;
  }

  const [isOk, response] = await eqService.createMapping(data, Number(syncStore.value?.id));
  if (!isOk) {
    const errorsBE = response.data.errors || {};
    const firstError = ((Object.values(errorsBE) || [])[0] || []) as string[];

    firstError[0] && toastStore.show(firstError[0]);
    progressStore.log(EQ_MAPPING_LOAD, 'completed');
    return;
  }

  await syncService.enableSync(Number(syncStore.value?.id));
  await SynchronizationsService.publishSynchronization(Number(syncStore.value?.id));

  history.push(ROUTES.sync);
  progressStore.log(EQ_MAPPING_LOAD, 'completed');
};

export const saveSync = async () => {
  progressStore.log(EQ_MAPPING_LOAD, 'progress');
  errorsStore.clear(EQ_FORM_ID);
  const formData = form.fetchWithoutObservable<AnyObject>(EQ_FORM_ID);
  const data = mapFormMappings(formData, true);
  const validator = new Validator(data, getMappingValidation(), '', () => false);
  const errors = validator.errors;
  !!errors && errorsStore.add(EQ_FORM_ID, errors);
  if (errors) {
    progressStore.log(EQ_MAPPING_LOAD, 'completed');
    return;
  }

  const [isOk, response] = await eqService.createMapping(data, Number(syncStore.value?.id));
  if (!isOk) {
    const errorsBE = response.data.errors || {};
    const firstError = ((Object.values(errorsBE) || [])[0] || []) as string[];

    firstError[0] && toastStore.show(firstError[0]);
    progressStore.log(EQ_MAPPING_LOAD, 'completed');
    return;
  }

  history.push(ROUTES.sync);
  progressStore.log(EQ_MAPPING_LOAD, 'completed');
};

export const startSync = async (id: number) => {
  progressStore.log(EQ_MAPPING_LOAD, 'progress');
  await syncService.enableSync(Number(id));
  await SynchronizationsService.publishSynchronization(Number(id));
  history.push(ROUTES.sync);
  progressStore.log(EQ_MAPPING_LOAD, 'completed');
};

export const backToSettings = () => {
  confirmationModalStore.openModal({
    title: t.staticAsString('shared.exportConfirm.title'),
    body: t.staticAsString('sync.you_will_lose_all_data'),
    type: 'confirm',
    onConfirm: () => {
      stepStore.set(SETTINGS_STEP);
      form.onChange(EQ_FORM_ID, 'distances', []);
      form.onChange(EQ_FORM_ID, 'classes', []);
      form.onChange(EQ_FORM_ID, 'waves', []);
    },
  });
};

import { observer } from 'mobx-react';
import * as React from 'react';

import { SelectFilterWithSearch } from 'components/Filter/DefaultFilters';

import { t } from 'utils';

type Props = {
  value: nil | string | number;
} & FilterProps;

const PATH = 'App\\Jobs\\Admin\\Utils\\';

const LABELS = {
  copyRacers: 'CopyRacersJob',
  delete: 'DeleteAccountJob',
  mergeAccounts: 'MergeAccountsJob',
  creditSingleRegistration: 'RefundOrderJob',
  creditDistanceRegistration: 'RefundDistanceJob',
  generateCoupon: 'GenerateCouponsJob',
  bibNumbers: 'GenerateBibSeriesJob',
  duplicateDistance: 'DuplicateDistanceJob',
};

@observer
class TypeFilterComponent extends React.Component<Props> {
  options = () => {
    return Object.keys(LABELS).map<SelectOption>((key) => {
      return {
        key: key,
        value: key,
        label: t.staticAsString(`supportTaskManager.list.actions.${key}` as TranslationLockedKeys),
      } as SelectOption;
    });
  };

  render() {
    const { props } = this;
    return <SelectFilterWithSearch {...props} options={this.options()} />;
  }
}

const calcValue = (_name: any, type: string) => ({ type: PATH + LABELS[type] });
const calcHandler = () => ({ type: '=' });

export const type: FilterListDescriptor = {
  filter: {
    persistance: {
      advanced: {
        calcValue,
        calcHandler,
      },

      prettify: (name, value: OrderStateType) => {
        return value && t.staticAsString(`supportTaskManager.list.actions.${value}` as TranslationLockedKeys);
      },
    },
  },

  frontend: {
    type: 'custom',
    component: TypeFilterComponent,
  },
};

import { DISTANCE_FORM_ERRORS } from 'modules/Distances/constants';
import { editRegistrationService, transferRegistrationService } from 'modules/Distances/services';
import { mapToTransferRegistration } from 'modules/Distances/utils';
import { mapToEditRegistration } from 'modules/Distances/utils/mapToEditRegistration';

import { errorsStore } from 'stores';

export const submitSelfServices = async (data: any) => {
  errorsStore.clear(DISTANCE_FORM_ERRORS);
  const distance = data.distance;
  const isSelfServiceChecked = data.editorSettings.tab_options.includes('selfServices');

  if (distance) {
    const distanceId = distance.id as number;

    if (!isSelfServiceChecked) {
      transferRegistrationService.delete(distanceId);
      editRegistrationService.delete(distanceId);
      return true;
    }

    if (distance.is_transfer_registration_available) {
      const transferRegistrationSettings = mapToTransferRegistration(distance.transfer_registration_settings);
      transferRegistrationService.save(distanceId, transferRegistrationSettings, distance.isTransferRegistrationEdit);
    } else {
      if (distance.transfer_registration_settings) {
        transferRegistrationService.delete(distanceId);
      }
    }

    if (distance.is_edit_registration_available) {
      const editRegistrationSettings = mapToEditRegistration(distance.edit_registration_settings);
      const [isOk, response] = await editRegistrationService.save(
        distanceId,
        editRegistrationSettings,
        Boolean(distance.edit_registration_settings?.id),
      );

      if (!isOk) {
        errorsStore.add(DISTANCE_FORM_ERRORS, { ['self_services.edit_registration']: response?.data?.errors });
        return false;
      }
    } else {
      if (distance.edit_registration_settings) {
        editRegistrationService.delete(distanceId);
      }
    }
    return true;
  }
};

export const submitTransferRegistration = async (data: any) => {
  errorsStore.clear(DISTANCE_FORM_ERRORS);
  const distance = data.distance;
  const isSelfServiceChecked = data.editorSettings.tab_options.includes('selfServices');

  if (distance) {
    const distanceId = distance.id as number;

    if (!isSelfServiceChecked) {
      const isOk = await transferRegistrationService.delete(distanceId);
      return { type: 'delete', response: isOk };
    }

    if (distance.is_transfer_registration_available) {
      const transferRegistrationSettings = mapToTransferRegistration(distance.transfer_registration_settings);
      const [isOk, response] = await transferRegistrationService.save(
        distanceId,
        transferRegistrationSettings,
        distance.isTransferRegistrationEdit,
      );

      if (!isOk) {
        errorsStore.add(DISTANCE_FORM_ERRORS, { ['self_services.transfer_registration']: response?.data?.errors });
      }
      return { type: 'save', response: isOk };
    } else {
      if (distance.transfer_registration_settings) {
        const isOk = await transferRegistrationService.delete(distanceId);
        return { type: 'delete', response: isOk };
      }
    }
  }
};

export const submitEditRegistration = async (data: any) => {
  errorsStore.clear(DISTANCE_FORM_ERRORS);
  const distance = data.distance;
  const isSelfServiceChecked = data.editorSettings.tab_options.includes('selfServices');

  if (distance) {
    const distanceId = distance.id as number;

    if (!isSelfServiceChecked) {
      const isOk = await editRegistrationService.delete(distanceId);
      return { type: 'delete', response: isOk };
    }

    if (distance.is_edit_registration_available) {
      const editRegistrationSettings = mapToEditRegistration(distance.edit_registration_settings);
      const [isOk, response] = await editRegistrationService.save(distanceId, editRegistrationSettings, distance.isEditRegistrationEdit);

      if (!isOk) {
        errorsStore.add(DISTANCE_FORM_ERRORS, { ['self_services.edit_registration']: response?.data?.errors });
        return { type: 'save', response: isOk };
      }
    } else {
      if (distance.edit_registration_settings) {
        const isOk = await editRegistrationService.delete(distanceId);
        return { type: 'delete', response: isOk };
      }
    }
    return true;
  }
};

import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

import { helperRaceService, helperDistancesService } from 'services';

import { helperDistancesStore, helperRacesStore } from 'stores';

import { loadMedical } from '../actions';

import { CFWithRegisteredUsersStore, isParticipantsPresentStore, hasCompletedOrders } from '../stores';

import { renderStepsIfCalendarRace } from '../reactions';
import { checkHasCompletedOrders } from './checkHasCompletedOrders';
import { checkIsThereParticipants } from './checkIsTherParticipants';
import { validateRaceDate } from './validateRaceDate';

async function reloadDistance() {
  const match = matchPath(history.location.pathname, ROUTES.updateDistanceRoute);

  if (!match) {
    return;
  }

  const { raceId, distanceId } = match.params as any;
  helperRaceService.loadResource(+raceId), helperDistancesService.loadRaceDistance(+distanceId, 'update');
}

async function mountUpdateDistance() {
  isParticipantsPresentStore.off();
  hasCompletedOrders.off();
  helperDistancesStore.cleanSelected();

  const match = matchPath(history.location.pathname, ROUTES.updateDistanceRoute);

  if (!match) {
    return;
  }

  const { raceId, distanceId } = match.params as any;
  await helperRaceService.loadResource(+raceId);
  await Promise.all([
    helperDistancesService.loadRaceDistance(+distanceId, 'update'),
    checkIsThereParticipants(),
    checkHasCompletedOrders(),
  ]);

  await loadMedical(helperRacesStore.selected?.organizer_id);
  validateRaceDate();
}

async function mountNewDistance(onChange: AnyFunction) {
  isParticipantsPresentStore.off();
  hasCompletedOrders.off();
  renderStepsIfCalendarRace(onChange);
}

function unmountUpdateDistance() {
  helperDistancesStore.cleanSelected();
  CFWithRegisteredUsersStore.clear();
}

export { reloadDistance, mountUpdateDistance, mountNewDistance, unmountUpdateDistance };

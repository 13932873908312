import { Box } from '@mui/material';
import { debounce } from 'lodash';
import { Observer } from 'mobx-react';
import { reloadRace } from 'modules/Races/actions';
import { RACE_FORM_ID } from 'modules/Races/constants';
import { initForm } from 'modules/Races/utils';
import React, { ReactElement, useEffect, useState } from 'react';

import { InformationNote } from 'components/InformationNote';
import { RaceLanguages as RaceLanguagesComponent } from 'components/Stepper/Step/RaceLanguages';

import { t } from 'utils/t';

import { availableLocalesStore, errorsStore, form } from 'stores';

import { StepHelperText, StepTitle } from '../Shared/StepTitle';
import { StepProps } from '../sharedProps';
import DefaultLanguageAlertDialog from './DefaultLanguageAlert';

type Props = StepProps & {};
let debouncedUpdate: any;

function RaceLanguages({ step, liveUpdate }: Props): ReactElement {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const onLanguagesSelect = async ({ selectedLanguages, defaultLocale }) => {
    if (defaultLocale) {
      const prevSelectedLanguages = form.fetch(RACE_FORM_ID, ['languagesData', 'selectedLanguages']);
      const prevDefaultLocale = form.fetch(RACE_FORM_ID, ['languagesData', 'defaultLocale']);
      form.onChange(RACE_FORM_ID, 'languagesData', { selectedLanguages, defaultLocale });
      const isOk = await liveUpdate('languagesData');
      if (!isOk) {
        setIsDialogOpen(true);
        form.onChange(RACE_FORM_ID, 'languagesData', {
          // Roll back changes made to the selected languages
          selectedLanguages: prevSelectedLanguages,
          defaultLocale: prevDefaultLocale,
        });
      }

      errorsStore.clear('UPDATE_RACE');
      return;
    }

    if (errorsStore.getAll('UPDATE_RACE')) return;

    form.onChange(RACE_FORM_ID, 'languagesData', { selectedLanguages, defaultLocale });

    // Using debounce here, user could click language checkbox faster, then server responses
    // We are using server response as source of thruth to re-init form data
    // User could click on 3 more languages, while server is processing 1, so clicked checkboxes
    // will dissapear
    liveUpdate('languagesData');
  };

  useEffect(() => {
    // Register debounced function once, on init rendering
    debouncedUpdate = debounce(() => {
      liveUpdate('languagesData');
    }, 0);

    return () => {
      errorsStore.clear('UPDATE_RACE');
      reloadRace();
      initForm();
    };
  }, []);
  return (
    <>
      <StepTitle step={step} />
      <StepHelperText step={step} />
      <DefaultLanguageAlertDialog isOpen={isDialogOpen} handleClose={handleClose} />
      <Box maxWidth={441} width={'100%'}>
        <InformationNote
          isDissmissible
          title={t.staticAsString('races.steps.languages.note')}
          body={t.staticAsString('races.steps.languages.missingTranslationsWarn')}
        />
      </Box>

      <Observer>
        {() => {
          const languagesData = form.fetch<RaceFormData>(RACE_FORM_ID)?.languagesData;
          if (!languagesData) {
            return <></>;
          }
          const errors = (): { pref_lang_code?: string[] } => {
            const pref_lang_code = errorsStore.get('UPDATE_RACE', 'pref_lang_code') || [''];
            return { pref_lang_code };
          };
          const { selectedLanguages = [], defaultLocale = 'en' } = languagesData;

          return (
            <>
              {/* <Loading action={`UPDATE_${RACE}`} /> */}
              <RaceLanguagesComponent
                errors={errors()}
                selectedLanguages={selectedLanguages}
                defaultLocale={defaultLocale}
                locales={availableLocalesStore.value}
                onLanguagesSelect={onLanguagesSelect}
              />
            </>
          );
        }}
      </Observer>
    </>
  );
}

export { RaceLanguages };

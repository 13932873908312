import { Observer } from 'mobx-react-lite';
import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'src/components/Condition';
import { Icon } from 'src/components/Icon';

import { participantsCountStore } from '../../stores';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 24px 100px;
  align-items: center;
  gap: 8px;
`;

const StyledIcon = styled(Icon)`
  height: 20px;
`;

const Info = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.palette.grey5};
`;

export const Participants = () => {
  return (
    <Observer>
      {() => {
        const { count, maxCount } = participantsCountStore.value || {};

        return (
          <Wrapper>
            <StyledIcon value='account-multiple' />
            <Info>
              <Show if={Boolean(maxCount)}>
                {count} / {maxCount}
              </Show>
              <Show if={!maxCount}>{count}</Show>
            </Info>
          </Wrapper>
        );
      }}
    </Observer>
  );
};

import { clearReceiptSettings } from 'modules/Races/actions';
import { ADDITIONAL_OPTIONS } from 'modules/Races/constants';
import React, { ReactElement } from 'react';

import { StepTitle, StepHelperText } from '../Shared/StepTitle';
import { StepProps } from '../sharedProps';
import { SingleSwitch } from './SingleSwitch';

type Props = StepProps & {};

function AdditionalOptions({ step, liveUpdate }: Props): ReactElement {
  const onStepToggle = ({ name, value }: { name: string; value: boolean }): void => {
    liveUpdate('editorSettings');
  };

  const deleteRelation = (name: string) => () => {
    if (name === 'receiptSettings') {
      clearReceiptSettings();
    }
  };
  return (
    <>
      <StepTitle step={step} />
      <StepHelperText step={step} />
      {ADDITIONAL_OPTIONS.map((tabOption) => {
        return (
          <SingleSwitch
            key={tabOption}
            relationHasData={true}
            name={`editorSettings.${tabOption}`}
            onStepToggle={onStepToggle}
            onRelationDelete={deleteRelation(tabOption)}
          />
        );
      })}
    </>
  );
}

export { AdditionalOptions };

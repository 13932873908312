export const DASHBOARD_STATES = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  REGISTRATIONS_LOADING: 'REGISTRATIONS_LOADING',
  TURNOVER_LOADING: 'TURNOVER_LOADING',
  AGE_LOADING: 'AGE_LOADING',
  PARTICIPANTS_LOADING: 'PARTICIPANTS_LOADING',
  RACE_LOADING: 'RACE_LOADING',
  RACE_SEARCH_FORM: 'RACE_SEARCH_FORM',
};

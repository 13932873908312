import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { Observer } from 'mobx-react';
import { ExistingDistanceList } from 'modules/StartList/components/ExistingDistanceLIst';
import moment from 'moment';
import React from 'react';

import { FormattedDate } from 'components/FormattedDate';
import { Icon } from 'components/Icon';

import { commonDateFormats, t } from 'utils';

import { startlistRacersStore } from '../../../../stores';

import { Buttons } from '../../../Toolbar/Buttons';

const Toolbar = ({
  distance,
  onAddNewRacer,
  onChange,
  exportFile,
  onEditDistance,
  onDeleteDistance,
}: {
  distance: DistanceType;
  onAddNewRacer: (e: React.SyntheticEvent) => void;
  onChange: (e: React.SyntheticEvent) => void;
  exportFile: (type: string) => void;
  onEditDistance: () => void;
  onDeleteDistance: () => void;
}) => {
  const distanceValue = distance.value;

  const renderPrice = (prices: Array<AnyObject>) => {
    if (prices.length === 0) {
      return '';
    }

    return prices.map((price: AnyObject) => {
      return (
        <li className='price-item' key={`price-${price.id}`}>
          <p className='price-date'>
            <FormattedDate value={price.date_from} format={commonDateFormats.shortDate} />
            {' - '}
            <FormattedDate value={price.date_to} format={commonDateFormats.shortDate} />
          </p>
          <p className='price-value'>
            {price.value}
            <span className='price-sek'>{(distance as any).raceCurrency()}</span>
          </p>
        </li>
      );
    });
  };

  return (
    <div className='toolbar racer-toolbar'>
      <div className='details'>
        <div className='distance-details'>
          <div className='distance-info'>
            <div className='date'>{moment(distanceValue?.race_date).format('DD MMM YYYY')}</div>
            {distanceValue?.location && (
              <div className='location'>
                <Icon value='location' />
                {distanceValue.location || ''}
              </div>
            )}
          </div>
        </div>
        <ExistingDistanceList distance={distance} onChange={onChange} />
        <div className='distance-prices'>
          <ul className='prices-list'>{renderPrice(distanceValue?.prices || [])}</ul>
        </div>
      </div>
      <div className='buttons'>
        <div className='add-new-button'>
          <Button onClick={onAddNewRacer} variant='contained' className='add-button'>
            <AddIcon className='icon' />
            <span className='button-title'>{t.staticAsString('racers.list.newRacer')}</span>
          </Button>
        </div>
        <Observer>
          {() => {
            return (
              <Buttons
                type='racers'
                exportListKey={'startlist.export.racers'}
                exportWavesKey={'startlist.export.waves'}
                distance={distance as any}
                exportFile={exportFile}
                onEditDistance={onEditDistance}
                onDeleteDistance={onDeleteDistance}
                totalEntries={startlistRacersStore.paginationMeta.total || 0}
                shouldHideExportButton={startlistRacersStore.values.length === 0}
              />
            );
          }}
        </Observer>
      </div>
    </div>
  );
};

export { Toolbar };

import { observer } from 'mobx-react';
import { registrationFieldsTools } from 'modules/StartList/utils';
import * as React from 'react';
import { generatePath, NavLink } from 'react-router-dom';

import { CONFIRM_POPUP_TYPES, ROUTES } from 'src/constants';

import { ConfirmationModal } from 'components/ConfirmationModal';

import { t } from 'utils';

import { helperDistancesStore } from 'stores';

import distanceService from '../../services/distanceAction';

import { SettingsMenu } from './SettingsMenu';

type Props = {
  distance: DistanceType;
  onEditSquads: () => void;
};

type State = {
  onConfirm: Function | null;
  confirmTitle: string;
  confirmBody: string;
  confirmType: string;
};

@observer
class Nav extends React.Component<Props, State> {
  distance?: DistanceType;

  state = {
    onConfirm: null,
    confirmTitle: '',
    confirmBody: '',
    confirmType: '',
  };

  onHideStartList = (id: number) => {
    const { modelSelected, selected } = helperDistancesStore;

    if (!modelSelected || !selected) {
      return;
    }

    if (!selected.show_startlist) {
      return distanceService.isHiddenStartList(selected.id, selected.show_startlist);
    }

    this.setState({
      onConfirm: () => {
        distanceService.isHiddenStartList(selected.id, selected.show_startlist);
        this._clearPopup();
      },
      confirmTitle: t.staticAsString('races.confirmPopup.mainTitle') as any,
      confirmBody: t.staticAsString('races.distance.hideStartList.confirm', { name: modelSelected.value.name }) as any,
      confirmType: CONFIRM_POPUP_TYPES.confirm,
    });
  };

  handleToggleBibsVisibility = () => {
    const { modelSelected, selected } = helperDistancesStore;
    if (!modelSelected || !selected) return;

    if (!Boolean(selected.show_bibs)) {
      return distanceService.toggleBibsVisibility(selected.id, selected.race!.id, true);
    }

    this.setState({
      onConfirm: () => {
        distanceService.toggleBibsVisibility(selected.id, selected.race!.id, false);
        this._clearPopup();
      },
      confirmTitle: t.staticAsString('races.confirmPopup.mainTitle') as any,
      confirmBody: t.staticAsString('races.distance.hideBibNumbers.confirm', { name: modelSelected.value.name }) as any,
      confirmType: CONFIRM_POPUP_TYPES.confirm,
    });
  };

  _clearPopup = () => {
    if (this.state.onConfirm) {
      this.setState({
        onConfirm: null,
      });
    }
  };

  render() {
    const { distance, onEditSquads } = this.props;
    const { onConfirm, confirmBody, confirmTitle, confirmType } = this.state;
    const distanceModel = helperDistancesStore.modelSelected;
    const raceId = distance?.race?.id || '';
    const id = distance?.id || '';
    const haveSquads = registrationFieldsTools.isSelected(distance.registration_fields || [], 'squad_id' as registrationFields);

    return (
      <div className='start-list-nav'>
        <ConfirmationModal
          open={!!onConfirm}
          onConfirm={onConfirm || (() => {})}
          onClose={this._clearPopup}
          title={confirmTitle}
          body={confirmBody}
          type={confirmType}
        />
        <NavLink to={generatePath(ROUTES.distancesRacersRoute, { raceId, id })}>{t.staticAsString('racers.list.header')}</NavLink>
        <NavLink to={generatePath(ROUTES.resultsRoute, { raceId, id })}>{t.staticAsString('racers.list.results')}</NavLink>
        <div className='nav-settings'>
          {haveSquads && (
            <button onClick={onEditSquads} className='start-list-manage-squad-button'>
              {t.staticAsString('racers.squads.manageSquads')}
            </button>
          )}

          <SettingsMenu
            distance={distanceModel}
            onHideStartList={this.onHideStartList as AnyFunction}
            handleToggleBibsVisibility={this.handleToggleBibsVisibility}
          />
        </div>
      </div>
    );
  }
}

export { Nav };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { DISTANCE_TEAM_URL, DISTANCE_TYPE } from 'src/constants';

import { action, request } from 'utils';

import { progressStore, errorsStore } from 'stores';

import { UPDATE_TEAM } from '../constants';

import { distanceStore, teamResultsStore, singleResultsStore, resultsStubStore, fieldRegisterStore } from '../stores';

import { storeData } from '../tools';
import { fieldUpdateOptions } from '../types';

class UpdateTeam {
  storeData = storeData.bind(this);

  get store(): ILoadable<ResultType, FiltersType> {
    const distanceType = this.distanceType();

    switch (distanceType) {
      case DISTANCE_TYPE.single:
        return singleResultsStore;
      case DISTANCE_TYPE.team:
        return teamResultsStore;
      default:
        return resultsStubStore;
    }
  }

  distanceType(): nil | distanceType {
    const distanceModel = distanceStore.modelSelected;

    if (!distanceModel) {
      return;
    }

    return distanceModel.value.type;
  }

  @request({ action: UPDATE_TEAM, writeError: false })
  updateRequest(distanceId: number, id: number, data: Object): any {
    const url = generatePath(DISTANCE_TEAM_URL, { id });
    return axios.patch(url, data);
  }

  @action({ action: UPDATE_TEAM })
  async update(distanceId: number, id: number, value: Object, updateOptions: fieldUpdateOptions): Promise<boolean> {
    const startRequestTimestamp = new Date();
    const { fieldId, storeOptions } = updateOptions;
    progressStore.log(fieldId, 'progress');

    const [status, response] = await this.updateRequest(distanceId, id, value);

    if (status) {
      await this.storeData(response.data.data, storeOptions);
    }

    if (!status) {
      errorsStore.add(fieldId, response.data.errors);
    }

    await progressStore.logWithPromise(fieldId, 'completed', { minRequestTime: 400, requestStartTime: startRequestTimestamp });

    if (!!status) {
      fieldRegisterStore.deactivateField(fieldId);
    }

    return status;
  }
}

export { UpdateTeam };

export const GET_RECEIPT_SETTINGS = 'GET_RECEIPT_SETTINGS';
export const UPDATE_RECEIPT_SETTINGS = 'UPDATE_RECEIPT_SETTINGS';

export const receiptSettingsFields = [
  'address',
  'city',
  'company_name',
  'country_id',
  'created_at',
  'id',
  'post_code',
  'race_id',
  'updated_at',
  'vat_number',
];

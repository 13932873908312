import { RawRegistrationsJSON, Registrations } from '../types/registrations.type';
import { mapLabels } from './common';

export function mapRegistrations(raw: RawRegistrationsJSON.Data): Registrations {
  return {
    total: raw.registrations.doc_count,
    trend: {
      current: raw.correlation.buckets.all.current.doc_count,
      previous: raw.correlation.buckets.all.previous.doc_count,
      rate: raw.correlation.buckets.all?.rate?.value ?? null,
    },
    data: raw.registrations.histogram.buckets.map((bucket) => {
      return {
        label: mapLabels(bucket.key_as_string),
        value: bucket.doc_count,
      };
    }),
  };
}

import { RADIO_AMOUNT } from './radio.constant';

export const COUPONS_FORM_ID = 'COUPONS_FORM';

export const COUPON_FORM_STATES = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  SAVING: 'SAVING',
  DISTANCES_LOADING: 'DISTANCES_LOADING',
  RACE_LOADING: 'RACE_LOADING',
  COUPON_LOADING: 'COUPON_LOADING',
};

export const COUPONS_FORM_DEFAULT_VALUE = {
  discount_percentage: RADIO_AMOUNT.value,
  addon: false,
  registration_fee: false,
};

export enum USAGE_TYPES {
  allDistances = 0,
  addons = 1,
  addonsPlusAllDistances = 2,
}

export const COUPONS_FORM_DATE_FORMAT = 'YYYY-MM-DD';

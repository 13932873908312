// Prev date - start time of the result or finish time of prev checkpoint
import { t } from 'utils';

import { Result } from 'models';

import { toastStore } from 'stores';

import { fieldRegisterStore, distanceStore } from '../stores';

function handleMissingStartTime(fieldId: string) {
  fieldRegisterStore.deactivateField(fieldId);
  const message = t.staticAsString('results.errors.missing_start_time');
  toastStore.show(message);

  return null;
}

function handleMissingPrevCheckpoint(fieldId: string, result: Result, checkpointId: number | nil) {
  fieldRegisterStore.deactivateField(fieldId);

  const distanceModel = distanceStore.modelSelected;

  if (!distanceModel) {
    return null;
  }

  const checkpointIndex = distanceModel.checkpoints.findIndex((checkpoint) => checkpoint.value.id === checkpointId);

  let missingCheckpoint: any = undefined;
  let missingCheckpointIndex: any = undefined;
  let isMissingStartTime = result.isInvalidStartTime();

  for (var i = checkpointIndex - 1; i >= 0; i--) {
    const checkpoint = distanceModel.checkpoints[i];
    const split = result.findSplitByCheckpoint(checkpoint.value.id);

    if (!split || !split.value.finish_time) {
      missingCheckpointIndex = i;
      missingCheckpoint = checkpoint;
    } else {
      break;
    }
  }

  if (isMissingStartTime && missingCheckpointIndex === 0) {
    return handleMissingStartTime(fieldId);
  }

  const title = missingCheckpoint && missingCheckpoint.name();

  const message = t.staticAsString('results.errors.missing_prev_split', { title: title });

  toastStore.show(message);

  return null;
}

export { handleMissingStartTime, handleMissingPrevCheckpoint };

import { observer } from 'mobx-react';
import * as React from 'react';
import shortid from 'shortid';

import { ROUTES } from 'src/constants';

import { NavToolbar } from 'components/NavToolbar';

import { t } from 'utils';

import { raceDetailsStore } from '../../stores';

const NavContainerImpl = () => {
  const race = raceDetailsStore.raceDetails;

  if (!race) {
    return null;
  }

  const items = [
    {
      key: shortid(),
      path: ROUTES.racesRoute,
      label: t.staticAsString('races.detail.nav.allRaces'),
    },
    {
      key: shortid(),
      label: race.value.name,
    },
  ];
  return <NavToolbar items={items} />;
};

const NavContainer = observer(NavContainerImpl);

export { NavContainer };

import { observer } from 'mobx-react';
import * as React from 'react';

import { CONFIRM_POPUP_TYPES, EXPORT_DATA_SIZES } from 'src/constants/mixed';

import { Icon } from 'components/Icon';

import { t } from 'utils';
import { appEnvControl } from 'utils/appEnvironment';

import confirmModalStore from 'stores/confirmationModal';
import locale from 'stores/locale';

type Props = {
  types: Array<string>;
  onExport(e: React.SyntheticEvent, type: string): void;
  totalEntries: number;
};

function ExportToolbarComponent({ types, onExport, totalEntries }: Props) {
  if (totalEntries === 0) return null;

  const confirmExport = (e, type) => {
    if (totalEntries <= EXPORT_DATA_SIZES[appEnvControl.currentEnvWithStub()]) return onExport(e, type);
    confirmModalStore.openModal({
      title: t.staticAsString('shared.exportConfirm.title') as any,
      body: t.staticAsString('shared.exportConfirm.body', {
        maxAmount: EXPORT_DATA_SIZES[appEnvControl.currentEnvWithStub()].toLocaleString(locale.value),
      }) as any,
      type: CONFIRM_POPUP_TYPES.confirm,
      onConfirm: () => onExport(e, type),
    });
  };

  return (
    <div className='export-toolbar'>
      <ul className='export-list'>
        {types.map((type, index) => (
          <li
            key={`${type}_${index}`}
            className='export-item'
            onClick={(e: React.SyntheticEvent) => {
              confirmExport(e, type);
            }}
          >
            <Icon value='download' className='download-icon' />
            <span className='label'>{t.staticAsString(`shared.export.${type}` as TranslationLockedKeys)}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}

export const ExportToolbar = ExportToolbarComponent;

import classNames from 'classnames';
import * as React from 'react';

import { RACE_STATUSES_INT } from 'src/constants';

import { Icon } from 'components/Icon';

import { t } from 'utils';

type Props = {
  approveStatus: number | nil;
};

export const RaceStatusChip = ({ approveStatus }: Props): React.ReactElement | null => {
  switch (approveStatus) {
    case RACE_STATUSES_INT.awaiting:
      return (
        <div className={classNames('race-status awaiting')}>
          <Icon value={'awaiting'} className='icon' />
          <p>{t.staticAsString('races.detail.awaitingRace')}</p>
        </div>
      );
    case RACE_STATUSES_INT.reject:
      return (
        <div className={classNames('race-status rejected')}>
          <Icon value={'reject'} className='icon' />
          <p>{t.staticAsString('races.detail.rejectedRace')}</p>
        </div>
      );
    case RACE_STATUSES_INT.approve:
      return (
        <div className={classNames('race-status approved')}>
          <Icon value={'approved'} className='icon' />
          <p>{t.staticAsString('races.detail.approvedRace')}</p>
        </div>
      );
    case RACE_STATUSES_INT.draft:
      return (
        <div className={classNames('race-status awaiting')}>
          <Icon value={'warning'} className='icon' />
          <p>{t.staticAsString('races.statuses.draft')}</p>
        </div>
      );
    default:
      return null;
  }
};

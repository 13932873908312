import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { IS_CUSTOM_FIELD_HAS_RACER } from 'src/constants/api';
import { request, action } from 'src/utils';

export class CustomFieldsService {
  @request({ action: shortid() })
  request(distanceId: number, customFieldId: number): any {
    const url = generatePath(IS_CUSTOM_FIELD_HAS_RACER, { distanceId, customFieldId });
    return axios.get(url);
  }

  @action({ action: shortid() })
  async isCustomFieldHasRacer(distanceId: number, customFieldId: number) {
    const [isOk, response] = await this.request(distanceId, customFieldId);

    if (isOk) {
      return { [customFieldId]: response.data.has_racers };
    }
  }
}

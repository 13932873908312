import { isEmpty } from 'lodash';

import { getRaceWavesOptions, getSyncWavesOptions, getRaceClassesOptions, getSyncClassesOptions, showClass, showWave } from '../selectors';

export const canSave = (distances: AnyObject[] = [], classes: AnyObject[] = [], waves: AnyObject[] = []): boolean => {
  // const raceClasses = getRaceClassesOptions.get();
  // const syncClasses = getSyncClassesOptions.get();
  //
  // const raceWaves = getRaceWavesOptions.get();
  // const syncWaves = getSyncWavesOptions.get();
  //
  // const shouldValidateWaves = showWave.get() && (!isEmpty(raceWaves) || !isEmpty(syncWaves));
  // const shouldValidateClasses = showClass.get() && (!isEmpty(raceClasses) || !isEmpty(syncClasses));
  //
  // if (shouldValidateWaves && isEmpty(waves)) return false;
  // if (shouldValidateClasses && isEmpty(classes)) return false;

  return !isEmpty(distances);
};

import { TableHead, TableCell, TableRow } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

export const paymentOverviewTableStyles = makeStyles({
  tableWrapper: {
    marginBottom: '48px',
  },
  totalWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalSum: {
    height: '24px',
    color: 'rgb(38, 46, 53)',
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    marginBottom: '16px',
    marginTop: '12px',
  },
});

export const StyledTableHead = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(38, 46, 53)',
  },
}))(TableHead);

export const StyledTableHeaderCell = withStyles((theme) => ({
  root: {
    color: 'rgb(255, 255, 255)',
    fontSize: '14px',
    fontWeight: 'normal',
  },
}))(TableCell);

export const StyledTableCell = withStyles((theme) => ({
  root: {
    // borderBottom: 'none',
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 16,
    },
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:last-child td': {
      borderBottom: 0,
    },
  },
}))(TableRow);

export const StyledPartSumTableRow = withStyles((theme) => ({
  root: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
  },
}))(TableRow);

export const StyledPartSumTableCell = withStyles((theme) => ({
  root: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    fontWeight: 'bold',
  },
}))(TableCell);

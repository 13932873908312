import { Result } from 'models';

import { ResultsContextType } from '../../../context';
import { formatSplitDiff } from '../../../presenters';

function showPrevSplitDiff(checkpointId: number, prevCheckpointId: nil | number, model: Result, context: ResultsContextType) {
  const splitModel = model.findSplitByCheckpoint(checkpointId);
  const prevSplitModel = model.findSplitByCheckpoint(prevCheckpointId || 0);

  return () => {
    const resultStartTime = model.value.start_time;
    const prevSplitFinishTime = prevSplitModel?.value?.finish_time;

    const startTime = prevSplitFinishTime || resultStartTime;
    const splitFinishTime = splitModel?.value?.finish_time;

    return formatSplitDiff(splitModel, startTime, splitFinishTime, model, context);
  };
}

function showResultDiff(checkpointId: number, model: Result, context: ResultsContextType): Function {
  const splitModel = model.findSplitByCheckpoint(checkpointId);

  return () => {
    const resultStartTime = model.value.start_time;
    const splitFinishTime = splitModel?.value?.finish_time;

    return formatSplitDiff(splitModel, resultStartTime, splitFinishTime, model, context);
  };
}

export { showResultDiff, showPrevSplitDiff };

import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { DISTANCE_TEAMS_URL } from 'src/constants';

import { request, action } from 'utils';

import { LOAD_UPDATE_FORM } from '../constants';

class TeamsService {
  @request({ action: shortid() })
  request(distanceId: number, params: AnyObject): any {
    const url = generatePath(DISTANCE_TEAMS_URL, { id: distanceId });
    return axios.get(url, { params });
  }

  @action({ action: LOAD_UPDATE_FORM })
  async isDistanceHasTeams(distanceId: number, params: AnyObject) {
    const [isOk, response] = await this.request(distanceId, params);

    if (isOk) {
      return Boolean(response.data.data.length);
    }

    return false;
  }
}

export { TeamsService };

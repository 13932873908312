import { localeStore } from 'stores';

import { IMPORT_HEADER_ROW } from '../constants';

function importHeaders(name: 'bib' | 'time_result') {
  const localePostfix = localeStore.value === 'sv' ? 'sv' : 'en';
  return IMPORT_HEADER_ROW[`${name}_${localePostfix}` as 'bib_en' | 'bib_sv' | 'time_result_en' | 'time_result_sv'];
}

export { importHeaders };

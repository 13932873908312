import { makeStyles } from '@mui/styles';

export const useSelectStyles = makeStyles({
  root: {},
  // TODO: fix this problematic height. Placeholder located too hight above input underline
  selectMenu: {
    height: 24,
    fontSize: 16,
  },
  // TODO: console error, check if select is not broker
  //   fullWidth: {
  //     width: '100%',
  //   },
  //   formControl: {
  //     marginTop: 7,
  //   },
});

export const useSelectOptionStyles = makeStyles({
  menuItem: {
    paddingBottom: 6,
    paddingTop: 6,
    '& p': {
      fontSize: 14,
    },
  },
});

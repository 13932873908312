import * as React from 'react';

import { t } from 'utils/t';

import { Wrapper, PageTitle, LinkFields, CouponFields, Body, ControlsEdit } from '../components';

import { REGISTRATION_LINKS_FORM_ID } from '../constants';

import { mountEdit } from '../actions';

export const Edit = () => {
  React.useEffect(() => {
    mountEdit();
  }, []);

  return (
    <Wrapper id={REGISTRATION_LINKS_FORM_ID} cleanErrorOnChange>
      <Body>
        <PageTitle>{t.staticAsString('smartLinks.editSmartLink')}</PageTitle>
        <LinkFields />
        <CouponFields />
        <ControlsEdit />
      </Body>
    </Wrapper>
  );
};

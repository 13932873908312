import { cloneDeep } from 'lodash';

export const mapErrorRefund = (data: AnyObject): RawError => {
  const response = cloneDeep(data);
  let errors: AnyObject = [];

  if (response.delete_source_account) {
    errors.deleteSourceAccount = response.delete_source_account;
  }

  if (response.source_email) {
    errors.sourceEmail = response.source_email;
  }

  if (response.target_email) {
    errors.targetEmail = response.target_email;
  }

  if (response.amount) {
    errors.amount = response.amount;
  }

  if (response.id) {
    errors.refundDistance = response.id;
    errors.order = response.id;
  }

  return errors;
};

// TODO, DEPRECATED, remove!!!!!
// Deprecated, zachem minaesh tuta bohatitoho
// Napisano zh, remove. A ti supportish shos tut
//
// Opiat?!?!? idi zvidsi
import classNames from 'classnames';
import { isEmpty, compact, flatten } from 'lodash';
import moment from 'moment';
import * as React from 'react';

import { ImprovedDatePicker } from 'components/Form';

import { time } from 'utils';

type FilterValue = nil | string | moment.Moment;
type Value = [FilterValue, FilterValue];
type StateValue = [nil | moment.Moment, nil | moment.Moment];

type Props = {
  name: string;
  value: Value;
  label: string;
  applyFilter?: (
    arg0: {
      [K in string]: Value;
    },
  ) => void;
  updateSearchHandlers?: (
    handlers: {
      [K in string]: string;
    },
  ) => void;
  disableSingleDate?: boolean;
} & FilterProps;

type State = {
  value: StateValue;
};

class DateRangeFilter extends React.Component<Props, State> {
  // @ts-ignore
  id: string;

  state: State = {
    value: ((): StateValue => {
      const { value } = this.props;

      return this.prepareValueForOnChange(this.parse(value));
    })(),
  };

  prepareValueForOnChange(value: Value): StateValue {
    let parsedValue = flatten([value]);

    if (isEmpty(compact(parsedValue))) {
      return [null, null];
    }

    const [from, to] = parsedValue;
    let momentFrom = moment(from || (null as any));
    let momentTo = moment(to || (null as any));

    if (momentFrom.isValid()) {
      momentFrom.startOf('day');
    } else {
      momentFrom = null as any;
    }

    if (momentTo.isValid()) {
      momentTo.endOf('day');
    } else {
      momentTo = null as any;
    }

    if (this.props.disableSingleDate && momentFrom.isSame(momentTo, 'day')) {
      momentTo = null as any;
    }

    return [momentFrom, momentTo];
  }

  parse(value: Value): Value {
    const [rawFrom, rawTo] = value || [];

    let from: moment.Moment | undefined = time(rawFrom as any, 'datetime')
      .ifIsValid()
      ?.parse().val;
    let to: moment.Moment | undefined = time(rawTo as any, 'datetime')
      .ifIsValid()
      ?.parse().val;

    return [from, to];
  }

  compose(value: StateValue): Value {
    const values = flatten([value]);

    const [from, to] = values;

    let parsedFrom: any = null;
    let parsedTo: any = null;

    if (from) {
      parsedFrom = time.workTime(from, 'datetime').compose().val;
    }

    if (to) {
      parsedTo = time.workTime(to, 'datetime').compose().val;
    }

    return [parsedFrom, parsedTo];
  }

  onChange = (value: Value) => {
    const parsedValue = this.prepareValueForOnChange(value);
    this.setState({ value: parsedValue }, this._applyNewHandlers);
  };

  applyFilter = () => {
    const { name, applyFilter } = this.props;
    const { value } = this.state;
    const composedValue = this.compose(value);

    // @ts-ignore
    applyFilter({ [name]: composedValue });
  };

  clear = () => {
    this.onChange([null, null]);
  };

  _applyNewHandlers = () => {
    const [startDate, endDate] = this.state.value;
    const { updateSearchHandlers, name } = this.props;

    const isFromPresent = !!startDate;
    const isToPresent = !!endDate;

    let handler = 'between';

    if (isFromPresent && !isToPresent) {
      handler = '>=';
    }

    if (!isFromPresent && isToPresent) {
      handler = '<=';
    }

    // @ts-ignore
    updateSearchHandlers({ [name]: handler });
  };

  render() {
    const [startDate, endDate] = this.state.value;
    return (
      <div className='filter-container date-range-picker-filter'>
        <div className='form-group'>
          <ImprovedDatePicker
            startDate={startDate}
            endDate={endDate}
            isSingleDate={false}
            onChange={this.onChange as any}
            isSingleMonthRange={false}
          />
        </div>
        <div className='filter-control'>
          <span className={classNames('clear', { hidden: !(startDate || endDate) })} onClick={this.clear}>
            Clear
          </span>
          <span className='apply' onClick={this.applyFilter}>
            Apply
          </span>
        </div>
      </div>
    );
  }
}

export { DateRangeFilter };

import { openIntegrationForm } from 'modules/IntegrationForm/actions';

import { t } from 'utils';

import { toastStore } from 'stores';

import { integrationsService } from '../services';

import { raceStore, integrationsStore } from '../stores';

export const onCreateIntegration = (): void => {
  const raceId = Number(raceStore.value?.id);
  const limitReached = Number(raceStore.value?.distances_count) * 2 <= Number(integrationsStore.value?.length);

  if (limitReached) {
    toastStore.show(t.staticAsString('integrations.errors.limitReached'));
    return;
  }

  openIntegrationForm(raceId, null, () => {
    integrationsService.getIntegrations(raceId);
  });
};

import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';

import { withProgressSpinner } from 'hocs';

import { Show } from 'components/Condition';
import { TableHeaderItem } from 'components/Filter/TableHeaderItem';
import { Table as MaterialTable } from 'components/Table';
import { TableEmptyListNotification } from 'components/TableEmptyListNotification';

import { t } from 'utils';

import { Row } from '../../components';

import { LOAD_PAYMENTS } from '../../constants';

import { Context } from '../../context';
import { ContextType } from '../../context';

type Props = {
  columns: Array<string>;
  columnWidth: {
    [K in string]: number | string;
  };
};

@withProgressSpinner(LOAD_PAYMENTS)
@observer
export class Table extends React.Component<Props> {
  context: ContextType = null as any;

  static contextType = Context;

  renderHead = (): Array<React.ReactNode> => {
    const { columns, columnWidth } = this.props;

    return columns.map((column) => {
      const width = columnWidth[column];
      const className = `cell-${column}`;
      const translation = `payments.list.table.${column}`;

      return (
        <TableHeaderItem
          key={`head:${column}`}
          name={column}
          width={Number(width)}
          className={className}
          label={t(translation as TranslationLockedKeys) as any}
        />
      );
    });
  };

  renderRows = (): Array<React.ReactNode> | React.ReactNode => {
    const { paymentsStore } = this.context.payments.stores;
    const values = paymentsStore.modelValues || [];

    return values.map((model) => <Row key={`row:${model.value.id}`} item={model} />);
  };

  render() {
    const { paymentsStore } = this.context.payments.stores;

    const listOrders = paymentsStore.modelValues;

    return (
      <React.Fragment>
        <Show if={!isEmpty(listOrders)}>
          <MaterialTable className='table-list'>
            <TableHead>
              <TableRow>{this.renderHead()}</TableRow>
            </TableHead>
            <TableBody>{this.renderRows()}</TableBody>
          </MaterialTable>
        </Show>
        <Show if={isEmpty(listOrders)}>
          <TableEmptyListNotification text={t.staticAsString('payments.noCreatedOrders' as TranslationLockedKeys)} />
        </Show>
      </React.Fragment>
    );
  }
}

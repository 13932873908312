import { OrderDetails } from 'modules/Payments/models';
import * as React from 'react';

import { t } from 'utils';

type Props = {
  value: nil | OrderDetails;
};

export function AccountInfo({ value }: Props) {
  if (!value) {
    return null;
  }
  const jsonValue = value.value;

  return (
    <div className='account-info-list payments-detail'>
      <div className='info-title'>
        <p>{t.staticAsString('payments.detail.accountInfo.title')}</p>
      </div>
      <div className='info-body'>
        <div className='info-item'>
          <p className='info-label'>{t.staticAsString('payments.detail.accountInfo.name')}</p>
          <p className='info-value'>{value.customerFullName()}</p>
        </div>
        <div className='info-item'>
          <p className='info-label'>{t.staticAsString('payments.detail.accountInfo.email')}</p>
          <p className='info-value'>{jsonValue.customer?.email}</p>
        </div>
        <div className='info-item'>
          <p className='info-label'>{t.staticAsString('payments.detail.accountInfo.birthday')}</p>
          <p className='info-value'>{jsonValue.customer?.birthday}</p>
        </div>
      </div>
    </div>
  );
}

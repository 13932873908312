import OpenIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { Show } from 'components/Condition';

import { ResultsContext } from '../../context';
import { toolTipStyles } from './styles';

type Props = {
  link: undefined | string;
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
};

function ActivityTooltip(props: Props) {
  const isSource = !!props.link;
  const classes = toolTipStyles();

  const onLinkOpen = () => {
    window.open(props.link);
  };

  const context = React.useContext(ResultsContext);
  const { isCumulativeDistance } = context.results.derivations;

  return (
    <Observer>
      {() => {
        const isCumulative = isCumulativeDistance.get();

        return (
          <Tooltip
            title={
              <React.Fragment>
                <Box className={classes.root}>
                  <Show if={!isCumulative}>
                    <span className={classes.link} onClick={props.onClick}>
                      Edit link
                    </span>
                  </Show>

                  <Show if={isSource}>
                    <Show if={!isCumulative}>
                      <Box mx={1} className={classes.separator} />
                    </Show>
                    <OpenIcon className={classes.icon} onClick={onLinkOpen} />
                  </Show>
                </Box>
              </React.Fragment>
            }
            placement='bottom'
            disableHoverListener={isCumulative && !isSource}
          >
            <div>{props.children}</div>
          </Tooltip>
        );
      }}
    </Observer>
  );
}

export { ActivityTooltip };

import 'lodash';
import { getMinAmountByCurrency } from 'modules/Distances/utils';

import { t } from 'utils';

import { helperRacesStore } from 'stores';

const customFields = () => {
  const raceCurrency = helperRacesStore.selected?.currency?.iso_code;
  const minAmount = getMinAmountByCurrency(raceCurrency);
  return {
    _validation: (targetObject: CustomFieldType) => {
      const imageValidation =
        targetObject?.type === 'extra'
          ? {
              image: {
                presence: {
                  message: () => t.staticAsString('shared.errors.presence'),
                  allowEmpty: false,
                },
              },
            }
          : {};

      return {
        ...imageValidation,
        name: {
          presence: {
            message: () => t.staticAsString('shared.errors.presence'),
            allowEmpty: false,
          },
        },
        helper_text: {
          length: {
            maximum: 255,
          },
        },
        type: {
          presence: {
            message: () => t.staticAsString('shared.errors.presence'),
            allowEmpty: false,
          },
        },
        is_required: {
          presence: {
            message: () => t.staticAsString('shared.errors.presence'),
            allowEmpty: false,
          },
          inclusion: {
            within: [0, 1],
            message: () => t.staticAsString('shared.errors.presence'),
          },
        },
      };
    },
    values: {
      _validation: {
        value: {
          presence: {
            message: () => t.staticAsString('shared.errors.presence'),
            allowEmpty: false,
          },
        },
        price: {
          numericality: (_value) => {
            return {
              greaterThanOrEqualTo: Boolean(+_value) ? minAmount : 0,
              message: () =>
                t.staticAsString('shared.errors.greaterThanMinAmount', {
                  min: minAmount,
                  currency: raceCurrency,
                }),
            };
          },
        },
        vat_percents: {
          presence: (_value: number | null, object: AnyObject) => {
            if (+object.price) {
              return {
                message: () => {
                  const error = t.staticAsString('distances.steps.customFieldsForm.vat_percents_required');

                  return `^${error}`;
                },
                allowEmpty: false,
              };
            }

            return null;
          },
        },
      },
    },
  };
};

export { customFields };

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import Cropper from 'react-cropper';

import { IMAGE_UPLOAD, RACE_IMAGE_ASPECT_RATIO } from 'src/constants';

import { Checkbox } from 'components/Fields';

import { synchronousValidation, t } from 'utils';

import { errorsStore } from 'stores';

import { ImageField } from './ImageField';
import { View } from './View';
import { imageWithCrop as constrains } from './constrains';

type Props = FieldBaseProps & {
  additional?: TextFieldAdditionalProps;
  errors?: Array<string>;
  onChange: (arg0: { name: string; value: nil | Blob }) => void;
  value: Blob | string | null;
  name: string;
  fileName?: string;
  action: string;
};

type State = {
  originalImage?: string;
  originalImageBlob?: Blob;
  isModalOpen: boolean;
  croppedData: string;
  savingEnabled: boolean;
};

const MIN_HEIGHT = 500;
const MIN_WIDTH = 500;

@observer
class ImageFieldWithCrop extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.debouncedCrop = debounce(this._crop, 50);
  }

  debouncedCrop: (event: Cropper.CropEvent) => void;
  declare refs: any;

  state = {
    // Blob link
    originalImage: '',
    // Blob
    isModalOpen: false,
    croppedData: '',
    savingEnabled: false,
  };

  static defaultProps = {
    action: IMAGE_UPLOAD,
  };

  onChangeImageField = ({ name, value }: { name: string; value: nil | Blob }) => {
    if (value) {
      const image = URL.createObjectURL(value);
      const isValid = !!this.validate(value);

      this.setState({
        ...this.state,
        isModalOpen: isValid,
        originalImage: image,
        originalImageBlob: value,
      });

      return;
    }

    this.setState(
      {
        ...this.state,
        originalImage: '',
        croppedData: '',
      },
      this.onChange,
    );
  };

  onClose = () => {
    this.setState({
      ...this.state,
      isModalOpen: false,
    });
  };

  _crop = () => {
    const croppedData = this.refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.7);

    this.setState({
      ...this.state,
      croppedData,
    });
  };

  onChange = async () => {
    const { additional, onChange, name } = this.props;
    const { croppedData } = this.state;

    let resp: any = null;
    let value = null;

    if (croppedData) {
      resp = await fetch(croppedData);
      value = resp && (await resp.blob());
    }

    onChange({
      name,
      value,
    });

    if (additional?.liveUpdate) {
      additional.liveUpdate(name);
    }

    this.onClose();
  };

  errors(): Array<string> {
    if (this.props.errors) {
      return this.props.errors;
    } else {
      const { action } = this.props;
      const apiErrors = errorsStore.errors.api[action];
      const validationErrors = errorsStore.errors.validation[action];

      if (validationErrors) {
        return validationErrors.flatErrors();
      }
      if (apiErrors) {
        return apiErrors.flatErrors();
      }
    }

    return [];
  }

  @synchronousValidation({ constrains, action: IMAGE_UPLOAD })
  validate(value: Blob): any {
    return { image: value };
  }

  onChangeCheckbox = (e: React.ChangeEventHandler | any) => {
    this.setState({
      ...this.state,
      savingEnabled: e.target.checked,
    });
  };

  renderLabel = () => {
    return (
      <>
        <span style={{ fontWeight: 'bold' }}>{t.staticAsString('imageCrop.checkboxLabel.reminder')}</span>
        &nbsp;
        <span>{t.staticAsString('imageCrop.checkboxLabel.text')}</span>
      </>
    );
  };

  render() {
    const { additional = {}, value, fileName } = this.props;

    const { originalImage, isModalOpen } = this.state;
    return (
      <React.Fragment>
        <Dialog open={isModalOpen} onClose={this.onClose}>
          <DialogContent>
            <div style={{ minWidth: MIN_WIDTH, minHeight: MIN_HEIGHT }}>
              <Cropper
                minContainerWidth={MIN_WIDTH}
                minContainerHeight={MIN_HEIGHT}
                style={{ height: '550px', width: '100%' }}
                ref='cropper'
                viewMode={1}
                src={originalImage}
                crop={this.debouncedCrop}
                aspectRatio={RACE_IMAGE_ASPECT_RATIO.ratio}
              />
            </div>
          </DialogContent>
          <DialogActions style={{ display: 'block', padding: '8px 24px' }}>
            <Checkbox name='copyrights' onChange={this.onChangeCheckbox} value={this.state.savingEnabled} label={this.renderLabel()} />
            <div style={{ float: 'right', marginTop: '5px' }}>
              <Button onClick={this.onChange} color='primary' disabled={!this.state.savingEnabled}>
                Save
              </Button>
              <Button onClick={this.onClose} color='primary' autoFocus>
                Close
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        <ImageField
          fileName={fileName}
          value={value}
          name='icon'
          View={View}
          label={''}
          errors={this.errors()}
          onChange={this.onChangeImageField}
          handleDeleteImage={additional.handleDeleteImage}
        />
      </React.Fragment>
    );
  }
}

export { ImageFieldWithCrop };

import { cloneDeep } from 'lodash';

export const mapToConfirmBody = (data: AnyObject): AnyObject => {
  const form = cloneDeep(data);

  return {
    sourceEmail: form.sourceEmail?.value || '',
    sourceEmailName: form.sourceEmail?.key || '',
    targetEmail: form.targetEmail?.value || '',
    targetEmailName: form.targetEmail?.key || '',
  };
};

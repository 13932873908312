import shortId from 'shortid';

export const INTEGRATION_TABLE_HEAD = [
  {
    id: shortId(),
    name: 'actions',
    style: {
      minWidth: 80,
      maxWidth: 80,
      width: 80,
    },
  },
  {
    id: shortId(),
    name: 'webhook_url',
    style: {
      width: '50%',
      maxWidth: 1,
    },
  },
  {
    id: shortId(),
    name: 'date_range',
    style: {
      width: '50%',
    },
  },
  {
    id: shortId(),
    name: 'status',
    style: {
      minWidth: 80,
      maxWidth: 80,
      width: 80,
    },
  },
];

import { computed, makeObservable, override } from 'mobx';

import { Distance as DistanceModel } from 'models';

import { HelperDistances } from 'stores';

class Distance extends HelperDistances {
  static defaultFilters = {};

  declare filters: FiltersType;

  declare resourceParams: FiltersType;

  constructor() {
    super();

    makeObservable(this);
    this.filters = Distance.defaultFilters;
    this.resourceParams = { with: 'prices;waves;classes;checkpoints;registration_fields;goal;disciplines;race.distances' };
  }

  @computed
  get hasClasses() {
    if (!this.modelSelected) {
      return false;
    }

    return !!this.modelSelected.classes.length;
  }

  @computed
  get hasWaves() {
    if (!this.modelSelected) {
      return false;
    }

    return !!this.modelSelected.waves.length;
  }

  @computed
  get hasDisciplines() {
    if (!this.modelSelected) {
      return false;
    }

    return !!this.modelSelected.disciplines.length;
  }

  @computed
  get hasCheckpoints() {
    if (!this.modelSelected) {
      return false;
    }

    return !!this.modelSelected.checkpoints.length;
  }

  @override
  get modelSelected() {
    if (!this.selected) {
      return null;
    }

    return new DistanceModel(this.selected);
  }

  @override
  async addSelectedValue(value: DistanceType): Promise<any> {
    this.selected = value;
  }

  @override
  clearFilters() {
    super.clearFilters();
    this.filters = Distance.defaultFilters;
  }

  // TODO: shall we add label option to phrase?
  valuesForSelect(): Array<SelectOption> {
    return this.modelValues.map<SelectOption>((value) => ({
      key: value.value.id,
      value: value.value.id,
      label: value.value.name || '',
      labelOption: `${value.raceLength() || 0} km`,
    }));
  }
}

export { Distance, Distance as DistanceStore };

import { isUndefined, toString } from 'lodash';
import * as React from 'react';

import { DateField, NumberField, AutocompleteSelect } from 'components/Form/Fields';

import { gender } from 'utils';

import { countriesStore } from 'stores';

import { singleFields } from '../../../constants';

import { handleMissingStartTime, activityLinkCorrector } from '../../../actions';

import { prepareForEdit, isEmptyDate } from '../../../tools';
import { GenerateFieldEdit } from '../../../types';
import { generateField } from '../../Fields';

const FIELD_EDIT = {
  [singleFields.time_result]: function (model, _context) {
    return (onChange, updatedValue, onBlur: BlurEvent, error) => {
      const { value } = model;
      const diff = prepareForEdit.duration(value.start_time, value.finish_time);

      const passedValue = isUndefined(updatedValue) && diff !== 0 ? diff : updatedValue;
      const fieldId = model.generateFieldId('time_result');

      if (isEmptyDate(value.start_time)) {
        return handleMissingStartTime(fieldId);
      }

      return generateField('duration', passedValue, onChange, onBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [singleFields.start_time]: function (model, _context) {
    return (onChange, updatedValue, onBlur: BlurEvent, error) => {
      const { value } = model;
      const startTime = prepareForEdit.dateTime(value.start_time);
      const passedValue = isUndefined(updatedValue) ? startTime : updatedValue;
      const fieldId = model.generateFieldId('start_time');

      return generateField('datetime', passedValue, onChange, onBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [singleFields.finish_time]: function (model, _context) {
    return (onChange, value, onBlur: BlurEvent, error) => {
      const finishTime = prepareForEdit.dateTime(model.value.finish_time);
      const passedValue = isUndefined(value) ? finishTime : value;
      const fieldId = model.generateFieldId('finish_time');

      return generateField('datetime', passedValue, onChange, onBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [singleFields.activity_link]: function (model, _context) {
    return (onChange, value, onBlur: BlurEvent, error) => {
      const passedValue = isUndefined(value) ? model.value.activity_link : value;
      const fieldId = model.generateFieldId('activity_link');

      const autoFixOnBlur = () => activityLinkCorrector.fixOnBlur(passedValue, onChange, onBlur);

      return generateField('text', passedValue, onChange, autoFixOnBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [singleFields.distance_logged]: function (model, _context) {
    return (onChange, value, onBlur: BlurEvent, error) => {
      const passedValue = isUndefined(value) ? model.value.distance_logged : value;
      const fieldId = model.generateFieldId('distance_logged');

      const currentMaxWidth = toString(model.value.distance_logged).length;
      const staticMaxWidth = 10;

      return (
        <NumberField
          value={passedValue}
          onChange={onChange}
          name={fieldId}
          id={fieldId}
          label=''
          error={error}
          additional={{ onBlur }}
          maxWidth={currentMaxWidth > staticMaxWidth ? currentMaxWidth : staticMaxWidth}
        />
      );
    };
  } as GenerateFieldEdit,

  [singleFields.distance_logged_at]: function (model, _context) {
    return (onChange, value, onBlur: BlurEvent, error) => {
      const passedValue = isUndefined(value) ? prepareForEdit.date(model.value.distance_logged_at) : value;
      const fieldId = model.generateFieldId('distance_logged_at');

      return (
        <DateField value={passedValue} onChange={onChange} name={fieldId} id={fieldId} label='' error={error} additional={{ onBlur }} />
      );
    };
  } as GenerateFieldEdit,

  [singleFields['racer.bib_number']]: function (model, _context) {
    return (onChange, changedValue, onBlur: BlurEvent, error) => {
      const { racer } = model;
      if (!racer) {
        return null;
      }

      const value = racer?.value?.bib_number;
      const passedValue = isUndefined(changedValue) ? value : changedValue;
      const fieldId = racer.generateFieldId('bib_number') || '';

      return generateField('text', passedValue, onChange, onBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [singleFields['racer.firstname']]: function (model, _context) {
    return (onChange, changedValue, onBlur: BlurEvent, error) => {
      const { racer } = model;
      if (!racer) {
        return null;
      }

      const value = racer.value.firstname;
      const passedValue = isUndefined(changedValue) ? value : changedValue;
      const fieldId = racer.generateFieldId('firstname') || '';

      return generateField('text', passedValue, onChange, onBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [singleFields['racer.lastname']]: function (model, _context) {
    return (onChange, changedValue, onBlur: BlurEvent, error) => {
      const { racer } = model;
      if (!racer) {
        return null;
      }

      const value = racer.value.lastname;
      const passedValue = isUndefined(changedValue) ? value : changedValue;
      const fieldId = racer.generateFieldId('lastname') || '';

      return generateField('text', passedValue, onChange, onBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [singleFields['racer.union']]: function (model, _context) {
    return (onChange, changedValue, onBlur: BlurEvent, error) => {
      const { racer } = model;
      if (!racer) {
        return null;
      }

      const value = racer.value.union;
      const passedValue = isUndefined(changedValue) ? value : changedValue;
      const fieldId = racer.generateFieldId('union') || '';

      return generateField('text', passedValue, onChange, onBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [singleFields['racer.gender']]: function (model, _context) {
    return (onChange, changedValue, onBlur: BlurEvent, error) => {
      const { racer } = model;
      if (!racer) {
        return null;
      }

      const options = gender.options();
      const selectedOption = toString(model.racer?.value?.gender);
      const passedValue = isUndefined(changedValue) ? selectedOption : changedValue;
      const fieldId = racer.generateFieldId('gender') || '';

      return generateField('select', passedValue, onChange, onBlur, fieldId, error, options);
    };
  } as GenerateFieldEdit,

  [singleFields['racer.birthday']]: function (model, _context) {
    return (onChange, value, onBlur: BlurEvent, error) => {
      const { racer } = model;
      if (!racer) {
        return null;
      }

      const passedValue = isUndefined(value) ? prepareForEdit.date(racer.value.birthday) : value;
      const fieldId = model.generateFieldId('birthday');

      return (
        <DateField value={passedValue} onChange={onChange} name={fieldId} id={fieldId} label='' error={error} additional={{ onBlur }} />
      );
    };
  } as GenerateFieldEdit,

  [singleFields['racer.country_id']]: function (model, context, intl) {
    return (onChange, changedValue, onBlur: BlurEvent, error) => {
      const { racer } = model;
      if (!racer) {
        return null;
      }

      const options: any = countriesStore.valuesForCountryDropdown();
      const selectedOption = options.find((el) => el.value === model.racer?.value?.country_id);

      const passedValue = isUndefined(changedValue) ? selectedOption : changedValue;
      const fieldId = racer.generateFieldId('country_id') || '';

      return (
        <AutocompleteSelect
          value={passedValue}
          onChange={onChange}
          name={fieldId}
          id={fieldId}
          label=''
          error={error}
          additional={{
            onBlur,
            options,
            disableClearable: true,
          }}
        />
      );
    };
  } as GenerateFieldEdit,

  [singleFields['racer.nationality_id']]: function (model, context, intl) {
    return (onChange, changedValue, onBlur: BlurEvent, error) => {
      const { racer } = model;
      if (!racer) {
        return null;
      }

      const options: any = countriesStore.valuesForNationalityDropdown();
      const selectedOption = options.find((el) => el.key === model.racer?.value?.nationality_id);

      const passedValue = isUndefined(changedValue) ? selectedOption : changedValue;
      const fieldId = racer.generateFieldId('nationality_id') || '';

      return (
        <AutocompleteSelect
          value={passedValue}
          onChange={onChange}
          name={fieldId}
          id={fieldId}
          label=''
          error={error}
          additional={{
            onBlur,
            options,
            disableClearable: true,
          }}
        />
      );
    };
  } as GenerateFieldEdit,

  [singleFields['racer.class_id']]: function (model, context) {
    return (onChange, changedValue, onBlur: BlurEvent, error) => {
      const { racer } = model;
      if (!racer) {
        return null;
      }

      const distanceModel = context.results.stores.distanceStore.modelSelected;
      const options = (distanceModel && distanceModel.relationForSelect('classes')) || [];
      const selectedOption = racer?.value?.class_id;
      const passedValue = isUndefined(changedValue) ? selectedOption : changedValue;
      const fieldId = racer.generateFieldId('class_id') || '';

      return generateField('select', passedValue, onChange, onBlur, fieldId, error, options);
    };
  } as GenerateFieldEdit,

  [singleFields['racer.wave_id']]: function (model, context) {
    return (onChange, changedValue, onBlur: BlurEvent, error) => {
      const { racer } = model;
      if (!racer) {
        return null;
      }

      const distanceModel = context.results.stores.distanceStore.modelSelected;
      const options = (distanceModel && distanceModel.relationForSelect('waves')) || [];
      const selectedOption = racer?.value?.wave_id;
      const passedValue = isUndefined(changedValue) ? selectedOption : changedValue;
      const fieldId = racer.generateFieldId('wave_id') || '';

      return generateField('select', passedValue, onChange, onBlur, fieldId, error, options);
    };
  } as GenerateFieldEdit,

  [singleFields['racer.discipline_id']]: function (model, context, intl) {
    return (onChange, changedValue, onBlur, error) => {
      const { racer } = model;
      if (!racer) {
        return null;
      }

      const distanceModel = context.results.stores.distanceStore.modelSelected;
      const options = (distanceModel && distanceModel.relationForSelect('disciplines')) || [];
      const selectedOption = racer?.value?.discipline_id;
      const passedValue = isUndefined(changedValue) ? selectedOption : changedValue;
      const fieldId = racer.generateFieldId('discipline_id') || '';

      return generateField('select', passedValue, onChange, onBlur, fieldId, error, options);
    };
  } as GenerateFieldEdit,
};

export { FIELD_EDIT };

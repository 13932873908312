import { omit } from 'lodash';
import { smartLinkDetailsService } from 'modules/SmartLinks/SmartLinkDetails/services/smartLinksDetails.service';

import { ROUTES } from 'src/constants';
import { form, toastStore } from 'src/stores';

import { validate } from 'utils/core';
import { history } from 'utils/history';
import { t } from 'utils/t';

import { REGISTRATION_LINKS_FORM_ID } from '../constants';

import { getToken, mapBEToForm, mapFormToBE } from '../utils';

import { linksService } from '../services';

import { linkStore } from '../stores';

import { smartLinksFormValidation } from '../validations';

export const handleSubmitEditLink = async () => {
  const token = getToken();
  const data = form.fetchWithoutObservable(REGISTRATION_LINKS_FORM_ID) as any;
  const withDiscount = Boolean(data.discount);
  const isValid = validate(REGISTRATION_LINKS_FORM_ID, data, smartLinksFormValidation(withDiscount) as any);
  if (!isValid) return;

  const mappedData = mapFormToBE(data);
  const isOk = await linksService.updateSmartLink(token, mappedData);
  if (isOk) {
    toastStore.show(t.staticAsString('smartLinks.formUpdated', { name: mappedData.name }));
    history.push(ROUTES.smartLinks);
  }
};

export const getLinkData = async () => {
  const token = getToken();
  const linkData = await smartLinkDetailsService.loadSmartLink(token);
  const formData = await mapBEToForm(linkData);

  if (!formData.race_id) {
    return;
  }
  const formDataWithoutRace = omit(formData, ['race_id']);

  form.onChange(REGISTRATION_LINKS_FORM_ID, 'race_id', formData.race_id);
  form.merge(REGISTRATION_LINKS_FORM_ID, formDataWithoutRace);
  form.onChange(REGISTRATION_LINKS_FORM_ID, 'wave_id', formData.wave_id);
  form.onChange(REGISTRATION_LINKS_FORM_ID, 'max_usages', formData.max_usages);

  linkStore.set(formData);
};

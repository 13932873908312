import { Button } from '@mui/material';
import classnames from 'classnames';
import { omit } from 'lodash';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import { CALENDAR_RACE_STEPS_ARRAY, RACE_FORM_ID } from 'modules/Races/constants';
import { mapOrganizerToContacts } from 'modules/Races/mapper';
import { organizerStore } from 'modules/Races/stores';
import { getOrganizer } from 'modules/Races/utils';
import { isCalendarRaceType } from 'modules/Races/utils/isCalendarRaceType';
import { getRaceValidationSchema } from 'modules/Races/validations';
import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { Form } from 'components/Form';
import { Loading } from 'components/Loading';
import VerticalStepper from 'components/Stepper/VerticalStepper';

import { validate } from 'utils/core';
import { t } from 'utils/t';

import { form, localeStore, progressStore, sessionStore } from 'stores';
import errors from 'stores/errors';

import { LOAD_FORM, NEW_RACE_STEPS_ARRAY } from '../../constants';

import { mount, scrollToErrorField, unmount } from '../../actions';

import createService from '../../services/newRace';

import { Toolbar } from '../Shared/Toolbar';
import { GeneralSettings, RACE_ERRORS_HASH, RACE_STEPS } from '../Steps';
import { formStyles } from '../styles';

interface Props {}

function NewRace(): ReactElement {
  const classes = formStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isVerified = sessionStore.isOrganizerVerified;
  const stepsArray = isCalendarRaceType.get() ? CALENDAR_RACE_STEPS_ARRAY : NEW_RACE_STEPS_ARRAY;
  const history = useHistory();

  useEffect(() => {
    const load = async () => {
      await mount.new();
      // Form initialization
      const commissions = organizerStore.value?.commissions;
      let currency_id = null;
      if (commissions && commissions.length > 0) {
        const first = commissions[0];
        currency_id = first.currency_id;
      }
      form.merge(RACE_FORM_ID, {
        currency_id,
        pref_lang_code: localeStore.value,
      });
      setIsLoading(false);
    };
    load();
    return () => {
      unmount();
    };
  }, []);

  const onSubmit = () => {
    setIsSubmitting(true);
    const values = form.fetch<AnyObject>(RACE_FORM_ID);
    const isCalendarRace = isCalendarRaceType.get();
    const isValid = validate(RACE_FORM_ID, Object.assign(toJS(values)), getRaceValidationSchema(isCalendarRace) as any);
    if (isValid) {
      createService.create(preProcessData(toJS(values), isCalendarRace));
    } else {
      setIsSubmitting(false);
    }
    scrollToErrorField();
  };

  const preProcessData = (values: AnyObject, isCalendarRace: boolean) => {
    const organizer: OrganizerType | nil = sessionStore.isOrganizer ? organizerStore.value : getOrganizer(values.organizer_id.value);

    const image = values.image;

    let data: AnyObject = { ...omit(values, 'image') };

    if (isCalendarRace) {
      data.pref_lang_code = 'en';
    }

    if (organizer) {
      data.contacts = mapOrganizerToContacts(organizer);
    }

    if (!data.location?.country) {
      delete data.location;
    }

    if (sessionStore.isOrganizer) {
      delete data.organizer_id;
    }

    if (!!data.organizer_id) {
      data.organizer_id = (data.organizer_id as AnyObject).key;
    }

    if (!!data.sport_id) {
      data.sport_id = (data.sport_id as AnyObject).key;
    }

    Object.assign(data, {
      [data.pref_lang_code]: { name: data.name, ...(!!data.description ? { description: data.description } : {}) },
    });
    delete data.description;
    delete data.name;

    return { data, image, onChangeCallback: () => {} };
  };

  const computeErrors = () => {
    return stepsArray.filter((step) => RACE_ERRORS_HASH[step].some((k: string) => k in (errors.getAll(RACE_FORM_ID) ?? {})));
  };

  if (progressStore.isLoading(LOAD_FORM)) {
    return <Loading action={LOAD_FORM} />;
  }

  return (
    <div className={classnames('content', ' main-distance-form', { 'with-banner': !isVerified })}>
      <Toolbar />
      <div className='form-wrap'>
        <Observer
          render={() => (
            <VerticalStepper
              stepModel='races'
              step={{ value: RACE_STEPS.raceDetails, index: 0 }}
              steps={stepsArray}
              disableIndices={[]}
              onChange={() => {}}
              errors={computeErrors()}
            />
          )}
        />
        <Form disableAutofill id={RACE_FORM_ID} className='distance-form new-distance'>
          <GeneralSettings liveUpdate={() => Promise.resolve(true)} step={RACE_STEPS.raceDetails} />
          <div className={classes.controls}>
            <div className={classes.leftButtonGroup} />
            <div className={classes.rightButtonGroup}>
              <Button
                className='secondary'
                onClick={() => {
                  history.push(ROUTES.racesRoute);
                }}
              >
                {t('races.new.cancel')}
              </Button>
              <Button
                style={{ marginLeft: 21 }}
                disableElevation
                variant='contained'
                color='primary'
                className='submit'
                onClick={onSubmit}
                disabled={isSubmitting}
              >
                {t('races.new.continue')}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export { NewRace };

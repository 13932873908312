import { Observer } from 'mobx-react';
import React, { ReactElement, useCallback } from 'react';

import { SystemUsersViewActions } from '../../../../actions/view.actions/systemUsers.view.actions';

import { SystemUserReportsStore } from '../../../../stores/systemUserReports.store';

import { ReportFiltersMappers } from '../../../../mappers/report-filters.mapper';
import { ReportsTable } from '../Table';
import { generateSystemUserColumns } from '../Table/Columns';

const ReportsTableWrapper = (): ReactElement => {
  const fetchReports = useCallback(({ pageIndex = 0, pageSize, filters, sortBy }) => {
    let orderBy = 'created_at';
    let sortedBy = 'DESC';
    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      orderBy = id.includes('.') ? id.split('.').join('|') : id;
      sortedBy = desc ? 'DESC' : 'ASC';
    }
    SystemUsersViewActions.loadSystemUserReports({
      orderBy,
      sortedBy,
      page: pageIndex + 1,
      limit: pageSize,
      ...ReportFiltersMappers.toBackend(filters),
    });
  }, []);

  return (
    <div style={{ padding: 0, backgroundColor: 'white' }} className='content main-container-list races-list'>
      <Observer
        render={() => {
          return (
            <ReportsTable
              fetchReports={fetchReports}
              tableData={SystemUserReportsStore.reports?.value?.data || []}
              tableMeta={SystemUserReportsStore.reports?.value?.meta || {}}
              initialSizeOfPage={30}
              pageSizeOptions={[30, 50, 100, 300]}
              tableColumns={generateSystemUserColumns()}
            />
          );
        }}
      />
    </div>
  );
};

export { ReportsTableWrapper };

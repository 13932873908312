import * as React from 'react';
import styled from 'styled-components';

import { Loading } from 'src/components/Loading';
import { Button } from 'src/components/core';
import { ButtonHolder } from 'src/modules/Synchronisation/Shared';
import { t } from 'src/utils';

import { Distances } from '../../components';

import { EQ_MAPPING_INIT, EQ_MAPPING_LOAD } from '../../constants';

import { backToSettings, mountSync, saveStartSync, saveSync, unmountSync } from '../../actions';

const StyledButtonHolder = styled(ButtonHolder)`
  padding: 28px 28px 64px;
`;

export const SaveSync = () => {
  React.useEffect(() => {
    mountSync();
    return unmountSync;
  }, []);

  return (
    <>
      <Loading action={EQ_MAPPING_INIT} />
      <Loading action={EQ_MAPPING_LOAD} />
      <Distances />
      <StyledButtonHolder>
        <Button type='cancel' onClick={backToSettings}>
          {t('shared.back')}
        </Button>
        <Button type='apply' onClick={saveStartSync}>
          Save & Start Sync
        </Button>
        <Button type='apply' onClick={saveSync}>
          Save Sync
        </Button>
      </StyledButtonHolder>
    </>
  );
};

import { mergeWith, isArray, isObject } from 'lodash';
import { isObservableArray } from 'mobx';

function mergeValues(values1: ErrorType = {}, values2: ErrorType = {}) {
  return mergeWith(values1, values2, (value1, value2) => {
    if (!value1 && (isObservableArray(value2) || isArray(value2))) {
      return value2;
    }

    if (!value2 && (isObservableArray(value1) || isArray(value1))) {
      return value1;
    }

    if ((isObservableArray(value1) || isArray(value1)) && (isObservableArray(value2) || isArray(value2))) {
      const isValue1Primitive = value1.every((el) => !isObject(el));
      const isValue2Primitive = value1.every((el) => !isObject(el));

      if (isValue1Primitive && isValue2Primitive) {
        return [...value1, ...value2];
      }

      value1.map((el1, index) => {
        const el2 = value2[index];
        // @ts-ignore
        return this.mergeValues(el1, el2);
      });
    }
  });
}

export { mergeValues };

import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils/history';

export const getToken = () => {
  const matchedPath = matchPath(history.location.pathname, ROUTES.campaignLinkEdit);
  const { token } = matchedPath.params;
  return token;
};

// Store option generator for each field of each col
import { Result, Split, Racer, Team } from 'models';

import { StoreOption } from '../types';

// Provide i'ds for each cols to update
const result: { [K in string]: Function } = {
  start_time: function (model: Result): StoreOption[] {
    return model.generateStoreOptions('place', 'start_time');
  },

  // Sync result.finish_time, result.lastSplit.finish_time
  finish_time: function (model: Result): StoreOption[] {
    const lastSplit = model.lastSplit();
    const lastSplitStoreOptions = lastSplit && lastSplit.generateStoreOptions('finish_time');

    return [...model.generateStoreOptions('place', 'finish_time'), ...(lastSplitStoreOptions || [])];
  },
};

const split: { [K in string]: Function } = {
  // Sync result.lastSplit.finish_time, result.finish_time
  finish_time: function (model: Split): StoreOption[] {
    let storeOptions: any[] = [];

    if (model.isVirtual()) {
      storeOptions.push(...model.allStoreOptions());
    } else {
      storeOptions.push(model.generateStoreOption('finish_time'));
    }

    const result = model.result;

    if (model.isLast && result) {
      storeOptions.push(...result.generateStoreOptions('finish_time'));
    }

    return storeOptions;
  },
};

const racer: { [K in string]: Function } = {};
const team: { [K in string]: Function } = {};

function defaultStoreOptionsGenerator(property: string, model: Result | Split | Racer | Team | Racer): StoreOption[] {
  return model.generateStoreOptions(property);
}

function generateStoreOptions(property: string, model: Result | Split | Racer | Team | Racer): StoreOption[] {
  let generator;

  switch (model.constructor) {
    case Result:
      generator = result[property];
      break;
    case Split:
      generator = split[property];
      break;
    case Racer:
      generator = racer[property];
      break;
    case Team:
      generator = team[property];
      break;
    default:
      break;
  }

  if (!generator) {
    return defaultStoreOptionsGenerator(property, model);
  }

  return generator(model);
}

export { generateStoreOptions };

import { racesStore, distanceStore, singleResultsStore, teamResultsStore } from '../stores';

import { DeleteResult } from './deleteResult';
import { DistanceActions } from './distanceActions';
import { DistancesService } from './distances';
import { ExportService } from './export';
import { ImportService } from './import';
import { RacersService } from './racers';
import { RacersNewResultService } from './racersNewResult';
import { RacesService } from './races';
import { ResultsService } from './results';
import { SingleResultsService } from './singleResults';
import { TeamResultsService } from './teamResults';
import { TeamsService } from './teams';
import { TeamsNewResultService } from './teamsNewResult';
import { UpdateRacer } from './updateRacer';
import { UpdateResult } from './updateResult';
import { UpdateSplit } from './updateSplit';
import { UpdateTeam } from './updateTeam';

const racesService = new RacesService(racesStore);
const racersService = new RacersService();
const teamsService = new TeamsService();
const distancesService = new DistancesService(distanceStore);
const singleResultsService = new SingleResultsService(singleResultsStore);
const teamResultsService = new TeamResultsService(teamResultsStore);
const resultsService = new ResultsService();
const updateRacerService = new UpdateRacer();
const updateResultService = new UpdateResult();
const deleteResultService = new DeleteResult();
const updateSplitService = new UpdateSplit();
const updateTeamService = new UpdateTeam();
const exportService = new ExportService();
const distanceActionsService = new DistanceActions();
const importService = new ImportService();
const racersNewResultService = new RacersNewResultService();
const teamsNewResultService = new TeamsNewResultService();

export {
  teamsService,
  racersService,
  racesService,
  distancesService,
  singleResultsService,
  teamResultsService,
  resultsService,
  updateRacerService,
  updateResultService,
  deleteResultService,
  updateSplitService,
  updateTeamService,
  exportService,
  distanceActionsService,
  importService,
  racersNewResultService,
  teamsNewResultService,
};

export * from './races';
export * from './distances';
export * from './singleResults';
export * from './teamResults';
export * from './results';
export * from './updateRacer';
export * from './updateResult';
export * from './deleteResult';
export * from './updateSplit';
export * from './updateTeam';
export * from './export';
export * from './distanceActions';

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { ORGANIZER_DISTANCES_URL } from 'src/constants';

import { action, request } from 'utils';

import { GET_DISTANCES_FOR_TASKS } from '../constants';

import { mapDistances } from '../utils';

import { targetDistancesStore, sourceDistanceStore, refundDistancesStore } from '../stores';

class DistancesService {
  @request({ action: GET_DISTANCES_FOR_TASKS })
  async getDistancesRequest(params: nil | FiltersType): Promise<any> {
    const path = generatePath(ORGANIZER_DISTANCES_URL);
    return axios.get(path, { params });
  }

  @action({ action: GET_DISTANCES_FOR_TASKS })
  async getTargetDistances(params: nil | FiltersType): Promise<any> {
    const [status, response] = await this.getDistancesRequest(params);

    if (status) {
      targetDistancesStore.set(mapDistances(response.data.data));
    }
  }

  @action({ action: GET_DISTANCES_FOR_TASKS })
  async getSourceDistances(params: nil | FiltersType): Promise<any> {
    const [status, response] = await this.getDistancesRequest(params);

    if (status) {
      sourceDistanceStore.set(mapDistances(response.data.data));
    }
  }

  @action({ action: GET_DISTANCES_FOR_TASKS })
  async getRefundDistances(params: nil | FiltersType): Promise<any> {
    const [status, response] = await this.getDistancesRequest(params);

    if (status) {
      refundDistancesStore.set(mapDistances(response.data.data));
    }
  }
}

export const distancesService = new DistancesService();

import { emptyData } from '../common/empty-data';
import { Age, RawAgeJSON } from '../types/age.type';

const formatLabelRange = (labelRange: string): string => {
  const [from, to] = labelRange.split('-');
  if (to === '*') return labelRange;
  return `${from}-${Number(to) - 1}`;
};

export function mapAge(raw: RawAgeJSON.Data, range: number): Age {
  return {
    range,
    data:
      raw.age_range.buckets.map((bucket) => {
        return {
          label: formatLabelRange(bucket.key.replaceAll('.0', '')),
          value: bucket.doc_count,
          percentage: Number(bucket.percentage.value.toFixed(0)),
        };
      }) ?? emptyData,
  };
}

import { isCalendarRaceType } from 'modules/Races/utils/isCalendarRaceType';

import { ADDITIONAL_OPTIONS, CALENDAR_RACE_STEPS_ARRAY, RACE_STEPS_ARRAY } from '../constants';

import { raceFormData } from './initForm';

const getAvailableSteps = () => {
  const stepsArray = isCalendarRaceType.get() ? CALENDAR_RACE_STEPS_ARRAY : RACE_STEPS_ARRAY;
  let stepsToShow = [...stepsArray];
  const { languagesData, editorSettings = [] } = raceFormData();
  const enabledAdditionalSteps = Object.keys(editorSettings).filter((key) => editorSettings[key]);
  stepsToShow = stepsToShow.filter((step) => {
    const a = ADDITIONAL_OPTIONS.includes(step);
    const b = enabledAdditionalSteps.includes(step);
    return !a || b;
  });

  if (languagesData?.selectedLanguages?.length === 0) {
    stepsToShow = stepsToShow.filter((step) => step !== 'translations');
  }
  return stepsToShow;
};

export { getAvailableSteps };

export const IFRAME_PREFIX = '/iframe';

export const ROUTES = {
  rootRoute: '/',
  dashboardRoute: '/dashboard',
  dashboardPaymentsRoute: '/dashboard/payments',
  dashboardTurnoverRoute: '/dashboard/turnover',
  dashboardParticipantsRoute: '/dashboard/participants',
  dashboardAgeRoute: '/dashboard/age',
  signUp: '/sign-up',
  setPassword: '/set-password',
  forgotPassword: '/forgot-password',
  sportTypesRoute: '/sport_categories',
  sportTypeRoute: '/sport_categories/:id',
  sportTypeWithOptionalParamRoute: '/sport_categories/:id?',
  distancesRacersRoute: '/races/:raceId/distances/:id/racers',
  distancesTeamsRoute: '/races/:raceId/distances/:id/teams',
  distancesMembersRoute: '/races/:raceId/distances/:id/members',
  resultsRoute: '/races/:raceId/distances/:id/results',
  racesRoute: '/races',
  racersRoute: '/racers',
  newRace: '/races/new',
  raceRoute: '/races/:id',
  receiptSettings: '/races/:id/receipt-settings',
  raceEditRoute: '/races/:id/edit',
  raceWithOptionalParamRoute: '/races/:id?',
  distancesRoute: '/races/:raceId/distances',
  newDistanceRoute: '/races/:raceId/distances/new',
  updateDistanceRoute: '/races/:raceId/distances/:distanceId',
  distanceRoute: '/races/:raceId/distances/:id',
  duplicateDistance: '/distances/:id/duplicate',
  couponsRoute: '/coupons',
  couponRoute: '/coupons/:id',
  organizerWithOptionalParamRoute: '/organizers/:id?',
  organizersRoute: '/organizers',
  organizerRoute: '/organizers/:id',
  systemUsersRoute: '/system-users',
  systemUserRoute: '/system-users/:id',
  systemUserNewRoute: '/system-users/new',
  systemUserEditRoute: '/system-users/:id/edit',
  systemUserWithOptionalParamRoute: '/system-users/:id?',
  systemUsersDeleteRoute: '/system-users-delete',
  customersRoute: '/customers',
  customerRoute: '/customers/:id',
  customersWithOptionalParamRoute: '/customers/:id?',
  paymentsRoute: '/payments',
  paymentRoute: '/payments/:id',
  paymentsWithOptionalParamRoute: '/payments/:id?',
  profileRoute: '/profile',
  errorPage: '/something-went-wrong',
  notFound: '/not-found',
  serviceUnavailable: '/service-unavailable',
  integrations: '/races/:id/integrations',
  previewLink: '/:locale/races/:id/about',
  supportManagerTasksRoute: '/support-manager/tasks',
  supportManagerTaskRoute: '/support-manager/tasks/:id',
  supportManagerCopyRacers: '/support-manager/copy-racers',
  sync: '/sync',
  createRRSync: '/sync/create-rr',
  createEQSync: '/sync/create-eq',
  editEQSync: '/sync/:id',

  campaignLinks: '/campaign-links',
  campaignLinkDetails: '/campaign-links/:token',
  campaignLinkEdit: '/campaign-links/:token/edit',
  createCampaignLink: '/campaign-links/new',

  smartLinks: '/registration-links',
  smartLinkDetails: '/registration-links/:token',
  smartLinkEdit: '/registration-links/:token/edit',
  createSmartLink: '/registration-links/new',

  transactionsRoute: '/reconciliation/transactions',
  commissionsRoute: '/reconciliation/commissions',
  commissionRoute: '/reconciliation/commissions/:id',
  reportsRoute: '/reconciliation/reports',
  reportRoute: '/reconciliation/reports/:reportId',
  transactionRoute: '/reconciliation/transactions/:transactionId',
  terms: '/terms',
  organizerEmailVerification: '/organizer/verification/:token',
  cooperationAgreement: '/cooperation-agreement',
  processAgreement: '/data-processing-agreement',

  iframeRoot: IFRAME_PREFIX,
  iframeCooperationAgreement: `${IFRAME_PREFIX}/cooperation-agreement`,
  iframeProcessAgreement: `${IFRAME_PREFIX}/data-processing-agreement`,
};

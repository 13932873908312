import { defaultListFilters } from 'utils';

export const id: FilterListDescriptor = {
  filter: defaultListFilters.numberRange,
  frontend: { type: 'numberRange' },
  sort: {
    default: true,
    reorderOnSearch: true,
    defaultBy: 'DESC',
    calcOrderBy: (name) => name,
  },
};

import { Paper, Typography, Button } from '@mui/material';
import * as React from 'react';

import { t } from 'utils';

import { buttonStyles, endStatusStyles } from '../styles';

export const LoadingRetry = ({ upload, onRetry }: { upload?: boolean; onRetry: AnyFunction }) => {
  const classes = endStatusStyles();
  const buttonClasses = buttonStyles();
  return (
    <Paper elevation={3} className={`${classes.paper} ${classes.spaceBetween}`}>
      <Typography variant='h5' component='h2' className={classes.text}>
        {t.staticAsString('shared.loadPopup.noConnection')}
      </Typography>
      <Button size='small' color='primary' className={buttonClasses.root} onClick={onRetry}>
        {t.staticAsString('shared.loadPopup.btnAction.loadRetry')}
      </Button>
    </Paper>
  );
};

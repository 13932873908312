import { action, observable, makeObservable } from 'mobx';

import { t } from 'src/utils';

import { CONFIRM_POPUP_TYPES } from '../constants/mixed';

type TDynamic = string | React.ReactNode;

type ConfirmationModalProps = {
  title: TDynamic;
  body: TDynamic;
  onClose(): void;
  onConfirm: Function;
  isOpen: boolean;
  confirmationValue: string;
  confirmationLabel?: string;
  btnLabel: {
    confirm?: TDynamic;
    cancel?: TDynamic;
    ok?: TDynamic;
  };
  type: string;
};

class ConfirmationModal {
  @observable
  props: ConfirmationModalProps = {
    title: '',
    body: '',
    onConfirm: () => {},
    isOpen: false,
    confirmationValue: '',
    confirmationLabel: '',
    btnLabel: {
      confirm: 'shared.confirmPopup.btnAction.confirm',
      cancel: 'shared.confirmPopup.btnAction.cancel',
      ok: 'shared.confirmPopup.btnAction.ok',
    },
    type: CONFIRM_POPUP_TYPES.confirm,
  } as any;

  @observable
  confirmValue: string = '';
  @observable
  confirmError: string = '';

  constructor() {
    makeObservable(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  @action
  changeConfirm(value) {
    this.confirmValue = value;
  }

  @action
  changeConfirmErrors(error) {
    this.confirmError = error;
  }

  @action
  openModal({
    title,
    body,
    type = CONFIRM_POPUP_TYPES.confirm,
    confirmationValue = '',
    confirmationLabel = '',
    onConfirm,
    btnLabel = {
      confirm: 'shared.confirmPopup.btnAction.confirm',
      cancel: 'shared.confirmPopup.btnAction.cancel',
      ok: 'shared.confirmPopup.btnAction.ok',
    },
  }: {
    title: TDynamic;
    body: TDynamic;
    type?: string;
    confirmationValue?: string;
    confirmationLabel?: string;
    onConfirm: Function;
    btnLabel?: {
      confirm?: TDynamic;
      cancel?: TDynamic;
      ok?: TDynamic;
    };
  }) {
    this.props = {
      ...this.props,
      btnLabel,
      title,
      body,
      type,
      onConfirm,
      confirmationValue,
      confirmationLabel,
      isOpen: true,
    };
  }

  handleConfirm() {
    if (this.props.confirmationValue && this.props.confirmationValue !== this.confirmValue) {
      this.confirmError = t.staticAsString('confirmationModal.notEqualError');
      return;
    }

    this.props.onConfirm();
    this.handleClose();
  }

  @action
  handleClose() {
    if (Boolean(this.props.onClose)) {
      this.props.onClose();
    }
    this.confirmError = '';
    this.confirmValue = '';
    this.props = {
      title: '',
      body: '',
      onClose: () => {},
      onConfirm: () => {},
      confirmationValue: '',
      confirmationLabel: '',
      isOpen: false,
      btnLabel: {
        confirm: 'shared.confirmPopup.btnAction.confirm',
        cancel: 'shared.confirmPopup.btnAction.cancel',
        ok: 'shared.confirmPopup.btnAction.ok',
      },
      type: CONFIRM_POPUP_TYPES.confirm,
    };
  }
}

export { ConfirmationModal };
export default new ConfirmationModal();

import * as React from 'react';

import { ConfirmationModal } from 'components/ConfirmationModal';

import { t } from 'utils';

type Args = {
  i18Title?: string;
  i18Body?: string;
  btlLabel?: {
    confirm?: string;
    cancel?: string;
    ok?: string;
  };
  type: string;
};

type State = {
  open: boolean;
  confirmAction: nil | Function;
  title: any;
  body: any;
};

type Props = {};

const withConfirmModal =
  (namespace: string, { i18Title, i18Body, type }: Args) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class ListModuleHOC extends React.Component<Props, State> {
      state = {
        open: false,
        confirmAction: undefined,
        title: '',
        body: '',
      };

      _getTitle = (titleParams: Object = {}) => {
        if (!i18Title) {
          return '';
        }

        return t(i18Title as TranslationLockedKeys, titleParams);
      };

      _getBody = (bodyParams: Object = {}) => {
        if (!i18Body) {
          return '';
        }

        return t(i18Body as TranslationLockedKeys, bodyParams);
      };

      _wrappedProps = (): WithConfirmModalProps<string> => {
        return {
          [namespace]: {
            close: this.onClose,
            open: this.open,
          },
        };
      };

      onClose = () => {
        this.setState({ ...this.state, open: false });
      };

      open = (confirmAction?: Function, titleParams: Object = {}, bodyParams: Object = {}) => {
        this.setState({
          open: true,
          title: this._getTitle(titleParams),
          body: this._getBody(bodyParams),
          confirmAction: () => {
            this.onClose();
            confirmAction && confirmAction();
          },
        });
      };

      render() {
        const { open, confirmAction, title, body } = this.state;

        return (
          <React.Fragment>
            <ConfirmationModal title={title} body={body} type={type} open={open} onClose={this.onClose} onConfirm={confirmAction as any} />
            <WrappedComponent {...(this.props as any)} {...this._wrappedProps()} />
          </React.Fragment>
        );
      }
    } as unknown as void;

export { withConfirmModal };

import { t } from 'utils';

export const getIntegrationValidation = () => {
  const presence = {
    allowEmpty: false,
    // @ts-ignore TS check if whether this is working
    message: t.staticAsString('integrations.validation.required'),
  };

  return {
    webhook_url: {
      presence,
      url: {
        // @ts-ignore TS check if whether this is working
        message: t.staticAsString('integrations.validation.url_format'),
      },
    },
    distances: {
      presence,
    },
    is_sync: {
      presence,
    },
    is_active: {
      presence,
    },
  };
};

import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { Observer } from 'mobx-react';
import { loadAge } from 'modules/Dashboard/actions/age.action';
import { loadParticipants } from 'modules/Dashboard/actions/participants.action';
import { loadRegistrations } from 'modules/Dashboard/actions/registrations.action';
import { loadTurnover } from 'modules/Dashboard/actions/turnover.action';
import { chartRangePickerStore } from 'modules/Dashboard/stores/interval.store';
import { ChartInterval, ChartRange } from 'modules/Dashboard/types/interval';
import { lastDays, lastMonths, lastYear } from 'modules/Dashboard/utils/date';

import { t } from 'utils/t';

type Props = {
  collection: 'registrations' | 'turnover' | 'participants' | 'age';
};

export default function SelectRangeButtons({ collection }: Props) {
  const makeButtonStyle = (range: ChartRange) => {
    const buttonSx = {
      background: '#FAFAFA',
      border: '1px solid rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.56)',
      fontSize: 14,
      fontWeight: 400,
      width: 44,
      height: 44,
      '&:hover': {
        background: '#A8AEB8',
        border: '1px solid rgb(148, 153, 162)',
        fontWeight: 700,
        color: 'white',
      },
    };
    if (chartRangePickerStore.selectedRange === range) {
      return {
        ...buttonSx,
        background: '#A8AEB8',
        border: '1px solid rgb(148, 153, 162)',
        fontWeight: 700,
        color: 'white',
      };
    }
    return buttonSx;
  };

  const loadData = () => {
    switch (collection) {
      case 'registrations':
        loadRegistrations(true, true);
        break;
      case 'participants':
        loadParticipants(true, true);
        break;
      case 'turnover':
        loadTurnover(true, true);
        break;
      case 'age':
        loadAge(2, true, true);
        break;
      default:
        break;
    }
  };

  const handleSelectButton = (range: ChartRange) => () => {
    chartRangePickerStore.setRange(range);
    let interval: ChartInterval = 'month';
    let from = '';
    let to = '';
    switch (range) {
      case '24h':
        const lastDay = lastDays(1);
        interval = 'hour';
        from = lastDay.from;
        to = lastDay.to;
        break;
      case '7d':
        const fullLastWeek = lastDays(7);
        interval = 'day';
        from = fullLastWeek.from;
        to = fullLastWeek.to;
        break;
      case '30d':
        const lastMonth = lastMonths(1);
        interval = 'day';
        from = lastMonth.from;
        to = lastMonth.to;
        break;
      case '3m':
        const lastThreeMonths = lastMonths(3);
        interval = 'month';
        from = lastThreeMonths.from;
        to = lastThreeMonths.to;
        break;
      case '6m':
        const lastHalfAYear = lastMonths(6);
        interval = 'month';
        from = lastHalfAYear.from;
        to = lastHalfAYear.to;
        break;
      case '12m':
        const fullLastYear = lastYear();
        interval = 'month';
        from = fullLastYear.from;
        to = fullLastYear.to;
        break;
    }
    chartRangePickerStore.setInterval(interval, from, to);
    loadData();
  };
  return (
    <Observer
      render={() => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
            <Typography sx={{ fontSize: 14, mr: 1 }}>{t('dashboard.show')}:</Typography>
            <ButtonGroup variant='outlined' aria-label='outlined button group'>
              <Button onClick={handleSelectButton('24h')} sx={makeButtonStyle('24h')}>
                24h
              </Button>
              <Button onClick={handleSelectButton('7d')} sx={makeButtonStyle('7d')}>
                7d
              </Button>
              <Button onClick={handleSelectButton('30d')} sx={makeButtonStyle('30d')}>
                30d
              </Button>
              <Button onClick={handleSelectButton('3m')} sx={makeButtonStyle('3m')}>
                3m
              </Button>
              <Button onClick={handleSelectButton('6m')} sx={makeButtonStyle('6m')}>
                6m
              </Button>
              <Button onClick={handleSelectButton('12m')} sx={makeButtonStyle('12m')}>
                12m
              </Button>
            </ButtonGroup>
          </Box>
        );
      }}
    />
  );
}

import * as React from 'react';

type Props = {
  children: React.ReactNode;
};

class Layout extends React.Component<Props> {
  render() {
    const { children } = this.props;
    return (
      <main className='auth'>
        <div className='background-image'>
          <div className='logo-container'>
            <div className='logo-wrapper-inner'>
              <div className='logo'></div>
            </div>
            <span>SUPER ADMIN</span>
          </div>
          <div className='content'>{children}</div>
        </div>
      </main>
    );
  }
}

export default Layout;

import { TableCell } from '@mui/material';
import { OrderStatus, START_LIST_ORDER_STATUSES } from 'modules/Payments/constants';
import * as React from 'react';

import { FormattedDate } from 'components/FormattedDate';

import { commonDateFormats } from 'utils';

function Prices({ value }: { value: RacerType }) {
  const status = START_LIST_ORDER_STATUSES.find(
    (status) => status.key === (value.order_status ? value.order_status : value.payment_required ? OrderStatus.pending : ''),
  )?.value;

  if (!value.order_id) return <PricesComponent status={status} />;

  const amount = value.order_total ? `${+(value.order_total / 100).toFixed(2)}` : '';

  return (
    <PricesComponent
      id={value.order_id}
      coupon={value.order_coupon_codes}
      createdAt={value.order_created_at}
      currency={value.order_currency}
      amount={amount}
      status={status}
    />
  );
}

function PricesComponent({
  coupon,
  id,
  createdAt,
  amount,
  currency,
  status,
}: {
  coupon?: string[];
  id?: number | null;
  createdAt?: string | null;
  amount?: string | null;
  currency?: string | null;
  status?: string;
}) {
  return (
    <React.Fragment>
      <TableCell className='cell'>{coupon?.join(', ')}</TableCell>
      <TableCell className='cell'>{id}</TableCell>
      <TableCell className='cell'>
        <FormattedDate value={createdAt} format={commonDateFormats.shortDateWithTime} />
      </TableCell>
      <TableCell className='cell'>
        {amount} {currency}
      </TableCell>
      <TableCell className='cell'>{status}</TableCell>
    </React.Fragment>
  );
}

export { Prices };

import { OrderItemType, OrderStatus, PaymentStatus } from '../constants';

export type OrderItem = {
  id: number;
  order_id: number;
  registration_participant_id: number;
  type: OrderItemType;
  title: string;
  subtitle: string;
  price: number;
  original_price: number;
  vat_value: number;
  vat_amount: number;
  vat_is_percent: boolean;
  discount_value: number;
  discount_amount: number;
  discount_is_percent: boolean;
  coupon_code: string | null;
  coupon_id: number | null;
  item_id: number | null;
  item_type: string;
};

export type CustomerType = {
  id: number;
  address: string | null;
  birthday: string | null;
  city: string | null;
  company: string | null;
  country_id: number | null;
  email: string | null;
  emergency_name: string | null;
  emergency_phone: string | null;
  external_swimrun_id: string | null;
  firstname: string | null;
  gender: GenderEnum;
  lastname: string | null;
  nationality_id: number | null;
  post_code: string | null;
  shirt_size: string | null;
  shoe_size: string | null;
  squad_id: number | null;
  telephone: string | null;
  union: string | null;
  uuid: string;
};

export enum GenderEnum {
  male = 1,
  female = 2,
  other = 3,
}

export type ReceiptType = {
  name: string;
  size: number;
  url: string;
  uuid: string;
};

export type OrderType = {
  id: number;
  uuid: string;
  total: number;
  currency: string;
  refund_protect_used: boolean;
  status: OrderStatus;
  payment_status: PaymentStatus;
  organizer_id: number;
  receipt_uuid: string;
  address: string | null;
  birthday: string | null;
  city: string | null;
  country_id: number;
  custom_fields_sum: number;
  distance_id: number;
  distance_original_price: number;
  distance_title: string | null;
  distance_vat_value: number;
  distances_count: number;
  discount: number;
  email: string | null;
  firstname: string | null;
  gender: GenderEnum;
  lastname: string | null;
  post_code: number;
  receipt: ReceiptType | null;
  created_at: string | null;
  race_id: number;
  race_title: string;
  payment_external_id: string;
  ref_number: string;
};

export type OrderDetailsType = {
  id: number;
  uuid: string;
  registration_id: string;
  customer_id: number;
  total: number;
  vat: number;
  discount: number;
  currency: string;
  refund_protect_used: boolean;
  status: OrderStatus;
  payment_status: PaymentStatus;
  race_id: number;
  organizer_id: number;
  user_id: number;
  receipt_uuid: string;
  coupon_codes: string[];
  items: OrderItem[];
  customer: CustomerType;
  created_at: string;
};

export type PaymentDetailsType = {
  id: number;
  uuid: string;
  currency: string;
  created_at: string;
  status: OrderStatus;
  amount: number;
  external_id: string;
  method_details: PaymentMethodDetails;
  order_id: number;
  provider: keyof typeof PAYMENT_PROVIDERS;
};

export const PAYMENT_PROVIDERS = {
  1: 'Payex',
  2: 'Stripe',
};

export const PAYMENT_TRANSACTION_TYPES = {
  1: 'Init',
  2: 'Authorization',
  3: 'Capture',
  4: 'Refund',
};

export const PAYMENT_METHOD_TYPES = {
  1: 'card',
  2: 'swish',
  3: 'mobile swish',
};

export type PaymentMethodDetails = {
  card: MethodDetails;
  type: string;
};

export type MethodDetails = {
  brand: string;
  country?: string;
  exp_month?: number;
  exp_year?: number;
  funding?: string;
  last4?: string;
  network?: string;
};

export type PaymentTransactionType = {
  id: number;
  uuid: string;
  order_id: number;
  type: keyof typeof PAYMENT_TRANSACTION_TYPES;
  amount: number;
  currency: string;
  status: OrderStatus;
  provider: keyof typeof PAYMENT_PROVIDERS;
  method: keyof typeof PAYMENT_METHOD_TYPES;
  external_id: string;
  failed_reason: string;
  failed_code: string;
  description: string;
};

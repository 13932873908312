import { loadCurrencyService } from 'services';

import { progressStore } from 'stores';

import { CommissionConstants } from '../constants/commission.constants';

import { CommissionService } from '../services/commission.service';

import { CommissionStore } from '../stores/commission.store';

import { CommissionFilters } from '../types/filters.type';

export namespace CommissionActions {
  export async function init() {
    progressStore.log(CommissionConstants.Table, 'progress');
    await Promise.all([loadCurrencyService.loadResources(), CommissionService.loadOrganizer()]);
    progressStore.log(CommissionConstants.Table, 'completed');
  }

  export async function load(filters: CommissionFilters) {
    progressStore.log(CommissionConstants.Table, 'progress');
    const data = await CommissionService.load(filters);
    CommissionStore.commissions.set(data);
    progressStore.log(CommissionConstants.Table, 'completed');
  }

  export async function unmount() {
    CommissionStore.commissions.value = null;
  }
}

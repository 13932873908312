import { action, observable, makeObservable } from 'mobx';

class BooleanStore {
  @observable value: boolean;

  constructor(value?: boolean) {
    makeObservable(this);
    this.value = value || false;
  }

  @action
  on() {
    this.value = true;
  }

  @action
  off() {
    this.value = false;
  }

  @action
  toogle() {
    this.value = !this.value;
  }
}

export { BooleanStore };

import { Transaction } from 'modules/Reconciliation/Transactions/types/transaction.type';

import { Gender } from '../types/gender.type';

export const emptyData = [
  {
    value: 0,
    label: '',
    percentage: 0,
  },
  {
    value: 0,
    label: '',
    percentage: 0,
  },
  {
    value: 0,
    label: '',
    percentage: 0,
  },
  {
    value: 0,
    label: '',
    percentage: 0,
  },
  {
    value: 0,
    label: '',
    percentage: 0,
  },
  {
    value: 0,
    label: '',
    percentage: 0,
  },
  {
    value: 0,
    label: '',
    percentage: 0,
  },
  {
    value: 0,
    label: '',
    percentage: 0,
  },
  {
    value: 0,
    label: '',
    percentage: 0,
  },
  {
    value: 0,
    label: '',
    percentage: 0,
  },
];

export const emptyTx = {
  id: '' as any,
  currency: '',
  email: '',
  race_title: '',
  total: '' as any,
  race_id: 0,
};

export const emptyTransationsData: Partial<Transaction>[] = [
  emptyTx,
  emptyTx,
  emptyTx,
  emptyTx,
  emptyTx,
  emptyTx,
  emptyTx,
  emptyTx,
  emptyTx,
  emptyTx,
];

export const emptyGenderData: Gender = {
  dominantGender: 'unknown',
  dominant: 0,
  total: '' as any,
  data: [
    {
      name: 'Male',
      value: 1,
      fill: '#FCC75D',
    },
    {
      name: 'Female',
      value: 1,
      fill: '#7CCBA5',
    },
    {
      name: 'Other',
      value: 1,
      fill: '#5DA6FC',
    },
  ],
};

import { distancesService } from 'src/modules/Results/services';
import { distanceStore } from 'src/modules/Results/stores';

async function reloadDistance() {
  const distance = distanceStore.modelSelected;

  if (!distance) {
    return;
  }

  const id = distance.value.id;

  await distancesService.loadResource(id);
}

export { reloadDistance };

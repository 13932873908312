import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { REFUND } from 'src/constants';
import { REFUND_DELETE_URL } from 'src/constants/api';

import { action, request, t } from 'utils';

import { toastStore } from 'stores';

class LoadRefundService {
  @request({ action: `DELETE_${REFUND}` })
  async deleteRefundRequest(id: string | number, params: AnyObject): Promise<any> {
    const path = generatePath(REFUND_DELETE_URL, { id });
    return axios.delete(path, params);
  }

  @action({ action: `DELETE_${REFUND}` })
  async deleteRefund(id: string | number, params: AnyObject): Promise<any> {
    const [status, response] = await this.deleteRefundRequest(id, params);

    if (status) {
      toastStore.show(t.staticAsString('supportTaskManager.responseCreditSingleOk --- deleted' as TranslationLockedKeys));
    } else {
      toastStore.show(t.staticAsString('cannot delete' as TranslationLockedKeys));
    }
  }
}

const refundService = new LoadRefundService();
export { LoadRefundService, refundService };
export default refundService;

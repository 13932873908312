import moment from 'moment';

import { t, time } from 'utils';

import { Distance } from './distance';
import { Race } from './race';

class Coupon {
  value: CouponType;
  race: nil | Race;
  distances: Array<Distance>;

  constructor(value: CouponType) {
    this.value = value;
    this.race = null;
    this.distances = [];
    this.initRace();
    this.initDistances();
  }

  initRace(): void {
    const { race } = this.value;
    if (!race) {
      return;
    }

    this.race = new Race(race);
  }

  initDistances(): void {
    const { distances } = this.value;
    if (!distances) {
      return;
    }

    this.distances = distances.map((el) => new Distance(el));
  }

  currency(): string {
    const { race } = this;

    if (!race) {
      return '';
    }

    return race.currency();
  }

  discountAmount() {
    const { value } = this;
    const currency = this.currency();

    if (value.discount_percentage) {
      return `${parseInt(value.discount_amount, 0)} %`;
    } else {
      return `${value.discount_amount} ${currency}`;
    }
  }

  status(): string {
    const { status } = this.value;

    // Translation keys are written as { 0: "Unavailable, 1: "Aavailable" }
    // Number(disabled:true) gives us 1 so we need to flip boolean before conversion.
    return t.staticAsString(`categories.statusState.${Number(status)}` as TranslationLockedKeys) as any;
  }

  disabled(): string {
    const { status } = this.value;
    const disabledKey = Number(status);

    return t.staticAsString(`categories.statusState.${disabledKey}` as TranslationLockedKeys);
  }

  raceName(): string {
    const { race } = this;

    if (!race) {
      return '';
    }

    return race.value.name || '';
  }

  distanceNames(): string {
    const { distances } = this;
    return distances.map((distance) => distance.value.name).join(', ');
  }

  activeDate(): string {
    const { date_from, date_to } = this.value;
    const [dateFrom, dateTo] = [moment(date_from), moment(date_to)];

    if (!dateFrom.isValid() && !dateTo.isValid()) {
      return '';
    }

    let from = '';
    let to = '';

    if (dateFrom.isValid()) {
      from = time.date(dateFrom) as any;
    }
    if (dateTo.isValid()) {
      to = time.date(dateTo) as any;
    }

    return `${from} - ${to}`;
  }

  isTypeAllDistances() {
    return this.value.type === 1;
  }

  isTypeSelectedDistances() {
    return this.value.type === 2;
  }
}

export { Coupon };

import { dateRange } from './dateRange';
import { none } from './none';
import { numberRange } from './numberRange';
import { priceRange } from './priceRange';
import { string } from './string';

export const defaultListFilters: {
  [K in DefaultFrontendListFilter]: FilterDescriptor;
} = {
  dateRange,
  numberRange,
  priceRange,
  string,
  none,
};

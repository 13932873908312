import { defaultListFilters } from 'utils';

import { FilterDescriptorWrapper } from 'models';

import { SingleCols, TeamCols, SplitCols } from '../constants';

import { classId } from './classId';
import { countryId } from './countryId';
import { disciplineId } from './disciplineId';
import { gender } from './gender';
import { nationalityId } from './nationalityId';
import { prevSplitDiff } from './prevSplitDiff';
import { resultDiff } from './resultDiff';
import { waveId } from './waveId';

type Cols = SingleCols | TeamCols | SplitCols;
// @ts-ignore
const descriptors: {
  [K in Cols]: FilterListDescriptor;
} = {
  place: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      default: true,
      reorderOnSearch: true,
      defaultBy: 'ASC',
      calcOrderBy: (name) => name,
    },
  },

  start_time: {
    filter: defaultListFilters.none,
    sort: {
      reorderOnSearch: true,
      calcOrderBy: (name) => name,
    },
  },

  finish_time: {
    filter: defaultListFilters.none,
    sort: {
      reorderOnSearch: true,
      calcOrderBy: (name) => name,
    },
  },

  distance_logged: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      reorderOnSearch: true,
      calcOrderBy: (name) => name,
    },
  },

  distance_logged_at: {
    filter: defaultListFilters.dateRange,
    frontend: { type: 'dateRange' },
    sort: {
      reorderOnSearch: true,
      calcOrderBy: (name) => name,
    },
  },

  // Racer filters ----
  'racer.bib_number': {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      reorderOnSearch: true,
      calcOrderBy: (name) => 'racers:racer_id|racers.bib_number',
    },
  },

  'racer.firstname': {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      calcOrderBy: () => 'racers:racer_id|racers.firstname',
    },
  },

  'racer.lastname': {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      calcOrderBy: () => 'racers:racer_id|racers.lastname',
    },
  },

  'racer.gender': {
    ...gender,
    sort: {
      calcOrderBy: () => 'racers:racer_id|racers.gender',
    },
  },

  'racer.country_id': countryId,
  'racer.nationality_id': nationalityId,

  'racer.class_id': classId,
  'racer.wave_id': waveId,
  'racer.discipline_id': disciplineId,

  'racer.union': {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  // ----

  // Team filters ----
  'team.bib_number': {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      reorderOnSearch: true,
      calcOrderBy: () => 'teams:team_id|teams.bib_number',
    },
  },

  'team.name': {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      calcOrderBy: () => 'teams:team_id|teams.name',
    },
  },

  'team.class_id': classId,
  'team.wave_id': waveId,
  // ----

  // Split filters ----

  'splits.prev_split_diff': prevSplitDiff,
  'splits.result_diff': resultDiff,
};

export const filterDescriptors = Object.keys(descriptors).reduce((acc, desc) => {
  return { ...acc, [desc]: new FilterDescriptorWrapper(desc, descriptors[desc as SingleCols | TeamCols | SplitCols]) };
}, {});

export const MuiSwitch = {
  styleOverrides: {
    root: {
      width: 36,
      height: 20,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
          transform: 'translateX(16px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: ({ theme }) => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: ({ theme }) => (theme.palette.mode === 'light' ? 0.7 : 0.3),
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 16,
        height: 16,
      },
      '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: 'rgba(21, 27, 39, 0.24)',
        opacity: 1,
        transition: ({ theme }) =>
          theme.transitions.create(['background-color'], {
            duration: 500,
          }),
      },
    },
  },
};

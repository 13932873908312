import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { DISTANCE_RESULTS_URL } from 'src/constants';

import { request, action } from 'utils';

import { LOAD_TEAM_RESULTS } from '../constants';

import { distanceStore } from '../stores';
import { TeamResultsStore } from '../stores';

import { processSplits } from '../tools';

class TeamResultsService {
  store: TeamResultsStore;

  constructor(store: TeamResultsStore) {
    this.store = store;
  }

  @request({ action: LOAD_TEAM_RESULTS })
  async loadResourcesRequest(id: number, params: FiltersType): Promise<any> {
    const url = generatePath(DISTANCE_RESULTS_URL, { id });
    return axios.get(url, { params });
  }

  @action({ action: LOAD_TEAM_RESULTS, minRequestTime: 800 })
  async loadResources(distanceId: number, newParams: FiltersType = {}, pageNum: number = 1): Promise<any> {
    let { page, params, filters } = this.store;
    page = page !== pageNum && pageNum ? pageNum : page;

    const queryParams = {
      ...params,
      ...newParams,
      page,
    };

    const [status, response] = await this.loadResourcesRequest(distanceId, queryParams);
    if (status) {
      const values = response.data.data;
      this.store.updateCurrentDistance(distanceId);

      const processedValues = this._processValues(values);
      this.store.addValues(processedValues, page, { ...filters, ...newParams });

      const paginationMeta = response.data.meta && response.data.meta.pagination;
      if (paginationMeta) {
        this.store.addPaginationMeta(paginationMeta);
      }
    }

    return response;
  }

  async load(params?: { limit?: number; page?: number }): Promise<void> {
    const distanceId = this.store.distanceId;

    if (!params) {
      await this.loadResources(distanceId);
      return;
    }

    const { page, limit } = params;

    if (page) {
      await this.loadResources(distanceId, {}, page);
    }

    if (limit) {
      await this.loadResources(distanceId, { limit });
    }
  }

  // Add missing splits
  _processValues(values: ResultType[]): ResultType[] {
    const distanceModel = distanceStore.modelSelected;

    if (!distanceModel) {
      return [];
    }

    const checkpoints = distanceModel.checkpoints;

    return values.map((result) => {
      const splits = processSplits(result, checkpoints);
      return { ...result, splits };
    });
  }
}

export { TeamResultsService };

import { TableRow } from '@mui/material';
import * as React from 'react';

import { TasksCells } from '../../components/Row';

import { TasksContext } from '../../context';
import { TaskTable } from '../../types';

type Props = {
  item: TaskTable;
};

class Row extends React.Component<Props> {
  static contextType = TasksContext;

  render() {
    const { item } = this.props;
    return (
      <TableRow key={`racer${item.id}`}>
        <TasksCells value={item} />
      </TableRow>
    );
  }
}

export { Row };

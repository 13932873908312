import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACE, RACE_CONTACT_EMAILS_URL, RACE_IMAGE_URL, RACES_URL, ROUTES } from 'src/constants';

import { action, history, objectsDifference, request, t } from 'utils';

import { Race } from 'models';

import { helperImageUploadService } from 'services';

import { helperRacesStore, toastStore } from 'stores';

class EditRace {
  @request({ action: `UPDATE_${RACE}` })
  updateRequest(id: number, changedData: Object): any {
    return axios.patch(`${RACES_URL}/${id}`, changedData, { params: { with: 'contacts;distances' } });
  }

  @request({ action: `UPDATE_${RACE}` })
  removeRaceImageRequest(raceId: number): any {
    return axios.delete(generatePath(RACE_IMAGE_URL, { id: raceId }));
  }

  @request({ action: 'GET_CONTACT_EMAIL' })
  private getContactEmailRequest(id: number): any {
    return axios.get(generatePath(RACE_CONTACT_EMAILS_URL, { id }));
  }

  @request({ action: `UPDATE_${RACE}` })
  uploadRaceImageRequest(raceId: number, data: FormData): any {
    return axios.post(generatePath(RACE_IMAGE_URL, { id: raceId }), data);
  }

  @action({ action: 'GET_CONTACT_EMAIL' })
  async getContactEmail(raceId: number): Promise<[boolean, contactEmails]> {
    const [isOk, resp] = await this.getContactEmailRequest(raceId);
    return [isOk, resp?.data?.data];
  }

  @action({ action: `UPDATE_${RACE}` })
  async update({ image, data, onChangeCallback }: { image: nil | Blob; data: AnyObject; onChangeCallback: AnyFunction }): Promise<any> {
    // Image upload
    if (image instanceof Blob) {
      const respImgUrl = await helperImageUploadService.imageUpload(image);
      if (respImgUrl) {
        data.image = respImgUrl;
        // Change image field in state too, to avoid multiple image uploading
        onChangeCallback({ name: 'image', value: respImgUrl });
      } else {
        // Interupts on submit in case to show image upload errors
        return;
      }
    }

    // Race create
    const { id } = data;
    const dataToSend = this.formatData(data);
    const [status, response] = await this.updateRequest(id, dataToSend);
    if (status) {
      const value = response.data.data;

      toastStore.show(t.staticAsString('races.edit.successUpdate', { name: value.name || '' }));

      history.push(`${ROUTES.racesRoute}/${value.id}`);
    }
  }

  @action({ action: `UPDATE_${RACE}` })
  async updateField({ raceId, data }: { raceId: number; data: AnyObject }): Promise<[boolean, RaceType]> {
    const [isOk, race] = await this.updateRequest(raceId, data);
    return [isOk, race?.data?.data];
  }

  @action({ action: `UPDATE_${RACE}` })
  async removeRaceImage({ raceId }: { raceId: number }): Promise<[boolean, null]> {
    const [isOk, _] = await this.removeRaceImageRequest(raceId);
    return [isOk, null];
  }

  @action({ action: `UPDATE_${RACE}` })
  async uploadRaceImage({ raceId, value }: { raceId: number; value: Blob }): Promise<[boolean, null]> {
    const formData = new FormData();
    formData.append('image', value);
    if (!value) return [true, null];
    const [isOk, _] = await this.uploadRaceImageRequest(raceId, formData);
    return [isOk, null];
  }

  formatData(data: AnyObject) {
    const { selected } = helperRacesStore;
    if (!selected) {
      return data;
    }

    let dataToSend = objectsDifference(data, selected);

    if (data.location) {
      dataToSend = {
        ...dataToSend,
        location: data.location,
      };
    }

    return {
      ...dataToSend,
      contact_emails: data.contact_emails,
    };
  }

  generateUpdateLocationValue(location: Object) {
    return Object.entries(location).reduce((acc, [column, value]) => {
      if (!!value) {
        return { ...acc, [column]: value };
      }

      return acc;
    }, {});
  }
}

export { EditRace };
export default new EditRace();

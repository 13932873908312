import { store } from 'utils/messages/store';

import { copyRacersService } from '../services';

import { copyRacersStore } from '../stores';

export const loadCopyRacers = async (
  params: {
    limit?: number;
    page?: number;
  } = {},
) => {
  let { page: storePage, params: storeParams, filters: storeFilters } = copyRacersStore;

  let { limit = storeFilters.limit, page = storePage } = params;
  if (params.limit) {
    page = 1;
  }

  const queryParams = {
    ...storeParams,
    page,
    limit,
  };
  const [status, { data, meta }] = await copyRacersService.loadCopyRacers(queryParams);

  if (!status) return;

  copyRacersStore.addValues(data, page, { ...storeFilters, limit });

  const paginationMeta = meta && meta.pagination;

  if (paginationMeta) {
    copyRacersStore.addPaginationMeta(paginationMeta);
  }
};

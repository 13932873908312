import { helperOrganizersLoadService, loadCurrencyService } from 'services';

import { ReportsService } from '../services/reports.service';

import { ReportsStore } from '../stores/reports.store';

import { ReportsMapper } from '../mappers/reports.mapper';
import { ReportsFilters } from '../types/filters.type';
import { ReportsResponse } from '../types/report-response.type';

export namespace ReportsActions {
  export async function init() {
    await Promise.all([loadCurrencyService.loadResources(), helperOrganizersLoadService.loadResources()]);
  }

  export async function load(filters: ReportsFilters) {
    const data = await ReportsService.load<ReportsResponse>(filters);
    ReportsStore.reports.set(Object.assign(data, { data: data.data.filter((r) => Boolean(r.race)).map(ReportsMapper.fromBackend) }));
  }

  export async function unmount() {
    ReportsStore.reports.value = null;
  }

  export async function exportReports(type: string) {
    await ReportsService.exportReports(type);
  }
}

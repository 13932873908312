import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React, { CSSProperties } from 'react';

const useStyles = makeStyles((theme) => ({
  dots: {
    width: 2,
    height: 2,
    color: '#f9d026',
    boxShadow: '9.1px 0 0 3.4px, 18.2px 0 0 1.4px, 27.299999999999997px 0 0 0',
    animation: '$dots-pulse 0.7s infinite alternate linear',
  },
  '@keyframes dots-pulse': {
    '50%': {
      boxShadow: '9.1px 0 0 1.4px, 18.2px 0 0 3.4px, 27.299999999999997px 0 0 1.4px',
    },

    '100%': {
      boxShadow: '9.1px 0 0 0, 18.2px 0 0 1.4px, 27.299999999999997px 0 0 3.4px',
    },
  },
}));

const LoadingDots = ({ className, style }: { className?: string; style?: CSSProperties }) => {
  const classes = useStyles();
  return <div className={classNames(classes.dots, className)} style={style}></div>;
};

export { LoadingDots };

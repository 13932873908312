import moment from 'moment';

import { time } from 'utils';

function isTheSameDates(val1: nil | string, val2: nil | string): boolean {
  if (!time.isValid(val1 as moment.MomentInput, 'datetimems')) {
    return false;
  }

  if (!time.isValid(val2 as moment.MomentInput, 'datetimems')) {
    return false;
  }

  return (
    time
      .datetimems(val1 as moment.MomentInput)
      .parse()
      .val.valueOf() ===
    time
      .datetimems(val2 as moment.MomentInput)
      .parse()
      .val.valueOf()
  );
}

export { isTheSameDates };

import Dialog from '@mui/material/Dialog';
import withStyles from '@mui/styles/withStyles';
import { observer } from 'mobx-react';
import * as React from 'react';

import { closeConfirmModal } from '../../actions';

import { confirmStore } from '../../stores';

import { Title, Content, Bottom } from '.';

type Props = {};

const DialogStyled = withStyles({
  paper: {
    width: 770,
    height: 340,
  },
})(Dialog);

@observer
export class ConfirmModal extends React.Component<Props> {
  render() {
    const open: boolean = confirmStore.value?.open || false;
    const title: string = confirmStore.value?.title || '';
    const content: string = confirmStore.value?.content || '';

    return (
      <DialogStyled open={open} onClose={closeConfirmModal} maxWidth='md'>
        <Title title={title} />
        <Content content={content} />
        <Bottom />
      </DialogStyled>
    );
  }
}

import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

function getDistanceId(): number | null {
  const match = matchPath(history.location.pathname, ROUTES.updateDistanceRoute);

  if (!match) {
    return null;
  }

  return match.params.distanceId || null;
}

export { getDistanceId };

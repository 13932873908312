import { generatePath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { t, history } from 'utils';

import { toastStore } from 'stores';

import { distancesService } from '../services';

import { distanceStore } from '../stores';

function onDeleteDistance() {
  const { selected } = distanceStore;

  if (!selected) {
    return;
  }

  const raceId = selected.race_parent_id;
  const distanceId = selected.id;

  const status = distancesService.deleteDistance(distanceId);

  if (!status) {
    return toastStore.show(t.staticAsString('distances.canNotDelete'));
  }

  const url = generatePath(ROUTES.raceRoute, { id: raceId });

  history.push(url);

  toastStore.show(t.staticAsString('distances.delete'));
}

export { onDeleteDistance };

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

import { Icons } from './Icons';

type StyledSvgProp = {
  size: number | string;
};

const useStyledSvgClasses = makeStyles<Theme, StyledSvgProp>(() => ({
  root: {
    height: ({ size }) => `${size}px`,
    width: ({ size }) => `${size}px`,

    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
}));

type Props = {
  name: keyof typeof Icons | string;
  size?: number | string;
  className?: string;
  onClick?: AnyFunction;
};

export const Svg: React.FC<Props> = (props) => {
  const { name, size = 24, className, onClick, ...rest } = props;
  const Icon = Icons[name];
  const classes = useStyledSvgClasses({ size });

  if (!Icon) return null;

  return (
    <div {...rest} className={`${classes.root} ${className}`} onClick={onClick}>
      {Icon}
    </div>
  );
};

import { Observer } from 'mobx-react';
import * as React from 'react';
import { NavLink, generatePath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { t } from 'utils';

type Props = {};

function NavComponent(props: Props) {
  return (
    <Observer>
      {() => {
        return (
          <div className='start-list-nav'>
            <NavLink to={generatePath(ROUTES.supportManagerTasksRoute)}>{t.staticAsString('supportTaskManager.common.tasks')}</NavLink>
            <NavLink to={generatePath(ROUTES.supportManagerCopyRacers)}>{t.staticAsString('supportTaskManager.common.copyRacers')}</NavLink>
          </div>
        );
      }}
    </Observer>
  );
}

const Nav = React.memo<Props>(NavComponent);

export { Nav };

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import { withStyles } from '@mui/styles';
import isEmpty from 'lodash/isEmpty';
import { observer, inject } from 'mobx-react';
import * as React from 'react';

import { Toast as ToastStore } from 'stores';

type Props = {
  toastStore: ToastStore;
};

type State = {
  open: boolean;
  id: string | null;
};

const StyledSnackBar = withStyles({
  root: {
    zIndex: 5000,
  },
})(Snackbar);

@inject('toastStore')
@observer
class Toast extends React.Component<Props, State> {
  static defaultProps = {
    toastStore: null as any,
  };

  handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.toastStore.hide();
  };

  toast = (): ToastType | object => {
    const toast = this.props.toastStore.toast;
    if (isEmpty(toast)) return toast;
    else return toast;
  };

  render() {
    const isOpen = this.props.toastStore.isOpen;
    // Add ability to show action
    const { id, content, duration = 3000, variant = '' } = this.toast() as ToastType;

    const autoHideDuration = duration < 0 ? undefined : duration;

    return (
      <div className='toast-container'>
        <StyledSnackBar
          {...{ autoHideDuration }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          className={variant}
          open={isOpen}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={
            <span className='message' id={id}>
              {content}
            </span>
          }
          action={[
            // @ts-ignore
            <IconButton key='close' aria-label='Close' color='inherit' onClick={this.handleClose} size='large'>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

export { Toast };

import { Observer } from 'mobx-react';
import * as React from 'react';

import { organizersStore } from 'stores';

import { AccountInfo } from '../components/Detail/AccountInfo';

import { editModalStore } from '../stores';

let AccountInfoComponent = () => {
  return (
    <Observer>
      {() => {
        const { modelSelected } = organizersStore;

        if (!modelSelected) {
          return null;
        }

        return <AccountInfo value={modelSelected} onEdit={() => editModalStore.on()} />;
      }}
    </Observer>
  );
};

export { AccountInfoComponent as AccountInfo };

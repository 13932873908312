import { BooleanStore } from './boolean';
import confirmationModalStore, { ConfirmationModal } from './confirmationModal';
import countriesStore, { Country } from './countries';
import couponsStore, { Coupon } from './coupons';
import currenciesStore, { Currency } from './currencies';
import customersStore, { Customer } from './customers';
import { DummyStore } from './dummy';
import errorsStore, { Errors } from './errors';
import { helperDistancesStore, HelperDistances } from './helperDistances';
import helperOrganizersStore, { HelperOrganizer } from './helperOrganizers';
import { helperRacesStore, HelperRace } from './helperRaces';
import hostsStore from './hosts';
import loadProgress, { LoadProgress } from './loadProgress';
import { Loadable } from './loadable';
import localeStore, { Locale } from './locale';
import organizersStore, { Organizer } from './organizers';
import progressStore, { Progress } from './progress';
import racesStore, { Race } from './races';
import sessionStore, { Session } from './session';
import { sidebarStore } from './sidebar.store';
import sportTypesStore, { SportType } from './sportTypes';
import sportTypesAuxiliaryStore, { SportTypeAuxiliary } from './sportTypesAuxiliary';
import squadsStore, { Squads } from './squads';
import toastStore, { Toast } from './toast';

// import { BooleanStore } from './booleanStore';
export * from './sort';
export * from './search';
export * from './windowSize';
export * from './messages';
export * from './form';
export * from './booleanStore';
export * from './disposers';
export * from './availableLocales';
export * from './searchSelect';

export const isMonitorJob = new BooleanStore();

export {
  ConfirmationModal,
  confirmationModalStore,
  BooleanStore,
  DummyStore,
  sessionStore,
  errorsStore,
  progressStore,
  toastStore,
  localeStore,
  sportTypesStore,
  sportTypesAuxiliaryStore,
  racesStore,
  helperOrganizersStore,
  helperRacesStore,
  organizersStore,
  customersStore,
  currenciesStore,
  sidebarStore,
  Progress,
  Session,
  Errors,
  Toast,
  Locale,
  Loadable,
  SportType,
  SportTypeAuxiliary,
  Race,
  HelperOrganizer,
  HelperRace,
  Coupon,
  Currency,
  couponsStore,
  helperDistancesStore,
  HelperDistances,
  Country,
  countriesStore,
  Organizer,
  Customer,
  loadProgress,
  LoadProgress,
  hostsStore,
  Squads,
  squadsStore,
};

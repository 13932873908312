import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Show } from 'components/Condition';
import { SearchSelect, InputWrapper, WrappedCheckbox } from 'components/Form';

import { t } from 'utils';

import { isNewFormValue } from '../../utils';

import { loadSourceEmails, loadTargetEmails } from '../../actions';

import { sourceEmailStore, targetEmailStore } from '../../stores';

type Props = {
  show: boolean;
};

@observer
export class FieldsMergeAccounts extends React.Component<Props> {
  onInputChangeSource = (event, newInputValue, name) => {
    if (isNewFormValue(newInputValue, name)) {
      loadSourceEmails(newInputValue);
    }
  };

  onInputChangeSourceTarget = (event, newInputValue, name) => {
    if (isNewFormValue(newInputValue, name)) {
      loadTargetEmails(newInputValue);
    }
  };

  render() {
    const { show } = this.props;

    return (
      <Show if={show}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputWrapper
              name='sourceEmail'
              settings={{
                required: true,
                label: t.staticAsString('supportTaskManager.form.sourceDistanceEmail'),
                additional: {
                  options: sourceEmailStore.value,
                },
                view: {
                  variant: 'outlined',
                },
                onInputChange: this.onInputChangeSource,
              }}
              Component={SearchSelect}
            />
          </Grid>

          <Grid item xs={6}>
            <InputWrapper
              name='targetEmail'
              settings={{
                required: true,
                label: t.staticAsString('supportTaskManager.form.targetDistanceEmail'),
                additional: {
                  options: targetEmailStore.value,
                },
                view: {
                  variant: 'outlined',
                },
                onInputChange: this.onInputChangeSourceTarget,
              }}
              Component={SearchSelect}
            />
          </Grid>

          <Grid item xs={12}>
            <InputWrapper
              name='deleteSourceAccount'
              settings={{
                required: true,
                label: t.staticAsString('supportTaskManager.form.deactivateAccountAfter'),
              }}
              Component={WrappedCheckbox}
            />
          </Grid>
        </Grid>
      </Show>
    );
  }
}

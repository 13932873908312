import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { COUPON_URL, COUPONS_URL } from 'src/constants';

import { request, t } from 'utils';

import { errorsStore, form, toastStore } from 'stores';

import { COUPONS_FORM_ID } from '../constants';

import { mapCouponToForm, mapErrorsBE } from '../utils';

import { couponFormModalStore } from '../stores';

class CouponService {
  @request({ action: COUPONS_FORM_ID })
  async loadCoupon(id: number): Promise<void> {
    try {
      const url = generatePath(COUPON_URL, { id });
      const response = await axios.get(url, { params: { with: 'distances' } });
      const formData = await mapCouponToForm(response.data.data);
      form.merge(COUPONS_FORM_ID, formData);
      couponFormModalStore.set({
        ...couponFormModalStore.value!,
        coupon: formData,
      });
    } catch (err) {
      toastStore.show(Object.values(err.data.errors).join(' '));
    }
  }

  @request({ action: COUPONS_FORM_ID })
  async createCupon(data: AnyObject): Promise<any> {
    try {
      const response = await axios.post<CouponType>(generatePath(COUPONS_URL), data);
      toastStore.show(t('coupons.createSuccess'));
      return response;
    } catch (err) {
      errorsStore.add(COUPONS_FORM_ID, mapErrorsBE(err.response.data.errors));
      toastStore.show(Object.values(err.data.errors).join(' '));
    }
  }

  @request({ action: COUPONS_FORM_ID })
  async updateCoupon(id: number, data: AnyObject): Promise<any> {
    try {
      const response = await axios.patch<CouponType>(generatePath(COUPON_URL, { id }), data);
      toastStore.show(t('coupons.updateSuccess'));
      return response;
    } catch (err) {
      errorsStore.add(COUPONS_FORM_ID, mapErrorsBE(err.response.data.errors));
      toastStore.show(Object.values(err.data.errors).join(' '));
    }
  }
}

export const couponFormService = new CouponService();

import { AddRaceMenu } from 'modules/Races/components/List/Toolbar/AddRaceMenu';
import React from 'react';

import { t } from 'utils';

type Props = {
  onAddNewRace: (e: React.SyntheticEvent) => void;
  onAddNewCalendarRace: (e: React.SyntheticEvent) => void;
};

const Toolbar = ({ onAddNewRace, onAddNewCalendarRace }: Props) => {
  return (
    <div className='toolbar'>
      <h1 className='title'>{t.staticAsString('races.list.header')}</h1>
      <AddRaceMenu onAddNewRaceIdRace={onAddNewRace} onAddNewCalendarRace={onAddNewCalendarRace} />
    </div>
  );
};

export { Toolbar };

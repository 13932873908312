import { ROUTES } from 'src/constants';

import { history } from 'utils';

import { helperDistancesService, loadCountryService } from 'services';

import { helperDistancesStore } from 'stores';

import { membersTeamService } from '../services';
import memberService from '../services/membersService';

import { startlistMembersStore } from '../stores';
import { membersTeamStore } from '../stores';

async function mount(_race_id: string, distance_id: string) {
  const distanceId = +distance_id;

  loadCountryService.loadResources();
  helperDistancesService.loadRaceDistance(distanceId);
  memberService.loadResources(distanceId);
  membersTeamService.loadTeamsForMembers(distanceId);
}

async function onChangeDistance(race_id: string, distance_id: string) {
  const distanceId = +distance_id;
  const raceId = +race_id;

  const { selected } = helperDistancesStore;
  const selectedDistance = (selected?.race?.distances || []).find((distance) => distance.id === distanceId);

  if (!selectedDistance) {
    return;
  }

  const type = selectedDistance.type === 'team' ? 'teams' : 'racers';

  startlistMembersStore.clearAll();
  history.push(`${ROUTES.racesRoute}/${raceId}/distances/${distanceId}/${type}`);
}

async function unmount() {
  startlistMembersStore.clearAll();
  membersTeamStore.clearAll();
}

const membersLifecycle = {
  mount,
  unmount,
  onChangeDistance,
};

export { membersLifecycle };

import { toJS } from 'mobx';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import shortid from 'shortid';

import { ROUTES } from 'src/constants';

import { NavToolbar } from 'components/NavToolbar';

import { t } from 'utils';

import { Distance as DistanceType } from 'models';

type Props = {
  distance: DistanceType;
  match?: AnyObject;
};

@withRouter
export class Breadcrumbs extends React.Component<Props> {
  items = (): Array<NavToolbarItemType> => {
    const { distance } = this.props;
    const race: AnyObject = distance.race;

    return [
      { key: shortid(), path: ROUTES.racesRoute, label: t.staticAsString('races.detail.nav.races') as any },
      { key: shortid(), path: `${ROUTES.racesRoute}/${race?.value?.id}`, label: race.value.name },
      { key: shortid(), label: distance.value.name },
    ];
  };
  render() {
    return <NavToolbar items={this.items()} />;
  }
}

import { Button } from '@mui/material';
import { toString } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router-dom';

import { TEAM } from 'src/constants';

import { withErrorClean } from 'hocs';

import { Show } from 'components/Condition';
import { SelectField, SelectWithSearch } from 'components/Fields';
import { Input } from 'components/Fields/Input';

import { oneFieldValidation, t, validation } from 'utils';

import { Errors as ErrorsStore } from 'stores';

import localTeamService from '../../../../services/teamsService';

import { team as createTeamConstraints, teamValidation } from '../../../../validations';

type Props = {
  errorsStore: ErrorsStore;
  distance: DistanceType;
  onClose: () => void;
  onSubmit: () => void;
} & RouterProps;

type State = {
  team: TeamType;
};

const action = `CREATE_${TEAM}`;

// @ts-ignore
@withRouter
@withErrorClean(action)
@inject('errorsStore', 'helperDistancesStore')
@observer
class TeamForm extends React.Component<Props, State> {
  team: TeamType = {
    id: 0,
    distance_id: 0,
    name: '',
    class_id: null,
    wave_id: null,
  };

  state = {
    team: this.team,
  };

  @validation({ action, constrains: createTeamConstraints })
  validate() {
    return this.state.team;
  }

  onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { distance } = this.props;
    const { team } = this.state;
    const isValidated = await this.validate();
    const teamData: AnyObject = Object.keys(team).reduce((result, key) => {
      if (key === 'bib_number')
        return {
          ...result,
          [key]: Number(team[key]),
        };

      if (team[key]) {
        return {
          ...result,
          [key]: team[key],
        };
      }

      return result;
    }, {});

    if (!isValidated || !!this.errors().length) {
      return;
    }

    localTeamService.createResource(teamData as TeamType, distance.id, () => this.props.onClose());
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const name = e.target.name;

    if (name === 'bib_number' && value && /^0/.test(toString(value))) {
      return;
    }

    this.setState(
      {
        ...this.state,
        team: {
          ...this.state.team,
          [name]: value,
        },
      },
      () => {
        const constrainsField = { [name]: teamValidation[name] };
        oneFieldValidation(action, this.state.team, constrainsField);
      },
    );
  };

  errors = () => {
    const { errorsStore } = this.props;
    const errors = errorsStore.errors.api[action];
    const validationErrors = errorsStore.errors.validation[action];

    if (validationErrors) {
      return validationErrors.formatErrors();
    }
    if (errors) {
      return errors.formatErrors();
    }
    return {};
  };

  defaultInputProps = (name: TeamKeys) => {
    return {
      name,
      value: this.state.team[name],
      onChange: this.onChange,
      errors: this.errors()[name],
    };
  };

  renderClassesForSelect = () => {
    const { classes } = this.props.distance;

    return classes?.map((classItem) => ({
      key: classItem.id,
      value: classItem.id,
      label: classItem.title,
    }));
  };

  renderWavesForSelect = () => {
    const { distance } = this.props;

    return distance.waves?.map((wave: WaveType) => ({
      key: wave.id,
      value: wave.id,
      label: wave.name,
    }));
  };

  render() {
    const { onClose, distance } = this.props;
    const wavesOptions = this.renderWavesForSelect() ?? [];

    return (
      <div className='content main-racer-form'>
        <h1 className='racer-title'>{t.staticAsString('teams.addNew')}</h1>
        <div className='racer-form-element'>
          <Input {...this.defaultInputProps('name')} fullWidth label={`${t.staticAsString('teams.new.name') as any}*`} />
        </div>
        <div className='racer-form-element'>
          <Input {...this.defaultInputProps('bib_number')} fullWidth label={t.staticAsString('racers.new.bib') as any} />
        </div>
        {Number(distance.classes?.length) > 0 && (
          <div className='racer-form-element z-index-5'>
            <SelectWithSearch
              {...this.defaultInputProps('class_id')}
              options={this.renderClassesForSelect()}
              label={`${t.staticAsString('teams.new.class') as any}*`}
            />
          </div>
        )}
        <Show if={wavesOptions.length > 0}>
          <div className='racer-form-element'>
            <SelectField
              fullWidth
              {...this.defaultInputProps('wave_id')}
              options={wavesOptions}
              label={t.staticAsString('teams.new.waves')}
            />
          </div>
        </Show>
        <div className='btn-group'>
          <Button className='cancel' onClick={() => onClose()}>
            {t.staticAsString('teams.new.cancel')}
          </Button>
          <Button className='submit' onClick={(e) => this.onSubmit(e)}>
            {t.staticAsString('teams.new.submit')}
          </Button>
        </div>
      </div>
    );
  }
}

export { TeamForm };

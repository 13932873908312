import classNames from 'classnames';
import { isNil } from 'lodash';
import * as React from 'react';

import { duration } from 'utils';

import { TextField } from '../TextField';
import { timeDurationMask, parseTimeDurationToDiff } from '../core/mask';

type Props = {
  additional?: TextFieldAdditionalProps;
  view?: BaseViewProps;
  value: nil | number;
} & FieldBaseProps;

/*
 * Input:
 * - number
 *
 * Output:
 * - number      - Valid value, diff (number of milliseconds)
 * - null        - Empty value
 * - undefined   - Not a valid value
 *
 * Edit range - 00:00:00 - 99:59:59
 */
function TimeDuration(props: Props) {
  const formattedValue = isNil(props.value) ? '' : duration.time(props.value, true);

  const [localState, setState] = React.useState(formattedValue);

  return (
    <TextField
      {...props}
      className={classNames('input-mask', props.className)}
      additional={{
        InputProps: { inputComponent: timeDurationMask },
        ...(props.additional || {}),
      }}
      value={localState}
      onChange={({ name, value }, e) => {
        const parsedTimeDiff = parseTimeDurationToDiff(value);

        setState(value);
        props.onChange({ name, value: parsedTimeDiff }, e);
      }}
    />
  );
}

export { TimeDuration, TimeDuration as TimeDurationField };

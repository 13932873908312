import { Card, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement } from 'react';

import { Icon } from 'components/Icon';

import { t } from 'utils/t';

import { prepareItemsTable, setTransactionType } from '../utils/prepareItemsTable';

import { TransactionStore } from '../stores/transaction.store';

import ItemsTable from './ItemsTable';
import TransactionDate from './TransactionDate';
import TransactionStatus from './TransactionStatus';

interface Props {}
const useStyles = makeStyles({
  txTitle: {
    color: 'rgb(38, 46, 53)',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  subInfo: {
    display: 'flex',
  },
  subInfoUnit: {
    marginRight: 64,
  },
  typography: {
    marginBottom: '15px',
  },
  title: {
    color: 'rgb(38, 46, 53)',
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  indicator: {
    color: 'rgb(168, 174, 184)',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  content: {
    color: 'rgb(38, 46, 53)',
    fontSize: '16px',
  },
});
export default function TransactionInfo({}: Props): ReactElement {
  const c = useStyles();
  const tx = TransactionStore.transaction.value;
  const receiptUrl = tx?.payment?.order?.receipt?.url ?? '';

  const formatCurrency = (value: number) => `${value.toFixed(2)} ${tx?.currency}`;
  const formatVat = (value: number) => `${value}%`;

  const title = (prefix: string) => t.staticAsString(`reconciliation.transaction.${prefix}` as any);
  if (tx === null) return <div />;
  return (
    <>
      <Card style={{ padding: '26px 16px', marginBottom: 24 }} variant='outlined'>
        <section style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 24 }}>
              <Typography className={c.txTitle}>
                {title('transaction')}&nbsp;#{tx.id}
              </Typography>
              <Typography>
                {title('raceId')}&nbsp;{tx.payment.order.race_id}
              </Typography>
            </div>
            <div style={{ marginRight: tx.type === 4 ? 8 : 0 }}>
              <TransactionStatus status={tx.status === 3 ? 'failed' : 'completed'} />
            </div>
            <div>
              {/* {tx.type === 4 && <TransactionStatus status={tx.payment_info.is_fully_refunded ? 'refunded' : 'partiallyRefunded'} />} */}
            </div>
            <div
              style={{
                margin: '8px 16px 0',
                width: '1px',
                height: '24px',
                background: 'rgba(168, 174, 184, 0.48)',
              }}
            />
            <div style={{ marginRight: 8 }}>
              <TransactionDate datetime={tx.created_at} />
            </div>
          </div>
          <div style={{ marginRight: 8 }}>
            <a href={receiptUrl} download>
              <Icon value='pdf' />
            </a>
          </div>
        </section>

        <Typography className={c.title}>{title('generalInformation')}</Typography>
        <section className={c.subInfo}>
          <div className={c.subInfoUnit}>
            <Typography className={c.typography}>
              <span className={c.indicator}>{title('name')}</span>{' '}
              <span className={c.content}>
                {tx.payment.order.customer.firstname} {tx.payment.order.customer.lastname}
              </span>
            </Typography>
            <Typography className={c.typography}>
              <span className={c.indicator}>{title('email')}</span> <span className={c.content}>{tx.payment.order.customer.email}</span>
            </Typography>
          </div>
          <div className={c.subInfoUnit}>
            <Typography className={c.typography}>
              <span className={c.indicator}>{title('currency')}</span> <span className={c.content}>{tx.payment.currency}</span>
            </Typography>
            <Typography className={c.typography}>
              <span className={c.indicator}>{title('paymentMethod')}</span>{' '}
              <span className={c.content}>{tx.payment.provider === 1 ? 'PayEx' : 'Stripe'}</span>
            </Typography>
          </div>
        </section>
      </Card>
      <>
        <Typography className={c.title}>{title('listOfItems')}</Typography>
        <ItemsTable
          columns={[
            { title: title('item'), accessor: 'title' },
            { title: title('totalVatExcluded'), accessor: 'vat_excl', fn: formatCurrency },
            { title: title('vat'), accessor: 'vat_value', fn: formatVat },
            { title: title('vatSum'), accessor: 'vat_amount', fn: formatCurrency },
            { title: title('totalVatIncluded'), accessor: 'price', fn: formatCurrency },
          ]}
          withTotal
          data={prepareItemsTable(tx)}
        />
      </>
      <>
        <Typography className={c.title}>{title('transactions')}</Typography>
        <ItemsTable
          columns={[
            { title: title('externalId'), accessor: 'uuid' },
            { title: title('descriptionTitle'), accessor: 'description' },
            { title: title('date'), accessor: 'created_at' },
            { title: title('totalSum'), accessor: 'amount', fn: formatCurrency },
            { title: title('transactionType'), accessor: 'type' },
          ]}
          data={tx.payment.transactions.map((transaction) => ({ ...transaction, type: setTransactionType(transaction.type) })) as any[]}
        />
      </>
      {/* {tx.payment_info.transactions && (
        <>
          <Typography className={c.title}>Transactions</Typography>
          <TransactionsTable
            columns={[
              { title: 'External ID', accessor: 'payment_provider' },
              { title: 'Description/title', accessor: 'description' },
              { title: 'Date', accessor: 'created_at' },
              { title: 'Total sum', accessor: 'amount' },
              { title: 'Transaction type', accessor: 'transaction_type' },
            ]}
            data={tx.payment_info.transactions}
          />
        </>
      )} */}
    </>
  );
}

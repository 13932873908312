import { DUPLICATE_FORM } from 'modules/RaceDetails/constants';
import { duplicateModalStore } from 'modules/RaceDetails/stores';

import { form } from 'stores';

export const duplicateFormInit = (type: 'race' | 'distance', distanceId?: number, participantsCount?: number) => {
  form.merge(DUPLICATE_FORM, { include_participants: false });
  duplicateModalStore.set({
    open: true,
    type,
    distanceId,
    participantsCount,
  });
};

import { generateOnExportReceipt, onExportPaymentsList, generateOnExportPaymentDetail } from 'modules/exportPayment/actions';
import { createContext } from 'react';

import { mount, unmount, mountDetails, unmountDetails } from '../actions';

import { loadService, detailsService } from '../services';

import { paymentsStore, detailsStore } from '../stores';

import { getBreadcrumbs } from '../selections';

export type ContextType = {
  payments: {
    actions: {
      mount: typeof mount;
      unmount: typeof unmount;
      generateOnExportReceipt: typeof generateOnExportReceipt;
      onExportPaymentsList: typeof onExportPaymentsList;
    };
    services: {
      loadService: typeof loadService;
    };
    stores: {
      paymentsStore: typeof paymentsStore;
    };
  };
  details: {
    actions: {
      mountDetails: typeof mountDetails;
      unmountDetails: typeof unmountDetails;
      generateOnExportPaymentDetail: typeof generateOnExportPaymentDetail;
    };
    services: {
      detailsService: typeof detailsService;
    };
    stores: {
      detailsStore: typeof detailsStore;
    };
    selections: {
      getBreadcrumbs: typeof getBreadcrumbs;
    };
  };
};

const ContextData: ContextType = {
  payments: {
    actions: {
      mount,
      unmount,
      generateOnExportReceipt,
      onExportPaymentsList,
    },
    services: {
      loadService,
    },
    stores: {
      paymentsStore,
    },
  },
  details: {
    actions: {
      mountDetails,
      unmountDetails,
      generateOnExportPaymentDetail,
    },
    services: {
      detailsService,
    },
    stores: {
      detailsStore,
    },
    selections: {
      getBreadcrumbs,
    },
  },
};
const Context = createContext<ContextType>(ContextData);

export { Context, ContextData };

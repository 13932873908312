import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const commonStyles = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.grey10}`,
});

export const useTranslationTableStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginBottom: 16,
  },
  titlePanel: {
    ...commonStyles(theme),
    height: 48,
    padding: 14,
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    background: theme.palette.white2,
  },
  childPanel: {
    ...commonStyles(theme),
    color: theme.palette.grey1,
    fontSize: 16,
    fontWeight: 'normal',
    padding: '12px 16px',
    textOverflow: 'ellipsis',
    marginTop: -1,
  },
  childTitle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingRight: '30px',
  },
  arrowIcon: {
    fill: theme.palette.grey6,
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.red5,
    height: 16,
    width: 16,
    borderRadius: '50%',
    color: theme.palette.white4,
    fontWeight: 'bold',
    fontSize: 10,
  },
  badgeSuccess: {
    background: '#66bb6a',
  },
}));

export const useWrapperStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.grey1,
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
  },
}));

import { Observer } from 'mobx-react';
import React, { useCallback } from 'react';

import { t } from 'utils/t';

import { campaignLinkDetailsService } from '../services/campaignLinksDetails.service';

import { campaignLinkOrderStore } from '../stores';

import { filtersToBackend } from '../mapper';
import { TransactionsTable } from './Table';
import { generateTransactionColumns } from './Table/Columns';

interface Props {
  token: string;
}

export const Transactions: React.FC<Props> = ({ token }) => {
  const fetchTxs = useCallback(({ pageIndex = 0, pageSize, filters, sortBy }) => {
    let orderBy = 'created_at';
    let sortedBy = 'DESC';
    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      switch (id) {
        case 'customer.firstname':
          orderBy = id.split('.').join('s|');
          break;
        case 'customer.lastname':
          orderBy = id.split('.').join('s|');
          break;
        case 'customer.email':
          orderBy = id.split('.').join('s|');
          break;
        case id.includes('.'):
          orderBy = id.split('.').join('|');
        default:
          orderBy = id;
      }
      sortedBy = desc ? 'DESC' : 'ASC';
    }

    campaignLinkDetailsService.loadCampaignLinkOrders(token, {
      page: pageIndex + 1,
      limit: pageSize,
      orderBy,
      sortedBy,
      ...filtersToBackend(filters),
    });
  }, []);

  return (
    <>
      <h1 style={{ paddingLeft: 24, paddingTop: 38, paddingBottom: 30 }}>{t.staticAsString('coupons.detail.listOfRegistrations')}</h1>
      <div style={{ padding: 0, backgroundColor: 'white', overflow: 'auto' }}>
        <Observer
          render={() => {
            const tableData = campaignLinkOrderStore.value?.data.map((order) => {
              return order;
            });
            return (
              <TransactionsTable
                tableData={tableData ?? []}
                tableMeta={campaignLinkOrderStore.value?.meta ?? {}}
                initialSizeOfPage={30}
                pageSizeOptions={[30, 50, 100, 300]}
                tableColumns={generateTransactionColumns()}
                fetchTxs={fetchTxs}
              />
            );
          }}
        />
      </div>
    </>
  );
};

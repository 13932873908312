import { openIntegrationForm } from 'modules/IntegrationForm/actions';
import { generatePath } from 'react-router-dom';

import { RACE_INTEGRATIONS, ROUTES } from 'src/constants';

import { history } from 'utils';

import { integrationExistStore, raceDetailsStore } from '../stores';

export const onClickIntegration = (): void => {
  const raceId = Number(raceDetailsStore.race?.id);
  const url = generatePath(ROUTES.integrations, { id: raceId });

  if (!integrationExistStore.value) {
    openIntegrationForm(raceId, null, () => {
      history.push(url);
    });
  } else {
    history.push(url);
  }
};

import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { detailsStore } from '../stores';

export const getBreadcrumbs: IComputedValue<Array<Object>> = computed((): Array<Object> => {
  const label = detailsStore.orderDetails.id;

  return [
    {
      key: `breadcrumb:${label}`,
      label,
    },
  ];
});

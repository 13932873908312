import { pick, values } from 'lodash';
import moment from 'moment';

import { helperRacesStore } from 'stores';

import { dateCols } from '../types';
import { findDate, todayStart } from './date';
import { generateRestrictionFields } from './dateRestrictionScheme';

type MomentRange = { fromCol: dateCols | nil; fromValue: nil | moment.Moment; toCol: dateCols | nil; toValue: nil | moment.Moment } | nil;

function generateMomentRangeFor(col: dateCols, distance: DistanceType | AnyObject): MomentRange {
  const raceModel = helperRacesStore.modelSelected;

  if (!raceModel) {
    return null;
  }

  const scheme: {
    [K in dateCols]: nil | string | moment.Moment;
  } = {
    registration_starts_at: distance.registration_starts_at,
    registration_ends_at: distance.registration_ends_at,
    race_date: distance.race_date,
    ends_at: distance.ends_at,
    today: todayStart() as any,
    race_start: raceModel.value.race_date,
    race_end: raceModel.value.race_end_date,
  };

  const { fromCols, toCols } = generateRestrictionFields(col, distance);

  let [fromCol, fromValue] = findDate('latest', pick(scheme, fromCols));
  let [toCol, toValue] = findDate('earliest', pick(scheme, toCols));

  return { fromCol, fromValue, toCol, toValue };
}

export { generateMomentRangeFor };

import { StoreOption } from '../types';

// Store columns from updateOptions by specific columns
// TODO: add proper type for data, response data
const storeData = async function (data: { [K in string]: any }, updateOptions: Array<StoreOption>) {
  // @ts-ignore
  const store = this.store as ILoadable<any, any>;

  updateOptions.forEach((updateData) => {
    const value = data[updateData.property];
    store.setValueByKey(updateData.storeId, value);
  });
};

export { storeData };

import moment from 'moment';

import { dashboardRacesStore } from '../stores/races.store';

// format date as YYYY-MM-DD HH:MM:SS using moment
const formatDate = (date: Date) => {
  return moment(date).format('YYYY-MM-DD HH:mm:ss');
};

export function lastYear() {
  const year = new Date().getFullYear();
  const to = new Date();
  const from = new Date(year - 1, 0, 1);

  // to.setHours(23, 59, 59, 999);
  return { from: formatDate(from), to: formatDate(to) };
}

export function lastMonths(numberOfMonths = 6) {
  const today = new Date();
  const from = new Date(today.setMonth(today.getMonth() - numberOfMonths));
  const to = new Date();
  // to.setHours(23, 59, 59, 999);
  return { from: formatDate(from), to: formatDate(to) };
}

export function lastDays(numberOfDays = 7) {
  const today = new Date();
  const from = new Date(today.setDate(today.getDate() - numberOfDays));
  const to = new Date();
  return { from: formatDate(from), to: formatDate(to) };
}

export function currentRaceCreatedAt() {
  const race = dashboardRacesStore.selectedRace;
  if (race) {
    return race.created_at;
  }
}

dashboardRacesStore;

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { EDITOR_SETTINGS_URL } from 'src/constants';

import { action, request } from 'utils';

import { CREATE_EDITOR_SETTINGS, GET_EDITOR_SETTINGS, UPDATE_EDITOR_SETTINGS } from '../constants';

import { EditorSettingsType } from '../types';

class EditorSettings {
  @request({ action: GET_EDITOR_SETTINGS })
  getEditorSettingsRequest(id: number | string): Promise<any> {
    const receiptSettingsPath = generatePath(EDITOR_SETTINGS_URL, { id });

    return axios.get(receiptSettingsPath, id as any);
  }

  @action({ action: GET_EDITOR_SETTINGS })
  async getEditorSettings(id: number | string): Promise<any> {
    const [status, response] = await this.getEditorSettingsRequest(id);

    return [status, response?.data?.data[0]?.tab_options];
  }

  @request({ action: UPDATE_EDITOR_SETTINGS })
  updateEditorSettingsRequest(id: number | string, data: EditorSettingsType): Promise<any> {
    const receiptSettingsPath = generatePath(EDITOR_SETTINGS_URL, { id });
    return axios.patch(receiptSettingsPath, data);
  }

  @action({ action: UPDATE_EDITOR_SETTINGS })
  async updateEditorSettings(id: number | string, data: EditorSettingsType): Promise<any> {
    const [status, response] = await this.updateEditorSettingsRequest(id, data);

    return [status, response];
  }

  @request({ action: CREATE_EDITOR_SETTINGS })
  createEditorSettingsRequest(id: number | string, data: EditorSettingsType): any {
    const receiptSettingsPath = generatePath(EDITOR_SETTINGS_URL, { id });
    return axios.post(receiptSettingsPath, data);
  }

  @action({ action: CREATE_EDITOR_SETTINGS })
  async createEditorSettings(id: number | string, data: EditorSettingsType): Promise<any> {
    const [status, response] = await this.createEditorSettingsRequest(id, data);

    return [status, response];
  }
}

export { EditorSettings };
export default new EditorSettings();

import { Icon } from 'components/Icon';

import { t } from 'utils/t';

import { InfoMessageStyles } from './styles';

export const InfoMessage = () => {
  const classes = InfoMessageStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.topWrapper}>
        <span className={classes.iconWrapper}>
          <Icon value='smallInfo' />
        </span>
        <span className={classes.infoMessage}>{t.staticAsString('races.new.organizerInfo')}</span>
      </div>
      <p>{t.staticAsString('races.new.organizerInfoMessage')}</p>
    </div>
  );
};

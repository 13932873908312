import { START_LIST } from 'src/constants';

export const LOAD_MEMBERS_TEAMS = 'LOAD_MEMBERS_TEAMS';
export const LOAD_MEMBERS_TEAM = 'LOAD_MEMBERS_TEAM';
export const LOAD_CUSTOMER_PROFILE = 'LOAD_CUSTOMER_PROFILE';

export const ATTACH_TO_PROFILE_FORM = 'ATTACH_TO_PROFILE_FORM';
export const SEND_ATTACH_TO_PROFILE = 'SEND_ATTACH_TO_PROFILE';

export const UPDATE_RACER = `UPDATE_${START_LIST}`;
export const IMPORT_START_LIST = 'IMPORT_START_LIST';

import { defaultListFilters } from 'utils';

import { FilterDescriptorWrapper } from 'models';

import { PaymentColumns } from '../types';
import { countryId } from './countryId';
import { gender, state } from './dropdown';
import { created_time } from './time';

export const paymentDescriptors: {
  [K in PaymentColumns]: FilterListDescriptor;
} = {
  id: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      default: true,
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },
  ref_number: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },
  payment_external_id: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },
  created_at: {
    filter: defaultListFilters.dateRange,
    frontend: { type: 'dateRange' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  created_time,

  organizer_id: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  race_id: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  race_title: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  distance_id: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  distance_title: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  email: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: () => 'email',
    },
  },

  firstname: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: () => 'firstname',
    },
  },

  lastname: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: () => 'lastname',
    },
  },

  gender,

  birthday: {
    filter: defaultListFilters.dateRange,
    frontend: { type: 'dateRange' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: () => 'birthday',
    },
  },

  address: {
    filter: defaultListFilters.none,
    frontend: { type: 'none' },
  },

  post_code: {
    filter: defaultListFilters.none,
    frontend: { type: 'none' },
  },

  city: {
    filter: defaultListFilters.none,
    frontend: { type: 'none' },
  },

  country_id: countryId,

  distance_original_price: {
    filter: defaultListFilters.priceRange,
    frontend: { type: 'numberRange' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },
  discount: {
    filter: defaultListFilters.priceRange,
    frontend: { type: 'numberRange' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },
  custom_fields_sum: {
    filter: defaultListFilters.priceRange,
    frontend: { type: 'numberRange' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },
  total: {
    filter: defaultListFilters.priceRange,
    frontend: { type: 'numberRange' },
    sort: {
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  distance_vat_value: {
    filter: defaultListFilters.none,
    frontend: { type: 'none' },
  },

  status: state,

  receipt: {
    filter: defaultListFilters.none,
    frontend: { type: 'none' },
  },
};

export const filterDescriptors = Object.keys(paymentDescriptors).reduce((acc, desc) => {
  return {
    ...acc,
    [desc]: new FilterDescriptorWrapper(desc, paymentDescriptors[desc as PaymentColumns]),
  };
}, {});

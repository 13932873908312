import * as React from 'react';

import {
  CheckPointName,
  CoordinateName,
  WrapperCheckPointCoordinate,
  WrapperCheckPointName,
  WrapperOneCheckPoint,
} from 'src/modules/Distances/components/shared/Steps/GPX/components/ManageCheckPointsModal/styled';
import { CheckPoint } from 'src/modules/Distances/components/shared/Steps/GPX/types';

import { t } from 'utils';

type Props = {
  checkpoint: CheckPoint;
};

export const OneCheckPointActive = (props: Props) => {
  const { checkpoint } = props;

  return (
    <WrapperOneCheckPoint>
      <WrapperCheckPointName>
        <CheckPointName>{checkpoint.name}</CheckPointName>
      </WrapperCheckPointName>

      <WrapperCheckPointCoordinate>
        <div>
          <CoordinateName>{t.staticAsString('distances.steps.helperText.gpx.chosePoint')}</CoordinateName>
        </div>
      </WrapperCheckPointCoordinate>
    </WrapperOneCheckPoint>
  );
};

import { omit } from 'lodash';

function find(fields: RegistrationField[], name: registrationFields): RegistrationField | void {
  return fields.find((item) => item.name === name);
}

function isSelected(fields: RegistrationField[], name: registrationFields): boolean {
  const field = find(fields, name);
  return !!(field && field.selected);
}

function isRequired(fields: RegistrationField[], name: registrationFields): boolean {
  const field = find(fields, name);
  return !!(field && field.required);
}

function omitNotSelectedFieldsValues(fields: RegistrationField[], value: Object) {
  const fieldsToOmit = fields.filter((field) => !field.selected).map((field) => field.name);
  return omit(value, fieldsToOmit);
}

function omitNotSelectedFields(fields: RegistrationField[]): RegistrationField[] {
  return fields.filter((field) => Boolean(field.selected));
}

export const registrationFieldsTools = {
  isSelected,
  isRequired,
  omitNotSelectedFields,
  omitNotSelectedFieldsValues,
};

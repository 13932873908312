import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { MAGIC_LINK_URL, MAGIC_LINK_ORDERS_URL } from 'src/constants';

import { action, request } from 'utils/core';

import { LOAD_SMART_LINK, LOAD_SMART_LINK_ORDERS } from '../constants';

import { smartLinkOrderStore } from '../stores';

import { TransactionsFilters } from '../types';

class SmartLinkDetailsService {
  @request({ action: LOAD_SMART_LINK })
  private loadSmartLinkRequest(token: string): any {
    const url = generatePath(MAGIC_LINK_URL, { token: token });
    return axios.get(url, { params: { with: 'race.currency' } });
  }

  @action({ action: LOAD_SMART_LINK })
  async loadSmartLink(token: string): Promise<any> {
    const [isOk, response] = await this.loadSmartLinkRequest(token);
    if (isOk) {
      return response.data.data;
    }
  }

  @request({ action: LOAD_SMART_LINK_ORDERS })
  private loadSmartLinkOrdersRequest(token: string, filters: TransactionsFilters): any {
    const url = generatePath(MAGIC_LINK_ORDERS_URL, { token: token });
    return axios.get(url, { params: filters });
  }

  @action({ action: LOAD_SMART_LINK_ORDERS })
  async loadSmartLinkOrders(token: string, filters: TransactionsFilters): Promise<any> {
    const [isOk, response] = await this.loadSmartLinkOrdersRequest(token, filters);
    if (isOk) {
      smartLinkOrderStore.set(response.data);
    }
  }
}

export const smartLinkDetailsService = new SmartLinkDetailsService();

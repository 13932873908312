import { LOAD_RACES, LOAD_RACE, CLEAN_RACE } from 'modules/Races/constants';

import { RACE, RACES_URL } from 'src/constants';

import { request, action } from 'utils';

import { LoadableService } from 'services';

import { racesStore } from 'stores';
import { Race as RaceStore } from 'stores';

const DEFAULT_PAGE = 1;

class LoadService extends LoadableService<RaceType, FiltersType, RaceStore> {
  constructor(store: RaceStore) {
    super({ store, resourcesUrl: RACES_URL, resource: RACE });
  }

  @request({ action: LOAD_RACES })
  async loadResourcesRequest(params: FiltersType): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @action({ action: LOAD_RACES })
  async loadResources(params: FiltersType = {}, pageNum: number = 1): Promise<any> {
    return super.loadResources(params, pageNum);
  }

  @request({ action: LOAD_RACES })
  async loadResourceRequest(id: string, params: FiltersType): Promise<any> {
    return super.loadResourceRequest.bind(this)(id, params);
  }

  @action({ action: LOAD_RACE, minRequestTime: 800 })
  async loadResource(id: string, params: FiltersType = {}): Promise<any> {
    return super.loadResource.bind(this)(id, params);
  }

  @action({ action: CLEAN_RACE })
  clean(): void {
    return super.clean.bind(this)();
  }

  async applySearch(callback: Function, ...searchParams: Array<FiltersType>) {
    const searchModel = this.store.retrieveSearchDataModel();

    searchParams.forEach((searchParam) => {
      searchModel.appendFilters(searchParam);
    });

    const paramsForStoring = searchModel.params() as any;
    searchModel.enableTrimEmptyArrayOption();
    const paramsForSending = searchModel.params();

    if (paramsForSending !== this.store.filters.search) {
      // This hack here is for race location filter,
      // It requires to search should be represented with 'race.location' instead of
      // 'race_location'
      if (typeof paramsForSending?.search === 'string') {
        paramsForSending.search = paramsForSending.search.replace('race_location', 'race.location');
      }
      await this.loadResources(paramsForSending || { search: '', searchFields: '' }, DEFAULT_PAGE);
      callback();
    }

    if (paramsForStoring && paramsForStoring.search && paramsForStoring.searchFields) {
      this.store.updateSearch(paramsForStoring.search, paramsForStoring.searchFields);
    }
  }

  async deleteFilters(callback: Function, ...filterNames: Array<string>) {
    const searchModel = this.store.retrieveSearchDataModel();
    searchModel.deleteFilters(...filterNames);

    // This hack here is for race location filter,
    // It requires to search should be represented with 'race.location' instead of
    // 'race_location'
    const paramsForSending = searchModel.params();
    if (typeof paramsForSending?.search === 'string') {
      paramsForSending.search = paramsForSending.search.replace('race_location', 'race.location');
    }

    await this.loadResources(paramsForSending || { search: '', searchFields: '' }, DEFAULT_PAGE);
  }

  clearFilters() {
    this.loadResources(
      {
        search: '',
        searchFields: '',
      },
      1,
    );
  }
}

export { LoadService };
export default new LoadService(racesStore);

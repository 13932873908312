import { createContext } from 'react';

import { DISTANCE_TYPE } from 'src/constants';

import * as actions from '../actions';

import {
  singleResultsService,
  teamResultsService,
  updateRacerService,
  updateResultService,
  updateSplitService,
  updateTeamService,
} from '../services';
import * as services from '../services';

import { singleResultsStore, teamResultsStore } from '../stores';
import * as stores from '../stores';

import * as derivations from '../derivations';
import { isColumnVisibleSingle, isColumnVisibleTeam } from '../tools';

export type ResultsContextType = {
  results: {
    derivations: typeof derivations;
    actions: typeof actions;
    stores: typeof stores;
    services: typeof services;

    single: {
      store: typeof singleResultsStore;
      service: typeof singleResultsService;
      isColumnVisible: typeof isColumnVisibleSingle;
    };
    team: {
      store: typeof teamResultsStore;
      service: typeof teamResultsService;
      isColumnVisible: typeof isColumnVisibleTeam;
    };
    updateServices: {
      racer: typeof updateRacerService;
      result: typeof updateResultService;
      split: typeof updateSplitService;
      team: typeof updateTeamService;
    };
  };
};

const resultsContextData = {
  results: {
    derivations,
    actions,
    stores,
    services,

    single: {
      store: singleResultsStore,
      service: singleResultsService,
      isColumnVisible: isColumnVisibleSingle,
    },
    team: {
      store: teamResultsStore,
      service: teamResultsService,
      isColumnVisible: isColumnVisibleTeam,
    },
    updateServices: {
      racer: updateRacerService,
      result: updateResultService,
      split: updateSplitService,
      team: updateTeamService,
    },
  },
};
const ResultsContext = createContext<ResultsContextType>(resultsContextData);

export { ResultsContext, resultsContextData };

import { TableHead, TableRow } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';
import shortid from 'shortid';

import { TableHeaderItem } from 'components/Filter/TableHeaderItem';

import { t } from 'utils';

import { COLUMN_WIDTH } from './columns';

const classes = {
  thead: ['squads-header'],
};

class THead extends React.Component {
  _renderThLabels = (cols: Array<string>): React.ReactNode => {
    return cols.map((field) => {
      const width = COLUMN_WIDTH[field];
      const className = `cell-${field}`;
      const translation = `squads.list.table.${field}`;

      return (
        <TableHeaderItem
          key={shortid.generate()}
          name={field}
          width={width}
          className={classNames(...classes.thead, className)}
          label={t(translation as TranslationLockedKeys) as any}
        />
      );
    });
  };

  render() {
    return (
      <React.Fragment>
        <TableHead>
          <TableRow>{this._renderThLabels(['name', 'action'])}</TableRow>
        </TableHead>
      </React.Fragment>
    );
  }
}

export { THead };

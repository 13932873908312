import { Box, TextField } from '@mui/material';
import React from 'react';

import { t } from 'utils';

import { FilterWrapper } from './FilterWrapper';

export const NumberRangeFilter = ({ column: { filterValue = [], setFilter } }) => {
  return (
    <FilterWrapper>
      <Box sx={{ maxWidth: '100px' }}>
        <TextField
          autoFocus
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val === '0' || val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          value={filterValue[0] === undefined ? '' : filterValue[0]}
          variant='outlined'
          size='small'
          label={t.staticAsString('races.filters.from')}
        />
      </Box>
      <Box sx={{ maxWidth: '100px' }}>
        <TextField
          value={filterValue[1] === undefined ? '' : filterValue[1]}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val === '0' || val ? parseInt(val, 10) : undefined]);
          }}
          variant='outlined'
          size='small'
          label={t.staticAsString('races.filters.to')}
        />
      </Box>
    </FilterWrapper>
  );
};

import { taskService } from '../services';

import { tasksStore } from '../stores';

export const loadTasks = async (
  params: {
    limit?: number;
    page?: number;
  } = {},
) => {
  let { page: storePage, params: storeParams, filters: storeFilters } = tasksStore;

  let { limit = storeFilters.limit, page = storePage } = params;

  if (params.limit) {
    page = 1;
  }

  const queryParams = {
    ...storeParams,
    page,
    limit,
  };

  const [status, { data, meta }] = await taskService.loadRacers(queryParams);

  if (!status) return;

  tasksStore.addValues(data, page, { ...storeFilters, limit });

  const paginationMeta = meta && meta.pagination;

  if (paginationMeta) {
    tasksStore.addPaginationMeta(paginationMeta);
  }
};

const allOption = () => ({
  key: 'all',
  value: 'all',
  label: 'All',
});

function isSelectedAll(selectedOptions: Array<any>, allOptionsLength: number) {
  const index = selectedOptions.findIndex((el) => el === 'all');

  const isAllOptionSelected =
    (index > 0 && allOptionsLength === 1) || (index > 0 && allOptionsLength > 1) || (index === 0 && selectedOptions.length === 1);

  if (isAllOptionSelected) {
    return true;
  }

  if (selectedOptions.length === 0) {
    return true;
  }

  return false;
}

function withAllOptions(options: SelectOption[]) {
  return [allOption(), ...options];
}

export { withAllOptions, allOption, isSelectedAll };

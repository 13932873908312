import React from 'react';

type Props = {
  children: React.ReactNode[] | React.ReactNode;
  index: number;
};

function Section({ children, index }: Props) {
  const remapedChildren = React.Children.map(children, (child, childIndex: number) => {
    const mark = childIndex <= 0 ? `${index}. ` : `${index}.${childIndex} `;

    return React.cloneElement(child as React.ReactElement, { index: mark });
  });

  return <div className='terms-section'>{remapedChildren}</div>;
}

export { Section };

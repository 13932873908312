import moment from 'moment';

import { t, time } from 'utils';

import { AMOUNT_TYPE_FIXED, AMOUNT_TYPE_ORDER, COUPON_DISCOUNT_TYPE_PERCENTAGE, TYPE_ORDER } from '../constants';

import {
  COPY_RACERS_JOB,
  DELETE_ACCOUNT_JOB,
  GENERATE_COUPON_JOB,
  MERGE_ACCOUNTS_JOB,
  REFUND_DISTANCE_JOB,
  REFUND_PAYMENT_JOB,
  optionsAmountTypes,
  GENERATE_BIB_JOB,
  REFUND_JOB,
  DUPLICATE_DISTANCE_JOB,
  DUPLICATE_RACE_JOB,
  REFUND_ORDER_JOB,
  REFUND_RACE_JOB,
} from '../utils';

const base: string = 'supportTaskManager.list.table';

const onMergeAccounts = (params: AnyObject): Array<string> => {
  const sourceEmail = t.staticAsString(`${base}.sourceEmail` as TranslationLockedKeys);
  const targetEmail = t.staticAsString(`${base}.targetEmail` as TranslationLockedKeys);
  const accountDeleted = t.staticAsString(`${base}.accountDeleted` as TranslationLockedKeys);
  const yes = t.staticAsString(`${base}.yes` as TranslationLockedKeys);
  const no = t.staticAsString(`${base}.no` as TranslationLockedKeys);
  const deleteSourceAccount = params.deleteSourceAccount === true ? yes : no;

  return [`${sourceEmail}: ${params.sourceEmail}`, `${targetEmail}: ${params.targetEmail}`, `${accountDeleted}: ${deleteSourceAccount}`];
};

const onDelete = (params: AnyObject): Array<string> => {
  const email = t.staticAsString(`${base}.email` as TranslationLockedKeys);

  return [`${email}: ${params.email}`];
};
const onCopyRacers = (params: AnyObject): Array<string> => {
  const sourceDistance = t.staticAsString(`${base}.sourceDistance` as TranslationLockedKeys);
  const targetDistance = t.staticAsString(`${base}.targetDistance` as TranslationLockedKeys);
  const startDate = t.staticAsString(`${base}.targetDistance` as TranslationLockedKeys);
  const finishDate = t.staticAsString(`${base}.finishDate` as TranslationLockedKeys);

  return [
    `${sourceDistance}: ${params.source_distance_id}`,
    `${targetDistance}: ${params.target_distance_id}`,
    `${startDate}: ${getDate(params.start_at)}`,
    `${finishDate}: ${getDate(params.end_at)}`,
  ];
};

const onRefund = (params: AnyObject, idName: string): Array<string> => {
  const amountTypeName = optionsAmountTypes().filter((item) => item.value === params.amountType)[0].label;
  const currency = params.currency ?? t.staticAsString(`${base}.defaultCurrency` as TranslationLockedKeys);
  const amountTypeLabel = params.amountType === AMOUNT_TYPE_FIXED ? currency : '%';
  let amount = `${params.amount} ${amountTypeLabel}`;

  if (params.amountType === AMOUNT_TYPE_ORDER) {
    amount = t.staticAsString(`${base}.full` as TranslationLockedKeys);
  }

  idName = t.staticAsString(`${base}.${idName}` as TranslationLockedKeys);

  const yes = t.staticAsString(`${base}.yes` as TranslationLockedKeys);
  const no = t.staticAsString(`${base}.no` as TranslationLockedKeys);
  const kept = params.deleteParticipants === true ? yes : no;
  const participantsKept = t.staticAsString(`${base}.participantsKept` as TranslationLockedKeys);

  const typeRefund = t.staticAsString(`${base}.typeRefund` as TranslationLockedKeys);
  const amountName = t.staticAsString(`${base}.amount` as TranslationLockedKeys);

  return [`${idName}: ${params.id}`, `${typeRefund}: ${amountTypeName}`, `${amountName}: ${amount}`, `${participantsKept}: ${kept}`];
};

const onRefundDistance = (params: AnyObject): Array<string> => {
  return onRefund(params, 'distanceId');
};

const onRefundSingle = (params: AnyObject): Array<string> => {
  return onRefund(params, 'orderId');
};

const onRefundJob = (params: AnyObject): Array<string> => {
  return onRefund(params, 'orderId');
};

const onGenerateCoupon = (params: AnyObject): Array<string> => {
  const distances =
    params.params && params.params.length > 0 ? params.params.join(',') : t.staticAsString(`${base}.all` as TranslationLockedKeys);

  const currency = params.params ?? t.staticAsString(`${base}.defaultCurrency` as TranslationLockedKeys);
  const amountType = params.type === parseInt(COUPON_DISCOUNT_TYPE_PERCENTAGE) ? '%' : currency;
  const usageType = params.usage_type ? t.staticAsString(`${base}.usageType${params.usage_type}` as TranslationLockedKeys) : '';

  const raceId = t.staticAsString(`${base}.raceId` as TranslationLockedKeys);
  const distancesIDs = t.staticAsString(`${base}.distancesIDs` as TranslationLockedKeys);
  const couponQuantity = t.staticAsString(`${base}.couponQuantity` as TranslationLockedKeys);
  const couponLength = t.staticAsString(`${base}.couponLength` as TranslationLockedKeys);
  const discountAmount = t.staticAsString(`${base}.discountAmount` as TranslationLockedKeys);
  const couponCodeType = t.staticAsString(`${base}.couponCodeType` as TranslationLockedKeys);
  const dateRange = t.staticAsString('supportTaskManager.form.dateRange');

  const data = [
    `${raceId}: ${params.race}`,
    `${distancesIDs}: ${distances}`,
    `${couponQuantity}: ${params.quantity}`,
    `${couponLength}: ${params.length}`,
    `${discountAmount}: ${params.amount} ${amountType}`,
    `${couponCodeType}: ${usageType}`,
  ];

  if (params.from && params.to) {
    return [...data, `${dateRange}: ${getDate(params.from)} - ${getDate(params.to)}`];
  }

  return data;
};

const onGenerateBib = (params: AnyObject): Array<string> => {
  const distanceId = t.staticAsString('supportTaskManager.form.distanceInputId');
  const bib = t.staticAsString('supportTaskManager.form.startingInputBib');
  const classes = t.staticAsString('supportTaskManager.form.classesInput');
  const classId = t.staticAsString('supportTaskManager.form.classId');
  const classTitle = t.staticAsString('supportTaskManager.form.classTitle');

  let data = [`${distanceId}: ${params.distance.id ? params.distance.id : params.distance}`];
  if (params.bib) {
    data.push(`${bib}: ${params.bib}`);
  }

  if (params.classes && params.classes.length > 0) {
    let classesData: any = [];
    classesData.push('');

    classesData = params.classes.map((item) => [`${bib}: ${item.bib}`, ` ${classId}: ${item.id}`, ` ${classTitle}: ${item.title}`]);

    return [...data, `${classes}:`, classesData.join('<br/>')];
  }

  return data;
};

const onGenerateRaceJob = (params: AnyObject): string[] => {
  const organizerId = t.staticAsString('payments.list.table.organizer_id');
  const raceId = t.staticAsString('supportTaskManager.list.table.raceId');
  const includeParticipants = t.staticAsString('supportTaskManager.includeParticipants');

  let data: string[] = [];

  if (params.include_participants) {
    data.push(`${includeParticipants}: ${params.include_participants}`);
  }

  if (params.organizer) {
    data.push(`${organizerId}: ${params.organizer}`);
  }

  if (params.race) {
    data.push(`${raceId}: ${params.race}`);
  }

  return data;
};

const onGenerateDuplicateDistance = (params: AnyObject): Array<string> => {
  return onGenerateBib(params);
};

const methods: Object = {
  [MERGE_ACCOUNTS_JOB]: onMergeAccounts,
  [REFUND_PAYMENT_JOB]: onRefundSingle,
  [REFUND_DISTANCE_JOB]: onRefundDistance,
  [REFUND_ORDER_JOB]: onRefundSingle,
  [REFUND_RACE_JOB]: onRefundDistance,
  [REFUND_JOB]: onRefundJob,
  [DELETE_ACCOUNT_JOB]: onDelete,
  [COPY_RACERS_JOB]: onCopyRacers,
  [GENERATE_COUPON_JOB]: onGenerateCoupon,
  [GENERATE_BIB_JOB]: onGenerateBib,
  [DUPLICATE_DISTANCE_JOB]: onGenerateDuplicateDistance,
  [DUPLICATE_RACE_JOB]: onGenerateRaceJob,
};

const getDate = (date) => {
  return time.workDatetime(moment(date)).formatAsString('shortDate');
};

export const inputParamsToString = (input: AnyObject, key: string): string => {
  if (key && methods[key]) {
    return methods[key](input).join('<br/>');
  }

  return t.staticAsString('supportTaskManager.errorCheckMethods');
};

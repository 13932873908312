import { Box, Typography } from '@mui/material';

export const CustomAgeTooltip = ({ active, payload, label, ...rest }) => {
  if (active && payload && payload.length) {
    const { value, percentage } = payload[0].payload;
    return (
      <Box sx={{ p: 1.5, borderRadius: 1, backgroundColor: 'white', border: '1px solid black' }}>
        <Typography>
          <span style={{ fontWeight: 600 }}>{label}</span> age bracket has&nbsp;
          <span style={{ fontWeight: 600 }}>{value} participants</span>
        </Typography>
        <Typography>This bracket constitutes {percentage}%</Typography>
      </Box>
    );
  }
  return null;
};

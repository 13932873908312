export const COPY_RACERS_JOB: string = 'App\\Jobs\\Admin\\Utils\\CopyRacersJob';
export const REFUND_PAYMENT_JOB: string = 'App\\Jobs\\Admin\\Utils\\RefundPaymentJob';
export const REFUND_DISTANCE_JOB: string = 'App\\Jobs\\Admin\\Utils\\RefundDistanceJob';
export const DELETE_ACCOUNT_JOB: string = 'App\\Jobs\\Admin\\Utils\\DeleteAccountJob';
export const MERGE_ACCOUNTS_JOB: string = 'App\\Jobs\\Admin\\Utils\\MergeAccountsJob';
export const GENERATE_COUPON_JOB: string = 'App\\Jobs\\Admin\\Utils\\GenerateCouponsJob';
export const GENERATE_BIB_JOB: string = 'App\\Jobs\\Admin\\Utils\\GenerateBibSeriesJob';
export const REFUND_JOB: string = 'App\\Jobs\\Admin\\Utils\\RefundJob';
export const REFUND_ORDER_JOB: string = 'App\\Jobs\\Admin\\Utils\\RefundOrderJob';
export const REFUND_RACE_JOB: string = 'App\\Jobs\\Admin\\Utils\\RefundRaceJob';
export const DUPLICATE_DISTANCE_JOB: string = 'App\\Jobs\\Admin\\Utils\\DuplicateDistanceJob';
export const DUPLICATE_RACE_JOB: string = 'App\\Jobs\\Admin\\Utils\\DuplicateRaceJob';

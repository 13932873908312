import axios from 'axios';

import { ORGANIZER_VERIFICATION, ORGANIZER_EMAIL_VERIFICATION } from 'src/constants';

import { request, action } from 'utils';

class VerificationService {
  @request({ action: ORGANIZER_EMAIL_VERIFICATION })
  verifyEmailRequest(token: string): any {
    return axios.post(ORGANIZER_VERIFICATION, { token });
  }

  @action({ action: ORGANIZER_EMAIL_VERIFICATION })
  async verifyEmail(token: string) {
    const [status, response] = await this.verifyEmailRequest(token);

    return [status, response];
  }
}

const verificationService = new VerificationService();

export { verificationService };

import { observer } from 'mobx-react';
import * as React from 'react';

import { SelectFilterWithSearch } from 'components/Filter/DefaultFilters';

type Props = {
  value: nil | string | number;
} & FilterProps;

export const ORDER_STATE_LABEL = {
  ready: 'Ready',
  awaiting: 'Awaiting',
};

@observer
class OrderStateComponent extends React.Component<Props> {
  options = () => {
    return Object.keys(ORDER_STATE_LABEL).map<SelectOption>((value) => {
      return {
        key: value,
        value: value,
        label: ORDER_STATE_LABEL[value as OrderStateType],
      } as SelectOption;
    });
  };

  render() {
    const { props } = this;
    return <SelectFilterWithSearch {...props} options={this.options()} />;
  }
}

const calcValue = (_name: any, value: OrderStateType) => {
  switch (value) {
    case 'ready':
      return { status: 'paid' };
    case 'awaiting':
      return { status: 'payment_required' };
    default:
      break;
  }
};

const calcHandler = (_name: any, _value: OrderStateType) => {
  return { status: '=' };
};

export const status: FilterListDescriptor = {
  filter: {
    persistance: {
      advanced: {
        calcValue,
        calcHandler,
      },

      prettify: (name, value: OrderStateType) => {
        return ORDER_STATE_LABEL[value];
      },
    },
  },

  frontend: {
    type: 'custom',
    component: OrderStateComponent,
  },
};

import { Info } from '@mui/icons-material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Card, IconButton, List, ListItem, ListItemText, Switch, TablePagination, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import { debounce, omit } from 'lodash';
import { Observer } from 'mobx-react';
import NoCommissions from 'modules/Reconciliation/shared/components/NoCommissions';
import { SystemUserViewStore } from 'modules/SystemUsers/stores/systemUserView.store';
import React, { useCallback, useEffect, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { useFilters, usePagination, useSortBy, useTable, useExpanded } from 'react-table';

import { progressStore } from 'src/stores';
import { t } from 'src/utils';

import { Spinner } from 'components/Spinner';

import { ORGANIZER_PAYMENT_METHOD, ORGANIZER_PAYMENT_METHODS } from '../../../../../../constants';
import { SystemUserCommissionsConstants } from '../../../../constants/systemUserCommissions.constants';

import { SystemUserCommissionsStore } from '../../../../stores/systemUserCommissions.store';

import { PaymentMethod } from '../../../../types';
import { DefaultColumnFilter } from './DefaultColumnFilter';

const CommissionsTable = ({
  fetchCommissions,
  tableMeta,
  tableColumns,
  tableData,
  selectedCurrencyId,
  initialSizeOfPage,
  pageSizeOptions,
}): JSX.Element => {
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => tableData, [tableData]);

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    state: { sortBy, filters, pageIndex, pageSize },
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    setPageSize,
    pageOptions,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        pageSize: 30,
      },
      manualFilters: true,
      manualSortBy: true,
      manualPagination: true,
      pageCount: tableMeta.pagination?.total ?? 0,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const debouncedCallback = useCallback(
    debounce((props) => fetchCommissions(props), 500),
    [],
  );

  useEffect(() => {
    return debouncedCallback({ pageIndex, pageSize, filters, sortBy });
  }, [fetchCommissions, pageIndex, pageSize, filters, sortBy]);

  const rendereBaseCommissions = () => {
    const commissions = SystemUserViewStore.profile.value?.active_commissions;
    if (commissions && commissions.length > 0) {
      const commission = commissions.find((c) => c.currency_id === selectedCurrencyId);
      if (commission) {
        return <NoCommissions commission={commission} />;
      }
    }
  };

  const handleSelectPaymentMethod = (pm: PaymentMethod) => async (event: React.MouseEvent<HTMLButtonElement>) => {
    const exists = Boolean(pm.id);
    const isEnabled = !pm.enabled;
    const url = exists ? generatePath(ORGANIZER_PAYMENT_METHOD, { id: pm.id }) : ORGANIZER_PAYMENT_METHODS;
    const payload = {
      currency: pm.currency,
      organizer_id: pm.organizer_id,
      payment_method_id: pm.payment_method_id,
      amount: pm.amount,
      percent_value: pm.percent_value,
      enabled: isEnabled,
    };

    try {
      let response: PaymentMethod;
      if (exists) response = (await axios.patch<any>(url, payload)).data.data;
      else response = (await axios.post<any>(url, payload)).data.data;
      SystemUserViewStore.profile.value!.payment_methods = SystemUserViewStore.profile.value!.payment_methods.map((method) => {
        if (method.currency_id !== selectedCurrencyId) return method;
        return {
          ...method,
          enabled: pm.payment_method_id === method.payment_method_id ? isEnabled : method.enabled,
          id: method.payment_method_id === pm.payment_method_id ? response.id : method.id,
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const buildTBody = () => {
    if (!progressStore.isLoading(SystemUserCommissionsConstants.Table) && rows.length === 0) {
      return (
        <tr>
          <td className='cell' colSpan={5}>
            <Typography style={{ marginBottom: 20 }} variant='h5'>
              {t.static('systemUsers.commissions.noDataWithQuery')}
            </Typography>
          </td>
        </tr>
      );
    }

    return rows.map((row) => {
      prepareRow(row);
      return (
        <tr className='row' {...row.getRowProps()}>
          {row.cells.map((cell) => {
            return (
              <td className='cell' {...cell.getCellProps()}>
                {cell.render('Cell')}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <Observer
      render={() => {
        SystemUserCommissionsStore.editCommission.value;
        return (
          <>
            <div>
              {rendereBaseCommissions()}
              <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                {progressStore.isLoading(SystemUserCommissionsConstants.Table) && (
                  <div
                    style={{
                      backgroundColor: 'white',
                      height: '100%',
                      width: '100%',
                      top: 0,
                      left: 0,
                      opacity: '0.5',
                      zIndex: 10,
                      position: 'absolute',
                    }}
                  >
                    <Spinner type={'cubesSpinner'} />
                  </div>
                )}

                <Box sx={{ display: 'flex', gap: 2, px: '20px' }}>
                  <Typography sx={{ fontWeight: 700, fontSize: 20, marginBottom: 3 }}>
                    Payment methods
                    <Tooltip title={<Typography variant='body2'>{t.static('systemUsers.paymentInfoTooltip')}</Typography>}>
                      <IconButton size='large'>
                        <Info color='disabled' />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, mx: '20px' }}>
                  {SystemUserViewStore.profile.value?.payment_methods
                    .filter((pm) => pm.currency_id === selectedCurrencyId)
                    .map((paymentMethod) => (
                      <Card key={paymentMethod.payment_method_id} variant='outlined' sx={{ minWidth: 200, borderRadius: 4 }}>
                        <List disablePadding>
                          <ListItem dense>
                            <ListItemText primary={paymentMethod.title} secondary={paymentMethod.default ? 'Default' : 'Available'} />
                          </ListItem>
                          <ListItem dense>
                            <ListItemText primary={`${paymentMethod.amount / 100} ${paymentMethod.currency}`} secondary={'Fixed fee'} />
                          </ListItem>
                          <ListItem dense>
                            <ListItemText primary={`${paymentMethod.percent_value / 100}%`} secondary={'Commission'} />
                          </ListItem>
                          <ListItem dense>
                            <ListItemText primary='Enable' />
                            <Switch
                              disabled={paymentMethod.default}
                              onClick={handleSelectPaymentMethod(paymentMethod)}
                              checked={paymentMethod.enabled}
                            />
                          </ListItem>
                        </List>
                      </Card>
                    ))}
                </Box>
                <div className='standart-table simple' style={{ marginBottom: '50px', marginTop: '30px' }}>
                  <table {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => {
                            return (
                              <th {...column.getHeaderProps()} style={{ minWidth: column.minWidth }}>
                                <div {...omit(column.getHeaderProps(column.getSortByToggleProps()), 'title')}>
                                  <div style={{ cursor: 'pointer', display: 'flex', height: 28 }}>
                                    {column.render('Header')}
                                    <span>
                                      {column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <ArrowDownwardIcon style={{ marginLeft: 10 }} />
                                        ) : (
                                          <ArrowUpwardIcon style={{ marginLeft: 10 }} />
                                        )
                                      ) : (
                                        <div style={{ width: 24, height: 24, marginLeft: 10 }} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>{buildTBody()}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <TablePagination
              sx={{ position: 'fixed', bottom: '47px', right: '24px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
              component='div'
              count={pageOptions.length}
              page={pageIndex}
              rowsPerPageOptions={pageSizeOptions}
              onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
                if (progressStore.isLoading(SystemUserCommissionsConstants.Table)) return;
                gotoPage(newPage);
              }}
              rowsPerPage={pageSize}
              onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                setPageSize(Number(event.target.value))
              }
            />
            <div style={{ height: 50 }} />
          </>
        );
      }}
    />
  );
};

export { CommissionsTable };

import { SystemUsersResponse } from 'modules/SystemUsers/types/systemUsers-response.type';

import { t } from 'utils';

import { toastStore } from 'stores';

import { SystemUsersService } from '../services/systemUsers.service';

import { SystemUsersStore } from '../stores/systemUsers.store';

import { SystemUsersMapper } from '../mappers/systemUsers.mapper';
import { SystemUsersFilters } from '../types/filters.type';
import { loadSystemUserRoles } from './loadRoles.action';

export namespace SystemUsersActions {
  export async function init() {
    await loadSystemUserRoles();
  }

  export async function load(filters: SystemUsersFilters) {
    const data = await SystemUsersService.load(filters);
    SystemUsersStore.systemUsers.set(Object.assign(data, { data: data.data.map(SystemUsersMapper.fromBackend) }));
  }

  export async function deleteUser(id: number) {
    const response = await SystemUsersService.deleteValue(id);

    if (response) {
      const filteredList = SystemUsersStore.systemUsers.value?.data.filter((user) => user.id !== id);

      SystemUsersStore.systemUsers.set(Object.assign(SystemUsersStore.systemUsers.value as SystemUsersResponse, { data: filteredList }));
      toastStore.show(t.staticAsString('systemUsers.deleteSuccess', { id }));
    }
  }

  export async function unmount() {
    SystemUsersStore.systemUsers.value = null;
  }
}

import React from 'react';

import { t } from 'utils';

const Toolbar = () => {
  return (
    <div className='toolbar'>
      <h1 className='title'>{t.staticAsString('customers.list.header')}</h1>
    </div>
  );
};

export { Toolbar };

import { MESSAGES_HEADER_KEY } from './constants';

let version = 0;

function init() {
  version = +(localStorage.getItem(MESSAGES_HEADER_KEY) as string);
}

function store(newVersion: number) {
  version = newVersion;
  localStorage.setItem(MESSAGES_HEADER_KEY, `${version || ''}`);
}

function compare(newVersion: number) {
  return version === newVersion;
}

const util = {
  init,
  store,
  compare,
};

export { util as version };

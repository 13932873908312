import { t } from 'utils';

import { toastStore, progressStore } from 'stores';

import { DELETE_DISTANCE } from '../constants';

import { distancesService } from '../services';

import { distancesStore, raceDetailsStore } from '../stores';

export const deleteDistanceAction = async (id: number) => {
  progressStore.log(DELETE_DISTANCE, 'progress');
  const { modelValues } = distancesStore;

  if (!modelValues) {
    return;
  }

  // cleaning from store before server request for better UX experience
  distancesStore.deleteDistanceById(id);

  const status = await distancesService.deleteDistance(id);

  const raceId = raceDetailsStore.raceDetails?.value.id;

  if (!raceId) {
    return;
  }

  distancesStore.setPage(1);
  await distancesService.loadDistances(raceId);

  progressStore.log(DELETE_DISTANCE, 'completed');

  if (status) {
    toastStore.show(t.staticAsString('distances.delete'));
    return;
  }

  toastStore.show(t.staticAsString('distances.canNotDelete'));
};

import { Box, Card, Grid, Typography } from '@mui/material';
import DashboardBreadcrumbs from 'modules/Dashboard/common/components/Breadcrumbs';
import SelectRangeButtons from 'modules/Dashboard/common/components/Interval';
import RaceSearch from 'modules/Dashboard/common/components/RaceSearch';
import { dashboardTextStyles } from 'modules/Dashboard/common/text.styles';
import { Registrations } from 'modules/Dashboard/types/registrations.type';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Icon } from 'components/Icon';

import { history } from 'utils/history';
import { t } from 'utils/t';

import { chartRangePickerStore } from '../../stores/interval.store';

type Props = {
  data: Registrations | null;
};

export default function RegistrationsChart({ data }: Props) {
  const textStyles = dashboardTextStyles;
  const renderTrend = () => {
    const rate = data?.trend.rate;
    if (!rate) return '100';
    return rate.toFixed(0);
  };
  const renderTrendIcon = () => {
    const rate = data?.trend.rate;
    if (!rate || rate >= 0) {
      return 'circleUp';
    }
    return 'circleDown';
  };
  return (
    <Box>
      <DashboardBreadcrumbs currentPage={t.staticAsString('dashboard.payments')} />
      <Grid p={4} pt={3} container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ minHeight: '100%', p: 4 }}>
            <Box display='flex' justifyContent='space-between'>
              <Typography sx={textStyles.cardTitle}>{t('dashboard.payments')}</Typography>
              <Icon onClick={() => history.push('/dashboard')} style={{ cursor: 'pointer' }} value='collapse' />
            </Box>
            <Box mt={2} display='flex' flexDirection='row' alignItems='center'>
              <Typography sx={textStyles.metricTitle}>{data?.total}</Typography>
              <Box marginTop='5px' display='flex' alignItems='center' sx={textStyles.explanationTitle}>
                <Icon style={{ paddingLeft: 30, marginTop: 5 }} value={renderTrendIcon()} />{' '}
                <b style={{ color: 'black', fontWeight: 700, paddingRight: 4, paddingLeft: 9 }}>{renderTrend() ?? '100'}%</b> vs{' '}
                {chartRangePickerStore.humanizedRange}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <SelectRangeButtons collection='registrations' />
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Typography sx={{ fontSize: 14, mr: 1 }}>{t('dashboard.event')}:</Typography>
                <RaceSearch collection='registrations' />
              </Box>
            </Box>
            <ResponsiveContainer width={'100%'} height={500}>
              <AreaChart style={{ left: '-30px', fontSize: 14, marginTop: 48 }} data={data?.data}>
                <defs>
                  <linearGradient id={'registrations'} x1='0' y1='0' x2='0' y2='1'>
                    <stop offset='5%' stopColor={'#F9D026'} stopOpacity={0.6} />
                    <stop offset='95%' stopColor={'#F9D026'} stopOpacity={0.15} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray='3' />
                <XAxis dataKey='label' />
                <YAxis />
                <Area fillOpacity={1} fill={`url(#registrations)`} type='monotone' dataKey='value' strokeWidth={5} stroke='#F9D026' />
                <Tooltip />
              </AreaChart>
            </ResponsiveContainer>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

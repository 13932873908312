import { t } from 'utils/t';

export const ProcessAgreementTable = () => {
  return (
    <table>
      <thead>
        <tr>
          <th>{t.staticAsString('processAgreement.table.subProcessor')}</th>
          <th>{t.staticAsString('processAgreement.table.org')}</th>
          <th>{t.staticAsString('racers.fields.address')}</th>
          <th>{t.staticAsString('processAgreement.table.processingActivities')}</th>
          <th>{t.staticAsString('races.new.location')}</th>
        </tr>
      </thead>
      <tbody>
        <tr style={{ height: '20px' }}>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>Stripe Payments Europe, Ltd.</td>
          <td>IE513174</td>
          <td>C/O A&L GOODBODY , 662880</td>
          <td>{t.staticAsString('systemUsers.detail.paymentsTitle')}</td>
          <td>{t.staticAsString('processAgreement.table.ireland')}</td>
        </tr>
        <tr>
          <td>PayEx Sverige AB</td>
          <td>556735-5671</td>
          <td>S:T Handspan, 621 56 Visby</td>
          <td>{t.staticAsString('systemUsers.detail.paymentsTitle')}</td>
          <td>{t.staticAsString('processAgreement.table.sweden')}</td>
        </tr>
        <tr>
          <td>Amazon Web Services Emea Sarl </td>
          <td>921425422 / NO28858582</td>
          <td>38, Avenue John F. Kennedy, Lu-1855</td>
          <td>{t.staticAsString('processAgreement.table.dataStorage')}</td>
          <td>{t.staticAsString('processAgreement.table.luxembourg')}</td>
        </tr>
        <tr>
          <td>EQ-Timing AB</td>
          <td>556873-4627</td>
          <td>Stenvägen 8, 443 61 Stenkullen</td>
          <td>{t.staticAsString('processAgreement.table.timeKeeping')}</td>
          <td>{t.staticAsString('processAgreement.table.sweden')}</td>
        </tr>
        <tr>
          <td>C-TIME Sweden AB</td>
          <td>559232-9980</td>
          <td>Fiskarevägen 5, 545 35 Töreboda</td>
          <td>{t.staticAsString('processAgreement.table.timeKeeping')}</td>
          <td>{t.staticAsString('processAgreement.table.sweden')}</td>
        </tr>
        <tr>
          <td>RaceTracker</td>
          <td>920 708 706</td>
          <td>Jan Petersens gate 3, 4041 Hafrsfjord</td>
          <td>{t.staticAsString('processAgreement.table.timeKeeping')}</td>
          <td>{t.staticAsString('processAgreement.table.norway')}</td>
        </tr>
        <tr>
          <td>Amplitude</td>
          <td>US107099440</td>
          <td>201 Third Street, Suite 200, San Francisco, CA, 94103</td>
          <td>{t.staticAsString('processAgreement.table.analytics')}</td>
          <td>{t.staticAsString('processAgreement.table.unitedStates')}</td>
        </tr>
      </tbody>
    </table>
  );
};

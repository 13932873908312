import classNames from 'classnames';
import { compact, flatten, isEmpty } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import shortid from 'shortid';

import { FieldValue, ImprovedDatePicker } from 'components/Form';

type Value = [FieldValue, FieldValue];

type Props = {
  value: Value;
  withYearSelect?: boolean;
} & FilterProps;

type State = {
  value: [FieldValue, FieldValue];
};

class DateRangeFilter extends React.Component<Props, State> {
  id: string = shortid();
  state = { value: this.parseValues(this.props.value) };

  parseValues(value: Value): Value {
    let parsedValue = flatten([value]);
    if (isEmpty(compact(parsedValue))) {
      return [null as any, null as any];
    }

    const [from, to] = parsedValue;
    let momentFrom = moment(from || (null as any));
    let momentTo = moment(to || (null as any));

    if (momentFrom.isValid()) {
      momentFrom.startOf('day');
    } else {
      momentFrom = null as any;
    }

    if (momentTo.isValid()) {
      momentTo.endOf('day');
    } else {
      momentTo = null as any;
    }

    return [momentFrom, momentTo];
  }

  parsedValues = this.parseValues;

  onChange = (value: Value) => {
    const parsedValue = this.parseValues(value);
    this.setState({ value: parsedValue });
  };

  applyFilter = () => {
    const { name } = this.props;
    const [from, to] = this.state.value;
    this.props.apply({ name, value: [from, to] });
  };

  clear = () => this.onChange([null, null]);

  render() {
    const [startDate, endDate] = this.state.value;

    return (
      <div className='filter-container date-range-picker-filter'>
        <div className='form-group'>
          <ImprovedDatePicker
            startDate={startDate}
            endDate={endDate}
            isSingleDate={false}
            onChange={this.onChange as any}
            isSingleMonthRange={false}
          />
        </div>
        <div className='filter-control'>
          <span className={classNames('clear', { hidden: !(startDate || endDate) })} onClick={this.clear}>
            Clear
          </span>
          <span className='apply' onClick={this.applyFilter}>
            Apply
          </span>
        </div>
      </div>
    );
  }
}

export { DateRangeFilter };

import { makeStyles } from '@mui/styles';

export const useAutocompleteStyles = makeStyles({
  option: {
    fontSize: '14px',
  },
  noOptions: {
    fontSize: '14px',
  },
});

import classNames from 'classnames';
import * as React from 'react';

import { TextField } from '../TextField';
import { generateNumberMask, numberMaskTools } from '../core/mask';

type Props = {
  additional?: TextFieldAdditionalProps;
  view?: BaseViewProps;
  maxWidth?: number;
} & FieldBaseProps;

type State = {
  value: string;
};

/*
 * Input:
 * - number
 * - null
 *
 * Output:
 * - number - Valid value
 * - null   - Empty value
 */
class Number extends React.Component<Props, State> {
  state = { value: numberMaskTools.formatToMask(this.props.value) };
  mask = generateNumberMask({ limit: this.props.maxWidth });

  componentDidUpdate = () => {
    if (this.props.value !== this.state.value) {
      this.setState({ value: this.props.value });
    }
  };

  onChange: FieldOnChange = ({ name, value }, e) => {
    const externalValue = numberMaskTools.formatFromMask(value);

    this.setState({ value });

    this.props.onChange(
      {
        name,
        value: externalValue,
      },
      e,
    );
  };

  render() {
    const { className, additional } = this.props;
    return (
      <TextField
        {...this.props}
        className={classNames('input-mask', className)}
        additional={{
          InputProps: { inputComponent: this.mask },
          ...(additional || {}),
        }}
        value={this.state.value}
        onChange={this.onChange}
      />
    );
  }
}

export { Number, Number as NumberField };

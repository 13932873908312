import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const historyStore: any = [];

history.listen((location: any, action: string) => {
  if (action !== 'REPLACE') historyStore.push(location);
});

export { history, historyStore };

import classNames from 'classnames';
import * as React from 'react';
import shortid from 'shortid';

import { Input } from 'components/Fields/Input';

import { t } from 'utils';

type FieldType = 'number' | 'text';

type Props = {
  autoFocus?: boolean;
  type: FieldType;
  description?: string;
} & FilterProps;

type State = {
  from: nil | string | number;
  to: nil | string | number;
};

class RangeFilter extends React.Component<Props, State> {
  id: string;

  state: State = {
    from: null,
    to: null,
  };

  constructor(props: Props) {
    super(props);
    this.id = shortid();
    let [from, to] = ['', ''];

    if (props.value instanceof Array) {
      [from, to] = props.value;
    } else {
      from = props.value;
    }

    this.state = {
      from: from || +from === 0 ? from : null,
      to: to || +to === 0 ? to : null,
    };
  }

  validate(value: nil | string | number) {
    if (!value) {
      return true;
    }
    const { type } = this.props;
    if (type === 'number') {
      return !/[^\d]/.test(value.toString());
    }
    return true;
  }

  onChangeFrom = (e: React.ChangeEvent<any>) => {
    const value = e.currentTarget.value;
    if (!this.validate(value)) {
      return;
    }
    this.setState({
      from: value,
    });
  };

  onChangeTo = (e: React.ChangeEvent<any>) => {
    const value = e.currentTarget.value;
    if (!this.validate(value)) {
      return;
    }
    this.setState({
      to: value,
    });
  };

  applyFilter = () => {
    const { name } = this.props;
    const { from, to } = this.state;
    this.props.apply({ name, value: [from, to] });
  };

  clear = () => {
    this.setState({
      from: null,
      to: null,
    });
  };

  render() {
    const { name, type } = this.props;
    const { from, to } = this.state;
    const isValuePresent = !!from || from === 0 || !!to || to === 0;
    const fromVal = from === 0 ? from : from || '';
    const toVal = to === 0 ? to : to || '';

    const labelKeyFrom = type === 'number' ? 'fromNumber' : 'from';
    const labelKeyTo = type === 'number' ? 'toNumber' : 'to';
    return (
      <div className='filter-container'>
        {this.props.description ? <div className='filter-description'>{this.props.description}</div> : null}
        <div className='form-group'>
          <Input
            label={t.staticAsString(`races.filters.${labelKeyFrom}` as TranslationLockedKeys)}
            className={classNames('form-control', 'range-field')}
            autoFocus
            type={type}
            name={`from_${name}`}
            onChange={this.onChangeFrom}
            value={`${fromVal}`}
            errors={[]}
          />
          <Input
            label={t.staticAsString(`races.filters.${labelKeyTo}` as TranslationLockedKeys)}
            className={classNames('form-control', 'range-field')}
            type={type}
            name={`to_${name}`}
            onChange={this.onChangeTo}
            value={`${toVal}`}
            errors={[]}
          />
        </div>
        <div className='filter-control'>
          <span className={classNames('clear', { hidden: !isValuePresent })} onClick={this.clear}>
            Clear
          </span>
          <span className='apply' onClick={this.applyFilter}>
            Apply
          </span>
        </div>
      </div>
    );
  }
}

export { RangeFilter };

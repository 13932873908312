import makeStyles from '@mui/styles/makeStyles';

const distanceModeStyles = makeStyles({
  icon: {
    height: 24,
    display: 'inline',
  },

  title: {
    fontSize: 16,
    paddingBottom: 9,
  },

  container: {
    justifyContent: 'space-between',
  },
});

export { distanceModeStyles };

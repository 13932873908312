import { cloneDeep } from 'lodash';
import moment from 'moment';

import { Distance } from 'models';

import { SYNC_VALUES } from '../constants';

import { IntegrationType } from '../types';

export const mapToForm = (data: IntegrationType) => {
  const copy = cloneDeep(data);
  const distances = data.distances
    .filter((item) => !!item)
    .map((distance) => {
      const model = new Distance(distance as any);
      return {
        key: distance.id,
        value: distance.id,
        label: model.value.name,
      };
    });
  const is_sync: AnyObject[] = [];
  if (copy.is_sync_start_list) is_sync.push(SYNC_VALUES()[0]);
  if (copy.is_sync_results) is_sync.push(SYNC_VALUES()[1]);

  const date_range = copy.started_at && copy.finished_at ? { startDate: moment(copy.started_at), endDate: moment(copy.finished_at) } : null;

  return {
    webhook_url: copy.webhook_url,
    distances,
    is_sync,
    send_start_list_results_objects: Boolean(data.send_start_list_results_objects),
    is_active: !!copy.is_active,
    date_range,
  };
};

import { remove } from 'lodash';
import shortID from 'shortid';

import { form } from 'src/stores';

import { EQ_FORM_ID } from '../constants';

export const handleAddItem = (scope: string) => () => {
  const data = form.fetchWithoutObservable<AnyObject[]>(EQ_FORM_ID, scope) || [];
  const newItem = { id: shortID() };

  form.onChange(EQ_FORM_ID, scope, [...data, newItem]);
};

export const handleDeleteItem = (scope: string, id: string) => () => {
  const data = form.fetchWithoutObservable<AnyObject[]>(EQ_FORM_ID, scope) || [];
  remove(data, (item) => item.id === id);

  form.onChange(EQ_FORM_ID, scope, data);
};

export const handleAfterSelectDistance = (prefix) => {
  cleanFields(prefix);
};

const cleanFields = (prefix) => {
  form.onChange(EQ_FORM_ID, `${prefix}.waves`, []);
  form.onChange(EQ_FORM_ID, `${prefix}.classes`, []);
};

import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { SupportTaskManagerCopyRacers } from './CopyRacers';
import { ErrorExpanded } from './ErrorExpanded';
import { SupportTaskManagerTasks } from './Tasks';

class SupportTaskManager extends React.Component<unknown> {
  render() {
    return (
      <div className='details-start-lists'>
        <Switch>
          <Route path={ROUTES.supportManagerTaskRoute} component={ErrorExpanded} />
          <Route path={ROUTES.supportManagerTasksRoute} component={SupportTaskManagerTasks} />
          <Route path={ROUTES.supportManagerCopyRacers} component={SupportTaskManagerCopyRacers} />
        </Switch>
      </div>
    );
  }
}

export { SupportTaskManager };

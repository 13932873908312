import { Chip, Collapse, FormControlLabel } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Observer } from 'mobx-react-lite';
import { Classes, Waves } from 'modules/Synchronisation/EQTiming/components';

import { InputWrapper, AutocompleteSelectInfinityLoad, Checkbox } from 'src/components/Form';
import { form } from 'src/stores';
import { t } from 'src/utils';

import { Show } from 'components/Condition';

import { EQ_FORM_ID } from '../../constants';

import { handleAddItem, handleAfterSelectDistance, handleDeleteItem } from '../../actions';

import { ColumnTitle, FieldColumns, SyncContainer, SyncItem } from '../../../Shared';
import { showDistancePlus, getRaceDistanceOptions, getSyncDistanceOptions } from '../../selectors';
import { MapItemType } from '../../types';
import { CustomFields } from './CustomFields';

export const Distances = () => {
  return (
    <Observer>
      {() => {
        const data = form.fetch<MapItemType[]>(EQ_FORM_ID, 'distances') || [];

        return (
          <SyncContainer
            title={t.staticAsString('coupons.new.distances')}
            addText={t.staticAsString('sync.add_distance')}
            onAdd={handleAddItem('distances')}
            showPlus={showDistancePlus.get()}
            hideFieldsTitles
          >
            {data.map((item, index) => {
              return (
                <Collapse key={item.id}>
                  <Show if={Boolean(item.internal_id?.label)}>
                    <Divider textAlign='left'>
                      <Chip label={item.internal_id?.label} />
                    </Divider>
                  </Show>
                  <Show if={!Boolean(item.internal_id?.label)}>
                    <Divider sx={{ margin: '17px 0' }} />
                  </Show>

                  <FieldColumns>
                    <ColumnTitle>{t('races.filters.id')}</ColumnTitle>
                    <ColumnTitle>{t('sync.race_results')}</ColumnTitle>
                  </FieldColumns>

                  <SyncItem
                    key={item.id}
                    onDelete={handleDeleteItem('distances', item.id)}
                    syncBibs={
                      <FormControlLabel
                        sx={{ mb: 2 }}
                        label='Sync bibs'
                        control={
                          <InputWrapper
                            name={`distances.${index}.sync_bibs`}
                            settings={{
                              label: 'Sync bibs',
                              defaultChecked: true,
                            }}
                            Component={Checkbox}
                          />
                        }
                      />
                    }
                    bottomComponent={
                      <>
                        <CustomFields distanceIndex={index} />
                        <Classes distanceIndex={index} />
                        <Waves distanceIndex={index} />
                      </>
                    }
                  >
                    <InputWrapper
                      name={`distances.${index}.internal_id`}
                      settings={{
                        label: t.staticAsString('sync.distance.raceid_title'),
                        additional: {
                          options: getRaceDistanceOptions.get(),
                        },
                        view: {
                          reservePlaceForHelperText: true,
                        },
                        afterSelect: () => handleAfterSelectDistance(`distances.${index}`),
                      }}
                      Component={AutocompleteSelectInfinityLoad}
                    />
                    <InputWrapper
                      key={item.id}
                      name={`distances.${index}.external_id`}
                      settings={{
                        label: t.staticAsString('sync.distance.eq_external_title'),
                        additional: {
                          options: getSyncDistanceOptions.get(),
                        },
                        view: {
                          reservePlaceForHelperText: true,
                        },
                        afterSelect: () => handleAfterSelectDistance(`distances.${index}`),
                      }}
                      Component={AutocompleteSelectInfinityLoad}
                    />
                  </SyncItem>
                </Collapse>
              );
            })}
          </SyncContainer>
        );
      }}
    </Observer>
  );
};

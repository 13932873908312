import { TableHead, TableRow } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';

import { TableHeaderItem } from 'components/Filter/TableHeaderItem';

import { t } from 'utils';

import { teamFields } from '../../../../Filters';
import { MAIN_COLUMNS, PAYMENT_COLUMNS, COLUMN_WIDTH } from './columns';

type Props = {
  distance: DistanceType;
};

const WAVE_TYPE = 'wave';

const classes = {
  thead: {
    custom: ['start-list-table-header', 'table-header', 'cell-custom'],
    regular: ['start-list-table-header', 'filter-item'],
  },
};

class THead extends React.Component<Props> {
  _renderThLabels = (cols: Array<string>): React.ReactNode => {
    return cols.map((field) => {
      if (!this._isFieldShown(field)) {
        return null;
      }

      const width = COLUMN_WIDTH[field];
      const translation = `teams.list.table.${field}`;

      return (
        <TableHeaderItem
          key={field}
          name={field}
          width={width}
          className={classNames(...classes.thead.regular)}
          label={t(translation as TranslationLockedKeys) as any}
        />
      );
    });
  };

  _isFieldShown = (field: string) => {
    const { distance } = this.props;
    const hasPrices = (distance.prices || []).length > 0;

    switch (field) {
      case teamFields.wave_id:
        return distance.start_type === WAVE_TYPE;
      case teamFields.class_id:
        return (distance?.classes?.length || 0) > 0;
      case teamFields['owner.order.id']:
      case teamFields['owner.order.created_at']:
      case teamFields['owner.order.coupon_codes']:
      // @ts-ignore
      case teamFields['owner.order.currency']:
      case teamFields['owner.order.state']:
      case teamFields['owner.order.amount']:
        return hasPrices;
      default:
        return true;
    }
  };

  render() {
    return (
      <React.Fragment>
        <TableHead>
          <TableRow>
            {this._renderThLabels(['action'])}
            {this._renderThLabels([...MAIN_COLUMNS])}
            {this._renderThLabels(PAYMENT_COLUMNS)}
          </TableRow>
        </TableHead>
      </React.Fragment>
    );
  }
}

export { THead };

import { Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import * as React from 'react';

import { t } from 'utils';

import { modalStore } from '../stores';

type Props = {};

const DialogTitleStyled = withStyles({
  root: {
    fontSize: 20,
    borderBottom: '1px solid #e0e0e0',
    fontWeight: 500,
  },
})(DialogTitle);

@observer
export class ModalTitle extends React.Component<Props> {
  render() {
    const isEdit = !!modalStore.value?.isEdit;
    const title = isEdit ? t.staticAsString('integrations.form.editTitle') : t.staticAsString('integrations.form.createTitle');

    return (
      <DialogTitleStyled>
        <Typography variant='h4' component='div'>
          {title}
        </Typography>
      </DialogTitleStyled>
    );
  }
}

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { MERGE_REQUEST_URL } from 'src/constants';

import { action, request, t } from 'utils';

import { errorsStore, toastStore } from 'stores';

import { SEND_MERGE_ACCOUNTS, SUPPORT_TASK_MANAGER_FORM } from '../constants';

import { mapErrorMergeAccounts } from '../utils';

import { closeForm, mountIndex, unmountIndex } from '../actions';

class MergeAccountsService {
  @request({ action: SEND_MERGE_ACCOUNTS })
  async sendMergeAccountsRequest(params: AnyObject): Promise<any> {
    const path = generatePath(MERGE_REQUEST_URL);
    return axios.post(path, params);
  }

  @action({ action: SEND_MERGE_ACCOUNTS })
  async sendMergeAccounts(params: AnyObject): Promise<any> {
    const [status, response] = await this.sendMergeAccountsRequest(params);
    if (status) {
      closeForm();
      unmountIndex();
      mountIndex();
      toastStore.show(t.staticAsString('supportTaskManager.responseMergeAccountsOk'));
    } else {
      errorsStore.add(SUPPORT_TASK_MANAGER_FORM, mapErrorMergeAccounts(response.data.errors));
    }
  }
}

export const mergeAccountsService = new MergeAccountsService();

import { CopyRacersTable as Type } from '../types';

export class CopyRacersTable {
  value: Type;

  constructor(value: Type) {
    this.value = value;
  }

  organizer(): string {
    return this.value.organizer?.email;
  }

  created_at(): string {
    return this.value.created_at;
  }

  sourceDistance(): string {
    return this.value.sourceDistance?.name;
  }

  targetDistance(): string {
    return this.value.targetDistance?.name;
  }

  startAt(): string {
    return this.value.start_at;
  }

  endAt(): string {
    return this.value.end_at;
  }
}

import moment from 'moment';

import { CampaignLinkBE } from '../../shared/types';

export const mapLinkToFE = (link: CampaignLinkBE) => {
  const date = {
    name: link.name,
  };
  return { ...link, date };
};

export const SUPPORT_TASK_MANAGER_FORM = 'SUPPORT_TASK_MANAGER_FORM';
export const SUPPORT_TASK_MANAGER_FORM_INIT = 'SUPPORT_TASK_MANAGER_FORM_INIT';

export const GET_DISTANCES_FOR_TASKS = 'GET_DISTANCES_FOR_TASKS';
export const GET_EMAILS_FOR_TASKS = 'GET_EMAILS_FOR_TASKS';

export const SEND_COPY_RACERS = 'SEND_COPY_RACERS';
export const SEND_MERGE_ACCOUNTS = 'SEND_MERGE_ACCOUNTS';

export const GET_ORDERS_OPTIONS_DATA = 'GET_ORDERS_OPTIONS_DATA';

export const SEND_REFUND = 'SEND_REFUND';

export const GET_COUPON_RACES = 'GET_COUPON_RACES';
export const GENERATE_COUPON_CODE = 'GENERATE_COUPON_CODE';
export const GET_RACE_DISTANCES = 'GET_RACE_DISTANCES';

export const GET_BIB_RACES = 'GET_BIB_RACES';
export const GENERATE_BIB = 'GENERATE_BIB';
export const GET_ORGANIZERS = 'GET_ORGANIZERS';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';
import * as React from 'react';
import shortid from 'shortid';

import { AutocompleteSelectInfinityLoad } from 'components/Form';

type Props = {
  options: Array<SelectOption>;
  className?: string;
  loadMore: Function;
  search: Function;
  noMoreData: boolean;
} & FilterProps;

type State = {
  value: any;
  textInput: string | any;
  menuSearchOpen: boolean;
};

class FilterAutocompleteAsyncLoad extends React.Component<Props, State> {
  id: string;
  intervalId: number | null;

  debouncedSearch: AnyFunction | any;

  constructor(props: Props) {
    super(props);
    this.id = shortid();

    this.intervalId = null;

    const value = props.options.find((opt) => +opt.value === +props.value);
    this.state = {
      value: value,
      menuSearchOpen: false,
      textInput: (isString(value?.label) ? value?.label : '') as string,
    };
  }

  componentDidMount() {
    this.debouncedSearch = debounce((val) => {
      this.props.search(val);
    }, 500);
    this.props.loadMore();
  }

  onTextInput = (value: string) => {
    this.debouncedSearch(value);

    this.setState({
      textInput: value,
    });
  };

  onChange = ({ value }: { name: string; value: { label: string } }) => {
    this.setState({
      value,
      textInput: value.label,
    });
  };

  applyFilter = () => {
    const { name } = this.props;
    const value = this.state.value?.value;
    this.props.apply({ name, value });
  };

  clear = () => {
    this.setState({
      value: null,
      textInput: '',
    });
  };

  render() {
    const { name, options, label, noMoreData } = this.props;
    const { value } = this.state;

    return (
      <div className='filter-container'>
        <div className='form-group'>
          <AutocompleteSelectInfinityLoad
            hasMore={!noMoreData}
            value={value}
            loadMore={this.props.loadMore}
            onSearch={this.onTextInput}
            name={name}
            onChange={this.onChange}
            label={label}
            additional={{ options, fullWidth: true }}
            view={{ variant: 'standard' }}
          />
        </div>
        <div className='filter-control'>
          <span className={classNames('clear', { hidden: !value })} onClick={this.clear}>
            Clear
          </span>
          <span className='apply' onClick={this.applyFilter}>
            Apply
          </span>
        </div>
      </div>
    );
  }
}

export { FilterAutocompleteAsyncLoad };

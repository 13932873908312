import { Box, Typography } from '@mui/material';
import RefundDisplay from 'modules/Reconciliation/shared/components/Refund';

import { t } from 'utils/t';

import { ReportConstants } from '../constants/report.constants';

import { formatCurrency } from '../utils';
import { reportCurrency } from '../utils/currency.util';

import { ReportStore } from '../stores/report.store';

import { DistanceItem } from '../types/report.type';
import ReportHeader from './Header';
import TableWithSummary from './common/TableWithSummary';
import { genericReportStyles } from './general.styles';

type Props = {};

export default function PaymentOverview({}: Props) {
  const report = ReportStore.report.value!;
  const styles = genericReportStyles();
  const title = (prefix: string) => t.staticAsString(`reconciliation.report.paymentOverview.${prefix}` as any);

  return (
    <div>
      <ReportHeader />
      <Typography style={{ marginTop: 42, marginBottom: 32 }} className={styles.reportGenericTitle}>
        {title('detailedReport')}
      </Typography>
      <TableWithSummary
        tableName='PaymentOverview'
        columns={[
          { title: title('distanceProduct'), accessor: 'itemName', align: 'left', width: ReportConstants.COLUMN_WIDTH.name },
          { title: title('purchased'), accessor: 'transactionsCount', align: 'center', width: ReportConstants.COLUMN_WIDTH.purchased },
          {
            title: title('totalExVat'),
            accessor: 'totalExcludingVAT',
            fn: (item: DistanceItem) => formatCurrency(item.totalExcludingVAT),
            align: 'center',
            width: ReportConstants.COLUMN_WIDTH.totalExVat,
          },
          {
            title: `${title('vat')} %`,
            accessor: 'vatPercentage',
            align: 'center',
            width: ReportConstants.COLUMN_WIDTH.vatPercentage,
          },
          {
            title: title('vat'),
            accessor: 'vatSum',
            fn: (item: DistanceItem) => formatCurrency(item.vatSum),
            align: 'center',
            width: ReportConstants.COLUMN_WIDTH.vatSum,
          },
          {
            title: title('totalIncVat'),
            accessor: 'totalIncludingVat',
            fn: (item: DistanceItem) => formatCurrency(item.totalIncludingVat),
            align: 'right',
            width: ReportConstants.COLUMN_WIDTH.totalIncVat,
          },
        ]}
        emptyMessage={'No data'}
        summaryText={title('partSum')}
        data={report.payment_overview.transactions_report.distanceItems}
      />
      <TableWithSummary
        tableName='PaymentOverview'
        columns={[
          { title: title('raceIdExtras'), accessor: 'itemName', align: 'left', width: ReportConstants.COLUMN_WIDTH.name },
          { title: title('purchased'), accessor: 'transactionsCount', align: 'center', width: ReportConstants.COLUMN_WIDTH.purchased },
          {
            title: title('totalExVat'),
            accessor: 'totalExcludingVAT',
            fn: (item: DistanceItem) => formatCurrency(item.totalExcludingVAT),
            align: 'center',
            width: ReportConstants.COLUMN_WIDTH.totalExVat,
          },
          {
            title: `${title('vat')} %`,
            accessor: 'vatPercentage',
            align: 'center',
            width: ReportConstants.COLUMN_WIDTH.vatPercentage,
          },
          {
            title: title('vat'),
            accessor: 'vatSum',
            fn: (item: DistanceItem) => formatCurrency(item.vatSum),
            align: 'center',
            width: ReportConstants.COLUMN_WIDTH.vatSum,
          },
          {
            title: title('totalIncVat'),
            accessor: 'totalIncludingVat',
            fn: (item: DistanceItem) => formatCurrency(item.totalIncludingVat),
            align: 'right',
            width: ReportConstants.COLUMN_WIDTH.totalIncVat,
          },
        ]}
        data={report.payment_overview.transactions_report.raceIDExtrasItems}
        emptyMessage={title('noExtrasPurchased')}
        summaryText={title('partSum')}
      />
      <TableWithSummary
        isRefund
        tableName='PaymentOverview'
        columns={[
          { title: title('refundsAndDeductions'), accessor: 'itemName', align: 'left', width: ReportConstants.COLUMN_WIDTH.name },
          { title: title('purchased'), accessor: 'transactionsCount', align: 'center', width: ReportConstants.COLUMN_WIDTH.purchased },
          {
            title: title('totalExVat'),
            accessor: 'totalExcludingVAT',
            fn: (item: DistanceItem) => (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {<RefundDisplay value={item.totalExcludingVAT} currency={reportCurrency()!} />}
              </Box>
            ),

            align: 'center',
            width: ReportConstants.COLUMN_WIDTH.purchased,
          },
          {
            title: `${title('vat')} %`,
            accessor: 'vatPercentage',
            align: 'center',
            width: ReportConstants.COLUMN_WIDTH.vatPercentage,
          },
          {
            title: title('vat'),
            accessor: 'vatSum',
            fn: (item: DistanceItem) => formatCurrency(item.vatSum),
            align: 'center',
            width: ReportConstants.COLUMN_WIDTH.vatSum,
          },
          {
            title: title('totalIncVat'),
            accessor: 'totalIncludingVat',
            fn: (item: DistanceItem) => (
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                {<RefundDisplay value={item.totalIncludingVat} currency={reportCurrency()!} />}
              </Box>
            ),
            align: 'right',
            width: ReportConstants.COLUMN_WIDTH.totalIncVat,
          },
        ]}
        data={report.payment_overview.transactions_report.refundItems}
        emptyMessage={title('noRefundsOrDeductions')}
        summaryText={title('partSum')}
      />
      <TableWithSummary
        tableName='PaymentOverview'
        summaryText={title('partSum')}
        withTotal
        totalSumText={title('total')}
        totalSum={formatCurrency(report.sum)}
        columns={[]}
        data={[]}
      />
    </div>
  );
}

import { isUndefined } from 'lodash';

import { Result } from 'models';

import { toastStore } from 'stores';

import { generateStoreOptions } from '../stores';

import { ResultsContextType } from '../context';
import { updateMessage } from '../presenters';
import { validateSingleResult, validateRacer, validateTeam } from '../tools';
import { fieldUpdateOptions } from '../types';

async function updateRacer(property: string, valueToUpdate: any, model: Result, context: ResultsContextType): Promise<boolean> {
  const { racer } = model;

  if (!racer) {
    return false;
  }

  const racerId = racer.value.id;
  const fieldId = racer.generateFieldId(property);
  const storeOptions = generateStoreOptions(property, racer);

  if (isUndefined(valueToUpdate)) {
    context.results.stores.fieldRegisterStore.deactivateField(fieldId);
    return false;
  }

  const isValid = validateRacer(fieldId, property, valueToUpdate);

  if (!isValid) {
    return false;
  }

  const service = context.results.updateServices.racer;
  const distanceId = model.value.distance_id;

  const formattedData = getFormattedData(property, valueToUpdate);
  const updateOptions = { property, fieldId, storeOptions };

  const status = await service.update(distanceId, racerId, formattedData, updateOptions);

  status && toastStore.show(updateMessage('racer', fieldId));

  return status;
}

async function updateTeam(property: string, valueToUpdate: any, model: Result, context: ResultsContextType): Promise<boolean> {
  const { team } = model;

  if (!team) {
    return false;
  }

  const teamId = team.value.id;
  const fieldId = team.generateFieldId(property);
  const storeOptions = generateStoreOptions(property, team);

  if (isUndefined(valueToUpdate)) {
    context.results.stores.fieldRegisterStore.deactivateField(fieldId);
    return false;
  }

  const isValid = validateTeam(fieldId, property, valueToUpdate);

  if (!isValid) {
    return false;
  }

  const service = context.results.updateServices.team;
  const distanceId = model.value.distance_id;
  const formattedData = { [property]: valueToUpdate };
  const updateOptions = { property, fieldId, storeOptions };

  const status = await service.update(distanceId, teamId, formattedData, updateOptions);

  status && toastStore.show(updateMessage('team', fieldId));

  return status;
}

async function updateRacerResult(
  updateOptions: fieldUpdateOptions,
  valueToUpdate: any,
  model: Result,
  context: ResultsContextType,
): Promise<boolean> {
  const service = context.results.updateServices.result;
  const racerId = model.value.racer_id;
  const { property, fieldId } = updateOptions;
  const formattedData = { [property]: valueToUpdate };

  const isValid = validateSingleResult(fieldId, property, valueToUpdate);

  if (!isValid) {
    return false;
  }

  const status = await service.update('racer', +racerId, formattedData, updateOptions);

  status && toastStore.show(updateMessage('racer', fieldId));

  return status;
}

async function updateTeamResult(
  updateOptions: fieldUpdateOptions,
  valueToUpdate: any,
  model: Result,
  context: ResultsContextType,
): Promise<boolean> {
  const service = context.results.updateServices.result;
  const teamId = model.value.team_id as number;
  const { property, fieldId } = updateOptions;
  const formattedData = { [property]: valueToUpdate };
  const status = await service.update('team', teamId, formattedData, updateOptions);

  status && toastStore.show(updateMessage('racer', fieldId));

  return status;
}

async function updateSplit(
  checkpointId: number,
  updateOptions: fieldUpdateOptions,
  valueToUpdate: any,
  model: Result,
  context: ResultsContextType,
): Promise<boolean> {
  const service = context.results.updateServices.split;
  const racerId = model.value.racer_id;
  const teamId = model.value.team_id;
  const { property, fieldId } = updateOptions;
  const formattedData = { [property]: valueToUpdate };
  let status = false;

  if (!!racerId) {
    status = await service.createOrUpdate('racer', racerId, checkpointId, formattedData, updateOptions);
    status && toastStore.show(updateMessage('racer', fieldId, true));
  }

  if (!!teamId) {
    status = await service.createOrUpdate('team', teamId, checkpointId, formattedData, updateOptions);
    status && toastStore.show(updateMessage('team', fieldId, true));
  }

  return status;
}

const getFormattedData = (property: any, valueToUpdate: any) => {
  const parsedIntProperties = ['bib_number'];
  const parsedValueToUpdate = parsedIntProperties.indexOf(property) !== -1 ? parseInt(valueToUpdate) : valueToUpdate;
  return { [property]: parsedValueToUpdate };
};

export { updateRacer, updateSplit, updateTeam, updateTeamResult, updateRacerResult };

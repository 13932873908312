import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ERRORS_PREFIXES } from 'modules/Distances/constants';
import { clearNamespaceErrors } from 'modules/Distances/utils/errors';
import * as React from 'react';
import shortid from 'shortid';

import { DISTANCE_STEPS, DRAG_IDS, PERSIST_DISTANCE as action } from 'src/constants';

import { withErrorClean } from 'hocs';

import { DNDContainer } from 'components/DragNDrop';

import { reorderUtil, t } from 'utils';

import { Race as RaceModel } from 'models';

import { State as OriginalFormState } from '../../../../shared/stateHelpers';
import { Item } from './Item';

type Props = {
  formData: OriginalFormState;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
    isDirty?: boolean,
    value?: AnyObject,
    isNeedLiveUpdate?: boolean,
  ) => void;
  helperData: {
    race: nil | RaceModel;
  };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

const stepAction = `${action}_${DISTANCE_STEPS.waves}`;

const skipRule: AnyFunction = (object: WaveType) => !!object._delete;

@withErrorClean(stepAction)
class Waves extends React.Component<Props> {
  componentDidMount() {
    this._addDefaultValues();
  }

  onChange = (values: Array<WaveType>, callback: Function = () => {}, isNeedLiveUpdate = true) => {
    const { onChange, liveUpdate } = this.props;

    isNeedLiveUpdate && liveUpdate({ waves: values }, true);
    onChange(
      {
        waves: values,
      },
      '',
      () => {
        callback();
      },
      true,
      { waves: values },
      isNeedLiveUpdate,
    );
  };

  onDragEnd = (result: AnyObject): void => {
    const { waves } = this.props.formData;
    if (!result?.destination) {
      return;
    }
    const items = reorderUtil.changePosition([...waves], result.source.index, result.destination.index);
    this.onChange(items);
  };

  onAddNew = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { waves } = this.props.formData;
    const values = [...waves, getWave(true, waves.length)];

    this.onChange(
      values,
      () => {
        clearNamespaceErrors(ERRORS_PREFIXES.waves);
      },
      false,
    );
  };

  onRemove = (id: number | string) => {
    const { waves } = this.props.formData;
    const values = waves
      .filter((wave) => !wave._delete)
      .reduce((acc: any[], wave: WaveType | any) => {
        if (wave.id === id) {
          return [
            ...acc,
            {
              ...wave,
              _delete: true,
            },
          ];
        } else if (wave.__id === id) {
          return acc;
        } else {
          return [...acc, wave];
        }
      }, []);

    this.onChange(values, () => {
      clearNamespaceErrors(ERRORS_PREFIXES.waves);
    });
  };

  onChangeWave = (id: number | string, changedDistanceWaves: WaveType, callback: Function = () => {}) => {
    const { waves } = this.props.formData;

    const newData = waves.map((wave) => (wave.id === id || wave.__id === id ? changedDistanceWaves : wave));

    this.onChange(newData, callback);
  };

  _renderDistanceWaves = (): React.ReactNode => {
    const { formData } = this.props;
    const { waves } = formData;
    const valuesForRender = filterDeleted(waves);

    return valuesForRender.reduce((acc: any[], wave: WaveType | any, index: number) => {
      return [...acc, <Item key={wave.id || wave.__id} index={index} value={wave} onChange={this.onChangeWave} onRemove={this.onRemove} />];
    }, []);
  };

  _addDefaultValues = () => {
    const { waves } = this.props.formData;
    const { onChange } = this.props;

    if (!waves.filter((el) => !el._delete).length) {
      onChange({ waves: [getWave(true)] }, '', () => {});
    }
  };

  render() {
    return (
      <div className='distance-wave-fields-form form-content-wrapper'>
        <Box mb={4} style={{ cursor: 'pointer' }} display='flex' justifyContent='center' alignItems='center' onClick={this.onAddNew}>
          <AddCircleOutline style={{ marginRight: 10 }} color='primary' className='icon' />
          <Typography color='primary'>{t.staticAsString('distances.steps.wavesForm.add')}</Typography>
        </Box>
        <DNDContainer droppableId={DRAG_IDS.WAVES} className='custom-field-list' onDragEnd={this.onDragEnd}>
          {this._renderDistanceWaves()}
        </DNDContainer>
        {this.props.controls}
      </div>
    );
  }
}

const getWave = (isFirst = false, wavesLength?: number): WaveType => {
  return {
    index: wavesLength ?? 0,
    __id: shortid(),
    name: '',
    _new: isFirst,
    max_members: 1,
    is_visible: true,
  };
};

const filterDeleted = (values: Array<WaveType>): Array<WaveType> => {
  return values.filter((el) => !el._delete);
};
const normalizeDataForBackend = (values: WaveType[]): Array<{ _delete?: boolean; id?: number } | WaveType> => {
  return values.map((el) => {
    if (!!el._delete) {
      return { id: el.id, _delete: true };
    }

    return { ...el, _delete: false };
  });
};

export { Waves, getWave, normalizeDataForBackend };

import { delay } from 'lodash';
import { AuthService } from 'modules';

import { ROUTES } from 'src/constants';

import { history, t } from 'utils';

import { sessionStore, toastStore } from 'stores';

import { verificationService } from '../services';

import { handleErrorVerification, handleSuccessVerification } from './handlers';

const moveToMainPage = () => history.push(ROUTES.rootRoute);
const showPopUpWithDelay = (fn, wait) => delay(fn, wait);

const authService = new AuthService();

const verifyEmail = async (token: string) => {
  const [status, response] = await verificationService.verifyEmail(token);

  if (!status) {
    moveToMainPage();

    if (sessionStore.isLogged) {
      toastStore.show(t.staticAsString('toast.verificationEmail.error'), null, 5000, 'error');
    } else {
      showPopUpWithDelay(handleErrorVerification, 1000);
    }
    return;
  }

  if (response.data.logout) {
    authService.logout();
    moveToMainPage();
    showPopUpWithDelay(handleSuccessVerification, 1000);
    return;
  }

  if (status && sessionStore.isLogged) {
    sessionStore.setUserVerified();
    moveToMainPage();
  } else {
    moveToMainPage();
    showPopUpWithDelay(handleSuccessVerification, 1000);
  }
};

export { verifyEmail };

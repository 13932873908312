import { DEFAULT_LOCALE } from 'src/constants';

let cache: TranslationsType = {};

function fetch() {
  return cache;
}

function clear() {
  const defaultMessages = cache[DEFAULT_LOCALE];
  cache = {};

  if (defaultMessages) {
    store({ [DEFAULT_LOCALE]: defaultMessages });
  }
}

async function store(value: TranslationsType) {
  cache = { ...cache, ...value };
}

const util = {
  fetch,
  clear,
  store,
};

export { util as cache };

import MaterialTooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import * as React from 'react';

import { Icon } from 'components/Icon';

type Props = {
  message: React.ReactNode;
};

const TooltipStyled: AnyFunction | any = withStyles((theme) => ({
  tooltip: {
    fontSize: 12,
  },
}))(MaterialTooltip);

function Tooltip({ message }: Props) {
  return (
    <React.Fragment>
      <TooltipStyled title={message} arrow interactive placement='bottom'>
        <span>
          <Icon className='info-helder' value='info' />
        </span>
      </TooltipStyled>
    </React.Fragment>
  );
}

type Pr = {
  message: any;
  children: React.ReactNode;
};

function FieldWithTooltip({ children, message }: Pr) {
  return (
    <div className='tooltip-wrap'>
      <Tooltip message={message} />
      {children}
    </div>
  );
}

export { Tooltip, FieldWithTooltip };

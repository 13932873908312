import { localeStore } from 'stores';

import { RaceName } from '../types';

export class RacerRace {
  value: RaceName;

  constructor(value: RaceName) {
    this.value = value;
  }

  forSelect(): SelectOption {
    const id = `${this.value.id || ''}`;

    return {
      label: this.value.name,
      value: id,
      key: id,
    };
  }

  label(): string {
    return this.value.name;
  }
}

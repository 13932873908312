import * as React from 'react';

import { Code } from './Code';
import { DateTo } from './DateTo';
import { Description } from './Description';
import { DiscountAmount } from './DiscountAmount';
import { DistancesName } from './DistancesName';
import { Id } from './Id';
import { Name } from './Name';
import { Quantity } from './Quantity';
import { RaceName } from './RaceName';
import { Statuses } from './Statuses';

export interface FilterProps {
  name: string;
  value: FilterType;
  values: FiltersType;
  label: string;
  applyFilter: (
    arg0: {
      [K in string]: string;
    },
  ) => void;
  updateSearchHandlers: (
    arg0: {
      [K in string]: string;
    },
  ) => void;
}

const filtersComponents: AnyObject = {
  name: Name,
  id: Id,
  code: Code,
  qty: Quantity,
  left: Quantity,
  discount_amount: DiscountAmount,
  'race.translations.name': RaceName,
  'distances.translations.name': DistancesName,
  date_to: DateTo,
  status: Statuses,
  description: Description,
};

const Filter = (props: FilterProps) => {
  const { name } = props;
  const Component = filtersComponents[name];

  return <Component {...props} disableSingleDate={!!(name === 'date_to')} />;
};

Filter.types = Object.keys(filtersComponents);

export { Filter };

import { generatePath, matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history, historyStore } from 'utils';

export const redirectAfterUpdate = (raceId: number) => {
  const previousRoute = historyStore[historyStore.length - 2];

  const hasMatch = [ROUTES.distancesMembersRoute, ROUTES.distancesRacersRoute, ROUTES.distancesTeamsRoute, ROUTES.resultsRoute].some(
    (route) => matchPath(previousRoute?.pathname, route),
  );

  if (hasMatch) {
    history.push(previousRoute.pathname);
    return;
  }

  const URL = generatePath(ROUTES.raceRoute, { id: raceId });
  history.push(URL);
};

export const redirectToTranslations = (raceId: number, distanceId: number) => {
  const URL = generatePath(ROUTES.distanceRoute, { raceId, id: distanceId });
  history.replace(URL + '?step=translations&reload=true');
};

export const redirectToGPX = (raceId: number, distanceId: number) => {
  const URL = generatePath(ROUTES.distanceRoute, { raceId, id: distanceId });
  location.replace(URL + '?step=GPX&reload=true');
};

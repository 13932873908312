import { t } from 'utils';

const codeFormatMapper = {
  sv: 'se',
};

type CodeFormatKeys = keyof typeof codeFormatMapper;

function formatShortLocale(value: string): string {
  return (codeFormatMapper[value as CodeFormatKeys] || value).toUpperCase();
}

function formatLongLocale(value: string): string {
  return t.staticAsString(`locales.label.${value}` as TranslationLockedKeys);
}

function formatLongWithShortLocale(value: string) {
  return `${formatLongLocale(value)} (${formatShortLocale(value)})`;
}

export { formatShortLocale, formatLongLocale, formatLongWithShortLocale };

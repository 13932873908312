import { MenuItem } from '@mui/material';
import { exportSingleCoupon } from 'modules/Coupons/actions';
import { couponDetailsService } from 'modules/Coupons/services/couponsDetails.service';
import { openCouponForm } from 'modules/CouponsForm/actions';
import React from 'react';

import { CONFIRM_POPUP_TYPES } from 'src/constants/mixed';

import { Show } from 'components/Condition';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { Icon } from 'components/Icon';
import { SettingsMenu } from 'components/SettingsMenu';
import { DateRange } from 'components/TimeRange';

import { history } from 'utils/history';
import { t } from 'utils/t';

import { Coupon as CouponModel } from 'models';

import loadService from '../../services/load';

import { BadgeActive, BadgeInactive } from './Badges';

type State = {
  onConfirm: AnyFunction | null;
  confirmTitle: string;
  confirmBody: string;
  confirmType: string;
};

export const Header: React.FC<{ couponData: CouponType }> = ({ couponData }) => {
  const couponModel = new CouponModel(couponData);
  const usageType =
    couponData.usage_type === 0 || 1 || 2
      ? t.staticAsString(`supportTaskManager.list.table.usageType${couponData.usage_type}` as TranslationLockedKeys)
      : '';

  const [state, setState] = React.useState<State>({
    onConfirm: null,
    confirmTitle: '',
    confirmBody: '',
    confirmType: '',
  });

  const onEdit = (): void => {
    const isShowConfirm = couponData.qty - couponData.completed_orders_count < 0;
    openCouponForm(couponData.id, isShowConfirm, () => couponDetailsService.loadCoupon(couponData.id));
  };

  const clearPopup = () => {
    if (state.onConfirm) {
      setState({
        ...state,
        onConfirm: null,
      });
    }
  };

  const onDelete = (): void => {
    setState({
      onConfirm: () => {
        loadService.deleteValue(couponData.id);
        clearPopup();
        history.goBack();
      },
      confirmTitle: t.staticAsString('coupons.confirmPopup.mainTitle') as any,
      confirmBody: t.staticAsString('coupons.list.delete.confirm', { couponName: couponData.name }) as any,
      confirmType: CONFIRM_POPUP_TYPES.confirm,
    });
  };

  const onExport = async (id: number) => {
    await exportSingleCoupon(id);
  };

  const { onConfirm, confirmBody, confirmTitle, confirmType } = state;

  return (
    <div className='header'>
      <ConfirmationModal
        open={Boolean(onConfirm)}
        onConfirm={onConfirm || (() => {})}
        onClose={clearPopup}
        title={confirmTitle}
        body={confirmBody}
        type={confirmType}
      />
      <div className='header-top-line'>
        <div className='coupon-name'>
          <span className='title'>{`${t.staticAsString('coupons.detail.name')}: “${couponData.name}” `}</span>
          <Show if={Boolean(couponData.status)}>
            <BadgeActive />
          </Show>
          <Show if={!Boolean(couponData.status)}>
            <BadgeInactive />
          </Show>
        </div>
        <div className='controls'>
          <Icon className='xls' value='xls' onClick={() => onExport(couponData.id)} />
          <SettingsMenu
            dataTestId='CouponActions'
            additionalMenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
            }}
          >
            <MenuItem onClick={onEdit}>
              <p>{t.staticAsString('coupons.EditCouponCode')}</p>
            </MenuItem>
            <MenuItem style={{ color: '#ce5659' }} onClick={onDelete}>
              <p>{t.staticAsString('coupons.deleteCouponCode')}</p>
            </MenuItem>
          </SettingsMenu>
        </div>
      </div>
      <div className='coupon-code-race-name'>
        <span className='name'>{`${t.staticAsString('coupons.detail.couponCode')}: `}</span>
        <span style={{ marginRight: '24px' }} className='value'>
          {couponData.code}
        </span>
        <span className='name'>{`${t.staticAsString('coupons.detail.raceName')}: `}</span>
        <span className='value'>{couponData.race?.name || ''}</span>
      </div>
      <div className='general-information'>{t.staticAsString('coupons.detail.general')}</div>
      <div className='general-information-line line1'>
        <GeneralInfoItem name={t.staticAsString('coupons.detail.quantity')} value={couponData.qty} />
        <GeneralInfoItem name={t.staticAsString('coupons.detail.used')} value={couponData.completed_orders_count} />
        <GeneralInfoItem name={t.staticAsString('coupons.detail.discountAmount')} value={couponModel.discountAmount()} />
      </div>
      <div className='general-information-line'>
        <GeneralInfoItem name={t.staticAsString('coupons.detail.couponCodeType')} value={usageType} />
        <GeneralInfoItem
          name={t.staticAsString('coupons.detail.dataRange')}
          value={<DateRange from={couponData.date_from} to={couponData.date_to} />}
        />
      </div>
    </div>
  );
};

const GeneralInfoItem = ({ name, value }) => {
  return (
    <span>
      <span className='name'>{name}</span>
      <span className='value'>{value}</span>
    </span>
  );
};

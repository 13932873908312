import { loadCopyRacers } from '../actions';

import { copyRacersStore } from '../stores';

export const mountIndex = async () => {
  const { page: storePage, params: storeParams } = copyRacersStore;
  await loadCopyRacers({ page: storePage, limit: storeParams.limit });
};

export const unmountIndex = (): void => {
  copyRacersStore.clearAll();
};

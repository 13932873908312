import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';

import { Show } from 'components/Condition';
import { SettingsMenu as Menu } from 'components/SettingsMenu';

import { t } from 'utils';

import { Distance } from 'models';

type Props = {
  distance: nil | Distance;
  onHideStartList: React.MouseEventHandler;
  handleToggleBibsVisibility: AnyFunction;
};

const SettingsMenu = ({ distance, onHideStartList, handleToggleBibsVisibility }: Props) => {
  if (!distance) {
    return null;
  }

  return (
    <div className='toolbar'>
      <div className='toolbox'>
        <div className='group-action'>
          <Show if={!distance.race.value.race_url}>
            <Menu>
              <MenuItem className={'menu-card-item'} onClick={onHideStartList}>
                <p>{t(distance.value.show_startlist ? 'shared.distance.hideStartListTab' : 'shared.distance.showStartListTab')}</p>
              </MenuItem>
              <MenuItem className={'menu-card-item'} onClick={handleToggleBibsVisibility}>
                <p>{t(distance.value.show_bibs ? 'shared.distance.hideBibNumbersTab' : 'shared.distance.showBibNumbersTab')}</p>
              </MenuItem>
            </Menu>
          </Show>
        </div>
      </div>
    </div>
  );
};

export { SettingsMenu };

import { isNaN } from 'lodash';

import { START_TYPE, DISTANCE_TYPE, DISTANCE_MODES } from 'src/constants';

import { t } from 'utils';

import { generateDateValidation } from './generateDateValidations';

// Min members in team
const MIN_MEMBERS = 2; // min - max_members_quantity,

const MINIMUM_TEXT_DEFAULT = 3;
const MAXIMUM_TEXT_DEFAULT = 100;

const DEFAULT_LENGTH_PATTERN = `^$|(^.{${MINIMUM_TEXT_DEFAULT},${MAXIMUM_TEXT_DEFAULT}})$`;
const DISTANCE_DETAILS_VALIDATION_FIELDS = [
  'distance_mode',
  'type',
  'start_type',
  'registration_starts_at',
  'registration_ends_at',
  'ends_at',
  'race_date',
  'max_members_quantity',
  'min_members_quantity',
  'goal',
  'goal_type',
];
const baseStep = (object: DistanceType) => {
  const isDistanceTeam = object.type === DISTANCE_TYPE.team;
  const isCumulative = object.distance_mode === DISTANCE_MODES.CUMULATIVE;
  const isNotClassical = object.distance_mode !== DISTANCE_MODES.CLASSIC;
  const isUpdateForm = !!object.id;
  const isNormal = object.distance_mode === 1;
  return {
    _validation: {
      name: {
        presence: {
          allowEmpty: false,
          message: () => t.staticAsString('shared.errors.presence'),
        },
        format: {
          pattern: DEFAULT_LENGTH_PATTERN,
          message: () =>
            t.staticAsString('shared.errors.lengthWithFormat', {
              min: MINIMUM_TEXT_DEFAULT,
              max: MAXIMUM_TEXT_DEFAULT,
            }),
        },
      },
      start_type: {
        presence: {
          allowEmpty: false,
        },
        inclusion: {
          within: START_TYPE,
        },
      },
      ...(isNormal
        ? {
            type: {
              presence: {
                allowEmpty: false,
              },
            },
          }
        : {}),
      type: {
        presence: {
          allowEmpty: false,
        },
      },
      distance_mode: {
        presence: {
          message: () => t.staticAsString('shared.errors.presence'),
          allowEmpty: false,
        },
      },

      registration_starts_at: generateDateValidation('registration_starts_at', object, {
        required: true,
        allowRegisrationStartPastToday: isUpdateForm,
      }),
      registration_ends_at: generateDateValidation('registration_ends_at', object, { required: true }),
      race_date: generateDateValidation('race_date', object, { required: true }),

      ...(isNotClassical
        ? {
            ends_at: generateDateValidation('ends_at', object, { required: true }),
          }
        : {}),

      race_length: {
        presence: {
          allowEmpty: true,
        },
      },

      ...(isDistanceTeam
        ? {
            max_members_quantity: {
              presence: {
                message: () => t.staticAsString('shared.errors.presence'),
                allowEmpty: false,
              },

              equality: {
                attribute: 'min_members_quantity',
                message: () => t.staticAsString('shared.errors.maxMembers'),
                comparator: (minMembers: nil | number, maxMembers: nil | number) => {
                  const min = Number(minMembers);
                  const max = Number(maxMembers);
                  if (isNaN(min) || isNaN(max)) {
                    return;
                  }
                  return min >= max;
                },
              },

              numericality: {
                greaterThanOrEqualTo: MIN_MEMBERS,
                message: () => t.staticAsString('shared.errors.maxMemNumericality'),
              },
            },
          }
        : {}),

      ...(isDistanceTeam
        ? {
            min_members_quantity: {
              presence: {
                message: () => t.staticAsString('shared.errors.presence'),
                allowEmpty: false,
              },
              equality: {
                attribute: 'max_members_quantity',
                message: () => t.staticAsString('shared.errors.minMembers'),
                comparator: (maxMembers: nil | number, minMembers: nil | number) => {
                  const min = Number(minMembers);
                  const max = Number(maxMembers);
                  if (isNaN(min) || isNaN(max)) {
                    return;
                  }
                  return min >= max;
                },
              },

              numericality: {
                greaterThanOrEqualTo: MIN_MEMBERS,
                message: () => t.staticAsString('shared.errors.maxMemNumericality'),
              },
            },
          }
        : {}),
    },

    ...(isCumulative
      ? {
          goal: {
            _validation: {
              goal_type: {
                presence: {
                  allowEmpty: false,
                },
              },
            },
          },
        }
      : {}),
  };
};

export { baseStep, DISTANCE_DETAILS_VALIDATION_FIELDS };

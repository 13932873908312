import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';

import { TextField } from '../TextField';

type Props = {
  additional?: TextFieldAdditionalProps;
  view?: BaseViewProps;
  options: SelectOption[];
} & FieldBaseProps;

function Select(props: Props) {
  const { options, value, additional = {} } = props;

  const onChange: FieldOnChange = (changeArgs, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e?.target?.value;

    props.onChange({ ...changeArgs, value }, e);
    if (additional?.liveUpdate !== undefined) {
      additional.liveUpdate(e.target?.name);
    }
  };

  const additionalProps = { ...additional, select: true };

  return (
    <TextField {...props} value={value} onChange={onChange} additional={additionalProps}>
      {options.map((option) => (
        <MenuItem key={option.key} value={option.value}>
          {option.label || null}
        </MenuItem>
      ))}
    </TextField>
  );
}

export { Select };

import { clearReceiptSettings } from 'modules/Races/actions';
import { RACE_FORM_ID } from 'modules/Races/constants';
import * as React from 'react';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { SwitchWithLabel, InputWrapper } from 'components/Form';

import { t } from 'utils/t';

import { form } from 'stores';

import { formStyles } from '../../styles';

type Props = {
  liveUpdate: (fieldName: string) => Promise<boolean>;
};

export const SettingsSwitch = ({ liveUpdate }: Props) => {
  const classes = formStyles();

  const onSwitchToggle = ({ value }) => {
    if (!value) {
      setDialogOpen(true);
    } else {
      toggleSwitch(value);
    }
  };
  const handleCancel = () => {
    setDialogOpen(false);
  };
  const handleConfirm = () => {
    clearReceiptSettings();
    toggleSwitch(false);
    setDialogOpen(false);
  };

  const toggleSwitch = (value: boolean) => {
    form.onChange(RACE_FORM_ID, 'editorSettings.receiptSettings', value);
    liveUpdate('editorSettings');
  };
  const [open, setDialogOpen] = React.useState(false);

  return (
    <div className={classes.wrapper}>
      <ConfirmationModal
        open={open}
        onConfirm={handleConfirm}
        onClose={handleCancel}
        title={t.staticAsString('distances.steps.tabOptionsForm.confirmPopup.mainTitle')}
        body={t.staticAsString('races.steps.receiptSettings.confirmPopup.mainBody')}
      />
      <InputWrapper
        name='editorSettings.receiptSettings'
        settings={{
          view: { fullWidth: true, variant: 'outlined' },
          label: t.staticAsString(`races.steps.receiptSettings`),
          onChange: onSwitchToggle,
        }}
        Component={SwitchWithLabel}
      />
    </div>
  );
};

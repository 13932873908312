import { observer } from 'mobx-react';
import * as React from 'react';

import { progressStore } from 'stores';

import { Spinner } from '../Spinner';

type Props = {
  action: string;
  opacity?: number;
};

@observer
export class Loading extends React.Component<Props> {
  static defaultProps = {
    opacity: 0.7,
  };

  styles() {
    const { opacity } = this.props;
    return { opacity };
  }

  render() {
    const { action } = this.props;

    if (!progressStore.isLoading(action)) {
      return null;
    }

    return (
      <div className='loading-overlay' style={this.styles()}>
        <Spinner type='cubesSpinner' />
      </div>
    );
  }
}

import { Table, TableBody, TableCell as MuiTableCell, TableHead, TableRow, Select, MenuItem, InputBase } from '@mui/material';
import { withStyles } from '@mui/styles';
import { cloneDeep, debounce } from 'lodash';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { Show } from 'components/Condition';

import { t } from 'utils';
import { parseHtml } from 'utils';

import { initTargetLocale } from '../utils';

import { LocaleItem } from './components/LocaleItem';
import { cellStyles, useCellInputStyles } from './styles';

type Props = {
  translations: TableTranslationsFormat;
  defaultLocale: availableLocales;
  locales: availableLocales[];
  onUpdate: Function;
  onHtmlEditNavigate: (name: string) => () => void;
};

let debouncedUpdate: any;
const TableCell = withStyles(cellStyles)(MuiTableCell);

const GeneralTranslations = ({ translations, defaultLocale, locales, onUpdate, onHtmlEditNavigate }: Props) => {
  const [target, setTarget] = React.useState(initTargetLocale(locales, defaultLocale));
  const selectTarget = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTarget(e.target.value as availableLocales);
  };

  // State handler to keep track whenever user is typing or not and which field. Or blurs field.
  const initState: { isTyping: boolean; data: { value: string; name: string } } = {
    isTyping: false,
    data: { name: '', value: '' },
  };
  const [typing, setTyping] = React.useState(initState);
  // State for local editing
  const [data, setData] = React.useState(translations);

  const onTranslationChange = ({ value, name, translation, blur }) => {
    // Main input handler:
    // 1. Update local storage
    // 2. Send update info to parent component (for future use)
    // 4. Save when user end's typing (1.5s ?) or on blur event.

    // On blur logic:
    // Emit change only if blur detected earlier then typing delay
    // Simple blur when user just clicking fields should not emit changes
    // Change event happened when typing.isTyping value changed to false
    if (!name) return;

    const clonedData = cloneDeep(data);
    const index = clonedData[name].findIndex((el) => el.locale === translation.locale);
    clonedData[name].splice(index, 1, { ...translation, value });
    setData(clonedData);

    const nameWithLocale = `${translation.locale}.${name}`;

    if (blur && typing.isTyping) {
      // Immediate change
      // Cancel debounced update if present
      setTyping({ isTyping: false, data: { name: nameWithLocale, value } });
      debouncedUpdate.cancel();
    }

    if (!blur) {
      // Wait for user input, then update store and save on backend
      setTyping({ isTyping: true, data: { name: nameWithLocale, value } });
      debouncedUpdate({ name: nameWithLocale, value });
    }
  };

  React.useEffect(() => {
    // Register debounced function once, on init rendering
    debouncedUpdate = debounce((data) => {
      setTyping({ isTyping: false, data });
    }, 500);
  }, []);

  React.useEffect(() => {
    const { name, value } = typing.data;
    if (!typing.isTyping && name && value) {
      onUpdate([typing.data]);
    }
  }, [typing]);

  const cellInputClasses = useCellInputStyles();

  const isHtml = (name: string) => {
    return name.includes('description');
  };

  return (
    <Observer>
      {() => {
        return (
          <React.Fragment>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center' style={{ width: '50%' }}>
                    {t('translations.source')}
                    <LocaleItem locale={defaultLocale} />
                  </TableCell>
                  <TableCell align='center'>
                    {t('translations.target')}
                    <Select value={target} onChange={selectTarget} input={<InputBase />}>
                      {locales
                        .filter((el) => el !== defaultLocale)
                        .map((locale) => (
                          <MenuItem key={locale} value={locale}>
                            <LocaleItem locale={locale} />
                          </MenuItem>
                        ))}
                    </Select>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(data).map((name) => {
                  return (
                    <TableRow key={name}>
                      {data[name].map((translation) => {
                        return (
                          <React.Fragment key={`${translation.locale}${name}`}>
                            <Show if={translation.locale === defaultLocale}>
                              <TableCell component='th' scope='row' className={cellInputClasses.leftCell}>
                                {parseHtml(translation.value)}
                              </TableCell>
                            </Show>
                            <Show if={translation.locale === target}>
                              <Show if={isHtml(name)}>
                                <TableCell
                                  component='th'
                                  scope='row'
                                  onClick={onHtmlEditNavigate(name)}
                                  className={cellInputClasses.rightHtmlCell}
                                >
                                  {parseHtml(translation.value)}
                                </TableCell>
                              </Show>
                              <Show if={!isHtml(name)}>
                                <TableCell component='th' scope='row'>
                                  <InputBase
                                    className={cellInputClasses.rightCell}
                                    value={parseHtml(translation.value)}
                                    multiline
                                    onChange={(e) =>
                                      onTranslationChange({
                                        value: e.target.value,
                                        name,
                                        translation,
                                        blur: false,
                                      })
                                    }
                                    onBlur={(e) =>
                                      onTranslationChange({
                                        value: e.target.value,
                                        name,
                                        translation,
                                        blur: true,
                                      })
                                    }
                                  />
                                </TableCell>
                              </Show>
                            </Show>
                          </React.Fragment>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </React.Fragment>
        );
      }}
    </Observer>
  );
};

export { GeneralTranslations };

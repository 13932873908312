import { systemUsersService } from 'modules/SystemUsers/services';
import { editModalStore } from 'modules/SystemUsers/stores';
import type { SystemUser as SystemUserType } from 'modules/SystemUsers/types';
import { omitEmptyFields, mapToPost } from 'modules/SystemUsers/utils';

import { t } from 'utils';

import { toastStore, helperRacesStore } from 'stores';

import { loadMedical, loadTiming } from '../actions';

import { medicalAssistants, timingAssistants } from '../stores';

const createSuccessfulToast = (name: string): void => {
  toastStore.show(t.staticAsString('systemUsers.createSuccess', { name }));
};

export const createSystemUser = async (_: any, systemUser: SystemUserType, fieldsToDelete: string[]): Promise<void> => {
  const data = mapToPost(omitEmptyFields(systemUser));
  const [status, newUser] = await systemUsersService.createSysUser(data);

  if (!status) {
    return;
  }

  createSuccessfulToast(systemUser.full_name);

  if (systemUser.predefinedRole?.name === 'assistant') {
    await loadTiming();

    const assistantToSelect = timingAssistants.values.find((el) => el.id === newUser.id);

    if (assistantToSelect) {
      timingAssistants.selectValue(assistantToSelect);
    }
  } else if (systemUser.predefinedRole?.name === 'medical') {
    await loadMedical(helperRacesStore.selected?.organizer_id);

    const assistantToSelect = medicalAssistants.values.find((el) => el.id === newUser.id);

    if (assistantToSelect) {
      medicalAssistants.selectValue(assistantToSelect);
    }
  }

  editModalStore.off();
};

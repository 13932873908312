import TableBody from '@mui/material/TableBody';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';
import shortid from 'shortid';

import { Show } from 'components/Condition';
import { Spinner } from 'components/Spinner';
import { Table as TableComponent } from 'components/Table';
import { TableEmptyListNotification } from 'components/TableEmptyListNotification';

import { t } from 'utils';

import { progressStore } from 'stores';

import { LOAD_TASKS } from '../../constants';

import { TasksContext } from '../../context';
import { TaskTable as RacerType } from '../../types';
import { THead } from '../THead';
import { Row } from './Row';

type Props = {};

@observer
class Table extends React.Component<Props> {
  static contextType = TasksContext;
  declare context: React.ContextType<typeof TasksContext>;

  render() {
    const tasksList: Array<RacerType> = this.context.results.stores.racersStore.values;

    return (
      <div className='component-list racers-list non-sticky'>
        <Show if={!isEmpty(tasksList)}>
          <TableComponent>
            <THead />
            {!progressStore.isLoading(LOAD_TASKS) && (
              <TableBody>
                {tasksList.map<React.ReactNode>((el) => (
                  <Row item={el} key={shortid()} />
                ))}
              </TableBody>
            )}
          </TableComponent>
        </Show>
        <Show if={isEmpty(tasksList)}>
          <TableEmptyListNotification text={t.staticAsString('supportTaskManager.noData')} />
        </Show>
        {progressStore.isLoading(LOAD_TASKS) && <Spinner type={Spinner.types.default} />}
      </div>
    );
  }
}

export { Table };

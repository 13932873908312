import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { API_CUSTOM_FIELDS_SYNC, RACE_CUSTOM_FIELDS_URL } from 'src/constants';
import { request, action } from 'src/utils';

import { CUSTOM_FIELDS_RR_REQUEST } from '../constants';

import { mapOptions } from '../utils';

import { syncCustomFieldsStore, raceCustomFieldsStore } from '../stores';

import { distanceToOptionsMap, raceDistancesStore } from '../../Shared';

class SyncCustomFields {
  @request({ action: CUSTOM_FIELDS_RR_REQUEST })
  async getCustomFieldsRequests(id: number): Promise<any> {
    const url = generatePath(API_CUSTOM_FIELDS_SYNC, { id });
    return axios.get(url);
  }

  @action({ action: CUSTOM_FIELDS_RR_REQUEST })
  async getCustomFields(id: number) {
    const [status, response] = await this.getCustomFieldsRequests(id);

    if (status) {
      syncCustomFieldsStore.set(distanceToOptionsMap(response.data.data));
    }
  }
}

class RaceCustomFields {
  @request({ action: CUSTOM_FIELDS_RR_REQUEST })
  async getCustomFieldsRequests(id: number): Promise<any> {
    const url = generatePath(RACE_CUSTOM_FIELDS_URL, { id });
    return axios.get(url);
  }

  @action({ action: CUSTOM_FIELDS_RR_REQUEST })
  async getCustomFields(id: number) {
    const [status, response] = await this.getCustomFieldsRequests(id);

    if (status) {
      raceCustomFieldsStore.set(mapOptions(response.data.data, raceDistancesStore.value || [], 'name'));
    }
  }
}

export const syncCustomFieldsService = new SyncCustomFields();
export const raceCustomFieldsService = new RaceCustomFields();

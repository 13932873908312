import Menu from '@mui/material/Menu';
import classNames from 'classnames';
import { isFunction, isNil } from 'lodash';
// @ts-ignore
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import * as React from 'react';
import shortId from 'shortid';

import { Show } from 'components/Condition';

import { Trigger as DefaultTrigger } from './Trigger';
import { TriggerProps } from './Trigger';

const classes = {
  toolbar: {
    menu: ['menu-card'],
    text: ['menu-card-text'],
  },
};

type Props = {
  withText?: boolean;
  children: React.ReactElement[] | React.ReactElement;
  Trigger?: (p: TriggerProps) => React.ReactElement;
  additionalMenuProps?: Object;
  id?: number;
  dataTestId?: string;
};

let SettingsMenu = ({ Trigger = DefaultTrigger, children, withText = false, id, additionalMenuProps = {}, dataTestId }: Props) => {
  const generateOnItemClick = (child: AnyObject, onMenuClose: Function) => () => {
    const originalOnClick = child.props?.onClick;

    isFunction(originalOnClick) && originalOnClick();
    onMenuClose();
  };

  let hasValidMenuItem = true;
  if (Array.isArray(children)) {
    hasValidMenuItem = children.some((child) => !isNil(child));
  }

  const [localId] = React.useState(shortId());

  return (
    <PopupState variant='popover' popupId={localId || id}>
      {(popupState: any) => (
        <Show if={hasValidMenuItem}>
          <div onClick={bindTrigger(popupState).onClick}>
            <Trigger withText={withText} dataTestId={dataTestId} />
          </div>
          <Menu
            {...bindMenu(popupState)}
            className={classNames(...classes.toolbar.menu)}
            keepMounted
            id='settings-menu'
            {...additionalMenuProps}
          >
            {React.Children.map<React.ReactElement, React.ReactElement>(children, (child: React.ReactElement) => {
              return (
                child &&
                React.cloneElement(child, {
                  onClick: generateOnItemClick(child, popupState.close),
                  className: classNames(child.props.className),
                })
              );
            })}
          </Menu>
        </Show>
      )}
    </PopupState>
  );
};

export * from './Trigger';
export { SettingsMenu };

import { generatePath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { t } from 'utils';

import { helperRacesStore } from 'stores';

export const makeBreadcrumbs = (): Array<{ path?: string; label: string | nil }> => {
  if (!helperRacesStore.modelSelected) {
    return [];
  }
  const value = helperRacesStore.modelSelected.value;
  return [
    { label: value.name, path: generatePath(ROUTES.raceRoute, { id: value.id }) },
    { label: t.staticAsString('races.detail.editRace') },
  ];
};

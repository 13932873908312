import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';

import { FRONTEND_DATE_FORMAT } from 'src/constants';

import { LabelForFilter } from 'components/Filter/ChipFilterLabel';
import { ClearAllChipLabel } from 'components/Filter/ChipFilterLabel/ClearAll';

import { defaultListFilters, t } from 'utils';

import { Race as RaceStore } from 'stores';

import loadService from '../../../services/load';

import { ApproveStatusFilterLabel } from './Labels/ApproveStatus';
import { SportTypeFilterLabel } from './Labels/SportType';

type Props = {
  racesStore: RaceStore;
};

type State = {
  selectedFilter: nil | string;
  anchorEl: nil | HTMLElement;
};

const COLUMNS = ['sport_id', 'id', 'name', 'approve_status', 'race_date', 'race_location', 'total_price', 'organizer.email', 'updated_at'];

const CUSTOM_LABELS: AnyObject = {
  sport_id: SportTypeFilterLabel,
  approve_status: ApproveStatusFilterLabel,
};

@inject('racesStore')
@observer
class FilterLabelToolbar extends React.Component<Props, State> {
  state = {
    selectedFilter: null,
    anchorEl: null,
  };

  static defaultProps = {
    racesStore: null as any,
  };

  clearFilters = (): void => {
    loadService.clearFilters();
  };

  deleteFilters = (...names: Array<string>) => {
    loadService.deleteFilters(() => {}, ...names);
  };

  processFilterValue = (name: string, value: any): any => {
    switch (name) {
      case 'updated_at':
      case 'race_date':
        return defaultListFilters.dateRange.persistance.prettify('', value, { dateFormat: 'datetime' });
      // return moment(value).format(FRONTEND_DATE_FORMAT);
      default:
        return value;
    }
  };

  _renderLabels = (): Array<React.ReactNode> => {
    const { racesStore } = this.props;
    const searchData = racesStore.searchDataModel.search;

    return COLUMNS.map((param) => {
      const processedValue = this.processFilterValue(param, searchData[param]);
      const LabelComponent = CUSTOM_LABELS[param] || LabelForFilter;
      return (
        <LabelComponent
          onClear={this.deleteFilters}
          key={param}
          name={param}
          values={searchData}
          className='filter-item'
          label={t.staticAsString(`races.list.table.${param}` as TranslationLockedKeys)}
          value={processedValue}
        />
      );
    });
  };

  _renderClearAll = (): React.ReactNode => {
    const { racesStore } = this.props;
    const searchData = racesStore.searchDataModel.search;

    return <ClearAllChipLabel onClear={this.clearFilters} key={'clear_all'} values={searchData} className='filter-item' />;
  };

  _isFiltersEmpty = () => {
    const { racesStore } = this.props;
    const searchData = racesStore.searchDataModel.search;

    return isEmpty(searchData);
  };

  render() {
    return (
      <div className={classNames('filters-toolbar', { empty: this._isFiltersEmpty() })}>
        {[...this._renderLabels(), this._renderClearAll()]}
      </div>
    );
  }
}

export { FilterLabelToolbar };

import { DEFAULT_LOCALE, LOCALES } from 'src/constants';

import { browserLanguage } from 'utils';

function fetchDefault(): availableLocales {
  const locale = savedLocale() || browserLocale();

  if (!LOCALES.includes(locale)) {
    return DEFAULT_LOCALE;
  }

  return locale;
}

function savedLocale() {
  return localStorage.getItem('locale') || null;
}

function browserLocale() {
  return browserLanguage() || null;
}

export { fetchDefault };

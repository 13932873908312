function convertForSelect(options: Array<any>): Array<any> {
  return options.map((option) => {
    const label = option.price ? `${option.value} (${option.price} ${option.currency})` : option.value ? option.value : '';

    return {
      key: option.id,
      value: option.id,
      label,
    };
  });
}

export { convertForSelect };

import { computed, observable, action, makeObservable, override } from 'mobx';

import { SearchSerializer } from 'utils';

import { Race as RaceModel } from 'models';

import { Loadable } from './loadable';

class Race extends Loadable<RaceType, FiltersType> {
  defaultFilters: FiltersType = {
    with: 'distances;organizer;location;sport',
    limit: 30,
    searchJoin: 'AND',
    orderBy: 'approve_status',
    sortedBy: 'asc',
  };

  declare filters: FiltersType;

  declare resourceParams: FiltersType;

  @observable
  handlers = {
    sport_id: '=',
    id: 'between',
    name: 'like',
    approve_status: '=',
    race_date: 'between',
    race_location: 'like',
    total_price: 'between',
    'organizer.email': 'like',
    status: '=',
    updated_at: 'between',
  };

  static allowBlank: string[] = [];

  constructor() {
    super();

    makeObservable(this);
    this.filters = this.defaultFilters;
    this.resourceParams = {
      with: 'distances.prices;organizer;location;currency;rejectReason.admin',
    };
  }

  @computed
  get modelValues(): Array<RaceModel> {
    return this.values.map((race) => new RaceModel(race));
  }

  @computed
  get modelSelected(): nil | RaceModel | any {
    if (this.selected) {
      return new RaceModel(this.selected);
    } else {
      return null;
    }
  }

  @action
  updateSearch(search: string, searchFields: string): void {
    if (!search) {
      this.filters.search = null as any;
      this.filters.searchFields = null as any;
      delete this.filters.search;
      delete this.filters.searchFields;
    } else {
      this.filters.search = search;
      this.filters.searchFields = searchFields;
    }
  }

  @action
  updateSearchHandlers(
    newHandlers: {
      [K in string]: string;
    },
  ) {
    this.handlers = {
      ...this.handlers,
      ...newHandlers,
    };
  }

  @action
  resetFilters(): void {
    this.filters.search = '';
    this.filters.searchFields = '';
  }

  @computed
  get searchDataModel(): SearchSerializer {
    const search = this.filters.search as any;
    return new SearchSerializer(search, this.handlers, Race.allowBlank);
  }

  retrieveSearchDataModel(): SearchSerializer {
    return this.searchDataModel.clone();
  }

  @override
  clearAll() {
    super.clearAll();
    this.filters = this.defaultFilters;
  }
}

export { Race };
export default new Race();

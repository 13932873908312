import { isEmpty } from 'lodash';

import { getRaceCustomFieldsOptions, getSyncCustomFieldsOptions } from '../selectors';

export const isValidDistances = (distances: AnyObject[] = []): boolean => {
  return !isEmpty(distances);
};

export const isValidCF = (customFields: AnyObject[] = []): boolean => {
  // const raceCF = getRaceCustomFieldsOptions.get();
  // const syncCF = getSyncCustomFieldsOptions.get();
  //
  // const shouldValidateCF = !isEmpty(raceCF) || !isEmpty(syncCF);
  //
  // return !(shouldValidateCF && isEmpty(customFields));

  return true;
};

import { TableHead, TableRow } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';

import { DISTANCE_TYPE } from 'src/constants';

import { TableHeaderItem } from 'components/Filter/TableHeaderItem';

import { t } from 'utils';

import { Checkpoint as CheckpointModel } from 'models';

import { HeadersType, SingleHeaders, TeamHeaders } from '../../components/Thead';

import { DEFAULT_COLUMN_WIDTH, splitFields } from '../../constants';

import { ResultsContext } from '../../context';
import { ActionCol } from './ActionCol';

type Props = {};

const classes = {
  thead: {
    custom: ['start-list-table-header', 'table-header', 'cell-custom'],
    regular: ['start-list-table-header', 'filter-item'],
  },
};

const HEADERS_COMPONENT = {
  [DISTANCE_TYPE.single]: SingleHeaders,
  [DISTANCE_TYPE.team]: TeamHeaders,
};

class THead extends React.Component<Props> {
  static contextType = ResultsContext;
  declare context: React.ContextType<typeof ResultsContext>;

  HeaderComponent = (): nil | React.ComponentType<HeadersType> => {
    const distanceModel = this.context.results.stores.distanceStore.modelSelected;

    if (!distanceModel) {
      return null;
    }

    const type = distanceModel.value.type;
    return HEADERS_COMPONENT[type];
  };

  _renderCheckpoints = () => {
    const distanceModel = this.context.results.stores.distanceStore.modelSelected;

    if (!distanceModel) {
      return null;
    }

    return distanceModel.checkpoints
      .sort((a, b) => a.value.index - b.value.index)
      .map((el: CheckpointModel) => {
        const { id } = el.value;
        return (
          <React.Fragment>
            <TableHeaderItem
              key={`${id || ''}-prev_split_diff`}
              name={splitFields['splits.prev_split_diff']}
              dynamicSortOptions={{ id }}
              width={DEFAULT_COLUMN_WIDTH}
              className={classNames(...classes.thead.regular)}
              label={t.staticAsString('results.split.prev_split_diff', { name: el.value.name })}
            />
            <TableHeaderItem
              key={`${id || ''}-result_diff`}
              name={splitFields['splits.result_diff']}
              dynamicSortOptions={{ id }}
              width={DEFAULT_COLUMN_WIDTH}
              className={classNames(...classes.thead.regular)}
              label={t.staticAsString('results.split.result_diff', { name: el.value.name })}
            />
          </React.Fragment>
        );
      });
  };

  render() {
    const HeaderComponent = this.HeaderComponent();

    return (
      <React.Fragment>
        <TableHead>
          <TableRow>
            <ActionCol />
            {!HeaderComponent ? null : <HeaderComponent />}
            {this._renderCheckpoints()}
          </TableRow>
        </TableHead>
      </React.Fragment>
    );
  }
}

export { THead };

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { omit } from 'lodash';
import * as React from 'react';

import { useAutocompleteStyles } from '../styles';

type Props = {
  additional?: AutocompleteAdditionalProps & { liveUpdate?: Function };
  view?: BaseViewProps;
  value: number;
  onBlur?: Function;
  afterSelect?: Function;
} & FieldBaseProps;
export function AutocompleteSelect(props: Props) {
  const { name, afterSelect, label, value, error, onChange, additional, view = {} } = props;
  const classes = useAutocompleteStyles();
  const inputClasses = additional?.classes && additional.classes();
  const { reservePlaceForHelperText, ...viewProps } = view;
  const helperText = error || (reservePlaceForHelperText && ' ');
  const withError = !!error;

  const onSelect =
    (name: string, onChange: AnyFunction) =>
    (e: React.ChangeEvent<object>, value: AnyObject): void => {
      onChange({ name, value }, e);
      if (afterSelect) {
        afterSelect(value);
      }
      if (additional?.liveUpdate) {
        additional.liveUpdate(name);
      }
    };
  return (
    <Autocomplete
      {...(additional || ({} as any))}
      {...omit(view, 'reservePlaceForHelperText')}
      id={name}
      multiple={false}
      classes={classes}
      getOptionLabel={(option: { label: string }) => option.label}
      isOptionEqualToValue={(option: AnyObject) => option.key === value.key}
      value={value || null}
      onChange={onSelect(name, onChange)}
      renderOption={(props, option: AnyObject) => {
        return (
          <li {...props} key={option.key}>
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          required={additional?.required}
          name={name}
          {...(params as any)}
          {...viewProps}
          error={withError}
          helperText={helperText}
          label={label}
          classes={inputClasses}
        />
      )}
    />
  );
}

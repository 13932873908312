// RESULTS
// Single Results
export type SingleCols =
  | 'place'
  | 'start_time'
  | 'finish_time'
  | 'time_result'
  | 'activity_link'
  | 'distance_logged'
  | 'distance_logged_at'
  | 'racer.bib_number'
  | 'racer.firstname'
  | 'racer.lastname'
  | 'racer.gender'
  | 'racer.country_id'
  | 'racer.nationality_id'
  | 'racer.class_id'
  | 'racer.discipline_id'
  | 'racer.wave_id'
  | 'racer.union';

export const singleFields = {
  place: 'place',
  start_time: 'start_time',
  finish_time: 'finish_time',
  time_result: 'time_result',
  distance_logged: 'distance_logged',
  distance_logged_at: 'distance_logged_at',
  activity_link: 'activity_link',
  'racer.bib_number': 'racer.bib_number',
  'racer.firstname': 'racer.firstname',
  'racer.lastname': 'racer.lastname',
  'racer.email': 'racer.email',
  'racer.gender': 'racer.gender',
  'racer.birthday': 'racer.birthday',
  'racer.country_id': 'racer.country_id',
  'racer.nationality_id': 'racer.nationality_id',
  'racer.class_id': 'racer.class_id',
  'racer.wave_id': 'racer.wave_id',
  'racer.discipline_id': 'racer.discipline_id',
  'racer.union': 'racer.union',
};

const MAIN_COLUMNS = [
  singleFields.place,
  singleFields['racer.bib_number'],
  singleFields['racer.firstname'],
  singleFields['racer.lastname'],
  singleFields['racer.email'],
  singleFields['racer.gender'],
  singleFields['racer.birthday'],
  singleFields['racer.country_id'],
  singleFields['racer.nationality_id'],
  singleFields['racer.class_id'],
  singleFields['racer.wave_id'],
  singleFields['racer.discipline_id'],
  singleFields['racer.union'],
  singleFields.start_time,
  singleFields.finish_time,
  singleFields.time_result,
  singleFields.distance_logged,
  singleFields.distance_logged_at,
  singleFields.activity_link,
];

const COLUMN_WIDTH: {
  [K in string]: number;
} = {
  [singleFields['activity_link']]: 190,
  [singleFields['racer.email']]: 200,
  [singleFields['racer.gender']]: 110,
  [singleFields.start_time]: 170,
  [singleFields.finish_time]: 170,
};

const SINGLE_RESULTS_COLUMNS = {
  MAIN_COLUMNS,
  COLUMN_WIDTH,
};

export { SINGLE_RESULTS_COLUMNS };

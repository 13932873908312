import Box from '@mui/material/Box';
import withStyles from '@mui/styles/withStyles';
import { observer } from 'mobx-react';
import { DUPLICATE_FORM } from 'modules/RaceDetails/constants';
import * as React from 'react';

import { Show } from 'components/Condition';
import { Form, InputWrapper, WrappedCheckbox } from 'components/Form';

import { t } from 'utils';

type Props = {
  participantsCount?: number | null;
  duplicateType?: 'race' | 'distance' | null;
};

const FieldContainer = withStyles({
  root: {
    paddingBottom: 19,
  },
})(Box);

@observer
export class Fields extends React.Component<Props> {
  render() {
    return (
      <Form id={DUPLICATE_FORM}>
        <Show if={Boolean(this.props.participantsCount)}>
          <FieldContainer>
            <InputWrapper
              name='include_participants'
              settings={{
                label: t.staticAsString(`distances.duplicate.includeParticipants`),
                required: false,
              }}
              Component={WrappedCheckbox}
            />
          </FieldContainer>
        </Show>
      </Form>
    );
  }
}

import * as React from 'react';

import { NO_SOURCE_LINK } from 'src/constants';

import { recognizeLink } from 'utils';

import { ActivityLink } from '../components/Activity';

function formatActivityLink(value: undefined | string, onEdit: ClickSpanEvent): React.ReactNode {
  if (!value) {
    return <ActivityLink {...NO_SOURCE_LINK} link={value} onEdit={onEdit} />;
  }

  const match = recognizeLink(value);

  return <ActivityLink {...match} link={value} onEdit={onEdit} />;
}

export { formatActivityLink };

import { registerCouponFormDistanceSelectReaction } from './distance.select.reaction';
import { registerCouponFormRaceSelectReaction } from './race.select.reaction';

export * from './distance.select.reaction';
export * from './race.select.reaction';

export function registerAllCouponFormReactions() {
  registerCouponFormRaceSelectReaction();
  registerCouponFormDistanceSelectReaction();
}

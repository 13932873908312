import moment from 'moment';

import { t } from 'utils';

import { errorsStore } from 'stores';

import { teamFields } from '../../../constants';

import { updateTeamResult, updateTeam } from '../../../actions';

import { generateStoreOptions } from '../../../stores';

import { processForUpdate, prepareForEdit } from '../../../tools';
import { GenerateFieldUpdate } from '../../../types';

const FIELD_UPDATE = {
  [teamFields.time_result]: function (model, context) {
    return async (value: number) => {
      const property = 'finish_time';
      const fieldId = model.generateFieldId('time_result');

      errorsStore.clear(fieldId);

      const storeOptions = generateStoreOptions(property, model);
      const updateOptions = { fieldId, property, storeOptions };

      const prevDiff = prepareForEdit.duration(model.value.start_time, model.value.finish_time);
      const [shouldBeUpdated, updateValue] = processForUpdate.duration(fieldId, value, prevDiff, model.value.start_time);

      if (!shouldBeUpdated) {
        return false;
      }

      if (value === 0) {
        errorsStore.add(fieldId, { fieldId: [t.staticAsString('results.errors.cannotBeZero')] });
        return false;
      }

      return await updateTeamResult(updateOptions, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [teamFields.start_time]: function (model, context) {
    // eslint-disable-next-line
    return async (value: nil | timeSpace.WorkDate) => {
      const property = 'start_time';
      const fieldId = model.generateFieldId(property);
      const storeOptions = generateStoreOptions(property, model);
      const updateOptions = { fieldId, property, storeOptions };

      const [shouldBeUpdated, updateValue] = processForUpdate.dateTime(fieldId, value, model.value.start_time);

      if (!shouldBeUpdated) {
        return false;
      }

      if (moment(model.value.finish_time as any).diff(value?.value) < 1) {
        errorsStore.add(fieldId, { fieldId: [t.staticAsString('results.errors.cannotBeZero')] });
        return false;
      }

      return await updateTeamResult(updateOptions, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [teamFields.finish_time]: function (model, context) {
    // eslint-disable-next-line
    return async (value: nil | timeSpace.WorkDate) => {
      const property = 'finish_time';
      const fieldId = model.generateFieldId(property);
      const storeOptions = generateStoreOptions(property, model);
      const updateOptions = { fieldId, property, storeOptions };

      const [shouldBeUpdated, updateValue] = processForUpdate.dateTime(fieldId, value, model.value.finish_time);

      if (!shouldBeUpdated) {
        return false;
      }

      if (value && value.value.diff(moment(model.value.start_time)) < 1) {
        errorsStore.add(fieldId, { fieldId: [t.staticAsString('results.errors.cannotBeZero')] });
        return false;
      }

      return await updateTeamResult(updateOptions, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [teamFields['team.bib_number']]: function (model, context) {
    const propertyName = 'bib_number';
    return async (value: any) => {
      return await updateTeam(propertyName, value, model, context);
    };
  } as GenerateFieldUpdate,

  [teamFields['team.name']]: function (model, context) {
    const propertyName = 'name';
    return async (value: any) => {
      return await updateTeam(propertyName, value, model, context);
    };
  } as GenerateFieldUpdate,

  [teamFields['team.class_id']]: function (model, context) {
    const propertyName = 'class_id';
    return async (value: number | nil) => {
      if (!model.team) {
        return false;
      }

      const prevValue = model.team.value.class_id;
      const fieldId = model.team.generateFieldId(propertyName);

      const [shouldBeUpdated, updateValue] = processForUpdate.dropDown(fieldId, value, prevValue);

      if (!shouldBeUpdated) {
        return false;
      }

      return await updateTeam(propertyName, Number(updateValue), model, context);
    };
  } as GenerateFieldUpdate,

  [teamFields['team.wave_id']]: function (model, context) {
    const propertyName = 'wave_id';
    return async (value: number | nil) => {
      if (!model.team) {
        return false;
      }

      const prevValue = model.team.value.class_id;
      const fieldId = model.team.generateFieldId(propertyName);

      const [shouldBeUpdated, updateValue] = processForUpdate.dropDown(fieldId, value, prevValue);

      if (!shouldBeUpdated) {
        return false;
      }
      return await updateTeam(propertyName, Number(updateValue), model, context);
    };
  } as GenerateFieldUpdate,
};

export { FIELD_UPDATE };

import { t } from 'utils/t';

export const COUPON_CODE_TYPES = [
  {
    key: 'registration_fee',
    value: 'registration_fee',
    label: t('coupons.new.registration_fee'),
  },
  {
    key: 'addon',
    value: 'addon',
    label: t('coupons.new.addons'),
  },
];

import { Box, Grow } from '@mui/material';
import React from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { HINT_QUERY, ROUTES } from 'src/constants';

import * as Images from 'img/upsell';

import { Button } from 'components/core';

import { history, t } from 'utils';

const Wrapper = styled.div<{ backgroundImage: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 20px;

  width: 100%;
  height: 100%;

  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.backgroundImage});
`;

const StyledBox = styled(Box)`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 740px;
  padding: 45px 20px 46px;
  background: white;
  box-shadow: 0px 6px 6px rgba(5, 72, 184, 0.5);
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 480px;
`;

const Title = styled.div`
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 28px;
  letter-spacing: 0.02em;
  line-height: 28px;
  color: ${(props) => props.theme.palette.grey800};
`;

const SubTitle = styled.div<{ wide?: boolean }>`
  ${({ wide }) =>
    wide &&
    `
    width: 593px;
  `}
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${(props) => props.theme.palette.grey700};
`;

const BoldText = styled.span`
  font-weight: 600;
  font-size: 18px;
`;

const Image = styled.img`
  margin-bottom: 25px;
  width: 466px;
  height: 267px;
`;

const StyledButton = styled(Button)`
  height: 45px;
  width: 100%;
  margin-bottom: 16px;
  text-transform: none;

  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
`;
const StyledLink = styled.a`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #0548b8;
  cursor: pointer;
`;

export type UpsellPage = 'dashboard' | 'transactions' | 'coupons' | 'raceAssistants' | 'racers' | 'smartRegistrationLinks' | 'reports';

type Props = {
  page: UpsellPage;
  calendarRaces: Array<RaceType>;
};
const Upsell = ({ page, calendarRaces }: Props) => {
  const image2x = Images[`${page}_2x`];
  const srcSet = `${image2x} 2x`;
  const link = 'https://raceid.com/organizer/pricing/';
  const firstCalendarRace = calendarRaces[0];

  return (
    <Wrapper backgroundImage={Images[`${page}_background`]}>
      <Grow in={true} timeout={800}>
        <StyledBox>
          <Body>
            <Title>{t.staticAsString(`upsell.title.${page}`)}</Title>
            <SubTitle wide={page === 'reports'}>{t.staticAsString(`upsell.subTitle.${page}`)}</SubTitle>
            <Image src={Images[page]} srcSet={srcSet} alt='promo-img' />
            <SubTitle>
              <BoldText>{t.staticAsString(`upsell.boldText.${page}`)}</BoldText>
              {t.staticAsString(`upsell.text.useRaceId`)}
            </SubTitle>
            <StyledButton
              type='apply'
              onClick={() => {
                history.push(
                  firstCalendarRace ? `${generatePath(ROUTES.raceRoute, { id: firstCalendarRace.id })}?${HINT_QUERY}` : '/races',
                );
              }}
            >
              {t.staticAsString('upsell.buttonText')}
              {' >'}
            </StyledButton>
            <StyledLink href={link} target='_blank'>
              {t.staticAsString('upsell.linkText')}
              {' >'}
            </StyledLink>
          </Body>
        </StyledBox>
      </Grow>
    </Wrapper>
  );
};

export { Upsell };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACES_URL, UPSELL_RACES_LOAD } from 'src/constants';

import { action, request } from 'utils';

class UpsellService {
  @request({ action: UPSELL_RACES_LOAD })
  async loadRacesRequest(params): Promise<any> {
    const url = generatePath(RACES_URL);

    return axios.get(url, {
      params: {
        page: 1,
        limit: 10,
        orderBy: 'created_at',
        sortedBy: 'desc',
        ...params,
      },
    });
  }

  @action({ action: UPSELL_RACES_LOAD, minRequestTime: 500 })
  async loadRaceIdRaces(): Promise<Array<RaceType>> {
    const params = {
      search: `race_url:null`,
      searchFields: 'race_url:=',
      searchJoin: 'AND',
    };
    const [isOk, response] = await this.loadRacesRequest(params);

    return isOk ? response.data.data : [];
  }

  @action({ action: UPSELL_RACES_LOAD, minRequestTime: 500 })
  async loadCalendarRaces(): Promise<Array<RaceType>> {
    const params = {
      search: `race_url:null`,
      searchFields: 'race_url:!=',
      searchJoin: 'AND',
    };
    const [isOk, response] = await this.loadRacesRequest(params);

    return isOk ? response.data.data : [];
  }
}

export const upsellService = new UpsellService();

type Props = {
  dateFormat: BEDateFormat;
};

type Args = [BEDateFormat];

function dateRange(...args: Args): Props {
  const [dateFormat] = args;

  return {
    dateFormat,
  };
}

dateRange.generate = function (...args: Args): Function {
  return () => dateRange(...args);
};

dateRange.default = function (): Props {
  return dateRange('datetime');
};

export { dateRange };

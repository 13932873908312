import { Button } from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { helper } from 'racese-react-phone-input-2';
import * as React from 'react';
import { withRouter } from 'react-router-dom';

import { COUNTRY, ORGANIZER } from 'src/constants';

import { withProgressSpinner } from 'hocs';

import { AutocompleteSelect, PhoneInput, TextField } from 'components/Fields';

import { oneFieldValidation, t, validation } from 'utils';

import { helperDistancesService, loadCountryService } from 'services';

import { countriesStore, errorsStore, organizersStore } from 'stores';

import { loadService } from '../../../services/load';

import { base, updateOrganizer as updateOrganizerConstraints } from '../../../validations';

type Props = {
  onClose: (data?) => void;
  entityId: number | null;
  clearSelectedOnUnmount: boolean;
} & RouterProps &
  HOC.withIntl;

type State = {
  organizer: OrganizerType;
};

const action = `PERSIST_${ORGANIZER}`;

@withRouter
@withProgressSpinner([`LOAD_${ORGANIZER}`, `LOAD_${COUNTRY}S`])
@observer
export class Form extends React.Component<Props, State> {
  static defaultProps = {
    clearSelectedOnUnmount: true,
  };

  state: State = {
    organizer: {
      id: 0,
      country_id: 0,
      country_obj: { key: null, value: 0 },
      email: '',
      password: '',
      city: '',
      country: '',
      address: '',
      full_name: '',
      phone: {},
      firstname: '',
      lastname: '',
      post_code: '',
    },
  };

  @validation({ action, constrains: updateOrganizerConstraints })
  validateUpdate() {
    return this.state.organizer;
  }

  componentDidMount() {
    const id = organizersStore.selected && organizersStore.selected.id;
    const { entityId } = this.props;
    loadCountryService.loadResources();

    if (entityId && entityId !== id) {
      loadService.loadResource();
    }

    this._updateStateFromStore();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    this._updateStateFromStore();
  }

  componentWillUnmount() {
    if (this.props.clearSelectedOnUnmount) {
      organizersStore.removeSelectedValue();
    }

    errorsStore.clearError(action);
  }

  onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const { entityId } = this.props;

    const isValidated = this.validateUpdate();

    if (!isValidated || !isEmpty(this.errors())) {
      return;
    }

    loadService.updateResource(entityId || 0, this._formatData(), (data) => this.props.onClose(data));
  };

  onChangeRegular = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: any = null;
    let name = '';
    const delayTime = 400;

    switch (e.type) {
      case 'click':
        value = e.target.value;
        name = e.target.name;
        break;
      case 'change':
        value = e.currentTarget.value;
        name = e.currentTarget.name;
        break;
      default:
        value = e.currentTarget.value;
        name = e.currentTarget.name;
    }

    const validateField = () => {
      if (!name) {
        return;
      }

      const constrainsField = { [name]: base[name] };

      oneFieldValidation(action, this.state.organizer, constrainsField);
    };

    this.setState(
      {
        ...this.state,
        organizer: {
          ...this.state.organizer,
          [name]: value,
        },
      },
      debounce(validateField, delayTime),
    );
  };

  onChangePhone = (name: string, value: Object) => {
    this.setState({
      ...this.state,
      organizer: {
        ...this.state.organizer,
        [name]: value,
      },
    });
  };

  onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    this.onChangeWithoutEvent({ name, value: +checked });
  };

  onChangeWithoutEvent = ({ name, value }: { name: string; value: any }) => {
    this.setState({
      ...this.state,
      organizer: {
        ...this.state.organizer,
        [name]: value,
      },
    });
  };

  errors = () => {
    const errors = errorsStore.errors.api[action];
    const validationErrors = errorsStore.errors.validation[action];

    if (validationErrors) {
      return validationErrors.formatErrors();
    }
    if (errors) {
      return errors.formatErrors();
    }
    return {};
  };

  onChangeCountry = ({ name, value }: any) => {
    this.onChangeWithoutEvent({ name, value });
  };

  onChangeRaceId = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.currentTarget.value, 10);
    const name = e.currentTarget.name;

    helperDistancesService.loadRaceDistances(value);

    this.setState({
      ...this.state,
      organizer: {
        ...this.state.organizer,
        [name]: value,
      },
    });
  };

  onChangeDistances = (e: React.ChangeEvent) => {
    this.setState({
      ...this.state,
      organizer: {
        ...this.state.organizer,
      },
    });
  };

  defaultInputProps = (name: string) => {
    return {
      name,
      value: (this.state.organizer as any)[name],
      onChange: this.onChangeRegular,
      errors: this.errors()[name],
      fullWidth: true,
      label: t.staticAsString(`organizers.form.${name}` as TranslationLockedKeys),
    };
  };

  _formatData = (): any => {
    const { organizer } = this.state;
    const { entityId } = this.props;
    let data: AnyObject = { ...organizer };
    delete data.firstname;
    delete data.lastname;

    if (!organizer.password && entityId) {
      delete data.password;
    }
    data.phone = data.phone?.phone;

    data.country_id = organizer.country_obj?.value || null;
    delete data.country_obj;

    return data;
  };

  _updateStateFromStore = () => {
    const organizer = organizersStore.selected;

    if (organizer && this.state.organizer.id === 0 && Boolean(countriesStore.values.length)) {
      const countries = countriesStore.valuesForCountryDropdown();
      const country_obj = countries.find((country) => country.value === organizer.country_id) || { key: null, value: 0, label: '' };

      const countryData = helper.takeCountryData(organizer.phone || '');
      this.setState({
        ...this.state,
        organizer: {
          ...organizer,
          country_obj,
          phone: {
            phone: organizer.phone || '',
            countryData: {
              dialCode: countryData.dialCode,
              name: countryData.name,
              countryCode: countryData.iso2,
            },
          },
        },
      });
    }
  };

  render() {
    organizersStore.selected;

    const { onClose, entityId } = this.props;

    return (
      <div className='content main-organizer-form'>
        <h1>{t.staticAsString('profile.editTitle')}</h1>
        <form className='organizer-form new-organizer' onSubmit={this.onSubmit}>
          <div className='form-content-wrapper'>
            <div className='form-fields-wrapper'>
              <TextField {...this.defaultInputProps('email')} />
              <TextField
                {...this.defaultInputProps('password')}
                type='password'
                label={t.staticAsString(`organizers.form.${entityId ? 'passwordEdit' : 'password'}` as TranslationLockedKeys) as any}
              />
              <TextField {...this.defaultInputProps('full_name')} />
              <PhoneInput {...this.defaultInputProps('phone')} onChange={this.onChangePhone} />
              <TextField {...this.defaultInputProps('address')} />
              <TextField {...this.defaultInputProps('city')} />
              <AutocompleteSelect
                {...this.defaultInputProps('country_obj')}
                label={t.staticAsString(`organizers.form.country`)}
                options={countriesStore.valuesForCountryDropdown()}
                onChange={this.onChangeCountry}
              />
              <TextField {...this.defaultInputProps('post_code')} />
              <div className='btn-group control-btn '>
                <Button className='cancel' onClick={() => onClose()}>
                  {t.staticAsString('races.new.cancel')}
                </Button>
                <Button className='submit' onClick={(e) => this.onSubmit(e)}>
                  {t.staticAsString('organizers.form.submit')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export type TableRow = {
  id: number;
  parentRaceId?: number;
  name: string;
  isRace?: boolean;
  isDistance?: boolean;
  currency: Currency;
  subRows: TableRow[];
  commission: TCommission | null;
};
export type Model = {
  id: number;
  full_name: string;
  name: string;
  email: string;
  organizer_id: number;
  commission: TCommission | null;
  distances: Distance[];
  currency: Currency;
};

export type Currency = {
  id: number;
  is_visible: boolean;
  iso_code: string;
  num_code: number;
};

export type TCommission = {
  id: number;
  amount: number;
  percent_value: number;
  vat_percents: number;
  currency_id: number;
  is_absorb: boolean;
};

export type DefaultCommission = {
  id: number;
  amount: number;
  percent_value: number;
  vat_percents: number;
  currency_id: number;
  refund_fee: number;
};

export type Distance = {
  id: number;
  name: string;
  race_parent_id: number;
  commission?: TCommission | null;
};

export type CommissionResponse = {
  data: TableRow[];
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
};

export type TCommissionResponse = {
  id: number;
  amount: number;
  percent_value: number;
  vat_percents: number;
  currency_id: number;
  is_absorb: boolean;
};

export enum CommissionCreateEnum {
  Race = 'App\\Race',
  Distance = 'App\\Distance',
}

export type TCommissionError = { amount?: boolean; percent_value?: boolean; is_absorb?: boolean };

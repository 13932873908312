import { form, progressStore } from 'stores';

import { COUPON_FORM_STATES, COUPONS_FORM_DEFAULT_VALUE, COUPONS_FORM_ID } from '../constants';

import { couponFormRaceService, couponFormService } from '../services';

import { registerAllCouponFormReactions } from '../reactions';

export const mountCouponForm = async (couponId?: number) => {
  progressStore.log(COUPON_FORM_STATES.INIT, 'progress');
  await couponFormRaceService.loadRaces();

  // First we in itialize the form, either empty or not.
  if (Boolean(couponId)) await couponFormService.loadCoupon(couponId!);
  else form.merge(COUPONS_FORM_ID, COUPONS_FORM_DEFAULT_VALUE);

  // And only then we register reactions.
  registerAllCouponFormReactions();
  progressStore.log(COUPON_FORM_STATES.INIT, 'completed');
};

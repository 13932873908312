import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { COMMISSION_URL, COMMISSIONS_URL } from 'src/constants';

import { progressStore } from 'stores';

import { CommissionConstants } from '../constants/commissions.constants';

import { CommissionTransformMapper } from '../mappers/transform.mapper';
import { CommissionFilters } from '../types/filters.type';

export namespace CommissionsService {
  export async function load(filters?: CommissionFilters) {
    progressStore.log(CommissionConstants.Table, 'progress');
    try {
      const response = await axios.get(COMMISSIONS_URL, {
        params: {
          ...filters,
          per_page: 50,
        },
      });
      return {
        data: CommissionTransformMapper.fromBackend(response.data.data),
        meta: response.data.meta,
      };
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(CommissionConstants.Table, 'completed');
    }
  }

  export async function patch(id: number, field: string, value: string) {
    try {
      await axios.patch(generatePath(COMMISSION_URL, { id }), { [field]: value });
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(CommissionConstants.Table, 'completed');
    }
  }
}

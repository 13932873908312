import { makeStyles } from '@mui/styles';

/*
 * TODO TS, some of those styles can be broken see https://stackoverflow.com/questions/59464618/using-custom-theme-in-makestyles
 * and styles in here from theme that be not fetched cause theme is not created yet
 * PLEASE CHECK
 * just uncomment theme: any to check what can be broken
 */
export const addBtnStyles = makeStyles<any>((theme: any): any => {
  return {
    addNew: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: (props: any) => (props.center ? 'center' : 'flex-start'),
      cursor: 'pointer',
    },
    icon: {
      color: theme.palette.orange1,
      height: '24px',
    },
    label: {
      fontSize: 14,
      marginLeft: 8,
      color: theme.palette.orange1,
      fontWeight: '500',
      lineHeight: '24px',
      textTransform: 'uppercase',
    },
  };
});

/*
 * TODO TS, some of those styles can be broken see https://stackoverflow.com/questions/59464618/using-custom-theme-in-makestyles
 * and styles in here from theme that be not fetched cause theme is not created yet
 * PLEASE CHECK
 * just uncomment theme: any to check what can be broken
 */
export const assignedAssistantsStyles = makeStyles<any>((theme: any): any => ({
  root: {},
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: 8,
  },
  heading: {
    fontSize: 16,
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  detailsHeading: {
    fontSize: 14,
    marginRight: 8,
  },
  details: {
    flexDirection: 'column',
    border: '1px solid',
    borderColor: theme.palette.white3,
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
  delete: {
    '& span': {
      maxHeight: 24,
      '& div': {
        maxHeight: 24,
      },
    },
  },
  expand: {
    color: theme.palette.grey6,
  },
}));

export const checkBoxStyles = makeStyles<any>((theme: any): any => ({
  label: {
    fontSize: 14,
    color: theme.palette.grey6,
  },
}));

export const mainPanelStyles = makeStyles<any>(() => ({
  root: {
    marginBottom: 8,
    borderRadius: 0,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
}));

export const expansionPanelSummaryStyles = makeStyles<any>((theme: any): any => ({
  root: {
    padding: '0 12px',
    marginBottom: -1,
    height: 56,
    minHeight: 56,
    border: '1px solid',
    borderColor: theme.palette.white3,
    backgroundColor: 'rgba(237, 237, 237, 0.3)',

    '&$expanded': {
      height: 56,
      minHeight: 56,
      transition: 'background-color 1s ease',
      backgroundColor: 'rgba(237, 237, 237, 0.3)',
      borderBottom: 0,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    margin: 0,
    height: 46,
    '&$expanded': {
      height: 46,
      margin: 0,
    },
  },
  expanded: {},
}));

export const formControlsStyles = makeStyles<any>((theme: any): any => {
  return {
    addNew: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '34px',
      marginBottom: '26px',
      cursor: 'pointer',
    },
    icon: {
      color: theme.palette.orange1,
      height: '24px',
    },
    label: {
      marginLeft: '16px',
      color: theme.palette.orange1,
      fontWeight: '500',
      lineHeight: '24px',
      textTransform: 'uppercase',
    },
    controlsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },
    createBtn: {
      marginLeft: 16,
      minWidth: '96px',
      borderRadius: '2px',
      backgroundColor: theme.palette.yellow1,
      color: 'black',
      fontSize: '14px',
      fontWeight: 500,
    },
    cancelBtn: {
      fontSize: '14px',
      minWidth: '96px',
    },
  };
});

export const centeredIconStyles = makeStyles<any>({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& div': {
      height: 18,
      '& svg': {
        position: 'absolute',
        height: 18,
        width: 18,
      },
    },
  },
});

export const toolTipStyles = makeStyles<any>({
  popper: {
    opacity: 1,
  },
  tooltip: {
    maxWidth: 400,
    fontSize: 10,
    padding: 14,
  },
});

/*
 * TODO TS, some of those styles can be broken see https://stackoverflow.com/questions/59464618/using-custom-theme-in-makestyles
 * and styles in here from theme that be not fetched cause theme is not created yet
 * PLEASE CHECK
 * just uncomment theme: any to check what can be broken
 */
export const itemsStyles = makeStyles<any>((theme: any): any => ({
  root: {
    width: '100%',
  },
  truncated: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    '&:hover': {
      textOverflow: 'clip',
      whiteSpace: 'normal',
      wordBreak: 'break-all',
    },
  },
  heading: {
    flex: 1,
    fontSize: 16,
    flexBasis: '33.33%',
    flexShrink: 0,
    alignSelf: 'center',

    color: theme.palette.grey1,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  content: {
    border: '2px solid green',
  },
  secondaryHeading: {
    flex: 1,
    fontSize: 16,
    flexBasis: '33.33%',
    flexShrink: 0,
    alignSelf: 'center',
  },
  details: {
    flexDirection: 'column',
    border: '1px solid',
    borderColor: theme.palette.white3,
  },
  detailsBox: {
    height: 43,
    display: 'flex',
    paddingLeft: 14,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.white3,
  },
  delete: {
    '& span': {
      maxHeight: 24,
      '& div': {
        maxHeight: 24,
      },
    },
  },
  removeAssistant: {
    maxWidth: 41,
    '& span': {
      minWidth: 24,
      minHeigth: 24,
      '& svg': {
        color: theme.palette.grey6,
        maxWidth: 18.87,
        maxHeigth: 18.87,
      },
    },
  },
  expand: {
    color: theme.palette.grey6,
  },
  divider: {
    height: 28,
    alignSelf: 'center',
  },
}));

export const itemMainPanelStyles = makeStyles<any>(() => ({
  root: {
    marginBottom: 8,
    borderRadius: 0,
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
}));

/*
 * TODO TS, some of those styles can be broken see https://stackoverflow.com/questions/59464618/using-custom-theme-in-makestyles
 * and styles in here from theme that be not fetched cause theme is not created yet
 * PLEASE CHECK
 * just uncomment theme: any to check what can be broken
 */
export const itemExpansionPanelSummaryStyles = makeStyles<any>((theme: any): any => ({
  root: {
    padding: (props) => (props.noAssistants ? '0 0 0 12px' : '0 12px'),
    marginBottom: -1,
    height: 43,
    minHeight: 43,
    border: '1px solid',
    borderColor: theme.palette.white3,

    '&$expanded': {
      height: 43,
      minHeight: 43,
      transition: 'background-color 1s ease',
      backgroundColor: theme.palette.yellow5,
      borderBottom: 0,
    },
  },
  content: {
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',

    margin: 0,
    height: 46,
    '&$expanded': {
      height: 46,
      margin: 0,
    },
  },
  expanded: {},
}));

/*
 * TODO TS, some of those styles can be broken see https://stackoverflow.com/questions/59464618/using-custom-theme-in-makestyles
 * and styles in here from theme that be not fetched cause theme is not created yet
 * PLEASE CHECK
 * just uncomment theme: any to check what can be broken
 */
export const checkpointFormStyles = makeStyles<any>((theme: any): any => ({
  root: {
    width: '100%',
    color: theme.palette.grey6,
    '& form': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  title: {
    margin: '25px 24px 18px 24px',
  },
  block: {
    '&:first-child': {
      marginTop: 24,
    },
    marginLeft: 24,
    marginRight: 24,
    marginTop: 7,
    marginBottom: 7,
  },
  addButton: {
    marginTop: 29,
    marginBottom: 16,
  },
  controls: {
    marginTop: 16,
    marginBottom: 16,
  },
  heading: {
    color: theme.palette.textPrimary,
    fontSize: 20,
    lineHeight: 1.2,
  },
  text: {},
}));

export const format = 'HH:MM';
export const momentFormat = 'HH:mm';
export const placeholder = 'hh:mm';
export const mask = [
  /\d/,
  /\d/, // hour
  ':',
  /\d/,
  /\d/, // minute
];
export const placeholderChar = '_';
export const INVALID_DATE: 'Invalid date' = 'Invalid date';

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { SYNCHRONIZATIONS_URL, MAPPING_SYNCHRONIZATION_URL } from 'src/constants';
import { toastStore } from 'src/stores';
import { request, action, t } from 'src/utils';

import { EQ_TIMING_REQUEST, EQ_MAPPING_REQUEST } from '../constants';

import { syncStore, SyncType } from '../../Shared';

class EqTiming {
  @request({ action: EQ_TIMING_REQUEST })
  async createSyncRequest(data: SyncType): Promise<any> {
    return axios.post(SYNCHRONIZATIONS_URL, data);
  }

  @action({ action: EQ_TIMING_REQUEST })
  async createSync(data: SyncType) {
    const [status, response] = await this.createSyncRequest(data);

    if (status) {
      syncStore.set(response.data.data);
    } else {
      const firstError = ((Object.values(response.data.errors || {}) || [])[0] as string[]) || [];
      toastStore.show(t.staticAsString(firstError[0] as TranslationLockedKeys));
    }

    return status;
  }

  @request({ action: EQ_MAPPING_REQUEST })
  async createMappingRequest(data: AnyObject, id: number): Promise<any> {
    const url = generatePath(MAPPING_SYNCHRONIZATION_URL, { id });
    return axios.post(url, data);
  }

  @action({ action: EQ_MAPPING_REQUEST })
  async createMapping(data: AnyObject, id: number) {
    const [isOk, response] = await this.createMappingRequest(data, id);
    return [isOk, response];
  }
}

export const eqService = new EqTiming();

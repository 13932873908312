import { isEmpty } from 'lodash';

import { ObjectForValidation } from '../types/objectForValidation';

const constructObjectForValidation = (state: any): ObjectForValidation => {
  const { tab_options: tabOptions } = state.editorSettings;

  const getClasses = () => {
    if (isEmpty(state.classes) && tabOptions.includes('classes')) {
      return [{ title: '', _delete: undefined }];
    }
    return state.classes.map((el) => ({
      title: el.title,
      _delete: el._delete,
    }));
  };

  const getDisciplines = () => {
    if (isEmpty(state.disciplines) && tabOptions.includes('disciplines')) {
      return [{ title: '' }];
    }
    return state.classes.map((el) => ({
      title: el.title,
    }));
  };

  const getPrices = () => {
    if (isEmpty(state.prices) && tabOptions.includes('prices')) {
      return [
        {
          vat_percents: null,
          prices: {
            value: '',
            date_from: '',
          },
        },
      ];
    }
    return state.prices.map((el) => ({
      vat_percents: state.distance.vat_percents,
      prices: {
        value: el.value,
        date_from: el.date_from,
      },
    }));
  };

  const getWaves = () => {
    if (isEmpty(state.waves) && tabOptions.includes('waves')) {
      return [{ name: '', max_members: 1 }];
    }
    return state.waves.map((el) => ({
      name: el.name,
      max_members: el.max_members,
    }));
  };

  const getSelfServices = () => {
    return {
      price: tabOptions.includes('selfServices') ? state.distance.transfer_registration_settings?.price : '',
    };
  };

  const getCustomFields = () => {
    if (isEmpty(state.custom_fields) && tabOptions.includes('custom_fields')) {
      return [
        {
          helper_text: '',
          image: undefined,
          is_required: -1,
          name: '',
          type: '',
          values: undefined,
          _delete: undefined,
        },
      ];
    }
    return state.custom_fields.map((el) => ({
      image: el.image,
      name: el.name,
      helper_text: el.helper_text,
      type: el.type,
      is_required: el.is_required,
      _delete: el._delete,
      values: el.values?.map((optionEl) => ({
        price: optionEl.price,
        value: optionEl.value,
        vat_percents: optionEl.vat_percents,
        _delete: optionEl._delete,
      })),
    }));
  };

  return {
    distance: {
      name: state.distance.name,
      distance_mode: state.distance.distance_mode,
      registration_starts_at: state.distance.registration_starts_at,
      registration_ends_at: state.distance.registration_ends_at,
      race_date: state.distance.race_date,
      ends_at: state.distance.ends_at,
      race_length: state.distance.race_length,
      max_members_quantity: state.distance.max_members_quantity,
      min_members_quantity: state.distance.min_members_quantity,
      race_qty: state.distance.race_qty,
      allow_no_profile_registration: state.distance.allow_no_profile_registration,
      type: state.distance.type,
      start_type: state.distance.start_type,
      goal: {
        goal_type: state.goal.goal_type,
        goal_max_length: state.goal_max_length,
      },
    },

    classes: getClasses(),

    disciplines: getDisciplines(),

    prices: getPrices(),

    waves: getWaves(),

    custom_fields: getCustomFields(),

    self_services: getSelfServices(),

    basic_info: {
      name: state.distance.name,
    },
  };
};

export { constructObjectForValidation };

import FormHelperText from '@mui/material/FormHelperText';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import shortid from 'shortid';

import { Show } from 'components/Condition';
import { TimeWithoutSeconds } from 'components/Form/Fields';

import { t } from 'utils';

import { errorsStore } from 'stores';

const ACTION = 'TIME_RANGE_FILTER';

type Props = {
  autoFocus?: boolean;
} & FilterProps;

type State = {
  fromValue?: Date | null | any;
  toValue?: Date | null | any;
};

@observer
class TimeRangeFilter extends React.Component<Props, State> {
  id: string;
  action: string;

  constructor(props: Props) {
    super(props);
    this.id = shortid();
    const dates = props.value || [];

    this.state = {
      fromValue: moment(dates[0], 'HH:mm').toDate(),
      toValue: moment(dates[1], 'HH:mm').toDate(),
    };
    this.action = `${ACTION}:${props.name}`;
  }

  componentWillUnmount() {
    errorsStore.clearFrontendError(this.action);
  }

  parseValuesToDate = (): Array<string> => {
    const { fromValue, toValue } = this.state;
    if (!fromValue || !toValue) {
      return [];
    }
    const timeFrom = moment(fromValue).isValid() ? moment(fromValue).format('HH:mm') : '00:00';
    const timeTo = moment(toValue).isValid() ? moment(toValue).format('HH:mm') : '23:59';

    return [timeFrom, timeTo];
  };

  onChange =
    (name: string) =>
    ({ value }: { value: any }) => {
      this.setState({
        [name]: value,
      });
    };

  validate = (): boolean => {
    errorsStore.clearFrontendError(this.action);

    const { fromValue, toValue } = this.state;

    if ((!fromValue || !moment(fromValue).isValid()) && (!toValue || !moment(toValue).isValid())) {
      errorsStore.addFrontendError(this.action, { fromToError: [t.staticAsString('filter.errors.fieldsRequired') as any] });
      return false;
    }

    const start = moment(fromValue as any);
    const end = moment(toValue as any);

    if (start.isSameOrAfter(end)) {
      errorsStore.addFrontendError(this.action, { fromToError: [t.staticAsString('filter.errors.toLowerThanFrom') as any] });
      return false;
    }

    return true;
  };

  keyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.applyFilter();
    }
  };

  applyFilter = (): void => {
    const { name } = this.props;
    const valid = this.validate();

    if (!valid) {
      return;
    }
    const value = this.parseValuesToDate();

    this.props.apply({ name, value });
  };

  clear = () => {
    this.setState({
      fromValue: null,
      toValue: null,
    });
  };

  render() {
    const { name } = this.props;
    const { fromValue, toValue } = this.state;
    const errorObject = errorsStore.getFormattedErrors(this.action) || {};
    const errors = errorObject.fromToError || [];

    const displayClear = !!fromValue || moment(fromValue as any).isValid() || !!toValue || moment(toValue as any).isValid();

    return (
      <div className='filter-container'>
        <div className='form-group' onKeyUp={this.keyPress}>
          <TimeWithoutSeconds
            id={`from_${name}`}
            name={`from_${name}`}
            label=''
            className={classNames('form-control', 'range-field')}
            value={fromValue}
            onChange={this.onChange('fromValue')}
          />
          <div className='separate-from-to'>-</div>
          <TimeWithoutSeconds
            id={`to_${name}`}
            name={`to_${name}`}
            className={classNames('form-control', 'range-field')}
            label=''
            value={toValue}
            onChange={this.onChange('toValue')}
          />
        </div>
        <Show if={!!errors[0]}>
          <FormHelperText className='errors' id={`${name}_error`}>
            {errors[0]}
          </FormHelperText>
        </Show>
        <div className='filter-control'>
          <span className={classNames('clear', { hidden: !displayClear })} onClick={this.clear}>
            Clear
          </span>
          <span className='apply' onClick={this.applyFilter}>
            Apply
          </span>
        </div>
      </div>
    );
  }
}

export { TimeRangeFilter };

import { Observer } from 'mobx-react';
import * as React from 'react';

import { withProgressSpinner, withSearch, withSorting, ListModule } from 'hocs';

import { ExportToolbar } from 'components/ExportToolbar';
import { FilterLabelToolbar } from 'components/Filter/FilterLabelToolbar';
import { SubscribedPagination } from 'components/Pagination';

import { Toolbar } from '../../components/Toolbar';

import { EXPORT_TYPES, COLUMNS, COLUMN_WIDTH, LOAD_PAYMENTS } from '../../constants';

import { mount, unmount } from '../../actions';

import { loadService } from '../../services';

import { Context } from '../../context';
import { ContextType } from '../../context';
import { Table } from '../Table';

type Props = {};

@withProgressSpinner([LOAD_PAYMENTS])
@ListModule(loadService, mount, unmount)
@withSorting(loadService)
@withSearch(loadService, 'payments.list.table')
export class AdminPayments extends React.Component<Props> {
  context: ContextType = null as any;
  static contextType = Context;

  componentDidMount(): void {
    this.context.payments.actions.mount();
  }

  componentWillUnmount(): void {
    this.context.payments.actions.unmount();
  }

  render() {
    const {
      actions: { onExportPaymentsList },
      stores: { paymentsStore },
    } = this.context.payments;
    const onExport = (_e: React.SyntheticEvent, type: string) => {
      onExportPaymentsList(type, paymentsStore.params, paymentsStore.paginationMeta);
    };

    return (
      <div className='payments-container content main-container-list'>
        <div className='toolbar-container'>
          <Toolbar />
          <Observer>
            {() => {
              return <ExportToolbar types={EXPORT_TYPES} onExport={onExport} totalEntries={paymentsStore.paginationMeta.total || 0} />;
            }}
          </Observer>
          <FilterLabelToolbar />
        </div>
        <div className='component-list scrollable-wrapper'>
          <Table columns={COLUMNS} columnWidth={COLUMN_WIDTH} />
        </div>
        <SubscribedPagination />
      </div>
    );
  }
}

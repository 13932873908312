import moment from 'moment';

import { duration, time } from 'utils';

import { EMPTY_TIME_DIFF } from '../../constants';

import { roundDiff } from '../round';

function basic(startDate: nil | string, finishDate: nil | string, emptyStub: string) {
  if (!time.isValid(startDate as moment.MomentInput, 'datetimems') || !time.isValid(finishDate as moment.MomentInput, 'datetimems')) {
    return emptyStub;
  }

  const start = time.datetimems(startDate as moment.MomentInput).parse();
  const finish = time.datetimems(finishDate as moment.MomentInput).parse();

  return duration.time(roundDiff(duration.calcDiff(start.val, finish.val)), true);
}

function raw(startDate: nil | string, finishDate: nil | string) {
  return basic(startDate, finishDate, '');
}

function stub(startDate: nil | string, finishDate: nil | string) {
  return basic(startDate, finishDate, EMPTY_TIME_DIFF);
}

const formatDuration = {
  basic,
  raw,
  stub,
};

export { formatDuration };

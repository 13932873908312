import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';

import { htmlSanitizer, t } from 'utils';

type Props = {
  userEmail: string;
};

const WrapperText = styled.span`
  align-items: center;
  font-size: 16px;
  line-height: 24px;

  b {
    font-weight: 700;
    color: black;
  }
`;

const VerifyBannerBodyText = ({ userEmail }: Props) => {
  return (
    <WrapperText>
      <span
        key={shortid()}
        dangerouslySetInnerHTML={{ __html: htmlSanitizer(t.staticAsString('banner.verifyEmail.body.text', { userEmail })) }}
      ></span>
    </WrapperText>
  );
};

export { VerifyBannerBodyText };

import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import { Box, MenuItem, TextField, Tooltip } from '@mui/material';
import { truncate } from 'lodash';
import { Transaction } from 'modules/Reconciliation/Transactions/types/transaction.type';
import RefundDisplay from 'modules/Reconciliation/shared/components/Refund';
import { formatCurrency } from 'modules/Reconciliation/shared/utils/formatCurrency';
import React, { useCallback } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { ColumnWithLooseAccessor } from 'react-table';

import { ROUTES } from 'src/constants/routes';

import { DatePicker } from 'components/Form/Fields';
import { Icon } from 'components/Icon';

import { t } from 'utils/t';

import { currenciesStore } from 'stores';

const COLUMNS = [
  'id',
  'external_id',
  'order_id',
  'created_date',
  'email',
  'race_id',
  'race_title',
  'distance_id',
  'distance_title',
  'organizer_id',
  'first_name',
  'last_name',
  'status',
  'type',
  'discount',
  'custom_fields_amount',
  'total',
  'currency',
  'receipt',
] as const;

const title = (prefix: string) => t.staticAsString(`reconciliation.transactions.${prefix}` as any);
const header = (prefix: string) => t.static(`reconciliation.transactions.${prefix}` as any);

function NumberRangeFilter({ column: { filterValue = [], setFilter, preFilteredRows, id } }) {
  return (
    <Box display='flex' flexDirection='row'>
      <Box minWidth='80px' p={0.5}>
        <TextField
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val === '0' || val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          value={filterValue[0]}
          variant='outlined'
          size='small'
          label={title('from')}
        />
      </Box>
      <Box minWidth='80px' p={0.5}>
        <TextField
          value={filterValue[1]}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val === '0' || val ? parseInt(val, 10) : undefined]);
          }}
          variant='outlined'
          size='small'
          label={title('to')}
        />
      </Box>
    </Box>
  );
}

function SelectColumnFilter(props) {
  const {
    column: { filterValue, setFilter, preFilteredRows, id, selectProps },
  } = props;
  const options = selectProps.options() || [];

  return (
    <Box minWidth='160px' p={0.5}>
      <TextField
        variant='outlined'
        size='small'
        fullWidth
        select
        name={id}
        label='Select'
        value={filterValue ?? 'All'}
        onChange={(e) => {
          if (e.target.value === 'All') {
            return setFilter(undefined);
          }
          setFilter(e.target.value);
        }}
      >
        <MenuItem key='All' value='All'>
          {title('all')}
        </MenuItem>
        {options.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}

function DateFilter(props) {
  const {
    column: { filterValue, setFilter, preFilteredRows, id },
  } = props;
  return (
    <Box minWidth='250px' p={0.5}>
      <DatePicker
        hideArrow
        view={{
          fullWidth: true,
          readOnly: true,
          variant: 'outlined',
          size: 'small',
        }}
        label=''
        name='date_picker'
        value={filterValue}
        onChange={({ name, value }) => {
          setFilter(value);
        }}
        onClear={() => {
          if (id === 'created_date') {
            setFilter(null);
            setFilter(null);
          }
        }}
      />
    </Box>
  );
}

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<Transaction> & AnyObject } = {
  id: {
    id: 'id',
    Header: header('transactionNumber'),
    accessor: (tx) => tx.id,
    Cell: ({ row: { original } }) => (
      <Link to={`/reconciliation/transactions/${original.id}`} style={{ color: 'black', textDecoration: 'underline' }}>
        {original.id}
      </Link>
    ),
    filter: 'between',
    Filter: NumberRangeFilter,
  },
  order_id: {
    id: 'order_id',
    Header: 'Order ID',
    accessor: (tx) => tx.order_id,
  },
  external_id: {
    id: 'external_id',
    Header: header('externalId'),
    accessor: (tx) => tx.external_id,
    Cell: ({
      row: {
        original: { external_id },
      },
    }) => {
      const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
      let timer: number = 0;
      const handleClick = () => {
        navigator.clipboard.writeText(external_id);
        if (!timer) {
          setTooltipIsOpen(true);
          timer = setTimeout(() => {
            setTooltipIsOpen(false);
            timer = 0;
          }, 1000);
        }
      };
      return (
        <Tooltip
          placement='top'
          open={tooltipIsOpen}
          title={
            <span style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
              <FileCopyOutlined fontSize='small' style={{ marginRight: 5 }} /> {title('copiedToClipboard')}
            </span>
          }
        >
          <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleClick}>
            <FileCopyOutlined fontSize='small' style={{ marginRight: 5 }} /> {truncate(external_id, { length: 30 })}
          </span>
        </Tooltip>
      );
    },
  },

  created_date: {
    id: 'created_date',
    Header: header('date'),
    Filter: DateFilter,
    accessor: (tx) => tx.created_date,
    Cell: ({ row: { original } }) => {
      return <>{original.created_date}</>;
    },
  },

  race_id: {
    id: 'race_id',
    Header: header('race_id'),
    accessor: (tx) =>
      tx.race_id ? (
        <Link to={generatePath(ROUTES.raceRoute, { id: tx.race_id })} style={{ color: 'black', textDecoration: 'underline' }}>
          {tx.race_id}
        </Link>
      ) : (
        '—'
      ),
  },
  race_title: {
    id: 'race_title',
    Header: header('race_title'),
    accessor: (tx) =>
      tx.race_title ? (
        <Link to={generatePath(ROUTES.raceRoute, { id: tx.race_id })} style={{ color: 'black', textDecoration: 'underline' }}>
          {tx.race_title}
        </Link>
      ) : (
        '—'
      ),
  },
  distance_id: {
    id: 'distance_id',
    Header: header('distance_id'),
    // accessor: (tx) => tx.distance_id ?? '—',
    // ! Return in 7.2.0
    accessor: (tx) => {
      let route = ROUTES.distancesRacersRoute;
      if (tx.distance_type === 'team') route = ROUTES.distancesTeamsRoute;
      return tx.distance_id && tx.race_id ? (
        <Link to={generatePath(route, { raceId: tx.race_id, id: tx.distance_id })} style={{ color: 'black', textDecoration: 'underline' }}>
          {tx.distance_id}
        </Link>
      ) : (
        '—'
      );
    },
  },
  distance_title: {
    id: 'distance_title',
    Header: header('distance_title'),
    accessor: (tx) => {
      let route = ROUTES.distancesRacersRoute;
      if (tx.distance_type === 'team') route = ROUTES.distancesTeamsRoute;
      return tx.distance_title && tx.race_id ? (
        <Link to={generatePath(route, { raceId: tx.race_id, id: tx.distance_id })} style={{ color: 'black', textDecoration: 'underline' }}>
          {tx.distance_title}
        </Link>
      ) : (
        '—'
      );
    },
  },
  email: {
    id: 'email',
    Header: header('email'),
    accessor: (tx) => tx.email ?? '—',
  },
  organizer_id: {
    id: 'organizer_id',
    Header: header('organizer_id'),
    accessor: (tx) =>
      tx.organizer_id ? (
        <Link to={generatePath(ROUTES.systemUserRoute, { id: tx.organizer_id })} style={{ color: 'black', textDecoration: 'underline' }}>
          {tx.organizer_id}
        </Link>
      ) : (
        '—'
      ),
  },
  first_name: {
    id: 'firstname',
    Header: header('firstname'),
    accessor: (tx) => tx.firstname ?? '—',
  },
  last_name: {
    id: 'lastname',
    Header: header('lastname'),
    accessor: (tx) => tx.lastname ?? '—',
  },
  status: {
    id: 'status',
    Header: header('state'),
    Filter: SelectColumnFilter,
    filter: 'equals',
    selectProps: {
      options: () => [
        {
          key: 'pending',
          value: 1,
          label: title('pending'),
        },
        {
          key: 'completed',
          value: 2,
          label: title('completed'),
        },
        {
          key: 'failed',
          value: 3,
          label: title('failed'),
        },
      ],
    },
    accessor: (tx) => tx.status,
    Cell: ({ row: { original } }) => {
      let text = '';
      let color = '';
      let bg = '';
      switch (original.status) {
        case 1: {
          text = title('pending');
          color = '#65B7FF';
          bg = 'rgba(101, 183, 255, 0.12);';
          break;
        }
        case 2: {
          text = title('completed');
          color = '#66BB6A';
          bg = 'rgba(102, 187, 106, 0.12)';
          break;
        }
        case 3: {
          text = title('failed');
          color = '#FF8A65';
          bg = 'rgba(255, 138, 101, 0.12)';
          break;
        }
      }

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            fontSize: 12,
            textTransform: 'uppercase',
            width: 95,
            height: 22,
            color,
            backgroundColor: bg,
          }}
        >
          {text}
        </Box>
      );
    },
  },
  type: {
    id: 'type',
    Header: header('transactionType'),
    Filter: SelectColumnFilter,
    filter: 'equals',
    selectProps: {
      options: () => [
        {
          key: 2,
          value: 2,
          label: title('authorization'),
        },
        {
          key: 3,
          value: 3,
          label: title('capture'),
        },
        {
          key: 4,
          value: 4,
          label: title('refunded'),
        },
      ],
    },
    accessor: (tx) => tx.type,
    Cell: ({ row: { original } }) => {
      let text = '';
      let color = '';
      switch (original.type) {
        case 2: {
          text = title('authorization');
          color = '#65B7FF';
          break;
        }
        case 3: {
          text = title('capture');
          color = '#F9D026';
          break;
        }
        case 4: {
          text = title('refunded');
          color = '#A8AEB8';
          break;
        }
      }

      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <Box
            sx={{
              height: 8,
              width: 8,
              backgroundColor: color,
              borderRadius: '50%',
              display: 'inline-block',
              mr: 1,
            }}
          ></Box>
          {text}
        </Box>
      );
    },
  },

  discount: {
    id: 'discount',
    Header: header('discount'),
    accessor: (tx) => tx.discount,
    Cell: ({ row: { original } }) => <>{formatCurrency(original.discount / 100, original.currency) ?? '—'}</>,
    filter: 'between',
    Filter: NumberRangeFilter,
  },

  custom_fields_amount: {
    id: 'custom_fields_amount',
    Header: header('custom_fields_amount'),
    accessor: (tx) => tx.custom_fields_amount,
    Cell: ({ row: { original } }) => <>{formatCurrency((original.custom_fields_amount ?? 0) / 100, original.currency) ?? '—'}</>,
    filter: 'between',
    Filter: NumberRangeFilter,
  },

  total: {
    id: 'total',
    Header: header('grandTotal'),
    accessor: (tx) => tx.total,
    Cell: ({ row: { original } }) => {
      const roundedTotal = original.total / 100;
      if (original.type === 4) {
        return <RefundDisplay value={roundedTotal} currency={original.currency} />;
      }
      return <>{formatCurrency(roundedTotal, original.currency)}</>;
    },
    filter: 'between',
    Filter: NumberRangeFilter,
  },
  currency: {
    id: 'currency',
    Header: header('currency'),
    filter: 'equals',
    Filter: SelectColumnFilter,
    selectProps: {
      options: () => currenciesStore.valuesForSelect,
    },
    any: 'props',
    accessor: (tx) => tx.currency,
    Cell: ({ row: { original } }) => <>{original.currency ?? '—'}</>,
  },
  receipt: {
    id: 'receipt',
    Header: header('receipt'),
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      return (
        <div style={{ textAlign: 'center', marginRight: '20px' }}>
          {original.receipt ? (
            <a href={original.receipt.url} download={`Receipt ${original.distance_title}.pdf`}>
              <Icon value='download' />
            </a>
          ) : (
            '—'
          )}
        </div>
      );
    },
  },
};

export function generateTransactionColumns() {
  return COLUMNS.map((c) => RenderColumns[c]);
}

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { UpsellWrapper } from 'components/Upsell';

import { Detail } from './Detail';
import FormPage from './FormPage/FormPage';

class SystemUsers extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path={ROUTES.systemUsersRoute} render={() => <UpsellWrapper page={'raceAssistants'} />} exact />
        <Route path={ROUTES.systemUserNewRoute} component={FormPage} exact />
        <Route path={ROUTES.systemUserEditRoute} component={FormPage} />
        <Route path={ROUTES.systemUserRoute} component={Detail} />
      </Switch>
    );
  }
}

export { SystemUsers };

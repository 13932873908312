import { t } from 'utils';

import { toastStore } from 'stores';

import { distanceActionsService } from '../services';

import { distanceStore } from '../stores';

import { loadNewResultAutocomplete } from './loadNewResultAutocomplete';

async function resetResults() {
  const distanceModel = distanceStore.modelSelected;

  if (!distanceModel) {
    return;
  }

  const status = await distanceActionsService.deleteResults(distanceModel.value.id);
  await loadNewResultAutocomplete();

  const message = status ? 'distances.reset.results' : 'distances.canNotDelete';

  toastStore.show(t(message));
}

export { resetResults };

import * as React from 'react';

type TableEmptyListNotificationProps = {
  text: TranslationLockedKeys | string;
};

const TableEmptyListNotification = (props: TableEmptyListNotificationProps) => {
  const { text } = props;

  return (
    <div className='table-no-content'>
      <div className='not-items'>{text}</div>
    </div>
  );
};

export { TableEmptyListNotification };

import * as React from 'react';

import { Switch } from 'components/Fields';

type Props = FieldBaseProps;

export const SwitchWithLabel = (props: Props) => {
  const { name, value, label, onChange } = props;

  return (
    <Switch
      name={name}
      value={value}
      label={label}
      errors={[]}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(
          {
            name,
            value: e.currentTarget.checked,
          },
          e,
        )
      }
    />
  );
};

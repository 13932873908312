import { openConfirmModal, setConfirmContent, setConfirmTitle } from 'modules/StartList/actions/confirm';
import { ATTACH_TO_PROFILE_FORM } from 'modules/StartList/constants';
import { mapToConfirmBody } from 'modules/StartList/mapper';
import { modalStore } from 'modules/StartList/stores';
import { AttachToProfileForm } from 'modules/StartList/types';
import { attachToProfileValidation } from 'modules/StartList/validations';

import { t, validate } from 'utils';

import { errorsStore, form } from 'stores';

export const openForm = async (racer: RacerType) => {
  await form.merge(ATTACH_TO_PROFILE_FORM, {
    sourceEmail: { value: racer.email, key: racer.id },
    isTeamMember: racer.team_id,
  });
  modalStore.set({ open: true });
};

export const closeForm = (): void => {
  modalStore.set({
    ...(modalStore.value as any),
    open: false,
  });
};

export const onSave = (): void => {
  errorsStore.clear(ATTACH_TO_PROFILE_FORM);
  const attachToProfileForm: AttachToProfileForm = form.fetchWithoutObservable(ATTACH_TO_PROFILE_FORM);

  const isValid: boolean = validate(ATTACH_TO_PROFILE_FORM, attachToProfileForm, attachToProfileValidation);

  if (!isValid) return;

  setConfirmModalData(attachToProfileForm);
  openConfirmModal();
};

const setConfirmModalData = (form: AttachToProfileForm): void => {
  const title: string = t.staticAsString('startlist.confirm.attachToProfile.title');
  const content: string = t.staticAsString('startlist.confirm.attachToProfile.body', mapToConfirmBody(form));

  setConfirmTitle(title);
  setConfirmContent(content);
};

import { t } from 'utils';

const base = {
  email: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    email: true,
  },
};

export { base };

import { errorsStore, form, progressStore } from 'stores';

import { DEFAULT_FORM_VALUE, INTEGRATION_FORM, INTEGRATION_FORM_INIT } from '../constants';

import { distancesService, integrationService } from '../services';

import { distancesStore, integrationStore, modalStore } from '../stores';

export const mount = async (integrationId: number | null) => {
  progressStore.log(INTEGRATION_FORM_INIT, 'progress');
  const raceId = Number(modalStore.value?.raceId);

  await Promise.all([
    distancesService.loadDistances(raceId),
    integrationService.getIntegration(raceId, integrationId),
    form.merge(INTEGRATION_FORM, DEFAULT_FORM_VALUE),
  ]);

  progressStore.log(INTEGRATION_FORM_INIT, 'completed');
};

export const unmount = () => {
  distancesStore.clear();
  modalStore.clear();
  integrationStore.clear();
  errorsStore.clear(INTEGRATION_FORM);
};

import { makeAutoObservable } from 'mobx';

class FiltersStore {
  filters: AnyObject = {};
  constructor() {
    makeAutoObservable(this);
  }

  setFilters(filters: AnyObject) {
    this.filters = filters;
  }

  getFilters() {
    return this.filters;
  }
}

const filtersStore = new FiltersStore();
export { filtersStore };

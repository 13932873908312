import { InfoOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { BE_DATE_FORMAT } from 'src/constants/time';

import { Show } from 'components/Condition';

import { t } from 'utils/t';

import { formatCurrency } from '../utils';

import { ReportStore } from '../stores/report.store';

import { PlatformFeeVat, TransactionsVatSummary } from '../types/report.type';
import TableWithSummary from './common/TableWithSummary';
import { cardContentStyles, genericReportStyles } from './general.styles';

type Props = {};

export default function VatReport({}: Props) {
  const styles = genericReportStyles();
  const cardStyles = cardContentStyles();
  const r = ReportStore.report.value!;
  const title = (prefix: string) => t.staticAsString(`reconciliation.report.vatReport.${prefix}` as any);

  return (
    <>
      <Box mb={2}>
        <Typography className={styles.reportGenericTitle}>{title('vatReportForTransactions')}</Typography>
        <Show if={!isEmpty(r.vat_report.vat_statement_summary)}>
          <Box>
            <span className={cardStyles.cardEntryLeft}>{title('statementId')}&nbsp;</span>
            <span className={cardStyles.cardEntryRight}>{r.vat_report.vat_statement_summary.statementID}</span>
          </Box>
          <Box>
            <span className={cardStyles.cardEntryLeft}>{title('clientVatNumber')}&nbsp;</span>
            <span className={cardStyles.cardEntryRight}>{r.vat_report.vat_statement_summary.clientVatNo}</span>
          </Box>
          <Box>
            <span className={cardStyles.cardEntryLeft}>{title('event')}&nbsp;</span>
            <span className={cardStyles.cardEntryRight}>{r.vat_report.vat_statement_summary.eventName}</span>
          </Box>
        </Show>

        <Box>
          <span className={cardStyles.cardEntryLeft}>{title('period')}&nbsp;</span>
          <span className={cardStyles.cardEntryRight}>
            {moment(r.vat_report.vat_statement_summary.from).format(BE_DATE_FORMAT)} {title('to')}{' '}
            {moment(r.vat_report.vat_statement_summary.to).format(BE_DATE_FORMAT)}
          </span>
        </Box>
      </Box>
      <Box>
        <Box style={{ marginTop: 32, marginBottom: 32 }} display='flex' alignItems='center' flexDirection='row'>
          <Typography className={styles.reportGenericTitle}>{title('vatSummaryForTransactions')}</Typography>
          <Box ml={4} display='flex' alignItems='center'>
            <InfoOutlined color='disabled' fontSize='small' />
            <Typography style={{ fontSize: 12, marginLeft: 8 }}>{title('note1')}</Typography>
          </Box>
        </Box>
        <TableWithSummary
          tableName='VatReport'
          summaryText={title('total')}
          columns={[
            { title: `${title('vat')}%`, accessor: 'vatValue', fn: (item) => `${item.vatValue}%`, align: 'left' },
            {
              title: title('totalIncVat'),
              accessor: 'grossAmount',
              align: 'center',

              fn: (item: TransactionsVatSummary) => formatCurrency(item.grossAmount),
            },
            {
              title: title('vatAmount'),
              accessor: 'vatAmount',
              align: 'center',
              fn: (item: TransactionsVatSummary) => formatCurrency(item.vatAmount),
            },
            {
              title: title('totalExVat'),
              accessor: 'netAmount',
              align: 'right',
              fn: (item: TransactionsVatSummary) => formatCurrency(item.netAmount),
            },
          ]}
          emptyMessage={title('noTransactionsFound')}
          data={r.vat_report.transactions_vat_summary}
        />
      </Box>
      <Box>
        <Box style={{ marginTop: 32, marginBottom: 32 }} display='flex' alignItems='center' flexDirection='row'>
          <Typography className={styles.reportGenericTitle}>{title('vatSummaryForRefunds')}</Typography>
          <Box ml={4} display='flex' alignItems='center'>
            <InfoOutlined color='disabled' fontSize='small' />
            <Typography style={{ fontSize: 12, marginLeft: 8 }}>{title('note2')}</Typography>
          </Box>
        </Box>
        <TableWithSummary
          tableName='VatReport'
          summaryText='Total'
          columns={[
            { title: `${title('vat')}%`, accessor: 'vatPercentage', align: 'left', fn: (item) => `${item.vatValue}%` },
            { title: title('totalIncVat'), accessor: 'grossAmount', align: 'center', fn: (item) => formatCurrency(item.grossAmount) },
            { title: title('vatAmount'), accessor: 'vatValue', align: 'center', fn: (item) => formatCurrency(item.vatAmount) },
            { title: title('totalExVat'), accessor: 'netAmount', align: 'right', fn: (item) => formatCurrency(item.netAmount) },
          ]}
          data={r.vat_report.refunds_vat_summary}
          emptyMessage={title('noRefundsFound')}
        />
      </Box>
      <Box>
        <Box style={{ marginTop: 32, marginBottom: 32 }} display='flex' alignItems='center' flexDirection='row'>
          <Typography className={styles.reportGenericTitle}>{title('vatSummaryForPlatformInvoice')}</Typography>
          <Box ml={4} display='flex' alignItems='center'>
            <InfoOutlined color='disabled' fontSize='small' />
            <Typography style={{ fontSize: 12, marginLeft: 8 }}>{title('note3')}</Typography>
          </Box>
        </Box>
        <TableWithSummary
          tableName='VatReportPlatformInvoice'
          summaryText={title('total')}
          emptyMessage={title('noPlatformInvoiceFound')}
          columns={[
            { title: `${title('vat')}%`, accessor: 'vatValue', fn: (item: PlatformFeeVat) => `${item.vat_value}%`, align: 'left' },
            {
              title: title('totalIncVat'),
              accessor: 'grossAmount',
              align: 'center',
              fn: (item: PlatformFeeVat) => formatCurrency(item.total),
            },
            {
              title: title('vatAmount'),
              accessor: 'vatAmount',
              align: 'center',
              fn: (item: PlatformFeeVat) => formatCurrency(item.vat_amount),
            },
            {
              title: title('totalExVat'),
              accessor: 'netAmount',
              align: 'right',
              fn: (item: PlatformFeeVat) => formatCurrency(item.total_excluding_vat),
            },
          ]}
          data={r.invoice.platform_fees_vat.sort((a, b) => a.vat_value - b.vat_value)}
        />
      </Box>
    </>
  );
}

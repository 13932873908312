import { countriesStore } from 'stores';

function formatCountry(id: number | null) {
  const model = countriesStore.findModelValue(Number(id) || 0);

  if (!model) {
    return '';
  }

  return model.name();
}

export { formatCountry };

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import MaterialSwitch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles, withStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { Icon } from 'components/Icon';

import { stringFormatter } from 'utils';

type Props = {
  classes?: {
    [K in string]: string;
  };
  name: string;
  onChange: (e: React.ChangeEvent) => void;
  value: boolean | string;
  errors: Array<string>;
  type?: string;
  id?: string;
  label?: string;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  searchOptions?: AnyObject;
};

const StyledSwitch = withStyles({
  track: {
    backgroundColor: '#A8AEB8',
  },
})(MaterialSwitch);

const Switch = ({
  classes = {
    root: 'field-root',
    label: 'label',
    // TODO: this props causing errors to appear in console. Check if switches are not broken
    // switchBase: 'switch-base',
    // icon: 'icon',
    // iconChecked: 'checked-icon',
    // checked: 'checked',
    // bar: 'bar',
  },

  name,
  onChange,
  value,
  errors = [],
  type = 'text',
  id = '',
  label = '',
  className = 'form-control',
  fullWidth = false,
  disabled = false,
  tooltipText = '',
}: Props) => {
  return (
    <div className={classNames('switch-field')}>
      <FormControlLabel
        disabled={disabled}
        classes={classes}
        control={<StyledSwitch name={name} checked={!!value} onChange={onChange} color='primary' />}
        label={
          <Box display='flex'>
            <span className='switch-label'>{label || errors.join('')}</span>
            {tooltipText && (
              <Tooltip title={stringFormatter.withNewLine(tooltipText)} placement='right' classes={{ tooltip: 'switch-popover' }}>
                <span className='tooltip-icon'>
                  <Icon value='info' />
                </span>
              </Tooltip>
            )}
          </Box>
        }
      />
    </div>
  );
};

export { Switch };

import { modalStore } from '../stores';

import { mount, unmount } from './lifecycle';

export const openIntegrationForm = (
  raceId: number,
  integrationId: number | null = null,
  successCallback: Function = () => {},
  failCallback: Function = () => {},
) => {
  modalStore.set({
    open: true,
    isEdit: Boolean(integrationId),
    raceId,
    successCallback,
    failCallback,
  });

  mount(integrationId);
};

export const closeIntegrationForm = () => {
  modalStore.set({
    ...(modalStore.value as any),
    open: false,
  });
  setTimeout(() => {
    unmount();
  }, 500);
};

import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { Checkbox } from 'components/Fields';

import { t } from 'utils';

type Props = {
  onChange: (field: string, data: RegistrationField) => void;
  value: RegistrationField;
  hiddenFields: {
    [K in string]: Array<string>;
  };
  disabled: boolean;
  tooltip: boolean;
};

const FIELD_REQUIRED = 'required';
const FIELD_SELECTED = 'selected';

const StyledInfoOutlined: AnyFunction | any = withStyles((theme) => ({
  root: {
    cursor: 'pointer',
    color: theme.palette.grey6,
    marginLeft: 7,
  },
}))(InfoOutlined);

class Item extends React.Component<Props> {
  onChangeCheckbox = (e: React.ChangeEventHandler | any) => {
    const { name, checked } = e.target;

    this.onChange(name, +checked);
  };

  onChange = (name: string, changedValue: unknown) => {
    const { value, onChange } = this.props;
    let changeData = { [name]: changedValue };

    if (name === FIELD_REQUIRED && !!changedValue) {
      changeData = {
        [FIELD_REQUIRED]: 1,
        [FIELD_SELECTED]: 1,
      };
    }

    if (name === FIELD_SELECTED && !changedValue && value[FIELD_REQUIRED]) {
      changeData = {
        [FIELD_REQUIRED]: 0,
        [FIELD_SELECTED]: 0,
      };
    }

    const newSelectedField = {
      ...value,
      ...changeData,
    };

    onChange(newSelectedField.name, newSelectedField);
  };

  renderField: React.ReactElement | any = ({ name, children }: { name: string; children: React.ReactNode }): React.ReactNode => {
    const { value, hiddenFields } = this.props;
    const hideSettings = hiddenFields[value.name];

    if (hideSettings && hideSettings.includes(name)) {
      return <div className='hidden' />;
    }

    return children;
  };

  renderTooltip = (name: string) => {
    return (
      <div className='label-icon'>
        <Tooltip title={t.staticAsString(`distances.steps.registrationFieldsForm.description.${name}` as TranslationLockedKeys)}>
          <StyledInfoOutlined />
        </Tooltip>
      </div>
    );
  };

  render() {
    const { value, disabled } = this.props;
    const RenderField: React.ComponentType<any> = this.renderField;

    if (value._delete) {
      return null;
    }

    return (
      <div className={classNames('row', { disabled })}>
        <Typography className={this.props.tooltip ? 'cell label-tooltip' : 'cell label'}>
          {t.staticAsString(`distances.steps.registrationFieldsForm.${value.name}` as TranslationLockedKeys)}
        </Typography>
        {this.props.tooltip && this.renderTooltip(value.name)}

        <div className='cell selected'>
          <RenderField name={FIELD_SELECTED}>
            <Checkbox name={FIELD_SELECTED} disabled={disabled} value={value.selected} onChange={this.onChangeCheckbox} />
          </RenderField>
        </div>
        <div className='cell required'>
          <RenderField name={FIELD_REQUIRED}>
            <Checkbox name={FIELD_REQUIRED} disabled={disabled} value={value.required} onChange={this.onChangeCheckbox} className='muted' />
          </RenderField>
        </div>
      </div>
    );
  }
}

export { Item };

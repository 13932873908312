import { t } from 'utils';

import { errorsStore } from 'stores';

import { updateSplit } from '../../../actions';

import { generateStoreOptions } from '../../../stores';

import { processForUpdate, prepareForEdit } from '../../../tools';
import { GenerateFieldUpdate } from '../../../types';

function handleMissingSplit(checkpointId: number, resultId: number) {
  console.error(`WARNING, unhandled missing split for checkpoint: ${checkpointId}, result: ${resultId}`);
  return false;
}

const updatePrevSplitDiff = function (model, context, checkpointId: number, prevCheckpointId: nil | number) {
  return async (value: number) => {
    const splitModel = model.findSplitByCheckpoint(checkpointId);
    const prevCheckpoint = model.findSplitByCheckpoint(prevCheckpointId || NaN);

    if (!splitModel) {
      return handleMissingSplit(checkpointId, model.value.id);
    }

    const property = 'finish_time';
    const fieldId = splitModel.generateFieldId('prev_split_diff');
    const storeOptions = generateStoreOptions(property, splitModel);
    const updateOptions = { fieldId, property, storeOptions };
    const startTime = prevCheckpoint?.value.finish_time || model.value.start_time;
    const prevValue = prepareForEdit.duration(startTime, splitModel.value.finish_time);

    errorsStore.clear(fieldId);

    const [shouldBeUpdated, updateValue] = processForUpdate.duration(fieldId, value, prevValue, startTime);

    if (value === 0) {
      errorsStore.add(fieldId, { fieldId: [t.staticAsString('results.errors.cannotBeZero')] });
      return false;
    }

    if (!shouldBeUpdated) {
      return false;
    }

    return await updateSplit(checkpointId, updateOptions, updateValue, model, context);
  };
} as GenerateFieldUpdate;

const updateResultDiff = function (model, context, checkpointId: number) {
  return async (value: number) => {
    const splitModel = model.findSplitByCheckpoint(checkpointId);

    if (!splitModel) {
      return handleMissingSplit(checkpointId, model.value.id);
    }

    const property = 'finish_time';
    const storeOptions = generateStoreOptions(property, splitModel);
    const fieldId = splitModel.generateFieldId('result_diff');
    const updateOptions = { fieldId, property, storeOptions };
    const prevValue = prepareForEdit.duration(model.value.start_time, splitModel.value.finish_time);

    errorsStore.clear(fieldId);

    const [shouldBeUpdated, updateValue] = processForUpdate.duration(fieldId, value, prevValue, model.value.start_time);

    if (value === 0) {
      errorsStore.add(fieldId, { fieldId: [t.staticAsString('results.errors.cannotBeZero')] });
      return false;
    }

    if (!shouldBeUpdated) {
      return false;
    }

    return await updateSplit(checkpointId, updateOptions, updateValue, model, context);
  };
} as GenerateFieldUpdate;

export { updatePrevSplitDiff, updateResultDiff };

import { Dialog, DialogContent } from '@mui/material';
import { Observer } from 'mobx-react';
import React from 'react';

import { organizersStore, sessionStore } from 'stores';

import { Form } from '../components/shared/Form';

import { editModalStore } from '../stores';

export const EditProfileDialog = () => {
  const { selected } = organizersStore;

  if (!selected) {
    return null;
  }

  const onClose = (data) => {
    editModalStore.off();

    if (data && data.full_name) {
      sessionStore.setFullName(data.full_name);
    }
  };

  return (
    <Observer>
      {() => {
        return (
          <Dialog open={editModalStore.value} onClose={onClose}>
            <DialogContent>
              <Form entityId={selected.id} onClose={onClose} clearSelectedOnUnmount={false} />
            </DialogContent>
          </Dialog>
        );
      }}
    </Observer>
  );
};

import React from 'react';

import { InputFilter } from 'components/Filter';

import { t } from 'utils';

import { FilterProps } from './index';

type Props = {
  value: nil | string;
} & FilterProps;

const RaceLocation = (props: Props) => {
  return (
    <InputFilter label={t.staticAsString('races.filters.organizer_name.race_location' as TranslationLockedKeys)} {...(props as any)} />
  );
};

export { RaceLocation };

import { Box, Card, Grid, Typography } from '@mui/material';
import DashboardBreadcrumbs from 'modules/Dashboard/common/components/Breadcrumbs';
import SelectRangeButtons from 'modules/Dashboard/common/components/Interval';
import RaceSearch from 'modules/Dashboard/common/components/RaceSearch';
import { dashboardTextStyles } from 'modules/Dashboard/common/text.styles';
import { DashboardStore } from 'modules/Dashboard/stores/dashboard.store';
import { chartRangePickerStore } from 'modules/Dashboard/stores/interval.store';
import { dashboardRacesStore } from 'modules/Dashboard/stores/races.store';
import { Turnovers } from 'modules/Dashboard/types/turnover.type';
import { formatCurrency } from 'modules/Reconciliation/shared/utils/formatCurrency';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Icon } from 'components/Icon';

import { history } from 'utils/history';
import { t } from 'utils/t';

type Props = {
  data: Turnovers | null;
};

export default function TurnoverChart({ data }: Props) {
  const textStyles = dashboardTextStyles;
  const renderTrend = () => {
    const rate = data?.trend.rate;
    if (!rate) return '100';
    return rate.toFixed(0);
  };
  const renderTrendIcon = () => {
    const rate = data?.trend.rate;
    if (!rate || rate >= 0) {
      return 'circleUp';
    }
    return 'circleDown';
  };
  return (
    <>
      <Box>
        <DashboardBreadcrumbs currentPage={t.staticAsString('dashboard.turnover')} />
        <Grid p={4} pt={3} container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ minHeight: '100%', p: 4 }}>
              <Box display='flex' justifyContent='space-between'>
                <Typography sx={textStyles.cardTitle}>{t('dashboard.turnover')}</Typography>
                <Icon onClick={() => history.push('/dashboard')} style={{ cursor: 'pointer' }} value='collapse' />
              </Box>
              <Box mt={2} display='flex' flexDirection='row' alignItems='center'>
                <Typography sx={textStyles.metricTitle}>
                  {formatCurrency(
                    DashboardStore.turnovers.value?.total ?? 0,
                    dashboardRacesStore.selectedRace?.currency?.iso_code ?? 'EUR',
                  )}
                </Typography>
                <Box marginTop='5px' display='flex' alignItems='center' sx={textStyles.explanationTitle}>
                  <Icon style={{ paddingLeft: 30, marginTop: 5 }} value={renderTrendIcon()} />{' '}
                  <b style={{ color: 'black', fontWeight: 700, paddingRight: 4, paddingLeft: 9 }}>{renderTrend() ?? '100'}%</b> vs last{' '}
                  {chartRangePickerStore.humanizedRange}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <SelectRangeButtons collection='turnover' />
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                  <Typography sx={{ fontSize: 14, mr: 1 }}>Event:</Typography>
                  <RaceSearch />
                </Box>
              </Box>
              <ResponsiveContainer width={'100%'} height={500}>
                <AreaChart style={{ left: '-10px', fontSize: 14, marginTop: 48 }} data={data?.data}>
                  <defs>
                    <linearGradient id={'turnover'} x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='5%' stopColor={'#7CCBA5'} stopOpacity={0.6} />
                      <stop offset='95%' stopColor={'#7CCBA5'} stopOpacity={0.15} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid strokeDasharray='3' />
                  <XAxis dataKey='label' />
                  <YAxis unit={` ${dashboardRacesStore.selectedRace?.currency?.iso_code}`} />
                  <Area fillOpacity={1} fill={`url(#turnover)`} type='monotone' dataKey='value' strokeWidth={5} stroke='#7CCBA5' />
                  <Tooltip />
                </AreaChart>
              </ResponsiveContainer>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TableBody from '@mui/material/TableBody';
import { observer, inject } from 'mobx-react';
import * as React from 'react';
import shortid from 'shortid';

import { HELPER_DISTANCE, TEAM, ROUTES } from 'src/constants';

import { withProgressSpinner, withStoresClean, withSearch, withSorting } from 'hocs';

import { FilterLabelToolbar } from 'components/Filter/FilterLabelToolbar';
import { Table } from 'components/Table';

import { history, t } from 'utils';

import { helperDistancesService } from 'services';

import { Locale as LocalStore, HelperDistances as HelperDistancesStore, Session as SessionStore, confirmationModalStore } from 'stores';

import { exportTeams } from '../../../actions';

import teamService from '../../../services/teamsService';

import { teamsStore } from '../../../stores';

import { ImportErrorsModal } from '../../ImportErrorsModal';
import { Nav } from '../../Nav/team';
import { Breadcrumbs } from './Breadcrumbs';
import { Item } from './Item';
import { TeamForm } from './New';
import { Pagination } from './Pagination';
import { THead } from './THead';
import { Toolbar } from './Toolbar';

type Props = {
  localeStore: LocalStore;
  sessionStore: SessionStore;
  helperDistancesStore: HelperDistancesStore;
  match: AnyObject;
};

type State = {
  isModalOpen: boolean;
  editCouponId: number | null;
};

const QUERY_TYPE_FOR_DISTANCE = 'team';

@withSorting(teamService)
@withSearch(teamService, 'teams.list.table')
@withProgressSpinner(`LOAD_${TEAM}S`, { hideClassName: 'hidden' })
@withProgressSpinner(`LOAD_${HELPER_DISTANCE}`, { hideClassName: 'hidden' })
@withStoresClean(teamsStore)
@inject('localeStore', 'helperDistancesStore', 'sessionStore')
@observer
class List extends React.Component<Props, State> {
  static defaultProps = {
    localeStore: null as any,
    sessionStore: null as any,
  };

  state = {
    isModalOpen: false,
    editCouponId: null,
  };

  componentDidMount() {
    this._loadData();
  }

  componentDidUpdate(prevProps: Props) {
    const { id } = this.props.match.params;
    const prevId = prevProps.match.params.id;
    if (id !== prevId) {
      teamsStore.clearAll();
      this._loadData();
    }
  }

  items = (): Array<React.ReactNode> => {
    const { helperDistancesStore } = this.props;
    const { selected } = helperDistancesStore;

    if (!selected) {
      return [];
    }

    return teamsStore.values.map<React.ReactNode>((teamItem) => (
      <Item key={shortid()} item={teamItem} distance={selected} deleteTeam={this.onDeleteTeamWrapper} />
    ));
  };

  onChangeDistance = (e: React.ChangeEvent<HTMLInputElement>) => {
    const distanceId: any = e.target.value;
    const raceId = this.props.match.params.raceId;
    const { modelSelected } = this.props.helperDistancesStore;
    const selectedDistance = modelSelected?.race.distances.find((distance) => distance.value.id === distanceId);
    const type = selectedDistance?.value.type === 'team' ? 'teams' : 'racers';

    history.push(`${ROUTES.racesRoute}/${raceId}/distances/${distanceId}/${type}`);
  };

  getRequired = (required_fields: Array<Object>): AnyObject => {
    let requiredHash: AnyObject = {};

    required_fields.forEach((item: AnyObject) => {
      requiredHash[item.name] = item.selected;
    });

    return requiredHash;
  };

  createTeam = (): void => {
    this.setState({
      ...this.state,
      isModalOpen: true,
    });
  };

  onClose = () => {
    this.setState({
      ...this.state,
      isModalOpen: false,
      editCouponId: null,
    });
  };

  onDeleteTeam = (team_id: number) => {
    const { selected: distance } = this.props.helperDistancesStore;
    if (!distance) {
      return;
    }

    teamService.deleteValue(distance.id, team_id);
  };

  onDeleteTeamWrapper = (team_id: number) => {
    confirmationModalStore.openModal({
      title: t.staticAsString('teams.confirmPopup.mainTitle'),
      body: t.staticAsString('teams.confirmPopup.mainBody'),
      type: 'confirm',
      onConfirm: () => {
        this.onDeleteTeam(team_id);
      },
    });
  };

  getContent = () => {
    const { helperDistancesStore, sessionStore } = this.props;
    const { selected } = helperDistancesStore;
    const organizerVerified = sessionStore.isOrganizerVerified;

    if (!selected) {
      return null;
    }
    let Content;

    if (teamsStore && teamsStore.values.length > 0) {
      Content = (
        <React.Fragment>
          <div className='component-list racers-list'>
            <Table>
              <THead distance={selected} />
              <TableBody>{this.items()}</TableBody>
            </Table>
          </div>
          <Pagination teamsStore={teamsStore} distance_id={selected.id} switchedOnTop={organizerVerified} />
        </React.Fragment>
      );
    } else {
      Content = (
        <div className='racer-no-content'>
          <div className='not-racers'>{t.staticAsString('teams.noRegisteredTeams')}</div>
        </div>
      );
    }

    return Content;
  };

  onEditDistance = () => {
    const { selected } = this.props.helperDistancesStore;

    if (!selected) {
      return;
    }

    history.push(`${ROUTES.racesRoute}/${selected.race?.id}/distances/${selected.id}`);
  };

  onDeleteDistanceWrapper = () => {
    const { helperDistancesStore } = this.props;
    const { selected } = helperDistancesStore;

    if (!selected) {
      return;
    }

    confirmationModalStore.openModal({
      title: t.staticAsString('distances.confirmPopup.mainTitle'),
      body: t.staticAsString('distances.confirmPopup.mainBody', { name: selected.name }),
      type: 'confirm',
      confirmationValue: selected.name,
      confirmationLabel: t.staticAsString('confirmationModal.distance.label'),
      onConfirm: () => {
        this.onDeleteDistance();
      },
    });
  };

  onDeleteDistance = () => {
    const { selected } = this.props.helperDistancesStore;

    if (!selected || !selected.race) {
      return;
    }

    helperDistancesService.deleteDistance(selected.race.id, selected.id);
  };

  _loadData = () => {
    const distanceId = this.props.match.params.id;

    helperDistancesService.loadRaceDistance(distanceId, QUERY_TYPE_FOR_DISTANCE);
    teamService.loadResources(distanceId);
  };

  render() {
    const { helperDistancesStore } = this.props;
    const { selected, modelSelected } = helperDistancesStore;
    const { isModalOpen } = this.state;

    if (!selected || !selected.race || !selected.race.distances || !modelSelected) {
      return null;
    }

    return (
      <div className='content main-container-list start-list'>
        <div className='sticky-left'>
          <Dialog open={isModalOpen} onClose={this.onClose}>
            <DialogContent>
              {/*@ts-ignore*/}
              <TeamForm distance={selected} onClose={this.onClose} />
            </DialogContent>
          </Dialog>
          <ImportErrorsModal />
          <Breadcrumbs distance={modelSelected} />
          <div className='toolbar-container'>
            <Toolbar
              distance={modelSelected}
              onAddNewTeam={this.createTeam}
              onChange={this.onChangeDistance}
              exportFile={exportTeams}
              onEditDistance={this.onEditDistance}
              onDeleteDistance={this.onDeleteDistanceWrapper}
            />
          </div>
          <Nav distance={selected} />
          <FilterLabelToolbar />
        </div>
        {this.getContent()}
      </div>
    );
  }
}

export { List };

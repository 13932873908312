import { pick } from 'lodash';

const keys = ['goal_expiration_date', 'goal_type', 'goal_max_length'];

function mapGoalFromBe(value: nil | GoalType) {
  if (!value) {
    return value;
  }

  const parsedGoal = pick(value, keys);

  if (value.goal_max_length) {
    parsedGoal.goal_max_length = value.goal_max_length;
  }

  return parsedGoal;
}

function mapGoalForBe(value: nil | GoalType) {
  if (!value) {
    return value;
  }

  return pick(value, keys);
}

export { mapGoalFromBe, mapGoalForBe };

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import classNames from 'classnames';
import * as React from 'react';

import { Tooltip } from '../core/Tooltip';
import { generateClasses } from '../core/classes';

type Props = FieldBaseProps &
  AdditionalMaterialProps &
  FieldWithTooltip & {
    onBlur?: React.FocusEventHandler;
    onKeyUp?: React.KeyboardEventHandler;
    options: Array<SelectOption>;
    value: SelectOption;
  };

/*
 * @Deprecated
 * in favor of new props policy and '../Select/index.js implementation
 * based on TextField
 */
const SelectField = (props: Props) => {
  const { id, value, name, onChange, error, className, label, onBlur, additional, tooltip, options } = props;

  const inputClasses = generateClasses(value);
  const { fullWidth, autoFocus } = additional;

  return (
    <FormControl
      fullWidth={fullWidth}
      className={classNames('input-wrap', 'form-control', { [className || '']: !!className }, { error: !!error })}
      error={!!error}
      aria-describedby='name-error-text'
    >
      <InputLabel htmlFor={id} classes={{ root: 'form-label' }}>
        {label}
      </InputLabel>
      <Select
        fullWidth={fullWidth}
        value={value && value.key}
        error={!!error}
        autoFocus={autoFocus}
        onChange={(e: SelectChangeEvent<{ name?: string; value: any }>) => {
          const optionKey = e.target.value;
          const option = options.find((el) => el.key === optionKey);

          onChange({ name, value: option }, e as any);
        }}
        classes={inputClasses}
        inputProps={{ name, id }}
        onBlur={onBlur}
      >
        {options.map((item) => (
          <MenuItem value={item.key} key={item.key} className='menu-item'>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {tooltip && <Tooltip {...tooltip} error={error} />}
      <FormHelperText className='errors' id={`${id}_error`}>
        {error as React.ReactNode}
      </FormHelperText>
    </FormControl>
  );
};

SelectField.defaultProps = {
  defaultClassName: 'form-control input-wrap',
  additional: { fullWidth: false, type: 'text' },
};

export { SelectField };

import { t } from 'utils';

import { toastStore } from 'stores';

const showToast = (id: string, params?: AnyObject) => {
  toastStore.show(t(id as TranslationLockedKeys, params));
};

export const publishToast = (status: boolean, value: number | boolean): void => {
  if (status) {
    return showToast(value ? 'races.detail.publishing.successMessage' : 'races.detail.unpublishing.successMessage');
  }

  showToast(value ? 'races.detail.publishing.errorMessage' : 'races.detail.unpublishing.errorMessage');
};

export const registrationToast = (status: boolean, value: number | boolean) => {
  if (status) {
    return showToast(value ? 'races.detail.register.successMessage' : 'races.detail.unregister.successMessage');
  }

  showToast(value ? 'races.detail.register.errorMessage' : 'races.detail.unregister.errorMessage');
};

export const resultsToast = (status: boolean, value: number | boolean) => {
  if (status) {
    return showToast(value ? 'races.detail.showingResults.successMessage' : 'races.detail.hidingResults.successMessage');
  }

  showToast(value ? 'races.detail.showingResults.successMessage' : 'races.detail.hidingResults.successMessage');
};

export const startlistToast = (status: boolean, value: number | boolean) => {
  if (status) {
    return showToast(value ? 'races.detail.showingStartlist.successMessage' : 'races.detail.hidingStartlist.successMessage');
  }

  showToast(value ? 'races.detail.showingStartlist.successMessage' : 'races.detail.hidingStartlist.successMessage');
};

import { DISTANCE_FORM_ERRORS } from '../constants';

import { constructObjectForValidation, nestedValidation } from '../utils';

import { State } from '../shared/stateHelpers';
import { baseStep, classes, customFields, disciplines, prices, selfServices, waves } from '../validations';

const notDelete = (obj: any) => !obj._delete;

const validateForm = (formData: State, isNewDistance: boolean = false) => {
  const transformedData = constructObjectForValidation(formData);
  const isSelfServiceChecked = formData.editorSettings.tab_options.includes('selfServices');

  if (isNewDistance) {
    return nestedValidation({
      action: DISTANCE_FORM_ERRORS,
      schema: baseStep,
      data: {
        ...transformedData.distance,
        goal: { ...formData.goal },
      },
    });
  }

  const updateDistanceValidations = [
    nestedValidation({
      action: DISTANCE_FORM_ERRORS,
      schema: baseStep,
      data: {
        ...transformedData.distance,
        goal: { ...formData.goal },
      },
    }),

    nestedValidation({
      action: DISTANCE_FORM_ERRORS,
      schema: classes,
      data: transformedData.classes.filter(notDelete),
      prefix: 'classes',
    }),

    nestedValidation({
      action: DISTANCE_FORM_ERRORS,
      schema: disciplines,
      data: transformedData.disciplines.filter(notDelete),
      prefix: 'disciplines',
    }),

    nestedValidation({
      action: DISTANCE_FORM_ERRORS,
      schema: waves,
      data: transformedData.waves.filter(notDelete),
      prefix: 'waves',
    }),

    nestedValidation({
      action: DISTANCE_FORM_ERRORS,
      schema: customFields,
      data: transformedData.custom_fields.filter(notDelete),
      prefix: 'custom_fields',
    }),

    nestedValidation({
      action: DISTANCE_FORM_ERRORS,
      schema: selfServices,
      data: transformedData.prices.filter(notDelete),
      prefix: 'self_services',
    }),
  ];

  if (isSelfServiceChecked) {
    updateDistanceValidations.push(
      nestedValidation({
        action: DISTANCE_FORM_ERRORS,
        schema: selfServices,
        data: transformedData.self_services,
        prefix: 'self_services',
      }),
    );
  }

  if (formData.prices.length) {
    updateDistanceValidations.push(
      nestedValidation({
        action: DISTANCE_FORM_ERRORS,
        schema: prices,
        data: transformedData.prices.filter(notDelete),
        prefix: 'prices',
      }),
    );
  }

  return updateDistanceValidations.every(Boolean);
};

export { validateForm };

import { DummyStore } from 'stores';

export const modalStore = new DummyStore<{
  open: boolean;
}>({
  open: false,
});

export const sourceDistanceStore = new DummyStore<AnyObject[]>([]);
export const targetDistancesStore = new DummyStore<AnyObject[]>([]);

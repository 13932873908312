export * from './racersColumns';

export const LOAD_RACERS = 'LOAD_RACERS';
export const LOAD_RACES_NAMES = 'LOAD_RACES_NAMES';
export const LOAD_DISTANCES_NAMES = 'LOAD_DISTANCES_NAMES';
export const LOAD_RACER = 'LOAD_RACER';
export const EXPORT_RACERS = 'EXPORT_RACERS';
export const LOAD_RESULTS = 'RACERS_LOAD_RESULTS';
export const EMPTY_TIME_DIFF = '--:--:--';

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DNS = 'DNS';
export const DNF = 'DNF';

export const RACERS_STATUSES = {
  READY: 'ready',
  AWAITING: 'awaiting',
};

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { TEAMS_RESULT_URL } from 'src/constants';

import { request, action } from 'utils';

import { NEW_RESULT_FORM_ID } from '../constants';

class TeamsNewResult {
  @request({ action: NEW_RESULT_FORM_ID })
  request(id: number, body: AnyObject): Promise<any> {
    const url = generatePath(TEAMS_RESULT_URL, { id });
    const response = axios.post(url, body);

    return response;
  }

  @action({ action: NEW_RESULT_FORM_ID })
  async create(id: number, data: AnyObject): Promise<'conflict' | 'success' | 'error'> {
    const [status, response] = await this.request(id, data);

    if (response.status === 409) {
      return 'conflict';
    }

    return status ? 'success' : 'error';
  }
}

export { TeamsNewResult, TeamsNewResult as TeamsNewResultService };

import { Observer } from 'mobx-react';
import { RaceField } from 'modules/SmartLinks/Form/components/RaceField';
import { WaveField } from 'modules/SmartLinks/Form/components/WaveField';
import * as React from 'react';
import styled from 'styled-components';

import { InputWrapper, TextField } from 'src/components/Form';

import { Show } from 'components/Condition';

import { t } from 'utils/t';

import { form } from 'stores';

import { REGISTRATION_LINKS_FORM_ID } from '../constants';

import { waveMaxMembers } from '../utils';

import { mount, unmount } from '../actions';

import { DistanceField } from './DistanceField';
import { FromTo } from './FromTo';
import { Title } from './Styled';

const HelperText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ce5659;
`;

export const LinkFields = () => {
  React.useEffect(() => {
    mount();
    return unmount;
  }, []);

  return (
    <Observer>
      {() => {
        const maxSlotsInWave = waveMaxMembers();
        const oversubscribed = maxSlotsInWave && (form.fetch(REGISTRATION_LINKS_FORM_ID, 'max_usages') as number) > maxSlotsInWave;
        return (
          <>
            <InputWrapper
              name='name'
              settings={{
                label: t.staticAsString('smartLinks.nameForCode'),
                view: {
                  variant: 'outlined',
                  fullWidth: true,
                  required: true,
                },
              }}
              Component={TextField}
            />
            <Title>{t.staticAsString('smartLinks.linkDetails')}</Title>
            <RaceField />
            <DistanceField />
            <FromTo />
            <WaveField />

            <InputWrapper
              name='max_usages'
              settings={{
                label: t.staticAsString('smartLinks.maxNumberOfUsages'),
                view: {
                  variant: 'outlined',
                  fullWidth: true,
                  required: true,
                },
                additional: {
                  type: 'number',
                },
              }}
              Component={TextField}
            />
            <Show if={Boolean(oversubscribed)}>
              <HelperText>{t.staticAsString('smartLinks.oversubsribe')}</HelperText>
            </Show>
          </>
        );
      }}
    </Observer>
  );
};

import Dialog from '@mui/material/Dialog';
import withStyles from '@mui/styles/withStyles';
import { Observer } from 'mobx-react';
import * as React from 'react';
import { FC } from 'react';

import { closeConfirmModal } from '../../actions';

import { confirmStore } from '../../stores';

import { Title, Content, Bottom } from '.';

const DialogStyled = withStyles({
  paper: {
    width: 770,
  },
})(Dialog);

export const ConfirmModal: FC = () => {
  return (
    <Observer>
      {() => {
        const open: boolean = confirmStore.value?.open || false;
        const title: string = confirmStore.value?.title || '';
        const content: string = confirmStore.value?.content || '';

        return (
          <DialogStyled open={open} onClose={closeConfirmModal} maxWidth='md'>
            <Title title={title} />
            <Content content={content} />
            <Bottom />
          </DialogStyled>
        );
      }}
    </Observer>
  );
};

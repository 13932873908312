import { Button } from '@mui/material';
import React from 'react';

import { t } from 'utils';

type Props = {
  onClick: React.MouseEventHandler;
};

const ApproveBtn = ({ onClick }: Props) => (
  <Button onClick={onClick} color='success' variant='contained'>
    {t.staticAsString('races.detail.approve.title')}
  </Button>
);

export { ApproveBtn };

import { Collapse } from '@mui/material';
import { Observer } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';

import { InputWrapper, AutocompleteSelectInfinityLoad } from 'src/components/Form';
import { form } from 'src/stores';
import { t } from 'src/utils';

import { EQ_FORM_ID } from '../../constants';

import { handleAddItem, handleDeleteItem } from '../../actions';

import { SyncContainer, SyncItem } from '../../../Shared';
import { showClassPlus, getSyncClassesOptions, getRaceClassesOptions, showClass } from '../../selectors';
import { MapItemType } from '../../types';

type Props = {
  distanceIndex: number;
};
export const Classes: FC<Props> = ({ distanceIndex }) => {
  const prefix = `distances.${distanceIndex}`;
  return (
    <Observer>
      {() => {
        const data = form.fetch<MapItemType[]>(EQ_FORM_ID, `${prefix}.classes`) || [];
        const distance = form.fetch<MapItemType>(EQ_FORM_ID, prefix);

        if (!distance) return null;

        return (
          <SyncContainer
            title={t.staticAsString('translations.title.distanceClasses')}
            addText={t.staticAsString('sync.add_class')}
            onAdd={handleAddItem(`${prefix}.classes`)}
            showPlus={showClassPlus(`${prefix}.classes`, Number(distance.internal_id?.key))}
            showBlock={showClass.get()}
          >
            {data.map((item, index) => {
              return (
                <Collapse key={item.id}>
                  <SyncItem onDelete={handleDeleteItem(`${prefix}.classes`, item.id)}>
                    <InputWrapper
                      name={`${prefix}.classes.${index}.internal_id`}
                      settings={{
                        label: t.staticAsString('sync.class.raceid_title'),
                        additional: {
                          options: getRaceClassesOptions(Number(distance.internal_id?.key), `${prefix}.classes`)(),
                        },
                        view: {
                          reservePlaceForHelperText: true,
                        },
                      }}
                      Component={AutocompleteSelectInfinityLoad}
                    />
                    <InputWrapper
                      key={item.id}
                      name={`distances.${distanceIndex}.classes.${index}.external_id`}
                      settings={{
                        label: t.staticAsString('sync.class.external_title'),
                        additional: {
                          options: getSyncClassesOptions.get(),
                        },
                        view: {
                          reservePlaceForHelperText: true,
                        },
                      }}
                      Component={AutocompleteSelectInfinityLoad}
                    />
                  </SyncItem>
                </Collapse>
              );
            })}
          </SyncContainer>
        );
      }}
    </Observer>
  );
};

import { DetailsService } from './details';
import { PaymentsService } from './load';

const loadService = new PaymentsService();
const detailsService = new DetailsService();

export { loadService, detailsService };

export * from './load';
export * from './details';

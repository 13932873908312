import { gpxStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { Coordinate, GpxRoute } from 'src/modules/Distances/components/shared/Steps/GPX/types';

export const getCenter = (): Coordinate => {
  if (gpxStore.value) {
    const { coordinates } = gpxStore.value as GpxRoute;
    return coordinates[Math.round((coordinates.length - 1) / 2)];
  }

  return { lng: 0, lat: 0 };
};

import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import * as React from 'react';

type Props = FieldBaseProps & FieldWithTooltip;

function TimeMaterial(props: Props) {
  return (
    <TextField
      {...(props as any)}
      type='time'
      InputLabelProps={{
        shrink: true,
      }}
      className={classNames('input-mask', props.className)}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        props.onChange({ name, value }, e);
      }}
    />
  );
}

export { TimeMaterial, TimeMaterial as TimeMaterialField };

import CircularProgress from '@mui/material/CircularProgress';
import { PaymentStatus } from 'modules/Payments/constants';
import { getOrderStatuses } from 'modules/exportPayment/utils';

import { Icon } from 'components/Icon';

import { time, t } from 'utils';

import { OrderDetails } from '../../models';

type Props = {
  value: OrderDetails;
  isPdfDownloading: boolean;
  onExport(orderId: number): Promise<void>;
};

export function PaymentInfo({ value, isPdfDownloading, onExport }: Props) {
  const jsonValue = value.value;
  const orderId = jsonValue.id;

  return (
    <div className='account-info-wrapper payments-detail'>
      <div className='account-info-title-wrapper'>
        <h3 className='account-info-title'>{t.staticAsString('payments.detail.accountTitle')}</h3>
      </div>
      <div className='body-wrapper'>
        <div className='account-info-body'>
          <div className='info-item'>
            <p className='info-label'>{t.staticAsString('payments.detail.id')}</p>
            <p className='info-value'>№ {jsonValue.id}</p>
          </div>

          <div className='info-item'>
            <p className='info-label'>{t.staticAsString('payments.detail.created_at')}</p>
            <p className='info-value'>{time.datetime(jsonValue.created_at).parse().format('shortDateWithTime')}</p>
          </div>

          <div className='info-item'>
            <p className='info-label'>{t.staticAsString('payments.detail.state')}</p>
            <p className='info-value'>
              {getOrderStatuses().find((status) => status.key === (jsonValue.status || PaymentStatus.pending))?.value}
            </p>
          </div>
        </div>
        <div className='info-item-export'>
          {isPdfDownloading ? (
            <CircularProgress size='32px' />
          ) : (
            <Icon onClick={() => onExport(orderId)} value={'pdf'} className='export-icon' />
          )}
        </div>
      </div>
    </div>
  );
}

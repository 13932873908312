import { Dialog, DialogContent } from '@mui/material';
import { Observer } from 'mobx-react';
import { Form } from 'modules/SystemUsers/components';
import { editModalStore, systemUsersStore } from 'modules/SystemUsers/stores';
import type { Role } from 'modules/SystemUsers/types';

import { helperRacesStore } from 'stores';

import { createSystemUser } from '../../actions';

export const EditDialog = ({ predefinedRole }: { predefinedRole: Role }) => {
  return (
    <Observer>
      {() => {
        return (
          <Dialog open={editModalStore.value} onClose={() => editModalStore.off()}>
            <DialogContent>
              {/* @ts-ignore */}
              <Form
                predefinedRole={predefinedRole}
                predefinedCreator={helperRacesStore.selected?.organizer_id}
                loadResource={() => {}}
                createOrUpdateResource={createSystemUser}
                onClose={() => editModalStore.off()}
                systemUsersStore={systemUsersStore}
                entityId={null}
              />
            </DialogContent>
          </Dialog>
        );
      }}
    </Observer>
  );
};

import { cloneDeep } from 'lodash';

import { MappedIntegrationType, IntegrationType } from '../types';

export const mapIntegrations = (integrations: IntegrationType[]): MappedIntegrationType[] => {
  const copy = cloneDeep(integrations);

  return copy.map((item) => ({
    ...item,
    date_range: {
      started_at: item.started_at,
      finished_at: item.finished_at,
    },
    status: !!item.is_active,
  }));
};

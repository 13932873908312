import { BooleanStore, DisposersStore, DummyStore } from 'stores';

import { DISTANCE_STEPS, distanceStep } from '../../../constants';

export * from './medicalAssistants';
export * from './timingAssistants';

const CheckpointModalStore = new BooleanStore();

/**
 * @description
 * Store that will indicate is racers or teams already have been added to the current editing distance
 */
const isParticipantsPresentStore = new BooleanStore();
const CFWithRegisteredUsersStore = new DummyStore<AnyObject>({});
const currentStepStore = new DummyStore<{ value: distanceStep; index: number }>({
  value: DISTANCE_STEPS.nameAndDescription,
  index: 0,
});
const hasCompletedOrders = new BooleanStore();
const distanceFormDisposers = new DisposersStore();

export {
  CheckpointModalStore,
  isParticipantsPresentStore,
  hasCompletedOrders,
  CFWithRegisteredUsersStore,
  distanceFormDisposers,
  currentStepStore,
};

import { cloneDeep } from 'lodash';

export const mapOrders = (data: AnyObject[]): AnyObject[] => {
  const copy = cloneDeep(data);

  return copy.map((item) => {
    return {
      key: item.id,
      value: item.id,
      currency: item.currency,
      label: item.firstname || item.lastname ? `${item.id} - ${item.firstname} ${item.lastname}` : `${item.id}`,
      firstName: item.firstname,
      lastName: item.lastname,
      email: item.email,
      orderDate: item.created_at,
    };
  });
};

import { AreaChart, Area, ResponsiveContainer } from 'recharts';

type Props = {
  id: string;
  color: string;
  data: any[];
  width: number;
  height: number;
};

export default function CardAreaChart({ width, height, id, color, data }: Props) {
  return (
    <ResponsiveContainer width={'100%'}>
      <AreaChart width={width} height={height} data={data}>
        <defs>
          <linearGradient id={id} x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor={color} stopOpacity={0.6} />
            <stop offset='95%' stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area strokeWidth={3} type='monotone' dataKey='value' stroke={color} fillOpacity={1} fill={`url(#${id})`} />
      </AreaChart>
    </ResponsiveContainer>
  );
}

import { changeLanguage } from './changeLanguage';
import { handleVersionChange } from './handleVersions';
import { init } from './init';

const translations = {
  init,
  changeLanguage,
  handleVersionChange,
};

export { translations };

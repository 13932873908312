import { DummyStore } from 'src/stores';

const defaultParams = {
  limit: 20,
  page: 1,
};

type Params = {
  limit: number;
  page: number;
  search?: string;
  searchFields?: string;
};

export const racesHasMoreStore = new DummyStore(true);
export const racesStore = new DummyStore<SelectOption[]>([]);
export const racesParamsStore = new DummyStore<Params>(defaultParams);

import * as React from 'react';

import { TextEditor } from 'components/Form';

import { t } from 'utils';

import { Tooltip } from './Tooltip';

type Props = {
  name: string;
  value: string;
  onChange:
    | ((
        arg0: {
          [K in string]: string;
        },
      ) => void)
    | AnyFunction;
  errors: Array<string>;
  tooltip: string;
  label: string;
};

let EmailContentImpl = ({ name, value, onChange, errors = [], tooltip, label }: Props) => {
  return (
    <div className='email-content-editor-wrapper'>
      <div className='editor-tooltip-wrapper'>
        <Tooltip message={t(tooltip as TranslationLockedKeys) as any} />
      </div>
      <TextEditor
        name={name}
        value={value}
        onChange={onChange}
        additional={{
          placeholder: label,
          withLanguageSelector: true,
        }}
        error={errors[0]}
        label={label}
      />
    </div>
  );
};

export const EmailContent = React.memo<Props>(EmailContentImpl);

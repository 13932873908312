import { isDate, isNaN } from 'lodash';
import moment from 'moment';
import Xregexp, { ExecArray } from 'xregexp';

import { TIME_PATTERN } from 'src/constants';

import { momentFormat } from './constants';

export function formatTimeToMask(value: nil | Date | any): string {
  const momentValue = moment(value);

  if (!momentValue.isValid()) {
    return '';
  }

  return moment(value).format(momentFormat);
}

export function formatMaskToTime(value: string, prevDate?: nil | Date): nil | Date {
  if (!TIME_PATTERN.test(value)) {
    return;
  }

  const match: AnyFunction | any = Xregexp.exec(value, TIME_PATTERN) as unknown as
    | nil
    | {
        hours: nil | string;
        minutes: nil | string;
        seconds: nil | string;
      };
  const { hours, minutes, seconds } = match;

  const dateToChange = isDate(prevDate) && !!prevDate.getTime() ? prevDate : new Date();

  const hour = Number(hours);
  const minute = Number(minutes);
  const second = Number(seconds);

  if (isNaN(hour) || isNaN(minute) || isNaN(second)) {
    return;
  }

  dateToChange.setHours(hour, minute, second);
  return dateToChange;
}

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { LOCALES, FLAGS } from 'src/constants';

import { t } from 'utils';

import { formatLongWithShortLocale } from '../utils/formatter';

import { LocaleIcon } from './LocaleIcon';
import { dialogStyles } from './styles';

export const LanguageSelectDialog = ({
  open,
  current,
  onSelect,
  onClose,
}: {
  open: boolean;
  current: string;
  onSelect: Function;
  onClose: AnyFunction;
}) => {
  const classes = dialogStyles();

  const langItem = (locale) => {
    const selectLanguage = () => {
      onSelect(locale);
      onClose();
    };
    return (
      <div key={locale} onClick={selectLanguage} className={classes.locale}>
        <LocaleIcon value={locale} flag={FLAGS[locale]} selected={locale} onClick={() => {}} />
        <p className={`${locale === current ? classes.selected : ''} ${classes.localeText}`}>{formatLongWithShortLocale(locale)}</p>
      </div>
    );
  };

  return (
    <Observer>
      {() => {
        return (
          <Dialog maxWidth={'md'} open={open} onClose={onClose}>
            <DialogTitle className={classes.title}>
              {t.staticAsString('footer.selectLanguage')}
              <IconButton aria-label='close' className={classes.closeButton} onClick={onClose} size='large'>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent className={classes.localesList}>{LOCALES.map((locale) => langItem(locale))}</DialogContent>
          </Dialog>
        );
      }}
    </Observer>
  );
};

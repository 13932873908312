import { SystemUserForm } from './systemUser';

export type SystemUsersResponse = {
  data: SystemUserForm[];
  links: {
    first: string | null;
    last: string | null;
    next: string | null;
    prev: string | null;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
};

export enum COLUMN_WIDTH_ENUM {
  action = '',
  id = '',
  email = '220px',
  roles = '',
  full_name = '220px',
  phone = '160px',
  city = '160px',
  post_code = '160px',
  created_at = '',
}

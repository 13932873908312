import { isFunction } from 'lodash';

type Value = Function[];

class Disposers {
  value: Value = [];

  register(value: Function) {
    this.value = [...this.value, value];
  }

  disposeAll() {
    this.value.forEach((dispose) => {
      isFunction(dispose) && dispose();
    });

    this.value = [];
  }
}

export { Disposers, Disposers as DisposersStore };

import { Theme } from '@mui/material';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { ActionButton } from 'modules/RaceDetails/components';
import * as React from 'react';

import { Icon } from 'components/Icon';

import { t } from 'utils';

import { Race } from 'models';

type Props = {
  race: nil | Race;
  onClick?: React.MouseEventHandler;
  className?: string;
  isApproved: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    flexDirection: 'column',
    gap: '15px',
    lineHeight: '16px',
    letterSpacing: 'unset',
    padding: '14px 0',
  },
}));

const Visibility = ({ race, onClick, className, isApproved }: Props) => {
  const c = useStyles();

  return (
    <ActionButton className={classnames(className, c.button)} variant='text' onClick={onClick}>
      <Switch disabled={!isApproved} checked={Boolean(race?.value.is_searchable)} />
      Toggle visibility in search on the website
    </ActionButton>
  );
};

export { Visibility };

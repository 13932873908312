import { formatter } from 'utils';

import { Distance } from 'models';

export class Payment {
  value: PaymentType;
  distance: Distance | null;

  constructor(value: PaymentType) {
    this.value = value;
    this.distance = null;
    this._initDistance();
  }

  currency() {
    const { currency } = this.value;
    return currency || '';
  }

  totalSum(): string {
    const { amount } = this.value;
    return `${this.currency()} ${formatter.integer(amount as any)}`;
  }

  priceUsed(): string {
    const { price_used } = this.value;

    if (!price_used) {
      return '';
    }

    return `${this.currency()} ${formatter.integer(price_used as any)}`;
  }

  discountedAmount(): string {
    const { discounted_amount } = this.value;

    if (!discounted_amount) {
      return '';
    }

    return `${this.currency()} ${formatter.integer(discounted_amount as any)}`;
  }

  amountForCustomFields(): string {
    const { amount_for_custom_fields } = this.value;

    if (!amount_for_custom_fields) {
      return '';
    }

    return `${this.currency()} ${formatter.integer(amount_for_custom_fields as any)}`;
  }

  orderId(): string {
    const { id } = this.value;

    if (!id) {
      return '';
    }

    return `${id}`;
  }

  _initDistance() {
    const { value } = this;
    if (value.distance) {
      this.distance = new Distance(value.distance);
    }
  }

  distanceName(): string {
    if (!this.distance) {
      return '';
    }

    return `${this.distance.value.name} ${formatter.integer(this.distance?.raceLength())} km` || '';
  }

  userFullName(): string {
    const { user } = this.value;

    if (!user) {
      return '';
    }

    return `${user.firstname} ${user.lastname}`;
  }
}

import * as React from 'react';

import { ListModuleContext } from 'components/context';

// NOTE, Current hoc passes main service and store down to dom tree
const ListModule =
  (service: ExtensionableService<unknown>, mount: Function, unmount: Function) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class ListModuleClazz extends React.Component<P> {
      contextToPass: ListModuleContextProps;

      constructor(props: any) {
        super(props);

        this.contextToPass = {
          load: {
            service,
          },
        };
      }

      componentDidMount() {
        mount();
      }

      componentWillUnmount() {
        unmount();
      }

      render() {
        return (
          <ListModuleContext.Provider value={this.contextToPass}>
            <WrappedComponent {...this.props} />
          </ListModuleContext.Provider>
        );
      }
    } as unknown as void;

export { ListModule };

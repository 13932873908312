import axios from 'axios';

import { USER_PLATFORM_HEADER } from 'src/constants/headers';
import { USER_PLATFORM_ADMIN } from 'src/constants/settings';

function setupUserPlatform() {
  axios.defaults.headers[USER_PLATFORM_HEADER] = USER_PLATFORM_ADMIN;
}

export { setupUserPlatform };

import classNames from 'classnames';
import { flatten, isEmpty } from 'lodash';
import { observer, inject } from 'mobx-react';
import * as React from 'react';

import { LabelForFilter as FilterChipLabel } from 'components/Filter/ChipFilterLabel';
import { ClearAllChipLabel } from 'components/Filter/ChipFilterLabel/ClearAll';

import { t } from 'utils';

import { Coupon as CouponStore } from 'stores';

import loadService from '../../../services/load';

import { StatusFilterLabel } from './Labels/Status';
import { TimeRange } from './Labels/TimeRange';

type Props = {
  couponsStore: CouponStore;
};

type State = {
  selectedFilter: nil | string;
  anchorEl: nil | HTMLElement;
};

const COLUMNS = [
  'id',
  'name',
  'code',
  'qty',
  'left',
  'discount_amount',
  'race.translations.name',
  'distances.translations.name',
  'date_to',
  'status',
  'description',
];

const CUSTOM_LABELS: AnyObject = {
  status: StatusFilterLabel,
};

@inject('couponsStore')
@observer
class FilterLabelToolbar extends React.Component<Props, State> {
  state = {
    selectedFilter: null,
    anchorEl: null,
  };

  static defaultProps = {
    intl: null as any,
    couponsStore: null as any,
  };

  clearFilters = (): void => {
    loadService.clearFilters();
  };

  deleteFilters = (...names: Array<string>) => {
    loadService.deleteFilters(() => {}, ...names);
  };

  processFilterValue = (name: string, value: any): any => {
    const [from, to] = flatten([value]);
    switch (name) {
      case 'date_to':
        if (!from && !to) {
          return null;
        }
        return <TimeRange from={from} to={to} />;
      default:
        return value;
    }
  };

  _renderLabels = (): Array<React.ReactNode> => {
    const { couponsStore } = this.props;
    const searchData = couponsStore.searchDataModel.search;

    return COLUMNS.map((param: string) => {
      const processedValue = this.processFilterValue(param, searchData[param]);
      const LabelComponent = CUSTOM_LABELS[param] || FilterChipLabel;
      // $FlowFixMe
      return (
        <LabelComponent
          onClear={this.deleteFilters}
          key={param}
          name={param}
          values={searchData}
          className='filter-item'
          label={t.staticAsString(`coupons.list.table.${param}` as TranslationLockedKeys)}
          value={processedValue}
          beforeLabelText={
            ['left', 'qty'].includes(param) ? `${t.staticAsString(`coupons.list.table.${param}` as TranslationLockedKeys)}:` : ''
          }
        />
      );
    });
  };

  _renderClearAll = (): React.ReactNode => {
    const { couponsStore } = this.props;
    const searchData = couponsStore.searchDataModel.search;

    return <ClearAllChipLabel onClear={this.clearFilters} key={'clear_all'} values={searchData} className='filter-item' />;
  };

  _isFiltersEmpty = () => {
    const { couponsStore } = this.props;
    const searchData = couponsStore.searchDataModel.search;

    return isEmpty(searchData);
  };

  render() {
    return (
      <div className={classNames('filters-toolbar', { empty: this._isFiltersEmpty() })}>
        {[...this._renderLabels(), this._renderClearAll()]}
      </div>
    );
  }
}

export { FilterLabelToolbar };

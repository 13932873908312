import { DummyStore } from 'src/stores';

import { SETTINGS_STEP } from '../constants';

import { OptionWithParentId } from '../types';

export * from './races';

export const stepStore = new DummyStore(SETTINGS_STEP);

export const syncCustomFieldsStore = new DummyStore<SelectOption[]>([]);
export const raceCustomFieldsStore = new DummyStore<OptionWithParentId[]>([]);

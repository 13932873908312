import { cloneDeep } from 'lodash';
import { Moment } from 'moment';
import * as React from 'react';
import { DayPickerRangeController, DayPickerSingleDateController } from 'react-dates';

type Props = {
  isSingleDate: boolean;
  date?: Moment;
  startDate?: Moment;
  endDate?: Moment;
  minDate?: Moment | null;
  maxDate?: Moment | null;
  isMinDateExclusive: boolean;
  isMaxDateExclusive: boolean;
  onChange: (arg: { startDate: Moment | null; endDate: Moment | null } | Moment | null) => void;
};

type State = {
  focus: 'startDate' | 'endDate';
  focused: boolean;
};

export class Calendar extends React.Component<Props, State> {
  static defaultProps = {
    startDate: null,
    endDate: null,
  };

  state: State = {
    focus: 'startDate',
    focused: true,
  };

  onChangeFocus = (focus: 'startDate' | 'endDate') => {
    this.setState({ focus });
  };

  isDayBlocked = (day: Moment): boolean => {
    const dayLocal = cloneDeep(day).local();
    const { minDate, maxDate, isMinDateExclusive, isMaxDateExclusive } = this.props;

    if (!isMinDateExclusive && dayLocal.isBefore(minDate || NaN, 'day')) {
      return true;
    }

    if (isMinDateExclusive && dayLocal.isSameOrBefore(minDate || NaN, 'day')) {
      return true;
    }

    if (!isMaxDateExclusive && dayLocal.isAfter(maxDate || NaN, 'day')) {
      return true;
    }

    if (isMaxDateExclusive && dayLocal.isSameOrAfter(maxDate || NaN, 'day')) {
      return true;
    }

    return false;
  };

  handleChange = (value: { startDate: Moment | null; endDate: Moment | null }) => {
    const { onChange, startDate, endDate } = this.props;

    if (startDate && endDate) {
      onChange({ startDate: value.startDate || value.endDate, endDate: null });
      return;
    }

    if (value.startDate && value.endDate) {
      this.setState({ focus: 'startDate' });
    }

    onChange(value);
  };

  handleSingleChange = (value: Moment | null) => {
    const { date, onChange } = this.props;

    if (date) {
      onChange(value);
      return;
    }

    if (value) {
      this.setState({ focused: true });
    }

    onChange(value);
  };

  render() {
    const { startDate, endDate, date, isSingleDate } = this.props;

    return isSingleDate ? (
      <DayPickerSingleDateController
        date={(date as any) || null}
        onDateChange={this.handleSingleChange as AnyFunction}
        focused={this.state.focused}
        onFocusChange={({ focused }) => this.setState({ focused })}
        isDayBlocked={this.isDayBlocked as AnyFunction}
        noBorder
        numberOfMonths={1}
        initialVisibleMonth={null}
        hideKeyboardShortcutsPanel
        transitionDuration={0}
      />
    ) : (
      <DayPickerRangeController
        startDate={(startDate as any) || null}
        endDate={(endDate as any) || null}
        onDatesChange={this.handleChange as AnyFunction}
        focusedInput={this.state.focus}
        onFocusChange={this.onChangeFocus}
        isDayBlocked={this.isDayBlocked as AnyFunction}
        initialVisibleMonth={null}
        numberOfMonths={2}
        minimumNights={0}
        keepOpenOnDateSelect={true}
        noBorder
        hideKeyboardShortcutsPanel
        transitionDuration={0}
      />
    );
  }
}

import { RadioGroup, FormHelperText, Box } from '@mui/material';

import { RadioItem } from 'components/Form';

type Props = FieldBaseProps &
  AdditionalMaterialProps & {
    defaultChecked?: boolean;
    disabled?: boolean;
    options: SelectOption[];
    orientation?: 'horizontal' | 'vertical';
  };

const ORIENTATION_VALUE: { [k: string]: string } = {
  horizontal: 'row',
  vertical: 'column',
};

export const WrappedRadio = (props: Props) => {
  const { name, value, options, onChange, defaultChecked = false, error, orientation = 'horizontal', disabled } = props;

  const onSelect = (event) => {
    let value = event.currentTarget.value;

    if (value === 'false') {
      value = false;
    }
    if (value === 'true') {
      value = true;
    }

    onChange({ name, value }, event);
  };

  const isChecked = (item) => {
    const checked = item.value ? item.value === value : (item?.default as boolean);
    return item.value === false && value === false ? true : checked;
  };

  const errorText = (error) => {
    if (typeof error === 'object' && error !== null && 'props' in error) {
      return error.props.id;
    }

    return '';
  };

  return (
    <RadioGroup row className='discount-type' name={name} onChange={onSelect}>
      <Box flexDirection={'column'}>
        <Box sx={{ display: 'flex', flexDirection: ORIENTATION_VALUE[orientation] }}>
          {options.map((item) => {
            return (
              <RadioItem
                defaultChecked={defaultChecked}
                name={name}
                value={item.value}
                label={item.label as any}
                key={item.key as any}
                checked={isChecked(item)}
                disabled={disabled}
              />
            );
          })}
        </Box>
        <FormHelperText error>{errorText(error)}</FormHelperText>
      </Box>
    </RadioGroup>
  );
};

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACE_URL } from 'src/constants';

import { action, request } from 'utils';

import { LOAD_RACE } from '../constants';

import { RaceStore } from '../stores';

class RacesService {
  store: RaceStore;

  constructor(store: RaceStore) {
    this.store = store;
  }

  @request({ action: LOAD_RACE })
  loadResourceRequest(raceId: number, params: FiltersType): any {
    const path = generatePath(RACE_URL, { id: raceId });
    return axios.get(path, { params });
  }

  @action({ action: LOAD_RACE })
  async loadResource(raceId: number) {
    const params = this.store.resourceParams;
    const [status, response] = await this.loadResourceRequest(raceId, params);

    if (status) {
      this.store.addSelectedValue(response.data.data);
    }
  }
}

export { RacesService };

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { Typography } from '@mui/material';
import MaterialTooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

import TooltipImage from 'img/email-content-v3.png';

type TooltipProps = {
  message: string;
};

const styles = makeStyles((theme: any) => ({
  content: {
    color: 'rgba(0,0,0, 0.87)',
    backgroundColor: '#fff',
    fontSize: 12,
    maxHeight: 400,
    overflow: 'auto',
    padding: 12,
  },
  img: {
    marginTop: 15,
  },
  tooltip: {
    popper: {
      opacity: 1,
    },
    tooltip: {
      backgroundColor: 'rgba(168, 174, 184, 0.2)',
      maxWidth: 400,
      fontSize: 12,
      padding: 8,
    },
  },
  grey: {
    color: theme.palette.grey6,
  },
  centered: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  text: {
    paddingLeft: 8,
    textDecoration: 'underline',
  },
}));

// if passing styles to classes prop (not className),
// then styles object should not be nested
const toolTipStyles = makeStyles({
  popper: {
    opacity: 1,
    zIndex: 100,
  },
  tooltip: {
    backgroundColor: 'rgba(168, 174, 184, 0.2)',
    maxWidth: 400,
    fontsize: 12,
    padding: 8,
  },
});

const Tooltip = React.forwardRef((props: TooltipProps, ref) => {
  const classes = styles();
  const toolTipClasses = toolTipStyles();
  const scrollRef = React.useRef<HTMLDivElement | null>(null);

  const { message } = props;

  const onScrollTo = () => {
    scrollRef.current!.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <MaterialTooltip
      onOpen={onScrollTo}
      title={
        <div ref={scrollRef} className={classes.content}>
          {message}
          <img className={classes.img} src={TooltipImage} alt='email content example' />
        </div>
      }
      classes={toolTipClasses}
      arrow
    >
      <div className={classes.centered}>
        <InfoOutlined className={`${classes.centered} ${classes.grey}`} />
        <Typography variant='body2' className={`${classes.text} ${classes.grey}`}>
          Click to see the example
        </Typography>
      </div>
    </MaterialTooltip>
  );
});

export { Tooltip };

import axios from 'axios';
import { SynchronizationsConstants } from 'modules/Synchronisation/Table/constants/synchronizations.constants';
import { generatePath } from 'react-router-dom';

import {
  DISABLE_SYNCHRONIZATION_URL,
  ENABLE_SYNCHRONIZATION_URL,
  PUBLISH_SYNCHRONIZATION_URL,
  SYNCHRONIZATION_URL,
  SYNCHRONIZATIONS_URL,
} from 'src/constants';

import { t } from 'utils';

import { progressStore, toastStore } from 'stores';

import { SynchronizationFilters } from '../types/filters.type';

export namespace SynchronizationsService {
  export async function load<T>(filters?: SynchronizationFilters) {
    progressStore.log(SynchronizationsConstants.SynchronizationsTable, 'progress');
    try {
      const data = await axios.get<T>(SYNCHRONIZATIONS_URL, {
        params: {
          ...filters,
        },
      });

      return data.data;
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(SynchronizationsConstants.SynchronizationsTable, 'completed');
    }
  }

  export async function toggleSynchronizationStatus(id: number, isEnabled: boolean) {
    progressStore.log(SynchronizationsConstants.SynchronizationsTable, 'progress');
    try {
      const data = await axios.post(generatePath(isEnabled ? DISABLE_SYNCHRONIZATION_URL : ENABLE_SYNCHRONIZATION_URL, { id }));
      return data.status;
    } catch (error) {
      toastStore.show(t.staticAsString(isEnabled ? 'sync.toast.error.deactivate' : 'sync.toast.error.activate'));
      throw error;
    } finally {
      progressStore.log(SynchronizationsConstants.SynchronizationsTable, 'completed');
    }
  }

  export async function publishSynchronization(id: number) {
    progressStore.log(SynchronizationsConstants.SynchronizationsTable, 'progress');
    try {
      const data = await axios.post(generatePath(PUBLISH_SYNCHRONIZATION_URL, { id }));
      return data.status;
    } catch (error) {
      toastStore.show(t.staticAsString('sync.toast.error.reSync'));
      return false;
    } finally {
      progressStore.log(SynchronizationsConstants.SynchronizationsTable, 'completed');
    }
  }

  export async function deleteSynchronization(id: number) {
    progressStore.log(SynchronizationsConstants.SynchronizationsTable, 'progress');
    try {
      const data = await axios.delete(generatePath(SYNCHRONIZATION_URL, { id }));
      return data.status;
    } catch (error) {
      toastStore.show(t.staticAsString('sync.toast.error.delete'));
      throw error;
    } finally {
      progressStore.log(SynchronizationsConstants.SynchronizationsTable, 'completed');
    }
  }
}

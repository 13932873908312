import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TableBody from '@mui/material/TableBody';
import { observer, inject } from 'mobx-react';
import { AttachToProfileModal } from 'modules/StartList/components/AttachToProfileModal';
import * as React from 'react';
import shortid from 'shortid';

import { COUNTRY, HELPER_DISTANCE, RACER, ROUTES } from 'src/constants';

import { withProgressSpinner, withStoresClean, withSorting, withSearch } from 'hocs';

import { FilterLabelToolbar } from 'components/Filter/FilterLabelToolbar';
import { Table } from 'components/Table';

import { history, t } from 'utils';

import { helperDistancesService } from 'services';

import {
  confirmationModalStore,
  Country as CountryStore,
  HelperDistances as HelperDistancesStore,
  Locale as LocalStore,
  Session as SessionStore,
} from 'stores';

import { exportMembers, openForm } from '../../../actions';

import { membersLifecycle } from '../../../services';
import memberService from '../../../services/membersService';
import racerService from '../../../services/racersService';

import { startlistMembersStore } from '../../../stores';

import { ImportErrorsModal } from '../../ImportErrorsModal';
import { Nav } from '../../Nav/team';
import { Breadcrumbs } from './Breadcrumbs';
import { Item } from './Item';
import { RacerForm } from './New';
import { Pagination } from './Pagination';
import { THead } from './THead';
import { Toolbar } from './Toolbar';

type Props = {
  localeStore: LocalStore;
  sessionStore: SessionStore;
  helperDistancesStore: HelperDistancesStore;
  countriesStore: CountryStore;
  match: AnyObject;
};

type State = {
  isModalOpen: boolean;
  onStepDeleteConfirm: Function | null;
  onStepDeleteConfirmTitle: string;
  onStepDeleteConfirmBody: string;
};

@withSearch(memberService, 'racers.list.table')
@withSorting(memberService)
@withProgressSpinner([`LOAD_${COUNTRY}S`, `LOAD_${HELPER_DISTANCE}`, `LOAD_${RACER}S`], { hideClassName: 'hidden' })
@withStoresClean(startlistMembersStore)
@inject('localeStore', 'helperDistancesStore', 'countriesStore', 'sessionStore')
@observer
class List extends React.Component<Props, State> {
  static defaultProps = {
    localeStore: null as any,
    sessionStore: null as any,
  };

  state = {
    isModalOpen: false,
    onStepDeleteConfirm: null,
    onStepDeleteConfirmTitle: '',
    onStepDeleteConfirmBody: '',
  };

  componentDidMount() {
    const distanceId = this.props.match.params.id;
    const raceId = this.props.match.params.raceId;

    membersLifecycle.mount(raceId, distanceId);
  }

  componentWillUnmount() {
    membersLifecycle.unmount();
  }

  items = () => {
    const { helperDistancesStore } = this.props;
    const { selected } = helperDistancesStore;

    return startlistMembersStore.values.map<React.ReactNode>((racerItem) => {
      return (
        <Item
          key={shortid()}
          item={racerItem}
          distance={selected as any}
          deleteRacer={this.onDeleteRacerWrapper}
          handleCopy={this.onCopyRegistrationLink}
          handleResend={this.onResend}
          handleAttachToProfile={this.onAttachToProfile}
          handleDeactivateRefundProtect={this.handleDeactivateRefundProtect}
          checkedRequiredFields={this.getRequired(selected?.registration_fields || [])}
          isRefundProtectPaid={startlistMembersStore.values.filter((item) => Boolean(item.order_refund_protect_amount))}
        />
      );
    });
  };

  onChangeDistance = (e: React.ChangeEvent<HTMLInputElement>) => {
    const distanceId = e.target.value;
    const raceId = this.props.match.params.raceId;

    membersLifecycle.onChangeDistance(raceId, distanceId);
  };

  getRequired = (required_fields: Array<AnyObject>): AnyObject => {
    let requiredHash: AnyObject = {};

    required_fields.forEach((item) => {
      requiredHash[item.name] = item.selected;
    });

    return requiredHash;
  };

  createRacer = (): void => {
    this.setState({
      ...this.state,
      isModalOpen: true,
    });
  };

  onClose = () => {
    this.setState({
      ...this.state,
      isModalOpen: false,
    });
  };

  onDeleteRacer = (racer: RacerType) => {
    const distance = this.props.helperDistancesStore.selected || {};
    memberService.deleteValue(racer);
    this._clearPopup();
  };

  onCopyRegistrationLink = async (racerId: number): Promise<any> => {
    const distance: AnyObject = this.props.helperDistancesStore.selected || {};
    await racerService.getRegistrationLink(distance.id, racerId);
  };

  onResend = (racerId: number, isSuccesfulEmail: boolean): void => {
    const distance: AnyObject = this.props.helperDistancesStore.selected || {};
    racerService.resendInvitation(distance.id, racerId, isSuccesfulEmail);
  };

  onAttachToProfile = (racer: RacerType): void => {
    if (racer.user_id) {
      confirmationModalStore.openModal({
        title: t.staticAsString('racers.confirmPopup.mainTitle'),
        body: t.staticAsString('racers.confirmPopup.attachProfile'),
        type: 'confirm',
        onConfirm: () => {
          openForm(racer);
        },
      });
    } else {
      openForm(racer);
    }
  };

  onDeleteRacerWrapper = (racer: RacerType) => {
    confirmationModalStore.openModal({
      title: t.staticAsString('racers.confirmPopup.mainTitle'),
      body: t.staticAsString('racers.confirmPopup.mainBody'),
      type: 'confirm',
      onConfirm: () => {
        this.onDeleteRacer(racer);
      },
    });
  };

  handleDeactivateRefundProtect = (racer_id: number) => {
    // TODO add functionality to remove RP later
  };

  _clearPopup = () => {
    if (this.state.onStepDeleteConfirm) {
      this.setState({
        onStepDeleteConfirm: null,
      });
    }
  };

  getContent = () => {
    const { helperDistancesStore } = this.props;
    const { selected } = helperDistancesStore;

    if (!selected) {
      return null;
    }
    let Content;

    if (startlistMembersStore && startlistMembersStore.values.length > 0) {
      Content = (
        <React.Fragment>
          <div className='component-list racers-list'>
            <Table>
              <THead distance={selected} checkedRequiredFields={this.getRequired(selected.registration_fields || [])} />
              <TableBody>{this.items()}</TableBody>
            </Table>
          </div>
        </React.Fragment>
      );
    } else {
      Content = (
        <div className='racer-no-content'>
          <div className='not-racers'>{t.staticAsString('racers.noRegisteredRacers')}</div>
        </div>
      );
    }

    return Content;
  };

  onEditDistance = () => {
    const { selected } = this.props.helperDistancesStore;

    history.push(`${ROUTES.racesRoute}/${selected?.race?.id}/distances/${selected?.id}`);
  };

  onDeleteDistanceWrapper = () => {
    const { helperDistancesStore } = this.props;
    const { selected } = helperDistancesStore;

    if (!selected) {
      return;
    }

    confirmationModalStore.openModal({
      title: t.staticAsString('distances.confirmPopup.mainTitle'),
      body: t.staticAsString('distances.confirmPopup.mainBody', { name: selected.name }),
      type: 'confirm',
      confirmationValue: selected.name,
      confirmationLabel: t.staticAsString('confirmationModal.distance.label'),
      onConfirm: () => {
        this.onDeleteDistance();
      },
    });
  };

  onDeleteDistance = () => {
    const { selected } = this.props.helperDistancesStore;
    helperDistancesService.deleteDistance(Number(selected?.race?.id), Number(selected?.id));
  };

  render() {
    const { helperDistancesStore, countriesStore, sessionStore } = this.props;
    const { selected, modelSelected } = helperDistancesStore;
    const { isModalOpen } = this.state;
    const organizerVerified = sessionStore.isOrganizerVerified;

    if (!selected || !selected.race || !selected.race.distances) {
      return null;
    }

    return (
      <div className='content main-container-list start-list'>
        <div className='sticky-left'>
          <Dialog open={isModalOpen} onClose={this.onClose}>
            <DialogContent sx={{ overflowX: 'hidden' }}>
              <RacerForm
                distance={modelSelected!}
                helperDistances={selected!}
                onClose={this.onClose}
                countries={countriesStore.values as any}
              />
            </DialogContent>
          </Dialog>

          <AttachToProfileModal />
          <ImportErrorsModal />
          <Breadcrumbs distance={modelSelected as any} />
          <div className='toolbar-container'>
            <Toolbar
              distance={modelSelected as any}
              onAddNewRacer={this.createRacer}
              onChange={this.onChangeDistance}
              exportFile={exportMembers}
              onEditDistance={this.onEditDistance}
              onDeleteDistance={this.onDeleteDistanceWrapper}
            />
          </div>
          <Nav distance={selected} />
          <FilterLabelToolbar />
        </div>
        {this.getContent()}
        <Pagination startlistMembersStore={startlistMembersStore} distance_id={selected.id} switchedOnTop={organizerVerified} />
      </div>
    );
  }
}

export { List };

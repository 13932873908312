import { TableCell } from '@mui/material';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { withConfirmModal } from 'hocs';

import { SettingsMenu } from 'components/SettingsMenu';

import { t } from 'utils';

import { deleteResult } from '../../actions';

import { isActionColumn } from '../../derivations';
import { DeleteAction } from './MenuActions';
import { Trigger } from './Trigger';
import { styles } from './styles';

type OriginalProps = {
  racerId: number;
};

type Props = OriginalProps & HOC.withConfirmModal<'deleteConfirm'>;

let ActionCellComp = ({ racerId, deleteConfirm }: Props) => {
  const classes = styles();

  const onDelete = () => {
    deleteConfirm.open(() => {
      deleteResult(racerId);
    });
  };

  return (
    <Observer>
      {() => {
        if (!isActionColumn.get()) {
          return null;
        }

        return (
          <TableCell className={classes.root}>
            <SettingsMenu
              id={racerId}
              Trigger={Trigger}
              additionalMenuProps={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
            >
              <DeleteAction onClick={onDelete}>{t.staticAsString('results.deleteResult')}</DeleteAction>
            </SettingsMenu>
          </TableCell>
        );
      }}
    </Observer>
  );
};
const deleteDecorator = withConfirmModal('deleteConfirm', {
  i18Title: 'races.confirmPopup.mainTitle',
  i18Body: 'results.confirmModal',
  type: 'confirm',
});

export const ActionCell = deleteDecorator(ActionCellComp) as unknown as React.ComponentType<OriginalProps>;

import moment from 'moment';

import { time } from 'utils';

import { Distance } from './distance';
import { Racer } from './racer';
import { Split } from './split';
import { Team } from './team';

type StoreOption = {
  property: string;
  storeId: string;
};

class Result {
  value: ResultType;
  racer: nil | Racer;
  team: nil | Team;
  distance: nil | Distance;
  // @ts-ignore
  splits: Split[];
  namespace: nil | string;

  constructor(value: ResultType, namespace?: string) {
    this.value = value;
    this.namespace = namespace;
    this.initRacer();
    this.initTeam();
    this.initDistance();
    this.initSplits();
  }

  initRacer() {
    const { racer } = this.value;
    const racerPrefix = this.generateFieldId('racer');

    if (racer) {
      this.racer = new Racer(racer, racerPrefix);
    }
  }

  initTeam() {
    const { team } = this.value;
    const teamPrefix = this.generateFieldId('team');

    if (team) {
      this.team = new Team(team, teamPrefix);
    }
  }

  initDistance() {
    const { distance } = this.value;
    if (distance) {
      this.distance = new Distance(distance);
    }
  }

  initSplits() {
    const { splits } = this.value;

    const splitLength = (splits?.length || 0) - 1;

    this.splits = (splits || []).map<Split>((el, index) => {
      const fieldId = `${this.generateFieldId('splits')}.${index}`;

      const model = new Split(el, fieldId);

      model.setResult(this);

      if (index >= splitLength) {
        model.setAsLast();
      }

      return model;
    });
  }

  findSplitByCheckpoint(checkpointId: nil | number): nil | Split {
    return this.splits.find((el) => el.value.checkpoint_id === checkpointId);
  }

  lastSplit(): nil | Split {
    const { splits } = this;
    return splits[splits.length - 1];
  }

  setFieldIdPrefix(prefix: string) {
    this.namespace = prefix;
  }

  generateFieldId(columnName: string): string {
    return [this.namespace, columnName].join('.');
  }

  generateStoreOption(property: string): StoreOption {
    return { property, storeId: this.generateFieldId(property) };
  }

  generateStoreOptions(...properties: string[]): StoreOption[] {
    return properties.map((property) => this.generateStoreOption(property));
  }

  isInvalidStartTime(): boolean {
    const { start_time } = this.value;

    return !time.isValid(start_time, 'datetimems');
  }

  isInvalidFinishTime(): boolean {
    const { finish_time } = this.value;

    return !time.isValid(finish_time as moment.MomentInput, 'datetimems');
  }

  isHardDnf(): boolean {
    const { value } = this;
    return !!value.hard_dnf;
  }
}

export { Result };

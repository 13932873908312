import { toJS } from 'mobx';
import { COUPON_CODE_TYPES, COUPON_FORM_STATES, COUPONS_FORM_ID } from 'modules/CouponsForm/constants';
import { couponFormType } from 'modules/CouponsForm/types';
import { couponFormValidation } from 'modules/CouponsForm/validations';

import { CONFIRM_POPUP_TYPES } from 'src/constants/mixed';

import { validate } from 'utils/core';
import { t } from 'utils/t';

import { confirmationModalStore, progressStore } from 'stores';
import { form } from 'stores/form';

import { mapFormToCouponApi, mapFormToPatchCouponApi } from '../utils';

import { couponFormModalStore } from '../stores';

import { closeCouponForm } from './control.action';
import { createCoupon } from './create.action';
import { updateCoupon } from './update.action';

const isEdit = () => couponFormModalStore.value?.isEdit;

const getIsAddonsEnabled = () => {
  const coupon_code = form.fetchWithoutObservable<AnyObject[]>(COUPONS_FORM_ID, 'coupon_type') || [];

  return coupon_code.some((item) => item.key === COUPON_CODE_TYPES[1].key);
};

export const onConfirmSubmit = () => {
  confirmationModalStore.openModal({
    title: t.staticAsString('races.confirmPopup.mainTitle'),
    body: t.staticAsString('couponFormDialog.confirmPopup.body'),
    type: CONFIRM_POPUP_TYPES.confirm,
    onConfirm: () => submit(),
  });
};

export const handlePrepareSubmit = () => {
  const values: couponFormType = form.fetchWithoutObservable(COUPONS_FORM_ID);
  const isValid = validate(COUPONS_FORM_ID, values, couponFormValidation() as any);
  if (!isValid) return;

  const isAllDistancesSelected = Boolean(form.fetch(COUPONS_FORM_ID, 'isAllDistancesSelected'));
  const isAddonsEnabled = getIsAddonsEnabled();

  if (isAllDistancesSelected) {
    if (isAddonsEnabled) return handleConfirmAddonsPlusAllDistances();
    return handleConfirmAllDistances(submit);
  }

  if (isAddonsEnabled) return handleConfirmAddons();

  return submit();
};

export const handleConfirmAllDistances = (confirmFn: Function) => {
  confirmationModalStore.openModal({
    title: t.staticAsString('coupons.warningPopUp.title'),
    body: t.staticAsString('coupons.warningPopUp.allDistanceWarning'),
    type: CONFIRM_POPUP_TYPES.confirm,
    btnLabel: {
      confirm: 'shared.confirmPopup.btnAction.yesSure',
      cancel: 'shared.confirmPopup.btnAction.cancel',
      ok: 'shared.confirmPopup.btnAction.ok',
    },
    onConfirm: () => Promise.resolve(setTimeout(confirmFn, 100)),
  });
};

const handleConfirmAddons = () => {
  confirmationModalStore.openModal({
    title: t.staticAsString('coupons.warningPopUp.title'),
    body: t.staticAsString('coupons.warningPopUp.addonWarning'),
    type: CONFIRM_POPUP_TYPES.confirm,
    btnLabel: {
      confirm: 'shared.confirmPopup.btnAction.yesSure',
      cancel: 'shared.confirmPopup.btnAction.cancel',
      ok: 'shared.confirmPopup.btnAction.ok',
    },
    onConfirm: submit,
  });
};

const handleConfirmAddonsPlusAllDistances = () => {
  confirmationModalStore.openModal({
    title: t.staticAsString('coupons.warningPopUp.title'),
    body: t.staticAsString('coupons.warningPopUp.addonsPlusAllDistances'),
    type: CONFIRM_POPUP_TYPES.confirm,
    btnLabel: {
      confirm: 'shared.confirmPopup.btnAction.yesSure',
      cancel: 'shared.confirmPopup.btnAction.cancel',
      ok: 'shared.confirmPopup.btnAction.ok',
    },
    onConfirm: submit,
  });
};

const submit = async () => {
  const payload = isEdit()
    ? mapFormToPatchCouponApi(toJS(form.fetch(COUPONS_FORM_ID)))
    : mapFormToCouponApi(toJS(form.fetch(COUPONS_FORM_ID)));
  progressStore.log(COUPON_FORM_STATES.SAVING, 'progress');

  let status;

  if (isEdit()) [status] = await updateCoupon(couponFormModalStore.value!.couponId!, payload);
  else [status] = await createCoupon(payload);

  if (status) {
    await couponFormModalStore.value!.successCallback();
    closeCouponForm();
  }
  progressStore.log(COUPON_FORM_STATES.SAVING, 'completed');
};

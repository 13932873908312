import { observable, action, computed, makeObservable } from 'mobx';

class HostsStore {
  @observable
  web: string = '';

  @observable
  admin: string = '';

  constructor() {
    makeObservable(this);
  }

  @computed
  get isHostsEmpty(): boolean {
    return !(Boolean(this.web) && Boolean(this.admin));
  }

  @action
  setHosts(web: string, admin: string): void {
    this.web = web;
    this.admin = admin;
  }
}

export default new HostsStore();

import { DummyStore } from 'stores';

export const modalStore = new DummyStore<{
  open: boolean;
  isEdit: boolean;
  raceId: number | null;
  successCallback?: Function;
  failCallback?: Function;
}>({
  open: false,
  isEdit: false,
  raceId: null,
});
export const distancesStore = new DummyStore<AnyObject[]>([]);
export const integrationStore = new DummyStore<AnyObject | null>(null);

import { emptyData } from '../common/empty-data';
import { Participants, RawParticipantsJSON } from '../types/participants.type';
import { mapLabels } from './common';

export function mapParticipants(raw: RawParticipantsJSON.Data): Participants {
  return {
    total: raw.correlation.buckets.all.current.doc_count,
    trend: {
      current: raw.correlation.buckets.all.current.doc_count,
      previous: raw.correlation.buckets.all.previous.doc_count,
      rate: raw.correlation.buckets.all?.rate?.value ?? null,
    },
    data:
      raw.races.buckets[0]?.histogram.buckets.map((bucket) => {
        return {
          label: mapLabels(bucket.key_as_string),
          value: bucket.doc_count,
        };
      }) ?? emptyData,
  };
}

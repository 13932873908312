import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import * as React from 'react';

import { t } from 'utils';

import { menuItemStyles } from './styles';

type Props = {
  onClick: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  children: React.ReactNode;
};

export const DeleteAction = React.forwardRef<HTMLDivElement, Props>(({ onClick, children }: Props, ref) => {
  const classes = menuItemStyles();

  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <DeleteIcon className={classes.delete} />
      </ListItemIcon>
      <Typography className={classes.delete}>{children}</Typography>
    </MenuItem>
  );
});

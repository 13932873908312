import { Box } from '@mui/material';
import React from 'react';

type Props = {
  children: React.ReactNode;
};

export const FilterWrapper = ({ children }: Props) => {
  return <Box sx={{ display: 'flex', justifyContent: 'space-between', minWidth: '250px', padding: '16px 12px' }}>{children}</Box>;
};

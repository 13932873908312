/*

 * New result form, racer autocomplete
*/
import { cloneDeep } from 'lodash';
import { observable, action, computed, makeObservable } from 'mobx';

import { Racer as RacerModel } from 'models';

import { singleResultsStore } from '..';

class Racers {
  @observable
  values: RacerType[] = [];

  static defaultFilters = {
    status: 'paid',
    // TODO This should be fixed when we will add select with pagination
    limit: 5000,
    doesntHave: 'result',
    search: 'status:paid',
    searchFields: 'status:=',
    searchJoin: 'AND',
  };

  constructor() {
    makeObservable(this);
  }

  @action
  addValues(values: RacerType[]) {
    this.values = values;
  }

  find(id: number) {
    return this.modelValues.find((el) => el.value.id === id);
  }

  @action
  cleanValues() {
    this.values = [];
  }

  @action
  deleteRacer(id: number) {
    this.values = this.values.filter((racer) => racer.id !== id);
  }

  @computed
  get modelValues(): RacerModel[] {
    return this.values.map((racer) => new RacerModel(racer));
  }

  @computed
  get selectOptions(): SelectOption[] {
    const filteredRacersIds = singleResultsStore.values.map((result) => result?.racer?.id);
    return this.modelValues
      .filter((racer) => !filteredRacersIds.includes(racer.value.id))
      .map((racer) => {
        const compareData = this.formatRacerForSearch(racer.value);
        return { ...racer.forSelect(this.formatRacer), compareData };
      });
  }

  private formatRacer = (racer: RacerType) => {
    if (!racer.bib_number) {
      return racer.full_name;
    }

    return `${racer.full_name} (BIB: ${racer.bib_number || ''})`;
  };

  private formatRacerForSearch = (racer: RacerType) => {
    return `${racer.full_name} ${racer.bib_number || ''}`;
  };
}

export { Racers, Racers as RacersStore };

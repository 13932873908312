/*
Examples:
EN format
{
  "LTS": "h:mm:ss A",
  "LT": "h:mm A",
  "L": "MM/DD/YYYY",
  "LL": "MMMM D, YYYY",
  "LLL": "MMMM D, YYYY h:mm A",
  "LLLL": "dddd, MMMM D, YYYY h:mm A"
}

SV format
{
  "LTS": "HH:mm:ss",
  "LT": "HH:mm",
  "L": "YYYY-MM-DD",
  "LL": "D MMMM YYYY",
  "LLL": "D MMMM YYYY [kl.] HH:mm",
  "LLLL": "dddd D MMMM YYYY [kl.] HH:mm",
  "lll": "D MMM YYYY HH:mm",
  "llll": "ddd D MMM YYYY HH:mm"
}
*/
import moment from 'moment';

import { BE_FORMAT, FE_DATETIME_MS_FORMAT } from 'src/constants';

import { localeStore } from 'stores';

// Generate date format based on current selected locale
function generateDateFormat(format: any): string {
  let selectedLocal = localeStore.value;

  // Enforces British date format DD/MM/YY
  if (selectedLocal === 'en') selectedLocal = 'en-gb' as any;
  return moment.localeData(selectedLocal).longDateFormat(format);
}

type Formats = {
  [K in CommonDateFormats]: () => string;
};

const commonDateFormats: Formats = {
  shortDate: () => generateDateFormat('L'), // DD/MM/YYYY
  date: () => generateDateFormat('Ll'), // MMM DD, YYYY
  time: () => generateDateFormat('LT'),
  time24: () => 'HH:mm',
  timeWithSeconds: () => generateDateFormat('LTS'),
  shortDateWithTime: () => `${commonDateFormats.shortDate()} ${commonDateFormats.time()}`,
  shortDateWithTimeAndSeconds: () => `${commonDateFormats.shortDate()} ${commonDateFormats.timeWithSeconds()}`,
  BEdate: () => BE_FORMAT.date,
  BEdatetime: () => BE_FORMAT.datetime,
  BEdatetimems: () => BE_FORMAT.datetimems,
  FEdatetimems: () => FE_DATETIME_MS_FORMAT,
};

export { commonDateFormats, generateDateFormat };

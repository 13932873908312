import { t } from 'utils';

export const updateResult = {
  activity_link: {
    url: {
      message: () => t.staticAsString('races.new.errors.invalid_url'),
    },
  },
  distance_logged: {
    numericality: {
      greaterThan: 0,
      message: () => t.staticAsString('results.errors.validations.distance_logged'),
    },
  },
  finish_time: {
    datetime: {
      message: 'Vnezapnaya Validashka!',
    },
  },
};

import { Box } from '@mui/material';
import React, { FC } from 'react';

import { StatusTypeEnum } from '../../../../../types/systemUserPayments-response.type';

type Props = {
  status: number;
};

export const StatusCell: FC<Props> = (props) => {
  let text = '';
  let color = '';
  let bg = '';
  switch (props.status) {
    case StatusTypeEnum.PENDING: {
      text = 'Pending';
      color = '#65B7FF';
      bg = 'rgba(101, 183, 255, 0.12);';
      break;
    }
    case StatusTypeEnum.COMPLETED: {
      text = 'Completed';
      color = '#66BB6A';
      bg = 'rgba(102, 187, 106, 0.12)';
      break;
    }
    case StatusTypeEnum.FAILED: {
      text = 'Failed';
      color = '#FF8A65';
      bg = 'rgba(255, 138, 101, 0.12)';
      break;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: 12,
        textTransform: 'uppercase',
        width: 95,
        height: 22,
        color,
        backgroundColor: bg,
      }}
    >
      {text}
    </Box>
  );
};

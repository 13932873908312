import { cloneDeep } from 'lodash';

import { checkPointStore, gpxStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { CheckPoint, GpxRoute } from 'src/modules/Distances/components/shared/Steps/GPX/types';

export const setPointsDefaultCoordinates = () => {
  const { coordinates } = gpxStore.value as GpxRoute;
  let checkPoints = cloneDeep(checkPointStore.value) as Array<CheckPoint>;

  if (checkPoints.length > 0) {
    const startCheckPoint = checkPoints[0].coordinate;
    const finishCheckPoint = checkPoints[checkPoints.length - 1].coordinate;

    if (startCheckPoint.lng === 0 && startCheckPoint.lat === 0) {
      startCheckPoint.lng = coordinates[0].lng;
      startCheckPoint.lat = coordinates[0].lat;
      checkPoints[0].type = 'ready';
    }

    if (finishCheckPoint.lng === 0 && finishCheckPoint.lat === 0) {
      finishCheckPoint.lng = coordinates[coordinates.length - 1].lng;
      finishCheckPoint.lat = coordinates[coordinates.length - 1].lat;
      checkPoints[checkPoints.length - 1].type = 'ready';
    }

    checkPointStore.set(checkPoints);
  }
};

import { computed, makeObservable } from 'mobx';

import { Loadable } from 'stores';

import { SystemUserForm } from '../types';

class SystemUserFormStore extends Loadable<SystemUserForm, FiltersType> {
  constructor() {
    super();

    makeObservable(this);
  }

  @computed
  get modelSelected(): SystemUserForm | nil {
    if (this.selected) {
      return this.selected;
    } else {
      return null;
    }
  }
}

export { SystemUserFormStore };

import { racersService } from '../services';

import { distancesNamesFilters } from '../stores';

export const loadDistancesNames = async (
  params: {
    limit?: number;
    page?: number;
    search?: string;
  } = {},
) => {
  let { page: storePage, filters: storeFilters } = distancesNamesFilters;

  const { search: storeSearch } = storeFilters;

  let { limit = storeFilters.limit, page = storePage, search = storeSearch } = params;

  if (params.limit) {
    page = 1;
  }

  const queryParams = {
    ...storeFilters,
    page,
    limit,
    search,
  };

  const [status, { data, meta }] = await racersService.loadDistancesNames(queryParams);

  if (!status) return;

  distancesNamesFilters.appendValues(data, page, {
    ...storeFilters,
    limit,
    search,
  });

  const paginationMeta = meta && meta.pagination;

  if (paginationMeta) {
    distancesNamesFilters.addPaginationMeta(paginationMeta);
  }
};

export const loadAndSelectRelatedDistanceToRace = async (raceId: number, page = 1, searchText?: string) => {
  // When user selects race,
  // keep only distances
  // related to this race in distance name filters
  // (actally only distances with corresponding race_parent_id)
  const { relatedFilters } = distancesNamesFilters;

  const queryParams = {
    ...relatedFilters,
    search: `race_parent_id:${raceId}`,
    page,
    ...(searchText && {
      search: `name:${searchText};race_parent_id:${raceId}`,
      searchFields: 'name:like;race_parent_id:=',
      searchJoin: 'AND',
    }),
  };

  const [status, { data, meta }] = await racersService.loadDistancesNames(queryParams);

  if (!status) return;

  if (meta?.pagination) {
    distancesNamesFilters.addPaginationMeta(meta?.pagination);
  }

  distancesNamesFilters.appendValues(data, page, queryParams);
  distancesNamesFilters.selectRaceId(raceId);
};

import { RACE_FORM_ID } from 'modules/Races/constants';
import moment from 'moment';

import { DEFAULT_RACE_IMAGE } from 'src/constants/mixed';

import { currenciesStore, form, helperOrganizersStore, helperRacesStore, sportTypesAuxiliaryStore } from 'stores';

import { editorSettingsStore, receiptSettingsStore } from '../stores';

import { mapOrganizerFromContacts, receiptMapper } from '../mapper';

export const initForm = () => {
  const race = helperRacesStore.selected;
  const organizers = helperOrganizersStore.valuesForSelect();
  const sportTypes = sportTypesAuxiliaryStore.valuesForSelect();
  const currenciesOptions = currenciesStore.valuesForSelect;

  if (race !== null) {
    const organizer = organizers.find((item) => item.key === race!.organizer_id);
    const organizerInfo = race.contacts && mapOrganizerFromContacts(race.contacts);
    const sport = sportTypes.find((item) => item.key === race!.sport_id);
    const currency = currenciesOptions.find((item) => item.key === race!.currency?.id);
    const receiptSettings = receiptMapper.mapFrom(receiptSettingsStore.value);
    const editorSettings = (editorSettingsStore.value || []).reduce((acc: object, cur: string) => {
      acc[cur] = true;
      return acc;
    }, {});
    const selectedLanguages = race?.selected_lang_codes?.filter((lang) => lang !== race?.pref_lang_code) ?? [];

    const formData: RaceFormData = {
      name: race?.name,
      description: race?.description,
      image: race?.image === DEFAULT_RACE_IMAGE ? '' : race?.image,
      currency_id: currency?.value,
      sport_id: sport,
      organizer_id: organizer,
      race_date: moment(race?.race_date),
      race_end_date: moment(race?.race_end_date),
      location: race?.location,
      translations: race?.translations,
      race_url: race?.race_url,
      editorSettings,
      languagesData: {
        selectedLanguages,
        defaultLocale: race?.pref_lang_code,
      },
      ...receiptSettings,
      ...organizerInfo,
    };
    form.merge(RACE_FORM_ID, formData);
  }
};

export const raceFormData = (name?: keyof RaceFormData): AnyObject => form.fetch<RaceFormData>(RACE_FORM_ID, name);
export const raceFormUpdate = (value: object) => form.merge(RACE_FORM_ID, value);

import * as React from 'react';

import { TEAM_RESULTS_COLUMNS } from '../../../constants';

import { generateOnEdit } from '../../../actions';

import { RowType } from '../';
import { ResultsContext } from '../../../context';
import { isColumnVisibleTeam } from '../../../tools';
import { EditableCell } from '../../EditableCell';
import { FIELD_EDIT } from './edit';
import { FIELD_SHOW } from './show';
import { FIELD_UPDATE } from './update';

const { MAIN_COLUMNS } = TEAM_RESULTS_COLUMNS;

function TeamRowComponent({ value }: RowType) {
  return (
    <ResultsContext.Consumer>
      {(context) => {
        return MAIN_COLUMNS.map((column) => {
          if (!isColumnVisibleTeam(column)) {
            return null;
          }

          const generateShowFun = FIELD_SHOW[column as string];

          const show = generateShowFun && generateShowFun(value, context);

          const generateEditFun = FIELD_EDIT[column as string];

          const edit = generateEditFun && generateEditFun(value, context);

          const generateUpdateFun = FIELD_UPDATE[column as string];

          const update = generateUpdateFun && generateUpdateFun(value, context);

          const fieldId = value.generateFieldId(column);

          return (
            <EditableCell onEdit={generateOnEdit(fieldId, column, context)} show={show} edit={edit} update={update} fieldId={fieldId} />
          );
        });
      }}
    </ResultsContext.Consumer>
  );
}

const TeamRow = React.memo<RowType>(TeamRowComponent);
export { TeamRow };

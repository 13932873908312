import { errorsStore, form } from 'stores';

import { COPY, SUPPORT_TASK_MANAGER_COPY_FORM } from '../constants';

import { modalStore, targetDistancesStore, sourceDistanceStore } from '../stores';

export const mount = async () => {
  await Promise.all([
    form.merge(SUPPORT_TASK_MANAGER_COPY_FORM, {
      action: COPY,
    }),
  ]);
};

export const unmount = (): void => {
  modalStore.clear();
  errorsStore.clear(SUPPORT_TASK_MANAGER_COPY_FORM);

  sourceDistanceStore.clear();
  targetDistancesStore.clear();
};

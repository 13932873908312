import { makeStyles } from '@mui/styles';

export const styles = makeStyles({
  root: {
    position: 'fixed',
    left: 0,
    width: 50,
    padding: 5,
  },
  trigger: {
    padding: 6,
    color: '#a8aeb8',
  },
});

export const menuItemStyles = makeStyles({
  delete: {
    color: '#ce5659',
    textTransform: 'capitalize',
    marginRight: '20px',
  },
});

import classNames from 'classnames';
import { isEmpty, compact } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';

import { LabelForFilter } from 'components/Filter/ChipFilterLabel';
import { ClearAllChipLabel } from 'components/Filter/ChipFilterLabel/ClearAll';
import { SearchContext } from 'components/context';

import { t } from 'utils';

import { Organizer as OrganizerStore } from 'stores';

type Props = {
  organizersStore: OrganizerStore;
};

// NOTE, Search
// Subscriber for search context
@observer
class FilterLabelToolbar extends React.Component<Props> {
  static defaultProps = {
    organizersStore: null as any,
  };

  static contextType = SearchContext;
  declare context: SearchContextProps | any;

  clearFilters = (): void => {
    const { search } = this.context;
    if (!search) {
      return;
    }

    search.service.reset();
  };

  deleteFilters = (...names: Array<string>) => {
    const { search } = this.context;
    if (!search) {
      return;
    }

    search.service.delete(() => {}, ...names);
  };

  _onFilterClick = (e: React.SyntheticEvent, name: string) => {
    const { search } = this.context;

    if (!search) {
      return;
    }

    search.onFilterShow(e, name);
  };

  _renderLabels = (): Array<React.ReactNode> => {
    const { search } = this.context;

    if (!search) {
      return [];
    }

    const { filterDescriptors } = search.store;
    const filters = search.store.values;
    const filterNames = Object.keys(filters);

    return filterNames.map((param) => {
      const descriptor = filterDescriptors[param];

      if (!search.store.isFEActive(param)) {
        return null;
      }

      const processedValue = descriptor.prettifyFilterValue(filters[param]);

      return (
        <LabelForFilter
          onClear={this.deleteFilters}
          onOpen={this._onFilterClick}
          key={param}
          name={param}
          className='filter-item'
          value={processedValue}
        />
      );
    });
  };

  _renderClearAll = (): React.ReactNode => {
    const { search } = this.context;
    if (!search) {
      return null;
    }

    const searchData = search.store.values;

    return <ClearAllChipLabel onClear={this.clearFilters} key={'clear_all'} values={searchData} className='filter-item' />;
  };

  _isFiltersEmpty = () => {
    const { search } = this.context;

    if (!search) {
      return true;
    }

    const searchData = search.store.values;
    return isEmpty(searchData);
  };

  render() {
    const labels = this._renderLabels();
    const isFiltersPresented = !!compact(labels).length;

    return (
      <div
        className={classNames('filters-toolbar', {
          empty: this._isFiltersEmpty(),
        })}
      >
        {[...labels]}
        {isFiltersPresented && this._renderClearAll()}
      </div>
    );
  }
}

export { FilterLabelToolbar };

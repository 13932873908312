import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { omit, cloneDeep } from 'lodash';
import debounce from 'lodash/debounce';
import * as React from 'react';
import { useCallback, useEffect } from 'react';

import { LoadingDots } from 'components/LoadingDots';

import { useAutocompleteStyles } from '../styles';
import { ListBox } from './ListBox';
import { Option } from './Option';

type Options = {
  id: number;
  key: number;
  label?: string | null;
};

type Props = {
  additional?: AutocompleteAdditionalProps & { liveUpdate?: Function; fullWidth?: boolean };
  view?: BaseViewProps;
  value: number;
  onBlur?: Function;
  afterSelect?: Function;
  loadMore: Function;
  onSearch: Function;
  hasMore: boolean;
  disableClearable?: boolean;
} & FieldBaseProps;

export function AutocompleteSelectInfinityLoad(props: Props) {
  const {
    disableClearable = false,
    name,
    afterSelect,
    label,
    value,
    error,
    onChange,
    additional,
    view = {},
    hasMore,
    loadMore,
    onSearch,
  } = props;
  const options = cloneDeep(additional?.options);
  const lastOption = options?.[options?.length - 1];
  const classes = useAutocompleteStyles();
  const inputClasses = additional?.classes && additional.classes();
  const { reservePlaceForHelperText, ...viewProps } = view;
  const helperText = error || (reservePlaceForHelperText && ' ');
  const withError = !!error;

  useEffect(() => {
    // We need to add the current value of the field to the options array,
    // because when loading options asynchronously from BE
    // the value of the current field may be present only on the following pages
    if (value && !options?.find((option) => option.key === value.key)) {
      options?.unshift(value);
    }
  }, [value, options]);

  const debouncedSearch = useCallback(
    debounce((val: string) => {
      onSearch && onSearch(val);
    }, 500),
    [],
  );

  const onTextInput = (e: React.SyntheticEvent<any, any>) => {
    const { value = '' } = e.currentTarget;
    debouncedSearch(value);
  };

  const onSelect =
    (name: string, onChange: AnyFunction) =>
    (e: React.ChangeEvent<object>, value: AnyObject): void => {
      onChange({ name, value }, e);
      if (afterSelect) {
        afterSelect(value);
      }
      if (additional?.liveUpdate) {
        additional.liveUpdate(name);
      }
    };

  const handleClose = () => {
    if (!value) {
      debouncedSearch('');
    }
  };

  return (
    <Autocomplete
      {...(additional || ({} as any))}
      {...omit(view, 'reservePlaceForHelperText')}
      id={name}
      ListboxComponent={ListBox}
      multiple={false}
      disableClearable={disableClearable}
      classes={classes}
      getOptionLabel={(option: Options) => option.label}
      isOptionEqualToValue={(option: Options) => option.key === value.key}
      value={value || null}
      loading={hasMore}
      loadingText={<LoadingDots />}
      onChange={onSelect(name, onChange)}
      onClose={handleClose}
      renderOption={(props, option: { label: string; key: number }, state) => {
        return hasMore && lastOption?.key === option.key ? (
          <Option
            props={props}
            key={option.key}
            label={option.label}
            lastOption
            onVisible={() => {
              loadMore && loadMore();
            }}
          />
        ) : (
          <Option props={props} key={option.key} label={option.label} />
        );
      }}
      renderInput={(params: any) => (
        <TextField
          fullWidth
          name={name}
          {...(params as any)}
          {...viewProps}
          onChange={onTextInput}
          error={withError}
          helperText={helperText}
          label={label}
          classes={inputClasses}
        />
      )}
    />
  );
}

import { observer, inject } from 'mobx-react';
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import { START_LIST } from 'src/constants';

import { AutocompleteSelect as Select } from 'components/Fields';

import { Country as CountryModel } from 'models';

import { Country as CountryStore, Errors as ErrorsStore, squadsStore } from 'stores';

type Props = {
  item: RacerType;
  field: string;
  value: any;
  errorsStore: ErrorsStore;
  distance: DistanceType;
  onChange: (name: string, value: any, callback: Function) => void;
  countriesStore: CountryStore;
  errors: ErrorsStore;
  saveRacer: (field: string) => void;
};

const action = `UPDATE_${START_LIST}`;

@inject('countriesStore', 'errorsStore')
@onClickOutside
@observer
export class AutocompleteSelect extends React.Component<Props> {
  id = 'startlist-select-field';
  closeTimeoutId: number | null = null;
  isCleared = false;

  save = () => {
    const { saveRacer, field } = this.props;
    saveRacer(field);
  };

  saveTimeout = () => {
    this.closeTimeoutId = setTimeout(() => this.save(), 100);
  };

  stopSaveTimeout = () => {
    this.closeTimeoutId && clearTimeout(this.closeTimeoutId);
  };

  handleClickOutside = (evt: React.FocusEvent) => {
    if (evt.target.className === 'MuiAutocomplete-listbox') {
      return;
    }

    if (!evt.target.id && this.isCleared) {
      this.onChange({ value: '', name: '' }, evt);
      this.save();
    }
  };

  onClose = () => {
    this.stopSaveTimeout();
    this.saveTimeout();
  };

  onChange = ({ value, name }: any, e: React.FocusEvent) => {
    const { onChange, field } = this.props;

    this.stopSaveTimeout();

    onChange(field, value, () => {
      this.saveTimeout();
    });
  };

  getLabels = (field: string): string => {
    const value: AnyObject = {
      country: CountryModel.nameLabel(),
    };

    return value[field];
  };

  convertForSelect = (options: Array<any> = [], label: string = 'value'): Array<any> => {
    return options.map((option) => ({
      key: `${this.props.field}_${option.id}`,
      value: option['id'],
      label: option[this.getLabels(label) || label] || '',
    }));
  };

  errors = () => {
    const { errorsStore } = this.props;
    const errors = errorsStore.errors.api[action];
    const validationErrors = errorsStore.errors.validation[action];

    if (validationErrors) {
      return validationErrors.formatErrors();
    }
    if (errors) {
      return errors.formatErrors();
    }
    return {};
  };

  squad_idOptions = () => {
    return squadsStore.valuesForDropdown();
  };

  onInputChange = (event, newInputValue, reason) => {
    this.isCleared = false;

    if (reason === 'clear') {
      this.isCleared = true;
    }
  };

  render() {
    const { field, value } = this.props;
    // eslint-disable-next-line
    const options = eval(`this.${field}Options()`);
    const errors = this.errors();

    const generateValue = () => {
      if (field === 'squad_id') return squadsStore.findFormatedValue(value);

      return { label: value, value, key: value };
    };

    return (
      <Select
        name={this.id}
        value={generateValue()}
        onChange={(e) => this.onChange({ value: e.value.value, name: '' }, e)}
        onInputChange={this.onInputChange}
        onClose={this.onClose}
        options={options}
        errors={errors[field]}
        label=''
      />
    );
  }
}

import { FormHelperText } from '@mui/material';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import { Icon } from 'components/Icon';

import { t } from 'utils';

import { ImageFieldWithCrop } from './ImageFieldWithCrop';

const classes = {
  active: ['active-container'],
  accept: ['accept-container'],
  reject: ['reject-container'],
  default: [''],
};

export const DropFileField: FC<{ onChange: AnyFunction; value: string | Blob; name: string; errors: string[]; disabled?: boolean }> = ({
  onChange,
  value,
  name,
  errors = [],
  disabled,
}) => {
  const [error, setError] = useState(' ');

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone({
    accept: ['image/jpeg', 'image/jpg', 'image/png'],
    noClick: true,
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      setError(' ');
      const SIZE_5_MB: Number = 5e6;
      const newValue = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )[0];

      if (newValue && newValue.size > SIZE_5_MB) {
        return setError(t.staticAsString('file.download.tooBig'));
      }

      onChange({ name: 'image', value: newValue });
    },
  });

  const chooseClassKey = (): string => {
    if (isDragAccept) return 'accept';
    if (isDragReject || error.trim()) return 'reject';
    if (isDragActive) return 'active';
    return 'default';
  };

  const haveValue = Boolean((value as Blob).size || (value as string).length);

  useEffect(() => {
    if (errors.length > 0) {
      setError(errors[0]);
    }
  }, [errors]);

  return (
    <div className='drop-file-field'>
      {haveValue ? (
        <div className='download-file'>
          <ImageFieldWithCrop shouldRenderFileName name={name} onChange={onChange} value={value} disabled={disabled} />
        </div>
      ) : (
        <div className='container'>
          <div {...getRootProps()} className={`dropzone-container ${classNames(...classes[chooseClassKey()])}`}>
            <input {...getInputProps()} />
            <Icon value='add-photo' />

            <span style={{ padding: '12px 0 8px 0' }}>
              {t.staticAsString('file.download.drop')}
              <button type='button' onClick={open} className='extra-download-button' disabled={disabled}>
                {t.staticAsString('file.download.browse')}
              </button>
            </span>

            <p>{t.staticAsString('file.download.formats')}</p>
          </div>

          <FormHelperText className='errors'>{error}</FormHelperText>
        </div>
      )}
    </div>
  );
};

import * as React from 'react';

import { Switch } from 'components/Fields';

import { t } from 'utils';

import progress from 'stores/progress';

type Props = {
  onChange: (field: string, value: boolean) => void;
  name: string;
  selected: boolean;
};

class Item extends React.Component<Props> {
  onChange = (e: React.ChangeEventHandler | any) => {
    const { name, onChange } = this.props;
    const value = e.target.checked;

    onChange(name, value);
  };

  tabOptionsWithTooltip = ['checkpoints', 'classes', 'disciplines', 'custom_fields', 'selfServices', 'medical_assistants', 'GPX'];

  render() {
    const { name, selected } = this.props;
    return (
      <React.Fragment>
        <Switch
          disabled={name === 'checkpoints' && progress.isLoading('MESSAGES_FETCHING')}
          name={`tab_options_${name}`}
          value={selected}
          label={t.staticAsString(`distances.steps.tabOptionsForm.${name}` as TranslationLockedKeys) as any}
          tooltipText={
            this.tabOptionsWithTooltip.includes(name)
              ? t.staticAsString(`distances.steps.tabOptionsFormTooltip.${name}` as TranslationLockedKeys)
              : undefined
          }
          onChange={this.onChange}
          className='muted'
          errors={[]}
        />
        <div className='separator' />
      </React.Fragment>
    );
  }
}

export { Item };

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import * as React from 'react';

import { addBtnStyles } from '../styles';

type Props = {
  onClick?: AnyFunction;
  label: string;
  center?: boolean;
};

export const AddBtn = (props: Props) => {
  const classes = addBtnStyles(props);

  return (
    <div className={classes.addNew} onClick={props.onClick}>
      <AddCircleOutlineIcon className={classes.icon} />
      <p className={classes.label}> {props.label}</p>
    </div>
  );
};

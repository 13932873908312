import moment from 'moment';

import { t } from 'utils';

import { errorsStore } from 'stores';

import { singleFields } from '../../../constants';

import { updateRacerResult, updateRacer } from '../../../actions';

import { generateStoreOptions } from '../../../stores';

import { processForUpdate, prepareForEdit, prepareForUpdate, isDiffZero } from '../../../tools';
import { GenerateFieldUpdate } from '../../../types';

const FIELD_UPDATE = {
  [singleFields.time_result]: function (model, context) {
    return async (value: number) => {
      const property = 'finish_time';
      const fieldId = model.generateFieldId('time_result');

      errorsStore.clear(fieldId);

      const storeOptions = generateStoreOptions(property, model);
      const updateOptions = { fieldId, property, storeOptions };

      const prevDiff = prepareForEdit.duration(model.value.start_time, model.value.finish_time);
      const [shouldBeUpdated, updateValue] = processForUpdate.duration(fieldId, value, prevDiff, model.value.start_time);

      if (!shouldBeUpdated) {
        return false;
      }

      if (value === 0) {
        errorsStore.add(fieldId, { fieldId: [t.staticAsString('results.errors.cannotBeZero')] });
        return false;
      }

      return await updateRacerResult(updateOptions, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields.start_time]: function (model, context) {
    // eslint-disable-next-line
    return async (value: nil | timeSpace.WorkDate) => {
      const property = 'start_time';
      const fieldId = model.generateFieldId(property);
      const storeOptions = generateStoreOptions(property, model);
      const updateOptions = { fieldId, property, storeOptions };

      const [shouldBeUpdated, updateValue] = processForUpdate.dateTime(fieldId, value, model.value.start_time);

      if (!shouldBeUpdated) {
        return false;
      }

      if (isDiffZero(value?.value!, moment(model.value.finish_time || NaN))) {
        errorsStore.add(fieldId, { fieldId: [t.staticAsString('results.errors.cannotBeZero')] });
        return false;
      }

      return await updateRacerResult(updateOptions, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields.finish_time]: function (model, context) {
    // eslint-disable-next-line
    return async (value: nil | timeSpace.WorkDate) => {
      const property = 'finish_time';
      const fieldId = model.generateFieldId(property);
      const storeOptions = generateStoreOptions(property, model);
      const updateOptions = { fieldId, property, storeOptions };

      const [shouldBeUpdated, updateValue] = processForUpdate.dateTime(fieldId, value, model.value.finish_time);

      if (!shouldBeUpdated) {
        return false;
      }

      if (isDiffZero(moment(model.value.start_time), value?.value)) {
        errorsStore.add(fieldId, { fieldId: [t.staticAsString('results.errors.cannotBeZero')] });
        return false;
      }

      return await updateRacerResult(updateOptions, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields.distance_logged]: function (model, context) {
    return async (value: nil | string) => {
      const property = 'distance_logged';
      const fieldId = model.generateFieldId(property);
      const storeOptions = generateStoreOptions(property, model);
      const updateOptions = { fieldId, property, storeOptions };

      const [shouldBeUpdated, updateValue] = processForUpdate.nonNil(fieldId, value, model.value.distance_logged);

      if (!shouldBeUpdated) {
        return false;
      }

      return await updateRacerResult(updateOptions, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields.distance_logged_at]: function (model, context) {
    return async (value: nil | timeSpace.WorkDate) => {
      const property = 'distance_logged_at';
      const fieldId = model.generateFieldId(property);
      const storeOptions = generateStoreOptions(property, model);
      const updateOptions = { fieldId, property, storeOptions };

      const [shouldBeUpdated, updateValue] = processForUpdate.plainNulable(
        fieldId,
        prepareForUpdate.date(value),
        model.value.distance_logged_at,
      );

      if (!shouldBeUpdated) {
        return false;
      }

      return await updateRacerResult(updateOptions, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields.activity_link]: function (model, context) {
    return async (value: nil | string) => {
      const property = 'activity_link';
      const fieldId = model.generateFieldId(property);
      const storeOptions = generateStoreOptions(property, model);
      const updateOptions = { fieldId, property, storeOptions };

      const [shouldBeUpdated, updateValue] = processForUpdate.plainNulable(fieldId, value, model.value.activity_link);

      if (!shouldBeUpdated) {
        return false;
      }

      return await updateRacerResult(updateOptions, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields['racer.bib_number']]: function (model, context) {
    const propertyName = 'bib_number';
    return async (value: any) => {
      return await updateRacer(propertyName, value, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields['racer.firstname']]: function (model, context) {
    const propertyName = 'firstname';
    return async (value: any) => {
      return await updateRacer(propertyName, value, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields['racer.lastname']]: function (model, context) {
    const propertyName = 'lastname';
    return async (value: any) => {
      return await updateRacer(propertyName, value, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields['racer.union']]: function (model, context) {
    const propertyName = 'union';
    return async (value: any) => {
      return await updateRacer(propertyName, value, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields['racer.gender']]: function (model, context) {
    const propertyName = 'gender';
    return async (value: nil | number) => {
      if (!model.racer) {
        return false;
      }

      const prevValue = model.racer.value.gender;
      const fieldId = model.racer.generateFieldId(propertyName);

      const [shouldBeUpdated, updateValue] = processForUpdate.dropDown(fieldId, value, prevValue);

      if (!shouldBeUpdated) {
        return false;
      }

      return await updateRacer(propertyName, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields['racer.birthday']]: function (model, context) {
    return async (value: nil | timeSpace.WorkDate) => {
      if (!model.racer) {
        return false;
      }

      const property = 'birthday';
      const fieldId = model.generateFieldId(property);

      const [shouldBeUpdated, updateValue] = processForUpdate.plainNulable(
        fieldId,
        prepareForUpdate.date(value),
        model.racer.value.birthday,
      );

      if (!shouldBeUpdated) {
        return false;
      }

      return await updateRacer(property, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields['racer.country_id']]: function (model, context) {
    const propertyName = 'country_id';
    return async (value: SelectOption | nil) => {
      if (!model.racer) {
        return false;
      }

      const changedValue = value?.value;
      const prevValue = model.racer.value.country_id;
      const fieldId = model.racer.generateFieldId(propertyName);

      const [shouldBeUpdated, updateValue] = processForUpdate.dropDown(fieldId, changedValue, prevValue);

      if (!shouldBeUpdated) {
        return false;
      }

      return await updateRacer(propertyName, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields['racer.nationality_id']]: function (model, context) {
    const propertyName = 'nationality_id';
    return async (value: SelectOption | nil) => {
      if (!model.racer) {
        return false;
      }

      const changedValue = value?.value;
      const prevValue = model.racer.value.nationality_id;
      const fieldId = model.racer.generateFieldId(propertyName);

      const [shouldBeUpdated, updateValue] = processForUpdate.dropDown(fieldId, changedValue, prevValue);

      if (!shouldBeUpdated) {
        return false;
      }

      return await updateRacer(propertyName, updateValue, model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields['racer.class_id']]: function (model, context) {
    const propertyName = 'class_id';
    return async (value: nil | number) => {
      if (!model.racer) {
        return false;
      }

      const prevValue = model.racer.value.class_id;
      const fieldId = model.racer.generateFieldId(propertyName);

      const [shouldBeUpdated, updateValue] = processForUpdate.dropDown(fieldId, value, prevValue);

      if (!shouldBeUpdated) {
        return false;
      }

      return await updateRacer(propertyName, Number(updateValue), model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields['racer.wave_id']]: function (model, context) {
    const propertyName = 'wave_id';
    return async (value: nil | number) => {
      if (!model.racer) {
        return false;
      }

      const prevValue = model.racer.value.wave_id;
      const fieldId = model.racer.generateFieldId(propertyName);

      const [shouldBeUpdated, updateValue] = processForUpdate.dropDown(fieldId, value, prevValue);

      if (!shouldBeUpdated) {
        return false;
      }

      return await updateRacer(propertyName, Number(updateValue), model, context);
    };
  } as GenerateFieldUpdate,

  [singleFields['racer.discipline_id']]: function (model, context) {
    const propertyName = 'discipline_id';
    return async (value: nil | number) => {
      if (!model.racer) {
        return false;
      }

      const prevValue = model.racer.value.discipline_id;
      const fieldId = model.racer.generateFieldId(propertyName);

      const [shouldBeUpdated, updateValue] = processForUpdate.dropDown(fieldId, value, prevValue);

      if (!shouldBeUpdated) {
        return false;
      }

      return await updateRacer(propertyName, Number(updateValue), model, context);
    };
  } as GenerateFieldUpdate,
};

export { FIELD_UPDATE };

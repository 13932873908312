import { SELECTED_BY_DEFAULT_REGISTRATION_FIELDS } from 'src/constants';

import { helperDistancesStore } from 'stores';

import { registrationFieldsTools } from '../utils';

const MAXIMUM_TEXT_DEFAULT = 255;

const racerValidation: AnyObject = {
  bib_number: {
    length: {
      maximum: 5,
    },
  },

  firstname: {
    presence: {
      allowEmpty: false,
    },
    length: {
      maximum: MAXIMUM_TEXT_DEFAULT,
    },
  },

  lastname: {
    presence: {
      allowEmpty: false,
    },
    length: {
      maximum: MAXIMUM_TEXT_DEFAULT,
    },
  },
  email: {
    presence: {
      allowEmpty: false,
    },
    email: true,
  },
  gender: {
    presence: {
      allowEmpty: false,
    },
  },
};

export const racerEditValidation = {
  ...racerValidation,
  bib_number: {
    ...racerValidation.bib_number,
    presence: {
      allowEmpty: false,
    },
    numericality: {
      greaterThan: 0,
      message: 'must be greater than 0',
    },
  },
};

export const getFieldValidation = (field: AnyObject) => {
  let { name, selected } = field;

  let required = false;

  if (name === 'gender' || name === 'country_id' || name === 'birthday' || name === 'nationality_id' || name === 'squad_id') {
    selected = false;
  }

  if (name === 'telephone' || name === 'emergency_phone') {
    return {
      phoneNumber: {
        allowEmpty: true,
      },
    };
  }

  if (SELECTED_BY_DEFAULT_REGISTRATION_FIELDS.includes(name) || field.required) {
    required = true;
  }

  return {
    ...(required
      ? {
          presence: {
            allowEmpty: false,
          },
        }
      : {}),
    ...(selected
      ? {
          length: {
            maximum: MAXIMUM_TEXT_DEFAULT,
          },
        }
      : {}),
    ...(name === 'email' ? { email: true } : {}),
  };
};

const classValidation = {
  class_id: {
    presence: {
      allowEmpty: false,
    },
  },
};

const disciplineId = {
  discipline_id: {
    presence: {
      allowEmpty: false,
    },
  },
};

const racer = (_object: RacerType) => {
  const distance = helperDistancesStore.selected;

  let validationData = racerValidation;

  if (Number(distance?.classes?.length) > 0) {
    validationData = { ...validationData, ...classValidation };
  }

  if (Number(distance?.disciplines?.length) > 0) {
    validationData = { ...validationData, ...disciplineId };
  }

  return validationData;
};

export { racer, racerValidation };

import { copyRacersService } from '../services';

import { organizersFilters } from '../stores';

export const loadOrganizers = async (
  params: {
    limit?: number;
    page?: number;
    search?: string;
    searchFields?: string;
  } = {},
) => {
  let { page: storePage, filters: storeFilters } = organizersFilters;

  const { search: storeSearch, searchFields: storeSearchFields } = storeFilters;

  let { limit = storeFilters.limit, page = storePage, search = '', searchFields = '' } = params;

  if (params.limit) {
    page = 1;
  }

  const queryParams = {
    ...storeFilters,
    page,
    limit,
    search: [storeSearch, search].filter(Boolean).join(';'),
    searchFields: [storeSearchFields, searchFields].filter(Boolean).join(';'),
  };

  const [status, { data, meta }] = await copyRacersService.loadOrganizers(queryParams);

  if (!status) return;

  organizersFilters.appendValues(data, page, {
    ...storeFilters,
    limit,
    search,
  });

  const paginationMeta = meta && meta.pagination;

  if (paginationMeta) {
    organizersFilters.addPaginationMeta(paginationMeta);
  }
};

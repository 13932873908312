import classNames from 'classnames';
import * as React from 'react';

import { TextField } from '../TextField';
import { timeMask, formatMaskToTime, formatTimeToMask } from '../core/mask';

type Props = {
  additional?: TextFieldAdditionalProps;
  view?: BaseViewProps;
} & FieldBaseProps;

function Time(props: Props) {
  const [localState, setState] = React.useState(formatTimeToMask(props.value));

  return (
    <TextField
      {...props}
      className={classNames('input-mask', props.className)}
      additional={{
        InputProps: { inputComponent: timeMask },
        ...(props.additional || {}),
      }}
      value={localState}
      onChange={({ name, value }, e) => {
        setState(value);
        props.onChange({ name, value: formatMaskToTime(value) }, e);
      }}
    />
  );
}

export { Time, Time as TimeField };

import { uniqBy } from 'lodash';
import { observable, action, makeObservable, override } from 'mobx';

import { Distance as DistanceModel } from 'models';

import { HelperDistances } from 'stores';

class Distances extends HelperDistances {
  defaultFilters = {
    withCount: 'racers;teams',
    limit: 10,
    orderBy: 'index',
    sortedBy: 'ASC',
  };

  declare values: Array<DistanceType>;

  @observable hasMore: boolean = true;

  constructor() {
    super();

    makeObservable(this);
    this.values = [];
  }

  @action
  setDistances(distances: Array<DistanceType>) {
    this.values = distances;
  }

  @action
  setPage(page: number) {
    this.page = page;
  }

  @action
  appendDistances(data: Array<DistanceType>): void {
    this.values = uniqBy([...this.values, ...data], 'id');
  }

  @action
  deleteDistanceById(id: number) {
    this.values = this.values.filter((distance) => distance.id !== id);
  }

  @override
  get modelValues(): Array<DistanceModel> {
    return this.values.map((distance) => new DistanceModel(distance));
  }

  @action
  setHasMore(hasMore: boolean = true): void {
    this.hasMore = hasMore;
  }

  @override
  clearData() {
    this.values = [];
    this.page = 1;
    this.hasMore = true;
  }
}

const distancesStore = new Distances();
export { distancesStore };

import { time } from 'utils';

import { beFormat } from './constants';

function formatDateToMask(value: nil | timeSpace.WorkDate): string {
  if (!value) {
    return '';
  }

  return value.compose(beFormat).val;
}

function formatMaskToDate(value: string): nil | timeSpace.WorkDate {
  if (value === '') {
    return null;
  }

  if (!time.isValid(value, beFormat)) {
    return undefined;
  }

  return time(value, beFormat).parse();
}

function pipeForMaxAndMin(
  value: nil | timeSpace.WorkDate,
  max: nil | moment.Moment | any,
  min: nil | moment.Moment | any,
): nil | timeSpace.WorkDate {
  if (!value) {
    return value;
  }

  if (max && value.val.isAfter(max)) {
    return time.workDate(max);
  }

  if (min && value.val.isBefore(min)) {
    return time.workDate(min);
  }

  return value;
}

export const dateMaskTools = {
  formatToMask: formatDateToMask,
  formatFromMask: formatMaskToDate,
  pipeForMaxAndMin,
};

import axios from 'axios';
import { UPDATE_SMART_LINK } from 'modules/SmartLinks/SmartLinkDetails/constants';
import { generatePath } from 'react-router-dom';

import { MAGIC_LINKS_URL, MAGIC_LINK_URL, ROUTES } from 'src/constants';
import { request, action, history, t } from 'src/utils';

import { toastStore } from 'stores';

import { CREATE_MAGIC_LINK_REQUEST } from '../constants';

class Links {
  @request({ action: CREATE_MAGIC_LINK_REQUEST })
  async createMagicLinkRequest(data: AnyObject): Promise<any> {
    return axios.post(MAGIC_LINKS_URL, data);
  }

  @action({ action: CREATE_MAGIC_LINK_REQUEST })
  async createMagicLink(data) {
    const [isOk] = await this.createMagicLinkRequest(data);
    return isOk;
  }

  @request({ action: UPDATE_SMART_LINK })
  updateSmartLinkRequest(token: string, data: AnyObject): any {
    const url = generatePath(MAGIC_LINK_URL, { token });
    return axios.patch(url, data);
  }

  @action({ action: UPDATE_SMART_LINK })
  async updateSmartLink(token: string, data: AnyObject): Promise<any> {
    const [isOk] = await this.updateSmartLinkRequest(token, data);
    return isOk;
  }
}

export const linksService = new Links();

import { Observer } from 'mobx-react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { Show } from 'src/components/Condition';
import { REFERRAL_PROGRAM_URL, ROUTES } from 'src/constants';

import { Icon } from 'components/Icon';

import { t } from 'utils';

import { translations } from 'actions';

import { localeStore, hostsStore, sessionStore } from 'stores';

import { LanguageSelectDialog } from './components/LanguageSelectDialog';
import { languageSelectStyles } from './components/styles';
import { formatLongWithShortLocale } from './utils/formatter';

type Props = {};

const Footer = ({}: Props) => {
  const currentYear = new Date().getFullYear();
  const [open, setOpen] = React.useState(false);
  const classes = languageSelectStyles();

  return (
    <Observer>
      {() => {
        const locale = localeStore.value;
        const isLogged = sessionStore.isLogged;

        return (
          <footer className='main-footer'>
            <LanguageSelectDialog open={open} current={locale} onClose={() => setOpen(false)} onSelect={translations.changeLanguage} />
            <div className='copyright'>
              <p>RACEID © {currentYear}</p>
              <Show if={isLogged}>
                <a className='faq' href='https://support.raceid.com' rel='noopener noreferrer' target='_blank'>
                  {t.staticAsString('footer.faq')}
                </a>
                <a className='faq' href={hostsStore.web} target='_blank'>
                  {t.staticAsString('footer.website')}
                </a>
                <a className='faq' href={REFERRAL_PROGRAM_URL} target='_blank'>
                  {t.staticAsString('footer.referral')}
                </a>
                <NavLink to={ROUTES.cooperationAgreement} target={'_blank'} className='faq'>
                  {t.staticAsString('registration.cooperationAgreement')}
                </NavLink>
                <NavLink to={ROUTES.processAgreement} target={'_blank'} className='faq'>
                  {t.staticAsString('registration.processAgreement')}
                </NavLink>
              </Show>
            </div>
            <div className={classes.button} onClick={() => setOpen(true)}>
              <Icon className={classes.icon} value='language' />
              <p>{formatLongWithShortLocale(locale)}</p>
            </div>
          </footer>
        );
      }}
    </Observer>
  );
};

export { Footer };

import * as React from 'react';
import styled from 'styled-components';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const ButtonsHolderWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

export const ButtonHolder: React.FC<Props> = (props) => {
  return (
    <ButtonsHolderWrap className={props.className}>
      <Buttons>{props.children}</Buttons>
    </ButtonsHolderWrap>
  );
};

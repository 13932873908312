import { generatePath } from 'react-router-dom';

import { ROUTES } from 'src/constants';
import { history, t } from 'src/utils';

import { toastStore } from 'stores';

import { copiedLinkStore } from '../stores';

export const handleCreateNew = () => {
  history.push(ROUTES.createCampaignLink);
};

export const handleEditLink = (token: string) => {
  const path = generatePath(ROUTES.campaignLinkEdit, { token: token });
  history.push(path);
};

export const handleCopyLink = (url: string) => {
  navigator.clipboard.writeText(url);
  copiedLinkStore.set(url);
  toastStore.show(`${t.staticAsString('campaignLinks.copiedLink')} ${url}`, null, 4000);
  setTimeout(function () {
    copiedLinkStore.value === url && copiedLinkStore.set('');
  }, 4000);
};

import GTranslateIcon from '@mui/icons-material/GTranslate';
import { Button, Theme, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

import { t } from 'utils';

type Props = {
  disabled?: boolean;
  loading?: boolean;
  tooltip?: string;
  onClick: Function;
  onMouseEnter: Function;
  onMouseLeave: Function;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    // Strange behaviour when child container overflows parent with width set to 100%
    width: '100%',
    height: 48,
    border: `1px solid ${theme.palette.grey1}`,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.grey1,
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'none',
    paddingRight: 16,
  },
}));

export const GoogleTranslateButton = ({ loading = false, disabled = false, tooltip = '', onClick, onMouseEnter, onMouseLeave }: Props) => {
  const classes = useStyles();
  const handleTouchStart = () => {
    onMouseEnter();
  };
  const handleTouchEnd = () => {
    onMouseLeave();
  };

  const handleClick = (e) => {
    onClick(e);
  };

  return (
    <div>
      <Tooltip title={tooltip}>
        <Button
          disabled={disabled || loading}
          className={classes.button}
          onClick={handleClick}
          onMouseEnter={handleTouchStart}
          onMouseLeave={handleTouchEnd}
          endIcon={loading ? <CircularProgress /> : <GTranslateIcon />}
        >
          {t('translations.withGoogle')}
        </Button>
      </Tooltip>
    </div>
  );
};

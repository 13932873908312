import { isEmpty } from 'lodash';

import { t } from 'utils';
import { validate } from 'utils/core';

import { form, errorsStore, toastStore } from 'stores';

import { INTEGRATION_FORM } from '../constants';

import { mapToCreate } from '../utils';

import { integrationService } from '../services';

import { modalStore, integrationStore } from '../stores';

import { getIntegrationValidation } from '../validations';
import { closeIntegrationForm } from './controls';

const onCreate = async (integration: AnyObject) => {
  const raceId = Number(modalStore.value?.raceId);
  const data = mapToCreate(integration);

  const status = await integrationService.createIntegration(raceId, data);

  if (status && !!modalStore.value?.successCallback) modalStore.value.successCallback();
  if (!status && !!modalStore.value?.failCallback) {
    modalStore.value.failCallback();
    return;
  }

  toastStore.show(t.staticAsString('integrations.form.successfullyCreated'));
  closeIntegrationForm();
};

const onEdit = async (integration: AnyObject) => {
  const raceId = Number(modalStore.value?.raceId);
  const id = Number(integrationStore.value?.id);
  const data = mapToCreate(integration);

  if (isEmpty(data)) {
    closeIntegrationForm();
    return;
  }

  const status = await integrationService.editIntegration(raceId, id, data);

  if (status && !!modalStore.value?.successCallback) modalStore.value.successCallback();
  if (!status && !!modalStore.value?.failCallback) {
    modalStore.value.failCallback();
    return;
  }

  toastStore.show(t.staticAsString('integrations.form.successfullyEdited'));
  closeIntegrationForm();
};

export const onSave = (): void => {
  errorsStore.clear(INTEGRATION_FORM);

  const integration: AnyObject = form.fetchWithoutObservable(INTEGRATION_FORM);
  const isValid = validate(INTEGRATION_FORM, integration, getIntegrationValidation() as any);

  if (!isValid) return;

  if (modalStore.value?.isEdit) {
    onEdit(integration);
    return;
  }

  onCreate(integration);
};

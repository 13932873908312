import { Button, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';
import Linkify from 'react-linkify';

import { t } from 'utils';

const classes = {
  popup: {
    title: ['popup-title'],
    body: ['popup-body'],
    text: ['popup-text'],
  },
};

type Props = {
  body: string;
  reason: string;
  closeDialog: React.MouseEventHandler;
};

const textDecorator = (href: string, text: string, key: string | number) => (
  <a href={href} key={key} target='_blank'>
    {text}
  </a>
);

export const Confirmation = ({ closeDialog, reason, body }: Props) => {
  let title = t.staticAsString('races.detail.rejectedRace');

  return (
    <React.Fragment>
      <DialogTitle id='alert-dialog-title' className={classNames(...classes.popup.title)}>
        {title}
      </DialogTitle>
      <DialogContent className={classNames(...classes.popup.body)}>
        <DialogContentText id='alert-dialog-description'>{body}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <DialogContent className={classNames(...classes.popup.text)}>
          <DialogContentText id='alert-dialog-text'>
            <Linkify componentDecorator={textDecorator}>{reason}</Linkify>
          </DialogContentText>
        </DialogContent>
      </DialogActions>

      <DialogActions>
        <Button onClick={closeDialog} color='primary' autoFocus>
          {t.staticAsString('shared.messagePopup.btnAction.ok')}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

import { hostsService } from 'services/hosts';

import { hostsStore } from 'stores';

async function load() {
  const data = await hostsService.fetch();

  if (data && data.web && data.admin) {
    hostsStore.setHosts(data.web, data.admin);
  }
}

export { load };

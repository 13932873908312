import { t } from 'utils/t';

import { sessionStore } from '../../../../stores';
import { DistanceOption, WaveOptions } from '../stores';

import { mapRacesForDropdown } from '../../../Synchronisation/RaceResults/utils';
import { CampaignLink } from '../../shared/types';

export const mapToOptionsRaces = (data: AnyObject[], name: string = 'name'): SelectOption[] => {
  return data.map((item) => ({
    key: item.id,
    value: item.id,
    label: item[name],
    organizer_id: item.organizer_id,
  }));
};

export const mapToOptionsWaves = (data: AnyObject[], name: string = 'name'): WaveOptions[] => {
  return data
    .filter((wave) => wave.is_visible)
    .map((item) => {
      const count = item.max_members - (item.srl_slots_count - item.srl_slots_used_count) - item.racers_count;
      return {
        key: item.id,
        value: item.id,
        label: item.max_members ? `${item[name]} (${count} ${t.staticAsString('shared.slots')})` : item[name],
        slots: item.max_members && item.max_members - item.racers_count,
      };
    });
};

export const mapToDistanceOption = (data: AnyObject[]): DistanceOption[] => {
  return data.map((item) => ({
    key: item.id,
    value: item.id,
    label: item.name,
    registration_starts_at: item.registration_starts_at,
    registration_ends_at: item.registration_ends_at,
  }));
};

export const mapFormToBE = (data: AnyObject): AnyObject => {
  const races: { id: string }[] = [];
  for (const key in data) {
    if (key.includes('races')) {
      races.push({ id: data[key].value });
    }
  }
  return {
    title: data.title,
    name: data.name,
    description: data.description,
    organizer_id: sessionStore.user?.user_id,
    races,
  };
};

export const mapBEToForm = async (data: CampaignLink): Promise<any> => {
  return {
    name: data.name,
    title: data.title,
    description: data.description,
    races: mapRacesForDropdown(data.races),
  };
};

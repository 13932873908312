import FormControlLabel from '@mui/material/FormControlLabel';
import MaterialRadio from '@mui/material/Radio';
import classNames from 'classnames';
import * as React from 'react';

type Props = {
  classes?: {
    [K in string]: string;
  };
  name: string;
  onChange: (e: React.ChangeEvent) => void;
  value: string;
  errors?: Array<string>;
  type?: string;
  id?: string;
  label?: string;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  selected: string | number;
};

const Radio = ({
  classes = {
    root: 'field-root',
    label: 'label',
  },

  name,
  onChange,
  value,
  errors = [],
  type = 'text',
  id = '',
  label = '',
  className,
  fullWidth = false,
  selected,
  disabled,
}: Props) => {
  return (
    <div className={classNames('radio-field', className)}>
      <FormControlLabel
        // FIXME: passing classes causes console errors, removing affects nothing, though.
        // classes={classes}
        disabled={disabled}
        control={
          <MaterialRadio
            checked={value === selected}
            className={classes.root}
            classes={{
              checked: classes.checked,
            }}
            name={name}
            value={value}
            onChange={onChange}
          />
        }
        label={label}
      />
    </div>
  );
};

export { Radio };

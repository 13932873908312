import { Button, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import classNames from 'classnames';
import { intersection, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { COUPON, BACKEND_DATE_FORMATE, HELPER_RACE, DISTANCES } from 'src/constants';

import { loadable, withProgressSpinner, withErrorClean } from 'hocs';

import { TextField, DatePicker, MultiSelect, AutocompleteSelect } from 'components/Fields';

import { validation, t } from 'utils';

import { helperRaceService, helperDistancesService } from 'services';

import { helperRacesStore, helperDistancesStore, couponsStore } from 'stores';
import { Errors as ErrorsStore } from 'stores';

import { withAllOptions, allOption, isSelectedAll } from '../../../utils';

import localCouponService from '../../../services/load';

import { couponMapper } from '../../../mapper';
import { createCoupon as createCouponConstraints } from '../../../validations';

type State = {
  coupon: {
    id: number;
    name: string;
    description: nil | string;
    code: string;
    race_id: nil | SelectOption;
    distances: Array<number>;
    date_from: nil | string;
    date_to: nil | string;
    discount_amount: string;
    discount_percentage: number;
    qty: string;
  };
  isAllDistancesSelected: boolean;
};

type Props = {
  errorsStore: ErrorsStore;
  onClose: () => void;
  onSubmit?: () => void;
  couponId: nil | number;
} & RouterProps;

const action = `PERSIST_${COUPON}`;
const AMOUNT = 0;
const PERCENTAGE = 1;
const VALIDATE_FIELDS: AnyObject = {
  qty: /^[0-9]+$/,
  code: /^\S*$/,
  discount_amount: /(^\d+\.?\d*)$/,
};

@withRouter
@loadable({ service: helperRaceService, cleanOnUnmout: false })
@withErrorClean(action)
@withProgressSpinner(`LOAD_${COUPON}`)
@withProgressSpinner(`LOAD_${DISTANCES}`)
@withProgressSpinner(`LOAD_${HELPER_RACE}S`)
@inject('errorsStore', 'helperRacesStore', 'helperDistancesStore', 'couponsStore')
@observer
class CouponForm extends Component<Props, State> {
  static defaultProps = {
    errorsStore: null as any,
  };

  state = {
    coupon: {
      id: 0,
      name: '',
      description: '',
      code: '',
      race_id: null,
      distances: [],
      date_from: null,
      date_to: null,
      discount_amount: '',
      discount_percentage: 0,
      qty: '',
    },

    isAllDistancesSelected: false,
  };

  @validation({ action, constrains: createCouponConstraints })
  validate() {
    return this.state.coupon;
  }

  componentDidMount() {
    if (this.props.couponId) {
      localCouponService.loadResource(`${this.props.couponId}`);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const coupon = couponsStore.selected;

    if (!isEmpty(helperRacesStore.values) && coupon && prevState.coupon.id === 0) {
      helperDistancesService.loadRaceDistances(coupon.race_id);
      this._setNewCoupon();
    }
  }

  componentWillUnmount() {
    couponsStore.removeSelectedValue();
  }

  onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const isValidated = await this.validate();

    if (!isValidated || !!this.errors().length) {
      return;
    }

    localCouponService.createOrUpdateResource(couponMapper.mapTo(this.state.coupon), () => this.props.onClose());
  };

  onChangeRegular = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: any = null;
    let name = '';

    switch (e.type) {
      case 'click':
        value = e.target.value;
        name = e.target.name;
        break;
      case 'change':
        value = e.currentTarget.value;
        name = e.currentTarget.name;
        break;
      default:
        value = e.currentTarget.value;
        name = e.currentTarget.name;
    }

    if (VALIDATE_FIELDS[name] && value.length && !value.match(VALIDATE_FIELDS[name])) {
      return;
    }

    this.setState({
      ...this.state,
      coupon: {
        ...this.state.coupon,
        [name]: value,
      },
    });
  };

  onChangeWithoutEvent = ({ name, value }: { name: string; value: any }) => {
    this.setState({
      ...this.state,
      coupon: {
        ...this.state.coupon,
        [name]: value,
      },
    });
  };

  errors = () => {
    const { errorsStore } = this.props;
    const errors = errorsStore.errors.api[action];
    const validationErrors = errorsStore.errors.validation[action];

    if (validationErrors) {
      return validationErrors.formatErrors();
    }

    if (errors) {
      return errors.formatErrors();
    }
    return {};
  };

  onChangeRaceId = ({ name, value }: { name: string; value?: { key: number } }) => {
    helperDistancesService.loadRaceDistances(Number(value?.key));

    this.setState({
      ...this.state,
      coupon: {
        ...this.state.coupon,
        [name]: value,
        distances: [],
      },
      isAllDistancesSelected: true,
    });
  };

  onChangeDistances = (e: React.ChangeEvent<any>) => {
    const selectedValues: Array<number> = e.target.value as any;
    const distancesIds = helperDistancesStore.values.map((value) => value.id);
    const newValues = intersection(selectedValues, distancesIds);

    if (isSelectedAll(selectedValues, distancesIds.length)) {
      return this.onSelectAllDistances();
    }

    this.setState({
      ...this.state,
      coupon: { ...this.state.coupon, distances: newValues },
      isAllDistancesSelected: false,
    });
  };

  onSelectAllDistances = () => {
    this.setState({
      coupon: { ...this.state.coupon, distances: [] },
      isAllDistancesSelected: true,
    });
  };

  defaultInputProps = (name: string): any => {
    return {
      name,
      value: (this.state.coupon as any)[name],
      onChange: name !== 'race_id' ? this.onChangeRegular : this.onChangeRaceId,
      errors: this.errors()[name],
      fullWidth: true,
    };
  };

  _currency = (): string => {
    const { race_id } = this.state.coupon;
    if (!race_id) {
      return '';
    }

    const race = helperRacesStore.findModelValue(race_id!);
    if (!race) {
      return '';
    }

    return `(${race.currency()})`;
  };

  _selectedDistances = () => (this.state.isAllDistancesSelected ? [allOption().value] : this.state.coupon.distances);

  _setNewCoupon = () => {
    const model = couponsStore.modelSelected;

    if (!model) {
      return;
    }

    const stateKeys = Object.keys(this.state.coupon);
    const newCoupon = stateKeys.reduce((acc, col) => {
      return { ...acc, [col]: model.value[col] };
    }, {});

    const distances = (model.value.distances || []).map((el) => el.id);
    const races = helperRacesStore.valuesForSelect();
    const race_id = races.find((race) => race.key === model.value.race_id);

    this.setState({
      ...this.state,
      coupon: {
        ...this.state.coupon,
        ...newCoupon,
        distances,
        race_id: race_id || undefined,
      },
      isAllDistancesSelected: !distances.length && model.isTypeAllDistances(),
    });
  };

  render() {
    const { coupon } = this.state;
    const { onClose } = this.props;
    const racesSelectOptions = helperRacesStore.valuesForSelect();
    const distancesSelectOptions = withAllOptions(helperDistancesStore.valuesForSelect());
    // eslint-disable-next-line
    couponsStore.selected;

    return (
      <div className='content main-coupon-form'>
        <h1>{t.staticAsString(`coupons.${coupon.id ? 'edit' : 'addNew'}` as TranslationLockedKeys)}</h1>
        <form className='coupon-form new-coupon' onSubmit={this.onSubmit}>
          <div className='form-content-wrapper'>
            <div className='form-fields-wrapper'>
              <TextField {...this.defaultInputProps('name')} label={`${t.staticAsString('coupons.new.name')}*`} />
              <TextField {...this.defaultInputProps('description')} label={t.staticAsString('coupons.new.description')} />
              <TextField {...this.defaultInputProps('code')} label={`${t.staticAsString('coupons.new.code')}*`} />
              <AutocompleteSelect
                {...this.defaultInputProps('race_id')}
                options={racesSelectOptions}
                label={`${t.staticAsString('coupons.new.race')}*`}
                disableClearable
              />
              <MultiSelect
                {...this.defaultInputProps('distances')}
                options={distancesSelectOptions}
                onChange={this.onChangeDistances}
                value={this._selectedDistances()}
                className='coupon-distances select-with-search'
                label={t.staticAsString('coupons.new.distances')}
                disabled={!coupon.race_id}
              />
              <div className='date-picker-group'>
                <div className='coupon-date-to'>
                  <DatePicker
                    {...this.defaultInputProps('date_from')}
                    label={t.staticAsString('coupons.new.date_from')}
                    onChange={this.onChangeWithoutEvent}
                    dateFormat={BACKEND_DATE_FORMATE}
                  />
                </div>
                <div className='coupon-date-from'>
                  <DatePicker
                    {...this.defaultInputProps('date_to')}
                    label={t.staticAsString('coupons.new.date_to')}
                    onChange={this.onChangeWithoutEvent}
                    dateFormat={BACKEND_DATE_FORMATE}
                  />
                </div>
              </div>
              <RadioGroup className='discount-type'>
                <h2>{t.staticAsString('coupons.new.discount_type')}</h2>
                <FormControlLabel
                  label={`${t.staticAsString('coupons.new.amount')}${this._currency()}`}
                  value='Amount'
                  className={classNames({
                    checked: +coupon.discount_percentage === 0,
                  })}
                  control={
                    <Radio
                      checked={parseInt(coupon.discount_percentage.toString(), 10) === AMOUNT}
                      onChange={this.onChangeRegular}
                      value='0'
                      className='radio-distance-type'
                      name='discount_percentage'
                      color='primary'
                      aria-label={`${t.staticAsString('coupons.new.amount')}${this._currency()}`}
                    />
                  }
                />
                <FormControlLabel
                  label={`${t.staticAsString('coupons.new.perc')}`}
                  value='Percent'
                  className={classNames({
                    checked: +coupon.discount_percentage === 1,
                  })}
                  control={
                    <Radio
                      checked={parseInt(coupon.discount_percentage.toString(), 10) === PERCENTAGE}
                      onChange={this.onChangeRegular}
                      value='1'
                      className='radio-distance-type'
                      name='discount_percentage'
                      color='primary'
                      aria-label={`${t.staticAsString('coupons.new.perc')}`}
                    />
                  }
                />
              </RadioGroup>
              <TextField {...this.defaultInputProps('discount_amount')} label={`${t.staticAsString('coupons.new.discount_amount')}*`} />
              <TextField {...this.defaultInputProps('qty')} label={`${t.staticAsString('coupons.new.qty')}*`} />
              <div className='btn-group coupon-btn '>
                <Button className='cancel' onClick={() => onClose()}>
                  {t.staticAsString('races.new.cancel')}
                </Button>
                <Button className='submit' onClick={(e) => this.onSubmit(e)}>
                  {t.staticAsString('coupons.new.submit')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export { CouponForm };

import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { ReactElement } from 'react';

import {
  StyledTableHead,
  StyledTableRow,
  StyledTableHeaderCell,
  StyledTableCell,
  paymentOverviewTableStyles,
} from '../PaymentOverview/styles';
import TableSummary from './TableSummary';
import { getStyle } from './utils';

type Column = {
  title: string;
  accessor: string;
  fn?: (a: any) => any;
  align: 'left' | 'right' | 'center';
  width?: string;
};

type Props = {
  withTotal?: boolean;
  skipSummary?: boolean;
  summaryText: string;
  tableName: 'PaymentOverview' | 'Invoice' | 'VatReport' | 'VatReportPlatformInvoice';
  totalSum?: number | string;
  totalSumText?: string;
  emptyMessage?: string;
  isRefund?: boolean;
  columns: Column[];
  data: any[];
};

export default function TableWithSummary({
  emptyMessage,
  columns,
  data,
  withTotal = false,
  skipSummary = false,
  summaryText,
  totalSum = 0,
  totalSumText = '',
  tableName,
  isRefund = false,
}: Props): ReactElement {
  const styles = paymentOverviewTableStyles();
  return (
    <section className={styles.tableWrapper}>
      <Table>
        <StyledTableHead>
          <StyledTableRow>
            {columns.map((styles, colIdx) => (
              <StyledTableHeaderCell align={columns[colIdx].align} key={styles.title} width={styles.width}>
                {styles.title}
              </StyledTableHeaderCell>
            ))}
          </StyledTableRow>
        </StyledTableHead>
        <TableBody>
          {data.length === 0 && emptyMessage && (
            <Typography variant='h6' style={{ padding: '16px' }}>
              {emptyMessage}
            </Typography>
          )}
          {data.map((entry, rowIdx) => {
            return (
              <StyledTableRow key={rowIdx}>
                {columns.map((col, colIdx) => {
                  return (
                    <StyledTableCell align={col.align} style={getStyle(data, columns, rowIdx, colIdx)} key={col.accessor + colIdx}>
                      {(col.fn !== undefined ? col.fn(entry) : entry[col.accessor]) ?? ''}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
            );
          })}
          {!skipSummary && data.length > 0 && (
            <TableSummary isRefund={isRefund} tableName={tableName} data={data} columns={columns} summaryText={summaryText} />
          )}
        </TableBody>
      </Table>
      {withTotal && (
        <section className={styles.totalWrapper}>
          <Typography className={styles.totalSum}>{totalSumText}</Typography>
          <Typography className={styles.totalSum}>{totalSum}</Typography>
        </section>
      )}
    </section>
  );
}

import { Dialog } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';

import { Show } from 'components/Condition';

import { Confirmation, RejectForm } from './dialogViews';

type Props = {
  open: boolean;
  body: string;
  reason: string;
  isTooltip: boolean;
  onSubmit: Function;
  closeDialog: React.MouseEventHandler;
};

const classes = {
  popup: {
    message: ['message-popup'],
    actions: ['message-actions'],
  },
};

const RejectionDialog = (props: Props) => {
  let { body, onSubmit, open, reason, closeDialog, isTooltip } = props;

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth={true}
      maxWidth={'sm'}
      className={classNames(...classes.popup.message)}
    >
      <Show if={!isTooltip}>
        <RejectForm onSubmit={onSubmit} closeDialog={closeDialog} />
      </Show>
      <Show if={isTooltip}>
        <Confirmation body={body} reason={reason} closeDialog={closeDialog} />
      </Show>
    </Dialog>
  );
};

export { RejectionDialog };

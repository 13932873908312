import { isEmpty } from 'lodash';

import { handleExport } from 'utils';

import { exportJobService } from 'services';

import { distanceStore, resultsStubStore } from '../stores';

async function onExportResults() {
  const { selected } = distanceStore;

  if (!selected) {
    return;
  }

  const distanceId = selected.id;

  const [status, response] = await exportJobService.exportResults(distanceId, params());

  if (!status) {
    return;
  }

  const { id } = response.data.data;

  const total = 4500;

  // 1500 orders loaded per ~1 second
  const estimatedLoadingTime = total / 1500;

  handleExport({
    id,
    estimatedLoadingTime,
    fileName: 'Results.xlsx',
    onRetry: () => onExportResults(),
  });
}

function params(): FiltersType {
  const activeStore = resultsStubStore.retrieveActiveStore();
  const { search, searchFields, sortedBy, searchJoin, orderBy } = activeStore.params;

  const params = { sortedBy, searchJoin, orderBy };

  if (isEmpty(search)) {
    return params;
  }

  return { ...params, search, searchFields };
}

export { onExportResults };

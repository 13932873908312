import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

import { loadCountryService } from 'services';

import { progressStore } from 'stores';

import { INITIALIZE } from '../constants';

import { loadService } from '../services';

import { paymentsStore } from '../stores';

export const mount = async (): Promise<void> => {
  progressStore.log(INITIALIZE, 'progress');

  await Promise.all([loadCountryService.loadResources(), loadService.load(paymentsStore.params)]);

  progressStore.log(INITIALIZE, 'completed');
};

export const unmount = (): void => {
  const match = matchPath(history.location.pathname, ROUTES.paymentRoute);
  if (!match) {
    paymentsStore.clearAll();
  }
};

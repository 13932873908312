import { InfoOutlined } from '@mui/icons-material';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC } from 'react';

type Props = {
  text: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    alignContent: 'center',
    marginBottom: '10px',
    padding: '0 12px',
  },
  icon: {
    color: theme.palette.yellow1,
    marginRight: 9,
    fontSize: 18,
    alignSelf: 'flex-end',
  },

  text: {
    fontSize: 12,
    color: theme.palette.grey6,
    letterSpacing: 0,
  },
}));

export const DisabledHelperText: FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <InfoOutlined className={classes.icon} />
      <Typography className={classes.text} component='p'>
        {props.text}
      </Typography>
    </Box>
  );
};

import { cloneDeep } from 'lodash';

import { checkPointStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { CheckPoint } from 'src/modules/Distances/components/shared/Steps/GPX/types';

export const removeCheckPointCoordinates = (id: number) => {
  const checkPoints = cloneDeep(checkPointStore.value) as Array<CheckPoint>;
  const checkPoint = checkPoints.filter((item) => item.id === id);

  if (checkPoint && checkPoint.length > 0) {
    checkPoint[0].coordinate.lat = 0;
    checkPoint[0].coordinate.lng = 0;
    checkPoint[0].type = 'set';
    checkPointStore.set(checkPoints);
  }
};

import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { Members } from './Members';
import { Racers } from './Racers';
import { Teams } from './Teams';

class StartLists extends React.Component<unknown> {
  static defaultProps = {
    racesStore: null as any,
  };

  render() {
    return (
      <div className='details-start-lists'>
        <Switch>
          <Route path={ROUTES.distancesRacersRoute} component={Racers} />
          <Route path={ROUTES.distancesTeamsRoute} component={Teams} />
          <Route path={ROUTES.distancesMembersRoute} component={Members} />
        </Switch>
      </div>
    );
  }
}

export { StartLists };

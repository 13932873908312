import { Chip } from '@mui/material';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';

import { t } from 'utils';

import { classes } from './index';

type Props = {
  onClear: () => void;
  className: string;
  values: any;
};

const ClearAllChipLabel = ({ onClear, className = '', values }: Props) => {
  if (isEmpty(values)) {
    return null;
  }

  return (
    <Chip
      className={classNames(className, 'chip-card', 'clear-all-chip')}
      classes={classes}
      onClick={() => onClear()}
      label={t.staticAsString('shared.resetAll')}
    />
  );
};

export { ClearAllChipLabel };

import { transaction } from 'mobx';

import { form, errorsStore } from 'stores';

import { NEW_RESULT_FORM_ID } from '../../constants';

import { newResultModal } from '../../stores';

function open() {
  transaction(() => {
    errorsStore.clear(NEW_RESULT_FORM_ID);
    form.clean(NEW_RESULT_FORM_ID);
    newResultModal.on();
  });
}

function close() {
  newResultModal.off();
}

export { close, open };

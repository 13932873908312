import Typography from '@mui/material/Typography';
import { ERRORS_PREFIXES } from 'modules/Distances/constants';
import { clearNamespaceErrors } from 'modules/Distances/utils/errors';
import * as React from 'react';
import shortid from 'shortid';

import { DISTANCE_STEPS, PERSIST_DISTANCE as action } from 'src/constants';

import { Icon } from 'components/Icon';

import { t } from 'utils';

import { State as OriginalFormState } from '../../../../shared/stateHelpers';
import { Item } from './Item';

type Props = {
  formData: OriginalFormState;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
    isDirty?: boolean,
    value?: AnyObject,
    isNeedLiveUpdate?: boolean,
  ) => void;

  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

const stepAction = `${action}_${DISTANCE_STEPS.disciplines}`;

const skipRule: AnyFunction = (object: DisciplineType) => !!object._delete;

class Disciplines extends React.Component<Props> {
  componentDidMount() {
    this._addDefaultValues();
  }

  onChange = (values: Array<DisciplineType>, callback: Function = () => {}, isNeedLiveUpdate = true) => {
    const { onChange, liveUpdate } = this.props;
    isNeedLiveUpdate && liveUpdate({ disciplines: values }, true);
    onChange(
      {
        disciplines: values,
      },
      '',
      () => {
        callback();
      },
      true,
      { disciplines: values },
      isNeedLiveUpdate,
    );
  };

  onAddNew = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { disciplines } = this.props.formData;
    const values = [...disciplines, getDistanceDiscipline()];

    this.onChange(
      values,
      () => {
        clearNamespaceErrors(ERRORS_PREFIXES.disciplines);
      },
      false,
    );
  };

  onRemove = (id: number | string) => {
    const { disciplines } = this.props.formData;
    const values = disciplines
      .filter((discipline) => !discipline._delete)
      .reduce((acc: any, value: AnyObject | any) => {
        if (value.id === id) {
          return [
            ...acc,
            {
              ...value,
              _delete: true,
            },
          ];
        } else if (value.__id === id) {
          return acc;
        } else {
          return [...acc, value];
        }
      }, []);
    this.onChange(values, () => {
      clearNamespaceErrors(ERRORS_PREFIXES.disciplines);
    });
  };

  onChangeDistanceDisciplines = (id: number | string, changedDistanceDiscipline: DisciplineType, callback: Function = () => {}) => {
    const { disciplines } = this.props.formData;

    const newData = disciplines.map((distanceDiscipline) =>
      distanceDiscipline.id === id || distanceDiscipline.__id === id ? changedDistanceDiscipline : distanceDiscipline,
    );

    this.onChange(newData, callback);
  };

  _addDefaultValues = () => {
    const { disciplines, distance } = this.props.formData;
    const { onChange } = this.props;
    const minValuesForRender = Number(distance.max_members_quantity);

    if (minValuesForRender && distance.type === 'team') {
      const list = Array.from(Array(minValuesForRender), () => getDistanceDiscipline(true));
      if (!disciplines.filter((el) => !el._delete).length) {
        this.onChange(list);
      }
    } else {
      if (!disciplines.filter((el) => !el._delete).length) {
        onChange({ disciplines: [getDistanceDiscipline(true)] }, '', () => {});
      }
    }

    if (disciplines.length && minValuesForRender && distance.type === 'team') {
      const diffValuesForRender = minValuesForRender - disciplines.length;
      if (diffValuesForRender > 0) {
        const addedValues = Array.from(Array(diffValuesForRender), () => getDistanceDiscipline(true));
        this.onChange([...disciplines, ...addedValues]);
      }
    }
  };

  _renderDistanceDisciplines = (): React.ReactNode => {
    const { formData } = this.props;
    const { disciplines } = formData;

    const minValuesForRender = formData.distance.max_members_quantity;
    const isTeam = formData.distance.type === 'team';
    const valuesForRender = filterDeleted(disciplines);

    return valuesForRender.reduce((acc: any, distanceDiscipline: any, index: number) => {
      const separator = acc.length ? [<li className='separator' key={`separator-${index}`} />] : [];
      return [
        ...acc,
        ...separator,
        <Item
          key={distanceDiscipline.id || distanceDiscipline.__id}
          index={index}
          minValuesForRender={minValuesForRender && isTeam ? minValuesForRender : 0}
          value={distanceDiscipline}
          onChange={this.onChangeDistanceDisciplines}
          onRemove={this.onRemove}
        />,
      ];
    }, []);
  };

  render() {
    return (
      <div className='distance-class-fields-form form-content-wrapper'>
        <div className='add-new' onClick={this.onAddNew}>
          <Icon value='add-small' className='icon' />
          <Typography className='label'>{t.staticAsString('distances.steps.disciplinesForm.add')}</Typography>
        </div>
        <ul className='distance-classes-list'>{this._renderDistanceDisciplines()}</ul>
        {this.props.controls}
      </div>
    );
  }
}

const getDistanceDiscipline = (isFirst: boolean = false): DisciplineType => {
  return {
    __id: shortid(),
    title: '',
    _new: isFirst,
  };
};

const filterDeleted = (values: Array<DisciplineType>): Array<DisciplineType> => {
  return values.filter((el) => !el._delete);
};

const normalizeDataForBackend = (
  values: DisciplineType[],
): Array<
  | {
      _delete?: boolean;
      id?: number;
    }
  | DisciplineType
> => {
  return values.map((el) => {
    const copy: any = { ...el };
    delete copy.__id;
    delete copy._new;

    if (!!copy._delete) {
      return { id: copy.id, _delete: true };
    }

    return { ...copy, _delete: false };
  });
};

export { Disciplines, getDistanceDiscipline, filterDeleted, normalizeDataForBackend };

import { errorsStore } from 'stores';

import { COUPONS_FORM_ID } from '../constants';

import { clearAllCouponFormStores, couponFormDisposers, couponFormModalStore } from '../stores';

import { mountCouponForm } from './mount.action';

export const openCouponForm = (couponId: number, isShowConfirm: boolean, successCallback: Function = () => {}) => {
  couponFormModalStore.set({
    open: true,
    isEdit: Boolean(couponId),
    couponId,
    successCallback,
    coupon: {},
    isShowConfirm: isShowConfirm,
  });
  mountCouponForm(couponId);
};

export const closeCouponForm = () => {
  clearAllCouponFormStores();
  couponFormDisposers.disposeAll();
  errorsStore.clear(COUPONS_FORM_ID);
};

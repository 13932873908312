import { cloneDeep, isEqual, isEmpty } from 'lodash';

import { integrationStore } from '../stores';

import { IntegrationType, IntegrationEditType } from '../types';

export const mapToEdit = (integration: IntegrationType): IntegrationEditType => {
  const copy = cloneDeep(integration);
  const defaultIntegration = integrationStore.value || {};

  const mappedData = Object.keys(copy).reduce((result, key) => {
    const isEdited = !compareValue(key, copy[key], defaultIntegration[key]);
    if (isEdited || key === 'method') return result;

    return {
      ...result,
      [key]: copy[key],
    };
  }, {});

  if (isEmpty(mappedData)) return {};

  return {
    ...(mappedData as any),
    method: 'PUT',
  };
};

const compareValue = (key: string, currentValue: any, defaultValue: any) => {
  switch (key) {
    case 'distances':
      return isEqual(currentValue, defaultValue);

    default:
      if (!currentValue && !defaultValue) return true;
      return currentValue === defaultValue;
  }
};

import { observer } from 'mobx-react';
import * as React from 'react';

import { SelectFilterWithSearch } from 'components/Filter/DefaultFilters';

import { gender as genderUtil } from 'utils';

type Props = {
  value: nil | string | number;
} & FilterProps;

@observer
class GendersComponent extends React.Component<Props> {
  render() {
    const { props } = this;
    const options = genderUtil.options();

    return <SelectFilterWithSearch {...props} value={+props.value} options={options} />;
  }
}

export const gender: FilterListDescriptor = {
  filter: {
    persistance: {
      handler: 'like',

      calcValue: function (name, value) {
        return value;
      },

      prettify: function (name, value) {
        return genderUtil.format(value);
      },
    },
  },
  frontend: {
    type: 'custom',
    component: GendersComponent,
  },
};

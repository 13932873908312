import { StyledEngineProvider } from '@mui/material';
import 'cropperjs/dist/cropper.css';
import { Provider } from 'mobx-react';
import { CouponsFormDialog } from 'modules/CouponsForm';
import { IntegrationForm } from 'modules/IntegrationForm';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/nb';
import 'moment/locale/sv';
import * as React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { addLocaleData } from 'react-intl';
import da from 'react-intl/locale-data/da';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fi from 'react-intl/locale-data/fi';
import fr from 'react-intl/locale-data/fr';
import no from 'react-intl/locale-data/no';
import sv from 'react-intl/locale-data/sv';
import { Router } from 'react-router-dom';
import { Routes } from 'routes';

import 'src/assets/scss/main.scss';
import { INIT_TRANSLATIONS } from 'src/constants';

import { withProgressSpinner } from 'hocs';

import { IntlProvider } from 'components/Intl';
import { LoadingBar } from 'components/LoadingBar';
import { ThemeProvider } from 'components/Theme';
import { Toast } from 'components/Toast';

import { history } from 'utils';

import { translations } from 'actions';

import * as stores from 'stores';

import GlobalConfirmationModal from './components/ConfirmationModal/GlobalConfirmationModal';
import { unregister } from './registerServiceWorker';

addLocaleData([...en, ...sv, ...fr, ...es, ...fi, ...no]);
translations.init();

@withProgressSpinner(INIT_TRANSLATIONS)
class App extends React.Component<{}> {
  render() {
    return (
      <Provider {...stores}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <IntlProvider>
              <React.Fragment>
                <Toast />
                <LoadingBar />
                <Router history={history}>
                  <Routes />
                </Router>
                {/* Modals */}
                <CouponsFormDialog />
                <IntegrationForm />
                <GlobalConfirmationModal />
              </React.Fragment>
            </IntlProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    );
  }
}

unregister();
export { App };

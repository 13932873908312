import React from 'react';
import shortid from 'shortid';

import { ROUTES } from 'src/constants';

import { NavToolbar } from 'components/NavToolbar';

import { t } from 'utils';

type Props = {
  items: Array<{
    label: string;
    path?: string;
    key: string;
  }>;
};

function Breadcrumbs({ items }: Props) {
  const breadCrumbs = [{ key: shortid(), label: t.staticAsString('payments.list.header') as any, path: ROUTES.paymentsRoute }, ...items];
  return (
    <div className='toolbar'>
      <NavToolbar items={breadCrumbs} />
    </div>
  );
}

export { Breadcrumbs };

import { isArray } from 'lodash';

import { filterFormatValue } from '../filterLabelFormatters';

export const numberRange: FilterDescriptor = {
  persistance: {
    calcHandler: function (name, value) {
      if (!isArray(value)) {
        return;
      }
      const [start, end] = value;

      const fromPresented = !!start;
      const toPresented = !!end;

      if (fromPresented && !toPresented) {
        return '>=';
      }

      if (toPresented && !fromPresented) {
        return '<=';
      }

      return 'between';
    },

    calcValue: function (name, value) {
      if (!isArray(value)) {
        return;
      }
      const [start, end] = value;

      const fromPresented = !!start;
      const toPresented = !!end;

      if (fromPresented && !toPresented) {
        return start;
      }

      if (toPresented && !fromPresented) {
        return end;
      }

      return value;
    },

    prettify: function (name, value) {
      return filterFormatValue(value);
    },
  },
};

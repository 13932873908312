import { capitalize } from 'lodash';

import { formatter } from 'utils';

import { OrderItemType } from '../constants';

import { OrderDetailsType, PaymentDetailsType } from '../types';

class OrderDetails {
  value: OrderDetailsType;
  payment: PaymentDetailsType;

  constructor(value: OrderDetailsType, payment: PaymentDetailsType) {
    this.value = value;
    this.payment = payment;
  }

  payedItems() {
    const { items } = this.value;
    return items.filter(
      (item) =>
        [OrderItemType.custom_field, OrderItemType.transfer_registration, OrderItemType.service_fee].includes(item.type) &&
        item.original_price,
    );
  }

  refundProtect() {
    const { refund_protect_used, items } = this.value;
    const refund_protect_amount = items.find((item) => item.type === OrderItemType.refund_protect)?.price || 0;

    return { refund_protect_used, refund_protect_amount: +(refund_protect_amount / 100).toFixed(2) };
  }

  currency() {
    const { currency } = this.value;
    return currency || '';
  }

  totalPrice(): number {
    const { total } = this.value;

    return +(total / 100).toFixed(2);
  }

  distanceOriginalPrice(): number | undefined {
    const { items } = this.value;

    const originalPrice = items.find((item) => item.type === OrderItemType.distance)?.original_price;

    return originalPrice ? +(originalPrice / 100).toFixed(2) : 0;
  }

  distanceDiscountAmount(): number | undefined {
    const { items } = this.value;

    const discountAmount = items
      .filter((item) => item.type === OrderItemType.distance)
      .reduce((acc, item) => acc + item.discount_amount, 0);

    return formatter.toFrontEndPrice(discountAmount);
  }

  getVat(): string {
    const { vat } = this.value;

    if (!vat) {
      return '';
    }

    return `${+(vat / 100).toFixed(2)}%`;
  }

  discountedAmount(): number {
    const { discount } = this.value;

    return +(discount / 100).toFixed(2);
  }

  customerFullName(): string {
    const { customer } = this.value;

    if (!customer) {
      return '';
    }

    return `${customer.firstname} ${customer.lastname}`;
  }

  orderId(): string {
    const { id } = this.value;

    if (!id) {
      return '';
    }

    return `${id}`;
  }

  distanceName(): string {
    const { items } = this.value;

    const distanceTitle = items.find((item) => item.type === OrderItemType.distance)?.title;

    return distanceTitle ? `${distanceTitle}` : '';
  }

  racersCounter(): string {
    const { items } = this.value;
    const racersCount = items.filter((item) => item.type === OrderItemType.distance).length;

    return racersCount === 0 ? '' : `${racersCount}`;
  }

  userFullName(): string {
    const {
      customer: { firstname, lastname },
    } = this.value;

    if (!firstname && !lastname) {
      return '';
    }

    return `${firstname} ${lastname}`;
  }

  paymentMethod(): string {
    const paymentMethodType = this.payment?.method_details?.type;

    if (!paymentMethodType) {
      return '';
    }

    return `${capitalize(paymentMethodType)} ${capitalize(this.payment?.method_details?.[paymentMethodType]?.brand)}`;
  }

  isTransferRegistration(): boolean {
    const { items } = this.value;
    return Boolean(items.find((item) => item.type === OrderItemType.transfer_registration));
  }
}

export { OrderDetails };

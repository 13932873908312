import { observer } from 'mobx-react';
import React from 'react';

import { Body } from '../../components';

import { raceDetailsStore } from '../../stores';

type OriginalProps = {};

type Props = OriginalProps & HOC.withIntl;

@observer
class BodyContainerImpl extends React.Component<Props> {
  render() {
    const raceDetails = raceDetailsStore.raceDetails;

    if (!raceDetails) {
      return null;
    }

    const raceUrl = raceDetails.value.race_url || '';
    const description = raceDetails.value.description || '';
    const contactEmails = raceDetails.contactEmails() || '';

    return <Body raceUrl={raceUrl} description={description} contactEmails={contactEmails} />;
  }
}

export const BodyContainer = BodyContainerImpl as unknown as React.ComponentType<OriginalProps>;

import { t } from 'utils';

import { convertFieldIdToCol } from '../tools';

function updateMessage(type: 'racer' | 'team', fieldId: string, isSplit: boolean = false) {
  const col = convertFieldIdToCol(fieldId);
  const entityMessage = t.staticAsString(`shared.${type}` as TranslationLockedKeys) as any;
  const columnMessage = (t.staticAsString(`results.fields.${col}` as TranslationLockedKeys) as any).toLowerCase();
  const splitMessage = isSplit ? ' ' + (t.staticAsString('shared.split') as any).toLowerCase() + ' ' : ' ';
  const updateMessage = (t.staticAsString('shared.updated') as any).toLowerCase();

  return `${entityMessage}${splitMessage}${columnMessage} ${updateMessage}`;
}

export { updateMessage };

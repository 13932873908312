import { computed, makeObservable } from 'mobx';
import shortid from 'shortid';

import { Country as CountryModel } from 'models';

import { Loadable } from './loadable';

// id of Norway and Sweeden - countries to be in top of country list
const PROMOTE_COUNTRIES = [215, 166];

class Country extends Loadable<CountryType, FiltersType> {
  declare filters: FiltersType;

  constructor() {
    super();
    makeObservable(this);
    this.filters = {
      limit: 250,
      orderBy: 'name',
      sortedBy: 'desc',
    };
  }

  @computed
  get modelValues(): Array<CountryModel> {
    return this.values.map((value) => new CountryModel(value));
    // TODO, fix
  }

  findModelValue(id: number): nil | CountryModel {
    return this.modelValues.find((el) => el.value.id === id);
  }

  valuesForCountryDropdown(): Array<{
    key: string;
    value: number;
    label: string;
  }> {
    const promoteCountries = this.modelValues
      .filter((item) => PROMOTE_COUNTRIES.indexOf(item.value.id) != -1)
      .map((country) => ({
        key: shortid(),
        value: country.value.id,
        label: country.name(),
      }));

    const restCountries = this.modelValues
      .filter((country) => !PROMOTE_COUNTRIES.includes(country.value.id))
      .map((country) => ({
        key: shortid(),
        value: country.value.id,
        label: country.name(),
      }));

    return [...promoteCountries, ...restCountries];
  }

  valuesForNationalityDropdown(): Array<SelectOption> {
    const sorter = new Intl.Collator('sv', {
      usage: 'sort',
    });

    const sortedValues = this.modelValues
      .filter((country) => !PROMOTE_COUNTRIES.includes(country.value.id))
      .map((el) => el.forNationalitySelect())
      .sort((item, nextItem) => sorter.compare(item.label as string, nextItem.label as string));

    const swedenAndNorway = (this.modelValues || [])
      .filter((item) => PROMOTE_COUNTRIES.indexOf(item.value.id) != -1)
      .map((value) => value.forNationalitySelect());

    return [...swedenAndNorway, ...sortedValues];
  }
}

export { Country };
export default new Country();

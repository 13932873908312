import { Divider, InputAdornment, Tooltip, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { FORM_ID } from 'modules/Distances/constants';
import { EditDialog } from 'modules/Distances/containers/EditDialog';
import { Assistant } from 'modules/Distances/types';
import { remapWith } from 'modules/Distances/utils';
import { editModalStore, systemUsersStore } from 'modules/SystemUsers/stores';
import * as React from 'react';
import styled from 'styled-components';

import { Show } from 'components/Condition';
import { Form, InputWrapper, TextField } from 'components/Form';

import { t, validate } from 'utils';

import { errorsStore, form } from 'stores';

import { TimingAssistantsAutocomplete, FormControls, AddBtn, AssignedAssistants } from './components';
import { checkpointFormStyles } from './styles';
import { createCheckpoint, base } from './validations';

type Props = {
  checkpointsLength: number;
  isEditMode: boolean;
  saveCheckpoint: AnyFunction;
  onAssistantsSelect: AnyFunction;
  allowStartToggle: AnyFunction;
  allowFinishToggle: AnyFunction;
  onClose: AnyFunction;
  timingAssistantsList: Array<Assistant>;
  selectedAssistants: Array<Assistant>;
  recentlyCreatedAssistant?: Assistant;
};

const WrapperFlex = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1;

  .radius {
    margin-left: 24px;
  }
`;

export const CheckpointForm = ({
  checkpointsLength,
  isEditMode,
  saveCheckpoint,
  timingAssistantsList,
  onAssistantsSelect,
  onClose,
  selectedAssistants,
  recentlyCreatedAssistant,
  allowStartToggle,
  allowFinishToggle,
}: Props) => {
  const onSubmit = (e: React.SyntheticEvent) => {
    const data = form.fetch<any>(FORM_ID);
    const isValid = validate(FORM_ID, data, createCheckpoint(base) as any);

    if (!isNumber(data.index)) {
      data.index = checkpointsLength;
    }

    if (!isValid) {
      return;
    }

    saveCheckpoint();
    onClose();
  };

  const onRemoveAssistant = (id) => {
    const newSelection = selectedAssistants.filter((el) => el.id !== id);

    return onAssistantsSelect(newSelection);
  };

  const onAddNewAssistant = () => {
    systemUsersStore.removeSelectedValue();
    editModalStore.on();
  };

  const clearValidationText = (e: React.FocusEvent<HTMLInputElement>) => {
    errorsStore.clean(FORM_ID, e.currentTarget.name);
  };

  if (recentlyCreatedAssistant) {
    onAssistantsSelect([...selectedAssistants, recentlyCreatedAssistant]);
  }

  const predefinedRole = { name: 'assistant', title: t.staticAsString('systemUsers.form.assistant') };

  const classes = checkpointFormStyles();

  return (
    <div className={classes.root}>
      <EditDialog predefinedRole={predefinedRole} />
      <Form id={FORM_ID} onSubmit={onSubmit} disableAutofill withScroll>
        <div className={classes.block}>
          <InputWrapper
            name='name'
            settings={{
              label: t.staticAsString('distances.steps.checkpointsForm.new.name'),
              additional: {
                autoFocus: true,
                fullWidth: true,
              },
              view: {
                variant: 'outlined',
              },
            }}
            Component={TextField}
          />
        </div>
        <div className={classes.title}>
          <Typography className={classes.heading}>{t.staticAsString('distances.steps.checkpointsForm.new.timingAssistants')}</Typography>
        </div>
        <div className={classes.block}>
          <TimingAssistantsAutocomplete
            value={remapWith(selectedAssistants, timingAssistantsList)}
            options={timingAssistantsList}
            onChange={onAssistantsSelect}
          />
        </div>
        <Show if={selectedAssistants.length > 0}>
          <div className={classes.block}>
            <AssignedAssistants
              onRemoveAssistant={onRemoveAssistant}
              selected={selectedAssistants}
              allowStartToggle={allowStartToggle}
              allowFinishToggle={allowFinishToggle}
            />
          </div>
        </Show>
        <div className={`${classes.block} ${classes.addButton}`}>
          <AddBtn onClick={onAddNewAssistant} label={t.staticAsString('distances.steps.checkpointsForm.new.createAssistant') as any} />
        </div>

        <Divider />

        <div className={`${classes.block} ${classes.controls}`}>
          <FormControls onCancel={onClose} isEditMode={isEditMode} />
        </div>
      </Form>
    </div>
  );
};

// This was a radius field so you you want to revert it yuo can simply insert it after length field
//
//
// <InputWrapper
//   name='radius'
//   settings={{
//     label: t.staticAsString('distances.steps.checkpointsForm.new.radius'),
//     additional: {
//       fullWidth: true,
//       onFocus: clearValidationText,
//     },
//     view: {
//       className: 'radius',
//       fullWidth: true,
//       variant: 'outlined',
//       InputProps: {
//         endAdornment: (
//           <Tooltip classes={pick(classes, 'tooltip')} title={t('distances.steps.checkpointsForm.new.radiusTooltip')!}>
//             <InputAdornment style={{ cursor: 'pointer' }} position='end'>
//               <IconInfoOutlined color='disabled' />
//             </InputAdornment>
//           </Tooltip>
//         ),
//       },
//     },
//     type: 'number',
//   }}
//   Component={TextField}
// />

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { SQUAD, DISTANCE_SQUADS_URL, DISTANCE_SQUAD_UPDATE_URL } from 'src/constants';

import { request, action, t } from 'utils';

import { Squads as SquadsStore, squadsStore, toastStore } from 'stores';

import { LoadableService } from './loadable';

class SquadsService extends LoadableService<SquadsType, FiltersType, SquadsStore> {
  constructor(store: SquadsStore) {
    super({
      store,
      resourcesUrl: DISTANCE_SQUADS_URL,
      resource: SQUAD,
      resourceUrl: 'none',
    });
  }

  @request({ action: `GET_${SQUAD}S` })
  async loadSquadsRequest(id: number, params: any): Promise<any> {
    const url = generatePath(DISTANCE_SQUADS_URL, { id });
    return axios.get(url, { params });
  }

  @request({ action: `UPDATE_${SQUAD}` })
  async updateSquadRequest(distanceId: number, squadId: number, name: string): Promise<any> {
    const url = generatePath(DISTANCE_SQUAD_UPDATE_URL, { distanceId, squadId });
    return axios.patch(url, { name });
  }

  @request({ action: `DELETE_${SQUAD}` })
  async deleteSquadRequest(distanceId: number, squadId: number): Promise<any> {
    const url = generatePath(DISTANCE_SQUAD_UPDATE_URL, { distanceId, squadId });
    return axios.delete(url);
  }

  @request({ action: `CREATE_${SQUAD}` })
  async addSquadRequest(id: number, name: string): Promise<any> {
    const url = generatePath(DISTANCE_SQUADS_URL, { id });
    return axios.post(url, { name });
  }

  @action({ action: `GET_${SQUAD}S`, minRequestTime: 800 })
  async loadDistanceSquads(distanceId: number, params?: {}): Promise<any> {
    let { page, filters } = this.store || {};
    const queryParams = {
      page,
      ...filters,
      ...params,
      orderBy: 'name',
      sortedBy: 'ASC',
    };

    const [status, response] = await this.loadSquadsRequest(distanceId, queryParams);

    if (status) {
      this.store.appendSquads(response.data.data);
      this.store.setHasMore(response.data.meta.pagination.total_pages > this.store.page);
      this.store.setPage(this.store.page + 1);
    }

    return response;
  }

  @action({ action: `UPDATE_${SQUAD}` })
  async updateDistanceSquad(distanceId: number, squadId: number, name: string): Promise<any> {
    const [status, response] = await this.updateSquadRequest(distanceId, squadId, name);

    if (status) {
      this.store.updateValue(response.data.data);
      return response;
    }

    toastStore.show(t.staticAsString('squad.canNotEdit'));
    return response;
  }

  @action({ action: `DELETE_${SQUAD}` })
  async deleteDistanceSquad(distanceId: number, squadId: number): Promise<any> {
    const [status, response] = await this.deleteSquadRequest(distanceId, squadId);

    if (status) {
      this.store.removeValue(squadId);
    }

    return response;
  }

  @action({ action: `CREATE_${SQUAD}` })
  async addDistanceSquad(distanceId: number, name: string): Promise<any> {
    const [status, response] = await this.addSquadRequest(distanceId, name);

    if (status) {
      this.store.addValue(response.data.data);
    }

    return response;
  }
}

const squadsService = new SquadsService(squadsStore);
export { squadsService, SquadsService };

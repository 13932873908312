import { clone } from 'lodash';

import { dateMaskTools } from 'components/Form/Fields/core';

import { phone } from 'utils';

import { countriesStore } from 'stores';

function racerToState(racer: RacerType) {
  const state: AnyObject = clone(racer);

  if (racer.birthday) {
    state.birthday = dateMaskTools.formatFromMask(racer.birthday);
  }

  if (racer.emergency_phone) {
    state.emergency_phone = phone.parse(racer.emergency_phone);
  }

  if (racer.telephone) {
    state.telephone = phone.parse(racer.telephone);
  }

  if (racer.country_id) {
    const country = countriesStore.findModelValue(racer.country_id);
    state.country_id = country?.forSelect();
  }

  if (racer.nationality_id) {
    const country = countriesStore.findModelValue(racer.nationality_id);
    state.nationality_id = country?.forNationalitySelect();
  }

  return state;
}

export { racerToState };

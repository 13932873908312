export const none: FilterDescriptor = {
  persistance: {
    handler: 'none',
    calcValue: function (name, value) {
      return;
    },

    prettify: function (name, value) {
      return;
    },
  },
};

import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Form, InputWrapper, Select } from 'components/Form';

import { t } from 'utils';

import { form } from 'stores';

import { Info } from '../../components/Actions';

import { SUPPORT_TASK_MANAGER_COPY_FORM } from '../../constants';

import { optionsAction } from '../../utils';

import { FieldsCopyRacers } from '.';

type Props = {};

@observer
export class Fields extends React.Component<Props> {
  getInfoText(): string {
    return t.staticAsString('supportTaskManager.copy.info');
  }

  render() {
    return (
      <DialogContent>
        <Form id={SUPPORT_TASK_MANAGER_COPY_FORM}>
          <Box>
            <InputWrapper
              name='action'
              settings={{
                required: true,
                placeholder: t.staticAsString('supportTaskManager.form.actions'),
                label: t.staticAsString('supportTaskManager.form.actions'),
                options: optionsAction(),
                additional: {
                  fullWidth: true,
                },
                requiredMark: true,
                view: {
                  variant: 'outlined',
                },
              }}
              Component={Select}
            />
          </Box>

          <Info />
          <FieldsCopyRacers />
        </Form>
      </DialogContent>
    );
  }
}

import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import withStyles from '@mui/styles/withStyles';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';

import { Form, InputWrapper, TextField, MultiSelect, DatePicker, WrappedCheckbox } from 'components/Form';

import { t } from 'utils';

import { INTEGRATION_FORM, SYNC_VALUES } from '../constants';

import { onBlurWebhookURL } from '../actions';

import { distancesStore } from '../stores';

type Props = {};

const FieldContainer = withStyles({
  root: {
    paddingBottom: 19,
  },
})(Box);

@observer
export class Fields extends React.Component<Props> {
  getDefaultSettings = (name: string, withPlaceholder: boolean = false) => {
    const settings: any = {
      label: t.staticAsString(`integrations.form.${name}` as TranslationLockedKeys),
      required: true,
    };

    if (withPlaceholder) settings.placeholder = t.staticAsString(`integrations.form.${name}` as TranslationLockedKeys);

    return settings;
  };

  render() {
    return (
      <DialogContent>
        <Form id={INTEGRATION_FORM}>
          <FieldContainer>
            <InputWrapper
              name='webhook_url'
              settings={{
                ...this.getDefaultSettings('webhook_url'),
                view: {
                  variant: 'outlined',
                },
                additional: {
                  required: true,
                  fullWidth: true,
                  onBlur: onBlurWebhookURL('webhook_url'),
                },
              }}
              Component={TextField}
            />
          </FieldContainer>
          <FieldContainer>
            <InputWrapper
              name='distances'
              settings={{
                ...this.getDefaultSettings('distances'),
                placeholder: t.staticAsString('integrations.form.distances'),
                options: distancesStore.value,
              }}
              Component={MultiSelect}
            />
          </FieldContainer>
          <FieldContainer>
            <InputWrapper
              name='is_sync'
              settings={{
                ...this.getDefaultSettings('is_sync'),
                placeholder: t.staticAsString('integrations.form.is_sync'),
                options: SYNC_VALUES(),
              }}
              Component={MultiSelect}
            />
          </FieldContainer>
          <Box>
            <InputWrapper
              name='send_start_list_results_objects'
              settings={{
                ...this.getDefaultSettings('send_start_list_results_objects'),
                required: false,
                tooltipText: t.staticAsString('integrations.form.send_start_list_results_objects.tooltip'),
              }}
              Component={WrappedCheckbox}
            />
          </Box>
          <FieldContainer>
            <InputWrapper
              name='is_active'
              settings={{
                ...this.getDefaultSettings('is_active'),
                required: false,
              }}
              Component={WrappedCheckbox}
            />
          </FieldContainer>
          <FieldContainer>
            <InputWrapper
              name='date_range'
              settings={{
                ...this.getDefaultSettings('date_range'),
                required: false,
                minDate: moment().subtract(1, 'day').endOf('day'),
                tooltip: {
                  text: t.staticAsString('integrations.form.date_range_tooltip'),
                  position: 'bottom',
                },
              }}
              Component={DatePicker}
            />
          </FieldContainer>
        </Form>
      </DialogContent>
    );
  }
}

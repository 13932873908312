import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import * as React from 'react';

import { t } from 'utils';

import { onCreateIntegration } from '../actions';

type Props = {};

const BoxWrapper = withStyles({
  root: {
    padding: 24,
    paddingBottom: 10,
  },
})(Box);

const ButtonStyled = withStyles({
  root: {
    borderRadius: 2,
    padding: '12px 15px',
  },
})(Button);

const Title = withStyles({
  root: {
    fontSize: 20,
  },
})(Typography);

export class Toolbar extends React.Component<Props> {
  render() {
    return (
      <BoxWrapper>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Title>{t.staticAsString('integrations.list.title')}</Title>
          <ButtonStyled variant='contained' color='primary' disableElevation onClick={onCreateIntegration}>
            <AddIcon />
            {t.staticAsString('integrations.list.addIntegration')}
          </ButtonStyled>
        </Box>
      </BoxWrapper>
    );
  }
}

import { Typography } from '@mui/material';
import * as React from 'react';

import { Show } from 'components/Condition';
import { TranslationField, InputWrapper } from 'components/Form';

import { t } from 'utils';

import { useStyles } from '../styles';

export const CustomFields = ({
  localesToSelect,
  labels,
  selectedLocale,
  onTranslationChange,
  savedFields,
  prefilled,
}: {
  prefilled: { [k in string]?: string };
  localesToSelect: availableLocales[];
  selectedLocale: availableLocales;
  labels?: TranslationLabel;
  onTranslationChange: Function;
  savedFields: { [k in FieldName]?: boolean };
}) => {
  const classes = useStyles();

  const customFieldBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.target.value;

    onTranslationChange({ name, value, blur: true });
  };

  return (
    <React.Fragment>
      {localesToSelect.map((locale) => (
        <Show key={`${locale}.name`} if={selectedLocale === locale}>
          <div key={`${locale}.name`} className={classes.formElement}>
            <InputWrapper
              name={`${locale}.customFields.name`}
              settings={{
                additional: {
                  onBlur: customFieldBlur,
                  onInputChange: onTranslationChange,
                },
                label: labels?.customFields?.name,
                hasCheckmark: savedFields[`${locale}.customFields.name`],
                prefilledValue: prefilled[`${locale}.customFields.name`],
              }}
              Component={TranslationField}
            />
          </div>
          <Show if={!!labels?.customFields?.helper_text}>
            <div key={`${locale}.helper_text`} className={classes.formElement}>
              <InputWrapper
                name={`${locale}.customFields.helper_text`}
                settings={{
                  label: labels?.customFields?.helper_text,
                  additional: {
                    onBlur: customFieldBlur,
                    onInputChange: onTranslationChange,
                  },
                  hasCheckmark: savedFields[`${locale}.customFields.helper_text`],
                  prefilledValue: prefilled[`${locale}.customFields.helper_text`],
                }}
                Component={TranslationField}
              />
            </div>
          </Show>

          <Show if={!!labels?.customFields?.values?.length}>
            <Typography className={classes.optionsText}>{t('translations.options')}</Typography>
            <React.Fragment>
              {labels?.customFields?.values?.map((value, i) => (
                <div key={`${locale}.${value.id}`} className={classes.formElement}>
                  <InputWrapper
                    name={`${locale}.customFields.values.${i}.value`}
                    settings={{
                      label: value.value,
                      additional: {
                        onBlur: customFieldBlur,
                        onInputChange: onTranslationChange,
                      },
                      hasCheckmark: savedFields[`${locale}.customFields.values.${i}.value`],
                      prefilledValue: prefilled[`${locale}.customFields.values.${i}.value`],
                    }}
                    Component={TranslationField}
                  />
                </div>
              ))}
            </React.Fragment>
          </Show>
        </Show>
      ))}
    </React.Fragment>
  );
};

import { CONFIRM_POPUP_TYPES } from 'src/constants/mixed';

import { t } from 'utils';

import { confirmationModalStore } from 'stores';

const SuccessTranslateSchema = {
  title: () => t.staticAsString('popUp.verification.title'),
  body: () => t.staticAsString('popUp.verification.body'),
};

const ErrorTranslateSchema = {
  title: () => t.staticAsString('popUp.verification.error.title'),
  body: () => t.staticAsString('popUp.verification.error.body'),
};

const handleSuccessVerification = () => {
  return confirmationModalStore.openModal({
    title: t(SuccessTranslateSchema.title),
    body: t(SuccessTranslateSchema.body),
    type: CONFIRM_POPUP_TYPES.confirm,
    btnLabel: {
      confirm: t.staticAsString('popUp.verification.button'),
    },
    onConfirm: () => {},
  });
};

const handleErrorVerification = () => {
  return confirmationModalStore.openModal({
    title: t(ErrorTranslateSchema.title),
    body: t(ErrorTranslateSchema.body),
    type: CONFIRM_POPUP_TYPES.confirm,
    btnLabel: {
      cancel: t.staticAsString('popUp.verification.error.button'),
    },
    onConfirm: () => {},
  });
};

export { handleSuccessVerification, handleErrorVerification };

import { isFunction } from 'lodash';
import { Observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';

type Value = moment.Moment | string | nil;
type Format = () => string;

type Props = {
  value: Value;
  format: Format;
  parseFormat?: string;
};

let FormattedDateComponent = ({ value, format, parseFormat }: Props | any) => {
  let generateFormat = () => isFunction(format) && format();

  return (
    <Observer>
      {() => {
        const momentValue = moment(value, parseFormat || null);

        if (!value || !momentValue.isValid()) {
          return null;
        }

        const generatedFormat = generateFormat();

        if (!generatedFormat) {
          return null;
        }

        return <>{momentValue.format(generatedFormat)}</>;
      }}
    </Observer>
  );
};

const FormattedDate = React.memo<Props>(FormattedDateComponent);

const formatDate = (value: Value, format: Format): React.ReactNode => {
  return <FormattedDate value={value} format={format} />;
};

export { FormattedDate, formatDate };

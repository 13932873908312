import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

import { Props } from './';

const classes = makeStyles({
  label: {
    color: '#a8aeb8',
  },
});

function Cancel({ children, onClick, ...rest }: Props) {
  return (
    <Button classes={classes()} size='medium' variant='text' onClick={onClick} disableElevation {...rest}>
      {children || null}
    </Button>
  );
}

export { Cancel };

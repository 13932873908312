import Dialog from '@mui/material/Dialog';
import withStyles from '@mui/styles/withStyles';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Loading } from 'components/Loading';

import { Fields, ModalBottom, ModalTitle } from '../components';

import { CREATE_INTEGRATION, INTEGRATION_FORM_INIT } from '../constants';

import { closeIntegrationForm } from '../actions';

import { modalStore } from '../stores';

type Props = {};

const DialogStyled = withStyles({
  paper: {
    width: 770,
  },
})(Dialog);

@observer
export class IntegrationForm extends React.Component<Props> {
  render() {
    return (
      <DialogStyled open={Boolean(modalStore.value?.open)} onClose={closeIntegrationForm}>
        <Loading action={INTEGRATION_FORM_INIT} />
        <Loading action={CREATE_INTEGRATION} />
        <ModalTitle />
        <Fields />
        <ModalBottom />
      </DialogStyled>
    );
  }
}

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import * as React from 'react';
import { Link, generatePath } from 'react-router-dom';
import validate from 'validate.js';

import { ROUTES } from 'src/constants';

import { DateRange } from 'components/TimeRange';

import { time } from 'utils';
import { t } from 'utils';

import { Race as RaceModel } from 'models';

import { StatusItem } from './StatusItem';

type Props = {
  item: RaceModel;
};

const Item = ({ item }: Props) => {
  const value = item.value;

  return (
    <TableRow className={classNames('races_list_row')}>
      <TableCell className={classNames('cell cell-name')}>
        <Link className='label' to={generatePath(ROUTES.raceRoute, { id: item.value.id })}>
          {item.value.name}
        </Link>
      </TableCell>
      <TableCell className={classNames('cell cell-id')}>
        <Link className='label' to={generatePath(ROUTES.raceRoute, { id: item.value.id })}>
          <StatusItem item={item} />
        </Link>
      </TableCell>
      <TableCell className={classNames('cell cell-id')}>{value.id}</TableCell>
      <TableCell className={classNames('cell')}>{item.sportTypeTitle()}</TableCell>
      <TableCell className={classNames('cell cell-date')}>
        <DateRange from={value.race_date} to={value.race_end_date} />
      </TableCell>
      <TableCell className={classNames('cell')}>{item.location()}</TableCell>
      <TableCell className={classNames('cell')}>{item.organizerTitle()}</TableCell>
      <TableCell className={classNames('cell')}>{time.datetime(value.updated_at).parseFromBe().format('shortDateWithTime')}</TableCell>
    </TableRow>
  );
};

export { Item };

import { helperOrganizersStore, sportTypesAuxiliaryStore } from 'stores';

import { EditFormState } from '../types';

function mapRaceToEditState(value: null | RaceType, initState: EditFormState): EditFormState | nil {
  if (!value) {
    return null;
  }

  const organizers = helperOrganizersStore.valuesForSelect();
  const sportTypes = sportTypesAuxiliaryStore.valuesForSelect();

  const organizer = organizers.find((item) => item.key === value.organizer_id);
  const sport = sportTypes.find((item) => item.key === value.sport_id);
  const description = value.description;
  return {
    ...initState,
    // @ts-ignore
    race: {
      ...value,
      organizer_id: organizer,
      sport_id: sport,
      description,
    },

    isLoaded: true,
  };
}

export const stateMapper = {
  mapRaceToEditState,
};

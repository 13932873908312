import React, { FC } from 'react';

import { t } from 'utils';

import { PageTitle, Section, Title, Paragraph } from '../components/Shared';

const CooperationAgreement: FC = () => {
  return (
    <>
      <Title t={'cooperationAgreement.header'} />
      <p className='terms-description'>{t('cooperationAgreement.description')}</p>
      <Section index={1}>
        <Title t={'cooperationAgreement.section1.title'} />
        <Paragraph t={'cooperationAgreement.section1.1'} />
        <Paragraph t={'cooperationAgreement.section1.2'} />
      </Section>
      <Section index={2}>
        <Title t={'cooperationAgreement.section2.title'} />
        <Paragraph t={'cooperationAgreement.section2.1'} />
        <Paragraph t={'cooperationAgreement.section2.2'} />
      </Section>
      <Section index={3}>
        <Title t={'cooperationAgreement.section3.title'} />
        <Paragraph t={'cooperationAgreement.section3.1'} />
        <Paragraph t={'cooperationAgreement.section3.2'} />
        <Paragraph t={'cooperationAgreement.section3.3'} />
        <Paragraph t={'cooperationAgreement.section3.4'} />
        <Paragraph t={'cooperationAgreement.section3.5'} />
        <Paragraph t={'cooperationAgreement.section3.6'} />
        <Paragraph t={'cooperationAgreement.section3.7'} />
      </Section>
      <Section index={4}>
        <Title t={'cooperationAgreement.section4.title'} />
        <Paragraph t={'cooperationAgreement.section4.1'} />
        <Paragraph t={'cooperationAgreement.section4.2'} />
      </Section>
      <Section index={5}>
        <Title t={'cooperationAgreement.section5.title'} />
        <Paragraph t={'cooperationAgreement.section5.1'} />
      </Section>
      <Section index={6}>
        <Title t={'cooperationAgreement.section6.title'} />
        <Paragraph t={'cooperationAgreement.section6.1'} />
      </Section>
      <Section index={7}>
        <Title t={'cooperationAgreement.section7.title'} />
        <Paragraph t={'cooperationAgreement.section7.1'} />
        <Paragraph t={'cooperationAgreement.section7.2'} />
        <Paragraph t={'cooperationAgreement.section7.3'} />
        <Paragraph t={'cooperationAgreement.section7.4'} />
      </Section>
      <Section index={8}>
        <Title t={'cooperationAgreement.section8.title'} />
        <Paragraph t={'cooperationAgreement.section8.1'} />
        <Paragraph t={'cooperationAgreement.section8.2'} />
        <Paragraph t={'cooperationAgreement.section8.3'} />
        <Paragraph t={'cooperationAgreement.section8.4'} />
      </Section>
      <Section index={9}>
        <Title t={'cooperationAgreement.section9.title'} />
        <Paragraph t={'cooperationAgreement.section9.1'} />
        <Paragraph t={'cooperationAgreement.section9.2'} />
      </Section>
      <Section index={10}>
        <Title t={'cooperationAgreement.section10.title'} />
        <Paragraph t={'cooperationAgreement.section10.1'} />
        <Paragraph t={'cooperationAgreement.section10.2'} />
      </Section>
      <Section index={11}>
        <Title t={'cooperationAgreement.section11.title'} />
        <Paragraph t={'cooperationAgreement.section11.1'} />
      </Section>
      <Section index={12}>
        <Title t={'cooperationAgreement.section12.title'} />
        <Paragraph t={'cooperationAgreement.section12.1'} />
        <Paragraph t={'cooperationAgreement.section12.2'} />
        <Paragraph t={'cooperationAgreement.section12.3'} />
        <Paragraph t={'cooperationAgreement.section12.4'} />
      </Section>
      <Section index={13}>
        <Title t={'cooperationAgreement.section13.title'} />
        <Paragraph t={'cooperationAgreement.section13.1'} />
        <Paragraph t={'cooperationAgreement.section13.2'} />
        <Paragraph t={'cooperationAgreement.section13.3'} />
        <Paragraph t={'cooperationAgreement.section13.4'} />
        <Paragraph t={'cooperationAgreement.section13.5'} />
      </Section>
      <Section index={14}>
        <Title t={'cooperationAgreement.section14.title'} />
        <Paragraph t={'cooperationAgreement.section14.1'} />
        <Paragraph t={'cooperationAgreement.section14.2'} />
        <Paragraph t={'cooperationAgreement.section14.3'} />
      </Section>
      <Section index={15}>
        <Title t={'cooperationAgreement.section15.title'} />
        <Paragraph t={'cooperationAgreement.section15.1'} />
      </Section>
    </>
  );
};

export { CooperationAgreement };

import { Countries, RawCountriesJSON } from '../types/countries';

export function mapCountries(raw: RawCountriesJSON.Data): Countries {
  return {
    total: raw.nationality_count.buckets.reduce((acc, bucket) => acc + bucket.doc_count, 0),
    data: raw.nationality_count.buckets.map((bucket) => {
      return {
        label: bucket.key,
        value: bucket.doc_count,
      };
    }),
  };
}

import { racesService } from '../services';

import { racesStore, racesParamsStore } from '../stores';

export const loadMoreRaces = () => {
  racesService.getRaces();
};

export const searchRaces = (query: string) => {
  racesStore.clear();
  racesParamsStore.set({
    ...(racesParamsStore.value as any),
    page: 1,
    search: `name:${query}`,
    searchFields: 'name:like',
  });
  racesService.getRaces();
};

import { cloneDeep } from 'lodash';

import { checkPointStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { CheckPoint } from 'src/modules/Distances/components/shared/Steps/GPX/types';

export const isAllCheckpointsAdded = (): boolean => {
  const checkPoints = cloneDeep(checkPointStore.value) as Array<CheckPoint>;

  let isAllAdded = true;

  checkPoints.forEach((item) => {
    if (item.coordinate.lng === 0 && item.coordinate.lat === 0) {
      isAllAdded = false;
      return;
    }
  });

  return isAllAdded;
};

import { Box } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';
import shortid from 'shortid';

import { AutocompleteSelect } from 'components/Fields';

type Value = nil | number | string;

type Props = {
  options: Array<SelectOption>;
  className?: string;
  applyFilter: (
    arg0: {
      [K in string]: Value;
    },
  ) => void;
} & FilterProps;

type State = {
  value: any;
  menuSearchOpen: boolean;
};

class FilterAutocompleteWithSearch extends React.Component<Props, State> {
  id: string;

  constructor(props: Props) {
    super(props);
    this.id = shortid();

    const value = props.options.find((opt) => +opt.value === +props.value);
    this.state = {
      value: value,
      menuSearchOpen: false,
    };
  }

  onChange = ({ value }: { name: string; value: Object }) => {
    this.setState({
      value,
    });
  };

  applyFilter = () => {
    const { name } = this.props;
    const value = this.state.value?.value;
    this.props.applyFilter({ [name]: value });
  };

  clear = () => {
    this.setState({
      value: null,
    });
  };

  render() {
    const { name, options, label } = this.props;
    const { value } = this.state;
    return (
      <div className='filter-container'>
        <Box className='form-group' sx={{ marginBottom: '22px' }}>
          <AutocompleteSelect
            name={name}
            label={label}
            errors={[]}
            options={options}
            value={value}
            className='autocomplete-with-search'
            onChange={this.onChange}
            disableClearable
          />
        </Box>
        <div className='filter-control'>
          <span className={classNames('clear', { hidden: !value })} onClick={this.clear}>
            Clear
          </span>
          <span className='apply' onClick={this.applyFilter}>
            Apply
          </span>
        </div>
      </div>
    );
  }
}

export { FilterAutocompleteWithSearch };

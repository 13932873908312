import { cloneDeep, isEmpty, isNull, omit, omitBy, remove, sortBy } from 'lodash';
import updateDistanceService from 'modules/Distances/services/update';
import { formatData, formatFieldData } from 'modules/Distances/utils';
import { isCalendarRaceType } from 'modules/Races/utils/isCalendarRaceType';

import { prepareFile } from 'utils';

import { filesService } from 'services';

import { helperDistancesStore, helperRacesStore, progressStore } from 'stores';

import { DISTANCE, DISTANCE_MODES, DISTANCE_STEPS } from '../../../constants';

import { State as UserInputData } from '../shared/stateHelpers';

export const liveUpdateField = async (updateData: AnyObject, formData: UserInputData) => {
  const payload = cloneDeep(updateData);
  const distanceId = formData?.distance && formData?.distance.id;

  if (!payload || !distanceId) return;

  const race = helperRacesStore.selected;
  const defaultLocale = race?.pref_lang_code || 'en';

  let dataToSend: AnyObject = {};
  const queryParams: { with: string } = { with: '' };

  //Registration fields
  if (payload.registrationFields) {
    queryParams.with = 'registration_fields';
  }

  //Checkpoints
  if (payload.checkpoints?.length) {
    queryParams.with = 'checkpoints.assistants.organizer';
  }

  //Waves
  if (payload.waves?.length) {
    queryParams.with = 'waves';
  }

  //Classes
  if (payload.classes?.length) {
    queryParams.with = 'classes';
  }

  //Disciplines (Relays)
  if (payload.disciplines?.length) {
    queryParams.with = 'disciplines';
  }

  //RefundProtect
  if ('refund_protect_enabled' in payload) {
    dataToSend.refund_protect_enabled = payload.refund_protect_enabled;
  }

  //Prices
  if ('prices' in payload) {
    queryParams.with = 'prices';
  }
  //TabOptions (Additional)
  if (payload.editorSettings) {
    queryParams.with = 'editor_settings;checkpoints';
  }

  //Custom fields
  if (payload.custom_fields?.length) {
    const isEmptyCustomFields = !(payload.custom_fields || []).some((form) => !form._delete);
    if (isEmptyCustomFields) {
      remove(dataToSend.editor_settings.tab_options, (option) => option === DISTANCE_STEPS.custom_fields);
    } else {
      for (const cf of payload.custom_fields) {
        if (cf.image?.uuid) {
          cf.image_uuid = cf.image.uuid;
          delete cf['image'];
        }
        if (cf.image?.size) {
          progressStore.log(`UPDATE_${DISTANCE}`, 'progress');
          const submitData = prepareFile(cf.image);
          const resp = await filesService.upload(submitData);

          if (resp.isOk) {
            cf.image_uuid = resp.uuid;
            delete cf['image'];
          }
        }
        if (cf.image_uuid === null) delete cf['image_uuid'];
      }

      dataToSend.custom_fields = formatData('custom_fields', payload);

      if (dataToSend.custom_fields?.length) {
        dataToSend.custom_fields = sortBy(dataToSend.custom_fields, (item) => Boolean(item._delete)).map((cf) => {
          let field = {
            ...omit(cf, ['name', 'helper_text']),
            [defaultLocale]: { name: cf.name, helper_text: cf.helper_text },
            is_required: Boolean(cf.is_required),
          };
          if (cf.values !== undefined) {
            field = {
              ...field,
              is_required: Boolean(cf.is_required),
              values: sortBy(cf.values, (item) => Boolean(item._delete)).map((cfv) => ({
                [defaultLocale]: { value: cfv.value },
                ...omit(cfv, ['value']),
                is_required: Boolean(cfv.is_required),
                price: cfv.price || 0,
                vat_percents: Number(cfv.vat_percents) || 0,
              })),
            };
          }
          return field;
        });
      }
      queryParams.with = queryParams.with + ';custom_fields.values;custom_fields.image';
    }
  }

  const withoutPricesStep = !formData.editorSettings.tab_options.some((option: string | any) => option === DISTANCE_STEPS.prices);
  if (isNull(payload.vat_percents) || (!isCalendarRaceType && withoutPricesStep)) {
    dataToSend.vat_percents = null;
  }

  dataToSend = { ...formatFieldData(payload), ...dataToSend };

  const distanceMode = dataToSend.distance_mode || helperDistancesStore?.selected?.distance_mode;

  if (dataToSend.distance_mode === DISTANCE_MODES.VIRTUAL || dataToSend.distance_mode === DISTANCE_MODES.CLASSIC) {
    dataToSend.goal = { _delete: true };
  }

  if (distanceMode === DISTANCE_MODES.CUMULATIVE) {
    dataToSend.goal = { ...(dataToSend.goal || {}), _delete: false };
  }

  if (dataToSend.distance_mode === DISTANCE_MODES.CLASSIC) {
    dataToSend.ends_at = undefined;
  }

  if (distanceMode !== DISTANCE_MODES.CUMULATIVE) {
    dataToSend.goal = undefined;
  }

  if (distanceMode !== DISTANCE_MODES.CLASSIC) {
    dataToSend.medical_assistants = [];
    dataToSend.editor_settings = {
      ...dataToSend.editor_settings,
      tab_options:
        'editorSettings' in payload
          ? payload.editorSettings.tab_options.filter((option) => option !== DISTANCE_STEPS.medical_assistants)
          : formData.editorSettings.tab_options.filter((option) => option !== DISTANCE_STEPS.medical_assistants),
    };
  }

  if (dataToSend.min_members_quantity) dataToSend.min_members_quantity = Number(dataToSend.min_members_quantity);
  if (dataToSend.max_members_quantity) dataToSend.max_members_quantity = Number(dataToSend.max_members_quantity);

  if (!isEmpty(dataToSend)) {
    const [isOk, distance] = await updateDistanceService.updateField(distanceId, dataToSend, omitBy(queryParams, isEmpty));

    if (isOk) {
      return distance;
    }
    return false;
  }

  progressStore.log(`UPDATE_${DISTANCE}`, 'completed');
  return false;
};

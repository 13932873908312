import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { API_CLASSES_SYNC, RACE_CLASSES_URL } from 'src/constants';
import { request, action } from 'src/utils';

import { CLASSES_EQ_REQUEST } from '../constants';

import { mapOptions, optionsWithParentMap } from '../utils';

import { syncClassesStore, raceClassesStore } from '../stores';

import { raceDistancesStore, syncDistancesStore } from '../../Shared';

class SyncClasses {
  @request({ action: CLASSES_EQ_REQUEST })
  async getClassesRequest(id: number): Promise<any> {
    const url = generatePath(API_CLASSES_SYNC, { id });
    return axios.get(url);
  }

  @action({ action: CLASSES_EQ_REQUEST })
  async getClasses(id: number) {
    const [isOk, response] = await this.getClassesRequest(id);

    if (isOk) {
      syncClassesStore.set(optionsWithParentMap(response.data.data, syncDistancesStore.value as SelectOption[]));
    }
  }
}

class RaceClasses {
  @request({ action: CLASSES_EQ_REQUEST })
  async getClassesRequest(id: number): Promise<any> {
    const url = generatePath(RACE_CLASSES_URL, { id });
    return axios.get(url);
  }

  @action({ action: CLASSES_EQ_REQUEST })
  async getClasses(id: number) {
    const [status, response] = await this.getClassesRequest(id);

    if (status) {
      raceClassesStore.set(mapOptions(response.data.data, raceDistancesStore.value || []));
    }
  }
}

export const syncClassesService = new SyncClasses();
export const raceClassesService = new RaceClasses();

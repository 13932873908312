import { gpxGoogleMapStore, checkPointStore, markersStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { CheckPoint, GpxGoogleMap } from 'src/modules/Distances/components/shared/Steps/GPX/types';
import { getMarker } from 'src/modules/Distances/components/shared/Steps/GPX/utils/getMarker';

import { globalMap } from '../MapView';

export const setMarkersInStore = () => {
  const checkPoints = checkPointStore.value as Array<CheckPoint>;
  const gpxGoogleMap = gpxGoogleMapStore.value as GpxGoogleMap;

  if (gpxGoogleMap) {
    // const { map, maps } = gpxGoogleMap;
    const { map, maps } = globalMap;

    const lastIndex = checkPoints.length - 1;

    const markers = checkPoints.map((checkPoint, index) => {
      const markerType = {
        index: checkPoint.index,
        label: checkPoint.label,
        coordinate: checkPoint.coordinate,
      };
      const isLabelRight = index === 0 || index === lastIndex;
      return getMarker(map, maps, markerType, isLabelRight);
    });

    markersStore.set(markers);
  }
};

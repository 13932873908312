import { apiLoad } from './apiLoad';
import { set } from './set';

/*
 * Load messages from local store or api and
 * change messages for intl, and messagesStore
 */
async function load(value: availableLocales) {
  if (await set(value)) {
    return;
  }

  await apiLoad(value);
  set(value);
}

export { load };

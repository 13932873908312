import { isObject } from 'lodash';
import * as React from 'react';

import { filterListProps } from 'utils';

class FilterDescriptorWrapper {
  value: FilterListDescriptor;
  name: string;

  constructor(name: string, value: FilterListDescriptor) {
    this.value = value;
    this.name = name;
  }

  filterDescriptor(): FilterDescriptor {
    return this.value?.filter;
  }

  sortDescriptor(): SortDescriptor {
    const { value, name } = this;
    const sort = value.sort;

    if (isObject(sort)) {
      return sort as SortDescriptor;
    }

    return {
      calcOrderBy: () => name,
    };
  }

  frontendDescriptor(): nil | FrontendHandlerDescriptor {
    return this.value?.frontend;
  }

  findHook(hookName: 'apply' | 'delete' | 'reset'): Function {
    const descriptor = this.filterDescriptor();

    if (!descriptor.behavior) {
      return () => {};
    }

    return descriptor.behavior && descriptor.behavior[hookName];
  }

  calculateOrderBy(sortBy: SortTypes, params?: AnyObject | nil): string {
    const descriptor = this.sortDescriptor();
    const { name } = this;
    return descriptor.calcOrderBy(name, sortBy, params);
  }

  calculateSearch(value: any): nil | Object {
    const { name } = this;
    const descriptor = this.filterDescriptor();

    if (descriptor.persistance.advanced) {
      return descriptor.persistance.advanced.calcValue(name, value);
    }

    if (descriptor.persistance.calcValue) {
      return { [name]: descriptor.persistance.calcValue(name, value) };
    }
  }

  calculateSearchFields(value: any): nil | Object {
    const { name } = this;
    const descriptor = this.filterDescriptor();

    if (descriptor.persistance.advanced) {
      return descriptor.persistance.advanced.calcHandler(name, value);
    }

    if (descriptor.persistance.handler) {
      return { [name]: descriptor.persistance.handler };
    }

    if (descriptor.persistance.calcHandler) {
      return { [name]: descriptor.persistance.calcHandler(name, value) };
    }
    const calcHandler = descriptor.persistance.calcHandler;
    // @ts-ignore
    return calcHandler && calcHandler(name, value);
  }

  prettifyFilterValue(value: any) {
    const { name } = this;
    const descriptor = this.filterDescriptor();
    const propsGenerator = this.frontendComponentPropsGenerator();
    return descriptor?.persistance.prettify(name, value, propsGenerator());
  }

  isHandlingByFrontend(): boolean {
    return this.frontendFilterType() !== 'none';
  }

  isHiddenChipLabel(): boolean {
    // hidden in case if !isHandlingByFrontend too
    const descriptor = this.frontendDescriptor();
    return !!descriptor?.hideChipLabel;
  }

  isNeccessaryToApplySortBySearch(): boolean {
    const descriptor = this.sortDescriptor();
    return !!descriptor?.reorderOnSearch;
  }

  isSortingEnabled(): boolean {
    return !!this.value?.sort;
  }

  frontendFilterType(): FrontendListFilter {
    const descriptor = this.frontendDescriptor();

    if (!descriptor) {
      return 'none';
    }

    return descriptor.type;
  }

  frontendComponentPropsGenerator(): Function {
    const type = this.frontendFilterType();
    const descriptor = this.frontendDescriptor();
    const defaultProps = filterListProps[type].default;

    if (!descriptor) {
      return defaultProps;
    }

    return descriptor.props || defaultProps;
  }

  // eslint-disable-next-line
  frontendFilterComponent(): nil | React.ComponentType<any> {
    const descriptor = this.frontendDescriptor();

    if (!descriptor) {
      return;
    }

    return descriptor.component;
  }
}

export { FilterDescriptorWrapper };

import { TableRow } from '@mui/material';
import * as React from 'react';

import { RacersCells } from '../../components/Row';

import { RacersContext } from '../../context';
import { Racer } from '../../types';

type Props = {
  item: Racer;
};

class Row extends React.Component<Props> {
  static contextType = RacersContext;

  render() {
    const { item } = this.props;
    return (
      <TableRow>
        <RacersCells value={item} />
      </TableRow>
    );
  }
}

export { Row };

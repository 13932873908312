import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton } from '@mui/material';
import { inject, observer } from 'mobx-react';
import { AuthService } from 'modules';
import MissingPaymentDetails from 'modules/MissingPaymentDetails';
import * as React from 'react';

import logoutImage from 'img/logout.png';
import squareLogo from 'img/svg/square-logo-bg.svg';

import { Icon } from 'components/Icon';

import { history } from 'utils';

import { Session as SessionStore, sidebarStore } from 'stores';

const authService = new AuthService();

type OriginalProps = {};

type Props = {
  sessionStore: OriginalProps & SessionStore;
};

const HeaderComponent = ({ sessionStore: { fullName, isOrganizer, user } }: Props) => {
  const navigateToProfile = () => {
    const id = user?.user_id || user?.organizer_id;
    history.push(`/system-users/${id}`);
  };

  const handleToggleSidebar = () => {
    sidebarStore.toggle();
  };
  return (
    <header className='main-header'>
      <Box sx={{ display: 'flex', alignItems: 'center', pl: 3 }}>
        <IconButton color='inherit' aria-label='open drawer' onClick={handleToggleSidebar} edge='start'>
          <MenuIcon sx={{ color: 'white' }} />
        </IconButton>
        <Box sx={{ cursor: 'pointer', display: 'flex', width: 40, height: 40, alignItems: 'center', ml: 2 }} onClick={navigateToProfile}>
          <img src={squareLogo} alt='RaceID' />
        </Box>
      </Box>
      <ul className='toolbar'>
        <li className='item user-profile' onClick={navigateToProfile}>
          <MissingPaymentDetails />
          <Icon className='icon' value='account-circle' />

          <p className='user-name'>{fullName}</p>
        </li>
        <li className='item logout' onClick={() => authService.logout()}>
          <img src={logoutImage} alt='logout' />
        </li>
      </ul>
    </header>
  );
};

export const Header = inject('sessionStore')(observer(HeaderComponent as unknown as React.ComponentType<OriginalProps>));

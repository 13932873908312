import { OrderType } from 'modules/Payments/types';

class Order {
  value: OrderType;

  constructor(value: OrderType) {
    this.value = value;
  }

  refundProtect() {
    const { refund_protect_used } = this.value;

    return { refund_protect_used };
  }

  currency() {
    const { currency } = this.value;
    return currency || '';
  }

  totalSum(): string {
    const { total } = this.value;

    return `${this.currency()} ${+(total / 100).toFixed(2)}`;
  }

  orderId(): string {
    const { id } = this.value;

    if (!id) {
      return '';
    }

    return `${id}`;
  }

  distanceName(): string {
    const { distance_title } = this.value;
    if (!distance_title) {
      return '';
    }

    return `${distance_title}`;
  }

  userFullName(): string {
    const { firstname, lastname } = this.value;

    if (!firstname && !lastname) {
      return '';
    }

    return `${firstname} ${lastname}`;
  }
}

export { Order };

import { generatePath } from 'react-router-dom';

import { DISTANCE_TYPE, ROUTES } from 'src/constants';

import { history } from 'utils';

import { distanceStore } from '../stores';

function onChangeDistance(id: number) {
  const distanceModel = distanceStore.findModelValue(id);

  if (!distanceModel) {
    return;
  }

  const { race_parent_id, type } = distanceModel.value;

  let url = '';

  switch (type) {
    case DISTANCE_TYPE.single:
      url = generatePath(ROUTES.distancesRacersRoute, { raceId: race_parent_id, id });
      break;
    case DISTANCE_TYPE.team:
      url = generatePath(ROUTES.distancesTeamsRoute, { raceId: race_parent_id, id });
      break;
    default:
      break;
  }

  if (!url) {
    return;
  }

  history.push(url);
}

export { onChangeDistance };

import { makeStyles } from '@mui/styles';

export const loadingBarStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 66,
    left: 252,
    zIndex: 10,
    '& > div': {
      margin: 4,
    },
  },
});

/*
 * TODO TS, some of those styles can be broken see https://stackoverflow.com/questions/59464618/using-custom-theme-in-makestyles
 * and styles in here from theme that be not fetched cause theme is not created yet
 * PLEASE CHECK
 * just uncomment theme: any to check what can be broken
 */
export const cardStyles = makeStyles((theme: any) => ({
  root: {
    minWidth: 360,
    maxHeight: 133,
    backgroundColor: theme.palette.textPrimary,
  },
}));

export const actionsStyles = makeStyles({
  root: {
    padding: '12px 14px 11px 8px',
    justifyContent: 'flex-end',
  },
});

export const contentStyles = makeStyles({
  root: {
    padding: '0 16px',
  },
});

export const buttonStyles = makeStyles({
  root: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 1.25,
    marginRight: 25,
  },
});

/*
 * TODO TS, some of those styles can be broken see https://stackoverflow.com/questions/59464618/using-custom-theme-in-makestyles
 * and styles in here from theme that be not fetched cause theme is not created yet
 * PLEASE CHECK
 * just uncomment theme: any to check what can be broken
 */
export const endStatusStyles = makeStyles((theme: any) => ({
  paper: {
    minWidth: 360,
    minHeight: 51,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.textPrimary,
  },
  icon: {
    margin: '14px 0px 13px 12px',
  },
  passColor: {
    color: '#66bb6a',
  },
  errorColor: {
    color: '#cf6679',
  },
  text: {
    fontSize: 16,
    marginLeft: 12,
    color: 'white',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
}));

const linearProgressColors = {
  colorPrimary: {
    backgroundColor: '#595532',
  },
  bar: {
    backgroundColor: '#f9d026',
  },
};

export const linearProgressStyles = makeStyles(() => {
  return {
    root: {
      maxWidth: 260,
      marginLeft: 16,
    },
    ...linearProgressColors,
  };
});

export const linearProgressAccordionStyles = makeStyles(() => {
  return {
    bar: {
      ...linearProgressColors.bar,
    },
  };
});

export const headerStyles = makeStyles({
  root: {
    color: 'white',
    paddingBottom: 8,

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    fontSize: 16,
  },
});

export const subheaderStyles = makeStyles({
  root: {
    color: 'rgba(255, 255, 255, 0.54)',
    paddingBottom: 8,
    fontSize: 12,
  },
});

export const extraStyles = makeStyles({
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  primaryText: {
    color: '#262e35',
  },
  grey: {
    color: '#a8aeb8',
  },
  white: {
    color: 'white',
  },
  mb4: {
    marginBottom: 4,
  },
  ml8: {
    marginLeft: 8,
  },
  mr10: {
    marginRight: 10,
  },
  mx1: {
    margin: '0 1px',
  },
  font14: {
    fontSize: 14,
  },
  font16: {
    fontSize: 16,
  },
  pb0: {
    paddingBottom: 0,
  },
  p4: {
    padding: 4,
  },
  icon: {
    marginLeft: 4,
    padding: 0,
    maxWidth: 24,
    maxHeight: 24,
    width: 24,
    height: 24,
    '& svg': {
      fontSize: 19,
    },
  },
});

export const accordionStyles = makeStyles({
  root: {
    minWidth: 360,
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius: '2px',
    boxShadow: '0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14)',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
});
export const accordionSummaryStyles = makeStyles({
  root: {
    backgroundColor: '#262e35',
    color: 'white',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
});
export const accordionDetailsStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    color: 'white',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
});

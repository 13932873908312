import VerifiedIcon from '@mui/icons-material/Verified';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { ActionButton } from 'modules/RaceDetails/components';
import * as React from 'react';

import { Show } from 'components/Condition';
import { Icon } from 'components/Icon';

import { t } from 'utils';

import { Race } from 'models';

import { RACE_STATUSES_INT } from '../../../../../constants';

type Props = {
  race: nil | Race;
  onClick?: React.MouseEventHandler;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    flexDirection: 'column',
    gap: '15px',
    lineHeight: '16px',
    letterSpacing: 'unset',
  },
  redDot: {
    position: 'absolute',
    left: '-10px',
    top: '-10px',
  },
}));

const PublishBtn = ({ race, onClick, className }: Props) => {
  const c = useStyles();
  const isPublished = race?.value.published;
  const approveStatus = race?.approveStatus();
  const isApproved = approveStatus === RACE_STATUSES_INT.approve;
  return (
    <ActionButton className={classnames(className, c.button)} variant='text' onClick={onClick}>
      <Show if={isApproved && !isPublished}>
        <Icon value={'red-dot'} className={c.redDot} />
      </Show>
      <VerifiedIcon color={isPublished ? 'primary' : 'inherit'} />
      {t.staticAsString(`races.detail.${isPublished ? 'published' : 'publish'}` as TranslationLockedKeys)}
    </ActionButton>
  );
};

export { PublishBtn };

import { campaignLinkOrderStore, campaignLinkStore } from '../stores';

import { getCampaignLinkData } from './fetchData';

export const mount = () => {
  getCampaignLinkData();
};

export const unmount = () => {
  campaignLinkOrderStore.clear();
  campaignLinkStore.clear();
};

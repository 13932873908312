import React from 'react';

import { FilterAutocompleteWithSearch } from 'components/Filter/DefaultFilters';
import { raceStatus } from 'components/RaceStatus';

import { FilterProps } from './index';

type Props = {
  value: nil | string | number;
} & FilterProps;

const options = () => [
  {
    value: '1',
    label: raceStatus('awaiting'),
    key: 1,
  },
  {
    value: '2',
    label: raceStatus('rejected'),
    key: 2,
  },
  {
    value: '3',
    label: raceStatus('approved'),
    key: 3,
  },
  {
    value: '4',
    label: raceStatus('draft'),
    key: 4,
  },
];

const ApproveStatuses = (props: Props) => {
  return <FilterAutocompleteWithSearch {...(props as any)} options={options()} />;
};

export { ApproveStatuses };

import { ExpandMore } from '@mui/icons-material';
import { Typography, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import type { Assistant } from 'modules/Distances/types';
import { getAssistantLabel } from 'modules/Distances/utils/getAssistantLabel';
import * as React from 'react';

import { Checkbox } from 'components/Fields';
import { Icon } from 'components/Icon';

import { t } from 'utils';

import { assignedAssistantsStyles, checkBoxStyles, mainPanelStyles, expansionPanelSummaryStyles } from '../styles';
import { ManageDistanceTooltip } from './ManageDistanceTooltip';

type Props = {
  selected: Array<Assistant>;
  onRemoveAssistant: Function;
  allowStartToggle: Function;
  allowFinishToggle: Function;
};

export const AssignedAssistants = ({ selected, allowStartToggle, allowFinishToggle, onRemoveAssistant }: Props) => {
  const classes = assignedAssistantsStyles();
  const checkBoxClasses = checkBoxStyles();
  const mainPanelClasses = mainPanelStyles();
  const summaryClasses = expansionPanelSummaryStyles();

  const removeAssistant = (e: React.SyntheticEvent, id: number | nil) => {
    e.stopPropagation();
    onRemoveAssistant(id);
  };

  return (
    <div className={classes.root}>
      {selected.map((assistant) => {
        const label = getAssistantLabel(assistant);

        return (
          <Accordion classes={mainPanelClasses} key={assistant.id}>
            <AccordionSummary
              classes={summaryClasses}
              expandIcon={<ExpandMore className={classes.expand} />}
              aria-controls='panel1bh-content'
              id='panel1bh-header'
            >
              <Typography className={classes.heading}>{label}</Typography>
              <IconButton aria-label='delete' className={classes.delete} onClick={(e) => removeAssistant(e, assistant.id)}>
                <Icon value='delete-outline' />
              </IconButton>
            </AccordionSummary>

            <AccordionDetails className={classes.details}>
              <div className={classes.box}>
                <Typography className={classes.detailsHeading}>{t.staticAsString('distances.steps.checkpointsForm.new.manage')}</Typography>
                <ManageDistanceTooltip message={t.staticAsString('distances.steps.checkpointsForm.new.tooltip') as any} />
              </div>
              <div className={classes.box}>
                <Checkbox
                  classes={checkBoxClasses}
                  label={t.staticAsString('distances.steps.checkpointsForm.allow_start') as any}
                  value={assistant.allow_start}
                  name='checkbox'
                  disabled={false}
                  onChange={() => allowStartToggle(assistant.id)}
                />
                <Checkbox
                  classes={checkBoxClasses}
                  label={t.staticAsString('distances.steps.checkpointsForm.allow_finish') as any}
                  value={assistant.allow_finish}
                  name='checkbox'
                  disabled={false}
                  onChange={() => allowFinishToggle(assistant.id)}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

import * as React from 'react';

type Props = {
  onClick?: (e: React.SyntheticEvent) => any;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  label?: string | TranslationLockedKeys;
  href?: string;
};

const AuthSubmit = ({ label, children, ...rest }: Props) => {
  return (
    <button className='btn auth-submit' {...rest}>
      {label || children}
    </button>
  );
};

export { AuthSubmit };

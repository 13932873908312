import Logo from 'img/brand-new-logo.svg';

const Header = () => {
  return (
    <header className='terms-header'>
      <div className='terms-logo-container'>
        <img className='logo' src={Logo} alt='Logo' />
      </div>
    </header>
  );
};

export { Header };

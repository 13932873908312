import axios from 'axios';

import { RESEND_VERIFICATION_LINK, RESEND_LINK } from 'src/constants';

import { t } from 'utils';
import { action } from 'utils/core/action';
import { request } from 'utils/core/request';

import { toastStore } from 'stores';

class ResendVerificationLinkService {
  @request({ action: RESEND_LINK })
  resendLinkRequest(): Promise<any> {
    return axios.post(RESEND_VERIFICATION_LINK);
  }

  @action({ action: RESEND_LINK })
  async resendLink(closeModal?: Function) {
    const [status, response] = await this.resendLinkRequest();

    if (status) {
      toastStore.show(t.staticAsString('organizer.sendLinkSuccess'));
    } else {
      toastStore.show(t.staticAsString('organizer.sendLinkError'));
    }
  }
}

const resendVerificationLinkService = new ResendVerificationLinkService();

export { resendVerificationLinkService };

import { Box, Button } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { Observer } from 'mobx-react';
import React from 'react';

import BannerBase from 'components/Banners/BannerBase';
import { Show } from 'components/Condition';

import { t } from 'utils';

import { resendVerificationLinkService } from 'services';

import { sessionStore } from 'stores';

import { VerifyBannerBodyText } from './VerifyBannerBodyText';

type Props = {};

const useStyles = makeStyles((theme) => ({
  wrapperBoxOuter: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 100,
  },
  wrapperBoxInner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

const StyledButton = withStyles((theme) => ({
  root: {
    height: 36,
    padding: '10px 16px',
    whiteSpace: 'nowrap',
    fontSize: 14,
    color: `${theme.palette.grey1}`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:focus': {
      color: theme.palette.grey1,
    },
  },
  label: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '16px',
    letterSpacing: '1.25px',
  },
}))(Button);

const VerifyEmailBanner = (props: Props) => {
  const classes = useStyles();

  const sendLink = () => {
    resendVerificationLinkService.resendLink();
  };

  return (
    <Observer>
      {() => {
        const isVerified = sessionStore.isOrganizerVerified;
        const userEmail = sessionStore.email;

        return (
          <Show if={!isVerified}>
            <Box className={classes.wrapperBoxOuter}>
              <BannerBase
                showCloseButton={false}
                content={
                  <Box className={classes.wrapperBoxInner}>
                    <VerifyBannerBodyText userEmail={userEmail} />
                    <StyledButton variant='outlined' onClick={sendLink}>
                      {t.staticAsString('banner.verifyEmail.link')}
                    </StyledButton>
                  </Box>
                }
              />
            </Box>
          </Show>
        );
      }}
    </Observer>
  );
};

export default VerifyEmailBanner;

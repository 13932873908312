/*
 * Clear stored messages and load new, becase of the version change
 */
import localeStore from 'stores/locale';

import { apiLoad } from './apiLoad';
import { set } from './set';

let isLoading: boolean;

async function reload() {
  if (isLoading) {
    return;
  }

  isLoading = true;

  const locale = localeStore.value;

  await apiLoad(locale);
  set(locale);

  isLoading = false;
}

export { reload };

import moment from 'moment';
import * as React from 'react';

type Props = {
  date_from: string;
  date_to: string;
  value: string;
  id: number | undefined;
  currency: string;
};

function ItemComponent({ date_from, date_to, value, id, currency }: Props) {
  const fromLabel = moment(date_from);
  const toLabel = moment(date_to);

  let dateLabel = '';

  if (fromLabel.isValid()) {
    dateLabel = fromLabel.format('DD.MM.YY');
  }

  if (toLabel.isValid() && toLabel !== fromLabel) {
    dateLabel = `${fromLabel.format('DD.MM.YY')} - ${toLabel.format('DD.MM.YY')}`;
  }

  return (
    <li className='price-item'>
      <p className='price-date'>{dateLabel}</p>
      <p className='price-value'>
        {value}
        <span className='price-sek'>{currency}</span>
      </p>
    </li>
  );
}

const Item = React.memo<Props>(ItemComponent);

export { Item };

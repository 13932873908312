import SettingsIcon from '@mui/icons-material/Settings';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';

import { Show } from 'components/Condition';

import { t } from 'utils';

export type TriggerProps = {
  withText?: boolean;
  onClick?: React.MouseEventHandler;
  dataTestId?: string;
};

function Trigger({ withText, dataTestId }: TriggerProps) {
  return (
    <div className='settings'>
      <IconButton
        className='icon-button-settings'
        aria-label='Settings'
        aria-controls='long-menu'
        aria-haspopup='true'
        size='large'
        data-test-id={dataTestId}
      >
        <SettingsIcon />
      </IconButton>

      <Show if={!!withText}>
        <span className={'menu-card-text'}>{t('shared.settings')}</span>
      </Show>
    </div>
  );
}

export { Trigger };

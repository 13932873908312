import { gpxGoogleMapStore, markersStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { CheckPoint, GpxGoogleMap } from 'src/modules/Distances/components/shared/Steps/GPX/types';

export const setMarker = (checkpoint: CheckPoint) => {
  const markers = markersStore.value;
  const { map } = gpxGoogleMapStore.value as GpxGoogleMap;

  if (markers && markers.length > 0) {
    markers[checkpoint.index].setPosition(checkpoint.coordinate);
    markers[checkpoint.index].setMap(map);
  }
};

import { t } from 'utils';

import { Distance } from 'models';

const base: NestedValidationScheme = {
  entity_id: {
    presence: {
      message: () => t.staticAsString('results.errors.validations.racer_id'),
    },
  },

  time_result: {
    presence: {
      allowEmpty: false,
    },
  },

  activity_link: {
    url: {
      message: () => t.staticAsString('races.new.errors.invalid_url'),
    },
  },
};

const generateNewResultContraints = (_distance: Distance): NestedValidationScheme => {
  return {
    ...base,
  };
};

export { generateNewResultContraints };

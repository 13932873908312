/* TODO
 * Add models mapping(optional).
 * Add find value method
 * Add find modelValue method
 * Add exists method
 */
import { set } from 'lodash';
import { observable, action, computed, makeObservable } from 'mobx';

abstract class Loadable<T, F> implements ILoadable<T, F> {
  // @ts-ignore
  extensions: Array<Extensionable>;

  @observable
  values: Array<T> = [];

  @observable
  page = 1;

  @observable
  paginationMeta: PaginationMeta = {};

  @observable
  selected: T | null = null;

  // TODO Replace filters params with resourcesParams
  @observable
  filters: F = {} as any;

  @observable
  resourceParams: F = {} as any;

  constructor() {
    makeObservable(this);
  }

  @action
  addValues(values: Array<T>, page: number, filters: F) {
    this.values = values;
    this.page = page;
    this.filters = filters;
  }

  @action
  appendValues(values: Array<T>, page: number, filters: F) {
    this.values = page !== 1 ? (this.values = [...this.values, ...values]) : (this.values = [...values]);
    this.page = page;
    this.filters = filters;
  }

  @action
  addPaginationMeta(meta: PaginationMeta) {
    this.paginationMeta = meta;
  }

  @action
  addValue(value: T, direction?: 'start' | 'end') {
    switch (direction) {
      case 'start':
        this.values = [value, ...this.values];
        return;
      case 'end':
        this.values = [...this.values, value];
        return;
      default:
        this.values = [...this.values, value];
    }
  }

  @action
  updateValue(value: T) {
    // @ts-ignore
    const index = this.values.findIndex((e) => e.id === value.id);
    if (index !== -1) {
      this.values.splice(index, 1, value);
    }
  }

  @action
  updateValues(...values: T[]) {
    values.forEach((el) => {
      this.updateValue(el);
    });
  }

  @action
  setValueByKey(key: string, value: any) {
    set(this.values, key, value);
  }

  @action
  clearData() {
    this.values = [];
  }

  @action
  removeValue(id: number) {
    // @ts-ignore
    this.values = this.values.filter((value) => value.id !== id);
  }

  @action
  addSelectedValue(value: T) {
    this.selected = value;
  }

  @action
  removeSelectedValue() {
    this.selected = null;
  }

  @action
  partialUpdateSelectedValue(
    changes: {
      [K in string]: any;
    },
  ) {
    if (!this.selected) {
      return;
    }

    this.selected = {
      ...this.selected,
      ...changes,
    };
  }

  @action
  clearFilters() {
    (this.extensions || []).forEach((ext) => {
      ext.reset();
    });
  }

  // All merged params (extensions + builtin filters)
  @computed
  get params(): FiltersType {
    const { filters, extensions } = this;

    return (extensions || []).reduce(
      (acc, ext) => {
        return { ...acc, ...ext.params };
      },
      { ...(filters as any) },
    );
  }

  @action
  clearAll() {
    this.clearData();
    this.removeSelectedValue();
    this.clearFilters();
  }
}

export { Loadable };

import { Button } from '@mui/material';
import { debounce, omit, keys } from 'lodash';
import { observer } from 'mobx-react';
import { helper } from 'racese-react-phone-input-2';
import * as React from 'react';
import { withRouter } from 'react-router-dom';

import { CONFIRM_POPUP_TYPES } from 'src/constants';

import { withProgressSpinner, withConfirmModal } from 'hocs';

import { Show } from 'components/Condition';
import { Checkbox, TextField, PhoneInput, SelectField, AutocompleteSelect } from 'components/Fields';

import { validation, newOneFieldValidation, validate, t } from 'utils';

import { loadCountryService } from 'services';

import { errorsStore, countriesStore, sessionStore } from 'stores';

import { PERSIST_SYSTEM_USER, LOAD_SYSTEM_USER } from '../../constants';

import { getName, getCurrentUserData, isSystemUserNeedCreator } from '../../utils';

import { SystemUser as SystemUserModel } from '../../models';

import { SystemUsers, organizersStore } from '../../stores';

import { Role } from '../../types';
import { base, createSystemUser as createSystemUserConstraints, updateSystemUser as updateSystemUserConstraints } from './validations';

type Props = {
  predefinedRole?: Role;
  predefinedCreator?: number;
  systemUsersStore: SystemUsers;
  organizersStore: typeof organizersStore;
  onClose(): void;
  entityId: number | null;
  clearSelectedOnUnmount: boolean;
  changedFields?: AnyObject;
  loadResource: AnyFunction;
  createOrUpdateResource: AnyFunction;
} & RouterProps &
  WithConfirmModalProps<'roleChangePopupFromAssistant'> &
  WithConfirmModalProps<'roleChangePopupFromMedical'> &
  HOC.withIntl;

type State = {
  systemUser: AnyObject;
  changedFields: AnyObject;
};

const action = PERSIST_SYSTEM_USER;

@withRouter
@withConfirmModal('roleChangePopupFromAssistant', {
  i18Title: 'systemUsers.roleChangePopup.mainTitle',
  i18Body: 'systemUsers.roleChangePopup.mainBodyFromAssistant',
  type: CONFIRM_POPUP_TYPES.confirm,
})
@withConfirmModal('roleChangePopupFromMedical', {
  i18Title: 'systemUsers.roleChangePopup.mainTitle',
  i18Body: 'systemUsers.roleChangePopup.mainBodyFromMedical',
  type: CONFIRM_POPUP_TYPES.confirm,
})
@withProgressSpinner(LOAD_SYSTEM_USER)
@observer
export class Form extends React.Component<Props, State> {
  state = {
    systemUser: {
      id: 0,
      email: '',
      role: '',
      firstname: '',
      lastname: '',
      full_name: '',
      post_code: '',
      password: '',
      confirm_password: '',
      created_by: { key: (sessionStore.isOrganizer && sessionStore.user?.user_id) || 0 },
      phone: '',
      city: '',
      address: '',
      country_id: {},
      send_password_changed_email: false,
      payments: [],
      roles: [],
    },
    changedFields: {},
  };

  static defaultProps = {
    clearSelectedOnUnmount: true,
  };

  debouncedValidation = debounce((name: string) => {
    const constrainsField = { [name]: base[name] };

    errorsStore.clean(action, name);
    newOneFieldValidation(action, this.state.systemUser, constrainsField);
  }, 400);

  @validation({ action, constrains: updateSystemUserConstraints })
  validateUpdate() {
    return this.state.systemUser;
  }

  @validation({ action, constrains: createSystemUserConstraints })
  validateCreate() {
    return this.state.systemUser;
  }

  componentDidMount() {
    const { entityId, predefinedCreator, predefinedRole } = this.props;
    loadCountryService.loadResources();

    if (entityId) {
      this.props.loadResource(entityId.toString());
    }

    const role = predefinedRole ? predefinedRole.name : this.state.systemUser.role;
    const created_by = predefinedCreator
      ? { key: predefinedCreator, label: '', value: predefinedCreator }
      : this.state.systemUser.created_by;

    if (this.props.predefinedRole) {
      this.setState({
        ...this.state,
        systemUser: {
          ...this.state.systemUser,
          role,
          created_by,
        },
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const systemUser = this.props.systemUsersStore.selected;

    // prevState.systemUser.id === 0 was causing problem that after user update state is not refreshed
    // prevState.systemUser.id will represent previously edited user and in case it is different -
    // form will be updated properly
    if (systemUser && prevState.systemUser.id !== systemUser?.id) {
      const user = new SystemUserModel(systemUser);
      const role = user.role();

      const countryData = helper.takeCountryData(systemUser.phone || '');
      const phone = {
        phone: systemUser.phone || '',
        countryData: {
          dialCode: countryData.dialCode,
          name: countryData.name,
          countryCode: countryData.iso2,
        },
      };

      this.setState({
        ...this.state,
        systemUser: {
          ...systemUser,
          phone,
          role,
        },
      });
    }
  }

  componentWillUnmount() {
    if (this.props.clearSelectedOnUnmount) {
      this.props.systemUsersStore.removeSelectedValue();
    }

    errorsStore.clearError(action);
    errorsStore.clear(action);
  }

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const { entityId } = this.props;
    const validateFunc = (scheme: any) => validate(action, this.state.systemUser, scheme);
    const isValid = entityId ? validateFunc(updateSystemUserConstraints) : validateFunc(createSystemUserConstraints);

    if (!isValid || !!this.errors().length) {
      return;
    }

    this.props.createOrUpdateResource(entityId || 0, this._formatData(), this._deletedFields());
  };

  isCreatorAdmin = (id) => {
    return id === sessionStore.user?.user_id;
  };

  onChangeCountry = ({ name, value }: any) => {
    this.onChangeWithoutEvent({ name, value });
  };

  onChangeCreator = ({ name, value }: any) => {
    this.onChangeWithoutEvent({ name, value });
  };

  onChangeRegular = (e: React.SyntheticEvent<HTMLInputElement>, changedValue: any) => {
    let value: any = null;
    let name: any = '';

    switch (e.type) {
      case 'click':
        // @ts-ignore
        value = e.target.value;
        // @ts-ignore
        name = e.target.name;
        break;
      case 'change':
        value = e.currentTarget.value;
        name = e.currentTarget.name;
        break;
      default:
        value = e.currentTarget.value;
        name = e.currentTarget.name;
    }

    this.setState(
      {
        ...this.state,
        systemUser: {
          ...this.state.systemUser,
          [name]: value,
        },
        changedFields: {
          ...this.state.changedFields,
          [name]: changedValue,
        },
      },
      () => this.debouncedValidation(name),
    );
  };

  onChangePhone = (name: string, value: Object) => {
    this.onChangeWithoutEvent({ name, value }, () => this.debouncedValidation(name));
  };

  onChangeCheckbox = (e: React.SyntheticEvent<HTMLInputElement>) => {
    // @ts-ignore
    const { name, checked } = e.target;
    this.onChangeWithoutEvent({ name, value: checked });
  };

  onChangeWithoutEvent = ({ name, value }: { name: string; value: any }, callback?: any) => {
    this.setState(
      {
        ...this.state,
        systemUser: {
          ...this.state.systemUser,
          [name]: value,
        },
        changedFields: {
          ...this.state.changedFields,
          [name]: value,
        },
      },
      callback || null,
    );
  };

  errors = () => {
    const errors = errorsStore.errors.api[action];
    const validationErrors = errorsStore.erroribus[action];

    if (validationErrors) {
      return validationErrors;
    }

    if (errors) {
      return errors.formatErrors();
    }

    return {};
  };

  defaultInputProps = (name: string): any => {
    return {
      name,
      value: this.state.systemUser[name],
      onChange: this.onChangeRegular,
      errors: errorsStore.get(action, name),
      fullWidth: true,
      label: t.staticAsString(`systemUsers.form.${name}` as TranslationLockedKeys),
    };
  };

  organizerOptions = () => {
    const organizers = this.props?.organizersStore?.value || [];

    const options = organizers.map<SelectOption>((org: OrganizerType) => {
      return {
        key: org.id,
        label: getName(org),
        value: org.id,
      };
    });

    const currentLoggedUser = getCurrentUserData();

    if (sessionStore.isOrganizer) {
      return [currentLoggedUser, ...options];
    }

    return options;
  };

  defaultRoleProps = (name: string) => {
    const { predefinedRole = '' } = this.props;

    let roles = this.props.systemUsersStore.roles;
    if (predefinedRole) {
      roles = [predefinedRole];
    }
    const options = roles.map<SelectOption>((role: Role) => ({
      key: role.name,
      label: t.staticAsString(`systemUsers.form.${role.name}` as TranslationLockedKeys),
      value: role.name,
    }));

    return {
      ...this.defaultInputProps(name),
      options,
    };
  };

  onChangeRole = (e: React.SyntheticEvent, changedValue: any) => {
    // @ts-ignore
    const value = e.target.value;
    // @ts-ignore
    const name = e.target.name;
    let createdByOption: { key: number } | number = this.state.systemUser.created_by;

    const changeFrom = this.state.systemUser[name];
    const changeTo = value;
    const alreadyChanged = this.state.changedFields[name];

    const performChange = (createdBy = createdByOption) => {
      this.setState({
        ...this.state,
        systemUser: {
          ...this.state.systemUser,
          [name]: value,
          created_by: createdBy,
        },
        changedFields: {
          ...this.state.changedFields,
          [name]: changedValue,
          created_by: createdBy,
        },
      });
    };

    if (!alreadyChanged) {
      if (changeFrom === 'medical' && changeTo === 'assistant') {
        this.props.roleChangePopupFromMedical.open(() => performChange());

        return;
      }
      if (changeFrom === 'assistant' && changeTo === 'medical') {
        this.props.roleChangePopupFromAssistant.open(() => performChange());

        return;
      }
    }

    if (['medical', 'assistant'].includes(changeFrom) && ['organizer', 'admin'].includes(changeTo)) {
      performChange(0);
      return;
    }

    performChange();

    this.debouncedValidation(name);
  };

  _isSendSendPasswordChangedEmailIsShown = () => {
    const { entityId, predefinedRole } = this.props;
    const { password } = this.state.systemUser;

    if (predefinedRole) {
      return false;
    }

    if (!entityId) {
      return true;
    }

    if (!!password) {
      return true;
    }

    return false;
  };

  _formatData = (): any => {
    const { systemUser } = this.state;
    const { entityId, predefinedRole } = this.props;

    const omits = ['payments'];

    if (!systemUser.password && entityId) {
      omits.push('send_password_changed_email', 'password');
    }

    if (!isSystemUserNeedCreator(systemUser.role)) {
      omits.push('created_by');
    }

    const data = omit(systemUser, omits);

    // TODO TS, looks like type mismatch
    // @ts-ignore
    const phone = data.phone?.phone;

    return {
      ...data,
      predefinedRole,
      phone,
    };
  };

  _deletedFields = () => {
    // @ts-ignore
    return keys(this.state.changedFields).filter<string>((name) => {
      let isDeleted = false;

      switch (name) {
        case 'phone':
          // @ts-ignore
          isDeleted = !Boolean(this.state.systemUser.phone?.phone);
          break;
        default:
          isDeleted = !Boolean(this.state.systemUser[name]);
          break;
      }

      return isDeleted;
    });
  };

  _isCreatedByEnabled = () => {
    if (sessionStore.isOrganizer) {
      return false;
    }
    const { predefinedCreator } = this.props;
    const { systemUser } = this.state;

    return Boolean(!predefinedCreator && isSystemUserNeedCreator(systemUser.role));
  };

  render() {
    // Note, necessary for mobx
    // eslint-disable-next-line
    this.props.systemUsersStore.selected;

    const { systemUser } = this.state;
    const { onClose, entityId } = this.props;

    return (
      <div className='content main-organizer-form'>
        <Show if={sessionStore.isAdmin}>
          <h1>{t.staticAsString(`systemUsers.${systemUser.id ? 'edit' : 'addNew'}` as TranslationLockedKeys)}</h1>
        </Show>
        <Show if={sessionStore.isOrganizer}>
          <h1>{t.staticAsString(`systemUsers.raceAssistant.${systemUser.id ? 'edit' : 'addNew'}` as TranslationLockedKeys)}</h1>
        </Show>
        <form className='organizer-form new-organizer' onSubmit={this.onSubmit}>
          <div className='form-content-wrapper'>
            <div className='form-fields-wrapper'>
              <TextField {...this.defaultInputProps('email')} />
              <TextField
                {...this.defaultInputProps('password')}
                type='password'
                label={t.staticAsString(`systemUsers.form.${entityId ? 'passwordEdit' : 'password'}` as TranslationLockedKeys)}
              />
              <TextField
                {...this.defaultInputProps('confirm_password')}
                type='password'
                label={t.staticAsString(
                  `systemUsers.form.${entityId ? 'passwordEditConfirm' : 'passwordConfirm'}` as TranslationLockedKeys,
                )}
              />
              <Show if={this._isCreatedByEnabled()}>
                <AutocompleteSelect
                  {...this.defaultInputProps('created_by')}
                  options={this.organizerOptions()}
                  onChange={this.onChangeCreator}
                />
              </Show>
              <Show if={!this.props.predefinedRole}>
                <SelectField {...this.defaultRoleProps('role')} onChange={this.onChangeRole} />
              </Show>
              <TextField
                {...{
                  ...this.defaultInputProps('full_name'),
                  label: t.staticAsString(`systemUsers.form.${sessionStore.isAdmin ? 'full_name' : 'raceAssistantFullName'}`),
                }}
              />
              <TextField {...this.defaultInputProps('firstname')} />
              <TextField {...this.defaultInputProps('lastname')} />
              <PhoneInput {...this.defaultInputProps('phone')} onChange={this.onChangePhone} />
              <TextField {...this.defaultInputProps('address')} />
              <TextField {...this.defaultInputProps('city')} />
              <AutocompleteSelect
                {...this.defaultInputProps('country_id')}
                options={countriesStore.valuesForCountryDropdown()}
                onChange={this.onChangeCountry}
              />
              <TextField {...this.defaultInputProps('post_code')} />
              <Show if={this._isSendSendPasswordChangedEmailIsShown()}>
                <Checkbox {...this.defaultInputProps('send_password_changed_email')} onChange={this.onChangeCheckbox} />
              </Show>
              <div className='btn-group control-btn '>
                <Button className='cancel' onClick={() => onClose()}>
                  {t.staticAsString('races.new.cancel')}
                </Button>
                <Button className='submit' onClick={(e) => this.onSubmit(e)}>
                  {t.staticAsString('systemUsers.form.submit')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

import { BooleanStore, DummyStore } from 'stores';

import type { EditSystemUserId, Role } from '../types';
import { SystemUserFormStore } from './systemUserForm.store';
import { SystemUsers } from './systemUsers';

const systemUserRolesStore = new DummyStore<Role[]>([]);

const systemUsersStore = new SystemUsers();
const editIdStore = new DummyStore<EditSystemUserId>();
const organizersStore = new DummyStore<OrganizerType[]>();
const editModalStore = new BooleanStore();
const systemUserFormStore = new SystemUserFormStore();

export { systemUserRolesStore, systemUsersStore, systemUserFormStore, SystemUsers, editIdStore, editModalStore, organizersStore };

import moment from 'moment';

import { BE_FORMAT } from 'src/constants';

import { parsedDate, inputDate } from '../types/index';
import { invalidDate } from './invalidDate';
import { validateBeDate } from './validate';

function parse(date: inputDate, type: BEDateFormat, isUtc: boolean): parsedDate {
  const format = BE_FORMAT[type];

  const isInvalid = !validateBeDate(date, type);

  isInvalid && invalidDate(date, type);

  const init = isUtc ? moment.utc : moment;

  return init(date, format, true);
}

function parseDateUtc(date: inputDate, type: BEDateFormat): parsedDate {
  return parse(date, type, true);
}

function parseDate(date: inputDate, type: BEDateFormat): parsedDate {
  return parse(date, type, false);
}

export { parseDate, parseDateUtc };

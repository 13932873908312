import { Moment } from 'moment';

import { BE_DATE_FORMAT } from 'src/constants/time';

import { CommissionTableFilters } from '../types/filters.type';

const currencies = {
  4: 'USD',
  1: 'SEK',
  8: 'NZD',
  3: 'NOK',
  7: 'GBP',
  2: 'EUR',
  6: 'CAD',
};

const IdFields = [''];
const DateFields = [''];
const LikeFields = ['email'];
const BetweenFields = [''];
export namespace CommissionFiltersMappers {
  export function toBackend(filters: CommissionTableFilters[]) {
    const searchFilters = filters.reduce(
      (acc, { id, value }) => {
        if (IdFields.includes(id)) {
          acc.search += `${id}:${value.value};`;
          acc.searchFields += `${id}:=;`;
        }
        if (DateFields.includes(id)) {
          acc.search += `${id}:${mapDaterange(value.startDate, value.endDate)};`;
          acc.searchFields += `${id}:between;`;
        }
        if (LikeFields.includes(id)) {
          if (id === 'payment_info.currency') value = currencies[Number(value)];
          acc.search += `${id}:${value};`;
          acc.searchFields += `${id}:like;`;
        }
        if (BetweenFields.includes(id)) {
          acc.search += `${id}:${value.join(',')};`;
          acc.searchFields += `${id}:between;`;
        }
        return acc;
      },
      {
        search: '',
        searchFields: '',
      },
    );

    searchFilters.search = searchFilters.search.substring(0, searchFilters.search.length - 1);
    searchFilters.searchFields = searchFilters.searchFields.substring(0, searchFilters.searchFields.length - 1);
    return searchFilters;
  }
}

const mapDaterange = (start: Moment, end: Moment): string => {
  return `${start.format(BE_DATE_FORMAT)},${end.format(BE_DATE_FORMAT)}`;
};

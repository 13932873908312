import { errorsStore } from 'stores';

import { RACE_FORM_ID } from '../constants';

export const scrollToErrorField = () => {
  const errorInputName = errorsStore.getFirstErrorInputName(RACE_FORM_ID);
  const formScroll = document.getElementsByName(errorInputName)[0];
  formScroll?.scrollIntoView({ block: 'end', behavior: 'smooth' });
  return Boolean(formScroll);
};

import { Info } from '@mui/icons-material';
import { Box, createFilterOptions, FormLabel, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Observer } from 'mobx-react';
import { couponFormDistancesService, couponFormRaceService } from 'modules/CouponsForm/services';
import moment from 'moment';
import React, { ReactElement } from 'react';

import { AutocompleteSelectInfinityLoad, Form, InputWrapper, WrappedRadio } from 'components/Form';
import { DatePicker, MultiCheckbox, MultiSelect, TextField } from 'components/Form/Fields';

import { t } from 'utils/t';

import { form } from 'stores';

import { COUPON_CODE_TYPES, COUPONS_FORM_ID, RADIO_AMOUNT, RADIO_PERCENTAGE } from '../constants';

import { couponFormDistanceStore, couponFormRacesStore } from '../stores';

const FieldContainer = withStyles({ root: { paddingBottom: 19 } })(Box);
const filterOptions = createFilterOptions<any>({
  stringify: (el) => el.label,
});

const StyledTypography = withStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
}))(Typography);

const StyledFormLabel = withStyles({
  root: {
    marginBottom: 10,
  },
})(FormLabel);

function CouponsForm(): ReactElement {
  return (
    <Observer
      render={() => {
        const isRaceSelected = () => Boolean(form.fetch(COUPONS_FORM_ID, 'race_id'));

        const getDistancesLabel = () => {
          if (!isRaceSelected()) return t.staticAsString('coupons.new.selectRaceFirst');
          return t.staticAsString('coupons.new.distances');
        };

        const loadMoreRaces = () => {
          couponFormRaceService.loadRaces();
        };

        const loadMoreDistances = () => {
          const race: { id: number; label: string } = form.fetch(COUPONS_FORM_ID, 'race_id');
          couponFormDistancesService.loadDistances(race.id);
        };
        const searchRaces = (text: string) => {
          couponFormRacesStore.clear();
          couponFormRacesStore.setParams({
            page: 1,
            search: `${couponFormRacesStore.params.search};name:${text}`,
            searchFields: `${couponFormRacesStore.params.searchFields};name:like`,
          });
          couponFormRaceService.loadRaces();
        };

        return (
          <Form id={COUPONS_FORM_ID} cleanErrorOnChange>
            <FieldContainer>
              <InputWrapper
                name='name'
                settings={{
                  additional: { fullWidth: true },
                  label: `${t.staticAsString('coupons.new.name')}`,
                  view: {
                    required: true,
                    variant: 'outlined',
                  },
                }}
                Component={TextField}
              />
            </FieldContainer>
            <FieldContainer>
              <InputWrapper
                name='description'
                settings={{
                  additional: { fullWidth: true },
                  label: `${t.staticAsString('coupons.new.description')}`,
                  required: false,
                  view: {
                    variant: 'outlined',
                  },
                }}
                Component={TextField}
              />
            </FieldContainer>
            <FieldContainer>
              <InputWrapper
                name='code'
                settings={{
                  additional: { fullWidth: true },
                  label: `${t.staticAsString('coupons.new.code')}`,
                  view: {
                    required: true,
                    variant: 'outlined',
                  },
                }}
                Component={TextField}
              />
            </FieldContainer>
            <FieldContainer>
              <InputWrapper
                name='race_id'
                settings={{
                  additional: {
                    fullWidth: true,
                    options: couponFormRacesStore.races,
                    filterOptions,
                  },
                  label: `${t.staticAsString('coupons.new.race')}`,
                  view: {
                    required: true,
                    variant: 'outlined',
                    reservePlaceForHelperText: true,
                  },
                  hasMore: couponFormRacesStore.hasMore,
                  loadMore: loadMoreRaces,
                  onSearch: searchRaces,
                }}
                Component={AutocompleteSelectInfinityLoad}
              />
            </FieldContainer>
            <FieldContainer>
              <InputWrapper
                name='distances'
                settings={{
                  additional: { fullWidth: true },
                  label: getDistancesLabel(),
                  required: true,
                  variant: isRaceSelected() ? 'outlined' : 'filled',
                  placeholder: 'Close',
                  options: couponFormDistanceStore.distances ?? [],
                  disabled: !isRaceSelected(),
                  optionsCount: couponFormDistanceStore.distances?.length,
                  customRenderAllValue: t.staticAsString('coupons.allDistances'),
                  hasMore: couponFormDistanceStore.hasMore,
                  loadMore: loadMoreDistances,
                }}
                Component={MultiSelect}
              />
            </FieldContainer>
            <FieldContainer>
              <InputWrapper
                name='date_range'
                settings={{
                  additional: {
                    fullWidth: true,
                  },
                  minDate: moment(),
                  label: `${t.staticAsString('coupons.new.date_from')} — ${t.staticAsString('coupons.new.date_to')}`,
                  view: {
                    required: true,
                  },
                }}
                Component={DatePicker}
              />
            </FieldContainer>
            <FieldContainer>
              <StyledFormLabel>{t('coupons.new.discount_type')}*</StyledFormLabel>
              <InputWrapper
                name='discount_percentage'
                settings={{
                  required: true,
                  options: [RADIO_AMOUNT, RADIO_PERCENTAGE],
                }}
                Component={WrappedRadio}
              />
            </FieldContainer>

            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <FieldContainer>
                  <InputWrapper
                    name='discount_amount'
                    settings={{
                      type: 'number',
                      additional: { fullWidth: true },
                      label: `${t.staticAsString('coupons.new.discount_amount')}`,
                      view: {
                        required: true,
                        variant: 'outlined',
                      },
                    }}
                    Component={TextField}
                  />
                </FieldContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <FieldContainer>
                  <InputWrapper
                    name='qty'
                    settings={{
                      type: 'number',
                      additional: { fullWidth: true },
                      label: `${t.staticAsString('coupons.new.qty')}`,
                      view: {
                        required: true,
                        variant: 'outlined',
                      },
                    }}
                    Component={TextField}
                  />
                </FieldContainer>
              </Grid>
            </Grid>
            <Box display='flex' alignItems='center'>
              <StyledTypography gutterBottom>
                {t('coupons.new.codeType')}*
                <Tooltip title={<Typography variant='body2'>{t('coupons.new.typeInfo')}</Typography>}>
                  <IconButton size='large'>
                    <Info color='disabled' />
                  </IconButton>
                </Tooltip>
              </StyledTypography>
            </Box>
            <FieldContainer>
              <InputWrapper
                name='coupon_type'
                settings={{
                  additional: {
                    options: COUPON_CODE_TYPES,
                  },
                }}
                Component={MultiCheckbox}
              />
            </FieldContainer>
          </Form>
        );
      }}
    />
  );
}

export { CouponsForm };

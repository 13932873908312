import { errorsStore, form } from 'src/stores';

import { SystemUserFormPageConstants } from '../../constants/systemUserFormPage.constants';

import { systemUserFormStore, organizersStore } from '../../stores';

export const unmountSystemUserForm = () => {
  systemUserFormStore.clearAll();
  organizersStore.clear();
  form.clean(SystemUserFormPageConstants.systemUsersFormId);
  errorsStore.clearError(SystemUserFormPageConstants.systemUsersFormId);
  errorsStore.clear(SystemUserFormPageConstants.systemUsersFormId);
};

import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react';
import { EXPORT_ORDER_DETAIL } from 'modules/exportPayment/constants';
import * as React from 'react';

import { withProgressSpinner } from 'hocs';

import { progressStore } from 'stores';

import { PaymentInfo, PaymentTechInfo, AccountInfo, PayedItems, Breadcrumbs, TransactionsTable } from '../../components';

import { INITIALIZE_DETAILS } from '../../constants';

import { OrderDetails } from '../../models';

import { ContextType } from '../../context';
import { Context } from '../../context';

type Props = {};

type State = {};

@withProgressSpinner(INITIALIZE_DETAILS)
@observer
export class Details extends React.Component<Props, State> {
  context: ContextType = null as any;
  static contextType = Context;

  componentDidMount(): void {
    this.context.details.actions.mountDetails();
  }

  componentWillUnmount(): void {
    this.context.details.actions.unmountDetails();
  }

  render() {
    const { getBreadcrumbs } = this.context.details.selections;
    const { detailsStore } = this.context.details.stores;
    const { generateOnExportPaymentDetail } = this.context.details.actions;
    if (isEmpty(detailsStore.orderDetails)) {
      return null;
    }

    const paymentModel = new OrderDetails(detailsStore.orderDetails, detailsStore.payment);
    const distanceTitle = paymentModel.distanceName();
    return (
      <div className='content main-container-detail'>
        <Breadcrumbs items={getBreadcrumbs.get() as any} />
        <PaymentInfo
          isPdfDownloading={progressStore.isLoading(EXPORT_ORDER_DETAIL)}
          value={paymentModel}
          onExport={generateOnExportPaymentDetail(detailsStore.orderDetails.id)}
        />
        <div className='additional-info-container'>
          <AccountInfo value={paymentModel} />
          <PaymentTechInfo value={paymentModel} />
        </div>
        <PayedItems value={paymentModel} />
        <TransactionsTable distanceTitle={distanceTitle} />
      </div>
    );
  }
}

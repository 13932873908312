import { SynchronizationTypeEnum } from 'modules/Synchronisation/Table/types/synchronization.type';

export namespace SynchronizationsConstants {
  export const SynchronizationsInit = 'SynchronizationsInit';
  export const SynchronizationsTable = 'SynchronizationsTable';
  export const SynchronizationForm = 'SynchronizationForm';

  export const SynchronizationType: { [key in SynchronizationTypeEnum]: string } = {
    1: 'EQ Timing',
    2: 'Race Result',
  };
}

import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { ERRORS_PREFIXES } from 'modules/Distances/constants';
import { clearFieldError, clearNamespaceErrors, getRelationErrors } from 'modules/Distances/utils/errors';
import moment, { Moment } from 'moment';
import numberFormatter from 'number-to-words';
import * as React from 'react';
import validate from 'validate.js';

import { BACKEND_DATE_FORMATE } from 'src/constants';

import { DatePicker, TextField } from 'components/Form';

import { t } from 'utils';

type Props = {
  onChange: (id: number | string, data: PriceType, callback?: Function, isNeedLiveUpdate?: boolean) => void;
  onRemove: (id: number | string) => void;
  value: PriceType;
  index: number;
  minPriceDate: string | any;
  maxPriceDate: string | any;
  helperData: any;
  currency: string;
  dateFrom?: Moment | '';
};

type State = {
  open: boolean;
  assistantOpen: boolean;
};

@observer
class Item extends React.Component<Props, State> {
  onlyNumberRegexp = /^[\d.]*$/;

  componentDidMount() {
    const { dateFrom } = this.props;
    !this.props.value.date_from && this.onChange('date_from', moment(dateFrom).format('YYYY-MM-DD'), false);
  }

  onChange = (name: string, changedValue: unknown, isNeedLiveUpdate = true) => {
    const { index, value, onChange } = this.props;
    const errorToClear = getRelationErrors(index, ERRORS_PREFIXES.prices, `prices.${name}`).length ? `prices.${name}` : name;

    const changeData = { ...value, [name]: changedValue };

    const id = value.id || value.__id || '';
    onChange(
      id,
      changeData,
      () => {
        clearFieldError(index, ERRORS_PREFIXES.prices, errorToClear);
        clearNamespaceErrors(ERRORS_PREFIXES.prices);
      },
      isNeedLiveUpdate,
    );
  };

  onChangePlain = (e: React.ChangeEventHandler | any) => {
    const { name, value } = e.currentTarget;

    this.onChange(name, value);
  };

  onChangeOnlyNumber = (e: React.ChangeEventHandler | any) => {
    const { name, value } = e;
    const isNumber = this.onlyNumberRegexp.test(value.toString());

    if (isNumber) {
      this.onChange(name, value);
    }
  };

  onChangeWithoutEvent = ({ name, value }: { name: string; value: any }) => {
    this.onChange(name, value);
  };

  onSingleDateChange = ({ name, value }) => {
    this.onChange(name, isEmpty(value) ? '' : value.format(BACKEND_DATE_FORMATE));
  };

  defaultInputProps = (name: string) => {
    const value = (this.props.value as AnyObject)[name];

    return {
      value: value,
      name,
      errors: [],
      fullWidth: true,
    };
  };

  defaultSingleDateInputProps = (name: string) => {
    const { value, index } = this.props;

    const date = (value as AnyObject)[name];
    // const error = undefined;
    const error =
      getRelationErrors(index, ERRORS_PREFIXES.prices, 'prices.date_from')?.[0] ||
      getRelationErrors(index, ERRORS_PREFIXES.prices, 'date_from')?.[0];

    return {
      value: date && moment.utc(date),
      name,
      error,
      fullWidth: true,
    };
  };

  onRemove = () => {
    const { onRemove, value } = this.props;
    const id = value.id || value.__id || '';
    onRemove(id);
  };

  _dateRestriction = (): {
    [K in string]: moment.Moment;
  } => {
    const { minPriceDate, maxPriceDate } = this.props;

    const minDate = moment.utc(minPriceDate);
    const maxDate = moment.utc(maxPriceDate);

    if (minDate.isValid() && maxDate.isValid()) {
      return {
        minDate,
        maxDate,
      };
    }

    return {};
  };

  render() {
    const { index, value, currency, dateFrom } = this.props;
    const ordinalTitle = validate.capitalize(numberFormatter.toWordsOrdinal(index + 1));
    const error =
      getRelationErrors(index, ERRORS_PREFIXES.prices, 'prices.value')?.[0] ||
      getRelationErrors(index, ERRORS_PREFIXES.prices, 'value')?.[0];

    if (value._delete) {
      return null;
    }

    return (
      <li className='distance-price-item'>
        <TextField
          {...this.defaultInputProps('value')}
          label={t.staticAsString('distances.steps.pricesForm.value', { index: ordinalTitle, currency }) as any}
          error={error}
          onChange={this.onChangeOnlyNumber}
        />
        <DatePicker
          {...this.defaultSingleDateInputProps('date_from')}
          label={t.staticAsString('distances.steps.pricesForm.date_from')}
          onChange={this.onSingleDateChange}
          {...this._dateRestriction()}
          additional={{
            isRange: false,
          }}
        />
        <div className='remove-item-wrapper'>
          {index ? (
            <span className='remove-item-label' onClick={this.onRemove}>
              {t.staticAsString('distances.steps.pricesForm.delete')}
            </span>
          ) : (
            ''
          )}
        </div>
      </li>
    );
  }
}

export { Item };

import { t } from 'utils';

export const BIB_MIN = 0;
export const BIB_MAX = 99999;

export const generateBib = (): any => {
  const presence = {
    allowEmpty: false,
    message: t.staticAsString('validation.required'),
  };

  return {
    bibRace: {
      presence,
    },

    bibDistances: {
      presence,
    },

    bibStartingDistance: {
      presence,
    },

    bibStarting: {
      presence: {
        allowEmpty: true,
      },
      equality: {
        attribute: 'bibStartingDistance',
        message: t.staticAsString('supportTaskManager.form.validation.bibNumber'),
        comparator: function (bibStarting, bibStartingDistance) {
          if (bibStartingDistance) {
            return bibStarting > BIB_MIN && bibStarting < BIB_MAX;
          }

          return false;
        },
      },
    },
  };
};

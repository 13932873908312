import moment, { Moment } from 'moment';

import { BE_DATETIME_FORMAT } from 'src/constants/time';

import { TableFilters } from '../../shared/types';

const DateFields = ['date'];
const LikeFields = ['title', 'token', 'distance.name', 'race.name', 'wave.name'];
const BetweenFields = ['id', 'max_usages', 'used_times'];

export function filtersToBackend(filters: TableFilters[]) {
  const searchFilters = filters.reduce(
    (acc, { id, value }) => {
      if (DateFields.includes(id)) {
        switch (id) {
          case 'date':
            acc.search += `date_from:${moment(value.startDate).startOf('day').format(BE_DATETIME_FORMAT)};`;
            acc.search += `date_to:${moment(value.endDate).endOf('day').format(BE_DATETIME_FORMAT)};`;
            acc.searchFields += `date_from:>=;`;
            acc.searchFields += `date_to:<=;`;
            break;
          default:
            acc.search += `${id}:${mapDaterange(value.startDate, value.endDate)};`;
            acc.searchFields += `${id}:between;`;
        }
      }
      if (LikeFields.includes(id)) {
        switch (id) {
          case 'distance.name':
          case 'race.name':
          case 'wave.name':
            acc.search += `${id.split('.').join('.translations.')}:${value};`;
            acc.searchFields += `${id.split('.').join('.translations.')}:like;`;
            break;
          default:
            acc.search += `${id}:${value};`;
            acc.searchFields += `${id}:like;`;
        }
      }
      if (BetweenFields.includes(id)) {
        const [start, end] = value;
        switch (id) {
          case 'id':
          case 'max_usages':
          case 'used_times':
            if (start === undefined) {
              acc.search += `${id}:${end};`;
              acc.searchFields += `${id}:<=;`;
              break;
            }
            if (end === undefined) {
              acc.search += `${id}:${start};`;
              acc.searchFields += `${id}:>=;`;
              break;
            }
          default:
            acc.search += `${id}:${value.join(',')};`;
            acc.searchFields += `${id}:between;`;
        }
      }
      return acc;
    },
    {
      search: '',
      searchFields: '',
    },
  );

  searchFilters.search = searchFilters.search.substring(0, searchFilters.search.length - 1);
  searchFilters.searchFields = searchFilters.searchFields.substring(0, searchFilters.searchFields.length - 1);
  return searchFilters;
}

const mapDaterange = (start: Moment, end: Moment): string => {
  return `${start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(BE_DATETIME_FORMAT)},${end
    .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    .format(BE_DATETIME_FORMAT)}`;
};

import { reaction } from 'mobx';

import { form } from 'src/stores';

import { CAMPAIGN_LINKS_FORM_ID } from '../constants';

import { loadWaves } from '../actions';

import { disposersStore } from '../stores';

export const observeDistanceField = () => {
  const disposer = reaction(
    () => form.fetch(CAMPAIGN_LINKS_FORM_ID, 'distance_id'),
    (distance: AnyObject) => {
      form.onChange(CAMPAIGN_LINKS_FORM_ID, 'wave_id', null);

      if (Boolean(distance?.key)) {
        loadWaves(distance.key);
      }
    },
  );

  disposersStore.register(disposer);
};

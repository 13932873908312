import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

import { progressStore } from 'stores';

import { TransactionConstants } from '../constants/transaction.constants';

import { TransactionService } from '../services/transaction.service';

import { TransactionStore } from '../stores/transaction.store';

export namespace TransactionActions {
  export async function init() {
    progressStore.log(TransactionConstants.TransactionForm, 'progress');
    const match = matchPath(history.location.pathname, ROUTES.transactionRoute);
    const { transactionId } = match.params as any;
    const tx = await TransactionService.load(+transactionId);
    TransactionStore.transaction.set(tx);
    progressStore.log(TransactionConstants.TransactionForm, 'completed');
  }
}

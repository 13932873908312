import { Observer } from 'mobx-react';
import type { Assistant } from 'modules/Distances/types';
import { getAssistantLabel } from 'modules/Distances/utils/getAssistantLabel';
import React from 'react';

import { AutocompleteWithTags } from 'components/Fields';

import { t } from 'utils';

type Props = {
  value: Array<Assistant>;
  options: Array<Assistant>;
  onChange: Function;
} & HOC.withIntl;

let TimingAssistantsAutocomplete = ({ onChange, options, value }: Props) => {
  const defProps = {
    name: 'timing_assistants',
    placeholder: t.staticAsString('distances.steps.checkpointsForm.new.placeholder') as any,
    label: '',
    errors: [],
    disabled: false,
    onClose: (values) => {
      onChange(values);
    },
    getOptionLabel: getAssistantLabel,
  };

  return (
    <Observer>
      {() => {
        return (
          <AutocompleteWithTags
            {...defProps}
            options={options}
            value={value}
            noOptionsText={t.staticAsString('autocomplete.noOptions') as any}
          />
        );
      }}
    </Observer>
  );
};

export { TimingAssistantsAutocomplete };

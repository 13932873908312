import { isUndefined } from 'lodash';

import { handleMissingPrevCheckpoint, handleMissingStartTime } from '../../../actions';

import { prepareForEdit, isEmptyDate } from '../../../tools';
import { GenerateFieldEdit } from '../../../types';
import { generateField } from '../../Fields';

const editPrevSplitDiff: GenerateFieldEdit = function (model, _context, checkpointId: nil | number, prevCheckpointId: nil | number) {
  const splitModel = model.findSplitByCheckpoint(checkpointId);
  const prevSplitModel = model.findSplitByCheckpoint(prevCheckpointId || 0);
  const isPrevCheckpoint = !!prevCheckpointId;

  return (onChange, value, onBlur: BlurEvent, error) => {
    if (!splitModel) {
      return null;
    }

    let startTime = isPrevCheckpoint ? prevSplitModel?.value?.finish_time : model.value.start_time;

    const finishTime = splitModel?.value?.finish_time;

    const diff = prepareForEdit.duration(startTime, finishTime);
    const passedValue = isUndefined(value) && diff !== 0 ? diff : value;
    const fieldId = splitModel.generateFieldId('prev_split_diff');

    if (isEmptyDate(startTime)) {
      return handleMissingPrevCheckpoint(fieldId, model, checkpointId);
    }

    return generateField('duration', passedValue, onChange, onBlur, fieldId, error);
  };
};

const editResultDiff = function (model, _context, checkpointId: nil | number) {
  const splitModel = model.findSplitByCheckpoint(checkpointId);

  return (onChange, value, onBlur: BlurEvent, error) => {
    if (!splitModel) {
      return null;
    }
    const startTime = model.value.start_time;
    const finishTime = splitModel?.value?.finish_time;

    const diff = prepareForEdit.duration(startTime, finishTime);

    const passedValue = isUndefined(value) && diff !== 0 ? diff : value;
    const fieldId = splitModel.generateFieldId('result_diff');

    if (isEmptyDate(startTime)) {
      return handleMissingStartTime(fieldId);
    }

    return generateField('duration', passedValue, onChange, onBlur, fieldId, error);
  };
} as GenerateFieldEdit;

export { editPrevSplitDiff, editResultDiff };

// import classNames from 'classnames';
import React, { FC } from 'react';

import { t } from 'utils';

type Props = {
  t: TranslationLockedKeys;
  index?: string;
  // className?: string;
};

const Title: FC<Props> = (props) => {
  return (
    <strong>
      <p className='terms-section--title'>{t(props.t, { index: props.index || '' })}</p>
      {/* <p className={classNames(props.className, 'terms-section--title')}>{t(props.t, { index: props.index || '' })}</p> */}
    </strong>
  );
};

export { Title };

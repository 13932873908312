import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { ENABLE_SYNCHRONIZATION_URL } from 'src/constants';
import { request, action } from 'src/utils';

import { SYNC_ENABLE_REQUEST } from '../constants';

class Sync {
  @request({ action: SYNC_ENABLE_REQUEST })
  async enableSyncRequest(id: number): Promise<any> {
    const url = generatePath(ENABLE_SYNCHRONIZATION_URL, { id });
    return axios.post(url);
  }

  @action({ action: SYNC_ENABLE_REQUEST })
  async enableSync(id: number) {
    const [isOk] = await this.enableSyncRequest(id);

    return isOk;
  }
}

export const syncService = new Sync();

import { ROUTES } from 'src/constants';
import { t } from 'src/utils';

export const outputRefundParamsToString = (input: AnyObject, id: number): string => {
  if (input.ok && input.ok === true) {
    return t.staticAsString('shared.messagePopup.btnAction.ok');
  }

  if (input.runtime_exceptions) {
    const errorText = t.staticAsString('supportTaskManager.errorText');

    return `<a href="${ROUTES.supportManagerTasksRoute}/${id}">${errorText}</a>`;
  }

  if (input.message) return input.message;

  return '';
};

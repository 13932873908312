import { singleFields, teamFields } from '../constants';

import { distanceStore } from '../stores';

import { isNonClassicDistance, isCumulativeDistance } from '../derivations';
import { isRegistrationFieldEnabled } from '../tools';

export type isColumnVisible = (field: string) => boolean;

function isColumnVisibleSingle(field: string): boolean {
  switch (field) {
    case singleFields['racer.birthday']:
      return isRegistrationFieldEnabled('birthday');
    case singleFields['racer.wave_id']:
      return distanceStore.hasWaves;
    case singleFields['racer.class_id']:
      return distanceStore.hasClasses;
    case singleFields['racer.discipline_id']:
      return distanceStore.hasDisciplines;
    case singleFields['racer.union']:
      return isRegistrationFieldEnabled('union');
    case singleFields['racer.country_id']:
      return isRegistrationFieldEnabled('country_id');
    case singleFields['racer.nationality_id']:
      return isRegistrationFieldEnabled('nationality_id');
    case singleFields['activity_link']:
      return isNonClassicDistance.get();
    case singleFields.distance_logged:
    case singleFields.distance_logged_at:
      return isCumulativeDistance.get();
    default:
      return true;
  }
}

function isColumnVisibleTeam(field: string): boolean {
  switch (field) {
    case teamFields['team.wave_id']:
      return distanceStore.hasWaves;
    case teamFields['team.class_id']:
      return distanceStore.hasClasses;
    default:
      return true;
  }
}

export { isColumnVisibleSingle, isColumnVisibleTeam };

import React from 'react';

import { t } from 'utils';

let RaceStatus = ({ value }: { value: string }) => {
  return <p>{t.staticAsString(`races.statuses.${value}` as TranslationLockedKeys)}</p>;
};

const raceState = (value: string) => {
  return t.staticAsString(`races.states.${value}` as TranslationLockedKeys);
};

const raceStatus = (value: string) => {
  return t.staticAsString(`races.statuses.${value}` as TranslationLockedKeys);
};

export { raceState, raceStatus, RaceStatus };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACERS_RESULT_URL } from 'src/constants';

import { request, action } from 'utils';

import { DELETE_RESULT } from '../constants';

import { resultsService } from '../services';

class DeleteResult {
  @request({ action: DELETE_RESULT })
  deleteResultRequest(racerId: number): Promise<any> {
    const url = generatePath(RACERS_RESULT_URL, { id: racerId });
    return axios.delete(url);
  }

  @action({ action: DELETE_RESULT })
  async deleteResult(racerId: number) {
    const [status] = await this.deleteResultRequest(racerId);

    if (status) {
      resultsService.load();
    }

    return !!status;
  }
}

export { DeleteResult, DeleteResult as deleteResultService };

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { cloneDeep, remove } from 'lodash';
import * as React from 'react';

import { LoadingDots } from 'components/LoadingDots';

import { t } from 'utils';

import { Tooltip } from '../core/Tooltip';
import { DefaultItem } from './DefaultItem';
import { SelectItem } from './Item';

const StyledSelect = withStyles({
  iconOutlined: {
    right: 16,
  },
})(Select);

const StyledMenuItem = withStyles({
  root: {
    height: 48,
    paddingRight: 7,
  },
})(MenuItem);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 72;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Props = FieldBaseProps & {
  onBlur?: Function;
  onKeyUp?: Function;
  options: Array<SelectOption>;
  placeholder?: string;
  customRenderAllValue?: string;
  customRenderSelected: (options: SelectOption[]) => React.ReactNode;
  optionsCount?: number;
  value: SelectOption;
  required?: boolean;
  variant?: 'filled' | 'outlined' | 'standard';
  tooltip?: {
    text: string;
    position: 'bottom' | 'icon';
  };
  loadMore?: Function;
  hasMore?: boolean;
};

type State = {
  show: boolean;
};

export class MultiSelect extends React.Component<Props, State> {
  static defaultProps = {
    options: [],
    placeholder: '',
    value: [],
  };

  state = {
    show: false,
  };

  onToggle = (show: boolean) => (): void => {
    this.setState({
      show,
    });
  };

  handleChange = (item: any) => (): void => {
    const { onChange, onBeforeChange, onAfterChange, name, value } = this.props;
    const isExist = !!value.find((option) => option.key === item.key);
    const newValue = cloneDeep(value);
    if (isExist) {
      remove(newValue, (option: AnyObject) => option.key === item.key);
    } else {
      newValue.push(item);
    }
    if (onBeforeChange) onBeforeChange({ name, value: newValue });
    onChange({ name, value: newValue });
    if (onAfterChange) onAfterChange({ name, value: newValue });
  };

  handleSelectAll = () => {
    const { onChange, onAfterChange, onSelectAll, name, value, options } = this.props;

    if (value.length === options.length) {
      onChange({ name, value: [] });
      if (onSelectAll) onSelectAll({ name, value: [] });
      if (onAfterChange) onAfterChange({ name, value: [] });
    } else {
      onChange({ name, value: options });
      if (onSelectAll) onSelectAll({ name, value: options });
      if (onAfterChange) onAfterChange({ name, value: options });
    }
  };

  isSelected = (key: number | string): boolean => {
    const { value, options } = this.props;
    if (key === 'all') {
      return value.length === options.length;
    }

    return !!value.find((item) => item.key === key);
  };

  render() {
    const {
      options,
      label,
      name,
      variant = 'outlined',
      placeholder,
      customRenderAllValue,
      customRenderSelected,
      optionsCount,
      value,
      required,
      className,
      error,
      tooltip,
      disabled = false,
      loadMore,
      hasMore,
    } = this.props;
    const { show } = this.state;
    const labelId = `${name}:label`;
    const labelText = label;
    const lastOptionIndex = options.length - 1;

    return (
      <FormControl
        disabled={disabled}
        fullWidth
        required={required}
        variant={variant}
        className={classNames('input-wrap', 'form-control', { [className || '']: !!className }, { error: !!error })}
        error={!!error}
        aria-describedby='name-error-text'
      >
        <InputLabel required={required} disabled={disabled} id={labelId}>
          {labelText}
        </InputLabel>
        <StyledSelect
          disabled={disabled}
          labelId={labelId}
          id={name}
          multiple
          open={show}
          multiline
          required={required}
          value={value}
          label={labelText}
          renderValue={(selected: SelectOption[]) => {
            if (customRenderAllValue && selected.length === optionsCount) return customRenderAllValue;
            if (customRenderSelected) return customRenderSelected(selected);
            return selected.map((item) => item.label).join(', ');
          }}
          MenuProps={MenuProps}
          onOpen={this.onToggle(true)}
          onClose={this.onToggle(false)}
        >
          <DefaultItem label={placeholder || ''} onClick={this.onToggle(false)} />
          <StyledMenuItem onClick={this.handleSelectAll} value={options as any}>
            <SelectItem
              key={'all'}
              onChange={this.handleSelectAll}
              checked={this.isSelected('all')}
              label={t.staticAsString('multiSelect.selectAll')}
            />
          </StyledMenuItem>
          {options.map((option: AnyObject, index: number) => {
            return (
              <StyledMenuItem key={option.key} onClick={this.handleChange(option)} value={option.value}>
                {loadMore && lastOptionIndex === index ? (
                  <SelectItem
                    onChange={this.handleChange(option)}
                    checked={this.isSelected(option.key)}
                    hasMore={hasMore}
                    loadMore={loadMore}
                    label={option.label}
                  />
                ) : (
                  <SelectItem onChange={this.handleChange(option)} checked={this.isSelected(option.key)} label={option.label} />
                )}
              </StyledMenuItem>
            );
          })}
          {loadMore && hasMore && (
            <StyledMenuItem key={'loading'}>
              <LoadingDots />
            </StyledMenuItem>
          )}
        </StyledSelect>

        {tooltip && <Tooltip {...tooltip} error={error} />}
        <FormHelperText className='errors'>
          <>{error || ' '}</>
        </FormHelperText>
      </FormControl>
    );
  }
}

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { Item } from 'modules/Races/components/List/Item';
import React, { useState } from 'react';

import { Icon } from 'components/Icon';

import { useSelectOptionStyles, useSelectStyles } from './styles';

type Props = {
  classes?: {
    [K in string]: string;
  };
  name: string;
  onChange: AnyFunction;
  onClose?: AnyFunction;
  value: string | number;
  errors: Array<string>;
  type?: string;
  id?: string;
  label?: string;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  className?: string;
  options: Array<SelectOption>;
  fullWidth?: boolean;
  reservePlaceForHelperText?: boolean;
  required?: boolean;
  disabled?: boolean;
};

const SelectField2 = ({
  classes,
  name,
  onChange,
  onClose,
  value,
  errors = [],
  id = '',
  label = '',
  variant = 'outlined',
  options = [],
  className,
  fullWidth = false,
  reservePlaceForHelperText = true,
  required,
  disabled,
}: Props) => {
  classes = classes || useSelectStyles();
  const selectOptionClasses: { [Key in string]: any } = useSelectOptionStyles();

  const formName = id ? `${id}_${name}` : name;
  const isErr = errors.length > 0;
  const errorMessage = errors.join(' ');
  const helperText = errorMessage || (reservePlaceForHelperText && ' ');

  const [hovered, setHovered] = useState(options[0]);

  const optionsList = () => {
    return (
      <div className='popover-container'>
        <div className='options-wrap'>
          <div className='options-list'>
            {options.map((item) => (
              <MenuItem
                key={item.key}
                value={item.value}
                classes={{
                  root: 'list-option',
                }}
                onMouseOver={() => setHovered(item)}
                onClick={() => onChange(name, item.value)}
                dense
              >
                {!!item.icon && <Icon className='dropdown-icon' value={item.icon} />}
                <Typography>{item.label}</Typography>
              </MenuItem>
            ))}
          </div>
          <div className='helper-wrap'>
            <div className='text-wrap'>
              <div className='title'>{hovered?.label}</div>
              <div className='text'>{hovered?.description}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const toRender = (item: string) => {
    const option = options.find((opt) => opt.value === item);
    return option?.label;
  };

  return (
    <FormControl
      variant={variant}
      fullWidth={fullWidth}
      className={classNames(className, { error: isErr }, classes.formControl)}
      error={isErr}
      aria-describedby='name-error-text'
      disabled={disabled}
    >
      <InputLabel id={`${formName}-label`} shrink={!!value} classes={{ root: 'form-label' }} required={required}>
        {label}
      </InputLabel>
      <Select
        labelId={`${formName}-label`}
        id={formName}
        fullWidth={fullWidth}
        label={label}
        value={value}
        error={isErr}
        onClose={onClose}
        classes={classes}
        renderValue={toRender}
        inputProps={{
          name: name,
          id: formName,
        }}
      >
        {optionsList()}
      </Select>
      <FormHelperText className='errors'>{helperText || ' '}</FormHelperText>
    </FormControl>
  );
};

export { SelectField2 };

import * as React from 'react';

import { t } from 'utils/t';

import { Wrapper, PageTitle, LinkFields, Body, ControlsCreate } from '../components';

import { CAMPAIGN_LINKS_FORM_ID } from '../constants';

import { handleSubmitNew } from '../actions/create';

export const New = () => {
  return (
    <Wrapper id={CAMPAIGN_LINKS_FORM_ID} onSubmit={handleSubmitNew} cleanErrorOnChange>
      <Body>
        <PageTitle>{t.staticAsString('campaignLinks.newCampaignLink')}</PageTitle>
        <LinkFields />
        <ControlsCreate />
      </Body>
    </Wrapper>
  );
};

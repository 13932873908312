import { observer, Observer } from 'mobx-react';
import * as React from 'react';

import { ListModuleContext } from 'components/context';

import { sessionStore } from 'stores';

import { Pagination } from './Pagination';

@observer
class SubscribedPagination extends React.Component<{}> {
  static contextType = ListModuleContext;
  declare context: ListModuleContextProps | any;

  onChangePage = (event: any, newPage: number) => {
    if (!this.context.load) {
      return;
    }

    const { service } = this.context.load;
    const page = newPage + 1;

    service.load({ page });
  };

  onChangePerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!this.context.load) {
      return;
    }

    const { value } = event.target;
    const { service } = this.context.load;

    service.load({ limit: +value });
  };

  paginationProps(): {
    page?: number;
    perPage?: number;
    total?: number;
  } {
    if (!this.context.load) {
      return {};
    }

    const { store } = this.context.load.service;
    const { page, paginationMeta } = store;
    const { per_page, total } = paginationMeta;

    return {
      page: page - 1,
      perPage: Number(per_page) || 30,
      total: total || 0,
    };
  }

  render() {
    return (
      <Observer>
        {() => {
          const organizerVerified = sessionStore.isOrganizerVerified;

          return (
            <Pagination
              {...(this.paginationProps() as any)}
              onChangePage={this.onChangePage}
              onChangePerPage={this.onChangePerPage}
              switchedOnTop={organizerVerified}
            />
          );
        }}
      </Observer>
    );
  }
}

export { SubscribedPagination };

import { cloneDeep } from 'lodash';

import { CheckPoint } from 'src/modules/Distances/components/shared/Steps/GPX/types';
import { t } from 'src/utils';

export const mapFromCheckPoints = (d: AnyObject): Array<CheckPoint> => {
  const data: AnyObject = cloneDeep(d) as AnyObject;
  const lastIndex = data.checkpoints.length - 1;

  const checkpoints = data.checkpoints
    .sort((a, b) => a.index - b.index)
    .map((item, index) => {
      const lng = item.coordinates ? item.coordinates.coordinates.coordinates[0] : 0;
      const lat = item.coordinates ? item.coordinates.coordinates.coordinates[1] : 0;

      return {
        id: item.id,
        name: getName(item.name),
        label: getLabel(item.index, index === lastIndex),
        length: item.length,
        index: item.index + 1,
        coordinate: {
          lng,
          lat,
        },
        type: lng !== 0 && lat !== 0 ? 'ready' : 'set',
        showCloseButton: index !== lastIndex,
      };
    });

  return [
    {
      ...checkpoints[0],
      index: 0,
      id: -1,
      label: t.staticAsString('distances.steps.helperText.gpx.start'),
      coordinate: {
        lng: 0,
        lat: 0,
      },
    },
  ].concat(checkpoints);
};

const getName = (name) => (name.length > 25 ? name.substr(0, 25) + '...' : name);

const getLabel = (index, isLastIndex) => {
  if (isLastIndex) {
    return t.staticAsString('distances.steps.helperText.gpx.finish');
  }

  return String(index + 1);
};

import { first, last, tap } from 'lodash';
import moment from 'moment';

import { generateMomentRangeFor } from '../utils';

function generateDateRestrictions(
  type: 'registration_range' | 'cvr_distance_date_range' | 'normal_distance_date',
  distance: DistanceType,
): { minDate: moment.Moment | nil; maxDate: moment.Moment | nil } {
  let minDate: moment.Moment | nil = null;
  let maxDate: moment.Moment | nil = null;

  switch (type) {
    case 'registration_range':
      minDate = generateMomentRangeFor('registration_starts_at', distance)?.fromValue;
      maxDate = generateMomentRangeFor('registration_ends_at', distance)?.toValue;
      break;

    case 'cvr_distance_date_range':
      minDate = generateMomentRangeFor('race_date', distance)?.fromValue;
      maxDate = generateMomentRangeFor('ends_at', distance)?.toValue;
      break;

    case 'normal_distance_date':
      tap(generateMomentRangeFor('race_date', distance), (restrictions) => {
        minDate = restrictions?.fromValue;
        maxDate = restrictions?.toValue;
      });
      break;
  }

  return { minDate, maxDate };
}

export { generateDateRestrictions };

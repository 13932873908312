import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { TextField, InputWrapper, AutocompleteSelectInfinityLoad } from 'src/components/Form';
import { Loading } from 'src/components/Loading';
import { Button } from 'src/components/core';
import { Tip, ButtonHolder } from 'src/modules/Synchronisation/Shared';
import { t } from 'src/utils';

import { EQ_SETTINGS_INIT } from '../../constants';

import { handleCancel, changeStep, searchRaces, loadMoreRaces } from '../../actions';

import { racesHasMoreStore, racesStore } from '../../stores';

const Wrapper = styled.div`
  width: 726px;
  padding: 0 26px 26px;
`;

const Fields = styled.div`
  padding-top: 32px;
`;

export const SyncSettings: React.FC = () => {
  return (
    <Wrapper>
      <Loading action={EQ_SETTINGS_INIT} />
      <Tip />
      <Fields>
        <Observer>
          {() => {
            return (
              <InputWrapper
                name='race_id'
                settings={{
                  label: t.staticAsString('sync.distances.raceid_event_name'),
                  additional: {
                    options: racesStore.value,
                  },
                  view: {
                    reservePlaceForHelperText: true,
                  },
                  hasMore: racesHasMoreStore.value,
                  loadMore: loadMoreRaces,
                  onSearch: searchRaces,
                }}
                Component={AutocompleteSelectInfinityLoad}
              />
            );
          }}
        </Observer>
        <InputWrapper
          name='event_id'
          settings={{
            label: t.staticAsString('sync.eq_event_id'),
            view: {
              variant: 'outlined',
              fullWidth: true,
            },
          }}
          Component={TextField}
        />
      </Fields>
      <ButtonHolder>
        <Button type='cancel' onClick={handleCancel}>
          {t('shared.cancel')}
        </Button>
        <Button type='apply' onClick={changeStep}>
          {t('sync.start_sync_process')}
        </Button>
      </ButtonHolder>
    </Wrapper>
  );
};

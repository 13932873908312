import type { ResultsContextType } from '../context';

function formatDisciplineId(disciplineId: number | string | nil, context: ResultsContextType): string | null {
  const distanceModel = context.results.stores.distanceStore.modelSelected;

  if (!distanceModel) {
    return null;
  }

  const disciplineModel = distanceModel.findRelationModelValue('disciplines', +(disciplineId as number));

  if (!disciplineModel) {
    return null;
  }

  return disciplineModel.label();
}

export { formatDisciplineId };

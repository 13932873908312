import { Theme } from '@mui/material';
import { makeStyles, StyleRulesCallback } from '@mui/styles';

export const cellStyles: StyleRulesCallback<Theme, {}, 'head' | 'body'> = (theme: Theme) => ({
  head: {
    backgroundColor: theme.palette.white2,
    border: `1px solid ${theme.palette.grey8}`,
    '& p': {
      color: theme.palette.grey1,
      fontWeight: 'normal',
      display: 'inline-block',
    },
  },
  body: {
    color: theme.palette.grey5,
    fontSize: 14,
    fontWeight: 'normal',
    border: `1px solid ${theme.palette.grey8}`,
  },
});

export const useCellInputStyles = makeStyles((theme: Theme) => ({
  rightCell: {
    verticalAlign: 'text-top',
    color: theme.palette.grey1,
    fontSize: 14,
    fontWeight: 'normal',
    width: '100%',
  },

  leftCell: {
    verticalAlign: 'text-top',
    wordBreak: 'break-all',
  },

  rightHtmlCell: {
    verticalAlign: 'text-top',
    cursor: 'pointer',
    '& .MuiInputBase-input': {
      cursor: 'pointer',
    },
  },
}));

export const useLocaleStyles = makeStyles((theme: Theme) => ({
  flagIcon: {
    minWidth: 22,
    minHeight: 16,
    marginLeft: 8,
    marginRight: 12,
  },
  label: {
    color: theme.palette.grey1,
    fontSize: 12,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    display: 'inline-block',
  },
}));

import InputLabel from '@mui/material/InputLabel';
import { observer } from 'mobx-react';
import * as React from 'react';

import { selectStore } from './store';

type Props = {
  children: React.ReactNode;
  emptyValue: boolean;
  formName: string;
};

@observer
export class Label extends React.Component<Props> {
  render() {
    const { children, emptyValue, formName } = this.props;

    return (
      <InputLabel htmlFor={formName} shrink={selectStore.isFocused(formName) || !emptyValue}>
        {children}
      </InputLabel>
    );
  }
}

import { loadTasks } from '../actions';

import { tasksStore } from '../stores';

import { loadOrganizers } from '.';

export const mountIndex = async () => {
  const { page: storePage, params: storeParams } = tasksStore;
  await loadTasks({ page: storePage, limit: storeParams.limit });

  loadOrganizers();
};

export const unmountIndex = (): void => {
  tasksStore.clearAll();
};

import { TableRow } from '@mui/material';
import * as React from 'react';

import { ActionCell } from '../../components/ActionCell';
import { CopyRacersTasksCells } from '../../components/Row';

import { CopyRacersContext } from '../../context';
import { CopyRacersTable } from '../../types';

type Props = {
  item: CopyRacersTable;
};

class Row extends React.Component<Props> {
  static contextType = CopyRacersContext;

  render() {
    const { item } = this.props;
    const resultId = item.id;
    return (
      <TableRow key={`racer${item.id}`}>
        <ActionCell taskId={resultId} />
        <CopyRacersTasksCells value={item} />
      </TableRow>
    );
  }
}

export { Row };

import { Box, TextField } from '@mui/material';
import moment from 'moment';
import { generatePath, Link } from 'react-router-dom';
import { ColumnWithLooseAccessor } from 'react-table';

import { ROUTES } from 'src/constants';

import { DatePicker } from 'components/Form/Fields';
import { Icon } from 'components/Icon';

import { t } from 'utils/t';

import { handleEditLink } from '../../actions';

import { SmartLinkFE } from '../../../shared/types';
import { LinkComponent } from './LinkComponent';

const COLUMNS = ['edit', 'ID', 'linkName', 'url', 'amountOfLinks', 'usedLinks', 'raceName', 'distanceName', 'wave', 'date'];

function NumberRangeFilter({ column: { filterValue = [], setFilter, id } }) {
  return (
    <Box display='flex' flexDirection='row'>
      <Box minWidth='80px' p={0.5}>
        <TextField
          type='number'
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val === '0' || val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          value={filterValue[0]}
          variant='outlined'
          size='small'
          label={t.static('reconciliation.transactions.from')}
        />
      </Box>
      <Box minWidth='80px' p={0.5}>
        <TextField
          type='number'
          value={filterValue[1]}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val === '0' || val ? parseInt(val, 10) : undefined]);
          }}
          variant='outlined'
          size='small'
          label={t.static('reconciliation.transactions.to')}
        />
      </Box>
    </Box>
  );
}

function DateFilter(props) {
  const {
    column: { filterValue, setFilter, preFilteredRows, id },
  } = props;
  return (
    <Box minWidth='250px' p={0.5}>
      <DatePicker
        hideArrow
        view={{
          fullWidth: true,
          readOnly: true,
          variant: 'outlined',
          size: 'small',
        }}
        label=''
        name='date_picker'
        value={filterValue}
        onChange={({ name, value }) => {
          setFilter(value);
        }}
        onClear={() => {
          if (id === 'createdAt' || 'updated_at') {
            setFilter(null);
          }
        }}
      />
    </Box>
  );
}

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<SmartLinkFE> } = {
  edit: {
    id: 'edit',
    Cell: ({ row: { original } }) => <Icon style={{ cursor: 'pointer' }} value='edit' onClick={() => handleEditLink(original.token)} />,
  },

  ID: {
    id: 'id',
    Header: t.static('smartLinks.id'),
    accessor: (link) => link.id,
    filter: 'between',
    Filter: NumberRangeFilter,
  },

  linkName: {
    id: 'name',
    Header: t.static('smartLinks.name'),
    accessor: (link) => link.name ?? '—',
    Cell: ({ row: { original } }) => {
      return (
        <Link
          style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'underline' }}
          to={generatePath(ROUTES.smartLinkDetails, { token: original.token })}
        >
          {original.name}
        </Link>
      );
    },
  },

  url: {
    id: 'token',
    Header: t.static('smartLinks.url'),
    accessor: (link) => link.link ?? '—',
    Cell: ({ row: { original } }) => {
      return <LinkComponent url={original.link} />;
    },
  },

  amountOfLinks: {
    id: 'max_usages',
    Header: t.static('smartLinks.codes'),
    accessor: (link) => link.max_usages ?? '—',
    filter: 'between',
    Filter: NumberRangeFilter,
  },

  usedLinks: {
    id: 'used_times',
    Header: t.static('smartLinks.used'),
    accessor: (link) => link.used_times ?? '—',
    filter: 'between',
    Filter: NumberRangeFilter,
  },

  raceName: {
    id: 'race.name',
    Header: t.static('smartLinks.event'),
    accessor: (link) => link.race?.name ?? '—',
  },

  distanceName: {
    id: 'distance.name',
    Header: t.static('shared.goalType.1'),
    accessor: (link) => link.distance?.name ?? '—',
  },

  wave: {
    id: 'wave.name',
    Header: t.static('shared.startType.wave'),
    accessor: (link) => link.wave?.name ?? '—',
  },

  date: {
    id: 'date',
    Header: t.static('shared.customFieldTypes.date'),
    Filter: DateFilter,
    accessor: (link) => link.date,
    Cell: ({ row: { original } }) => {
      return (
        <>
          {moment(original.date_from).format('YYYY/MM/DD HH:mm')} - {moment(original.date_to).format('YYYY/MM/DD HH:mm')}
        </>
      );
    },
  },
};

export function generateTransactionColumns() {
  return COLUMNS.map((c) => RenderColumns[c]);
}

import { Box } from '@mui/material';
import { debounce, isEmpty } from 'lodash';
import { loadTransferRegistrationData, submitEditRegistration, submitTransferRegistration } from 'modules/Distances/actions';
import { loadEditRegistrationData } from 'modules/Distances/actions/loadEditRegistrationData';
import { ERRORS_PREFIXES } from 'modules/Distances/constants';
import { TransferRegistrationValue } from 'modules/Distances/types';
import { clearNamespaceErrors, getDistanceErrors } from 'modules/Distances/utils';
import moment from 'moment';
import * as React from 'react';

import { BACKEND_DATE_FORMATE, CONFIRM_POPUP_TYPES, DISTANCE_STEPS, PERSIST_DISTANCE as action } from 'src/constants';

import { withErrorClean } from 'hocs';

import { Show } from 'components/Condition';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { PriceInput, Switch } from 'components/Fields';
import { DatePicker } from 'components/Form';

import { t } from 'utils';

import { Race as RaceModel } from 'models';

import { helperDistancesStore } from 'stores';

import { State as OriginalFormState } from '../../../../shared/stateHelpers';

const RANCE_KEYS = {
  from: 'minDate',
  to: 'maxDate',
};
type ConfirmPopup = 'edit' | 'transfer';

type Props = {
  formData: OriginalFormState;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
  ) => void;
  errors: {
    [K in string]: Array<string>;
  };
  helperData: {
    race: nil | RaceModel;
  };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

type State = {
  confirmPopup: boolean;
  confirmType: ConfirmPopup;
  isSubmittingSelfServices: boolean;
};

const stepAction = `${action}_${DISTANCE_STEPS.selfServices}`;

@withErrorClean(stepAction)
class SelfServices extends React.Component<Props, State> {
  state: State = {
    confirmPopup: false,
    confirmType: 'transfer',
    isSubmittingSelfServices: false,
  };

  componentDidMount() {
    const id = this.props.formData.distance?.id as number;
    (async () => {
      await loadTransferRegistrationData(id, this.transferDataLoaded);
      await loadEditRegistrationData(id, this.editRegistrationDataLoaded);
    })();
  }

  handleLiveUpdate = debounce(async (data, type) => {
    const { formData } = this.props;
    if (type === 'transfer') {
      await submitTransferRegistration({
        ...formData,
        distance: data,
      });
    }

    if (type === 'edit') {
      await submitEditRegistration({
        ...formData,
        distance: data,
      });
    }

    this.setState({
      isSubmittingSelfServices: false,
    });
  }, 1000);

  editRegistrationDataLoaded = (data: TransferRegistrationValue) => {
    const { formData, onChange } = this.props;
    onChange(
      {
        ...formData.distance,
        is_edit_registration_available: true,
        isEditRegistrationEdit: true,
        edit_registration_settings: {
          from: data.from && moment(data.from).format(BACKEND_DATE_FORMATE),
          to: data.to && moment(data.to).format(BACKEND_DATE_FORMATE),
          id: data.id,
        },
      },
      'distance',
      () => {},
    );
  };

  transferDataLoaded = (data: TransferRegistrationValue) => {
    const { formData, onChange } = this.props;

    onChange(
      {
        ...formData.distance,
        is_transfer_registration_available: true,
        isTransferRegistrationEdit: true,
        transfer_registration_settings: {
          from: data.from,
          to: data.to,
          price: data.price,
        },
      },
      'distance',
      () => {},
    );
  };

  getMinDate = (distance) => {
    const startDistance = distance.registration_starts_at ?? '';
    const today = moment();

    if (startDistance) {
      return today > moment(startDistance) ? moment.utc(today) : moment.utc(startDistance);
    }

    return moment.utc(today);
  };

  defaultRaceDateRangeInputProps = (prefix: 'transfer_registration' | 'edit_registration', name: string) => {
    const { formData } = this.props;
    const { isSubmittingSelfServices } = this.state;
    const errors = getDistanceErrors('self_services')?.[prefix];
    const distance = formData.distance;

    const data = distance[`${prefix}_settings`]?.[name] ?? '';

    const maxDate = distance.registration_ends_at ?? '';

    const error = (errors && errors[name]) || (errors && errors[name]);

    const range = {
      minDate: this.getMinDate(distance),
      maxDate: maxDate && (moment.utc(maxDate) as any),
    };

    return {
      value: data && moment.utc(data),
      error: error?.[0],
      fullWidth: true,
      additional: { isRange: false, disabled: isSubmittingSelfServices },
      [RANCE_KEYS[name]]: range[RANCE_KEYS[name]],
      name,
    };
  };

  onChangeSwitch = (type: ConfirmPopup) => {
    this.setState({ isSubmittingSelfServices: true });
    const { onChange, formData } = this.props;
    const { is_transfer_registration_available, is_edit_registration_available } = formData.distance;
    this.handleLiveUpdate(
      {
        ...formData.distance,
        ...(type === 'transfer' && { is_transfer_registration_available: !is_transfer_registration_available }),
        ...(type === 'edit' && { is_edit_registration_available: !is_edit_registration_available }),
      },
      type,
    );
    onChange(
      {
        ...formData.distance,
        ...(type === 'transfer' && {
          is_transfer_registration_available: !is_transfer_registration_available,
          isTransferRegistrationEdit: !is_transfer_registration_available,
        }),
        ...(type === 'edit' && {
          is_edit_registration_available: !is_edit_registration_available,
          isEditRegistrationEdit: !is_edit_registration_available,
        }),
      },
      'distance',
      () => {},
    );
  };

  onShowConfirm = (type: ConfirmPopup = 'transfer') => {
    const hasActiveTransferRefistrations = Boolean(helperDistancesStore.selected?.active_transfer_registrations_count);
    const { is_transfer_registration_available, is_edit_registration_available } = this.props.formData.distance;

    if (
      (is_transfer_registration_available && hasActiveTransferRefistrations && type === 'transfer') ||
      (is_edit_registration_available && type === 'edit')
    ) {
      this.setState({ confirmPopup: true, confirmType: type });
    } else {
      this.onChangeSwitch(type);
    }
  };

  onEditRegistrationDateRangeChange = ({ name, value }) => {
    const { formData, onChange } = this.props;
    this.handleLiveUpdate(
      {
        ...formData.distance,
        edit_registration_settings: {
          ...formData.distance.edit_registration_settings,
          [name]: isEmpty(value) ? null : value.format(BACKEND_DATE_FORMATE),
        },
      },
      'edit',
    );
    onChange(
      {
        ...formData.distance,
        edit_registration_settings: {
          ...formData.distance.edit_registration_settings,
          [name]: isEmpty(value) ? null : value.format(BACKEND_DATE_FORMATE),
        },
      },
      'distance',
      () => {},
    );
  };

  onRegistrationDateRangeChange = ({ name, value }) => {
    const { formData, onChange } = this.props;
    this.handleLiveUpdate(
      {
        ...formData.distance,
        transfer_registration_settings: {
          ...formData.distance.transfer_registration_settings,
          [name]: isEmpty(value) ? null : value.format(BACKEND_DATE_FORMATE),
        },
      },
      'transfer',
    );
    onChange(
      {
        ...formData.distance,
        transfer_registration_settings: {
          ...formData.distance.transfer_registration_settings,
          [name]: isEmpty(value) ? null : value.format(BACKEND_DATE_FORMATE),
        },
      },
      'distance',
      () => {},
    );
  };

  onChangePrice = (e: React.ChangeEventHandler | any): void => {
    const { onChange, formData } = this.props;
    const { name, value } = e.currentTarget;
    clearNamespaceErrors(ERRORS_PREFIXES.self_services);
    this.handleLiveUpdate(
      {
        ...formData.distance,
        transfer_registration_settings: {
          ...formData.distance.transfer_registration_settings,
          [name]: value,
        },
      },
      'transfer',
    );
    onChange(
      {
        ...formData.distance,
        transfer_registration_settings: {
          ...formData.distance.transfer_registration_settings,
          [name]: value,
        },
      },
      'distance',
      () => {},
    );
  };

  clearConfirmPopup = () => {
    this.setState({ confirmPopup: false });
  };

  onConfirmPopup = () => {
    const { confirmType } = this.state;
    this.clearConfirmPopup();
    this.onChangeSwitch(confirmType);
  };

  render() {
    const { confirmPopup, isSubmittingSelfServices } = this.state;
    const { formData, helperData } = this.props;
    const { transfer_registration_settings, is_transfer_registration_available, is_edit_registration_available } = formData.distance;
    const { race } = helperData;
    const price = transfer_registration_settings?.price ?? '';
    const errorPrice = getDistanceErrors('self_services')?.price ?? '';

    return (
      <div className='distance-self-services-fields-form form-content-wrapper'>
        <p className='about-self-services'>{t.staticAsString('distances.steps.selfServicesForm.about')}</p>

        <Switch
          name={'is_edit_registration_available'}
          value={is_edit_registration_available}
          label={t.staticAsString('distances.steps.selfServicesForm.editRegistration')}
          tooltipText={t.staticAsString('distances.steps.selfServicesForm.editRegistrationTooltip')}
          onChange={() => this.onChangeSwitch('edit')}
          className='muted'
          errors={[]}
          disabled={isSubmittingSelfServices}
        />

        <Show if={is_edit_registration_available}>
          <div className='self-services-fields-wrapper'>
            <Box sx={{ display: 'flex', gap: '24px', mb: '2px' }}>
              <DatePicker
                {...this.defaultRaceDateRangeInputProps('edit_registration', 'from')}
                label={t.staticAsString('distances.steps.selfServicesForm.timeFramesFrom')}
                onChange={this.onEditRegistrationDateRangeChange}
              />
              <DatePicker
                {...this.defaultRaceDateRangeInputProps('edit_registration', 'to')}
                label={t.staticAsString('distances.steps.selfServicesForm.timeFramesTo')}
                onChange={this.onEditRegistrationDateRangeChange}
              />
            </Box>
          </div>
        </Show>

        <Switch
          name={'is_transfer_registration_available'}
          value={is_transfer_registration_available}
          label={t.staticAsString('distances.steps.selfServicesForm.transferRegistration')}
          onChange={() => this.onShowConfirm('transfer')}
          className='muted'
          errors={[]}
          disabled={isSubmittingSelfServices}
        />

        <Show if={is_transfer_registration_available}>
          <div className='self-services-fields-wrapper'>
            <Box sx={{ display: 'flex', gap: '24px', mb: '50px' }}>
              <DatePicker
                {...this.defaultRaceDateRangeInputProps('transfer_registration', 'from')}
                label={t.staticAsString('distances.steps.selfServicesForm.timeFramesFrom')}
                onChange={this.onRegistrationDateRangeChange}
              />
              <DatePicker
                {...this.defaultRaceDateRangeInputProps('transfer_registration', 'to')}
                label={t.staticAsString('distances.steps.selfServicesForm.timeFramesTo')}
                onChange={this.onRegistrationDateRangeChange}
              />
            </Box>

            <PriceInput
              value={price}
              name='price'
              label={t.staticAsString('distances.steps.selfServicesForm.priceWithCurrency', { currency: race?.currency() ?? 'Unknown' })}
              errors={errorPrice}
              onChange={this.onChangePrice}
              tooltipText={t.staticAsString('distances.steps.selfServicesForm.textInfo')}
              currency=''
              fullWidth
              disabled={isSubmittingSelfServices}
            />
          </div>
        </Show>

        {this.props.controls}

        <ConfirmationModal
          open={confirmPopup}
          onConfirm={this.onConfirmPopup}
          onClose={this.clearConfirmPopup}
          title={t.staticAsString('distances.confirmCloseTitle')}
          body={t.staticAsString('distances.steps.selfServicesForm.confirmBody')}
          type={CONFIRM_POPUP_TYPES.confirm}
        />
      </div>
    );
  }
}

export { SelfServices };

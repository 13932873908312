import React from 'react';
import styled from 'styled-components';

import { FileUploader } from 'src/components/Form';

const Wrapper = styled.div`
  width: calc(100% - 64px);
  margin: 32px auto 24px;
`;

const fileTypes = ['GPX'];

type Props = {
  setFile: (file: File) => Promise<void>;
};

const FileDropzone = ({ setFile }: Props) => {
  return (
    <Wrapper>
      <FileUploader handleChange={setFile} name='gpx_file' types={fileTypes} maxSize={5} />
    </Wrapper>
  );
};

export { FileDropzone };

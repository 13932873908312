import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React from 'react';
import { DayPickerRangeController, FocusedInputShape } from 'react-dates';

import { Icon } from 'components/Icon';

type FocusType = 'startDate' | 'endDate' | null;

type Props = {
  classes?: {
    [K in string]: string;
  };
  name: string;
  errors: Array<string>;
  type?: string;
  id?: string;
  className?: string;
  startDate: any;
  endDate?: any;
  onChange: (values: [undefined | null | string | moment.Moment | any, undefined | null | string | moment.Moment | any]) => void;
  focus?: FocusType;
  withYearSelect?: boolean;
};

type State = {
  focus: FocusType;
};

class DateRangeField extends React.Component<Props, State> {
  static defaultProps = {
    className: classNames('form-control', 'react-date-range'),
    id: '',
  };

  state: State = {
    focus: 'startDate',
  };

  constructor(props: Props) {
    super(props);
    const { focus } = props;
    this.state = { focus: focus || 'startDate' };
  }

  onChangeFocus = (newFocus: FocusedInputShape | null): void => {
    this.setState({
      focus: newFocus,
    });
  };

  onChange = (value: { startDate: Moment | null; endDate: Moment | null }) => {
    const { onChange, startDate, endDate } = this.props;

    if (startDate && endDate) {
      onChange([value.startDate || value.endDate, null]);
      return;
    }

    if (value.startDate && value.endDate) {
      this.setState({ focus: 'startDate' });
    }

    onChange([value.startDate, value.endDate]);
  };

  renderMonthElement({ month, onYearSelect }) {
    return (
      <div className='month-header'>
        <div>
          <span>{month.format('MMMM')}</span>
        </div>
        <div className='change-year'>
          <Icon
            className='year-navigation-button'
            onClick={() => {
              const currentYear = month.year();
              onYearSelect(month, currentYear - 1);
            }}
            value='arrow-left'
          />
          <span className='year-value'>{month.year()}</span>
          <Icon
            className='year-navigation-button'
            onClick={() => {
              const currentYear = month.year();
              onYearSelect(month, currentYear + 1);
            }}
            value='arrow-right'
          />
        </div>
      </div>
    );
  }

  render() {
    const { name, id, startDate, endDate, className, withYearSelect } = this.props;
    const formName = id ? `${id}_${name}` : name;
    return (
      <div className={className}>
        <DayPickerRangeController
          renderMonthElement={withYearSelect ? this.renderMonthElement : null}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={this.onChange as AnyFunction}
          focusedInput={this.state.focus}
          onFocusChange={this.onChangeFocus}
          minimumNights={0}
          numberOfMonths={2}
          keepOpenOnDateSelect={true}
          initialVisibleMonth={null}
          noBorder
          hideKeyboardShortcutsPanel
          transitionDuration={0}
        />
      </div>
    );
  }
}

export { DateRangeField, DateRangeField as DateRange };

import { SPORT_TYPE_AUXILIARY, SPORT_TYPES_URL } from 'src/constants';

import { request, action } from 'utils';

import { sportTypesAuxiliaryStore } from 'stores';
import { SportTypeAuxiliary as SportTypeStore } from 'stores';

import { LoadableService } from './loadable';

class LoadService extends LoadableService<SportTypeType, FiltersType, SportTypeStore> {
  constructor(store: SportTypeStore) {
    super({ store, resourcesUrl: SPORT_TYPES_URL, resource: SPORT_TYPE_AUXILIARY });
  }

  @request({ action: `LOAD_${SPORT_TYPE_AUXILIARY}S` })
  async loadResourcesRequest(params: FiltersType): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @action({ action: `LOAD_${SPORT_TYPE_AUXILIARY}S` })
  async loadResources(params: FiltersType = {}, pageNum: number = 1): Promise<any> {
    return super.loadResources(params, pageNum);
  }
}

export { LoadService };
export default new LoadService(sportTypesAuxiliaryStore);

import { Theme } from '@mui/material';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { ActionButton } from 'modules/RaceDetails/components';
import { raceDetailsStore } from 'modules/RaceDetails/stores';
import * as React from 'react';

import { t } from 'utils';

import { Race } from 'models';

type Props = {
  race: nil | Race;
  onClick?: React.MouseEventHandler;
  className?: string;
  isApproved?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    flexDirection: 'column',
    gap: '15px',
    lineHeight: '16px',
    letterSpacing: 'unset',
  },
}));

const OpenRegistration = observer(({ race, onClick, className, isApproved, ...rest }: Props) => {
  const c = useStyles();
  const isAnyDistancesPresented = raceDetailsStore.isAnyDistancesPresented;
  return (
    <ActionButton className={classnames(className, c.button)} variant='text' onClick={onClick}>
      <Switch
        disabled={!(isAnyDistancesPresented || race?.value.allow_registration) || !isApproved}
        checked={Boolean(race?.value.allow_registration)}
      />
      {t.staticAsString('races.detail.openRegistration')}
    </ActionButton>
  );
});

export { OpenRegistration };

import { TodayOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactElement } from 'react';

import { t } from 'utils/t';

interface Props {
  datetime: string;
}

const useStyles = makeStyles({
  text: {
    color: 'rgba(38, 46, 53, 0.64)',
    fontSize: '12px',
  },
  icon: {
    color: 'rgb(168, 174, 184)',
    marginRight: 5,
  },
});

export default function TransactionDate({ datetime }: Props): ReactElement {
  const c = useStyles();
  return (
    <div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
      <TodayOutlined className={c.icon} />
      <Typography className={c.text}>{datetime.split(' ').join(` ${t.staticAsString('reconciliation.transaction.at')} `)}</Typography>
    </div>
  );
}

import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '8px 16px',
    height: '40px',
    background: '#262E35',
    color: 'white',
    width: '100%',
  },
  headerText: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#FFFFFF',
  },
});

type Props = {
  title?: string;
  supportTitle?: string;
};

export default function CardHeader({ title, supportTitle }: Props) {
  const c = useStyles();
  return (
    <header className={c.header}>
      <div className={c.headerText}>{title}</div>
      <div className={c.headerText}>{supportTitle}</div>
    </header>
  );
}

import { get } from 'lodash';
import { observer } from 'mobx-react';
import { getDistanceErrors } from 'modules/Distances/utils/errors';
import React from 'react';

import { DISTANCE_STEPS, PERSIST_DISTANCE as action } from 'src/constants';

import { TextField } from 'components/Fields';
import { TextEditor } from 'components/Form/Fields';

import { t } from 'utils';

import { Race as RaceModel } from 'models';

import { clearNamespaceErrors } from '../../../../utils/errors';

import { State as OriginalFormState } from '../../../../shared/stateHelpers';

type Props = {
  formData: OriginalFormState | any;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
    isDirty?: boolean,
  ) => void;
  helperData: {
    race: nil | RaceModel;
  };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

const FIELDS = {
  allow_no_profile_registration: 'allow_no_profile_registration',
  start_type: 'start_type',
  distance_mode: 'distance_mode',
};

const stepAction = `${action}_${DISTANCE_STEPS.nameAndDescription}`;

@observer
class NameAndDescriptionStep extends React.Component<Props> {
  onChangeWithEvent = (e: React.ChangeEventHandler | any) => {
    let value = null;
    let name = '';

    switch (e.type) {
      case 'click':
        value = e.target.value;
        name = e.target.name;
        break;
      case 'keydown':
        value = e.target.value;
        name = e.target.name;
        break;
      case 'change':
        value = e.currentTarget.value;
        name = e.currentTarget.name;
        break;
      default:
        value = e.currentTarget.value;
        name = e.currentTarget.name;
    }
    this.onChange({ name, value });
  };

  onChangeWithoutEvent = ({ name, value }: { name: string; value: any }) => {
    this.onChange({ name, value });
  };

  onChange = ({ name, value }: { name: string; value: any }, callback: Function = () => {}) => {
    const formattedValue = this._formatInput(name, value);
    const { formData, onChange, liveUpdate } = this.props;
    const distance = formData.distance;

    clearNamespaceErrors(name);
    liveUpdate({ [name]: formattedValue }, true);
    onChange(
      { ...distance, [name]: formattedValue },
      'distance',
      () => {
        callback();
      },
      true,
    );
  };

  defaultInputProps = (name: string) => {
    const { formData } = this.props;
    const value = get(formData.distance, name);
    const errors = getDistanceErrors(name);

    return {
      value: value,
      name,
      errors: errors,
      fullWidth: true,
    };
  };

  _formatInput = (name: string, value: any) => {
    if (!Boolean(value)) {
      return null;
    }

    if (name === FIELDS.allow_no_profile_registration) {
      return parseInt(value, 10);
    }

    return value;
  };

  render() {
    return (
      <div className='form-content-wrapper'>
        <TextField
          {...this.defaultInputProps('name')}
          label={t.staticAsString('distances.steps.baseStepForm.name')}
          onChange={this.onChangeWithEvent}
        />
        <TextEditor
          {...this.defaultInputProps('description')}
          label={t.staticAsString('distances.steps.baseStepForm.description')}
          additional={{
            placeholder: t.staticAsString('distances.steps.baseStepForm.description'),
          }}
          onChange={this.onChangeWithoutEvent}
        />
        <div className='separator' />
        {this.props.controls}
      </div>
    );
  }
}

export { NameAndDescriptionStep };

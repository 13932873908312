import { Validator } from 'utils/validator';

import { errorsStore } from 'stores';

export function nestedValidation(reqArgs: {
  action: string;
  schema: {
    [K in any]: any;
  };
  data: AnyObject;
  prefix?: string;
  skipRule?: (object: AnyObject) => boolean;
}) {
  const action = reqArgs.action;
  const validationSchema = reqArgs.schema;
  const prefix = reqArgs.prefix;

  if (!validationSchema) {
    console.warn(`Empty validator object for action ${action}`);
  }
  const skipRule =
    reqArgs.skipRule ||
    function () {
      return false;
    };
  const validator = new Validator(reqArgs.data, validationSchema, prefix, skipRule);
  const errors: any = validator.errors;

  !!errors && errorsStore.add(action, errors);

  return !errors;
}

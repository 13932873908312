import { DummyStore } from 'stores';

export const integrationsStore = new DummyStore<AnyObject[]>([]);

export const paginationStore = new DummyStore<{ page: number; limit: number; total: number }>({
  page: 1,
  limit: 30,
  total: 0,
});
export const raceStore = new DummyStore<RaceType>();
export const confirmationDeleteStore = new DummyStore<{ open: boolean; id: number | null }>({
  open: false,
  id: null,
});

import { cloneDeep } from 'lodash';

import { OptionWithParentId } from '../types';

export const mapRacesForDropdown = (races: AnyObject[]): SelectOption[] => {
  return cloneDeep(races).map((item) => ({
    key: item.id,
    value: item.id,
    label: `${item.name} (${item.id})`,
  }));
};

export const mapOptions = (list: AnyObject[], distances: SelectOption[], labelName: string = 'title'): OptionWithParentId[] => {
  return list.map((item) => {
    const distance = distances.find((distance) => distance.key === item.distance_id);
    return {
      key: item.id,
      value: item.id,
      label: `${item[labelName]} (${distance?.label || ''})`,
      parentId: Number(distance?.key),
    };
  });
};

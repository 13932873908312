import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { ActionButton } from 'modules/RaceDetails/components';
import * as React from 'react';

import { Icon } from 'components/Icon';

import { t } from 'utils';

import { Race } from 'models';

type Props = {
  race: nil | Race;
  onClick?: React.MouseEventHandler;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    flexDirection: 'column',
    gap: '15px',
    lineHeight: '16px',
    letterSpacing: 'unset',
    padding: '14px 0',
  },
}));

const DownloadStartList = ({ race, onClick, className }: Props) => {
  const c = useStyles();

  return (
    <ActionButton className={classnames(className, c.button)} variant='text' onClick={onClick}>
      <Icon value='download-alt' />
      {t.staticAsString('racers.list.downloadStartList')}
    </ActionButton>
  );
};

export { DownloadStartList };

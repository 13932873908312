import React from 'react';

import { CouponDetails } from '../components';

import { mountCouponDetails, unmountCouponDetails } from '../actions';

export const CouponDetailsContainer = () => {
  React.useEffect(() => {
    mountCouponDetails();
    return () => {
      unmountCouponDetails();
    };
  }, []);

  return <CouponDetails />;
};

export { CouponDetailsContainer as CouponDetails };

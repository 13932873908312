import { fetchApiOrigin } from '../../apiOrigin';
import { storeKey, HTML_ELEMENT } from './constants';

/*
 * Fetch current api origin alias from localStorage
 * forced - for testing and playground ONLY
 */
function fetchApiFromStorage(): string | null {
  const value = localStorage.getItem(storeKey);
  return (value && fetchApiOrigin(value)) || null;
}

/*
 * Fetch current api origin from document
 * MAIN
 */
function fetchApiFromDocument(): string | null {
  const value = document.getElementById(HTML_ELEMENT);

  if (!value) {
    return null;
  }

  return value.dataset.api || null;
}

export { fetchApiFromDocument, fetchApiFromStorage };

import { monitorLoadProgressByJobId, selectFile, t } from 'utils';

import { toastStore, errorsStore, progressStore, loadProgress } from 'stores';

import { IMPORT_START_LIST } from '../constants';

import { importService, racersService } from '../services';

import { startlistMembersStore, startlistRacersStore, teamsStore } from '../stores';

import { exportTempalateType } from '../types';
import { reloadDistance } from './reloadDistance';

export const onImport = async (type: keyof typeof importType, startListType: exportTempalateType) => {
  const importType = {
    addNew: '1',
    updateExisting: '2',
    addAndUpdate: '3',
  };

  errorsStore.clearError(IMPORT_START_LIST);

  const { distanceId } =
    startListType === 'racers' ? startlistRacersStore : startListType === 'members' ? startlistMembersStore : teamsStore;

  if (!distanceId) {
    return;
  }

  const file = await selectFile('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

  if (!file) {
    return;
  }
  progressStore.log(IMPORT_START_LIST, 'progress');

  const data = new FormData();
  data.append('file', file);
  data.append('type', importType[type]);

  const [status, response] = await importService.import(distanceId, data);

  if (!status) {
    toastStore.show(t.staticAsString('startList.messages.importFail'));
    return;
  }

  const estimatedLoadingTime = file.size / 10000;

  const { id } = response.data.data;

  loadProgress.addLoadingJob(id, estimatedLoadingTime, {
    upload: true,
    fileName: file.name,
    onRetry: () => onImport(type, startListType),
  });

  const [monitorStatus, jobResponse] = await monitorLoadProgressByJobId(id);

  if (monitorStatus) {
    await reloadDistance();
    await racersService.loadResources(distanceId);
  } else {
    errorsStore.addApiError(IMPORT_START_LIST, jobResponse, 'data');
  }
};

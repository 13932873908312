import { Box, Typography } from '@mui/material';
import Axios from 'axios';
import { formatCurrency } from 'modules/Reconciliation/shared/utils/formatCurrency';
import React from 'react';
import { generatePath } from 'react-router-dom';

import { COMMISSION_URL, ORGANIZER_PAYMENT_METHOD } from 'src/constants';
import { t } from 'src/utils';

import ToggleServiceSettings from 'components/AbsorbServiceFee/AbsorbServiceFee';

import { currenciesStore } from 'stores';

import { Commission } from '../../../SystemUsers/types';

type Props = {
  commission: Commission;
};

export default function NoCommissions({ commission }: Props) {
  const [isAbsorb, setIsAbsorb] = React.useState<boolean>(commission.is_absorb);
  const handleToggleAbsorb = () => {
    const flipAbsorb = async () => {
      setIsAbsorb(!isAbsorb);
      try {
        await Axios.put(generatePath(COMMISSION_URL, { id: commission.id }), {
          is_absorb: !isAbsorb,
        });
      } catch (error) {
        console.log(error);
      }
    };
    flipAbsorb();
  };

  const handleTogglePaymentMethod = (pid: number) => {
    const togglePaymentMethod = async () => {
      try {
        await Axios.put(generatePath(ORGANIZER_PAYMENT_METHOD, { id: pid }));
      } catch (error) {
        console.log(error);
      }
    };
    togglePaymentMethod();
  };

  return (
    <Box sx={{ px: '20px', pb: '20px' }}>
      <Typography sx={{ fontWeight: 700, fontSize: 20, marginBottom: 3 }}>{t.static('reconciliation.noCommissions.header')}</Typography>
      <Box sx={{ border: '1px solid rgba(168, 174, 184, 0.24)', borderRadius: 4, p: 3 }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontSize: 16, color: '#A8AEB8', fontWeight: 700 }}>Service fee</Typography>
            <Typography>
              {formatCurrency(commission.amount / 100, currenciesStore.currencyById(commission.currency_id)?.iso_code ?? 'EUR')}
            </Typography>
            <ToggleServiceSettings
              label='Absorb service fee'
              handleToggle={handleToggleAbsorb}
              isAbsorb={isAbsorb}
              tooltip={t.staticAsString('servicefee.info')}
              showInfo={true}
            />
          </Box>
          <Box sx={{ width: '1px', height: 46, mx: 3, background: '#D9D9D9' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontSize: 16, color: '#A8AEB8', fontWeight: 700 }}>
              {t.static('reconciliation.noCommissions.commissionPercent')} %
            </Typography>
            <Typography>{commission.percent_value / 100}%</Typography>
          </Box>
          <Box sx={{ width: '1px', height: 46, mx: 3, background: '#D9D9D9' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontSize: 16, color: '#A8AEB8', fontWeight: 700 }}>Refund fee</Typography>
            <Typography>
              {formatCurrency(commission.refund_fee / 100, currenciesStore.currencyById(commission.currency_id)?.iso_code ?? 'EUR')}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

import * as React from 'react';

import { NATIONALITY_ID } from 'src/constants/mixed';

import { FormattedDate } from 'components/FormattedDate';

import { commonDateFormats, t } from 'utils';

import { squadsService } from 'services';

import { countriesStore, squadsStore } from 'stores';

import { AutocompleteSelect } from './AutocompleteSelect';
import { DatePickerInput } from './DatePicker';
import { Input as CustomInput } from './Input';
import { Select } from './Select';

type Props = {
  field: string;
  item: RacerType;
  changedFields: Object;
  isEdit: string | null;
  distance: DistanceType;
  saveRacer: (...args: any[]) => void;
  onChange: (name: string, value: any, changedValue: any) => void;
};

const REGISTERED_TYPE: AnyObject = {
  address: CustomInput,
  post_code: CustomInput,
  company: CustomInput,
  union: CustomInput,
  nationality_id: Select,
  squad_id: AutocompleteSelect,
  birthday: DatePickerInput,
  telephone: CustomInput,
  shoe_size: CustomInput,
  shirt_size: CustomInput,
  emergency_name: CustomInput,
  emergency_phone: CustomInput,
};

class RegisteredFields extends React.Component<Props> {
  formatData = (field: string, value: any): any => {
    const squadLabel = squadsStore.values.find((item) => item.id === value)?.name;
    const { item } = this.props;

    switch (field) {
      case 'birthday':
        return <FormattedDate value={value} format={commonDateFormats.shortDate} />;
      case 'squad_id':
        return squadLabel ?? (item.squad_id && item.squad?.name) ?? '';
      default:
        return value;
    }
  };

  onChange = (field: string, value: any) => {
    const { onChange } = this.props;
    onChange(field, value, value);
  };

  render() {
    const { distance, field, isEdit, item, saveRacer } = this.props;
    const value = (item as AnyObject)[field] || '';
    const Field = REGISTERED_TYPE[field];

    const rest = {
      hasMore: squadsService.store.hasMore,
      loadMore: () => {
        squadsService.loadDistanceSquads(distance.id);
      },
      onRemove: () => this.onChange(field, null),
    };

    if (isEdit === field && Field) {
      return (
        <Field
          onChange={this.onChange}
          item={item}
          field={field}
          value={value}
          saveRacer={saveRacer}
          errors={[]}
          distance={distance}
          {...(field === 'squad_id' ? rest : {})}
        />
      );
    }

    if (field === NATIONALITY_ID) {
      const neededCountry = countriesStore.findModelValue(+(item as AnyObject)[field]);

      if (!neededCountry) {
        return '';
      }

      return neededCountry.nationalityName();
    }

    return this.formatData(field, value);
  }
}

export { RegisteredFields };

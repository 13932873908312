import Typography from '@mui/material/Typography';
import { reject, pick, assign, debounce } from 'lodash';
import { observer } from 'mobx-react';
import { ERRORS_PREFIXES } from 'modules/Distances/constants';
import { havePaidCustomFields } from 'modules/Distances/utils';
import { clearFieldError, clearNamespaceErrors, getDistanceErrors, getRelationErrors } from 'modules/Distances/utils/errors';
import moment from 'moment';
import * as React from 'react';
import shortid from 'shortid';

import { DISTANCE_STEPS, PERSIST_DISTANCE as action } from 'src/constants';

import { withErrorClean } from 'hocs';

import { TextField } from 'components/Fields';
import { Icon } from 'components/Icon';

import { t } from 'utils';

import { Race as RaceModel } from 'models';

import { hasCompletedOrders } from '../../../../stores';

import { State as OriginalFormState } from '../../../../shared/stateHelpers';
import { Item } from './Item';

type Props = {
  formData: OriginalFormState;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
    isDirty?: boolean,
  ) => void;
  helperData: {
    race: nil | RaceModel;
  };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

const FIELDS = {
  vat_percents: 'vat_percents',
};

const stepAction = `${action}_${DISTANCE_STEPS.prices}`;

const keys = ['distance_id', 'date_from', 'value'];

@withErrorClean(stepAction)
@observer
class Prices extends React.Component<Props> {
  onlyNumberRegexp = /^[\d.]*$/;

  componentDidMount() {
    this._addDefaultValues();
  }

  onChange = (name: string, values: Array<Object> | Object, callback: Function = () => {}, isNeedLiveUpdate = true) => {
    const { onChange, formData, liveUpdate } = this.props;

    let updatedState: AnyObject = {};

    if (name === 'distance') {
      assign(updatedState, { distance: { ...formData.distance, ...values } });
      isNeedLiveUpdate && liveUpdate(values, true);
    }
    if (name === 'prices') {
      assign(updatedState, { [name]: values });
      isNeedLiveUpdate && liveUpdate({ [name]: values }, true);
    }

    onChange(
      {
        ...updatedState,
      },
      '',
      () => {
        callback();
      },
      false,
    );
  };

  onChangeDistance = (name: string, value: any): void => {
    this.onChange('distance', {
      [name]: value,
    }),
      clearFieldError(0, ERRORS_PREFIXES.prices, 'vat_percents');
  };

  onAddNew = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { prices } = this.props.formData;
    const values = [...prices, getPrice()];

    this.onChange(
      'prices',
      values,
      () => {
        clearNamespaceErrors(ERRORS_PREFIXES.prices);
      },
      false,
    );
  };

  onRemove = (id: number | string) => {
    const { prices } = this.props.formData;
    const values = reject(prices, (price) => price.id === id || price.__id === id);

    this.onChange('prices', values, () => {
      clearNamespaceErrors(ERRORS_PREFIXES.prices);
    });
  };

  onChangePrice = (id: number | string, changedDistancePrices: PriceType, callback: Function = () => {}, isNeedLiveUpdate) => {
    const { prices } = this.props.formData;

    const newData = prices.map((price) => (price.id === id || price.__id === id ? changedDistancePrices : price));

    this.onChange('prices', newData, callback, isNeedLiveUpdate);
  };

  onChangeOnlyNumber = (e: React.ChangeEventHandler | any): void => {
    const { name, value } = e.currentTarget;
    const isNumber = this.onlyNumberRegexp.test(value.toString());

    const formattedValue = this._formatValue({ name, value });
    clearNamespaceErrors(ERRORS_PREFIXES.vat_percents);
    if (isNumber) {
      this.onChangeDistance(name, formattedValue);
    }
  };

  _formatValue = ({ name, value }: { name: string; value: string }) => {
    switch (name) {
      case FIELDS.vat_percents:
        return value || null;
      default:
        return value;
    }
  };

  _renderDistancePrices = (): React.ReactNode => {
    const { formData } = this.props;
    const { prices } = formData;
    const { distance } = formData;
    const minPriceDate = distance.registration_starts_at;
    const maxPriceDate = distance.registration_ends_at;
    const currency = this._currency();

    return prices.reduce((acc: any, price: any, index: number) => {
      const separator = acc.length ? [<li className='separator' key={`separator-${index}`}></li>] : [];
      const momentDate = moment(price.date_from);
      return [
        ...acc,
        ...separator,
        <Item
          minPriceDate={minPriceDate}
          maxPriceDate={maxPriceDate}
          helperData={{ formData }}
          key={price.id || price.__id}
          index={index}
          value={price}
          onChange={this.onChangePrice}
          onRemove={this.onRemove}
          currency={currency}
          dateFrom={momentDate.isValid() ? momentDate : moment(minPriceDate)}
        />,
      ];
    }, []);
  };

  _renderVATField = (): React.ReactNode => {
    const errors = getRelationErrors(0, ERRORS_PREFIXES.prices, 'vat_percents').length
      ? getRelationErrors(0, ERRORS_PREFIXES.prices, 'vat_percents')
      : getDistanceErrors('vat_percents');
    const { formData } = this.props;
    const { vat_percents } = formData.distance;
    const value = vat_percents && vat_percents.toString();

    const { prices } = formData;
    // const isAnyPriceSaved = prices.some((price) => !price._new);
    return (
      <div className='vat-wrapper'>
        <TextField
          value={value}
          name='vat_percents'
          label={t.staticAsString('distances.steps.pricesForm.vat_percents')}
          errors={errors}
          tooltipText={t.staticAsString('distances.steps.pricesForm.vatHelperText')}
          position='icon'
          onChange={this.onChangeOnlyNumber}
          fullWidth
          // disabled={hasCompletedOrders.value && isAnyPriceSaved}
        />
      </div>
    );
  };

  _addDefaultValues = () => {
    const { prices } = this.props.formData;
    const { onChange } = this.props;

    if (!prices.length) {
      onChange({ prices: [getPrice(true)] }, '', () => {});
    }
  };

  _currency = (): string => {
    const { race } = this.props.helperData;
    if (!race) {
      return '';
    }

    const currency = race.currency();
    return currency ? `(${currency})` : '';
  };

  render() {
    return (
      <div className='distance-price-fields-form form-content-wrapper'>
        <div className='add-new' onClick={this.onAddNew}>
          <Icon value='add-small' className='icon' />
          <Typography className='label'>{t.staticAsString('distances.steps.pricesForm.add')}</Typography>
        </div>
        <ul className='distance-prices-list'>
          {this._renderVATField()}
          <div className='separator' />
          {this._renderDistancePrices()}
        </ul>
        {this.props.controls}
      </div>
    );
  }
}

const getPrice = (isFirst: boolean = false): PriceType => {
  return {
    __id: shortid(),
    value: '',
    date_from: '',
    _new: isFirst,
  };
};

const normalizeDataForBackend = (
  values: PriceType[],
): Array<
  | {
      id?: number;
    }
  | PriceType
> => {
  return values;
};

const normalizeDataFromBackend = (values: PriceType[] | any): Array<PriceType> => {
  return values.map((price: any) => ({
    ...pick(price, keys),
    __id: shortid(),
  }));
};

const cleanStep = (formData: OriginalFormState | any): OriginalFormState => {
  const { custom_fields, distance } = formData;
  distance.vat_percents = null;

  if (!havePaidCustomFields(custom_fields)) distance.refund_protect_enabled = false;

  return { ...formData, distance };
};

export { Prices, getPrice, normalizeDataForBackend, normalizeDataFromBackend, cleanStep };

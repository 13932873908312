import { isEmpty } from 'lodash';

import { helperDistancesStore } from 'src/stores';

import { customFieldService } from '../services';

import { CFWithRegisteredUsersStore } from '../stores';

export async function CFwithRegisteredUsers() {
  const currentDistance = helperDistancesStore.selected;

  if (!currentDistance || !currentDistance.custom_fields || !isEmpty(CFWithRegisteredUsersStore.value)) {
    return;
  }

  const distanceId = currentDistance.id;
  const customFieldsIDs = currentDistance.custom_fields.map((el) => el.id);

  const CFwithRegisteredUsers = await Promise.all(
    customFieldsIDs.map((id) => {
      return customFieldService.isCustomFieldHasRacer(distanceId, id);
    }),
  );

  const CFwithRegisteredUsersObj = {};
  CFwithRegisteredUsers.forEach((el, i) => {
    Object.assign(CFwithRegisteredUsersObj, CFwithRegisteredUsers[i]);
  });
  CFWithRegisteredUsersStore.set(CFwithRegisteredUsersObj);
}

import { set } from 'lodash';

function unfold(values: RawError): ErrorType {
  const keys = Object.keys(values);

  let errors = {};

  keys.forEach((key) => {
    const value = values[key];
    set(errors, key, value);
  });

  return errors;
}

export { unfold };

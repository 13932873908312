import { Button, Tooltip } from '@mui/material';
import { debounce, isNumber, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import validate from 'validate.js';

import { RACER } from 'src/constants';

import { withErrorClean } from 'hocs';

import { Show } from 'components/Condition';
import { AutocompleteSelect } from 'components/Fields';
import { Checkbox } from 'components/Fields/Checkbox';
import { Input } from 'components/Fields/Input';
import { SelectField } from 'components/Fields/SelectField';
import { AutocompleteSelectInfinityLoad, Date, PhoneInput, TextField } from 'components/Form';
import { Icon } from 'components/Icon';

import { validation, oneFieldValidation, gender, t } from 'utils';

import { Distance } from 'models';

import { countriesStore } from 'stores';
import { Errors as ErrorsStore, Country as CountryType } from 'stores';

import { registrationFieldsTools } from '../../../../utils';

import { loadCustomerProfileService, membersLifecycle, membersService, membersTeamService } from '../../../../services';

import { membersTeamStore } from '../../../../stores';

import { raceNewMapper, racerToState } from '../../../../mapper';
import { member as createMemberConstraints, memberValidation } from '../../../../validations';

type OriginalProps = {
  errorsStore?: ErrorsStore;
  distance: Distance;
  helperDistances: DistanceType;
  onClose: () => void;
  onSubmit?: () => void;
  countries: CountryType;
} & RouterProps;

type Props = OriginalProps & HOC.withIntl;

type State = {
  racer: {
    bib_number: string;
    firstname: string;
    lastname: string;
    email: string;
    gender: string;
    team_id: nil | number;
    user_id: nil | number;
    additional_fields?: Array<string>;
  };
};

const action = `CREATE_${RACER}`;

const VALIDATE_FIELDS: AnyObject = {
  bib_number: /^[0-9]+$/,
};

const FIELDS = {
  email: 'email',
};

@withRouter
@withErrorClean(action)
@inject('errorsStore', 'helperDistancesStore')
@observer
class RacerFormImpl extends React.Component<Props, State> {
  initialState = {
    racer: {
      firstname: '',
      lastname: '',
      bib_number: '',
      email: '',
      gender: '',
      team_id: null,
      user_id: null,
    },
  };

  state: State = { ...this.initialState };

  debouncedLoadRacerProfile: Function;

  constructor(props: Props) {
    super(props);
    this.debouncedLoadRacerProfile = debounce(this.loadRacerProfile, 500);
  }

  @validation({ action, constrains: createMemberConstraints })
  validate() {
    return this.state.racer;
  }

  componentWillUnmount() {
    const { distance } = this.props;
    membersTeamStore.clearAll();
    membersTeamStore.clearFilters();
    membersTeamService.loadTeamsForMembers(distance.value.id);
  }

  onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { distance } = this.props;
    const isValidated = await this.validate();

    if (!isValidated || !!this.errors().length) {
      return;
    }

    await membersService.createResource(raceNewMapper.mapTo(this.state.racer) as any, distance?.value?.id, () => this.props.onClose());
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | null | boolean = null;
    let name = '';

    switch (e.type) {
      case 'click':
        value = e.target.value;
        name = e.target.name;
        break;
      case 'change':
        value = e.currentTarget.value;
        name = e.currentTarget.name;
        break;
      default:
        value = e.currentTarget.value;
        name = e.currentTarget.name;
    }

    if (name === 'payment_required') {
      value = e.currentTarget.checked;
    }

    if (VALIDATE_FIELDS[name] && (value as string).length && !(value as string).match(VALIDATE_FIELDS[name])) {
      return;
    }

    this.setState(
      {
        ...this.state,
        racer: {
          ...this.state.racer,
          [name]: value,
        },
      },
      () => {
        const constrainsField = { [name]: memberValidation[name] };
        oneFieldValidation(action, this.state.racer, constrainsField);
        this.afterOnChangeHook({ name, value });
      },
    );
  };

  onChangePhone = (name: string, value: Object) => this.onChangeForm({ name, value });

  onChangeForm = ({ name, value }: AnyObject): void => {
    this.setState(
      {
        racer: {
          ...this.state.racer,
          [name]: value,
        },
      },
      () => {
        const constrainsField = { [name]: memberValidation[name] };
        oneFieldValidation(action, this.state.racer, constrainsField);
        this.afterOnChangeHook({ name, value });
      },
    );
  };

  errors = () => {
    const { errorsStore } = this.props;
    const errors = errorsStore?.errors.api[action];
    const validationErrors = errorsStore?.errors.validation[action];

    if (validationErrors) {
      return validationErrors.formatErrors();
    }
    if (errors) {
      return errors.formatErrors();
    }
    return {};
  };

  defaultInputProps = (name: string) => {
    let value = (this.state.racer as AnyObject)[name];
    if (name === 'gender' && isNumber(value)) {
      value = value.toString();
    }

    return {
      name,
      value,
      onChange: this.onChange,
      errors: this.errors()[name],
      fullWidth: true,
    };
  };

  inputProps = (name: string): any => {
    return {
      name,
      id: name,
      value: this.state.racer[name],
      onChange: this.onChangeForm,
      errors: this.errors()[name],
      error: this.errors()[name],
      label: `${t.staticAsString(`racers.new.${name}` as TranslationLockedKeys)}`,
      view: {
        fullWidth: true,
      },
    };
  };

  loadRacerProfile = async () => {
    const { email } = this.state.racer;
    const emailErrors = validate({ email }, { email: { email: true } });

    let racerData: RacerType | nil;

    if (email && !emailErrors) {
      racerData = await loadCustomerProfileService.load(email);
    }

    if (!racerData) {
      return;
    }

    this.setState({
      racer: {
        ...this.initialState.racer,
        ...racerToState(racerData),
      },
    });
  };

  afterOnChangeHook = ({ name, value }: { name: string; value: any }) => {
    if (name === FIELDS.email) {
      this.debouncedLoadRacerProfile();
    }
  };

  renderTeamsForSelect = () => {
    const { helperDistances } = this.props;

    return membersTeamStore.values
      .filter((member) => member.racers_count !== helperDistances.max_members_quantity)
      .map((item) => ({
        key: item.id,
        value: item.id,
        label: `${item.name}(${item.racers_count || 0}/${helperDistances.max_members_quantity})` || '',
      }));
  };

  isSelected = (name: string): boolean => {
    const { distance } = this.props;
    return registrationFieldsTools.isSelected(distance.value.registration_fields || [], name as registrationFields);
  };

  isRequired = (name: string): boolean => {
    const { distance } = this.props;
    return registrationFieldsTools.isRequired(distance.value.registration_fields || [], name as registrationFields);
  };

  render() {
    const { onClose, distance } = this.props;
    const genderOptions = gender.options();

    const isPricesEnabled = !isEmpty(distance.value.prices);

    const disciplineOptions = distance.relationForSelect('disciplines');

    return (
      <div className='content main-racer-form'>
        <h1 className='racer-title'>{t.staticAsString('racers.addNewMember')}</h1>
        <div className='full-name racer-form-element'>
          <Input {...this.defaultInputProps('firstname')} label={t.staticAsString('racers.new.firstName')} required />
          <Input {...this.defaultInputProps('lastname')} label={t.staticAsString('racers.new.lastName')} required />
        </div>
        <div className='racer-form-element'>
          <Input {...this.defaultInputProps('email')} label={t.staticAsString('racers.new.email')} required />
        </div>

        {/* NOTE, disabled push payment*/}
        <Show if={isPricesEnabled && false}>
          <div className='racer-form-element checkbox-wrap'>
            <Checkbox {...this.defaultInputProps('payment_required')} label={t.staticAsString('racers.new.payment_required')} />
            <Tooltip
              title={t.staticAsString('racers.new.paymentTooltip')}
              placement='bottom-start'
              classes={{
                tooltip: 'checkbox-tooltip',
                popper: 'checkbox-popper',
              }}
            >
              <span className='tooltip-icon'>
                <Icon value='tooltip' />
              </span>
            </Tooltip>
          </div>
        </Show>
        {/* NOTE, disabled push payment*/}

        <div className='racer-form-element'>
          <AutocompleteSelectInfinityLoad
            {...this.defaultInputProps('team_id')}
            view={{
              required: true,
              variant: 'outlined',
              reservePlaceForHelperText: true,
            }}
            additional={{
              options: this.renderTeamsForSelect(),
            }}
            label={t.staticAsString('racers.new.team')}
            onChange={this.onChangeForm}
            hasMore={membersTeamService.store.hasMore}
            loadMore={() => {
              membersTeamService.loadTeamsForMembers(distance.value.id);
            }}
            onSearch={(text: string) => {
              membersTeamService.store.clearAll();
              membersTeamService.store.setFilters(
                {
                  search: `name:${text}`,
                  searchFields: `name:like`,
                },
                1,
              );
              membersTeamService.loadTeamsForMembers(distance.value.id);
            }}
          />
        </div>
        <Show if={disciplineOptions.length > 0}>
          <div className='racer-form-element'>
            <AutocompleteSelect
              {...this.defaultInputProps('discipline_id')}
              required
              options={disciplineOptions}
              label={t.staticAsString('racers.new.discipline_id')}
              onChange={this.onChangeForm}
              sx={{ marginBottom: '20px' }}
            />
          </div>
        </Show>
        <Show if={this.isSelected('country_id')}>
          <div className='racer-form-element'>
            <AutocompleteSelect
              {...this.inputProps('country_id')}
              options={countriesStore.valuesForCountryDropdown()}
              label={t.staticAsString('racers.new.country')}
              onChange={this.onChangeForm}
              sx={{ marginBottom: '20px' }}
            />
          </div>
        </Show>
        <div className='racer-form-element'>
          <SelectField
            {...(this.defaultInputProps('gender') as any)}
            required
            classNasme='gender'
            options={genderOptions}
            label={t.staticAsString('racers.new.gender')}
            variant={'standard'}
          />
        </div>
        <Show if={this.isSelected('birthday')}>
          <div className='racer-form-element'>
            <Date {...this.inputProps('birthday')} label={t.staticAsString('racers.new.birthday')} />
          </div>
        </Show>
        <Show if={this.isSelected('nationality_id')}>
          <div className='racer-form-element'>
            <AutocompleteSelect
              {...this.inputProps('nationality_id')}
              options={countriesStore.valuesForNationalityDropdown()}
              label={t.staticAsString('racers.new.nationality_id')}
              onChange={this.onChangeForm}
              sx={{ marginBottom: '20px' }}
            />
          </div>
        </Show>
        <Show if={this.isSelected('telephone')}>
          <div className='racer-form-element z-index-3'>
            <PhoneInput {...this.inputProps('telephone')} fullWidth onChange={this.onChangePhone} />
          </div>
        </Show>
        <Show if={this.isSelected('address')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('address')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('post_code')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('post_code')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('city')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('city')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('union')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('union')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('emergency_name')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('emergency_name')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('emergency_phone')}>
          <div className='racer-form-element z-index-3'>
            <PhoneInput {...this.inputProps('emergency_phone')} fullWidth onChange={this.onChangePhone} />
          </div>
        </Show>
        <Show if={this.isSelected('shoe_size')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('shoe_size')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('shirt_size')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('shirt_size')} />
          </div>
        </Show>
        <Show if={this.isSelected('company')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('company')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('external_swimrun_id')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('external_swimrun_id')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <div className='btn-group'>
          <Button className='cancel' onClick={() => onClose()}>
            {t.staticAsString('racers.new.cancel')}
          </Button>
          <Button className='submit' onClick={(e) => this.onSubmit(e)}>
            {t.staticAsString('racers.new.submit')}
          </Button>
        </div>
      </div>
    );
  }
}

export const RacerForm = RacerFormImpl as unknown as React.ComponentType<OriginalProps>;

import TablePagination from '@mui/material/TablePagination';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import racerService from '../../../services/racersService';

import { Racers as RacersStore } from '../../../stores';

type Props = {
  startlistRacersStore: RacersStore;
  distance_id: number;
  switchedOnTop: boolean;
};

let Pagination = ({ startlistRacersStore, distance_id, switchedOnTop }: Props) => {
  const { paginationMeta } = startlistRacersStore;
  const page = startlistRacersStore.page;
  const { per_page, total } = paginationMeta;

  return (
    <TablePagination
      component='div'
      count={total || 0}
      rowsPerPage={per_page || 30}
      rowsPerPageOptions={[30, 50, 100, 200]}
      page={page - 1}
      className={classnames({ pagination: !switchedOnTop })}
      classes={{
        selectLabel: 'caption',
        displayedRows: 'caption',
        select: 'choose-num',
        selectIcon: 'choose-num-svg',
      }}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      onPageChange={(event, page) => {
        racerService.loadResources(distance_id, {}, page + 1);
      }}
      onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        racerService.loadResources(distance_id, { limit: Number(event.target.value) });
      }}
    />
  );
};

Pagination = observer(Pagination);
export { Pagination };

import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { getAssistantLabel } from 'modules/Distances/utils/getAssistantLabel';
import * as React from 'react';

type Props = {
  onRemove(id: number | string): void;
  value: MedicalAssistantType;
  index: number;
} & HOC.withIntl;

export class Item extends React.Component<Props> {
  onRemove = () => {
    const { onRemove, value } = this.props;
    const id = value.id || '';
    onRemove(id);
  };

  render() {
    const { value } = this.props;

    return (
      <li className='distance-medical-assistant-item'>
        <p>{getAssistantLabel(value)}</p>
        <IconButton aria-label='remove medical assistant' onClick={this.onRemove} size='large'>
          <CloseIcon />
        </IconButton>
      </li>
    );
  }
}

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';
import { flow } from 'lodash';
import React, { useEffect } from 'react';

import { Show } from 'src/components/Condition';
import { Footer } from 'src/components/Footer';
import { withoutGuestLayout, withoutHeader, withoutSidebar, withoutFooter } from 'src/components/hocs';
import { sessionStore } from 'src/stores';

import { intercomUtil, detectIframeLink, history } from 'utils';

import { Header } from '../components/Header';
import { MainContent } from '../components/MainContent';

import { CooperationAgreement } from './CooperationAgreement';
import { ProcessAgreement } from './ProcessAgreement';
import { termsActionsStyles } from './styles';

const COOPERATION_AGREEMENT_PATH_PREFIX = 'cooperation-agreement';
const PROCESS_AGREEMENT_PATH_PREFIX = 'data-processing-agreement';

const Base = () => {
  const classes = termsActionsStyles();
  let ComposedComponent;

  useEffect(() => {
    intercomUtil.init();

    return () => {
      intercomUtil.shutdown();
    };
  }, []);

  const navigateToBack = (): void => {
    window.close();
  };

  const validateLink = (path: string): boolean => {
    const urlParts = history.location.pathname.split('/');

    return Boolean(urlParts.find((item) => item === path));
  };

  if (validateLink(COOPERATION_AGREEMENT_PATH_PREFIX)) {
    ComposedComponent = CooperationAgreement;
  }
  if (validateLink(PROCESS_AGREEMENT_PATH_PREFIX)) {
    ComposedComponent = ProcessAgreement;
  }

  return (
    <>
      <Header />
      <Show if={!detectIframeLink()}>
        <div className='go-to-back-action'>
          <IconButton aria-label='back' className={classes.backButton} onClick={navigateToBack} size='large'>
            <ArrowBackIosIcon />
          </IconButton>
          <span className='go-to-back-action--label'>go back to the main page</span>
        </div>
      </Show>
      <MainContent children={<ComposedComponent />} />
      <Footer />
    </>
  );
};

const compose = flow([withoutHeader, withoutSidebar, withoutFooter]);

const Terms = sessionStore.isLogged ? compose(Base) : withoutGuestLayout(Base);

export { Terms };

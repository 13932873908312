import { Button } from '@mui/material';
import * as React from 'react';

import { t } from 'utils';

import { formControlsStyles } from '../styles';

type Props = {
  isEditMode?: boolean;
  onCancel?: AnyFunction;
  label?: string;
};

export const FormControls = (props: Props) => {
  const classes = formControlsStyles();

  return (
    <div className={classes.controlsContainer}>
      <Button className={classes.cancelBtn} onClick={props.onCancel}>
        {t.staticAsString('races.new.cancel')}
      </Button>
      <Button className={classes.createBtn} disableTouchRipple type='submit'>
        {props.isEditMode ? t.staticAsString('races.edit.submit') : t.staticAsString('races.new.submit')}
      </Button>
    </div>
  );
};

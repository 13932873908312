// Deprecated code
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MaterialInput from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { PhoneInput as PhoneInputComponent } from 'racese-react-phone-input-2';
import * as React from 'react';

import { Icon } from 'components/Icon';

import { stringFormatter, t } from 'utils';

type Props = {
  classes?: {
    [K in string]: string;
  };
  onChange: Function;
  keyPress?: React.KeyboardEventHandler;
  onBlur?: React.FocusEventHandler;
  onFocus?: React.FocusEventHandler;
  errors: Array<string>;
  name: string;
  value: any;
  type?: string;
  id?: string;
  label?: string;
  className?: string;
  tooltipText?: string;
  position?: 'icon' | 'bottom';
  fullWidth?: boolean;
  autoFocus?: boolean;
};

type State = {
  isInFocus: boolean;
};

export class PhoneInput extends React.Component<Props, State> {
  state = {
    isInFocus: false,
  };
  static defaultProps = {
    classes: {
      root: 'field-root',
      input: 'field',
    },

    keyPress: null as any,
    errors: [],
    autoFocus: false,
    type: 'text',
    id: '',
    label: '',
    className: 'form-control',
    fullWidth: false,
    position: 'icon',
    tooltipText: '',
  };

  renderTooltip = () => {
    const { position, tooltipText, errors, name, id } = this.props;
    const formName = id ? `${id}_${name}` : name;
    const errorMessage = errors.join(' ');

    switch (position) {
      case 'bottom':
        return (
          !errorMessage &&
          tooltipText && (
            <FormHelperText className='tooltip-text' id={`${formName}_tooltip`}>
              {tooltipText}
            </FormHelperText>
          )
        );

      case 'icon':
        return tooltipText ? (
          <Tooltip title={stringFormatter.withNewLine(tooltipText)} classes={{ tooltip: 'switch-popover' }}>
            <span>
              <Icon className='info-helper' value='info' />
            </span>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              <React.Fragment>
                <p>{t.staticAsString('phoneInput.tooltip.p1')}</p>
                <p>{t.staticAsString('phoneInput.tooltip.p2')}</p>
                <p>{t.staticAsString('phoneInput.tooltip.p3')}</p>
              </React.Fragment>
            }
            classes={{ tooltip: 'switch-popover' }}
          >
            <span>
              <Icon className='info-helper' value='info' />
            </span>
          </Tooltip>
        );

      default:
        return null;
    }
  };

  renderInput = React.forwardRef((props: Object, ref: React.RefObject<HTMLInputElement>) => {
    return (
      <PhoneInputComponent
        {...props}
        ref={ref}
        placeholder=''
        onChange={this.handleChange}
        enableSearchField
        enableLongNumbers
        disableSearchIcon
        disableDropdown={false}
      />
    );
  });

  onFocus = (e: React.FocusEvent<any>) => {
    this.setState({
      isInFocus: true,
    });

    this.props.onFocus && this.props.onFocus(e);
  };

  onBlur = (e: React.FocusEvent<any>) => {
    this.setState({
      isInFocus: false,
    });

    this.props.onBlur && this.props.onBlur(e);
  };

  handleChange = (phone: string, countryData: Object) => {
    const { onChange, name } = this.props;

    onChange(name, {
      phone,
      countryData,
    });
  };

  render() {
    const { classes, name, keyPress, value, errors, autoFocus, type, id, label, className, fullWidth } = this.props;

    const val = value === undefined || value === null ? '' : value.phone;

    const formName = id ? `${id}_${name}` : name;
    const isErr = errors.length > 0;
    const inputClasses = {
      ...{
        focused: 'focused',
        underline: val ? 'filled-underline' : 'underline',
      },
      ...classes,
    };

    const errorMessage = errors.join(' ');

    const isShrink = !!val || this.state.isInFocus;
    return (
      <FormControl
        fullWidth={fullWidth}
        className={classNames('input-wrap', className, { error: isErr })}
        error={isErr}
        aria-describedby='name-error-text'
      >
        <InputLabel shrink={isShrink} htmlFor={formName} classes={{ root: 'form-label phone-label' }}>
          {label}
        </InputLabel>
        <MaterialInput
          fullWidth={fullWidth}
          classes={inputClasses}
          autoComplete='off'
          name={name}
          id={formName}
          value={val}
          type={type}
          autoFocus={autoFocus}
          onKeyUp={keyPress}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          inputComponent={this.renderInput}
        />
        {this.renderTooltip()}
        <FormHelperText className='errors' id={`${formName}_error`}>
          {errorMessage || ' '}
        </FormHelperText>
      </FormControl>
    );
  }
}

import { cloneDeep } from 'lodash';

import { DISTANCE_STEPS } from 'src/constants';

export const mapTranslationDistance = (d: AnyObject): DistanceType => {
  const data = cloneDeep(d) as DistanceType;

  if (data.editor_settings) {
    const tabOptionWithoutTranslations = [...data.editor_settings.tab_options].filter((item) => item !== DISTANCE_STEPS.translations);
    data.editor_settings.tab_options = tabOptionWithoutTranslations;
  }

  return data;
};

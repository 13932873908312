import DialogContent from '@mui/material/DialogContent';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import styled from 'styled-components';

import { Loading } from 'src/components/Loading';
import { MapView } from 'src/modules/Distances/components/shared/Steps/GPX/MapView';
import { VALIDATE_CHECK_POINT_COORDINATES } from 'src/modules/Distances/components/shared/Steps/GPX/constants';

import { CheckPoints } from '.';

const DialogContentStyled = withStyles({
  root: {
    padding: '24px',
    overflow: 'hidden',
  },
})(DialogContent);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 auto;
`;

export const BodyModal = () => {
  return (
    <DialogContentStyled>
      <Wrapper>
        <Loading action={VALIDATE_CHECK_POINT_COORDINATES} />
        <MapView isModal={true} />
        <CheckPoints />
      </Wrapper>
    </DialogContentStyled>
  );
};

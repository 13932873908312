import MuiSwitcher from '@mui/material/Switch';
import * as React from 'react';

type Props = FieldBaseProps & AdditionalMaterialProps;

export const Switch = (props: Props) => {
  const { id, name, value, disabled, onChange } = props;

  return (
    <MuiSwitcher
      id={id}
      name={name}
      value={value}
      disabled={disabled}
      checked={Boolean(value)}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(
          {
            name,
            value: e.currentTarget.checked,
          },
          e,
        )
      }
    />
  );
};

import classNames from 'classnames';
import * as React from 'react';

import { TextField } from '../TextField';
import { dateMask, dateMaskTools } from '../core/mask';

type Props = {
  additional?: TextFieldAdditionalProps;
  view?: BaseViewProps;
  max?: moment.Moment;
  min?: moment.Moment;
  format?: 'world' | 'us';
} & FieldBaseProps;

/*
 * Input:
 * - time.WorkDate
 * - null
 *
 * Output:
 * - time.WorkDate - Valid value
 * - null          - Empty value
 * - undefined     - Not a valid value
 */
function Date(props: Props) {
  const stringValue = dateMaskTools.formatToMask(props.value);

  const [localState, setState] = React.useState(stringValue);
  const [prevValue, setPrevValue] = React.useState(stringValue);

  if (stringValue !== prevValue && !!stringValue) {
    setState(stringValue);
    setPrevValue(stringValue);
  }

  // const memoMask = React.useMemo(() => dateMask(props.format), []);
  const memoMask = React.useMemo(() => dateMask, []);

  return (
    <TextField
      {...props}
      className={classNames('input-mask', props.className)}
      additional={{
        InputProps: { inputComponent: memoMask },
        ...(props.additional || {}),
      }}
      view={{ variant: 'standard', fullWidth: true }}
      value={localState}
      onChange={({ name, value }, e) => {
        const externalValue = dateMaskTools.pipeForMaxAndMin(dateMaskTools.formatFromMask(value), props.max, props.min);

        const internalValue = dateMaskTools.formatToMask(externalValue);

        setState(internalValue || value);

        props.onChange(
          {
            name,
            value: externalValue,
          },
          e,
        );
      }}
    />
  );
}

export { Date, Date as DateField };

import { uniqBy } from 'lodash';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';

import { BE_DATETIME_FORMAT } from 'src/constants/time';

import { mergeRaceForm } from '../utils/merge-race-form.utils';

export type Options = {
  id: number;
  key: number;
  label?: string | null;
};

const defaultValues = {
  params: {
    search: `approve_status:3;race_date:${moment(new Date()).format(BE_DATETIME_FORMAT)}`,
    searchFields: 'approve_status:=',
    searchJoin: 'AND',
    with: 'currency',
    page: 1,
    limit: 10,
  },
};

export class DashboardRacesStore {
  races: Array<RaceType> = [];
  params: AnyObject = defaultValues.params;
  isPast: boolean = false;
  hasMore: boolean = true;
  public selectedRace: RaceType | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get raceId(): number | null {
    return this.selectedRace?.id ?? null;
  }

  setRaces(data: Array<RaceType>): void {
    this.races = uniqBy([...this.races, ...data], 'id');
  }

  setParams(params: Object): void {
    this.params = {
      ...this.params,
      ...params,
    };
  }

  setHasMore(hasMore: boolean = true): void {
    this.hasMore = hasMore;
  }

  selectRace(race: Options) {
    const foundRace = this.races.find((r) => r.id === race.id);
    this.selectedRace = foundRace || null;
    mergeRaceForm();
  }

  clear(): void {
    this.races = [];
    this.params = defaultValues.params;
    this.hasMore = true;
    this.selectedRace = null;
  }
}

const dashboardRacesStore = new DashboardRacesStore();
export { dashboardRacesStore };

export type RacersCols = 'id' | 'organizer_id' | 'source_distance_id' | 'target_distance_id' | 'start_at' | 'end_at';

export const fields = {
  id: 'id',
  organizer: 'organizer',
  created_at: 'created_at',
  sourceDistance: 'sourceDistance',
  targetDistance: 'targetDistance',
  startAt: 'startAt',
  endAt: 'endAt',
};

const MAIN_COLUMNS = [
  fields.id,
  fields.organizer,
  fields.created_at,
  fields.sourceDistance,
  fields.targetDistance,
  fields.startAt,
  fields.endAt,
];

const COLUMN_WIDTH: {
  [K in string]: number;
} = {
  id: 75,
  type: 80,
  reportedBy: 80,
  created_at: 60,
  status: 35,
};

const TASKS_COLUMNS = {
  MAIN_COLUMNS,
  COLUMN_WIDTH,
};

export { TASKS_COLUMNS };

import { isUndefined, isNull, isNil } from 'lodash';

import { fieldRegisterStore } from '../../stores';

import { isTheSameDates } from '../isTheSameDates';
import { prepareForUpdate } from './prepareForUpdate';

type processForUpdateType = (fieldId: string, changedValue: any, prevValue: any, ...args: any) => [boolean, any];

const dateTime: processForUpdateType = (fieldId: string, changedValue: nil | timeSpace.WorkDate, prevValue: nil | string) => {
  // Value did not changed and should not be updated
  if (isUndefined(changedValue)) {
    fieldRegisterStore.deactivateField(fieldId);
    return [false, null];
  }

  // Value has been cleaned
  if (isNull(changedValue)) {
    return [true, null];
  }

  const updateValue = changedValue.compose('datetimems').val;

  // Value has been changed, but remain the same
  if (isTheSameDates(updateValue, prevValue)) {
    fieldRegisterStore.deactivateField(fieldId);
    return [false, null];
  }

  // Value changed
  return [true, updateValue];
};

const duration: processForUpdateType = (fieldId: string, changedValue: number, prevValue: nil | number, startTime: string) => {
  // Value did not changed and should not be updated
  if (isUndefined(changedValue)) {
    fieldRegisterStore.deactivateField(fieldId);
    return [false, null];
  }

  // Value has been cleaned
  if (isNull(changedValue)) {
    return [true, null];
  }

  // Value has been changed, but remain the same
  if (changedValue === prevValue) {
    return [false, null];
  }

  const valueToUpdate = prepareForUpdate.duration(startTime, changedValue);

  // Value changed
  return [true, valueToUpdate];
};

const dropDown: processForUpdateType = (fieldId: string, changedValue: any, prevValue: any) => {
  if (isNil(changedValue)) {
    fieldRegisterStore.deactivateField(fieldId);
    return [false, null];
  }

  if (changedValue === prevValue) {
    fieldRegisterStore.deactivateField(fieldId);
    return [false, null];
  }

  return [true, changedValue];
};

const plainNulable: processForUpdateType = (fieldId: string, changedValue: any, prevValue: any) => {
  if (isUndefined(changedValue)) {
    fieldRegisterStore.deactivateField(fieldId);
    return [false, null];
  }

  if (changedValue === prevValue) {
    fieldRegisterStore.deactivateField(fieldId);
    return [false, null];
  }

  if (!changedValue) {
    fieldRegisterStore.deactivateField(fieldId);
    return [true, null];
  }

  return [true, changedValue];
};

const plainNonNulable: processForUpdateType = (fieldId: string, changedValue: any, prevValue: any) => {
  if (changedValue === prevValue) {
    fieldRegisterStore.deactivateField(fieldId);
    return [false, null];
  }

  if (!changedValue) {
    fieldRegisterStore.deactivateField(fieldId);
    return [false, null];
  }

  return [true, changedValue];
};

const nonNil: processForUpdateType = (fieldId: string, changedValue: any, prevValue: any) => {
  if (changedValue === prevValue) {
    fieldRegisterStore.deactivateField(fieldId);
    return [false, null];
  }

  if (isNil(changedValue)) {
    fieldRegisterStore.deactivateField(fieldId);
    return [false, null];
  }

  return [true, changedValue];
};

const processForUpdate = {
  dateTime,
  duration,
  dropDown,
  plainNulable,
  plainNonNulable,
  nonNil,
};

export { processForUpdate };

import { defaultListFilters } from 'utils';

import { FilterDescriptorWrapper } from 'models';

import { classId } from './classId';
import { waveId } from './waveId';

// TEAMS
// Team Startist filters

type Cols =
  | 'id'
  | 'bib_number'
  | 'name'
  | 'class_id'
  | 'wave_id'
  | 'added_at'
  | 'added_by'
  | 'owner.order.coupon_codes'
  | 'owner.order.id'
  | 'owner.order.created_at'
  | 'owner.order.amount'
  | 'owner.order.state'
  | 'owner.order.currency';

type Descs = { [K in Cols]: FilterListDescriptor };

export const teamFields: {
  [K in Cols]: Cols;
} = {
  id: 'id',
  bib_number: 'bib_number',
  name: 'name',
  class_id: 'class_id',
  wave_id: 'wave_id',
  added_at: 'added_at',
  added_by: 'added_by',
  'owner.order.currency': 'owner.order.currency',
  'owner.order.coupon_codes': 'owner.order.coupon_codes',
  'owner.order.id': 'owner.order.id',
  'owner.order.created_at': 'owner.order.created_at',
  'owner.order.state': 'owner.order.state',
  'owner.order.amount': 'owner.order.amount',
};

const descriptors: Descs = {
  bib_number: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      default: true,
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  class_id: classId,

  name: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: true,
  },

  wave_id: waveId,

  added_at: {
    filter: defaultListFilters.dateRange,
    frontend: { type: 'dateRange' },
    sort: true,
  },

  id: {
    filter: defaultListFilters.none,
  },

  added_by: {
    filter: defaultListFilters.none,
  },

  'owner.order.state': {
    filter: defaultListFilters.none,
  },

  'owner.order.currency': {
    filter: defaultListFilters.none,
  },

  'owner.order.id': {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      reorderOnSearch: true,
      calcOrderBy: () => 'owner.payment_info:id|payment_info.id',
    },
  },

  'owner.order.amount': {
    filter: defaultListFilters.priceRange,
    frontend: { type: 'numberRange' },
    sort: {
      reorderOnSearch: true,
      calcOrderBy: () => 'owner.payment_info:id|payment_info.amount',
    },
  },

  'owner.order.coupon_codes': {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      calcOrderBy: () => 'owner.payment_info:id|payment_info.coupon_code_used',
    },
  },

  'owner.order.created_at': {
    filter: defaultListFilters.dateRange,
    frontend: { type: 'dateRange' },
    sort: {
      calcOrderBy: () => 'owner.payment_info:id|payment_info.created_at',
    },
  },
};

export const teamFiltersDescriptors = Object.keys(descriptors).reduce((acc, desc) => {
  return { ...acc, [desc]: new FilterDescriptorWrapper(desc, descriptors[desc as Cols]) };
}, {});

import { MoreVert } from '@mui/icons-material';
import { Box, Fade, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import { SystemUserForm } from 'modules/SystemUsers/types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ColumnWithLooseAccessor } from 'react-table';

import { confirmationModalStore, countriesStore } from 'src/stores';
import { history, t } from 'src/utils';

import { DatePicker } from 'components/Form/Fields';

import { validRoleFromBackend } from '../../../utils';

import { SystemUsersActions } from '../../../actions/loadSystemUsers.action';

import { systemUserRolesStore } from '../../../stores';

const COLUMNS = ['action', 'id', 'email', 'roles', 'company', 'phone', 'country', 'city', 'post_code', 'created_at'] as const;

const title = (prefix: string) => t.static(`systemUsers.list.table.${prefix}` as any);

function NumberRangeFilter({ column: { filterValue = [], setFilter, preFilteredRows, id } }) {
  return (
    <Box display='flex' flexDirection='row'>
      <Box minWidth='80px' p={0.5}>
        <TextField
          type='number'
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => {
              return [val === '0' || val ? parseInt(val, 10) : undefined, old[1]];
            });
          }}
          value={filterValue[0] || ''}
          variant='outlined'
          size='small'
          label='From'
        />
      </Box>
      <Box minWidth='80px' p={0.5}>
        <TextField
          type='number'
          value={filterValue[1] || ''}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val === '0' || val ? parseInt(val, 10) : undefined]);
          }}
          variant='outlined'
          size='small'
          label='To'
        />
      </Box>
    </Box>
  );
}

function DateFilter(props) {
  const {
    column: { filterValue, setFilter, preFilteredRows, id },
  } = props;
  return (
    <Box minWidth='250px' p={0.5}>
      <DatePicker
        hideArrow
        view={{
          fullWidth: true,
          readOnly: true,
          variant: 'outlined',
          size: 'small',
        }}
        label=''
        name='created_at'
        value={filterValue}
        onChange={({ name, value }) => {
          setFilter(value);
        }}
        onClear={() => {
          if (id === 'created_at') {
            setFilter(null);
            setFilter(null);
          }
        }}
      />
    </Box>
  );
}

function SelectColumnFilter(props) {
  const {
    column: { filterValue, setFilter, preFilteredRows, id, selectProps },
  } = props;
  const options = selectProps.options() || [];

  return (
    <Box minWidth='160px' p={0.5}>
      <TextField
        variant='outlined'
        size='small'
        fullWidth
        select
        name={id}
        label='Select role'
        value={filterValue ?? 'All'}
        onChange={(e) => {
          if (e.target.value === 'All') {
            return setFilter(undefined);
          }
          setFilter(e.target.value);
        }}
      >
        <MenuItem key='All' value='All'>
          All
        </MenuItem>
        {options.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<SystemUserForm> & AnyObject } = {
  id: {
    id: 'id',
    Header: title('id'),
    accessor: (user) => user.id,
    Cell: ({ row: { original } }) => (
      <>
        {['medical', 'assistant'].includes(original.roles[0]?.name) ? (
          <span>{original.id}</span>
        ) : (
          <Link to={`/system-users/${original.id}`} style={{ color: 'black', textDecoration: 'underline' }}>
            {original.id}
          </Link>
        )}
      </>
    ),
    filter: 'between',
    Filter: NumberRangeFilter,
  },
  email: {
    id: 'email',
    Header: title('email'),
    accessor: (user) => {
      return user.email;
    },
  },
  roles: {
    id: 'roles.name',
    Header: title('role'),
    Filter: SelectColumnFilter,
    filter: 'equals',
    accessor: (user) => validRoleFromBackend(user.roles[0]?.name),
    selectProps: {
      options: () =>
        systemUserRolesStore.value?.map((role, index) => {
          return {
            key: index,
            value: role.name,
            label: validRoleFromBackend(role.name),
          };
        }),
    },
  },
  company: {
    id: 'company',
    Header: title('full_name'),
    accessor: (user) => user.company ?? '',
  },
  phone: {
    id: 'phone',
    Header: title('phone'),
    accessor: (user) => user.phone,
  },
  country: {
    id: 'country',
    Header: title('country'),
    accessor: (user) => user.country_id,
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      const country = countriesStore.findModelValue(original.country_id);
      return <div>{country?.name() ?? '-'}</div>;
    },
  },
  city: {
    id: 'city',
    Header: title('city'),
    accessor: (user) => user.city,
  },
  post_code: {
    id: 'post_code',
    Header: title('post_code'),
    accessor: (user) => user.post_code,
  },
  created_at: {
    id: 'created_at',
    Header: title('created_at'),
    accessor: (user) => user.created_at,
    Filter: DateFilter,
  },
  action: {
    id: 'user',
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    Header: title('action'),
    Cell: ({ row: { original } }) => {
      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
      const open = Boolean(anchorEl);
      const handleClose = () => setAnchorEl(null);
      const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
      const handleEdit = () => {
        history.push(`/system-users/${original.id}/edit`);
        setAnchorEl(null);
      };
      const handleDelete = (id: number) => {
        confirmationModalStore.openModal({
          title: t.staticAsString('systemUsers.confirmPopup.mainTitle'),
          body: t.staticAsString('systemUsers.confirmPopup.mainBody', { email: original.email }),
          type: 'confirm',
          confirmationValue: original.email,
          confirmationLabel: t.staticAsString('systemUsers.confirmPopup.label'),
          onConfirm: () => SystemUsersActions.deleteUser(id),
        });

        setAnchorEl(null);
      };

      return (
        <>
          <IconButton onClick={handleClick} style={{ display: 'flex', alignItems: 'center' }}>
            <MoreVert sx={{ cursor: 'pointer' }} />
          </IconButton>
          <Menu
            id='fade-menu'
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleEdit}>{title('edit')}</MenuItem>
            <MenuItem sx={{ color: '#CE5659' }} onClick={() => handleDelete(original.id)}>
              {title('delete')}
            </MenuItem>
          </Menu>
        </>
      );
    },
  },
};

export function generateSystemUserColumns() {
  return COLUMNS.map((c) => RenderColumns[c]);
}

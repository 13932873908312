import { form } from 'stores';

import {
  CREDIT_DISTANCE,
  CREDIT_SINGLE,
  MERGE,
  SUPPORT_TASK_MANAGER_FORM,
  TYPE_DISTANCE,
  TYPE_ORDER,
  COUPON,
  BIB_NUMBERS,
} from '../constants';

import { mapToFormRefund, mapToFormMergeAccounts, mapToFormGenerateCoupons, mapToFormGenerateBib } from '../utils';

import { generateBibService, refundDistanceService, refundSingleService } from '../services';
import { mergeAccountsService, generateCouponsService } from '../services';

import { confirmStore } from '../stores';

export const openConfirmModal = (): void => {
  confirmStore.set({
    ...(confirmStore.value as any),
    open: true,
  });
};

export const closeConfirmModal = (): void => {
  confirmStore.set({
    open: false,
    title: '',
    content: '',
  });
};

export const setConfirmTitle = (text: string): void => {
  confirmStore.set({
    ...(confirmStore.value as any),
    title: text,
  });
};

export const setConfirmContent = (text: string): void => {
  confirmStore.set({
    ...(confirmStore.value as any),
    content: text,
  });
};

export const onConfirmModal = async () => {
  const actionForm: AnyObject = form.fetchWithoutObservable(SUPPORT_TASK_MANAGER_FORM);

  if (actionForm.action === MERGE) {
    await confirmActionMergeAccounts(actionForm);
  }

  if (actionForm.action === CREDIT_SINGLE) {
    await confirmActionCreditSingle(actionForm);
  }

  if (actionForm.action === CREDIT_DISTANCE) {
    await confirmActionCreditDistance(actionForm);
  }

  if (actionForm.action === COUPON) {
    await confirmActionGenerateCoupon(actionForm);
  }

  if (actionForm.action === BIB_NUMBERS) {
    await confirmActionGenerateBib(actionForm);
  }
};

const confirmActionMergeAccounts = async (actionForm: AnyObject) => {
  const params = mapToFormMergeAccounts(actionForm);
  await mergeAccountsService.sendMergeAccounts(params);
};

const confirmActionCreditSingle = async (actionForm: AnyObject) => {
  const params = mapToFormRefund(actionForm, TYPE_ORDER);
  await refundSingleService.sendRefund(params);
};

const confirmActionCreditDistance = async (actionForm: AnyObject) => {
  const params = mapToFormRefund(actionForm, TYPE_DISTANCE);
  await refundDistanceService.sendRefund(params);
};

const confirmActionGenerateCoupon = async (actionForm: AnyObject) => {
  const params = mapToFormGenerateCoupons(actionForm);
  await generateCouponsService.generate(params);
};

const confirmActionGenerateBib = async (actionForm: AnyObject) => {
  const params = mapToFormGenerateBib(actionForm);
  await generateBibService.generate(params);
};

import { Collapse } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import { observer } from 'mobx-react';
import {
  exportSummary,
  handleConfirmUnverified,
  publishAction,
  registerAction,
  unPublishAction,
  unRegisterAction,
} from 'modules/RaceDetails/actions';
import {
  DownloadStartList,
  OpenRegistration,
  PublishBtn,
  RaceType,
  SendForApproveBtn,
} from 'modules/RaceDetails/components/Toolbar/buttons';
import { raceDetailsStore, raceTypeStore } from 'modules/RaceDetails/stores';
import React from 'react';
import { generatePath } from 'react-router-dom';

import { appEnvControl, t } from 'utils';

import { confirmationModalStore, sessionStore } from 'stores';
import confirmModalStore from 'stores/confirmationModal';
import locale from 'stores/locale';

import { CONFIRM_POPUP_TYPES, EXPORT_DATA_SIZES, RACES_URL, RACE_STATUSES_INT, RACE_URL } from '../../../../constants';

import { Visibility } from './buttons/Visibility';

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    display: 'grid',
    rowGap: '28px',
    columnGap: '16px',
    [theme.breakpoints.up(2500)]: {
      gridAutoFlow: 'column',
    },
  },
  row: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    gap: '16px',
  },

  gridItem: {
    display: 'flex',
    height: '86px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',

    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
}));

export const ToolbarMain = observer(() => {
  const classes = useStyles();
  const { raceDetails, isAnyDistanceCreated } = raceDetailsStore;
  // sessionStore.isAdmin
  const approveStatus = raceDetails?.approveStatus();
  const isAwaiting = approveStatus === RACE_STATUSES_INT.awaiting;
  const isRejected = approveStatus === RACE_STATUSES_INT.reject;
  const isApproved = approveStatus === RACE_STATUSES_INT.approve;
  const isDraft = approveStatus === RACE_STATUSES_INT.draft;

  const approveRequiredRaceActionPopup = () => {
    confirmationModalStore.openModal({
      title: t.staticAsString('races.forbiddenRaceActionPopup.title'),
      body: t.staticAsString('races.publishRejected.body'),
      type: CONFIRM_POPUP_TYPES.info,
      onConfirm: () => {},
    });
  };

  const forbiddenRaceActionPopup = () => {
    confirmationModalStore.openModal({
      title: t.staticAsString('races.forbiddenRaceActionPopup.title'),
      body: t.staticAsString('races.forbiddenRaceActionPopup.body'),
      type: CONFIRM_POPUP_TYPES.info,
      onConfirm: () => {},
    });
  };

  const publishPopup = () => {
    if (!isApproved) {
      confirmationModalStore.openModal({
        title: t.staticAsString('races.forbiddenRaceActionPopup.title'),
        body: t.staticAsString('races.publishRejected.body'),
        type: CONFIRM_POPUP_TYPES.info,
        onConfirm: () => {},
      });
    } else {
      publishAction();
    }
  };

  const publishNonRegisteredPopup = () => {
    confirmationModalStore.openModal({
      title: t.staticAsString('races.publishNonRegisteredPopup.title'),
      body: t.staticAsString('races.publishNonRegisteredPopup.body'),
      type: CONFIRM_POPUP_TYPES.confirm,
      onConfirm: () => {
        publishAction();
      },
    });
  };

  const onTooglePublished = () => {
    const race = raceDetails?.value;
    const isAnyDistancesPresented = raceDetailsStore.isAnyDistancesPresented;

    if (!race) {
      return;
    }

    if (!isApproved) {
      return approveRequiredRaceActionPopup();
    }

    if (!isAnyDistancesPresented) {
      if (race.published) {
        return unPublishAction();
      }

      return forbiddenRaceActionPopup();
    }

    if (race.published) {
      return unPublishAction();
    }

    if (race.allow_registration) {
      return publishPopup();
    }

    publishNonRegisteredPopup();
  };

  const handleToggleSearchable = async () => {
    await Axios.patch(generatePath(RACE_URL, { id: raceDetails?.value.id }), {
      is_searchable: !raceDetails?.value.is_searchable,
    });
    raceDetailsStore.partialRaceUpdate({ is_searchable: !raceDetails?.value.is_searchable });
  };

  const confirmExport = () => {
    confirmModalStore.openModal({
      title: t.staticAsString('shared.exportConfirm.title') as any,
      body: t.staticAsString('shared.exportConfirm.body', {
        maxAmount: EXPORT_DATA_SIZES[appEnvControl.currentEnvWithStub()].toLocaleString(locale.value),
      }) as any,
      type: CONFIRM_POPUP_TYPES.confirm,
      onConfirm: () => raceDetails && exportSummary(raceDetails.value.id),
    });
  };

  const onForbidRaceAction = () => {
    const race = raceDetailsStore.raceDetails?.value;
    if (!race) {
      return;
    }

    if (!isApproved) {
      confirmationModalStore.openModal({
        title: t.staticAsString('races.forbiddenRaceActionPopup.title'),
        body: t.staticAsString('races.publishRejected.body'),
        type: CONFIRM_POPUP_TYPES.info,
        onConfirm: () => {},
      });
      return;
    }

    if (raceDetailsStore.isAnyDistancesPresented || race.allow_registration) {
      onToggleRegistration();
      return;
    }

    forbiddenRaceActionPopup();
  };

  const onToggleRegistration = () => {
    const race = raceDetailsStore.raceDetails?.value;
    if (!race) {
      return;
    }

    if (!race.allow_registration) {
      return registerAction();
    }

    unRegisterAction();
  };

  const adminTools = () => {
    return (
      <Collapse
        in={isDraft || isRejected}
        orientation='horizontal'
        unmountOnExit
        sx={{
          '& .MuiCollapse-wrapperInner': {
            width: '100%',
          },
        }}
      >
        <SendForApproveBtn className={classes.gridItem} onClick={handleConfirmUnverified} showNotification />
      </Collapse>
    );
  };

  const organizerTools = () => {
    const showTip = !isAnyDistanceCreated;
    const isDisabled = isAwaiting || !isAnyDistanceCreated;

    return (
      <Collapse
        in={!isApproved}
        orientation='horizontal'
        unmountOnExit
        sx={{
          '& .MuiCollapse-wrapperInner': {
            width: '100%',
          },
        }}
      >
        <SendForApproveBtn
          className={classes.gridItem}
          disabled={isDisabled}
          tip={showTip}
          onClick={handleConfirmUnverified}
          showNotification={!isAwaiting}
        />
      </Collapse>
    );
  };

  return (
    <div className={classes.grid}>
      <div className={classes.row}>
        {sessionStore.isAdmin ? adminTools() : organizerTools()}
        <PublishBtn className={classes.gridItem} race={raceDetails} onClick={onTooglePublished} />
        <RaceType className={classes.gridItem} />
      </div>
      <Collapse in={raceTypeStore.switchValue}>
        <div className={classes.row}>
          <DownloadStartList className={classes.gridItem} race={raceDetails} onClick={confirmExport} />
          <OpenRegistration className={classes.gridItem} race={raceDetails} onClick={onForbidRaceAction} isApproved={isApproved} />
        </div>
      </Collapse>
      <Collapse in={raceTypeStore.switchValue}>
        <div className={classes.row}>
          <Visibility isApproved={isApproved} className={classes.gridItem} race={raceDetails} onClick={handleToggleSearchable} />
        </div>
      </Collapse>
    </div>
  );
});

import * as React from 'react';
import shortid from 'shortid';

import { t } from 'utils';

export type Props = {};

export type State = {
  category: {
    title: string;
    active: number;
  };
};

class SportTypeForm<P extends Props, S extends State> extends React.Component<P, S> {
  id: string;

  constructor(props: P) {
    super(props);
    this.id = shortid();
  }

  onChange = (e: React.ChangeEvent | any) => {
    this.setState({
      category: {
        ...this.state.category,
        [e.target.name]: e.target.value,
      },
    });
  };

  statusOptions = () => {
    return [
      {
        value: '1',
        label: t.staticAsString('categories.active'),
        key: 1,
      },
      {
        value: '0',
        label: t.staticAsString('categories.inactive'),
        key: 2,
      },
    ];
  };

  onFileChange = ({ name, value }: { name: string; value: nil | Blob }) => {
    this.setState(
      {
        category: {
          ...this.state.category,
          [name]: value,
        },
      },
      () => {
        this.validate();
      },
    );
  };

  validate(): any {}
}

export { SportTypeForm };

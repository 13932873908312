import moment from 'moment';

import { SmartLinkBE } from '../../shared/types';

export const mapLinkToFE = (link: SmartLinkBE) => {
  const date = {
    startDate: moment(link.date_from),
    endDate: moment(link.date_to),
  };
  return { ...link, date };
};

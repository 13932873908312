import { EXPORT_DATA } from 'src/constants';

import { t, handleExport } from 'utils';

import { exportJobService } from 'services';

import { customersStore, toastStore } from 'stores';

export const exportData = async (type: string): Promise<void> => {
  const { paginationMeta, params } = customersStore;
  const { total = EXPORT_DATA.defaultMetaData } = paginationMeta;
  if (total > EXPORT_DATA.metaDataLimit) {
    toastStore.show(t.staticAsString('shared.loadPopup.tooMuchData'));
    return;
  }

  const [status, response] = await exportJobService.exportCustomers(type, params);

  if (!status) {
    return;
  }
  const { id } = response.data.data;

  const estimatedLoadingTime = total / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second

  handleExport({
    id,
    estimatedLoadingTime,
    fileName: `Customers.${type}`,
    onRetry: () => exportData(type),
  });
};

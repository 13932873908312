import { TableHead, TableCell, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';

export const StyledTableHead = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(38, 46, 53)',
  },
}))(TableHead);

export const StyledTableHeaderCell = withStyles((theme) => ({
  root: {
    color: 'rgb(255, 255, 255)',
    fontSize: '14px',
    fontWeight: 'normal',
  },
}))(TableCell);

export const StyledTableCell = withStyles((theme) => ({
  root: {
    // '&:first-child': {
    //   paddingLeft: 0,
    // },
    // '&:last-child': {
    //   paddingRight: 16,
    // },
  },
}))(TableCell);

export const StyledRefundTableCell = withStyles((theme) => ({
  root: {
    padding: '8px 16px',
    fontSize: '16px',
    color: 'rgb(189, 0, 0)',
    borderBottom: 'none',
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:last-child td': {
      borderBottom: 0,
    },
  },
}))(TableRow);

export const StyledRefundTableRow = withStyles((theme) => ({
  root: {
    background: 'white',
  },
}))(TableRow);

export const StyledTotalTableRow = withStyles((theme) => ({
  root: {
    background: 'white',
  },
}))(TableRow);

export const StyledTotalTableCell = withStyles((theme) => ({
  root: {
    padding: '8px 16px',
    fontSize: '16px',
    borderBottom: 'none',
  },
}))(TableCell);

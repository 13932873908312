import { isUndefined, isNull } from 'lodash';
import { observable, action, computed, makeObservable } from 'mobx';

import { SearchSerializer } from 'utils';

class Search implements Extensionable {
  // { [name of the filter like firstname ]: any value }
  @observable
  // @ts-ignore
  values: {
    [K in string]: any;
  };
  filterDescriptors: WrappedFiltersListDescriptors;

  constructor(filterDescriptors: WrappedFiltersListDescriptors) {
    makeObservable(this);
    this.reset();
    this.filterDescriptors = filterDescriptors;
  }

  @action
  async applyValue(name: string, value: any): Promise<void> {
    this.values[name] = value;
  }

  @action
  async deleteValues(...filterNames: Array<string>) {
    await filterNames.forEach((filterName) => {
      this.values[filterName] = null;
    });
  }

  @computed
  get params(): FiltersType {
    const { search, searchFields } = this.generateBEParams();
    // @ts-ignore
    const searchModel = new SearchSerializer(search, searchFields, []);
    return searchModel.params() || {};
  }

  @action
  async reset(): Promise<void> {
    this.values = {};
  }

  isFEActive(name: string): boolean {
    const descriptor = this.filterDescriptors[name];
    const value = this.values[name];

    if (!descriptor) {
      return false;
    }

    if (descriptor.isHiddenChipLabel()) {
      return false;
    }

    return !!descriptor.prettifyFilterValue(value);
  }

  generateBEParams(): {
    search: Object;
    searchFields: Object;
  } {
    const filterNames = Object.keys(this.values);
    let search = {};
    let searchFields = {};

    filterNames.forEach((filterName) => {
      const descriptor = this.filterDescriptors[filterName];
      const currentFilterValue = this.values[filterName];

      if (!descriptor) {
        return;
      }

      if (isUndefined(currentFilterValue) || isNull(currentFilterValue)) {
        return;
      }

      // NOTE, Calculate filter values
      const calculatedSearch = descriptor.calculateSearch(currentFilterValue) || {};
      search = { ...search, ...calculatedSearch };

      // NOTE, Calculate BE filter comparison operators
      const calculatedSearchFields = descriptor.calculateSearchFields(currentFilterValue);
      searchFields = { ...searchFields, ...calculatedSearchFields };
    });

    return { search, searchFields };
  }
}

export { Search, Search as SearchStore };

import { Collapse } from '@mui/material';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

import { Show } from 'src/components/Condition';
import { Svg } from 'src/components/Icon';
import { t } from 'src/utils';

type Props = React.PropsWithChildren<{
  title: string;
  addText: string;
  onAdd: AnyFunction;
  showPlus: boolean;
  showBlock?: boolean;
  hideFieldsTitles?: boolean;
}>;

const Wrapper = styled.div`
  margin-bottom: 40px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;

  padding: 0 24px;
  margin-bottom: 24px;
  width: 100%;
  height: 48px;

  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.08);
`;

const FieldsWrap = styled.div`
  padding: 0 24px;
`;

const AddButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.disabled ? props.theme.palette.grey4 : props.theme.palette.orange1)};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  svg {
    path {
      fill: ${(props) => (props.disabled ? props.theme.palette.grey4 : null)};
    }
  }
`;

const StyledSvg = styled(Svg)`
  margin-right: 8px;
`;

export const FieldColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 24px;
  gap: 16px;
`;

export const ColumnTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
`;

export const SyncContainer: React.FC<Props> = (props) => {
  const { title, children, addText, onAdd, showBlock = true, showPlus, hideFieldsTitles } = props;

  return (
    <Collapse in={showBlock} unmountOnExit>
      <Wrapper>
        <Header>{title}</Header>
        <FieldsWrap>
          <Show if={!isEmpty(children) && !hideFieldsTitles}>
            <FieldColumns>
              <ColumnTitle>{t('races.filters.id')}</ColumnTitle>
              <ColumnTitle>{t('sync.race_results')}</ColumnTitle>
            </FieldColumns>
          </Show>
          <TransitionGroup>{children}</TransitionGroup>
          <AddButton onClick={onAdd} disabled={!showPlus}>
            <StyledSvg name='OutlinePlus' size={24} />
            {addText}
          </AddButton>
        </FieldsWrap>
      </Wrapper>
    </Collapse>
  );
};

import { Close } from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, FormHelperText, IconButton, Popover, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import moment, { Moment } from 'moment';
import * as React from 'react';

import { Show } from 'components/Condition';
import { Tooltip } from 'components/Form/Fields/core/Tooltip';

import { time } from 'utils';

import { ButtonGroup } from './ButtonGroup';
import { Calendar } from './Calendar';

const CALENDAR_HEIGHT = 388;

type DateRangeType = { startDate?: Moment; endDate?: Moment };

type Props = FieldBaseProps & {
  additional?: TextFieldAdditionalProps;
  view?: BaseViewProps;
  onClear?: Function;
  onBlur?: Function;
  onKeyUp?: Function;
  placeholder?: string;
  helperText?: string;
  value: DateRangeType | Moment;
  minDate?: moment.Moment | nil;
  maxDate?: moment.Moment | nil;
  required?: boolean;
  isMinDateExclusive?: boolean;
  isMaxDateExclusive?: boolean;
  hideArrow?: boolean;
  tooltip?: {
    text: string;
    position: 'bottom' | 'icon';
  };
};

type State = {
  show: boolean;
  value: DateRangeType | Moment;
};

const StyledArrow = withStyles((theme) => ({
  root: {
    top: 24,
    right: 16,
    color: theme.palette.primary.grey6,
    position: 'absolute',
    pointerEvents: 'none',
  },
}))(ArrowDropDownIcon);

export class DatePicker extends React.Component<Props, State> {
  anchorEl: Element | null = null;
  renderOnTop: boolean = false;

  updateAnchorPos() {
    const { top = 0 } = this.anchorEl?.getBoundingClientRect() || {};

    this.renderOnTop = window.innerHeight - top < CALENDAR_HEIGHT;
  }

  state: State = {
    show: false,
    value: {},
  };

  componentDidMount() {
    if (this.props.value) {
      this.setState({ ...this.state, value: this.props.value });
    }
  }

  onToggle = (show: boolean) => (e: React.MouseEvent<HTMLInputElement>) => {
    const { value, additional } = this.props;
    if (additional?.disabled) return;

    this.anchorEl = show ? e?.currentTarget : null;

    this.setState({
      show,
      value: show ? value : {},
    });

    this.updateAnchorPos();
  };

  onChange = (value: { startDate?: moment.Moment; endDate?: moment.Moment }): void => {
    this.setState({
      value,
    });
  };

  onApply = (e: React.MouseEvent<HTMLInputElement>) => {
    const { name, onChange } = this.props;
    const { value } = this.state as any;

    if (value.startDate && value.endDate === null) {
      value.endDate = value.startDate;
    }

    onChange({ name, value });
    if (this.props.additional?.liveUpdate) {
      this.props.additional.liveUpdate(name);
    }
    this.onToggle(false)(e);
  };

  onClear = () => {
    this.setState({ ...this.state, value: {} });
    if (this.props.onClear) this.props.onClear();
  };

  getValue = (): string => {
    const { value, additional = {} } = this.props;
    const { isRange = true } = additional;
    if (!value) {
      return '';
    }
    if (!isRange) {
      if (isEmpty(value)) {
        return '';
      }
      return time.workDatetime(value).formatAsString('shortDate');
    }
    if (!value?.startDate || !value?.endDate) {
      return '';
    }

    const startDate = time.workDatetime(value?.startDate).formatAsString('shortDate');
    const endDate = value.endDate.isValid() ? time.workDatetime(value.endDate).formatAsString('shortDate') : '';
    return `${startDate} - ${endDate}`;
  };

  render() {
    const {
      additional = {},
      view = {},
      label,
      name,
      helperText,
      required,
      tooltip,
      error,
      className,
      minDate,
      maxDate,
      isMinDateExclusive,
      isMaxDateExclusive,
      hideArrow = false,
      onClear,
    } = this.props;
    const { isRange = true, autoFocus = false } = additional;
    const { size = 'medium', readOnly = false } = view;

    const { show, value } = this.state;
    const labelText = required ? `${label}*` : label;

    const singleDate = isEmpty(value) ? undefined : (value as Moment);
    const { startDate, endDate } = (value as DateRangeType) || {};

    const leftOffset = (window.innerWidth - (isRange ? 620 : 320)) / 2;
    const topOffset = (window.innerHeight - CALENDAR_HEIGHT) / 2;

    return (
      <FormControl
        variant='outlined'
        className={classNames('input-wrap', 'form-control', { [className || '']: !!className }, { error: !!error })}
        error={!!error}
        aria-describedby='name-error-text'
        fullWidth
      >
        <TextField
          id={name}
          name={name}
          label={labelText}
          InputProps={{
            readOnly,
            endAdornment:
              onClear && Boolean(this.getValue()) ? (
                <Close
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear();
                  }}
                />
              ) : null,
          }}
          disabled={additional.disabled}
          autoFocus={autoFocus}
          error={!!error}
          value={this.getValue()}
          onClick={this.onToggle(true)}
          variant='outlined'
          helperText={helperText}
          size={size}
        />
        {!hideArrow && (
          <Show if={tooltip?.position !== 'icon'}>
            <StyledArrow />
          </Show>
        )}
        <Popover
          anchorEl={this.anchorEl}
          open={show}
          onClose={this.onToggle(false)}
          anchorPosition={{ left: leftOffset, top: topOffset }}
          anchorReference='anchorPosition'
          className='price-popover'
        >
          <div>
            <div className='close-icon-wrapper'>
              <CloseIcon onClick={() => this.setState({ ...this.state, show: false })} />
            </div>
            <Calendar
              isMinDateExclusive={isMinDateExclusive || false}
              isMaxDateExclusive={isMaxDateExclusive || false}
              startDate={startDate}
              endDate={endDate}
              date={singleDate}
              isSingleDate={!isRange}
              minDate={minDate}
              maxDate={maxDate}
              onChange={this.onChange as AnyFunction}
            />
            <ButtonGroup displayClear={!isEmpty(value)} displayApply onClear={this.onClear} onApply={this.onApply} />
          </div>
        </Popover>
        {tooltip && <Tooltip {...tooltip} error={error} />}
        <FormHelperText className='errors'>{error ? <>{error}</> : ' '}</FormHelperText>
      </FormControl>
    );
  }
}

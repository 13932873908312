import { SynchronizationActions } from 'modules/Synchronisation/Table/actions/list/lifecycle';
import { SynchronizationsConstants } from 'modules/Synchronisation/Table/constants/synchronizations.constants';
import { SynchronizationsService } from 'modules/Synchronisation/Table/services/synchronizations.service';
import { SynchronizationFilters } from 'modules/Synchronisation/Table/types/filters.type';
import { SynchronizationTypeEnum } from 'modules/Synchronisation/Table/types/synchronization.type';

import { ROUTES } from 'src/constants';
import { t, history } from 'src/utils';

import { errorsStore, form, toastStore } from 'stores';

export const toggleSynchronizationStatus = async (id: number, isEnabled: boolean, requestFilters: SynchronizationFilters) => {
  const status = await SynchronizationsService.toggleSynchronizationStatus(id, isEnabled);

  if (status) {
    toastStore.show(t.staticAsString(isEnabled ? 'sync.toast.success.deactivate' : 'sync.toast.success.activate'));
    SynchronizationActions.load(requestFilters);
  }
};

export const reSynchronization = async (id: number, requestFilters: SynchronizationFilters) => {
  const status = await SynchronizationsService.publishSynchronization(id);

  if (status) {
    toastStore.show(t.staticAsString('sync.toast.success.reSync'));
    SynchronizationActions.load(requestFilters);
  }
};

export const deleteSynchronization = async (id: number, requestFilters: SynchronizationFilters) => {
  const status = await SynchronizationsService.deleteSynchronization(id);

  if (status) {
    toastStore.show(t.staticAsString('sync.toast.delete'));
    SynchronizationActions.load(requestFilters);
  }
};

export const choseSyncType = (): void => {
  errorsStore.clear(SynchronizationsConstants.SynchronizationForm);
  const syncForm = form.fetchWithoutObservable<AnyObject>(SynchronizationsConstants.SynchronizationForm);

  if (syncForm.type === SynchronizationTypeEnum.RaceResult) history.push(ROUTES.createRRSync);
  if (syncForm.type === SynchronizationTypeEnum.EQTiming) history.push(ROUTES.createEQSync);
};

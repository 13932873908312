import { Moment } from 'moment';

import { BE_DATETIME_FORMAT } from 'src/constants/time';

import { ReportsTableFilters } from '../types/filters.type';
import { FilterKeys } from '../types/report.type';

const IdFields = ['organizer.id'];
const DateFields = ['created_at', 'updated_at'];
const LikeFields = ['races.name', 'organizers.email'];
const BetweenFields = [
  'id',
  'created_time',
  'distance.race_parent_id',
  'vat_amount',
  'payment_info.discounted_amount',
  'payment_info.amount_for_custom_fields',
  'payment_info.amount',
  'payment_info.price_used',
  'sum',
];
export namespace ReportsFiltersMappers {
  export function toBackend(filters: ReportsTableFilters[]) {
    const searchFilters = filters.reduce(
      (acc, { id, value }) => {
        if (IdFields.includes(id)) {
          acc.search += `${id}:${value.value};`;
          acc.searchFields += `${id}:=;`;
        }
        if (DateFields.includes(id)) {
          acc.search += `${id}:${mapDaterange(value.startDate, value.endDate)};`;
          acc.searchFields += `${id}:between;`;
        }
        if (LikeFields.includes(id)) {
          if (id === 'races.name') {
            id = FilterKeys.RACE_KEY;
          } else if (id === 'organizers.email') {
            id = FilterKeys.ORGANIZER_KEY;
          }

          acc.search += `${id}:${value};`;
          acc.searchFields += `${id}:like;`;
        }
        if (BetweenFields.includes(id)) {
          switch (id) {
            case 'sum':
              acc.search += `${id}:0,${value * 100};`;
              acc.searchFields += `${id}:between;`;
              break;
            case 'id':
              const [start, end] = value;
              if (start === undefined) {
                acc.search += `${id}:${end};`;
                acc.searchFields += `${id}:<=;`;
                break;
              }
              if (end === undefined) {
                acc.search += `${id}:${start};`;
                acc.searchFields += `${id}:>=;`;
                break;
              }
            default:
              acc.search += `${id}:${value.join(',')};`;
              acc.searchFields += `${id}:between;`;
          }
        }
        return acc;
      },
      {
        search: '',
        searchFields: '',
      },
    );

    searchFilters.search = searchFilters.search.substring(0, searchFilters.search.length - 1);
    searchFilters.searchFields = searchFilters.searchFields.substring(0, searchFilters.searchFields.length - 1);
    return searchFilters;
  }
}

const mapDaterange = (start: Moment, end: Moment): string => {
  return `${start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(BE_DATETIME_FORMAT)},${end
    .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    .format(BE_DATETIME_FORMAT)}`;
};

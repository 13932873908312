import { KeyboardArrowDown, KeyboardArrowUp, AddCircleOutline } from '@mui/icons-material';
import classNames from 'classnames';
import { isUndefined, isEmpty } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { ERRORS_PREFIXES } from 'modules/Distances/constants';
import { clearNamespaceErrors, getRelationErrors } from 'modules/Distances/utils/errors';
import * as React from 'react';

import {
  CUSTOM_FIELD_TYPES,
  CUSTOM_FIELDS_WITH_VALUES,
  CUSTOM_FIELD_TYPES_MATCHES,
  DRAG_IDS,
  CONFIRM_POPUP_TYPES,
  DISTANCE,
} from 'src/constants';
import { CFWithRegisteredUsersStore } from 'src/modules/Distances/stores';

import { DNDContainer, DNDItem } from 'components/DragNDrop';
import { Radio, TextField, RadioGroup, SelectField2, DropFileField, Switch } from 'components/Fields';
import { Icon } from 'components/Icon';

import { reorderUtil, t } from 'utils';

import { progressStore } from 'stores';
import confirmModalStore from 'stores/confirmationModal';

import { clearFieldError } from '../../../../utils/errors/formErrors';

import { hasCompletedOrders } from '../../../../stores';

import { getCustomFieldValue } from './';
import { CustomFieldValueItem } from './CustomFieldValueItem';

type Props = {
  onChange: (id: number | string, data: CustomFieldType, callback?: Function, withConfirm?: boolean) => void;
  onRemove: (id: number | string) => void;
  toggleCollapse: React.MouseEventHandler;
  value: CustomFieldType;
  index: number;
  currency: string;
  open: boolean;
  isDragDisabled?: boolean;
};

type State = {
  openedValueId: number | string | null;
  initial: boolean;
};

const FIELDS = {
  is_required: 'is_required',
  type: 'type',
};

@observer
class Item extends React.Component<Props, State> {
  onlyNumberRegexp = /^[0-9]*\.?[0-9]*$/;

  state = {
    openedValueId: null,
    initial: true,
  };

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.initial) {
      const { values } = props?.value;

      const customValues: any = filterDeleted(values || []);

      if (!isEmpty(customValues)) {
        return {
          initial: false,
          openedValueId: customValues[0]?.__id,
        };
      }
    }

    return null;
  }

  componentDidMount() {
    // if (this.props.value.image) {
    //   const image: any = toJS(this.props.value.image);
    //   this.onChangeImage({ name: 'image', value: image });
    // }
  }

  // onChanges
  onChangeOpen = (name: string, state: boolean) => {
    this.setState({
      [name]: state,
    } as any);
  };

  onCheckConfirmModal = (name: string, changedValue: unknown, callback: Function = () => {}, withConfirm?: boolean | any) => {
    const { value } = this.props;
    const newValue = String(this._formatValue(name, changedValue));
    const isRacersRegistered = CFWithRegisteredUsersStore.value && value.id && CFWithRegisteredUsersStore.value[value.id.toString()];

    if (isRacersRegistered && value.type !== newValue) {
      confirmModalStore.openModal({
        title: t.staticAsString('shared.exportConfirm.title'),
        body: t.staticAsString('shared.messagePopup.messageCustomFieldConfirm'),
        type: CONFIRM_POPUP_TYPES.confirm,
        onConfirm: () => this.onChange(name, changedValue, callback, withConfirm),
      });

      return;
    }

    this.onChange(name, changedValue, callback, withConfirm);
  };

  onChange = (name: string, changedValue: unknown, callback: Function = () => {}, withConfirm?: boolean | any) => {
    const { value, onChange } = this.props;
    const formattedValue = this._formatValue(name, changedValue);

    let changeData = { ...value };

    changeData = {
      ...changeData,
      [name]: formattedValue,
    };

    const id = value.id || value.__id || '';
    onChange(
      id,
      changeData,
      () => {
        callback();
        this._afterOnChange(name, value);
      },
      withConfirm,
    );
  };

  onChangePlain = (e: React.ChangeEventHandler | any) => {
    const { name, value } = e.currentTarget;

    this.onChange(name, value);
  };

  onChangeRadio = (e: React.ChangeEventHandler | any) => {
    const { name, value } = e.target;

    this.onChange(name, value);
  };

  onChangeCustomFieldValue = (id: number | string, data: CustomFieldValue, callback?: Function) => {
    const { values } = this.props.value;

    let newValues = !!values ? [...values] : [];

    newValues = newValues.map((el) => (el.__id === id || el.id === id ? data : el));

    this.onChange('values', newValues, callback);
  };

  onChangeOnlyNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.onlyNumberRegexp.test(e.currentTarget.value.toString())) {
      this.onChangePlain(e);
    }
  };

  onChangeImage = ({ name, value }: { name: string; value: nil | Blob }) => {
    this.onChange(name, value);
  };

  // -------------

  isError = (index: number): boolean => {
    // TODO, ready to use solution in case we need to add error markers for collapsed form
    // const errors = getRelationErrors(index, ERRORS_PREFIXES.custom_fields);

    // if (!errors) {
    //   return false;
    // }

    // return values(flat(errors)).some(Boolean)

    return false;
  };

  defaultInputProps = (name: string) => {
    const value = (this.props.value as AnyObject)[name];

    return {
      value: value,
      name,
      errors: [],
      fullWidth: true,
    };
  };

  onRemove = (e: React.MouseEvent) => {
    if (progressStore.isLoading(`UPDATE_${DISTANCE}`)) return;
    e.stopPropagation();
    const { onRemove, value } = this.props;
    const id = value.id || value.__id || '';
    onRemove(id);
  };

  onToggleEnabledField = (e: React.ChangeEventHandler | any) => {
    this.onChange('is_enabled', e.target.checked);
  };

  // Custom field values
  onAddCustomFieldValue = () => {
    if (progressStore.isLoading(`UPDATE_${DISTANCE}`)) return;

    const { value, index } = this.props;
    const values = value.values;
    const isFirst = !values || !values.length;
    const newCustomFieldValue = getCustomFieldValue(isFirst, values?.length);

    const newValues = [...(values || []), newCustomFieldValue];

    this.onChange('values', newValues, () => {
      clearNamespaceErrors(`${ERRORS_PREFIXES.custom_fields}.${index}.values`);
    });

    this.toggleCollapse(newCustomFieldValue.__id || '');
  };

  onRemoveCustomFieldValue = (id: number | string) => {
    const { value, index } = this.props;
    const values = value.values;
    let withConfirm = true;

    if (!values) {
      return;
    }

    const newValues = values.reduce((acc: any, customFieldValue: CustomFieldValue | any) => {
      if (customFieldValue.id === id) {
        return [
          ...acc,
          {
            ...customFieldValue,
            _delete: true,
          },
        ];
      } else if (customFieldValue.__id === id) {
        withConfirm = customFieldValue.value || customFieldValue.price;
        return acc;
      } else {
        return [...acc, customFieldValue];
      }
    }, []);

    this.onChange(
      'values',
      newValues,
      () => {
        clearNamespaceErrors(`${ERRORS_PREFIXES.custom_fields}.${index}.values`);
      },
      withConfirm,
    );
  };

  hasAbilityToAddNewCustomFieldValue = () => {
    const { type } = this.props.value;
    return CUSTOM_FIELDS_WITH_VALUES.includes(type);
  };

  toggleCollapse = (id: string | number) => {
    const { openedValueId } = this.state;
    const newValue = openedValueId === id ? null : id;

    this.setState({
      openedValueId: newValue,
    });
  };

  onDragEnd = (result: AnyObject): void => {
    const { value } = this.props;

    // dropped outside the list
    if (!result?.destination) {
      return;
    }

    const items = reorderUtil.changePosition([...(value.values || [])], result.source.index, result.destination.index);

    this.onChange('values', items);
  };

  onMouseDown = (e: React.MouseEvent) => {
    const { toggleCollapse } = this.props;
    const { open } = this.props;

    if (open) {
      toggleCollapse(e);
    }
  };

  // -------------------

  _renderCustomFieldValues = (): Array<React.ReactNode> | null => {
    const { value, index, currency } = this.props;
    const { openedValueId } = this.state;
    const { values } = value;
    const errorPrefix = `${ERRORS_PREFIXES.custom_fields}.${index}`;

    if (values) {
      const valuesForRender = filterDeleted(values);
      const withoutDelete = valuesForRender.length === 1;

      return valuesForRender.map((customFieldValue: AnyObject | any, index: number): React.ReactNode => {
        const actualId = isUndefined(customFieldValue.id) ? customFieldValue.__id || '' : customFieldValue.id;

        return (
          <CustomFieldValueItem
            key={actualId}
            hasCompletedOrders={hasCompletedOrders.value}
            isBlank={Boolean(customFieldValue.id)}
            value={customFieldValue}
            onChange={this.onChangeCustomFieldValue}
            onRemove={this.onRemoveCustomFieldValue}
            open={openedValueId === (customFieldValue.__id || actualId)}
            toggleCollapse={() => this.toggleCollapse(customFieldValue.__id || actualId)}
            index={index}
            errorPrefix={errorPrefix}
            withoutDelete={withoutDelete}
            currency={currency}
          />
        );
      });
    }

    return null;
  };

  _formatValue = (name: string, value: unknown | any) => {
    if (name === FIELDS.is_required) {
      return parseInt(value, 10);
    }

    return value;
  };

  _afterOnChange = (name: string, changedValue: unknown) => {
    const { value } = this.props;
    if (name === FIELDS.type) {
      if (!this.hasAbilityToAddNewCustomFieldValue()) {
        this._deleteCustomFieldValues();
      } else if (!value.values || !value.values.length) {
        this.onAddCustomFieldValue();
      }
    }
  };

  _deleteCustomFieldValues = () => {
    const { value, onChange } = this.props;
    let newValue = { ...value };
    delete newValue.values;
    const id = value.id || value.__id || '';
    onChange(id, newValue);
  };

  _typesOptions(): Array<SelectOption> {
    return CUSTOM_FIELD_TYPES.map((field) => {
      const option = CUSTOM_FIELD_TYPES_MATCHES[field];

      return {
        label: t.staticAsString(`shared.customFieldTypes.${field}` as TranslationLockedKeys),
        key: `shared.customFieldTypes.${field}`,
        value: field,
        description: t.staticAsString(`helper.${field}.text` as TranslationLockedKeys),
        ...option,
      };
    });
  }

  _renderAddNewCustomFieldValue = () => {
    if (!this.hasAbilityToAddNewCustomFieldValue()) {
      return;
    }

    return (
      <div className='item-toolbar'>
        <div className='add-new-custom-field-item' onClick={this.onAddCustomFieldValue}>
          <AddCircleOutline className='add-icon' />
          <p>{t.staticAsString('distances.steps.customFieldsForm.addOption')}</p>
        </div>
      </div>
    );
  };

  render() {
    const { index, value, open, toggleCollapse } = this.props;
    const isError = this.isError(index);
    const OpenIcon = open ? KeyboardArrowUp : KeyboardArrowDown;
    const { is_required } = this.props.value;
    const classes = classNames('custom-field-item', { collapsed: !open }, { error: isError });
    const disabled = progressStore.isLoading(`UPDATE_${DISTANCE}`);

    if (value._delete) {
      return null;
    }

    const headerOptions = {
      title: value?.name || t.staticAsString('distances.steps.customFieldsForm.formTitle'),
      controls: (
        <div className='item-controls'>
          <Switch name='is_enabled' value={value?.is_enabled} onChange={this.onToggleEnabledField} errors={[]} disabled={disabled} />
          <Icon className='delete-icon' value='delete-active' onClick={this.onRemove} />
          <OpenIcon className='open-icon' onClick={toggleCollapse} />
        </div>
      ),
    };

    const dndContainerId = `${DRAG_IDS.CUSTOM_FIELD_VALUES}.${value.id || value.__id || ''}`;

    return (
      <DNDItem
        className={classes}
        id={`${value.id || value.__id || ''}`}
        index={value.index}
        onMouseDown={this.onMouseDown}
        headerOptions={headerOptions}
        isDragDisabled={disabled}
      >
        <div id={`form-${value.id || value.__id || ''}`} className={classNames('custom-field-form', { hide: !open })}>
          {value.type === 'extra' && (
            <div className='item-toolbar-extra'>
              <DropFileField
                name='image'
                onChange={this.onChangeImage}
                value={(value.image as AnyObject)?.url || value.image || ''}
                errors={getRelationErrors(index, ERRORS_PREFIXES.custom_fields, 'image')}
              />
            </div>
          )}

          <TextField
            {...this.defaultInputProps('name')}
            label={t.staticAsString('distances.steps.customFieldsForm.name')}
            errors={getRelationErrors(index, ERRORS_PREFIXES.custom_fields, 'name')}
            onChange={this.onChangePlain}
            disabled={disabled}
          />
          <SelectField2
            {...this.defaultInputProps('type')}
            label={t.staticAsString('distances.steps.customFieldsForm.type')}
            errors={getRelationErrors(index, ERRORS_PREFIXES.custom_fields, 'type')}
            onChange={this.onCheckConfirmModal}
            options={this._typesOptions()}
            className='form-control'
            disabled={disabled}
          />
          <TextField
            {...this.defaultInputProps('helper_text')}
            label={t.staticAsString('distances.steps.customFieldsForm.helper_text')}
            errors={getRelationErrors(index, ERRORS_PREFIXES.custom_fields, 'helper_text')}
            position='icon'
            tooltipText={t.staticAsString('custom_fields.helper')}
            onChange={this.onChangePlain}
            multiline
            hasPaddingRight
            disabled={disabled}
          />
          <RadioGroup
            classes={{
              root: is_required !== -1 ? '' : 'unchecked',
              label: 'legend-custom-field',
              error: 'error',
            }}
            focused={false}
            label={t.staticAsString('distances.steps.customFieldsForm.is_required')}
            errors={getRelationErrors(index, ERRORS_PREFIXES.custom_fields, 'is_required')}
          >
            <div className='radio-wrap'>
              <Radio
                {...this.defaultInputProps('is_required')}
                classes={{
                  label: is_required === 1 ? 'radio-label' : 'radio-label unchecked',
                  root: 'radio-input',
                  checked: 'checked',
                }}
                label={(t.staticAsString('shared.yes') as any).toUpperCase()}
                errors={getRelationErrors(index, ERRORS_PREFIXES.custom_fields, 'is_required')}
                value='1'
                selected={is_required.toString()}
                onChange={this.onChangeRadio}
                disabled={disabled}
              />
              <Radio
                {...this.defaultInputProps('is_required')}
                classes={{
                  label: is_required === 0 ? 'radio-label' : 'radio-label unchecked',
                  root: 'radio-input',
                  checked: 'checked',
                }}
                label={(t.staticAsString('shared.no') as any).toUpperCase()}
                errors={getRelationErrors(index, ERRORS_PREFIXES.custom_fields, 'is_required')}
                value='0'
                selected={is_required.toString()}
                onChange={this.onChangeRadio}
                disabled={disabled}
              />
            </div>
          </RadioGroup>
        </div>
        <div className={classNames({ hide: !open })}>
          <DNDContainer droppableId={dndContainerId} className='field-values-list' onDragEnd={this.onDragEnd}>
            {this._renderCustomFieldValues()}
          </DNDContainer>
          {this._renderAddNewCustomFieldValue()}
        </div>
      </DNDItem>
    );
  }
}

const filterDeleted = <
  T extends {
    _delete?: boolean;
  },
>(
  values: Array<T>,
): Array<T> => {
  return values.filter((el) => !el._delete);
};

const normalizeDataForBackend = (
  values: nil | CustomFieldValue[],
): {
  values?: Array<
    | {
        _delete?: boolean;
        id?: number;
      }
    | CustomFieldValue
  >;
} => {
  if (!values) {
    return {};
  }

  const processedValues = values.map((el) => {
    if (!!el._delete) {
      return { id: el.id, _delete: true };
    }

    return { ...el, _delete: false };
  });

  return { values: processedValues };
};

export { normalizeDataForBackend };
export { Item };

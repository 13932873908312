import { keys, isNil } from 'lodash';
import Xregexp from 'xregexp';

import { _addErrors } from './_addErrors';

function pattern(withAnchors: boolean) {
  return Xregexp(
    `${withAnchors ? '^' : ''}(?<relationName>\\w+)\\.(?<customFieldIndex>\\d+)` +
      `\\.values\\.(?<customFieldValueIndex>\\d+)(?<locale>\\.[a-z]{2,2})?\\.(?<fieldName>\\w+)${withAnchors ? '$' : ''}`,
  );
}

/**
 * @description
 * Remap and add to errorsStore, BE errors errors, specifically:
 *
 * custom_fields.0.values.0.en.value to custom_fields.0.values.0.value
 *
 */
function catchErrorsForCustomFieldValues(errors: RawError | nil) {
  if (!errors) {
    return;
  }

  const errorKeys = keys(errors);

  const mappedErrors = errorKeys.reduce((processedErrors, errorKey) => {
    const match = Xregexp.exec(errorKey, pattern(true));

    if (!match) {
      return processedErrors;
    }

    let { fieldName, customFieldIndex, relationName, customFieldValueIndex } = match;
    const parsedErrorIndex = parseInt(match.errorInde);

    if (isNil(parsedErrorIndex)) {
      return processedErrors;
    }

    const newErrorKey = `${relationName}.${customFieldIndex}.values.${customFieldValueIndex}.${fieldName}`;

    const oldErrors = errors[errorKey] || [];
    const newErrors = oldErrors.map((error) => Xregexp.replace(error, pattern(false), '$<fieldName>'));

    processedErrors[newErrorKey] = newErrors;

    return processedErrors;
  }, {});
  return mappedErrors;
}

export { catchErrorsForCustomFieldValues };

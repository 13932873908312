import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useEffect, useRef } from 'react';

import useIntersectionObserver from 'utils/hooks/useIntersectionObserver';

type Props = {
  label: string | React.ReactNode;
  checked?: boolean;
  onChange: AnyFunction;
  loadMore?: Function;
  hasMore?: boolean;
};

export const SelectItem: React.FC<Props> = (props: Props) => {
  const { label, checked, onChange, loadMore, hasMore } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible) {
      loadMore && loadMore();
    }
  }, [isVisible]);

  return (
    <>
      <ListItemText
        {...(hasMore && loadMore ? { ref } : {})}
        title={String(label)}
        primary={label}
        primaryTypographyProps={{ noWrap: true }}
      />
      <Checkbox color='primary' checked={checked} onChange={onChange} />
    </>
  );
};

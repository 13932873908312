import { generatePath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

import { distanceStore } from '../stores';

function onEditDistance() {
  const distanceModel = distanceStore.modelSelected;

  if (!distanceModel) {
    return;
  }

  const { race_parent_id, id } = distanceModel.value;

  history.push(generatePath(ROUTES.distanceRoute, { raceId: race_parent_id, id }));
}

export { onEditDistance };

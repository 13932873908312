import ErrorIcon from '@mui/icons-material/Error';
import { Paper, Typography } from '@mui/material';
import * as React from 'react';

import { t } from 'utils';

import { endStatusStyles } from '../styles';

export const LoadingError = ({ upload }: { upload?: boolean }) => {
  const classes = endStatusStyles();
  return (
    <Paper elevation={3} className={classes.paper}>
      <ErrorIcon className={`${classes.icon} ${classes.errorColor}`} />
      <Typography variant='h5' component='h2' className={classes.text}>
        {upload ? t.staticAsString('shared.loadPopup.uploadError') : t.staticAsString('shared.loadPopup.loadError')}
      </Typography>
    </Paper>
  );
};

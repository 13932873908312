import { t } from 'utils/t';

const presence = (messageKey?: TranslationLockedKeys) => {
  return {
    presence: {
      allowEmpty: false,
      message: t.staticAsString(messageKey ?? 'shared.errors.presence'),
    },
  };
};

export const smartLinksFormValidation = (withDiscount: boolean) => {
  const validationWithoutDiscount = {
    name: {
      ...presence(),
      length: { minimum: 3, maximum: 255 },
    },
    race_id: {
      ...presence('coupons.new.errors.race_id'),
    },
    distance_id: {
      ...presence('coupons.new.errors.distances'),
    },
    date_to: {
      ...presence(),
    },
    date_from: {
      ...presence(),
    },
    max_usages: {
      ...presence(),
      numericality: {
        greaterThanOrEqualTo: 1,
        lessThanOrEqualTo: 10000,
      },
    },
  };

  if (withDiscount) {
    return {
      ...validationWithoutDiscount,
      discount_type: {
        ...presence(),
      },
      discount_sum: {
        ...presence(),
      },
      discount_sum_type: {
        ...presence('coupons.new.errors.coupon_type'),
      },
    };
  }

  return validationWithoutDiscount;
};

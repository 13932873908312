const inputClassesWithValue = {
  focused: 'focused',
  root: 'field-root',
  input: 'field',
  underline: 'filled-underline',
};

const inputClassesWithoutValue = {
  focused: 'focused',
  root: 'field-root',
  input: 'field',
  underline: 'underline',
};

// Classes used by material input
function generateClasses(value: any): Object {
  if (!!value) {
    return inputClassesWithValue;
  } else {
    return inputClassesWithoutValue;
  }
}

export { generateClasses };

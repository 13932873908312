import * as React from 'react';

import ReferralProgramBanner from 'components/Banners/ReferralProgramBanner';
import VerifyEmailBanner from 'components/Banners/VerifyEmailBanner';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { Sidebar } from 'components/Sidebar';

import 'utils/customValidators';

type Props = {
  children: React.ReactNode;
};

const Main = ({ children }: Props) => {
  return (
    <div className='main-layout'>
      <div className='main-wrapper'>
        <Header />
        <div className='content-wrapper'>
          <Sidebar />
          <main className='main-content'>
            {/* Banner was removed because of RP-10938 task --- maybe will be need to restore it */}
            {/* <ReferralProgramBanner /> */}
            {children}
            <VerifyEmailBanner />
          </main>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Main;

//@ flow
import { observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { withProgressSpinner } from 'hocs';

import { LOAD_ORGANIZER } from '../constants';

import { mount, unmount, loadPayments } from '../actions';

import { editModalStore } from '../stores';

import { AccountInfo } from './AccountInfo';
import { Breadcrumbs } from './Breadcrumbs';
import { EditProfileDialog } from './EditProfileDialog';
import { Payments } from './Payments';

type Props = {};

@withProgressSpinner(LOAD_ORGANIZER)
@withRouter
@observer
export class Base extends React.Component<Props> {
  componentDidMount() {
    mount();
    loadPayments();
  }

  componentWillUnmount() {
    unmount();
  }

  render() {
    // eslint-disable-next-line
    editModalStore.value;

    return (
      <div className='content main-container-detail yolo'>
        <EditProfileDialog />
        <Breadcrumbs />
        <AccountInfo />
        <Payments />
      </div>
    );
  }
}

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { REPORTS_BY_ORGANIZER } from 'src/constants';
import { progressStore } from 'src/stores';

import { SystemUserReportsConstants } from '../constants/systemUserReports.constants';

import { SystemUserViewStore } from '../stores/systemUserView.store';

import { SystemUsersReportsMapper } from '../mappers/systemUsersReports.mapper';
import { SystemUsersFilters } from '../types/filters.type';

export namespace SystemUserReportsService {
  export async function load(filters?: SystemUsersFilters) {
    progressStore.log(SystemUserReportsConstants.Table, 'progress');
    const organizerId = SystemUserViewStore.profile.value?.id;
    try {
      const response = await axios.get(generatePath(REPORTS_BY_ORGANIZER, { id: organizerId }), {
        params: {
          ...filters,
          with: 'race;file',
          searchJoin: 'AND',
          per_page: 100,
        },
      });

      return {
        data: SystemUsersReportsMapper.toFront(response.data.data),
        meta: response.data.meta,
      };
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(SystemUserReportsConstants.Table, 'completed');
    }
  }
}

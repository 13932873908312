import { observer } from 'mobx-react';
import * as React from 'react';

import { Show } from 'components/Condition';
import { SearchSelect, InputWrapper } from 'components/Form';

import { t } from 'utils';

import { loadOrders } from '../../actions';

import { ordersStore } from '../../stores';

type Props = {
  show: boolean;
};

@observer
export class FieldsSingleRegistration extends React.Component<Props> {
  onSelectChangeOrder = (event, newInputValue) => {
    loadOrders(newInputValue);
  };

  render() {
    const { show } = this.props;

    return (
      <Show if={show}>
        <InputWrapper
          name='order'
          settings={{
            required: true,
            label: t.staticAsString('supportTaskManager.form.orderId'),
            additional: {
              options: ordersStore.value,
            },
            view: {
              variant: 'outlined',
            },
            onInputChange: this.onSelectChangeOrder,
          }}
          Component={SearchSelect}
        />
      </Show>
    );
  }
}

import { Button, Tooltip } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { onImport } from 'modules/StartList/actions/onImport';
import { exportTempalateType } from 'modules/StartList/types';
import * as React from 'react';
import shortId from 'shortid';

import { Icon } from 'components/Icon';

import { t } from 'utils/t';

type Props = { startListType: exportTempalateType };

export const ImportMenu: React.FC<Props> = ({ startListType }) => {
  const [id] = React.useState(shortId());

  const withHandleClose = (handler: AnyFunction, type: string, close: AnyFunction) => () => {
    handler(type, startListType);
    close();
  };

  return (
    <PopupState variant='popover' popupId={id}>
      {(popupState: any) => (
        <Button className='button-item'>
          <Tooltip title={t.staticAsString('startlist.tooltip.import') as any} classes={{ tooltip: 'switch-popover' }}>
            <div>
              <div onClick={bindTrigger(popupState).onClick}>
                <div className='button-icon'>
                  <Icon value='import' />
                </div>
                <div className='button-title'>{t.staticAsString('startlist.import')}</div>
              </div>
              <Menu {...bindMenu(popupState)} className='menu-card' keepMounted id='settings-menu'>
                <MenuItem className={'menu-card-item'} onClick={withHandleClose(onImport, 'addNew', popupState.close)}>
                  <p>{t('startlist.import.addParticipants' as TranslationLockedKeys)}</p>
                </MenuItem>
                <MenuItem className={'menu-card-item'} onClick={withHandleClose(onImport, 'updateExisting', popupState.close)}>
                  <p>{t('startlist.import.updateParticipants' as TranslationLockedKeys)}</p>
                </MenuItem>
                <MenuItem className={'menu-card-item'} onClick={withHandleClose(onImport, 'addAndUpdate', popupState.close)}>
                  <p>{t('startlist.import.addAndUpdateParticipants' as TranslationLockedKeys)}</p>
                </MenuItem>
              </Menu>
            </div>
          </Tooltip>
        </Button>
      )}
    </PopupState>
  );
};

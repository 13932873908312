import { OrderDetails } from 'modules/Payments/models';
import * as React from 'react';

import { Show } from 'components/Condition';

import { t } from 'utils';

type Props = {
  value: OrderDetails;
};

export function PaymentTechInfo({ value }: Props) {
  if (!value) {
    return null;
  }

  const paymentMethod = value.paymentMethod();

  return (
    <div className='account-info-list payments-detail'>
      <div className='info-title'>
        <p>{t.staticAsString('payments.detail.paymentTechInfo.title')}</p>
      </div>
      <div className='info-body'>
        <div className='info-item'>
          <p className='info-label'>{t.staticAsString('payments.detail.paymentTechInfo.currency')}</p>
          <p className='info-value'>{value.currency()}</p>
        </div>
        <Show if={Boolean(paymentMethod)}>
          <div className='info-item'>
            <p className='info-label'>{t.staticAsString('payments.detail.paymentTechInfo.method')}</p>
            <p className='info-value'>{paymentMethod}</p>
          </div>
        </Show>
      </div>
    </div>
  );
}

import { MESSAGES_HEADER_KEY } from 'utils/messages/constants';

import { apiLoad } from 'actions/messages/apiLoad';

import { localeStore } from 'stores';

import { ResponseProcessor } from '../responseProcessor';

/*
 * ANNOTATION
 * Wrapper around axios promise
 * Halding promise: status, errors, auth token
 */
export const request = (reqArgs: { action: string; root?: string; writeError?: boolean }) => {
  reqArgs = { root: 'errors', ...reqArgs, writeError: true };

  return (target: any, propertyKey: string, descriptor: any): any => {
    var oldValue = descriptor.value;
    descriptor.value = async function (...args: unknown[]) {
      try {
        const response = await oldValue.call(this, ...args);
        if (response.headers[MESSAGES_HEADER_KEY]) {
          const currentVersion = localStorage.getItem(MESSAGES_HEADER_KEY);
          if (Number(currentVersion) !== Number(response.headers[MESSAGES_HEADER_KEY])) {
            localStorage.setItem(MESSAGES_HEADER_KEY, response.headers[MESSAGES_HEADER_KEY]);
            apiLoad(localeStore.locale);
          }
        }
        new ResponseProcessor({ ...reqArgs, response } as any).process();
        return [true, response];
      } catch (e) {
        new ResponseProcessor({ ...reqArgs, response: e.response } as any).process();
        return [false, e.response];
      }
    };

    return descriptor;
  };
};

import { TableHead, TableCell, TableRow, Table, Box, TableBody } from '@mui/material';
import React from 'react';

import { t } from 'src/utils';

import { formatCurrency } from '../utils';

import { PlatformFeeVat } from '../types/report.type';
import { StyledTableRow } from './PaymentOverview/styles';

type Props = {
  exclVat: number;
  platform_fees_vat?: PlatformFeeVat[];
};

export default function PlatformFeesVat({ exclVat, platform_fees_vat = [] }: Props) {
  if (platform_fees_vat.length === 0) {
    return null;
  }

  const title = (prefix: string) => t.staticAsString(`reconciliation.report.platformFeesVat.${prefix}` as any);

  const vatTotalByAmount = platform_fees_vat.reduce((a, b) => a + b.vat_amount, 0);

  const fixToInteger = (value: number) => value.toFixed(2);
  return (
    <Table width={'100%'} sx={{ my: 8 }}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ borderBottom: '1px solid black' }}>
            <Box sx={{ alignItems: 'start', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ mb: 1 }}>{title('exclVat')}</Box>
              <Box>{formatCurrency(fixToInteger(exclVat))}</Box>
            </Box>
          </TableCell>
          <TableCell sx={{ borderBottom: '1px solid black' }}>
            <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ mb: 1 }}>{title('vat')}</Box>
              <Box>{formatCurrency(fixToInteger(vatTotalByAmount))}</Box>
            </Box>
          </TableCell>
          <TableCell sx={{ borderBottom: '1px solid black' }}>
            <Box sx={{ alignItems: 'end', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ mb: 1 }}>{title('deductedPaid')}</Box>
              <Box>{formatCurrency(fixToInteger(vatTotalByAmount + exclVat))}</Box>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {platform_fees_vat.map((pfv, i) => {
          return (
            <StyledTableRow key={i}>
              <TableCell align='left'>{pfv.vat_value}%</TableCell>
              <TableCell align='center'>{formatCurrency(fixToInteger(pfv.vat_amount))}</TableCell>
              <TableCell align='right'></TableCell>
            </StyledTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

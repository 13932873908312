import { Box, FormControlLabel, FormGroup, Switch, Tooltip, Typography } from '@mui/material';
import Axios from 'axios';
import { observer } from 'mobx-react';
import { resultsService } from 'modules/Results/services';
import { ExistingDistanceList } from 'modules/StartList/components/ExistingDistanceLIst';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';

import { DISTANCE_FINISH_URL } from 'src/constants';

import { Icon } from 'components/Icon';
import { withConfirmModal } from 'components/hocs';

import { t } from 'utils/t';

import { toastStore } from 'stores';

import { Actions, Prices, DistanceDetails } from '../../components/Toolbar';

import { ResultsContext } from '../../context';

type Props = {} & WithConfirmModalProps<'deleteDistanceConfirm'> & WithConfirmModalProps<'importConfirm'>;

@withConfirmModal('deleteDistanceConfirm', {
  i18Title: 'distances.confirmPopup.mainTitle',
  i18Body: 'distances.confirmPopup.mainBody',
  type: 'confirm',
})
@withConfirmModal('importConfirm', {
  i18Title: 'results.importConfirm.title',
  i18Body: 'results.importConfirm.body',
  type: 'confirm',
})
@observer
class ToolbarContainer extends React.Component<Props> {
  static contextType = ResultsContext;
  declare context: React.ContextType<typeof ResultsContext>;

  getValues = () => {
    const store = this.context.results[this.distanceType()];
    return store && store.store.modelValues;
  };

  onChangeDistance = (e: React.ChangeEvent<HTMLInputElement>) => {
    const distanceId = e.target.value;
    const { onChangeDistance } = this.context.results.actions;

    onChangeDistance(+distanceId);
  };

  onDeleteDistance = () => {
    const { onDeleteDistance } = this.context.results.actions;
    const distanceModel = this.context.results.stores.distanceStore.modelSelected;

    if (!distanceModel) {
      return;
    }

    this.props.deleteDistanceConfirm.open(onDeleteDistance, {}, { name: distanceModel.name() });
  };

  distanceType = (): string => {
    const distanceModel = this.context.results.stores.distanceStore.modelSelected;

    if (!distanceModel) {
      return '';
    }

    return distanceModel.value.type;
  };

  onImport = () => {
    const values = this.getValues();
    const { onImport } = this.context.results.actions;
    if (values.length > 0) {
      this.props.importConfirm.open(onImport);
    } else {
      onImport();
    }
  };

  allowedActions = () => {
    const { distanceStore } = this.context.results.stores;
    const isCumulative = distanceStore.modelSelected && distanceStore.modelSelected.isMode('cumulative');

    const values = this.getValues();
    const hasValues = values.length > 0;

    return {
      import: !isCumulative,
      export: hasValues,
      exportTemplate: !isCumulative,
      edit: true,
      delete: true,
    };
  };

  render() {
    const { distanceStore, racesStore } = this.context.results.stores;
    const distanceModel = distanceStore.modelSelected;
    const raceModel = racesStore.modelSelected;

    if (!distanceModel || !raceModel) {
      return null;
    }

    const { onExportResults, onEditDistance, onExportTemplate } = this.context.results.actions;
    const { location, race_date, status } = distanceModel.value;

    const handleFinishDistance = async () => {
      try {
        await Axios.post(generatePath(DISTANCE_FINISH_URL, { id: distanceModel.value.id }));
        distanceModel.value.status = 'finished';
        toastStore.show('Distance set to finished');
      } catch (error) {}
    };
    return (
      <div className='toolbar racer-toolbar'>
        <div className='details'>
          <DistanceDetails date={race_date} location={location} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ExistingDistanceList distance={distanceModel} onChange={this.onChangeDistance} />
            <Box sx={{ marginLeft: 6, display: 'flex', alignItems: 'center' }}>
              <FormGroup>
                <FormControlLabel
                  onChange={handleFinishDistance}
                  labelPlacement='start'
                  control={<Switch checked={distanceModel.isStatusFinished()} sx={{ marginLeft: 1 }} />}
                  label='Finish Distance'
                />
              </FormGroup>
              <Tooltip
                title={
                  <Typography variant='body1'>
                    In unfinished distances, empty results are not visible in the live results. In finished distances, empty results are
                    displayed as “DNF” in the live results. You can edit results at any time.
                  </Typography>
                }
                placement='right'
              >
                <span className='tooltip-icon'>
                  <Icon style={{ marginLeft: 22 }} value='info' className='icon' />
                </span>
              </Tooltip>
            </Box>
          </Box>
          <Prices values={distanceModel.value.prices || []} currency={raceModel.currency()} />
        </div>

        <div className='buttons'>
          <Actions
            onExportResults={onExportResults}
            onExportTemplate={onExportTemplate}
            onEditDistance={onEditDistance}
            onDeleteDistance={this.onDeleteDistance}
            onImportResults={this.onImport}
            actions={this.allowedActions()}
          />
        </div>
      </div>
    );
  }
}
const Toolbar = injectIntl(ToolbarContainer);
export { Toolbar };

import { cloneDeep } from 'lodash';

import { Race } from 'models';

export const mapCouponRaces = (data: AnyObject[]): AnyObject[] => {
  const copy = cloneDeep(data);

  return copy.map((item) => {
    const model = new Race(item as any);

    return {
      key: item.id,
      value: item.id,
      label: `${model.name()}(${item.id})`,
    };
  });
};

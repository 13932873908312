import { racersService, teamsService } from '../services';

import { racersStore, teamsStore, distanceStore } from '../stores';

async function loadNewResultAutocomplete() {
  const currentDistance = distanceStore.modelSelected;

  if (!currentDistance) {
    return;
  }

  /* Do not load racers for non finished
   * distances, then 'Add result' button will
   * be hidden and there are no way to create result
   */
  if (!currentDistance.isStatusFinished() && currentDistance.isMode('classic')) {
    return;
  }

  const load = currentDistance.isSingle() ? loadRacers : loadTeams;
  await load(currentDistance.value.id);
}

async function loadRacers(id: number) {
  const racers = await racersService.load(id);
  racersStore.addValues(racers);
}

async function loadTeams(id: number) {
  const teams = await teamsService.load(id);
  teamsStore.addValues(teams);
}

export { loadNewResultAutocomplete };

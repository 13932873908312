import { openIntegrationForm } from 'modules/IntegrationForm/actions';

import { integrationsService } from '../services';

import { raceStore } from '../stores';

export const onEditIntegration = (id: number) => (): void => {
  const raceId = Number(raceStore.value?.id);
  openIntegrationForm(raceId, id, () => {
    integrationsService.getIntegrations(raceId);
  });
};

import { defaults, isEmpty } from 'lodash';

import { cache } from './cache';

function fetchFun(locale: availableLocales, defaultLocale: availableLocales): null | MessagesType {
  const translations = cache.fetch();

  if (isEmpty(translations[locale])) {
    return null;
  }

  return defaults(translations[locale], translations[defaultLocale]);
}

export { fetchFun };

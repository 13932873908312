export namespace CommissionConstants {
  export const Table = 'Table';

  export const Mappers = {
    grand_total: 'payment_info:id|amount',
  };

  export const COLUMN_WIDTH = {
    name: '250px',
  };
}

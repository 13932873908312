import { form } from 'stores';
import currencies from 'stores/currencies';

import { SystemUserFormPageService } from '../../services/systemUserFormPage.service';

import { systemUserFormStore } from '../../stores';

export const loadSystemUser = async (id: number, params: AnyObject = {}): Promise<void> => {
  const data = await SystemUserFormPageService.load(id, params);

  if (data.commissions) {
    data.commissions.forEach((commission) => {
      const currency = currencies.currencyById(commission.currency_id)?.iso_code;
      if (currency) {
        form.merge(currency, { amount: commission.amount / 100 });
        form.merge(currency, { percent_value: commission.percent_value / 100 });
        form.merge(currency, { refund_fee: commission.refund_fee / 100 });
        form.merge(currency, { vat_percents: `${commission.vat_percents / 100}` });
      }
    });
  }
  systemUserFormStore.addSelectedValue(data);
};

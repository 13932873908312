import { cloneDeep } from 'lodash';

import { AMOUNT_TYPE_ORDER, TYPE_ORDER } from '../constants';

import { TaskForm } from '../types';

export const mapToFormRefund = (data: AnyObject, type: number): AnyObject => {
  const form: TaskForm = cloneDeep(data) as TaskForm;

  const mappedData = {
    type: type,
    amount_type: form.amountType,
    id: type === TYPE_ORDER ? form.order.value : form.refundDistance.value,
    delete_participants: form.deleteParticipants,
  };
  const amountFloat = parseFloat(form.amount);
  const amount = !isNaN(amountFloat) ? Number(amountFloat.toFixed(2)) : 0;

  return form.amountType === AMOUNT_TYPE_ORDER ? mappedData : { ...mappedData, amount };
};

import axios from 'axios';

import { MAGIC_LINKS_URL } from 'src/constants';

import { action, request } from 'utils/core';

import { LOAD_SMART_LINKS } from '../constants';

import { Filters } from '../../shared/types';

class SmartLinksList {
  @request({ action: LOAD_SMART_LINKS })
  loadLinksListRequest(filters?: Filters): any {
    return axios.get(MAGIC_LINKS_URL, { params: filters });
  }

  @action({ action: LOAD_SMART_LINKS })
  async loadLinksList(filters?: Filters): Promise<any> {
    const [isOk, response] = await this.loadLinksListRequest(filters);
    if (isOk) {
      return response.data;
    }
  }
}

export const smartLinksService = new SmartLinksList();

import moment from 'moment';

import { BE_DATETIME_MS_FORMAT } from 'src/constants';

import { time } from 'utils';

import { beFormat } from './constants';

export function formatTimestampToMask(value: nil | timeSpace.WorkDate): string {
  if (!value) {
    return '';
  }
  return value.formatAsString('FEdatetimems');
}

export function formatMaskToTimestamp(value: string): nil | timeSpace.WorkDate {
  if (value === '') {
    return null;
  }

  if (!time.isValid(value, 'feDateTimeMs')) {
    return undefined;
  }

  return time(moment(value).format(BE_DATETIME_MS_FORMAT), beFormat).parse();
}

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { isNull, has } from 'lodash';
import { toJS } from 'mobx';
import React, { useEffect } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { ColumnWithLooseAccessor } from 'react-table';

import { ROUTES } from 'src/constants/routes';
import { sessionStore } from 'src/stores';

import AbsorbServiceFee from 'components/AbsorbServiceFee/AbsorbServiceFee';

import { t } from 'utils/t';

import { SystemUsersViewActions } from '../../../../actions/view.actions/systemUsers.view.actions';

import { SystemUserCommissionsStore } from '../../../../stores/systemUserCommissions.store';

import { CommissionMapper } from '../../../../mappers/systemUsersCommissions.mapper';
import { TableRow, TCommission, TCommissionError } from '../../../../types';
import { InheritNoticeCell } from './InheritNoticeCell';

type Props = {
  a: number;
  b?: number;
  c?: number; // i want "c" to be optional, but if it is present, i want it to be a number
};

const title = (prefix: string) => t.static(`systemUsers.commissions.${prefix}` as any);

const COLUMNS = ['expander', 'distances', 'absorb', 'commissions_amount', 'commissions', 'vat_percents', 'action'] as const;

const isEdit = (id: number) => SystemUserCommissionsStore.editCommission.value?.id === id;

const SelectCell = (props) => {
  const {
    value: initialValue,
    field,
    column: { id, selectProps },
  } = props;
  const options = selectProps.options() || [];
  const [value, setValue] = React.useState(initialValue);
  const onChange = (e) => {
    setValue(e.target.value);
    SystemUserCommissionsStore.editCommission.value = {
      ...SystemUserCommissionsStore.editCommission.value!,
      commission: {
        ...SystemUserCommissionsStore.editCommission.value?.commission!,
        [field]: e.target.value,
      },
    };
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    SystemUserCommissionsStore.editCommission.value = {
      ...SystemUserCommissionsStore.editCommission.value!,
      commission: {
        ...SystemUserCommissionsStore.editCommission.value?.commission!,
        [field]: options[0].value,
      },
    };
  }, []);

  return (
    <Box minWidth='160px' p={0.5}>
      <TextField
        style={{ borderRadius: 0, maxWidth: 150, marginTop: 0, backgroundColor: 'white' }}
        variant='outlined'
        size='small'
        fullWidth
        select
        name={id}
        value={value ?? 0}
        onChange={onChange}
      >
        {options.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

const EditableCell = ({ value: initialValue = false, field }) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    SystemUserCommissionsStore.error.set({ ...SystemUserCommissionsStore.error.value, [e.target.name as string]: false });
    setValue(e.target.value);
    SystemUserCommissionsStore.editCommission.value = {
      ...SystemUserCommissionsStore.editCommission.value!,
      commission: {
        ...SystemUserCommissionsStore.editCommission.value?.commission!,
        [field]: e.target.value,
      },
    };
  };

  useEffect(() => {
    setValue(initialValue);
    return () => {
      SystemUserCommissionsStore.error.set({ amount: false, percent_value: false, is_absorb: false });
    };
  }, [initialValue]);

  return (
    <TextField
      style={{ borderRadius: 0, maxWidth: 100, marginTop: 0, backgroundColor: 'white' }}
      variant='outlined'
      size='small'
      value={value ?? ''}
      onChange={onChange}
      type='number'
    />
  );
};

const renderMargin = (io?: boolean, ir?: boolean, id?: boolean) => {
  if (io) return 0;
  if (ir) return 2;
  if (id) return 4;
};

const EditableAbsorbCell = (props) => {
  const { value: initialValue, field } = props;
  const [value, setValue] = React.useState<boolean>(initialValue);

  useEffect(() => {
    setValue(initialValue);
    return () => {
      SystemUserCommissionsStore.error.set({ amount: false, percent_value: false, is_absorb: false });
    };
  }, [initialValue]);

  const handleUpdateAbsorb = () => {
    setValue(!value);
    SystemUserCommissionsStore.error.set({ ...SystemUserCommissionsStore.error.value, ['is_absorb']: false });
    SystemUserCommissionsStore.editCommission.value = {
      ...SystemUserCommissionsStore.editCommission.value!,
      commission: {
        ...SystemUserCommissionsStore.editCommission.value?.commission!,
        [field]: !value,
      },
    };
  };

  return <AbsorbServiceFee label='Absorb' isAbsorb={value} handleToggle={handleUpdateAbsorb} showInfo={false} />;
};

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<TableRow> & AnyObject } = {
  expander: {
    id: 'expander',
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => <span />,
    Cell: ({ row }) =>
      row.canExpand ? (
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              display: 'flex',
              alignItems: 'center',
            },
          })}
        >
          {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
        </span>
      ) : null,
  },
  distances: {
    id: 'name',
    Header: title('race&Distances'),
    accessor: (entry) => entry.name,
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      const getLink = () => {
        if (original.isRace) return generatePath(ROUTES.raceRoute, { id: original.id });
        if (original.isDistance) return generatePath(ROUTES.distancesRacersRoute, { raceId: original.parentRaceId, id: original.id });
        return '/';
      };
      return (
        <Box sx={{ ml: renderMargin(original.isRace, original.isDistance), fontWeight: original.isRace ? 600 : 400 }}>
          <Link to={getLink()} style={{ color: 'black' }}>
            {original.name}
          </Link>
        </Box>
      );
    },
  },

  commissions_amount: {
    id: 'commission.amount',
    Header: title('commissionFixed'),
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    accessor: (entry) => entry.commission?.amount,
    Cell: (props) => {
      if (isEdit(props.row.original.id ?? 0)) return <EditableCell field='amount' {...props} />;
      if (isNull(props.row.original.commission)) {
        return <InheritNoticeCell row={props.row.original} />;
      } else {
        return (
          <>{`${props.row.original.commission?.amount ?? '-'} ${
            props.row.original.commission?.amount ? props.row.original.currency.iso_code : ''
          }`}</>
        );
      }
    },
  },

  commissions: {
    id: 'commission.percent_value',
    Header: title('commissionPercent'),
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    accessor: (commission) => commission.commission?.percent_value,
    Cell: (props) => {
      if (isEdit(props.row.original.id ?? 0)) return <EditableCell field='percent_value' {...props} />;
      if (isNull(props.row.original.commission)) {
        return <InheritNoticeCell row={props.row.original} />;
      } else {
        return <>{props.row.original.commission?.percent_value}</>;
      }
    },
  },
  absorb: {
    id: 'commission.is_absorb',
    Header: 'Absorb',
    filterable: false,
    sortable: false,
    disableFilters: true,
    accessor: (commission) => commission.commission?.is_absorb,
    Cell: (props) => {
      if (isEdit(props.row.original.id ?? 0)) return <EditableAbsorbCell field='is_absorb' {...props} />;
      if (isNull(props.row.original.commission)) {
        return <InheritNoticeCell row={props.row.original} />;
      } else {
        return <>{props.row.original.commission.is_absorb ? 'Absorbing' : 'Not absorbing'}</>;
      }
    },
  },
  vat_percents: {
    id: 'commission.vat_percents',
    Header: t.static('reconciliation.commissions.vatPercent'),
    accessor: (commission) => commission.commission?.vat_percents,
    Cell: (props) => {
      const isFormRendering = isEdit(props.row.original.id ?? 0);
      if (isFormRendering) {
        return <SelectCell field='vat_percents' {...props} />;
      }

      if (isNull(props.row.original.commission)) {
        return <InheritNoticeCell row={props.row.original} />;
      }

      return <>{props.row.original.commission.vat_percents}%</>;
    },
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    selectProps: {
      options: () => [
        {
          key: '0',
          value: 0,
          label: '0%',
        },
        {
          key: '25',
          value: 25,
          label: '25%',
        },
      ],
    },
  },

  action: {
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    id: 'action',
    minWidth: 220,
    Header: title('action'),
    Cell: ({ row: { original } }) => {
      const renderButton = () => {
        const getButtonText = () => {
          if (isEdit(original.id)) return title('save');
          if (original.commission === null) return title('setCommission');
          return title('edit');
        };

        const hasEmptyField = (commission: TCommission) => {
          let hasError = false;

          if (isNull(commission)) {
            SystemUserCommissionsStore.error.set({ amount: true, percent_value: true, is_absorb: true });
            return true;
          }

          if (!has(commission, 'amount') || (has(commission, 'amount') && !Boolean(commission?.amount))) {
            SystemUserCommissionsStore.error.set({ ...SystemUserCommissionsStore.error.value, amount: true });

            hasError = true;
          }
          if (!has(commission, 'percent_value') || (has(commission, 'percent_value') && !Boolean(commission?.percent_value))) {
            SystemUserCommissionsStore.error.set({ ...SystemUserCommissionsStore.error.value, percent_value: true });

            hasError = true;
          }

          return hasError;
        };

        const submit = () => {
          const observeOriginal = SystemUserCommissionsStore.editCommission.value;
          if (isNull(original.commission)) {
            SystemUsersViewActions.createCommission(CommissionMapper.toBackend(Boolean(original.commission), observeOriginal as TableRow));
          } else {
            SystemUsersViewActions.updateCommission(
              original.commission.id,
              CommissionMapper.toBackend(Boolean(original.commission), observeOriginal as TableRow),
            );
          }
        };

        const handleClick = () => {
          if (isEdit(original.id)) return submit();
          return SystemUserCommissionsStore.editCommission.set(original);
        };

        const handleClose = () => {
          SystemUserCommissionsStore.editCommission.set(null);
        };

        const handleReset = () => {
          if (isNull(original.commission)) return;
          SystemUsersViewActions.resetCommission(original.commission.id);
        };

        return (
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleClick}>
              {getButtonText()}
            </Typography>
            {isEdit(original.id) && (
              <Typography sx={{ ml: 2, textDecoration: 'underline', cursor: 'pointer' }} onClick={handleClose}>
                {title('close')}
              </Typography>
            )}
            {isEdit(original.id) && !isNull(original.commission) && (
              <Typography sx={{ ml: 2, textDecoration: 'underline', cursor: 'pointer' }} onClick={handleReset}>
                {title('reset')}
              </Typography>
            )}
          </Box>
        );
      };
      return renderButton();
    },
  },
};

export function generateCommissionColumns() {
  return COLUMNS.map((c) => RenderColumns[c]).filter((el) => {
    if (!sessionStore.isAdmin) return el.id !== 'action';

    return el;
  });
}

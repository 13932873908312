import { pick } from 'lodash';

import { validate } from 'utils';

import { updateResult, updateRacer, updateTeam } from '../validation';

function generateValidate(scheme) {
  return (fieldId: string, property: string, value: any) => {
    const validationScheme = pick(scheme, property);
    const data = { [property]: value };
    return validate(fieldId, data, validationScheme);
  };
}

const validateSingleResult = generateValidate(updateResult);
const validateRacer = generateValidate(updateRacer);
const validateTeam = generateValidate(updateTeam);

export { validateSingleResult, validateRacer, validateTeam };

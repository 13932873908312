import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Button, Collapse, Tooltip } from '@mui/material';
import { debounce, isEmpty, isNumber, toString } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import validate from 'validate.js';

import { RACER } from 'src/constants';

import { withErrorClean } from 'hocs';

import { Show } from 'components/Condition';
import { AutocompleteSelect } from 'components/Fields';
import { Checkbox } from 'components/Fields/Checkbox';
import { Input } from 'components/Fields/Input';
import { SelectField } from 'components/Fields/SelectField';
import { AutocompleteSelectInfinityLoad, Date, PhoneInput, TextField } from 'components/Form';

import { gender, oneFieldValidation, t, validation } from 'utils';

import { Distance } from 'models';

import { squadsService } from 'services';

import { countriesStore, Country as CountryType, Errors as ErrorsStore, squadsStore } from 'stores';

import { registrationFieldsTools } from '../../../../utils';

import { loadCustomerProfileService, racersService } from '../../../../services';

import { raceNewMapper, racerToState } from '../../../../mapper';
import { racer as createRacerConstraints, racerValidation } from '../../../../validations';

type OriginalProps = {
  errorsStore?: ErrorsStore;
  distance: Distance;
  onClose: () => void;
  onSubmit?: () => void;
  countries?: CountryType;
} & RouterProps;

type Props = OriginalProps & HOC.withIntl;

type State = {
  racer: AnyObject;
};

const action = `CREATE_${RACER}`;

const VALIDATE_FIELDS: AnyObject = {
  bib_number: /^[0-9]+$/,
};

const FIELDS = {
  email: 'email',
};

@withRouter
@inject('errorsStore', 'helperDistancesStore')
@withErrorClean(action)
@observer
class RacerFormImpl extends React.Component<Props, State> {
  initialState = {
    racer: {
      bib_number: '',
      firstname: '',
      lastname: '',
      email: '',
      class_id: '',
      gender: '',
      squad_id: '',
      user_id: null,
      payment_required: false,
    },
  };

  state: State = { ...this.initialState };

  debouncedLoadRacerProfile: AnyFunction;

  constructor(props: Props) {
    super(props);
    this.debouncedLoadRacerProfile = debounce(this.loadRacerProfile, 500);
  }

  componentWillUnmount() {
    const { distance } = this.props;
    squadsService.store.clearAll();
    squadsService.store.clearFilters();
    squadsService.loadDistanceSquads(distance.value.id);
  }

  @validation({ action, constrains: createRacerConstraints })
  validate() {
    return this.state.racer;
  }

  onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { distance } = this.props;
    const isValidated = await this.validate();

    if (!isValidated || !!this.errors().length) {
      return;
    }

    racersService.createResource(raceNewMapper.mapTo(this.state.racer) as any, distance.value!.id, () => this.props.onClose());
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value: string | null | boolean = null;
    let name = '';

    switch (e.type) {
      case 'click':
        value = e.target.value;
        name = e.target.name;
        break;
      case 'change':
        value = e.currentTarget.value;
        name = e.currentTarget.name;
        break;
      default:
        value = e.currentTarget.value;
        name = e.currentTarget.name;
    }

    if (name === 'payment_required') {
      value = e.currentTarget.checked;
    }

    if (VALIDATE_FIELDS[name] && (value as string).length && !(value as string).match(VALIDATE_FIELDS[name])) {
      return;
    }

    if (name === 'bib_number' && value && /^0/.test(toString(value))) {
      return;
    }

    this.setState(
      {
        ...this.state,
        racer: {
          ...this.state.racer,
          [name]: value,
        },
      },
      () => {
        const constrainsField = { [name]: racerValidation[name] };
        oneFieldValidation(action, this.state.racer, constrainsField);
        this.afterOnChangeHook({ name, value });
      },
    );
  };

  onChangeForm = ({ value, name }: AnyObject): void => {
    this.setState(
      {
        racer: {
          ...this.state.racer,
          [name]: value,
        },
      },
      () => {
        const constrainsField = { [name]: racerValidation[name] };
        oneFieldValidation(action, this.state.racer, constrainsField);
        this.afterOnChangeHook({ name, value });
      },
    );
  };

  onChangePhone = (name: string, value: Object) => this.onChangeForm({ name, value });

  errors = () => {
    const { errorsStore } = this.props;
    const errors = errorsStore?.errors.api[action];
    const validationErrors = errorsStore?.errors.validation[action];

    if (validationErrors) {
      return validationErrors.formatErrors();
    }
    if (errors) {
      return errors.formatErrors();
    }
    return {};
  };

  defaultInputProps = (name: string) => {
    let value = (this.state.racer as AnyObject)[name];
    if (name === 'gender' && isNumber(value)) {
      value = value.toString();
    }

    return {
      name,
      value,
      onChange: this.onChange,
      errors: this.errors()[name],
      fullWidth: true,
    };
  };

  inputProps = (name: string): any => {
    return {
      name,
      id: name,
      value: this.state.racer[name],
      onChange: this.onChangeForm,
      errors: this.errors()[name],
      error: this.errors()[name],
      label: `${t.staticAsString(`racers.new.${name}` as TranslationLockedKeys)}`,
      view: {
        fullWidth: true,
      },
    };
  };

  loadRacerProfile = async () => {
    const { email } = this.state.racer;
    const emailErrors = validate({ email }, { email: { email: true } });

    let racerData: RacerType | nil;

    if (email && !emailErrors) {
      racerData = await loadCustomerProfileService.load(email);
    }

    if (!racerData) {
      return;
    }

    this.setState({
      racer: {
        ...this.initialState.racer,
        ...racerToState(racerData),
      },
    });
  };

  afterOnChangeHook = ({ name, value }: { name: string; value: any }) => {
    if (name === FIELDS.email) {
      this.debouncedLoadRacerProfile();
    }
  };

  isSelected = (name: string): boolean => {
    const { distance } = this.props;
    return registrationFieldsTools.isSelected(distance.value.registration_fields || [], name as registrationFields);
  };

  isRequired = (name: string): boolean => {
    const { distance } = this.props;
    return registrationFieldsTools.isRequired(distance.value.registration_fields || [], name as registrationFields);
  };

  render() {
    const { onClose, distance } = this.props;
    const { racer } = this.state;
    const genderOptions = gender.options();
    const squadsOptions = squadsStore.valuesForDropdown();
    const isPricesEnabled = !isEmpty(distance.value?.prices);
    const classesOptions = distance.relationForSelect('classes');
    const disciplineOptions = distance.relationForSelect('disciplines');
    const wavesOptions = distance.relationForSelect('waves');

    return (
      <div className='content main-racer-form'>
        <h1 className='racer-title'>{t.staticAsString('racers.addNew')}</h1>
        <div className='full-name racer-form-element'>
          <Input {...this.defaultInputProps('firstname')} label={t.staticAsString('racers.new.firstName')} required />
          <Input {...this.defaultInputProps('lastname')} label={t.staticAsString('racers.new.lastName')} required />
        </div>
        <div className='racer-form-element'>
          <Input {...this.defaultInputProps('email')} label={t.staticAsString('racers.new.email')} required />
        </div>
        <Show if={isPricesEnabled}>
          <div className='racer-form-element checkbox-wrap'>
            <Checkbox {...this.defaultInputProps('payment_required')} label={t.staticAsString('racers.new.payment_required')} />
            <Tooltip
              title={t.staticAsString('racers.new.paymentTooltip')}
              placement='bottom-start'
              classes={{
                tooltip: 'checkbox-tooltip',
                popper: 'checkbox-popper',
              }}
            >
              <span className='tooltip-icon'>
                <HelpOutlineIcon />
              </span>
            </Tooltip>
          </div>
        </Show>
        <Collapse in={!racer.payment_required}>
          <div className='racer-form-element'>
            <Input {...this.defaultInputProps('bib_number')} label={t.staticAsString('racers.new.bib')} />
          </div>
        </Collapse>
        <Show if={classesOptions.length > 0}>
          <div className='racer-form-element'>
            <AutocompleteSelect
              {...this.defaultInputProps('class_id')}
              required
              options={classesOptions}
              label={t.staticAsString('racers.new.class')}
              onChange={this.onChangeForm}
              sx={{ marginBottom: '20px' }}
            />
          </div>
        </Show>
        <Show if={wavesOptions.length > 0}>
          <div className='racer-form-element'>
            <SelectField
              {...this.defaultInputProps('wave_id')}
              options={wavesOptions}
              label={t.staticAsString('racers.new.waves')}
              variant={'standard'}
            />
          </div>
        </Show>
        <Show if={disciplineOptions.length > 0}>
          <div className='racer-form-element'>
            <AutocompleteSelect
              {...this.defaultInputProps('discipline_id')}
              options={disciplineOptions}
              label={t.staticAsString('racers.new.discipline_id')}
              onChange={this.onChangeForm}
              sx={{ marginBottom: '20px' }}
            />
          </div>
        </Show>
        <Show if={this.isSelected('country_id')}>
          <div className='racer-form-element'>
            <AutocompleteSelect
              {...this.inputProps('country_id')}
              options={countriesStore.valuesForCountryDropdown()}
              label={t.staticAsString('racers.new.country')}
              onChange={this.onChangeForm}
              sx={{ marginBottom: '20px' }}
            />
          </div>
        </Show>
        <div className='racer-form-element'>
          <SelectField
            {...this.defaultInputProps('gender')}
            options={genderOptions}
            label={t.staticAsString('racers.new.gender')}
            required
            variant={'standard'}
          />
        </div>
        <Show if={this.isSelected('birthday')}>
          <div className='racer-form-element'>
            <Date {...this.inputProps('birthday')} label={t.staticAsString('racers.new.birthday')} />
          </div>
        </Show>
        <Show if={this.isSelected('nationality_id')}>
          <div className='racer-form-element'>
            <AutocompleteSelect
              {...this.inputProps('nationality_id')}
              options={countriesStore.valuesForNationalityDropdown()}
              label={t.staticAsString('racers.new.nationality_id')}
              onChange={this.onChangeForm}
              sx={{ marginBottom: '20px' }}
            />
          </div>
        </Show>
        <Show if={this.isSelected('telephone')}>
          <div className='racer-form-element z-index-3'>
            <PhoneInput {...this.inputProps('telephone')} fullWidth onChange={this.onChangePhone} />
          </div>
        </Show>
        <Show if={this.isSelected('address')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('address')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('post_code')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('post_code')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('city')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('city')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('union')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('union')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('squad_id')}>
          <div className='racer-form-element'>
            <AutocompleteSelectInfinityLoad
              {...this.inputProps('squad_id')}
              view={{
                required: true,
                variant: 'standard',
                reservePlaceForHelperText: true,
              }}
              additional={{
                options: squadsOptions,
              }}
              label={t.staticAsString('racers.new.squads')}
              onChange={this.onChangeForm}
              hasMore={squadsService.store.hasMore}
              loadMore={() => {
                squadsService.loadDistanceSquads(distance.value.id);
              }}
              onSearch={(text: string) => {
                squadsService.store.clearAll();
                squadsService.store.setFilters(
                  {
                    search: `name:${text}`,
                    searchFields: `name:like`,
                  },
                  1,
                );
                squadsService.loadDistanceSquads(distance.value.id);
              }}
            />
          </div>
        </Show>
        <Show if={this.isSelected('emergency_name')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('emergency_name')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('emergency_phone')}>
          <div className='racer-form-element z-index-3'>
            <PhoneInput {...this.inputProps('emergency_phone')} fullWidth onChange={this.onChangePhone} />
          </div>
        </Show>
        <Show if={this.isSelected('shoe_size')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('shoe_size')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('shirt_size')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('shirt_size')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('company')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('company')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <Show if={this.isSelected('external_swimrun_id')}>
          <div className='racer-form-element'>
            <TextField {...this.inputProps('external_swimrun_id')} view={{ variant: 'standard', fullWidth: true }} />
          </div>
        </Show>
        <div className='btn-group'>
          <Button className='cancel' onClick={() => onClose()}>
            {t.staticAsString('racers.new.cancel')}
          </Button>
          <Button className='submit' onClick={(e) => this.onSubmit(e)}>
            {t.staticAsString('racers.new.submit')}
          </Button>
        </div>
      </div>
    );
  }
}

export const RacerForm = RacerFormImpl as unknown as React.ComponentType<OriginalProps>;

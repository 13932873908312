import React from 'react';
import shortid from 'shortid';

import { ROUTES } from 'src/constants';

import { NavToolbar } from 'components/NavToolbar';

import { t } from 'utils';

import { sessionStore } from 'stores';

type Props = {
  items: Array<{ label: string; path?: string; key: string }>;
} & HOC.withIntl;

let Breadcrumbs = ({ items }: Props) => {
  const breadCrumbs = [
    {
      key: shortid(),
      label: t.staticAsString(sessionStore.isAdmin ? 'systemUsers.systemUsers' : 'sidebar.raceAssistants'),
      path: ROUTES.systemUsersRoute,
    },
    ...items,
  ];

  return (
    <div className='toolbar'>
      <NavToolbar items={breadCrumbs} />
    </div>
  );
};

export { Breadcrumbs };

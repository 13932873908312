import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { loadMedical } from 'modules/Distances/actions';
import { EditDialog } from 'modules/Distances/containers/EditDialog';
import { medicalAssistants as medicalAssistantsStore } from 'modules/Distances/stores';
import { editModalStore, systemUsersStore } from 'modules/SystemUsers/stores';
import * as React from 'react';

import { t } from 'utils';

import { helperRacesStore } from 'stores';

import { State as OriginalFormState } from '../../../../shared/stateHelpers';
import { Header } from './Header';
import { Item } from './Item';

type Props = {
  formData: OriginalFormState;
  onChange(AnyObject, nestedKey: string, callback?: AnyFunction, isDirty?: boolean, value?: AnyObject, isNeedLiveUpdate?: boolean): void;
  errors: { [K in string]: Array<string> };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

@observer
class MedicalAssistants extends React.Component<Props> {
  state = {
    medicalAssistants: [],
  };
  componentDidMount() {
    loadMedical(helperRacesStore.selected?.organizer_id).then(() => this._addDefaultValues());

    reaction(
      () => medicalAssistantsStore.selectedValues.length > 0,
      (isCreatedMedicalAssistant) => {
        let selected: any = this.props.formData.medical_assistants || [];
        if (isCreatedMedicalAssistant) {
          this.selectMedicalAssistant([...selected, ...medicalAssistantsStore.selectedValues] as MedicalAssistantType[]);
          medicalAssistantsStore.clearSelected();
        }
      },
    );
  }

  onChange = (values: Array<MedicalAssistantType>, isNeedLiveUpdate = true) => {
    const { onChange, liveUpdate } = this.props;

    isNeedLiveUpdate && liveUpdate({ medical_assistants: values });
    onChange(
      {
        medical_assistants: values,
      },
      '',
      () => {},
      true,
      {
        medical_assistants: values,
      },
    );
  };

  onAddNew = (e: React.SyntheticEvent) => {
    systemUsersStore.removeSelectedValue();
    editModalStore.on();
  };

  onRemove = (id: number | string) => {
    const { medical_assistants } = this.props.formData;

    if (!medical_assistants) {
      return;
    }

    const values = medical_assistants.filter((value) => {
      return value.id !== id;
    });

    this.onChange(values);
  };

  _addDefaultValues = () => {
    const { medical_assistants = [] } = this.props.formData;

    // Replacing received selected assistants with actual options objects
    // Autocomplete will select element if only this is same object {id: 5} === {id: 5}
    const values = medical_assistants
      .map((assistant) => medicalAssistantsStore.values.find((value) => value.id === assistant.id))
      .filter((el) => !!el);

    this.onChange(values as any, false);
  };

  _renderDistanceMedicalAssistants = (): React.ReactNode => {
    const { formData, errors } = this.props;
    const { medical_assistants } = formData;

    if (!medical_assistants) {
      return null;
    }

    return medical_assistants.reduce(
      (acc, distanceMedicalAssistant, index) => {
        const separator = <li className='separator' key={`separator-${index}`} />;
        return [
          ...acc,
          <Item key={distanceMedicalAssistant.id} index={index} value={distanceMedicalAssistant} onRemove={this.onRemove} />,
          separator,
        ];
      },
      [<li className='separator' key={'separator-init'} />],
    );
  };

  selectMedicalAssistant = (values: Array<MedicalAssistantType>) => {
    this.onChange(values);
  };

  render() {
    const { formData } = this.props;
    const predefinedRole = { name: 'medical', title: t.staticAsString('systemUsers.form.medical') };
    let selected: any = formData.medical_assistants || [];

    return (
      <div className='distance-medical-assistants-form form-content-wrapper'>
        <EditDialog predefinedRole={predefinedRole} />
        <p className='subtitle'>{t.staticAsString('distances.steps.medicalAssistantsForm.subtitle')}</p>

        <div className='add-new' onClick={this.onAddNew}>
          <AddCircleOutlineIcon className='icon' />
          <p className='label'>{t.staticAsString('distances.steps.medicalAssistantForm.add')}</p>
        </div>
        <Header
          value={selected}
          onChange={this.selectMedicalAssistant}
          options={medicalAssistantsStore.values}
          selectionUpdate={medicalAssistantsStore.selectedValues}
        />
        <p className='list-title'>{t.staticAsString('distances.steps.medicalAssistantsForm.listtitle')}</p>
        <ul className='distance-medical-assistants-list'>{this._renderDistanceMedicalAssistants()}</ul>
        {this.props.controls}
      </div>
    );
  }
}

const normalizeDataForBackend = (values: Array<MedicalAssistantType>): Array<number> => {
  if (!values) {
    return [];
  }
  return values.filter((el) => !el._delete).map((el) => el.id);
};

export { MedicalAssistants, normalizeDataForBackend };

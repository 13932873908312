import axios from 'axios';

import { USERS_URL } from 'src/constants';

import { request, action } from 'utils';

import { LOAD_CUSTOMER_PROFILE } from '../constants';

import { mapCustomerToRacer } from './dataProcessor';

class LoadCustomerProfile {
  @request({ action: LOAD_CUSTOMER_PROFILE })
  loadRequest(email: string = ''): Promise<any> {
    const params = {
      search: `email:${email}`,
      searchFields: 'email:=',
      page: 1,
      limit: 1,
    };

    return axios.get(USERS_URL, {
      params,
    });
  }

  @action({ action: LOAD_CUSTOMER_PROFILE })
  async load(email: string): Promise<nil | RacerType> {
    const [, response] = await this.loadRequest(email);
    return mapCustomerToRacer(response.data.data[0]);
  }
}

const loadCustomerProfileService = new LoadCustomerProfile();
export { LoadCustomerProfile, loadCustomerProfileService };

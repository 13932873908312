import { Done, KeyboardArrowDown, KeyboardArrowUp, WarningRounded } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import cn from 'classnames';
import React, { useState } from 'react';

import { Show } from 'components/Condition';
import { Flag } from 'components/Flag/Flag';

import { t, parseHtml } from 'utils';

import { useTranslationTableStyles, useWrapperStyles } from '../styles';

interface TranslationTableProps {
  title: string;
  data: translationTableShape[];
  onClick: (type: string, path: (string | number)[]) => void;
}

export function TranslationExpansion({ title, data, onClick }: TranslationTableProps) {
  const classes = useTranslationTableStyles();

  const [isExpanded, setIsExpanded] = useState(true);
  const handleSetIsExpanded = () => setIsExpanded(!isExpanded);

  let arrowIcon = <KeyboardArrowUp className={classes.arrowIcon} />;
  if (isExpanded) arrowIcon = <KeyboardArrowDown className={classes.arrowIcon} />;

  const handleClick = (entry: translationTableShape) => () => onClick(entry.type, entry.path);

  if (!data.length) {
    return null;
  }
  return (
    <section className={classes.wrapper}>
      <div onClick={handleSetIsExpanded} className={classes.titlePanel}>
        {title}
        {arrowIcon}
      </div>
      <div className='children'>
        {isExpanded &&
          data
            .filter((entry) => Boolean(entry.title))
            .map((value) => {
              const isCompleted = value.missingCount === 0;
              return (
                <div onClick={handleClick(value)} key={value.title} className={classes.childPanel}>
                  <div className={classes.childTitle}>{parseHtml(value.title)}</div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Show if={value.is_outdated}>
                      <Tooltip
                        title={
                          <>
                            <Typography variant='body2'>{t('translations.outdatedWarning')}</Typography>
                          </>
                        }
                      >
                        <WarningRounded style={{ fill: 'orange', fontSize: 24, marginRight: 10 }} />
                      </Tooltip>
                    </Show>
                    <Tooltip
                      title={
                        <div>
                          {Boolean(value.missing?.length) ? (
                            <>
                              <Typography variant='body2'>{t('translations.missingTranslations')}</Typography>
                              {value.missing?.map((flag) => (
                                <span key={flag} style={{ marginRight: 8 }}>
                                  <Flag locale={flag as availableLocales} />
                                </span>
                              )) ?? ''}
                            </>
                          ) : (
                            <Typography variant='body1'>{t('translations.allTranslated')}</Typography>
                          )}
                        </div>
                      }
                    >
                      <div className={cn(classes.badge, { [classes.badgeSuccess]: isCompleted })}>
                        {isCompleted ? <Done style={{ fill: 'white', fontSize: 14 }} /> : value.missingCount}
                      </div>
                    </Tooltip>
                  </div>
                </div>
              );
            })}
      </div>
    </section>
  );
}

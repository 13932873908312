import { cloneDeep } from 'lodash';

import { t } from 'utils';

export const mapErrorCopyRacers = (data: AnyObject): RawError => {
  const form = cloneDeep(data);

  let errors: AnyObject = [];

  if (form.source_distance_id) {
    errors.sourceDistance = form.source_distance_id;
  }

  if (form.target_distance_id) {
    errors.targetDistance = form.target_distance_id;
  }

  if (form.start_at || form.end_at) {
    errors.dateRange = [t.staticAsString('supportTaskManager.form.validation.startDateEndDateError')];
  }

  return errors;
};

import { makeAutoObservable } from 'mobx';

class ReportFiltersStore {
  filters: AnyObject = {};
  constructor() {
    makeAutoObservable(this);
  }

  setFilters(filters: AnyObject) {
    this.filters = filters;
  }

  getFilters() {
    return this.filters;
  }
}

const filtersStore = new ReportFiltersStore();
export { filtersStore };

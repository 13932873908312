import { Observer } from 'mobx-react';
import * as React from 'react';

import { t } from 'src/utils';

import { SETTINGS_STEP, SAVE_SYNC_STEP } from '../constants';

import { stepStore } from '../stores';

import { Header } from '../../Shared';

export const HeaderWrapper = () => {
  return (
    <Observer>
      {() => {
        const titles = {
          [SETTINGS_STEP]: t.staticAsString('sync.step.settings'),
          [SAVE_SYNC_STEP]: t.staticAsString('sync.step.save'),
        };

        return (
          <Header title={t.staticAsString('sync.race_results')} stepNumber={1} stepTitle={titles[stepStore.value as keyof typeof titles]} />
        );
      }}
    </Observer>
  );
};

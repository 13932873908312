import { Transaction } from 'modules/Reconciliation/Transactions/types/transaction.type';

import { DummyStore } from 'stores';

import { Age } from '../types/age.type';
import { Countries } from '../types/countries';
import { Coupons } from '../types/coupons.type';
import { Gender } from '../types/gender.type';
import { Participants } from '../types/participants.type';
import { Registrations } from '../types/registrations.type';
import { Turnovers } from '../types/turnover.type';

export namespace DashboardStore {
  export const country = new DummyStore<Countries>();
  export const age = new DummyStore<Age>();
  export const registrations = new DummyStore<Registrations>();
  export const turnovers = new DummyStore<Turnovers>();
  export const participants = new DummyStore<Participants>();
  export const coupons = new DummyStore<Coupons>();
  export const genders = new DummyStore<Gender>();
  export const transactions = new DummyStore<Transaction[]>();
}

import { Typography, Box } from '@mui/material';
import { Observer } from 'mobx-react';
import { reloadDistance } from 'modules/Distances/actions';
import { StepTitle } from 'modules/Races/components/Steps/Shared/StepTitle';
import { formStyles } from 'modules/Races/components/styles';
import qs from 'query-string';
import React, { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { InformationNote } from 'components/InformationNote';
import TranslationStages from 'components/Stepper/Step/TranslationStages';

import { t } from 'utils/t';

import { helperDistancesStore, helperRacesStore } from 'stores';

type Props = {
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

function Translations({ controls }: Props): ReactElement {
  const { selectedLanguages, defaultLocale } = helperRacesStore.languagesData;
  const classes = formStyles();

  const history = useHistory();
  const params = qs.parse(history.location?.search ?? '');
  const shouldReload = Boolean(params['reload']);
  useEffect(() => {
    if (shouldReload) {
      // Hard replace current route forcing Distance to reload.
      // In the process drop the [reload] param to avoid reload cycle.
      window.location.href = history.location?.pathname + '?step=translations';
    }
    return () => {};
  }, []);

  return (
    <>
      <StepTitle step={'translations'} />
      <Box padding={'0 16px'} width={'100%'} maxWidth={475}>
        <InformationNote
          isDissmissible
          title={t.staticAsString('races.steps.languages.note')}
          body={t.staticAsString('distances.steps.missingTranslationsWarn')}
        />
      </Box>
      <div className={classes.wrapperFullWidth}>
        <Observer
          render={() => {
            if (shouldReload)
              return (
                <Typography variant='h5' align='center'>
                  {t.staticAsString('distances.updatingTranslations') + '...'}
                </Typography>
              );
            return (
              <TranslationStages
                data={helperDistancesStore.selected as DataWithTranslations}
                locales={selectedLanguages}
                defaultLocale={defaultLocale}
                reloadData={reloadDistance}
              />
            );
          }}
        ></Observer>
        {controls}
      </div>
    </>
  );
}

export { Translations };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { API_DISTANCES_SYNC, RACE_DISTANCES_URL } from 'src/constants';
import { request, action } from 'src/utils';

import { DISTANCES_REQUEST } from '../constants';

import { distanceToOptionsMap, raceDistancesStoreMap } from '../utils';

import { distancesTypeStore, raceDistancesStore, syncDistancesStore } from '../stores';

class SyncDistances {
  @request({ action: DISTANCES_REQUEST })
  async getDistancesRequest(id: number): Promise<any> {
    const url = generatePath(API_DISTANCES_SYNC, { id });
    return axios.get(url);
  }

  @action({ action: DISTANCES_REQUEST })
  async getDistances(id: number) {
    const [status, response] = await this.getDistancesRequest(id);

    if (status) {
      syncDistancesStore.set(distanceToOptionsMap(response.data.data));
    }
  }
}

class RaceDistances {
  @request({ action: DISTANCES_REQUEST })
  async getDistancesRequest(raceId: number): Promise<any> {
    const url = generatePath(RACE_DISTANCES_URL, { raceId });
    const params = {
      limit: 1000,
    };

    return axios.get(url, { params });
  }

  @action({ action: DISTANCES_REQUEST })
  async getDistances(id: number) {
    const [status, response] = await this.getDistancesRequest(id);

    if (status) {
      raceDistancesStore.set(distanceToOptionsMap(response.data.data));
      distancesTypeStore.set(raceDistancesStoreMap(response.data.data));
    }
  }
}

export const syncDistancesServices = new SyncDistances();
export const raceDistancesService = new RaceDistances();

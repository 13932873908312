import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import RefundDisplay from 'modules/Reconciliation/shared/components/Refund';
import moment from 'moment';

import { BE_DATE_FORMAT } from 'src/constants/time';

import { Show } from 'components/Condition';

import { t } from 'utils/t';

import { formatCurrency } from '../../utils';
import { reportCurrency } from '../../utils/currency.util';

import { ReportStore } from '../../stores/report.store';

import { Transaction } from '../../types/report.type';
import TableWithSummary from '../common/TableWithSummary';
import { cardContentStyles, genericReportStyles } from '../general.styles';

type Props = {};

export default function TransactionsList({}: Props) {
  const styles = genericReportStyles();
  const cardStyles = cardContentStyles();
  const r = ReportStore.report.value!;
  const title = (prefix: string) => t.staticAsString(`reconciliation.report.transactionList.${prefix}` as any);

  return (
    <>
      <Box mb={2}>
        <Box display='flex' alignItems='start' justifyContent='space-between'>
          <Box>
            <Show if={!isEmpty(r.vat_report.vat_statement_summary)}>
              <Box>
                <span className={cardStyles.cardEntryLeft}>{title('statementId')}&nbsp;</span>
                <span className={cardStyles.cardEntryRight}>{r.vat_report.vat_statement_summary.statementID}</span>
              </Box>
              <Box>
                <span className={cardStyles.cardEntryLeft}>{title('clientVatNo')}&nbsp;</span>
                <span className={cardStyles.cardEntryRight}>{r.vat_report.vat_statement_summary.clientVatNo}</span>
              </Box>
              <Box>
                <span className={cardStyles.cardEntryLeft}>{title('event')}&nbsp;</span>
                <span className={cardStyles.cardEntryRight}>{r.vat_report.vat_statement_summary.eventName}</span>
              </Box>
            </Show>

            <Box>
              <span className={cardStyles.cardEntryLeft}>{title('period')}&nbsp;</span>
              <span className={cardStyles.cardEntryRight}>
                {moment(r.vat_report.vat_statement_summary.from).format(BE_DATE_FORMAT)}&nbsp;
                {title('to')}&nbsp;
                {moment(r.vat_report.vat_statement_summary.to).format(BE_DATE_FORMAT)}
              </span>
            </Box>
          </Box>
          <Box>
            <Typography className={styles.reportGenericTitle}>{title('reconciliationOfSales')}</Typography>
            <Typography className={styles.date}>{r.created_at.toString()}</Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <TableWithSummary
          skipSummary
          summaryText='='
          tableName='PaymentOverview'
          columns={[
            {
              title: title('registration'),
              accessor: 'registrationDate',
              fn: (v) => moment(v.registrationDate).format(BE_DATE_FORMAT),
              align: 'left',
            },
            { title: title('firstName'), accessor: 'customerFirstName', align: 'left' },
            { title: title('lastName'), accessor: 'customerLastName', align: 'left' },
            { title: title('address'), accessor: 'customerAddress', align: 'left' },
            { title: title('postal'), accessor: 'customerPostCode', align: 'left' },
            { title: title('city'), accessor: 'customerCity', align: 'left' },
            { title: title('country'), accessor: 'customerCountry', align: 'left' },
            { title: title('transactionId'), accessor: 'transactionID', align: 'left' },
            {
              title: title('total'),
              accessor: 'transactionAmount',
              align: 'center',
              fn: (item: Transaction) => {
                if (item.transactionType === 4) {
                  return (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      {<RefundDisplay value={item.transactionAmount} currency={reportCurrency()!} />}
                    </Box>
                  );
                }

                return formatCurrency(item.transactionAmount);
              },
            },
            {
              title: title('Vat'),
              accessor: 'transactionVat',
              align: 'right',
              fn: (item: Transaction) => formatCurrency(item.transactionVat),
            },
          ]}
          data={r.transactions_list.transactions}
        />
      </Box>
    </>
  );
}

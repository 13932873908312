import GetApp from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { observer } from 'mobx-react';
import React from 'react';

import { CONFIRM_POPUP_TYPES, EXPORT_DATA_SIZES, EXPORT_DATA } from 'src/constants';

import { t } from 'utils';
import { appEnvControl } from 'utils/appEnvironment';

import confirmModalStore from 'stores/confirmationModal';
import locale from 'stores/locale';

import { racersStore } from '../../stores';

type Props = {
  onExportRacers: (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>, type?: any) => any;
};

const ActionsComponent = ({ onExportRacers }: Props) => {
  const hasValues = racersStore.values.length > 0;

  const confirmExport = () => {
    const {
      paginationMeta: { total = EXPORT_DATA.defaultMetaData },
    } = racersStore;

    if (total <= EXPORT_DATA_SIZES[appEnvControl.currentEnvWithStub()]) return onExportRacers();

    confirmModalStore.openModal({
      title: t.staticAsString('shared.exportConfirm.title') as any,
      body: t.staticAsString('shared.exportConfirm.body', {
        maxAmount: EXPORT_DATA_SIZES[appEnvControl.currentEnvWithStub()].toLocaleString(locale.value),
      }) as any,
      type: CONFIRM_POPUP_TYPES.confirm,
      onConfirm: () => onExportRacers(),
    });
  };

  return hasValues ? (
    <div className='buttons'>
      <div className='export-buttons'>
        <ul className='list-buttons'>
          <Button className='button-item' onClick={confirmExport}>
            <Tooltip title={t.staticAsString('racers.actions.tooltip.export') as any} classes={{ tooltip: 'switch-popover' }}>
              <div>
                <div className='button-icon'>
                  <GetApp />
                </div>
                <div className='button-title'>{t.staticAsString('racers.actions.export')}</div>
              </div>
            </Tooltip>
          </Button>
        </ul>
      </div>
    </div>
  ) : null;
};

const Actions = observer(ActionsComponent);

export { Actions };

import { action, observable, makeObservable } from 'mobx';

class DummyStore<T = null> {
  constructor(value: T | null = null) {
    makeObservable(this);
    this.value = value;
    this.defaultValue = value;
  }

  @observable
  value: null | T = null;

  defaultValue: null | T = null;

  @action
  set(value: T) {
    this.value = value;
  }

  @action
  clear() {
    this.value = this.defaultValue;
  }
}

export { DummyStore };

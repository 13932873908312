import { observer } from 'mobx-react';
import * as React from 'react';

import { FilterAutocompleteAsyncLoad } from 'components/Filter/DefaultFilters';

import { RacerRace } from '../models';

import { loadRacesNames, loadAndSelectRelatedDistanceToRace } from '../actions';

import { racersStore, racesNamesFilters, distancesNamesFilters } from '../stores';

type Props = {} & FilterProps;

@observer
class RaceNameComponent extends React.Component<Props> {
  options = () => {
    return racesNamesFilters.filteredValues.map((el) => {
      const racerRace = new RacerRace(el);

      return racerRace.forSelect();
    });
  };

  loadMore = (): void => {
    const { current_page = 0 } = racesNamesFilters.paginationMeta;
    loadRacesNames({ page: current_page + 1 });
  };

  searchFilter = (text: string) => {
    loadRacesNames({
      page: 1,
      search: `name:${text}`,
    });
  };

  render() {
    const { props } = this;
    return (
      <FilterAutocompleteAsyncLoad
        {...props}
        noMoreData={racesNamesFilters.noMoreData}
        options={this.options()}
        loadMore={this.loadMore}
        search={this.searchFilter}
      />
    );
  }
}

export const raceName: FilterListDescriptor = {
  filter: {
    persistance: {
      calcValue: function (name, value) {
        return value;
      },

      prettify: function (name, value) {
        const title = racesNamesFilters.getRaceNameById(+value);

        return title;
      },
    },
    behavior: {
      apply: async (name, value) => {
        racersStore.filterByName('race', value);
        loadAndSelectRelatedDistanceToRace(value);
      },
      delete: async (name) => {
        racersStore.disableFilterByName('race');
        distancesNamesFilters.clearRelativeSelection();
      },
      reset: async (name) => {
        racersStore.disableFilterByName('race');
        distancesNamesFilters.clearRelativeSelection();
      },
    },
  },
  frontend: {
    type: 'custom',
    component: RaceNameComponent,
  },
  sort: {
    default: false,
    reorderOnSearch: true,
    defaultBy: 'ASC',
    calcOrderBy: (name) => 'distances|race_parent_id',
  },
};

import { errorsStore } from 'stores';

import { SUPPORT_TASK_MANAGER_FORM } from '../constants';

import { racesService } from '../services';

export const loadRaces = async () => {
  errorsStore.clear(SUPPORT_TASK_MANAGER_FORM);
  await racesService.getCouponRaces();
};

import { REFUND_JOBS } from 'src/modules/SupportTaskManager/Tasks/constants';

import { t } from 'utils';

import { hostsStore } from 'stores';

import { dictionary, GENERATE_COUPON_JOB, inputParamsToString, outputParamsToString, outputRefundParamsToString } from '../utils';

import { TaskTable as TaskType } from '../types';

export class TaskTable {
  value: TaskType;

  constructor(value: TaskType) {
    this.value = value;
  }

  action(): string {
    return dictionary(this.value.type);
  }

  parameters(): string {
    return inputParamsToString(this.value.input, this.value.type);
  }

  reportedBy(): string {
    return this.value.user.email;
  }

  output(): string {
    const { output, type, id } = this.value;

    if (output) {
      const isAnyRefundType = REFUND_JOBS.includes(type);

      if (type === GENERATE_COUPON_JOB) {
        return `<a href="${output.url}" target="_blank">${t.staticAsString('supportTaskManager.downloadXLSX')}</a>`;
      }

      return isAnyRefundType ? outputRefundParamsToString(output, id) : outputParamsToString(output);
    }

    return '';
  }

  created_at(): string {
    return this.value.created_at;
  }
}

import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

import { helperRaceService } from 'services';

import { helperDistancesStore, helperRacesStore } from 'stores';

import TranslationStages from '../TranslationStages';

const locales: availableLocales[] = ['en', 'sv', 'fi', 'fr', 'no', 'es'];
const defaultLocale: availableLocales = 'en';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  list: {
    flex: 1,
    padding: '2%',
    height: '100%',
    overflow: 'scroll',
  },
  listEl: {
    border: '1px solid black',
    fontSize: 12,
    cursor: 'pointer',
  },
}));

export const AbstractTranslationContainer = () => {
  const classes = useStyles();

  const loadCustomFields = async () => {
    // await helperDistancesService.loadRaceDistance(5572, 2648, 'update');
    //@ts-ignore
    const data = helperDistancesStore?.modelSelected?.value as DataWithTranslations;
    setDistance(data);
    setReloadCb(() => loadCustomFields);
  };

  const loadCheckpoints = async () => {
    // await helperDistancesService.loadRaceDistance(5572, 3152, 'update');
    //@ts-ignore
    const data = helperDistancesStore?.modelSelected?.value as DataWithTranslations;
    setDistance(data);
    setReloadCb(() => loadCheckpoints);
  };

  const loadRace = async () => {
    await helperRaceService.loadResource(5572);

    //@ts-ignore
    const data = helperRacesStore?.modelSelected?.value as DataWithTranslations;
    setDistance(data);
    setReloadCb(() => loadRace);
  };

  const [reloadCb, setReloadCb] = React.useState(() => loadCustomFields);
  const reloadData = () => {
    reloadCb();
  };

  const [distance, setDistance] = React.useState<DataWithTranslations>();

  React.useEffect(() => {
    loadRace();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.list}>
        <Button onClick={loadCustomFields}>Load with custom fields</Button>
        <Button onClick={loadCheckpoints}>Load with checkpoints</Button>
        <Button onClick={loadRace}>Load Race</Button>
        <TranslationStages data={distance} defaultLocale={defaultLocale} locales={locales} reloadData={reloadData} />
      </div>
    </div>
  );
};

import { EXPORT_DATA, EXPORT_DATA_SIZES } from 'src/constants';
import { HTTP_STATUS } from 'src/constants/httpCodes';

import { t, handleExport } from 'utils';
import { appEnvControl } from 'utils/appEnvironment';

import type { Distance } from 'models';

import { exportJobService } from 'services';

import { toastStore } from 'stores';

import { distanceStore } from '../stores';

async function onExportTemplate() {
  const { paginationMeta, modelSelected: distance } = distanceStore;
  const { total = EXPORT_DATA.defaultMetaData } = paginationMeta;

  if (total > EXPORT_DATA.metaDataLimit) {
    toastStore.show(t.staticAsString('shared.loadPopup.tooMuchData'));
    return;
  }

  if (!distance) {
    return;
  }

  const distanceId = distance.value.id;

  const [status, response] = await exportJobService.exportTemplate(distanceId);

  if (response.status === HTTP_STATUS.NO_CONTENT) {
    toastStore.show(t.staticAsString('results.errors.no_racers'));
    return;
  }

  if (!status) {
    return;
  }

  const { id } = response.data.data;
  const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
  let estimatedLoadingTime = total / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second
  if (total > maxTotal) {
    estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
  }

  handleExport({
    id,
    estimatedLoadingTime,
    fileName: fileName(distance),
    onRetry: () => onExportTemplate(),
  });
}

function fileName(distance: Distance) {
  const name = distance.value.name;
  const length = `${distance.raceLength()}km`;

  return `Template - ${name} - ${length}.xlsx`;
}

export { onExportTemplate };

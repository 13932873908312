import classNames from 'classnames';
import * as React from 'react';
import shortid from 'shortid';

import { SelectWithSearch } from 'components/Fields/SelectWithSearch';

type Props = {
  autoFocus?: boolean;
  options: Array<SelectOption>;
  className?: string;
  onMenuOpen?: Function;
  onMenuClose?: Function;
} & FilterProps;

type State = {
  value: any;
  menuSearchOpen: boolean;
};

class SelectFilterWithSearch extends React.Component<Props, State> {
  id: string;

  constructor(props: Props) {
    super(props);
    this.id = shortid();
    this.state = {
      value: props.value,
      menuSearchOpen: false,
    };
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    this.setState({
      value,
    });
  };

  applyFilter = () => {
    const { name } = this.props;
    const { value } = this.state;
    this.props.apply({ name, value });
  };

  clear = () => {
    this.setState({
      value: null,
    });
  };

  render() {
    const { name, options, label, onMenuOpen, onMenuClose } = this.props;
    const { value, menuSearchOpen } = this.state;
    const valueForSelect = !value && value !== 0 ? '' : value;
    return (
      <div className='filter-container'>
        <div className='form-group'>
          <SelectWithSearch
            id={this.id}
            label={label}
            value={valueForSelect}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            errors={[]}
            name={name}
            onChange={this.onChange}
            options={options}
            className={classNames({
              open: menuSearchOpen,
            })}
          />
        </div>
        <div className='filter-control'>
          <span className={classNames('clear', { hidden: !value })} onClick={this.clear}>
            Clear
          </span>
          <span className='apply' onClick={this.applyFilter}>
            Apply
          </span>
        </div>
      </div>
    );
  }
}

export { SelectFilterWithSearch };

import { Coupons, RawCouponsJSON } from '../types/coupons.type';

export function mapCoupons(raw: RawCouponsJSON.Data): Coupons {
  return {
    total: raw.correlation.buckets.all.current.coupons_count.value ?? 0,
    trend: {
      current: raw.correlation.buckets.all.current.doc_count,
      previous: raw.correlation.buckets.all.previous.doc_count,
      rate: raw.correlation.buckets.all.rate.value,
    },
    data: raw.coupons_count.histogram.buckets.map((bucket) => {
      return {
        label: bucket.key_as_string.toString(),
        value: bucket.doc_count,
      };
    }),
  };
}

import { publishToast } from '../utils';

import { raceDetailsService } from '../services';

import { raceDetailsStore } from '../stores';

const togglePublishAction = async (newValue: boolean) => {
  const race = raceDetailsStore.raceDetails?.value;

  if (!race) {
    return;
  }

  const params = {
    published: newValue,
  };

  raceDetailsStore.partialRaceUpdate(params);

  let [status] = await raceDetailsService.togglePublish();

  // correct server response, show success message
  publishToast(status, newValue);

  if (status) {
    return;
  }

  // bad server response, error message was shown before, roll back state
  const previousValue = !newValue;

  raceDetailsStore.partialRaceUpdate({ published: previousValue });
};

export const unPublishAction = () => {
  togglePublishAction(false);
};

export const publishAction = () => {
  togglePublishAction(true);
};

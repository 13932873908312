import TableBody from '@mui/material/TableBody';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Spinner } from 'components/Spinner';
import { Table as MaterialTable } from 'components/Table';

import { t } from 'utils';

import { Result } from 'models';

import { progressStore } from 'stores';

import { LOAD_RESULTS } from '../../constants';

import { ResultsContext } from '../../context';
import { THead } from '../THead';
import { Row } from './Row';

type Props = {};

@observer
class Table extends React.Component<Props> {
  static contextType = ResultsContext;
  declare context: React.ContextType<typeof ResultsContext>;

  distanceType = (): string => {
    const distanceModel = this.context.results.stores.distanceStore.modelSelected;

    if (!distanceModel) {
      return '';
    }

    return distanceModel.value.type;
  };

  render() {
    const store = this.context.results[this.distanceType()];
    const values: Array<Result> = store && store.store.modelValues;

    if (!values.length) {
      return (
        <div className='racer-no-content'>
          <div className='not-racers'>{t.staticAsString('results.noData')}</div>
        </div>
      );
    }
    return (
      <div className='component-list racers-list results-table'>
        <MaterialTable>
          <THead />
          {!progressStore.isLoading(LOAD_RESULTS) && (
            <TableBody>
              {values.map<React.ReactNode>((el) => (
                <Row item={el} key={el.value.id} />
              ))}
            </TableBody>
          )}
        </MaterialTable>
        {progressStore.isLoading(LOAD_RESULTS) && <Spinner type={Spinner.types.default} />}
      </div>
    );
  }
}

export { Table };

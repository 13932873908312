import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { REFUND_URL } from 'src/constants';

import { action, request, t } from 'utils';

import { errorsStore, toastStore } from 'stores';

import { SEND_REFUND, SUPPORT_TASK_MANAGER_FORM } from '../constants';

import { mapErrorRefund } from '../utils';

import { closeForm, mountIndex, unmountIndex } from '../actions';

class RefundDistanceService {
  @request({ action: SEND_REFUND })
  async sendRefundRequest(params: AnyObject): Promise<any> {
    const path = generatePath(REFUND_URL);
    return axios.post(path, params);
  }

  @action({ action: SEND_REFUND })
  async sendRefund(params: AnyObject): Promise<any> {
    const [status, response] = await this.sendRefundRequest(params);
    if (status) {
      closeForm();
      unmountIndex();
      mountIndex();
      toastStore.show(t.staticAsString('supportTaskManager.responseCreditDistanceOk'));
    }
    if (response.data.errors) {
      errorsStore.add(SUPPORT_TASK_MANAGER_FORM, mapErrorRefund(response.data.errors));
    }
  }
}

export const refundDistanceService = new RefundDistanceService();

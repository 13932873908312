import { progressStore } from 'src/stores';

import { EQ_MAPPING_INIT } from '../../constants';

import { syncClassesService, raceClassesService, syncWavesService, raceWavesService } from '../../services';
import { raceCustomFieldsService } from '../../services/customFields';

import { raceDistancesService, syncDistancesServices, syncStore, syncDistancesStore, raceDistancesStore } from '../../../Shared';

export const mountSync = async () => {
  progressStore.log(EQ_MAPPING_INIT, 'progress');

  if (!syncStore.value?.id) {
    progressStore.log(EQ_MAPPING_INIT, 'completed');
    return;
  }

  await raceDistancesService.getDistances(syncStore.value.race_id);
  await syncDistancesServices.getDistances(syncStore.value.id);

  await Promise.all([
    syncClassesService.getClasses(syncStore.value.id),
    syncWavesService.getWaves(syncStore.value.id),
    raceClassesService.getClasses(syncStore.value.race_id),
    raceWavesService.getWaves(syncStore.value.race_id),
    raceCustomFieldsService.getCustomFields(syncStore.value.race_id),
  ]);

  progressStore.log(EQ_MAPPING_INIT, 'completed');
};

export const unmountSync = () => {
  syncDistancesStore.clear();
  raceDistancesStore.clear();
};

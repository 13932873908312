import { computed, makeObservable } from 'mobx';

import { Loadable } from './loadable';

class Currency extends Loadable<CurrencyType, FiltersType> {
  declare filters: FiltersType;

  defaultCurrencyPattern = /sek/i;

  constructor() {
    super();

    makeObservable(this);
    this.filters = {
      orderBy: 'iso_code',
      sortedBy: 'desc',
      searchJoin: 'AND',
    };
  }

  currencyById(id: number) {
    return this.values.find((currency) => currency.id === id);
  }

  currencyByLabel(label: string) {
    return this.values.find((currency) => currency.iso_code === label);
  }

  @computed
  get valuesForSelect(): Array<SelectOption> {
    return this.values
      .map((value) => ({
        key: value.id,
        value: value.id,
        label: value.iso_code,
        default: this.defaultCurrencyPattern.test(value.iso_code),
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }
}

export { Currency };
export default new Currency();

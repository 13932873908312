import Dialog from '@mui/material/Dialog';
import withStyles from '@mui/styles/withStyles';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Loading } from 'components/Loading';

import { t } from 'utils';

import { SUPPORT_TASK_MANAGER_COPY_FORM_INIT } from '../../constants';

import { closeForm } from '../../actions';

import { modalStore } from '../../stores';

import { TitleModal, Fields, ModalBottom } from '.';

type Props = {};

const DialogStyled = withStyles({
  paper: {
    width: 770,
    height: 515,
  },
})(Dialog);

@observer
export class CopyRacersFormModal extends React.Component<Props> {
  render() {
    const open: boolean = modalStore.value?.open || false;

    const getModalTitle = (): string => {
      return t.staticAsString('supportTaskManager.form.title');
    };

    return (
      <DialogStyled open={open} onClose={closeForm} maxWidth='md'>
        <Loading action={SUPPORT_TASK_MANAGER_COPY_FORM_INIT} />
        <TitleModal title={getModalTitle()} />
        <Fields />
        <ModalBottom />
      </DialogStyled>
    );
  }
}

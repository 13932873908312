import { cloneDeep } from 'lodash';

import { checkPointStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { CheckPoint, Coordinate } from 'src/modules/Distances/components/shared/Steps/GPX/types';

export const setAndReturnCheckPointCoordinates = (coordinate: Coordinate) => {
  const checkPoints = cloneDeep(checkPointStore.value) as Array<CheckPoint>;
  const activeCheckPoint = checkPoints.filter((item) => item.type === 'active');

  if (activeCheckPoint && activeCheckPoint.length > 0) {
    activeCheckPoint[0].coordinate.lat = coordinate.lat;
    activeCheckPoint[0].coordinate.lng = coordinate.lng;
    activeCheckPoint[0].type = 'ready';
    activeCheckPoint[0].showCloseButton = true;

    checkPointStore.set(checkPoints);

    return activeCheckPoint[0];
  }

  return null;
};

import { helperOrganizersLoadService, loadCurrencyService } from 'services';

import { form, progressStore } from 'stores';

import { TransactionConstants } from '../constants/transaction.constants';

import { TransactionsService } from '../services/transactions.service';

import { TransactionsStore } from '../stores/transactions.store';

import { TxFilters } from '../types/filters.type';
import { Transaction } from '../types/transaction.type';
import { TxResponse } from '../types/tx-response.type';

export namespace TransactionsActions {
  export async function init() {
    await Promise.all([loadCurrencyService.loadResources(), helperOrganizersLoadService.loadResources()]);
    // progressStore.log(TransactionConstants.TransactionForm, 'completed');
  }

  export async function exportTransactions(type: string) {
    await TransactionsService.exportTransactions(type);
  }

  export async function load(filters: TxFilters) {
    const data = await TransactionsService.load<TxResponse>(filters);
    TransactionsStore.txs.set(data);
  }
  export async function unmount() {
    TransactionsStore.txs.value = null;
  }
}

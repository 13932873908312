import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { COPY_RACERS_DELETE_TASK_URL, COPY_RACERS_URL, ORGANIZERS_UTILS_URL } from 'src/constants';

import { action, request, t } from 'utils';

import { errorsStore, toastStore } from 'stores';

import { DELETE_TASK_COPY_RACERS, LOAD_COPY_RACERS, SEND_COPY_RACERS, SUPPORT_TASK_MANAGER_COPY_FORM, LOAD_ORGANIZERS } from '../constants';

import { mapErrorCopyRacers } from '../utils';

import { closeForm, loadCopyRacers as loadRacersAction, mountIndex, unmountIndex } from '../actions';

import { copyRacersStore } from '../stores';

class CopyRacersService {
  @request({ action: SEND_COPY_RACERS })
  private async sendCopyRacersRequest(params: AnyObject): Promise<any> {
    const path = generatePath(COPY_RACERS_URL);
    return axios.post(path, params);
  }

  @request({ action: LOAD_COPY_RACERS })
  private loadRacersRequest(params: nil | FiltersType): any {
    const path = generatePath(COPY_RACERS_URL);
    return axios.get(path, { params });
  }

  @request({ action: DELETE_TASK_COPY_RACERS })
  private deleteTaskRequest(id: number): any {
    const path = generatePath(COPY_RACERS_DELETE_TASK_URL, { id });
    return axios.delete(path);
  }

  @request({ action: LOAD_ORGANIZERS })
  private loadOrganizersRequest(params: nil | FiltersType): any {
    const path = generatePath(ORGANIZERS_UTILS_URL);
    return axios.get(path, { params });
  }

  @action({ action: SEND_COPY_RACERS })
  async sendCopyRacers(params: AnyObject): Promise<any> {
    const [status, response] = await this.sendCopyRacersRequest(params);

    if (status) {
      closeForm();
      unmountIndex();
      mountIndex();
      toastStore.show(t.staticAsString('supportTaskManager.responseCopyRacersOk'));
    } else {
      errorsStore.add(SUPPORT_TASK_MANAGER_COPY_FORM, mapErrorCopyRacers(response.data.errors));
    }
  }

  @action({ action: DELETE_TASK_COPY_RACERS })
  async deleteTask(id: number): Promise<any> {
    const [status] = await this.deleteTaskRequest(id);

    if (status) {
      unmountIndex();
      mountIndex();
      toastStore.show(t.staticAsString('supportTaskManager.list.table.taskWasDeleted', { id }));
    } else {
      toastStore.show('Error !!!');
    }
  }

  @action({ action: LOAD_COPY_RACERS })
  async loadCopyRacers(params: nil | FiltersType) {
    const [status, response] = await this.loadRacersRequest(params);

    return [status, response.data];
  }

  @action({ action: LOAD_COPY_RACERS })
  async load(params?: { limit?: number; page?: number }): Promise<void> {
    await loadRacersAction(params);
  }

  @action({ action: LOAD_ORGANIZERS })
  async loadOrganizers(params: nil | FiltersType) {
    const [status, response] = await this.loadOrganizersRequest(params);

    return [status, response.data];
  }

  get store() {
    return copyRacersStore;
  }
}

export const copyRacersService = new CopyRacersService();

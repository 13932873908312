import { localeStore } from 'stores';

type CheckpointType = {
  name: string;
  name_en: string;
};

class Checkpoint {
  value: CheckpointType;

  constructor(value: CheckpointType) {
    this.value = value;
  }

  get name(): string {
    return this.value.name;
  }
}

export { Checkpoint };

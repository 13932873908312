import {
  TextfieldImg,
  TextfieldImg2x,
  TextfieldImg3x,
  DropdownImg,
  DropdownImg2x,
  DropdownImg3x,
  CheckboxImg,
  CheckboxImg2x,
  CheckboxImg3x,
  RadioImg,
  RadioImg2x,
  RadioImg3x,
  DateImg,
  DateImg2x,
  DateImg3x,
  TimeImg,
  TimeImg2x,
  TimeImg3x,
  ExtraImg,
  ExtraImg2x,
  ExtraImg3x,
} from 'img/custom_fields';

export const CUSTOM_FIELD_TYPES_MATCHES: AnyObject = {
  textfield: {
    icon: 'text-format',
    images: [TextfieldImg, TextfieldImg2x, TextfieldImg3x],
  },
  drop_down: {
    icon: 'drop-down',
    images: [DropdownImg, DropdownImg2x, DropdownImg3x],
  },
  checkbox: {
    icon: 'check_box',
    images: [CheckboxImg, CheckboxImg2x, CheckboxImg3x],
  },
  radio: {
    icon: 'radiobutton_icon',
    images: [RadioImg, RadioImg2x, RadioImg3x],
  },
  date: {
    icon: 'calendar_helper',
    images: [DateImg, DateImg2x, DateImg3x],
  },
  time: {
    icon: 'access-time',
    images: [TimeImg, TimeImg2x, TimeImg3x],
  },
  extra: {
    icon: 'extra',
    images: [ExtraImg, ExtraImg2x, ExtraImg3x],
  },
};

import { cloneDeep } from 'lodash';

import { GpxRoute } from 'src/modules/Distances/components/shared/Steps/GPX/types';

export const mapFromRoute = (d: AnyObject): GpxRoute => {
  const data: AnyObject = cloneDeep(d) as AnyObject;

  return {
    distanceId: data.distance_id,
    coordinates: data.route.coordinates.map(([lng, lat]) => ({ lng, lat })),
  };
};

import axios from 'axios';
import { isEmpty } from 'lodash';
import { generatePath } from 'react-router-dom';

import { RACE_INTEGRATIONS } from 'src/constants';

import { action, request } from 'utils';

import { GET_INTEGRATIONS } from '../constants';

import { integrationExistStore } from '../stores';

class IntegrationsService {
  @request({ action: GET_INTEGRATIONS })
  async getIntegrationsRequest(id: number, params: { page?: number; limit?: number }): Promise<any> {
    const url = generatePath(RACE_INTEGRATIONS, { id });
    return axios.get(url, { params });
  }

  @action({ action: GET_INTEGRATIONS })
  async getIntegrations(raceId: number): Promise<any> {
    const params = {
      page: 1,
      limit: 1,
    };

    const [status, response] = await this.getIntegrationsRequest(raceId, params);

    if (status) {
      const integrationExist = !isEmpty(response.data.data);
      integrationExistStore.set(integrationExist);
    }
  }
}

export const integrationsService = new IntegrationsService();

import { COUPON_CODE_TYPES, RADIO_AMOUNT_VALUE, RADIO_PERCENTAGE_VALUE } from 'modules/CouponsForm/constants';
import { SmarLinkCouponType, SmartLinkBE, SmartLinkCouponUsageType } from 'modules/SmartLinks/shared/types';
import moment from 'moment';

import { BACKEND_DATETIME_FORMATE } from 'src/constants';

import { t } from 'utils/t';

import { distancesService, racesService } from '../services';

import { DistanceOption, WaveOptions } from '../stores';

export const mapToOptionsRaces = (data: AnyObject[], name: string = 'name'): SelectOption[] => {
  return data.map((item) => ({
    key: item.id,
    value: item.id,
    label: item[name],
    organizer_id: item.organizer_id,
  }));
};

export const mapToOptionsWaves = (data: AnyObject[], name: string = 'name'): WaveOptions[] => {
  return data
    .filter((wave) => wave.is_visible)
    .map((item) => {
      const count = item.max_members - (item.srl_slots_count - item.srl_slots_used_count) - item.racers_count;
      return {
        key: item.id,
        value: item.id,
        label: item.max_members ? `${item[name]} (${count} ${t.staticAsString('shared.slots')})` : item[name],
        slots: item.max_members && item.max_members - item.racers_count,
      };
    });
};

export const mapToDistanceOption = (data: AnyObject[]): DistanceOption[] => {
  return data.map((item) => ({
    key: item.id,
    value: item.id,
    label: item.name,
    registration_starts_at: item.registration_starts_at,
    registration_ends_at: item.registration_ends_at,
  }));
};

const getDiscountSumTypetoBE = (discountType: Array<AnyObject>) => {
  const typeArr = discountType.map((type) => type.value);
  if (typeArr.includes(COUPON_CODE_TYPES[0].value) && !typeArr.includes(COUPON_CODE_TYPES[1].value)) {
    return SmartLinkCouponUsageType.DistanceOnly;
  }
  if (!typeArr.includes(COUPON_CODE_TYPES[0].value) && typeArr.includes(COUPON_CODE_TYPES[1].value)) {
    return SmartLinkCouponUsageType.CustomFieldsOnly;
  }
  return SmartLinkCouponUsageType.DistanceAndCustomFields;
};

export const getDiscountSumTypeToFE = (discountType: SmartLinkCouponUsageType) => {
  if (discountType === SmartLinkCouponUsageType.DistanceOnly) {
    return [COUPON_CODE_TYPES[0]];
  }
  if (discountType === SmartLinkCouponUsageType.CustomFieldsOnly) {
    return [COUPON_CODE_TYPES[1]];
  }
  return COUPON_CODE_TYPES;
};

export const mapFormToBE = (data: AnyObject): AnyObject => {
  const max_usages = Number(data.max_usages);
  const distance_id = data.distance_id.value;
  const organizer_id = data.race_id.organizer_id;
  const race_id = data.race_id.value;
  const date_from = data.date_from && data.date_from.startOf('day').format(BACKEND_DATETIME_FORMATE);
  const date_to = data.date_to && data.date_to.endOf('day').format(BACKEND_DATETIME_FORMATE);
  const wave_id = data.wave_id?.value;
  const discount_sum_type = data.discount_sum_type && getDiscountSumTypetoBE(data.discount_sum_type);
  const discount_sum = data.discount_sum && Number(data.discount_sum);
  const discount_type =
    data.discount_type &&
    (data.discount_type === RADIO_AMOUNT_VALUE ? SmarLinkCouponType.DiscountAmontType : SmarLinkCouponType.DiscountPercentageType);
  delete data.dates;
  if (data.discount === false) {
    delete data.discount;
  }

  return {
    ...data,
    max_usages,
    distance_id,
    organizer_id,
    race_id,
    date_from,
    date_to,
    wave_id,
    discount_sum,
    discount_sum_type,
    discount_type,
  };
};

export const mapBEToForm = async (data: SmartLinkBE): Promise<any> => {
  const race_id = await racesService.getRace(data.race_id);
  const distance_id = await distancesService.getDistance(data.distance_id);
  const date_from = moment(data.date_from);
  const date_to = moment(data.date_to);
  const wave_id = data.wave && mapToOptionsWaves([data.wave])[0];
  const discount_type =
    data.discount_type && (data.discount_type === SmarLinkCouponType.DiscountAmontType ? RADIO_AMOUNT_VALUE : RADIO_PERCENTAGE_VALUE);
  const discount_sum_type = (data.discount_sum_type && getDiscountSumTypeToFE(data.discount_sum_type)) || [];

  return {
    name: data.name,
    distance_id,
    race_id,
    date_from,
    date_to,
    wave_id,
    max_usages: data.max_usages,
    discount: Boolean(data.discount),
    discount_type,
    discount_sum: data.discount_sum,
    discount_sum_type,
  };
};

import * as React from 'react';

import { formatter } from 'utils';

import { Item } from './Item';

type Props = {
  values: Array<PriceType>;
  currency: string;
};

function Prices({ values, currency }: Props) {
  return (
    <div className='distance-prices'>
      <ul className='prices-list'>
        {values.map((el: AnyObject) => {
          const { date_from, date_to, value, id } = el;
          return <Item {...{ id, date_from, date_to }} value={formatter.integer(value)} currency={currency} key={`price-${id}`} />;
        })}
      </ul>
    </div>
  );
}

export { Prices };

import { Box } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

import { Svg } from 'src/components/Icon';

type Props = React.PropsWithChildren<{
  onDelete: AnyFunction;
  item?: any;
  bottomComponent?: React.ReactNode;
  syncBibs?: React.ReactNode;
}>;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 24px;
  gap: 16px;
  align-items: center;
`;

const StyledSvg = styled(Svg)`
  cursor: pointer;
  margin-top: 18px;
  align-self: flex-start;
`;

export const SyncItem: React.FC<Props> = (props) => {
  const { children, onDelete, item, bottomComponent, syncBibs, ...rest } = props;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Wrapper>
        {children}
        <StyledSvg name='Trash' size={24} onClick={onDelete} />
      </Wrapper>
      {syncBibs}
      {bottomComponent}
    </Box>
  );
};

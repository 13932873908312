import * as React from 'react';

import { t } from 'utils';

export const SYNC_VALUES = () => [
  {
    label: t.staticAsString('integrations.form.racers_value'),
    value: 'is_sync_start_list',
    key: 'is_sync_start_list',
  },
  {
    label: t.staticAsString('integrations.form.results_value'),
    value: 'is_sync_results',
    key: 'is_sync_results',
  },
];

export const DEFAULT_FORM_VALUE = {
  is_active: false,
  send_start_list_results_objects: false,
};

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { DISTANCE_TEAMS_URL } from 'src/constants';

import { request, action, SearchSerializer } from 'utils';

import { LOAD_MEMBERS_TEAM, LOAD_MEMBERS_TEAMS } from '../constants';

import { MembersTeamStore } from '../stores';

class MembersTeam {
  store: MembersTeamStore;

  constructor(store: MembersTeamStore) {
    this.store = store;
  }

  @request({ action: LOAD_MEMBERS_TEAMS })
  teamsForMembersRequest(id: number, params: FiltersType): Promise<any> {
    const url = generatePath(DISTANCE_TEAMS_URL, { id });
    return axios.get(url, { params });
  }

  // Update single team in store.values
  @action({ action: LOAD_MEMBERS_TEAMS })
  async updateFewTeamsForMembers(...ids: Array<nil | number>) {
    const { distanceId } = this.store;

    if (!distanceId) {
      return;
    }

    const storeParams = this.store.params;
    const searchModel = new SearchSerializer({ id: ids }, { id: 'in' }, []);

    const params = {
      ...storeParams,
      ...(searchModel.params() || {}),
      searchJoin: 'AND',
    };

    const [status, response] = await this.teamsForMembersRequest(distanceId, params);

    if (status) {
      const data = response.data.data;
      this.store.updateValues(...data);
    }
  }

  // Update all teams in store.values
  @action({ action: LOAD_MEMBERS_TEAM })
  async loadTeamsForMembers(newDistanceId?: number) {
    const oldDistanceId = this.store.distanceId;
    const { params, page } = this.store;

    if (!!newDistanceId) {
      this.store.updateCurrentDistance(newDistanceId);
    }

    const distanceId = newDistanceId || oldDistanceId;

    if (!distanceId) {
      return;
    }

    const [status, response] = await this.teamsForMembersRequest(distanceId, { ...params, page });

    if (status) {
      const data = response.data.data;

      const paginationMeta = response.data.meta.pagination;
      this.store.setHasMore(paginationMeta.total_pages > page);
      this.store.appendValues(data, paginationMeta.current_page, params);
    }
  }
}

export { MembersTeam, MembersTeam as MembersTeamService };

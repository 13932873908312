import * as React from 'react';

import { ApproveStatuses } from './ApproveStatuses';
import { IdRange } from './IdRange';
import { OrganizerEmail } from './OrganizerEmail';
import { OrganizerName } from './OrganizerName';
import { Prices } from './Prices';
import { RaceDate } from './RaceDate';
import { RaceLocation } from './RaceLocation';
import { RaceName } from './RaceName';
import { SportType } from './SportType';
import { UpdatedAt } from './UpdatedAt';

export interface FilterProps {
  name: string;
  value: FilterType;
  values: FiltersType;
  label: string;
  applyFilter: (
    arg0: {
      [K in string]: string;
    },
  ) => void;
  updateSearchHandlers: (
    arg0: {
      [K in string]: string;
    },
  ) => void;
}

const filtersComponents: AnyObject = {
  sport_id: SportType,
  approve_status: ApproveStatuses,
  id: IdRange,
  name: RaceName,
  race_date: RaceDate,
  race_location: RaceLocation,
  total_price: Prices,
  'organizer.full_name': OrganizerName,
  'organizer.email': OrganizerEmail,
  updated_at: UpdatedAt,
};

const Filter = (props: FilterProps) => {
  const { name } = props;
  const Component = filtersComponents[name];
  return <Component {...props} />;
};

Filter.types = Object.keys(filtersComponents);

export { Filter };

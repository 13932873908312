import moment from 'moment';

import { InputDate, WorkDate } from './templates';
import { inputDate } from './types';
import { validateBeDate } from './utils';

/*
 * Ultimate interface for parsing date, formating and composing for BE
 * all necessary utils for setting proper timezone and date manipulation
 *
 * Timezones
 * time.datetime('2019-01-01 12:00:00').parseFromBe()
 * simply consider string as utc date and converts string to moment with local timezone
 *
 * time.datetime(('2019-01-01 12:00:00').parse().local()
 * Is not working like parseFromBe, becase parse consider date as local timezeon
 *
 * On the contrary code like
 * time.datetime(('2019-01-01 12:00:00').parse().setUtc().compose()
 * working the same way as
 * time.datetime(('2019-01-01 12:00:00').parse().composeForBe()
 *
 * USAGE:
 * Local timezone +2H
 *
 * time.datetime('2019-01-01 12:00:00').parseFromBe().formatAsString('time')         | 14:00:00
 * time.date('2019-01-01 12:00:00').parseFromBe()                                    | Exception, format is not matching
 * time.datetimems('2019-01-01 12:00:00.999').parse().compose().val                  | '2019-01-01 12:00:00.999'
 * time.datetimems('2019-01-01 12:00:00.999').parse().compose('date').val            | '2019-01-01'
 * time.datetime(moment("2020-02-10 14:00:00")).parse().composeForBe().val           | '2020-02-10 12:00:00'
 *
 * time.workDatetime(moment("2020-02-10 14:00:00")).composeForBe().val               | '2020-02-10 12:00:00'
 * time.workDateTime(moment("2020-02-10 14:00:00")).composeForBe('date').val         | '2020-02-10'
 * time.workDate(moment("2020-02-10 14:00:00")).composeForBe().val                   | '2020-02-10'
 * time.workDatetimems(moment("2020-02-10 14:00:00")).composeForBe().val             | '2020-02-10 12:00:00.000'
 *
 *
 */

function time(value: inputDate, type: BEDateFormat) {
  switch (type) {
    case 'date':
      return date(value);
    case 'datetime':
      return datetime(value);
    case 'datetimems':
      return datetimems(value);
    default:
      return datetime(value);
  }
}

/*
 * to work with string or moment with parsing flow
 */
function date(value: inputDate): InputDate<inputDate> {
  return new InputDate<inputDate>(value, 'date');
}

function datetime(value: inputDate): InputDate<inputDate> {
  return new InputDate<inputDate>(value, 'datetime');
}

function datetimems(value: inputDate): InputDate<inputDate> {
  return new InputDate<inputDate>(value, 'datetimems');
}

// ----------

/*
 * to work with moment directly
 */
function workDate(value: moment.Moment) {
  return new WorkDate(value, 'date');
}

function workDatetime(value: moment.Moment) {
  return new WorkDate(value, 'datetime');
}

function workDatetimems(value: moment.Moment) {
  return new WorkDate(value, 'datetimems');
}

function workTime(value: moment.Moment, type: BEDateFormat) {
  switch (type) {
    case 'date':
      return workDate(value);
    case 'datetime':
      return workDatetime(value);
    case 'datetimems':
      return workDatetimems(value);
    default:
      return workDate(value);
  }
}

time.date = date;
time.datetime = datetime;
time.datetimems = datetimems;
time.workDate = workDate;
time.workDatetime = workDatetime;
time.workDatetimems = workDatetimems;
time.workTime = workTime;
time.isValid = validateBeDate;
time.InputDate = InputDate;
time.WorkDate = WorkDate;

export { time, WorkDate, InputDate };

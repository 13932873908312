import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACER_SPLIT_URL, TEAM_SPLIT_URL, DISTANCE_TYPE } from 'src/constants';

import { action, request } from 'utils';

import { progressStore, errorsStore } from 'stores';

import { CREATE_OR_UPDATE_SPLIT } from '../constants';

import { distanceStore, teamResultsStore, singleResultsStore, resultsStubStore, fieldRegisterStore } from '../stores';

import { storeData } from '../tools';
import { fieldUpdateOptions } from '../types';

class UpdateSplit {
  storeData = storeData.bind(this);

  get store(): ILoadable<ResultType, FiltersType> {
    const distanceType = this.distanceType();

    switch (distanceType) {
      case DISTANCE_TYPE.single:
        return singleResultsStore;
      case DISTANCE_TYPE.team:
        return teamResultsStore;
      default:
        return resultsStubStore;
    }
  }

  distanceType(): nil | distanceType {
    const distanceModel = distanceStore.modelSelected;

    if (!distanceModel) {
      return;
    }

    return distanceModel.value.type;
  }

  @request({ action: CREATE_OR_UPDATE_SPLIT, writeError: false })
  racerRequest(racerId: number, checkpointId: number, data: Object): any {
    const url = generatePath(RACER_SPLIT_URL, { racerId, checkpointId });
    return axios.patch(url, data);
  }

  @request({ action: CREATE_OR_UPDATE_SPLIT, writeError: false })
  teamRequest(teamId: number, checkpointId: number, data: Object): any {
    const url = generatePath(TEAM_SPLIT_URL, { teamId, checkpointId });
    return axios.patch(url, data);
  }

  updateRequest(entity: 'racer' | 'team', entityId: number, checkpointId: number, data: Object): any {
    switch (entity) {
      case 'racer':
        return this.racerRequest(entityId, checkpointId, data);
      case 'team':
        return this.teamRequest(entityId, checkpointId, data);
      default:
        return null;
    }
  }

  // Create split or update split
  @action({ action: CREATE_OR_UPDATE_SPLIT })
  async createOrUpdate(
    entity: 'racer' | 'team',
    entityId: number,
    checkpointId: number,
    value: Object,
    updateOptions: fieldUpdateOptions,
  ): Promise<boolean> {
    const startRequestTimestamp = new Date();
    const { fieldId, storeOptions } = updateOptions;
    progressStore.log(fieldId, 'progress');

    const [status, response] = await this.updateRequest(entity, entityId, checkpointId, value);

    if (status) {
      await this.storeData(response.data.data, storeOptions);
    }

    if (!status) {
      errorsStore.add(fieldId, response.data.errors);
    }

    await progressStore.logWithPromise(fieldId, 'completed', { minRequestTime: 400, requestStartTime: startRequestTimestamp });

    if (!!status) {
      fieldRegisterStore.deactivateField(fieldId);
    }

    return status;
  }
}

export { UpdateSplit };

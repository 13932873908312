import { makeAutoObservable } from 'mobx';

import { t } from 'utils/t';

import { lastYear } from '../utils/date';

import { ChartInterval, ChartRange } from '../types/interval';

class ChartRangePickerStore {
  selectedRange: ChartRange = '12m';
  chartInterval: ChartInterval = 'month';
  from: string;
  to: string;

  constructor() {
    const { from, to } = lastYear();
    this.from = from;
    this.to = to;
    makeAutoObservable(this);
  }

  setRange(range: ChartRange) {
    this.selectedRange = range;
  }

  get humanizedRange() {
    switch (this.selectedRange) {
      case '12m':
        return t.staticAsString('dashboard.interval.year');
      case '6m':
        return t.staticAsString('dashboard.interval.sixMonths');
      case '3m':
        return t.staticAsString('dashboard.interval.threeMonths');
      case '30d':
        return t.staticAsString('dashboard.interval.month');
      case '7d':
        return t.staticAsString('dashboard.interval.week');
      case '24h':
        return t.staticAsString('dashboard.interval.day');
    }
  }

  setInterval(interval: ChartInterval, from: string, to: string) {
    this.chartInterval = interval;
    this.from = from;
    this.to = to;
  }

  reset() {
    const { from, to } = lastYear();
    this.selectedRange = '12m';
    this.chartInterval = 'month';
    this.from = from;
    this.to = to;
  }
}

export const chartRangePickerStore = new ChartRangePickerStore();

import { observer } from 'mobx-react';
import * as React from 'react';

import { CONFIRM_POPUP_TYPES } from 'src/constants';

import { ConfirmationModal } from 'components/ConfirmationModal';

import { t } from 'utils';

import { onConfirmDelete, onCancelDelete } from '../actions';

import { confirmationDeleteStore } from '../stores';

type Props = {};

@observer
export class ConfirmationProvider extends React.Component<Props> {
  render() {
    return (
      <ConfirmationModal
        title={t.staticAsString('integrations.list.deleteTitle')}
        body={t.staticAsString('integrations.list.deleteBody')}
        open={confirmationDeleteStore.value?.open as boolean}
        onClose={onCancelDelete}
        onConfirm={onConfirmDelete}
        type={CONFIRM_POPUP_TYPES.confirm}
      />
    );
  }
}

import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';

import { RACE_IMAGE_ASPECT_RATIO } from 'src/constants';

import ImageIcon from 'img/upload_image.png';

import { AspectRatioImage } from 'components/core';

import { t } from 'utils';

type ViewProps = {
  onChange: React.ChangeEventHandler;
  errors: Array<string>;
  preview: string;
  clearValue: () => void;
  value: Blob | string | null;
  imageSource: string;
  fileName?: string;
  shouldRenderFileName?: boolean;
  disabled?: boolean;
};

type ArgumentsType = { children: React.ReactElement; isPreviewAvailable: boolean };

const Preview = ({ children, isPreviewAvailable }: ArgumentsType): React.ReactElement | null => (isPreviewAvailable ? children : null);
const UploadForm = ({ children, isPreviewAvailable }: ArgumentsType): React.ReactElement | null => (!isPreviewAvailable ? children : null);

let View = ({ onChange, errors, preview, clearValue, imageSource, fileName, shouldRenderFileName, disabled }: ViewProps) => {
  const isPreviewAvailable = !!(preview || imageSource);

  return (
    <div className='icon-field'>
      <Preview isPreviewAvailable={isPreviewAvailable}>
        <label htmlFor='race-icon'>
          <AspectRatioImage src={preview || imageSource} ratio={RACE_IMAGE_ASPECT_RATIO.ratio}>
            {fileName && <span className='file-name'>{fileName}</span>}
            {shouldRenderFileName ? (
              <EditIcon className='edit-icon' />
            ) : (
              <CancelIcon className='cancel-icon' {...(disabled ? {} : { onClick: clearValue })} />
            )}
          </AspectRatioImage>
        </label>
      </Preview>
      <UploadForm isPreviewAvailable={isPreviewAvailable}>
        <label htmlFor='race-icon' className='icon-label'>
          <img className='upload-icon' alt='upload icon' src={ImageIcon} />
          <p className='main-upload-image-title'>
            <span>{t.staticAsString('races.new.upload_title')}</span>
            <span className='sub-title'>{t.staticAsString('races.new.upload_sub_title')}</span>
          </p>
        </label>
      </UploadForm>
      <span className='errors'>{errors}</span>
      <input
        id='race-icon'
        className='icon-input'
        type='file'
        name='icon'
        {...(disabled ? {} : { onChange: onChange })}
        accept='image/*'
        disabled={disabled}
      />
      <span className='file-name-label' />
    </div>
  );
};

export { View };

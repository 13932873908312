import { action, computed, observable, makeObservable, override } from 'mobx';

import { SearchSerializer } from 'utils';

import { Organizer as OrganizerModel } from 'models';

import { Loadable } from './loadable';

class Organizer extends Loadable<OrganizerType, FiltersType> {
  declare filters: FiltersType;

  declare resourceParams: FiltersType;

  @observable
  handlers = {
    id: 'between',
    username: 'like',
    email: 'like',
    full_name: 'like',
    phone: 'like',
    address: 'like',
    city: 'like',
    country: 'like',
    post_code: 'like',
    created_at: 'between',
  };

  static allowBlank: string[] = [];

  constructor() {
    super();

    makeObservable(this);
    this.filters = {
      with: '',
      limit: 30,
      searchJoin: 'AND',
      orderBy: 'id',
      sortedBy: 'DESC',
    };

    this.resourceParams = {
      with: 'paymentDetails',
    };
  }

  @computed
  get modelValues(): Array<OrganizerModel> {
    return this.values.map((race) => new OrganizerModel(race));
  }

  @computed
  get modelSelected(): nil | OrganizerModel {
    if (this.selected) {
      return new OrganizerModel(this.selected);
    } else {
      return null;
    }
  }

  @action
  updateSearch(search: string, searchFields: string): void {
    if (!search) {
      this.filters.search = null as any;
      this.filters.searchFields = null as any;
      delete this.filters.search;
      delete this.filters.searchFields;
    } else {
      this.filters.search = search;
      this.filters.searchFields = searchFields;
    }
  }

  @action
  updateSearchHandlers(
    newHandlers: {
      [K in string]: string;
    },
  ) {
    this.handlers = {
      ...this.handlers,
      ...newHandlers,
    };
  }

  @computed
  get searchDataModel(): SearchSerializer {
    const search = this.filters.search as any;
    return new SearchSerializer(search, this.handlers, Organizer.allowBlank);
  }

  retrieveSearchDataModel(): SearchSerializer {
    return this.searchDataModel.clone();
  }

  @override
  clearAll() {
    super.clearAll();
    // TODO TS, could throw exception
    // please check
    // @ts-ignore
    this.filters = this.defaultFilters;
  }
}

export { Organizer };
export default new Organizer();

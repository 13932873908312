import * as React from 'react';
import { ForwardedRef, forwardRef } from 'react';

interface ListBoxProps extends React.HTMLAttributes<HTMLUListElement> {}

export const ListBox = forwardRef(function ListBoxBase(props: ListBoxProps, ref: ForwardedRef<HTMLUListElement>) {
  const { children, ...rest } = props;

  return (
    <ul {...rest} role='list-box'>
      {children}
    </ul>
  );
});

import { sessionStore } from 'stores';

import { systemUsersService } from '../services';

import { organizersStore } from '../stores';

async function loadOrganizers() {
  if (sessionStore.isOrganizer) {
    return;
  }

  const resourceParams = {
    limit: 5000,
    search: 'roles.name:organizer',
    searchFields: 'roles.name:=',
    searchJoin: 'AND',
    with: 'roles',
  };

  const [status, organizers] = await systemUsersService.loadOrganizers(resourceParams);

  if (!status) {
    return;
  }

  organizersStore.set(organizers);
}

export { loadOrganizers };

import { Dialog, DialogContent, Button, DialogActions, DialogTitle, DialogContentText } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';

import { t } from 'utils';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  errors: [][];
};

const classes = {
  popup: {
    title: ['popup-title'],
    body: ['popup-body'],
    text: ['popup-text'],
  },
};

export const Modal = ({ onClose, isOpen, errors }: Props) => {
  return (
    <Dialog open={isOpen} className='results-import-errors-dialog' fullWidth>
      <DialogTitle id='alert-dialog-title' className={classNames(...classes.popup.title)}>
        {t.staticAsString('results.errorsModal.title')}
      </DialogTitle>

      <DialogContent className={classNames(...classes.popup.body)}>
        <DialogContentText className='alert-dialog-description'>
          <p className='body-title'>{t.staticAsString('results.errorsModal.bodyTitle')}</p>
          {errors.map((item, index) => (
            <div key={'key-error-' + index}>{item.join('<br/>')}</div>
          ))}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color='primary' autoFocus>
          {t.staticAsString('results.errorsModal.clear')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

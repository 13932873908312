import { Radio } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { withStyles } from '@mui/styles';

type Props = {
  label: string;
  value: any;
  name: string;
  checked: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
};

const FormControlLabelStyled = withStyles({
  label: {
    fontSize: '14px',
  },
})(FormControlLabel);

export const RadioItem = (props: Props) => {
  let { value, label, checked, disabled } = props;

  return <FormControlLabelStyled label={label} value={value} control={<Radio checked={checked} color='primary' />} disabled={disabled} />;
};

function formatUnicorn(value: string, params: any) {
  const keys = Object.keys(params);

  let str = (value || '').slice();

  keys.forEach((key) => {
    str = str.replace(new RegExp(`{\\s*${key}\\s*}`, 'gi'), params[key]);
  });

  return str;
}

export { formatUnicorn };

import axios from 'axios';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { EXPORT_PAYMENT_RECEIPT, ORGANIZER_PAYMENT_EXPORT_URL, PAYMENTS_EXPORT_URL, EXPORT_ORDER } from 'src/constants';

import { action, request } from 'utils';

import { EXPORT_ORDER_DETAIL } from '../constants';

export class ExportService {
  @request({ action: shortid() })
  private async downloadRequest(url: string): Promise<any> {
    return axios({
      method: 'GET',
      responseType: 'blob',
      url,
    });
  }

  @request({ action: shortid() })
  private async exportPaymentsRequest(params: Object): Promise<any> {
    return axios.get(PAYMENTS_EXPORT_URL, { params });
  }

  @request({ action: shortid() })
  private async exportPaymentDetailRequest(id: number): Promise<any> {
    const params = {
      type: 'pdf',
    };
    const url = generatePath(EXPORT_ORDER, {
      id,
    });
    return axios.get(url, { params });
  }

  @request({ action: shortid() })
  private async _exportReceiptRequest(id: number): Promise<any> {
    const url = generatePath(EXPORT_PAYMENT_RECEIPT, { id });
    return axios.get(url);
  }

  @action({ action: shortid() })
  async exportPayments(filters: AnyObject): Promise<void> {
    const [status, response] = await this.exportPaymentsRequest(filters);
    if (!status) {
      return;
    }

    const [downloadStatus, downloadResponse] = await this.downloadRequest(response.data.url);
    if (!downloadStatus) {
      return;
    }

    const dateStr = moment().format('DD-MM-YY-HH:mm');
    const fileName = `${dateStr}-payments.${filters.type}`;
    saveAs(downloadResponse.data, fileName);
  }

  @action({ action: EXPORT_ORDER_DETAIL })
  async exportPaymentDetail(id: number): Promise<any[]> {
    const [status, response] = await this.exportPaymentDetailRequest(id);

    return [status, response];
  }

  @action({ action: shortid() })
  async exportReceipt(paymentId: number): Promise<null | string> {
    const [status, response] = await this._exportReceiptRequest(paymentId);
    if (!status) {
      return null;
    }

    return response.data.url;
  }
}

import { DUPLICATE_FORM } from 'modules/RaceDetails/constants';
import { duplicateDataType } from 'modules/RaceDetails/types';
import { generatePath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history, monitorJobId, t } from 'utils';

import { form, toastStore } from 'stores';

import { raceDetailsService } from '../services';

import { distancesStore, duplicateModalStore, raceDetailsStore } from '../stores';

import { mount } from './initialize';

export const duplicateRaceAction = async () => {
  const data: duplicateDataType = form.fetchWithoutObservable(DUPLICATE_FORM);
  duplicateModalStore.set({ open: false });
  const jobId = await raceDetailsService.duplicateRace(data);

  if (!jobId) {
    return;
  }
  const [monitorStatus, response] = await monitorJobId(jobId);

  if (!monitorStatus) {
    return;
  }

  const { raceDetails } = raceDetailsStore;

  if (!raceDetails) {
    return;
  }

  toastStore.show(t('races.detail.duplication.successMessage', { name: raceDetails.value.name }));

  const duplicatedRaceId = response?.data?.data?.race;
  if (duplicatedRaceId) {
    const url = generatePath(ROUTES.raceRoute, { id: duplicatedRaceId });
    history.push(url);
    distancesStore.clearData();
    mount();
  }
};

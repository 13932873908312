import * as React from 'react';

import { Info, Wrapper, Header } from '../components';

import { mount, unmount } from '../actions';

export const Base = () => {
  React.useEffect(() => {
    mount();

    return unmount;
  }, []);

  return (
    <Wrapper>
      <Header />
      <Info />
    </Wrapper>
  );
};

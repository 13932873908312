import { computed } from 'mobx';
import { OptionWithParentId } from 'modules/Synchronisation/EQTiming/types';

import { form } from 'src/stores';

import { EQ_FORM_ID } from '../constants';

import { raceClassesStore, raceCustomFieldsStore, raceWavesStore, syncClassesStore, syncWavesStore } from '../stores';

import { raceDistancesStore, syncDistancesStore } from '../../Shared';

const filterSimple = (entity: string, name: string, store: AnyObject, distanceId?: number) => () => {
  if (!store.value) return [];

  const selectedOptions = form.fetch<AnyObject[]>(EQ_FORM_ID, entity) || [];

  const result: OptionWithParentId[] = store.value.filter((item) => !selectedOptions.find((option) => option[name]?.key === item.key));

  if (distanceId) {
    return result.filter((item) => item.parentId === distanceId);
  }

  return result;
};

const filterWithDependency = (entity: string, name: string, store: AnyObject) => () => {
  if (!store.value) return [];

  const selectedOptions = form.fetch<AnyObject[]>(EQ_FORM_ID, entity) || [];
  const distances = form.fetch<AnyObject[]>(EQ_FORM_ID, 'distances') || [];

  return store.value.filter(
    (item) =>
      !selectedOptions.find((option) => {
        return option[name]?.key === item.key || !distances.find((distance) => distance[name]?.key === item.parentId);
      }),
  );
};

export const getRaceDistanceOptions = computed(filterSimple('distances', 'internal_id', raceDistancesStore));
export const getSyncDistanceOptions = computed(filterSimple('distances', 'external_id', syncDistancesStore));

export const getRaceClassesOptions = (distanceId: number, entity: string) => {
  return filterSimple(entity, 'internal_id', raceClassesStore, distanceId);
};

export const getSyncClassesOptions = computed(() => syncClassesStore.value);

export const getRaceWavesOptions = (distanceId: number, entity: string) => {
  return filterSimple(entity, 'internal_id', raceWavesStore, distanceId);
};

export const getSyncWavesOptions = (distanceId: number) => syncWavesStore.value?.filter((item) => item.parentId === distanceId);
export const getRaceCustomFieldsOptions = (distanceId: number, entity: string) =>
  filterSimple(entity, 'internal_id', raceCustomFieldsStore, distanceId);

import axios from 'axios';
import moment from 'moment';

import { LOCALES } from 'src/constants';

import { intercomUtil } from 'utils';

import { localeStore } from 'stores';

function update(locale: availableLocales) {
  if (!LOCALES.includes(locale)) {
    return;
  }

  localeStore.setLocale(locale);

  storeLocale(locale);
  change3ToolsLocale(locale);
}

function storeLocale(locale: string) {
  localStorage.setItem('locale', locale);
}

function change3ToolsLocale(locale: string) {
  intercomUtil.changeLanguage(locale);
  intercomUtil.restart();

  axios.defaults.headers.common['locale'] = locale;
  moment.locale(locale);
}

export { update };

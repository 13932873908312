import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

import { SearchSelect, InputWrapper, DatePicker } from 'components/Form';

import { t } from 'utils';

import { loadSourceDistances, loadTargetDistances } from '../../actions/loadDistances';

import { sourceDistanceStore, targetDistancesStore } from '../../stores';

type Props = {};

@observer
export class FieldsCopyRacers extends React.Component<Props> {
  onInputChangeSource = (event, newInputValue) => {
    loadSourceDistances(newInputValue);
  };

  onInputChangeSourceTarget = (event, newInputValue) => {
    loadTargetDistances(newInputValue);
  };

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputWrapper
            name='sourceDistance'
            settings={{
              required: true,
              label: t.staticAsString('supportTaskManager.form.sourceDistanceId'),
              additional: {
                options: sourceDistanceStore.value,
              },
              onInputChange: this.onInputChangeSource,
              view: {
                variant: 'outlined',
              },
            }}
            Component={SearchSelect}
          />
        </Grid>

        <Grid item xs={6}>
          <InputWrapper
            name='targetDistance'
            settings={{
              required: true,
              label: t.staticAsString('supportTaskManager.form.targetDistanceId'),
              additional: {
                options: targetDistancesStore.value,
              },
              onInputChange: this.onInputChangeSourceTarget,
              view: {
                variant: 'outlined',
              },
            }}
            Component={SearchSelect}
          />
        </Grid>

        <Grid item xs={12}>
          <InputWrapper
            name='dateRange'
            settings={{
              required: true,
              label: t.staticAsString('supportTaskManager.form.startDateEndDate'),
              view: {
                variant: 'outlined',
              },
            }}
            Component={DatePicker}
          />
        </Grid>
      </Grid>
    );
  }
}

import { validate, t } from 'utils';

import { form, errorsStore } from 'stores';

import { SUPPORT_TASK_MANAGER_COPY_FORM } from '../constants';

import { mapToConfirmBody } from '../utils';

import { openConfirmModal, setConfirmContent, setConfirmTitle } from '../actions';

import { CopyRacersForm } from '../types';
import { copyRacers } from '../validations';

export const onSave = (): void => {
  errorsStore.clear(SUPPORT_TASK_MANAGER_COPY_FORM);

  const taskForm: CopyRacersForm = form.fetchWithoutObservable(SUPPORT_TASK_MANAGER_COPY_FORM);
  const isValid: boolean = validate(SUPPORT_TASK_MANAGER_COPY_FORM, taskForm, copyRacers());

  if (!isValid) return;

  setConfirmModalData(taskForm);
  openConfirmModal();
};

const setConfirmModalData = (form: CopyRacersForm): void => {
  const base: string = 'supportTaskManager.confirm.copy';

  const title: string = t.staticAsString(`${base}.title` as TranslationLockedKeys);
  const content: string = t.staticAsString(`${base}.body` as TranslationLockedKeys, mapToConfirmBody(form));

  setConfirmTitle(title);
  setConfirmContent(content);
};

import { validate } from 'utils';
import { t } from 'utils';

import { form, toastStore } from 'stores';

import { NEW_RESULT_FORM_ID } from '../../constants';

import { racersNewResultService, teamsNewResultService, resultsService } from '../../services';

import { resultableWrapperStore } from '../../stores';

import { currentDistance } from '../../derivations';
import { mapperNewResultFormNewResult } from '../../mapper';
import { NewResultForm } from '../../types';
import { generateNewResultContraints } from '../../validation';
import { loadNewResultAutocomplete } from '../loadNewResultAutocomplete';
import { close } from './modal';

async function submit() {
  const data: NewResultForm = form.fetch(NEW_RESULT_FORM_ID);
  const distance = currentDistance.model.get();
  const entityId = +data.entity_id?.value;

  if (!distance) {
    return;
  }

  const isValid = validate(NEW_RESULT_FORM_ID, processForValidation(data), generateNewResultContraints(distance));

  if (!isValid) {
    return;
  }

  if (!entityId) {
    return;
  }

  const dataToSend = mapperNewResultFormNewResult.mapTo(data, distance);

  let status: 'conflict' | 'success' | 'error';

  if (currentDistance.isSingle.get()) {
    status = await racersNewResultService.create(entityId, dataToSend);
  }

  if (currentDistance.isTeam.get()) {
    status = await teamsNewResultService.create(entityId, dataToSend);
  }

  if (status! === 'success') {
    close();
    succMessage(entityId);
    resultableWrapperStore.delete(entityId);
    resultsService.load();
  }

  if (status! === 'conflict') {
    close();
    resultsService.load();
    loadNewResultAutocomplete();
  }
}

function processForValidation(data: NewResultForm): NewResultForm {
  const copy = { ...data };

  if (copy.time_result === 0) {
    copy.time_result = null;
  }

  if (!copy.activity_link) {
    copy.activity_link = null;
  }

  if (!Number(copy.distance_logged)) {
    copy.distance_logged = null;
  }

  return copy;
}

function succMessage(entityId: number) {
  const name = resultableWrapperStore.fetchName(entityId);

  const message = t.staticAsString('results.newForm.success', { name });
  toastStore.show(message);
}

export { submit };

import { get } from 'lodash';

import { RACE, superTranslationsModelTypeMap } from 'src/constants';

import { detectHtml } from 'utils';

import { fieldTranslations } from 'services/fieldTranslations';

import { progressStore } from 'stores';

// For debug purpose, to clear custom fields translations
export const deleteCustomFields = async (data) => {
  await fieldTranslations.deleteTranslations({
    id: data.customFields.custom_field_id,
    tid: data.customFields.id,
  });

  await Promise.all(
    data.customFields.values.map((value) => {
      return fieldTranslations.deleteValueTranslations({
        id: value.field_value_id,
        tid: value.id,
      });
    }),
  );
};

export const contentTranslate = async (modelData: TranslateModelData[]) => {
  progressStore.log(`UPDATE_${RACE}`, 'progress');
  const prepared = modelData.map((d) => {
    const { locale, modelId, modelName, key } = d;
    return {
      locale,
      model_id: modelId,
      model: get(superTranslationsModelTypeMap, [`${modelName}.${key}`]),
      key,
      value: d.value!,
    };
  });
  const result = await fieldTranslations.superTranslations(prepared);
  progressStore.log(`UPDATE_${RACE}`, 'completed');
  return result.data;
};

export const googleTranslate = async ({
  from,
  to,
  q,
}: {
  from: availableLocales;
  to: availableLocales;
  q: string[] | string;
}): Promise<{ text: string }[]> => {
  const format = detectHtml(q) ? 'html' : 'text';
  const result = await fieldTranslations.googleTranslations({ source: from, target: to, term: q, format });

  return result;
};

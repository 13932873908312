import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { DISTANCE_RESULTS_IMPORT_URL } from 'src/constants';

import { action, request } from 'utils';

import { IMPORT_RESULTS } from '../constants';

class Import {
  @request({ action: IMPORT_RESULTS })
  importRequest(distanceId: number, data: FormData): any {
    const url = generatePath(DISTANCE_RESULTS_IMPORT_URL, { distanceId });
    return axios.post(url, data);
  }

  @action({ action: IMPORT_RESULTS })
  async import(distanceId: number, data: FormData): Promise<any> {
    const responseData = await this.importRequest(distanceId, data);

    return responseData;
  }
}

export { Import, Import as ImportService };

import * as React from 'react';

type Props = {
  if: boolean;
  children: React.ReactNode;
};

function Hide(props: Props): any {
  if (props.if) {
    return null;
  }

  return props.children;
}

const Show = React.forwardRef<HTMLDivElement, Props>((props: Props, ref): any => {
  if (!props.if) {
    return null;
  }

  return props.children;
});

export { Hide, Show };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RECEIPT_SETTINGS_URL } from 'src/constants';

import { request, action } from 'utils';

import { GET_RECEIPT_SETTINGS, UPDATE_RECEIPT_SETTINGS } from '../constants';

import { ReceiptSettingsType } from '../types';

class ReceiptSettings {
  @request({ action: GET_RECEIPT_SETTINGS })
  getReceiptSettingsRequest(id: number | string): Promise<any> {
    const receiptSettingsPath = generatePath(RECEIPT_SETTINGS_URL, { id });

    return axios.get(receiptSettingsPath, id as any);
  }

  @action({ action: GET_RECEIPT_SETTINGS })
  async getReceiptSettings(id: number | string): Promise<any> {
    const [status, response] = await this.getReceiptSettingsRequest(id);

    return [status, response.data.data];
  }

  @request({ action: UPDATE_RECEIPT_SETTINGS })
  updateReceiptSettingsRequest(id: number | string, data: ReceiptSettingsType): Promise<any> {
    const receiptSettingsPath = generatePath(RECEIPT_SETTINGS_URL, { id });
    return axios.patch(receiptSettingsPath, data);
  }

  @action({ action: UPDATE_RECEIPT_SETTINGS })
  async updateReceiptSettings(id: number | string, data: ReceiptSettingsType): Promise<any> {
    const [status, response] = await this.updateReceiptSettingsRequest(id, data);

    return [status, response];
  }
}

export { ReceiptSettings };
export default new ReceiptSettings();

import { Collapse } from '@mui/material';
import { Observer } from 'mobx-react-lite';
import { MapItemType } from 'modules/Synchronisation/EQTiming/types';
import { FC } from 'react';

import { InputWrapper, AutocompleteSelectInfinityLoad } from 'src/components/Form';
import { form } from 'src/stores';
import { t } from 'src/utils';

import { RACE_RESULTS_FORM_ID } from '../../constants';

import { handleAddItem, handleDeleteItem } from '../../actions';

import { CustomFieldItemType, SyncContainer, SyncItem, distancesTypeStore } from '../../../Shared';
import { getRaceCustomFieldsOptions, getSyncCustomFieldsOptions, showCustomFields, showCustomFieldsPlus } from '../../selectors';

type Props = {
  distanceIndex: number;
};
export const CustomFields: FC<Props> = ({ distanceIndex }) => {
  const prefix = `distances.${distanceIndex}`;
  return (
    <Observer>
      {() => {
        const data = form.fetch<CustomFieldItemType[]>(RACE_RESULTS_FORM_ID, `${prefix}.custom_fields`) || [];
        const distance = form.fetch<MapItemType>(RACE_RESULTS_FORM_ID, prefix);
        const isTeamDistance = Boolean(
          distancesTypeStore?.value?.find((el) => el?.id === distance?.internal_id?.key && el.type === 'team'),
        );
        if (!distance || isTeamDistance) return null;

        return (
          <SyncContainer
            title={t.staticAsString('sync.custom_fields.title')}
            addText={t.staticAsString('sync.add_custom_fields')}
            onAdd={handleAddItem(`${prefix}.custom_fields`)}
            showPlus={showCustomFieldsPlus(`${prefix}.custom_fields`, Number(distance.internal_id?.key))}
            showBlock={showCustomFields.get()}
          >
            {data.map((item, index) => {
              return (
                <Collapse key={item.id}>
                  <SyncItem onDelete={handleDeleteItem(`${prefix}.custom_fields`, item.id)}>
                    <InputWrapper
                      name={`${prefix}.custom_fields.${index}.internal_id`}
                      settings={{
                        label: t.staticAsString('sync.custom_field.raceid_title'),
                        additional: {
                          options: getRaceCustomFieldsOptions(Number(distance.internal_id?.key), `${prefix}.custom_fields`)(),
                        },
                        view: {
                          reservePlaceForHelperText: true,
                        },
                      }}
                      Component={AutocompleteSelectInfinityLoad}
                    />
                    <InputWrapper
                      key={item.id}
                      name={`${prefix}.custom_fields.${index}.external_id`}
                      settings={{
                        label: t.staticAsString('sync.custom_field.external_title'),
                        additional: {
                          options: getSyncCustomFieldsOptions.get(),
                        },
                        view: {
                          reservePlaceForHelperText: true,
                        },
                      }}
                      Component={AutocompleteSelectInfinityLoad}
                    />
                  </SyncItem>
                </Collapse>
              );
            })}
          </SyncContainer>
        );
      }}
    </Observer>
  );
};

import TablePagination from '@mui/material/TablePagination';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Race as RaceStore } from 'stores';

import loadService from '../../services/load';

type Props = {
  racesStore: RaceStore;
  switchedOnTop: boolean;
};

const PaginationComponent = ({ racesStore, switchedOnTop }: Props) => {
  const { paginationMeta } = racesStore;
  const page = racesStore.page;
  const { per_page, total } = paginationMeta;
  return (
    <TablePagination
      component='div'
      count={total || 0}
      rowsPerPage={per_page || 30}
      rowsPerPageOptions={[30, 50, 100, 200]}
      page={page - 1}
      className={classnames({ pagination: !switchedOnTop })}
      classes={{
        selectLabel: 'caption',
        displayedRows: 'caption',
        select: 'choose-num',
        selectIcon: 'choose-num-svg',
      }}
      backIconButtonProps={{
        'aria-label': 'Previous Page',
      }}
      nextIconButtonProps={{
        'aria-label': 'Next Page',
      }}
      onPageChange={(event, page) => {
        loadService.loadResources({}, page + 1);
      }}
      onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        loadService.loadResources({ limit: Number(event.target.value) });
      }}
    />
  );
};

export const Pagination = observer(PaginationComponent);

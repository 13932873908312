import { observable, action, makeObservable } from 'mobx';

import { HealthStoreType } from '../types';

class Health implements HealthStoreType {
  @observable isServerAlive = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setAliveStatus(isAlive: boolean) {
    this.isServerAlive = isAlive;
  }
}

const healthStore = new Health();
export { healthStore };

import { isEmpty } from 'lodash';

import { stringFormatter } from 'utils';

import { form } from 'stores';

import { INTEGRATION_FORM } from '../constants';

export const onBlurWebhookURL = (name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = e.currentTarget;

  if (isEmpty(value)) {
    return;
  }

  const newValue = stringFormatter.withHttpUrl(value);
  form.onChange(INTEGRATION_FORM, name, newValue);
};

import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';

import { BE_DATE_FORMAT, BE_DATETIME_FORMAT } from 'src/constants/time';

import { t } from 'utils/t';

import { ReportConstants } from '../constants/report.constants';

import { formatCurrency } from '../utils';

import { ReportStore } from '../stores/report.store';

import { PlatformFee } from '../types/report.type';
import CardHeader from './CardHeader';
import PlatformFeesVat from './PlatformFeesVat';
import TableWithSummary from './common/TableWithSummary';
import { cardContentStyles, genericReportStyles } from './general.styles';

type Props = {};

export default function RaceIdInvoice({}: Props) {
  const styles = genericReportStyles();
  const cardStyles = cardContentStyles();
  const r = ReportStore.report.value!;
  const title = (prefix: string) => t.staticAsString(`reconciliation.report.raceIdInvoice.${prefix}` as any);

  return (
    <>
      <Box mb={2}>
        <Typography className={styles.reportGenericTitle}>{title('invoice')}</Typography>
        <Box>
          <span className={cardStyles.cardEntryLeft}>{title('invoiceNumber')}&nbsp;</span>
          <span className={cardStyles.cardEntryRight}>{'ID' + r.invoice.invoice_statement.invoiceNo.toString()}</span>
        </Box>
        <Box>
          <span className={cardStyles.cardEntryLeft}>{title('statementRefNumber')}&nbsp;</span>
          <span className={cardStyles.cardEntryRight}>{r.invoice.invoice_statement.statementRefNumber}</span>
        </Box>
        <Box>
          <span className={cardStyles.cardEntryLeft}>{title('invoiceDate')}&nbsp;</span>
          <span className={cardStyles.cardEntryRight}>
            {moment.utc(r.invoice.invoice_statement.invoiceDate).format(BE_DATETIME_FORMAT)}
          </span>
        </Box>
      </Box>
      <Grid container spacing={2} alignItems='stretch'>
        <Grid xs={12} sm={6} item>
          <CardHeader
            title='Period'
            supportTitle={`
${moment(r.invoice.invoice_statement.from).format(BE_DATE_FORMAT)} to
${moment(r.invoice.invoice_statement.to).format(BE_DATE_FORMAT)}
          `}
          />
          <Box className={cardStyles.card}>
            <Box className={cardStyles.cardSection}>
              <span className={cardStyles.cardEntryLeft}>{title('clientName')}&nbsp;</span>
              <span className={cardStyles.cardEntryRight}>{r.invoice.invoice_statement.clientName}</span>
            </Box>
            <Box className={cardStyles.cardSection}>
              <span className={cardStyles.cardEntryLeft}>{title('address')}&nbsp;</span>
              <span className={cardStyles.cardEntryRight}>{r.invoice.invoice_statement.address ?? '—'}</span>
            </Box>
            <Box className={cardStyles.cardSection}>
              <span className={cardStyles.cardEntryLeft}>{title('zipCode')}</span>
              <span className={cardStyles.cardEntryRight}>{r.invoice.invoice_statement.zipCode ?? '—'}</span>
            </Box>
            <Box className={cardStyles.cardSection}>
              <span className={cardStyles.cardEntryLeft}>{title('city')}&nbsp;</span>
              <span className={cardStyles.cardEntryRight}>{r.invoice.invoice_statement.city ?? '—'}</span>
            </Box>
            <Box className={cardStyles.cardSection} style={{ marginBottom: 10 }}>
              <span className={cardStyles.cardEntryLeft}>{title('country')}&nbsp;</span>
              <span className={cardStyles.cardEntryRight}>{r.invoice.invoice_statement.country ?? '—'}</span>
            </Box>
          </Box>
        </Grid>
        <Grid sm={6} item>
          <CardHeader title='' />
          <Box style={{ minHeight: 228, display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={cardStyles.card}>
            <Box className={cardStyles.invoiceDoNotPay}>
              <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                <Box>
                  <Typography variant='h6'>{title('doNotPay')}</Typography>
                </Box>
                <Box>
                  <Typography variant='body1'>{`(${title('alreadyDeducted')})`}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Typography style={{ marginTop: 42, marginBottom: 32 }} className={styles.reportGenericTitle}>
        {title('platformFees')}
      </Typography>
      <TableWithSummary
        tableName='Invoice'
        columns={[
          { title: 'Description', accessor: 'description', align: 'left' },
          {
            title: title('amount'),
            accessor: 'totalExcludingVAT',
            fn: (item: PlatformFee) => item.amount,
            align: 'center',
          },
          {
            title: title('unit'),
            accessor: 'unit',
            align: 'center',
          },
          {
            title: title('ppu'),
            accessor: 'vatSum',
            fn: (item: PlatformFee) =>
              item.unit !== ReportConstants.COMMISSION_UNIT ? formatCurrency(item.pricePerUnit) : `${item.pricePerUnit} ${item.unit}`,
            align: 'center',
          },
          {
            title: title('total'),
            accessor: 'total',
            align: 'center',
            fn: (item: PlatformFee) => formatCurrency(item.total),
          },
          {
            title: 'VAT %',
            accessor: 'vatPercent',
            align: 'center',
          },
          {
            title: 'VAT Sum',
            accessor: 'vatSum',
            align: 'center',
            fn: (item: PlatformFee) => formatCurrency(item.vatSum),
          },
          {
            title: title('totalIncVat'),
            accessor: 'totalIncVat',
            align: 'right',
            fn: (item: PlatformFee) => formatCurrency(item.totalIncVat),
          },
        ]}
        data={r.invoice.platform_fees}
        summaryText={title('total')}
      />
      <PlatformFeesVat exclVat={r.invoice.platform_fees.reduce((a, b) => a + b.total, 0)} platform_fees_vat={r.invoice.platform_fees_vat} />
    </>
  );
}

import { Observer } from 'mobx-react-lite';
import * as React from 'react';

import { AutocompleteSelectInfinityLoad, InputWrapper } from 'src/components/Form';
import { form } from 'src/stores';

import { t } from 'utils/t';

import { REGISTRATION_LINKS_FORM_ID } from '../constants';

import { chosenDistance } from '../utils';

import { loadWaves, searchWaves } from '../actions';

import { wavesHasMoreStore, wavesStore } from '../stores';

export const WaveField = () => {
  return (
    <Observer>
      {() => {
        const disabled = !form.fetch(REGISTRATION_LINKS_FORM_ID, 'distance_id');
        const distance = chosenDistance();
        return (
          <InputWrapper
            name='wave_id'
            settings={{
              label: t.staticAsString('racers.new.wave'),
              additional: {
                options: wavesStore.value || [],
                disabled,
              },
              view: {
                reservePlaceForHelperText: true,
              },
              hasMore: wavesHasMoreStore.value,
              loadMore: distance && (() => loadWaves(distance.id)),
              onSearch: searchWaves,
            }}
            Component={AutocompleteSelectInfinityLoad}
          />
        );
      }}
    </Observer>
  );
};

import { startlistToast } from '../utils';

import { raceDetailsService } from '../services';

import { raceDetailsStore } from '../stores';

const toggleStartListAction = async (newValue: number) => {
  const race = raceDetailsStore.raceDetails?.value;

  if (!race) {
    return [];
  }

  const { id } = race;
  const params = {
    show_startlist: newValue,
  };

  raceDetailsStore.partialRaceUpdate(params);
  const [status] = await raceDetailsService.toggleStartListVisibility(id, params);

  // correct server response, show success message
  startlistToast(status, newValue);

  if (status) {
    return;
  }

  // bad server response, error message was shown before, roll back state
  const previousValue = +!newValue;

  raceDetailsStore.partialRaceUpdate({ show_startlist: previousValue });
};

export const hideStartListAction = () => {
  toggleStartListAction(0);
};

export const showStartListAction = () => {
  toggleStartListAction(1);
};

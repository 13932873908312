export const DEFAULT_LOCALE: availableLocales = 'en';
// LOCALES used for switching main interface locale
export const LOCALES: availableLocales[] = [
  'en',
  'sv',
  'fr',
  'es',
  'fi',
  'no',
  'el',
  // 'da',
  /* 'de', 'pt' */
];
// FLAGS used translations locales
export const FLAGS: { [key in availableLocales]: string } = {
  en: 'gb',
  sv: 'se',
  fr: 'fr',
  es: 'es',
  fi: 'fi',
  no: 'no',
  el: 'gr',
  de: 'de',
  pt: 'pt',
  da: 'dk',
};

export const DEFAULT_CURRENCY: availableCurrencies = 'SEK';
// LOCALES used for switching main interface locale
export const CURRENCIES: availableCurrencies[] = ['SEK', 'EUR', 'NOK', 'USD', 'CAD', 'GBP', 'NZD', 'PLN', 'DKK', 'MAD', 'CHF'];
// FLAGS used translations locales
export const CURRENCY_FLAGS: { [key in availableCurrencies]: string } = {
  SEK: 'se',
  EUR: 'eu',
  NOK: 'no',
  USD: 'us',
  CAD: 'ca',
  GBP: 'gb',
  NZD: 'nz',
  PLN: 'pl',
  AUD: 'au',
  DKK: 'dk',
  CHF: 'ch',
  MAD: 'ma',
};

export const USER_PLATFORM_ADMIN = 'RaceID Admin';

export const TEXT_EDITOR_KEY = 'x617jlgmryv5knlcevpes76z9kwnuhmajbbxszx238f6kfyg';
export const TEXT_EDITOR_BASE_FONT = `
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body { font-family: 'Montserrat'; }`;

import * as React from 'react';

import { generateOnEdit } from '../../../actions';

import { RowType } from '../';
import { ResultsContext } from '../../../context';
import { EditableCell } from '../../EditableCell';
import { editPrevSplitDiff, editResultDiff } from './edit';
// TODO, move show,edit,update to ../../../tools
import { showPrevSplitDiff, showResultDiff } from './show';
import { updatePrevSplitDiff, updateResultDiff } from './update';

function SplitRowComponent({ value }: RowType) {
  return (
    <ResultsContext.Consumer>
      {(context) => {
        const distanceModel = context.results.stores.distanceStore.modelSelected;

        if (!distanceModel) {
          return null;
        }

        const checkpoints = distanceModel.checkpoints;

        return checkpoints.map<React.ReactNode>((checkpoint, index) => {
          const prevCheckpoint = checkpoints[index - 1]?.value?.id;
          const split = value.findSplitByCheckpoint(checkpoint.value.id);
          const showPrevSplitDiffFun = showPrevSplitDiff(checkpoint.value.id || NaN, prevCheckpoint, value, context);
          const showResultDiffFun = showResultDiff(checkpoint.value.id || NaN, value, context);

          const editPrevSplitDiffFun = editPrevSplitDiff(value, context, checkpoint.value.id, prevCheckpoint);
          const editResultDiffFun = editResultDiff(value, context, checkpoint.value.id);

          const updatePrevSplitDiffFun = updatePrevSplitDiff(value, context, checkpoint.value.id, prevCheckpoint);
          const updateResultDiffFun = updateResultDiff(value, context, checkpoint.value.id);

          const prevSplitFieldId = (split && split.generateFieldId('prev_split_diff')) || '';
          const resultDiffFieldId = (split && split.generateFieldId('result_diff')) || '';

          return (
            /* eslint-disable */
            <>
              <EditableCell
                onEdit={generateOnEdit(prevSplitFieldId, 'finish_time', context)}
                show={showPrevSplitDiffFun}
                edit={editPrevSplitDiffFun}
                update={updatePrevSplitDiffFun}
                fieldId={prevSplitFieldId}
              />
              <EditableCell
                onEdit={generateOnEdit(resultDiffFieldId, 'finish_time', context)}
                show={showResultDiffFun}
                edit={editResultDiffFun}
                update={updateResultDiffFun}
                fieldId={resultDiffFieldId}
              />
            </>
          );
        });
      }}
    </ResultsContext.Consumer>
  );
}

const SplitRow = React.memo<RowType>(SplitRowComponent);
export { SplitRow };

import { Payment } from './payment';

class Organizer {
  value: OrganizerType;
  payments: Array<Payment>;

  constructor(value: OrganizerType) {
    this.value = value;
    this.payments = [];
    this._initPayments();
  }

  fullName(): string {
    const { value } = this;
    if (!value.full_name) {
      return value.email;
    }

    return `${value.email} (${value.full_name})`;
  }

  _initPayments() {
    const { value } = this;
    if (value.payments) {
      this.payments = value.payments.map((payment) => new Payment(payment));
    }
  }
}

export { Organizer };

import { cloneDeep, compact } from 'lodash';
import { observer } from 'mobx-react';
import { FORM_ID, SAVE_CHECKPOINTS } from 'modules/Distances/constants';
import { ConfigureCheckpointDialog } from 'modules/Distances/containers/ConfigureCheckpoint';
import { CheckpointModalStore } from 'modules/Distances/stores';
import { populateCheckpointAssistants } from 'modules/Distances/utils';
import * as React from 'react';
import shortid from 'shortid';

import { DISTANCE_STEPS, PERSIST_DISTANCE as action } from 'src/constants';
import { getDefaultCheckpoints } from 'src/modules/Distances/components/shared/Steps/Checkpoints/utils';

import { withErrorClean, withProgressSpinner } from 'hocs';

import { Hide, Show } from 'components/Condition';

import { t } from 'utils';

import { Race as RaceModel } from 'models';

import { form, progressStore } from 'stores';

import { State as OriginalFormState } from '../../../../shared/stateHelpers';
import { Items } from './Items';
import { AddBtn } from './components';

type Props = {
  formData: OriginalFormState;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
    isDirty?: boolean,
  ) => void;
  errors: {
    [K in string]: Array<string>;
  };
  helperData: {
    race: nil | RaceModel;
  };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

const stepAction = `${action}_${DISTANCE_STEPS.checkpoints}`;

const skipRule: AnyFunction = (object: CheckpointType) => !!object._delete;

@withErrorClean(stepAction)
@withProgressSpinner(SAVE_CHECKPOINTS)
@observer
class Checkpoints extends React.Component<Props> {
  onChange = (values: Array<CheckpointType>, callback: Function = () => {}) => {
    const { onChange, liveUpdate } = this.props;

    liveUpdate({ checkpoints: values }, true);
    onChange(
      {
        checkpoints: values,
      },
      '',
      () => {
        callback();
      },
      true,
    );
  };

  onSave = (checkpointToSave: CheckpointType) => {
    progressStore.log(SAVE_CHECKPOINTS, 'progress');
    const { checkpoints } = this.props.formData;

    // Find index of newly created or existing checkpoint
    const index = checkpoints.findIndex(
      (el) => (checkpointToSave.id && el.id === checkpointToSave.id) || (checkpointToSave.__id && el.__id === checkpointToSave.__id),
    );

    // Update old
    if (checkpoints[index]) {
      const updatedCheckpoints = [...checkpoints];
      updatedCheckpoints.splice(index, 1, cloneDeep(checkpointToSave));

      return this.onChange(updatedCheckpoints);
    }

    // Add new
    let headCheckpoints = checkpoints.filter((item) => !item._delete).slice();
    let lastCheckpoint;

    if (checkpoints.length > 1) {
      lastCheckpoint = headCheckpoints.pop();
    }

    const result = compact([
      ...headCheckpoints,
      {
        ...cloneDeep(checkpointToSave),
        __id: shortid(),
      },
      lastCheckpoint,
    ]);

    this.onChange(result);
  };

  handleAddNewClick = () => {
    form.clean(FORM_ID);
    CheckpointModalStore.on();
  };

  onRemove = (id: number | string) => {
    progressStore.log(SAVE_CHECKPOINTS, 'progress');
    const { checkpoints } = this.props.formData;
    const values = checkpoints.reduce((acc: CheckpointType[], checkpoint: CheckpointType) => {
      if (checkpoint.id === id) {
        return [
          ...acc,
          {
            ...checkpoint,
            _delete: true,
          },
        ];
      } else if (checkpoint.__id === id) {
        return acc;
      } else {
        return [...acc, checkpoint];
      }
    }, []);

    this.onChange(values);
  };

  onRemoveAssistant = (id: number | string, assistantId: number | string) => {
    const { checkpoints } = this.props.formData;

    const index = checkpoints.findIndex((el) => el.id === id || el.__id === id);

    if (index === -1) {
      return;
    }

    const checkpointUpd = { ...checkpoints[index] };

    checkpointUpd.assistants = checkpointUpd?.assistants?.filter((el) => el.id !== assistantId);

    const checkpointsUpd = [...checkpoints];
    checkpointsUpd.splice(index, 1, checkpointUpd);

    this.onChange(checkpointsUpd);
  };

  _renderCheckpoints = (): React.ReactNode => {
    const { formData } = this.props;
    const { checkpoints } = formData;
    const valuesForRender = filterDeleted(checkpoints);

    const editCheckpoint = (id) => {
      const checkpointToEdit = this.props.formData.checkpoints.find(
        (checkpoint) => (checkpoint.id && checkpoint.id === id) || (checkpoint.__id && checkpoint.__id === id),
      );

      if (!checkpointToEdit) {
        return;
      }

      form.merge(FORM_ID, populateCheckpointAssistants(checkpointToEdit));
      CheckpointModalStore.on();
    };

    return (
      <Items
        onChange={this.onChange}
        onEdit={editCheckpoint}
        checkpoints={valuesForRender}
        onRemove={this.onRemove}
        onRemoveAssistant={this.onRemoveAssistant}
      />
    );
  };

  render() {
    const {
      formData: { checkpoints },
    } = this.props;

    const noCheckpoints = !filterDeleted(checkpoints).length;

    if (noCheckpoints) {
      const defaultCheckpoints = getDefaultCheckpoints(checkpoints);
      this.onChange(defaultCheckpoints);
    }

    return (
      <div className='checkpoint-fields-form form-content-wrapper'>
        <div className='subtitle'>{t.staticAsString('distances.steps.checkpointsPage.title')}</div>
        <ConfigureCheckpointDialog onSave={this.onSave} checkpointsLength={checkpoints.length} />
        <AddBtn center onClick={this.handleAddNewClick} label={t.staticAsString('distances.steps.checkpointsForm.add') as any} />
        <Show if={noCheckpoints}>
          <div className='hint'>{t.staticAsString('distances.steps.checkpointsPage.hint')}</div>
        </Show>
        <Hide if={noCheckpoints}>
          <ul className='checkpoint-list'>{this._renderCheckpoints()}</ul>
          {this.props.controls}
        </Hide>
      </div>
    );
  }
}

const filterDeleted = (values: Array<CheckpointType>): Array<CheckpointType> => {
  return values.filter((el) => !el._delete).sort((a, b) => a.index - b.index);
};

const normalizeDataForBackend = (values: CheckpointType[]): Array<{ _delete?: boolean; id?: number } | CheckpointType> => {
  let index = -1;
  return values.map((el) => {
    if (!!el._delete) {
      return { id: el.id, _delete: true };
    }

    const { assistants = [] } = el;

    // TODO TS | fix
    // @ts-ignore
    el.assistants = assistants.map((assistant) => {
      const {
        // @ts-ignore
        allow_start = false,
        // @ts-ignore
        allow_finish = false,
        // @ts-ignore
        organizer_id,
        id,
      } = assistant;

      return {
        allow_start,
        allow_finish,
        organizer_id: organizer_id || id,
      };
    });

    index++;
    return {
      ...el,
      index,
      _delete: false,
    };
  });
};

export { Checkpoints, normalizeDataForBackend };

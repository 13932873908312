import { reaction } from 'mobx';

import { form } from 'stores';

import { EQ_FORM_ID } from '../constants';

import { disposersStore } from '../stores';

let memoizedValue: AnyObject[] = [];

export const observeDistanceChange = () => {
  disposersStore.register(
    reaction(
      () => {
        const distances = form.fetch<AnyObject[]>(EQ_FORM_ID, 'distances');

        return distances.map((_i, index) => form.fetch(EQ_FORM_ID, `distances.${index}`));
      },
      (distances) => {
        // .....
      },
    ),
  );
};

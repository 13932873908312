import classNames from 'classnames';
import * as React from 'react';

import { TableHeaderItem } from 'components/Filter/TableHeaderItem';

import { t } from 'utils';

import { SINGLE_RESULTS_COLUMNS } from '../../constants';

import { isColumnVisibleSingle, headerTranslation } from '../../tools';
import { HeadersType } from './';

const { MAIN_COLUMNS, COLUMN_WIDTH } = SINGLE_RESULTS_COLUMNS;

const classes = {
  thead: {
    regular: ['start-list-table-header', 'filter-item'],
  },
};

const SingleHeadersComponent: React.FC<HeadersType> = function ({}: HeadersType) {
  return MAIN_COLUMNS.map((column) => {
    if (!isColumnVisibleSingle(column)) {
      return null;
    }

    const width = COLUMN_WIDTH[column];
    const className = `cell-${column}`;
    const translation = headerTranslation(column);

    return (
      <TableHeaderItem
        key={column}
        name={column}
        width={width}
        className={classNames(...classes.thead.regular, className)}
        label={t(translation as TranslationLockedKeys) as any}
      />
    );
  }) as any;
};

const SingleHeaders = React.memo<HeadersType>(SingleHeadersComponent);
export { SingleHeaders };

import axios from 'axios';
import { mapCouponRaces } from 'modules/CouponsForm/utils';
import { generatePath } from 'react-router-dom';

import { RACE_URL, RACES_URL } from 'src/constants';

import { action, request } from 'utils';

import { COUPON_FORM_STATES } from '../constants';

import { couponFormRacesStore, couponFormSelectedRaceStore } from '../stores';

class CouponFormRaceService {
  @request({ action: COUPON_FORM_STATES.RACE_LOADING })
  async loadRaceRequest(id: number): Promise<any> {
    const url = generatePath(RACE_URL, { id });
    return axios.get(url, { params: { with: 'distances' } });
  }

  @action({ action: COUPON_FORM_STATES.RACE_LOADING, minRequestTime: 500 })
  async loadRace(id: number): Promise<void> {
    const [isOk, response] = await this.loadRaceRequest(id);
    if (isOk) {
      couponFormSelectedRaceStore.set(response.data.data);
    }
  }

  @request({ action: COUPON_FORM_STATES.RACE_LOADING })
  async loadRacesRequest(): Promise<any> {
    const url = generatePath(RACES_URL);
    const params = couponFormRacesStore.params;
    return axios.get(url, { params });
  }

  @action({ action: COUPON_FORM_STATES.RACE_LOADING, minRequestTime: 500 })
  async loadRaces(): Promise<void> {
    const [isOk, response] = await this.loadRacesRequest();

    if (isOk) {
      couponFormRacesStore.setRaces([...mapCouponRaces(response.data.data)]);
      couponFormRacesStore.setHasMore(response.data.meta.pagination.total_pages > couponFormRacesStore.params.page);
      couponFormRacesStore.setParams({ page: couponFormRacesStore.params.page + 1 });
    }
  }
}

export const couponFormRaceService = new CouponFormRaceService();

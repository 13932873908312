import { cloneDeep } from 'lodash';

export const mapErrorGenerateCoupons = (data: AnyObject): RawError => {
  const response = cloneDeep(data);
  let errors: AnyObject = [];

  if (response) {
    const { from, to } = response;
    let couponDateRange: Array<string> = [];
    if (to) couponDateRange.push(to.join(' '));
    if (from) couponDateRange.push(from.join(' '));

    errors.couponDateRange = [couponDateRange.join(' ')];
  }
  return errors;
};

import { observer } from 'mobx-react';
import * as React from 'react';

import { FilterAutocompleteAsyncLoad } from 'components/Filter/DefaultFilters';

import { OrganizerModel } from '../models';

import { loadOrganizers } from '../actions';

import { organizersFilters } from '../stores';

type Props = {} & FilterProps;

@observer
class RaceNameComponent extends React.Component<Props> {
  options = () => {
    return organizersFilters.filteredValues.map((el) => {
      const racerRace = new OrganizerModel(el);
      return racerRace.forSelect();
    });
  };

  loadMore = (): void => {
    const { current_page = 0 } = organizersFilters.paginationMeta;
    loadOrganizers({ page: current_page + 1 });
  };

  searchFilter = (text: string) => {
    const obj = {
      search: `email:${text}`,
      searchFields: 'email:like',
    };

    loadOrganizers({
      page: 1,
      ...(text ? obj : {}),
    });
  };

  render() {
    const { props } = this;
    return (
      <FilterAutocompleteAsyncLoad
        {...props}
        noMoreData={organizersFilters.noMoreData}
        options={this.options()}
        loadMore={this.loadMore}
        search={this.searchFilter}
      />
    );
  }
}

export const reportedBy: FilterListDescriptor = {
  filter: {
    persistance: {
      advanced: {
        calcValue: (name, value) => ({ user_id: value }),
        calcHandler: () => ({ user_id: '=' }),
      },

      prettify: function (name, value) {
        return organizersFilters.getOrganizerById(+value);
      },
    },
  },
  frontend: {
    type: 'custom',
    component: RaceNameComponent,
  },
};

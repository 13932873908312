import { HELPER_RACE, RACES_URL } from 'src/constants';

import { request, action } from 'utils';

import { helperRacesStore } from 'stores';
import { HelperRace as HelperRaceStore } from 'stores';

import { LoadableService } from './loadable';

class HelperRaceService extends LoadableService<RaceType, FiltersType, HelperRaceStore> {
  constructor(store: HelperRaceStore) {
    super({ store, resourcesUrl: RACES_URL, resource: HELPER_RACE });
  }

  @request({ action: `LOAD_${HELPER_RACE}` })
  async loadResourceRequest(...params: any) {
    // @ts-ignore
    return super.loadResourceRequest(...params);
  }

  @request({ action: `LOAD_${HELPER_RACE}S` })
  async loadResourcesRequest(...params: any) {
    // @ts-ignore
    return super.loadResourcesRequest(...params);
  }

  @action({ action: `LOAD_${HELPER_RACE}` })
  async loadResource(...params: any) {
    // @ts-ignore
    return super.loadResource(...params);
  }

  @action({ action: `LOAD_${HELPER_RACE}S` })
  async loadResources(...params: any) {
    if (!this.store.values.length) {
      return super.loadResources(...params);
    }
  }
}

const helperRaceService = new HelperRaceService(helperRacesStore);
export { HelperRaceService, helperRaceService };

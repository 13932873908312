import Grid from '@mui/material/Unstable_Grid2';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Show } from 'components/Condition';
import { InputWrapper, Number, WrappedRadio, AutocompleteSelectInfinityLoad } from 'components/Form';

import { t } from 'utils';

import { form } from 'stores';

import { FieldsBibClasses } from '../../containers/New/FieldsBibClasses';

import { SUPPORT_TASK_MANAGER_FORM } from '../../constants';

import { optionsBibStartingDistance, optionsBibStartingDistanceDisable } from '../../utils';

import { loadDistances, loadRaces, setBibClassesStore } from '../../actions';

import { bibClassesStore, distancesStore, racesStore, getParams, getRacesParams } from '../../stores';

type Props = {
  show: boolean;
};

@observer
export class FieldsGenerateBibNumbers extends React.Component<Props> {
  onInputChangeRace = (text) => {
    racesStore.clear();
    racesStore.setParams(getRacesParams(text));
    loadRaces();
  };

  loadMoreRaces = () => {
    loadRaces();
  };

  onChangeRace = ({ name, value }: { name: string | string[]; value: any }) => {
    form.onChange(SUPPORT_TASK_MANAGER_FORM, name, value);

    this.clearData();

    if (value) {
      loadDistances(value.value);
    }
  };

  onChangeBibDistances = ({ name, value }: { name: string | string[]; value: any }) => {
    form.onChange(SUPPORT_TASK_MANAGER_FORM, name, value);
    if (value) {
      setBibClassesStore(value.classes);
    } else {
      bibClassesStore.clear();
    }
  };

  loadMoreDistances = () => {
    const race = form.fetch<{ value: number }>(SUPPORT_TASK_MANAGER_FORM, 'bibRace');
    loadDistances(race.value);
  };

  onInputChangeBibDistances = (text: string) => {
    distancesStore.clear();
    const race = form.fetch<{ value: number }>(SUPPORT_TASK_MANAGER_FORM, 'bibRace');
    distancesStore.setParams(getParams(text));
    loadDistances(race.value);
  };

  clearData = () => {
    form.onChange(SUPPORT_TASK_MANAGER_FORM, 'bibDistances', null);
    form.onChange(SUPPORT_TASK_MANAGER_FORM, 'bibDistancesClasses', null);
    form.onChange(SUPPORT_TASK_MANAGER_FORM, 'bibDistanceClassStarting', null);

    form.onChange(SUPPORT_TASK_MANAGER_FORM, 'bibStarting', null);

    distancesStore.clear();
    bibClassesStore.clear();
  };

  render() {
    const { show } = this.props;
    const disableBibClassesOptions: boolean = !(bibClassesStore.value && bibClassesStore.value.length > 1);
    const showBibStartingDistance: boolean = Boolean(form.fetch<string>(SUPPORT_TASK_MANAGER_FORM, 'bibStartingDistance'));

    return (
      <Show if={show}>
        <Grid container spacing={2}>
          <InputWrapper
            name='bibRace'
            settings={{
              required: true,
              label: t.staticAsString('supportTaskManager.form.race'),
              additional: {
                options: racesStore.races,
                fullWidth: true,
              },
              onSearch: this.onInputChangeRace,
              onChange: this.onChangeRace,
              loadMore: this.loadMoreRaces,
              hasMore: racesStore.hasMore,
              view: {
                variant: 'outlined',
              },
            }}
            Component={AutocompleteSelectInfinityLoad}
          />

          <InputWrapper
            name='bibDistances'
            settings={{
              required: true,
              label: t.staticAsString('supportTaskManager.form.distances'),
              additional: {
                options: distancesStore.distances,
                fullWidth: true,
              },
              onChange: this.onChangeBibDistances,
              onSearch: this.onInputChangeBibDistances,
              loadMore: this.loadMoreDistances,
              hasMore: distancesStore.hasMore,
              view: {
                variant: 'outlined',
              },
            }}
            Component={AutocompleteSelectInfinityLoad}
          />

          <InputWrapper
            name='bibStartingDistance'
            settings={{
              required: true,
              options: disableBibClassesOptions ? optionsBibStartingDistanceDisable() : optionsBibStartingDistance(),
            }}
            Component={WrappedRadio}
          />

          <Show if={showBibStartingDistance}>
            <InputWrapper
              name='bibStarting'
              settings={{
                required: true,
                label: t.staticAsString('supportTaskManager.form.startingBib'),
                additional: {
                  fullWidth: true,
                },
                view: {
                  variant: 'outlined',
                },
              }}
              Component={Number}
            />
          </Show>

          <FieldsBibClasses show={!showBibStartingDistance} />
        </Grid>
      </Show>
    );
  }
}

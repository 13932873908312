import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { AdminPayments, Details } from '../';

export class AdminPaymentsContainer extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path={ROUTES.paymentsRoute} exact component={AdminPayments} />
        <Route path={ROUTES.paymentRoute} exact component={Details} />
      </Switch>
    );
  }
}

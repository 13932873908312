import { stringFormatDate, observableFormatDate, composeDate } from '../utils';

import { parsedDate, outputDate } from '../types/index';
import { InputDate } from './inputDate';

class WorkDate {
  value: parsedDate;
  defaultBeFormat: BEDateFormat;

  constructor(value: parsedDate, defaultBeFormat: BEDateFormat) {
    this.value = value;
    this.defaultBeFormat = defaultBeFormat;
  }

  /*
   * Format date for FE as react node
   */
  format(feFormatType: CommonDateFormats): React.ReactNode {
    return observableFormatDate(this.value, feFormatType);
  }

  /*
   * Compose date to valid BE format
   */
  compose(beFormatType?: BEDateFormat): InputDate<outputDate> {
    const format = beFormatType || this.defaultBeFormat;
    const value = composeDate(this.value, format);
    return new InputDate<outputDate>(value, format);
  }

  /*
   * Format date for FE as string
   */
  formatAsString(feFormatType: CommonDateFormats): string {
    return stringFormatDate(this.value, feFormatType);
  }

  /*
   * Compose date to valid BE format
   * with utc timezone
   */
  composeForBe(beFormatType?: BEDateFormat): InputDate<outputDate> {
    this.setUtc();

    return this.compose(beFormatType);
  }

  /*
   * Set utc timezone
   */
  setUtc(): this {
    this.value = this.value.utc();
    return this;
  }

  /*
   * Set local timezone
   */
  setLocal(): this {
    this.value = this.value.local();
    return this;
  }

  /*
   * Change value directly without reassigning
   */
  tap(callback: (el: parsedDate) => void): this {
    callback && callback(this.val);
    return this;
  }

  /*
   * Reasign value via callback
   */
  mutate(callback: (el: parsedDate) => parsedDate): this {
    const value = callback && callback(this.val);
    this.value = value;
    return this;
  }

  get val() {
    return this.value;
  }
}

export { WorkDate };

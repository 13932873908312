import { racerFields } from '../../../../Filters';

const MAIN_COLUMNS = [
  racerFields['team.name'],
  racerFields['firstname'],
  racerFields['lastname'],
  racerFields['email'],
  racerFields['class_id'],
  racerFields['discipline_id'],
  racerFields['gender'],
  racerFields['country_id'],
  racerFields['city'],
];

const PAYMENT_COLUMNS = [racerFields['order_id'], racerFields['order_created_at'], racerFields['order_total'], racerFields['order_status']];

const COLUMN_WIDTH: {
  [K in string]: number;
} = {
  action: 50,
  [racerFields['firstname']]: 150,
  [racerFields['lastname']]: 150,
  [racerFields['email']]: 250,
  [racerFields['order_created_at']]: 150,
};

const CUSTOM_FIELD_WIDTH = 250;

export { MAIN_COLUMNS, PAYMENT_COLUMNS, COLUMN_WIDTH, CUSTOM_FIELD_WIDTH };

import { CloseOutlined } from '@mui/icons-material';
import { Box, Collapse, IconButton, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';

import { Show } from 'components/Condition';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '0 8px 0 22px',
  },
  closeButton: {
    marginRight: 30,
    color: theme.palette.grey1,
    '& svg': {
      fontSize: 18,
    },
  },
  collapse: {
    flexShrink: 0,
  },
}));

type Props = {
  content: React.ReactNode;
  onClose?: () => void;
  showCloseButton?: boolean;
};

const BannerBase = ({ onClose, content, showCloseButton = true }: Props) => {
  const classes = useStyles();
  const [isBannerHidden, setIsBannerHidden] = React.useState(true);

  useEffect(() => {
    setIsBannerHidden(false);
  }, []);

  const handleClose = () => {
    setIsBannerHidden(true);
    onClose && onClose();
  };

  return (
    <Collapse in={!isBannerHidden} timeout={500} className={classes.collapse}>
      <Box className={classes.root}>
        <Box className={classes.content}>{content}</Box>
        <Show if={showCloseButton}>
          <IconButton size='small' color='default' className={classes.closeButton} onClick={handleClose}>
            <CloseOutlined fontSize='small' />
          </IconButton>
        </Show>
      </Box>
    </Collapse>
  );
};

export default BannerBase;

// Filters that automaticly mapped from filter descripotor and rendered automaticly
import * as React from 'react';

import { DatetRange } from './DateRange';
import { Input } from './Input';
import { NumberRange } from './NumberRange';

export type Props = {
  filterDescriptors: WrappedFiltersListDescriptors;
} & FilterProps;

const MAIN_FILTERS: {
  [K in FrontendListFilter]: React.ComponentType<any> | null;
} = {
  numberRange: NumberRange,
  priceRange: NumberRange,
  dateRange: DatetRange,
  string: Input,
  custom: null,
  none: null,
};

function invalidFilterError(name: string, filterType: string) {
  console.error(`Cannot handle filter - ${name} ${filterType}`);
}

const FilterWrapper = (props: Props) => {
  const { name, filterDescriptors } = props;
  const descriptor = filterDescriptors[name];

  if (!descriptor) {
    return null;
  }

  const type = descriptor.frontendFilterType();
  const component = descriptor.frontendFilterComponent();
  const propsGenerator: Function = descriptor.frontendComponentPropsGenerator();

  let Component = MAIN_FILTERS[type];

  if (type === 'custom') {
    Component = component || null;
  }

  if (!Component) {
    invalidFilterError(name, type);
    return null;
  }

  return <Component {...props} {...propsGenerator()} />;
};

export { FilterWrapper };

import React from 'react';

import { InputFilter } from 'components/Filter';

import { t } from 'utils';

import { FilterProps } from './index';

type Props = {
  value: nil | string | number | any;
} & FilterProps &
  any;

const Description = (props: Props) => {
  return <InputFilter {...props} label={t.staticAsString('coupons.list.table.description')} />;
};

export { Description };

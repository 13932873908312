import { t } from 'utils';

const base = {
  name: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      maximum: 50,
    },
  },
};

const createCheckpoint = (objForValidate: Object) => {
  let constraints = base;

  return {
    ...constraints,
  };
};

export { base, createCheckpoint };

import classNames from 'classnames';
import { isArray } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Spinner } from 'components/Spinner';

import { progressStore } from 'stores';

type Options = {
  hideClassName: string;
  spinner?: 'cubesSpinner' | 'cubesSpinnerSmall';
};

const withProgressSpinner =
  (
    action: string | string[],
    options: Options = {
      hideClassName: 'hidden',
    },
  ) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) => {
    const actions = [...(isArray(action) ? action : [action])];
    const spinnerType = options.spinner || Spinner.types.default;

    return observer(
      class extends React.Component<any> {
        render() {
          const isLoading = actions.some((action) => progressStore.isLoading(action || ''));

          return (
            <React.Fragment>
              {isLoading && <Spinner type={spinnerType as any} />}
              <div className={classNames({ [options.hideClassName]: isLoading })} style={{ height: '100%', overflow: 'auto' }}>
                {/* @ts-ignore */}
                <WrappedComponent {...this.props} />
              </div>
            </React.Fragment>
          );
        }
      },
    ) as unknown as void;
  };

export { withProgressSpinner };

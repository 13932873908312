import { uniqBy } from 'lodash';
import { observable, action, computed, makeObservable } from 'mobx';

import { RacerRace } from '../models';

import { RaceName } from '../types';

export class DistancesNamesFilters {
  static distancesNamesDefaultParams = {
    search: '',
    searchFields: 'name:like',
    searchJoin: 'AND',
    orderBy: 'id',
    sortedBy: 'asc',
    limit: 20,
    page: 1,
  };

  static relatedDistancesParams = {
    search: '',
    searchFields: 'race_parent_id:=',
    searchJoin: 'AND',
    orderBy: 'id',
    sortedBy: 'asc',
    limit: 20,
    page: 1,
  };

  @observable
  paginationMeta: PaginationMeta = {};

  @observable
  values: Array<RaceName> = [];

  @observable
  page = 1;

  @observable
  noMoreData = false;

  @observable
  selectionId: number | null = null;

  @observable
  filters: FiltersType = DistancesNamesFilters.distancesNamesDefaultParams;

  relatedFilters: FiltersType = DistancesNamesFilters.relatedDistancesParams;

  constructor() {
    makeObservable(this);
  }

  @action
  addPaginationMeta(meta: PaginationMeta) {
    this.paginationMeta = meta;
  }

  @action
  addValues(values: Array<RaceName>, page: number, filters: FiltersType) {
    this.values = values;
    this.page = page;
    this.filters = filters;

    this.noMoreData = !values.length;
  }

  @action
  appendValues(values: Array<RaceName>, page: number, filters: FiltersType) {
    this.values = uniqBy([...this.values, ...values], 'id');
    this.page = page;
    this.filters = filters;

    const { limit = 0 } = this.filters;

    this.noMoreData = values.length < limit;
  }

  @action
  clearAll() {
    this.values = [];
  }

  @action
  clearRelativeSelection() {
    this.selectionId = null;
  }

  getNameById(id: number, values: Array<RaceName>): string {
    const raceName = values.find((el) => {
      return +el.id === +id;
    });

    if (!raceName) {
      return '';
    }

    const race = new RacerRace(raceName);

    return race.value.name;
  }

  @action
  selectRaceId(id: number): void {
    this.selectionId = id;
  }

  @action
  getDistanceNameById(id: number): string {
    return this.getNameById(id, this.values);
  }

  @computed
  get filteredValues(): Array<RaceName> {
    if (this.selectionId) {
      return this.values.filter((el) => {
        return Number(el.race_parent_id) === Number(this.selectionId);
      });
    }

    return this.values;
  }
}

const distancesNamesFilters = new DistancesNamesFilters();
export { distancesNamesFilters };

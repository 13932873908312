import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import { Input } from 'components/Fields/Input';

type Props = {
  item: RacerType;
  distancesField: DistanceType;
  isEdit: boolean;
  changedFields: AnyObject;
  saveChanges: () => void;
  onChange: (value: any, checkedValue: any) => void;
  currentField: Object;
  value: any;
};

@onClickOutside
class CustomInput extends React.Component<Props> {
  handleClickOutside = (evt: React.MouseEvent) => {
    this.props.saveChanges();
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, item, distancesField } = this.props;
    const { value } = e.target;
    const currentField = item.fields?.find((el) => el.field_id === distancesField.id);
    let currentValue, changedValue;

    if (currentField) {
      currentValue = item.fields?.map((field) => {
        return field.id === currentField.id ? { ...currentField, value: value } : field;
      });
    } else {
      currentValue = [...(item.fields || []), { id: distancesField.id, field_id: distancesField.id, value: value }];
    }

    changedValue = this.getChangedValues(value);
    onChange(currentValue, changedValue);
  };

  getChangedValues = (value: string) => {
    const { distancesField, changedFields } = this.props;
    const changedValue = { id: distancesField.id, values: [value] };

    if ((changedFields.fields || []).find((field: AnyObject) => field.id === distancesField.id)) {
      return changedFields.fields.map((field: AnyObject) => (field.id === distancesField.id ? changedValue : field));
    }

    return [...(changedFields.fields || []), changedValue];
  };

  getContent = () => {
    const { item, distancesField, isEdit } = this.props;
    const currentField = item.fields?.find((itemField) => itemField.field_id === distancesField.id);
    const value = (currentField && currentField.value) || '';

    if (isEdit) {
      return <Input value={value} onChange={this.onChange} errors={[]} name={distancesField.name} />;
    }

    return value;
  };

  render() {
    const { distancesField, value } = this.props;

    return <Input value={value} onChange={this.onChange} errors={[]} name={distancesField.name} />;
  }
}

export { CustomInput };

import { Button, FormControl, FormHelperText } from '@mui/material';
import { withStyles } from '@mui/styles';
import { debounce, isEmpty, mapValues } from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { AuthService } from 'modules';
import * as React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import shortid from 'shortid';

import { Input } from 'src/components/Fields/Input';
import { REGISTRATION, BOOK_DEMO_MEETING_URL, ROUTES } from 'src/constants';

import { withErrorClean } from 'hocs';

import { AuthSubmit } from 'components/core/Buttons';

import { intercomUtil, oneFieldValidation, t, validation } from 'utils';

import { AutocompleteSelect } from '../../../components/Fields';
import { InputWrapper } from '../../../components/Form';
import { TermsSwitcher } from '../components/SignUp';
import { termsWithBase as ValidateConstraints } from '../components/validations';

import { loadCountryService } from '../../../services';

import { countriesStore, errorsStore } from '../../../stores';
import countries from '../../../stores/countries';

import { Props, SignUpState } from '../types';

const action = REGISTRATION;

const DemoStyledButton = withStyles((theme) => ({
  root: {
    height: 40,
    padding: '10px 16px',
    border: `1px solid ${theme.palette.grey6}`,
    borderRadius: 2,
    whiteSpace: 'nowrap',
    '&:focus': {
      color: theme.palette.grey1,
    },
    '&:hover': {
      borderColor: theme.palette.yellow1,
    },
    '&:hover .MuiButton-label': {
      color: theme.palette.yellow1,
    },
  },
  label: {
    color: theme.palette.grey6,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '16px',
    letterSpacing: '1.25px',
  },
}))(Button);

@withRouter
@withErrorClean(action)
@inject('sessionStore')
@inject('errorsStore')
@inject('countriesStore')
@observer
class SignUp extends React.Component<Props, SignUpState> {
  service: AuthService;
  id: string;

  constructor(props: Props) {
    super(props);
    this.service = new AuthService();
    this.id = shortid();
  }

  async componentDidMount() {
    await loadCountryService.loadResources();
    intercomUtil.shutdown();
  }

  componentWillUnmount() {
    intercomUtil.init();
  }

  state = {
    email: '',
    password: '',
    firstname: '',
    lastname: '',
    phone: '',
    country_id: null,
    isTakenCooperation: false,
    isTakenDataProcessing: false,
  };

  @validation({ action, constrains: ValidateConstraints })
  validate() {
    return this.state;
  }

  debouncedValidation = debounce((name: string) => {}, 400);

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { email, password, country_id, firstname, lastname, phone } = this.state;

    const isValid = this.validate();

    if (!isValid || !isEmpty(this.errors())) {
      return;
    }

    this.service.register({
      email,
      password,
      country_id: (country_id as any)?.value as number,
      firstname,
      lastname,
      phone,
    });
  };

  onChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    const name = target.name;
    const value = target.name === 'isTakenCooperation' || target.name === 'isTakenDataProcessing' ? target.checked : target.value;
    this.setState(
      {
        ...this.state,
        [name]: value,
      },
      () => this.debouncedValidation(name),
    );
  };

  errors = (): {
    [K in string]: Array<string>;
  } => {
    const { errorsStore } = this.props;
    const errors = errorsStore.errors.api[action];
    const validationErrors = errorsStore.errors.validation[action];

    if (validationErrors) {
      const { isTakenCooperation, isTakenDataProcessing } = validationErrors.formatErrors();

      if (isTakenCooperation || isTakenDataProcessing) {
        console.log(isTakenCooperation, isTakenDataProcessing);
        let errorTermsKeys = mapValues(validationErrors.formatErrors(), () => [t.staticAsString('shared.errors.required')]);

        return {
          ...validationErrors.formatErrors(),
          ...errorTermsKeys,
        };
      }

      return validationErrors.formatErrors();
    }
    if (errors) {
      return errors.formatErrors();
    }
    return {};
  };

  defaultInputProps = (name: string): any => ({
    name,
    onChange: this.onChange,
    id: this.id,
    value: this.state[name],
    errors: this.errors()[name],
    label: `${t.staticAsString(`registration.${name}` as TranslationLockedKeys)}*`,
  });

  render() {
    return (
      <form className='registration' onSubmit={this.onSubmit}>
        <Input {...this.defaultInputProps('email')} type='email' />
        <Input {...this.defaultInputProps('firstname')} type='text' />
        <Input {...this.defaultInputProps('lastname')} type='text' />
        <Input {...this.defaultInputProps('phone')} type='text' />
        <FormControl sx={{ mb: 1 }} fullWidth>
          <AutocompleteSelect
            className='form-control'
            sx={{
              width: '100%',
            }}
            inputSx={{
              borderRadius: '4px',
            }}
            inputProps={{
              style: {
                color: '#fafafa',
              },
            }}
            reservePlaceForHelperText
            errors={this.errors().country_id}
            options={countriesStore.valuesForCountryDropdown() ?? []}
            variant='outlined'
            fullWidth
            label={t.staticAsString('registration.country') + '*'}
            name='country_id'
            value={this.state.country_id}
            onChange={({ name, value }, event) => {
              this.setState(
                {
                  ...this.state,
                  [name]: value,
                },
                () => this.debouncedValidation(name),
              );
            }}
          />
          <FormHelperText sx={{ color: 'red' }}>{}</FormHelperText>
        </FormControl>
        <Input {...this.defaultInputProps('password')} type='password' />
        <div className='registration-terms'>
          <TermsSwitcher
            inputProps={{ ...{ ...this.defaultInputProps('isTakenCooperation'), label: '' } }}
            nameLink={t.staticAsString('registration.cooperationAgreement')}
            path={ROUTES.cooperationAgreement}
          />
        </div>
        <div className='registration-terms' style={{ marginTop: '10px' }}>
          <TermsSwitcher
            inputProps={{ ...{ ...this.defaultInputProps('isTakenDataProcessing'), label: '' } }}
            nameLink={t.staticAsString('registration.processAgreement')}
            path={ROUTES.processAgreement}
          />
        </div>
        <AuthSubmit label={t.staticAsString('registration.button.sigUp')} />
        <div className='registration-box'>
          <span className='registration-box--text'>{t.staticAsString('registration.hasAccount')}</span>&nbsp;
          <NavLink to='/' className='registration-box--link'>
            {t.staticAsString('registration.logIn.link')}
          </NavLink>
        </div>
        <div className='book-demo-link'>
          <DemoStyledButton variant='outlined' href={BOOK_DEMO_MEETING_URL} {...{ target: '_blank' }}>
            {t.staticAsString('registration.button.BookDemo')}
          </DemoStyledButton>
        </div>
      </form>
    );
  }
}

export { SignUp };

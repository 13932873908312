import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'src/constants/routes';

import { Coupons } from '../components';

import { CouponDetails } from './CoponDetailsContainer';

export const CouponsContainer = () => {
  return (
    <Switch>
      <Route path={ROUTES.couponsRoute} component={Coupons} exact />,
      <Route path={ROUTES.couponRoute} component={CouponDetails} />,
    </Switch>
  );
};

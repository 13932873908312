import { action, observable, computed, makeObservable } from 'mobx';
import { OrderType } from 'modules/Payments/types';

import { Order } from '../models';

export class PaymentsStore {
  @observable
  values: Array<OrderType> = [];

  @observable
  page = 1;

  @observable
  paginationMeta: PaginationMeta = {};

  static defaultFilters = {
    limit: 50,
    searchJoin: 'AND',
    sortedBy: 'DESC',
    orderBy: 'created_at',
  };

  @observable
  filters: FiltersType = PaymentsStore.defaultFilters;

  constructor() {
    makeObservable(this);
  }

  @action
  addValues(values: Array<OrderType>, page: number, filters: FiltersType) {
    this.values = values;
    this.filters = filters;
    this.page = page;
  }

  @action
  appendValues(values: Array<OrderType>, page: number, filters: FiltersType) {
    this.values = this.values = [...this.values, ...values];
    this.filters = filters;
    this.page = page;
  }

  @action
  addPaginationMeta(meta: PaginationMeta) {
    this.paginationMeta = meta;
  }

  @action
  clear(): void {
    this.values = [];
    this.paginationMeta = {};
  }

  @computed
  get payments(): Array<Order> {
    return this.values.map<Order>((payment) => new Order(payment));
  }
}

import { isString } from 'lodash';
import { flow } from 'lodash/fp';

/*
 * Main pipe to gather them all
 */
function pipe(...pipes: InputPipe[]) {
  return (value: any, prevValue: any) => {
    flow(...pipes)([value, prevValue]);
  };
}

function number(value: any, prevValue: any) {
  if (isString(value)) {
    if (!Number(value)) {
      return prevValue;
    }
  }

  return value;
}

pipe.number = number;

export { pipe };

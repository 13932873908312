import { isEmpty } from 'lodash';

import { sessionStore } from 'stores';

import { loadService } from '../services/load';

import { paymentsStore } from '../stores';

export const loadPayments = async (
  params: {
    page?: number;
  } = {},
) => {
  const user = sessionStore.session.user;

  if (isEmpty(user)) {
    return;
  }

  let { filters: storeFilters, page: storePage } = paymentsStore;
  let { limit = storeFilters.limit } = storeFilters;
  let page = params.page || storePage;

  const queryParams = {
    ...storeFilters,
    page,
    limit,
    search: `organizer_id:${user.user_id}`,
    searchFields: 'organizer_id:=',
  };

  const { data, meta } = await loadService.loadPayments(queryParams);

  const paginationMeta = meta?.pagination;

  if (!params.page || params.page === 1) {
    paymentsStore.addValues(data, page, { ...storeFilters, limit });
  } else {
    paymentsStore.appendValues(data, page, { ...storeFilters, limit });
  }

  if (paginationMeta) {
    paymentsStore.addPaginationMeta(paginationMeta);
  }
};
export const loadMore = () => {
  let { page } = paymentsStore;
  loadPayments({ page: page + 1 });
};

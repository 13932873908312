import classNames from 'classnames';

import { CURRENCY_FLAGS, FLAGS } from 'src/constants';

type Props = {
  locale: availableLocales;
  className?: string;
};

export const Flag = ({ locale, className = '' }: Props) => {
  return <span className={classNames('item', 'flag-icon', `flag-icon-${FLAGS[locale]}`, className)}></span>;
};

type CurrencyFlagProps = {
  currency: availableCurrencies;
  className?: string;
};
export const CurrencyFlag = ({ currency, className = '' }: CurrencyFlagProps) => {
  return <span className={classNames('item', 'flag-icon', `flag-icon-${CURRENCY_FLAGS[currency]}`, className)}></span>;
};

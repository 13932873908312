import { EXPORT_DATA, EXPORT_DATA_SIZES } from 'src/constants';

import { t, handleExport } from 'utils';
import { appEnvControl } from 'utils/appEnvironment';

import { exportJobService } from 'services';

import { couponsStore, toastStore } from 'stores';

export const exportData = async (type: string): Promise<void> => {
  const { paginationMeta, params } = couponsStore;
  const { total = EXPORT_DATA.defaultMetaData } = paginationMeta;
  if (total > EXPORT_DATA.metaDataLimit) {
    toastStore.show(t.staticAsString('shared.loadPopup.tooMuchData'));
    return;
  }

  const [status, response] = await exportJobService.exportCoupons(type, params);

  if (!status) {
    return;
  }

  const { id } = response.data.data;
  const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
  let estimatedLoadingTime = total / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second
  if (total > maxTotal) {
    estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
  }

  handleExport({
    id,
    estimatedLoadingTime,
    fileName: `Coupons.${type}`,
    onRetry: () => exportData(type),
  });
};

export const exportSingleCoupon = async (couponId): Promise<void> => {
  const [status, response] = await exportJobService.exportCoupon(couponId);

  if (!status) {
    return;
  }

  const { id } = response.data.data;

  handleExport({
    id,
    estimatedLoadingTime: 3,
    fileName: `Coupon.${couponId}`,
    onRetry: () => exportSingleCoupon(couponId),
  });
};

import { helperDistancesStore } from 'stores';

const MAXIMUM_TEXT_DEFAULT = 255;

const teamValidation = {
  bib_number: {
    numericality: {
      greaterThan: 0,
      message: 'must be greater than 0',
    },
    length: {
      maximum: 5,
    },
  },

  name: {
    presence: {
      allowEmpty: false,
    },
    length: {
      maximum: MAXIMUM_TEXT_DEFAULT,
    },
  },
};

const classValidation = {
  class_id: {
    presence: {
      allowEmpty: false,
    },
  },
};

const team = () => {
  const distance = helperDistancesStore.selected;

  if (distance?.classes?.length) {
    return { ...teamValidation, ...classValidation };
  }

  return teamValidation;
};

export { team, teamValidation };

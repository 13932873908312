import validate from 'validate.js';
import isFunction from 'lodash/isFunction';

const CONSTRAINTS_SCHEME_KEY = '_validation';

type ValidationScheme = {
  [K in string]: ValidationScheme;
};

type Errors = {
  [K in string]: Array<string>;
};

type DataForValidation =
  | {
      [K in string]: any;
    }
  | Array<DataForValidation>;

type SkipRule = (obj: AnyObject) => boolean;

class Validator {
  validationScheme: ValidationScheme;
  prefix: string;
  errors: Errors | nil;
  dataForValidation: DataForValidation;
  skipRule: SkipRule;

  static validate(
    object: {
      [K in any]: any;
    },
    validator:
      | {
          [K in any]: any;
        }
      | Function,
  ):
    | {
        [K in string]: Array<[string]>;
      }
    | null {
    if (!object || !validator)
      // @ts-ignore
      return;

    if (isFunction(validator)) return validate(object, validator(object));
    else return validate(object, validator);
  }

  constructor(dataForValidation: DataForValidation, validationScheme: ValidationScheme, prefix: string | undefined, skipRule: SkipRule) {
    this.validationScheme = validationScheme;
    this.prefix = prefix as string;
    this.dataForValidation = dataForValidation;
    this.errors = undefined;
    this.skipRule = skipRule;

    const errorKeyPrefix = prefix ? `${prefix}.` : '';

    this.validate(dataForValidation, validationScheme, errorKeyPrefix);
  }

  validate(dataForValidation: DataForValidation, generateSchema: ValidationScheme = this.validationScheme, prefix: string = '') {
    let schema = generateSchema;

    if (isFunction(generateSchema)) {
      schema = generateSchema(dataForValidation);
    }

    for (let [key, nestedSchema] of Object.entries(schema)) {
      if (key === CONSTRAINTS_SCHEME_KEY) {
        if (dataForValidation instanceof Array) {
          this.validateArray(dataForValidation, nestedSchema, prefix);
        } else {
          this.validateObject(dataForValidation, nestedSchema, prefix);
        }
      } else {
        if (dataForValidation instanceof Array) {
          dataForValidation.forEach((objectForValidation: any, index) => {
            const nestedErrorKeyPrefix = `${prefix}${index}.${key}.`;
            // @ts-ignore
            this.validate(objectForValidation[key], nestedSchema, nestedErrorKeyPrefix);
          });
        } else if (dataForValidation instanceof Object) {
          const nestedErrorKeyPrefix = `${prefix}${key}.`;
          // @ts-ignore
          this.validate(<any>dataForValidation[key], nestedSchema, nestedErrorKeyPrefix);
        }
      }
    }
  }

  validateObject(dataForValidation: DataForValidation, schema: ValidationScheme = this.validationScheme, prefix: string = '') {
    if (dataForValidation instanceof Array || !dataForValidation) {
      return;
    }

    const isSkipped = this.skipRule(dataForValidation);
    if (isSkipped) {
      return;
    }

    const errors: any = Validator.validate(dataForValidation, schema);
    if (!errors) {
      return;
    }

    this.addErrors(errors as any, prefix);
  }

  validateArray(dataForValidation: DataForValidation, schema: ValidationScheme = this.validationScheme, prefix: string = '') {
    if (!(dataForValidation instanceof Array) || !dataForValidation) {
      return;
    }

    dataForValidation.forEach((obj, index) => {
      this.validateObject(obj, schema, `${prefix}${index}.`);
    });
  }

  addErrors(
    errors: {
      [K in string]: Array<string>;
    },
    prefix: string = '',
  ) {
    if (!this.errors) {
      this.errors = {};
    }

    const errorKeys = Object.keys(errors);

    errorKeys.forEach((key) => {
      (this.errors as any)[`${prefix}${key}`] = errors[key];
    });
  }
}

export { Validator };

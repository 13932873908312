import { isEmpty } from 'lodash';

import { campaignLinksService } from '../services';

import { hasOrganizerLinksStore, campaignLinksStore } from '../stores';

import { Filters, CampaignLinkBE } from '../../shared/types';
import { mapLinkToFE } from '../mappers';

export async function loadLinks(filters?: Filters) {
  const { data, meta } = await campaignLinksService.loadLinksList(filters);
  console.log(data);
  const mappedData = data.map((link: CampaignLinkBE) => mapLinkToFE(link));
  campaignLinksStore.set({ data: mappedData, meta: meta });
}

export async function hasOrganizerLinks() {
  const { data } = await campaignLinksService.loadLinksList();
  !isEmpty(data) ? hasOrganizerLinksStore.on() : hasOrganizerLinksStore.off();
}

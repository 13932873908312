import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

import { SPORT_TYPE } from 'src/constants';
import { IconTooltipWrapper, StyledInfoOutlined, WrapperForm, WrapperSpeed } from 'src/modules/SportTypes/components/EditSportType';

import { withErrorClean } from 'hocs';

import { Input } from 'components/Fields/Input';
import { SelectField } from 'components/Fields/SelectField';

import { t, validation } from 'utils';

import { Errors as ErrorsStore, Progress as ProgressStore } from 'stores';

import { newSportTypeService } from '../../services/newSportType';

import { sportType as sportTypeConstrains } from '../../validations';
import { Props, SportTypeForm, State } from '../Form';
import { IconField } from '../Form/IconField';

type NewState = {
  category: {
    title: string;
    title_en: string;
    icon: Blob | null;
    active: number;
  };
} & State;

type NewProps = {
  errorsStore: ErrorsStore;
  progressStore: ProgressStore;
} & Props;

@withErrorClean(`CREATE_${SPORT_TYPE}`)
@inject('errorsStore', 'progressStore')
@observer
class NewSportType extends SportTypeForm<NewProps, NewState> {
  state = {
    category: {
      title: '',
      title_en: '',
      max_speed: '',
      icon: null,
      active: 1,
    },
  };

  action = `CREATE_${SPORT_TYPE}`;

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.validate();

    const validationErrors = this.props.errorsStore.errors.validation[this.action];
    if (validationErrors && validationErrors.flatErrors().length > 0) {
      return;
    }

    newSportTypeService.create(this.state.category);
  };

  errors = () => {
    const { errorsStore } = this.props;
    const errors = errorsStore.errors.api[this.action];
    const validationErrors = errorsStore.errors.validation[this.action];
    if (validationErrors) {
      return validationErrors.formatErrors();
    }
    if (errors) {
      return errors.formatErrors();
    }
    return {};
  };

  @validation({ action: `CREATE_${SPORT_TYPE}`, constrains: sportTypeConstrains })
  validate() {
    return this.state.category;
  }

  renderForm = () => {
    const { category } = this.state;
    const { progressStore } = this.props;
    const errors = this.errors();

    return (
      <form className='sport-type-form new-form' onSubmit={this.onSubmit}>
        <WrapperForm className='form-content-wrapper'>
          <Input
            id={this.id}
            onChange={this.onChange}
            value={category.title}
            name='title'
            errors={errors.title}
            label={t.staticAsString('categories.name') + ' (SV)'}
          />
          <Input
            id={this.id}
            onChange={this.onChange}
            value={category.title_en}
            name='title_en'
            errors={errors.title_en}
            label={t.staticAsString('categories.name') + ' (EN)'}
          />
          <SelectField
            id={this.id}
            onChange={this.onChange}
            value={category.active}
            name='active'
            errors={errors.status}
            label={t.staticAsString('categories.status')}
            options={this.statusOptions()}
            fullWidth
          />
          <WrapperSpeed>
            <Input
              id={this.id}
              onChange={this.onChange}
              value={category.max_speed}
              name='max_speed'
              errors={errors.max_speed}
              required
              label={t.staticAsString('categories.maxSpeed')}
            />

            <IconTooltipWrapper>
              <Tooltip title={t.staticAsString('categories.maxSpeed.tooltip')}>
                <StyledInfoOutlined />
              </Tooltip>
            </IconTooltipWrapper>
          </WrapperSpeed>

          <IconField name='icon' value={category.icon} onChange={this.onFileChange} errors={errors.icon} fileName />
          <Button className='submit-button' type='submit' disabled={progressStore.isLoading(this.action)}>
            {t.staticAsString('categories.save')}
          </Button>
        </WrapperForm>
      </form>
    );
  };

  render() {
    return (
      <div className='main-sport-type'>
        <div className='toolbar'>
          <h1 className='sport-type-title'>{t.staticAsString('categories.newCategory')}</h1>
        </div>
        <div className='list-wrapper'>{this.renderForm()}</div>
      </div>
    );
  }
}

export { NewSportType };

import moment from 'moment';

import { time } from 'utils';

import { DNS, DNF } from '../../constants';

import { roundMoment } from '../round';

function basic(value: nil | string, emptyStub: string) {
  if (!time.isValid(value as moment.MomentInput, 'datetimems')) {
    return emptyStub;
  }

  const parsedTime = time.datetimems(value as moment.MomentInput).parse();
  // parsedTime.mutate((date) => roundMoment(date));
  return parsedTime.formatAsString(parsedTime.value.milliseconds() ? 'FEdatetimems' : 'BEdatetime');
}

function dns(value: nil | string) {
  return basic(value, DNS);
}

function dnf(value: nil | string) {
  return basic(value, DNF);
}

function empty(value: nil | string) {
  return basic(value, '');
}

/*
 * Format time with specified fallback string
 * 'DNS'/'DNF'/''
 */
const formatTime = {
  basic,
  dns,
  dnf,
  empty,
};

export { formatTime };

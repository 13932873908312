import {
  SportTypes,
  Races,
  Racers,
  Distances,
  Coupons,
  StartLists,
  SystemUsers,
  Customers,
  AdminPayments,
  OrganizerProfile,
  Results,
  SupportTaskManager,
  TransactionsWrapper,
  CommissionsWrapper,
  ReportsWrapper,
  ReportWrapper,
  TransactionWrapper,
  Synchronisation,
  CommissionWrapper,
  SmartLinks,
  CampaignLinks,
  Terms,
} from 'modules';
import { Route, Redirect } from 'react-router-dom';
import shortId from 'shortid';

import { ROUTES } from 'src/constants';

const {
  sportTypeWithOptionalParamRoute,
  raceWithOptionalParamRoute,
  distancesRoute,
  couponsRoute,
  distancesRacersRoute,
  distancesTeamsRoute,
  distancesMembersRoute,
  resultsRoute,
  systemUserWithOptionalParamRoute,
  customersWithOptionalParamRoute,
  paymentsWithOptionalParamRoute,
  profileRoute,
  racesRoute,
  racersRoute,
  supportManagerTasksRoute,
  supportManagerCopyRacers,
  signUp,
  transactionsRoute,
  transactionRoute,
  commissionsRoute,
  reportsRoute,
  reportRoute,
  sync,
  commissionRoute,
  smartLinks,
  campaignLinks,
  processAgreement,
} = ROUTES;

const Admin = [
  <Route key={shortId()} exact path={transactionsRoute} component={TransactionsWrapper} />,
  <Route key={shortId()} exact path={transactionRoute} component={TransactionWrapper} />,
  <Route key={shortId()} exact path={commissionRoute} component={CommissionWrapper} />,
  <Route key={shortId()} exact path={commissionsRoute} component={CommissionsWrapper} />,
  <Route key={shortId()} exact path={reportsRoute} component={ReportsWrapper} />,
  <Route key={shortId()} exact path={reportRoute} component={ReportWrapper} />,
  <Route key={shortId()} path={sportTypeWithOptionalParamRoute} component={SportTypes} />,
  <Route key={shortId()} path={distancesRacersRoute} component={StartLists} />,
  <Route key={shortId()} path={distancesTeamsRoute} component={StartLists} />,
  <Route key={shortId()} path={distancesMembersRoute} component={StartLists} />,
  <Route key={shortId()} path={resultsRoute} component={Results} />,
  <Route key={shortId()} path={racersRoute} component={Racers} />,
  <Route key={shortId()} path={distancesRoute} component={Distances} />,
  <Route key={shortId()} path={raceWithOptionalParamRoute} component={Races} />,
  <Route key={shortId()} path={couponsRoute} component={Coupons} />,
  <Route key={shortId()} path={systemUserWithOptionalParamRoute} component={SystemUsers} />,
  <Route key={shortId()} path={customersWithOptionalParamRoute} component={Customers} />,
  <Route key={shortId()} path={paymentsWithOptionalParamRoute} component={AdminPayments} />,
  <Route key={shortId()} path={profileRoute} component={OrganizerProfile} />,
  <Route key={shortId()} path={supportManagerTasksRoute} component={SupportTaskManager} />,
  <Route key={shortId()} path={supportManagerCopyRacers} component={SupportTaskManager} />,
  <Route key={shortId()} path={sync} component={Synchronisation} />,
  <Route key={shortId()} path={smartLinks} component={SmartLinks} />,
  <Route key={shortId()} path={campaignLinks} component={CampaignLinks} />,
  <Redirect key={shortId()} from={signUp} exact to={racesRoute} />,
  <Route key={shortId()} component={Terms} path={processAgreement} />,
  <Redirect key={shortId()} from={'*'} exact to={racesRoute} />,
];

export default Admin;

// @ts-nocheck
import moment from 'moment';

import { sessionStore } from 'stores';

class Intercom {
  // This will turn off intercom for better future
  turnOff: boolean = true;

  constructor() {
    if (this.turnOff) return;

    const session = JSON.parse(localStorage.getItem('session') as string);
    window.intercomSettings = {
      app_id: 'gdcf2ieg',
      vertical_padding: 100,
    };

    if (session?.token) {
      this.init();
    }
  }

  addUserInfo() {
    if (this.turnOff) return;

    const name = `${sessionStore.session.user.full_name || ''}`;
    const user_id = sessionStore.session.user.user_id || sessionStore.session.user.organizer_id || '';
    const created_at = moment(sessionStore.session.user.created_at).unix();
    const source_website = window.location.hostname;

    window.intercomSettings = {
      ...window.intercomSettings,
      name,
      user_id,
      created_at,
      source_website,
    };

    try {
      window.Intercom('shutdown');
      window.Intercom('boot', window.intercomSettings);
    } catch (e) {
      console.error(e);
    }
  }

  deleteUserInfo() {
    if (this.turnOff) return;

    delete window.intercomSettings.name;
    delete window.intercomSettings.user_id;
    delete window.intercomSettings.created_at;

    this.shutdown();
    this.boot();
  }

  shutdown() {
    if (this.turnOff) return;

    if (!this.isAlive) {
      return;
    }

    try {
      window.Intercom('shutdown');
    } catch (e) {
      console.error('Double intercom shutdown error => ', e);
    } finally {
      this.isAlive = false;
    }
  }

  boot() {
    if (this.turnOff) return;

    window.Intercom('boot');
    this.isAlive = true;
  }

  hide() {
    window.Intercom('update', {
      hide_default_launcher: true,
    });
  }

  restart() {
    if (this.turnOff) return;

    try {
      window.Intercom('shutdown');
      window.Intercom('boot', window.intercomSettings);
    } catch (e) {
      console.error(e);
    }
  }

  changeLanguage(lang: string) {
    window.intercomSettings = {
      ...window.intercomSettings,
      language_override: lang === 'no' ? 'nb' : lang,
    };
  }

  init() {
    if (this.turnOff) return;

    this.isAlive = true;
    const ic = window.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', window.intercomSettings);
    } else {
      const initIntercom = function () {
        initIntercom.calling(arguments);
      };
      initIntercom.query = [];
      initIntercom.calling = function (args) {
        initIntercom.query.push(args);
      };
      window.Intercom = initIntercom;
      this.runIntercom = function () {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://widget.intercom.io/widget/gdcf2ieg';
        const scriptElement = document.getElementsByTagName('script')[0];
        scriptElement.parentNode.insertBefore(script, scriptElement);
      };
    }
    this.runIntercom();
  }
}

const intercomUtil = new Intercom();

export { intercomUtil };

import { OptionWithParentId } from '../types';

export const mapOptions = (list: AnyObject[], distances: SelectOption[], name: string = 'title'): OptionWithParentId[] => {
  return list.map((item) => {
    const distance = distances.find((distance) => distance.key === item.distance_id);
    return {
      key: item.id,
      value: item.id,
      label: `${item[name]} (${distance?.label || ''})`,
      parentId: Number(distance?.key),
    };
  });
};

export const optionsWithParentMap = (data: AnyObject[], distances: SelectOption[]): OptionWithParentId[] => {
  return data.map((item) => {
    const distance = distances.find((distance) => distance.key === item.distance_external_id);
    return {
      key: item.id,
      value: item.name,
      label: distance ? `${item.name} (${distance?.label || ''})` : item.name,
      parentId: Number(distance?.key),
    };
  });
};

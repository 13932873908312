import axios from 'axios';

import { API_HEALTH_CHECK } from 'src/constants';

import { action, request, history } from 'utils';

import { HEALTH_CHECK, DELAY, INITIAL_DELAY } from '../constants';

import { blockRouting, latestValidPath } from '../utils';

import { healthStore } from '../stores';

class HealthCheck {
  @request({ action: HEALTH_CHECK })
  async checkServerStatus(): Promise<Object> {
    return axios.get(API_HEALTH_CHECK);
  }

  @action({ action: HEALTH_CHECK })
  async healthCheck() {
    const unlisten = blockRouting();

    const checkTick = async () => {
      const [status]: string[] | any = await this.checkServerStatus();

      healthStore.setAliveStatus(status);

      if (!status) {
        setTimeout(checkTick, DELAY);
        return;
      }

      unlisten();
      history.replace(latestValidPath());
    };

    setTimeout(checkTick, INITIAL_DELAY);
  }
}

const healthCheckService = new HealthCheck();

export { healthCheckService };

import * as React from 'react';

import { Results as ResultsContainer } from './containers';
import { ResultsContext, resultsContextData } from './context';

const Results = () => (
  <ResultsContext.Provider value={resultsContextData}>
    {/* @ts-ignore intl */}
    <ResultsContainer />
  </ResultsContext.Provider>
);

export { Results };

import shortId from 'shortid';

import { t } from 'utils';

export const optionsBibStartingDistance = (): SelectOption[] => {
  return [
    {
      key: shortId(),
      label: t.staticAsString('supportTaskManager.form.radioClassDistance'),
      value: true,
    },
    {
      key: shortId(),
      label: t.staticAsString('supportTaskManager.form.radioClassClasses'),
      value: false,
    },
  ];
};

export const optionsBibStartingDistanceDisable = (): SelectOption[] => {
  return [
    {
      key: shortId(),
      label: t.staticAsString('supportTaskManager.form.radioClassDistance'),
      value: true,
    },
  ];
};

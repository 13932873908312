import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import { SystemUsersViewActions } from 'modules/SystemUsers/actions/view.actions/systemUsers.view.actions';
import { CommissionsTable } from 'modules/SystemUsers/components/Detail/CommissionsTable';
import React, { ReactElement, useCallback, useState } from 'react';

import { currenciesStore, sessionStore } from 'src/stores';

import { t } from 'utils';

import { SystemUserCommissionsStore } from '../../../../stores/systemUserCommissions.store';
import { SystemUserViewStore } from '../../../../stores/systemUserView.store';

import { CommissionFiltersMapper } from '../../../../mappers/commission-filters.mapper';
import { generateCommissionColumns } from '../Table/Columns';
import { ScrollableTab } from '../Tabs/ScrollableTab';

type Props = {
  currencies: availableCurrencies[];
};

const CommissionsTableWrapper = ({ currencies }: Props): ReactElement => {
  const [currentTab, setCurrentTab] = useState<availableCurrencies>(currencies[0]);

  const currentTabToCurrencyId = (value: availableCurrencies) => {
    return currenciesStore.values.find((c) => c.iso_code === value)?.id ?? 1;
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, value: any) => {
    setCurrentTab(value);
    SystemUsersViewActions.loadSystemUserCommissions({
      page: 1,
      limit: 30,
      ...CommissionFiltersMapper.toBackend(currentTabToCurrencyId(value), []),
    });
  };

  const fetchCommissions = useCallback(({ pageIndex = 0, pageSize, filters, sortBy }) => {
    let orderBy = 'created_at';
    let sortedBy = 'DESC';
    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      orderBy = id.includes('.') ? id.split('.').join('|') : id;
      sortedBy = desc ? 'DESC' : 'ASC';
    }
    SystemUsersViewActions.loadSystemUserCommissions({
      orderBy,
      sortedBy,
      page: pageIndex + 1,
      limit: pageSize,
      ...CommissionFiltersMapper.toBackend(currentTabToCurrencyId(currentTab), filters),
    });
  }, []);

  const showEmptyCommissionBanner = () => {
    return SystemUserViewStore.profile.value?.id === sessionStore.user?.user_id ||
      SystemUserViewStore.profile.value?.id === sessionStore.user?.organizer_id
      ? t.staticAsString('systemUsers.commissions.ownEmptyCommissions')
      : t.staticAsString('systemUsers.commissions.emptyCommissions');
  };

  return (
    <div style={{ padding: 0, backgroundColor: 'white' }} className='content main-container-list races-list'>
      {isEmpty(currencies) ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', p: 3 }}>
          <Typography sx={{ fontSize: 18, fontWeight: 500, mr: 1 }}>{showEmptyCommissionBanner()}</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ p: '0 20px' }}>
            <ScrollableTab onChange={handleChangeTab} value={currentTab} currencies={currencies} />
          </Box>

          <Observer
            render={() => {
              return (
                <CommissionsTable
                  fetchCommissions={fetchCommissions}
                  tableData={SystemUserCommissionsStore.commissions.value?.data || []}
                  tableMeta={SystemUserCommissionsStore.commissions.value?.meta ?? {}}
                  initialSizeOfPage={30}
                  selectedCurrencyId={currentTabToCurrencyId(currentTab)}
                  pageSizeOptions={[30, 50, 100, 300]}
                  tableColumns={generateCommissionColumns()}
                />
              );
            }}
          />
        </>
      )}
    </div>
  );
};

export { CommissionsTableWrapper };

import { Box } from '@mui/material';
import { ReportsWrapper } from 'modules';
import { Coupons } from 'modules/Coupons';
import { Dashboard } from 'modules/Dashboard';
import { OrganizerPayments } from 'modules/Payments';
import { Racers } from 'modules/Racers';
import { SmartLinks } from 'modules/SmartLinks';
import { SystemUsers } from 'modules/SystemUsers/containers/Base';
import React, { createElement, useEffect, useState } from 'react';

import { Spinner } from 'components/Spinner';
import { Upsell, UpsellPage } from 'components/Upsell/Upsell';

import { upsell } from 'actions/upsell';

type Props = {
  page: UpsellPage;
};

const components: { [K in UpsellPage]: any } = {
  ['dashboard']: Dashboard,
  ['transactions']: OrganizerPayments,
  ['coupons']: Coupons,
  ['raceAssistants']: SystemUsers,
  ['racers']: Racers,
  ['smartRegistrationLinks']: SmartLinks,
  ['reports']: ReportsWrapper,
};
const UpsellWrapper = ({ page }: Props) => {
  const [showUpsell, setShowUpsell] = useState<boolean>(false);
  const [calendarRaces, setCalendarRaces] = useState<Array<RaceType>>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const load = async () => {
      const { showUpsell, calendarRaces } = await upsell.init();
      setShowUpsell(showUpsell);
      if (calendarRaces) {
        setCalendarRaces(calendarRaces);
      }
      setIsLoading(false);
    };
    load();
  }, []);

  if (isLoading) {
    return (
      <Box>
        <Spinner type='cubesSpinner' />
      </Box>
    );
  }

  return showUpsell ? <Upsell page={page} calendarRaces={calendarRaces} /> : createElement(components[page]);
};

export { UpsellWrapper };

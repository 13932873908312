import { googleTranslate } from 'actions';

type GetGoogleTranslations = (from: availableLocales, to: availableLocales, data?: { [k: string]: string }) => object;

export const getGoogleTranslations: GetGoogleTranslations = async (from, to, data) => {
  if (!data) {
    return {};
  }

  const entries = Object.entries(data);

  const translationsResult = await googleTranslate({
    q: entries.map(([, value]) => value),
    from,
    to,
  });

  return entries.reduce((acc, [key], i) => {
    const newKey = `${to}${key.slice(2)}`;
    acc[newKey] = translationsResult[i].text;
    return acc;
  }, {});
};

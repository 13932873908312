import { app } from 'utils';

import { exportService } from '../services';

/**
 * @description
 * Perform export of receipt pdf
 * export will be performed via old export logic (popup window shall be opened
 */
export const generateOnExportReceipt = (id: number, url?: string) => () => {
  app.initDownloadTab();

  if (url) {
    if (window.downloadWindow) {
      window.downloadWindow.location = url as any as Location;
    }
    return;
  }

  return (async () => {
    const url = await exportService.exportReceipt(id);

    if (!url) {
      window.downloadWindow && window.downloadWindow.close();
      return;
    }

    if (window.downloadWindow) {
      window.downloadWindow.location = url as any as Location;
    }
  })();
};

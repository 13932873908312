import React from 'react';

import { DatePicker } from 'components/Form';

import { FilterWrapper } from './FilterWrapper';

export const DateFilter = (props) => {
  const {
    column: { filterValue, setFilter, render },
    closeFilter = () => {},
  } = props;
  return (
    <FilterWrapper>
      <DatePicker
        hideArrow
        view={{
          fullWidth: true,
          readOnly: true,
          variant: 'outlined',
          size: 'small',
        }}
        label={render('Header')}
        name='date_picker'
        value={filterValue}
        onChange={({ name, value }) => {
          setFilter(value);
          closeFilter();
        }}
        additional={{ autoFocus: true }}
      />
    </FilterWrapper>
  );
};

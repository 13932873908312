import { racersService } from '../services';

import { racesNamesFilters, distancesNamesFilters } from '../stores';

export const loadRacesNames = async (
  params: {
    limit?: number;
    page?: number;
    search?: string;
  } = {},
) => {
  let { page: storePage, filters: storeFilters } = racesNamesFilters;

  const { search: storeSearch } = storeFilters;

  let { limit = storeFilters.limit, page = storePage, search = storeSearch } = params;

  if (params.limit) {
    page = 1;
  }

  const queryParams = {
    ...storeFilters,
    page,
    limit,
    search,
  };

  const [status, { data, meta }] = await racersService.loadRacesNames(queryParams);

  if (!status) return;

  racesNamesFilters.appendValues(data, page, {
    ...storeFilters,
    limit,
    search,
  });

  const paginationMeta = meta && meta.pagination;

  if (paginationMeta) {
    racesNamesFilters.addPaginationMeta(paginationMeta);
  }
};

export const loadAndSelectRelatedRaceToDistance = async (distanceId: number) => {
  // When user selects distance,
  // keep only races (actually one race)
  // related to this distance in race name filters
  const { relatedFilters, filters } = racesNamesFilters;

  const distanceData = distancesNamesFilters.values.find((distance) => +distance.id === +distanceId);

  if (!distanceData) return;

  const { race_parent_id: id = 0 } = distanceData;

  const queryParams = {
    ...relatedFilters,
    search: `id:${id}`,
  };

  const [status, { data }] = await racersService.loadRacesNames(queryParams);

  if (!status) return;

  racesNamesFilters.appendValues(data, 1, filters);
  racesNamesFilters.selectRaceId(id);
};

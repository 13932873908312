import { observer } from 'mobx-react';
import * as React from 'react';

import { FilterAutocompleteAsyncLoad } from 'components/Filter/DefaultFilters';

import { squadsService } from 'services';

import { squadsStore } from 'stores';

type Props = {
  value: nil | string | number;
} & FilterProps;

@observer
class SquadsComponent extends React.Component<Props> {
  distanceId = squadsService.store.selectedDistanceId;
  loadMore = () => {
    this.distanceId && squadsService.loadDistanceSquads(this.distanceId);
  };

  searchFilter = (text: string) => {
    squadsService.store.clearAll();
    squadsService.store.setFilters(
      {
        search: `name:${text}`,
        searchFields: `name:like`,
      },
      1,
    );
    this.distanceId && squadsService.loadDistanceSquads(this.distanceId);
  };

  render() {
    const { props } = this;
    const options = squadsStore.valuesForDropdown();

    return (
      <FilterAutocompleteAsyncLoad
        {...props}
        noMoreData={!squadsService.store.hasMore}
        options={options}
        loadMore={this.loadMore}
        search={this.searchFilter}
      />
    );
  }
}

export const squads: FilterListDescriptor = {
  filter: {
    persistance: {
      handler: 'like',

      calcValue: function (name, value) {
        return value;
      },

      prettify: function (name, value) {
        return squadsStore.formatValue(value);
      },
    },
  },
  frontend: {
    type: 'custom',
    component: SquadsComponent,
  },
};

import { cloneDeep } from 'lodash';
import { getActiveCheckPoint } from 'modules/Distances/components/shared/Steps/GPX/utils';

import { validateCheckpointCoordinates } from 'src/modules/Distances/components/shared/Steps/GPX/actions/validateCheckpointCoordinates';
import { gpxGoogleMapStore, gpxStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { CheckPoint, GpxGoogleMap, GpxRoute } from 'src/modules/Distances/components/shared/Steps/GPX/types';

import { globalMap } from '../MapView';

export const drawTrack = () => {
  // const { map, maps } = gpxGoogleMapStore.value as GpxGoogleMap;
  const { map, maps } = globalMap;

  const { coordinates } = gpxStore.value as GpxRoute;

  const line = new (maps as any).Polyline({
    path: coordinates,
    geodesic: true,
    strokeColor: '#DDA100',
    strokeOpacity: 1.0,
    strokeWeight: 4,
  });

  line.addListener('click', onClick.bind(this));
  line.setMap(map);
};

const onClick = (event: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => {
  event.stop();
  const coordinate = {
    lat: Number(event.latLng?.lat().toFixed(6)),
    lng: Number(event.latLng?.lng().toFixed(6)),
    alt: 0,
  };

  let checkpoint = cloneDeep(getActiveCheckPoint()) as CheckPoint;
  checkpoint.coordinate = coordinate;

  validateCheckpointCoordinates(checkpoint);
};

import { isEqual, values } from 'lodash';
import { observable, action, computed, makeObservable } from 'mobx';

import { SORT_BY } from 'src/constants';

type Value = {
  name: string;
  sortBy: SortTypes;
  params: nil | AnyObject;
};

const REVERSE_SORTING = {
  [SORT_BY.asc]: SORT_BY.desc,
  [SORT_BY.desc]: SORT_BY.asc,
};

class Sort implements Extensionable {
  @observable
  value: nil | Value;
  filterDescriptors: WrappedFiltersListDescriptors;

  constructor(filterDescriptors: WrappedFiltersListDescriptors) {
    makeObservable(this);
    this.filterDescriptors = filterDescriptors;
    this.reset();
  }

  @action
  async applyValue(name: string, params?: Object): Promise<void> {
    const isInvertFilter = this.isActive(name, params);
    let sortBy: SortTypes = SORT_BY.asc;
    const currentSortBy = this.value?.sortBy;

    if (isInvertFilter && currentSortBy) {
      sortBy = REVERSE_SORTING[currentSortBy];
    }

    this.value = { name, params, sortBy };
  }

  @computed
  get params(): FiltersType {
    if (!this.value) {
      return {};
    }

    const { name, sortBy, params } = this.value;
    const descriptor = this.filterDescriptors[name];

    if (!descriptor) {
      return {};
    }

    return {
      orderBy: descriptor.calculateOrderBy(sortBy, params),
      sortedBy: sortBy,
    };
  }

  @action
  async reset(): Promise<void> {
    const { filterDescriptors } = this;

    const value = Sort.defaultSort(filterDescriptors);

    if (!value) {
      return;
    }

    this.value = value;
  }

  isActive(name: string, params?: Object) {
    return name === this.value?.name && isEqual(params, this.value?.params);
  }

  static defaultSort(descriptors: WrappedFiltersListDescriptors): nil | Value {
    const defaultDescriptor = values(descriptors).find((descriptor) => {
      return !!descriptor.sortDescriptor().default;
    });

    if (!defaultDescriptor) {
      return;
    }

    const defaultSortBy = defaultDescriptor.sortDescriptor().defaultBy || SORT_BY.asc;

    return {
      name: defaultDescriptor.name,
      sortBy: defaultSortBy,
      params: undefined,
    };
  }
}

export { Sort, Sort as SortStore };

import { Result, Split } from 'models';

import { DNS, DNF } from '../constants';

import { ResultsContextType } from '../context';
import { isDNSContext, isDNFContext, formatTime, formatDuration } from '../tools';

// Possible render values - DNS, DNF, timestamp
function formatStartTime(value: nil | string, result: Result, context: ResultsContextType) {
  if (isDNSContext(result, context)) {
    return DNS;
  }

  return formatTime.empty(value);
}

// Possible render values - DNS, DNF, timestamp
function formatFinishTime(value: nil | string, result: Result, context: ResultsContextType) {
  if (result.isHardDnf()) {
    return DNF;
  }

  if (isDNSContext(result, context)) {
    return DNS;
  }

  if (isDNFContext(result, context)) {
    return DNF;
  }

  return formatTime.empty(value);
}

// Possible render values - DNS, DNF, diff time
function formatDiff(start: nil | string, finish: nil | string, result: Result, context: ResultsContextType) {
  if (result.isHardDnf()) {
    return DNF;
  }

  if (isDNSContext(result, context)) {
    return DNS;
  }

  if (isDNFContext(result, context)) {
    return DNF;
  }

  return formatDuration.stub(start, finish);
}

function formatPlace(model: Result, context: ResultsContextType) {
  if (model.isHardDnf()) {
    return DNF;
  }

  if (isDNSContext(model, context)) {
    return DNS;
  }

  if (isDNFContext(model, context)) {
    return DNF;
  }

  return model.value.place;
}

function formatSplitDiff(split: nil | Split, start: nil | string, finish: nil | string, result: Result, context: ResultsContextType) {
  if (result.isHardDnf()) {
    return DNF;
  }

  if (isDNSContext(result, context)) {
    return DNS;
  }

  if (isDNFContext(result, context) && split && split.isLast) {
    return DNF;
  }

  return formatDuration.stub(start, finish);
}

export { formatStartTime, formatFinishTime, formatDiff, formatPlace, formatSplitDiff };

import { DEFAULT_LOCALE } from 'src/constants';

import { cache } from './cache';
import { MESSAGES_KEY } from './constants';

function store(value: Translations) {
  try {
    const oldData = fetch() || {};
    const data = JSON.stringify({ ...oldData, ...value });

    localStorage.setItem(MESSAGES_KEY, data);
  } catch (e) {}
}

function fetch(): null | Translations {
  try {
    const data = localStorage.getItem(MESSAGES_KEY);
    const translations: Translations = JSON.parse(data || '');

    return translations;
  } catch (e) {
    return null;
  }
}

function clear() {
  const defaultMessages = cache.fetch()[DEFAULT_LOCALE];

  if (!defaultMessages) {
    return localStorage.setItem(MESSAGES_KEY, '');
  }

  return store({ [DEFAULT_LOCALE]: defaultMessages } as Translations);
}

const util = {
  fetch,
  store,
  clear,
};

export { util as store };

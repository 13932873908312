import { Popover, Paper, TableHead, TableRow } from '@mui/material';
import { isEmpty } from 'lodash';
import { observer, inject } from 'mobx-react';
import * as React from 'react';

import { ThTd } from 'components/Filter/ThTd';

import { t } from 'utils';

import { Customer as CustomerStore } from 'stores';

import { loadService } from '../../../services/load';

import { Filter } from './Filters';

type Props = {
  customersStore: CustomerStore;
};

type State = {
  selectedFilter: Name | null;
  anchorEl: nil | Element;
};

type Name = 'id' | 'full_name' | 'email' | 'telephone' | 'post_code' | 'country_id' | 'created_at';

const POPPER_RELATIVE_Y_POSITION = -12;
const POPPER_RELATIVE_X_POSITION = 0;

const COLUMNS = ['action', 'id', 'full_name', 'email', 'telephone', 'post_code', 'country_id', 'created_at'];

@inject('customersStore')
@observer
class THead extends React.Component<Props, State> {
  state: State = {
    selectedFilter: null,
    anchorEl: null,
  };

  static defaultProps = {
    customersStore: null as any,
  };

  handleShowFilter = (e: nil | React.SyntheticEvent = null, selectedFilter: Name | null = null) => {
    this.setState({
      selectedFilter,
      anchorEl: e ? e.currentTarget : null,
    });
  };

  onModalClose = () => this.handleShowFilter();

  applyFilter = (...filters: Array<FiltersType>) => {
    const hideFilter = () => this.handleShowFilter();
    loadService.applySearch(hideFilter, ...filters);
  };

  clearFilters = () => {
    const { customersStore } = this.props;
    const searchData = customersStore.searchDataModel.search;

    if (isEmpty(searchData)) {
      return null;
    }

    const clear = () => {
      loadService.clearFilters();
    };

    return (
      <li onClick={clear} className='reset-filters'>
        {t.staticAsString('races.filters.resetAll')}
      </li>
    );
  };

  updateSearchHandlers = (
    handlers: {
      [K in string]: string;
    },
  ) => {
    const { customersStore } = this.props;
    customersStore.updateSearchHandlers(handlers);
  };

  _renderThLabels = (): React.ReactNode => {
    const { customersStore } = this.props;
    const searchData = customersStore.searchDataModel.search;

    return COLUMNS.map((param) => {
      const label = param && t.staticAsString(`customers.list.table.${param}` as TranslationLockedKeys);
      // $FlowFixMe
      return (
        <ThTd
          key={param}
          name={param}
          values={searchData}
          className='customer-table-header filter-item'
          label={label}
          value={searchData[param] || ''}
          onFilterShow={this.handleShowFilter}
          disabled={!Filter.types.includes(param)}
        />
      );
    });
  };

  _renderFilterPopover = (): React.ReactNode => {
    const { customersStore } = this.props;
    const { selectedFilter, anchorEl } = this.state;

    return (
      <Popover
        className='material-popover'
        classes={{ paper: 'popover-paper' }}
        anchorEl={anchorEl}
        open={!!this.state.selectedFilter}
        onClose={this.onModalClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: POPPER_RELATIVE_Y_POSITION,
          horizontal: this.state.selectedFilter === 'country_id' ? 100 : POPPER_RELATIVE_X_POSITION,
        }}
      >
        <Paper className='main-layout'>
          {selectedFilter && (
            <Filter
              name={selectedFilter}
              value={customersStore.searchDataModel.search[selectedFilter]}
              values={customersStore.searchDataModel.search}
              label={t.staticAsString(`customers.list.table.${selectedFilter}` as TranslationLockedKeys)}
              applyFilter={this.applyFilter}
              updateSearchHandlers={this.updateSearchHandlers}
            />
          )}
        </Paper>
      </Popover>
    );
  };

  render() {
    return (
      <React.Fragment>
        <TableHead>
          <TableRow>{this._renderThLabels()}</TableRow>
        </TableHead>
        {this._renderFilterPopover()}
      </React.Fragment>
    );
  }
}

export { THead };

import { t } from 'utils';

export const squadValidation: AnyObject = {
  presence: {
    allowEmpty: false,
    message: () => t.staticAsString('shared.errors.presence'),
  },
  length: {
    minimum: 3,
    maximum: 255,
  },
};

import { Chip } from '@mui/material';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { formatArray, classes } from 'components/Filter/ChipFilterLabel';

import { sportTypesAuxiliaryStore } from 'stores';

type Props = {
  value: FilterType;
  name: string;
  onClear: (filterNames: Array<string>) => void;
  className: string;
  values: FiltersType;
};

const formatSportTypeLabel = (value: number): string => {
  const sportType = sportTypesAuxiliaryStore.findModelValue(value);
  if (!sportType) {
    return '';
  }

  return sportType.title() || `${value}`;
};

const formatValue = (value: FilterType | string | any): string => {
  if (typeof value === 'string') {
    return formatValue(parseInt(value, 10));
  } else if (typeof value === 'number') {
    return formatSportTypeLabel(value);
  } else if (value instanceof Date) {
    return '';
  } else if (value instanceof Array) {
    return formatArray(value);
  } else {
    return `${value}` || '';
  }
};

let SportTypeFilterLabel = ({ value, name, onClear, className }: Props) => {
  if (!value) {
    return null;
  }

  return (
    <Chip className={classNames(className, 'chip-card')} classes={classes} onDelete={() => onClear([name])} label={formatValue(value)} />
  );
};

SportTypeFilterLabel = observer(SportTypeFilterLabel);

export { SportTypeFilterLabel };

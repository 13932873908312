import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GetApp from '@mui/icons-material/GetApp';
import { Button, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { onExportTemplate } from 'modules/StartList/actions';
import { exportTempalateType } from 'modules/StartList/types';

import { EXPORT_DATA_SIZES, CONFIRM_POPUP_TYPES } from 'src/constants/mixed';

import { Show } from 'components/Condition';
import { Icon } from 'components/Icon';

import { t } from 'utils';
import { appEnvControl } from 'utils/appEnvironment';

import { Distance } from 'models';

import confirmModalStore from 'stores/confirmationModal';
import locale from 'stores/locale';

import { ExportMenu } from '../Nav/ExportMenu';
import { ImportMenu } from '../Nav/ImportMenu';

const ButtonsComponent = ({
  type,
  distance,
  exportFile,
  onEditDistance,
  onDeleteDistance,
  totalEntries,
  shouldHideExportButton = false,
  exportListKey,
  exportWavesKey,
}: {
  type: exportTempalateType;
  exportFile: (type: string) => void;
  onEditDistance: () => void;
  onDeleteDistance: () => void;
  totalEntries: number;
  shouldHideExportButton?: boolean;
  distance?: Distance;
  exportListKey?: string;
  exportWavesKey?: string;
}) => {
  const confirmExport = (type: string, exportFunction: AnyFunction, withWaves?: boolean) => () => {
    if (totalEntries <= EXPORT_DATA_SIZES[appEnvControl.currentEnvWithStub()]) return exportFunction(type, withWaves);

    confirmModalStore.openModal({
      title: t.staticAsString('shared.exportConfirm.title') as any,
      body: t.staticAsString('shared.exportConfirm.body', {
        maxAmount: EXPORT_DATA_SIZES[appEnvControl.currentEnvWithStub()].toLocaleString(locale.value),
      }) as any,
      type: CONFIRM_POPUP_TYPES.confirm,
      onConfirm: () => exportFunction(type, withWaves),
    });
  };

  const withWaves = distance?.relationForSelect && !isEmpty(distance.relationForSelect('waves'));

  return (
    <div className='export-buttons'>
      <ul className='list-buttons'>
        <Button className='button-item' onClick={() => onExportTemplate(type)}>
          <Tooltip title={t.staticAsString('startlist.tooltip.template')} classes={{ tooltip: 'switch-popover' }}>
            <div>
              <div className='button-icon'>
                <Icon value='file-download' />
              </div>
              <div className='button-title'>{t.staticAsString('startlist.template')}</div>
            </div>
          </Tooltip>
        </Button>
        <ImportMenu startListType={type} />
        <Show if={Boolean(!shouldHideExportButton && withWaves)}>
          <ExportMenu
            exportListKey={exportListKey || 'startlist.export.racers'}
            exportWavesKey={exportWavesKey || 'startlist.export.waves'}
            onExportWaves={confirmExport('xlsx', exportFile, true)}
            onExportXlsx={confirmExport('xlsx', exportFile)}
          />
        </Show>
        <Show if={!shouldHideExportButton && !withWaves}>
          <Button className='button-item' onClick={confirmExport('xlsx', exportFile)}>
            <Tooltip title={t.staticAsString('startlist.tooltip.export')}>
              <div>
                <div className='button-icon'>
                  <GetApp />
                </div>
                <div className='button-title'>{t.staticAsString('racers.list.xlsx')}</div>
              </div>
            </Tooltip>
          </Button>
        </Show>
        <Button className='button-item' onClick={() => onEditDistance()}>
          <div>
            <div className='button-icon'>
              <EditIcon />
            </div>
            <div className='button-title'>{t.staticAsString('racers.list.edit')}</div>
          </div>
        </Button>
        <Button className='button-item' onClick={() => onDeleteDistance()}>
          <div>
            <div className='button-icon'>
              <DeleteIcon />
            </div>
            <div className='button-title'>{t.staticAsString('racers.list.delete')}</div>
          </div>
        </Button>
      </ul>
    </div>
  );
};

export const Buttons = observer(ButtonsComponent);

import { Moment } from 'moment';

import { BE_DATETIME_FORMAT } from 'src/constants';
import { currenciesStore } from 'src/stores';

import { SystemUserViewStore } from '../stores/systemUserView.store';

const DateFields = ['created_at'];
const EqualFields = ['status'];
const LikeFields = ['email', 'firstname', 'lastname', 'distance_title', 'external_id', 'currency', 'type'];
const BetweenFields = ['id', 'total', 'discount'];

export namespace PaymentFiltersMapper {
  export function toBackend(filters) {
    const organizerId = SystemUserViewStore.profile.value?.id;
    const searchFilters = filters.reduce(
      (acc, { id, value }) => {
        if (DateFields.includes(id)) {
          acc.search += `${id}:${mapDaterange(value.startDate, value.endDate)};`;
          acc.searchFields += `${id}:between;`;
        }
        if (LikeFields.includes(id)) {
          if (id === 'currency') value = currenciesStore.currencyById(value)?.iso_code;
          acc.search += `${id}:${value};`;
          acc.searchFields += `${id}:like;`;
        }
        if (EqualFields.includes(id)) {
          acc.search += `${id}:${value};`;
          acc.searchFields += `${id}:=;`;
        }
        if (BetweenFields.includes(id)) {
          switch (id) {
            case 'discount':
            case 'total':
            case 'id':
              let [start, end] = value;

              if (start === undefined) {
                if (id === 'total') end = Number(end) * 100;
                acc.search += `${id}:${end};`;
                acc.searchFields += `${id}:<=;`;
                break;
              }
              if (end === undefined) {
                if (id === 'total') start = Number(start) * 100;
                acc.search += `${id}:${start};`;
                acc.searchFields += `${id}:>=;`;
                break;
              }
            default:
              if (id === 'total') value = value.map((item) => item * 100);
              if (id === 'discount') value = value.map((item) => item * 100);
              acc.search += `${id}:${value.join(',')};`;
              acc.searchFields += `${id}:between;`;
          }
        }

        return acc;
      },
      { search: '', searchFields: '' },
    );

    searchFilters.search = searchFilters.search.substring(0, searchFilters.search.length - 1);
    searchFilters.searchFields = searchFilters.searchFields.substring(0, searchFilters.searchFields.length - 1);

    searchFilters.search = `organizer_id:${organizerId}${searchFilters.search ? `;${searchFilters.search}` : ''}`;
    searchFilters.searchFields = `organizer_id:=${searchFilters.searchFields ? `;${searchFilters.searchFields}` : ''}`;

    return searchFilters;
  }
}

const mapDaterange = (start: Moment, end: Moment): string => {
  return `${start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(BE_DATETIME_FORMAT)},${end
    .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    .format(BE_DATETIME_FORMAT)}`;
};

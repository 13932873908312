import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { choseSyncType } from 'modules/Synchronisation/Table/actions/list';
import { SynchronizationsConstants } from 'modules/Synchronisation/Table/constants/synchronizations.constants';
import { SynchronizationTypeEnum } from 'modules/Synchronisation/Table/types/synchronization.type';
import * as React from 'react';

import { Form, InputWrapper, Select } from 'components/Form';
import { Button } from 'components/core';

import { t } from 'utils';

import { form } from 'stores';

const DialogTitleStyled = withStyles({
  root: {
    fontSize: 20,
    borderBottom: '1px solid #e0e0e0',
    fontWeight: 500,
  },
})(DialogTitle);

const DialogStyled = styled(Dialog)(({ theme }) => ({
  fontSize: 20,
  borderBottom: '1px solid #e0e0e0',
  fontWeight: 500,
  '& .MuiDialog-paperFullWidth': {
    maxWidth: '770px',
  },
}));

const DialogActionsStyled = withStyles({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 24,
    paddingLeft: 24,
    borderTop: '1px solid #e0e0e0',
  },
  spacing: {
    '&>:not(:first-child)': {
      marginLeft: 16,
    },
  },
})(DialogActions);

export function CreateSyncModal({ open, onClose }: { open: boolean; onClose: Function }) {
  const onChangeSyncType = ({ value, name }) => {
    form.onChange(SynchronizationsConstants.SynchronizationForm, name, value);
  };

  const handleClose = () => {
    onClose();
  };

  const getSyncTypes = () => {
    return [
      {
        key: SynchronizationTypeEnum.EQTiming,
        label: SynchronizationsConstants.SynchronizationType[SynchronizationTypeEnum.EQTiming],
        value: SynchronizationTypeEnum.EQTiming,
      },
      {
        key: SynchronizationTypeEnum.RaceResult,
        label: SynchronizationsConstants.SynchronizationType[SynchronizationTypeEnum.RaceResult],
        value: SynchronizationTypeEnum.RaceResult,
      },
    ];
  };

  return (
    <div>
      <DialogStyled fullWidth open={open} onClose={handleClose}>
        <DialogTitleStyled>Choose synchronization type</DialogTitleStyled>
        <DialogContent sx={{ padding: 0 }}>
          <Form id={SynchronizationsConstants.SynchronizationForm}>
            <Box sx={{ padding: '24px 24px 8px' }}>
              <InputWrapper
                name='type'
                settings={{
                  placeholder: t.staticAsString('sync.modal.field.chooseSyncType'),
                  label: t.staticAsString('sync.modal.field.chooseSyncType'),
                  options: getSyncTypes(),
                  onChange: onChangeSyncType,
                  additional: {
                    fullWidth: true,
                  },
                  view: {
                    variant: 'outlined',
                  },
                }}
                Component={Select}
              />
            </Box>
          </Form>
        </DialogContent>

        <DialogActionsStyled>
          <Button type='cancel' onClick={handleClose}>
            {t.staticAsString('shared.cancel')}
          </Button>
          <Button type='apply' onClick={choseSyncType}>
            {t.staticAsString('shared.confirmPopup.btnAction.confirm')}
          </Button>
        </DialogActionsStyled>
      </DialogStyled>
    </div>
  );
}

// Various BE time and date formats
// to parse and format the date;

export const BE_DATE_FORMAT = 'YYYY-MM-DD';
export const BE_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const BE_DATETIME_MS_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';
export const FE_DATETIME_MS_FORMAT = 'YYYY-MM-DD HH:mm:ss.SS';
export const BE_DURATION_MS_FORMAT = 'HH:mm:ss.SSS';

export const BE_FORMAT: {
  [K in BEDateFormat]: string;
} = {
  date: BE_DATE_FORMAT,
  datetime: BE_DATETIME_FORMAT,
  datetimems: BE_DATETIME_MS_FORMAT,
  feDateTimeMs: FE_DATETIME_MS_FORMAT,
  timeWithMilliseconds: BE_DURATION_MS_FORMAT,
};

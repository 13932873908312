import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const formStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    paddingBottom: 20,
    maxWidth: 441,
    margin: '0 auto',
    width: '100%',
  },
  wrapperNoPadding: {
    maxWidth: 441,
    margin: '0 auto',
    width: '100%',
  },
  wrapperFullWidth: {
    paddingBottom: 20,
    margin: '0 auto',
    padding: '0 16px',
    width: '100%',
  },
  wrapperFullWidthDoublePadding: {
    paddingBottom: 40,
    margin: '0 auto',
    padding: '0 16px',
    width: '100%',
  },
  wrapperDoublePadding: {
    paddingBottom: 40,
    maxWidth: 441,
    margin: '0 auto',
    width: '100%',
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 16px',

    '& .secondary': {
      fontSize: '14px',
      color: `${theme.palette.textPrimary}`,
    },
  },
  leftButtonGroup: {
    justifyContent: 'flex-start',
  },
  rightButtonGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  helperText: {
    color: theme.palette.grey1,
    textAlign: 'center',
    maxWidth: 441,
    fontSize: 14,
    margin: '0 auto',
  },
  stepTitle: {
    textTransform: 'uppercase',
    color: theme.palette.grey1,
    fontSize: 16,
    fontWeight: 500,
  },
  tooltip: {
    fontSize: 14,
    '& .tooltip-link': {
      color: '#fff',
      fontWeight: 500,
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

import { dateRange } from './dateRange';
import { stub } from './stub';

type DefaultPropsGenerator = Function & {
  default: Function;
} & {
  generate: Function;
};

/* NOTE
 * Props that can modify filters component and chips component behavior
 * Passes for prettify and for the component props
 */
const filterListProps: {
  [K in FrontendListFilter]: DefaultPropsGenerator;
} = {
  dateRange,
  string: stub,
  none: stub,
  numberRange: stub,
  priceRange: stub,
  custom: stub,
};

export { filterListProps };

import { cloneDeep } from 'lodash';

export const mapErrorMergeAccounts = (data: AnyObject): RawError => {
  const response = cloneDeep(data);
  let errors: AnyObject = [];

  if (response.delete_source_account) {
    errors.deleteSourceAccount = response.delete_source_account;
  }

  if (response.source_email) {
    errors.sourceEmail = response.source_email;
  }

  if (response.target_email) {
    errors.targetEmail = response.target_email;
  }

  return errors;
};

import { Observer } from 'mobx-react-lite';
import { loadMoreRaces, searchRaces } from 'modules/SmartLinks/Form/actions';
import * as React from 'react';

import { AutocompleteSelectInfinityLoad, InputWrapper } from 'src/components/Form';

import { t } from 'utils/t';

import { racesHasMoreStore, racesStore } from '../stores';

export const RaceField = () => {
  return (
    <Observer>
      {() => {
        return (
          <InputWrapper
            name='race_id'
            settings={{
              label: t.staticAsString('dashboard.event'),
              additional: {
                options: racesStore.value || [],
              },
              view: {
                reservePlaceForHelperText: true,
                required: true,
              },
              hasMore: racesHasMoreStore.value,
              loadMore: loadMoreRaces,
              onSearch: searchRaces,
            }}
            Component={AutocompleteSelectInfinityLoad}
          />
        );
      }}
    </Observer>
  );
};

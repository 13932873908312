import { FormControl, Tooltip, Box, FormLabel, FormHelperText, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty } from 'lodash';
import * as React from 'react';

import { DISTANCE_MODES } from 'src/constants';

import { Show } from 'components/Condition';
import { Icon } from 'components/Icon';

import { t } from 'utils';

import { distanceModeStyles } from './styles';

const styles = makeStyles({
  title: {
    fontSize: 16,
  },

  icon: {
    height: 24,
    cursor: 'pointer',
  },
});

const tooltipStyles = makeStyles({
  tooltip: {
    fontSize: 12,
  },
});

type Props = {
  onChange: (arg0: { name: string; value: any }) => void;
  value: any;
  errors: string[];
};

function Label({ message, tooltipMessage }: { message: string; tooltipMessage?: string | any }) {
  const classes = styles();

  return (
    <React.Fragment>
      <Box display='flex' flexDirection='row-reverse'>
        <Show if={Boolean(tooltipMessage)}>
          <Tooltip classes={tooltipStyles()} title={(t(tooltipMessage) as React.ReactElement) || ''}>
            <Box display='flex' alignItems='center' pr={1} pl={1}>
              <Icon className={classes.icon} value='info' />
            </Box>
          </Tooltip>
        </Show>
        {t(message as TranslationLockedKeys)}
      </Box>
    </React.Fragment>
  );
}

function DistanceMode({ onChange, value, errors }: Props) {
  const isError = !isEmpty(errors);
  const classes = distanceModeStyles();

  const handleChangeRadio = (e_, value: string) => {
    onChange({ name: 'distance_mode', value: Number(value) });
  };

  return (
    <FormControl component='fieldset' required error={isError} focused={false} fullWidth>
      <FormLabel className={classes.title} component='legend'>
        {t.staticAsString('distances.steps.baseStepForm.distance_mode.title')}
      </FormLabel>

      <RadioGroup row aria-label='distance type' name='distance_mode' value={`${value}`} onChange={handleChangeRadio}>
        <FormControlLabel
          value={`${DISTANCE_MODES.CLASSIC}`}
          control={<Radio color='primary' />}
          label={<Label message='distances.steps.baseStepForm.distance_mode.classical' />}
        />
        <FormControlLabel
          value={`${DISTANCE_MODES.VIRTUAL}`}
          control={<Radio color='primary' />}
          label={
            <Label
              message='distances.steps.baseStepForm.distance_mode.virtual'
              tooltipMessage='distances.steps.baseStepForm.unofficialTooltip'
            />
          }
        />
        <FormControlLabel
          value={`${DISTANCE_MODES.CUMULATIVE}`}
          control={<Radio color='primary' />}
          label={
            <Label
              message='distances.steps.baseStepForm.distance_mode.cumulative'
              tooltipMessage='distances.steps.baseStepForm.cumulativeTooltip'
            />
          }
        />
      </RadioGroup>

      <FormHelperText>{isError ? errors.join(' ') : ' '}</FormHelperText>
    </FormControl>
  );
}

export { DistanceMode };

export type SmartLinkBE = {
  id: number;
  name: string;
  token: string;
  max_usages: number;
  used_times: number;
  date_from: string;
  date_to: string;
  discount?: number;
  discount_type?: SmarLinkCouponType;
  discount_sum?: number;
  discount_sum_type?: SmartLinkCouponUsageType;
  race_id: number;
  distance_id: number;
  wave?: AnyObject;
  wave_id?: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  link: string;
  race: RaceType;
  distance: DistanceType;
  organizer_id?: number;
};

export type SmartLinkFE = {
  id: number;
  name: string;
  token: string;
  max_usages: number;
  used_times: number;
  date_from: string;
  date_to: string;
  discount?: number;
  discount_type?: SmarLinkCouponType;
  discount_sum?: number;
  discount_sum_type?: SmartLinkCouponUsageType;
  race_id: number;
  distance_id: number;
  wave?: AnyObject;
  wave_id?: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  link: string;
  race: { name: string };
  distance: { name: string };
  date: Date;
};

type Date = {
  startDate: string;
  endDate: string;
};

export type SmartLinks = {
  data: SmartLinkFE[];
  meta: {
    pagination: {
      count: number;
      current_page: number;
      links: AnyObject;
      per_page: number;
      total: number;
      total_pages: number;
    };
  };
};

export type TableFilters = {
  id: string;
  value: any;
};

export type Filters = {
  with?: string;
  limit?: number;
  searchJoin?: 'AND';
  orderBy?: string;
  sortedBy?: string;
  page?: number;
  searchFields?: string;
  search?: string;
};

export enum SmartLinkCouponUsageType {
  DistanceOnly = 1,
  CustomFieldsOnly = 2,
  DistanceAndCustomFields = 3,
}

export enum SmarLinkCouponType {
  DiscountAmontType = 1,
  DiscountPercentageType = 2,
}

import { monitorLoadProgressByJobId } from 'utils';

import { exportJobService } from 'services';

import { loadProgress } from 'stores';

import { systemUsersStore } from '../stores';

export const exportData = async (type: string): Promise<void> => {
  const { paginationMeta, params } = systemUsersStore;
  const [status, response] = await exportJobService.exportOrganizers(type, params);
  if (!status) {
    return;
  }
  const { id } = response.data.data;
  const { total = 5000 } = paginationMeta;
  // 1500 orders loaded per ~1 second
  const estimatedLoadingTime = total / 1500;
  loadProgress.addLoadingJob(id, estimatedLoadingTime, {
    fileName: `SystemUsers.${type}`,
    onRetry: () => exportData(type),
  });
  const [monitorStatus, url] = await monitorLoadProgressByJobId(id);
  if (monitorStatus) {
    const link = document.createElement('a');
    link.href = `${url}`;
    link.click();
  }
};

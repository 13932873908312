import Typography from '@mui/material/Typography';
import * as React from 'react';

import { t, time } from 'utils';

import { ActionsMenu } from '../components';

export const displayInfoTable = (name: string, value: any, integrationId: number): string | React.ReactNode => {
  switch (name) {
    case 'actions':
      return <ActionsMenu menuId={integrationId} />;

    case 'status':
      if (value) {
        return t.staticAsString('integrations.list.table.status_enable');
      } else {
        return t.staticAsString('integrations.list.table.status_disable');
      }
    case 'date_range':
      if (!value.started_at && !value.finished_at) return null;

      const start = time.datetime(value.started_at).parse().formatAsString('shortDate');
      const end = time.datetime(value.finished_at).parse().formatAsString('shortDate');
      return `${start} - ${end}`;

    default:
      return <Typography noWrap>{value}</Typography>;
  }
};

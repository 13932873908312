export interface Report {
  id: number;
  organizer_id: number;
  race_id: number;
  file: ReportFileObject;
  file_path: null | string;
  sum: number;
  period_from: Date;
  period_to: Date;
  created_at: Date;
  updated_at: Date;
  race: Race;
  organizer: Organizer;
}

export interface ReportFileObject {
  created_at: string;
  deleted_at: null | string;
  disk: string;
  extension: string;
  mimeType: string;
  name: string;
  path: string;
  size: number;
  updated_at: string;
  uuid: string;
  url: string;
}

export interface Organizer {
  id: number;
  email: string;
  username: string;
  full_name: string;
  firstname: string;
  lastname: string;
  phone: null;
  address: null;
  city: null;
  created_at: Date;
  updated_at: Date;
  post_code: null;
  deleted_at: null;
  solo: boolean;
  created_by: null;
  device_token: string;
  country_id: null;
  website: null;
  facebook: null;
  instagram: null;
  linkedin: null;
  description: null;
}

export interface Race {
  id: number;
  sku: null;
  image_default: null;
  organizer_id: number;
  race_date: Date;
  race_end_date: Date;
  sport_id: number;
  race_url: null;
  status: string;
  allow_registration: boolean;
  published: boolean;
  location_id: number;
  created_at: Date;
  updated_at: Date;
  slug: string;
  currency_id: number;
  show_results: boolean;
  show_startlist: boolean;
  deleted_at: null;
  is_secret: boolean;
  pref_lang_code: string;
  is_searchable: boolean;
  is_ballot: boolean;
  race_union_id: null;
  approve_status: number;
  selected_lang_codes: string[];
  name: string;
  description: string;
}

export enum FilterKeys {
  RACE_KEY = 'race.name',
  ORGANIZER_KEY = 'organizer.email',
}

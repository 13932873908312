import React from 'react';

import { InputFilter } from 'components/Filter';

import { t } from 'utils';

import { FilterProps } from './index';

type Props = {
  value: nil | string;
} & FilterProps;

const OrganizerName = (props: Props) => {
  return <InputFilter label={t.staticAsString('races.filters.organizer.full_name')} {...(props as any)} />;
};

export { OrganizerName };

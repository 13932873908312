import * as React from 'react';

import { integrationsService } from '../services';

import { paginationStore, raceStore } from '../stores';

export const onChangeLimit = (e: React.ChangeEvent<HTMLInputElement>): void => {
  if (!paginationStore.value) return;
  const limit = e.target.value;

  paginationStore.set({
    ...paginationStore.value,
    limit: Number(limit),
    page: 1,
  });

  integrationsService.getIntegrations(Number(raceStore.value?.id));
};

export const onPageChange = (e: React.MouseEvent<HTMLElement> | null, page: number): void => {
  if (!paginationStore.value) return;

  paginationStore.set({
    ...paginationStore.value,
    page: page + 1,
  });

  integrationsService.getIntegrations(Number(raceStore.value?.id));
};

import { action, observable, makeObservable } from 'mobx';

import { OrderDetailsType, PaymentDetailsType, PaymentTransactionType } from '../types';

export class DetailsStore {
  @observable
  orderDetails: OrderDetailsType;

  @observable
  payment: PaymentDetailsType;

  @observable
  transactions: PaymentTransactionType[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  setOrderDetails(orderDetails: OrderDetailsType): void {
    this.orderDetails = orderDetails;
  }

  @action
  setPayment(payment: PaymentDetailsType): void {
    this.payment = payment;
  }

  @action
  setTransactions(transactions: PaymentTransactionType[]): void {
    this.transactions = transactions;
  }

  @action
  clear(): void {
    this.orderDetails = {} as OrderDetailsType;
    this.payment = {} as PaymentDetailsType;
    this.transactions = [];
  }
}

import { observable, action, makeObservable } from 'mobx';

import { RaceTypeStore } from '../types';

class RaceType implements RaceTypeStore {
  @observable
  error: string | null = null;

  @observable
  switchValue: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  setError(error: string | null) {
    this.error = error;
  }

  @action
  setSwitchValue(switchValue: boolean) {
    this.switchValue = switchValue;
  }

  @action
  clearAll(): void {
    this.error = null;
    this.switchValue = false;
  }
}

const raceTypeStore = new RaceType();
export { raceTypeStore };

import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils/history';

import { smartLinkDetailsService } from '../services/smartLinksDetails.service';

import { smartLinkStore } from '../stores';

export const getSmartLinkData = async () => {
  const { pathname } = history.location;
  const { params: { token } = { token: null } } = matchPath(pathname, { path: ROUTES.smartLinkDetails }) || {};

  if (!token) {
    return;
  }
  const linkData = await smartLinkDetailsService.loadSmartLink(token);
  smartLinkStore.set(linkData);
};

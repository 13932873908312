import classNames from 'classnames';
import React, { FC } from 'react';

import { htmlSanitizer, t } from 'utils';

type Props = {
  t: TranslationLockedKeys;
  index?: string;
  className?: string;
};

const Paragraph: FC<Props> = (props) => {
  const message = t.staticAsString(props.t, { index: props.index || '' });

  return <p className={classNames(props.className, 'terms-section--text')} dangerouslySetInnerHTML={{ __html: htmlSanitizer(message) }} />;
};

export { Paragraph };

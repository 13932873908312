export namespace SystemUsersPaymentsConstants {
  export const Table = 'Table';
  export enum COLUMN_WIDTH {
    external_id = '220px',
    email = '220px',
    distance_title = '200px',
    firstname = '200px',
    lastname = '200px',
  }
}

import * as React from 'react';

import Crane from 'img/crane.png';

import { t } from 'utils';

type Props = {};

export class Stub extends React.Component<Props> {
  render() {
    return (
      <div className='unavailable-service-container'>
        <div className='container'>
          <h1 className='title'>
            <b>{t.staticAsString('unavailableService.appDown')}</b>
          </h1>
          <p className='sub-title'>{t.staticAsString('unavailableService.subtitle')}</p>
          <div className='main-img-container'>
            <img className='main-img' width='470' height='400' alt='construction site' src={Crane} />
          </div>
        </div>
      </div>
    );
  }
}

import React, { FC, ReactElement } from 'react';

type MainContentProps = {
  children: ReactElement;
};

const MainContent: FC<MainContentProps> = ({ children }) => {
  return (
    <main className='terms-main-content'>
      <div className='terms-main-content-wrapper'>{children}</div>
    </main>
  );
};

export { MainContent };

import React from 'react';
import shortid from 'shortid';

import { NavToolbar } from 'components/NavToolbar';

import { t } from 'utils';

function Breadcrumbs() {
  const breadCrumbs = [{ key: shortid(), label: t.staticAsString('profile.profile') as any }];
  return (
    <div className='toolbar'>
      <NavToolbar items={breadCrumbs} />
    </div>
  );
}

export { Breadcrumbs };

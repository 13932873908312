import { Observer } from 'mobx-react-lite';
import * as React from 'react';

import { AutocompleteSelectInfinityLoad, InputWrapper } from 'src/components/Form';
import { form } from 'src/stores';

import { t } from 'utils/t';

import { REGISTRATION_LINKS_FORM_ID } from '../constants';

import { distancesStore } from '../stores';

export const DistanceField = () => {
  return (
    <Observer>
      {() => {
        const disabled = !form.fetch(REGISTRATION_LINKS_FORM_ID, 'race_id');

        return (
          <InputWrapper
            name='distance_id'
            settings={{
              label: t.staticAsString('shared.goalType.1'),
              additional: {
                options: distancesStore.value || [],
                disabled,
              },
              view: {
                reservePlaceForHelperText: true,
                required: true,
              },
            }}
            Component={AutocompleteSelectInfinityLoad}
          />
        );
      }}
    </Observer>
  );
};

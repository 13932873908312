import { form } from 'stores';

import { DASHBOARD_STATES } from '../constants/dashboard.constants';

import { dashboardRacesStore } from '../stores/races.store';

export function mergeRaceForm() {
  const race = dashboardRacesStore.selectedRace;
  if (race === null) return;
  form.merge(DASHBOARD_STATES.RACE_SEARCH_FORM, {
    race_id: {
      id: race.id,
      key: race.id,
      label: race.name,
    },
  });
}

import { CustomFieldsService } from './customFields';
import { EditRegistrationService } from './editRegistration';
import { OrdersService } from './orders';
import { RacersService } from './racers';
import { TeamsService } from './teams';
import { TransferRegistrationService } from './transferRegistration';

const racersService = new RacersService();
const teamsService = new TeamsService();
const ordersService = new OrdersService();
const editRegistrationService = new EditRegistrationService();
const transferRegistrationService = new TransferRegistrationService();
const customFieldService = new CustomFieldsService();

export { racersService, teamsService, editRegistrationService, transferRegistrationService, ordersService, customFieldService };

export * from './new';
export * from './update';

import { Typography } from '@mui/material';
import React, { ReactElement } from 'react';

import { formStyles } from 'src/modules/Distances/components/shared/styles';

import { t } from 'utils';

export const Description = (): ReactElement => {
  const classes = formStyles();

  return (
    <div>
      <Typography className={classes.helperText}>{t.staticAsString('distances.steps.helperText.GPX')}</Typography>
      <Typography className={classes.helperText}>{t.staticAsString('distances.steps.helperText.GPX2')}</Typography>
    </div>
  );
};

import { isEmpty } from 'lodash';
import { getDiscountSumTypeToFE } from 'modules/SmartLinks/Form/utils';
import { handleEditLink } from 'modules/SmartLinks/Table/actions';
import { SmartLinkBE, SmartLinkCouponUsageType } from 'modules/SmartLinks/shared/types';
import moment from 'moment';
import React from 'react';
import shortid from 'shortid';
import styled from 'styled-components';

import { BE_DATE_FORMAT } from 'src/constants';

import { Show } from 'components/Condition';
import { Icon } from 'components/Icon';
import { DateRange } from 'components/TimeRange';

import { t } from 'utils/t';

import { getDiscountAmount } from '../utils';

import { exportSmartLink } from '../actions';

const HeaderWrapper = styled.div`
  border: 1px solid rgba(168, 174, 184, 0.24);
  border-radius: 4px;
  padding: 24px 16px;
`;

const HeaderTopLine = styled.div`
  display: flex;
  justify-content: space-between;
  .heading {
    font-weight: 700;
    font-size: 24px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  .xls {
    margin-right: 19px;
  }
`;

const GeneralInfoBlock = styled.div`
  display: flex;
  margin-bottom: 11px;
  .general-block-section {
    display: flex;
    flex-wrap: wrap;
  }
`;

const CouponHeading = styled.div`
  font-weight: 700;
  font-size: 20px;
`;

const Name = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #a8aeb8;
  margin-right: 8px;
`;
const Value = styled.span`
  font-size: 16px;
  font-weight: 400;
  margin-right: 32px;
`;

const GeneralInfoItem = ({ name, value }) => {
  return (
    <span>
      <Name>{name}</Name>
      <Value>{value}</Value>
    </span>
  );
};

const DiscountItem = (discountType: SmartLinkCouponUsageType) => {
  return getDiscountSumTypeToFE(discountType).map((type, index) => {
    return (
      <React.Fragment key={shortid()}>
        <Show if={index > 0}>
          <span> & </span>
        </Show>
        <span>{type.label}</span>
      </React.Fragment>
    );
  });
};

export const Header: React.FC<{ smartLinkData: SmartLinkBE }> = ({ smartLinkData }) => {
  const onEdit = (): void => {
    handleEditLink(smartLinkData.token);
  };

  const onExport = async (token: string, id: number) => {
    await exportSmartLink(token, id);
  };

  return (
    <HeaderWrapper>
      <HeaderTopLine>
        <span className='heading'>{`${t.staticAsString('smartLinks.name')}: ${smartLinkData.name}`}</span>
        <Controls>
          <Icon className='xls' value='xls' onClick={() => onExport(smartLinkData.token, smartLinkData.id)} />
          <Icon value='edit' onClick={onEdit} />
        </Controls>
      </HeaderTopLine>
      <GeneralInfoBlock>
        <div className='general-block-section'>
          <GeneralInfoItem name={t.staticAsString('coupons.new.race')} value={smartLinkData.race.name} />
          <GeneralInfoItem name={t.staticAsString('coupons.detail.quantity')} value={smartLinkData.max_usages} />
          <GeneralInfoItem name={t.staticAsString('coupons.detail.used')} value={smartLinkData.used_times} />
        </div>
        <div className='general-block-section'>
          <GeneralInfoItem name={t.staticAsString('shared.goalType.1')} value={smartLinkData.distance.name} />
          <Show if={!isEmpty(smartLinkData.wave)}>
            <GeneralInfoItem name={t.staticAsString('teams.new.wave')} value={smartLinkData.wave?.name} />
          </Show>
          <GeneralInfoItem
            name={t.staticAsString('coupons.detail.dataRange')}
            value={
              <DateRange
                from={moment(smartLinkData.date_from).format(BE_DATE_FORMAT)}
                to={moment(smartLinkData.date_to).format(BE_DATE_FORMAT)}
              />
            }
          />
        </div>
      </GeneralInfoBlock>
      <Show if={Boolean(smartLinkData.discount)}>
        <CouponHeading>{t.staticAsString('coupons.list.new_coupon')}</CouponHeading>
        <GeneralInfoItem name={t.staticAsString('coupons.detail.discountAmount')} value={getDiscountAmount(smartLinkData)} />
        <GeneralInfoItem name={t.staticAsString('coupons.detail.couponCodeType')} value={DiscountItem(smartLinkData.discount_sum_type!)} />
      </Show>
    </HeaderWrapper>
  );
};

import { cloneDeep } from 'lodash';
import shortid from 'shortid';

import { t } from 'utils';

export const getDefaultCheckpoints = (checkpoints) => {
  let copy: any[] = cloneDeep(checkpoints);

  const start: any = {
    index: 0,
    assistants: [],
    length: 1,
    radius: 11,
    name: t.staticAsString('distances.steps.checkpointsForm.firstCheckpoint'),
    __id: shortid(),
  };

  const finish: any = {
    index: 1,
    assistants: [],
    length: 1,
    radius: 11,
    name: t.staticAsString('distances.steps.checkpointsForm.lastCheckpoint'),
    __id: shortid(),
  };

  copy.push(start);
  copy.push(finish);

  return copy;
};

import { observable, action, makeObservable } from 'mobx';

class SelectStore {
  @observable
  focusStorage: {
    [K in string]: boolean;
  } = {};

  constructor() {
    makeObservable(this);
  }

  @action
  setFocus(id: string, trigger: boolean): void {
    this.focusStorage[id] = trigger;
  }

  isFocused(id: string): boolean {
    return !!this.focusStorage[id];
  }
}

const selectStore = new SelectStore();
export { selectStore };

export type RacersCols =
  | 'id'
  | 'job_id'
  | 'type'
  | 'input.email'
  | 'output.message'
  | 'created_at'
  | 'started_at'
  | 'finished_at'
  | 'user.id'
  | 'user.name'
  | 'status';

export const fields = {
  id: 'id',
  action: 'type',
  parameters: 'parameters',
  reportedBy: 'reportedBy',
  created_at: 'created_at',
  output: 'output',
};

const MAIN_COLUMNS = [fields.id, fields.action, fields.parameters, fields.reportedBy, fields.created_at, fields.output];

const COLUMN_WIDTH: {
  [K in string]: number;
} = {
  id: 80,
  type: 80,
  reportedBy: 110,
  created_at: 90,
};

const TASKS_COLUMNS = {
  MAIN_COLUMNS,
  COLUMN_WIDTH,
};

export { TASKS_COLUMNS };

import { cloneDeep } from 'lodash';

import { BACKEND_DATE_FORMATE } from 'src/constants';

import { ALL_DISTANCES_VALUE, COUPON_CODE_TYPE_ADD_ONS, COUPON_CODE_TYPE_BOTH, COUPON_CODE_TYPE_REGISTRATION_FREE } from '../constants';

import { TaskForm } from '../types';

export const mapToFormGenerateCoupons = (data: AnyObject): AnyObject => {
  const form: TaskForm = cloneDeep(data) as TaskForm;
  let distances = form.couponDistances?.value === ALL_DISTANCES_VALUE ? [] : [form.couponDistances?.value];

  return {
    race: form.couponRace?.value,
    distances,
    from: form.couponDateRange?.startDate?.format(BACKEND_DATE_FORMATE),
    to: form.couponDateRange?.endDate?.format(BACKEND_DATE_FORMATE),
    type: parseInt(form.couponDiscountType),
    amount: form.couponDiscountAmount,
    quantity: form.couponQuantity,
    length: form.couponLength,
    usage_type: getUsageType(form.registrationFree, form.addons),
  };
};

const getUsageType = (registrationFree: boolean, addons: boolean): number => {
  if (registrationFree && addons) {
    return COUPON_CODE_TYPE_BOTH;
  }

  return addons ? COUPON_CODE_TYPE_ADD_ONS : COUPON_CODE_TYPE_REGISTRATION_FREE;
};

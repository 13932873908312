import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { isEmpty, omit } from 'lodash';
import { inject, observer } from 'mobx-react';
import { mountNewDistance, scrollToErrorField, validateOneStep } from 'modules/Distances/actions';
import { validateForm } from 'modules/Distances/actions/validateForm';
import { DISTANCE_FORM_ERRORS } from 'modules/Distances/constants';
import { currentStepStore, distanceFormDisposers } from 'modules/Distances/stores';
import * as React from 'react';
import { generatePath, withRouter } from 'react-router-dom';
import shortid from 'shortid';

import { CONFIRM_POPUP_TYPES, DISTANCE_STEPS, distanceStep, HELPER_RACE, PERSIST_DISTANCE as action, ROUTES } from 'src/constants';

import { loadable, withErrorClean, withProgressSpinner } from 'hocs';

import { ConfirmationModal } from 'components/ConfirmationModal';
import VerticalStepper from 'components/Stepper/VerticalStepper';

import { t } from 'utils';

import { helperRaceService } from 'services';

import { errorsStore, helperRacesStore, Progress as ProgressStore } from 'stores';

import { getStepErrors, remapIndexes } from '../../utils';

import newDistanceService from '../../services/new';

import { State, stepRelations } from '../../shared/stateHelpers';
import steps, { STEP_TITLES } from '../shared/Steps';
import { Toolbar } from '../shared/Toolbar';

type Props = {
  progressStore: ProgressStore;
} & RouterProps;
@loadable({ service: helperRaceService, param: 'raceId' })
@withRouter
@withProgressSpinner(`LOAD_${HELPER_RACE}`)
@withErrorClean(DISTANCE_FORM_ERRORS)
@inject('progressStore')
@observer
class NewDistance extends React.Component<Props, State> {
  state: State = {
    distance: {
      name: '',
      description: '',
      helper_text: '',
      registration_starts_at: undefined,
      registration_ends_at: undefined,
      race_date: undefined,
      ends_at: undefined,
      type: '',
      start_type: '',
      max_members_quantity: undefined,
      min_members_quantity: undefined,
      team_relay: false,
      is_for_kids: 0,
      allow_no_profile_registration: false,
      price: null,
      race_length: 0,
      race_qty: null,
      mark_racers_come: 0,
      show_grayed_out_racers: 0,
      allow_invite: 1,
      location: '',
      is_visible: true,
      vat_percents: '',
      refund_protect_enabled: true,
      distance_mode: undefined,
      registration_range: {},
      is_edit_registration_available: false,
      edit_registration_settings: {
        from: '',
        to: '',
      },
      is_transfer_registration_available: false,
      isTransferRegistrationEdit: false,
      isEditRegistrationEdit: false,
      transfer_registration_settings: {
        from: '',
        to: '',
        price: '',
      },
      racers_count: 0,
      raceband_used: false,
    },
    step: {
      value: DISTANCE_STEPS.nameAndDescription,
      index: 0,
    },
    registrationFields: [],
    editorSettings: {
      tab_options: [
        DISTANCE_STEPS.nameAndDescription,
        DISTANCE_STEPS.distanceDetails,
        DISTANCE_STEPS.confirmationEmail,
        DISTANCE_STEPS.registrationFields,
        DISTANCE_STEPS.tabOptions,
        DISTANCE_STEPS.prices,
        DISTANCE_STEPS.refund_protect,
      ],
    },
    checkpoints: [],
    classes: [],
    prices: [],
    waves: [],
    custom_fields: [],
    disciplines: [],
    confirmPopup: null,
    email_content: '',
    goal: {
      goal_type: null,
      goal_max_length: '',
    },
    medical_assistants: [],
    timing_assistants: [],
    isDirty: false,
  };

  componentDidMount() {
    mountNewDistance(this.onChange);
  }

  componentWillUnmount(): void {
    distanceFormDisposers.disposeAll();
  }

  onSubmit = () => {
    const isNewDistance = true;
    const data = this.state;
    const race = helperRacesStore.selected;
    if (!race) {
      return;
    }

    errorsStore.clear(DISTANCE_FORM_ERRORS);
    if (!validateForm(data, isNewDistance)) {
      scrollToErrorField();
      return;
    }
    data.checkpoints = remapIndexes(data.checkpoints);
    Object.assign(data, {
      distance: {
        ...omit(data.distance, 'name'),
        [race.pref_lang_code]: { name: data.distance.name },
      },
    });

    newDistanceService.submitForDistanceCreate(data, race);
  };
  onCancel = () => {
    const { index } = this.state.step;
    if (!!index) {
      return this.changeStep('dec');
    }
    this.setState({
      confirmPopup: this.onRedirectBack.bind(this),
    });
  };
  onRedirectBack = () => {
    const id: any = helperRacesStore.selected && helperRacesStore.selected.id;
    const { history } = this.props;
    const path = generatePath(ROUTES.raceRoute, { id });
    history.push(path);
  };
  // Stepper
  onChangeStep = (value: string) => {
    const { editorSettings } = this.state;
    const steps = editorSettings.tab_options;
    const newIndex = steps.indexOf(value);
    currentStepStore.set({
      value: value as distanceStep,
      index: newIndex,
    });
    this.setState({
      ...this.state,
      step: {
        value,
        index: newIndex,
      },
    });
  };
  // Stepper
  onChangeStepIndex = (index: number) => {
    const { editorSettings } = this.state;
    const steps = editorSettings.tab_options;
    const newStep = steps[index];
    if (!newStep) {
      return;
    }
    currentStepStore.set({
      value: newStep as distanceStep,
      index,
    });
    this.setState({
      ...this.state,
      step: {
        value: newStep,
        index,
      },
    });
  };

  // Stepper
  changeStep = (action: 'inc' | 'dec') => {
    const data = this.state;
    const { index } = this.state.step;
    const step = this.state.step.value;
    switch (action) {
      case 'inc':
        if (!validateOneStep(step as distanceStep, data)) {
          return;
        }
        this.onChangeStepIndex(index + 1);
        break;
      case 'dec':
        this.onChangeStepIndex(index - 1);
        break;
      default:
        return;
    }
  };

  // Main onChange
  onChange = (
    changes: {
      [K in string]: any;
    },
    nestedKey: string,
    callback: AnyFunction = () => {},
  ) => {
    if (!nestedKey) {
      this.setState(
        {
          ...this.state,
          ...changes,
        },
        callback,
      );
      return;
    }
    let checkpoints = this.state.checkpoints;
    if (nestedKey === 'editorSettings' && checkpoints.length === 0) {
      const { tab_options } = changes;
      const curr = this.state.editorSettings.tab_options;
      if (!curr.includes('checkpoints') && tab_options.includes('checkpoints')) {
        const start: any = {
          index: 0,
          assistants: [],
          length: 1,
          radius: 11,
          name: 'Mellantid 1',
          name_en: '1st Checkpoint',
          __id: shortid(),
        };
        const finish: any = {
          index: 1,
          assistants: [],
          length: this.state.distance.race_length,
          radius: 11,
          name: 'Mål',
          name_en: 'Finish',
          __id: shortid(),
        };
        checkpoints = [start, finish];
      }
    }
    this.setState(
      {
        ...this.state,
        checkpoints,
        [nestedKey]: {
          ...changes,
        },
      },
      callback,
    );
  };
  _isContinueAvailable = (stepIndex?: number): boolean => {
    const errors = errorsStore.errors.validation[`${action}_${DISTANCE_STEPS[this.state.step.value]}`];
    const stepErrors = errorsStore.errors.validation[`${action}_${DISTANCE_STEPS[stepIndex!]}`];
    if (!errors && !stepErrors) {
      return true;
    }
    return !errors?.flatErrors().length && !stepErrors?.flatErrors().length;
  };
  _isRelationEmpty = (step: string): boolean => {
    const relation = (this.state as AnyObject)[stepRelations[step]];
    if (isEmpty(relation)) {
      return true;
    }
    return false;
  };
  _isNew = () => window.location.pathname.split('/').pop() === 'new';
  _isSubmitShown = (): boolean => {
    const { step, editorSettings } = this.state;
    if (step.index === 1 && this._isNew()) return true;
    return step.index + 1 === editorSettings.tab_options.length;
  };
  _isLastStep = () => {
    const {
      step: { index },
      editorSettings: { tab_options },
    } = this.state;
    return index === tab_options.length - 1;
  };
  _clearConfirmPopup = () => {
    this.setState({ confirmPopup: null });
  };
  renderStep = () => {
    const { step } = this.state;
    const Step = steps[step.value];
    return (
      <Step
        formData={this.state}
        onChange={this.onChange}
        helperData={{ race: helperRacesStore.modelSelected }}
        controls={this._renderControls()}
        liveUpdate={() => {}}
      />
    );
  };
  _renderControls = () => {
    const { progressStore } = this.props;
    const { index } = this.state.step;
    // const isContinueAvailable = true || !this._isContinueAvailable() || progressStore.isLoading(action);
    const isContinueAvailable = true;
    const isSubmitShown = this._isSubmitShown();
    return (
      <div className='controls'>
        <div className='left-btn-group' />
        <div className='right-btn-group'>
          <Button className='cancel' onClick={this.onCancel} disableTouchRipple>
            {t(!!index ? 'shared.back' : ('shared.cancel' as TranslationLockedKeys))}
          </Button>
          {!isSubmitShown && (
            <Button
              // disabled={isContinueAvailable}
              className={classNames('continue', {
                // disabled: isContinueAvailable,
              })}
              disableTouchRipple
              onClick={() => this.changeStep('inc')}
            >
              {t.staticAsString('distances.steps.continue')}
            </Button>
          )}
          {isSubmitShown && (
            <Button
              // disabled={isContinueAvailable}
              className={classNames('continue', {
                // disabled: isContinueAvailable,
              })}
              disableTouchRipple
              onClick={this.onSubmit}
            >
              {t.staticAsString(this._isLastStep() ? 'distances.steps.submit' : 'distances.steps.continue')}
            </Button>
          )}
        </div>
      </div>
    );
  };
  _breadcrumbs = () => {
    const race = helperRacesStore.modelSelected;
    if (!race) {
      return [];
    }
    return [
      {
        path: `${ROUTES.racesRoute}/${race.value.id}`,
        label: race.value.name || '',
      },
      { label: t.staticAsString('distances.steps.addNew') as any },
    ];
  };
  render() {
    const { step, editorSettings, confirmPopup } = this.state;
    const stepTitleTranslateKey = STEP_TITLES[step.value] || ' ';
    const race = helperRacesStore.modelSelected;
    if (!race) {
      return null;
    }
    return (
      <div className='content main-distance-form'>
        <Toolbar items={this._breadcrumbs()} />
        <div className='form-wrap'>
          <VerticalStepper
            stepModel='distances'
            disableIndices={[2, 3, 4, 5, 6, 7, 8]}
            step={step}
            steps={editorSettings.tab_options}
            onChange={this.onChangeStep}
            errors={getStepErrors()}
          />
          <form className='distance-form new-distance' autoComplete='off'>
            <Typography className='step-title'>{t(stepTitleTranslateKey)}</Typography>
            {this.renderStep()}
          </form>
        </div>
        <ConfirmationModal
          open={!!confirmPopup}
          onConfirm={confirmPopup || ((() => {}) as any)}
          onClose={this._clearConfirmPopup}
          title={t.staticAsString('distances.confirmCloseTitle') as any}
          body={t.staticAsString('distances.newConfirmCloseBody') as any}
          type={CONFIRM_POPUP_TYPES.confirm}
        />
      </div>
    );
  }
}
export { action };
export { NewDistance };

import { Dialog, DialogContent, Button, DialogActions } from '@mui/material';
import { debounce } from 'lodash';
import * as React from 'react';
import Cropper from 'react-cropper';

import { EXTRA_CUSTOM_FIELD_IMAGE_RATIO, IMAGE_UPLOAD } from 'src/constants';

import { Checkbox } from 'components/Fields';
import { ImageField } from 'components/Fields/ImageField';

import { synchronousValidation, t } from 'utils';

import { View } from './View';
import { imageWithCrop as constrains } from './constrains';

type Props = {
  errors?: Array<string>;
  onChange: (arg0: { name: string; value: nil | Blob }) => void;
  value: Blob | string | null;
  name: string;
  fileName?: string;
  action: string;
  shouldRenderFileName?: boolean;
  disabled?: boolean;
};

type State = {
  originalImage?: string;
  originalImageBlob?: Blob;
  isModalOpen: boolean;
  croppedData: string;
  savingEnabled: boolean;
};

const MIN_HEIGHT = 500;
const MIN_WIDTH = 500;

class ImageFieldWithCrop extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.debouncedCrop = debounce(this._crop, 50);
  }

  componentDidMount() {
    const value: any = this.props.value;

    if (value && value.preview) {
      this.onChangeImageField({ name: 'icon', value });
    }
  }

  debouncedCrop: (event: Cropper.CropEvent) => void;
  declare refs: any;

  state = {
    // Blob link
    originalImage: '',
    // Blob
    isModalOpen: false,
    croppedData: '',
    savingEnabled: false,
  };

  static defaultProps = {
    action: IMAGE_UPLOAD,
  };

  onChangeImageField = ({ name, value }: { name: string; value: nil | Blob }) => {
    if (value) {
      const SIZE_5_MB: Number = 5e6;
      const image = URL.createObjectURL(value);
      const isValid = value.size > SIZE_5_MB ? false : Boolean(this.validate(value));

      this.setState({
        ...this.state,
        isModalOpen: isValid,
        originalImage: image,
        originalImageBlob: value,
      });

      return;
    }

    this.setState(
      {
        ...this.state,
        originalImage: '',
        croppedData: '',
      },
      this.onChange,
    );
  };

  onClose = () => {
    this.setState({
      ...this.state,
      isModalOpen: false,
    });
    this.props.onChange({
      name: 'image',
      value: null,
    });
  };

  _crop = () => {
    const { originalImageBlob } = this.state as State;
    const croppedData = this.refs.cropper.getCroppedCanvas().toDataURL(originalImageBlob?.type ?? 'image/jpeg', 0.7);

    this.setState({
      ...this.state,
      croppedData,
    });
  };

  onChange = async () => {
    const { onChange, name } = this.props;
    const { croppedData } = this.state;

    let resp: any = null;
    let value = null;

    if (croppedData) {
      resp = await fetch(croppedData);
      value = resp && (await resp.blob());
    }
    onChange({
      name,
      value,
    });
    this.setState({
      ...this.state,
      isModalOpen: false,
    });
  };

  @synchronousValidation({ constrains, action: IMAGE_UPLOAD })
  validate(value: Blob): any {
    return { image: value };
  }

  onChangeCheckbox = (e: React.ChangeEventHandler | any) => {
    this.setState({
      ...this.state,
      savingEnabled: e.target.checked,
    });
  };

  renderLabel = () => {
    return (
      <>
        <span style={{ fontWeight: 'bold' }}>{t.staticAsString('imageCrop.checkboxLabel.reminder')}</span>
        &nbsp;
        <span>{t.staticAsString('imageCrop.checkboxLabel.text')}</span>
      </>
    );
  };

  render() {
    const { value, fileName, shouldRenderFileName, disabled } = this.props;

    const { originalImage, isModalOpen } = this.state;

    return (
      <React.Fragment>
        <Dialog open={isModalOpen} onClose={this.onClose}>
          <DialogContent>
            <div style={{ minWidth: MIN_WIDTH, minHeight: MIN_HEIGHT }}>
              <Cropper
                minContainerWidth={MIN_WIDTH}
                minContainerHeight={MIN_HEIGHT}
                style={{ height: '550px', width: '100%' }}
                viewMode={1}
                ref='cropper'
                src={originalImage}
                crop={this.debouncedCrop}
                aspectRatio={EXTRA_CUSTOM_FIELD_IMAGE_RATIO}
              />
            </div>
          </DialogContent>
          <DialogActions style={{ display: 'block', padding: '8px 24px' }}>
            <Checkbox name='copyrights' onChange={this.onChangeCheckbox} value={this.state.savingEnabled} label={this.renderLabel()} />
            <div style={{ float: 'right', marginTop: '5px' }}>
              <Button onClick={this.onChange} color='primary' disabled={!this.state.savingEnabled}>
                Save
              </Button>
              <Button onClick={this.onClose} color='primary' autoFocus>
                Close
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <ImageField
          fileName={shouldRenderFileName ? (this.state as any).originalImageBlob?.name : fileName}
          value={value}
          name='icon'
          View={View}
          label={''}
          errors={this.props.errors ?? []}
          onChange={this.onChangeImageField}
          shouldRenderFileName={shouldRenderFileName}
          disabled={disabled}
        />
      </React.Fragment>
    );
  }
}

export { ImageFieldWithCrop };

import { Box, FormControlLabel, styled, Theme, Tooltip } from '@mui/material';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { isNull, pick } from 'lodash';
import { observer } from 'mobx-react';
import { updateRaceExternalLinkAction } from 'modules/RaceDetails/actions/updateRaceExternalLink';
import { ActionButton } from 'modules/RaceDetails/components';
import { externalLinkCacheStore, participantsCountStore, raceDetailsStore, raceTypeStore } from 'modules/RaceDetails/stores';
import { formStyles } from 'modules/Races/components/styles';
import { getFieldValidationSchema } from 'modules/Races/validations';
import * as React from 'react';

import { CONFIRM_POPUP_TYPES, HINT_QUERY } from 'src/constants';

import { Icon } from 'components/Icon';

import { history, stringFormatter, t, Validator } from 'utils';

import { confirmationModalStore } from 'stores';

type Props = {
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    flexDirection: 'column',
    gap: '15px',
    lineHeight: '16px',
    letterSpacing: 'unset',
    position: 'relative',
    padding: 0,
  },
}));

interface IFormControlLabel extends FormControlLabelProps {
  showHint?: boolean;
}

const StyledFormControlLabel = styled(FormControlLabel)<IFormControlLabel>(({ theme, showHint }) => ({
  margin: 0,
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: 'inherit',
  lineHeight: 'inherit',
  '.MuiFormControlLabel-label': {
    color: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
  },
  '& .tooltip-icon': {
    display: 'flex',
    position: 'absolute',
    right: '9px',
    top: '12px',
    '& .icon': {
      alignItems: 'center',
      display: 'flex',
    },
  },

  ...(showHint && {
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
    animation: 'pulse 2s infinite',
    borderRadius: '2px',
    '@keyframes pulse': {
      '0%': {
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0.7)',
      },
      '70%': {
        boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
      },
      '100%': {
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
      },
    },
  }),
}));

const RaceType = observer(({ className }: Props) => {
  const c = useStyles();

  const classes = formStyles();
  const race = raceDetailsStore.raceDetails?.value;
  const switchValue = raceTypeStore.switchValue;

  const {
    location: { search },
  } = history;
  const searchParams = new URLSearchParams(search);
  const showHint = !isNull(searchParams.get(HINT_QUERY));

  const updateLink = (value) => {
    raceTypeStore.setError(null);
    let formatted: string | null = stringFormatter.withHttpUrl(value);
    if (formatted === '') formatted = null;
    const errors = Validator.validate({ race_url: formatted }, getFieldValidationSchema('race_url', true));
    // @ts-ignore
    errors ? raceTypeStore.setError(errors['race_url'][0] || null) : updateRaceExternalLinkAction(formatted);
  };

  const switchToCalendarRaceType = (isChecked) => {
    raceTypeStore.setSwitchValue(isChecked);
    const cache = externalLinkCacheStore.value;
    const race_url = cache && race ? cache[race.id] : null;

    raceDetailsStore.partialRaceUpdate({
      race_url,
    });
    updateLink(race_url);
  };

  const toggleSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    raceTypeStore.setError(null);

    const isChecked = e.target.checked;

    if (isChecked) {
      raceTypeStore.setSwitchValue(isChecked);
      history.replace({
        pathname: history.location.pathname,
        search: '',
      });
      if (race) {
        const { id, race_url } = race;
        externalLinkCacheStore.set({ [id]: race_url || '' });
      }

      updateRaceExternalLinkAction(null);
    } else {
      const { count } = participantsCountStore.value || {};
      const { isSomeDistanceVirtualOrCumulative } = raceDetailsStore;

      if (count) {
        confirmationModalStore.openModal({
          title: t.staticAsString('races.forbiddenRaceActionPopup.title'),
          body: t.staticAsString('races.detail.confirmPopup.switchRaceType.body'),
          type: CONFIRM_POPUP_TYPES.info,
          onConfirm: () => {},
        });
        return;
      }

      if (isSomeDistanceVirtualOrCumulative) {
        confirmationModalStore.openModal({
          title: t.staticAsString('races.forbiddenRaceActionPopup.title'),
          body: t.staticAsString('races.detail.confirmPopup.switchRaceType.isSomeDistanceVirtualOrCumulative'),
          type: CONFIRM_POPUP_TYPES.info,
          onConfirm: () => {},
        });
        return;
      }
      switchToCalendarRaceType(isChecked);
    }
  };

  return (
    <ActionButton className={classnames(className, c.button)} variant='text'>
      <StyledFormControlLabel
        showHint={showHint}
        label={t.staticAsString('races.detail.typeSettings.label')}
        labelPlacement='start'
        control={
          <Box sx={{ display: 'flex' }}>
            <Switch onChange={toggleSwitch} checked={switchValue} color='primary' />
            <Tooltip
              classes={pick(classes, 'tooltip')}
              title={
                <>
                  {t.staticAsString('races.detail.typeSettings.tooltip')}
                  {': '}
                  <a className='tooltip-link' href={'https://raceid.com/organizer/pricing/'} target={'_blank'}>
                    https://raceid.com/organizer/pricing/
                  </a>{' '}
                </>
              }
              placement='right'
            >
              <span className='tooltip-icon'>
                <Icon value='info' className='icon' />
              </span>
            </Tooltip>
          </Box>
        }
      />
    </ActionButton>
  );
});

export { RaceType };

import { sortBy, isBoolean } from 'lodash';

class Reorder {
  reorder(list: Array<any>, startIndex: number, endIndex: number): Array<any> {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  changeIndexLayer<T>(list: Array<T>, fieldToChange: string = 'index', withSorting?: string | boolean): Array<T> {
    let newList = [...list];

    if (!!withSorting) {
      const sortingField = isBoolean(withSorting) ? fieldToChange : withSorting;
      newList = sortBy(newList, sortingField);
    }

    return newList.map((item, index) => ({
      ...item,
      [fieldToChange]: index,
    }));
  }

  changePosition(list: Array<unknown>, startIndex: number, endIndex: number, fieldToChange: string = 'index'): Array<any> {
    const results = this.reorder(list, startIndex, endIndex);
    return results.map((result, index) => {
      return {
        ...result,
        [fieldToChange]: index,
      };
    });
  }
}

const reorderUtil = new Reorder();

export { reorderUtil };

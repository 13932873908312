import { observable, action, makeObservable } from 'mobx';

import type { Assistant } from '../types';

export class MedicalAssistants {
  resourceParams = {
    limit: 5000,
    search: 'roles.name:medical',
    searchFields: 'roles.name:=;created_by:=',
    searchJoin: 'AND',
  };

  @observable
  values: Array<MedicalAssistantType> = [];

  @observable
  selectedValues: Array<MedicalAssistantType> = [];

  constructor() {
    makeObservable(this);
  }

  @action
  addValues(values: Array<MedicalAssistantType>) {
    this.values = values.map((newValue) => {
      const oldValue = this.values.find((value) => value.id === newValue.id);
      return oldValue || newValue;
    });
  }

  @action
  selectValue(value: MedicalAssistantType) {
    this.selectedValues.push(value);
  }

  @action
  clearSelected() {
    this.selectedValues = [];
  }
  @action
  clearValues() {
    this.values = [];
  }
}

export const medicalAssistants = new MedicalAssistants();

import { form } from 'stores';

import { COPY, SUPPORT_TASK_MANAGER_COPY_FORM } from '../constants';

import { mapToFormCopyRacers } from '../utils';

import { copyRacersService } from '../services';

import { confirmStore } from '../stores';

export const openConfirmModal = (): void => {
  confirmStore.set({
    ...(confirmStore.value as any),
    open: true,
  });
};

export const closeConfirmModal = (): void => {
  confirmStore.set({
    open: false,
    title: '',
    content: '',
  });
};

export const setConfirmTitle = (text: string): void => {
  confirmStore.set({
    ...(confirmStore.value as any),
    title: text,
  });
};

export const setConfirmContent = (text: string): void => {
  confirmStore.set({
    ...(confirmStore.value as any),
    content: text,
  });
};

export const onConfirmModal = async () => {
  const actionForm: AnyObject = form.fetchWithoutObservable(SUPPORT_TASK_MANAGER_COPY_FORM);

  const params = mapToFormCopyRacers(actionForm);
  await copyRacersService.sendCopyRacers(params);
};

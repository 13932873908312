import axios from 'axios';

import { TASKS_URL } from 'src/constants';
import { action, request } from 'src/utils';

import { LOAD_TASK } from '../constants';

import { taskStore } from '../stores';

class Load {
  @request({ action: LOAD_TASK })
  async loadRequest(taskId: number): Promise<any> {
    const query = `?orderBy=id&sortedBy=DESC&limit=1&search=id:${taskId}&searchFields=id:=&page=1`;
    return axios.get(`${TASKS_URL}${query}`);
  }

  @action({ action: LOAD_TASK })
  async load(taskId: number) {
    const [status, response] = await this.loadRequest(taskId);

    if (status) {
      taskStore.set(response.data.data[0]);
    }
  }
}

export const loadService = new Load();

import { observer } from 'mobx-react';
import { ERRORS_PREFIXES } from 'modules/Distances/constants';
import { clearFieldError, getRelationErrors } from 'modules/Distances/utils/errors';
import * as React from 'react';

import { TextField } from 'components/Fields';

import { t } from 'utils';

import { helperRacesStore, progressStore } from 'stores';

import { DISTANCE } from '../../../../../../constants';

type Props = {
  onChange: (id: number | string, data: DisciplineType, callback?: Function) => void;
  onRemove: (id: number | string) => void;
  value: DisciplineType;
  index: number;
  minValuesForRender: number;
};

@observer
export class Item extends React.Component<Props> {
  onChange = (name: string, changedValue: unknown, nestedKey?: string) => {
    const { value, onChange } = this.props;

    let changeData: AnyObject = { ...value };

    if (nestedKey) {
      changeData = {
        ...changeData,
        [nestedKey]: {
          ...changeData[nestedKey],
          [name]: changedValue,
        },
      };
    } else {
      changeData = {
        ...changeData,
        [name]: changedValue,
      };
    }

    const id = value.id || value.__id || '';
    onChange(id, changeData as any, () => {
      clearFieldError(this.props.index, ERRORS_PREFIXES.disciplines, name);
    });
  };

  onChangePlain = (e: React.ChangeEventHandler | any, nestedKey?: string) => {
    const { name, value } = e.currentTarget;

    this.onChange(name, value, nestedKey);
  };

  defaultInputProps = (name: string) => {
    const value = (this.props.value as AnyObject)[name];

    return {
      value: value,
      name,
      errors: [],
      fullWidth: true,
    };
  };

  onRemove = () => {
    const { onRemove, value } = this.props;
    const id = value.id || value.__id || '';
    onRemove(id);
  };

  render() {
    const { index, value, minValuesForRender } = this.props;
    const { defaultLocale } = helperRacesStore.languagesData;
    const disabled = progressStore.isLoading(`UPDATE_${DISTANCE}`);
    if (value._delete) {
      return null;
    }
    return (
      <li className='distance-class-item'>
        <TextField
          {...this.defaultInputProps('title')}
          label={t.staticAsString('distances.steps.disciplinesForm.title') as any}
          errors={getRelationErrors(index, ERRORS_PREFIXES.disciplines, 'title')}
          onChange={this.onChangePlain}
          disabled={disabled}
        />
        <div className='remove-item-wrapper'>
          {index >= minValuesForRender ? (
            <span className='remove-item-label' onClick={this.onRemove}>
              {t.staticAsString('distances.steps.disciplinesForm.delete')}
            </span>
          ) : (
            ''
          )}
        </div>
      </li>
    );
  }
}

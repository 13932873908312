import { defaultListFilters } from 'utils';

import { FilterDescriptorWrapper } from 'models';

import { distanceName } from './distanceName';
import { raceName } from './raceName';
import { status } from './status';

type RacersDescriptors = 'id' | 'distance.race_parent_id' | 'distance_id' | 'email' | 'firstname' | 'lastname' | 'status';
type Descs = { [K in RacersDescriptors]: FilterListDescriptor };
type DescsWrapped = { [K in RacersDescriptors]: FilterDescriptorWrapper };

const descriptors: Descs = {
  id: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      default: true,
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  'distance.race_parent_id': raceName,
  distance_id: distanceName,
  status: status,

  email: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  firstname: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  lastname: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },
};

const descriptorsKeys = Object.keys(descriptors) as Array<RacersDescriptors>;

export const filterDescriptors: DescsWrapped = descriptorsKeys.reduce((acc, desc) => {
  return {
    ...acc,
    [desc]: new FilterDescriptorWrapper(desc, descriptors[desc]),
  };
}, {} as DescsWrapped);

import { toString, isNaN, isString } from 'lodash';

function formatToMask(value: nil | number) {
  return toString(value);
}

function formatFromMask(value: nil | string): null | number {
  let val = NaN;

  if (isString(value)) {
    val = parseInt(value.replace(/\s/g, ''), 10);
  }

  if (isNaN(val)) {
    return null;
  }

  return val;
}

export const numberMaskTools = {
  formatToMask,
  formatFromMask,
};

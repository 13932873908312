import { racersService } from '../services';

import { racersStore } from '../stores';

export const loadRacers = async (
  params: {
    limit?: number;
    page?: number;
  } = {},
) => {
  let { page: storePage, params: storeParams, filters: storeFilters } = racersStore;

  let { limit = storeFilters.limit, page = storePage } = params;

  if (params.limit) {
    page = 1;
  }

  const queryParams = {
    ...storeParams,
    page,
    limit,
  };

  const [status, { data, meta }] = await racersService.loadRacers(queryParams);

  if (!status) return;

  racersStore.addValues(data, page, { ...storeFilters, limit });

  const paginationMeta = meta && meta.pagination;

  if (paginationMeta) {
    racersStore.addPaginationMeta(paginationMeta);
  }
};

import { Terms } from 'modules';
import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import shortId from 'shortid';

import { ROUTES } from 'src/constants';

const IframeContainer: FC = () => {
  return (
    <Switch>
      <Route key={shortId()} component={Terms} path={ROUTES.iframeCooperationAgreement} />
      <Route key={shortId()} component={Terms} path={ROUTES.iframeProcessAgreement} />
      <Redirect key={shortId()} from='*' to='/' />
    </Switch>
  );
};

export { IframeContainer };

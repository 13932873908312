import { isArray } from 'lodash';

import { toastStore } from 'stores';

// in case if BE returns errors that is not bind to any
// of the fields, messages should be handled via toast
function handleMessageErrors(errors: any) {
  if (!isArray(errors?.error)) {
    return;
  }

  toastStore.show(errors.error.join('. '));
}

export { handleMessageErrors };

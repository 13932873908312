import { CustomAgeTooltip } from 'modules/Dashboard/common/components/AgeTooltip';
import { useState } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

type Props = {
  id: string;
  color: string;
  data: {
    value: number;
    label: string;
    percentage: number;
  }[];
  width: number;
  height: number;
};

export default function AgeBarChartSmall({ height, color, data }: Props) {
  const [focusBar, setFocusBar] = useState<number | undefined>(undefined);
  return (
    <ResponsiveContainer height={height} width='100%'>
      <BarChart
        onMouseMove={(state) => {
          if (state.isTooltipActive) {
            setFocusBar(state.activeTooltipIndex);
          } else {
            setFocusBar(undefined);
          }
        }}
        style={{ fontSize: 14, left: '-30px' }}
        height={height}
        data={data}
      >
        <CartesianGrid vertical={false} strokeDasharray='3 3' />
        <XAxis type='category' dataKey='label' />
        <YAxis unit='%' type='number' dataKey={'percentage'} />
        <Tooltip cursor={{ fill: 'rgba(0, 0, 0, 0)' }} content={CustomAgeTooltip} />
        <Bar background={{ fill: '#eee' }} barSize={16} dataKey='percentage'>
          {data.map((_, index) => {
            const isFocused = focusBar === index;
            const fill = isFocused ? `rgba(${color}, 1)` : `rgba(${color}, 0.8)`;
            return <Cell key={`cell-${index}`} fill={fill} />;
          })}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
}

import { observer } from 'mobx-react';
import { duplicateFormInit } from 'modules/RaceDetails/actions/duplicateFormInit';
import React from 'react';
import { generatePath } from 'react-router-dom';

import { CONFIRM_POPUP_TYPES, ROUTES } from 'src/constants';

import { withConfirmModal } from 'hocs';

import { localeStore } from 'stores';
import { hostsStore } from 'stores';

import { Toolbar } from '../../components';

import {
  deleteRaceAction,
  showStartListAction,
  hideStartListAction,
  hideResultsAction,
  showResultsAction,
  rejectRace,
  approveRace,
} from '../../actions';

import { distancesStore, raceDetailsStore } from '../../stores';

type OriginalProps = {};

type Props = OriginalProps &
  WithConfirmModalProps<'deleteRacePopup'> &
  WithConfirmModalProps<'hideStartListPopup'> &
  WithConfirmModalProps<'publishPopup'> &
  WithConfirmModalProps<'approveRequiredRaceActionPopup'> &
  WithConfirmModalProps<'publishNonRegisteredPopup'> &
  WithConfirmModalProps<'forbiddenRaceActionPopup'> &
  WithConfirmModalProps<'hideResultsPopup'> &
  HOC.withIntl;

@withConfirmModal('deleteRacePopup', {
  i18Title: 'races.confirmPopup.mainTitle',
  i18Body: 'races.confirmPopup.mainBody',
  type: CONFIRM_POPUP_TYPES.confirm,
})
@withConfirmModal('hideStartListPopup', {
  i18Title: 'races.confirmPopup.mainTitle',
  i18Body: 'races.detail.hideStartList.confirm',
  type: CONFIRM_POPUP_TYPES.confirm,
})
@withConfirmModal('publishPopup', {
  i18Title: 'races.publishPopup.title',
  i18Body: 'races.publishPopup.body',
  type: CONFIRM_POPUP_TYPES.confirm,
})
@withConfirmModal('publishNonRegisteredPopup', {
  i18Title: 'races.publishNonRegisteredPopup.title',
  i18Body: 'races.publishNonRegisteredPopup.body',
  type: CONFIRM_POPUP_TYPES.confirm,
})
@withConfirmModal('hideResultsPopup', {
  i18Title: 'races.confirmPopup.mainTitle',
  i18Body: 'races.detail.hideResults.confirm',
  type: CONFIRM_POPUP_TYPES.confirm,
})
@withConfirmModal('forbiddenRaceActionPopup', {
  i18Title: 'races.forbiddenRaceActionPopup.title',
  i18Body: 'races.forbiddenRaceActionPopup.body',
  type: CONFIRM_POPUP_TYPES.info,
})
@withConfirmModal('approveRequiredRaceActionPopup', {
  i18Title: 'races.forbiddenRaceActionPopup.title',
  i18Body: 'races.publishRejected.body',
  type: CONFIRM_POPUP_TYPES.info,
})
@observer
class ToolbarContainerImpl extends React.Component<Props> {
  onToggleResults = () => {
    const raceDetails = raceDetailsStore.raceDetails;

    if (!raceDetails) {
      return;
    }

    const { show_results } = raceDetails.value;
    const name = raceDetails.value.name;

    if (!show_results) {
      return showResultsAction();
    }

    this.props.hideResultsPopup.open(hideResultsAction, {}, { name });
  };

  toggleStartListVisibility = () => {
    const { raceDetails } = raceDetailsStore;

    if (!raceDetails) {
      return;
    }

    const { show_startlist } = raceDetails.value;
    const name = raceDetails.value.name;

    if (!show_startlist) {
      return showStartListAction();
    }
    this.props.hideStartListPopup.open(hideStartListAction, {}, { name });
  };

  duplicateRace = () => {
    const { modelValues } = distancesStore;
    const raceParticipantsCount = modelValues.reduce((acc, distance) => acc + distance.entityCount(), 0);

    duplicateFormInit('race', undefined, raceParticipantsCount);
  };

  deleteRace = () => {
    const { raceDetails } = raceDetailsStore;

    if (!raceDetails) {
      return;
    }

    const name = raceDetails.value.name;
    const { id } = raceDetails.value;

    this.props.deleteRacePopup.open(deleteRaceAction, {}, { name, id });
  };

  onPreviewLink = () => {
    const { raceDetails } = raceDetailsStore;
    const id = raceDetails ? raceDetails.value.id : '';
    const racePreviewLink = id ? hostsStore.web + generatePath(ROUTES.previewLink, { locale: localeStore.value, id }) : '';

    window.open(racePreviewLink, '_blank');
  };

  isWebHostReady = (): boolean => {
    return hostsStore.web !== '';
  };

  render() {
    const { deleteRace, duplicateRace, onToggleResults, toggleStartListVisibility, onPreviewLink, isWebHostReady } = this;

    const { raceDetails, rejectData } = raceDetailsStore;

    // TODO: spread single component to smaller components:
    // Info box, status tools, toolbar, maybe menu

    return (
      <Toolbar
        race={raceDetails}
        rejectRace={rejectRace}
        approveRace={approveRace}
        rejectData={rejectData}
        onDelete={deleteRace}
        onDuplicate={duplicateRace}
        onToggleResults={onToggleResults}
        onHideStartList={toggleStartListVisibility}
        onPreviewLink={onPreviewLink}
        isWebHostReady={isWebHostReady()}
      />
    );
  }
}

export const ToolbarContainer = ToolbarContainerImpl as unknown as React.ComponentType<OriginalProps>;

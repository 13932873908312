import { cloneDeep } from 'lodash';

export const mapEmails = (data: AnyObject[]): AnyObject[] => {
  const copy = cloneDeep(data);

  return copy.map((item) => {
    return {
      key: item.id,
      value: item.email,
      label: `${item.id} ${item.email}`,
    };
  });
};

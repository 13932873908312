import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import * as React from 'react';

import { LoadingDots } from 'components/LoadingDots';

import useIntersectionObserver from 'utils/hooks/useIntersectionObserver';

const useStyles = makeStyles((theme) => ({
  loader: {
    marginTop: 12,
  },
}));

export const Option = ({
  props,
  label,
  lastOption = false,
  onVisible,
}: {
  props: AnyObject;
  label: string;
  lastOption?: boolean;
  onVisible?: Function;
}) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible) {
      onVisible && onVisible();
    }
  }, [isVisible]);

  return (
    <>
      {lastOption ? (
        <li {...props}>
          <div ref={ref}>
            <div>{label}</div>
            <LoadingDots className={classNames(classes.loader)} />
          </div>
        </li>
      ) : (
        <li {...props}>{label}</li>
      )}
    </>
  );
};

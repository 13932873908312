import { loadCountryService } from 'services';

import { mapFrom } from '../utils';

import { systemUsersService } from '../services';

import { systemUsersStore } from '../stores';

export const loadSystemUsers = async (
  params: {
    limit?: number;
    page?: number;
  } = {},
) => {
  let { page: storePage, params: storeParams, filters: storeFilters } = systemUsersStore;

  let { limit = storeFilters.limit, page = storePage } = params;

  if (params.limit) {
    page = 1;
  }

  const queryParams = {
    ...storeParams,
    page,
    limit,
  };

  await loadCountryService.loadResources();
  const [status, { data, meta }] = await systemUsersService.loadSystemUsers(queryParams);

  if (!status) return;

  const mappedUsers = data.map((user) => mapFrom(user));

  systemUsersStore.addValues(mappedUsers, page, { ...storeFilters, limit });

  const paginationMeta = meta && meta.pagination;

  if (paginationMeta) {
    systemUsersStore.addPaginationMeta(paginationMeta);
  }
};

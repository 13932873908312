import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import { observer } from 'mobx-react';
import * as React from 'react';
import shortId from 'shortid';

import { Show } from 'components/Condition';
import { InputWrapper, Number } from 'components/Form';

import { t } from 'utils';

import { form } from 'stores';

import { SUPPORT_TASK_MANAGER_FORM } from '../../constants';

import { bibClassesStore } from '../../stores';

type Props = {
  show: boolean;
};

const Title = withStyles({
  root: {
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    padding: '0 0 20px 0',
  },
})(Typography);

@observer
export class FieldsBibClasses extends React.Component<Props> {
  onInputChangeBib = (event) => {
    let data = this.getBibDistanceClassesStarting() ?? {};
    const id = event.name[0].substring(3);
    data[id] = event.value;

    form.onChange(SUPPORT_TASK_MANAGER_FORM, 'bibDistanceClassStarting', data);
  };

  getBibDistanceClassesStarting = () => {
    return form.fetch<string>(SUPPORT_TASK_MANAGER_FORM, 'bibDistanceClassStarting') as any;
  };

  renderClassTitle = (title: string) => {
    return (
      <Grid item xs={9}>
        <Title>{title}</Title>
      </Grid>
    );
  };

  renderBibNumber = (id) => {
    return (
      <Grid item xs={3}>
        <InputWrapper
          name={'bib' + id}
          settings={{
            required: true,
            label: t.staticAsString('supportTaskManager.form.startingBib'),
            additional: {
              fullWidth: true,
              onInputChange: this.onInputChangeBib,
            },
            view: {
              variant: 'outlined',
            },
          }}
          Component={Number}
        />
      </Grid>
    );
  };

  render() {
    const { show } = this.props;

    return (
      <Show if={show}>
        {bibClassesStore.value?.map((item) => {
          return (
            <Grid container spacing={0} key={shortId()}>
              {this.renderClassTitle(item.label)}
              {this.renderBibNumber(item.value)}
            </Grid>
          );
        })}
      </Show>
    );
  }
}

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import React from 'react';

import { t } from 'utils';

let Toolbar = ({
  onAddNewSystemUser,
}: {
  onAddNewSystemUser(e: React.SyntheticEvent): void;
} & HOC.withIntl) => {
  return (
    <div className='toolbar'>
      <h1 className='title'>{t.staticAsString('systemUsers.list.header')}</h1>
      <Button onClick={onAddNewSystemUser} className='add-button' variant='contained'>
        <AddIcon className='icon' />
        {t.staticAsString('systemUsers.list.new_systemUser')}
      </Button>
    </div>
  );
};

export { Toolbar };

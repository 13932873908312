const browserLanguage = function () {
  const result =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    // @ts-ignore
    navigator.userLanguage; // IE 11.0

  return result.slice(0, 2);
};

export { browserLanguage };

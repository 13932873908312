import IconInfoOutlined from '@mui/icons-material/InfoOutlined';
import { Box, Collapse, InputAdornment, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { debounce, isNull, pick } from 'lodash';
import { observer } from 'mobx-react';
import { updateRaceExternalLinkAction } from 'modules/RaceDetails/actions/updateRaceExternalLink';
import { UPDATE_RACE_EXTERNAL_LINK } from 'modules/RaceDetails/constants';
import { raceDetailsStore, raceTypeStore } from 'modules/RaceDetails/stores';
import SavingIndicator from 'modules/Races/components/Edit/Toolbar/SavingIndicator';
import { formStyles } from 'modules/Races/components/styles';
import { getFieldValidationSchema } from 'modules/Races/validations';
import React, { FC, useCallback } from 'react';

import { TextField } from 'components/Form';

import { stringFormatter, t, Validator } from 'utils';

type Props = {
  raceUrl: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 950,
    [theme.breakpoints.up(2500)]: {
      marginTop: '28px',
    },
  },
  savingIndicator: {
    paddingBottom: 18,
    marginLeft: 18,
    '&:empty': {
      marginLeft: 0,
    },
  },
}));

export const RaceUrl: FC<Props> = observer(({ raceUrl }) => {
  const classes = formStyles();
  const c = useStyles();

  const switchValue = raceTypeStore.switchValue;
  const error = raceTypeStore.error;

  const updateLink = (value) => {
    raceTypeStore.setError(null);
    let formatted: string | null = stringFormatter.withHttpUrl(value);
    if (formatted === '') formatted = null;
    const errors = Validator.validate({ race_url: formatted }, getFieldValidationSchema('race_url', true));
    // @ts-ignore
    errors ? raceTypeStore.setError((errors['race_url'][0] as string) || null) : updateRaceExternalLinkAction(formatted);
  };

  const debounceUpdateLink = useCallback(
    debounce((value) => {
      updateLink(value);
    }, 1000),
    [],
  );
  const handleChange = ({ value }) => {
    raceDetailsStore.partialRaceUpdate({
      race_url: value,
    });
    debounceUpdateLink(value);
  };

  return (
    <Collapse in={!switchValue}>
      <Box className={c.root}>
        <TextField
          name={'race_url'}
          value={raceUrl || ''}
          onChange={handleChange}
          error={error}
          label={t.staticAsString('races.new.race_url')}
          additional={{
            InputProps: {
              endAdornment: (
                <Tooltip classes={pick(classes, 'tooltip')} title={t('races.new.raceUrl.info')!}>
                  <InputAdornment style={{ cursor: 'pointer' }} position='end'>
                    <IconInfoOutlined color='disabled' />
                  </InputAdornment>
                </Tooltip>
              ),
            },
          }}
          view={{
            fullWidth: true,
            variant: 'outlined',
          }}
        />
        <SavingIndicator className={c.savingIndicator} action={UPDATE_RACE_EXTERNAL_LINK} hideBeforeFirstActionLoad />
      </Box>
    </Collapse>
  );
});

const LikeFields = ['name'];
const BetweenFields = ['commission.amount', 'commission.percent_value'];

export namespace CommissionFiltersMapper {
  export function toBackend(currentCurrencyTab: number, filters: CommissionTableFilters[]) {
    const searchFilters = filters.reduce(
      (acc, { id, value }) => {
        if (LikeFields.includes(id)) {
          acc.search += `${id}:${value};`;
          acc.searchFields += `${id}:like;`;
        }
        if (BetweenFields.includes(id)) {
          acc.search += `${id}:${value.join(',')};`;
          acc.searchFields += `${id}:between;`;
        }
        return acc;
      },
      {
        search: 'currency_id:' + currentCurrencyTab + ';',
        searchFields: 'currency_id:=;',
        searchJoin: 'AND',
      },
    );

    searchFilters.search = searchFilters.search.substring(0, searchFilters.search.length - 1);
    searchFilters.searchFields = searchFilters.searchFields.substring(0, searchFilters.searchFields.length - 1);
    return searchFilters;
  }
}

type CommissionFilters = {
  with?: string;
  limit?: number;
  searchJoin?: 'AND';
  orderBy?: string;
  sortedBy?: string;
  page?: number;
  searchFields?: string;
  search?: string;
};

type CommissionTableFilters = {
  id: string;
  value: any;
};

import { isFunction } from 'lodash';
import { Observer } from 'mobx-react';
import * as React from 'react';

import localeStore from 'stores/locale';

type Props = {
  render: Function;
};

function DynamicTranslationComponent(props: Props) {
  return (
    <Observer>
      {() => {
        localeStore.value;

        const { render } = props;
        if (!isFunction(render)) {
          return null;
        }

        return render();
      }}
    </Observer>
  );
}

const DynamicTranslation = React.memo<Props>(DynamicTranslationComponent);

function dynamicTranslation(render: AnyFunction) {
  return <DynamicTranslation render={render} />;
}

export { DynamicTranslation, dynamicTranslation };

import { toJS } from 'mobx';
import { raceDetailsStore } from 'modules/RaceDetails/stores';

import { EXPORT_DATA, EXPORT_DATA_SIZES } from 'src/constants';

import { appEnvControl, handleExport } from 'utils';

import { exportJobService } from 'services';

export const exportSummary = async (raceId: number): Promise<void> => {
  const [status, response] = await exportJobService.exportRacesSummaryRequest(raceId, {});

  const total = toJS(raceDetailsStore.raceDetails?.value)?.translations?.length || 1;

  if (!status) {
    return;
  }
  const fileName = `StartList.xlsx`;
  const { id } = response.data.data;
  const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
  let estimatedLoadingTime = total / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second
  if (total > maxTotal) {
    estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
  }

  handleExport({
    id,
    fileName,
    estimatedLoadingTime,
    onRetry: () => exportSummary(raceId),
  });
};

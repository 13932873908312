import { singleFields } from '../constants';

import { isGoalType } from '../derivations';

function headerTranslation(column: string) {
  const isGoalAltitude = isGoalType('altitude').get();

  switch (column) {
    case singleFields.distance_logged:
      return isGoalAltitude ? 'results.fields.distance_logged_altitude' : 'results.fields.distance_logged_distance';
    default:
      return `results.fields.${column}`;
  }
}

export { headerTranslation };

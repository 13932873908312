import { TextField, Autocomplete } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { Checkbox } from 'components/Fields';

type Props = {
  options: Array<Object>;
  value: Array<Object>;
  label: string;
  placeholder: string;
  noOptionsText: string;
  onClose: Function;
  getOptionLabel: (option: AnyObject) => string;
};

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #a8aeb8',
    height: '56px',
    overflow: 'hidden',
    borderRadius: 4,
    paddingLeft: '16px',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      border: 0,
      borderRadius: '4px 4px 0 0',
      boxShadow: '0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)',
      backgroundColor: '#fff',
      zIndex: '5',
    },
  },
  focused: {},
  option: {
    display: 'flex',
    minHeight: '42px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between !important',
    padding: 8,
  },
  optionTitle: {
    letterSpacing: '0.5px',
    lineHeight: 1.75,
    fontSize: '16px',
  },
  checkBox: {
    maxWidth: 26,
  },
  paper: {
    margin: '1px 0',
    boxShadow: '0px 3px 2px rgba(27,31,35,.15), 0px -3px 2px rgba(27,31,35,.15)',
  },
}));

export const AutocompleteWithTags = (props: Props) => {
  const classes = useStyles();
  const { getOptionLabel, onClose, value, placeholder, noOptionsText } = props;

  const [pendingValue, setPendingValue] = React.useState<Object[]>([]);
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (!isOpen) {
      // Update autocomlete selection with with formData value
      setPendingValue(value);
    }
  });

  const handleClose = () => {
    // Update step component formData
    onClose(pendingValue);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Autocomplete
        multiple
        onOpen={() => setOpen(true)}
        open={isOpen}
        disableCloseOnSelect
        onClose={handleClose}
        fullWidth
        disableClearable
        // disablePortal
        autoHighlight
        noOptionsText={noOptionsText}
        classes={{
          option: classes.option,
          paper: classes.paper,
          noOptions: classes.optionTitle,
        }}
        options={props.options}
        value={pendingValue}
        getOptionLabel={getOptionLabel}
        onChange={(event, newValue) => {
          setPendingValue(newValue);
        }}
        renderTags={() => null}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              classes: {
                root: classes.root,
              },
              disableUnderline: true,
            }}
            fullWidth
            placeholder={placeholder}
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <div className={classes.optionTitle}>{getOptionLabel(option)}</div>
            <Checkbox className={classes.checkBox} value={selected} name='checkbox' disabled={false} onChange={() => {}} />
          </li>
        )}
      />
    </React.Fragment>
  );
};

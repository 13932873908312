import { cloneDeep } from 'lodash';

export const mapDistanceClasses = (data: AnyObject[]): AnyObject[] => {
  const copy = cloneDeep(data);

  return copy.map((item) => {
    return {
      key: item.value.id,
      value: item.value.id,
      label: `${item.value.id} - ${item.value?.title}`,
    };
  });
};

import axios from 'axios';
import { sortBy } from 'lodash';
import { generatePath } from 'react-router-dom';

import { HELPER_RACE, RACES_URL, DISTANCES, HELPER_DISTANCE, ROUTES, DISTANCE_URL, DISTANCES_URL } from 'src/constants';
import { mapTranslationDistance } from 'src/modules/Distances/actions';

import { request, action, history, t } from 'utils';

import { helperDistancesStore, toastStore } from 'stores';
import { HelperDistances as HelperDisctancesStore } from 'stores';

import { LoadableService } from './loadable';

const WITH_RELATION_PARAMS = {
  single: 'prices;classes;disciplines;waves;registration_fields;custom_fields.values;race.distances;race.currency',
  team: 'prices;classes;disciplines;waves;race.distances;race.currency;teams;registration_fields',
  update:
    'waves;custom_fields.values;editor_settings;prices;registration_fields;classes;disciplines;checkpoints.assistants.organizer;email_content;medicalAssistants;goal;translations;custom_fields.image',
};

type queryTypeEnum = 'single' | 'team' | 'update';

// DEPRECATED, do not use
class HelperDistancesService extends LoadableService<DistanceType, FiltersType, HelperDisctancesStore> {
  constructor(store: HelperDisctancesStore) {
    super({
      store,
      resourcesUrl: DISTANCES_URL,
      resource: HELPER_RACE,
      resourceUrl: DISTANCES_URL,
    });
  }

  // TODO, replace with LOAD_HELPER_DISTANCES
  @request({ action: `LOAD_${DISTANCES}` })
  async loadRaceDistancesRequest(id: number, params: any): Promise<any> {
    return axios.get(`${RACES_URL}/${id}/distances`, { params });
  }

  // TODO, replace with LOAD_HELPER_DISTANCES
  @action({ action: `LOAD_${DISTANCES}`, minRequestTime: 200 })
  async loadRaceDistances(race_id: number): Promise<any> {
    let { filters, page } = this.store || {};

    const queryParams = {
      ...filters,
      limit: 20,
    };

    const [status, response] = await this.loadRaceDistancesRequest(race_id, queryParams);

    if (status) {
      const values = response.data[this.resourcesRoot];
      this.addValues(values, page, queryParams);

      const paginationMeta = response.data[this.metaRoot] && response.data[this.metaRoot][this.paginationMetaRoot];
      if (paginationMeta) {
        this.store.addPaginationMeta(paginationMeta);
      }
    }

    return response;
  }

  @request({ action: `LOAD_${HELPER_DISTANCE}` })
  async loadDistanceRequest(id: number, params: any): Promise<any> {
    const url = generatePath(DISTANCE_URL, { distanceId: id });
    return axios.get(url, { params });
  }

  @action({ action: `LOAD_${HELPER_DISTANCE}`, minRequestTime: 800 })
  async loadRaceDistance(distanceId: number, queryType: queryTypeEnum = 'single'): Promise<any> {
    let { page } = this.store || {};

    const queryParams = {
      with: WITH_RELATION_PARAMS[queryType as queryTypeEnum],
      page,
      withCount: 'racers;teams',
    };

    const [status, response] = await this.loadDistanceRequest(distanceId, queryParams);
    if (status) {
      response.data.data.custom_fields = sortBy(response.data.data.custom_fields, 'index');
      const mappedData = mapTranslationDistance(response.data.data);
      this.store.addSelectedValue(mappedData);
    } else {
      history.replace(ROUTES.notFound);
    }

    return response;
  }

  @request({ action: `DELETE_${HELPER_DISTANCE}` })
  async deleteDistanceRequest(raceId: number, id: number): Promise<any> {
    return axios.delete(`${DISTANCES_URL}/${id}`);
  }

  @action({ action: `DELETE_${HELPER_DISTANCE}` })
  async deleteDistance(raceId: number, distanceId: number): Promise<any> {
    const [status] = await this.deleteDistanceRequest(raceId, distanceId);

    if (status) {
      history.push(`${ROUTES.racesRoute}/${raceId}`);
      toastStore.show(t.staticAsString('distances.delete'));
      return;
    }

    toastStore.show(t.staticAsString('distances.canNotDelete'));
  }
}

const helperDistancesService = new HelperDistancesService(helperDistancesStore);
export { HelperDistancesService, helperDistancesService };

import { t } from 'utils';

export const RADIO_PERCENTAGE_VALUE = 'Percentage';
export const RADIO_PERCENTAGE = {
  key: 'percentage',
  label: t('coupons.new.perc'),
  value: RADIO_PERCENTAGE_VALUE,
};

export const RADIO_AMOUNT_VALUE = 'Amount';
export const RADIO_AMOUNT = {
  key: 'amount',
  label: t('coupons.new.amount'),
  value: RADIO_AMOUNT_VALUE,
};

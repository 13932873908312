import { omit } from 'lodash';

import { EXPORT_DATA, EXPORT_DATA_SIZES } from 'src/constants';

import { handleExport, t } from 'utils';
import { appEnvControl } from 'utils/appEnvironment';

import { exportJobService } from 'services';

import { toastStore } from 'stores';

import { startlistRacersStore, teamsStore, startlistMembersStore } from '../stores';

export const exportRacers = async (type: string, withWaves?: boolean): Promise<void> => {
  if (withWaves) return exportRacersWithWaves(type, startlistRacersStore);

  const { paginationMeta, params, distanceId } = startlistRacersStore;
  const { total = EXPORT_DATA.defaultMetaData } = paginationMeta;
  if (total > EXPORT_DATA.metaDataLimit) {
    toastStore.show(t.staticAsString('shared.loadPopup.tooMuchData'));
    return;
  }

  const [status, response] = await exportJobService.exportStartList(distanceId, type, params);

  if (!status) {
    return;
  }

  const fileName = `StartList.${type}`;

  const { id } = response.data.data;
  const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
  let estimatedLoadingTime = total / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second
  if (total > maxTotal) {
    estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
  }

  handleExport({
    id,
    fileName,
    estimatedLoadingTime,
    onRetry: () => exportRacers(type),
  });
};

export const exportRacersWithWaves = async (type: string, store: AnyObject): Promise<void> => {
  const { paginationMeta, params, distanceId } = store;
  const { total = EXPORT_DATA.defaultMetaData } = paginationMeta;

  const exportSearch = params.search || '';
  const waveId = Number(exportSearch.replace(/.*wave_id:(\d+).*/, '$1') || NaN);

  let filteredParams = {};

  if (waveId) {
    filteredParams = { search: `id:${waveId}`, searchFields: 'id:in' };
  }

  if (total > EXPORT_DATA.metaDataLimit) {
    toastStore.show(t.staticAsString('shared.loadPopup.tooMuchData'));
    return;
  }

  const [status, response] = await exportJobService.exportStartListWithWaves(distanceId, type, filteredParams);

  if (!status) {
    return;
  }

  const fileName = `StartListWaves.${type}`;

  const { id } = response.data.data;
  const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
  let estimatedLoadingTime = total / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second
  if (total > maxTotal) {
    estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
  }

  handleExport({
    id,
    fileName,
    estimatedLoadingTime,
    onRetry: () => exportRacersWithWaves(type, store),
  });
};

export const exportMembers = async (type: string): Promise<void> => {
  const { paginationMeta, params, distanceId } = startlistMembersStore;
  const { total = EXPORT_DATA.defaultMetaData } = paginationMeta;
  if (total > EXPORT_DATA.metaDataLimit) {
    toastStore.show(t.staticAsString('shared.loadPopup.tooMuchData'));
    return;
  }

  const [status, response] = await exportJobService.exportStartList(distanceId, type, params);

  if (!status) {
    return;
  }

  const fileName = `StartList.${type}`;
  const { id } = response.data.data;
  const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
  let estimatedLoadingTime = total / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second
  if (total > maxTotal) {
    estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
  }

  handleExport({
    id,
    fileName,
    estimatedLoadingTime,
    onRetry: () => exportMembers(type),
  });
};

export const exportTeams = async (type: string, withWaves?: boolean): Promise<void> => {
  if (withWaves) return exportRacersWithWaves(type, teamsStore);

  const { paginationMeta, params, distanceId } = teamsStore;
  const { total = EXPORT_DATA.defaultMetaData } = paginationMeta;
  if (total > EXPORT_DATA.metaDataLimit) {
    toastStore.show(t.staticAsString('shared.loadPopup.tooMuchData'));
    return;
  }

  const [status, response] = await exportJobService.exportTeamStartList(distanceId, type, params);

  if (!status) {
    return;
  }

  const fileName = `StartList.${type}`;
  const { id } = response.data.data;
  const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
  let estimatedLoadingTime = total / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second
  if (total > maxTotal) {
    estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
  }

  handleExport({
    id,
    fileName,
    estimatedLoadingTime,
    onRetry: () => exportTeams(type),
  });
};

import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { DISTANCE_COMPLETED_ORDERS_URL } from 'src/constants';

import { request, action } from 'utils';

import { LOAD_UPDATE_FORM } from '../constants';

import { IHasOrdersResponse } from '../types';

class OrdersService {
  @request({ action: shortid() })
  private request(distanceId: number): Promise<any> {
    const url = generatePath(DISTANCE_COMPLETED_ORDERS_URL, { distanceId });
    return axios.get<IHasOrdersResponse>(url);
  }

  @action({ action: LOAD_UPDATE_FORM })
  async hasCompletedOrders(distanceId: number) {
    const [status, response] = await this.request(distanceId);

    if (status) {
      return Boolean(response.data.has_completed_orders);
    }

    return false;
  }
}

export { OrdersService };

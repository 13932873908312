import React from 'react';

import { Icon } from 'components/Icon';

type Props = {
  value: number;
  currency: string;
};

export function RefundDisplay({ value, currency }: Props) {
  return (
    <span style={{ color: '#BD0000', display: 'flex', alignItems: 'center' }}>
      —{`${value} ${currency}`} <Icon style={{ marginLeft: 11 }} value='refund' />
    </span>
  );
}

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { Button } from 'components/core';

import { t } from 'utils';

import { NewResultForm } from '../../components/NewResultForm';

import { ResultsContext } from '../../context';

function NewResult() {
  const context = React.useContext(ResultsContext);

  return (
    <Observer>
      {() => {
        const { stores, actions } = context.results;
        const { newResultModal } = stores;
        const { submit, close } = actions.newResultForm;

        const isOpen = newResultModal.value;

        return (
          <Dialog open={isOpen} onClose={close}>
            <DialogTitle>{t.staticAsString('results.addResultsModal')}</DialogTitle>

            <Divider />

            <DialogContent style={{ width: 540 }}>
              <NewResultForm onSubmit={submit} />
            </DialogContent>

            <Box m={0.5} />

            <Divider />

            <DialogActions>
              <Button type='cancel' onClick={close}>
                {t.staticAsString('shared.cancel')}
              </Button>
              <Button type='apply' onClick={submit}>
                {t.staticAsString('results.addResult')}
              </Button>
              <Box px={0.5} />
            </DialogActions>
          </Dialog>
        );
      }}
    </Observer>
  );
}

export { NewResult };

import { makeStyles } from '@mui/styles';

export const languageSelectStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 24,
    '&:hover': {
      '& p': {
        textDecoration: 'underline',
      },
      '& div': {
        // icon
        opacity: 1,
      },
    },
    '& p': {
      fontSize: 12,
      color: 'white',
      marginLeft: 10,
    },
  },
  icon: {
    '& div': {
      // svg, div container initially is 38px x 24px, which is not square obviously
      display: 'flex',
      alignItems: 'center',
    },
    color: 'white',
    opacity: 0.8,
  },
}));

/*
 * TODO TS, some of those styles can be broken see https://stackoverflow.com/questions/59464618/using-custom-theme-in-makestyles
 * and styles in here from theme that be not fetched cause theme is not created yet
 * PLEASE CHECK
 * just uncomment theme: any to check what can be broken
 */
export const dialogStyles = makeStyles((theme: any) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '19px 16px 18px 16px',
    lineHeight: 1.2,
  },
  titleText: {
    color: theme.palette.grey7,
    fontSize: 20,
    fontWeight: 500,
  },
  closeButton: {
    position: 'absolute',
    top: 7,
    right: 4,
    '& svg': {
      color: theme.palette.grey6,
      maxWidth: 18,
      maxHeight: 18,
    },
  },
  localesList: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 770,
    flexFlow: 'row',
    flexWrap: 'wrap',
    padding: '0 16px 48px 16px',
  },
  locale: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 203,
    height: 50,
    marginTop: 25,

    '& span': {
      marginLeft: 16,
      maxWidth: 22,
      maxHeight: 16,
    },
    '&:hover': {
      backgroundColor: theme.palette.white3,
      '& p': {
        fontSize: 16,
        fontWeight: 500,
      },
    },
  },
  localeText: { fontSize: 16, fontWeight: 'normal', paddingLeft: 12 },
  selected: {
    fontSize: 16,
    fontWeight: 500,
  },
}));

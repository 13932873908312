import { t } from 'utils/t';

export const CouponDescription = (props: { couponDescription: string }) => {
  const { couponDescription } = props;
  return (
    <div className='coupon-description'>
      <p className='heading'>{t.staticAsString('coupons.detail.couponDescription')}</p>
      <div className='description'>{couponDescription}</div>
    </div>
  );
};

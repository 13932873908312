import classNames from 'classnames';
import * as React from 'react';
import { DragDropContext, DragStart, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';

type DnDProps = {
  children: React.ReactNode;
  droppableId: string;
  isOverClass?: string;
  className?: string;
  blocked?: boolean;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  onBeforeDragStart?: (initial: DragStart) => void;
};

class DNDContainer extends React.Component<DnDProps> {
  renderWrapper = () => (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => {
    const { children, isOverClass, className } = this.props;
    const { droppableProps, innerRef, placeholder } = provided;
    const { isDraggingOver } = snapshot;

    const classes = classNames(className, {
      [isOverClass || '']: isDraggingOver,
    });

    return (
      <div {...droppableProps} className={classes} ref={innerRef}>
        {children}
        {placeholder}
      </div>
    );
  };

  render() {
    const { droppableId, onBeforeDragStart, onDragEnd, blocked, children } = this.props;

    if (blocked) {
      return <div>{children}</div>;
    }

    return (
      <DragDropContext {...{ onBeforeDragStart }} onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId}>{this.renderWrapper()}</Droppable>
      </DragDropContext>
    );
  }
}

export { DNDContainer };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { CAMPAIGN_LINK_URL, CAMPAIGN_LINK_ORDERS_URL } from 'src/constants';

import { action, request } from 'utils/core';

import { LOAD_CAMPAIGN_LINK, LOAD_CAMPAIGN_LINK_ORDERS } from '../constants';

import { campaignLinkOrderStore } from '../stores';

import { TransactionsFilters } from '../types';

class CampaignLinkDetailsService {
  @request({ action: LOAD_CAMPAIGN_LINK })
  private loadCampaignLinkRequest(token: string): any {
    const url = generatePath(CAMPAIGN_LINK_URL, { token: token });
    return axios.get(url);
  }

  @action({ action: LOAD_CAMPAIGN_LINK })
  async loadCampaignLink(token: string): Promise<any> {
    const [isOk, response] = await this.loadCampaignLinkRequest(token);
    if (isOk) {
      return response.data.data;
    }
  }

  @request({ action: LOAD_CAMPAIGN_LINK_ORDERS })
  private loadCampaignLinkOrdersRequest(token: string, filters: TransactionsFilters): any {
    const url = generatePath(CAMPAIGN_LINK_ORDERS_URL, { token: token });
    return axios.get(url, { params: filters });
  }

  @action({ action: LOAD_CAMPAIGN_LINK_ORDERS })
  async loadCampaignLinkOrders(token: string, filters: TransactionsFilters): Promise<any> {
    const [isOk, response] = await this.loadCampaignLinkOrdersRequest(token, filters);
    if (isOk) {
      campaignLinkOrderStore.set(response.data);
    }
  }
}

export const campaignLinkDetailsService = new CampaignLinkDetailsService();

import { createFilterOptions } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { omit } from 'lodash';
import { Observer } from 'mobx-react';
import moment from 'moment';

import { InputWrapper, AutocompleteSelectInfinityLoad, Form, DatePicker } from 'components/Form';

import { t } from 'utils/t';

import { form } from 'stores';

import { ReportsService } from '../services/reports.service';

import { reportRacesStore } from '../stores/races';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isServiceFee: boolean;
};
const filterOptions = createFilterOptions<any>({
  stringify: (el) => el.label,
});

export default function ReportsModal({ isOpen, isServiceFee, onClose }: Props) {
  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    const data = form.fetch<any>('generate-report');
    let payload: AnyObject = {
      date_from: data.date_range.startDate.format('YYYY-MM-DD'),
      date_to: data.date_range.endDate.format('YYYY-MM-DD'),
    };
    if (isServiceFee) {
      ReportsService.generateServiceFeeReport(payload);
    } else {
      payload.race_id = data.race_id.id;
      ReportsService.generateReport(payload);
    }
    onClose();
  };

  return (
    <Observer
      render={() => {
        const loadMoreRaces = () => {
          ReportsService.loadRaces();
        };
        const searchRaces = (text: string) => {
          reportRacesStore.clear();
          reportRacesStore.setParams({
            page: 1,
            search: `name:${text}`,
            searchFields: `name:like`,
          });
          ReportsService.loadRaces();
        };
        return (
          <Dialog maxWidth='md' fullWidth open={isOpen} onClose={handleClose}>
            <DialogTitle>{isServiceFee ? 'Generate Service Fee Report' : 'Generate Report'}</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ mb: 2 }}>
                {isServiceFee ? 'Select date range' : 'Select event and date range to generate report'}
              </DialogContentText>
              <Form id='generate-report' onSubmit={handleSubmit}>
                {!isServiceFee && (
                  <InputWrapper
                    name='race_id'
                    settings={{
                      additional: {
                        fullWidth: true,
                        options: reportRacesStore.races,
                        filterOptions,
                      },
                      label: `${t.staticAsString('coupons.new.race')}`,
                      view: {
                        required: true,
                        variant: 'outlined',
                        reservePlaceForHelperText: true,
                      },
                      hasMore: reportRacesStore.hasMore,
                      loadMore: loadMoreRaces,
                      onSearch: searchRaces,
                    }}
                    Component={AutocompleteSelectInfinityLoad}
                  />
                )}
                <InputWrapper
                  name='date_range'
                  settings={{
                    additional: {
                      fullWidth: true,
                    },
                    maxDate: moment(),
                    label: `${t.staticAsString('coupons.new.date_from')} — ${t.staticAsString('coupons.new.date_to')}`,
                    view: {
                      required: true,
                    },
                  }}
                  Component={DatePicker}
                />
              </Form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSubmit}>Generate</Button>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
}

import classNames from 'classnames';
import * as React from 'react';

import { t } from 'utils';

import { Race as RaceModel } from 'models';

type Props = {
  item: RaceModel;
};

const StatusItem = ({ item }: Props) => {
  const value = item.value;
  const approveStatus = value.approve_status;

  switch (approveStatus) {
    case 1:
      return (
        <div className={classNames('draft-item awaiting')}>
          <p>{t.staticAsString('races.list.table.awaiting')}</p>
        </div>
      );
    case 2:
      return (
        <div className={classNames('draft-item rejected')}>
          <p>{t.staticAsString('races.list.table.rejected')}</p>
        </div>
      );
    case 3:
      return (
        <div className={classNames('draft-item approved')}>
          <p>{t.staticAsString('races.list.table.approved')}</p>
        </div>
      );
    case 4:
      return (
        <div className={classNames('draft-item draft')}>
          <p>{t.staticAsString('races.list.table.draft')}</p>
        </div>
      );
    default:
      return (
        <div className={classNames('draft-item awaiting')}>
          <p>{t.staticAsString('races.list.table.awaiting')}</p>
        </div>
      );
  }
};

export { StatusItem };

import Plus from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

import { Props } from './';

const styles = makeStyles({
  root: {
    padding: '4px 16px 4px 10px',
  },

  startIcon: {
    marginRight: '2px',
    marginLeft: '0px',
  },
});

function Add({ children, onClick, ...rest }: Props) {
  return (
    <Button
      size='small'
      variant='contained'
      color='primary'
      onClick={onClick}
      startIcon={<Plus style={{ fontSize: '24px' }} />}
      classes={styles()}
      {...rest}
    >
      {children || null}
    </Button>
  );
}

export { Add };

import { cloneDeep } from 'lodash';

import { Distance } from 'models';

export const mapDistances = (data: AnyObject[]): AnyObject[] => {
  const copy = cloneDeep(data);

  return copy.map((item) => {
    const model = new Distance(item as any);
    return {
      key: item.id,
      value: item.id,
      currency: model.raceCurrency(),
      label: `${item.id} ${model.name()}`,
      distanceName: model.name(),
    };
  });
};

import { includes } from 'lodash';

import { t } from 'utils';

import { singleFields } from '../../constants';

import { distanceStore } from '../../stores';

import { isClassicDistance, isCumulativeDistance } from '../../derivations';

// [isEditAvailable, reasonWhyNot]
type Value = [boolean, React.ReactNode];

function isEditAvailable(column: string): Value {
  if (isDistanceNotFinished()) {
    return [false, t.staticAsString('results.messages.cannotEdit')];
  }

  if (isCumulativeColumn(column)) {
    return [false, t.staticAsString('results.messages.cannotEdiCumulative')];
  }

  return [true, null];
}

function isDistanceNotFinished() {
  const currentDistance = distanceStore.modelSelected;
  if (!currentDistance) {
    return false;
  }

  return !currentDistance.isStatusFinished() && isClassicDistance.get();
}

function isCumulativeColumn(column: string) {
  if (!isCumulativeDistance.get()) {
    return false;
  }

  return includes(
    [
      singleFields.time_result,
      singleFields.start_time,
      singleFields.finish_time,
      singleFields.distance_logged,
      singleFields.distance_logged_at,
      singleFields.activity_link,
    ],
    column,
  );
}

export { isEditAvailable };

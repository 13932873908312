import { isEmpty } from 'lodash';
import { handleEditLink } from 'modules/SmartCampaignLinks/Table/actions';
import { CampaignLink } from 'modules/SmartCampaignLinks/shared/types';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { BE_DATE_FORMAT } from 'src/constants';

import { Show } from 'components/Condition';
import { Icon } from 'components/Icon';
import { DateRange } from 'components/TimeRange';

import { t } from 'utils/t';

import { exportCampaignLink } from '../actions';

const HeaderWrapper = styled.div`
  border: 1px solid rgba(168, 174, 184, 0.24);
  border-radius: 4px;
  padding: 24px 16px;
`;

const HeaderTopLine = styled.div`
  display: flex;
  justify-content: space-between;
  .heading {
    font-weight: 700;
    font-size: 24px;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: center;
  .xls {
    margin-right: 19px;
  }
`;

const GeneralInfoBlock = styled.div`
  display: flex;
  margin-bottom: 11px;
  .general-block-section {
    display: flex;
    flex-wrap: wrap;
  }
`;

const CouponHeading = styled.div`
  font-weight: 700;
  font-size: 20px;
`;

const Name = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #a8aeb8;
  margin-right: 8px;
`;
const Value = styled.span`
  font-size: 16px;
  font-weight: 400;
  margin-right: 32px;
`;

const GeneralInfoItem = ({ name, value }) => {
  return (
    <span>
      <Name>{name}</Name>
      <Value>{value}</Value>
    </span>
  );
};

export const Header: React.FC<{ campaignLinkData: CampaignLink }> = ({ campaignLinkData }) => {
  const onEdit = (): void => {
    handleEditLink(campaignLinkData.token);
  };

  const onExport = async (token: string, id: number) => {
    await exportCampaignLink(token, id);
  };

  return (
    <HeaderWrapper>
      <HeaderTopLine>
        <span className='heading'>{`${t.staticAsString('campaignLinks.name')}: ${campaignLinkData.name}`}</span>
      </HeaderTopLine>
      <GeneralInfoBlock>
        <div className='general-block-section'>
          <GeneralInfoItem name={t.staticAsString('sidebar.races')} value={campaignLinkData.races.map((race) => race.name).join(', ')} />
        </div>
        <div className='general-block-section'>
          <GeneralInfoItem name={'URL'} value={campaignLinkData.link} />
        </div>
      </GeneralInfoBlock>
      <GeneralInfoBlock>
        <div className='general-block-section'>
          <GeneralInfoItem name={'Description'} value={campaignLinkData.description} />
        </div>
      </GeneralInfoBlock>
    </HeaderWrapper>
  );
};

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/styles';
import { Observer } from 'mobx-react';
import { duplicateModalStore } from 'modules/RaceDetails/stores';
import React, { useEffect } from 'react';

import { t } from 'utils/t';

import { Fields } from './Fields';
import { ModalBottom } from './ModalBottom';

const DialogStyled = styled(Dialog)(({ theme }) => ({
  fontSize: 20,
  borderBottom: '1px solid #e0e0e0',
  fontWeight: 500,
  '& .MuiDialog-paperFullWidth': {
    maxWidth: '770px',
  },
}));

const DialogTitleStyled = styled(DialogTitle)(({ theme }) => ({
  fontSize: 20,
  borderBottom: '1px solid #e0e0e0',
  fontWeight: 500,
}));

const BodyText = styled(Box)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  padding: '19px 0',
  color: theme.palette.grey6,
}));

const DuplicateForm = () => {
  useEffect(() => {
    return () => {
      duplicateModalStore.clear();
    };
  }, []);

  return (
    <Observer>
      {() => (
        <DialogStyled
          fullWidth
          open={Boolean(duplicateModalStore.value?.open)}
          onClose={() => duplicateModalStore.set({ open: false, distanceId: null, type: null })}
        >
          <DialogTitleStyled>{t.staticAsString('races.confirmPopup.mainTitle')}</DialogTitleStyled>
          <DialogContent>
            <BodyText>{t.staticAsString('races.detail.duplication.confirm')}</BodyText>
            <Fields participantsCount={duplicateModalStore.value?.participantsCount} duplicateType={duplicateModalStore.value?.type} />
          </DialogContent>
          <ModalBottom />
        </DialogStyled>
      )}
    </Observer>
  );
};

export default DuplicateForm;

import classNames from 'classnames';
import * as React from 'react';

import { TextField } from '../TextField';
import { dateTimeMask, formatMaskToTimestamp, formatTimestampToMask } from '../core/mask';

type Props = {
  additional?: TextFieldAdditionalProps;
  view?: BaseViewProps;
} & FieldBaseProps;

/*
 * Input:
 * - time.WorkDate
 * - null
 *
 * Output:
 * - time.WorkDate - Valid value
 * - null          - Empty value
 * - undefined     - Not a valid value
 */
function DateTime(props: Props) {
  const [localState, setState] = React.useState(formatTimestampToMask(props.value));

  return (
    <TextField
      {...props}
      className={classNames('input-mask', props.className)}
      additional={{
        InputProps: { inputComponent: dateTimeMask },
        ...(props.additional || {}),
        ...(props.view || {}),
      }}
      value={localState}
      onChange={({ name, value }, e) => {
        setState(value);
        props.onChange({ name, value: formatMaskToTimestamp(value) }, e);
      }}
    />
  );
}

export { DateTime, DateTime as DateTimeField };

import { computed, makeObservable } from 'mobx';

import { SportType as SportTypeModel } from 'models';

import { Loadable } from './loadable';

class SportTypeAuxiliary extends Loadable<SportTypeType, FiltersType> {
  declare filters: FiltersType;

  constructor() {
    super();

    makeObservable(this);
    this.filters = {
      limit: 200,
      search: 'active:1',
      searchFields: 'active:=',
    };
  }

  @computed
  get modelValues(): Array<SportTypeModel> {
    return this.values.map((sportType) => new SportTypeModel(sportType));
  }

  findModelValue(id: number): nil | SportTypeModel {
    return this.modelValues.find((model) => model.value.id === id);
  }

  valuesForSelect(): Array<SelectOption> {
    return this.modelValues.map((value) => ({
      key: value.value.id,
      value: value.value.id,
      label: value.title() || '',
    }));
  }
}

export { SportTypeAuxiliary };
export default new SportTypeAuxiliary();

import ArrowDown from '@mui/icons-material/ArrowDropDown';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styled from '@mui/styles/styled';
import classNames from 'classnames';
import * as React from 'react';

function NoOptionsMessage(props: { innerProps: any; selectProps: any; children: React.ReactNode }) {
  return (
    <Typography color='textSecondary' className={classNames(props.selectProps.classes.noOptionsMessage, 'no-option')} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }: { inputRef: any }) {
  return <div ref={inputRef} {...props} />;
}

function Option(props: { innerRef: any; isFocused: boolean; isSelected: boolean; innerProps: any; children: React.ReactNode }) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component='div'
      className={classNames({ active: props.isSelected || props.isFocused }, 'menu-item')}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function SingleValue(props: { selectProps: any; innerProps: any; children: React.ReactNode }) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props: { selectProps: any; children: React.ReactNode }) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function Menu(props: { innerProps: any; selectProps: any; children: React.ReactNode }) {
  return (
    <Paper square className={classNames(props.selectProps.classes.paper, 'select-menu')} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

function DropdownIndicator(props: any) {
  return <ArrowDown />;
}

function IndicatorSeparator(props: any) {
  return null;
}

function Control(props: {
  classes?: {
    [K in string]: string;
  };
  name: string;
  selectValue: string | number;
  errors: Array<string>;
  id?: string;
  formName: string;
  label?: string;
  autoFocus?: boolean;
  className?: string;
  selectProps: any;
  innerRef: any;
  innerProps: any;
  children: React.ReactNode;
}) {
  const classes = {
    root: 'field-root',
    input: 'field',
  };
  const inputClasses = { ...{ focused: 'focused' }, underline: props.selectValue ? 'filled-underline' : 'underline', ...classes };
  return (
    <Input
      style={{ height: '37px', marginTop: '16px' }}
      id={props.formName}
      classes={inputClasses}
      inputComponent={inputComponent}
      value={props.selectValue}
      inputProps={{
        className: props.selectProps.classes.input,
        inputRef: props.innerRef,
        children: props.children,
        ...props.innerProps,
      }}
      autoFocus
      fullWidth
      {...props.selectProps.textFieldProps}
    />
  );
}

export { Option, Control, NoOptionsMessage, SingleValue, ValueContainer, Menu, DropdownIndicator, IndicatorSeparator };

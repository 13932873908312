export * from './singleResultsColumns';
export * from './teamResultsColumns';
export * from './splitResultsColumns';

export const LOAD_RACE = 'RESULTS_LOAD_RACE';
export const LOAD_AUTOCOMPLETE = 'RESULTS_LOAD_NEW_RESULTS_AUTOCOMPLETE';
export const LOAD_DISTANCES = 'RESULTS_LOAD_DISTANCES';
export const LOAD_DISTANCE = 'RESULTS_LOAD_DISTANCE';
export const DELETE_DISTANCE = 'RESULTS_DELETE_DISTANCE';
export const LOAD_SINGLE_RESULTS = 'RESULTS_SINGLE_LOAD_RESULTS';
export const LOAD_TEAM_RESULTS = 'RESULTS_TEAM_LOAD_RESULTS';
export const LOAD_RESULTS = 'RESULTS_LOAD_RESULTS';
export const LOAD_RACERS = 'RESULTS_LOAD_RACERS';

export const UPDATE_RACER = 'RESULTS_UPDATE_RACER';
export const UPDATE_TEAM = 'RESULTS_UPDATE_TEAM';
export const UPDATE_RESULT = 'RESULTS_UPDATE_RESULT';
export const CREATE_OR_UPDATE_SPLIT = 'RESULTS_CREATE_OR_UPDATE_SPLIT';

export const EXPORT_RESULTS = 'RESULTS_EXPORT_RESULTS';
export const EXPORT_TEMPLATE = 'RESULTS_EXPORT_TEMPLATE';
export const IMPORT_RESULTS = 'RESULTS_IMPORT_TEMPLATE';

export const UPDATE_DISTANCE = 'RESULTS_UPDATE_DISTANCE';
export const DELETE_RESULTS = 'RESULTS_DELETE_RESULTS';
export const DELETE_RESULT = 'RESULTS_DELETE_RESULT';

export const UPDATE_DISTANCE_RESULTS = 'UPDATE_DISTANCE_RESULTS';

export const DEFAULT_COLUMN_WIDTH = 250;

export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const EMPTY_TIME_DIFF = '--:--:--';
export const DNS = 'DNS';
export const DNF = 'DNF';

export const IMPORT_HEADER_ROW = {
  bib_en: 'Bib',
  bib_sv: 'Bib',
  time_result_en: 'Time Result',
  time_result_sv: 'Resultat',
};

export const NEW_RESULT_FORM_ID = 'RESULTS_NEW_RESULT_FORM_ID';
export const ALPHABET = [...'abcdefghijklmnopqrstuvwxyz'];

import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { CampaignLinksDetails } from './CampaignLinkDetails';
import { Edit, New } from './Form';
import { List } from './Table';

export const CampaignLinks = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.campaignLinks} component={List} />
      <Route path={ROUTES.createCampaignLink} component={New} />
      <Route path={ROUTES.campaignLinkEdit} component={Edit} />
      <Route path={ROUTES.campaignLinkDetails} component={CampaignLinksDetails} />
    </Switch>
  );
};

import { Observer } from 'mobx-react';
import { GET_GPX_ROUTE, SAVE_GPX_ROUTE } from 'modules/Distances/components/shared/Steps/GPX/constants';
import React, { ReactElement, ReactNode, useEffect } from 'react';
import styled from 'styled-components';

import { fontFamilyRoboto } from 'src/components/Form/Fields/FileUploader/style';
import { Loading } from 'src/components/Loading';
import {
  ButtonManageCheckPoints,
  CheckboxAboutUsing,
  Description,
  FileDropzone,
  FilePanel,
  MapView,
  RouteInfo,
} from 'src/modules/Distances/components/shared/Steps/GPX';
import { mount, saveGPX, unmount } from 'src/modules/Distances/components/shared/Steps/GPX/actions';
import { ManageCheckPointsModal } from 'src/modules/Distances/components/shared/Steps/GPX/components/ManageCheckPointsModal/ManageCheckPointsModal';
import { gpxRouteLoadingStore, gpxStore, isCheckedStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { GpxRoute } from 'src/modules/Distances/components/shared/Steps/GPX/types';
import { State as OriginalFormState } from 'src/modules/Distances/shared/stateHelpers';

import { Show } from 'components/Condition';

import { StepTitle } from '../Shared';

type Props = {
  formData: OriginalFormState;
  controls: ReactNode;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
  ) => void;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: ${fontFamilyRoboto};
`;

const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px 32px 20px;
`;

export const GPX = (props: Props): ReactElement => {
  const step = props.formData.step.value;

  useEffect(() => {
    isCheckedStore.value = Boolean(props.formData.distance.raceband_used);
    mount();
    return unmount();
  }, []);

  const handleUploadGPX = async (file: File) => {
    const fd = new FormData();
    fd.append('file', file);
    saveGPX(fd);
  };

  const onToggleCheckbox = (event: React.ChangeEventHandler | any) => {
    isCheckedStore.toogle();
    const { name, value } = event;
    const { formData, onChange } = props;

    const changedDistance = {
      distance: {
        ...formData.distance,
        [name]: value,
      },
    };
    onChange(changedDistance, '', () => {});
  };

  return (
    <Observer>
      {() => {
        const isGpxRouteLoading = gpxRouteLoadingStore.value;
        const gpxRoute = gpxStore.value as GpxRoute;

        return (
          <>
            <ManageCheckPointsModal />
            <StepTitle step={step} />

            <Wrapper>
              <Description />
              <Loading action={GET_GPX_ROUTE} />
              <Loading action={SAVE_GPX_ROUTE} />

              <Show if={!gpxRoute && !isGpxRouteLoading}>
                <FileDropzone setFile={handleUploadGPX} />
              </Show>

              <Show if={gpxRoute !== null}>
                <>
                  <FilePanel />
                  <MapView isModal={false} />
                  <CheckboxAboutUsing isChecked={isCheckedStore.value} onToggleCheckbox={onToggleCheckbox} />
                  <RouteInfo />
                  <ButtonManageCheckPoints />
                </>
              </Show>

              <BtnWrapper>{props.controls}</BtnWrapper>
            </Wrapper>
          </>
        );
      }}
    </Observer>
  );
};

export * from './tableColumns';

export const EXPORT_TYPES = ['csv', 'xlsx'];

export const POPPER_RELATIVE_Y_POSITION = -12;
export const POPPER_RELATIVE_X_POSITION = 0;

export const EXPORT_SYSTEM_USER = 'EXPORT_SYSTEM_USER';
export const DELETE_SYSTEM_USER = 'DELETE_SYSTEM_USER';
export const LOAD_SYSTEM_USER = 'LOAD_SYSTEM_USER';
export const LOAD_SYSTEM_USERS = 'LOAD_SYSTEM_USERS';
export const PERSIST_SYSTEM_USER = 'PERSIST_SYSTEM_USER';
export const PERSIST_SYSTEM_USERS = 'PERSIST_SYSTEM_USERS';
export const FAKE_LOAD_SYSTEM_USER = 'FAKE_LOAD_SYSTEM_USER';
export const LOAD_SYSTEM_USER_ROLES = 'LOAD_SYSTEM_USER_ROLES';
export const LOAD_ORGANIZERS = 'LOAD_ORGANIZERS';

import * as React from 'react';

import { TableHeaderItem } from 'components/Filter/TableHeaderItem';

import { t } from 'utils';

import { TASKS_COLUMNS } from '../../constants';

import { ActionCol } from '.';
import { HeadersType } from './';

const { MAIN_COLUMNS, COLUMN_WIDTH } = TASKS_COLUMNS;

const HeadersComponent: React.FC<HeadersType> = function () {
  return (
    <>
      <ActionCol />
      {
        MAIN_COLUMNS.map((column) => {
          const width = COLUMN_WIDTH[column];
          const className = `cell-${column}`;
          const translation = t.staticAsString(`supportTaskManager.list.fields.${column}` as TranslationLockedKeys);

          return <TableHeaderItem key={column} name={column} width={width} className={className} label={translation} />;
        }) as any
      }
    </>
  );
};

const CopyRacersHeaders = React.memo<HeadersType>(HeadersComponent);
export { CopyRacersHeaders };

import { stringFormatter } from 'utils';

import { form } from 'stores';

import { NEW_RESULT_FORM_ID } from '../constants';

import { NewResultForm } from '../types';

function fixInForm() {
  const data: NewResultForm = form.fetch(NEW_RESULT_FORM_ID);
  const newVal = correct(data.activity_link);

  form.onChange(NEW_RESULT_FORM_ID, 'activity_link', newVal);
}

function fixOnBlur(val: nil | string, onChange: FieldOnChange, onBlur: Function) {
  const newVal = correct(val);
  onChange({ name: 'activity_link', value: newVal }, null, onBlur);
}

function correct(val: nil | string): nil | string {
  if (!val) {
    return val;
  }

  return stringFormatter.withHttpUrl(val);
}

export const activityLinkCorrector = {
  fixOnBlur,
  fixInForm,
};

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import * as React from 'react';

import { useSelectStyles } from './styles';

type Props = {
  classes?: {
    [K in string]: string;
  };
  name: string;
  onChange: AnyFunction;
  value: Array<number>;
  errors: Array<string>;
  type?: string;
  id?: string;
  label?: string;
  className?: string;
  options: Array<SelectOption>;
  disabled: boolean;
  fullWidth?: boolean;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
};

const MultiSelect = ({
  name,
  onChange,
  value,
  errors = [],
  id = '',
  label = '',
  options = [],
  className,
  disabled = false,
  fullWidth = false,
  variant = 'outlined',
  classes,
}: Props) => {
  const formName = id ? `${id}_${name}` : name;
  const isErr = errors.length > 0;

  classes = useSelectStyles();

  return (
    // TODO: console errors, remove missing classes: formControl, fullWidth
    //     <FormControl
    //     className={classNames(className, classes.formControl, { error: isErr }, classes.fullWidth)}
    //     error={isErr}
    //     aria-describedby='name-error-text'
    //     disabled={disabled}
    //   >
    <FormControl
      className={classNames(className, { error: isErr }, classes.formControl)}
      error={isErr}
      aria-describedby='name-error-text'
      fullWidth={fullWidth}
      disabled={disabled}
      variant={variant}
    >
      <InputLabel htmlFor={formName} classes={{ root: 'form-label' }}>
        {label}
      </InputLabel>
      <Select multiple value={value} error={isErr} onChange={onChange} classes={classes} fullWidth={fullWidth} variant={variant}>
        {options.map((item) => {
          return (
            <MenuItem
              value={item.value}
              key={item.key}
              className={classNames('menu-item', {
                active: value.find((distance) => distance === item.key),
              })}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText className='errors' id={`${formName}_error`}>
        {errors.join(' ') || ' '}
      </FormHelperText>
    </FormControl>
  );
};

export { MultiSelect };

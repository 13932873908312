import copy from 'copy-to-clipboard';

import { t } from 'utils';

import { toastStore } from 'stores';

async function copyTextToClipboard(text: string, messageToDisplay?: string): Promise<void> {
  if (!navigator.clipboard) {
    copy(text);
    return;
  }

  try {
    await navigator.clipboard.writeText(text);

    if (messageToDisplay) {
      toastStore.show(t(messageToDisplay as TranslationLockedKeys));
    }
  } catch (err) {
    copy(text);
    console.error('Async: Could not copy text: ', err);
  }
}

export { copyTextToClipboard };

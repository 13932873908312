import { Warning } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { Observer } from 'mobx-react';

import { sessionStore } from 'stores';

import missingPaymentDetailsStore from './store';

type Props = {};

export default function Icon({}: Props) {
  if (sessionStore.isAdmin) return null;
  return (
    <Observer
      render={() => {
        const { isPaymentDetailsMissing } = missingPaymentDetailsStore;
        if (!isPaymentDetailsMissing) return null;
        return (
          <Tooltip
            title={
              <Typography variant='body1' sx={{ textAlign: 'center' }}>
                Update your currency and payment details
              </Typography>
            }
          >
            <Warning sx={{ color: '#CE5659', mr: 1 }} />
          </Tooltip>
        );
      }}
    />
  );
}

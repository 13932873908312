import { computed } from 'mobx';

import { form } from 'src/stores';

import { RACE_RESULTS_FORM_ID } from '../constants';

import { raceCustomFieldsStore, syncCustomFieldsStore } from '../stores';

import { raceDistancesStore, syncDistancesStore } from '../../Shared';

const filterSimple = (entity: string, name: string, store: AnyObject, distanceId?: number) => () => {
  if (!store.value) return [];

  const selectedOptions = form.fetch<AnyObject[]>(RACE_RESULTS_FORM_ID, entity) || [];

  const result = store.value.filter((item) => !selectedOptions.find((option) => option[name]?.key === item.key));

  if (distanceId) {
    return result.filter((item) => item.parentId === distanceId);
  }

  return result;
};

const filterWithDependency = (entity: string, name: string, store: AnyObject) => () => {
  if (!store.value) return [];

  const selectedOptions = form.fetch<AnyObject[]>(RACE_RESULTS_FORM_ID, entity);
  const distances = form.fetch<AnyObject[]>(RACE_RESULTS_FORM_ID, 'distances') || [];

  return store.value.filter(
    (item) =>
      !selectedOptions.find((option) => {
        return option[name]?.key === item.key || !distances.find((distance) => distance[name]?.key === item.parentId);
      }),
  );
};

export const getRaceDistanceOptions = computed(filterSimple('distances', 'internal_id', raceDistancesStore));
export const getSyncDistanceOptions = computed(filterSimple('distances', 'external_id', syncDistancesStore));

export const getRaceCustomFieldsOptions = (distanceId: number, entity: string) =>
  filterSimple(entity, 'internal_id', raceCustomFieldsStore, distanceId);
export const getSyncCustomFieldsOptions = computed(() => syncCustomFieldsStore.value);

import { Commission } from '../types/commissions.type';

export namespace CommissionTransformMapper {
  export function fromBackend(data: Commission.Model[]): Commission.TableRow[] {
    const out: Commission.TableRow[] = [];
    for (let i = 0; i < data.length; i++) {
      const el = data[i];
      const entry: Commission.TableRow = {
        id: el.id,
        raceId: el.id,
        currency: el.currency,
        commission: el.commission
          ? {
              ...el.commission,
              amount: el.commission.amount / 100,
              percent_value: el.commission.percent_value / 100,
              vat_percents: el.commission.vat_percents / 100,
            }
          : null,
        name: el.name,
        isRace: true,
        isDistance: false,
        subRows: [],
      };
      el.distances?.forEach((d) => {
        entry.subRows.push({
          id: d.id,
          raceId: el.id,
          currency: el.currency,
          name: d.name,
          isDistance: true,
          isRace: false,
          commission: d.commission
            ? {
                ...d.commission,
                amount: d.commission.amount / 100,
                percent_value: d.commission.percent_value / 100,
                vat_percents: d.commission.vat_percents / 100,
              }
            : null,
          subRows: [],
        });
      });
      out.push(entry);
    }
    return out;
  }
}

import { errorsStore, form } from 'stores';

import { COUPON_DISCOUNT_TYPE_AMOUNT, SUPPORT_TASK_MANAGER_FORM } from '../constants';

import {
  modalStore,
  targetDistancesStore,
  sourceDistanceStore,
  refundDistancesStore,
  sourceEmailStore,
  targetEmailStore,
  ordersStore,
  bibClassesStore,
  racesStore,
  distancesStore,
} from '../stores';

export const mount = async () => {
  await Promise.all([
    form.merge(SUPPORT_TASK_MANAGER_FORM, {
      deleteParticipants: false,
      deleteSourceAccount: false,
      couponDiscountType: COUPON_DISCOUNT_TYPE_AMOUNT,
      registrationFree: false,
      addons: false,
      bibStartingDistance: true,
    }),
  ]);
};

export const unmount = (): void => {
  modalStore.clear();
  errorsStore.clear(SUPPORT_TASK_MANAGER_FORM);

  sourceDistanceStore.clear();
  targetDistancesStore.clear();
  refundDistancesStore.clear();
  sourceEmailStore.clear();
  targetEmailStore.clear();
  ordersStore.clear();
  racesStore.clear();
  distancesStore.clear();
  bibClassesStore.clear();
};

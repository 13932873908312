import { validate, t } from 'utils';

import { form, errorsStore } from 'stores';

import { AMOUNT_TYPE_ORDER, BIB_NUMBERS, CREDIT_DISTANCE, CREDIT_SINGLE, SUPPORT_TASK_MANAGER_FORM } from '../constants';

import { mapToConfirmBody } from '../utils';

import { openConfirmModal, setConfirmContent, setConfirmTitle } from '../actions';

import { TaskForm } from '../types';
import { DictionaryValidationRules, dynamicValidation } from '../validations';

export const onSave = (): void => {
  errorsStore.clear(SUPPORT_TASK_MANAGER_FORM);

  const taskForm: TaskForm = form.fetchWithoutObservable(SUPPORT_TASK_MANAGER_FORM);
  const validationRule: Function = DictionaryValidationRules[taskForm.action];
  const isDynamicValidation: boolean = taskForm.action === BIB_NUMBERS && !taskForm.bibStartingDistance;
  const isValid: boolean = isDynamicValidation
    ? dynamicValidation(taskForm)
    : validate(SUPPORT_TASK_MANAGER_FORM, taskForm, validationRule());

  if (!isValid) return;

  setConfirmModalData(taskForm);
  openConfirmModal();
};

const setConfirmModalData = (form: TaskForm): void => {
  let base: string = `supportTaskManager.confirm.${form.action}`;

  if (form.action === CREDIT_DISTANCE || form.action === CREDIT_SINGLE) {
    if (form.amountType === AMOUNT_TYPE_ORDER) {
      base += 'FullAmount';
    }
  }

  if (form.action === BIB_NUMBERS) {
    if (!form.bibDistanceClassStarting) {
      base += 'NoClasses';
    }
  }

  const title: string = t.staticAsString(`${base}.title` as TranslationLockedKeys);
  const content: string = t.staticAsString(`${base}.body` as TranslationLockedKeys, mapToConfirmBody(form));

  setConfirmTitle(title);
  setConfirmContent(content);
};

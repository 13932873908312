import { observer } from 'mobx-react';
import * as React from 'react';

import { withProgressSpinner, withSearch, withSorting, ListModule } from 'hocs';

import { FilterLabelToolbar } from 'components/Filter/FilterLabelToolbar';
import { SubscribedPagination } from 'components/Pagination';

import { Title } from '../components';

import { LOAD_RACERS } from '../constants';

import { mount, unmount } from '../actions';

import { racersService } from '../services';

import { RacersContext } from '../context';
import { Table } from './Table';
import { Toolbar } from './Toolbar';

type OriginalProps = {};

type Props = OriginalProps & HOC.withIntl;

@withProgressSpinner([LOAD_RACERS])
@ListModule(racersService, mount, unmount)
@withSorting(racersService)
@withSearch(racersService, 'racers.fields')
@observer
class BaseImpl extends React.Component<Props> {
  static contextType = RacersContext;

  render() {
    return (
      <div className='details-racers-lists'>
        <div className='content main-container-list racers-list'>
          <div className='sticky-left'>
            <div className='toolbar-container'>
              <Title />
              <Toolbar />
            </div>
            <FilterLabelToolbar />
          </div>
          <div className='component-list'>
            <Table />
            <SubscribedPagination />
          </div>
        </div>
      </div>
    );
  }
}

export const Base = BaseImpl as unknown as React.ComponentType<OriginalProps>;

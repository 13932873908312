import { Info } from '@mui/icons-material';
import { Tooltip, Box, Typography, FormControlLabel, IconButton, Switch } from '@mui/material';

type Props = {
  handleToggle: () => void;
  isAbsorb: boolean;
  label?: string;
  showInfo: boolean;
  tooltip?: string;
};

export default function ToggleServiceSettings({ tooltip, handleToggle, showInfo, isAbsorb, label }: Props) {
  return (
    <FormControlLabel
      sx={{ marginLeft: 0 }}
      labelPlacement='start'
      control={
        <Box>
          <Switch onClick={handleToggle} sx={{ marginLeft: 1.5 }} checked={isAbsorb} />
          {showInfo && (
            <Tooltip title={<Typography variant='body2'>{tooltip}</Typography>}>
              <IconButton size='large'>
                <Info color='disabled' />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      }
      label={label}
    />
  );
}

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { TRANSACTIONS_URL } from 'src/constants';
import { progressStore } from 'src/stores';

import { SystemUsersPaymentsConstants } from '../constants/systemUsersPayments.constants';

import { SystemUsersPaymentsMapper } from '../mappers/systemUsersPayments.mapper';
import { SystemUsersFilters } from '../types/filters.type';

export namespace SystemUserPaymentsService {
  export async function load(filters?: SystemUsersFilters) {
    progressStore.log(SystemUsersPaymentsConstants.Table, 'progress');
    try {
      const response = await axios.get(generatePath(TRANSACTIONS_URL), {
        params: {
          ...filters,
          searchJoin: 'AND',
          per_page: 100,
        },
      });

      return {
        ...response,
        data: SystemUsersPaymentsMapper.toFront(response.data.data),
        meta: response.data.meta,
      };
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(SystemUsersPaymentsConstants.Table, 'completed');
    }
  }
}

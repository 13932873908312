import { makeStyles } from '@mui/styles';

export const termsActionsStyles = makeStyles((theme: any) => ({
  backButton: {
    backgroundColor: '#C3C6C8',
    width: '22px',
    height: '22px',
    marginRight: '21px',
    '& .MuiIconButton-label': {
      marginLeft: '5px',
    },
    '& svg': {
      color: '#272E34',
      maxWidth: 18,
      maxHeight: 18,
    },
  },
}));

import { toastStore } from 'stores';

import { ResultsContextType } from '../../context';
import { isEditAvailable } from './isEditAvailable';

type onEdit = (fieldId: string) => void;

function generateOnEdit(fieldId: string, column: string, context: ResultsContextType): onEdit {
  return () => {
    const [isEdit, reason] = isEditAvailable(column);

    if (!isEdit) {
      toastStore.show(reason);
      return;
    }

    context.results.stores.fieldRegisterStore.activateField(fieldId);
  };
}

export { generateOnEdit };

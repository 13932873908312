import { BE_FORMAT } from 'src/constants';

import { parsedDate, outputDate } from '../types/index';

function composeDate(date: parsedDate, type: BEDateFormat): outputDate {
  const format = BE_FORMAT[type];

  return date.format(format);
}

export { composeDate };

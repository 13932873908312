import * as React from 'react';
import styled from 'styled-components';

import { Svg } from 'src/components/Icon';

const Wrapper = styled.div`
  display: grid;
  gap: 4px;

  width: 100%;
  padding: 12px;

  border: 1px solid ${(props) => props.theme.palette.labelGrey};
  border-left: 4px solid ${(props) => props.theme.palette.main};
  border-radius: 4px;
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr 18px;
  gap: 4px;
  align-items: center;

  height: 24px;

  font-weight: 500;
  font-size: 14px;
`;

const StyledSvg = styled(Svg)`
  display: flex;
  cursor: pointer;
  justify-self: center;
  align-self: center;
`;

const ContentTip = styled.div`
  font-weight: 400;
  font-size: 14px;
`;

export const Tip: React.FC = () => {
  const [show, changeShow] = React.useState(true);

  if (!show) return null;

  return (
    <Wrapper>
      <Header>
        <Svg name='Info' size={16} />
        <div>Do you need help [update me]</div>
        <StyledSvg name='CrossGray' size={11} onClick={() => changeShow(false)} />
      </Header>
      <ContentTip>
        If you you have troubles with filling out the information, visit our Help Center for additional support. [update me]
      </ContentTip>
    </Wrapper>
  );
};

import React from 'react';

import { InputFilter } from 'components/Filter';

import { t } from 'utils';

import { FilterProps } from './index';

type Props = {
  value: nil | string | number;
  intl: IntlShape;
} & FilterProps;

const OrganizerEmail = (props: Props) => {
  const value: nil | string | number = props.values.name as any;

  return <InputFilter {...props} name={'organizer.email'} value={value} label={t.staticAsString('races.filters.organizer.email')} />;
};

export { OrganizerEmail };

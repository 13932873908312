import shortId from 'shortid';

import { t } from 'utils';

export const optionsSingleRegistrations = (): SelectOption[] => {
  return [
    {
      key: shortId(),
      label: t.staticAsString('supportTaskManager.form.keepParticipant'),
      value: false,
    },
    {
      key: shortId(),
      label: t.staticAsString('supportTaskManager.form.removeParticipant'),
      value: true,
    },
  ];
};

import * as React from 'react';
import styled from 'styled-components';

import { Loading } from 'src/components/Loading';
import { Button } from 'src/components/core';
import { t } from 'src/utils';

import { Distances } from '../../components';

import { RR_MAPPING_INIT, RR_MAPPING_LOAD } from '../../constants';

import { mountSync, backToSettings, saveSync } from '../../actions';

import { ButtonHolder } from '../../../Shared';

const StyledButtonHolder = styled(ButtonHolder)`
  padding: 28px 28px 64px;
`;

export const SaveSync = () => {
  React.useEffect(() => {
    mountSync();
  }, []);

  return (
    <>
      <Loading action={RR_MAPPING_INIT} />
      <Loading action={RR_MAPPING_LOAD} />
      <Distances />
      <StyledButtonHolder>
        <Button type='cancel' onClick={backToSettings}>
          {t('shared.back')}
        </Button>
        <Button type='apply' onClick={saveSync}>
          {t('sync.save_sync')}
        </Button>
      </StyledButtonHolder>
    </>
  );
};

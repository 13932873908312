import { helper } from 'racese-react-phone-input-2';

function parse(value: string): AnyObject {
  const parsedData = helper.takeCountryData(value);

  return {
    phone: value,
    countryData: {
      dialCode: parsedData.dialCode,
      name: parsedData.name,
      countryCode: parsedData.iso2,
    },
  };
}

const phone = {
  parse,
};

export { phone };

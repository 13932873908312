import { isNil, round } from 'lodash';
import { toJS } from 'mobx';
import m from 'moment';

import { Race } from 'models';

import { COUPON_CODE_TYPES, COUPONS_FORM_DATE_FORMAT, RADIO_AMOUNT_VALUE, RADIO_PERCENTAGE_VALUE, USAGE_TYPES } from '../constants';

import { couponFormRaceService } from '../services/races.service';

import { couponFormDistanceStore, couponFormModalStore, couponFormSelectedRaceStore } from '../stores';

import { couponFormToApiType, couponFormType } from '../types';
import { mapDistancesToCouponForm } from './distance.mapper';

export const mapCouponToForm = async (data: AnyObject): Promise<any> => {
  await couponFormRaceService.loadRace(data.race_id);
  const race = toJS(couponFormSelectedRaceStore.value)!;
  const raceModel = new Race(race);
  couponFormDistanceStore.setDistances(mapDistancesToCouponForm(race.distances!));
  couponFormDistanceStore.setHasMore(false);

  // Some coupons may have malformed date, in order for validation to work properly we populate date
  // only if both date_fom and date_to are valid Moment objects.
  let dateRange: {} | undefined = undefined;
  if (m(data.date_from).isValid() && m(data.date_to).isValid()) {
    dateRange = {
      startDate: m(data.date_from),
      endDate: m(data.date_to),
    };
  }

  let coupon_type: AnyObject[] = [];
  if (data.usage_type === USAGE_TYPES.addonsPlusAllDistances) coupon_type = [...COUPON_CODE_TYPES];
  if (data.usage_type === USAGE_TYPES.addons) coupon_type = [COUPON_CODE_TYPES[1]];
  if (data.usage_type === USAGE_TYPES.allDistances) coupon_type = [COUPON_CODE_TYPES[0]];

  const distances = () => {
    if (data.distances.length === 0) return mapDistancesToCouponForm(race.distances!);
    return mapDistancesToCouponForm(data.distances);
  };

  return {
    name: data.name,
    code: data.code,
    description: data.description,
    race_id: {
      id: race.id,
      key: race.id,
      label: raceModel.name(),
    },
    date_range: dateRange,
    distances: distances(),
    discount_percentage: !data.discount_percentage ? RADIO_AMOUNT_VALUE : RADIO_PERCENTAGE_VALUE,
    discount_amount: round(data.discount_amount),
    qty: data.qty,
    coupon_type,
  };
};

export const mapFormToCouponApi = (data: couponFormType): couponFormToApiType => {
  let usage_type: 0 | 1 | 2 = 0;

  if (data.coupon_type[0].key === COUPON_CODE_TYPES[1].key) usage_type = 1;
  if (data.coupon_type.length === 2) usage_type = 2;

  const date_from = data.date_range?.startDate && data.date_range.startDate.format(COUPONS_FORM_DATE_FORMAT);
  const date_to = data.date_range?.endDate && data.date_range.endDate.format(COUPONS_FORM_DATE_FORMAT);

  // Backend expect an empty array in case all distances were selected. This way all other distances
  // added to the race later will have coupon available for them.
  const distances = () => {
    if (couponFormDistanceStore.distances?.length === data.distances.length) return [];
    return data.distances.map(({ value }) => value);
  };

  return {
    code: data.code,
    date_from,
    date_to,
    description: data.description,
    discount_amount: Number(data.discount_amount),
    discount_percentage: data.discount_percentage === RADIO_PERCENTAGE_VALUE,
    distances: distances(),
    name: data.name,
    qty: Number(data.qty),
    race_id: data.race_id.id,
    usage_type,
  };
};

export const mapFormToPatchCouponApi = (data: couponFormType): AnyObject => {
  const mappedData = mapFormToCouponApi(data);
  const defaultData = mapFormToCouponApi(couponFormModalStore.value!.coupon as couponFormType);

  return Object.keys(mappedData).reduce((prev, current) => {
    if (!checkIfEdited(current, mappedData[current], defaultData)) {
      return {
        ...prev,
        [current]: isNil(mappedData[current]) ? null : mappedData[current],
      };
    }

    return prev;
  }, {});
};

const checkIfEdited = (name: string, value: any, defaultData: AnyObject = {}): boolean => {
  switch (name) {
    case 'distances':
      if (value?.length === 0) return false;

      return value.every((item) => (defaultData[name] || []).some((distance) => distance === item));

    default:
      return value === defaultData[name];
  }
};

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACES_URL } from 'src/constants';

import { action, request } from 'utils';

import { GET_BIB_RACES } from '../constants';

import { mapCouponRaces } from '../utils';

import { racesStore } from '../stores';

class RacesService {
  @request({ action: GET_BIB_RACES })
  private async getRacesRequest(params: nil | FiltersType): Promise<any> {
    return axios.get(generatePath(RACES_URL), { params });
  }

  @action({ action: GET_BIB_RACES })
  async getCouponRaces(): Promise<any> {
    const params = racesStore.params;
    const [status, response] = await this.getRacesRequest(params);

    if (status) {
      racesStore.setRaces([...racesStore.races, ...mapCouponRaces(response.data.data)]);
      racesStore.setHasMore(response.data.meta.pagination.total_pages > racesStore.params.page);
      racesStore.setParams({ page: racesStore.params.page + 1 });
    }
  }
}

export const racesService = new RacesService();

export namespace ReportConstants {
  export const ReportPage = 'ReportPage';

  export const COLUMN_WIDTH = {
    name: '350px',
    purchased: '130px',
    totalExVat: '130px',
    vatPercentage: '90px',
    vatSum: '130px',
    totalIncVat: '130px',
  };

  export const COMMISSION_UNIT = '%';
}

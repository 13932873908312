import * as React from 'react';

import { Actions } from '../../components/Toolbar';

const ToolbarContainer = () => {
  return (
    <div className='toolbar racer-toolbar'>
      <div className='buttons'>
        <Actions />
      </div>
    </div>
  );
};

export { ToolbarContainer as Toolbar };

import { Observer } from 'mobx-react';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { ExportToolbar } from 'components/ExportToolbar';
import { Button } from 'components/core';

import { t } from 'utils/t';

import { handleCreateNew, loadLinks, onExportData } from '../../actions';

import { smartLinksFiltersStore, smartLinksStore } from '../../stores';

import { filtersToBackend } from '../../mappers';
import { generateTransactionColumns } from './Columns';
import { LinksTable } from './Table';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px 18px 0;
`;

const StyledButton = styled(Button)`
  height: 48px;
  margin-left: 27px;
`;

const Heading = styled.h1`
  padding: 38px 0 30px 24px;
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
`;

const EXPORT_TYPES = ['csv', 'xlsx'];

export const SmartLinksList: React.FC = () => {
  const fetchLinks = useCallback(({ pageIndex = 0, pageSize, filters, sortBy }) => {
    let orderBy = 'created_at';
    let sortedBy = 'DESC';
    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      switch (id) {
        case 'date':
          orderBy = 'date_from';
          break;
        case 'race.name':
          orderBy = id.split('.').join('s|');
          break;
        case 'distance.name':
          orderBy = id.split('.').join('s|');
          break;
        case 'wave.name':
          orderBy = id.split('.').join('s|');
          break;
        case id.includes('.'):
          orderBy = id.split('.').join('|');
        default:
          orderBy = id;
      }
      sortedBy = desc ? 'DESC' : 'ASC';
    }

    const params = {
      page: pageIndex + 1,
      limit: pageSize,
      orderBy,
      sortedBy,
      ...filtersToBackend(filters),
    };

    smartLinksFiltersStore.set(params);
    loadLinks(params);
  }, []);

  return (
    <>
      <Header>
        <Heading>{t.staticAsString('smartLinks.list')}</Heading>
        <Controls>
          <div style={{ marginBottom: '18px' }}>
            <ExportToolbar types={EXPORT_TYPES} onExport={onExportData} totalEntries={10} />
          </div>
          <StyledButton type='add' onClick={handleCreateNew}>
            {t.staticAsString('smartLinks.createLink')}
          </StyledButton>
        </Controls>
      </Header>
      <div style={{ padding: 0, backgroundColor: 'white', overflow: 'auto' }}>
        <Observer
          render={() => {
            const tableData = smartLinksStore.value?.data.map((order) => {
              return order;
            });
            return (
              <LinksTable
                tableData={tableData ?? []}
                tableMeta={smartLinksStore.value?.meta ?? {}}
                initialSizeOfPage={30}
                pageSizeOptions={[30, 50, 100, 300]}
                tableColumns={generateTransactionColumns()}
                fetchLinks={fetchLinks}
              />
            );
          }}
        />
      </div>
    </>
  );
};

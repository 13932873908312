import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

type Props = {
  data: any[];
};

export default function Participants({ data }: Props) {
  return (
    <ResponsiveContainer height={565} width='100%'>
      <LineChart style={{ fontSize: 14, left: '-30px' }} data={data}>
        <CartesianGrid vertical={false} strokeDasharray='3' />
        <XAxis dataKey='label' />
        <YAxis />
        <Tooltip />
        <Line type='monotone' dataKey='value' strokeWidth={3} stroke='#5DA6FC' />
      </LineChart>
    </ResponsiveContainer>
  );
}

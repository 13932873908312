import { t } from 'utils';

import { COUPON_DISCOUNT_TYPE_PERCENTAGE } from '../constants';

export const generateCoupon = (): any => {
  const presence = {
    allowEmpty: false,
    message: t.staticAsString('validation.required'),
  };

  return {
    couponDiscountType: {
      presence,
    },

    couponRace: {
      presence,
    },

    couponDistances: {
      presence,
    },

    couponQuantity: {
      presence,
      equality: {
        attribute: 'couponDiscountType',
        message: t.staticAsString('supportTaskManager.form.validation.couponQuantity'),
        comparator: function (couponQuantity) {
          const MIN_QUANTITY = 0;
          return couponQuantity > MIN_QUANTITY;
        },
      },
    },

    couponLength: {
      presence,
      equality: {
        attribute: 'couponDiscountType',
        message: t.staticAsString('supportTaskManager.form.validation.couponLength'),
        comparator: function (couponLength) {
          const MIN_LENGTH = 5;
          const MAX_LENGTH = 256;
          return couponLength > MIN_LENGTH && couponLength < MAX_LENGTH;
        },
      },
    },

    couponDiscountAmount: {
      presence,
      equality: {
        attribute: 'couponDiscountType',
        message: function (value, attribute, validatorOptions, attributes) {
          if (attributes.couponDiscountType === COUPON_DISCOUNT_TYPE_PERCENTAGE) {
            return t.staticAsString('supportTaskManager.form.validation.recentBetween');
          }

          return t.staticAsString('supportTaskManager.form.validation.amountIsWrong');
        },
        comparator: function (couponDiscountAmount, couponDiscountType) {
          const PERCENT_MAX = 101;
          const PERCENT_MIN = 0;

          if (couponDiscountType === COUPON_DISCOUNT_TYPE_PERCENTAGE) {
            return couponDiscountAmount > PERCENT_MIN && couponDiscountAmount < PERCENT_MAX;
          }

          return couponDiscountAmount > PERCENT_MIN;
        },
      },
    },

    addons: {
      presence,
      equality: {
        attribute: 'registrationFree',
        message: t.staticAsString('supportTaskManager.form.validation.couponCodeType'),
        comparator: function (registrationFree, addons) {
          return addons || registrationFree;
        },
      },
    },

    registrationFree: {
      presence,
      equality: {
        attribute: 'addons',
        message: t.staticAsString('supportTaskManager.form.validation.couponCodeType'),
        comparator: function (registrationFree, addons) {
          return addons || registrationFree;
        },
      },
    },
  };
};

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACE_DISTANCE_URL, RESULTS_URL, DISTANCE_RESULTS_URL } from 'src/constants';

import { action, request } from 'utils';

import { UPDATE_DISTANCE, DELETE_RESULTS, UPDATE_DISTANCE_RESULTS } from '../constants';

import { resultsService } from '../services';

import { distanceStore } from '../stores';

class DistanceActions {
  @request({ action: UPDATE_DISTANCE })
  updateDistanceRequest(id: number, params: FiltersType): Promise<any> {
    const url = generatePath(RACE_DISTANCE_URL, { id });
    return axios.patch(url, params);
  }

  @action({ action: UPDATE_DISTANCE })
  async updateDistance(raceId: number, id: number, params: FiltersType): Promise<boolean> {
    const [status, response] = await this.updateDistanceRequest(id, params);

    if (status) {
      distanceStore.partialUpdateSelectedValue(response.data.data);
      return true;
    }

    return false;
  }

  @request({ action: UPDATE_DISTANCE_RESULTS })
  updateDistanceResultsRequest(id: number, params: FiltersType): Promise<any> {
    const url = generatePath(RESULTS_URL, { distanceId: id });
    return axios.patch(url, params);
  }

  @action({ action: UPDATE_DISTANCE_RESULTS })
  async updateDistanceResults(id: number, params: FiltersType): Promise<boolean> {
    const [status] = await this.updateDistanceResultsRequest(id, params);

    if (status) {
      distanceStore.partialUpdateSelectedValue(params);
      return true;
    }

    return false;
  }

  @request({ action: DELETE_RESULTS })
  deleteResultsRequest(id: number): Promise<any> {
    const url = generatePath(DISTANCE_RESULTS_URL, { id });
    return axios.delete(url);
  }

  @action({ action: DELETE_RESULTS })
  async deleteResults(distanceId: number) {
    console.log('Delete');
    const [status] = await this.deleteResultsRequest(distanceId);

    if (status) {
      resultsService.load();
    }

    return !!status;
  }
}

export { DistanceActions, DistanceActions as DistanceActionsService };

import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import * as React from 'react';
import { generatePath } from 'react-router-dom';
import shortId from 'shortid';

import { ROUTES } from 'src/constants';

import { NavToolbar } from 'components/NavToolbar';

import { t } from 'utils';

import { Race } from 'models';

import { raceStore } from '../stores';

type Props = {};

const StyledBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.greyFA,
    paddingLeft: 24,
  },
}))(Box);

@observer
export class Breadcrumbs extends React.Component<Props> {
  render() {
    if (!raceStore.value) return null;

    const id = raceStore.value.id;
    const model = new Race(raceStore.value);

    const items = [
      {
        key: shortId(),
        path: ROUTES.racesRoute,
        label: t.staticAsString('races.detail.nav.allRaces'),
      },
      {
        key: shortId(),
        path: generatePath(ROUTES.raceRoute, { id }),
        label: model.value.name,
      },
      {
        key: shortId(),
        label: t.staticAsString('integrations.list.title'),
      },
    ];

    return (
      <StyledBox>
        <NavToolbar items={items} />
      </StyledBox>
    );
  }
}

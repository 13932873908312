import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { OrganizerPayments, Details } from '../';

export class OrganizerPaymentsContainer extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path={ROUTES.paymentsRoute} exact component={OrganizerPayments} />
        <Route path={ROUTES.paymentRoute} exact component={Details} />
      </Switch>
    );
  }
}

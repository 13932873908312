import { Box, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';
import RefundDisplay from 'modules/Reconciliation/shared/components/Refund';
import moment from 'moment';
import React, { ReactElement } from 'react';

import { BE_DATE_FORMAT } from 'src/constants/time';

import { Show } from 'components/Condition';

import { t } from 'utils/t';

import { formatCurrency } from '../utils';
import { reportCurrency } from '../utils/currency.util';

import { ReportStore } from '../stores/report.store';

import CardHeader from './CardHeader';
import { cardContentStyles, genericReportStyles } from './general.styles';

interface Props {}

const useStyles = makeStyles({
  main: {
    marginBottom: '30px',
  },
  secondaryTitle: {
    marginBottom: '30px',
  },
  typography: {
    marginBottom: '15px',
  },
  indicator: {
    color: 'rgb(105, 105, 105)',
    fontSize: '16px',
  },
  content: {
    color: 'rgb(38, 46, 53)',
    fontSize: '16px',
  },

  date: {
    height: '17px',
    color: 'rgb(43, 53, 61)',
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '24px',
  },
  title: {
    height: '24px',
    color: 'rgb(38, 46, 53)',
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    marginBottom: '16px',
  },
});

export default function ReportHeader({}: Props): ReactElement {
  const r = ReportStore.report.value!;
  const c = useStyles();
  const genericStyles = genericReportStyles();
  const cardStyles = cardContentStyles();
  const title = (prefix: string) => t.staticAsString(`reconciliation.report.header.${prefix}` as any);

  const tittleId = r.invoice.invoice_statement.invoiceNo ? 'ID' + r.invoice.invoice_statement.invoiceNo.toString() : '';
  return (
    <>
      <section className={c.main}>
        <Box>
          <Typography style={{ fontWeight: 'bold', textTransform: 'uppercase' }} className={genericStyles.reportGenericTitle}>
            {title('reconciliationSales')}
          </Typography>
          <Typography className={c.date}>{r.created_at.toString()}</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6} item>
            <CardHeader title={title('statementId')} supportTitle={tittleId} />
            <Box style={{ minHeight: '364px' }} className={cardStyles.card}>
              <Box className={cardStyles.cardSection}>
                <span className={cardStyles.cardEntryLeft}>{title('clientName')}&nbsp;</span>
                <span className={cardStyles.cardEntryRight}>{r.organizer.full_name}</span>
              </Box>
              <Show if={Boolean(r.vat_report.vat_statement_summary.clientVatNo)}>
                <Box className={cardStyles.cardSection}>
                  <span className={cardStyles.cardEntryLeft}>{title('clientVatNumber')}&nbsp;</span>
                  <span className={cardStyles.cardEntryRight}>{r.vat_report.vat_statement_summary.clientVatNo}</span>
                </Box>
              </Show>
              <Box className={cardStyles.cardSection}>
                <span className={cardStyles.cardEntryLeft}>{title('event')}&nbsp;</span>
                <span className={cardStyles.cardEntryRight}>{r.race.name}</span>
              </Box>
              <Box className={cardStyles.cardSection}>
                <Typography className={cardStyles.cardSubtitle}>{title('paymentDetails')}</Typography>
              </Box>
              <Box className={cardStyles.cardSection}>
                <span className={cardStyles.cardEntryLeft}>{title('accountIban')}&nbsp;</span>
                <span className={cardStyles.cardEntryRight}>{r.payment_overview.statement.paymentAccountIban}</span>
              </Box>
              <Box className={cardStyles.cardSection} style={{ marginBottom: 10 }}>
                <span className={cardStyles.cardEntryLeft}>{title('swiftBic')}&nbsp;</span>
                <span className={cardStyles.cardEntryRight}>{r.payment_overview.statement.paymentBICSwift}</span>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} item>
            <CardHeader title={title('statementSummary')} />
            <Box className={cardStyles.card}>
              <Box style={{ paddingTop: 16 }} className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                <span className={cardStyles.cardEntryLeft}>{title('transactionBetween')}</span>
                <span className={cardStyles.cardEntryRight}>
                  {moment(r.vat_report.vat_statement_summary.from).format(BE_DATE_FORMAT)} to{' '}
                  {moment(r.vat_report.vat_statement_summary.to).format(BE_DATE_FORMAT)}
                </span>
              </Box>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />

              <Show if={r.payment_overview.statement.totalVolume === undefined}>
                <Box className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                  <Box className={cardStyles.statementSummarySubtitle}>{title('transactionTotal')}</Box>
                  <span className={cardStyles.cardEntryRight}>{r.payment_overview.statement.transactionsTotalCount}</span>
                </Box>
                <Box className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                  <span className={cardStyles.statementSummaryLeft}>{title('transaction')}&nbsp;</span>
                  <span className={cardStyles.cardEntryRight}>{r.payment_overview.statement.transactionsCount}</span>
                </Box>
                <Box className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                  <span className={cardStyles.statementSummaryLeft}>{title('refunds')}&nbsp;</span>
                  <span className={cardStyles.cardEntryRight}>{r.payment_overview.statement.refundsCount}</span>
                </Box>
              </Show>

              <Show if={r.payment_overview.statement.totalVolume !== undefined}>
                <Box className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                  <Box className={cardStyles.statementSummarySubtitle}>{title('registrationsTotal')}</Box>
                  <span className={cardStyles.cardEntryRight}>{r.payment_overview.statement.totalVolume}</span>
                </Box>
                <Box className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                  <span className={cardStyles.statementSummaryLeft}>{title('registrations')}&nbsp;</span>
                  <span className={cardStyles.cardEntryRight}>{r.payment_overview.statement.registrationsCount}</span>
                </Box>
                <Box className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                  <span className={cardStyles.statementSummaryLeft}>{title('transfers')}&nbsp;</span>
                  <span className={cardStyles.cardEntryRight}>{r.payment_overview.statement.transfersCount}</span>
                </Box>
                <Box className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                  <span className={cardStyles.statementSummaryLeft}>{title('refunds')}&nbsp;</span>
                  <span className={cardStyles.cardEntryRight}>{r.payment_overview.statement.refundsCount}</span>
                </Box>
              </Show>

              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              <Box className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                <Box className={cardStyles.statementSummarySubtitle}>{title('amountTotal')}</Box>
                <span className={cardStyles.cardEntryRight}>{formatCurrency(r.payment_overview.statement.amountTotal)}</span>
              </Box>
              <Box className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                <span className={cardStyles.statementSummaryLeft}>{title('transactionsAmount')}&nbsp;</span>
                <span className={cardStyles.cardEntryRight}>{formatCurrency(r.payment_overview.statement.transactionsAmount)}</span>
              </Box>
              <Box className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                <span className={cardStyles.statementSummaryLeft}>{title('refundsAmount')}&nbsp;</span>
                <span className={cardStyles.cardEntryRight}>-{formatCurrency(r.payment_overview.statement.refundsAmount)}</span>
              </Box>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              <Box className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                <span className={cardStyles.statementSummarySubtitle}>{title('fees&Deductions')}</span>
                <span className={cardStyles.cardEntryRight}>-{formatCurrency(r.payment_overview.statement.platformFeesAndDeductions)}</span>
              </Box>
              <Divider style={{ marginTop: 10, marginBottom: 15 }} />
              <Box style={{ marginBottom: 10 }} className={cn(cardStyles.cardSection, cardStyles.statementSummaryBlock)}>
                <span className={cardStyles.statementSummarySubtitle}>{title('totalPayout')}</span>
                <span className={cardStyles.cardEntryRight}>{formatCurrency(r.payment_overview.statement.totalPayoutSum)}</span>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </section>
    </>
  );
}

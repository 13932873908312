class Resize {
  mobileSize = 992;
  lowResolution = 1024;

  _getWindowWidth() {
    return window.innerWidth;
  }

  isMobile(): boolean {
    return this._getWindowWidth() <= this.mobileSize;
  }

  isLowResolution(): boolean {
    return this._getWindowWidth() < this.lowResolution;
  }
}

const resize = new Resize();
export { resize };

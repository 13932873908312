// flow
import * as React from 'react';

import { AdminPaymentsContainer, OrganizerPaymentsContainer } from './containers';
import { Context, ContextData } from './context';

export const AdminPayments = () => (
  <Context.Provider value={ContextData}>
    <AdminPaymentsContainer />
  </Context.Provider>
);

export const OrganizerPayments = () => (
  <Context.Provider value={ContextData}>
    <OrganizerPaymentsContainer />
  </Context.Provider>
);

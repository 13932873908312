import axios from 'axios';
import { generatePath } from 'react-router-dom';

import {
  FIELD_SUPER_TRANSLATIONS_URL,
  FIELD_CUSTOM_FIELDS_TRANSLATION_URL,
  FIELD_CUSTOM_FIELDS_VALUE_TRANSLATION_URL,
  GOOGLE_TRANSLATE_URL,
  SUPER_TRANSLATIONS_CONFIRM,
} from 'src/constants';

import { action } from 'utils/core/action';
import { request } from 'utils/core/request';

const loadAction = 'FIELD_TRANSLATE';

type SuperTranslationsPayload = {
  locale: availableLocales;
  model: string;
  model_id: number;
  key: string;
  value: string;
};

type GoogleTranslateionPayload = {
  source: availableLocales;
  target: availableLocales;
  term: string | string[];
  format: 'html' | 'text';
};
class FieldTranslations {
  @request({ action: loadAction })
  googleTranslationsRequest(data: GoogleTranslateionPayload): any {
    return axios.post(GOOGLE_TRANSLATE_URL, data);
  }

  @action({ action: loadAction })
  async googleTranslations(data: GoogleTranslateionPayload): Promise<{ text: string }[]> {
    const [status, response] = await this.googleTranslationsRequest(data);

    if (status) {
      return response.data.data;
    }

    return [];
  }

  @request({ action: loadAction })
  superTranslationsRequest(data: SuperTranslationsPayload[]): any {
    return axios.post(FIELD_SUPER_TRANSLATIONS_URL, {
      models: data,
    });
  }

  @action({ action: loadAction })
  async superTranslations(data: SuperTranslationsPayload[]): Promise<any> {
    const [status, response] = await this.superTranslationsRequest(data);

    if (status) {
      return response.data;
    }
  }

  @request({ action: '' })
  async superTranslationsConfirm(data: any): Promise<any> {
    return axios.post(SUPER_TRANSLATIONS_CONFIRM, {
      models: data,
    });
  }

  @request({ action: loadAction })
  deleteTranslationsRequest({ id, tid }): any {
    const url = generatePath(FIELD_CUSTOM_FIELDS_TRANSLATION_URL, {
      id,
      tid,
    });
    return axios.delete(url);
  }

  @action({ action: loadAction })
  async deleteTranslations(data): Promise<any> {
    const [status, response] = await this.deleteTranslationsRequest(data);

    if (status) {
      return response.data;
    }
  }
  @request({ action: loadAction })
  deleteValueTranslationsRequest({ id, tid }): any {
    const url = generatePath(FIELD_CUSTOM_FIELDS_VALUE_TRANSLATION_URL, {
      id,
      tid,
    });
    return axios.delete(url);
  }

  @action({ action: loadAction })
  async deleteValueTranslations(data): Promise<any> {
    const [status, response] = await this.deleteValueTranslationsRequest(data);

    if (status) {
      return response.data;
    }
  }
}

const fieldTranslations = new FieldTranslations();

export { FieldTranslations, fieldTranslations };

import { omit } from 'lodash';
import { action, observable, makeObservable } from 'mobx';

class LoadProgress {
  @observable
  multipleLoad = false;

  @observable
  loadingJobs = {};

  @observable
  hasConnection = true;

  constructor() {
    makeObservable(this);
    window.onoffline = () => {
      this.hasConnection = false;
    };
  }

  @action
  addLoadingJob(id: number | string, estimateSec: number, params: Object) {
    // When user has got back online flip hasConnectionf flag to true
    // otherwise download will start but the message will read 'No connection'.
    if (navigator.onLine) {
      this.hasConnection = true;
    }

    const job: LoadingJob = {
      upload: false,
      estimateSec,
      fileName: '',
      remainingSec: estimateSec,
      progress: 0,
      isFinished: false,
      isError: false,
      noConnection: false,
      isCancelled: false,
      onRetry: () => {},
    };
    this.loadingJobs[id] = { ...job, ...params };

    if (!this.multipleLoad) {
      this.multipleLoad = Object.keys(this.loadingJobs).length > 1;
    }
  }

  @action
  updateLoadingProgress(id: number | string, progress: number) {
    if (!this.loadingJobs[id] || this.loadingJobs[id].isError) return;

    let { estimateSec, remainingSec } = this.loadingJobs[id];

    let updProgress = this.loadingJobs[id].progress + progress;
    // If loading takes longer than expected, keep progress bar close to the end
    // Keep it like that untill finish status received
    if (updProgress >= 100) {
      updProgress = 99;
    }

    this.loadingJobs[id].progress = Math.round(updProgress * 100) / 100;

    remainingSec = Math.round(estimateSec * (1 - updProgress / 100));

    this.loadingJobs[id].remainingSec = remainingSec;
  }

  @action
  finishLoadingProgress(id: number | string) {
    if (!this.loadingJobs[id] || this.loadingJobs[id].isError) return;

    this.loadingJobs[id].progress = 100;
    this.loadingJobs[id].remainingSec = 0;
    this.loadingJobs[id].isFinished = true;

    this.delayedClosing(id);
  }

  @action
  cancelLoading(id: number | string) {
    this.loadingJobs[id].isCancelled = true;
    this.delayedClosing(id);
  }

  @action
  errorLoading(id: number | string) {
    this.loadingJobs[id].isError = true;
    this.delayedClosing(id);
  }

  @action
  connectionInterrupt(id: number | string) {
    this.loadingJobs[id].noConnection = true;
    this.delayedClosing(id, 20000);
  }

  @action
  onRetry = async () => {
    if (navigator.onLine) {
      Object.keys(this.loadingJobs).forEach(async (jobId: string | number) => {
        this.loadingJobs[jobId].noConnection = false;
        this.loadingJobs[jobId].isError = false;
        this.delayedClosing(jobId, 0);
        await this.loadingJobs[jobId].onRetry();
      });
      this.hasConnection = true;
    }
  };

  @action
  delayedClosing(id: number | string, delay: number = 2000) {
    setTimeout(() => {
      if (this.loadingJobs[id]?.autoHide !== undefined && !this.loadingJobs[id]?.autoHide) {
        return;
      }
      this.loadingJobs = omit(this.loadingJobs, `${id}`);

      if (Object.keys(this.loadingJobs).length === 0) {
        this.multipleLoad = false;
      }
    }, delay);
  }
}

export { LoadProgress };
export default new LoadProgress();

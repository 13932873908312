import { Typography } from '@mui/material';
import React from 'react';

import { t } from 'utils/t';

import { formStyles } from '../../styles';

function StepTitle({ step }: { step: string }) {
  const classes = formStyles();
  return (
    <h3 className={classes.stepTitle} style={{ marginBottom: 32 }}>
      {t(`races.steps.${step}` as TranslationLockedKeys)}
    </h3>
  );
}
function StepHelperText({ step }: { step: string }) {
  const classes = formStyles();
  return (
    <Typography className={classes.helperText} style={{ marginBottom: 32 }}>
      {t(`races.steps.helperText.${step}` as TranslationLockedKeys)}
    </Typography>
  );
}

export { StepTitle, StepHelperText };

import * as React from 'react';

export const NavigateRight = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.001 22.497C17.8 22.497 22.501 17.796 22.501 11.997C22.501 6.19802 17.8 1.49701 12.001 1.49701C6.20199 1.49701 1.50098 6.19802 1.50098 11.997C1.50098 17.796 6.20199 22.497 12.001 22.497Z'
      stroke='#50555B'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      className='circle'
    />
    <path d='M12.751 8.24701L16.501 11.997L12.751 15.747' stroke='#50555B' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M16.501 11.997H7.50098' stroke='#50555B' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
);

import { Box, Button, Divider, FormLabel, Grid, Typography } from '@mui/material';
import Axios from 'axios';
import { Observer } from 'mobx-react';
import { SystemUserCommissionsStore } from 'modules/SystemUsers/stores/systemUserCommissions.store';
import { ChangeEvent, useEffect } from 'react';

import { CONFIRM_POPUP_TYPES, PROFILE_URL } from 'src/constants';

import { Show } from 'components/Condition';
import { Form, InputWrapper, TextField, WrappedRadio, AutocompleteSelect, Select } from 'components/Form';

import { t, history } from 'utils';

import { countriesStore, form, sessionStore } from 'stores';
import confirmationModal from 'stores/confirmationModal';
import currencies from 'stores/currencies';
import toast from 'stores/toast';

import { DisabledHelperText } from '../../../components';

import { SystemUserFormPageConstants } from '../../../constants/systemUserFormPage.constants';

import { ScrollableTab } from '../ScrollableTab';
import { GroupTitle, SubTitle, useSystemUsersFormStyles, DisabledField } from '../styles';

const view = { fullWidth: true, variant: 'outlined' };
const label = (value: string) => t.staticAsString(`systemUsers.form.paymentDetails.${value}` as any);
type Props = {
  currentTab: number;
  currentCurrency: string;
  isCurrencySelected: boolean;
  selectedCurrencies: availableCurrencies[];
  handleChangeTab: (event: ChangeEvent<{}>, value: any) => void;
  handleSubmit: () => void;
  errors: Array<string>;
};

export default function PaymentDetails({
  handleChangeTab,
  handleSubmit,
  currentTab,
  currentCurrency,
  isCurrencySelected,
  selectedCurrencies,
  errors,
}: Props) {
  const classes = useSystemUsersFormStyles();

  useEffect(() => {
    const currentCurrenyID = currencies.values.find((currency) => currency.iso_code === currentCurrency)?.id;
    const defaultCommission = SystemUserCommissionsStore.defaultCommissions.value?.find(
      (commission) => commission.currency_id === currentCurrenyID,
    );
    const currentCurrencyCommissionValue = form.fetch<number>(currentCurrency, 'amount');
    const currentCurrencyCommissionPercentage = form.fetch<number>(currentCurrency, 'percent_value');
    const currentCurrencyRefundFee = form.fetch<number>(currentCurrency, 'refund_fee');
    const currentCurrencyVatPercentage = form.fetch<number>(currentCurrency, 'vat_percents');

    if (defaultCommission && !currentCurrencyCommissionValue) {
      form.merge(currentCurrency, { amount: defaultCommission?.amount / 100 });
    }
    if (defaultCommission && !currentCurrencyCommissionPercentage) {
      form.merge(currentCurrency, { percent_value: defaultCommission?.percent_value / 100 });
    }
    if (defaultCommission && !currentCurrencyRefundFee) {
      form.merge(currentCurrency, { refund_fee: defaultCommission?.refund_fee / 100 });
    }
    if (defaultCommission && !currentCurrencyVatPercentage) {
      form.merge(currentCurrency, { vat_percents: `${defaultCommission?.vat_percents / 100}` });
    }
  }, [currentCurrency]);

  const handleRequestDeleteAccount = () => {
    confirmationModal.openModal({
      title: 'Are you sure you want to delete your account?',
      body: 'By confirming, your account will be set for deletion. You will have 30 days to cancel by contacting support.',
      onConfirm: async () => {
        try {
          await Axios.delete(PROFILE_URL);
          toast.show('Your account has been set for deletion.');
        } catch (error) {}
      },
      type: CONFIRM_POPUP_TYPES.delete,
    });
  };

  return (
    <Observer
      render={() => {
        const isVatExempt = () => form.fetch<boolean | undefined>(currentCurrency, 'vat_exemption');
        const selectedCountryId = form.fetch<SelectOption>(currentCurrency, 'country_id')?.value;
        const isSweden = () => selectedCountryId === SystemUserFormPageConstants.swedenId;
        return (
          <>
            <Form id={currentCurrency} cleanErrorOnChange disableAutofill>
              <div className={classes.wrapper}>
                <div className={classes.form}>
                  <GroupTitle>{t.staticAsString('systemUsers.form.paymentDetails')}</GroupTitle>
                  <Show if={!isCurrencySelected}>
                    <Typography variant='h5'>{label('currencySelect')}</Typography>
                  </Show>
                  <Show if={isCurrencySelected}>
                    <ScrollableTab onChange={handleChangeTab} value={currentTab} currencies={selectedCurrencies} errors={errors} />
                    <div className={classes.inputWrapper}>
                      <InputWrapper
                        name='country_id'
                        settings={{
                          view: {
                            ...view,
                            required: true,
                            reservePlaceForHelperText: true,
                            variant: 'outlined',
                          },
                          additional: {
                            variant: 'outlined',
                            options: countriesStore.valuesForCountryDropdown(),
                          },
                          label: label('country'),
                        }}
                        Component={AutocompleteSelect}
                      />
                    </div>

                    <div className={classes.inputWrapper}>
                      <Grid spacing={2} container>
                        <Grid item xs={12} md={4}>
                          <DisabledField>
                            <InputWrapper
                              settings={{
                                required: true,
                                label: label('currencyCommissionPrice'),
                                view: {
                                  ...view,
                                  type: 'number',
                                  variant: !sessionStore.isAdmin ? 'filled' : view.variant,
                                },
                                additional: {
                                  disabled: !sessionStore.isAdmin,
                                },
                              }}
                              Component={TextField}
                              name='amount'
                            />
                          </DisabledField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <DisabledField>
                            <InputWrapper
                              settings={{
                                required: true,
                                label: label('currencyCommissionPercent'),
                                additional: {
                                  disabled: !sessionStore.isAdmin,
                                },
                                view: {
                                  ...view,
                                  type: 'number',
                                  variant: !sessionStore.isAdmin ? 'filled' : view.variant,
                                },
                              }}
                              Component={TextField}
                              name='percent_value'
                            />
                          </DisabledField>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <DisabledField>
                            <InputWrapper
                              settings={{
                                required: true,
                                label: 'Refund fee',
                                additional: {},
                                view: {
                                  type: 'number',
                                  disabled: !sessionStore.isAdmin,
                                  min: 0,
                                  max: 25,
                                  ...view,
                                  variant: !sessionStore.isAdmin ? 'filled' : view.variant,
                                },
                              }}
                              Component={TextField}
                              name='refund_fee'
                            />
                          </DisabledField>
                        </Grid>
                        <Grid item xs={12}>
                          <DisabledField>
                            <InputWrapper
                              settings={{
                                label: t.static(`reconciliation.commissions.vatPercent`),
                                options: [
                                  {
                                    key: '0',
                                    value: '0',
                                    label: '0%',
                                  },
                                  {
                                    key: '25',
                                    value: '25',
                                    label: '25%',
                                  },
                                ],
                                view: {
                                  ...view,
                                  variant: !sessionStore.isAdmin ? 'filled' : view.variant,
                                },
                                additional: {
                                  disabled: !sessionStore.isAdmin,
                                },
                              }}
                              Component={Select}
                              name='vat_percents'
                            />
                          </DisabledField>
                        </Grid>
                        <Show if={!sessionStore.isAdmin}>
                          <DisabledHelperText text={label('disableRoleHelperText')} />
                        </Show>
                      </Grid>
                      <Show if={!isSweden()}>
                        <div className={classes.inputWrapper}>
                          <FormLabel>{label('vat_exemption')}</FormLabel>
                          <InputWrapper
                            name='vat_exemption'
                            settings={{
                              required: true,
                              options: [
                                { key: 'yes', label: 'Yes', value: true, default: false },
                                { key: 'no', label: 'No', value: false, default: false },
                              ],
                            }}
                            Component={WrappedRadio}
                          />
                        </div>
                      </Show>
                      <Show if={isVatExempt() !== undefined && !isVatExempt() && !isSweden()}>
                        <div className={classes.inputWrapper}>
                          <InputWrapper
                            settings={{
                              label: label('vatNumber'),
                              view,
                            }}
                            Component={TextField}
                            name='vat_number'
                          />
                        </div>
                      </Show>
                      <Show if={!isSweden()}>
                        <div className={classes.inputWrapper}>
                          <InputWrapper
                            settings={{
                              label: label('iban&accountNumber'),
                              view,
                            }}
                            Component={TextField}
                            name='iban_number'
                          />
                        </div>
                        <div className={classes.inputWrapper}>
                          <InputWrapper
                            settings={{
                              label: label('swift&bicNumber'),
                              view,
                            }}
                            Component={TextField}
                            name='swift_big'
                          />
                        </div>
                      </Show>
                      <Show if={!isSweden()}>
                        <SubTitle>Additional information</SubTitle>
                        <div className={classes.inputWrapper}>
                          <InputWrapper
                            settings={{
                              label: label('clearing'),
                              view,
                            }}
                            Component={TextField}
                            name='clearing_routing_bsb_code'
                          />
                        </div>
                        <div className={classes.inputWrapper}>
                          <InputWrapper
                            settings={{
                              label: label('bankName'),
                              view,
                            }}
                            Component={TextField}
                            name='bank_name'
                          />
                        </div>
                        <div className={classes.inputWrapper}>
                          <InputWrapper
                            settings={{
                              label: label('bankAddress'),
                              view,
                            }}
                            Component={TextField}
                            name='bank_address'
                          />
                        </div>
                      </Show>
                      <Show if={isSweden()}>
                        <SubTitle>Bank transfer:</SubTitle>
                        <div className={classes.inputWrapper}>
                          <InputWrapper
                            settings={{
                              label: label('clearingNumber'),
                              view,
                            }}
                            Component={TextField}
                            name='clearing_number'
                          />
                        </div>
                        <div className={classes.inputWrapper}>
                          <InputWrapper
                            settings={{
                              label: `Account number`,
                              view,
                            }}
                            Component={TextField}
                            name='account_number'
                          />
                        </div>
                        <Show if={!isSweden()}>
                          <div className={classes.inputWrapper}>
                            <InputWrapper
                              settings={{
                                label: label('accountNumber'),
                                view,
                              }}
                              Component={TextField}
                              name='account_number'
                            />
                          </div>
                        </Show>
                        <Box mb={3} display='flex' justifyContent='center' flexDirection='row' alignItems='center'>
                          <Divider style={{ width: 260 }} />
                          <Box mx={2}>
                            <Typography>or</Typography>
                          </Box>
                          <Divider style={{ width: 260 }} />
                        </Box>
                        <div className={classes.inputWrapper}>
                          <InputWrapper
                            settings={{
                              label: label('accountPG/BG'),
                              view,
                            }}
                            Component={TextField}
                            name='pg_bg'
                          />
                        </div>
                      </Show>
                      {/* Will be uncommented in future see RP-11296 */}
                      {/* <Box mb={3} display='flex' justifyContent='center' flexDirection='row' alignItems='center'>
                      <Divider style={{ width: 582 }} />
                    </Box> */}
                      {/* <div className={classes.inputWrapper}>
                      <InputWrapper
                        settings={{
                          label: label('wishReceivePayments'),
                          view,
                        }}
                        Component={WrappedCheckbox}
                        name='receive_payments'
                      />
                    </div> */}
                    </div>
                  </Show>
                </div>
                <Show if={true}>
                  <div className={classes.wrapper}>
                    <div className={classes.form}>
                      <div style={{ marginTop: 20 }} className={classes.buttonGroup}>
                        <Button className='cancel' onClick={() => history.goBack()}>
                          {t.staticAsString('systemUsers.form.cansel')}
                        </Button>
                        <Button
                          style={{ marginLeft: 21 }}
                          disableElevation
                          variant='contained'
                          color='primary'
                          className='submit'
                          onClick={handleSubmit}
                        >
                          {t.staticAsString('systemUsers.form.submit')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Show>
              </div>
            </Form>

            <div className={classes.wrapper}>
              <div className={classes.form}>
                <GroupTitle>DELETE ACCOUNT</GroupTitle>
                <Typography>Pressing this button will delete your account and all associated race information within 30 days.</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    sx={{ marginTop: 2, width: '128px' }}
                    disableElevation
                    variant='contained'
                    color='error'
                    className='submit'
                    onClick={handleRequestDeleteAccount}
                  >
                    DELETE
                  </Button>
                </Box>
              </div>
            </div>
          </>
        );
      }}
    />
  );
}

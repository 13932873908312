import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACE_DISTANCES_URL } from 'src/constants';

import { request } from 'utils';

import { progressStore } from 'stores';

import { COUPON_FORM_STATES, COUPONS_FORM_ID } from '../constants';

import { mapDistancesToCouponForm } from '../utils';

import { couponFormDistanceStore } from '../stores';

class Distances {
  @request({ action: COUPONS_FORM_ID })
  async loadDistances(raceId: number): Promise<void> {
    progressStore.log(COUPON_FORM_STATES.DISTANCES_LOADING, 'progress');
    const params = couponFormDistanceStore.params;
    const response = await axios.get(generatePath(RACE_DISTANCES_URL, { raceId }), { params });

    couponFormDistanceStore.appendDistances(mapDistancesToCouponForm(response.data.data));
    couponFormDistanceStore.setHasMore(response.data.meta.pagination.total_pages > couponFormDistanceStore.params.page);
    couponFormDistanceStore.setParams({ page: couponFormDistanceStore.params.page + 1 });

    progressStore.log(COUPON_FORM_STATES.DISTANCES_LOADING, 'completed');
  }
}

export const couponFormDistancesService = new Distances();

import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import { observer } from 'mobx-react';
import { ATTACH_TO_PROFILE_FORM } from 'modules/StartList/constants';
import { loadTargetEmails } from 'modules/SupportTaskManager/Tasks/actions';
import { targetEmailStore } from 'modules/SupportTaskManager/Tasks/stores';
import * as React from 'react';

import { Form, InputWrapper, SearchSelect } from 'components/Form';

import { t } from 'utils';

import { form } from 'stores';

import { Info } from './Info';

type Props = {};

@observer
export class Fields extends React.Component<Props> {
  isNewFormValue = (newInputValue: any, name: string): boolean => {
    const formValue = form.fetch<string>(ATTACH_TO_PROFILE_FORM, name) as any;
    return formValue?.label !== newInputValue;
  };

  onInputChangeSourceTarget = (event, newInputValue, name) => {
    if (this.isNewFormValue(newInputValue, name)) {
      loadTargetEmails(newInputValue);
    }
  };

  render() {
    return (
      <DialogContent>
        <Form id={ATTACH_TO_PROFILE_FORM}>
          <Info text={t.staticAsString('startlist.attachToProfileForm.info')} />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box mb={2}>
                <InputWrapper
                  name='targetEmail'
                  settings={{
                    required: true,
                    label: t.staticAsString('supportTaskManager.form.targetDistanceEmail'),
                    additional: {
                      options: targetEmailStore.value,
                    },
                    view: {
                      variant: 'outlined',
                    },
                    onInputChange: this.onInputChangeSourceTarget,
                  }}
                  Component={SearchSelect}
                />
              </Box>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    );
  }
}

// flow
import * as React from 'react';

import { Racers as RacersContainer } from './containers';
import { RacersContext, racersContextData } from './context';

const Racers = () => (
  <RacersContext.Provider value={racersContextData}>
    <RacersContainer />
  </RacersContext.Provider>
);

export { Racers };

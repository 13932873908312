import shortid from 'shortid';

import { Checkpoint } from 'models';

// Complete missing splits
function processSplits(result: ResultType, checkpoints: Checkpoint[]): SplitType[] {
  return checkpoints.map((checkpoint) => {
    const checkpointId = checkpoint.value.id || 0;
    const splits = result.splits || [];
    const split = splits.find((el) => +el.checkpoint_id === +checkpointId);

    if (split) {
      return split;
    }

    return _initSplit(result.id, checkpointId);
  });
}

function _initSplit(resultId: number, checkpointId: number): SplitType {
  return {
    id: shortid(),
    checkpoint_id: checkpointId,
    result_id: resultId,
    finish_time: null,
  };
}

export { processSplits };

import { t } from 'utils';

export const copyRacers = (): any => {
  const presence = {
    allowEmpty: false,
    message: t.staticAsString('validation.required'),
  };

  return {
    sourceDistance: {
      presence,
    },
    targetDistance: {
      presence,
      equality: {
        attribute: 'sourceDistance',
        message: t.staticAsString('supportTaskManager.form.validation.cantBeSame'),
        comparator: function (targetDistance, sourceDistance) {
          return JSON.stringify(targetDistance.value) !== JSON.stringify(sourceDistance.value);
        },
      },
    },
    dateRange: {
      presence,
    },
  };
};

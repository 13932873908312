import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';
import { history } from 'src/utils';

import { loadService } from '../services';

import { taskStore } from '../stores';

export const mount = async () => {
  const match = matchPath(history.location.pathname, ROUTES.supportManagerTaskRoute);
  const id = Number(match?.params?.id);

  if (!id) history.push(ROUTES.supportManagerTasksRoute);

  await loadService.load(id);
};

export const unmount = () => {
  taskStore.clear();
};

import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'src/components/core/Buttons';
import { t } from 'src/utils';

import { handleSubmitEditLink } from '../actions';
import { handleCancelForm, handleSubmitNew } from '../actions/create';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ControlsCreate = () => {
  return (
    <Wrapper>
      <Button type='cancel' onClick={handleCancelForm}>
        {t('shared.cancel')}
      </Button>
      <Button type='apply' onClick={handleSubmitNew}>
        {t('races.new.submit')}
      </Button>
    </Wrapper>
  );
};

export const ControlsEdit = () => {
  return (
    <Wrapper>
      <Button type='cancel' onClick={handleCancelForm}>
        {t('shared.cancel')}
      </Button>
      <Button type='apply' onClick={handleSubmitEditLink}>
        {t('shared.edit')}
      </Button>
    </Wrapper>
  );
};

import { computed } from 'mobx';
import { raceDetailsStore } from 'modules/RaceDetails/stores';
import { RACE_TYPE } from 'modules/Races/constants';

import { history } from 'utils';

import { helperRacesStore } from 'stores';

export const isCalendarRaceType = computed(() => {
  const raceDetails = raceDetailsStore.raceDetails?.value;
  if (Boolean(raceDetails)) {
    return Boolean(raceDetails?.race_url);
  }

  const race = helperRacesStore.selected;
  if (Boolean(race)) {
    return Boolean(race?.race_url);
  }

  const {
    location: { search },
  } = history;
  const searchParams = new URLSearchParams(search);
  return searchParams.get('type') === RACE_TYPE.calendar;
});

import React, { useEffect } from 'react';
import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';
import { ORGANIZER_EMAIL_VERIFICATION } from 'src/constants';

import { withProgressSpinner } from 'hocs';

import { history } from 'utils';

import { verifyEmail } from '../actions';

const VerificationComponent = () => {
  useEffect(() => {
    const match = matchPath(history.location.pathname, ROUTES.organizerEmailVerification);

    if (!match) {
      return;
    }

    const { token } = match.params as any;

    verifyEmail(token);
  }, []);

  return <></>;
};

const OrganizerEmailVerification = withProgressSpinner([ORGANIZER_EMAIL_VERIFICATION])(VerificationComponent);

export { OrganizerEmailVerification };

import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactElement } from 'react';

import { t } from 'utils/t';

interface Props {}

const useStyles = makeStyles({
  footer: {
    backgroundColor: 'rgb(250, 250, 250)',
    padding: '24px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  columnTitle: {
    color: 'rgb(38, 46, 53)',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  columnText: {
    color: 'rgb(38, 46, 53)',
    fontSize: '14px',
    lineHeight: '24px',
    '& a': {
      color: 'rgb(38, 46, 53)',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
});

export default function ReportFooter({}: Props): ReactElement {
  const c = useStyles();
  return (
    <footer className={c.footer}>
      <section>
        <Typography className={c.columnTitle}>RaceID AB</Typography>
        <Typography className={c.columnText}>
          Virkesvägen 3H
          <br />
          120 30 Stockholm
          <br />
          Sweden
        </Typography>
      </section>
      <section>
        <Typography className={c.columnTitle}>{t.staticAsString('reconciliation.report.footer.companyInfo')}</Typography>
        <Typography className={c.columnText}>SE559058598901</Typography>
      </section>
      <section>
        <Typography className={c.columnTitle}>{t.staticAsString('reconciliation.report.footer.contact')}</Typography>
        <Typography className={c.columnText}>
          <a className={c.columnText} href='mailto:organizer@raceid.com'>
            organizer@raceid.com
          </a>
          <br />
          <a target='_blank' href='https://raceid.com'>
            www.raceid.com
          </a>
        </Typography>
      </section>
    </footer>
  );
}

import { TableCell, TableRow } from '@mui/material';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { squadValidation } from 'modules/StartList/validations';
import * as React from 'react';

import { START_LIST } from 'src/constants';

import { oneFieldValidation, t } from 'utils';

import { errorsStore } from 'stores';

import { Input } from '../../../Fields/Input';

type Props = {
  distance: DistanceType;
  errors?: Array<AnyObject>;
  onAddSquad: (name: string) => AnyObject | nil;
  onCloseCreating: AnyFunction;
};

type State = {
  isEdit: string | null;
  squad: AnyObject;
  changedFields: AnyObject;
};

const action = `UPDATE_${START_LIST}`;

class NewItem extends React.Component<Props, State> {
  state: State = {
    isEdit: 'newSquad',
    squad: { key: 0, value: 0, label: '' },
    changedFields: {},
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isEdit } = this.state;

    if (isEdit) {
      document.addEventListener('keyup', this.handlePressEnter as AnyFunction);
    }
  }

  handlePressEnter = (e: React.KeyboardEvent) => {
    const { isEdit } = this.state;

    if (e.keyCode === 13) {
      this.saveSquad(String(isEdit));
    }
  };

  onChange = (name: string, value: any): void => {
    this.setState(
      {
        ...this.state,
        squad: {
          ...this.state.squad,
          label: value,
        },
        changedFields: {
          ...this.state.changedFields,
          label: value,
        },
      },
      () => {
        const constrainsField = {
          [name]: squadValidation,
        };

        oneFieldValidation(action, { [name]: value }, constrainsField);
      },
    );
  };

  getSquadField = (): React.ReactNode => {
    const { distance } = this.props;
    const { squad } = this.state;

    return (
      <TableCell className={classNames('cell')} key={'new-squad'}>
        <Input
          onChange={this.onChange}
          item={{} as any}
          field={'newSquad'}
          value={squad.label}
          saveRacer={this.saveSquad}
          distance={distance}
          errorsStore={errorsStore}
          racersField={{}}
        />
      </TableCell>
    );
  };

  saveSquad = async (field: string) => {
    const { changedFields } = this.state;
    const { label } = changedFields;

    if (!label) {
      return this.props.onCloseCreating();
    }

    const constrainsField = {
      ['newSquad']: squadValidation,
    };

    const isValidate = oneFieldValidation(action, { newSquad: label }, constrainsField);

    if (!isValidate || isEmpty(changedFields)) {
      return this.props.onCloseCreating();
    }

    const status = await this.props.onAddSquad(label);

    if (status) {
      document.removeEventListener('keyup', this.handlePressEnter as AnyFunction);
      this.props.onCloseCreating();
    }

    errorsStore.clearFrontendError(action);
  };

  render() {
    return (
      <TableRow key={'new-squad'} id={'new-squad'}>
        {this.getSquadField()}

        <TableCell className={classNames('cell')}></TableCell>
      </TableRow>
    );
  }
}

export { NewItem };

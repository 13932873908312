import { isArray } from 'lodash';

import { filterFormatValue } from 'utils';

export const priceRange: FilterDescriptor = {
  persistance: {
    calcHandler: function (name, value) {
      if (!isArray(value)) {
        return;
      }
      const [start, end] = value;

      const fromPresented = !!start;
      const toPresented = !!end;

      if (fromPresented && !toPresented) {
        return '>=';
      }

      if (toPresented && !fromPresented) {
        return '<=';
      }

      return 'between';
    },

    calcValue: function (name, value) {
      if (!isArray(value)) {
        return;
      }
      const [start, end] = value;

      const fromPresented = !!start;
      const toPresented = !!end;

      if (fromPresented && !toPresented) {
        return +start * 100;
      }

      if (toPresented && !fromPresented) {
        return +end * 100;
      }

      return [+start * 100, +end * 100];
    },

    prettify: function (name, value) {
      return filterFormatValue(value);
    },
  },
};

import { action, makeObservable, override } from 'mobx';

import { Loadable, Sort, Search } from 'stores';

import { racersFiltersDescriptors } from '../Filters';

class Racers extends Loadable<RacerType, FiltersType> {
  declare filters: FiltersType;

  static defaultFilters = {
    limit: 200,
    with: 'wave;class;fields.field;team;squad',
    searchJoin: 'AND',
  };

  distanceId: number = 0;

  sort = new Sort(racersFiltersDescriptors);
  search = new Search(racersFiltersDescriptors);
  extensions = [this.sort, this.search];

  constructor() {
    super();

    makeObservable(this);
    this.filters = Racers.defaultFilters;
  }

  @action
  updateCurrentDistance(distanceId: number) {
    this.distanceId = distanceId;
  }

  @override
  clearFilters() {
    super.clearFilters();
    this.filters = Racers.defaultFilters;
  }

  // READY ORDER FILTER
  @action
  async enableReadyOrder() {
    this.filters['has'] = 'readyOrder';
  }

  @action
  async disableReadyOrder() {
    this.filters['has'] = null;
    delete this.filters['has'];
  }
}

export { Racers };

import AddIcon from '@mui/icons-material/Add';
import { Box, Button } from '@mui/material';
import { Observer } from 'mobx-react';
import { SystemUsersActions } from 'modules/SystemUsers/actions/loadSystemUsers.action';
import { SystemUsersFilterMappers } from 'modules/SystemUsers/mappers/filters.mapper';
import { SystemUsersStore } from 'modules/SystemUsers/stores/systemUsers.store';
import { ReactElement, useCallback, useEffect } from 'react';

import { t } from 'src/utils';

import { history } from 'utils/history';

import { sessionStore } from 'stores';

import { SystemUsersTable } from '../Table';
import { generateSystemUserColumns } from '../Table/Columns';

function SystemUsersTableWrapper(): ReactElement {
  const fetchSystemUsers = useCallback(({ pageIndex = 0, pageSize, filters, sortBy }) => {
    let orderBy = 'created_at';
    let sortedBy = 'DESC';
    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      orderBy = id.includes('.') ? id.split('.').join('|') : id;
      sortedBy = desc ? 'DESC' : 'ASC';
    }
    SystemUsersActions.load({ page: pageIndex + 1, limit: pageSize, orderBy, sortedBy, ...SystemUsersFilterMappers.toBackend(filters) });
  }, []);

  useEffect(() => {
    const init = async () => {
      await SystemUsersActions.init();
    };
    init();
    return () => {
      SystemUsersActions.unmount();
    };
  }, []);

  const handleNavigateToCreate = () => {
    history.push('/system-users/new');
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 0 }}>
        <h1 style={{ paddingLeft: 24, paddingTop: 38, paddingBottom: 30 }} className='title'>
          {t.static(sessionStore.isAdmin ? 'systemUsers.systemUsers' : 'sidebar.raceAssistants')}
        </h1>
        <Button onClick={handleNavigateToCreate} variant='contained' className='add-button' sx={{ mr: 3 }}>
          <AddIcon className='icon' />
          {t.static(sessionStore.isAdmin ? 'systemUsers.addNew' : 'systemUsers.addRaceAssistants')}
        </Button>
      </Box>
      <div style={{ padding: 0, backgroundColor: 'white' }} className='content main-container-list races-list'>
        <Observer
          render={() => {
            return (
              <SystemUsersTable
                tableData={SystemUsersStore.systemUsers.value?.data ?? []}
                tableMeta={SystemUsersStore.systemUsers.value?.meta ?? {}}
                initialSizeOfPage={30}
                pageSizeOptions={[30, 50, 100, 300]}
                tableColumns={generateSystemUserColumns()}
                fetchSystemUsers={fetchSystemUsers}
              />
            );
          }}
        />
      </div>
    </>
  );
}

export { SystemUsersTableWrapper };

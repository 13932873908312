import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import { Tooltip } from '@mui/material';
import { truncate } from 'lodash';
import React, { FC, useCallback } from 'react';

type Props = {
  external_id: string;
};

export const ExternalCell: FC<Props> = (props) => {
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
  let timer: number = 0;
  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(props.external_id);
    if (!timer) {
      setTooltipIsOpen(true);
      timer = setTimeout(() => {
        setTooltipIsOpen(false);
        timer = 0;
      }, 1000);
    }
  }, []);
  return (
    <Tooltip
      placement='top'
      open={tooltipIsOpen}
      title={
        <span style={{ display: 'flex', alignItems: 'center', fontSize: 12 }}>
          <FileCopyOutlined fontSize='small' style={{ marginRight: 5 }} /> {'Copied'}
        </span>
      }
    >
      <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleClick}>
        <FileCopyOutlined fontSize='small' style={{ marginRight: 5 }} /> {truncate(props.external_id, { length: 30 })}
      </span>
    </Tooltip>
  );
};

import { TCommissionResponse, TableRow } from 'modules/SystemUsers/types';

import { SystemUserCommissionsService } from '../../services/systemUserCommissions.service';

import { SystemUserCommissionsStore } from '../../stores/systemUserCommissions.store';

export async function loadSystemUserCommissionsAction(filters): Promise<void> {
  const data = await SystemUserCommissionsService.load(filters);
  SystemUserCommissionsStore.commissions.set(data);
}

export async function createCommissionAction(value: AnyObject) {
  const response = await SystemUserCommissionsService.create<TCommissionResponse>(value);

  SystemUserCommissionsStore.commissions.value!.data = SystemUserCommissionsStore.commissions.value?.data.map((race) => {
    if (race.id === value.commissionable_id) {
      return {
        ...race,
        commission: {
          ...race.commission,
          ...response.data,
          amount: response.data.amount / 100,
          percent_value: response.data.percent_value / 100,
          vat_percents: response.data.vat_percents / 100,
          is_absorb: response.data.is_absorb,
        },
      };
    }

    race.subRows = race.subRows.map((distance) => {
      if (distance.id === value.commissionable_id) {
        return {
          ...distance,
          commission: {
            ...distance.commission,
            ...response.data,
            amount: response.data.amount / 100,
            percent_value: response.data.percent_value / 100,
            vat_percents: response.data.vat_percents / 100,
            is_absorb: response.data.is_absorb,
          },
        };
      }
      return distance;
    });

    return race;
  }) as Array<TableRow>;
  SystemUserCommissionsStore.editCommission.value = null;
}

export async function updateCommissionAction(id: number, value: AnyObject) {
  const response = await SystemUserCommissionsService.patch<TCommissionResponse>(id, value);

  SystemUserCommissionsStore.commissions.value!.data = SystemUserCommissionsStore.commissions.value?.data.map((race) => {
    if (race.commission?.id === id) {
      return {
        ...race,
        commission: {
          ...response.data,
          amount: response.data.amount / 100,
          percent_value: response.data.percent_value / 100,
          vat_percents: response.data.vat_percents / 100,
          is_absorb: response.data.is_absorb,
        },
      };
    }

    race.subRows = race.subRows.map((distance) => {
      if (distance.commission?.id === id) {
        return {
          ...distance,
          commission: {
            ...distance.commission,
            ...response.data,
            amount: response.data.amount / 100,
            percent_value: response.data.percent_value / 100,
            vat_percents: response.data.vat_percents / 100,
            is_absorb: response.data.is_absorb,
          },
        };
      }

      return distance;
    });
    return race;
  }) as Array<TableRow>;
  SystemUserCommissionsStore.editCommission.value = null;
}

export async function resetCommissionAction(id: number) {
  const response = await SystemUserCommissionsService.remove(id);

  if (response.status !== 204) return;

  SystemUserCommissionsStore.commissions.value!.data = SystemUserCommissionsStore.commissions.value?.data.map((race) => {
    if (race.commission?.id === id) {
      return {
        ...race,
        commission: null,
      };
    }

    race.subRows = race.subRows.map((distance) => {
      if (distance.commission?.id === id) {
        return {
          ...distance,
          commission: null,
        };
      }

      return distance;
    });
    return race;
  }) as Array<TableRow>;
  SystemUserCommissionsStore.editCommission.value = null;
}

import { EXPORT_DATA, EXPORT_DATA_SIZES } from 'src/constants';

import { t, handleExport } from 'utils';
import { appEnvControl } from 'utils/appEnvironment';

import { exportJobService } from 'services';

import { toastStore } from 'stores';

import { startlistMembersStore, startlistRacersStore, teamsStore } from '../stores';

import { exportTempalateType } from '../types';

async function onExportTemplate(type: exportTempalateType) {
  const { paginationMeta, distanceId } = type === 'racers' ? startlistRacersStore : type === 'members' ? startlistMembersStore : teamsStore;
  const { total = EXPORT_DATA.defaultMetaData } = paginationMeta;

  if (total > EXPORT_DATA.metaDataLimit) {
    toastStore.show(t.staticAsString('shared.loadPopup.tooMuchData'));
    return;
  }

  const [status, response] = await exportJobService.exportStartListTemplate(Number(distanceId));

  if (!status) {
    return;
  }

  const fileName = `Startlist_${distanceId}.xlsx`;

  const { id } = response.data.data;
  const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
  let estimatedLoadingTime = total / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second
  if (total > maxTotal) {
    estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
  }

  handleExport({
    id,
    estimatedLoadingTime,
    fileName,
    onRetry: () => onExportTemplate(type),
  });
}

export { onExportTemplate };

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import * as React from 'react';

import { t } from 'utils';

const classes = {
  popup: {
    title: ['message-title'],
    body: ['message-body'],
    text: ['message-text'],
    content: ['message-content'],
    submit: ['message-submit'],
    cancel: ['message-cancel'],
    actions: ['message-actions'],
  },
};

type Props = {
  onSubmit: Function;
  closeDialog: React.MouseEventHandler;
};

export const RejectForm = ({ onSubmit, closeDialog }: Props) => {
  const [rejectReason, setRejectReason] = React.useState('');

  const handleReasonInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRejectReason(event.currentTarget.value);
  };

  const title = t.staticAsString('races.detail.rejection.title');
  const body = t.staticAsString('races.detail.rejection.subtitle');

  return (
    <React.Fragment>
      <DialogTitle id='alert-dialog-title' className={classNames(...classes.popup.title)}>
        {title}
      </DialogTitle>
      <DialogContent className={classNames(...classes.popup.content)}>
        <DialogContentText className={classNames(...classes.popup.body)}>{body}</DialogContentText>
        <TextField
          value={rejectReason}
          onChange={handleReasonInput}
          autoFocus
          fullWidth
          rows={3}
          maxRows={3}
          id='message'
          margin='dense'
          type='message'
          label={t.staticAsString('shared.messagePopup.message' as TranslationLockedKeys)}
          variant='outlined'
          multiline={true}
          className={classNames(...classes.popup.text)}
          placeholder={t.staticAsString('shared.messagePopup.messagePlaceholder')}
        />
      </DialogContent>
      <DialogActions className={classNames(...classes.popup.actions)}>
        <Button onClick={closeDialog} autoFocus className={classNames(...classes.popup.cancel)}>
          {t.staticAsString('shared.messagePopup.btnAction.cancel')}
        </Button>
        <Button onClick={() => onSubmit(rejectReason)} autoFocus disabled={!rejectReason} className={classNames(...classes.popup.submit)}>
          {t.staticAsString('shared.messagePopup.btnAction.submit')}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

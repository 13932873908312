import { flatten, compact, isEmpty } from 'lodash';
import moment from 'moment';
import * as React from 'react';

import { time } from 'utils';

import { DateRangeFilter } from '../DefaultFilters';

type Props = {
  dateFormat: BEDateFormat;
  withYearSelect?: boolean;
} & FilterProps;

type FilterValue = nil | moment.Moment;
type Value = [FilterValue, FilterValue];

// NOTE,
// Converse moment value to composed string and vice versa
const DatetRangeComponent = (props: Props) => {
  const { dateFormat, value, name } = props;
  const [rawFrom, rawTo] = value || [];

  let from: moment.Moment | null | any = time(rawFrom, dateFormat).ifIsValid()?.parse().val;
  let to: moment.Moment | null | any = time(rawTo, dateFormat).ifIsValid()?.parse().val;
  function apply({ value }: { value: Value }): void {
    const values = flatten([value]);

    if (isEmpty(compact(values))) {
      return props.delete(name);
    }

    const [from, to] = value;

    let parsedFrom: any = null;
    let parsedTo: any = null;

    if (from) {
      parsedFrom = time.workTime(from, dateFormat).compose().val;
    }

    if (to) {
      parsedTo = time.workTime(to, dateFormat).compose().val;
    }

    props.apply({ name, value: [parsedFrom, parsedTo] });
  }
  return <DateRangeFilter {...props} value={[from, to]} apply={apply} />;
};

const DatetRange = DatetRangeComponent;

export { DatetRange };

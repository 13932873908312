import { Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DayPickerRangeController, DayPickerSingleDateController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { Svg } from 'components/Icon';

import { HardElevation } from './components';
import { singleMonthRangeWrapperClasses, rangeWrapperClasses, singleWrapperClasses, yearContainerClasses } from './styles';
import { getNumberOfCalendarMonthWeeks } from './utils';

const CALENDAR_CELL_HEIGHT = 46;
const REST_CALENDAR_CONTENT_HEIGHT = 223;

export type FieldValue = Moment | null;

type Props = {
  isSingleMonthRange?: boolean;
  isSingleDate: boolean;
  date?: FieldValue;
  startDate?: FieldValue;
  endDate?: FieldValue;
  minDate?: FieldValue;
  maxDate?: FieldValue;
  onChange: (arg: [FieldValue, FieldValue] | null) => void;
};

type FocusState = 'startDate' | 'endDate';

export const ImprovedDatePicker = (props: Props): any => {
  const [focus, setFocus] = useState<FocusState>('startDate');
  const [focused, setFocused] = useState<boolean>(true);
  const [calendarHeight, setCalendarHeight] = useState<number>(450);

  useEffect(() => changeMonthHandler(props.minDate || moment()), []);

  const { startDate, endDate, date, isSingleDate, isSingleMonthRange, onChange } = props;
  const classesSingleMonthRangeWrapper = singleMonthRangeWrapperClasses();
  const classesSingleWrapperClasses = singleWrapperClasses({ containerHeight: calendarHeight });
  const classesRangeWrapper = rangeWrapperClasses();

  const changeMonthHandler = (newCurrentMonth: moment.Moment) =>
    setCalendarHeight(CALENDAR_CELL_HEIGHT * getNumberOfCalendarMonthWeeks(newCurrentMonth, 1) + REST_CALENDAR_CONTENT_HEIGHT);
  const onChangeFocus = (focus: FocusState) => setFocus(focus);

  const isDayBlocked = (day: Moment): boolean => {
    const { minDate, maxDate } = props;

    if (!maxDate && !!minDate) return day.isSameOrBefore(minDate);
    if (!minDate && !!maxDate) return day.isSameOrAfter(maxDate);

    if (!!minDate && !!maxDate) {
      return !day.isBetween(minDate, maxDate, 'day', '[]');
    }

    return false;
  };

  const handleChange = ({ startDate, endDate }: { startDate: Moment | null; endDate: Moment | null }) => {
    if (startDate && endDate && !endDate.isAfter(startDate)) {
      onChange([endDate || null, null]);
      return;
    }

    if (startDate && endDate) {
      setFocus('startDate');
    }

    onChange([startDate || null, endDate || null]);
  };

  const handleSingleChange = (value: Moment | null) => {
    const { date, onChange } = props;

    if (date) {
      onChange([value, null]);
      return;
    }

    if (value) {
      setFocused(true);
    }

    onChange([value, null]);
  };

  const classesYearContainer = yearContainerClasses();

  const renderMonthElement = ({ month, onYearSelect }) => (
    <div className='month-header' style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={classesYearContainer.root}>
        <div>
          <Typography variant='h6' className='navigation-name'>
            {month.format('MMMM')}
          </Typography>
        </div>
        <div className='change-year'>
          <Svg
            className='navigation-button'
            onClick={() => {
              const currentYear = month.year();
              onYearSelect(month, currentYear - 1);
              changeMonthHandler(month.year(currentYear - 1));
            }}
            name='NavigateLeft'
            size={24}
          />
          <Typography variant='h6' className='navigation-name'>
            {month.year()}
          </Typography>
          <Svg
            className='navigation-button'
            onClick={() => {
              const currentYear = month.year();
              onYearSelect(month, currentYear + 1);
              changeMonthHandler(month.year(currentYear + 1));
            }}
            name='NavigateRight'
            size={24}
          />
        </div>
      </div>
    </div>
  );

  if (isSingleMonthRange) {
    return (
      <HardElevation>
        <div className={classesSingleMonthRangeWrapper.root}>
          <DayPickerRangeController
            startDate={(startDate as any) || null}
            endDate={(endDate as any) || null}
            onDatesChange={handleChange as AnyFunction}
            focusedInput={focus}
            onFocusChange={onChangeFocus}
            isDayBlocked={isDayBlocked as AnyFunction}
            initialVisibleMonth={null}
            numberOfMonths={1}
            minimumNights={0}
            keepOpenOnDateSelect={true}
            noBorder
            hideKeyboardShortcutsPanel
            enableOutsideDays
            renderMonthElement={renderMonthElement}
            navPrev={<Svg className='navigate-left' name='NavigateLeft' size={24} />}
            navNext={<Svg className='navigate-right' name='NavigateRight' size={24} />}
            transitionDuration={0}
            firstDayOfWeek={1}
          />
        </div>
      </HardElevation>
    );
  }

  // Hack to remove problem with types
  const handlers = {
    onNextMonthClick: changeMonthHandler,
    onPrevMonthClick: changeMonthHandler,
  };

  return (
    <>
      {isSingleDate ? (
        <HardElevation level={0}>
          <div className={classesSingleWrapperClasses.root}>
            {
              // @ts-ignore
              <DayPickerSingleDateController
                date={(date as any) || null}
                onDateChange={handleSingleChange as AnyFunction}
                focused={focused}
                onFocusChange={({ focused }) => setFocused(focused)}
                isDayBlocked={isDayBlocked as AnyFunction}
                noBorder
                numberOfMonths={1}
                initialVisibleMonth={null}
                hideKeyboardShortcutsPanel
                enableOutsideDays
                renderMonthElement={renderMonthElement}
                navPrev={<Svg className='navigate-left' name='NavigateLeft' size={24} />}
                navNext={<Svg className='navigate-right' name='NavigateRight' size={24} />}
                firstDayOfWeek={1}
                {...handlers}
              />
            }
          </div>
        </HardElevation>
      ) : (
        <>
          <HardElevation level={0}>
            <div className={classesRangeWrapper.root}>
              <DayPickerRangeController
                startDate={(startDate as any) || null}
                endDate={(endDate as any) || null}
                onDatesChange={handleChange as AnyFunction}
                focusedInput={focus}
                onFocusChange={onChangeFocus}
                isDayBlocked={isDayBlocked as AnyFunction}
                initialVisibleMonth={null}
                numberOfMonths={2}
                minimumNights={0}
                keepOpenOnDateSelect={true}
                noBorder
                hideKeyboardShortcutsPanel
                enableOutsideDays
                renderMonthElement={renderMonthElement}
                navPrev={<Svg className='navigate-left' name='NavigateLeft' size={24} />}
                navNext={<Svg className='navigate-right' name='NavigateRight' size={24} />}
                transitionDuration={0}
                firstDayOfWeek={1}
              />
            </div>
          </HardElevation>
        </>
      )}
    </>
  );
};

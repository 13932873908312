class SearchSerializer implements ISearchProcessor {
  // @ts-ignore
  search: FiltersType;
  handlers: {
    [K in string]: string;
  };
  allowedBlank: Array<string>;
  // @ts-ignore
  serializedSearch: string;
  // @ts-ignore
  serializedSearchHandlers: string;
  trimEmptyArrayOption: boolean;

  constructor(
    search: FiltersType | string,
    handlers: {
      [K in string]: string;
    },
    allowedBlank: Array<string>,
  ) {
    this.handlers = handlers;
    this.allowedBlank = allowedBlank;
    this.trimEmptyArrayOption = false;

    if (typeof search === 'string') {
      this.serializedSearch = search;
      this.deserialize();
    } else if (typeof search === 'object') {
      this.search = search;
      this.serialize();
    } else {
      this.search = {};
      this.serialize();
    }
  }

  serialize() {
    this.serializedSearch = this.serializeSearch();
    this.serializedSearchHandlers = this.serializeSearchHandlers();
  }

  deserialize() {
    this.search = this.deserializeSearch();
  }

  // Adds new filters(merging with others)
  appendFilters(search: FiltersType) {
    this.search = { ...this.search, ...search };
    this.serialize();
  }

  // Clears all filters
  clearFilters(): void {
    this.search = {};
    this.serialize();
  }

  // Simply removes specific filters
  deleteFilters(...filterNames: Array<string>) {
    filterNames.forEach((filterName) => {
      delete this.search[filterName];
    });

    this.serialize();
  }

  enableTrimEmptyArrayOption() {
    this.trimEmptyArrayOption = true;
    this.serialize();
  }

  disableTrimEmptyArrayOption() {
    this.trimEmptyArrayOption = false;
    this.serialize();
  }

  serializeSearch(): string {
    const params = this.search;
    if (params) {
      return Object.keys(params).reduce((acc, param) => {
        let value = params[param];
        if (this._isParamEmpty(param, value) || !this.handlers[param]) {
          return acc;
        }

        let filter = '';
        const serializedValue = this._serializeValue(value);
        filter = `${param}:${serializedValue ? serializedValue : ''}`;
        if (!acc) {
          return filter;
        }
        return `${acc};${filter}`;
      }, '');
    }
    return '';
  }

  serializeSearchHandlers(): string {
    const params = this.search;
    const search = Object.keys(params);
    return search.reduce((acc, param) => {
      if (!params[param] || !this.handlers[param]) {
        return acc;
      }

      const filterHandler = `${param}:${this.handlers[param]}`;
      if (!acc) {
        return filterHandler;
      }
      return `${acc};${filterHandler}`;
    }, '');
  }

  deserializeSearch(): FiltersType {
    return this.serializedSearch.split(';').reduce((acc: any, val: string) => {
      // @ts-ignore
      if (val || val === 0) {
        const [, key, value] = val.split(/^(.*?):(.*)/);
        acc[key] = this._deserializeValue(value);
      }
      return acc;
    }, {});
  }

  params(): nil | FiltersType {
    const { serializedSearch, serializedSearchHandlers } = this;
    if (!serializedSearch) {
      return null;
    }
    return {
      search: serializedSearch,
      searchFields: serializedSearchHandlers,
    };
  }

  clone(): SearchSerializer {
    return new SearchSerializer(this.search, this.handlers, this.allowedBlank);
  }

  _isParamEmpty(paramName: string, value: FilterType): boolean {
    if (!value && !this._allowedBlankParam(paramName)) {
      return true;
    }
    if (!(value instanceof Date) && value instanceof Array) {
      const isInvalid = value.every((filter) => !filter);
      if (isInvalid) {
        return true;
      }
    }
    return false;
  }

  _allowedBlankParam(paramName: string): boolean {
    return this.allowedBlank.includes(paramName);
  }

  _serializeValue(value: FilterType): nil | string | boolean {
    if (value instanceof Array) {
      return this._serializeArray(value);
    } else if (typeof value === 'string') {
      return value;
    } else if (typeof value === 'number') {
      return `${value}`;
    } else {
      return value;
    }
  }

  _serializeArray(value: Array<FilterType>): string | null {
    let valueForSerialization = value;

    if (this.trimEmptyArrayOption) {
      valueForSerialization = valueForSerialization.filter((el) => el === 0 || !!el);
    }

    return valueForSerialization.map((filter) => this._serializeValue(filter)).join(',');
  }

  _deserializeValue(value: string) {
    if (/.*,.*/.test(value)) {
      return this._deserializeArray(value);
      // eslint-disable-next-line
    } else if (parseInt(value, 10) == (value as any)) {
      return parseInt(value, 10);
    } else {
      return value;
    }
  }

  _deserializeArray(value: string): Array<FilterType> | null {
    return value.split(',').map((filter) => this._deserializeValue(filter));
  }
}

export { SearchSerializer };

import { observer } from 'mobx-react';
import * as React from 'react';

import { withProgressSpinner, ListModule, withSorting, withSearch } from 'hocs';

import { FilterLabelToolbar } from 'components/Filter/FilterLabelToolbar';
import { SubscribedPagination } from 'components/Pagination';

import { Title } from '../components';
import { Nav } from '../components/Nav';

import { ConfirmModal } from '../containers/ConfirmModal';

import { LOAD_TASKS } from '../constants';

import { mountIndex, unmountIndex } from '../actions';

import { taskService } from '../services';

import { TaskFormModal } from './New';
import { Table } from './Table';
import { Toolbar } from './Toolbar';

type OriginalProps = {};

type Props = OriginalProps & HOC.withIntl;
type State = {};

@withProgressSpinner([LOAD_TASKS])
@ListModule(taskService, mountIndex, unmountIndex)
@withSorting(taskService)
@withSearch(taskService, 'supportTaskManager.list.fields')
@observer
class BaseImpl extends React.Component<Props, State> {
  render() {
    return (
      <div className='details-racers-lists'>
        <div className='content main-container-list racers-list support-task-manager-box'>
          <ConfirmModal />
          <TaskFormModal />
          <div className='sticky-left'>
            <div className='toolbar-container'>
              <Title />
              <Toolbar />
            </div>
            <FilterLabelToolbar />
            <Nav />
          </div>
          <div className='component-list'>
            <Table />
            <SubscribedPagination />
          </div>
        </div>
      </div>
    );
  }
}

export const Base = BaseImpl as unknown as React.ComponentType<OriginalProps>;

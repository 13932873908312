import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { SYSTEM_USER_ROLES_URL } from 'src/constants/api';

import { systemUserRolesStore } from '../stores';

export const loadSystemUserRoles = async (): Promise<void> => {
  const response = await axios.get(generatePath(SYSTEM_USER_ROLES_URL));
  systemUserRolesStore.set(response.data.data);
};

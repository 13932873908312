export type SystemUserCols =
  | ''
  | 'id'
  | 'email'
  | 'roles.name'
  | 'full_name'
  | 'phone'
  | 'address'
  | 'city'
  | 'country_id'
  | 'post_code'
  | 'created_at';

export const systemUsersFields = {
  action: 'action',
  id: 'id',
  email: 'email',
  'roles.name': 'roles.name',
  full_name: 'full_name',
  phone: 'phone',
  address: 'address',
  city: 'city',
  country_id: 'country_id',
  post_code: 'post_code',
  created_at: 'created_at',
};

const MAIN_COLUMNS = [
  systemUsersFields.action,
  systemUsersFields.id,
  systemUsersFields.email,
  systemUsersFields['roles.name'],
  systemUsersFields.full_name,
  systemUsersFields.phone,
  systemUsersFields.address,
  systemUsersFields.city,
  systemUsersFields.country_id,
  systemUsersFields.post_code,
  systemUsersFields.created_at,
];

const COLUMN_WIDTH: { [K in string]: number } = {};

const SYSTEM_USERS_COLUMNS = {
  MAIN_COLUMNS,
  COLUMN_WIDTH,
};

export { SYSTEM_USERS_COLUMNS };

export const MuiLink = {
  styleOverrides: {
    root: {
      '&:hover': {
        color: '#f9d026',
      },

      '&:visited': {
        color: '#262e35',

        '&:hover': {
          color: '#f9d026',
        },
      },
    },

    underlineNone: {
      '&:hover': {
        color: '#262e35',
      },
      '&:visited': {
        '&:hover': {
          color: '#262e35',
        },
      },
    },
  },
};

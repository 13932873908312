import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, TablePagination, TextField, Typography } from '@mui/material';
import { debounce, isEqual, omit } from 'lodash';
import { Observer } from 'mobx-react';
import NoCommissions from 'modules/Reconciliation/shared/components/NoCommissions';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useExpanded, useFilters, usePagination, useSortBy, useTable } from 'react-table';

import { t } from 'src/utils';

import { Spinner } from 'components/Spinner';

import { progressStore } from 'stores';

import { CommissionConstants } from '../../constants/commission.constants';

import { CommissionStore } from '../../stores/commission.store';
import { organizerStore } from '../../stores/currencies.store';

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter, id } }) {
  const handleSetFilter = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setFilter(e.target.value || undefined);
  return (
    <Box p={0.5} mb={1.5}>
      <TextField
        fullWidth
        variant='outlined'
        size='small'
        value={filterValue || ''}
        onChange={handleSetFilter}
        sx={{ width: CommissionConstants.COLUMN_WIDTH[id] }}
      />
    </Box>
  );
}

function deepCompareEquals(a: any, b: any) {
  return isEqual(a, b);
}

const CommissionTable = ({
  fetchCommissions,
  tableMeta,
  tableColumns,
  tableData,
  initialSizeOfPage,
  selectedCurrencyId,
  pageSizeOptions,
}): JSX.Element => {
  const data = useMemo(() => tableData, [tableData]);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const updateLocally = (idx, id, field, value) => {
    const obj = {
      ...CommissionStore.commissions.value!.data[idx],
      [field]: +value,
    };

    CommissionStore.commissions.set({
      meta: CommissionStore.commissions.value!.meta,
      data: CommissionStore.commissions.value!.data.map((c) => (c.id === id ? obj : c)),
    });
  };

  const {
    getTableProps,
    state: { sortBy, filters, pageIndex, pageSize },
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    setPageSize,
    pageOptions,
    gotoPage,
  } = useTable(
    {
      columns: tableColumns,
      defaultColumn,
      data,
      initialState: {
        pageSize: 30,
      },
      manualFilters: true,
      manualSortBy: true,
      manualPagination: true,
      pageCount: tableMeta.pagination?.total ?? 0,
      autoResetFilters: false,
      defaultCanSort: true,
      updateLocally,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const debouncedCallback = useCallback(
    debounce((props) => fetchCommissions(props), 500),
    [],
  );
  useEffect(() => {
    return debouncedCallback({ pageIndex, pageSize, filters, sortBy });
  }, [fetchCommissions, pageIndex, pageSize, filters, sortBy]);

  if (progressStore.isLoading(CommissionConstants.Table)) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 5 }}>
        <Spinner type={Spinner.types.default} />
      </Box>
    );
  }

  const rendereBaseCommissions = () => {
    const commissions = organizerStore.value.active_commissions;
    if (commissions && commissions.length > 0) {
      const commission = commissions.find((c) => c.currency_id === selectedCurrencyId);
      if (commission) {
        return <NoCommissions commission={commission} />;
      }
    }
  };

  const buildTBody = () => {
    if (!progressStore.isLoading(CommissionConstants.Table) && rows.length === 0) {
      return (
        <tr>
          <td className='cell' colSpan={5}>
            <Typography sx={{ ml: 10, mt: 3 }} variant='h5'>
              {t.staticAsString('reconciliation.commission.noDataAfterQuery')}
            </Typography>
          </td>
        </tr>
      );
    }

    return rows.map((row) => {
      prepareRow(row);
      return (
        <tr className='row' {...row.getRowProps()}>
          {row.cells.map((cell) => {
            return (
              <td className='cell' {...cell.getCellProps()}>
                {cell.render('Cell')}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <Observer
      render={() => {
        CommissionStore.modifyCommission.value;
        return (
          <>
            {progressStore.isLoading(CommissionConstants.Table) && (
              <div style={{ backgroundColor: 'white', height: '100%', width: '100%', zIndex: 10, opacity: '0.5', position: 'absolute' }} />
            )}
            <div className='standart-table simple' style={{ marginBottom: '50px' }}>
              {rendereBaseCommissions()}

              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        return (
                          <th {...column.getHeaderProps()}>
                            <div {...omit(column.getHeaderProps(column.getSortByToggleProps()), 'title')}>
                              <div style={{ cursor: 'pointer', display: 'flex', height: 28 }}>
                                {column.render('Header')}
                                <span>
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <ArrowDownwardIcon style={{ marginLeft: 10 }} />
                                    ) : (
                                      <ArrowUpwardIcon style={{ marginLeft: 10 }} />
                                    )
                                  ) : (
                                    <div style={{ width: 24, height: 24, marginLeft: 10 }} />
                                  )}
                                </span>
                              </div>
                            </div>
                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>{buildTBody()}</tbody>
              </table>
            </div>
            <TablePagination
              sx={{ position: 'fixed', bottom: '47px', right: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
              component='div'
              count={pageOptions.length}
              page={pageIndex}
              rowsPerPageOptions={pageSizeOptions}
              onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
                if (progressStore.isLoading(CommissionConstants.Table)) return;
                gotoPage(newPage);
              }}
              rowsPerPage={pageSize}
              onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                setPageSize(Number(event.target.value))
              }
            />
          </>
        );
      }}
    />
  );
};
export { CommissionTable };

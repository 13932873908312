import { defaultListFilters } from 'utils';

import { FilterDescriptorWrapper } from 'models';

import { SystemUserCols } from '../constants';

import { country } from './country';
import { role } from './role';

type Cols = SystemUserCols;

const descriptors: { [K in Cols]: FilterListDescriptor } = {
  id: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      default: true,
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },
  email: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      default: false,
      reorderOnSearch: true,
      defaultBy: 'ASC',
      calcOrderBy: (name) => name,
    },
  },
  'roles.name': role,
  full_name: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      default: false,
      reorderOnSearch: true,
      defaultBy: 'ASC',
      calcOrderBy: (name) => name,
    },
  },
  phone: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      default: false,
      reorderOnSearch: true,
      defaultBy: 'ASC',
      calcOrderBy: (name) => name,
    },
  },
  address: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      default: false,
      reorderOnSearch: true,
      defaultBy: 'ASC',
      calcOrderBy: (name) => name,
    },
  },
  city: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      default: false,
      reorderOnSearch: true,
      defaultBy: 'ASC',
      calcOrderBy: (name) => name,
    },
  },
  country_id: country,
  post_code: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
    sort: {
      default: false,
      reorderOnSearch: true,
      defaultBy: 'ASC',
      calcOrderBy: (name) => name,
    },
  },
  created_at: {
    filter: defaultListFilters.dateRange,
    frontend: { type: 'dateRange' },
    sort: {
      default: false,
      reorderOnSearch: true,
      defaultBy: 'ASC',
      calcOrderBy: (name) => name,
    },
  },
  '': null as any,
};

export const filterDescriptors = Object.keys(descriptors).reduce((acc, desc) => {
  return {
    ...acc,
    [desc]: new FilterDescriptorWrapper(desc, descriptors[desc]),
  };
}, {});

import { Info } from '@mui/icons-material';
import { Box, FormLabel, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';

import { Show } from 'components/Condition';
import { InputWrapper, DatePicker, WrappedRadio, Number, WrappedCheckbox, AutocompleteSelectInfinityLoad } from 'components/Form';

import { t } from 'utils';

import { form } from 'stores';

import { SUPPORT_TASK_MANAGER_FORM } from '../../constants';

import { optionsCouponDiscountType } from '../../utils';

import { loadDistances, loadRaces } from '../../actions';

import { distancesStore, racesStore, getParams, getRacesParams } from '../../stores';

type Props = {
  show: boolean;
};

const StyledFormLabel = withStyles({
  root: {
    marginBottom: 10,
  },
})(FormLabel);

@observer
export class FieldsGenerateCouponCodes extends React.Component<Props> {
  onInputChangeRace = (text) => {
    racesStore.clear();
    racesStore.setParams(getRacesParams(text, true));
    loadRaces();
  };

  loadMoreRaces = () => {
    loadRaces();
  };

  onChangeRace = ({ name, value }: { name: string | string[]; value: any }) => {
    form.onChange(SUPPORT_TASK_MANAGER_FORM, name, value);
    if (value) {
      loadDistances(value.value);
    } else {
      form.onChange(SUPPORT_TASK_MANAGER_FORM, 'couponDistances', null);
      distancesStore.clear();
    }
  };

  onChangeBibDistances = ({ name, value }: { name: string | string[]; value: any }) => {
    form.onChange(SUPPORT_TASK_MANAGER_FORM, name, value);
  };

  loadMoreDistances = () => {
    const race = form.fetch<{ value: number }>(SUPPORT_TASK_MANAGER_FORM, 'couponRace');
    loadDistances(race.value);
  };

  onInputChangeBibDistances = (text: string) => {
    distancesStore.clear();
    const race = form.fetch<{ value: number }>(SUPPORT_TASK_MANAGER_FORM, 'couponRace');
    distancesStore.setParams(getParams(text));
    loadDistances(race.value);
  };

  render() {
    const { show } = this.props;

    return (
      <Show if={show}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputWrapper
              name='couponRace'
              settings={{
                required: true,
                label: t.staticAsString('supportTaskManager.form.race'),
                additional: {
                  options: racesStore.races,
                },
                onSearch: this.onInputChangeRace,
                onChange: this.onChangeRace,
                loadMore: this.loadMoreRaces,
                hasMore: racesStore.hasMore,
              }}
              Component={AutocompleteSelectInfinityLoad}
            />
          </Grid>

          <Grid item xs={12}>
            <InputWrapper
              name='couponDistances'
              settings={{
                required: true,
                label: t.staticAsString('supportTaskManager.form.distances'),
                additional: {
                  options: distancesStore.distances,
                },
                onChange: this.onChangeBibDistances,
                onSearch: this.onInputChangeBibDistances,
                loadMore: this.loadMoreDistances,
                hasMore: distancesStore.hasMore,
              }}
              Component={AutocompleteSelectInfinityLoad}
            />
          </Grid>

          <Grid item xs={6}>
            <InputWrapper
              name='couponQuantity'
              settings={{
                required: true,
                label: t.staticAsString('supportTaskManager.form.couponQuantity'),
                additional: {
                  fullWidth: true,
                },
              }}
              Component={Number}
            />
          </Grid>

          <Grid item xs={6}>
            <InputWrapper
              name='couponLength'
              settings={{
                required: true,
                label: t.staticAsString('supportTaskManager.form.couponLength'),
                additional: {
                  fullWidth: true,
                },
              }}
              Component={Number}
            />
          </Grid>

          <Grid item xs={12}>
            <InputWrapper
              name='couponDateRange'
              settings={{
                minDate: moment(),
                required: true,
                label: t.staticAsString('supportTaskManager.form.couponDateRange'),
                view: {
                  variant: 'outlined',
                },
              }}
              Component={DatePicker}
            />
          </Grid>

          <Grid item xs={12}>
            <StyledFormLabel>{t('coupons.new.discount_type')}*</StyledFormLabel>
            <InputWrapper
              name='couponDiscountType'
              settings={{
                required: true,
                options: optionsCouponDiscountType(),
              }}
              Component={WrappedRadio}
            />
          </Grid>

          <Grid item xs={12}>
            <InputWrapper
              name='couponDiscountAmount'
              settings={{
                required: true,
                label: t.staticAsString('supportTaskManager.form.couponAmount'),
                additional: {
                  fullWidth: true,
                },
              }}
              Component={Number}
            />
          </Grid>

          <Grid item xs={12}>
            <Box display='flex' alignItems='center'>
              <Typography gutterBottom>
                {t('coupons.new.codeType')}*
                <Tooltip title={<Typography variant='body2'>{t('coupons.new.typeInfo')}</Typography>}>
                  <IconButton size='large'>
                    <Info color='disabled' />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <InputWrapper
              name='registrationFree'
              settings={{
                required: true,
                label: t.staticAsString('supportTaskManager.form.registrationsFree'),
              }}
              Component={WrappedCheckbox}
            />
          </Grid>

          <Grid item xs={6}>
            <InputWrapper
              name='addons'
              settings={{
                required: true,
                label: t.staticAsString('supportTaskManager.form.addOns'),
              }}
              Component={WrappedCheckbox}
            />
          </Grid>
        </Grid>
      </Show>
    );
  }
}

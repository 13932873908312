import { Observer } from 'mobx-react';
import * as React from 'react';

import { closeImportErrorsModal } from 'src/modules/StartList/actions';
import { IMPORT_START_LIST } from 'src/modules/StartList/constants';

import { errorsStore } from 'stores';

import { Modal } from '.';

export const ImportErrorsModal = () => {
  return (
    <Observer>
      {() => {
        const apiErrors = errorsStore.errors.api;

        if (!errorsStore.errors) {
          return null;
        }

        const data = apiErrors[IMPORT_START_LIST] as any;
        const isOpen = Boolean(data);
        let errors = [] as any;

        if (data?.response?.data) {
          const responseData = data.response.data;

          if (responseData.status === 'failed') {
            if (responseData.data && responseData.data.errors) {
              errors = Object.values(responseData.data.errors);
            } else {
              const message = responseData.data && responseData.data.message ? responseData.data.message : 'failed';
              errors.push([message]);
            }
          }
        }

        return <Modal isOpen={isOpen} errors={errors} onClose={closeImportErrorsModal} />;
      }}
    </Observer>
  );
};

import { Checkbox, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';

import { FLAGS } from 'src/constants';

import { LocaleIcon } from 'components/Footer/components/LocaleIcon';
import { Select } from 'components/Form';
import { formatLongLocale } from 'components/core';

import { t } from 'utils/t';

import { useStyles } from './styles';

interface Props {
  errors: { pref_lang_code?: string[] };
  selectedLanguages: availableLocales[];
  defaultLocale: availableLocales;

  locales: availableLocales[] | null;
  onLanguagesSelect: (data: { selectedLanguages: availableLocales[]; defaultLocale?: availableLocales }) => void;
}

export const RaceLanguages = ({ errors, locales, selectedLanguages, defaultLocale, onLanguagesSelect }: Props) => {
  if (!locales) {
    return null;
  }

  const handleChangeDefaultLocale = ({ value }) => {
    onLanguagesSelect({
      defaultLocale: value,
      selectedLanguages: [...selectedLanguages.filter((locale) => locale !== value), defaultLocale, value],
    });
  };

  const handleToggleSelectLanguage = (toggled: availableLocales, isChecked: boolean) => () => {
    if (isChecked) {
      onLanguagesSelect({ selectedLanguages: selectedLanguages.filter((locale) => locale !== toggled), defaultLocale });
    } else {
      onLanguagesSelect({ selectedLanguages: [toggled, ...selectedLanguages], defaultLocale });
    }
  };

  const classes = useStyles();
  return (
    <>
      <div className={classes.selectInputWrapper}>
        <Typography className={classes.sectionTitle}>{t('races.steps.languages.selectFrom')}</Typography>
        <Select
          id='default-lang-select'
          className={classes.selectInput}
          name='select'
          view={{
            fullWidth: true,
            variant: 'outlined',
          }}
          error={errors.pref_lang_code?.[0] || ''}
          value={defaultLocale}
          options={locales.map((locale) => ({
            key: locale,
            value: locale,
            label: formatLongLocale(locale),
          }))}
          label={t.staticAsString('races.steps.languages.setDefault' as TranslationLockedKeys)}
          onChange={handleChangeDefaultLocale}
        />
      </div>
      <div className={classes.languagesList}>
        <Typography className={classes.sectionTitle}>{t('races.steps.languages.selectTo')}</Typography>
        <List className={classes.languagesListWrapper} disablePadding>
          {locales
            .filter((locale) => locale !== defaultLocale)
            .map((locale) => {
              const isChecked = selectedLanguages.includes(locale);
              const checkBox = (
                <ListItemSecondaryAction>
                  <Checkbox
                    edge='end'
                    color='primary'
                    onChange={handleToggleSelectLanguage(locale, isChecked)}
                    checked={isChecked}
                    inputProps={{ 'aria-labelledby': 'whatever' }}
                  />
                </ListItemSecondaryAction>
              );
              return (
                <ListItem key={locale} className={classes.listItem}>
                  <ListItemText
                    primary={
                      <div className={classes.languageTitleContainer}>
                        <LocaleIcon value={locale} flag={FLAGS[locale]} selected={locale} onClick={() => {}} />
                        <p className={classes.languageTitle}>{formatLongLocale(locale)}</p>
                      </div>
                    }
                  />
                  {checkBox}
                </ListItem>
              );
            })}
        </List>
      </div>
    </>
  );
};

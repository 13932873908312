import { Typography } from '@mui/material';
import React from 'react';

import { t } from 'src/utils';

import { TableRow } from '../../../../types';

interface Props {
  row: TableRow;
}

const InheritNoticeCell = (props: Props) => {
  return (
    <>
      <Typography>
        {props.row.isRace
          ? t.staticAsString('systemUsers.commissions.inheritedFromOrganizer')
          : props.row.isDistance
          ? t.staticAsString('systemUsers.commissions.inheritedFromRace')
          : ''}
      </Typography>
    </>
  );
};

export { InheritNoticeCell };

import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { deleteCheckpointCoordinates } from 'src/modules/Distances/components/shared/Steps/GPX/actions';
import {
  OneCheckPointSet,
  OneCheckPointReady,
  OneCheckPointActive,
} from 'src/modules/Distances/components/shared/Steps/GPX/components/ManageCheckPointsModal';
import { checkPointStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { CheckPoint } from 'src/modules/Distances/components/shared/Steps/GPX/types';
import { removeCheckPointCoordinates, removeMarker } from 'src/modules/Distances/components/shared/Steps/GPX/utils';

const Wrapper = styled.div`
  width: 384px;
  margin: 0 0 0 26px;
  max-height: 620px;
  overflow-y: auto;
`;

export const CheckPoints = () => (
  <Observer>
    {() => {
      const checkPoints = checkPointStore.value as Array<CheckPoint>;

      const onRemove = (checkpoint) => {
        deleteCheckpointCoordinates(checkpoint);
        removeCheckPointCoordinates(checkpoint.id);
        removeMarker(checkpoint.index);
      };

      return (
        <Wrapper>
          {checkPoints.map((item, index) => {
            if (index === 0) {
              return false;
            }

            const key = 'oneCheckPoint' + item.id;

            if (item.type === 'ready') return <OneCheckPointReady key={key} checkpoint={item} onRemove={onRemove} />;
            if (item.type === 'set') return <OneCheckPointSet key={key} checkpoint={item} />;
            if (item.type === 'active') return <OneCheckPointActive key={key} checkpoint={item} />;
          })}
        </Wrapper>
      );
    }}
  </Observer>
);

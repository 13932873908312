import moment from 'moment';

import { chartRangePickerStore } from '../stores/interval.store';

export function mapLabels(label: Date) {
  const selectedRange = chartRangePickerStore.selectedRange;

  switch (selectedRange) {
    case '24h':
      return moment(label).format('HH') + 'h';
    case '7d':
      return moment(label).format('DD MMM');
    case '30d':
      return moment(label).format('DD MMM');
    case '3m':
    case '6m':
      return moment(label).format('MMM');
    case '12m':
      return moment(label).format('MMM');
    default:
      return moment(label).format('DD MMM YY');
  }
}

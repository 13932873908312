import { observer } from 'mobx-react';
import * as React from 'react';

import { SelectFilterWithSearch } from 'components/Filter/DefaultFilters';

import { countriesStore } from 'stores';

type Props = {} & FilterProps;

@observer
class CountryIdComponent extends React.Component<Props> {
  render() {
    const { props } = this;
    return <SelectFilterWithSearch {...props} options={countriesStore.valuesForCountryDropdown()} />;
  }
}

export const countryId: FilterListDescriptor = {
  filter: {
    persistance: {
      handler: '=',

      calcValue: function (name, value) {
        return value;
      },

      prettify: function (name, value) {
        const countryModel = countriesStore.findModelValue(+value);
        return countryModel && countryModel.name();
      },
    },
  },

  frontend: {
    type: 'custom',
    component: CountryIdComponent,
  },
};

import React from 'react';

import { InputFilter } from 'components/Filter';

import { t } from 'utils';

import { FilterProps } from './index';

type Props = {
  value: nil | string | number;
} & FilterProps;

const DistancesName = (props: Props) => {
  const name = 'distances.translations.name';
  const value: nil | string | number = props.values[name] as any;

  return <InputFilter {...props} name={name} value={value} label={t.staticAsString('races.filters.name')} />;
};

export { DistancesName };

import { observer } from 'mobx-react';
import * as React from 'react';

import { SelectFilterWithSearch } from 'components/Filter/DefaultFilters';

import { countriesStore } from 'stores';

type Props = {
  value: string | number | nil;
} & FilterProps;

@observer
class CountryComponent extends React.Component<Props> {
  render() {
    const { props } = this;
    return <SelectFilterWithSearch {...props} options={countriesStore.valuesForCountryDropdown()} />;
  }
}

export const country: FilterListDescriptor = {
  filter: {
    persistance: {
      handler: '=',

      calcValue: function (name, value) {
        return value;
      },

      prettify: function (name, value) {
        if (!value) {
          return '';
        }

        const countries = countriesStore.valuesForCountryDropdown();
        const country = countries.find((country) => country.value === value);
        return country?.label || '';
      },
    },
  },
  frontend: {
    type: 'custom',
    component: CountryComponent,
  },
};

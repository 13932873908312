import { Observer } from 'mobx-react';
import styled from 'styled-components';

import { Icon } from 'components/Icon';

import { removeHttp } from '../../utils';

import { handleCopyLink } from '../../actions';

import { copiedLinkStore } from '../../stores';

export const LinkComponent = ({ url }) => {
  const trimmedUrl = removeHttp(url);

  return (
    <Observer>
      {() => {
        const isCopied = copiedLinkStore.value === url;
        return (
          <LinkWrapper onClick={() => handleCopyLink(url)} isCopied={isCopied}>
            <LinkText>{trimmedUrl}</LinkText>
            <Icon style={{ height: '16px' }} value='copy' />
          </LinkWrapper>
        );
      }}
    </Observer>
  );
};

const LinkWrapper = styled.div<{ isCopied: boolean }>`
  background-color: '#e9e9e9';
  box-shadow: ${(props) => (props.isCopied ? 'inset 0 0 0 2px #8a8a8a' : 'none')};
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 6px 8px;
  cursor: pointer;
  :hover {
    background-color: white;
  }
  :active {
    box-shadow: inset 0 0 0 2px #8a8a8a;
  }
`;

const LinkText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
`;

import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import * as React from 'react';

import { FilterWrapper } from 'components/Filter/FilterImpl';
import { SearchContext } from 'components/context';

import { t } from 'utils';

import { SearchService } from 'services';

type State = {
  selectedFilter: nil | string;
  anchorEl: nil | Element;
  actions: Object;
};

const POPPER_RELATIVE_Y_POSITION = -12;
const POPPER_RELATIVE_X_POSITION = 0;

const defaultClasses = {
  popover: {
    main: 'material-popover',
    classes: { paper: 'popover-paper' },
    layout: 'main-layout',
  },
};

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const transormOrigin = {
  vertical: POPPER_RELATIVE_Y_POSITION,
  horizontal: POPPER_RELATIVE_X_POSITION,
};

// NOTE, Search
const withSearch =
  (service: ExtensionableSearchService, translationPrefix: string, classes: AnyObject = defaultClasses) =>
  <P extends any>(WrappedComponent: React.ComponentType<P>) =>
    class WithSearchHOC extends React.Component<any, State> {
      contextToPass: SearchContextProps;

      state = {
        selectedFilter: null,
        anchorEl: null,
        actions: {} as AnyObject,
      };

      constructor(props: any) {
        super(props);
        const store = service.store.search;
        const filterDescriptors = service.store.search.filterDescriptors;
        const searchService = new SearchService(service);

        if (props.sortService) {
          searchService.integrateWithSorting(props.sortService);
        }

        this.contextToPass = {
          search: {
            store,
            filterDescriptors,
            service: searchService,
            apply: this.apply,
            delete: this.delete,
            // @ts-ignore
            renderFilterPopover: this.renderFilterPopover,
            onFilterShow: this.handleShowFilter,
          },
        };
      }

      setActions = (actions: AnyObject) => {
        this.setState({
          actions,
        });
      };

      updatePopoverPosition = () => {
        const { actions } = this.state;

        if (actions && actions.updatePosition) {
          actions.updatePosition();
        }
      };

      apply = (
        value: {
          [K in string]: any;
        },
      ): void => {
        if (!this.contextToPass.search) {
          return;
        }

        const { service } = this.contextToPass.search;
        service.apply(this.onModalClose, value);
      };

      delete = (filterName: string): void => {
        if (!this.contextToPass.search) {
          return;
        }

        const { service } = this.contextToPass.search;
        service.delete(this.onModalClose, filterName);
      };

      handleShowFilter = (e: nil | React.SyntheticEvent = null, selectedFilter: nil | string = null) => {
        if (!this.contextToPass.search) {
          return;
        }

        const { filterDescriptors } = this.contextToPass.search.store;
        const descriptor = filterDescriptors[selectedFilter || ''];

        if (!descriptor) {
          return;
        }

        if (descriptor.frontendFilterType() === 'none') {
          return;
        }

        this.setState({
          selectedFilter,
          anchorEl: (e ? e.currentTarget : null) as any,
        });
      };

      renderFilterPopover: AnyFunction = (i18Prefix: string): React.ReactNode => {
        if (!this.contextToPass.search) {
          return null;
        }

        const { values, filterDescriptors } = this.contextToPass.search.store;
        const { selectedFilter, anchorEl } = this.state;

        const filter = selectedFilter && (
          <FilterWrapper
            filterDescriptors={filterDescriptors}
            name={selectedFilter as any}
            value={values[selectedFilter as any]}
            values={values}
            label={t.staticAsString(`${i18Prefix}.${selectedFilter}` as TranslationLockedKeys)}
            apply={this.apply}
            delete={this.delete}
          />
        );

        return (
          <Popover
            marginThreshold={50}
            action={this.setActions as any}
            className={classes.popover.main}
            classes={classes.popover.classes}
            anchorEl={anchorEl}
            open={!!selectedFilter}
            onClose={this.onModalClose}
            anchorOrigin={anchorOrigin as any}
            transformOrigin={transormOrigin}
            TransitionProps={{
              onEntered: this.updatePopoverPosition,
            }}
          >
            <Paper className={classes.popover.layout}>{filter || null}</Paper>
          </Popover>
        );
      };

      onModalClose = () => this.setState({ selectedFilter: null, anchorEl: null });

      render() {
        return (
          <SearchContext.Provider value={this.contextToPass}>
            <WrappedComponent {...(this.props as any)} searchService={this.contextToPass.search?.service} />
            {this.renderFilterPopover(translationPrefix)}
          </SearchContext.Provider>
        );
      }
    } as unknown as void;

export { withSearch, SearchContext };

import { Commission } from '../types/commissions.type';

export namespace CommissionTransformMapper {
  export function fromBackend(data: Commission.Model[]): Commission.TableRow[] {
    const out: Commission.TableRow[] = [];
    for (let i = 0; i < data.length; i++) {
      const el = data[i];
      const entry: Commission.TableRow = {
        id: el.id,
        email: el.email,
        commissionId: el.commission?.id ?? 0,
        amount: el.commission?.amount ?? 0,
        percent_value: el.commission?.percent_value ?? 0,
        name: el.full_name,
        isOrganizer: true,
        subRows: [],
      };
      el.races.forEach((r, i) => {
        entry.subRows?.push({
          id: r.id,
          email: r.name,
          amount: r.commission?.amount ?? 0,
          percent_value: r.commission?.percent_value ?? 0,
          name: r.name,
          isRace: true,
          currency: r.currency.iso_code,
          subRows: [],
        });
        r.distances?.forEach((d) => {
          entry.subRows[i]?.subRows.push({
            id: d.id,
            email: d.name,
            amount: d.commission?.amount ?? 0,
            percent_value: d.commission?.percent_value ?? 0,
            name: d.name,
            isDistance: true,
            parentRaceId: r.id,
            currency: r.currency.iso_code,
            subRows: [],
          });
        });
      });
      out.push(entry);
    }
    return out;
  }
}

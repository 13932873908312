import { EXPORT_DATA, EXPORT_DATA_SIZES } from 'src/constants';

import { t, handleExport } from 'utils';
import { appEnvControl } from 'utils/appEnvironment';

import { exportJobService } from 'services';

import { toastStore } from 'stores';

/**
 * @description
 * Perform xlsx or csv export of payments list
 */
export const onExportPaymentsList = async (type: string, params: AnyObject, paginationMeta: PaginationMeta) => {
  const { total = EXPORT_DATA.defaultMetaData } = paginationMeta;
  if (total > EXPORT_DATA.metaDataLimit) {
    toastStore.show(t.staticAsString('shared.loadPopup.tooMuchData'));
    return;
  }

  const [, response] = await exportJobService.exportPayments(type, params);

  const { id } = response.data.data;

  // 1500 orders loaded per ~1 second
  const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
  let estimatedLoadingTime = total / EXPORT_DATA.metaDataLoadingRatio;
  if (total > maxTotal) {
    estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
  }

  handleExport({
    id,
    estimatedLoadingTime,
    fileName: `Payments.${type}`,
    onRetry: () => onExportPaymentsList(type, params, paginationMeta),
  });
};

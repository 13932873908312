import React, { FC } from 'react';

import { t } from 'utils';

type Props = {
  title: TranslationLockedKeys;
};

const PageTitle: FC<Props> = (props) => {
  const { title } = props;

  return <h2 className='terms-main-content--title'>{t(title)}</h2>;
};

export { PageTitle };

import { computed } from 'mobx';

import { form } from 'src/stores';

import { EQ_FORM_ID } from '../constants';

import { syncClassesStore, syncWavesStore, raceClassesStore, raceWavesStore, raceCustomFieldsStore } from '../stores';

import { maxDistances } from '../../Shared';

const maxClasses = (internalDistanceId) => {
  return Math.min(
    Number(raceClassesStore.value?.filter((item) => item.parentId === internalDistanceId).length),
    Number(syncClassesStore.value?.length),
  );
};

const maxWaves = (internalDistanceId, externalDistanceId) => {
  return Math.min(
    Number(raceWavesStore.value?.filter((item) => item.parentId === internalDistanceId).length),
    Number(syncWavesStore.value?.filter((item) => item.parentId === externalDistanceId).length),
  );
};

const hasDistances = computed(() => {
  const distances = form.fetch<AnyObject[]>(EQ_FORM_ID, 'distances') || [];

  return distances.some((distance) => distance.external_id && distance.internal_id);
});

export const showDistancePlus = computed(() => {
  const max = maxDistances.get();
  const distances = form.fetch<AnyObject[]>(EQ_FORM_ID, 'distances') || [];

  return distances.length < max;
});

export const showClassPlus = (entity, internalDistanceId) => {
  const max = maxClasses(internalDistanceId);
  const classes = form.fetch<AnyObject[]>(EQ_FORM_ID, entity) || [];

  return classes.length < max && hasDistances.get();
};

export const showWavePlus = (entity, internalDistanceId, externalDistanceId) => {
  const max = maxWaves(internalDistanceId, externalDistanceId);
  const waves = form.fetch<AnyObject[]>(EQ_FORM_ID, entity) || [];

  return waves.length < max && hasDistances.get();
};

export const showClass = computed(() => {
  const selectedDistances = form.fetch<AnyObject[]>(EQ_FORM_ID, 'distances') || [];
  const isClassAvailable = Boolean(
    raceClassesStore.value?.filter((item) =>
      selectedDistances.some((distance) => distance.external_id?.key && distance.internal_id?.key === item.parentId),
    )?.length,
  );

  return isClassAvailable && hasDistances.get();
});

export const showWave = computed(() => {
  const selectedDistances = form.fetch<AnyObject[]>(EQ_FORM_ID, 'distances') || [];
  const isWaveAvailable = Boolean(
    raceWavesStore.value?.filter((item) =>
      selectedDistances.some((distance) => distance.external_id?.key && distance.internal_id?.key === item.parentId),
    )?.length,
  );

  return isWaveAvailable && hasDistances.get();
});

export const showCustomFieldsPlus = (entity, internalDistanceId) => {
  const max = Number(raceCustomFieldsStore.value?.filter((item) => item.parentId === internalDistanceId).length);
  const fields = form.fetch<AnyObject[]>(EQ_FORM_ID, entity) || [];

  return fields.length < max && hasDistances.get();
};

export const showCustomFields = computed(() => {
  const selectedDistances = form.fetch<AnyObject[]>(EQ_FORM_ID, 'distances') || [];
  const isCFAvailable = Boolean(
    raceCustomFieldsStore.value?.filter((cf) =>
      selectedDistances.some((distance) => distance.external_id?.key && distance.internal_id?.key === cf.parentId),
    )?.length,
  );
  return isCFAvailable && hasDistances.get();
});

import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { FC } from 'react';

import { htmlSanitizer, t } from 'utils';

type Props = {
  html: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  emptyState: {
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.5',
    fontSize: '16px',
  },
}));

export const Preview: FC<Props> = ({ html = '' }) => {
  const c = useStyles();

  if (!Boolean(html)) {
    return <Box className={c.emptyState}>{t.staticAsString('races.detail.emptyState.description')}</Box>;
  }

  return (
    <div
      className='html-preview'
      dangerouslySetInnerHTML={{
        __html: htmlSanitizer(html),
      }}
    />
  );
};

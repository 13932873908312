import { JSXElementConstructor, ReactElement, ReactNode } from 'react';

import { Result } from 'models';

import { singleFields } from '../../../constants';

import { ResultsContextType } from '../../../context';
import {
  formatStartTime,
  formatFinishTime,
  formatDiff,
  formatGender,
  formatClassId,
  formatWaveId,
  formatPlace,
  formatActivityLink,
  formatDistanceLogged,
  formatDistanceLoggedAt,
  formatCountry,
  formatDisciplineId,
  formatNationality,
} from '../../../presenters';

const FIELD_SHOW = {
  [singleFields.place]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      return formatPlace(model, context);
    };
  },

  [singleFields.start_time]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      const { value } = model;
      return formatStartTime(value.start_time, model, context);
    };
  },

  [singleFields.finish_time]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      const { value } = model;
      return formatFinishTime(value.finish_time, model, context);
    };
  },

  [singleFields.time_result]: function (model: Result, context: ResultsContextType): Function {
    return function (): string {
      const { value } = model;
      return formatDiff(value.start_time, value.finish_time, model, context);
    };
  },

  [singleFields.activity_link]: function (model: Result, context: ResultsContextType): Function {
    return function (): React.ReactNode {
      const { value } = model;
      const fieldId = model.generateFieldId('activity_link');
      const edit = context.results.actions.generateOnEdit(fieldId, 'activity_link', context);
      return formatActivityLink(value.activity_link, () => edit(fieldId));
    };
  },

  [singleFields.distance_logged]: function (model: Result, context: ResultsContextType): Function {
    return function (): string {
      const { value } = model;
      return formatDistanceLogged(value.distance_logged);
    };
  },

  [singleFields.distance_logged_at]: function (model: Result, context: ResultsContextType): Function {
    return function (): null | ReactElement<any, string | JSXElementConstructor<any>> | number | Iterable<ReactNode> | boolean {
      const { value } = model;
      return formatDistanceLoggedAt(value.distance_logged_at);
    };
  },

  [singleFields['racer.bib_number']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.racer) {
        return null;
      }
      return model.racer.value.bib_number;
    };
  },

  [singleFields['racer.firstname']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.racer) {
        return;
      }
      return model.racer.value.firstname;
    };
  },

  [singleFields['racer.lastname']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.racer) {
        return;
      }
      return model.racer.value.lastname;
    };
  },

  [singleFields['racer.union']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.racer) {
        return;
      }
      return model.racer.value.union;
    };
  },

  [singleFields['racer.gender']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.racer) {
        return;
      }
      return formatGender(model.racer.value.gender);
    };
  },

  [singleFields['racer.birthday']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.racer) {
        return;
      }
      return model.racer.value.birthday;
    };
  },

  [singleFields['racer.country_id']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.racer) {
        return;
      }
      return formatCountry(model.racer.value.country_id);
    };
  },

  [singleFields['racer.nationality_id']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.racer) {
        return;
      }
      return formatNationality(model.racer.value.nationality_id);
    };
  },

  [singleFields['racer.class_id']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.racer) {
        return null;
      }
      return formatClassId(model.racer.value.class_id, context);
    };
  },

  [singleFields['racer.wave_id']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.racer) {
        return null;
      }
      return formatWaveId(model.racer.value.wave_id, context);
    };
  },

  [singleFields['racer.discipline_id']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.racer) {
        return null;
      }
      return formatDisciplineId(model.racer.value.discipline_id, context);
    };
  },

  [singleFields['racer.email']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      return !model.racer ? null : model.racer.value.email;
    };
  },
};

export { FIELD_SHOW };

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import debounce from 'lodash/debounce';
import { observer, inject } from 'mobx-react';
import ListItems from 'modules/SportTypes/components/List/ListItems';
import * as React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import InfiniteScroll from 'react-infinite-scroller';

import { ROUTES } from 'src/constants';

import { Spinner } from 'components/Spinner';

import { history, t } from 'utils';

import { SportType as SportTypeStore, sportTypesStore, Locale as LocaleStore } from 'stores';

import { editSportType } from '../../services/editSportType';
import { loadService } from '../../services/load';

const { sportTypesRoute } = ROUTES;

@inject('sportTypesStore')
@inject('localeStore')
@observer
class List extends React.Component<{
  sportTypesStore: SportTypeStore;
  sportTypeRouterId: string | null;
  localeStore: LocaleStore;
}> {
  debouncedLoadMore: AnyFunction = debounce(() => loadService.loadMore(), 1000);

  static defaultProps = {
    sportTypesStore: null as any,
    localeStore: null as any,
  };

  componentDidMount() {
    this.debouncedLoadMore = debounce(() => loadService.loadMore(), 1000);
  }

  componentWillUnmount() {
    const { sportTypesStore } = this.props;
    sportTypesStore.clearAll();
  }

  onItemClick = (id: string) => {
    history.push(`${sportTypesRoute}/${id}`);
  };

  onAddCategory = () => {
    history.push(sportTypesRoute);
  };

  onDeleteValue = (id: number) => {
    loadService.deleteValue(id);
  };

  onSortEnd = (result: DropResult) => {
    if (!result.destination) return;

    const oldIndex = result.source.index;
    const newIndex = result.destination.index;
    const newBEIndex = sportTypesStore.values[result.destination.index].index;
    const sportType = sportTypesStore.getElementById(+result.draggableId);
    const id = sportType && sportType.id;

    if (typeof id === 'number' && typeof newIndex === 'number' && oldIndex !== newIndex) {
      editSportType.updateIndex({
        id,
        newIndex,
        oldIndex,
        newBEIndex,
      });
    }
  };

  infiniteScrollProps = () => {
    const { sportTypesStore } = this.props;
    const paginationMeta = sportTypesStore.paginationMeta;
    const currentPage = sportTypesStore.page;
    const hasMore = (paginationMeta.total_pages || 0) > currentPage;

    return {
      pageStart: 1,
      loader: <Spinner type={'cubesSpinner'} />,
      initialLoad: false,
      hasMore,
      className: 'list',
      element: 'ul',
      loadMore: this.debouncedLoadMore,
      useWindow: false,
    };
  };

  render() {
    return (
      <div className='main-list'>
        <div className='toolbar'>
          <Button className='new-sport-type' onClick={this.onAddCategory} variant='contained'>
            <AddIcon className='icon' />
            {t.staticAsString('categories.category')}
          </Button>
        </div>
        <div className='list-wrapper'>
          <InfiniteScroll {...this.infiniteScrollProps()}>
            <DragDropContext onDragEnd={this.onSortEnd}>
              <ListItems
                sportTypeRouterId={this.props.sportTypeRouterId}
                onItemClick={this.onItemClick}
                onDeleteValue={this.onDeleteValue}
              />
            </DragDropContext>
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

export { List };

import { makeStyles, styled } from '@mui/material';

export const dashboardTextStyles = {
  cardTitle: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 500,
    letterSpacing: 0.15,
  },
  metricTitle: {
    fontWeight: 700,
    fontSize: 34,
    lineHeight: '36px',
  },
  explanationTitle: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    color: '#707070',
  },
  legend: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    color: '#707070',
  },
};

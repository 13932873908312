export * from './receiptSettings';
export * from './editorSettings';

export const SHOW_TRANSLATIONS_WARNING = 'SHOW_TRANSLATIONS_WARNING';
export const SHOW_TRANSLATIONS_WARNING_FIELDS = ['languageData', 'name', 'description'];

export const LOAD_RACES = 'LOAD_RACES';
export const LOAD_RACE = 'LOAD_RACE';
export const CLEAN_RACE = 'CLEAN_RACE';
export const LOAD_FORM = 'LOAD_FORM';
export const RACE_FORM_ID = 'RACE_FORM';
export const NEW_RACE_STEPS_ARRAY: string[] = ['raceDetails', 'organizerInformation', 'receiptSettings', 'raceLanguages'];
export const CALENDAR_RACE_STEPS_ARRAY: string[] = ['raceDetails', 'organizerInformation'];
export const RACE_STEPS_ARRAY: string[] = [...NEW_RACE_STEPS_ARRAY, 'translations'];
export const ADDITIONAL_OPTIONS: string[] = [];

export const LIVE_UPDATING = 'LIVE_UPDATING';

export const RACE_TYPE = {
  calendar: 'calendar',
  raceId: 'RaceId',
};

import { Button } from '@mui/material';
import React from 'react';

import { t } from 'utils';

type Props = {
  onClick: React.MouseEventHandler;
};

const RejectBtn = ({ onClick }: Props) => (
  <Button onClick={onClick} color='error' variant='contained'>
    {t.staticAsString('races.detail.reject.title')}
  </Button>
);

export { RejectBtn };

import { map } from 'lodash';
import { convertForSelect } from 'modules/StartList/utils';
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import { MultiSelect } from 'components/Fields/MultiSelectFields';

type Props = {
  item: RacerType;
  distancesField: AnyObject;
  distance: AnyObject;
  isEdit: boolean;
  changedFields: AnyObject;
  onChange: AnyFunction;
  saveChanges: () => void;
  currentField: Array<AnyObject>;
};

@onClickOutside
class CustomMultiSelect extends React.Component<Props> {
  handleClickOutside = (evt: React.MouseEvent<HTMLElement>) => {
    if ((evt.target as any).localName !== 'li') {
      this.props.saveChanges();
    }
  };

  onChange = (e: any) => {
    const { onChange, item, distancesField } = this.props;
    const { value } = e.target;
    const distanceValues = distancesField.values.filter((field: AnyObject) => value.includes(field.id));
    let currentValue, changedValues;

    if (item.fields?.find((el) => el.field_id === distancesField.id)) {
      const values = item.fields.filter((racerField) => {
        return racerField.field_id !== distancesField.id;
      });
      currentValue = [...values, ...distanceValues];
    } else {
      currentValue = [...(item.fields || []), ...distanceValues];
    }

    changedValues = this.getChangedValues(value);

    onChange(currentValue, changedValues);
  };

  getChangedValues = (value: any) => {
    const { distancesField, changedFields } = this.props;
    const changedValue = { id: distancesField.id, values: value };

    if ((changedFields.fields || []).find((field: AnyObject) => field.id === distancesField.id)) {
      return changedFields.fields.map((field: AnyObject) => (field.id === distancesField.id ? changedValue : field));
    }

    return [...(changedFields.fields || []), changedValue];
  };

  render() {
    const { distancesField, distance, currentField } = this.props;

    const distancesFieldValues = map(distancesField.values, (fieldValue) => ({
      ...fieldValue,
      currency: distance?.race?.currency?.iso_code || '',
    }));
    const options = convertForSelect(distancesFieldValues);
    const selectedValues = currentField.map((field) => field.id);

    return (
      <MultiSelect
        value={selectedValues}
        name={distancesField.name}
        onChange={this.onChange}
        options={options}
        disabled={false}
        errors={[]}
        fullWidth
      />
    );
  }
}

export { CustomMultiSelect };

import { observer } from 'mobx-react';
import * as React from 'react';

import { SelectFilterWithSearch } from 'components/Filter/DefaultFilters';

import { countriesStore } from 'stores';

import { formatNationality } from '../presenters';

type Props = {
  value: string | number | nil;
} & FilterProps;

@observer
class NationalityIdComponent extends React.Component<Props> {
  options = () => countriesStore.modelValues.map((country) => country.forNationalitySelect());

  render() {
    const { props } = this;
    return <SelectFilterWithSearch {...props} options={this.options()} />;
  }
}

export const nationalityId: FilterListDescriptor = {
  filter: {
    persistance: {
      handler: '=',

      calcValue: function (name, value) {
        return value;
      },

      prettify: function (_name, value) {
        return formatNationality(value);
      },
    },
  },
  frontend: {
    type: 'custom',
    component: NationalityIdComponent,
  },
};

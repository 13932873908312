import { DashboardStore } from '../stores/dashboard.store';

import { DashboardActions } from './init';

export function resetDashboardStores() {
  DashboardStore.age.clear();
  DashboardStore.registrations.clear();
  DashboardStore.turnovers.clear();
  DashboardStore.participants.clear();
  DashboardStore.coupons.clear();
}

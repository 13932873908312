import isEmpty from 'lodash/isEmpty';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { Show } from 'components/Condition';

import { loadProgress } from 'stores';

import { ProgressAccordion } from './ProgressAccordion';
import { ProgressCard } from './ProgressCard';
import { LoadingRetry } from './components';
import { loadingBarStyles } from './styles';

export const LoadingBar = () => {
  const classes = loadingBarStyles();

  return (
    <Observer>
      {() => {
        const activeJobsIds = Object.keys(loadProgress.loadingJobs);

        if (!isEmpty(activeJobsIds) && !loadProgress.hasConnection) {
          return (
            <div className={classes.root}>
              <LoadingRetry onRetry={loadProgress.onRetry} />
            </div>
          );
        }

        return (
          <div className={classes.root}>
            <Show if={loadProgress.multipleLoad}>
              <ProgressAccordion jobs={loadProgress.loadingJobs} cancel={(id) => loadProgress.cancelLoading(id)} />
            </Show>
            <Show if={!loadProgress.multipleLoad}>
              {activeJobsIds.map((key) => {
                const { progress, isFinished, isError, noConnection, isCancelled, remainingSec, fileName, upload } =
                  loadProgress.loadingJobs[key];

                return (
                  <ProgressCard
                    key={key}
                    upload={upload}
                    progress={progress}
                    fileName={fileName}
                    isFinished={isFinished}
                    isError={isError}
                    noConnection={noConnection}
                    isCancelled={isCancelled}
                    timeLeft={remainingSec}
                    onRetry={loadProgress.onRetry}
                    onCancel={() => loadProgress.cancelLoading(key)}
                  />
                );
              })}
            </Show>
          </div>
        );
      }}
    </Observer>
  );
};

import { cloneDeep, pickBy } from 'lodash';
import { getOrganizer, raceFormUpdate } from 'modules/Races/utils';

import { form, sessionStore } from 'stores';

import { RACE_FORM_ID, receiptSettingsFields } from '../constants';

import { getRaceId } from '../utils';

import { organizerService } from '../services/organizer';
import receiptSettingsService from '../services/receiptSettings';

import { organizerStore, receiptSettingsStore } from '../stores';

import { receiptMapper } from '../mapper';
import { mapOrganizertoReceipt, mapReceiptToOrganizer } from '../mapper/organizerReceipt';

export const loadReceiptSettings = async () => {
  const id = getRaceId();
  if (!id) {
    return;
  }

  const [isOk, data] = await receiptSettingsService.getReceiptSettings(id);

  if (!isOk) {
    return;
  }

  receiptSettingsStore.set(data);
};

export const clearReceiptSettings = async () => {
  const id = getRaceId();
  if (!id) {
    return;
  }
  unmountReceipt();
  const emptyReceiptSettings = receiptSettingsFields.reduce((acc, setting) => ({ ...acc, [setting]: '' }), {});
  raceFormUpdate(emptyReceiptSettings);

  receiptSettingsService.updateReceiptSettings(id, emptyReceiptSettings);
};

export const unmountReceipt = async () => {
  receiptSettingsStore.clear();
};

const getOrganizerData = (): OrganizerType | nil => {
  const organizerId = form.fetch<AnyObject>(RACE_FORM_ID, 'organizer_id')?.value;
  return sessionStore.isOrganizer ? organizerStore.value : getOrganizer(organizerId);
};

export const onToggleOrganizerInfo = async ({ name, value }) => {
  const id = getRaceId();
  form.onChange(RACE_FORM_ID, name, value);

  const organizerData = getOrganizerData();

  if (value && organizerData) {
    await clearReceiptSettings();
    const formToMerge = mapOrganizertoReceipt(organizerData);
    raceFormUpdate(receiptMapper.mapFrom(formToMerge));
    receiptSettingsService.updateReceiptSettings(id, formToMerge);
  }
  if (!value) {
    await clearReceiptSettings();
  }
};

export const onChangeCheckbox = (e: React.ChangeEventHandler | any) => {
  const { name, checked } = e.target;
  form.onChange(RACE_FORM_ID, name, checked);
};

export const updateOrganizerByReceipt = async () => {
  const formData: AnyObject = cloneDeep(form.fetch(RACE_FORM_ID));
  const orginalOrganizer = cloneDeep(getOrganizerData());

  const receiptSettingsData = pickBy(formData, (value, key) => {
    return receiptSettingsFields.includes(key);
  });

  const mappedData = receiptMapper.mapToPatch(mapReceiptToOrganizer(receiptSettingsData), orginalOrganizer as any);

  await organizerService.updateOrganizer(mappedData, orginalOrganizer!.id);
};

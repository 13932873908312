import { TabScrollButton } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { withStyles } from '@mui/styles';
import * as React from 'react';

import { CheckMark } from 'components/CheckMark';
import { Show } from 'components/Condition';

import { LocaleItem } from '../GeneralTranslations/components/LocaleItem';
import { tabStyles, tabsStyles } from './styles';
import { useLabelStyles, useRootStyles, useLocaleStyles, useGradientStyles } from './styles';

type Props = {
  isFinished: (value: availableLocales) => boolean;
  locales: availableLocales[];
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
  value: number;
};

const StyledTabs = withStyles(tabsStyles)(Tabs);
const StyledTab = withStyles(tabStyles)(Tab);

export const ScrollableTab = ({ locales, isFinished, onChange, value }: Props) => {
  const classes = useRootStyles();
  const labelClasses = useLabelStyles();
  const localeClasses = useLocaleStyles();
  const gradientClasses = useGradientStyles();

  const ScrollButton = (props) => {
    return <TabScrollButton {...props} className={`${gradientClasses.root} ${gradientClasses[props.direction]}`} />;
  };

  return (
    <div className={classes.root}>
      <StyledTabs
        style={{ position: 'relative' }}
        value={value}
        onChange={onChange}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        ScrollButtonComponent={ScrollButton}
        aria-label='scrollable auto tabs'
      >
        {locales.map((locale) => (
          <StyledTab
            key={locale}
            label={
              <div className={localeClasses.root}>
                <Show if={isFinished(locale)}>
                  <CheckMark />
                </Show>
                <LocaleItem locale={locale} short className={labelClasses.label} />
              </div>
            }
          ></StyledTab>
        ))}
      </StyledTabs>
    </div>
  );
};

import DialogActions from '@mui/material/DialogActions';
import { styled, withStyles } from '@mui/styles';
import * as React from 'react';

import { Button } from 'components/core';

import { t } from 'utils';

import { closeForm, onSave } from '../../actions';

type Props = {};

const DialogActionsStyled = withStyles({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 24,
    paddingLeft: 24,
    borderTop: '1px solid #e0e0e0',
  },
  spacing: {
    '&>:not(:first-child)': {
      marginLeft: 16,
    },
  },
})(DialogActions);

export class ModalBottom extends React.Component<Props> {
  render() {
    return (
      <DialogActionsStyled>
        <Button type='cancel' onClick={closeForm}>
          {t.staticAsString('supportTaskManager.form.cancel')}
        </Button>

        <Button type='apply' onClick={onSave}>
          {t.staticAsString('supportTaskManager.form.continue')}
        </Button>
      </DialogActionsStyled>
    );
  }
}

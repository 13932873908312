import { CONFIRM_POPUP_TYPES } from 'src/constants/mixed';

import { VerifyBannerBodyText } from 'components/Banners';

import { t } from 'utils';

import { resendVerificationLinkService } from 'services';

import { confirmationModalStore, sessionStore } from 'stores';

import { awaitApproveRace } from './index';

export const handleConfirmUnverified = () => {
  const { isOrganizer, isOrganizerVerified, email: userEmail } = sessionStore;

  if (isOrganizer && !isOrganizerVerified) {
    return confirmationModalStore.openModal({
      title: t.staticAsString('banner.popUp.title') as TranslationLockedKeys,
      body: <VerifyBannerBodyText userEmail={userEmail} />,
      type: CONFIRM_POPUP_TYPES.confirm,
      btnLabel: {
        confirm: t.staticAsString('banner.verifyEmail.link') as TranslationLockedKeys,
      },
      onConfirm: onResendLink,
    });
  }

  return awaitApproveRace();
};

const onResendLink = () => {
  resendVerificationLinkService.resendLink();
};

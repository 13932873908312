import { cloneDeep, get, isNil, merge, set } from 'lodash';
import { action, observable, makeObservable } from 'mobx';

type FormData = {};

type Value = {
  [K in string]: nil | FormData;
};

interface IForm {
  value: Value;
  registerField: (id: string, name: string[] | string) => void;
  clean: (id: string) => void;
  onChange: (id: string, path: string, inputValue: any) => void;
}

// Note, storing form data
class Form implements IForm {
  @observable
  value: Value = {};

  constructor() {
    makeObservable(this);
  }

  @action
  registerField(id: string, name: string[] | string) {
    this.registerForm(id);

    const currentValue = this.fetch(id, name);

    if (isNil(currentValue)) {
      this.onChange(id, name, undefined);
    }
  }

  @action
  registerForm(id: string) {
    const currentValue = this.fetch(id);

    if (!isNil(currentValue)) {
      return;
    }

    this.value[id] = {};
  }

  @action
  clean(id: string) {
    this.value[id] = undefined;
  }

  @action
  remove(id: string, name: string) {
    // @ts-ignore
    delete this.value[id][name];
  }

  // id - form id
  // name - full nested path for value
  // inputValue - value for change
  @action
  onChange(id: string, name: string[] | string, inputValue: any) {
    const { value } = this;
    // @ts-ignore
    set(value[id], name, inputValue);
  }

  // TODO CHECK IF THIS IS ADEQUATE
  @action
  onBatchChange(id: string, data: { name: string; inputValue: any }[]) {
    const { value } = this;
    // @ts-ignore
    data.forEach(({ name, inputValue }) => set(value[id], name, inputValue));
  }

  @action
  async merge(id: string, value: Object) {
    this.value[id] = merge(this.value[id], value);
  }

  // id - form id
  // name - full nested path for value
  fetch<ExtectedValue>(id: string, name?: string | string[]): ExtectedValue {
    const { value } = this;
    if (!name) {
      return value[id] as any;
    }

    return get(value[id], name);
  }

  // When component is marked as observer
  // but component is not have to subscribe for this store
  fetchWithoutObservable<ExtectedValue>(id: string, name?: string): ExtectedValue {
    const { value } = this;
    if (!name) {
      return cloneDeep(value[id]) as any;
    }

    return get(cloneDeep(value[id]), name);
  }
}

const form = new Form();
export { Form, form };

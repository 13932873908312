import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { COUPON_URL } from 'src/constants';

import { request, t } from 'utils';

import { toastStore, couponsStore } from 'stores';

class CouponService {
  @request({ action: shortid() })
  async updateCoupon(id: number, data: AnyObject): Promise<any> {
    try {
      const response = await axios.patch<{ data: CouponType }>(generatePath(COUPON_URL, { id }), data);
      toastStore.show(t('coupons.updateSuccess'));
      couponsStore.updateValue(response.data.data);
      return response.data;
    } catch (err) {
      toastStore.show(Object.values(err.data.errors).join(' '));
    }
  }
  @request({ action: shortid() })
  async updateCouponAvailability(coupon: CouponType): Promise<any> {
    try {
      const response = await axios.patch<{ data: CouponType }>(generatePath(COUPON_URL, { id: coupon.id }), {
        disabled: !coupon.disabled,
        distances: coupon.distances?.map((d) => d.id),
      });
      toastStore.show(t('coupons.updateSuccess'));
      couponsStore.updateValue(Object.assign(coupon, { disabled: !coupon.disabled }));
      return response.data;
    } catch (err) {
      toastStore.show(Object.values(err.data.errors).join(' '));
    }
  }
}

export const couponListService = new CouponService();

import { observer } from 'mobx-react';
import * as React from 'react';

import { SelectFilterWithSearch } from 'components/Filter/DefaultFilters';

import { countriesStore } from 'stores';

import { formatCountry } from '../presenters';

type Props = {
  value: string | number | nil;
} & FilterProps;

@observer
class CountryComponent extends React.Component<Props> {
  render() {
    const { props } = this;
    return <SelectFilterWithSearch {...props} options={countriesStore.valuesForCountryDropdown()} />;
  }
}

export const countryId: FilterListDescriptor = {
  filter: {
    persistance: {
      handler: '=',

      calcValue: function (name, value) {
        return value;
      },

      prettify: function (name, value) {
        return formatCountry(value);
      },
    },
  },
  frontend: {
    type: 'custom',
    component: CountryComponent,
  },

  sort: {
    reorderOnSearch: true,
    defaultBy: 'DESC',
    calcOrderBy: () => 'country_id',
  },
};

import { Box, Chip } from '@mui/material';
import { generatePath, Link } from 'react-router-dom';
import { ColumnWithLooseAccessor } from 'react-table';

import { ROUTES } from 'src/constants';

import { Icon } from 'components/Icon';

import { t } from 'utils/t';

import { history } from '../../../../../utils';

import { handleEditLink } from '../../actions';

import { CampaignLink } from '../../../shared/types';
import { LinkComponent } from './LinkComponent';

const COLUMNS = ['edit', 'title', 'link', 'token', 'orderCount', 'linkName', 'races'];

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<CampaignLink> } = {
  edit: {
    id: 'edit',
    Cell: ({ row: { original } }) => <Icon style={{ cursor: 'pointer' }} value='edit' onClick={() => handleEditLink(original.token)} />,
  },
  title: {
    id: 'title',
    Header: t.static('campaignLinks.landingTitle'),
    accessor: (link) => link.title,
  },
  link: {
    id: 'link',
    Header: t.static('smartLinks.url'),
    disableSortBy: true,
    disableFilters: true,
    accessor: (link) => link.link ?? '—',
    Cell: ({ row: { original } }) => {
      return <LinkComponent url={original.link} />;
    },
  },

  orderCount: {
    id: 'orderCount',
    disableSortBy: true,
    disableFilters: true,
    Header: t.static('smartLinks.orderCount' as any),
    accessor: (link) => link.order_count,
  },
  linkName: {
    id: 'name',
    Header: t.static('smartLinks.name'),
    accessor: (link) => link.name ?? '—',
    Cell: ({ row: { original } }) => {
      return (
        <Link
          style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'underline' }}
          to={generatePath(ROUTES.campaignLinkDetails, { token: original.token })}
        >
          {original.name}
        </Link>
      );
    },
  },

  token: {
    id: 'token',
    Header: t.static('campaignLinks.token'),
    accessor: (link) => link.token,
  },

  races: {
    id: 'races',
    Header: t.static('smartLinks.event'),
    disableSortBy: true,
    disableFilters: true,
    accessor: (link) => link.races ?? [],
    Cell: ({ row: { original } }) => (
      <Box display='flex' gap={2}>
        {original.races.map((race) => (
          <Chip clickable onClick={() => history.push(generatePath(ROUTES.raceRoute, { id: race.id }))} key={race.id} label={race.name} />
        ))}
      </Box>
    ),
  },
};

export function generateTransactionColumns() {
  return COLUMNS.map((c) => RenderColumns[c]);
}

import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  gap: 16px;
  padding: 24px 24px 32px;

  h1 {
    font-weight: 500;
    font-size: 20px;
  }
`;

const Step = styled.div`
  display: grid;
  grid-template-columns: 20px auto;
  align-items: center;
  gap: 10px;
`;

const StepValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  font-size: 10px;
  color: white;
  background-color: ${(props) => props.theme.palette.main};
  border-radius: 50%;
`;

const StepText = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

type Props = {
  title: string;
  stepNumber: number;
  stepTitle: string;
};

export const Header: React.FC<Props> = (props) => {
  const { stepNumber, stepTitle, title } = props;

  return (
    <Wrapper>
      <h1>{title}</h1>
      <Step>
        <StepValue>{stepNumber}</StepValue>
        <StepText>{stepTitle}</StepText>
      </Step>
    </Wrapper>
  );
};

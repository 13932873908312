import { trim } from 'lodash';
import * as React from 'react';

class StringFormatter {
  withNewLine(string: string) {
    return string.split('\n').map((part) => {
      return <p key={part}>{part}</p>;
    });
  }

  /*
   * Find incomplete or invalid occurrence start from htt of https:// and
   * replace it with valid.
   * aHR0cHM6Ly95b3V0dS5iZS9sLU81SUhWaFdqMD90PTg2
   * tip b64
   */
  withHttpUrl(val: string): string {
    const url = trim(val);

    if (!url) {
      return url;
    }

    if (url.match(/^https?:\/\//)) {
      return url;
    }

    // I'ts focking tricky, and not perfect though
    // † if logic below got broken, 3 ave mariya`s should fix it †
    return url.replace(/^\s*(.*\/{2,}|\/{1,}|(http(s)?:?\/{0,})|htt|.*:\/{1,})?(.*?)\s*$/, 'https$3://$4');
  }
}

const stringFormatter = new StringFormatter();

export { stringFormatter };

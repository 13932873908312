import { RACE_STEPS_ARRAY } from '../../constants';

import { AdditionalOptions } from './AdditionalOptions';
import { BasicInformation } from './BasicInformation';
import { GeneralSettings } from './GeneralSettings';
import { OrganizerInformation } from './OrganizerInformation';
import { RaceLanguages } from './RaceLanguages';
import { ReceiptSettings } from './ReceiptSettings';
import { Translations } from './Translations';

export type raceStep =
  | 'raceDetails'
  | 'organizerInformation'
  | 'additionalOptions'
  | 'raceLanguages'
  | 'basicInformation'
  | 'translations'
  | 'receiptSettings';

const RACE_STEPS_COMPONENTS: { [k in raceStep]: any } = {
  raceDetails: GeneralSettings,
  organizerInformation: OrganizerInformation,
  additionalOptions: AdditionalOptions,
  raceLanguages: RaceLanguages,
  basicInformation: BasicInformation,
  translations: Translations,
  receiptSettings: ReceiptSettings,
};

const RACE_STEPS: { [key in raceStep]: string } = {
  raceDetails: 'raceDetails',
  organizerInformation: 'organizerInformation',
  additionalOptions: 'additionalOptions',
  raceLanguages: 'raceLanguages',
  basicInformation: 'basicInformation',
  translations: 'translations',
  receiptSettings: 'receiptSettings',
};

// Maps form field names to Steps.
// After a validation stepper will receive an array of step labels where errors happened.
const RACE_ERRORS_HASH: { [key in raceStep]: string[] } = {
  raceDetails: ['race_date_range', 'currency_id', 'sport_id', 'organizer_id', 'location', 'race_url', 'name', 'description'],
  organizerInformation: [
    'organizer_emails',
    'organizer_website',
    'organizer_name',
    'organizer_description',
    'organizer_instagram',
    'organizer_facebook',
    'organizer_linkedin',
  ],
  additionalOptions: [],
  raceLanguages: [],
  basicInformation: [],
  translations: [],
  receiptSettings: [],
};

export {
  RACE_STEPS_COMPONENTS,
  RACE_STEPS,
  RACE_STEPS_ARRAY,
  RACE_ERRORS_HASH,
  GeneralSettings,
  OrganizerInformation,
  AdditionalOptions,
  RaceLanguages,
  BasicInformation,
  Translations,
  ReceiptSettings,
};

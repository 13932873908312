import { observer } from 'mobx-react';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';

import { DRAG_IDS } from 'src/constants';

import { DNDContainer, DNDItem } from 'components/DragNDrop';
import { LoadingDots } from 'components/LoadingDots';

import { resize } from 'utils';

import { Distance } from 'models';

import { localeStore } from 'stores';

import { AddFirstDistance, AddNewDistance } from './AddNew';
import { Item } from './Item';

type Props = {
  currency: string;
  isRaceEnded?: boolean;
  onAddNew: () => void;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  onDuplicate: Function;
  onDeleteDistanceWrapper: Function;
  onLoadMore: Function;
  values: Array<Distance>;
  hasMoreDistances: boolean;
};

const DistancesWrapper = ({
  values,
  currency,
  onAddNew,
  onDragEnd,
  isRaceEnded,
  onDuplicate,
  onDeleteDistanceWrapper,
  onLoadMore,
  hasMoreDistances,
}: Props) => {
  const distances = values.map((distance, arrayIndex) => {
    const name = distance.value.name;
    const { index, id } = distance.value;
    const isLastItem = values.length - 1 === arrayIndex;

    return (
      <DNDItem blocked={resize.isLowResolution()} isDraggingClass='dragging' key={id} id={`${id}`} index={index} className='distance-dnd'>
        <Item
          distance={distance}
          currency={currency}
          key={distance.value.id}
          isRaceEnded={isRaceEnded}
          onDuplicate={() => onDuplicate(id, distance.entityCount())}
          onDelete={onDeleteDistanceWrapper(id, name)}
          locale={localeStore.value}
          isLastItem={isLastItem && hasMoreDistances}
          onVisible={() => {
            hasMoreDistances && onLoadMore();
          }}
        />
      </DNDItem>
    );
  });

  return (
    <ul className='distances-list'>
      {!values.length ? (
        <AddFirstDistance onClick={onAddNew} />
      ) : (
        <>
          <AddNewDistance key='new-distance' onClick={onAddNew} />
          <DNDContainer key='dropable-container' droppableId={DRAG_IDS.DISTANCES} onDragEnd={onDragEnd} blocked={resize.isLowResolution()}>
            {distances}
          </DNDContainer>
          {hasMoreDistances && <LoadingDots style={{ margin: '24px auto 0' }} />}
        </>
      )}
    </ul>
  );
};

const Distances = observer(DistancesWrapper);
export { Distances };

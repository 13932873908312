import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACE_INTEGRATIONS, RACE_INTEGRATION } from 'src/constants';

import { action, request } from 'utils';

import { GET_INTEGRATIONS, DELETE_INTEGRATION } from '../constants';

import { mapIntegrations } from '../utils';

import { integrationsStore, paginationStore } from '../stores';

class IntegrationsService {
  @request({ action: GET_INTEGRATIONS })
  async getIntegrationsRequest(id: number, params: { page?: number; limit?: number }): Promise<any> {
    const url = generatePath(RACE_INTEGRATIONS, { id });
    return axios.get(url, { params });
  }

  @action({ action: GET_INTEGRATIONS })
  async getIntegrations(raceId: number): Promise<any> {
    const params = {
      page: paginationStore.value?.page,
      limit: paginationStore.value?.limit,
    };

    const [status, response] = await this.getIntegrationsRequest(raceId, params);

    if (status) {
      const data = mapIntegrations(response.data.data);
      const meta = response.data.meta;
      integrationsStore.set(data);
      paginationStore.set({
        ...paginationStore.value,
        total: meta.total,
      } as any);
    }
  }

  @request({ action: DELETE_INTEGRATION })
  async deleteIntegrationRequest(raceId: number, id: number): Promise<any> {
    const url = generatePath(RACE_INTEGRATION, { raceId, id });
    return axios.delete(url);
  }

  @action({ action: DELETE_INTEGRATION })
  async deleteIntegration(raceId: number, integrationId: number): Promise<boolean> {
    const [status] = await this.deleteIntegrationRequest(raceId, integrationId);

    return status;
  }
}

export const integrationsService = new IntegrationsService();

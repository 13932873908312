import * as React from 'react';

type Params = {
  service: ILoadableService<any, any, ILoadable<any, any>>;
  loadResourcesOnResourceLoad?: boolean;
  resourcesOnly?: boolean;
  param?: string;
  cleanOnUnmount?: boolean | any;
};

// DEPRECATED
// TODO Do not reload list if loadResourcesOnResourceLoad is applied. Currenly every resource load triggering loading resources.
// WARNING, withRouter must be applied
const loadable =
  ({ service, loadResourcesOnResourceLoad = false, resourcesOnly = false, param = 'id', cleanOnUnmount = true }: Params | any) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class LoadableHOC extends React.Component<P> {
      componentDidMount() {
        this.loadResources();
        this.loadResource();
      }

      componentWillUnmount() {
        if (cleanOnUnmount) {
          service.clean();
        }
      }

      componentDidUpdate(prevProps: any) {
        if (resourcesOnly) {
          return;
        }
        const selectedValue = service.getStore().selected;
        // @ts-ignore
        if ((selectedValue && selectedValue.id + '') !== this.props.match.params[param] + '') {
          this.loadResource();
        }
        // @ts-ignore
        if (prevProps.match.params[param] !== this.props.match.params[param]) {
          this.loadResources();
        }
      }

      loadResource = (props?: any) => {
        if (resourcesOnly) {
          return;
        }
        if (!props) {
          props = this.props;
        }
        const matchParam = props.match.params[param];
        if (matchParam) {
          service.loadResource(matchParam);
        }
      };

      loadResources = () => {
        // @ts-ignore
        const matchParam = this.props.match.params[param];
        if (!matchParam) {
          service.loadResources();
        }
        if (matchParam && loadResourcesOnResourceLoad) {
          service.loadResources();
        }
      };

      render() {
        return <WrappedComponent {...this.props} />;
      }
    } as unknown as void;

export { loadable };

import { Moment } from 'moment';

import { BE_DATETIME_FORMAT } from 'src/constants/time';

import { currenciesStore } from 'stores';

import { TxTableFilters } from '../types/filters.type';

const IdFields = ['organizer.id'];
const DateFields = ['created_date', 'updated_at'];
const EqualFields = ['status', 'type'];
const LikeFields = [
  'order_id',
  'provider_transaction_id',
  'distances.translations.name',
  'distance_title',
  'distance_id',
  'race_title',
  'race_id',
  'organizer_id',
  'currency',
  'type',
  'email',
  'currency',
  'firstname',
  'lastname',
  'external_id',
];
const BetweenFields = [
  'id',
  'created_time',
  'distance.race_parent_id',
  'vat_amount',
  'custom_fields_amount',
  'discount',
  'payment_info.amount_for_custom_fields',
  'payment_info.amount',
  'total',
];
export namespace TxFiltersMappers {
  export function toBackend(filters: TxTableFilters[]) {
    const searchFilters = filters.reduce(
      (acc, { id, value }) => {
        const [start, end] = value;
        if (IdFields.includes(id)) {
          acc.search += `${id}:${value.value};`;
          acc.searchFields += `${id}:=;`;
        }
        if (DateFields.includes(id)) {
          acc.search += `${id}:${mapDaterange(value.startDate, value.endDate)};`;
          acc.searchFields += `${id}:between;`;
        }
        if (LikeFields.includes(id)) {
          if (id === 'currency') value = currenciesStore.currencyById(value)?.iso_code;
          acc.search += `${id}:${value};`;
          acc.searchFields += `${id}:like;`;
        }
        if (EqualFields.includes(id)) {
          acc.search += `${id}:${value};`;
          acc.searchFields += `${id}:=;`;
        }
        if (BetweenFields.includes(id)) {
          switch (id) {
            case 'id':
              if (start === undefined) {
                acc.search += `${id}:${end};`;
                acc.searchFields += `${id}:<=;`;
                break;
              }
              if (end === undefined) {
                acc.search += `${id}:${start};`;
                acc.searchFields += `${id}:>=;`;
                break;
              }
              if (start && end) {
                acc.search += `${id}:${value.join(',')};`;
                acc.searchFields += `${id}:between;`;
                break;
              }
            case 'total':
            case 'discount':
            case 'custom_fields_amount':
              if (start === undefined) {
                acc.search += `${id}:${end * 100};`;
                acc.searchFields += `${id}:<=;`;
                break;
              }
              if (end === undefined) {
                acc.search += `${id}:${start * 100};`;
                acc.searchFields += `${id}:>=;`;
                break;
              }
              if (start && end) {
                acc.search += `${id}:${value.map((el) => el * 100).join(',')};`;
                acc.searchFields += `${id}:between;`;
                break;
              }
            default:
              acc.search += `${id}:${value.join(',')};`;
              acc.searchFields += `${id}:between;`;
          }
        }
        return acc;
      },
      {
        search: '',
        searchFields: '',
      },
    );

    searchFilters.search = searchFilters.search.substring(0, searchFilters.search.length - 1);
    searchFilters.searchFields = searchFilters.searchFields.substring(0, searchFilters.searchFields.length - 1);
    return searchFilters;
  }
}

const mapDaterange = (start: Moment, end: Moment): string => {
  return `${start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(BE_DATETIME_FORMAT)},${end
    .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    .format(BE_DATETIME_FORMAT)}`;
};

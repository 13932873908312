import { Moment } from 'moment';

import { BE_DATETIME_FORMAT } from 'src/constants/time';

import { SynchronizationTableFilters } from '../types/filters.type';

const DateFields = ['created_at'];
const EqualFields = ['is_enabled', 'type'];
const LikeFields = ['race.translations.name', 'createdBy.full_name'];
const BetweenFields = ['id', 'race_id'];
export namespace SynchronisationFiltersMappers {
  export function toBackend(filters: SynchronizationTableFilters[]) {
    const searchFilters = filters.reduce(
      (acc, { id, value }) => {
        if (DateFields.includes(id)) {
          acc.search += `${id}:${mapDaterange(value.startDate, value.endDate)};`;
          acc.searchFields += `${id}:between;`;
        }
        if (LikeFields.includes(id)) {
          acc.search += `${id}:${value};`;
          acc.searchFields += `${id}:like;`;
        }
        if (EqualFields.includes(id)) {
          acc.search += `${id}:${value};`;
          acc.searchFields += `${id}:=;`;
        }
        if (BetweenFields.includes(id)) {
          const [start, end] = value;
          if (start === undefined) {
            acc.search += `${id}:${end};`;
            acc.searchFields += `${id}:<=;`;
          } else if (end === undefined) {
            acc.search += `${id}:${start};`;
            acc.searchFields += `${id}:>=;`;
          } else {
            acc.search += `${id}:${value.join(',')};`;
            acc.searchFields += `${id}:between;`;
          }
        }
        return acc;
      },
      {
        search: '',
        searchFields: '',
      },
    );

    searchFilters.search = searchFilters.search.substring(0, searchFilters.search.length - 1);
    searchFilters.searchFields = searchFilters.searchFields.substring(0, searchFilters.searchFields.length - 1);
    return searchFilters;
  }
}

export const sortMapToBackend = (sortBy): { orderBy: string; sortedBy: string } => {
  let orderBy = 'created_at';
  let sortedBy = 'DESC';
  if (sortBy.length > 0) {
    const { id, desc } = sortBy[0];
    orderBy = id.includes('.') ? id.split('.').join('|') : id;
    sortedBy = desc ? 'DESC' : 'ASC';
  }

  return { orderBy, sortedBy };
};

const mapDaterange = (start: Moment, end: Moment): string => {
  return `${start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(BE_DATETIME_FORMAT)},${end
    .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    .format(BE_DATETIME_FORMAT)}`;
};

import React, { FC, ComponentType, useState, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

const withoutGuestLayout =
  <P extends object>(Component: ComponentType<P>): FC<P> =>
  (...props) => {
    const [container, setContainer] = useState<HTMLElement | null>(null);

    useLayoutEffect(() => {
      const elementAuth = document.querySelector('main.auth') as HTMLElement;
      const elementBrandLogo = document.querySelector('.background-image') as HTMLElement;
      elementBrandLogo.style.display = 'none';
      elementAuth.classList.remove('auth');
      elementAuth.classList.add('terms-container');
      setContainer(elementAuth);

      return () => {
        elementBrandLogo.style.display = '';
        elementAuth.classList.remove('terms-container');
        elementAuth.classList.add('auth');
      };
    }, []);

    return container && createPortal(<Component {...(props as P)} />, container);
  };

export { withoutGuestLayout };

import { merge } from 'lodash';

import { _addErrors } from './_addErrors';
import { catchErrorsForCustomFieldValues } from './catchErrorsForCustomFieldValues';
import { catchErrorsForDistance } from './catchErrorsForDistance';
import { catchErrorsForGoal } from './catchErrorsForGoal';
import { catchErrorsForRelationWithLocale } from './catchErrorsForRelationWithLocale';
import { catchErrorsForRelation } from './catchErrorsForRelations';

function catchErrors(errors: RawError | nil) {
  const mappedErrors = merge(
    catchErrorsForRelation(errors),
    catchErrorsForDistance(errors),
    catchErrorsForRelationWithLocale(errors),
    catchErrorsForGoal(errors),
    catchErrorsForCustomFieldValues(errors),
  );

  if (!mappedErrors) {
    return;
  }

  _addErrors(mappedErrors);
}

export { catchErrors };

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const InfoMessageStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: 10,
    marginBottom: 30,
    maxWidth: 441,
    margin: '0 auto',
    width: '100%',
    fontSize: '14px',
    border: `1px solid ${theme.palette.grey4}`,
    borderLeft: `5px solid ${theme.palette.main}`,
    borderRadius: '5px',
  },
  iconWrapper: {
    display: 'inline-block',
    marginRight: '6px',
  },
  topWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  infoMessage: {
    fontWeight: 500,
    lineHeight: '24px',
  },
  infoMessageText: {
    lineHeight: '20px',
  },
}));

import * as React from 'react';

import { Loadable } from 'stores';

const withStoresClean =
  (...stores: Array<Loadable<Resource, FiltersType>>) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class WithStoresCleanHOC extends React.Component<P> {
      componentWillUnmount() {
        stores.forEach((store) => {
          if (!store || !store.clearData || !store.clearFilters || !store.removeSelectedValue) {
            return;
          }
          store.clearAll();
        });
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    } as unknown as void;

export { withStoresClean };

import { monitorLoadProgressByJobId, selectFile, t } from 'utils';

import { toastStore, errorsStore, progressStore, loadProgress } from 'stores';

import { IMPORT_RESULTS } from '../constants';

import { importService, resultsService } from '../services';

import { distanceStore } from '../stores';

import { loadNewResultAutocomplete } from './loadNewResultAutocomplete';
import { reloadDistance } from './reloadDistance';

async function execImport() {
  errorsStore.clearError(IMPORT_RESULTS);

  const distanceId = distanceStore.modelSelected?.value?.id;

  if (!distanceId) {
    return;
  }

  const file = await selectFile('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

  if (!file) {
    return;
  }

  progressStore.log(IMPORT_RESULTS, 'progress');

  const data = new FormData();
  data.append('file', file);

  const [status, response] = await importService.import(distanceId, data);

  if (!status) {
    // TODO TS definitely should be broken, please fix
    // @ts-ignore
    toastStore.show(t({ id: 'results.actions.messages.importFail' }));
    return;
  }

  const estimatedLoadingTime = file.size / 10000;

  const { id } = response.data.data;

  loadProgress.addLoadingJob(id, estimatedLoadingTime, {
    upload: true,
    fileName: file.name,
    onRetry: () => execImport(),
  });

  const [monitorStatus, jobResponse] = await monitorLoadProgressByJobId(id);
  if (monitorStatus) {
    await reloadDistance();
    await loadNewResultAutocomplete();
    await resultsService.load();
  } else {
    errorsStore.addApiError(IMPORT_RESULTS, jobResponse, 'data');
  }
}

export { execImport as onImport };

import classNames from 'classnames';
import { RACE_FORM_ID } from 'modules/Races/constants';

import { EmailChips, InputWrapper, TextField } from 'components/Form';

import { stringFormatter } from 'utils/stringFormat';
import { t } from 'utils/t';

import { form } from 'stores';

import { formStyles } from '../../styles';
import { StepTitle } from '../Shared/StepTitle';
import { StepProps } from '../sharedProps';
import { InfoMessage } from './InfoMessage';

type Props = StepProps & {
  handleDeleteContactEmail?: (id: number) => void;
};

const view = { fullWidth: true, variant: 'outlined' };

export const OrganizerInformation = ({ step, liveUpdate, handleDeleteContactEmail }: Props) => {
  const classes = formStyles();

  return (
    <>
      <StepTitle step={step} />
      <InfoMessage />
      <div className={classes.wrapper}>
        <InputWrapper
          name='organizer_emails'
          settings={{
            additional: {
              onInputChange: () => liveUpdate('organizer_emails'),
            },
            view: { ...view, helperText: t.staticAsString('races.new.emailTooltip') },
            label: t('races.new.contact_email'),
          }}
          Component={EmailChips}
        />
      </div>
      <div className={classes.wrapper}>
        <InputWrapper
          name='organizer_name'
          settings={{
            additional: {
              onBlur: (e: React.FocusEvent<HTMLInputElement>) => liveUpdate(e.target.name),
            },
            view,
            label: t('races.new.organizer_name'),
          }}
          Component={TextField}
        />
      </div>
      <div className={classes.wrapper}>
        <InputWrapper
          name='organizer_website'
          settings={{
            additional: {
              onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                let formatted: string | null = stringFormatter.withHttpUrl(e.target.value);
                if (formatted === '') formatted = null;
                form.onChange(RACE_FORM_ID, 'organizer_website', formatted);
                liveUpdate(e.target.name);
              },
            },
            view: { ...view, helperText: t.staticAsString('races.new.organizerWebsiteTooltip') },
            label: t('races.new.organizer_website'),
          }}
          Component={TextField}
        />
      </div>
      <div className={classes.wrapper}>
        <InputWrapper
          name='organizer_description'
          settings={{
            additional: {
              onBlur: (e: React.FocusEvent<HTMLInputElement>) => liveUpdate(e.target.name),
            },
            view,
            label: t('races.new.organizer_description'),
          }}
          Component={TextField}
        />
      </div>
      <div className={classes.wrapper}>
        <InputWrapper
          name='organizer_instagram'
          settings={{
            additional: {
              onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                let formatted: string | null = stringFormatter.withHttpUrl(e.target.value);
                if (formatted === '') formatted = null;
                form.onChange(RACE_FORM_ID, 'organizer_instagram', formatted);
                liveUpdate(e.target.name);
              },
            },
            view,
            label: t('races.new.organizer_instagram'),
          }}
          Component={TextField}
        />
      </div>
      <div className={classes.wrapper}>
        <InputWrapper
          name='organizer_facebook'
          settings={{
            additional: {
              onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                let formatted: string | null = stringFormatter.withHttpUrl(e.target.value);
                if (formatted === '') formatted = null;
                form.onChange(RACE_FORM_ID, 'organizer_facebook', formatted);
                liveUpdate(e.target.name);
              },
            },
            view,
            label: t('races.new.organizer_facebook'),
          }}
          Component={TextField}
        />
      </div>
      <div className={classes.wrapperDoublePadding}>
        <InputWrapper
          name='organizer_linkedin'
          settings={{
            additional: {
              onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                let formatted: string | null = stringFormatter.withHttpUrl(e.target.value);
                if (formatted === '') formatted = null;
                form.onChange(RACE_FORM_ID, 'organizer_linkedin', formatted);
                liveUpdate(e.target.name);
              },
            },
            view,
            label: t('races.new.organizer_linkedin'),
          }}
          Component={TextField}
        />
      </div>
    </>
  );
};

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { t } from 'utils/t';

type Props = {
  currentPage?: string;
};

export default function DashboardBreadcrumbs({ currentPage }: Props) {
  return (
    <Breadcrumbs
      sx={{
        pl: 4,
        pt: 3,
      }}
      separator={<NavigateNextIcon fontSize='small' />}
      aria-label='breadcrumb'
    >
      <Link key='1' color='inherit' to='/dashboard'>
        {t('sidebar.dashboard')}
      </Link>
      {currentPage && <Typography>{currentPage}</Typography>}
    </Breadcrumbs>
  );
}

import { observer } from 'mobx-react';
import DuplicateForm from 'modules/RaceDetails/containers/DuplicateForm/DuplicateForm';
import React from 'react';

import { withProgressSpinner } from 'hocs';

import { GET_RACE_DETAILS, GET_DISTANCES, DUPLICATE_ACTION } from '../constants';

import { mount, unmount } from '../actions';

import { BodyContainer } from './Body';
import { DistancesContainer } from './Distances';
import { NavContainer } from './Nav';
import { ToolbarContainer } from './Toolbar';

type Props = {};

@withProgressSpinner([GET_DISTANCES, GET_RACE_DETAILS, DUPLICATE_ACTION])
@observer
export class Base extends React.Component<Props> {
  componentDidMount() {
    mount();
  }

  componentWillUnmount() {
    unmount();
  }

  render() {
    return (
      <div className='content races-detail'>
        <div className='toolbar-wrapper'>
          <div className='navbar'>
            <NavContainer />
          </div>
          <ToolbarContainer />
        </div>
        <div className='race-content'>
          <BodyContainer />
          <DistancesContainer />
        </div>
        <DuplicateForm />
      </div>
    );
  }
}

import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { racersStore, teamsStore } from '../../stores';

const isNoResultable: IComputedValue<boolean> = computed(() => {
  return !racersStore.values.length && !teamsStore.values.length;
});

export { isNoResultable };

import { makeStyles } from '@mui/styles';
import cn from 'classnames';
import React from 'react';
import shortid from 'shortid';

import { RACE, ROUTES } from 'src/constants';

import { NavToolbar } from 'components/NavToolbar';

import { t } from 'utils';

import SavingIndicator from './SavingIndicator';

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

function Toolbar({
  breadcrumbs = [],
}: {
  breadcrumbs: Array<{
    path?: string;
    label: string | nil;
  }>;
}) {
  const classes = useStyles();
  const items = [
    { key: shortid(), path: ROUTES.racesRoute, label: t.staticAsString('races.detail.nav.allRaces') as any },
    ...breadcrumbs.map((el) => ({ ...el, key: shortid() })),
  ];
  return (
    <div className={cn('toolbar', classes.toolbar)}>
      <NavToolbar items={items} />
      <SavingIndicator action={`UPDATE_${RACE}`} />
    </div>
  );
}

export { Toolbar };

import { cloneDeep } from 'lodash';

import { Class as ClassModel, Distance } from 'models';

export type BibDistances = {
  key: number;
  value: number;
  currency: string;
  label: string;
  classes: ClassModel[];
};

export const mapBibDistances = (data: AnyObject[]): BibDistances[] => {
  const copy = cloneDeep(data);

  return copy.map((item) => {
    const model = new Distance(item as any);
    return {
      key: item.id,
      value: item.id,
      currency: model.raceCurrency(),
      label: `${item.id} ${model.name()}`,
      classes: model.classes,
    };
  });
};

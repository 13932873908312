import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import get from 'lodash/get';
import { formatCountry } from 'modules/Payments/presenters';
import { getOrderStatuses } from 'modules/exportPayment/utils';
import moment from 'moment';
import * as React from 'react';
import { generatePath, Link } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { Icon } from 'components/Icon';

import { COLUMN_WIDTH, COLUMNS, COLUMN_NAMES, GENDER_LABELS, PaymentStatus } from '../../constants';

import { Context } from '../../context';
import { ContextType } from '../../context';
import { PaymentColumns } from '../../types';

type Props = {
  item: AnyObject;
};

const Row = (props: Props) => {
  const context: ContextType = React.useContext(Context);

  const { item } = props;
  const jsonItem = item.value;

  const parseItem = (column: PaymentColumns) => {
    switch (column) {
      case COLUMN_NAMES.ref_number:
      case COLUMN_NAMES.payment_external_id:
      case COLUMN_NAMES.id:
        return (
          <Link className='label' to={generatePath(ROUTES.paymentRoute, { id: jsonItem.id })}>
            {get(jsonItem, column)}
          </Link>
        );

      case COLUMN_NAMES.created_at:
        return moment(jsonItem.created_at).format('MM/DD/YYYY');

      case COLUMN_NAMES.created_time:
        return moment(jsonItem.created_at).format('h:mm');

      case COLUMN_NAMES.distance_original_price:
        return item.priceUsed() ? `${item.priceUsed()} ${jsonItem.distances_count ? `(x${jsonItem.distances_count})` : ''}` : '';

      case COLUMN_NAMES.discount:
        return item.discountedAmount();

      case COLUMN_NAMES.custom_fields_sum:
        return item.amountForCustomFields();

      case COLUMN_NAMES.total:
        return item.totalSum();

      case COLUMN_NAMES['distance.translations.name']:
        return item.distanceName();

      case COLUMN_NAMES.distance_vat_value:
        return item.getVat();

      case COLUMN_NAMES.gender:
        return GENDER_LABELS[jsonItem.gender];

      case COLUMN_NAMES.country_id:
        return formatCountry(jsonItem.country_id);

      case COLUMN_NAMES.status:
        return getOrderStatuses().find((status) => status.key === (jsonItem.payment_status || jsonItem.status || PaymentStatus.pending))
          ?.value;

      case COLUMN_NAMES.receipt:
        if (jsonItem.receipt?.url) {
          return (
            <div className='download-cell'>
              <Icon
                onClick={context.payments.actions.generateOnExportReceipt(jsonItem.id, jsonItem.receipt?.url)}
                value='download-yellow'
              />
            </div>
          );
        }

        return null;

      default:
        return get(jsonItem, column);
    }
  };

  return (
    <TableRow>
      {COLUMNS.map((column) => (
        <TableCell key={`table-cell:${column}`} style={{ minWidth: COLUMN_WIDTH[column] }} className={classNames('cell')}>
          {parseItem(column)}
        </TableCell>
      ))}
    </TableRow>
  );
};

export { Row };

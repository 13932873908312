import { observer } from 'mobx-react';
import { OrderStatus, START_LIST_ORDER_STATUSES } from 'modules/Payments/constants';
import * as React from 'react';

import { SelectFilterWithSearch } from 'components/Filter/DefaultFilters';

import { startlistRacersStore, startlistMembersStore } from '../../stores';

type Props = {
  value: nil | string | number;
} & FilterProps;

type filterType = 'racers' | 'members';
type orderStateType = 'ready' | 'awaiting';

type Store = {
  readonly enableReadyOrder: () => Promise<void>;
  readonly disableReadyOrder: () => Promise<void>;
};

type ORDER_STATE_LABEL_KEYS = 'ready' | 'awaiting';

export const ORDER_STATE_LABEL = {
  ready: 'Ready',
  awaiting: 'Awaiting',
};

@observer
class OrderStateComponent extends React.Component<Props> {
  options = () => {
    const keys = Object.keys(ORDER_STATE_LABEL) as ORDER_STATE_LABEL_KEYS[];

    return keys.map<SelectOption>((value: ORDER_STATE_LABEL_KEYS) => {
      return {
        key: value,
        value: value,
        label: ORDER_STATE_LABEL[value],
      };
    });
  };

  render() {
    const { props } = this;
    return <SelectFilterWithSearch {...props} options={this.options()} />;
  }
}

const calcValue = (name: string, value: orderStateType) => {
  switch (value) {
    case 'ready':
      return {};
    case 'awaiting':
      return { status: 'payment_required', payment_required: 1 };
    default:
      break;
  }
};

const calcHandler = (name: string, value: orderStateType) => {
  switch (value) {
    case 'ready':
      return {};
    case 'awaiting':
      return { status: '=', payment_required: '=' };
    default:
      break;
  }
};

function store(type: filterType): Store {
  switch (type) {
    case 'racers':
      return startlistRacersStore;
    case 'members':
      return startlistMembersStore;
    default:
      return startlistRacersStore;
  }
}

function generateOrderState(type: filterType) {
  const orderState: FilterListDescriptor = {
    filter: {
      persistance: {
        advanced: {
          calcValue,
          calcHandler,
        },

        prettify: (name, value: orderStateType) => {
          return ORDER_STATE_LABEL[value];
        },
      },

      behavior: {
        apply: async (name, value: orderStateType) => {
          if (value === 'ready') {
            store(type).enableReadyOrder();
          } else {
            store(type).disableReadyOrder();
          }
        },
        delete: async () => {
          store(type).disableReadyOrder();
        },
        reset: async () => {
          store(type).disableReadyOrder();
        },
      },
    },

    frontend: {
      type: 'custom',
      component: OrderStateComponent,
    },
  };

  return orderState;
}

const state: FilterListDescriptor = {
  filter: {
    persistance: {
      advanced: {
        calcHandler: function (name, value) {
          const state = START_LIST_ORDER_STATUSES.find((item) => item.value === value);
          if (state?.key === OrderStatus.pending) {
            return { ['payment_required']: '=', ['order_status']: '=' };
          }
          return { [name]: '=' };
        },

        calcValue: function (name, value) {
          const state = START_LIST_ORDER_STATUSES.find((item) => item.value === value);
          if (state?.key === OrderStatus.pending) {
            return { ['payment_required']: 1, ['order_status']: 'null' };
          }
          return { [name]: state?.key };
        },
      },

      prettify: (name, value: orderStateType) => {
        const state = START_LIST_ORDER_STATUSES.find((item) => item.value === value);
        return state?.label;
      },
    },
  },

  frontend: {
    type: 'custom',
    component: (props: FilterProps) => <SelectFilterWithSearch {...props} options={START_LIST_ORDER_STATUSES} />,
  },
};

export { generateOrderState, state };

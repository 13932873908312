import { Input as MaterialInput, ListItem } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import * as React from 'react';

import { Icon } from 'components/Icon';

type Props = {
  fullWidth?: boolean;
  reservePlaceForHelperText?: boolean;

  classes?: Object;

  errors: Array<string>;
  options: Array<AnyObject>;
  variant?: 'standard' | 'filled' | 'outlined';

  value: string | number;
  className?: string;
  label?: string;
  id?: string;
  name: string;

  onChange: Function;
};

type State = {
  anchorEl?: HTMLElement | null;
  hoveredOption: { [Key in string]: any };
};

class SelectWithHelper extends React.Component<Props, State> {
  fieldAnchor?: HTMLElement;

  static defaultProps = {
    fullWidth: false,
    errors: [],
    label: '',
    id: '',
    className: 'form-control select',
    classes: {
      root: 'field-root',
    },
    reservePlaceForHelperText: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      anchorEl: null,
      hoveredOption: props.options[0],
    };
  }

  handleHover = (option: { [Key in string]: any }): void => {
    const { hoveredOption } = this.state;

    if (hoveredOption?.value === option.value) {
      return;
    }

    this.setState({
      hoveredOption: option,
    });
  };

  handleToggle = () => {
    if (!!this.state.anchorEl) {
      this.setState({
        anchorEl: null,
      });
      return;
    }

    this.setState({
      anchorEl: this.fieldAnchor,
    });
  };

  handleChange = (name: string, value: string): void => {
    this.props.onChange(name, value);
    this.handleToggle();
  };

  renderList = (): Array<React.ReactNode> => {
    const { options, name } = this.props;

    return options.map((item) => (
      <ListItem
        key={item.key}
        classes={{
          root: 'list-option',
        }}
        onMouseOver={() => this.handleHover(item)}
        onClick={() => this.handleChange(name, item.value)}
        dense
        button
      >
        {!!item.icon && <Icon className='dropdown-icon' value={item.icon} />}
        <Typography>{item.label}</Typography>
      </ListItem>
    ));
  };

  renderContent = () => {
    const { hoveredOption } = this.state;

    return (
      <div className='options-wrap'>
        <div className='options-list'>{this.renderList()}</div>
        <div className='helper-wrap'>
          <div className='helper-block'>
            <img src={hoveredOption?.images[0]} srcSet={`${hoveredOption?.images[1]} 2x, ${hoveredOption?.images[2]} 3x`} alt='helper' />
          </div>
          <div className='text-wrap'>
            <div className='title'>{hoveredOption?.label}</div>
            <div className='text'>{hoveredOption?.description}</div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { fullWidth, errors, className, label, options, value, name, id, reservePlaceForHelperText, variant = 'outlined' } = this.props;
    const { anchorEl } = this.state;

    const formName = id ? `${id}_${name}` : name;
    const isErr = errors.length > 0;
    const open = Boolean(anchorEl);

    const inputValue = options.find((option) => option.value === value)?.label;
    const errorMessage = errors.join(' ');

    const helperText = errorMessage || (reservePlaceForHelperText && ' ');

    return (
      <FormControl
        fullWidth={fullWidth}
        className={classNames('select-helper-container', className, { error: isErr })}
        error={isErr}
        variant={variant}
        aria-describedby='name-error-text'
      >
        <InputLabel shrink={!!value} htmlFor={formName} classes={{ root: 'form-label' }}>
          {label}
        </InputLabel>
        <MaterialInput
          classes={{
            input: 'field',
          }}
          value={inputValue}
          onClick={this.handleToggle}
          inputRef={(ref) => (this.fieldAnchor = ref)}
        />
        <Icon className={classNames('dropdown-arrow', { open })} value='dropdown_arrow' onClick={this.handleToggle} />

        <Popover
          open={open}
          onClose={() => this.handleToggle()}
          anchorEl={anchorEl}
          classes={{
            paper: 'popover-container',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {this.renderContent()}
        </Popover>

        <FormHelperText variant={variant} className='errors' id={`${formName}_error`}>
          {helperText}
        </FormHelperText>
      </FormControl>
    );
  }
}

export { SelectWithHelper };

import { ForgotPassword, IframeContainer, OutbountToken, SignIn, SignUp, Terms } from 'modules';
import { Redirect, Route } from 'react-router-dom';
import shortId from 'shortid';

import { ROUTES } from 'src/constants';

const Guest = [
  <Route key={shortId()} component={OutbountToken} path={ROUTES.setPassword} exact />,
  <Route key={shortId()} component={SignIn} path='/' exact />,
  <Route key={shortId()} component={SignUp} path={ROUTES.signUp} exact />,
  <Route key={shortId()} component={ForgotPassword} path={ROUTES.forgotPassword} />,
  <Route key={shortId()} component={Terms} path={ROUTES.processAgreement} />,
  <Route key={shortId()} component={IframeContainer} path={`${ROUTES.iframeRoot}*`} />,
  <Redirect key={shortId()} from='*' to='/' />,
];

export default Guest;

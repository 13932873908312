import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

type LabelStylesProps = {
  isInputFilled?: boolean;
};
type InputStylesProps = {
  isPrefilled?: boolean;
};

export const useFormControlStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
}));

export const useLabelStyles = makeStyles((theme: Theme) => ({
  root: (props: LabelStylesProps) =>
    props.isInputFilled
      ? {
          fontSize: 12,
          fontWeight: 'normal',
          color: 'white',
          backgroundColor: theme.palette.grey1,
          width: '133%',
          top: -3,
          height: 23,
          paddingLeft: 12,
          paddingTop: 5,

          display: 'inline-block',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }
      : {
          // No value, keep label as placeholder
          color: theme.palette.grey6,
          paddingLeft: 16,
          paddingTop: 5,
          height: 23,
          fontSize: 16,
          width: 'calc(100% - 34px)', // left space for checkmark

          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
  focused: {
    fontSize: '12px !important',
    fontWeight: 'normal',
    color: 'white !important',
    backgroundColor: theme.palette.grey1,
    width: '133% !important',
    top: -3,
    height: 23,
    paddingLeft: '12px !important',
    paddingTop: '5px !important',

    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const useInputStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.white3}`,
    padding: '0 16px',
    height: 43,
    '& .MuiInputBase-input': {
      background: (props: InputStylesProps) => (props.isPrefilled ? theme.palette.blue1 : ''),
      paddingBottom: 1,
      paddingTop: 0,
    },
  },
}));

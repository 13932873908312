import { debounce } from 'lodash';

import { SortService } from 'services';

// NOTE, Search
// UI is calling methods of this service to change searching parameters
class Search implements SearchService {
  service: ExtensionableSearchService;
  sortingService?: nil | SortService;
  defaultPage = 1;
  debouncedLoad = debounce(() => this.service.load(), 200);

  constructor(service: ExtensionableSearchService) {
    this.service = service;
  }

  async apply(callback: Function, ...searchParams: Array<any>) {
    this.service.store.page = this.defaultPage;
    let filter: any;
    for (filter of searchParams) {
      await this.callFilterHook(filter.name, filter.value, 'apply');
      await this.service.store.search.applyValue(filter.name, filter.value);
    }

    if (this.sortingService) {
      await this.sortingService.applyBasedOnSearch(searchParams);
    }

    callback && callback();
    this.debouncedLoad();
  }

  async delete(callback: Function, ...filterNames: Array<string>) {
    const { search } = this.service.store;

    for (let name of filterNames) {
      const filterValue = this.service.store.search.values[name];
      await this.callFilterHook(name, filterValue, 'delete');
    }

    await search.deleteValues(...filterNames);

    callback && callback();

    if (Object.keys(search.values).every((key) => !search.values[key])) {
      this.reset();
      return;
    }

    this.service.load();
  }

  async reset() {
    const { search } = this.service.store;

    const filterNames = Object.keys(this.service.store.search.values);

    for (let name of filterNames) {
      const filterValue = this.service.store.search.values[name];
      await this.callFilterHook(name, filterValue, 'reset');
    }

    await search.reset();

    if (this.sortingService) {
      await this.sortingService.service.store.sort.reset();
    }

    this.service.load();
  }

  async callFilterHook(name: string, value: any, hook: 'delete' | 'apply' | 'reset') {
    const descriptor = this.service.store.search.filterDescriptors[name];

    if (!descriptor) {
      return;
    }

    const filterHook = descriptor.findHook(hook);

    await (filterHook && filterHook(name, value, this));
  }

  // Integration with sorting
  // to apply sorting when searching
  integrateWithSorting(service: SortService) {
    this.sortingService = service;
  }
}

export { Search, Search as SearchService };

import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import classNames from 'classnames';
import { cloneDeep, remove } from 'lodash';
import * as React from 'react';

import { WrappedCheckbox } from 'components/Form/Fields';

type Props = FieldBaseProps & {
  additional?: TextFieldAdditionalProps & {
    options: SelectOption[];
  };
  disabled?: boolean;
};

export const MultiCheckbox = (props: Props) => {
  const { fullWidth, className, required, disabled, label, options = [] } = props?.additional || {};
  const { error, value = [], name, onChange } = props;

  const isChecked = (id: number | string): boolean => {
    return value.some((item) => item.key === id);
  };

  const handleChange = (args: { name: string; value: boolean }) => {
    if (args.value) {
      onChange({ name, value: [...value, options.find((option) => option.key === args.name)] });
    } else {
      const newValues = cloneDeep(value);
      remove(newValues, (item: SelectOption) => item.key === args.name);
      onChange({ name, value: newValues });
    }
  };

  const checkboxes = options.map((option) => (
    <WrappedCheckbox
      key={`checkbox-${option.key}`}
      name={option.key!.toString()}
      value={isChecked(option.key!)}
      onChange={handleChange}
      label={option.label as string}
      additional={{}}
      disabled={disabled}
    />
  ));

  return (
    <FormControl
      fullWidth={fullWidth}
      className={classNames('input-wrap', 'form-control', { [className || '']: !!className }, { error: !!error })}
      error={!!error}
      aria-describedby='name-error-text'
    >
      <FormLabel required={required} disabled={disabled}>
        {label}
      </FormLabel>
      <FormGroup row>{checkboxes}</FormGroup>
      <FormHelperText className='errors'>
        <>{error || ' '}</>
      </FormHelperText>
    </FormControl>
  );
};

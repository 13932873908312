import * as React from 'react';

import { Form } from 'src/components/Form';

import { PageWrapper, HeaderWrapper, Step } from '../components';

import { EQ_FORM_ID, SETTINGS_STEP, SAVE_SYNC_STEP } from '../constants';

import { mount, unmount } from '../actions';

import { SaveSync, SyncSettings } from './Steps';

export const EQForm = () => {
  React.useEffect(() => {
    mount();
    return unmount;
  }, []);

  return (
    <PageWrapper>
      <HeaderWrapper />
      <Form id={EQ_FORM_ID}>
        <Step step={SETTINGS_STEP}>
          <SyncSettings />
        </Step>
        <Step step={SAVE_SYNC_STEP}>
          <SaveSync />
        </Step>
      </Form>
    </PageWrapper>
  );
};

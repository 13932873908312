import { modalStore } from '../stores';

import { mount, unmount } from '.';
import { loadSourceDistances, loadTargetDistances } from './loadDistances';

export const openNewTaskForm = (): void => {
  loadSourceDistances();
  loadTargetDistances();

  modalStore.set({
    open: true,
  });

  mount();
};

export const closeForm = (): void => {
  modalStore.set({
    ...(modalStore.value as any),
    open: false,
  });
  setTimeout(() => {
    unmount();
  }, 500);
};

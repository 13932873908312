export const download = (url: string, fileName?: string) => {
  const link = document.createElement('a');
  link.href = url;

  if (fileName) {
    link.setAttribute('download', fileName);
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

import { Result, Distance } from 'models';

import { ResultsContextType } from '../context';

function isDNS(value: Result, distance: nil | Distance): boolean {
  if (!distance) {
    return false;
  }

  return value.isInvalidStartTime();
}

function isDNF(value: Result, distance: nil | Distance): boolean {
  if (!distance) {
    return false;
  }

  return distance.isStatusFinished() && value.isInvalidFinishTime();
}

function isDNSContext(value: Result, context: ResultsContextType) {
  const distance = context.results.stores.distanceStore.modelSelected;
  return isDNS(value, distance);
}

function isDNFContext(value: Result, context: ResultsContextType) {
  const distance = context.results.stores.distanceStore.modelSelected;
  return isDNF(value, distance);
}

export { isDNS, isDNSContext, isDNF, isDNFContext };

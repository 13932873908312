import React from 'react';

import { Icon } from 'components/Icon';

import { t } from 'utils';

type Props = {};

const InfoComponent = () => {
  return (
    <div className='container-info'>
      <Icon value='info' className='icon' />
      <p className='info'>{t.staticAsString('supportTaskManager.copy.info')}</p>
    </div>
  );
};

const Info = React.memo<Props>(InfoComponent);

export { Info };

import { loadCountryService } from 'services';

import { loadRacesNames, loadDistancesNames, loadRacers } from '../actions';

import { racersStore } from '../stores';

export const mount = async () => {
  const { page: storePage, params: storeParams } = racersStore;

  await loadCountryService.loadResources();
  await loadRacers({ page: storePage, limit: storeParams.limit });
  loadDistancesNames();
  loadRacesNames();
};

export const unmount = () => {
  racersStore.clearAll();
};

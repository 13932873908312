import { cloneDeep } from 'lodash';

import { checkPointStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { CheckPoint } from 'src/modules/Distances/components/shared/Steps/GPX/types';

export const setActiveCheckPoint = (id) => {
  const checkPoints = cloneDeep(checkPointStore.value) as Array<CheckPoint>;
  const lastIndex = checkPoints.length - 1;
  checkPoints.forEach((item, index) => {
    if (index !== lastIndex) {
      if (item.coordinate.lng === 0 && item.coordinate.lat === 0) {
        item.type = item.id === id ? 'active' : 'set';
      }
    }
  });

  checkPointStore.set(checkPoints);
};

import React from 'react';

import { RangeFilter } from 'components/Filter';

import { t } from 'utils';

import { FilterProps } from './index';

type Props = {
  value: [number | string | null, number | string | null];
} & FilterProps;

const Id = (props: Props) => {
  let value = props.value;

  if (!value) {
    value = [null, null];
  }

  return <RangeFilter {...(props as any)} label={t.staticAsString('coupons.list.table.id')} value={value} />;
};

export { Id };

import { observer } from 'mobx-react';
import * as React from 'react';

import { withProgressSpinner, ListModule, withSearch, withSorting } from 'hocs';

import { FilterLabelToolbar } from 'components/Filter/FilterLabelToolbar';
import { SubscribedPagination } from 'components/Pagination';

import { Title } from '../components';

import { ConfirmModal } from '../containers/ConfirmModal';

import { LOAD_COPY_RACERS } from '../constants';

import { mountIndex, unmountIndex } from '../actions';

import { copyRacersService } from '../services';

import { Nav } from '../../Tasks/components/Nav';
import { CopyRacersFormModal } from './New';
import { Table } from './Table';
import { Toolbar } from './Toolbar';

type OriginalProps = {};

type Props = OriginalProps & HOC.withIntl;
type State = {};

@withProgressSpinner([LOAD_COPY_RACERS])
@ListModule(copyRacersService, mountIndex, unmountIndex)
@withSorting(copyRacersService)
@withSearch(copyRacersService, 'supportTaskManager.list.fields')
@observer
class BaseImpl extends React.Component<Props, State> {
  render() {
    return (
      <div className='details-racers-lists'>
        <div className='content main-container-list racers-list support-task-manager-box'>
          <ConfirmModal />
          <CopyRacersFormModal />
          <div className='sticky-left'>
            <div className='toolbar-container'>
              <Title />
              <Toolbar />
            </div>
            <FilterLabelToolbar />
            <Nav />
          </div>
          <div className='component-list'>
            <Table />
            <SubscribedPagination />
          </div>
        </div>
      </div>
    );
  }
}

export const Base = BaseImpl as unknown as React.ComponentType<OriginalProps>;

import { cloneDeep } from 'lodash';
import moment from 'moment';

import { time } from 'utils';

import { AMOUNT_TYPE_PERCENTAGE, CREDIT_SINGLE } from '../constants';

export const mapToConfirmBody = (data: AnyObject): AnyObject => {
  const form = cloneDeep(data);

  const startAt = form.dateRange && form.dateRange.startDate ? getDate(form.dateRange.startDate) : '';
  const endAt = form.dateRange && form.dateRange.endDate ? getDate(form.dateRange.endDate) : '';
  const orderDate = form.order && form.order.orderDate ? getOrderDate(form.order.orderDate) : '';
  const bibClasses = form.bibDistances && form.bibDistances.classes ? getBibClasses(form.bibDistances.classes) : '';
  const amount = !isNaN(form.amount) ? parseFloat(form.amount).toFixed(2) : 0;

  return {
    sourceDistance: form.sourceDistance?.value,
    targetDistance: form.targetDistance?.value,
    startAt,
    endAt,
    sourceEmail: form.sourceEmail?.value || '',
    sourceEmailName: form.sourceEmail?.key || '',
    targetEmail: form.targetEmail?.value || '',
    targetEmailName: form.targetEmail?.key || '',

    orderId: form.order?.value,
    amountType: getAmountType(form),
    amount,
    firstName: form.order?.firstName,
    lastName: form.order?.lastName,
    email: form.order?.email,
    orderDate,

    distanceId: form.refundDistance?.value,
    distanceName: form.refundDistance?.distanceName,

    bibRace: form.bibRace?.label || '',
    bibDistance: form.bibDistances?.label || '',
    bibClasses,
  };
};

const getAmountType = (form: AnyObject): string => {
  const currency = form.action === CREDIT_SINGLE ? form.order?.currency : form.refundDistance?.currency;
  return form.amountType === AMOUNT_TYPE_PERCENTAGE ? '%' : currency;
};

const getDate = (date: any): string => {
  return time.workDatetime(date).formatAsString('shortDate');
};

const getOrderDate = (date: any): string => {
  return time.workDatetime(moment(date)).formatAsString('shortDate');
};

const getBibClasses = (data: any): string => {
  return data.map((item) => item.value.title).join(', ');
};

import React from 'react';

import { RangeFilter } from 'components/Filter';

import { FilterProps } from './index';

type Props = {
  value: [nil | number | string, nil | number | string];
} & FilterProps &
  any;

const DiscountAmount = (props: Props) => {
  let value = props.value;
  value = !value ? [null, null] : value;

  return <RangeFilter {...props} value={value} type={'number'} />;
};

export { DiscountAmount };

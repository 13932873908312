import { copiedLinkStore, hasOrganizerLinksStore, smartLinksFiltersStore, smartLinksStore } from '../stores';

import { hasOrganizerLinks } from './loadLinks';

export const unmount = () => {
  smartLinksStore.clear();
  copiedLinkStore.clear();
  smartLinksFiltersStore.clear();
  hasOrganizerLinksStore.off();
};

export const mount = () => {
  hasOrganizerLinks();
};

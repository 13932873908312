import { t } from 'utils';

export const selectOptionGenerator = (
  options:
    | {
        [K in string]: string;
      }
    | Array<string | number>,
  translatePrefix: string,
): Array<SelectOption> => {
  let optionsForSelect = options;

  if (!(options instanceof Array)) {
    optionsForSelect = Object.keys(options);
  }

  // $FlowFixMe
  return (optionsForSelect as any[]).map((option: any) => ({
    label: t.staticAsString(`${translatePrefix}.${option}` as TranslationLockedKeys),
    key: `${translatePrefix}.${option}`,
    value: option,
  }));
};

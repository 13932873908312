import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Editor } from '@tinymce/tinymce-react';
import classNames from 'classnames';
import * as React from 'react';

import { LOCALES, TEXT_EDITOR_BASE_FONT, TEXT_EDITOR_KEY } from 'src/constants';

import { Show } from 'components/Condition';
import { Tooltip } from 'components/Form/Fields/core';

import { htmlSanitizer, prepareFile, t } from 'utils';

import { filesService } from 'services/files';

import { localeStore } from 'stores';

import { Select } from '../Select';
import { TEXT_EDITOR_MENU, TEXT_EDITOR_PLUGINS, TEXT_EDITOR_QUICK_MENU, TEXT_EDITOR_TOOLBAR } from './constants';
import { AdditionalProps, ViewProps } from './types';

const AvailableLocales = () => [
  {
    key: LOCALES[0],
    label: t.staticAsString('locales.label.en'),
    value: LOCALES[0],
  },
  {
    key: LOCALES[1],
    label: t.staticAsString('locales.label.sv'),
    value: LOCALES[1],
  },
];

const hrefRegExp = new RegExp('href="([^"]+)"', 'g');
const withProtocol = (str: string) => {
  return str.replaceAll(hrefRegExp, (_, sub) => {
    return `href="${!/^https?:\/\//i.test(sub) ? `https://${sub}` : sub}"`;
  });
};

type Props = FieldBaseProps &
  FieldWithTooltip & {
    view: ViewProps;
    additional: AdditionalProps;
    children?: React.ReactNode;
    onChange: FieldOnChange | nil;
  };

type State = {
  language: string;
};

export class TextEditor extends React.Component<Props, State> {
  static defaultProps = {
    view: {},
    additional: {},
    label: '',
  };

  mceEditor?: AnyObject;

  state = {
    language: AvailableLocales()[0].value,
  };

  onInit = (editor: AnyObject) => {
    this.mceEditor = editor;

    editor.on('init', function () {
      editor.getContainer().className += ' editor-without-border';
    });
  };

  onChangeLanguage = ({ value }: AnyObject) => {
    this.setState({
      language: value,
    });
  };

  handleBlur = (e, editor) => {
    const {
      value,
      name,
      additional: { onBlur, onCustomBlur },
    } = this.props;
    onBlur && onBlur(e, editor);
    onCustomBlur && onCustomBlur({ name, value });
  };

  onChange = (content: string) => {
    // Certain Chrome plugin inserts this html into the Editor, purged it as it has no relation to the content.
    let trimmed = content
      .replace(
        `<div id="mouseposition-extension-element-full-container" style="position: fixed; inset: 0px; pointer-events: none; z-index: 2147483647; font-weight: 400;">
    <div id="mouseposition-extension-element-rect-display" style="display: none; position: absolute; background: rgba(255, 255, 255, 0.7); outline: black solid 1px; font-size: 12px; z-index: 2147483647; justify-content: center; align-items: center; user-select: none; cursor: default; color: #000000; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; width: 0px; height: 0px;">
    <pre style="flex: 1 1 0%; text-align: center; background-color: rgba(255, 255, 255, 0.7); color: #000000; min-width: 42px; min-height: 12px; transition: all 1s ease 0s;">&nbsp;</pre>
    </div>
    <pre id="mouseposition-extension-element-coordinate-display" style="position: absolute; display: none; background: #ffffff; font-size: 12px; line-height: 14px; border-radius: 3px; border-width: 1px; border-color: #222222 black #333333; border-style: solid; padding: 3px; z-index: 2147483647; color: #222222; user-select: none; cursor: default; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;"></pre>
    </div>`,
        '',
      )
      .trim();

    // It's possible to add bunch of spaces and empty paragraphs and apply styling to those.
    // We need to strip all HTML tags and check whether there's actual content and replace everything to empty string if not.
    if (trimmed.replace(/<(?!img|table|iframe|blockquote|li)[^>]*>?/gm, '').trim().length === 0) {
      trimmed = '';
    }

    trimmed.replaceAll('/^https?:///i', 'h');

    const {
      name,
      onChange,
      additional: { withLanguageSelector },
      value,
    } = this.props;
    const { language } = this.state;
    const newValue = withLanguageSelector ? { ...value, [language]: trimmed } : trimmed;

    onChange({ name, value: htmlSanitizer(withProtocol(newValue)) });
  };

  render() {
    const {
      name,
      value,
      disabled,
      view,
      additional: { placeholder, onCustomBlur, onBlur, withLanguageSelector, previewMode, ...additionalProps },
      className,
      tooltip,
      error,
      label,
    } = this.props;
    const { language } = this.state;

    let displayValue = value || '';
    if (value && withLanguageSelector) displayValue = value[language] || '';

    const initObj = previewMode
      ? {
          placeholder,
          oninit: this.onInit,
          setup: this.onInit,
          toolbar: false,
          menubar: false,
          branding: false,
          valid_styles: {
            span: 'text-decoration',
            table: 'width, height',
          },
          images_upload_handler: async (blobInfo, succ, err, _progress) => {
            const errorMessage: string = t.staticAsString('textEditor.imageTooBig');
            const SIZE_5_MB: Number = 5e6;
            if (blobInfo.blob().size > SIZE_5_MB) {
              return err(errorMessage, { remove: true });
            }

            const submitData = prepareFile(blobInfo.blob());
            const resp = await filesService.upload(submitData);

            if (!resp.isOk) {
              return err(resp.message, { remove: true });
            }

            succ(resp.url!);
          },
        }
      : {
          entity_encoding: 'raw',
          toolbar_mode: 'sliding',
          placeholder,
          oninit: this.onInit,
          setup: this.onInit,
          force_br_newlines: true,
          force_p_newlines: false,
          keep_styles: false,
          content_style: TEXT_EDITOR_BASE_FONT,
          toolbar: TEXT_EDITOR_TOOLBAR,
          plugins: TEXT_EDITOR_PLUGINS,
          menubar: TEXT_EDITOR_MENU,
          quickbars_selection_toolbar: TEXT_EDITOR_QUICK_MENU,
          branding: false,
          valid_styles: {
            span: 'text-decoration',
            table: 'width, height',
          },
          images_upload_handler: async (blobInfo, succ, err, _progress) => {
            const errorMessage: string = t.staticAsString('textEditor.imageTooBig');
            const SIZE_5_MB: Number = 5e6;
            if (blobInfo.blob().size > SIZE_5_MB) {
              return err(errorMessage, { remove: true });
            }

            const submitData = prepareFile(blobInfo.blob());
            const resp = await filesService.upload(submitData);

            if (!resp.isOk) {
              return err(resp.message, { remove: true });
            }

            succ(resp.url!);
          },
        };

    return (
      <FormControl
        fullWidth
        variant='outlined'
        className={classNames('input-wrap', 'form-control', { [className || '']: !!className }, { error: !!error })}
        error={!!error}
        aria-describedby='name-error-text'
      >
        <Show if={!!withLanguageSelector}>
          <Select
            label={t.staticAsString('textEditor.selector.label')}
            name={`language-${name}`}
            options={AvailableLocales()}
            value={language}
            onChange={this.onChangeLanguage}
          />
        </Show>
        <div className='editor-border'>
          <Show if={Boolean(label)}>
            <Typography className='text-editor-label'>{label}</Typography>
          </Show>
          <Editor
            key={`key-${localeStore.value}`}
            {...view}
            {...(additionalProps as any)}
            init={initObj}
            apiKey={TEXT_EDITOR_KEY}
            id={name}
            value={displayValue}
            disabled={disabled || previewMode}
            onBlur={this.handleBlur}
            onEditorChange={this.onChange}
          />
        </div>
        {tooltip && <Tooltip {...tooltip} error={error} />}
        <FormHelperText className='errors'>
          <>{error || ' '}</>
        </FormHelperText>
      </FormControl>
    );
  }
}

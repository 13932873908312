class Discipline {
  value: ClassType;

  constructor(value: ClassType) {
    this.value = value;
  }

  forSelect(): SelectOption {
    const id = `${this.value.id || ''}`;

    return {
      label: this.value.title,
      value: id,
      key: id,
    };
  }

  label(): string {
    return this.value.title;
  }
}

export { Discipline };

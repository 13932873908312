import { observer } from 'mobx-react';
import * as React from 'react';

import { SearchContext } from 'hocs';

import { Label } from './Label';
// SUBSCRIBER for sort context
import { FilterLabelWrapper, LabelWrapper } from './LabelWrapper';

type Props = {
  label: React.ReactNode;
  name: string;
  className: string;
  width?: number;
  dynamicSortOptions?: Object;
};

// NOTE, search
// Subscribed for search context
@observer
class TableHeaderItem extends React.Component<Props> {
  static contextType = SearchContext;
  declare context: React.ContextType<typeof SearchContext>;

  RenderWrapper = ({ children }: { children: React.ReactNode }) => {
    const { className, width } = this.props;
    const styles = !width ? {} : { width };

    if (!this.context.search) {
      return (
        <LabelWrapper style={styles} className={className}>
          {children}
        </LabelWrapper>
      );
    }

    const disabled = this._isFieldDisabled();
    const active = this._isFieldActive();

    return (
      <FilterLabelWrapper className={className} onClick={this._onFilterClick} disabled={disabled} active={active} style={styles}>
        {children}
      </FilterLabelWrapper>
    );
  };

  _onFilterClick = (e: React.SyntheticEvent) => {
    const { name } = this.props;

    if (!this.context.search) {
      return;
    }

    this.context.search.onFilterShow(e, name);
  };

  _isFieldDisabled = (): boolean => {
    const { name } = this.props;

    if (!this.context.search) {
      return true;
    }

    const descriptor = this.context.search.store.filterDescriptors[name];

    if (!descriptor) {
      return true;
    }

    return !descriptor.isHandlingByFrontend();
  };

  _isFieldActive = (): boolean => {
    const { name } = this.props;

    if (!this.context.search) {
      return true;
    }

    return this.context.search.store.isFEActive(name);
  };

  render() {
    const { label, name, dynamicSortOptions } = this.props;

    // eslint-disable-next-line
    this.context.search?.store?.values[name];

    const { RenderWrapper } = this;
    return (
      <RenderWrapper>
        {/*NOTE, Subscriber for sort context*/}
        <Label label={label} name={name} dynamicSortOptions={dynamicSortOptions} isSearchEnabled={!this._isFieldDisabled()} />
      </RenderWrapper>
    );
  }
}

export { TableHeaderItem };

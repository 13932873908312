import { difference } from 'lodash';
import * as React from 'react';

import {
  DISABLED_BY_DEFAULT_REGISTRATION_FIELDS,
  HIDDEN_FOR_TEAM_FIELDS,
  REGISTRATION_FIELDS,
  RegistrationFields as RegistrationFieldsEnum,
  SELECTED_BY_DEFAULT_REGISTRATION_FIELDS,
} from 'src/constants';

import { Race as RaceModel } from 'models';

import { State as OriginalFormState } from '../../../../shared/stateHelpers';
import { Headers } from './Headers';
import { Item } from './Item';

type Props = {
  formData: OriginalFormState;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
    isDirty?: boolean,
  ) => void;
  errors: {
    [K in string]: Array<string>;
  };
  helperData: {
    race: nil | RaceModel;
  };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

const HIDDEN_FIELDS = {
  coupon_code: ['required'],
};

// for adding tooltip add field name to the list below and translation with key starting with: 'distances.steps.registrationFieldsForm.description.' + name of field
const TOOLTIPED_FIELDS = ['squad_id'];

class RegistrationFields extends React.Component<Props> {
  fieldsRef: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.fieldsRef = React.createRef<any>();
  }

  componentDidMount() {
    this.handleScroll();
  }

  handleScroll = () => {
    const formScroll = document.getElementsByClassName('main-distance-form')[0];
    this.fieldsRef.current.scrollIntoView({ block: 'start' });
    formScroll.scrollTop = 0;
  };

  onChange = (fields: Array<RegistrationField>) => {
    const { onChange, liveUpdate } = this.props;

    liveUpdate({ registrationFields: fields });
    onChange(
      {
        registrationFields: fields,
      },
      '',
      () => {},
      false,
    );
  };

  onChangeRegistrationField = (fieldName: string, changedField: RegistrationField) => {
    const { registrationFields } = this.props.formData;

    const newData = registrationFields.map((field) => (field.name === fieldName ? changedField : field));

    this.onChange(newData);
  };

  _renderRegistrationFields = (): React.ReactNode => {
    return this._selectFieldsForRender().map((field) => {
      const isDisabled = DISABLED_BY_DEFAULT_REGISTRATION_FIELDS.includes(field.name);
      const isFieldHiddenForTeam = HIDDEN_FOR_TEAM_FIELDS.includes(field.name);
      const distanceType = this.props.formData?.distance?.type;
      const isHiddenForTeamDistance = distanceType ? distanceType === 'team' : true;
      const isTooltip = TOOLTIPED_FIELDS.includes(field.name);

      return isFieldHiddenForTeam && isHiddenForTeamDistance ? (
        <div />
      ) : (
        <Item
          value={field}
          onChange={this.onChangeRegistrationField}
          hiddenFields={HIDDEN_FIELDS}
          disabled={isDisabled}
          tooltip={isTooltip}
          key={field.id}
        />
      );
    });
  };

  _selectFieldsForRender = (): Array<RegistrationField> => {
    return this.props.formData.registrationFields;
  };
  render() {
    return (
      <div className='registration-fields-form form-content-wrapper' ref={this.fieldsRef}>
        <div className='registration-fields-list'>
          <Headers />
          {this._renderRegistrationFields()}
        </div>
        {this.props.controls}
      </div>
    );
  }
}

const generateRegistrationFields = (): Array<RegistrationField> => {
  return REGISTRATION_FIELDS.map((field) =>
    _getRegistrationField(field, SELECTED_BY_DEFAULT_REGISTRATION_FIELDS.includes(field) || RegistrationFieldsEnum.coupon_code === field),
  );
};

const generateRegistrationFieldsBasedOnData = (formData: OriginalFormState): Array<RegistrationField> => {
  const usedValues = formData.registrationFields.map((value) => value.name);

  const valuesToDelete = difference(usedValues, REGISTRATION_FIELDS);
  const valuesToCreate = difference(REGISTRATION_FIELDS, usedValues);

  let newValues = formData.registrationFields.map((value) => {
    if (valuesToDelete.includes(value.name)) {
      return { ...value, _delete: true };
    }

    return value;
  });

  valuesToCreate.forEach((name) => {
    newValues.push(_getRegistrationField(name));
  });

  return _sortRegistrationFields(newValues);
};

const _getRegistrationField = (name: string | any, isDefault: boolean = false): RegistrationField => {
  if (isDefault && [RegistrationFieldsEnum.coupon_code, RegistrationFieldsEnum.union].includes(name)) {
    return {
      title: '',
      name,
      selected: 1,
      required: 0,
    };
  }

  if (isDefault) {
    return {
      title: '',
      name,
      selected: 1,
      required: 1,
    };
  }

  return {
    title: '',
    name,
    selected: 0,
    required: 0,
  };
};

const _sortRegistrationFields = (values: Array<RegistrationField>): Array<RegistrationField> => {
  return values.sort((prevVal, nextVal) => REGISTRATION_FIELDS.indexOf(prevVal.name) - REGISTRATION_FIELDS.indexOf(nextVal.name));
};

export { RegistrationFields, generateRegistrationFields, generateRegistrationFieldsBasedOnData };

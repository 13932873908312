import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Popover, TablePagination, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { debounce, omit } from 'lodash';
import { Observer } from 'mobx-react';
import { DefaultColumnFilter, FilterLabelToolbar } from 'modules/Synchronisation/Table/components';
import { SynchronizationsConstants } from 'modules/Synchronisation/Table/constants/synchronizations.constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';

import { Show } from 'components/Condition';
import { Spinner } from 'components/Spinner';

import { t } from 'utils/t';

import { progressStore } from 'stores';

const LabelWrapper = styled(Box)(() => ({
  '&:hover': {
    '& .icon': {
      opacity: 1,
      visibility: 'visible',
    },
  },
}));

const SynchronizationsTable = ({
  fetchData,
  tableMeta,
  tableColumns,
  tableData,
  initialSizeOfPage,
  pageSizeOptions,
  initSort,
}): JSX.Element => {
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => tableData, [tableData]);

  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    state: { sortBy, filters, pageIndex, pageSize },
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    setPageSize,
    pageOptions,
    gotoPage,
    setAllFilters,
  } = useTable(
    {
      columns,
      defaultColumn,
      data,
      initialState: {
        pageSize: 30,
        sortBy: initSort,
      },
      manualFilters: true,
      manualSortBy: true,
      manualPagination: true,
      pageCount: tableMeta.pagination?.total ?? 0,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const debouncedCallback = useCallback(
    debounce((props) => fetchData(props), 500),
    [],
  );

  useEffect(() => {
    if (!isFirstRender) {
      debouncedCallback({ pageIndex, pageSize, filters, sortBy });
    }
  }, [pageIndex, pageSize, filters, sortBy]);

  const buildTBody = () => {
    if (!progressStore.isLoading(SynchronizationsConstants.SynchronizationsTable) && rows.length === 0) {
      return (
        <tr>
          <td className='cell' colSpan={8}>
            <Typography variant='h5' className={'table-no-content'}>
              {t.staticAsString('reconciliation.reports.noDataAvailable')}
            </Typography>
          </td>
        </tr>
      );
    }

    return rows.map((row) => {
      prepareRow(row);
      return (
        <tr className='row' {...row.getRowProps()}>
          {row.cells.map((cell) => {
            return (
              <td
                className='cell'
                {...cell.getCellProps({
                  style: {
                    minWidth: cell.column.minWidth,
                    width: cell.column.width,
                  },
                })}
              >
                {cell.render('Cell')}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <Observer
      render={() => {
        return (
          <>
            {progressStore.isLoading(SynchronizationsConstants.SynchronizationsTable) && (
              <>
                <Box
                  sx={{ backgroundColor: 'white', height: '100%', width: '100%', zIndex: 10, opacity: '0.5', position: 'absolute' }}
                ></Box>
                <Box sx={{ zIndex: 11, position: 'absolute', width: '100%' }}>
                  <Spinner type={'cubesSpinner'} />
                </Box>
              </>
            )}
            <Box sx={{ padding: ' 8px 24px' }}>
              <FilterLabelToolbar filters={filters} setAllFilters={setAllFilters} />
            </Box>
            <div className='standart-table simple sync-table'>
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

                        const handleClick = (event) => {
                          setAnchorEl(event.currentTarget);
                        };

                        const handleClose = () => {
                          setAnchorEl(null);
                        };

                        const open = Boolean(anchorEl);
                        const id = open ? 'simple-popover' : undefined;
                        return (
                          <th
                            {...column.getHeaderProps({
                              style: { minWidth: column.minWidth, width: column.width },
                            })}
                          >
                            <div {...omit(column.getHeaderProps(), 'title')}>
                              <LabelWrapper sx={{ display: 'flex', alignItems: 'center', height: 28 }}>
                                <span {...(column.canFilter && { onClick: handleClick, style: { cursor: 'pointer' } })}>
                                  {column.render('Header')}
                                </span>
                                <span>
                                  <Show if={column.canSort}>
                                    <ArrowUpwardIcon
                                      className={column.isSorted ? '' : 'icon'}
                                      sx={{
                                        marginLeft: '12px',
                                        cursor: 'pointer',
                                        fontSize: '14px',
                                        transition: 'transform 0.2s linear, opacity 0.2s linear, visibility 0.2s linear;',
                                        ...(column.isSorted
                                          ? column.isSortedDesc
                                            ? { visibility: 'visible', opacity: 1, transform: 'rotate(180deg)' }
                                            : { visibility: 'visible', opacity: 1 }
                                          : {
                                              visibility: 'hidden',
                                              opacity: 0,
                                              transition: 'transform 0.2s linear, opacity 0.2s linear, visibility 0.2s linear;',
                                            }),
                                      }}
                                      {...column.getSortByToggleProps()}
                                    />
                                  </Show>
                                </span>
                              </LabelWrapper>
                            </div>
                            <Popover
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                            >
                              <div>{column.canFilter ? column.render('Filter', { closeFilter: handleClose }) : null}</div>
                            </Popover>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>{buildTBody()}</tbody>
              </table>
              <TablePagination
                component='div'
                sx={{ position: 'sticky', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                count={pageOptions.length}
                page={pageIndex}
                rowsPerPageOptions={pageSizeOptions}
                onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
                  if (progressStore.isLoading(SynchronizationsConstants.SynchronizationForm)) return;
                  gotoPage(newPage);
                }}
                rowsPerPage={pageSize}
                onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setPageSize(Number(event.target.value))
                }
              />
            </div>
            <div style={{ height: 50 }} />
          </>
        );
      }}
    />
  );
};
export { SynchronizationsTable };

export interface PaymentsResponse {
  data: PaymentType[];
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

export interface PaymentType {
  id: number;
  created_date: string;
  created_time: string;
  updated_date: string;
  updated_time: string;
  external_id: string;
  currency: string;
  email: string;
  distance_title: string;
  firstname: string;
  lastname: string;
  status: number;
  total: number;
  full_name: string;
  type: number;
  discount: number;
  receipt: Receipt;
}

export interface Receipt {
  uuid: string;
  url: string;
  size: number;
  name: string;
}

export enum TransactionTypeEnum {
  AUTHORIZATION = 2,
  CAPTURE = 3,
  REFUNDED = 4,
}

export enum StatusTypeEnum {
  PENDING = 1,
  COMPLETED = 2,
  FAILED = 3,
}

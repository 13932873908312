import { computed, observable, action, makeObservable, override } from 'mobx';

import { SearchSerializer } from 'utils';

import { Loadable, Sort, Search } from 'stores';

import { Order as OrderModel } from '../models';

import { filterDescriptors } from '../filters';
import { OrderType } from '../types';

const sortStore = new Sort(filterDescriptors);
const searchStore = new Search(filterDescriptors);

class Payment extends Loadable<OrderType, FiltersType> {
  defaultFilters: FiltersType = {
    limit: 30,
    orderBy: 'id',
    sortedBy: 'DESC',
  };

  declare filters: FiltersType;

  declare page;

  declare paginationMeta: PaginationMeta;

  declare resourceParams: FiltersType;

  @observable
  handlers = {
    id: 'between',
    payee_reference: 'like',
    created_at: 'between',
    'racer.email': 'like',
    'racer.full_name': 'like',
    price_used: 'between',
    discounted_amount: 'between',
    amount_for_custom_fields: 'between',
    amount: 'between',
    state: 'like',
  };

  static allowBlank = [];

  sort = sortStore;
  search = searchStore;
  extensions = [this.sort, this.search];

  constructor() {
    super();

    makeObservable(this);
    this.filters = this.defaultFilters;
    this.resourceParams = {
      with: 'racer.fields;distance',
    };
    this.page = 1;
    this.paginationMeta = {};
  }

  @computed
  get modelValues(): Array<OrderModel> {
    return this.values.map((race) => new OrderModel(race));
  }

  @computed
  get modelSelected(): nil | OrderModel {
    if (this.selected) {
      return new OrderModel(this.selected);
    } else {
      return null;
    }
  }

  @override
  addPaginationMeta(meta: PaginationMeta) {
    this.paginationMeta = meta;
  }

  @action
  updateSearch(search: string, searchFields: string): void {
    if (!search) {
      this.filters.search = null as any;
      this.filters.searchFields = null as any;
      delete this.filters.search;
      delete this.filters.searchFields;
    } else {
      this.filters.search = search;
      this.filters.searchFields = searchFields;
    }
  }

  @action
  updateSearchHandlers(
    newHandlers: {
      [K in string]: string;
    },
  ) {
    this.handlers = {
      ...this.handlers,
      ...newHandlers,
    };
  }

  @action
  resetFilters(): void {
    this.clearFilters();
  }

  @computed
  get searchDataModel(): SearchSerializer {
    const search = this.filters.search as any;
    return new SearchSerializer(search, this.handlers, Payment.allowBlank);
  }

  retrieveSearchDataModel(): SearchSerializer {
    return this.searchDataModel.clone();
  }

  @override
  clearAll() {
    super.clearAll();
    this.filters = this.defaultFilters;
  }
}

export { Payment };

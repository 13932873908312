import { t } from 'utils';

import { AMOUNT_TYPE_FIXED, AMOUNT_TYPE_PERCENTAGE } from '../constants';

export const creditDistance = (): any => {
  const presence = {
    allowEmpty: false,
    message: t.staticAsString('validation.required'),
  };

  return {
    refundDistance: {
      presence,
    },

    amountType: {
      presence,
    },

    deleteParticipants: {
      presence,
    },

    amount: {
      presence,
      equality: {
        attribute: 'amountType',
        message: function (value, attribute, validatorOptions, attributes) {
          if (attributes.amountType === AMOUNT_TYPE_PERCENTAGE) {
            return t.staticAsString('supportTaskManager.form.validation.recentBetween');
          }

          return t.staticAsString('supportTaskManager.form.validation.amountIsWrong');
        },
        comparator: function (amount, amountType) {
          const PERCENT_MAX = 101;
          const PERCENT_MIN = 0;

          if (amountType === AMOUNT_TYPE_PERCENTAGE) {
            return amount > PERCENT_MIN && amount < PERCENT_MAX;
          }

          if (amountType === AMOUNT_TYPE_FIXED) {
            return !isNaN(amount) && parseInt(amount) > 0;
          }

          return true;
        },
      },
    },
  };
};

import {
  CommissionWrapper,
  CommissionsWrapper,
  DashboardAge,
  DashboardParticipants,
  DashboardRegistrations,
  DashboardTurnover,
  Distances,
  OrganizerProfile,
  Races,
  ReportWrapper,
  Results,
  StartLists,
  SystemUsers,
  Terms,
  TransactionWrapper,
  CampaignLinks,
  TransactionsWrapper,
} from 'modules';
import DeleteRedirector from 'modules/SystemUsers/DeleteRedirector';
import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import shortId from 'shortid';

import { ROUTES } from 'src/constants';

import { UpsellWrapper } from 'components/Upsell';

const {
  systemUsersDeleteRoute,
  dashboardRoute,
  dashboardPaymentsRoute,
  dashboardTurnoverRoute,
  dashboardParticipantsRoute,
  dashboardAgeRoute,
  raceWithOptionalParamRoute,
  paymentsWithOptionalParamRoute,
  couponsRoute,
  profileRoute,
  distancesRacersRoute,
  distancesTeamsRoute,
  distancesMembersRoute,
  systemUserWithOptionalParamRoute,
  distancesRoute,
  resultsRoute,
  racersRoute,
  signUp,
  smartLinks,
  processAgreement,
  transactionRoute,
  transactionsRoute,
  commissionsRoute,
  commissionRoute,
  campaignLinks,
  reportsRoute,
  reportRoute,
} = ROUTES;

const Organizer = [
  <Route key={shortId()} exact path={systemUsersDeleteRoute} component={DeleteRedirector} />,
  <Route key={shortId()} exact path={transactionsRoute} component={TransactionsWrapper} />,
  <Route key={shortId()} exact path={transactionRoute} component={TransactionWrapper} />,
  <Route key={shortId()} exact path={commissionRoute} component={CommissionWrapper} />,
  <Route key={shortId()} exact path={commissionsRoute} component={CommissionsWrapper} />,
  <Route key={shortId()} exact path={reportsRoute} render={() => <UpsellWrapper page={'reports'} />} />,
  <Route key={shortId()} exact path={reportRoute} component={ReportWrapper} />,
  <Route key={shortId()} path={dashboardPaymentsRoute} component={DashboardRegistrations} />,
  <Route key={shortId()} exact path={dashboardTurnoverRoute} component={DashboardTurnover} />,
  <Route key={shortId()} exact path={dashboardParticipantsRoute} component={DashboardParticipants} />,
  <Route key={shortId()} exact path={dashboardAgeRoute} component={DashboardAge} />,
  <Route key={shortId()} exact path={dashboardRoute} render={() => <UpsellWrapper page={'dashboard'} />} />,
  <Route key={shortId()} path={distancesRacersRoute} component={StartLists} />,
  <Route key={shortId()} path={distancesTeamsRoute} component={StartLists} />,
  <Route key={shortId()} path={distancesMembersRoute} component={StartLists} />,
  <Route key={shortId()} path={resultsRoute} component={Results} />,
  <Route key={shortId()} path={racersRoute} render={() => <UpsellWrapper page={'racers'} />} />,
  <Route key={shortId()} path={distancesRoute} component={Distances} />,
  <Route key={shortId()} path={raceWithOptionalParamRoute} component={Races} />,
  <Route key={shortId()} path={couponsRoute} render={() => <UpsellWrapper page={'coupons'} />} />,
  <Route key={shortId()} path={profileRoute} component={OrganizerProfile} />,
  <Route key={shortId()} path={systemUserWithOptionalParamRoute} component={SystemUsers} />,
  <Route key={shortId()} path={paymentsWithOptionalParamRoute} render={() => <UpsellWrapper page={'transactions'} />} />,
  <Route key={shortId()} path={smartLinks} render={() => <UpsellWrapper page={'smartRegistrationLinks'} />} />,
  <Route key={shortId()} path={campaignLinks} component={CampaignLinks} />,

  <Redirect key={shortId()} from={signUp} exact to={raceWithOptionalParamRoute} />,
  <Route key={shortId()} component={Terms} path={processAgreement} />,
  <Redirect key={shortId()} from={'*'} exact to={raceWithOptionalParamRoute} />,
];

export default Organizer;

import { Autocomplete, Chip, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactElement } from 'react';

export const useStyles = makeStyles({
  customMinWidth: {
    minWidth: 200,
  },
});

type Props = FieldBaseProps & {
  additional?: TextFieldAdditionalProps;
  view?: BaseViewProps;
  children?: React.ReactNode;
  onChange: FieldOnChange | nil;
};

function EmailChips(props: Props): ReactElement {
  const { view = {}, additional = {}, name, value = [], label, error } = props;
  const { reservePlaceForHelperText, helperText, ...viewProps } = view;

  const [inputValue, setInputValue] = React.useState('');

  const handleUpdateChip = (e, value) => {
    props.onChange({ name, value });
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (inputValue === '') {
      return;
    }
    const newValue = [...value, inputValue];
    handleUpdateChip(e, newValue);
    setInputValue('');
  };

  const handleSpacePress = (e: React.KeyboardEvent) => {
    if (inputValue === '') {
      return;
    }
    if (e.key === ' ') {
      const newValue = [...value, inputValue];
      handleUpdateChip(e, newValue);
      setInputValue('');
    }
  };

  const hText = error || helperText || (reservePlaceForHelperText && ' ');

  const withError = !!error;

  return (
    <Autocomplete
      onKeyDown={handleSpacePress}
      multiple
      id={name}
      options={[]}
      freeSolo
      inputValue={inputValue}
      renderTags={(value: string[], getTagProps) =>
        value.map((option: string, index: number) => <Chip variant='outlined' label={option} {...getTagProps({ index })} />)
      }
      onInputChange={(e: React.ChangeEvent<HTMLInputElement>, data) => {
        setInputValue(data.trim());
      }}
      value={value || []}
      onChange={handleUpdateChip}
      onBlur={onBlur}
      renderInput={(params) => (
        <TextField name={name} {...(params as any)} {...viewProps} error={withError} helperText={hText} label={label} />
      )}
    />
  );
}

export { EmailChips };

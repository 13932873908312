export const DELETE_RACE = 'DELETE_RACE';
export const REJECT_RACE = 'REJECT_RACE';
export const APPROVE_RACE = 'APPROVE_RACE';
export const LOAD_MORE_DISTANCES = 'LOAD_MORE_DISTANCES';
export const GET_DISTANCES = 'GET_DISTANCES';
export const DUPLICATE_RACE = 'DUPLICATE_RACE';
export const DELETE_DISTANCE = 'DELETE_DISTANCE';
export const UPDATE_DISTANCE = 'UPDATE_DISTANCE';
export const GET_RACE_DETAILS = 'GET_RACE_DETAILS';
export const HIDE_RESULTS_RACE = 'HIDE_RESULTS_RACE';
export const DUPLICATE_DISTANCE = 'DUPLICATE_DISTANCE';
export const DUPLICATE_ACTION = 'DUPLICATE_ACTION';
export const DUPLICATE_FORM = 'DUPLICATE_FORM';
export const TOGGLE_PUBLISH_RACE = 'TOGGLE_PUBLISH_RACE';
export const HIDE_START_LIST_RACE = 'HIDE_START_LIST_RACE';
export const AWAITING_APPROVE_RACE = 'AWAITING_APPROVE_RACE';
export const TOGGLE_REGISTRATION_RACE = 'TOGGLE_REGISTRATION_RACE';
export const UPDATE_RACE_EXTERNAL_LINK = 'UPDATE_RACE_EXTERNAL_LINK';
export const GET_INTEGRATIONS = 'GET_INTEGRATIONS';
export const GET_PARTICIPANTS_COUNT = 'GET_PARTICIPANTS_COUNT';

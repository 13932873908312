import axios from 'axios';
import { omit } from 'lodash';
import { generatePath } from 'react-router-dom';

import {
  CREATE_SYSTEM_USER_CURRENCY_URL,
  DELETE_SYSTEM_USER_CURRENCY_URL,
  SYSTEM_USER_URL,
  SYSTEM_USERS_URL,
  UPDATE_SYSTEM_USER_CURRENCY_URL,
} from 'src/constants/api';
import { request, action } from 'src/utils';

import { SystemUserFormPageConstants } from '../constants/systemUserFormPage.constants';

import { SystemUserFormPageMappers } from '../mappers/systemUserFormPage.mappers';

class SystemUserFormPageServiceClass {
  @request({ action: SystemUserFormPageConstants.LoadSystemUser })
  async loadRequest(id: number, params: AnyObject = {}): Promise<any> {
    return axios.get(generatePath(SYSTEM_USER_URL, { id }), {
      params: { ...params, with: 'paymentDetails;roles;commissions' },
    });
  }

  @action({ action: SystemUserFormPageConstants.LoadSystemUser })
  async load(id: number, params: AnyObject = {}): Promise<any> {
    const [status, response] = await this.loadRequest(id, params);
    return SystemUserFormPageMappers.fromBackend(response.data.data);
  }

  @request({ action: SystemUserFormPageConstants.systemUsersFormId })
  async createRequest(systemUser: AnyObject): Promise<any> {
    return axios.post(SYSTEM_USERS_URL, systemUser);
  }

  @action({ action: SystemUserFormPageConstants.systemUsersFormId })
  async create(systemUser: AnyObject): Promise<any> {
    const [status, response] = await this.createRequest(systemUser);
    return [status, response];
  }

  @request({ action: SystemUserFormPageConstants.systemUsersFormId })
  async updateRequest(systemUser: AnyObject): Promise<any> {
    const url = generatePath(SYSTEM_USER_URL, { id: systemUser.id });
    return axios.patch(url, systemUser);
  }

  @action({ action: SystemUserFormPageConstants.systemUsersFormId })
  async update(systemUser: AnyObject): Promise<any> {
    const [status, response] = await this.updateRequest(systemUser);
    return [status, response];
  }

  @request({ action: SystemUserFormPageConstants.systemUsersFormId })
  async createPaymentDetailsRequest(systemUser: number, payment: AnyObject): Promise<any> {
    const url = generatePath(CREATE_SYSTEM_USER_CURRENCY_URL, { id: systemUser });
    return axios.post(url, payment);
  }

  @action({ action: SystemUserFormPageConstants.systemUsersFormId })
  async createPaymentDetails(systemUser: number, payment: AnyObject): Promise<any> {
    const [status, response] = await this.createPaymentDetailsRequest(systemUser, payment);
    return [status, response];
  }

  @request({ action: SystemUserFormPageConstants.systemUsersFormId })
  async updatePaymentDetailsRequest(systemUserId: number, payment: AnyObject): Promise<any> {
    const url = generatePath(UPDATE_SYSTEM_USER_CURRENCY_URL, { id: systemUserId, currencyId: payment.currency_id });
    return axios.patch(url, omit(payment, 'currency_id'));
  }

  @action({ action: SystemUserFormPageConstants.systemUsersFormId })
  async updatePaymentDetails(systemUserId: number, payment: AnyObject): Promise<any> {
    const [status, response] = await this.updatePaymentDetailsRequest(systemUserId, payment);
    return [status, response];
  }

  @request({ action: SystemUserFormPageConstants.systemUsersFormId })
  async deleteSystemUserCurrencyRequest(systemUserId: number, currencyId: number): Promise<any> {
    const url = generatePath(DELETE_SYSTEM_USER_CURRENCY_URL, { id: systemUserId, currencyId });
    return axios.delete(url);
  }

  @action({ action: SystemUserFormPageConstants.systemUsersFormId })
  async deleteSystemUserCurrency(systemUserId: number, currencyId: number): Promise<any> {
    const [status, response] = await this.deleteSystemUserCurrencyRequest(systemUserId, currencyId);
    return [status, response];
  }
}

const SystemUserFormPageService = new SystemUserFormPageServiceClass();

export { SystemUserFormPageService };

export const EQ_FORM_ID = 'EQ_FORM_ID';

export const SETTINGS_STEP = 'SETTINGS_STEP';
export const SAVE_SYNC_STEP = 'SAVE_SYNC_STEP';

export const EQ_TIMING_REQUEST = 'EQ_TIMING_REQUEST';
export const RACES_EQ_REQUEST = 'RACES_EQ_REQUEST';
export const CLASSES_EQ_REQUEST = 'CLASSES_EQ_REQUEST';
export const WAVES_EQ_REQUEST = 'WAVES_EQ_REQUEST';
export const EQ_MAPPING_REQUEST = 'EQ_MAPPING_REQUEST';
export const EQ_SETTINGS_INIT = 'EQ_SETTINGS_INIT';
export const EQ_MAPPING_INIT = 'EQ_MAPPING_INIT';
export const EQ_MAPPING_LOAD = 'EQ_MAPPING_LOAD';
export const CUSTOM_FIELDS_EQ_REQUEST = 'CUSTOM_FIELDS_EQ_REQUEST';

import React from 'react';

import { InputFilter } from 'components/Filter';

import { t } from 'utils';

import { FilterProps } from './index';

type Props = {
  value: nil | string | number;
  intl: IntlShape;
} & FilterProps;

const RaceName = (props: Props) => {
  const value: nil | string | number = props.values.name as any;

  return <InputFilter {...props} name={'name'} value={value} label={t.staticAsString('races.filters.name')} />;
};

export { RaceName };

import { observer, inject } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import { START_LIST, BACKEND_DATE_FORMATE } from 'src/constants';

import { DatePicker } from 'components/Fields/DatePicker';

import { Errors as ErrorsStore } from 'stores';

type Props = {
  item: RacerType;
  field: string;
  errorsStore: ErrorsStore;
  distance: DistanceType;
  racersField: Object;
  saveRacer: Function;
  value: string;
  onChange: (name: string, value: any) => void;
};

const action = `UPDATE_${START_LIST}`;

@inject('errorsStore')
@onClickOutside
@observer
class DatePickerInput extends React.Component<Props> {
  handleClickOutside = (e: React.FocusEvent) => {
    const { saveRacer, field } = this.props;
    const clickedOutside = !e.target.closest('.react-datepicker-popper');

    if (clickedOutside) {
      saveRacer(field);
    }
  };

  onChange = (e: any) => {
    const { onChange, field } = this.props;
    let value: null | string = moment(e.value).format(BACKEND_DATE_FORMATE);

    if (!e.value) {
      value = null;
    }

    onChange(field, value);
  };

  errors = () => {
    const { errorsStore } = this.props;
    const errors = errorsStore.errors.api[action];
    const validationErrors = errorsStore.errors.validation[action];

    if (validationErrors) {
      return validationErrors.formatErrors();
    }
    if (errors) {
      return errors.formatErrors();
    }
    return {};
  };

  render() {
    const { field, value } = this.props;

    return <DatePicker value={value} onChange={this.onChange} name={field} />;
  }
}

export { DatePickerInput };

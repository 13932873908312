import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { withStyles } from '@mui/styles';
import { duplicateDistanceAction, duplicateRaceAction } from 'modules/RaceDetails/actions';
import { DUPLICATE_ACTION } from 'modules/RaceDetails/constants';
import { duplicateModalStore } from 'modules/RaceDetails/stores';
import * as React from 'react';
import { FC } from 'react';

import { t } from 'utils';

import { progressStore } from 'stores';

type Props = {};

const DialogActionsStyled = withStyles({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 24,
    paddingLeft: 24,
    borderTop: '1px solid #e0e0e0',
  },
  spacing: {
    '&>:not(:first-child)': {
      marginLeft: 16,
    },
  },
})(DialogActions);

const ButtonStyled = withStyles({
  root: {
    padding: '10px 28px',
    fontSize: 14,
    borderRadius: 2,
  },
  label: {
    height: 14,
  },
})(Button);

export const ModalBottom: FC<Props> = () => {
  const handleConfirm = async () => {
    progressStore.log(DUPLICATE_ACTION, 'progress');
    if (duplicateModalStore.value?.type === 'race') {
      await duplicateRaceAction();
    }
    if (duplicateModalStore.value?.type === 'distance') {
      await duplicateDistanceAction(duplicateModalStore.value?.distanceId!);
    }
    progressStore.log(DUPLICATE_ACTION, 'completed');
  };
  const handleClose = () => {
    duplicateModalStore.set({ open: false });
  };
  return (
    <DialogActionsStyled>
      <ButtonStyled onClick={handleClose}>{t.staticAsString('shared.confirmPopup.btnAction.cancel')}</ButtonStyled>
      <ButtonStyled variant='contained' color='primary' onClick={handleConfirm} disableElevation>
        {t.staticAsString('shared.confirmPopup.btnAction.confirm')}
      </ButtonStyled>
    </DialogActionsStyled>
  );
};

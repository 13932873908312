import { uniqBy } from 'lodash';
import { action, observable, makeObservable } from 'mobx';
import moment from 'moment';

import { BACKEND_DATE_FORMATE } from 'src/constants';

export type Options = {
  id: number;
  key: number;
  label?: string | null;
};

const defaultValues = {
  params: {
    searchJoin: 'AND',
    with: 'currency',
    page: 1,
    limit: 10,
    search: `published:1;race_end_date:${moment().format(BACKEND_DATE_FORMATE)}`,
    searchFields: 'published:=;race_end_date:>=',
  },
};

export class Races {
  @observable races: Array<Options> = [];

  @observable params: AnyObject = defaultValues.params;
  isPast: boolean = false;
  @observable hasMore: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action
  setRaces(data: Array<Options>): void {
    this.races = uniqBy([...this.races, ...data], 'id');
  }

  @action
  setParams(params: Object): void {
    this.params = {
      ...this.params,
      ...params,
    };
  }

  @action
  setHasMore(hasMore: boolean = true): void {
    this.hasMore = hasMore;
  }

  @action
  clear(): void {
    this.races = [];
    this.params = defaultValues.params;
    this.hasMore = true;
  }
}

const couponFormRacesStore = new Races();
export { couponFormRacesStore };

import axios from 'axios';

import { ROUTES, FORGOT_PASSWORD, FORGOT_PASSWORD_URL } from 'src/constants';

import { history, t, request } from 'utils';

import { toastStore } from 'stores';

export class ForgotPasswordService {
  @request({ action: FORGOT_PASSWORD, root: 'errors' })
  forgotPasswordRequest(email: string): any {
    return axios.post(FORGOT_PASSWORD_URL, { email });
  }

  async forgotPassword(email: string) {
    const [responseStatus] = await this.forgotPasswordRequest(email);
    if (responseStatus) {
      toastStore.show(t.staticAsString('forgotPassword.checkEmailBox' as TranslationLockedKeys));
      history.push(ROUTES.rootRoute);
    }
  }
}

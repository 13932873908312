export const format = 'HH:MM:SS';
export const momentFormat = 'HH:mm:ss';
export const placeholder = 'hh:mm:ss';
export const mask = [
  /\d/,
  /\d/, // hour
  ':',
  /\d/,
  /\d/, // minute
  ':',
  /\d/,
  /\d/, // second
];
export const placeholderChar = '_';

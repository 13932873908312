import moment from 'moment';

import { time, duration as durationUtil } from 'utils';

import { roundMoment, roundDiff } from '../round';

/*
 * Parse and calculate string into time.WorkDate
 * for edit in Fields/Date
 */
function date(value: nil | string): null | timeSpace.WorkDate {
  if (!time.isValid(value as moment.MomentInput, 'date')) {
    return null;
  }

  return time.date(value as moment.MomentInput).parse();
}

/*
 * Parse and calculate two strigs into millis difference
 * for edit in Fields/TimeDuration
 */
function dateTime(value: nil | string): null | timeSpace.WorkDate {
  if (!time.isValid(value as moment.MomentInput, 'datetimems')) {
    return null;
  }

  return time.datetimems(value as moment.MomentInput).parse();
  // .mutate((date) => roundMoment(date));
}

/*
 * Parse and calculate two strigs into millis difference
 * for edit in Fields/TimeDuration
 */
function duration(startDate: nil | string, finishDate: nil | string): number {
  if (!time.isValid(startDate as moment.MomentInput, 'datetimems') || !time.isValid(finishDate as moment.MomentInput, 'datetimems')) {
    return 0;
  }

  const start = time.datetimems(startDate as moment.MomentInput).parse().val;
  const finish = time.datetimems(finishDate as moment.MomentInput).parse().val;

  const diff = roundDiff(durationUtil.calcDiff(start, finish));

  if (diff < 0) {
    return 0;
  }

  return diff;
}

const prepareForEdit = {
  dateTime,
  duration,
  date,
};

export { prepareForEdit };

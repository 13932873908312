import { cloneDeep, pick } from 'lodash';

function processNewMember(value: RacerType): AnyObject {
  const copy = cloneDeep(value);
  copy.gender = +copy.gender as genderValue;

  if (!copy.team_id) {
    delete copy.team_id;
  }

  return copy;
}

const RACER_FIELDS = [
  'bib_number',
  'firstname',
  'lastname',
  'email',
  'class_id',
  'gender',
  'country_id',
  'nationality_id',
  'city',
  'comment',
  'telephone',
  'address',
  'post_code',
  'birthday',
  'union',
  'squad_id',
  'shoe_size',
  'shirt_size',
  'emergency_name',
  'emergency_phone',
  'external_swimrun_id',
  'company',
];

function mapCustomerToRacer(customerValue: nil | RacerType): nil | RacerType {
  if (!customerValue) {
    return;
  }

  const racerValue = pick(customerValue, RACER_FIELDS);

  return { ...racerValue, user_id: customerValue.id } as any;
}

function processNewRacer(value: RacerType): Object {
  const copy = cloneDeep(value);
  copy.gender = +copy.gender as genderValue;

  if (copy.payment_required) {
    delete (copy as any).bib_number;
  }

  return copy;
}

export { processNewMember, processNewRacer, mapCustomerToRacer };

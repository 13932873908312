import * as React from 'react';

import { SortingContext } from 'components/context';

import { SortService } from 'services';

// NOTE, Sort
const withSorting =
  (service: ExtensionableSortService) =>
  <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    class WithSortingHOC extends React.Component<any> {
      contextToPass: SortContextProps;

      constructor(props: any) {
        super(props);
        const store = service.store.sort;
        const sortingService = new SortService(service);

        if (props.searchService) {
          props.searchService.integrateWithSorting(sortingService);
        }

        const { filterDescriptors } = service.store.sort;

        this.contextToPass = {
          sort: {
            store,
            service: sortingService,
            filterDescriptors: filterDescriptors,
          },
        };
      }

      propsToPass = () => {
        const { contextToPass } = this;

        if (!contextToPass.sort) {
          return {};
        }

        return { sortService: contextToPass.sort.service };
      };

      render() {
        return (
          <SortingContext.Provider value={this.contextToPass}>
            <WrappedComponent {...(this.props as any)} sortService={this.contextToPass.sort?.service} />
          </SortingContext.Provider>
        );
      }
    } as unknown as void;

export { withSorting, SortingContext };

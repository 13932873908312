import moment from 'moment';
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import { BACKEND_DATETIME_FORMATE, BACKEND_TIME_FORMAT, FRONTEND_TIME_FORMAT } from 'src/constants';

import { Time } from 'components/Form/Fields';

type Props = {
  item: RacerType;
  distancesField: DistanceType;
  isEdit: boolean;
  changedFields: AnyObject;
  saveChanges: () => void;
  onChange: (value: any, checkedValue: any) => void;
  currentField: AnyObject;
  value: any;
};

@onClickOutside
class CustomTimeInput extends React.Component<Props> {
  handleClickOutside = () => {
    this.props.saveChanges();
  };

  onChange = ({ value }: { name: string; value: nil | Date }) => {
    const { onChange, item, distancesField } = this.props;

    const currentField = item.fields?.find((el) => el.field_id === distancesField.id);
    const newValue = this._valueFrom(value);
    let currentValue, changedValue;

    if (currentField) {
      currentValue = item.fields?.map((field) => {
        return field.id === currentField.id ? { ...currentField, value: newValue } : field;
      });
    } else {
      currentValue = [...(item.fields || []), { id: distancesField.id, field_id: distancesField.id, value: newValue }];
    }

    changedValue = this.getChangedValues(newValue as string);
    onChange(currentValue, changedValue);
  };

  getChangedValues = (value: string) => {
    const { distancesField, changedFields } = this.props;
    const changedValue = { id: distancesField.id, values: [value] };

    if ((changedFields.fields || []).find((field: AnyObject) => field.id === distancesField.id)) {
      return changedFields.fields.map((field: AnyObject) => (field.id === distancesField.id ? changedValue : field));
    }

    return [...(changedFields.fields || []), changedValue];
  };

  _valueTo = (value: any): Date => {
    const momentValue = moment(value, BACKEND_TIME_FORMAT);

    if (momentValue.isValid()) {
      return momentValue.toDate();
    }

    return new Date(NaN);
  };

  _valueFrom = (value: nil | Date): nil | string => {
    let momentValue = moment(value as Date);

    if (!momentValue.isValid() || !value) {
      momentValue = moment({ hours: 0, minutes: 0, seconds: 0 });
    }

    return momentValue.format(BACKEND_TIME_FORMAT);
  };

  getContent = () => {
    const { item, distancesField, isEdit } = this.props;
    const currentField = item.fields?.find((itemField) => itemField.field_id === distancesField.id);
    const value = this._valueTo((currentField?.value as any) || NaN);

    if (isEdit) {
      return (
        <Time id={`custom-field-time-${item.id}`} label='' error='' value={value} onChange={this.onChange} name={distancesField.name} />
      );
    }

    const time = moment(currentField?.value as any);

    if (!time.isValid()) {
      return currentField?.value?.value || null;
    }

    return time.format(FRONTEND_TIME_FORMAT);
  };

  render() {
    return this.getContent();
  }
}

export { CustomTimeInput };

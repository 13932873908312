import { CloseOutlined, FeedbackOutlined, InfoOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';
import * as React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    borderLeft: `4px solid ${theme.palette.yellow1}`,
    marginBottom: 32,
    position: 'relative',
    overflow: 'initial',
    opacity: 1,
    transition: 'opacity 0.1s ease-out',
  },
  invisible: {
    opacity: 0,
    display: 'none',
  },
  iconButton: {
    position: 'absolute',
    top: 6,
    cursor: 'pointer',
    right: 12,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  content: {
    paddingLeft: 16,
    paddingTop: 13,
    paddingRight: 12,
    '&:last-child': {
      paddingBottom: 12,
    },
  },
  icon: {
    color: theme.palette.grey6,
    marginRight: 9,
    fontSize: 18,
    alignSelf: 'flex-end',
  },
  note: {
    color: theme.palette.grey1,
    fontSize: 14,
    fontWeight: 'bold',
  },
  text: {
    fontSize: 14,
    color: theme.palette.grey1,
    letterSpacing: 0,
  },
}));

type Props = {
  title: string;
  body: string | React.ReactNode;
  isDissmissible?: boolean;
  isInfo?: boolean;
  onDissmiss?: () => void;
};

export const InformationNote = (props: Props) => {
  const classes = useStyles();
  const [isDismissing, setIsDismissing] = React.useState(false);
  const { title, body, isInfo = false, isDissmissible = false, onDissmiss } = props;

  const handleDissmiss = () => {
    setIsDismissing(true);
    if (onDissmiss) onDissmiss();
  };

  return (
    <Card variant='outlined' className={cn(classes.root, { [classes.invisible]: isDismissing })}>
      {isDissmissible && (
        <div onClick={handleDissmiss} className={classes.iconButton}>
          <CloseOutlined style={{ color: 'grey' }} fontSize='small' />
        </div>
      )}
      <CardContent className={classes.content}>
        <Box display='flex' pb={1}>
          {isInfo ? <InfoOutlined className={classes.icon} /> : <FeedbackOutlined className={classes.icon} />}
          <Typography className={classes.note} variant='body2'>
            {title}
          </Typography>
        </Box>
        <Typography className={classes.text} variant='body2' component='p'>
          {body}
        </Typography>
      </CardContent>
    </Card>
  );
};

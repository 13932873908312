import { apiLoad } from './apiLoad';
import { fallback } from './fallback';
import { load } from './load';
import { reload } from './reload';
import { set } from './set';

const messages = {
  load,
  reload,
  set,
  fallback,
  apiLoad,
};

export { messages };

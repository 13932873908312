import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { ReactElement } from 'react';

import { t } from 'utils/t';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export default function DefaultLanguageAlertDialog({ isOpen, handleClose }: Props): ReactElement {
  if (!isOpen) return <div />;
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='default-language-dialog-title'
      aria-describedby='default-language-dialog-description'
    >
      <DialogTitle id='default-language-dialog-title'>{t.staticAsString('races.steps.languages.defaultLanguageAlertTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText id='default-language-dialog-description'>
          {t.staticAsString('races.steps.languages.defaultLanguageAlertBody')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary' autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { cloneDeep } from 'lodash';
import * as React from 'react';

import { GeneralTranslations } from './GeneralTranslations';

const data = [
  { id: 1234, key: 'title', value: 'New Distance Title (EN)', language_id: 1 },
  { id: 1235, key: 'title', value: 'Ny avståndstitel (SV)', language_id: 2 },
  { id: 1236, key: 'title', value: 'Neuer Entfernungstitel (DE)', language_id: 3 },
  { id: 1237, key: 'class', value: 'Class (EN)', language_id: 1 },
  { id: 1238, key: 'class', value: 'Klass (SV)', language_id: 3 },
  {
    id: 1239,
    key: 'class',
    value: `<p>ENGLISH &nbsp;EDITED TWICE <a target="_blank" target="_blank" href="https://translate.google.com.ua/#view=home&amp;op=translate&amp;sl=en&amp;tl=uk&amp;text=*Actual%20Result%3A*%5BBE%5D%20%5BWEB%5D%20Team%20distance%20registration%3A%20Number%20of%20added%20team%20members%20are%20taken%20into%20the%20consideration%20as%20a%20value%20for%20%22Participants%20(MAX)%22%2C%20so%20in%20case%20if%20team%20leader%20is%20registering%20team%20with%20two%20members%20to%20the%20distance%20where%20is%20available%20only%20one%20empty%20place%20in%20%22Participants%20(MAX)%22%20%3E%20team%20leader%20will%20receive%20an%20error%20after%20clicking%20on%20%22Register%22%20button%20and%20team%20with%20team%20leader%20will%20be%20registered%20to%20the%20distance%20but%20team%20member%20won't">https://translate.google.com.ua/#view=home&amp;op=translate&amp;sl=en&amp;tl=uk&amp;text=*Actual%20Result%3A*%5BBE%5D%20%5BWEB%5D%20Team%20distance%20registration%3A%20Number%20of%20added%20team%20members%20are%20taken%20into%20the%20consideration%20as%20a%20value%20for%20%22Participants%20(MAX)%22%2C%20so%20in%20case%20if%20team%20leader%20is%20registering%20team%20with%20two%20members%20to%20the%20distance%20where%20is%20available%20only%20one%20empty%20place%20in%20%22Participants%20(MAX)%22%20%3E%20team%20leader%20will%20receive%20an%20error%20after%20clicking%20on%20%22Register%22%20button%20and%20team%20with%20team%20leader%20will%20be%20registered%20to%20the%20distance%20but%20team%20member%20won't</a></p>`,
    language_id: 2,
  },
  //   { id: 1240, key: 'class', value: 'Klasse (ES)', language_id: 4 },
];

const defaultLocale = 'en';
const locales: availableLocales[] = ['en', 'sv', 'es'];

export const TranslationsContainer = () => {
  const [translations, setTranslations] = React.useState(data);
  const onUpdate = (updatedTranslation) => {
    const clonedTranslations = cloneDeep(translations);
    const index = clonedTranslations.findIndex((el) => el.id === updatedTranslation.id);
    if (index !== -1) {
      clonedTranslations.splice(index, 1, updatedTranslation);
    } else {
      clonedTranslations.push(updatedTranslation);
    }

    setTranslations(clonedTranslations);
  };

  const onNavigate = (name) => () => {};

  return (
    <div style={{ width: '75%' }}>
      <GeneralTranslations
        translations={{}}
        defaultLocale={defaultLocale}
        locales={locales}
        onUpdate={onUpdate}
        onHtmlEditNavigate={onNavigate}
      />
    </div>
  );
};

import { t } from 'utils';

import { errorsStore } from 'stores';

import { UPDATE_RACER } from '../constants';

/*
 * In case if user updates bib_number, necessary to check if registration is complete
 * if not, then block edit
 */
function validateRegistrationState(racer: RacerType, prevBib: nil | string, nextBib: nil | string): [boolean, boolean] {
  if (!Boolean(racer.payment_required) || racer?.status !== 'payment_required') {
    return [true, false];
  }

  if (!Boolean(racer.payment_required)) {
    return [true, false];
  }

  const message = t('racers.errors.cannot_edit_bib') as any;

  /*
   * If prev bib number is exists, then registration already completed
   * If not next bib number, then update won't take place
   */

  if (!!prevBib) {
    return [true, false];
  }

  if (!nextBib) {
    return [false, true];
  }

  errorsStore.addFrontendError(UPDATE_RACER, { bib_number: [message] });
  return [false, false];
}

export { validateRegistrationState };

import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

import { progressStore } from 'stores';

import { INITIALIZE_DETAILS } from '../../constants';

import { detailsService } from '../../services';

import { detailsStore } from '../../stores';

export const mountDetails = async (): Promise<void> => {
  progressStore.log(INITIALIZE_DETAILS, 'progress');

  const { pathname } = history.location;
  const match: AnyObject | null = matchPath(pathname, {
    path: ROUTES.paymentRoute,
  });
  const paymentId = +match?.params?.id;
  if (!paymentId) {
    return;
  }

  await Promise.all([detailsService.loadDetails(paymentId), detailsService.loadOrderPayment(paymentId)]);

  if (detailsStore.payment?.id) {
    await detailsService.loadTransactions(detailsStore.payment.id);
  }

  progressStore.log(INITIALIZE_DETAILS, 'completed');
};

export const unmountDetails = (): void => {
  detailsStore.clear();
};

import { setCommission } from '../utils';

import { CommissionCreateEnum, Model, TableRow } from '../types';

export namespace CommissionMapper {
  export function fromBackend(data: Model[]): TableRow[] {
    const out: TableRow[] = [];
    for (let i = 0; i < data.length; i++) {
      const el = data[i];
      const entry: TableRow = {
        id: el.id,
        name: el.name,
        parentRaceId: el.organizer_id,
        isRace: true,
        currency: el.currency,
        commission: el.commission
          ? {
              ...el.commission,
              amount: el.commission.amount / 100,
              percent_value: el.commission.percent_value / 100,
              vat_percents: el.commission.vat_percents / 100,
            }
          : null,
        subRows: [],
      };
      el.distances.forEach((d, i) => {
        entry.subRows?.push({
          id: d.id,
          name: d.name,
          isDistance: true,
          parentRaceId: el.id,
          currency: el.currency,
          commission: d.commission
            ? {
                ...d.commission,
                amount: d.commission.amount / 100,
                percent_value: d.commission.percent_value / 100,
                vat_percents: d.commission.vat_percents / 100,
              }
            : null,
          subRows: [],
        });
      });
      out.push(entry);
    }
    return out;
  }
  export function toBackend(isEdit: boolean, value: TableRow, isResetMode?: boolean) {
    let payload;

    if (isEdit) {
      payload = {
        amount: isResetMode ? null : setCommission(value.commission?.amount),
        percent_value: isResetMode ? null : setCommission(value.commission?.percent_value),
        vat_percents: isResetMode ? null : setCommission(value.commission?.vat_percents),
        is_absorb: isResetMode ? null : value.commission?.is_absorb,
      };
    } else {
      payload = {
        amount: Number(value.commission?.amount) * 100,
        percent_value: Number(value.commission?.percent_value) * 100,
        vat_percents: Number(value.commission?.vat_percents) * 100,
        commissionable_type: value?.isRace ? CommissionCreateEnum.Race : CommissionCreateEnum.Distance,
        commissionable_id: value?.id,
        currency_id: value?.currency.id,
        is_absorb: value?.commission?.is_absorb ?? false,
      };
    }

    return payload;
  }
}

import { t } from 'utils';

const checkpoints = {
  _validation: {
    name: {
      presence: {
        message: () => t.staticAsString('shared.errors.presence'),
        allowEmpty: false,
      },
    },
    length: {
      presence: {
        message: () => t.staticAsString('shared.errors.presence'),
        allowEmpty: false,
      },
      numericality: {
        greaterThanOrEqualTo: 1,
      },
    },
  },
};

export { checkpoints };

import { isDate, isNaN } from 'lodash';
import moment from 'moment';
import Xregexp from 'xregexp';

import { TIME_PATTERN_WITHOUT_SECONDS } from 'src/constants';

import { momentFormat, INVALID_DATE } from './constants';

export function formatTimeWithoutSecondsToMask(value: nil | Date | any): string {
  const momentValue = moment(value);

  if (!momentValue.isValid()) {
    return '';
  }

  return moment(value).format(momentFormat);
}

export function formatMaskToTimeWithoutSeconds(
  value: string,
  prevDate?: nil | Date | typeof INVALID_DATE,
): nil | Date | typeof INVALID_DATE {
  if (!TIME_PATTERN_WITHOUT_SECONDS.test(value)) {
    return INVALID_DATE;
  }

  const match: AnyFunction | any = Xregexp.exec(value, TIME_PATTERN_WITHOUT_SECONDS) as unknown as
    | null
    | undefined
    | {
        hours: nil | string;
        minutes: nil | string;
        seconds: nil | string;
      };
  const { hours, minutes } = match;

  const dateToChange = isDate(prevDate) && !!prevDate.getTime() ? prevDate : new Date();

  const hour = Number(hours);
  const minute = Number(minutes);

  if (isNaN(hour) || isNaN(minute)) {
    return 'Invalid date';
  }

  dateToChange.setHours(hour, minute);
  return dateToChange;
}

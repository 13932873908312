import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  globalButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
    borderRadius: '2px',
    border: `1px solid ${theme.palette.grey9}`,
    '&:hover': {
      backgroundColor: theme.palette.yellow6,
    },
  },
  globalIcon: {
    ...theme.icon.baseIconButton,
  },
}));

import TablePagination from '@mui/material/TablePagination';
import { observer } from 'mobx-react';
import * as React from 'react';

import { onChangeLimit, onPageChange } from '../../actions';

import { paginationStore } from '../../stores';

type Props = {};

@observer
export class Pagination extends React.Component<Props> {
  render() {
    if (!paginationStore.value) return true;

    return (
      <TablePagination
        component='div'
        rowsPerPageOptions={[30, 50, 100, 200]}
        count={paginationStore.value.total}
        rowsPerPage={paginationStore.value.limit}
        page={paginationStore.value.page - 1}
        onPageChange={onPageChange}
        onRowsPerPageChange={onChangeLimit}
      />
    );
  }
}

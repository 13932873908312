import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';

import { REPORT_URL } from 'src/constants';

import { progressStore } from 'stores';

import { ReportConstants } from '../constants/report.constants';

import { ReportMappers } from '../mappers/fromBackend.mapper';
import { Report } from '../types/report.type';

export namespace ReportService {
  export async function load(reportId: number) {
    progressStore.log(ReportConstants.ReportPage, 'progress');
    try {
      const response = await axios.get<AxiosResponse<Report>>(generatePath(REPORT_URL, { reportId }), {
        params: {
          with: 'race;organizer;file;pdf',
        },
      });

      return ReportMappers.fromBackend(response.data.data);
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(ReportConstants.ReportPage, 'completed');
    }
  }
}

import { Box, TextField } from '@mui/material';
import { Transaction } from 'modules/Coupons/types/transaction.type';
import { formatCurrency } from 'modules/Reconciliation/shared/utils/formatCurrency';
import moment from 'moment';
import { ColumnWithLooseAccessor } from 'react-table';

import { DatePicker } from 'components/Form/Fields';

import { t } from 'utils/t';

const COLUMNS = [
  // 'transactionsID',
  'distanceName',
  'totalSum',
  'discountSum',
  'discountAmount',
  'firstName',
  'lastName',
  'email',
  'createdAt',
  'updatedAt',
] as const;

const title = (prefix: string) => t.staticAsString(`reconciliation.transactions.${prefix}` as any);
const header = (prefix: string) => t.static(`coupons.detail.${prefix}` as any);

function NumberRangeFilter({ column: { filterValue = [], setFilter, id } }) {
  return (
    <Box display='flex' flexDirection='row'>
      <Box minWidth='80px' p={0.5}>
        <TextField
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val === '0' || val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          value={filterValue[0]}
          variant='outlined'
          size='small'
          label={title('from')}
        />
      </Box>
      <Box minWidth='80px' p={0.5}>
        <TextField
          value={filterValue[1]}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val === '0' || val ? parseInt(val, 10) : undefined]);
          }}
          variant='outlined'
          size='small'
          label={title('to')}
        />
      </Box>
    </Box>
  );
}

function DateFilter(props) {
  const {
    column: { filterValue, setFilter, preFilteredRows, id },
  } = props;
  return (
    <Box minWidth='250px' p={0.5}>
      <DatePicker
        hideArrow
        view={{
          fullWidth: true,
          readOnly: true,
          variant: 'outlined',
          size: 'small',
        }}
        label=''
        name='date_picker'
        value={filterValue}
        onChange={({ name, value }) => {
          setFilter(value);
        }}
        onClear={() => {
          if (id === 'createdAt' || 'updated_at') {
            setFilter(null);
          }
        }}
      />
    </Box>
  );
}

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<Transaction> & AnyObject } = {
  // column "transactionsID" removed temporary, will be changed later

  // transactionsID: {
  //   id: 'id',
  //   Header: header('transactionsID'),
  //   accessor: (tx) => tx.id,
  //   filter: 'between',
  //   Filter: NumberRangeFilter,
  // },

  distanceName: {
    id: 'title',
    Header: header('distanceName'),
    accessor: (tx) => tx.title ?? '—',
  },

  totalSum: {
    id: 'orders.total',
    Header: header('totalSum'),
    accessor: (tx) => tx.order.total,
    Cell: ({ row: { original } }) => {
      const roundedTotal = original.order.total / 100;
      return <>{formatCurrency(roundedTotal, original.order.currency)}</>;
    },
    filter: 'between',
    Filter: NumberRangeFilter,
  },

  discountSum: {
    id: 'discount_amount',
    Header: header('discountSum'),
    accessor: (tx) => tx.discount_amount,
    filter: 'between',
    Filter: NumberRangeFilter,
    Cell: ({ row: { original } }) => <>{formatCurrency(original.discount_amount / 100, original.order.currency) ?? '—'}</>,
  },

  discountAmount: {
    id: 'discount_value',
    Header: header('discountAmount'),
    accessor: (tx) => tx.discount_value,
    Cell: ({ row: { original } }) => (
      <>{formatCurrency(original.discount_value / 100, original.discount_is_percent ? '%' : original.order.currency) ?? '—'}</>
    ),
    Filter: () => null,
  },

  firstName: {
    id: 'registration_participants.firstname',
    Header: header('firstName'),
    accessor: (tx) => tx.participant.firstname ?? '—',
  },

  lastName: {
    id: 'registration_participants.lastname',
    Header: header('lastName'),
    accessor: (tx) => tx.participant.lastname ?? '—',
  },

  email: {
    id: 'registration_participants.email',
    Header: header('email'),
    accessor: (tx) => tx.participant.email ?? '—',
  },

  createdAt: {
    id: 'created_at',
    Header: header('createdAt'),
    Filter: DateFilter,
    accessor: (tx) => tx.order.created_at,
    Cell: ({ row: { original } }) => {
      return <>{moment(original.order.created_at).format('YYYY/MM/DD HH:mm')}</>;
    },
  },

  updatedAt: {
    id: 'updated_at',
    Header: header('updatedAt'),
    Filter: DateFilter,
    accessor: (tx) => tx.order.updated_at,
    Cell: ({ row: { original } }) => {
      return <>{moment(original.order.updated_at).format('YYYY/MM/DD HH:mm')}</>;
    },
  },
};

export function generateTransactionColumns() {
  return COLUMNS.map((c) => RenderColumns[c]);
}

import { observer } from 'mobx-react';
import { duplicateFormInit } from 'modules/RaceDetails/actions/duplicateFormInit';
import { distancesService } from 'modules/RaceDetails/services';
import React from 'react';

import { confirmationModalStore } from 'src/stores';
import { t } from 'src/utils';

import { Distances } from '../../components';

import { deleteDistanceAction, addNewDistanceAction, dragEndAction } from '../../actions';

import { distancesStore, raceDetailsStore } from '../../stores';

type OriginalProps = {};

type Props = OriginalProps & WithConfirmModalProps<'deleteDistancePopup'> & HOC.withIntl;

@observer
class DistancesContainerImpl extends React.Component<Props> {
  onDeleteDistanceWrapper = (id: number, name: string) => () => {
    confirmationModalStore.openModal({
      title: t.staticAsString('distances.confirmPopup.mainTitle'),
      body: t.staticAsString('distances.confirmPopup.mainBody', { name }),
      type: 'confirm',
      confirmationValue: name,
      confirmationLabel: t.staticAsString('confirmationModal.distance.label'),
      onConfirm: () => deleteDistanceAction(id),
    });
  };

  render() {
    const { onDeleteDistanceWrapper } = this;

    const currency = raceDetailsStore.raceDetails?.currency() || '';
    const distances = distancesStore.modelValues;
    const raceId = raceDetailsStore.race?.id;

    const loadMoreDistances = () => {
      raceId && distancesService.loadDistances(raceId);
    };

    return (
      <Distances
        values={distances}
        currency={currency}
        onDragEnd={dragEndAction}
        onAddNew={addNewDistanceAction}
        onDeleteDistanceWrapper={onDeleteDistanceWrapper}
        onDuplicate={(id, participantsCount) => duplicateFormInit('distance', id, participantsCount)}
        onLoadMore={loadMoreDistances}
        hasMoreDistances={distancesStore.hasMore}
      />
    );
  }
}

export const DistancesContainer = DistancesContainerImpl as unknown as React.ComponentType<OriginalProps>;

import Dialog from '@mui/material/Dialog';
import withStyles from '@mui/styles/withStyles';
import { Observer } from 'mobx-react';
import { ConfirmModal } from 'modules/StartList/components/ConfirmModal';
import { SEND_ATTACH_TO_PROFILE } from 'modules/StartList/constants';
import { loadTargetEmails } from 'modules/SupportTaskManager/Tasks/actions';
import { FC, useEffect } from 'react';

import { Loading } from 'components/Loading';

import { t } from 'utils';

import { modalStore } from '../../stores';

import { Fields } from './Fields';
import { ModalBottom } from './ModalBottom';
import { TitleModal } from './TitleModal';

type Props = {};

const DialogStyled = withStyles({
  paper: {
    width: 770,
  },
})(Dialog);

export const AttachToProfileModal: FC<Props> = () => {
  const closeForm = (): void => {
    modalStore.set({
      ...(modalStore.value as any),
      open: false,
    });
  };

  useEffect(() => {
    loadTargetEmails();
  }, []);

  return (
    <Observer>
      {() => {
        const open: boolean = modalStore.value?.open || false;

        return (
          <>
            <ConfirmModal />
            <DialogStyled open={open} onClose={closeForm} maxWidth='md'>
              <Loading action={SEND_ATTACH_TO_PROFILE} />
              <TitleModal title={t.staticAsString('startlist.controls.attachToProfile')} />
              <Fields />
              <ModalBottom />
            </DialogStyled>
          </>
        );
      }}
    </Observer>
  );
};

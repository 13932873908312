import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { injectIntl } from 'react-intl';

import { withConfirmModal } from 'hocs';

import { Show } from 'components/Condition';
import { SettingsMenu } from 'components/SettingsMenu';

import { t } from 'utils';

type Props = {
  title: string;
  isResultsShown: boolean; // is results shown,
  isResultsResetable: boolean;
  isToggleResultsShown: boolean;
  onResetResults: Function;
  onToggleResults: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
} & WithConfirmModalProps<'hideResultsConfirm'> &
  WithConfirmModalProps<'resetResultsConfirm'>;

function SettingsComponent(props: Props) {
  const { isResultsShown, isResultsResetable, title, isToggleResultsShown } = props;
  const onResetResults = () => props.resetResultsConfirm.open(props.onResetResults, {}, { name: title });

  let onToggleResults = props.onToggleResults;

  if (isResultsShown) {
    // display confirm only during hiding
    onToggleResults = () => props.hideResultsConfirm.open(props.onToggleResults, {}, { name: title });
  }

  return (
    <SettingsMenu>
      <Show if={isToggleResultsShown}>
        <MenuItem className={'menu-card-item'} onClick={onToggleResults}>
          <p>{t(isResultsShown ? 'shared.distance.hideResultTab' : ('shared.distance.showResultTab' as TranslationLockedKeys))}</p>
        </MenuItem>
      </Show>
      <Show if={isResultsResetable}>
        <MenuItem className={'menu-card-item'} onClick={onResetResults}>
          <p>{t.staticAsString('shared.distance.resetResults')}</p>
        </MenuItem>
      </Show>
    </SettingsMenu>
  );
}

const hideDecorator = withConfirmModal('hideResultsConfirm', {
  i18Title: 'races.confirmPopup.mainTitle',
  i18Body: 'races.distance.hideResults.confirm',
  type: 'confirm',
});

const resetDecorator = withConfirmModal('resetResultsConfirm', {
  i18Title: 'races.confirmPopup.mainTitle',
  i18Body: 'races.distance.resetResults.confirm',
  type: 'confirm',
});

// @ts-ignore
const Settings = injectIntl(resetDecorator(hideDecorator(SettingsComponent)));

export { Settings };

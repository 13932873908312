import React from 'react';

import { InputFilter } from 'components/Filter';

import { t } from 'utils';

import { FilterProps } from './index';

type Props = {
  value: nil | string | number;
} & FilterProps;

const Name = (props: Props) => {
  return <InputFilter {...(props as any)} label={t.staticAsString('coupons.list.table.name')} />;
};

export { Name };

import { transform, isEqual, isObject } from 'lodash';

function objectsDifference(
  object: {
    [K in string]: any;
  },
  base: {
    [K in string]: any;
  },
): {
  [K in string]: any;
} {
  function changes(object: any, base: any) {
    return transform(object, function (result: any, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

export { objectsDifference };

import { Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styled from '@mui/styles/styled';
import classNames from 'classnames';
import { truncate } from 'lodash';
import * as React from 'react';

import { useSelectForDistanceStyles } from './styles';

type Props = {
  classes?: {
    [K in string]: string;
  };
  name: string;
  onChange: AnyFunction;
  value: string | number;
  errors: Array<string>;
  type?: string;
  id?: string;
  label?: string;
  className?: string;
  options: Array<SelectOption>;
  fullWidth: boolean;
};

const StyledMenuItem = styled(MenuItem)({
  fontSize: '16px',
});

// TODO: refactor this component so it could be used as distance select in toolbar
// as well as usuall form select

const SelectForDistance = ({
  classes = {
    root: 'field-root',
  },

  name,
  onChange,
  value,
  errors = [],
  id = '',
  label = '',
  options = [],
  className = 'form-control select',
  fullWidth = false,
}: Props) => {
  const styles = useSelectForDistanceStyles();

  const formName = id ? `${id}_${name}` : name;
  const isErr = errors.length > 0;

  return (
    <FormControl
      sx={{
        minWidth: '600px',
      }}
      fullWidth={fullWidth}
      className={classNames(className, { error: isErr }, styles.root)}
      error={isErr}
      aria-describedby='name-error-text'
    >
      <InputLabel htmlFor={formName} classes={{ root: 'form-label' }}>
        {label}
      </InputLabel>
      <Select
        fullWidth={fullWidth}
        value={value}
        error={isErr}
        onChange={onChange}
        classes={classes}
        inputProps={{
          name: name,
          id: formName,
        }}
      >
        {options.map((item) => (
          <StyledMenuItem value={item.value} key={item.key} className='menu-item'>
            <Tooltip title={item.label}>
              <span>{truncate(item.label?.toString(), { length: 40 })}</span>
            </Tooltip>
            <span className='select-label-options'> {item.labelOption} </span>
          </StyledMenuItem>
        ))}
      </Select>
      <FormHelperText className='errors' id={`${formName}_error`}>
        {errors.join(' ')}
      </FormHelperText>
    </FormControl>
  );
};

export { SelectForDistance };

import { TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import { isEmpty } from 'lodash';
import { observer, inject } from 'mobx-react';
import * as React from 'react';

import { ThTd } from 'components/Filter/ThTd';

import { t } from 'utils';

import { Coupon as CouponStore } from 'stores';

import loadService from '../../../services/load';

import { Filter } from './Filters';

type Props = {
  couponsStore: CouponStore;
};

type State = {
  selectedFilter: nil | string;
  anchorEl: nil | Element;
};

const POPPER_RELATIVE_Y_POSITION = -12;
const POPPER_RELATIVE_X_POSITION = 0;

const COLUMNS = [
  'action',
  'id',
  'name',
  'code',
  'qty',
  'left',
  'discount_amount',
  'race.translations.name',
  'distances.translations.name',
  'date_to',
  'status',
  'description',
];

@inject('couponsStore')
@observer
class THead extends React.Component<Props, State> {
  state = {
    selectedFilter: null,
    anchorEl: null,
  };

  static defaultProps = {
    intl: null as any,
    couponsStore: null as any,
  };

  handleShowFilter = (e: nil | React.SyntheticEvent = null, selectedFilter: nil | string = null) => {
    this.setState({
      selectedFilter,
      anchorEl: e ? e.currentTarget : null,
    });
  };

  onModalClose = () => this.handleShowFilter();

  applyFilter = (...filters: Array<FiltersType>) => {
    if (filters[0].date_to && !filters[0].date_to[1]) {
      filters[0].date_from = filters[0].date_to;
    }

    const hideFilter = () => this.handleShowFilter();
    loadService.applySearch(hideFilter, ...filters);
  };

  clearFilters = () => {
    const { couponsStore } = this.props;
    const searchData = couponsStore.searchDataModel.search;

    if (isEmpty(searchData)) {
      return null;
    }

    const clear = () => {
      loadService.clearFilters();
    };

    return (
      <li onClick={clear} className='reset-filters'>
        {t.staticAsString('races.filters.resetAll')}
      </li>
    );
  };

  updateSearchHandlers = (
    handlers: {
      [K in string]: string;
    },
  ) => {
    const { couponsStore } = this.props;
    couponsStore.updateSearchHandlers(handlers);
  };

  _renderThLabels = (): React.ReactNode => {
    const { couponsStore } = this.props;
    const searchData = couponsStore.searchDataModel.search;

    return COLUMNS.map((param) => {
      const label = param && t.staticAsString(`coupons.list.table.${param}` as TranslationLockedKeys);

      return (
        <ThTd
          key={param}
          name={param}
          values={searchData}
          className='coupon-table-header filter-item'
          label={label}
          value={searchData[param] || ''}
          onFilterShow={this.handleShowFilter}
          disabled={!Filter.types.includes(param)}
        />
      );
    });
  };

  _renderFilterPopover = (): React.ReactNode => {
    const { couponsStore } = this.props;
    const { selectedFilter, anchorEl } = this.state;

    return (
      <Popover
        className='material-popover'
        classes={{ paper: 'popover-paper' }}
        anchorEl={anchorEl}
        open={!!this.state.selectedFilter}
        onClose={this.onModalClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: POPPER_RELATIVE_Y_POSITION,
          horizontal: POPPER_RELATIVE_X_POSITION,
        }}
      >
        <Paper className='main-layout'>
          {selectedFilter && (
            <Filter
              name={String(selectedFilter)}
              value={couponsStore.searchDataModel.search[String(selectedFilter)]}
              values={couponsStore.searchDataModel.search}
              label={t.staticAsString(`races.filters.${selectedFilter}` as TranslationLockedKeys)}
              applyFilter={this.applyFilter}
              updateSearchHandlers={this.updateSearchHandlers}
            />
          )}
        </Paper>
      </Popover>
    );
  };

  render() {
    return (
      <React.Fragment>
        <TableHead>
          <TableRow>{this._renderThLabels()}</TableRow>
        </TableHead>
        {this._renderFilterPopover()}
      </React.Fragment>
    );
  }
}

export { THead };

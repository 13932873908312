export const formatData = (json: AnyObject): FormData => {
  let data = new FormData();
  Object.keys(json).forEach((field) => {
    let value = json[field];

    if (field === 'max_speed') {
      value = Number(value);
    }
    data.append(`${field}`, value);
  });

  return data;
};

import { InputAdornment, OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MaterialInput from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import classNames from 'classnames';
import * as React from 'react';

import { Icon } from 'components/Icon';

import { stringFormatter } from 'utils';

type Props = {
  classes?: {
    [K in string]: string;
  };
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  keyPress?: React.KeyboardEventHandler;
  onBlur?: React.FocusEventHandler;
  errors: Array<string>;
  name: string;
  value: any;
  type?: string;
  id?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  tooltipText?: string;
  variant?: 'outlined' | 'standard' | 'filled';
  position?: 'icon' | 'bottom';
  required?: boolean;
  rows?: number;
  rowsMax?: number;
  fullWidth?: boolean;
  autoFocus?: boolean;
  multiline?: boolean;
  hasPaddingRight?: boolean;
  reservePlaceForHelperText?: boolean;
  currency: string;
};
const PriceInput = ({
  classes = {
    root: 'field-root',
    input: 'field',
  },
  name,
  onChange,
  keyPress = null as any,
  onBlur,
  value,
  errors = [],
  autoFocus = false,
  type = 'text',
  id = '',
  label = '',
  className = 'form-control',
  disabled,
  fullWidth = false,
  tooltipText,
  position = 'bottom',
  reservePlaceForHelperText = true,
  multiline = false,
  rows = 1,
  rowsMax = 5,
  hasPaddingRight = false,
  required,
  variant = 'outlined',
  currency,
}: Props) => {
  const val = value === undefined || value === null ? '' : value;
  const formName = id ? `${id}_${name}` : name;
  const isErr = errors.length > 0;

  const inputClasses = {
    ...{ focused: 'focused' },
    ...classes,
    // TODO: console errors:  check if label is being placed correctly, if no - unskip this code
    // MuiInputLabel: {
    //   styleOverrides: {
    //     outlined: {
    //       '&$shrink': {
    //         transform: 'translate(14px, -6px) scale(0.9)',
    //       },
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     notchedOutline: {
    //       '& > legend': {
    //         fontSize: ' 0.9em',
    //       },
    //     },
    //   },
    // },
  };
  // Show only first error, which should be more relevant.
  // Long concated errors causing problems with overlaping to following fields below.
  // Also concating errors is not best UI/UX practice, according material-ui and others common libs
  const errorMessage = errors[0];
  const helperText = errorMessage || (reservePlaceForHelperText && ' ');

  const renderTooltip = () => {
    switch (position) {
      case 'bottom':
        return (
          !errorMessage &&
          tooltipText && (
            <FormHelperText className='tooltip-text' id={`${formName}_tooltip`}>
              {tooltipText}
            </FormHelperText>
          )
        );
      case 'icon':
        return (
          !!tooltipText && (
            <Tooltip title={stringFormatter.withNewLine(tooltipText)} classes={{ tooltip: 'switch-popover' }}>
              <span>
                <Icon className='info-helper with-background' value='info' />
              </span>
            </Tooltip>
          )
        );
      default:
        return null;
    }
  };

  let multilineProps = {};

  if (multiline) {
    multilineProps = {
      rows,
      rowsMax,
      multiline,
    };
  }

  const handleOnChange = (e) => {
    const onlyNumberRegexp = /^[0-9]*\.?[0-9]*$/;
    const { value } = e.currentTarget;

    const isNumber = onlyNumberRegexp.test(value.toString());

    if (isNumber) {
      onChange(e);
    }
  };

  const inputProps = {
    disabled,
    label,
    fullWidth,
    classes: inputClasses,
    style: { paddingRight: hasPaddingRight ? 25 : 0 },
    autoComplete: 'off',
    name: name,
    id: formName,
    value: val,
    onChange: handleOnChange,
    type: type,
    autoFocus: autoFocus,
    onKeyUp: keyPress,
    onBlur: onBlur,
    ...multilineProps,
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      className={classNames('input-wrap', className, { error: isErr })}
      error={isErr}
      aria-describedby='name-error-text'
    >
      <InputLabel variant={variant} required={required} htmlFor={formName}>
        {label}
      </InputLabel>
      {variant === 'outlined' ? <OutlinedInput {...inputProps} /> : <MaterialInput {...inputProps} />}
      {renderTooltip()}
      <FormHelperText className='errors' id={`${formName}_error`}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};
export { PriceInput };

import { loadGPX } from 'src/modules/Distances/components/shared/Steps/GPX/actions/loadGPX';
import {
  gpxGoogleMapStore,
  gpxStore,
  markersStore,
  openCheckPointsModalStore,
  checkPointStore,
} from 'src/modules/Distances/components/shared/Steps/GPX/stores';

export const mount = () => {
  loadGPX();
};

export const unmount = () => {
  openCheckPointsModalStore.off();
  gpxStore.clear();
  gpxGoogleMapStore.clear();
  markersStore.clear();
  checkPointStore.clear();
};

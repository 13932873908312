import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import cn from 'classnames';
import React, { ReactElement } from 'react';
import { withRouter } from 'react-router';
import shortid from 'shortid';

import { t } from 'utils';

const styles = {
  classes: {
    root: 'stepper-root',
    vertical: 'vertical',
  },
  stepClasses: {
    root: 'step-root',
    completed: 'completed',
  },
  stepLabelClasses: {
    root: 'step-label',
    iconContainer: 'icon-container',
    error: 'error',
    active: 'active',
    completed: 'completed',
    labelContainer: 'label-container',
    label: 'label',
  },
};

interface Props {
  steps: Array<string>;
  step: {
    value: string;
    index: number;
  };
  stepModel: 'distances' | 'races';
  onChange: (value: string) => void;
  classes?: {
    [K in string]: string;
  };
  stepClasses?: {
    [K in string]: string;
  };
  stepLabelClasses?: {
    [K in string]: string;
  };
  errors: string[];
  disableIndices?: number[];
}

function VerticalStepper(props: Props): ReactElement {
  const { disableIndices = [], stepModel, steps, onChange, step, errors = [] } = props;
  const { classes, stepClasses, stepLabelClasses } = styles;

  const renderSteps = (): React.ReactNode => {
    return steps.map((item, index) => {
      const isErr = errors.includes(item);
      const labelClassNames = [
        {
          active: step.value === item,
          completed: step.index > index,
          error: isErr,
        },
      ];

      const isDisabled = disableIndices.includes(index);

      return (
        <Step disabled={isDisabled} classes={stepClasses} key={shortid()} onClick={() => !isDisabled && onChange(item)}>
          <StepLabel error={isErr} className={cn(...labelClassNames)} classes={stepLabelClasses}>
            {t(`${stepModel}.steps.${item}` as TranslationLockedKeys)}
          </StepLabel>
          <StepContent>{''}</StepContent>
        </Step>
      );
    });
  };

  return (
    <div className='distance-stepper'>
      <Stepper classes={classes} orientation='vertical' activeStep={step.index}>
        {renderSteps()}
      </Stepper>
    </div>
  );
}

export default withRouter(VerticalStepper);

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import classNames from 'classnames';
import * as React from 'react';
import RichTextEditor from 'react-rte';

type Props = {
  classes: {
    [K in string]: string;
  };
  name: string;
  onChange: (arg0: { name: string; value: string }) => void;
  value: string;
  errors: Array<string>;
  type?: string;
  id?: string;
  label?: string;
  className?: string;
  toolbarConfig?: Object;
  searchOptions?: AnyObject;
};

type State = {
  value: any;
  htmlValue: string;
  prevHtmlValue: string;
};

/**
 * @deprecated
 * should be removed along with react-rte
 */
class TextEditor extends React.Component<Props, State> {
  static defaultProps = {
    errors: [],
    className: 'form-control',
    classes: { root: 'editor-wrapper', fieldName: 'text-editor' },
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      value: RichTextEditor.createEmptyValue(),
      htmlValue: '',
      prevHtmlValue: '',
    };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { value } = props;
    const { htmlValue, prevHtmlValue } = state;
    if (htmlValue === prevHtmlValue && value !== htmlValue && !!value) {
      return {
        ...state,
        value: RichTextEditor.createValueFromString(value, 'html'),
        htmlValue: value,
        prevHtmlValue: htmlValue,
      };
    }

    return null;
  }

  // TODO, refactor
  globalOnChange = () => {
    const { onChange, name } = this.props;

    const { htmlValue } = this.state;
    onChange({ name, value: htmlValue });
  };

  onChange = (value: any) => {
    const isEmpty = !value.getEditorState().getCurrentContent().hasText();
    let html = '';

    if (!isEmpty) {
      html = this._processHtml(value.toString('html'));
    }

    this.setState(
      {
        ...this.state,
        value,
        htmlValue: html,
        prevHtmlValue: this.state.htmlValue,
      },
      this.globalOnChange,
    );
  };

  _processHtml = (value: string): string => {
    return (value || '').replace(/<a /g, '<a target="_blank" ');
  };

  _toolbarConfigs = (): Object => {
    const { toolbarConfig } = this.props;

    if (!toolbarConfig) {
      return {};
    }

    return { toolbarConfig };
  };

  render() {
    const { value } = this.state;
    const { label, errors, className, classes } = this.props;
    const isErr = errors.length > 0;
    return (
      <FormControl
        fullWidth
        variant='outlined'
        className={classNames(className, classes.fieldName, { error: isErr })}
        error={isErr}
        aria-describedby='name-error-text'
      >
        <RichTextEditor className={classes.root} placeholder={label} value={value} onChange={this.onChange} {...this._toolbarConfigs()} />
        <FormHelperText className='errors'>{errors.join(' ')}</FormHelperText>
      </FormControl>
    );
  }
}

export { TextEditor };

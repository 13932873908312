import React, { ReactElement } from 'react';

import { t } from 'utils/t';

interface Props {
  status: 'completed' | 'failed' | 'partiallyRefunded' | 'refunded';
}

export default function TransactionStatus({ status }: Props): ReactElement {
  let backgroundColor = '';
  let color = '';
  let text = '';
  switch (status) {
    case 'completed':
      backgroundColor = 'rgba(102, 187, 106, 0.12)';
      color = 'rgb(102, 187, 106)';
      text = t.staticAsString('reconciliation.transaction.completed');
      break;
    case 'failed':
      backgroundColor = 'rgb(254,235,238)';
      color = '#de4a4a';
      text = t.staticAsString('reconciliation.transaction.failed');
      break;
    case 'partiallyRefunded':
      backgroundColor = 'rgba(137, 143, 154, 0.12)';
      color = 'rgba(38, 46, 53, 0.48)';
      text = t.staticAsString('reconciliation.transaction.partiallyRefunded');
      break;
    case 'refunded':
      backgroundColor = 'rgba(137, 143, 154, 0.12)';
      color = 'rgba(38, 46, 53, 0.48)';
      text = t.staticAsString('reconciliation.transaction.refunded');
      break;

    default:
      break;
  }

  return (
    <div
      style={{
        letterSpacing: 0,
        height: 22,
        fontSize: 12,
        fontWeight: 500,
        marginTop: 8,
        padding: '3px 12px 5px 12px',
        textTransform: 'uppercase',
        color,
        backgroundColor,
      }}
    >
      • {text}
    </div>
  );
}

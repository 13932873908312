import { DISTANCE_STEPS, distanceStep } from 'src/constants';

import { DISTANCE_FORM_ERRORS, ERRORS_PREFIXES } from '../constants';

import { clearFieldsErrors, clearNamespaceErrors, constructObjectForValidation, nestedValidation } from '../utils';

import { State } from '../shared/stateHelpers';
import {
  baseStep,
  classes,
  customFields,
  disciplines,
  DISTANCE_DETAILS_VALIDATION_FIELDS,
  prices,
  selfServices,
  waves,
} from '../validations';
import { nameAndDescription } from '../validations/nameAndDescription';

const notDelete = (obj: any) => !obj._delete;

export const stepValidationSchema = (step: distanceStep, formData: State) => {
  const transformedData = constructObjectForValidation(formData);
  switch (step) {
    case DISTANCE_STEPS.nameAndDescription:
      return nameAndDescription;
    case DISTANCE_STEPS.distanceDetails:
      return baseStep({ ...(transformedData.distance as DistanceType) });
    case DISTANCE_STEPS.classes:
      return classes;
    case DISTANCE_STEPS.disciplines:
      return disciplines;
    case DISTANCE_STEPS.waves:
      return waves;
    case DISTANCE_STEPS.custom_fields:
      return customFields();
    case DISTANCE_STEPS.selfServices:
      return selfServices();
    case DISTANCE_STEPS.prices:
      return prices();
    default:
      return { _validation: {} };
  }
};

export const validateOneStep = (step: distanceStep, formData: State) => {
  const transformedData = constructObjectForValidation(formData);
  const schema = stepValidationSchema(step, formData);
  switch (step) {
    case DISTANCE_STEPS.nameAndDescription:
      clearNamespaceErrors(ERRORS_PREFIXES.basic_info);
      return nestedValidation({
        action: DISTANCE_FORM_ERRORS,
        schema,
        data: transformedData.basic_info,
      });
    case DISTANCE_STEPS.distanceDetails:
      clearFieldsErrors(DISTANCE_DETAILS_VALIDATION_FIELDS);
      return nestedValidation({
        action: DISTANCE_FORM_ERRORS,
        schema,
        data: {
          ...transformedData.distance,
          goal: { ...formData.goal },
        },
      });
    case DISTANCE_STEPS.classes:
      clearNamespaceErrors(ERRORS_PREFIXES.classes);
      return nestedValidation({
        action: DISTANCE_FORM_ERRORS,
        schema,
        data: transformedData.classes.filter(notDelete),
        prefix: 'classes',
      });
    case DISTANCE_STEPS.disciplines:
      clearNamespaceErrors(ERRORS_PREFIXES.disciplines);
      return nestedValidation({
        action: DISTANCE_FORM_ERRORS,
        schema,
        data: transformedData.disciplines.filter(notDelete),
        prefix: 'disciplines',
      });
    case DISTANCE_STEPS.waves:
      clearNamespaceErrors(ERRORS_PREFIXES.waves);
      return nestedValidation({
        action: DISTANCE_FORM_ERRORS,
        schema,
        data: transformedData.waves.filter(notDelete),
        prefix: 'waves',
      });
    case DISTANCE_STEPS.custom_fields:
      clearNamespaceErrors(ERRORS_PREFIXES.custom_fields);
      return nestedValidation({
        action: DISTANCE_FORM_ERRORS,
        schema,
        data: transformedData.custom_fields.filter(notDelete),
        prefix: 'custom_fields',
      });
    case DISTANCE_STEPS.selfServices:
      clearNamespaceErrors(ERRORS_PREFIXES.self_services);
      return nestedValidation({
        action: DISTANCE_FORM_ERRORS,
        schema,
        data: transformedData.self_services,
        prefix: 'self_services',
      });
    case DISTANCE_STEPS.prices:
      clearNamespaceErrors(ERRORS_PREFIXES.prices);
      return nestedValidation({
        action: DISTANCE_FORM_ERRORS,
        schema,
        data: transformedData.prices.filter(notDelete),
        prefix: 'prices',
      });
    default:
      return true;
  }
};

import { DummyStore } from 'stores';

export const couponFormModalStore = new DummyStore<{
  open: boolean;
  isEdit: boolean;
  couponId?: number;
  coupon: AnyObject;
  successCallback: Function;
  failCallback?: Function;
  isShowConfirm: boolean;
}>({
  open: false,
  isEdit: false,
  successCallback: () => {
    throw 'Not implemented';
  },
  couponId: 0,
  coupon: {},
  isShowConfirm: false,
});

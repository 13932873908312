import { errorsStore } from 'stores';

import { SUPPORT_TASK_MANAGER_COPY_FORM } from '../constants';

import { distancesService } from '../services';

export const loadTargetDistances = async (text: string | null = null) => {
  errorsStore.clear(SUPPORT_TASK_MANAGER_COPY_FORM);
  await distancesService.getTargetDistances(getParams(text));
};

export const loadSourceDistances = async (text: string | null = null) => {
  errorsStore.clear(SUPPORT_TASK_MANAGER_COPY_FORM);
  await distancesService.getSourceDistances(getParams(text));
};

const getParams = (text: string | null): FiltersType => {
  const params = {
    with: 'race.currency',
  };

  if (text === null) {
    return params;
  }

  return {
    ...params,
    search: `id:${text}`,
    searchFields: `id:like`,
    searchJoin: 'AND',
  };
};

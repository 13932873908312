import React from 'react';

import { InputFilter } from 'components/Filter';

import { t } from 'utils';

import { FilterProps } from './index';

type Props = {
  value: nil | string | number;
} & FilterProps &
  any;

const Code = (props: Props) => {
  return <InputFilter {...props} label={t.staticAsString('coupons.list.table.code')} />;
};

export { Code };

export namespace TransactionConstants {
  export const Table = 'Table';
  export const TableFirstTime = 'TableFirstTime';

  export const Mappers = {
    grand_total: 'payment_info:id|amount',
  };

  export enum COLUMN_WIDTH {
    external_id = '220px',
    email = '220px',
    distance_title = '200px',
    firstname = '200px',
    lastname = '200px',
  }
}

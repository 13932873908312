import { Download } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import Axios from 'axios';
import { Observer } from 'mobx-react';
import { ReportsActions } from 'modules/Reconciliation/Reports/actions/lifecycle';
import { ReportsTable } from 'modules/Reconciliation/Reports/components/Table';
import { ReportsFiltersMappers } from 'modules/Reconciliation/Reports/mappers/filters.mapper';
import { ReportsStore } from 'modules/Reconciliation/Reports/stores/reports.store';
import React, { ReactElement, useCallback, useEffect } from 'react';

import { Show } from 'components/Condition';

import { handleExport } from 'utils/monitorLoadingJob';
import { t } from 'utils/t';

import { sessionStore } from 'stores';

import { filtersStore } from '../../stores/filters.store';

import ReportsModal from '../ReportModal';
import { generateReportColumns } from '../Table/Columns';

interface Props {}

function ReportsWrapper({}: Props): ReactElement {
  const [isReportsModalOpen, setIsReportsModalOpen] = React.useState({ isOpen: false, isServiceFee: false });

  const handleCloseReportsModal = () => {
    setIsReportsModalOpen({ isOpen: false, isServiceFee: false });
  };

  useEffect(() => {
    const init = async () => {
      await ReportsActions.init();
    };
    init();
    return () => {
      ReportsActions.unmount();
    };
  }, []);

  const fetchReports = useCallback(({ pageIndex = 0, pageSize, filters, sortBy }) => {
    let orderBy = 'created_at';
    let sortedBy = 'DESC';
    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      orderBy = id.includes('.') ? id.split('.').join('|') : id;
      sortedBy = desc ? 'DESC' : 'ASC';
    }
    const mapped = { page: pageIndex + 1, limit: pageSize, orderBy, sortedBy, ...ReportsFiltersMappers.toBackend(filters) };
    filtersStore.setFilters(mapped);
    ReportsActions.load(mapped);
  }, []);

  const handleExport = (type: string) => () => {
    ReportsActions.exportReports(type);
  };

  return (
    <div style={{ padding: 0, backgroundColor: 'white' }} className='content main-container-list races-list'>
      <ReportsModal isServiceFee={isReportsModalOpen.isServiceFee} isOpen={isReportsModalOpen.isOpen} onClose={handleCloseReportsModal} />
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h1 style={{ paddingLeft: 24, paddingTop: 38, paddingBottom: 30 }} className='title'>
          {t.staticAsString('reconciliation.reports.reports')}
        </h1>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 4 }}>
          <Show if={sessionStore.isAdmin}>
            <Button
              sx={{ px: 2, py: 1, mr: 2, color: '#DDA100', alignContent: 'center' }}
              onClick={() => setIsReportsModalOpen({ isOpen: true, isServiceFee: true })}
              size='large'
              color='primary'
              variant='outlined'
            >
              <Typography>Service fee report</Typography>
            </Button>
          </Show>
          <Show if={sessionStore.isAdmin}>
            <Button
              sx={{ px: 2, py: 1, mr: 2, color: '#DDA100', alignContent: 'center' }}
              onClick={() => setIsReportsModalOpen({ isOpen: true, isServiceFee: false })}
              size='large'
              color='primary'
              variant='outlined'
            >
              <Typography>Generate report</Typography>
            </Button>
          </Show>

          <Button
            className='export-item'
            sx={{ border: '2px solid #A8AEB8', px: 2, py: 1, mr: 2, color: '#DDA100', alignContent: 'center' }}
            onClick={handleExport('csv')}
            size='large'
            color='primary'
          >
            <Download sx={{ mr: 1, color: '#DDA100' }} />
            <Typography>CSV</Typography>
          </Button>
          <Button
            sx={{ border: '2px solid #A8AEB8', px: 2, py: 1, color: '#DDA100', alignContent: 'center' }}
            onClick={handleExport('xls')}
            size='large'
            color='primary'
          >
            <Download sx={{ mr: 1, color: '#DDA100' }} />
            <Typography>XLS</Typography>
          </Button>
        </Box>
      </Box>

      <Observer
        render={() => {
          return (
            <ReportsTable
              tableData={ReportsStore.reports.value?.data ?? []}
              tableMeta={ReportsStore.reports.value?.meta ?? {}}
              initialSizeOfPage={30}
              pageSizeOptions={[30, 50, 100, 300]}
              tableColumns={generateReportColumns()}
              fetchReports={fetchReports}
            />
          );
        }}
      />
    </div>
  );
}

export { ReportsWrapper };

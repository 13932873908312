import MTextField from '@mui/material/TextField';
import * as React from 'react';

type Props = FieldBaseProps & {
  additional?: TextFieldAdditionalProps;
  view?: BaseViewProps;
  children?: React.ReactNode;
  onChange: FieldOnChange | nil;
};

const TextField = React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
  const { name, value, error, label, children, additional = {}, view = {} } = props;
  const { reservePlaceForHelperText, helperText, ...viewProps } = view;
  const hText = error || helperText || (reservePlaceForHelperText && ' ');
  const classes = additional?.classes && additional.classes();

  const onChange = (e: React.ChangeEventHandler | any) => {
    props.onChange({ name, value: e.currentTarget?.value }, e);
  };

  return (
    <MTextField
      variant='standard'
      {...(additional as any)}
      {...viewProps}
      error={!!error}
      helperText={hText || ' '}
      name={name}
      value={value || ''}
      onChange={onChange}
      label={label}
      classes={classes}
      children={children}
      ref={ref}
    />
  );
});

export { TextField };

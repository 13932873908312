import { Button, Paper } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import shortid from 'shortid';

import { CONFIRM_POPUP_TYPES, SQUAD } from 'src/constants';

import { withConfirmModal, withErrorClean } from 'hocs';

import { LoadingDots } from 'components/LoadingDots';
import { Table } from 'components/Table';

import { t } from 'utils';

import { Distance } from 'models';

import { squadsService } from 'services';

import { Errors as ErrorsStore, helperDistancesStore, squadsStore } from 'stores';

import { Item } from './Item';
import { NewItem } from './NewItem';
import { THead } from './THead';

type OriginalProps = {
  errorsStore?: ErrorsStore;
  distance: Distance;
  onClose: () => void;
  onLoadMore: Function;
  hasMoreDistances: boolean;
} & RouterProps;

type Props = OriginalProps & WithConfirmModalProps<'deleteSquadPopup'> & HOC.withIntl;

type State = {
  isAddNew: boolean;
};

const action = `UPDATE_${SQUAD}`;

@withConfirmModal('deleteSquadPopup', {
  i18Title: 'squads.confirmPopup.mainTitle',
  i18Body: 'squads.confirmPopup.mainBody',
  type: CONFIRM_POPUP_TYPES.confirm,
})
@withRouter
@inject('errorsStore', 'helperDistancesStore')
@withErrorClean(action)
@observer
class SquadsFormImpl extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    isAddNew: false,
  };

  deleteSquad = async (id: number) => {
    const { distance } = this.props;

    await squadsService.deleteDistanceSquad(distance.value.id, id);
  };

  onDeleteSquadWrapper = (id: number, name: string) => () => this.props.deleteSquadPopup.open(() => this.deleteSquad(id));

  addSquad = async (name: string) => {
    const { distance } = this.props;

    const status = await squadsService.addDistanceSquad(distance.value.id, name);
    return status;
  };

  updateSquad = async (squadId: number, name: string) => {
    const { distance } = this.props;

    const status = await squadsService.updateDistanceSquad(distance.value.id, squadId, name);
    return status;
  };

  items = (): Array<React.ReactNode> => {
    const { hasMoreDistances, onLoadMore } = this.props;
    const { selected } = helperDistancesStore;

    if (!selected) {
      return [];
    }
    const valuesForDropdown = squadsStore.valuesForDropdown();

    const items = valuesForDropdown.map((squadItem, index) => {
      const isLastItem = valuesForDropdown.length - 1 === index;
      return (
        <Item
          key={shortid()}
          item={squadItem}
          distance={selected}
          onDeleteSquadWrapper={this.onDeleteSquadWrapper}
          onEditSquad={this.updateSquad}
          isLastItem={isLastItem && hasMoreDistances}
          onVisible={() => {
            hasMoreDistances && onLoadMore();
          }}
        />
      );
    });

    if (this.state.isAddNew) {
      items.unshift(
        <NewItem
          key={shortid()}
          distance={selected}
          onAddSquad={this.addSquad}
          onCloseCreating={() => this.setState({ isAddNew: false })}
        />,
      );
    }

    return items;
  };

  getContent = () => {
    let Content;
    const { hasMoreDistances } = this.props;
    if (squadsStore.valuesForDropdown().length > 0 || this.state.isAddNew) {
      Content = [
        <div key='component-list' className='component-list squads-list'>
          <Paper className='squads-scrollable-wrapper'>
            <Table
              className='table-list'
              pagination={
                hasMoreDistances && (
                  <div className='loader'>
                    <LoadingDots />
                  </div>
                )
              }
            >
              <THead />
              <TableBody>{this.items()}</TableBody>
            </Table>
          </Paper>
        </div>,
      ];
    } else {
      Content = <div className='squads-no-content'>{t.staticAsString('racers.squads.noSquads')}</div>;
    }

    return Content;
  };

  render() {
    const { onClose } = this.props;

    return (
      <div className='content main-squads-form'>
        <h1 className='squads-title'>
          {t.staticAsString('racers.squads.manageSquads')}

          <Button onClick={() => this.setState({ isAddNew: true })} color='primary' autoFocus>
            {t.staticAsString('squads.controls.add')}
          </Button>
        </h1>

        {this.getContent()}

        <div className='btn-group'>
          <Button className='close' onClick={onClose}>
            {t.staticAsString('racers.squads.close')}
          </Button>
        </div>
      </div>
    );
  }
}

export const SquadsForm = SquadsFormImpl as unknown as React.ComponentType<OriginalProps>;

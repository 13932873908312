import { makeStyles } from '@mui/styles';
import * as React from 'react';

import { ScrollableTab } from './ScrollableTab';

const locales: availableLocales[] = ['en', 'sv', 'fi', 'fr', 'no', 'es'];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10%',
  },
}));

export const ScrollableTabContainer = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  const isFinished = (locale) => {
    switch (locale) {
      case 'en':
        return false;
      case 'sv':
        return true;
      default:
        return false;
    }
  };

  return (
    <div className={classes.root}>
      <ScrollableTab locales={locales} onChange={onChange} value={value} isFinished={isFinished} />
    </div>
  );
};

import axios from 'axios';
import { values } from 'lodash';

import { TEAM, DISTANCES_URL, TEAMS_URL } from 'src/constants';

import { t, request, action } from 'utils';

import { toastStore } from 'stores';

import { handleMessageErrors } from '../utils';

import { Teams as TeamsStore } from '../stores';
import { teamsStore } from '../stores';

class LoadTeamService implements ExtensionableSearchService, ExtensionableSortService {
  store: TeamsStore;

  constructor(store: TeamsStore) {
    this.store = store;
  }

  @request({ action: `LOAD_${TEAM}S` })
  async loadResourcesRequest(id: number, params: FiltersType): Promise<any> {
    return axios.get(`${DISTANCES_URL}/${id}/teams`, {
      params,
    });
  }

  @action({ action: `LOAD_${TEAM}S_WITHOUT_PRELOADER`, minRequestTime: 800 })
  async loadResourcesWithoutPreloader(distanceId: number, newParams: FiltersType = {}, pageNum: number = 1): Promise<any> {
    const { params, page, filters } = this.store;
    const pageToBeLoaded = page !== pageNum && pageNum ? pageNum : page;

    const queryParams = {
      ...params,
      ...newParams,
      page: pageToBeLoaded,
    };

    const [status, response] = await this.loadResourcesRequest(distanceId, queryParams);

    if (status) {
      const values = response.data.data;
      this.store.updateCurrentDistance(distanceId);
      this.store.addValues(values, pageToBeLoaded, { ...filters, ...newParams });

      const paginationMeta = response.data.meta && response.data.meta.pagination;
      if (paginationMeta) {
        this.store.addPaginationMeta(paginationMeta);
      }
    }

    return response;
  }

  @action({ action: `LOAD_${TEAM}S`, minRequestTime: 800 })
  async loadResources(distanceId: number, params: FiltersType = {}, pageNum: number = 1): Promise<any> {
    return await this.loadResourcesWithoutPreloader(distanceId, params, pageNum);
  }

  @request({ action: `CREATE_${TEAM}` })
  async createResourceRequest(distance_id: number, team: TeamType): Promise<any> {
    return axios.post(`${DISTANCES_URL}/${distance_id}/teams`, team);
  }

  @action({ action: `CREATE_${TEAM}` })
  async createResource(team: TeamType, distance_id: number, closeModal: Function): Promise<any> {
    const [status, response] = await this.createResourceRequest(distance_id, team);

    if (status) {
      this.loadResources(distance_id);
      closeModal();
      toastStore.show(t.staticAsString('teams.createSuccess'));
    } else {
      handleMessageErrors(response.data.message);
    }
  }

  @request({ action: `UPDATE_${TEAM}` })
  async updateResourceRequest(params: Object, team_id: number, distance_id: number): Promise<any> {
    return axios.patch(`${TEAMS_URL}/${team_id}`, params);
  }

  @action({ action: `UPDATE_${TEAM}` })
  async updateResource(team: Object, item: TeamType | AnyObject): Promise<any> {
    const [status, response] = await this.updateResourceRequest(team, item.id, item.distance_id);

    if (status) {
      this.store.updateValue(item as any);
      // closeModal()
      toastStore.show(t.staticAsString('teams.updateSuccess'));
    } else {
      toastStore.show(values(response.data.errors).join(' '));
    }

    return status;
  }

  @request({ action: `RESET_${TEAM}` })
  deleteValueRequest(distance_id: number, id: number): any {
    return axios.delete(`${TEAMS_URL}/${id}`);
  }

  @action({ action: `DELETE_${TEAM}` })
  async deleteValue(distance_id: number, id: number) {
    const [status] = await this.deleteValueRequest(distance_id, id);

    if (status) {
      this.loadResources(distance_id);
      toastStore.show(t.staticAsString('teams.deleteSuccess'));
    }
  }

  @request({ action: `EXPORT_${TEAM}S` })
  async exportResourceRequest(distance_id: number, type: string): Promise<any> {
    return axios.get(`${DISTANCES_URL}/${distance_id}/export/startlist`, { params: { type } });
  }

  @action({ action: `EXPORT_${TEAM}S` })
  async exportResource(distance_id: number, type: string): Promise<any> {
    const [status, response] = await this.exportResourceRequest(distance_id, type);

    if (status) {
      window.open(response.data.url);
    }
  }

  async load(): Promise<void> {
    const distanceId = this.store.distanceId;
    await this.loadResourcesWithoutPreloader(distanceId);
  }
}

export { LoadTeamService };
export default new LoadTeamService(teamsStore);

export const remapIndexes = (values: Array<CheckpointType>): Array<CheckpointType> => {
  // Special checkpoints index remap
  // Keep all, but not deleted, checkpoints indexed in incremental order
  let i = 0;
  return values.map((el) => {
    if (el._delete) {
      return el;
    }
    return {
      ...el,
      index: i++,
    };
  });
};

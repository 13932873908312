import axios from 'axios';

import { TRANSACTIONS_URL } from 'src/constants';
import { EXPORT_DATA, EXPORT_DATA_SIZES } from 'src/constants';

import { appEnvControl } from 'utils/appEnvironment';
import { handleExport } from 'utils/monitorLoadingJob';

import { exportJobService } from 'services';

import { progressStore } from 'stores';

import { TransactionConstants } from '../constants/transaction.constants';

import { filtersStore } from '../stores/filters.store';

import { TxFilters } from '../types/filters.type';
import { exportTransactions } from './export';

export namespace TransactionsService {
  export async function load<T>(filters?: TxFilters) {
    progressStore.log(TransactionConstants.Table, 'progress');
    try {
      const data = await axios.get<T>(TRANSACTIONS_URL, {
        params: {
          ...filters,
          per_page: 50,
        },
      });
      return data.data;
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(TransactionConstants.Table, 'completed');
    }
  }

  export async function exportTransactions(type: string) {
    progressStore.log(TransactionConstants.Table, 'progress');
    try {
      const [isOk, response] = await exportJobService.exportPayments(type, filtersStore.getFilters());

      if (!isOk) {
        return;
      }
      const { id } = response.data.data;
      const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
      let estimatedLoadingTime = 6000 / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second
      if (6000 > maxTotal) {
        estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
      }

      handleExport({
        id,
        estimatedLoadingTime,
        fileName: `Transactions.${type || 'xlsx'}`,
        onRetry: () => exportTransactions(type),
      });
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(TransactionConstants.Table, 'completed');
    }
  }
}

import { reaction } from 'mobx';

import { form } from 'stores';

import { COUPONS_FORM_ID } from '../constants';

import { couponFormDistancesService } from '../services';

import { couponFormDisposers, couponFormDistanceStore } from '../stores';

export const registerCouponFormRaceSelectReaction = () => {
  couponFormDisposers.register(
    reaction(
      () => form.fetch(COUPONS_FORM_ID, 'race_id'),
      async (race: RaceType) => {
        couponFormDistanceStore.clear();
        form.onChange(COUPONS_FORM_ID, 'distances', []);
        if (Boolean(race)) await couponFormDistancesService.loadDistances(race.id);
      },
    ),
  );
};

import Paper from '@mui/material/Paper';
import MaterialTable from '@mui/material/Table';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';

import { windowSize } from 'stores';

type Props = {
  classes: {
    table: string;
    root: string;
    scrollable: string;
    notScrollable: string;
  };
  children: React.ReactNode;
  pagination?: React.ReactNode;
  className: string;
};

type State = {
  scrollable: boolean;
};

@observer
class Table extends React.Component<Props, State> {
  static defaultProps = {
    classes: { root: 'table-root', table: 'table', scrollable: 'scrollable', notScrollable: 'not-scrollable' },
    className: 'table-root',
  };

  state = { scrollable: false };

  tableRef = React.createRef<HTMLTableElement>();

  isScrollable = () => {
    const width = this.width();
    return windowSize.widthWithoutSidebar - width < 0;
  };

  width = (): number => {
    const { current } = this.tableRef;

    if (!current) {
      return 0;
    }
    return current.scrollWidth;
  };

  calcTableScroll = () => {
    const { scrollable } = this.state;

    const isNewScrollable = this.isScrollable();

    if (scrollable === isNewScrollable) {
      return;
    }

    this.setState({ scrollable: isNewScrollable });
  };

  componentDidUpdate() {
    this.calcTableScroll();
  }

  componentDidMount() {
    this.calcTableScroll();
  }

  render() {
    // eslint-disable-next-line
    windowSize.value;

    const { classes, children, pagination, className } = this.props;

    const { scrollable } = this.state;
    return (
      <Paper className={classNames(classes.root, className)}>
        <MaterialTable
          ref={this.tableRef}
          className={classNames(classes.table, { [classes.scrollable]: scrollable, [classes.notScrollable]: !scrollable })}
        >
          {children}
        </MaterialTable>
        {pagination}
      </Paper>
    );
  }
}

export { Table };

import { getDistanceId } from '../utils';

import { teamsService, racersService } from '../services';

import { isParticipantsPresentStore } from '../stores';

/**
 * @description
 * Load racers and teams per current distance
 * and write simple boolean value is there any racers or teams in distance
 */
async function checkIsThereParticipants() {
  const distanceId = getDistanceId();

  if (!distanceId) {
    return;
  }

  const responses = await Promise.all([
    teamsService.isDistanceHasTeams(distanceId, { limit: 1, filter: 'id' }),
    racersService.isDistanceHasRacers(distanceId, { limit: 1, filter: 'id' }),
  ]);

  const isThereParticipants = responses.some((response) => Boolean(response));

  if (isThereParticipants) {
    isParticipantsPresentStore.on();
  } else {
    isParticipantsPresentStore.off();
  }
}

export { checkIsThereParticipants };

import { Gender, RawGenderJSON } from '../types/gender.type';

export function mapGender(raw: RawGenderJSON.Data): Gender {
  const { female, male, other, unknown } = raw.gender_count.buckets;
  const fCount = female.doc_count;
  const mCount = male.doc_count;
  const oCount = other.doc_count + unknown.doc_count;
  const biggestCount = Math.max(fCount, mCount, oCount);
  const percentOfBiggest = +((biggestCount / [fCount, mCount, oCount].reduce((a, b) => a + b, 0)) * 100).toFixed(0);

  // find dominant gender
  let dominantGender = 'unknown';
  if (fCount === biggestCount) {
    dominantGender = 'female';
  } else if (mCount === biggestCount) {
    dominantGender = 'male';
  } else if (oCount === biggestCount) {
    dominantGender = 'other';
  }

  const out = {
    dominantGender,
    dominant: percentOfBiggest,
    total: fCount + mCount + oCount,
    data: [
      {
        name: 'Male',
        value: mCount,
        fill: '#FCC75D',
      },
      {
        name: 'Female',
        value: fCount,
        fill: '#7CCBA5',
      },
      {
        name: 'Other',
        value: oCount,
        fill: '#5DA6FC',
      },
    ],
  };

  const checkEmpty = () => {
    if (out.total === 0) {
      out.data.forEach((entry) => (entry.value = 1));
    }
  };

  checkEmpty();

  return out;
}

import { t } from 'utils';

const waves = {
  _validation: {
    name: {
      presence: {
        message: () => t.staticAsString('shared.errors.presence'),
        allowEmpty: false,
      },
      length: {
        minimum: 1,
        maximum: 50,
      },
    },
  },
};

export { waves };

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import React from 'react';

import { Icon } from 'components/Icon';

import { t } from 'utils';

type Props = {
  onClick: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    [theme.breakpoints.down(1000)]: {
      fontSize: '22px !important',
    },
  },
}));

const AddNewDistance = ({ onClick }: Props) => {
  const c = useStyles();
  return (
    <li className='add-new-distance-small'>
      <div className={classnames('title', c.title)}>{t.staticAsString('races.detail.distances')}</div>
      <Button onClick={onClick} variant='contained' className='button'>
        {t.staticAsString('races.detail.distances.add')}
        <AddRoundedIcon className='icon' />
      </Button>
    </li>
  );
};

const AddFirstDistance = ({ onClick }: Props) => {
  return (
    <div className='add-new-distance' onClick={onClick}>
      <Icon value='add-big' className='icon' />
      <h5 className='action-title'>{t.staticAsString('races.detail.distances.addFirst')}</h5>
      <span className='sub-title'>{t.staticAsString('races.detail.distances.addFirstSub')}</span>
    </div>
  );
};

export { AddNewDistance, AddFirstDistance };

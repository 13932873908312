import { cloneDeep } from 'lodash';

import { checkPointStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { CheckPoint } from 'src/modules/Distances/components/shared/Steps/GPX/types';

export const getActiveCheckPoint = (): CheckPoint | null => {
  const checkPoints = cloneDeep(checkPointStore.value) as Array<CheckPoint>;
  const activeCheckPoint = checkPoints.filter((item) => item.type === 'active');

  if (activeCheckPoint && activeCheckPoint.length > 0) {
    return activeCheckPoint[0];
  }

  return null;
};

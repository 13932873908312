import { Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
import React from 'react';

export const ActionButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.grey11),
    backgroundColor: theme.palette.grey11,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    fontSize: '14px',
    '&:hover': {
      color: theme.palette.getContrastText(theme.palette.grey4),
      backgroundColor: grey[200],
    },
  },
}))(Button);

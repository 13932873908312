import { t } from 'src/utils';

import { Transaction } from '../types/transaction.type';

export function prepareItemsTable(tx: Transaction) {
  const items = tx.payment.order.items;
  return items.map((item) => {
    return { ...item, vat_excl: item.vat_excl - item.discount_amount / 100 };
  });
}

export const setTransactionType = (type: number) => {
  const labels = {
    2: t.staticAsString(`reconciliation.transactions.authorization`),
    3: t.staticAsString(`reconciliation.transactions.capture`),
    4: t.staticAsString(`reconciliation.transactions.refunded`),
  };

  return labels[type];
};

import moment from 'moment';

import { RawTurnoversJSON, Turnovers } from '../types/turnover.type';
import { mapLabels } from './common';

export function mapTurnovers(raw: RawTurnoversJSON.Data): Turnovers {
  return {
    total: raw.correlation.buckets.all.current.amount.value / 100,
    trend: {
      current: raw.correlation.buckets.all.current.doc_count,
      previous: raw.correlation.buckets.all.previous.doc_count,
      rate: raw.correlation.buckets.all?.rate?.value ?? null,
    },
    data: raw.orders.histogram.buckets.map((bucket) => {
      return {
        value: bucket.amount?.value ? bucket.amount?.value / 100 : 0,
        label: mapLabels(bucket.key_as_string),
      };
    }),
  };
}

import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import styled from 'styled-components';

import { InputWrapper, WrappedCheckbox } from 'components/Form';

import { t } from 'utils';

const Wrapper = styled.div`
  width: calc(100% - 64px);
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  .MuiFormControlLabel-root {
    margin-right: 8px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;

  .MuiSvgIcon-root {
    margin: 0;
  }
`;

const StyledInfoOutlined: AnyFunction | any = withStyles((theme) => ({
  root: {
    cursor: 'pointer',
    color: theme.palette.grey6,
    marginLeft: 7,
  },
}))(InfoOutlined);

type Props = {
  isChecked: boolean;
  onToggleCheckbox: Function;
};

export const CheckboxAboutUsing = (props: Props) => {
  return (
    <Wrapper>
      <InputWrapper
        name='raceband_used'
        settings={{
          required: true,
          label: t.staticAsString('distances.steps.helperText.gpx.checkbox.text'),
          onChange: props.onToggleCheckbox,
          value: props.isChecked,
        }}
        Component={WrappedCheckbox}
      />

      <IconWrapper>
        <Tooltip title={t.staticAsString('distances.steps.helperText.gpx.checkbox.tooltip')}>
          <StyledInfoOutlined />
        </Tooltip>
      </IconWrapper>
    </Wrapper>
  );
};

class Customer {
  value: CustomerType;

  constructor(value: CustomerType) {
    this.value = value;
  }

  fullName(): string {
    const { value } = this;
    return `${value.firstname || 'Not Set'} ${value.lastname || 'Not Set'}`;
  }

  countryName(): string {
    const {
      value: { country },
    } = this;

    return `${country ? country.short_name_en : ''}`;
  }
}

export { Customer };

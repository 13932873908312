import { Observer } from 'mobx-react';
import { ReactElement, useCallback } from 'react';

import { SystemUsersViewActions } from '../../../../actions/view.actions/systemUsers.view.actions';

import { SystemUserPaymentsStore } from '../../../../stores/systemUserPayments.store';

import { PaymentFiltersMapper } from '../../../../mappers/payment-filters.mapper';
import { PaymentsTable } from '../Table';
import { generateSystemUserColumns } from '../Table/Columns';

function PaymentsTableWrapper(): ReactElement {
  const fetchPayments = useCallback(({ pageIndex = 0, pageSize, filters, sortBy }) => {
    let orderBy = 'created_at';
    let sortedBy = 'DESC';

    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];

      orderBy = id.includes('.') ? id.split('.').join('|') : id;
      sortedBy = desc ? 'DESC' : 'ASC';
    }

    SystemUsersViewActions.loadSystemUserPayments({
      orderBy,
      sortedBy,
      page: pageIndex + 1,
      limit: pageSize,
      ...PaymentFiltersMapper.toBackend(filters),
    });
  }, []);

  return (
    <div style={{ padding: 0, backgroundColor: 'white' }} className='content main-container-list races-list'>
      <Observer
        render={() => {
          return (
            <PaymentsTable
              tableData={SystemUserPaymentsStore.payments.value?.data ?? []}
              tableMeta={SystemUserPaymentsStore.payments.value?.meta ?? {}}
              initialSizeOfPage={30}
              pageSizeOptions={[30, 50, 100, 300]}
              tableColumns={generateSystemUserColumns()}
              fetchSystemUsers={fetchPayments}
            />
          );
        }}
      />
    </div>
  );
}

export { PaymentsTableWrapper };

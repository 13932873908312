import { uniqueId } from 'lodash';
import { observer } from 'mobx-react';
import { RaceField } from 'modules/SmartCampaignLinks/Form/components/RaceField';
import * as React from 'react';

import { InputWrapper, TextField } from 'src/components/Form';

import { t } from 'utils/t';

import { Button } from '../../../../components/core';

import { CAMPAIGN_LINKS_FORM_ID } from '../constants';

import { mount, unmount } from '../actions';

import { form } from '../../../../stores';

import { Title } from './Styled';

export const LinkFields = observer(() => {
  React.useEffect(() => {
    mount();
    return unmount;
  }, []);

  const raceKeys = () => {
    return Object.keys(form.fetch(CAMPAIGN_LINKS_FORM_ID) ?? {}).filter((key) => key.includes('races'));
  };

  const handleAddEvent = () => {
    const rand = Math.random().toString(36).substring(7);
    form.merge(CAMPAIGN_LINKS_FORM_ID, { [`races-${rand}`]: null });
  };

  const handleRemoveEvent = async (formKey: string) => {
    const data = Object.assign({}, form.fetch(CAMPAIGN_LINKS_FORM_ID));
    delete data[formKey];
    form.remove(CAMPAIGN_LINKS_FORM_ID, formKey);
  };

  return (
    <>
      <InputWrapper
        name='title'
        settings={{
          label: t.staticAsString('campaignLinks.landingTitle'),
          view: {
            variant: 'outlined',
            fullWidth: true,
            required: true,
          },
        }}
        Component={TextField}
      />
      <InputWrapper
        name='name'
        settings={{
          label: t.staticAsString('campaignLinks.nameForCode'),
          view: {
            variant: 'outlined',
            fullWidth: true,
            required: true,
          },
        }}
        Component={TextField}
      />
      <InputWrapper
        name='description'
        settings={{
          label: t.staticAsString('campaignLinks.description'),
          view: {
            type: 'textarea',
            multiline: true,
            minRows: 3,
            variant: 'outlined',
            fullWidth: true,
            required: true,
          },
        }}
        Component={TextField}
      />
      {/* <FromTo /> */}
      <Title>{t.staticAsString('campaignLinks.linkDetails')}</Title>
      {raceKeys().map((formKey) => (
        <RaceField formKey={formKey} key={formKey} handleRemove={handleRemoveEvent} />
      ))}
      <Button onClick={handleAddEvent} type='add'>
        event
      </Button>
    </>
  );
});

import { makeStyles } from '@mui/styles';

export const genericReportStyles = makeStyles({
  reportGenericTitle: {
    height: '22px',
    color: 'rgb(43, 53, 61)',
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  date: {
    height: '17px',
    color: 'rgb(43, 53, 61)',
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '24px',
  },
});

export const cardContentStyles = makeStyles({
  invoiceDoNotPay: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  cardSubtitle: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '23px',
    color: '#262E35',
    marginTop: 24,
    marginBottom: 8,
  },
  cardEntryLeft: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#696969',
  },
  cardEntryRight: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#262E35',
  },
  card: {
    border: '1px solid #E0E0E0',
  },
  cardSection: {
    padding: '4px 16px',
  },
  statementSummaryBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  statementSummarySubtitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#696969',
  },
  statementSummaryLeft: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
});

import { Box, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { isCalendarRaceType } from 'modules/Races/utils/isCalendarRaceType';
import moment from 'moment';
import * as React from 'react';
import { FC, useEffect, useRef } from 'react';
import { generatePath, Link } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { Show } from 'components/Condition';
import { Icon } from 'components/Icon';

import { t, formatter, history } from 'utils';
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver';

import { Distance } from 'models';

type Props = {
  currency: string;
  isRaceEnded?: boolean;
  distance: Distance;
  onDelete: React.MouseEventHandler;
  onDuplicate: React.MouseEventHandler;
  locale: string;
  isLastItem?: boolean;
  onVisible?: Function;
} & RouterProps;

const SINGLE_DISTANCE = 'single';

export const Item: FC<Props> = (props) => {
  const { distance, isLastItem = false, onVisible } = props;
  const jsonValue = distance.value;
  const localizedRaceDate = moment(jsonValue.race_date).locale(props.locale).format('DD MMM YYYY');
  const formattedRaceLength = formatter.integer(distance.raceLength());
  const raceLengthSplitted = formattedRaceLength.split('.');
  const ref = useRef<HTMLLIElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;
  const {
    distance: {
      value: { registration_ends_at, price },
    },
    currency,
  } = props;

  useEffect(() => {
    if (isVisible) {
      onVisible && onVisible();
    }
  }, [isVisible]);

  const onEdit = () => {
    const { value } = props.distance;

    const redirectUrl = generatePath(ROUTES.distanceRoute, {
      raceId: value.race_parent_id,
      id: value.id,
    });

    history?.push(redirectUrl);
  };

  const getUrl = (): string => {
    const { value } = props.distance;
    let url;

    if (value.type === SINGLE_DISTANCE) {
      url = `/races/${value.race_parent_id}/distances/${value.id}/racers`;
    } else {
      url = `/races/${value.race_parent_id}/distances/${value.id}/teams`;
    }
    return url;
  };

  const isRenderPrice = () => {
    if (isCalendarRaceType.get()) return false;

    const isDistanceEnded = moment(registration_ends_at).add(1, 'd').isBefore(new Date());
    return !(!price || isDistanceEnded);
  };

  return (
    <li className='distance-item' {...(isLastItem ? { ref } : {})}>
      <div className='distance-drag-handle'>
        <Icon className='distance-drag-icon' value='drag-icon' />
      </div>

      <div className='main-title title'>
        {isCalendarRaceType.get() ? distance.value.name : <Link to={getUrl()}>{distance.value.name}&nbsp;</Link>}
      </div>

      <Box className='action-group' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Tooltip title={t.staticAsString('shared.distance.duplicate')}>
          <IconButton className='icon-button-distance-action' onClick={props.onDuplicate}>
            <Icon value='copy-duplicate' className='icon' />
          </IconButton>
        </Tooltip>
        <Tooltip title={t.staticAsString('races.detail.distances.delete')}>
          <IconButton className='icon-button-distance-action' onClick={props.onDelete}>
            <Icon value='delete' className='icon' />
          </IconButton>
        </Tooltip>
        <Tooltip title={t.staticAsString('races.detail.distances.edit')}>
          <IconButton className='icon-button-edit' onClick={onEdit}>
            <Icon value='edit-outlined' className='icon' />
          </IconButton>
        </Tooltip>
      </Box>

      <div className='stats'>
        <Show if={Boolean(formattedRaceLength)}>
          <Tooltip title={`${formattedRaceLength} km`}>
            <Typography className='race-length' noWrap>
              {raceLengthSplitted[0]}
              <Show if={Boolean(raceLengthSplitted[1])}>
                .<span className='distance-length-meters'>{raceLengthSplitted[1]}</span>
              </Show>
              km
            </Typography>
          </Tooltip>
        </Show>
      </div>

      <div className='race-info'>
        <Typography className='info-text'>
          {t.staticAsString(`shared.distanceType.${jsonValue.type}` as TranslationLockedKeys)} <Box className='dot'>&middot;</Box>
        </Typography>
        <Show if={isRenderPrice()}>
          <Typography className='info-text'>
            {localizedRaceDate} <Box className='dot'>&middot;</Box>
          </Typography>
          <Typography className='info-text'>{`${price} ${currency}`}</Typography>
        </Show>
        <Show if={!isRenderPrice()}>
          <Typography className='info-text'>{localizedRaceDate}</Typography>
        </Show>
      </div>

      <div className='participants'>
        <div className='info-block'>
          <Typography className='info-text'>
            <Show if={Boolean(jsonValue.race_qty)}>
              {distance.entityCount()} / {jsonValue.race_qty}
            </Show>
            <Show if={!Boolean(jsonValue.race_qty)}>{distance.entityCount()}</Show>
          </Typography>
          <Icon value='person' className='icon' />
        </div>
      </div>
    </li>
  );
};

import { observer } from 'mobx-react';
import { membersTeamService } from 'modules/StartList/services';
import * as React from 'react';

import { ADDED_AT, COUNTRY_ID, GENDER } from 'src/constants';

import { FormattedDate } from 'components/FormattedDate';

import { commonDateFormats, gender } from 'utils';

import { countriesStore } from 'stores';

import { Input as CustomInput } from './Input';
import { Select } from './Select';

const CUSTOM_VALUES: AnyObject = {
  class_id: { name: 'classes', value: 'title', value_en: 'title_en' },
  discipline_id: { name: 'disciplines', value: 'title', value_en: 'title_en' },
  wave_id: { name: 'waves', value: 'name', value_en: 'name_en' },
  price_id: { name: 'prices', value: 'value' },
};

type Props = {
  item: any;
  errors: AnyObject;
  field: string;
  distance: DistanceType;
  isEdit: string | null;
  saveRacer: (...args: any[]) => void;
  onChange: (name: string, value: any, changedValue: any, callback: Function) => void;
};

const TEAM_FIELD = 'team_id';

const PERSONAL_FIELDS: AnyObject = {
  bib_number: CustomInput,
  city: CustomInput,
  country_id: Select,
  nationality_id: Select,
  email: CustomInput,
  firstname: CustomInput,
  lastname: CustomInput,
  name: CustomInput,
  gender: Select,
  wave_id: Select,
  class_id: Select,
  discipline_id: Select,
  price_id: Select,
  team_id: Select,
  comment: CustomInput,
  external_swimrun_id: CustomInput,
};

@observer
class PersonalFields extends React.Component<Props> {
  onChange = (field: string, value: any, callback: Function = () => {}) => {
    const { onChange } = this.props;
    onChange(field, value, value, callback);
  };

  getCustomValue = (field: string) => {
    const { distance, item } = this.props;
    const object = (distance as AnyObject)[CUSTOM_VALUES[field].name].find((obj: AnyObject) => obj.id === (item as AnyObject)[field]);
    const value = CUSTOM_VALUES[field].value;

    return (object && object[value]) || '';
  };

  getContent = () => {
    const { field, isEdit, distance, item, saveRacer } = this.props;
    const itemCopy: AnyObject = { ...item };
    const Field = PERSONAL_FIELDS[field];

    const rest = {
      hasMore: membersTeamService.store.hasMore,
      loadMore: () => {
        membersTeamService.loadTeamsForMembers(distance.id);
      },
    };

    if (isEdit === field && Field) {
      return (
        <Field
          onChange={this.onChange}
          item={itemCopy}
          field={field}
          value={itemCopy[field]}
          errors={[]}
          distance={distance}
          saveRacer={saveRacer}
          {...(field === 'team_id' ? rest : {})}
        />
      );
    }

    if (CUSTOM_VALUES[field]) {
      return this.getCustomValue(field);
    }

    if (field === GENDER && item[field] !== null) {
      return gender.format(+item[field] as genderValue);
    }

    if (field === COUNTRY_ID) {
      const neededCountry = countriesStore.findModelValue(+(item as AnyObject)[field]);
      if (!neededCountry) {
        return '';
      }

      return neededCountry.name();
    }

    if (field === ADDED_AT) {
      return <FormattedDate value={item[field]} format={commonDateFormats.shortDateWithTime} />;
    }

    if (field === TEAM_FIELD) {
      return this._renderTeamNameWithCounter();
    }

    return (item as AnyObject)[field];
  };

  _renderTeamNameWithCounter(): nil | string {
    const { distance, item } = this.props;
    const team = item.team;
    if (!team) {
      return null;
    }

    const labelQuantity = `(${team.racers_count || 0}/${distance.max_members_quantity})`;

    return `${team.name}${labelQuantity}`;
  }

  render() {
    const content = this.getContent();
    return content || null;
  }
}

export { PersonalFields };

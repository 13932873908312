import axios from 'axios';

import { SPORT_TYPE, SPORT_TYPES_URL, ROUTES } from 'src/constants';

import { request, action } from 'utils';
import { history } from 'utils';

import { LoadableService } from 'services';

import { sportTypesStore } from 'stores';
import { SportType as SportTypeStore } from 'stores';

class LoadService extends LoadableService<SportTypeType, FiltersType, SportTypeStore> {
  constructor(store: SportTypeStore) {
    super({ store, resourcesUrl: SPORT_TYPES_URL, resourceUrl: SPORT_TYPES_URL, resource: SPORT_TYPE });
  }

  @request({ action: `LOAD_${SPORT_TYPE}S` })
  async loadResourcesRequest(params: FiltersType): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @action({ action: `LOAD_${SPORT_TYPE}` })
  async loadResources(params: FiltersType = {}, pageNum: number = 1): Promise<any> {
    const {
      paginationMeta: { total_pages = 0 },
      page,
    } = sportTypesStore;

    if (total_pages > 0 && total_pages <= page) {
      return;
    }

    return super.loadResources({ ...params, orderBy: 'index', sortedBy: 'asc' }, pageNum);
  }

  @request({ action: `LOAD_${SPORT_TYPE}` })
  async loadResourceRequest(id: string, params: FiltersType): Promise<any> {
    return super.loadResourceRequest(id, params);
  }

  @action({ action: `LOAD_${SPORT_TYPE}` })
  async loadResource(id: string, params: FiltersType = {}): Promise<any> {
    return super.loadResource(id, params);
  }

  @action({ action: `CLEAN_${SPORT_TYPE}` })
  clean(): void {
    return super.clean.bind(this)();
  }

  addValues(values: Array<SportTypeType>, page: number, queryParams: FiltersType) {
    this.store.appendValues(values, page, queryParams);
  }

  @request({ action: `DELETE_${SPORT_TYPE}` })
  deleteValueRequest(id: number): any {
    return axios.delete(`${SPORT_TYPES_URL}/${id}`);
  }

  @action({ action: `DELETE_${SPORT_TYPE}` })
  async deleteValue(id: number) {
    const [status] = await this.deleteValueRequest(id);
    if (status) {
      this.store.removeValue(id);
      if (this.store.selected && this.store.selected.id === id) history.push(ROUTES.sportTypesRoute);
    }
  }
}

export { LoadService };
export const loadService = new LoadService(sportTypesStore);

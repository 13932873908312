import { Observer } from 'mobx-react';
import * as React from 'react';
import { NavLink, generatePath } from 'react-router-dom';

import { ROUTES, DISTANCE_TYPE } from 'src/constants';

import { t } from 'utils';

import { AddResult } from '../../components/AddResult';

import { Settings } from '../../containers/Settings';

type Props = {
  type: distanceType;
  distanceId: number;
  raceId: number;
};

const renderWithType = {
  [DISTANCE_TYPE.single]: function ({ raceId, distanceId }: Props) {
    const id = distanceId;
    return (
      <>
        <NavLink to={generatePath(ROUTES.distancesRacersRoute, { raceId, id })}>{t.staticAsString('racers.list.header')}</NavLink>
      </>
    );
  },
  [DISTANCE_TYPE.team]: function ({ raceId, distanceId }: Props) {
    const id = distanceId;
    return (
      <>
        <NavLink to={generatePath(ROUTES.distancesTeamsRoute, { raceId, id })}>{t.staticAsString('teams.list.header')}</NavLink>
        <NavLink to={generatePath(ROUTES.distancesMembersRoute, { raceId, id })}>{t.staticAsString('teams.list.members')}</NavLink>
      </>
    );
  },
};

function NavComponent(props: Props) {
  const { raceId, distanceId, type } = props;
  const id = distanceId;

  const Component = renderWithType[type];

  return (
    <Observer>
      {() => {
        return (
          <div className='start-list-nav'>
            <Component {...props} />
            <NavLink to={generatePath(ROUTES.resultsRoute, { raceId, id })}>{t.staticAsString('teams.list.results')}</NavLink>
            <div className='nav-settings'>
              <AddResult />
              <Settings />
            </div>
          </div>
        );
      }}
    </Observer>
  );
}

const Nav = React.memo<Props>(NavComponent);

export { Nav };

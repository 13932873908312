import { VisibilityOutlined } from '@mui/icons-material';
import { Box, TextField } from '@mui/material';
import { Report } from 'modules/Reconciliation/Reports/types/report.type';
import { formatCurrency } from 'modules/Reconciliation/shared/utils/formatCurrency';
import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnWithLooseAccessor } from 'react-table';

import { AutocompleteSelect } from 'components/Fields';
import { DatePicker } from 'components/Form/Fields';
import { Icon } from 'components/Icon';

import { t } from 'utils';

import { currenciesStore } from 'stores';

const COLUMNS = ['id', 'created_at', 'race_name', 'organizer', 'sum', 'action'] as const;

const title = (prefix: string) => t.static(`reconciliation.reports.${prefix}` as any);

function NumberRangeFilter({ column: { filterValue = [], setFilter, preFilteredRows, id } }) {
  return (
    <Box display='flex' flexDirection='row'>
      <Box minWidth='80px' p={0.5}>
        <TextField
          type='number'
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => {
              return [val === '0' || val ? parseInt(val, 10) : undefined, old[1]];
            });
          }}
          value={filterValue[0] || ''}
          variant='outlined'
          size='small'
          label='From'
        />
      </Box>
      <Box minWidth='80px' p={0.5}>
        <TextField
          type='number'
          value={filterValue[1] || ''}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val === '0' || val ? parseInt(val, 10) : undefined]);
          }}
          variant='outlined'
          size='small'
          label='To'
        />
      </Box>
    </Box>
  );
}

function NumberFilter({ column: { filterValue, setFilter, preFilteredRows, id } }) {
  const handleSetFilter = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setFilter(e.target.value || undefined);
  return (
    <Box p={0.5} mb={1.5}>
      <TextField type={'number'} fullWidth variant='outlined' size='small' value={filterValue || ''} onChange={handleSetFilter} />
    </Box>
  );
}

function AutocompleteFilter({ column: { filterValue, setFilter, selectProps, preFilteredRows, id } }) {
  const options = selectProps.options() || [];
  const searchFilter = (text: string) => {
    return [];
  };

  return (
    <Box minWidth='320px' p={0.5}>
      <AutocompleteSelect
        errors={[]}
        label=''
        name=''
        className='autocomplete-with-search'
        onChange={({ name, value }) => setFilter(value)}
        options={options}
        value={filterValue}
        loading={false}
        variant='outlined'
        size='small'
      />
    </Box>
  );
}

function DateFilter(props) {
  const {
    column: { filterValue, setFilter, preFilteredRows, id },
  } = props;
  return (
    <Box minWidth='250px' p={0.5}>
      <DatePicker
        hideArrow
        view={{
          fullWidth: true,
          readOnly: true,
          variant: 'outlined',
          size: 'small',
        }}
        label=''
        name='created_at'
        value={filterValue}
        onChange={({ name, value }) => {
          setFilter(value);
        }}
        onClear={() => {
          if (id === 'created_at') {
            setFilter(null);
            setFilter(null);
          }
        }}
      />
    </Box>
  );
}

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<Report> & AnyObject } = {
  id: {
    id: 'id',
    Header: title('reportNumber'),
    accessor: (report) => report.id,
    Cell: ({ row: { original } }) => (
      <Link to={`/reconciliation/reports/${original.id}`} style={{ color: 'black', textDecoration: 'underline' }}>
        {original.id}
      </Link>
    ),
    filter: 'between',
    Filter: NumberRangeFilter,
  },
  created_at: {
    id: 'created_at',
    Header: title('raceDate'),
    accessor: (report) => report.created_at,
    Filter: DateFilter,
  },
  race_name: {
    id: 'races.name',
    Header: title('raceName'),
    accessor: (report) => report.race?.name ?? <span style={{ fontWeight: 'bold', color: 'red' }}>{title('notRaceFound')}</span>,
  },
  organizer: {
    id: 'organizers.email',
    Header: title('organizer'),
    accessor: (report) => report.organizer.email,
  },
  sum: {
    id: 'sum',
    Header: title('sum'),
    Filter: NumberFilter,
    accessor: (report) => formatCurrency(report.sum, currenciesStore.currencyById(report.race?.currency_id)?.iso_code ?? ''),
    filterable: true,
  },
  action: {
    id: 'report',
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    Header: title('report'),
    Cell: ({ row: { original } }) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <a href={original.file.url} download={original.file.name}>
          <Icon style={{ cursor: 'pointer' }} value='xls' />
        </a>
      </div>
    ),
  },
};

export function generateReportColumns() {
  return COLUMNS.map((c) => RenderColumns[c]);
}

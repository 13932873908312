import { getOrderStatuses } from 'modules/exportPayment/utils';
import * as React from 'react';

import { SelectFilterWithSearch } from 'components/Filter/DefaultFilters';

import { GENDER_OPTIONS } from '../constants';

type orderStateType = 'ready' | 'awaiting';

export const gender: FilterListDescriptor = {
  filter: {
    persistance: {
      advanced: {
        calcHandler: function (name) {
          return { [name]: '=' };
        },

        calcValue: function (name, value) {
          const gender = GENDER_OPTIONS.find((item: AnyObject) => item.value === value);
          return { [name]: gender?.value };
        },
      },
      prettify: (name, value) => {
        const gender = GENDER_OPTIONS.find((item: AnyObject) => item.value === value) || {};
        return gender?.label;
      },
    },
  },
  frontend: {
    type: 'custom',
    component: (props: FilterProps) => <SelectFilterWithSearch {...props} options={GENDER_OPTIONS as any} />,
  },
  sort: {
    default: true,
    reorderOnSearch: true,
    defaultBy: 'DESC',
    calcOrderBy: () => 'gender',
  },
};

export const state: FilterListDescriptor = {
  filter: {
    persistance: {
      advanced: {
        calcHandler: function (name) {
          return { ['payment_status']: 'in' };
        },

        calcValue: function (name, value) {
          const state = getOrderStatuses().find((item) => item.value === value);
          return { ['payment_status']: state?.key === 1 ? `${state?.key},null` : state?.key };
        },
      },

      prettify: (name, value: orderStateType) => {
        const state = getOrderStatuses().find((item) => item.value === value);
        return state?.label;
      },
    },
  },

  frontend: {
    type: 'custom',
    component: (props: FilterProps) => <SelectFilterWithSearch {...props} options={getOrderStatuses()} />,
  },
};

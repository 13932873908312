import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TableBody from '@mui/material/TableBody';
import { inject, observer } from 'mobx-react';
import { AttachToProfileModal } from 'modules/StartList/components/AttachToProfileModal';
import { ImportErrorsModal } from 'modules/StartList/components/ImportErrorsModal';
import refundService from 'modules/StartList/services/refundService';
import * as React from 'react';
import shortid from 'shortid';

import { ROUTES } from 'src/constants';

import { withProgressSpinner, withSearch, withSorting, withStoresClean } from 'hocs';

import { FilterLabelToolbar } from 'components/Filter/FilterLabelToolbar';
import { Table } from 'components/Table';

import { history, t } from 'utils';

import { helperDistancesService, loadCountryService, squadsService } from 'services';

import {
  confirmationModalStore,
  Country as CountryStore,
  HelperDistances as HelperDistancesStore,
  Locale as LocalStore,
  progressStore,
  Session as SessionStore,
  squadsStore,
} from 'stores';
import squads from 'stores/squads';

import { exportRacers, openForm } from '../../../actions';

import racerService from '../../../services/racersService';

import { startlistRacersStore } from '../../../stores';

import { Nav } from '../../Nav/single';
import { Breadcrumbs } from './Breadcrumbs';
import { Item } from './Item';
import { RacerForm } from './New';
import { Pagination } from './Pagination';
import { SquadsForm } from './Squads';
import { THead } from './THead';
import { Toolbar } from './Toolbar';

type OriginalProps = {
  localeStore: LocalStore;
  countriesStore: CountryStore;
  sessionStore: SessionStore;
  helperDistancesStore: HelperDistancesStore;
  match: AnyObject;
};

type Props = OriginalProps & HOC.withIntl;

type State = {
  isModalOpen: boolean;
  isSquadsModalOpen: boolean;
  isAttachUserModalOpen: boolean;
  distanceId: number | null;
  hasPrice: boolean;

  loadCompleted: boolean;
};

@inject('localeStore', 'helperDistancesStore', 'sessionStore')
@withStoresClean(startlistRacersStore)
@withProgressSpinner(['LOAD_RACERS_DATA'], { hideClassName: 'hidden' })
@withSorting(racerService)
@withSearch(racerService, 'racers.list.table')
@observer
class ListImpl extends React.Component<Props, State> {
  static defaultProps = {
    localeStore: null as any,
    sessionStore: null as any,
  };

  state: State = {
    isModalOpen: false,
    isSquadsModalOpen: false,
    isAttachUserModalOpen: false,

    distanceId: null,
    hasPrice: false,

    loadCompleted: false,
  };

  componentDidMount() {
    this._loadData();
  }

  componentWillUnmount() {
    squads.clearAll();
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { helperDistancesStore } = props;
    const distance: DistanceType = helperDistancesStore.selected || ({} as DistanceType);

    if (state.distanceId !== distance.id) {
      const hasPrices = (distance.prices || []).length > 0;
      const customFieldWithPrice = (distance.custom_fields || []).some((field) => {
        if (field.values) {
          return field.values.some((value) => {
            return Number(value?.price) > 0;
          });
        }

        return false;
      });

      return {
        distanceId: distance.id,
        hasPrice: hasPrices || customFieldWithPrice,
      };
    }

    return null;
  }

  componentDidUpdate(prevProps: Props) {
    const { id } = this.props.match.params;

    const prevId = prevProps.match.params.id;
    if (id !== prevId) {
      startlistRacersStore.clearAll();
      this._loadData();
    }
  }

  items = (): Array<React.ReactNode> => {
    const { helperDistancesStore } = this.props;
    const { hasPrice } = this.state;
    const { selected } = helperDistancesStore;

    if (!selected) {
      return [];
    }

    return (startlistRacersStore.values || []).map((racerItem) => {
      return (
        <Item
          key={shortid()}
          item={racerItem}
          distance={selected}
          deleteRacer={this.onDeleteRacerWrapper}
          handleCopyLink={this.onCopyRegistrationLink}
          handleResend={this.onResend}
          handleAttachToProfile={this.onAttachToProfile}
          handleDeactivateRefundProtect={this.handleDeactivateRefundProtect}
          hasPrice={hasPrice}
          checkedRequiredFields={this.getRequired(selected?.registration_fields || [])}
        />
      );
    });
  };

  onChangeDistance = (e: React.ChangeEvent<HTMLInputElement>) => {
    const distanceId = e.target.value;
    const raceId = this.props.match.params.raceId;
    const { modelSelected } = this.props.helperDistancesStore as any;
    const selectedDistance = modelSelected.race.distances.find((distance: AnyObject) => distance.value.id === distanceId);
    const type = selectedDistance.value.type === 'team' ? 'teams' : 'racers';

    history.push(`${ROUTES.racesRoute}/${raceId}/distances/${distanceId}/${type}`);
  };

  getRequired = (required_fields: Array<AnyObject>): Object => {
    let requiredHash: AnyObject = {};

    required_fields.forEach((item: AnyObject) => {
      requiredHash[item.name] = item.selected;
    });

    return requiredHash;
  };

  createRacer = (): void => {
    this.setState({
      ...this.state,
      isModalOpen: true,
    });
  };

  onClose = (): void => {
    this.setState({
      ...this.state,
      isModalOpen: false,
    });
  };

  editSquads = (): void => {
    this.setState({
      ...this.state,
      isSquadsModalOpen: true,
    });
  };

  onCloseSquads = (): void => {
    this.setState({
      ...this.state,
      isSquadsModalOpen: false,
    });
  };

  onCloseAttachUser = (): void => {
    this.setState({
      ...this.state,
      isAttachUserModalOpen: false,
    });
  };

  onDeleteRacer = (racer_id: number) => {
    const distance = this.props.helperDistancesStore.selected || ({} as DistanceType);

    racerService.deleteValue(distance.id, racer_id);
  };

  onCopyRegistrationLink = async (racerId: number): Promise<void> => {
    const distance = this.props.helperDistancesStore.selected || ({} as DistanceType);
    await racerService.getRegistrationLink(distance.id, racerId);
  };

  onResend = (racerId: number, isSuccesfulEmail: boolean): void => {
    const distance = this.props.helperDistancesStore.selected || ({} as DistanceType);
    racerService.resendInvitation(distance.id, racerId, isSuccesfulEmail);
  };

  onAttachToProfile = (racer: RacerType): void => {
    if (racer.user_id) {
      confirmationModalStore.openModal({
        title: t.staticAsString('racers.confirmPopup.mainTitle'),
        body: t.staticAsString('racers.confirmPopup.attachProfile'),
        type: 'confirm',
        onConfirm: () => {
          openForm(racer);
        },
      });
    } else {
      openForm(racer);
    }
  };

  onDeleteRacerWrapper = (racer_id: number) => {
    confirmationModalStore.openModal({
      title: t.staticAsString('racers.confirmPopup.mainTitle'),
      body: t.staticAsString('racers.confirmPopup.mainBody'),
      type: 'confirm',
      onConfirm: () => {
        this.onDeleteRacer(racer_id);
      },
    });
  };

  handleDeactivateRefundProtect = (racer_id: number) => {
    // TODO add functionality to remove RP later
    refundService.deleteRefund(racer_id, {});
  };

  getContent = () => {
    const { helperDistancesStore, sessionStore } = this.props;
    const { hasPrice } = this.state;
    const { selected } = helperDistancesStore;
    const organizerVerified = sessionStore.isOrganizerVerified;

    if (!selected) {
      return null;
    }
    let Content;

    if (startlistRacersStore.values.length > 0) {
      Content = [
        <div key='component-list' className='component-list racers-list'>
          <Table className='table-list'>
            <THead distance={selected} hasPrice={hasPrice} checkedRequiredFields={this.getRequired(selected.registration_fields || [])} />
            <TableBody>{this.items()}</TableBody>
          </Table>
        </div>,
        <Pagination
          key='pagination'
          startlistRacersStore={startlistRacersStore}
          distance_id={selected.id}
          switchedOnTop={organizerVerified}
        />,
      ];
    } else {
      Content = (
        <div className='racer-no-content'>
          <div className='not-racers'>{t.staticAsString('racers.noRegisteredRacers')}</div>
        </div>
      );
    }

    return Content;
  };

  onEditDistance = () => {
    const { selected } = this.props.helperDistancesStore;

    const raceId = selected?.race?.id;
    const distanceId = selected?.id;

    if (!raceId || !distanceId) {
      return;
    }

    history.push(`${ROUTES.racesRoute}/${raceId}/distances/${distanceId}`);
  };

  onDeleteDistanceWrapper = () => {
    const { helperDistancesStore } = this.props;
    const { modelSelected } = helperDistancesStore;

    if (!modelSelected) {
      return;
    }

    confirmationModalStore.openModal({
      title: t.staticAsString('distances.confirmPopup.mainTitle'),
      body: t.staticAsString('distances.confirmPopup.mainBody', { name: modelSelected.value.name }),
      type: 'confirm',
      confirmationValue: modelSelected.value.name,
      confirmationLabel: t.staticAsString('confirmationModal.distance.label'),
      onConfirm: () => {
        this.onDeleteDistance();
      },
    });
  };

  onDeleteDistance = () => {
    const { selected } = this.props.helperDistancesStore;
    const raceId = selected?.race?.id;
    const distanceId = selected?.id;

    if (!raceId || !distanceId) {
      return;
    }

    helperDistancesService.deleteDistance(raceId, distanceId);
  };

  _loadData = async () => {
    progressStore.log('LOAD_RACERS_DATA', 'progress');
    const distanceId = this.props.match.params.id;
    squadsService.store.setSelectedDistanceId(distanceId);
    await loadCountryService.loadResources();
    await helperDistancesService.loadRaceDistance(distanceId);
    await racerService.loadResources(distanceId);

    await squadsService.loadDistanceSquads(distanceId, { limit: 20000 });
    progressStore.log('LOAD_RACERS_DATA', 'completed');

    this.setState({
      ...this.state,
      loadCompleted: true,
    });
  };

  loadMoreDistances = () => {
    const distanceId = this.props.match.params.id;
    squadsService.loadDistanceSquads(distanceId);
  };

  render() {
    const { helperDistancesStore } = this.props;
    const { selected, modelSelected } = helperDistancesStore;
    const { isModalOpen, isSquadsModalOpen } = this.state;

    if (!selected || !selected.race || !selected.race.distances) {
      return null;
    }

    return (
      <div className='content main-container-list start-list'>
        <div className='sticky-left'>
          <Dialog open={isModalOpen} onClose={this.onClose}>
            <DialogContent className='racer-form-modal' sx={{ overflowX: 'hidden' }}>
              <RacerForm distance={modelSelected!} onClose={this.onClose} />
            </DialogContent>
          </Dialog>

          <Dialog open={isSquadsModalOpen} onClose={this.onCloseSquads} PaperProps={{ className: 'squads-dialog' }}>
            <DialogContent className='squads-form-modal'>
              <SquadsForm
                distance={modelSelected!}
                onClose={this.onCloseSquads}
                hasMoreDistances={squadsStore.hasMore}
                onLoadMore={this.loadMoreDistances}
              />
            </DialogContent>
          </Dialog>

          <AttachToProfileModal />
          <ImportErrorsModal />
          <Breadcrumbs distance={modelSelected as any} />
          <div className='toolbar-container'>
            <Toolbar
              distance={modelSelected as any}
              onAddNewRacer={this.createRacer}
              onChange={this.onChangeDistance}
              exportFile={exportRacers}
              onEditDistance={this.onEditDistance}
              onDeleteDistance={this.onDeleteDistanceWrapper}
            />
          </div>
          <Nav distance={selected} onEditSquads={this.editSquads} />
          <FilterLabelToolbar />
        </div>
        {this.state.loadCompleted && this.getContent()}
      </div>
    );
  }
}

export const List = ListImpl as unknown as React.ComponentType<OriginalProps>;

import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

import { Show } from 'components/Condition';
import { ImageField } from 'components/Fields/ImageField';
import { Icon } from 'components/Icon';

import { t } from 'utils';

type ViewProps = {
  onChange: (e: React.ChangeEvent) => void;
  errors: Array<string>;
  clearValue: () => void;
  value: Blob | string | null;
  preview: string;
  imageSource: string;
  fileName?: string;
};

const useStyles = makeStyles({
  label: {
    lineHeight: 'inherit',
    marginLeft: '12px',
    position: 'relative',
  },
  cancelIcon: {
    position: 'absolute',
    top: '-10px',
    right: '-20px',
    color: '#a8aeb8',
    cursor: 'pointer',
  },
});

let View = ({ onChange, errors, preview, clearValue, imageSource, fileName }: ViewProps) => {
  const classes = useStyles();

  return (
    <div className='icon-field'>
      <label htmlFor='sport-type-icon' className='icon-label'>
        <Show if={!imageSource && !preview}>
          <div className='new-value'>
            <Icon value='plus-button' className='icon' />
            <Typography className={classes.label}>{t.staticAsString('categories.addIcon')}</Typography>
          </div>
        </Show>

        <Show if={!!preview && !imageSource}>
          <div className='icon-container'>
            <div className='preview' style={{ backgroundImage: `url(${preview})` }} />
            <Typography className={classes.label}>
              <>
                {fileName}
                <CancelIcon className={classes.cancelIcon} onClick={clearValue} />
              </>
            </Typography>
          </div>
        </Show>

        <Show if={!!imageSource}>
          <div className='icon-container'>
            <div className='preview' style={{ backgroundImage: `url(${imageSource})` }} />
            <Typography className={classes.label}>
              <>
                {fileName}
                <CancelIcon className={classes.cancelIcon} onClick={clearValue} />
              </>
            </Typography>
          </div>
        </Show>
      </label>
      <span className='errors'>{errors}</span>
      <input id='sport-type-icon' className='icon-input' type='file' name='icon' onChange={onChange} />
      <span className='file-name-label' />
    </div>
  );
};

type IconFieldProps = {
  errors: Array<string>;
  onChange: (arg0: { name: string; value: nil | Blob }) => void;
  value: Blob | string | null;
  name: string;
  fileName: string | null | any;
};

const IconField = ({ errors, onChange, value, fileName }: IconFieldProps) => {
  return <ImageField fileName={fileName} value={value} name='icon' View={View} label={''} errors={errors} onChange={onChange} />;
};

export { IconField };

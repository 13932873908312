import { getDistanceId } from '../utils';

import { ordersService } from '../services';

import { hasCompletedOrders } from '../stores';

async function checkHasCompletedOrders() {
  const distanceId = getDistanceId();

  if (!distanceId) {
    return;
  }

  const response = await ordersService.hasCompletedOrders(distanceId);

  if (response) {
    hasCompletedOrders.on();
  } else {
    hasCompletedOrders.off();
  }
}

export { checkHasCompletedOrders };

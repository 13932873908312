import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { GPX_ROUTE_URL, GPX_SAVE_ROUTE_URL } from 'src/constants';
import { loadCheckPoints } from 'src/modules/Distances/components/shared/Steps/GPX/actions';
import { DELETE_GPX_ROUTE, GET_GPX_ROUTE, SAVE_GPX_ROUTE } from 'src/modules/Distances/components/shared/Steps/GPX/constants';
import { gpxRouteLoadingStore, gpxStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { mapFromRoute } from 'src/modules/Distances/components/shared/Steps/GPX/utils';

import { action, request, t } from 'utils';

import { toastStore } from 'stores';

class GPXService {
  @request({ action: SAVE_GPX_ROUTE })
  private async saveGPXRequest(file: FormData, distanceId: number): Promise<any> {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const path = generatePath(GPX_SAVE_ROUTE_URL, { distanceId });
    return axios.post(path, file, config);
  }

  @request({ action: GET_GPX_ROUTE })
  private async loadGPXRequest(distanceId: number): Promise<any> {
    const path = generatePath(GPX_ROUTE_URL, { distanceId });
    return axios.get(path);
  }

  @request({ action: DELETE_GPX_ROUTE })
  private async deleteGPXRequest(distanceId: number): Promise<any> {
    const path = generatePath(GPX_ROUTE_URL, { distanceId });
    return axios.delete(path);
  }

  private async setData(status, response): Promise<any> {
    if (status) {
      gpxStore.set(mapFromRoute(response.data.data));
      loadCheckPoints();
    } else {
      gpxRouteLoadingStore.off();

      if (response.status === 403) {
        toastStore.show(t.staticAsString('distances.steps.helperText.gpx.wrongUser'), null, 5000, 'error');
      }
      if (response.status === 500) {
        toastStore.show(t.staticAsString('distances.steps.helperText.gpx.wrongFileFormat'), null, 5000, 'error');
      }
    }
  }

  @action({ action: GET_GPX_ROUTE })
  async loadGPX(distanceId: number): Promise<any> {
    const [status, response] = await this.loadGPXRequest(distanceId);
    this.setData(status, response);
  }

  @action({ action: SAVE_GPX_ROUTE })
  async saveGPX(file: FormData, distanceId: number): Promise<any> {
    const [status, response] = await this.saveGPXRequest(file, distanceId);
    this.setData(status, response);
  }

  @action({ action: DELETE_GPX_ROUTE })
  async deleteGPX(distanceId: number): Promise<any> {
    const [isOk] = await this.deleteGPXRequest(distanceId);

    if (isOk) {
      toastStore.show(t.staticAsString('distances.steps.helperText.gpx.trackDeletedOk'), null, 5000, 'success');
    } else {
      toastStore.show(t.staticAsString('distances.steps.helperText.gpx.trackDeletedFalse'), null, 5000, 'error');
    }
  }
}

export const gpxService = new GPXService();

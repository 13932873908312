import { fields } from '../../../constants';

import { TaskTable } from '../../../types';

type RacerModel = {
  value: TaskTable;
  action: Function;
  parameters: Function;
  reportedBy: Function;
  created_at: Function;
  output: Function;
};

const FIELD_SHOW = {
  [fields.id]: function (model: RacerModel): Function {
    return () => {
      return model.value.id;
    };
  },

  [fields.action]: function (model: RacerModel): Function {
    return () => {
      return model.action();
    };
  },

  [fields.parameters]: function (model: RacerModel): Function {
    return () => {
      return model.parameters();
    };
  },

  [fields.reportedBy]: function (model: RacerModel): Function {
    return () => {
      return model.reportedBy();
    };
  },

  [fields.created_at]: function (model: RacerModel): Function {
    return () => {
      return model.created_at();
    };
  },

  [fields.output]: function (model: RacerModel): Function {
    return () => {
      return model.output();
    };
  },
};

export { FIELD_SHOW };

import React from 'react';

type Props = {};

class Detail extends React.Component<Props> {
  render() {
    return <div>DISTANCE detail</div>;
  }
}

export { Detail };

import { debounce } from 'lodash';
import { PaymentStatus } from 'modules/Payments/constants';
import { getOrderStatuses } from 'modules/exportPayment/utils';
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { generatePath, NavLink } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import pdf from 'img/svg/icons/pdf.svg';

import { Show } from 'components/Condition';
import { Spinner } from 'components/Spinner';

import { time, t } from 'utils';

import { PAYMENTS_TABLE_WIDTH, FOOTER_AND_HEADER_HEIGHT } from '../../constants';

import { Order } from '../../models';

type Props = {
  page?: number;
  paginationMeta?: PaginationMeta;
  values: Array<Order>;
  onExport: (e: React.SyntheticEvent, id: number, url: string) => void;
  loadMore?: Function;
};

type ItemProps = {
  value: Order;
  onExport: (e: React.SyntheticEvent, id: number, url: string) => void;
};

type THeadProps = {};

const Header = ({}: THeadProps) => (
  <div className='payments-grid-row heading'>
    <div>{t.staticAsString('organizers.detail.payments.id')}</div>
    <div>{t.staticAsString('organizers.detail.payments.payee_reference')}</div>
    <div>{t.staticAsString('organizers.detail.payments.created_at')}</div>
    <div>{t.staticAsString('organizers.detail.payments.distance_name')}</div>
    <div>{t.staticAsString('organizers.detail.payments.email')}</div>
    <div>{t.staticAsString('organizers.detail.payments.fullname')}</div>
    <div>{t.staticAsString('organizers.detail.payments.total_sum')}</div>
    <div>{t.staticAsString('organizers.detail.payments.status')}</div>
    <div>{t.staticAsString('organizers.detail.payments.viewOrder')}</div>
  </div>
);

const Item = ({ value, onExport }: ItemProps): React.ReactElement => {
  const jsonValue = value.value;
  return (
    <div className='payments-grid-row item'>
      <div>
        <NavLink className='link-label' to={generatePath(ROUTES.paymentRoute, { id: jsonValue.id })}>
          {jsonValue.id}
        </NavLink>
      </div>
      <div>
        <NavLink className='link-label' to={generatePath(ROUTES.paymentRoute, { id: jsonValue.id })}>
          {jsonValue.uuid}
        </NavLink>
      </div>
      <div>
        {time
          .datetime(jsonValue.created_at || '')
          .parse()
          .format('shortDateWithTime')}
      </div>
      <div>{value.distanceName()}</div>
      <div>{jsonValue.email}</div>
      <div>{value.userFullName()}</div>
      <div>{value.totalSum()}</div>
      <div>{getOrderStatuses().find((status) => status.key === (jsonValue.payment_status || PaymentStatus.pending))?.value}</div>
      <Show if={Boolean(jsonValue.receipt?.url)}>
        <div className='download-item' onClick={(e: React.SyntheticEvent) => onExport(e, jsonValue.id, jsonValue.receipt?.url || '')}>
          <img src={pdf} alt='download' />
        </div>
      </Show>
    </div>
  );
};

export function Payments({ values, onExport, page, paginationMeta, loadMore }: Props) {
  const items = values.map((payment) => <Item value={payment} onExport={onExport} />);

  const debouncedLoadMore = debounce(() => {
    loadMore && loadMore();
  }, 1000);

  const hasMore = (paginationMeta?.total_pages || 0) > Number(page);

  return (
    <div className='payments-wrapper'>
      <h3 className='payments-title'>{t.staticAsString('organizers.detail.paymentsTitle')}</h3>
      <Header />
      <div
        id='scrollableDiv'
        style={{
          height: `calc(100vh - ${FOOTER_AND_HEADER_HEIGHT})`,
          width: PAYMENTS_TABLE_WIDTH,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <InfiniteScroll
          className='scroll-container'
          dataLength={items.length}
          next={debouncedLoadMore}
          hasMore={hasMore}
          loader={<Spinner type={'cubesSpinnerSmall'} />}
          scrollableTarget='scrollableDiv'
        >
          {items}
        </InfiniteScroll>
      </div>
    </div>
  );
}

import styled from 'styled-components';

import { Form } from 'src/components/Form';

export const Wrapper = styled(Form)`
  overflow-y: auto;
`;

export const Body = styled.div`
  padding: 24px;
  width: 728px;
`;

export const PageTitle = styled.h1`
  font-size: 20px;
  margin-bottom: 41px;
`;

export const Title = styled.div`
  font-size: 24px;
  padding: 16px 0;
`;

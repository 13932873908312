import { DISTANCE_MODES } from 'src/constants';

import { dateCols } from '../types';
import { isCreateDistance } from './conditions';

type RestrictRange = { fromCols: dateCols[]; toCols: dateCols[] };

function generateRestrictionFields(col: dateCols, distance: DistanceType | AnyObject): RestrictRange {
  const isClassicMode = distance.distance_mode === DISTANCE_MODES.CLASSIC;
  const isCreate = isCreateDistance();

  let fromCols: dateCols[] = [];
  let toCols: dateCols[] = [];

  switch (col) {
    case 'registration_starts_at':
      if (isCreate) {
        fromCols.push('today');
      }
      toCols.push('registration_ends_at');
      break;

    case 'registration_ends_at':
      fromCols.push('registration_starts_at');
      toCols.push('race_end');

      if (isClassicMode) {
        toCols.push('race_date');
      }
      break;

    case 'race_date':
      if (isClassicMode) {
        fromCols.push('registration_ends_at');
      } else {
        fromCols.push('race_start');
      }

      toCols.push('race_end');
      break;

    case 'ends_at':
      fromCols.push('race_date');
      toCols.push('race_end');
      break;
  }

  return { fromCols, toCols };
}

export { generateRestrictionFields };

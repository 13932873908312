import { uniqBy } from 'lodash';
import { observable, computed, action, makeObservable, override } from 'mobx';

import { Squads as SquadsModel } from 'models';

import { Loadable } from './loadable';

const defaultFilters = {
  limit: 20,
  withCount: 'racers',
};

class Squads extends Loadable<SquadsType, FiltersType> {
  declare filters: FiltersType;

  @observable hasMore: boolean = true;
  @observable selectedDistanceId: number | null;

  constructor() {
    super();

    makeObservable(this);

    this.filters = defaultFilters;
  }

  @computed
  get modelValues(): Array<SquadsModel> {
    return (this.values || []).map((value) => new SquadsModel(value));
  }

  valuesForDropdown(): Array<{
    key: number;
    value: number;
    label: string;
  }> {
    return this.modelValues.map((squad) => ({
      key: squad.value.id,
      value: squad.value.id,
      label: squad.name(),
    }));
  }

  @computed
  get valuesForSelect(): Array<SelectOption> {
    return this.modelValues.map((el) => el.forSelect());
  }

  @action
  setHasMore(hasMore: boolean = true): void {
    this.hasMore = hasMore;
  }

  @action
  setSelectedDistanceId(distanceId: number | null): void {
    this.selectedDistanceId = distanceId;
  }

  @action
  setPage(page: number) {
    this.page = page;
  }

  @action
  setFilters(filters: FiltersType, page: number): void {
    this.filters = {
      ...this.filters,
      ...filters,
    };
    this.page = page;
  }

  @override
  clearFilters() {
    super.clearFilters();
    this.filters = defaultFilters;
    this.page = 1;
  }

  @action
  appendSquads(data: Array<SquadsType>): void {
    this.values = uniqBy([...this.values, ...data], 'id');
  }

  findModelValue(id: number): nil | SquadsModel {
    return this.modelValues.find((el) => el.value.id === id);
  }

  formatValue(id: number): nil | string {
    if (!id) {
      return null;
    }

    return this.modelValues.find((el) => el.value.id === id)?.name();
  }

  findFormatedValue(id: number):
    | {
        key: number;
        value: number;
        label: string;
      }
    | nil {
    const squad = this.findModelValue(id);

    if (squad) {
      return {
        key: squad.value.id,
        value: squad.value.id,
        label: squad.name(),
      };
    }

    return null;
  }

  findValueByName(name: string): number | null {
    const squad = this.modelValues.find((el) => el.value.name === name);

    if (squad) {
      return squad.value.id;
    }

    return null;
  }

  @override
  clearData() {
    this.values = [];
    this.page = 1;
    this.hasMore = true;
  }
}

export { Squads };
export default new Squads();

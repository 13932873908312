import { Box } from '@mui/material';
import RefundDisplay from 'modules/Reconciliation/shared/components/Refund';

import { ReportConstants } from '../../constants/report.constants';

import { formatCurrency } from '../../utils';
import { reportCurrency } from '../../utils/currency.util';

import { StyledPartSumTableCell, StyledPartSumTableRow } from '../PaymentOverview/styles';
import { getStyle } from './utils';

type Props = {
  data: any[];
  columns: any[];
  summaryText: string;
  tableName: 'PaymentOverview' | 'Invoice' | 'VatReport' | 'VatReportPlatformInvoice';
  isRefund?: boolean;
};

export default function TableSummary({ data, columns, summaryText, tableName, isRefund }: Props) {
  if (tableName === 'VatReport') {
    return (
      <StyledPartSumTableRow>
        <StyledPartSumTableCell align='left' style={{ ...getStyle(data, columns, 0, 0), fontWeight: 'bold' }}>
          {summaryText}
        </StyledPartSumTableCell>
        <StyledPartSumTableCell align='center' style={{ fontWeight: 'bold' }}>
          {formatCurrency(data.reduce((acc, it) => acc + it.grossAmount, 0).toFixed(2))}
        </StyledPartSumTableCell>
        <StyledPartSumTableCell align='center' style={{ fontWeight: 'bold' }}>
          {formatCurrency(data.reduce((acc, it) => acc + it.vatAmount, 0).toFixed(2))}
        </StyledPartSumTableCell>
        <StyledPartSumTableCell align='right' style={{ fontWeight: 'bold' }}>
          {formatCurrency(data.reduce((acc, it) => acc + it.netAmount, 0).toFixed(2))}
        </StyledPartSumTableCell>
      </StyledPartSumTableRow>
    );
  }

  if (tableName === 'VatReportPlatformInvoice') {
    return (
      <StyledPartSumTableRow>
        <StyledPartSumTableCell align='left' style={{ ...getStyle(data, columns, 0, 0), fontWeight: 'bold' }}>
          {summaryText}
        </StyledPartSumTableCell>
        <StyledPartSumTableCell align='center' style={{ fontWeight: 'bold' }}>
          {formatCurrency(data.reduce((acc, it) => acc + it.total, 0).toFixed(2))}
        </StyledPartSumTableCell>
        <StyledPartSumTableCell align='center' style={{ fontWeight: 'bold' }}>
          {formatCurrency(data.reduce((acc, it) => acc + it.vat_amount, 0).toFixed(2))}
        </StyledPartSumTableCell>
        <StyledPartSumTableCell align='right' style={{ fontWeight: 'bold' }}>
          {formatCurrency(data.reduce((acc, it) => acc + it.total_excluding_vat, 0).toFixed(2))}
        </StyledPartSumTableCell>
      </StyledPartSumTableRow>
    );
  }
  if (tableName === 'Invoice') {
    return (
      <StyledPartSumTableRow>
        <StyledPartSumTableCell align='left' style={{ ...getStyle(data, columns, 0, 0), fontWeight: 'bold' }}>
          {summaryText}
        </StyledPartSumTableCell>
        <StyledPartSumTableCell align='center' />
        <StyledPartSumTableCell align='center' />
        <StyledPartSumTableCell align='center' />
        <StyledPartSumTableCell align='center'>
          {formatCurrency(data.reduce((acc, it) => acc + it.total, 0).toFixed(2))}
        </StyledPartSumTableCell>
        <StyledPartSumTableCell align='center' />
        <StyledPartSumTableCell align='center'>
          {formatCurrency(data.reduce((acc, it) => acc + it.vatSum, 0).toFixed(2))}
        </StyledPartSumTableCell>
        <StyledPartSumTableCell align='right' style={{ fontWeight: 'bold' }}>
          {formatCurrency(data.reduce((acc, it) => acc + it.totalIncVat, 0).toFixed(2))}
        </StyledPartSumTableCell>
      </StyledPartSumTableRow>
    );
  }
  return (
    <StyledPartSumTableRow>
      <StyledPartSumTableCell style={{ ...getStyle(data, columns, 0, 0), fontWeight: 'bold' }}>{summaryText}</StyledPartSumTableCell>
      <StyledPartSumTableCell />
      <StyledPartSumTableCell align='center' style={{ fontWeight: 'bold' }}>
        {isRefund ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {<RefundDisplay value={data.reduce((acc, it) => acc + it.totalExcludingVAT, 0).toFixed(2)} currency={reportCurrency()!} />}
          </Box>
        ) : (
          formatCurrency(data.reduce((acc, it) => acc + it.totalExcludingVAT, 0).toFixed(2))
        )}
      </StyledPartSumTableCell>
      <StyledPartSumTableCell />
      <StyledPartSumTableCell align='center' style={{ fontWeight: 'bold' }}>
        {formatCurrency(data.reduce((acc, it) => acc + it.vatSum, 0).toFixed(2))}
      </StyledPartSumTableCell>
      <StyledPartSumTableCell align='right' style={{ fontWeight: 'bold' }}>
        {isRefund ? (
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            {<RefundDisplay value={data.reduce((acc, it) => acc + it.totalIncludingVat, 0).toFixed(2)} currency={reportCurrency()!} />}
          </Box>
        ) : (
          formatCurrency(data.reduce((acc, it) => acc + it.totalIncludingVat, 0).toFixed(2))
        )}
      </StyledPartSumTableCell>
    </StyledPartSumTableRow>
  );
}

import { EXPORT_DATA, EXPORT_DATA_SIZES } from 'src/constants';

import { handleExport, t } from 'utils';
import { appEnvControl } from 'utils/appEnvironment';

import { exportJobService } from 'services';

import { toastStore } from 'stores';

import { racersStore } from '../stores';

export const exportData = async (type: string): Promise<void> => {
  const { paginationMeta, params } = racersStore;
  const { total = EXPORT_DATA.defaultMetaData } = paginationMeta;
  if (total > EXPORT_DATA.metaDataLimit) {
    toastStore.show(t.staticAsString('shared.loadPopup.tooMuchData'));
    return;
  }
  const [status, response] = await exportJobService.exportRacers(type, params);

  if (!status) {
    return;
  }
  const { id } = response.data.data;
  const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
  let estimatedLoadingTime = total / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second
  if (total > maxTotal) {
    estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
  }

  handleExport({
    id,
    estimatedLoadingTime,
    fileName: `Racers.${type || 'xlsx'}`,
    onRetry: () => exportData(type),
  });
};

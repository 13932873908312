import { generatePath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

import { raceDetailsStore } from '../stores';

export const addNewDistanceAction = async () => {
  const raceId = raceDetailsStore.raceDetails?.value.id;
  if (!raceId) {
    return;
  }

  const distanceUrl = generatePath(ROUTES.newDistanceRoute, { raceId });
  history.push(distanceUrl);
};

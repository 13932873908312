import axios from 'axios';

import { fetchApiFromStorage, fetchApiFromDocument } from './fetch';

// import { storeKey } from './constants';

function init() {
  const apiOriginName = fetchApiFromStorage() || fetchApiFromDocument();
  axios.defaults.baseURL = apiOriginName as string;
}

export { init };

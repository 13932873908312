import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Form, InputWrapper, Select } from 'components/Form';

import { t } from 'utils';

import { form } from 'stores';

import { Info } from '../../components/Actions';

import { CREDIT_SINGLE, MERGE, SUPPORT_TASK_MANAGER_FORM, CREDIT_DISTANCE, COUPON, BIB_NUMBERS } from '../../constants';

import { activeActionView, optionsAction } from '../../utils';

import { loadData } from '../../actions';

import { FieldsGenerateBibNumbers, FieldsGenerateCouponCodes, FieldsMergeAccounts, FieldsRegistration } from '.';

type Props = {};

@observer
export class Fields extends React.Component<Props> {
  getInfoText(): string {
    const action = form.fetch<string>(SUPPORT_TASK_MANAGER_FORM, 'action');
    return action ? t.staticAsString(`supportTaskManager.${action}.info` as TranslationLockedKeys) : '';
  }

  onChangeAction = ({ name, value }: { name: string | string[]; value: any }) => {
    form.onChange(SUPPORT_TASK_MANAGER_FORM, name, value);
    loadData(value);
  };

  render() {
    return (
      <DialogContent>
        <Form id={SUPPORT_TASK_MANAGER_FORM}>
          <Box mt={3}>
            <InputWrapper
              name='action'
              settings={{
                required: true,
                placeholder: t.staticAsString('supportTaskManager.form.actions'),
                label: t.staticAsString('supportTaskManager.form.actions'),
                options: optionsAction(),
                onChange: this.onChangeAction,
                additional: {
                  fullWidth: true,
                },
                requiredMark: true,
                view: {
                  variant: 'outlined',
                },
              }}
              Component={Select}
            />
          </Box>

          <Info text={this.getInfoText()} />

          <FieldsMergeAccounts show={activeActionView(MERGE)} />
          <FieldsRegistration showSingle={activeActionView(CREDIT_SINGLE)} showDistance={activeActionView(CREDIT_DISTANCE)} />
          <FieldsGenerateCouponCodes show={activeActionView(COUPON)} />
          <FieldsGenerateBibNumbers show={activeActionView(BIB_NUMBERS)} />
        </Form>
      </DialogContent>
    );
  }
}

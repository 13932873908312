import { Payment } from 'models';

import type { SystemUser as SystemUserType } from '../types';

class SystemUser {
  value: SystemUserType;
  payments: Array<Payment>;

  constructor(value: SystemUserType) {
    this.value = value;
    this.payments = [];
    this._initPayments();
  }

  fullName(): string {
    const { value } = this;
    if (value.full_name) {
      return `${value.full_name || ''}`;
    }
    return '';
  }

  _initPayments() {
    const { value } = this;
    if (value.payments) {
      this.payments = value.payments.map((payment) => new Payment(payment));
    }
  }

  role() {
    const { value } = this;

    return value?.roles?.[0]?.name || '';
  }

  isOrganizer() {
    return this.role() === 'organizer';
  }
  isAdmin() {
    return this.role() === 'admin';
  }
  isTimingAssistant() {
    return this.role() === 'assistant';
  }
  isMedicalAssistant() {
    return this.role() === 'medical';
  }
}

export { SystemUser };

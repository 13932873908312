import axios from 'axios';

import { GET_HOSTS_URL } from 'src/constants';

import { action } from 'utils/core/action';
import { request } from 'utils/core/request';

const loadAction = 'LOAD_HOST_DATA';

class HostsService {
  @request({ action: loadAction })
  fetchRequest(): any {
    return axios.get(GET_HOSTS_URL);
  }

  @action({ action: loadAction })
  async fetch() {
    const [status, response] = await this.fetchRequest();

    if (status) {
      return response.data;
    }

    return false;
  }
}

const hostsService = new HostsService();
export { hostsService };

import { keys, isNil } from 'lodash';
import Xregexp from 'xregexp';

import { _addErrors } from './_addErrors';

function pattern(withAnchors: boolean) {
  return Xregexp(`${withAnchors ? '^' : ''}(?<fieldName>\\w+)${withAnchors ? '$' : ''}`);
}

/**
 * @description
 * Remap and add to errorsStore, BE errors errors, specifically:
 *
 * someField to someField
 * ^someField$
 */
function catchErrorsForDistance(errors: RawError | nil) {
  if (!errors) {
    return;
  }

  const errorKeys = keys(errors);

  const mappedErrors = errorKeys.reduce((processedErrors, errorKey) => {
    const match = Xregexp.exec(errorKey, pattern(true));

    if (!match) {
      return processedErrors;
    }

    let { fieldName } = match;
    const parsedErrorIndex = parseInt(match.errorInde);

    if (isNil(parsedErrorIndex)) {
      return processedErrors;
    }

    const newErrorKey = `${fieldName}`;

    const oldErrors = errors[errorKey] || [];

    processedErrors[newErrorKey] = oldErrors;

    return processedErrors;
  }, {});

  return mappedErrors;
}

export { catchErrorsForDistance };

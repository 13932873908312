import { observer } from 'mobx-react';
import * as React from 'react';

import { withoutFooter, withoutHeader, withoutSidebar } from 'hocs';

import { Stub } from '../components';

import { healthCheckService } from '../services';

@withoutFooter
@withoutSidebar
@withoutHeader
@observer
export class unavailableServiceContainer extends React.Component<{}> {
  componentDidMount() {
    healthCheckService.healthCheck();
  }

  render() {
    return <Stub />;
  }
}

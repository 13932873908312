import axios from 'axios';
import Axios from 'axios';
import { generatePath } from 'react-router-dom';

import { DISTANCE_FINISH_URL, DISTANCE_TYPE } from 'src/constants';

import { action } from 'utils';

import { LOAD_RESULTS } from '../constants';

import { singleResultsService, teamResultsService } from '../services';

import { distanceStore, teamResultsStore, singleResultsStore, resultsStubStore, fieldRegisterStore } from '../stores';

// Service aggregator
// based on distance type it will choose more properly store and service
class Results implements ExtensionableService<unknown>, ExtensionableSearchService, ExtensionableSortService {
  stubStore = resultsStubStore;

  get store() {
    const distanceType = this.distanceType();

    switch (distanceType) {
      case DISTANCE_TYPE.single:
        return singleResultsStore;
      case DISTANCE_TYPE.team:
        return teamResultsStore;
      default:
        return this.stubStore;
    }
  }

  distanceType(): nil | distanceType {
    const distanceModel = distanceStore.modelSelected;

    if (!distanceModel) {
      return;
    }

    return distanceModel.value.type;
  }

  @action({ action: LOAD_RESULTS })
  async loadResource(id: number) {
    const distanceType = this.distanceType();

    switch (distanceType) {
      case DISTANCE_TYPE.single:
        singleResultsService.loadResources(id);
        teamResultsStore.clearAll();
        break;
      case DISTANCE_TYPE.team:
        teamResultsService.loadResources(id);
        singleResultsStore.clearAll();
        break;
      default:
        break;
    }
  }

  @action({ action: LOAD_RESULTS })
  async load(params?: { limit?: number; page?: number }): Promise<void> {
    const distanceType = this.distanceType();
    fieldRegisterStore.deactivateAllFields();

    switch (distanceType) {
      case DISTANCE_TYPE.single:
        await teamResultsStore.clearAll();
        await singleResultsService.load(params);
        break;
      case DISTANCE_TYPE.team:
        await singleResultsStore.clearAll();
        await teamResultsService.load(params);
        break;
      default:
        break;
    }
  }

  async finishDistance(id: number) {
    const url = generatePath(DISTANCE_FINISH_URL, { id });
    try {
      await axios.post(url);
      location.reload();
    } catch (error) {
      console.log(error);
    }
  }
}

export { Results, Results as ResultsService };

import { TableRow } from '@mui/material';
import * as React from 'react';

import { DISTANCE_TYPE } from 'src/constants';

import { Result } from 'models';

import { ActionCell } from '../../components/ActionCell';
import { SingleRow, TeamRow, SplitRow } from '../../components/Row';
import { RowType } from '../../components/Row';

import { ResultsContext } from '../../context';

type Props = {
  item: Result;
};

const ROW_COMPONENTS = {
  [DISTANCE_TYPE.single]: SingleRow,
  [DISTANCE_TYPE.team]: TeamRow,
};

class Row extends React.Component<Props> {
  static contextType = ResultsContext;
  declare context: React.ContextType<typeof ResultsContext>;

  RowComponent = (): nil | React.ComponentType<RowType> => {
    const distanceModel = this.context.results.stores.distanceStore.modelSelected;

    if (!distanceModel) {
      return null;
    }

    const type = distanceModel.value.type;
    return ROW_COMPONENTS[type];
  };

  render() {
    const { item } = this.props;
    const racerId = item.value.racer_id;
    const RowComponent = this.RowComponent();
    return (
      <TableRow key={`result${item.value.id}`}>
        <ActionCell racerId={racerId} />
        {!RowComponent ? null : <RowComponent value={item} />}
        <SplitRow value={item} />
      </TableRow>
    );
  }
}

export { Row };

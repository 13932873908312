import { computed, makeObservable } from 'mobx';

import { errorsStore } from 'stores';

import { IMPORT_RESULTS } from '../constants';

import { distanceStore } from '../stores';

import { generateImportErrors } from '../tools/generateImportErrors';

class ImportErrors {
  constructor() {
    makeObservable(this);
  }

  @computed
  get values(): string[] {
    const distance = distanceStore.modelSelected;
    const errorsModel = errorsStore.errors.api[IMPORT_RESULTS];
    const checkpoints = distance?.checkpoints || [];

    if (!errorsModel) {
      return [];
    }

    const errors: any = errorsModel.errors();

    return generateImportErrors(errors.errors, checkpoints);
  }

  @computed
  get isModalOpen(): boolean {
    return !!this.values.length;
  }
}

export { ImportErrors };

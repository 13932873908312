import TablePagination from '@mui/material/TablePagination';
import classnames from 'classnames';
import * as React from 'react';

import { t } from 'utils';

type Props = {
  page: number;
  total: number;
  perPage: number;
  perPageOptions?: number[];
  classes?: {
    selectLabel: string;
    displayedRows: string;
    select: string;
    selectIcon: string;
  };
  labels?: {
    prev: string;
    next: string;
  };
  switchedOnTop: boolean;
  onChangePage: (event: any, page: number) => void;
  onChangePerPage: (event: React.ChangeEvent) => void;
};

const PaginationComponent = ({
  classes = {
    selectLabel: 'caption',
    displayedRows: 'caption',
    select: 'choose-num',
    selectIcon: 'choose-num-svg',
  },

  labels,
  onChangePage,
  onChangePerPage,
  total,
  perPage,
  perPageOptions = [30, 50, 100, 200],
  page,
  switchedOnTop,
}: Props) => {
  const { next, prev } = labels || {};

  return (
    <TablePagination
      component='div'
      count={total}
      rowsPerPage={perPage}
      rowsPerPageOptions={perPageOptions}
      page={page}
      className={classnames({ pagination: !switchedOnTop })}
      classes={classes}
      backIconButtonProps={{
        'aria-label': prev || (t.staticAsString('shared.pagination.prev') as any),
      }}
      nextIconButtonProps={{
        'aria-label': next || (t.staticAsString('shared.pagination.next') as any),
      }}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangePerPage}
    />
  );
};

const Pagination = React.memo<Props>(PaginationComponent);

export { Pagination };

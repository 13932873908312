import { Observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { TextField, InputWrapper, AutocompleteSelectInfinityLoad } from 'src/components/Form';
import { Loading } from 'src/components/Loading';
import { Button } from 'src/components/core';
import { Tip } from 'src/modules/Synchronisation/Shared';
import { t } from 'src/utils';

import { RR_SETTINGS_INIT } from '../../constants';

import { handleCancel, changeStep, searchRaces, loadMoreRaces } from '../../actions';

import { racesHasMoreStore, racesStore } from '../../stores';

const Wrapper = styled.div`
  width: 726px;
  padding: 0 26px 26px;
`;

const Fields = styled.div`
  padding-top: 32px;
`;

const ButtonsHolderWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonsHolder = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 24px;
`;

export const SyncSettings: React.FC = () => {
  return (
    <Wrapper>
      <Loading action={RR_SETTINGS_INIT} />
      <Tip />
      <Fields>
        <Observer>
          {() => {
            return (
              <InputWrapper
                name='race_id'
                settings={{
                  label: t.staticAsString('sync.distances.raceid_event_name'),
                  additional: {
                    options: racesStore.value,
                  },
                  view: {
                    reservePlaceForHelperText: true,
                  },
                  hasMore: racesHasMoreStore.value,
                  loadMore: loadMoreRaces,
                  onSearch: searchRaces,
                }}
                Component={AutocompleteSelectInfinityLoad}
              />
            );
          }}
        </Observer>
        <InputWrapper
          name='event_id'
          settings={{
            label: t.staticAsString('sync.rr_event_name'),
            view: {
              variant: 'outlined',
              fullWidth: true,
            },
          }}
          Component={TextField}
        />
        <InputWrapper
          name='save_fields'
          settings={{
            label: t.staticAsString('sync.save_field_link'),
            view: {
              variant: 'outlined',
              fullWidth: true,
            },
          }}
          Component={TextField}
        />
        <InputWrapper
          name='results'
          settings={{
            label: t.staticAsString('sync.results_link'),
            view: {
              variant: 'outlined',
              fullWidth: true,
            },
          }}
          Component={TextField}
        />
        <InputWrapper
          name='contests'
          settings={{
            label: t.staticAsString('sync.contests_link'),
            view: {
              variant: 'outlined',
              fullWidth: true,
            },
          }}
          Component={TextField}
        />
        <InputWrapper
          name='fields'
          settings={{
            label: t.staticAsString('sync.fields_link'),
            view: {
              variant: 'outlined',
              fullWidth: true,
            },
          }}
          Component={TextField}
        />
      </Fields>
      <ButtonsHolderWrap>
        <ButtonsHolder>
          <Button type='cancel' onClick={handleCancel}>
            {t('shared.cancel')}
          </Button>
          <Button type='apply' onClick={changeStep}>
            {t('sync.start_sync_process')}
          </Button>
        </ButtonsHolder>
      </ButtonsHolderWrap>
    </Wrapper>
  );
};

const BASE = 'PAYMENTS';

export const EXPORT_ORDER_DETAIL = `${BASE}:EXPORT_ORDER_DETAIL`;

export const ORDER_ADMIN_STATUSES = [
  {
    key: 'status:ready',
    value: 'Ready',
    label: 'Ready',
  },
  {
    key: 'status:pending',
    value: 'Pending',
    label: 'Pending',
  },
  {
    key: 'status:aborted',
    value: 'Aborted',
    label: 'Aborted',
  },
  {
    key: 'status:failed',
    value: 'Failed',
    label: 'Failed',
  },
  {
    key: 'status:reversed',
    value: 'Reversed',
    label: 'Reversed',
  },
];

export const ORDER_ORGANIZER_STATUSES = [
  {
    key: 'status:ready',
    value: 'Ready',
    label: 'Ready',
  },
  {
    key: 'status:reversed',
    value: 'Reversed',
    label: 'Reversed',
  },
];

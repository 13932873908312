import { ToolbarMain } from 'modules/RaceDetails/components';
import { RaceUrl } from 'modules/RaceDetails/components/Body/RaceUrl';
import * as React from 'react';

import { Preview } from 'components/HtmlPreview';

import { htmlSanitizer, t } from 'utils';

type Props = {
  raceUrl: string;
  description: string;
  contactEmails: string;
};

export const Body = ({ raceUrl, description, contactEmails }: Props) => {
  return (
    <div className='info-body'>
      <ToolbarMain />
      <RaceUrl raceUrl={raceUrl} />
      <div className='race-title'>
        <h4 className='race-desc-title'>{t.staticAsString('races.detail.body.desc')}</h4>
        <Preview html={description} />
      </div>

      {contactEmails && (
        <div>
          <h4 className='race-desc-title'>{t.staticAsString('races.detail.body.emails')}</h4>
          <p className='description' dangerouslySetInnerHTML={{ __html: htmlSanitizer(contactEmails) }} />
        </div>
      )}
    </div>
  );
};

import { formatter } from 'utils';

import { isGoalType } from '../derivations';

function formatDistanceLogged(value: number | nil) {
  if (isGoalType('altitude').get()) {
    return formatAltitude(value);
  }

  return formatLength(value);
}

function formatAltitude(value: number | nil) {
  if (!value) {
    return '0 m';
  }

  return `${formatter.integer(value)} m`;
}

function formatLength(value: number | nil) {
  if (!value) {
    return '0 km';
  }

  return `${value / 1000} km`;
}

export { formatDistanceLogged };

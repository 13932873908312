import classNames from 'classnames';
import { PhoneInput as PhoneInputComponent } from 'racese-react-phone-input-2';
import * as React from 'react';

import { TextField } from '../TextField';

type Props = {
  additional?: TextFieldAdditionalProps;
  view?: BaseViewProps;
} & FieldBaseProps;

const RESERVED_PLACE_FOR_INPUT = 57;

class PhoneInput extends React.Component<Props> {
  inputRef = React.createRef<HTMLDivElement>();

  renderInput = React.forwardRef((props: Object, ref: React.RefObject<HTMLInputElement>) => {
    const width = this.inputRef?.current?.clientWidth || 0;
    const styles = { width: width - RESERVED_PLACE_FOR_INPUT };

    return (
      <PhoneInputComponent
        {...props}
        placeholder=''
        enableSearchField
        enableLongNumbers
        disableSearchIcon
        inputStyle={styles}
        disableDropdown={false}
        onChange={(phone, countryData) =>
          this.props.onChange(
            // @ts-ignore
            // TODO TS please check is phone input changing properly and ref is correct
            this.props.name,
            { phone, countryData },
          )
        }
      />
    );
  });

  render() {
    const val = this.props.value === undefined || this.props.value === null ? '' : this.props.value.phone;

    return (
      <TextField
        {...this.props}
        ref={this.inputRef}
        className={classNames('input-wrap', this.props.className)}
        additional={{
          InputProps: { inputComponent: this.renderInput },
          ...(this.props.additional || {}),
          ...(this.props.view || {}),
          InputLabelProps: { className: 'form-label phone-label' },
        }}
        onChange={null!}
        value={val}
      />
    );
  }
}

export { PhoneInput, PhoneInput as PhoneField };

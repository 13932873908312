import { cloneDeep } from 'lodash';

import { SyncType, SyncDistanceType } from '../types';

export const syncMap = (data: AnyObject, type: number): SyncType => {
  const copy = cloneDeep(data);

  copy.type = type;

  return Object.keys(copy).reduce((result, current) => {
    return {
      ...result,
      [current]: cases(current, copy[current]),
    };
  }, {}) as SyncType;
};

const cases = (key: string, value: any) => {
  switch (key) {
    case 'event_id':
      return Number(value);

    case 'race_id':
      return value.key;

    default:
      return value;
  }
};

export const distanceToOptionsMap = (data: SyncDistanceType[]): SelectOption[] => {
  return data.map((item) => ({
    key: item.id,
    value: item.name,
    label: item.name,
  }));
};

export const raceDistancesStoreMap = (data: Array<{ id: number; type: string }>): Array<{ id: number; type: string }> => {
  return data.map((item) => ({
    id: item.id,
    type: item.type,
  }));
};

export const mapFormMappings = (data: AnyObject, isEq: boolean): AnyObject => {
  const copy = cloneDeep(data);
  const possibleFields = ['classes', 'waves', 'custom_fields'];
  const distances = copy.distances.map((distance) => {
    if (distance.sync_bibs === undefined) distance.sync_bibs = true;
    const fields = possibleFields.reduce((result, key) => {
      if (!distance[key]) return result;
      const list = distance[key].map((item, index) => ({
        internal_id: item.internal_id?.key,
        external_id: key === 'custom_fields' && isEq ? `extra${index + 1}` : item.external_id?.key,
      }));
      if (!list.length) return result;
      return {
        ...result,
        [key]: list,
      };
    }, {});

    return {
      internal_id: distance.internal_id?.key,
      external_id: distance.external_id?.key,
      sync_bibs: distance.sync_bibs,
      ...fields,
    };
  });

  return { distances };
};

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { GOOGLE_TRANSLATE_URL } from 'src/constants';

import { request, action } from 'utils';

type TranslatePayload = {
  q: string;
  source: availableLocales;
  target: availableLocales;
};

class GoogleTranslate {
  @request({ action: 'GOOGLE_TRANSLATE' })
  async translateRequest(data?: TranslatePayload): Promise<any> {
    const url = generatePath(GOOGLE_TRANSLATE_URL);
    return axios.post(url, data);
  }
  @action({ action: 'GOOGLE_TRANSLATE' })
  async translate(data?: TranslatePayload): Promise<string> {
    const [status, response] = await this.translateRequest(data);
    if (status) {
      return response.data;
    } else {
      return '';
    }
  }
}

const translateService = new GoogleTranslate();

export { translateService };

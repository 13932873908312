import { get, set } from 'lodash';

import { translationsModelIdMap } from 'src/constants';

type FlattenFunction = (
  defaultLocale: availableLocales,
  path: TranslationPath,
  filteredTranslations: FilteredTranslations,
) => Partial<FlattenFuncResult>;

type FlattenFuncResult = {
  [K in availableLocales | 'other']: { [k: string]: string };
};

const fieldValuePath = {
  checkpoints: 'checkpoints.name',
  waves: 'waves.name',
  disciplines: 'disciplines.title',
  distanceClasses: 'distanceClasses.title',
  emailContent: 'emailContent',
};

export const formatTranslations = (
  translations: LookUpHash,
  locales: availableLocales[],
  defaultLocale: availableLocales,
): TableTranslationsFormat => {
  const defaultLocaleData = translations[defaultLocale];

  const allLocales = [...new Set([defaultLocale, ...locales])];

  if (!defaultLocaleData) {
    return {};
  }

  const result = {};

  Object.keys(defaultLocaleData).forEach((rootLabel: TranslationFieldType) => {
    if (['distanceData', 'raceData'].includes(rootLabel)) {
      ['name', 'description'].forEach((key) => {
        const defaultValue = get(translations[defaultLocale], [rootLabel, key]);
        if (defaultValue != null) {
          result[`${rootLabel}.${key}`] = allLocales.map((locale) => ({
            ...translations[locale]?.[rootLabel],
            value: get(translations[locale], [rootLabel, key]) || '',
            locale,
          }));
        }
      });
    }

    if (rootLabel === 'disciplines') {
      defaultLocaleData[rootLabel]?.forEach((_, idx) => {
        result[`${rootLabel}.${idx}.title`] = allLocales.map((locale) => ({
          ...get(translations, [locale, rootLabel, idx], {}),
          value: get(translations, [locale, rootLabel, idx, 'title']) || '',
          locale,
        }));
      });
    }

    if (rootLabel === 'emailContent') {
      result[`${rootLabel}.content`] = allLocales.map((locale) => ({
        ...get(translations, [locale, rootLabel], {}),
        value: get(translations, [locale, rootLabel, 'content']) || '',
        locale,
      }));
    }

    if (rootLabel === 'distanceClasses') {
      defaultLocaleData[rootLabel]?.forEach((distanceClass, idx) => {
        result[`${rootLabel}.${idx}.title`] = allLocales.map((locale) => ({
          ...distanceClass,
          value: get(translations, [locale, rootLabel, idx, 'title']) || '',
          locale,
        }));
      });
    }

    if (rootLabel === 'checkpoints') {
      defaultLocaleData[rootLabel]?.forEach((_, idx) => {
        result[`${rootLabel}.${idx}.name`] = allLocales.map((locale) => ({
          ...get(translations, [locale, rootLabel, idx], {}),
          value: get(translations, [locale, rootLabel, idx, 'name']) || '',
          locale,
        }));
      });
    }

    if (rootLabel === 'waves') {
      defaultLocaleData[rootLabel]?.forEach((_, idx) => {
        result[`${rootLabel}.${idx}.name`] = allLocales.map((locale) => ({
          ...get(translations, [locale, rootLabel, idx], {}),
          value: get(translations, [locale, rootLabel, idx, 'name']) || '',
          locale,
        }));
      });
    }

    if (rootLabel === 'customFields') {
      defaultLocaleData[rootLabel]?.forEach((customField, idx) => {
        result[`${rootLabel}.${idx}.name`] = allLocales.map((locale) => ({
          ...get(translations, [locale, rootLabel, idx], {}),
          value: get(translations, [locale, rootLabel, idx, 'name']) || '',
          locale,
        }));

        if (customField.helper_text) {
          result[`${rootLabel}.${idx}.helper_text`] = allLocales.map((locale) => ({
            ...get(translations, [locale, rootLabel, idx], {}),
            value: get(translations, [locale, rootLabel, idx, 'helper_text']) || '',
            locale,
          }));
        }

        customField.values?.forEach((value, vidx) => {
          result[`${rootLabel}.${idx}.values.${vidx}.value`] = allLocales.map((locale) => ({
            ...get(translations, [locale, rootLabel, idx, 'values', vidx], {}),
            value: get(translations, [locale, rootLabel, idx, 'values', vidx, 'value']) || '',
            locale,
          }));
        });
      });
    }
  });

  return result;
};

export const initTargetLocale = (locales: availableLocales[], defaultLocale: availableLocales): availableLocales => {
  return locales.find((locale) => locale !== defaultLocale) || 'en';
};

// We will do a lot of field by field look ups and can't afford to scan arrays all the time.
// Building a hash table for every locale->field:value will allow of O(1) access later.
// Example hash: {en: {distanceData: {name: 'Name', description: 'Description}}, sv: {...}, fr: {...}}
export const makeLookUpHash = (data: DataWithTranslations, locales: availableLocales[]): LookUpHash => {
  const lookUpHash = {};
  locales.forEach((locale) => (lookUpHash[locale] = {}));

  data?.translations?.forEach(({ locale, id, distance_id, race_id, name, description, is_outdated }) => {
    if (race_id) {
      set(lookUpHash, [locale, 'raceData'], { id, race_id, name, description, is_outdated });
    } else if (distance_id) {
      set(lookUpHash, [locale, 'distanceData'], { id, distance_id, name, description, is_outdated });
    }
  });

  data?.email_content?.translations?.forEach(({ locale, id, content, content_id, is_outdated }) => {
    set(lookUpHash, [locale, 'emailContent'], { id, content, content_id, is_outdated });
  });

  data?.disciplines?.forEach((d, idx) => {
    d.translations?.forEach(({ locale, id, discipline_id, title, is_outdated }) => {
      set(lookUpHash, [locale, 'disciplines', idx], { id, discipline_id, title, is_outdated });
    });
  });
  data?.classes?.forEach((c, idx) => {
    c.translations?.forEach(({ locale, distance_class_id, id, title, is_outdated }) => {
      set(lookUpHash, [locale, 'distanceClasses', idx], { distance_class_id, id, title, is_outdated });
    });
  });
  data?.checkpoints?.forEach((c, idx) => {
    c.translations?.forEach(({ locale, id, checkpoint_id, name, is_outdated }) => {
      set(lookUpHash, [locale, 'checkpoints', idx], { id, checkpoint_id, name, is_outdated });
    });
  });
  data?.waves?.forEach((w, idx) => {
    w.translations?.forEach(({ locale, id, distance_waves_id, name, is_outdated }) => {
      set(lookUpHash, [locale, 'waves', idx], { id, distance_waves_id, name, is_outdated });
    });
  });
  data?.custom_fields?.forEach((cf, idx) => {
    cf.translations?.forEach(({ id, custom_field_id, name, locale, helper_text, is_outdated }) => {
      set(lookUpHash, [locale, 'customFields', idx], { id, custom_field_id, name, helper_text, is_outdated });
    });
    if (Array.isArray(cf.values)) {
      cf.values.forEach((v, vidx) => {
        v.translations?.forEach(({ locale, value, id, field_value_id, is_outdated }) => {
          set(lookUpHash, [locale, 'customFields', idx, 'values', vidx], {
            value,
            id,
            field_value_id,
            is_outdated,
          });
        });
      });
    }
  });

  return lookUpHash;
};

// We gather results for translsation table by accessing cached values directly from lookUpHash.
export const assembleTranslations = (lookUpHash: LookUpHash, locales: availableLocales[], defaultLocale: availableLocales) => {
  const raceData: translationTableShape[] = [];
  const distanceData: translationTableShape[] = [];
  const customFields: translationTableShape[] = [];
  const disciplines: translationTableShape[] = [];
  const distanceClasses: translationTableShape[] = [];
  const checkpoints: translationTableShape[] = [];
  const waves: translationTableShape[] = [];
  const emailContent: translationTableShape[] = [];

  // Helper function to calculate missing translsations.
  // Function takes path, e.g. ['checkpoints', 'title'] and goes through every locale
  // looking up if [locale, path] exists in our hash.

  const withoutDefaultLocale = locales.filter((locale) => locale !== defaultLocale);
  const lookUpMissing = (path: (string | number)[]) =>
    withoutDefaultLocale.reduce(
      (acc, locale) => {
        if (Boolean(get(lookUpHash, [locale, ...path]))) {
          acc.missingCount = acc.missingCount - 1;
        } else {
          acc.missing.push(locale as never);
        }
        return acc;
      },
      { missingCount: withoutDefaultLocale.length, missing: [] },
    );

  const lookUpWarning = (path: (string | number)[]) =>
    withoutDefaultLocale.some((locale) => get(lookUpHash, [locale, ...path, 'is_outdated'], false));

  if (get(lookUpHash, [defaultLocale, 'raceData'])) {
    raceData.push({
      title: get(lookUpHash, [defaultLocale, 'raceData', 'name'], null),
      is_outdated: lookUpWarning(['raceData']),
      type: 'raceData',
      path: ['raceData', 'name'],
      ...lookUpMissing(['raceData', 'name']),
    });
    raceData.push({
      title: get(lookUpHash, [defaultLocale, 'raceData', 'description'], null),
      is_outdated: lookUpWarning(['raceData']),
      type: 'raceData',
      path: ['raceData', 'description'],
      ...lookUpMissing(['raceData', 'description']),
    });
  }

  if (get(lookUpHash, [defaultLocale, 'distanceData'])) {
    distanceData.push({
      title: get(lookUpHash, [defaultLocale, 'distanceData', 'name'], null),
      is_outdated: lookUpWarning(['distanceData']),
      type: 'distanceData',
      path: ['distanceData', 'name'],
      ...lookUpMissing(['distanceData', 'name']),
    });
    distanceData.push({
      title: get(lookUpHash, [defaultLocale, 'distanceData', 'description'], null),
      is_outdated: lookUpWarning(['distanceData']),
      type: 'distanceData',
      path: ['distanceData', 'description'],
      ...lookUpMissing(['distanceData', 'description']),
    });
  }

  if (get(lookUpHash, [defaultLocale, 'emailContent'])) {
    emailContent.push({
      title: get(lookUpHash, [defaultLocale, 'emailContent', 'content'], null),
      is_outdated: lookUpWarning(['emailContent']),
      type: 'emailContent',
      path: ['emailContent', 'content'],
      ...lookUpMissing(['emailContent', 'content']),
    });
  }

  get(lookUpHash, [defaultLocale, 'disciplines'], []).forEach((d, idx) => {
    if (Boolean(d.title)) {
      disciplines.push({
        title: get(lookUpHash, [defaultLocale, 'disciplines', idx, 'title'], null),
        is_outdated: lookUpWarning(['disciplines', idx]),
        type: 'disciplines',
        path: ['disciplines', idx, 'title'],
        ...lookUpMissing(['disciplines', idx, 'title']),
      });
    }
  });
  get(lookUpHash, [defaultLocale, 'distanceClasses'], []).forEach((dc, idx) => {
    if (Boolean(dc.title)) {
      distanceClasses.push({
        type: 'distanceClasses',
        title: get(lookUpHash, [defaultLocale, 'distanceClasses', idx, 'title'], null),
        is_outdated: lookUpWarning(['distanceClasses', idx]),
        ...lookUpMissing(['distanceClasses', idx, 'title']),
        path: ['distanceClasses', idx, 'title'],
      });
    }
  });

  get(lookUpHash, [defaultLocale, 'checkpoints'], []).forEach((c, idx) => {
    if (Boolean(c.name)) {
      checkpoints.push({
        title: c.name,
        is_outdated: lookUpWarning(['checkpoints', idx]),
        type: 'checkpoints',
        path: ['checkpoints', idx, 'name'],
        ...lookUpMissing(['checkpoints', idx, 'name']),
      });
    }
  });

  get(lookUpHash, [defaultLocale, 'waves'], []).forEach((w, idx) => {
    if (Boolean(w.name)) {
      waves.push({
        title: w.name,
        is_outdated: lookUpWarning(['waves', idx]),
        type: 'waves',
        path: ['waves', idx, 'name'],
        ...lookUpMissing(['waves', idx, 'name']),
      });
    }
  });

  get(lookUpHash, [defaultLocale, 'customFields'], []).forEach((cf, idx) => {
    if (Boolean(cf.name)) {
      customFields.push({
        title: cf.name,
        is_outdated: lookUpWarning(['customFields', String(idx)]),
        type: 'customFields',
        path: ['customFields', String(idx), 'name'],
        ...lookUpMissing(['customFields', String(idx), 'name']),
      });
    }
    if (Boolean(cf.helper_text)) {
      customFields.push({
        title: cf.helper_text,
        type: 'customFields',
        is_outdated: lookUpWarning(['customFields', String(idx)]),
        path: ['customFields', String(idx), 'helper_text'],
        ...lookUpMissing(['customFields', String(idx), 'helper_text']),
      });
    }

    if (Boolean(cf.values)) {
      cf.values.forEach(({ value }, i) => {
        customFields.push({
          title: value,
          type: 'customFields',
          is_outdated: lookUpWarning(['customFields', String(idx)]),
          path: ['customFields', String(idx), 'values', String(i)],
          ...lookUpMissing(['customFields', String(idx), 'values', String(i)]),
        });
      });
    }
  });

  return {
    raceData,
    distanceData,
    customFields,
    checkpoints,
    distanceClasses,
    disciplines,
    waves,
    emailContent,
  };
};

export const flattenObjectTranslationsByNames: FlattenFunction = (defaultLocale, path, translations) => {
  return Object.keys(translations).reduce(
    (acc, locale) => {
      const data = translations[locale];
      const section = locale === defaultLocale ? defaultLocale : 'other';

      const [fieldName] = path;

      if (!fieldName) {
        return {};
      }

      if (fieldName === 'customFields') {
        acc[section][`${locale}.${fieldName}.name`] = get(data, [fieldName, 'name'], '');
        const helperText = get(data, [fieldName, 'helper_text'], '');
        if (helperText) {
          acc[section][`${locale}.${fieldName}.helper_text`] = helperText;
        }
        data.customFields?.values?.forEach(({ value }, i) => {
          acc[section][`${locale}.customFields.values.${i}.value`] = value || '';
        });
      } else if (['distanceData', 'raceData', 'emailContent'].includes(fieldName)) {
        // distanceData contain name, description, email_content
        acc[section][`${locale}.${path.join('.')}`] = get(data, path, '');
      } else if (['checkpoints', 'waves'].includes(fieldName)) {
        // checkpoints and waves using 'name' for text representation
        acc[section][`${locale}.${fieldName}.name`] = get(data, fieldValuePath[fieldName], '');
      } else {
        // distanceClasses,disciplines using 'title' for text representation
        acc[section][`${locale}.${fieldName}.title`] = get(data, fieldValuePath[fieldName], '');
      }

      return acc;
    },
    { [defaultLocale]: {}, other: {} },
  );
};

type IsLocaleFinishedPartition = (
  data: AnyObject,
  path: TranslationPath,
  sheme?: CustomFieldLabel,
) => (locale: availableLocales) => boolean;

export const isLocaleFinished: IsLocaleFinishedPartition =
  (data = {}, path, scheme) =>
  (locale) => {
    const [fieldName] = path;
    if (!fieldName) {
      return false;
    }

    if (['distanceData', 'raceData', 'emailContent'].includes(fieldName)) {
      return !!data[`${locale}.${path.join('.')}`];
    }

    if (fieldName === 'customFields') {
      if (!scheme) {
        return false;
      }
      const fieldsToCheck = [`${locale}.${fieldName}.name`];
      if (scheme.helper_text != null) {
        fieldsToCheck.push(`${locale}.${fieldName}.helper_text`);
      }

      scheme.values?.forEach((v_, idx) => {
        fieldsToCheck.push(`${locale}.${fieldName}.values.${idx}.value`);
      });

      return fieldsToCheck.every((field) => !!data[field]);
    }

    return !!data[`${locale}.${fieldValuePath[fieldName]}`];
  };

export const getFormNameKey = (path: TranslationPath, locale?: availableLocales): string => {
  // Variety of single field names:
  // checkpoints.name
  // disciplines.title
  // distanceClasses.title
  // raceData.name
  // raceData.description
  // distanceData.name
  // distanceData.description
  // distanceData.email_content ?
  // waves.name
  // emailContent.content

  const [fieldName, key] = path;

  if (!fieldName) {
    return '';
  }
  // Prefix locale for non-label props
  const result = locale ? `${locale}.` : '';

  if (['distanceData', 'raceData', 'emailContent'].includes(fieldName)) {
    return `${result}${fieldName}.${key}`;
  }

  return `${result}${fieldValuePath[fieldName]}`;
};

export const getModelData = (
  name: string | string[],
  path: TranslationPath,
  defaultLocaleModel: LookUpHash[availableLocales],
): TranslateModelData => {
  if (!Array.isArray(name)) {
    name = name.split('.');
  }
  let [localeKey, rootLabel, fieldIdx, field, index, valueKey] = name;
  const isStagesUpdate = Number.isNaN(Number(fieldIdx));
  if (isStagesUpdate) {
    [localeKey, rootLabel, field, index, valueKey] = name;
  }
  // Table update name example:
  // distanceClasses.0.title
  // customFields.1.values.2.value

  // Abstract translations name example:
  // distanceClasses.title
  // customFields.values.2.value

  // In the meantime, base data is shallow object:
  // distanceData.title
  // distanceData.description

  const locale = localeKey as availableLocales;

  const textKey = valueKey || field;

  //   const basePath: Array<string | number> = isStagesUpdate ? path : [rootLabel, fieldIdx];
  let resultPath: Array<string | number> = isStagesUpdate ? path : [rootLabel, fieldIdx];
  if (rootLabel === 'customFields' && field === 'values') {
    resultPath = [...resultPath, field, index];
  }

  if (['distanceData', 'raceData', 'emailContent'].includes(rootLabel)) {
    resultPath = [rootLabel];
  }

  const modelId = get(defaultLocaleModel, [...resultPath, translationsModelIdMap[`${rootLabel}.${textKey}`]]);

  return { modelId, key: textKey, modelName: rootLabel, locale };
};

export const format = 'yyyy-mm-dd HH:MM:SS';
export const beFormat = 'datetimems';
export const placeholder = 'YYYY-MM-DD hh:mm:ss.ss';
export const mask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/, // year
  '-',
  /\d/,
  /\d/, // month
  '-',
  /\d/,
  /\d/, // day
  ' ',
  /\d/,
  /\d/, // hour
  ':',
  /\d/,
  /\d/, // minute
  ':',
  /\d/,
  /\d/, // second
  '.',
  /\d/,
  /\d/, // millisecond
];
export const placeholderChar = '_';

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { ORGANIZER_RACERS_EXPORT_URL } from 'src/constants';

import { request, action } from 'utils';

import { EXPORT_RACERS } from '../constants';

export class ExportService {
  @request({ action: EXPORT_RACERS })
  exportRequest(params?: FiltersType): any {
    const url = generatePath(ORGANIZER_RACERS_EXPORT_URL);
    return axios({
      method: 'GET',
      responseType: 'blob',
      params,
      url,
    });
  }

  @action({ action: EXPORT_RACERS })
  async exportRacers(params?: FiltersType): Promise<[Boolean, Blob]> {
    const [status, response] = await this.exportRequest(params);

    return [status, response.data];
  }
}

const exportService = new ExportService();
export { exportService };

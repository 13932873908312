import { observable, action, makeObservable } from 'mobx';

import { errorsStore } from 'stores';

class FieldRegister {
  @observable
  value: {
    [K in string]: boolean;
  } = {};

  constructor() {
    makeObservable(this);
  }

  @action
  activateField(fieldId: string) {
    this.deactivateAllFields();
    errorsStore.clear(fieldId);
    this.value[fieldId] = true;
  }

  @action
  deactivateField(fieldId: string) {
    this.value[fieldId] = false;
  }

  @action
  deactivateAllFields() {
    const { value } = this;
    Object.keys(value).forEach((id) => {
      value[id] = false;
    });
  }

  isActive(fieldId: string): boolean {
    return !!this.value[fieldId];
  }
}

export { FieldRegister };

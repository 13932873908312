import { makeObservable } from 'mobx';

import { DISTANCE_TYPE } from 'src/constants';

import { Loadable, Sort, Search } from 'stores';

import { filterDescriptors } from '../Filters';
import { TeamResultsStore, SingleResultsStore } from './index';
import { distanceStore, singleResultsStore, teamResultsStore } from './index';

const sortStore = new Sort(filterDescriptors);
const searchStore = new Search(filterDescriptors);

// For Results module is mainly used two stores:
// SingleResultsStore
// TeamResultsStore
// if distance is not loaded yet, necessary to stub calls to store from ui
class ResultsStub extends Loadable<ResultType, FiltersType> {
  declare values: ResultType[];

  sort = sortStore;
  search = searchStore;

  extensions = [this.sort, this.search];

  constructor() {
    super();

    makeObservable(this);
    this.values = [];
  }

  retrieveActiveStore(): ResultsStub | SingleResultsStore | TeamResultsStore {
    const value = distanceStore.selected;

    switch (value?.type) {
      case DISTANCE_TYPE.single:
        return singleResultsStore;
      case DISTANCE_TYPE.team:
        return teamResultsStore;
      default:
        return this;
    }
  }
}

export { ResultsStub, ResultsStub as ResultsStubStore, sortStore, searchStore };

import { DeleteOutline, DragIndicator, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Card, CardContent, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import { ERRORS_PREFIXES } from 'modules/Distances/constants';
import { clearFieldError, getRelationErrors } from 'modules/Distances/utils/errors';
import * as React from 'react';

import { CONFIRM_POPUP_TYPES, DISTANCE } from 'src/constants';

import { DNDItem } from 'components/DragNDrop';

import { t } from 'utils';
import numberWithSuffix from 'utils/numberSuffix';

import { progressStore } from 'stores';
import confirmModalStore from 'stores/confirmationModal';

const FieldContainer = withStyles({
  root: {
    paddingBottom: 19,
  },
})(Box);

type Props = {
  onChange: (id: number | string, data: ClassType, callback?: Function) => void;
  onRemove: (id: number | string) => void;
  value: ClassType;
  index: number;
  isExpanded?: boolean;
  isDragDisabled?: boolean;
};

type State = {
  isExpanded: boolean;
};

@observer
class ItemDnd extends React.Component<Props, State> {
  state = {
    isExpanded: true,
  };

  onChange = (name: string, changedValue: unknown) => {
    const { value, onChange } = this.props;

    const changeData = { ...value, [name]: changedValue };

    const id = value.id || value.__id || '';
    onChange(id, changeData, () => {
      clearFieldError(this.props.index, ERRORS_PREFIXES.classes, name);
    });
  };

  onChangePlain = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.onChange(name, value);
  };

  defaultInputProps = (name: string) => {
    const value = (this.props.value as AnyObject)[name];
    return {
      value,
      name,
      errors: [],
      fullWidth: true,
    };
  };

  onRemove = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    const { onRemove, value } = this.props;
    const id = value.id || value.__id || '';
    confirmModalStore.openModal({
      title: t.staticAsString('distances.steps.classesForm.confirmDeleteTitle'),
      body:
        value.title.length === 0
          ? t.staticAsString('distances.steps.classesForm.confirmDeleteBodyEmpty')
          : t.staticAsString('distances.steps.classesForm.confirmDeleteBody', { name: value.title }),
      type: CONFIRM_POPUP_TYPES.delete,
      btnLabel: {
        confirm: 'distances.steps.wavesForm.confirmDeleteOk',
        cancel: 'shared.confirmPopup.btnAction.cancel',
      },
      onConfirm: () => onRemove(id),
    });
  };

  render() {
    const { isExpanded } = this.state;
    const { index, value, isDragDisabled } = this.props;
    const defaultClassTitle = numberWithSuffix(index + 1) + ' ' + t.staticAsString('racers.new.class');
    const classTitle = isExpanded ? defaultClassTitle : value.title;
    const disabled = progressStore.isLoading(`UPDATE_${DISTANCE}`);

    if (value._delete) {
      return null;
    }

    return (
      <DNDItem className='wave-dnd-item' id={`${value.id || value.__id || ''}`} index={index} isDragDisabled={disabled}>
        <Card variant='outlined'>
          <Box
            style={{ backgroundColor: '#FAFAFA', cursor: 'pointer' }}
            p={2}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}
          >
            <Box display='flex' alignItems='center'>
              <DragIndicator style={{ marginRight: 10 }} color='disabled' />
              <Typography>{classTitle}</Typography>
            </Box>
            <Box>
              {index > 0 && <DeleteOutline style={{ marginRight: 10 }} onClick={this.onRemove} color='disabled' />}
              {isExpanded ? <ExpandLess color='disabled' /> : <ExpandMore color='disabled' />}
            </Box>
          </Box>
          <CardContent style={{ display: isExpanded ? 'block' : 'none' }}>
            <FieldContainer>
              <TextField
                {...this.defaultInputProps('title')}
                label={t.staticAsString('distances.steps.classesForm.title')}
                error={getRelationErrors(index, ERRORS_PREFIXES.classes, 'title')?.[0]}
                helperText={getRelationErrors(index, ERRORS_PREFIXES.classes, 'title') ?? ''}
                onChange={this.onChangePlain}
                variant='outlined'
                disabled={disabled}
              />
            </FieldContainer>
          </CardContent>
        </Card>
      </DNDItem>
    );
  }
}

export { ItemDnd };

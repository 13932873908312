import { NavLink } from 'react-router-dom';

import { t } from 'utils';

type Props = {
  item: NavToolbarItemType;
};

const NavElement = ({ item }: Props) => {
  const itemMessage = t.staticAsString(item.label as TranslationLockedKeys);
  const message = itemMessage !== '' ? itemMessage : item.label;
  return item.path ? (
    <NavLink className='nav-link' to={item.path}>
      {message}
    </NavLink>
  ) : (
    <span>{message}</span>
  );
};

export { NavElement };

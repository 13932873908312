import { defaultListFilters } from 'utils';

export const resultDiff: FilterListDescriptor = {
  filter: defaultListFilters.none,
  sort: {
    calcOrderBy: (_name, _sortBy, options) => {
      if (!options) {
        return '';
      }
      const id = options.id;
      return `splits:id|result_diff|checkpoint_id|${id}`;
    },
  },
};

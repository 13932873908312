import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

let unlisten: any;

const blockRouting = () => {
  if (unlisten) {
    return unlisten;
  }

  unlisten = history.listen((location: { pathname: string }) => {
    const isNotStubUrl = !matchPath(location.pathname, ROUTES.serviceUnavailable);

    if (isNotStubUrl) {
      history.replace(ROUTES.serviceUnavailable);
    }
  });

  return unlisten;
};

export { blockRouting };

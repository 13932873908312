import { defaultListFilters } from 'utils';

import { FilterDescriptorWrapper } from 'models';

import { reportedBy } from './reportedBy';

type CopyRacersDescriptors = 'id' | 'organizer';
type Descs = { [K in CopyRacersDescriptors]: FilterListDescriptor };
type DescsWrapped = { [K in CopyRacersDescriptors]: FilterDescriptorWrapper };

const descriptors: Descs = {
  id: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      default: true,
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  organizer: reportedBy,
};

const descriptorsKeys = Object.keys(descriptors) as Array<CopyRacersDescriptors>;

export const filterDescriptors: DescsWrapped = descriptorsKeys.reduce((acc, desc) => {
  return {
    ...acc,
    [desc]: new FilterDescriptorWrapper(desc, descriptors[desc]),
  };
}, {} as DescsWrapped);

import { observable, action, makeObservable, override } from 'mobx';

import { Loadable, Search, Sort } from 'stores';

import { filterDescriptors } from '../filters';
import { TaskTable as TaskType } from '../types';

const sortStore = new Sort(filterDescriptors);
const searchStore = new Search(filterDescriptors);

export class Tasks extends Loadable<TaskType, FiltersType> {
  defaultFilters: FiltersType = {
    orderBy: 'finished_at',
    sortedBy: 'desc',
    limit: 30,
  };

  sort = sortStore;
  search = searchStore;
  extensions = [this.sort, this.search];

  @observable
  handlers = {
    id: 'between',
  };

  declare filters;

  constructor() {
    super();

    makeObservable(this);
    this.filters = this.defaultFilters;
  }

  @action
  updateSearch(search: string, searchFields: string): void {
    if (!search) {
      this.filters.search = null as any;
      this.filters.searchFields = null as any;
      delete this.filters.search;
      delete this.filters.searchFields;
    } else {
      this.filters.search = search;
      this.filters.searchFields = searchFields;
    }
  }

  @action
  updateSearchHandlers(
    newHandlers: {
      [K in string]: string;
    },
  ) {
    this.handlers = {
      ...this.handlers,
      ...newHandlers,
    };
  }

  @action
  resetFilters(): void {
    this.clearFilters();
  }

  @override
  clearAll() {
    super.clearAll();
    this.clearData();
    this.clearFilters();
    this.filters = this.defaultFilters;
  }

  @action
  async filterByName(name: string, value: number) {
    this.filters[name] = value;
  }

  @action
  async disableFilterByName(name: string) {
    this.filters[name] = null;
    delete this.filters[name];
  }
}

const tasksStore = new Tasks();
export { tasksStore };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { DISTANCE, DISTANCE_HIDE, RESULTS_URL, START_LIST_URL, DISTANCE_URL } from 'src/constants';

import { request, action, t } from 'utils';

import { toastStore, helperDistancesStore } from 'stores';
import { HelperDistances as Distances } from 'stores';

class DistanceService {
  store: Distances;

  constructor(store: Distances) {
    this.store = store;
  }

  @request({ action: `HIDE_START_LIST_${DISTANCE}` })
  isHiddenStartListRequest(distanceId: number, params: FiltersType): any {
    return axios.patch(generatePath(START_LIST_URL, { distanceId }), params);
  }

  @action({ action: `HIDE_START_LIST_${DISTANCE}` })
  async isHiddenStartList(distanceId: number, currentValue: boolean) {
    const updateParams = {
      show_startlist: !currentValue,
    };

    const [isOk] = await this.isHiddenStartListRequest(distanceId, updateParams);

    const { show_startlist } = updateParams;

    if (isOk) {
      this.store.partialUpdateSelectedValue(updateParams);
      toastStore.show(t(!show_startlist ? 'races.detail.hidingStartlist.successMessage' : 'races.detail.showingStartlist.successMessage'));
    }
  }

  @request({ action: `TOGGLE_BIB_NUMBERS_${DISTANCE}` })
  toggleBibsVisibilityRequest(distanceId: number, params: FiltersType): any {
    return axios.patch(generatePath(DISTANCE_URL, { distanceId }), params);
  }

  @action({ action: `TOGGLE_BIB_NUMBERS_${DISTANCE}` })
  async toggleBibsVisibility(distanceId: number, _raceId: number, isVisible: boolean) {
    const updateParams = {
      show_bibs: isVisible,
    };

    const [isOk] = await this.toggleBibsVisibilityRequest(distanceId, updateParams);

    if (isOk) {
      this.store.partialUpdateSelectedValue(updateParams);
      toastStore.show(t(isVisible ? 'races.detail.showingBibNumbers.successMessage' : 'races.detail.hidingBibNumbers.successMessage'));
    }
  }

  @request({ action: `HIDE_RESULTS_${DISTANCE}` })
  isHiddenResultsRequest(raceId: number, distanceId: number, params: FiltersType): any {
    return axios.patch(generatePath(DISTANCE_HIDE, { distanceId }), params);
  }

  @action({ action: `HIDE_RESULTS_${DISTANCE}` })
  async isHiddenResults(raceId: number, distanceId: number, currentValue: number) {
    const updateParams = {
      show_results: +!currentValue,
    };

    const [status, response] = await this.isHiddenResultsRequest(raceId, distanceId, updateParams);
    const show = response.data.data.show_results;

    if (status) {
      this.store.partialUpdateSelectedValue(updateParams);
      toastStore.show(t(!show ? 'races.detail.hidingResults.successMessage' : 'races.detail.showingResults.successMessage'));
    }
  }

  @request({ action: `RESET_${DISTANCE}` })
  resetDistanceResultsRequest(raceId: number, distanceId: number): any {
    return axios.delete(generatePath(RESULTS_URL, { raceId, distanceId }));
  }

  @action({ action: `RESET_${DISTANCE}` })
  async resetDistanceResults(raceId: number, distanceId: number) {
    const [status] = await this.resetDistanceResultsRequest(raceId, distanceId);

    if (status) {
      toastStore.show(t.staticAsString('distances.reset.results'));
      return;
    }

    toastStore.show(t.staticAsString('distances.canNotDelete'));
  }
}

export { DistanceService };
export default new DistanceService(helperDistancesStore);

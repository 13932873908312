import axios from 'axios';
import debounce from 'lodash/debounce';
import { action, computed, observable, makeObservable } from 'mobx';

import { ADMIN, ORGANIZER, AUTHORIZATION_HEADER } from 'src/constants';

import { history, intercomUtil, sessionService } from 'utils';

export class Session {
  @observable
  session: SessionType = sessionService.retrieveSession();
  debouncedSetToken: Function;

  constructor() {
    makeObservable(this);
    this.updateToken();
    this.debouncedSetToken = debounce(this.setToken, 5000);
  }

  @computed
  get isLogged(): boolean {
    return !!(this.session.user && this.session.token);
  }

  @computed
  get isAdmin(): boolean {
    const roles = (this.session.user.roles || []).map((role) => role.name);
    return roles.includes(ADMIN);
  }

  @computed
  get isOrganizer(): boolean {
    const roles = (this.session.user.roles || []).map((role) => role.name);
    return roles.includes(ORGANIZER) && !roles.includes(ADMIN);
  }

  @computed
  get isOrganizerVerified(): boolean {
    return this.session.user.verified;
  }

  @computed
  get email(): string {
    return this.session.user.email;
  }

  @action
  async login(user: User, token: string) {
    this.session.user = user;
    this.session.token = token;
    this.updateToken();
    sessionService.storeSession(user, token);

    intercomUtil.addUserInfo();
  }

  @action
  async logout() {
    // @ts-ignore
    this.session.user = {};
    this.session.token = null;

    intercomUtil.deleteUserInfo();

    this.updateToken();
    history.push('/');

    const { user, token } = this.session;
    sessionService.storeSession(user, token);
  }

  @computed
  get user(): User | null {
    if (this.isLogged) {
      const user: User = this.session.user as any;
      return user;
    } else {
      return null;
    }
  }

  @action
  setFullName(fullName: string) {
    const user: User = this.session.user as any;
    user.full_name = fullName;

    this.login(user, this.session.token as string);
  }

  @action
  setUserVerified() {
    const user: User = this.session.user as any;
    user.verified = true;
    this.login(user, this.session.token as string);
  }

  @computed
  get fullName(): string {
    const user = this.user;
    return (user && user.full_name) || '';
  }

  @action
  setToken(newToken: string) {
    if (typeof newToken !== 'string') return;
    this.session.token = this.parseToken(newToken);
    this.updateToken();
    const { user, token } = this.session;
    sessionService.storeSession(user, token);
  }

  updateToken() {
    const token = this.session.token;
    if (token) {
      axios.defaults.headers.common[AUTHORIZATION_HEADER] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common[AUTHORIZATION_HEADER];
    }
  }

  parseToken(token: string): string {
    const match = token.match(/Bearer\s(.*)/);

    if (match) return match[1];
    else return '';
  }
}

export default new Session();

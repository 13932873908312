import { ACTIVITY_LINKS, DEFAULT_LINK } from 'src/constants';

import { IconNameType } from 'img/activity_links';

type Link = {
  contains?: string;
  name: string;
  icon: IconNameType;
};

export const recognizeLink = (link: string): Link => {
  return ACTIVITY_LINKS.find((item) => link.toLowerCase().includes(item.contains)) || DEFAULT_LINK;
};

import { registrationToast } from '../utils';

import { raceDetailsService } from '../services';

import { raceDetailsStore } from '../stores';

export const toggleRegistrationAction = async (newValue: boolean) => {
  const race = raceDetailsStore.raceDetails?.value;

  if (!race) {
    return;
  }

  const { id } = race;

  const params = {
    allow_registration: newValue,
  };

  raceDetailsStore.partialRaceUpdate(params);

  const [status] = await raceDetailsService.toggleRegistration(id, params);

  // correct server response, show success message
  registrationToast(status, newValue);

  if (status) {
    return;
  }

  // bad server response, error message was shown before, roll back state
  const previousValue = !newValue;

  raceDetailsStore.partialRaceUpdate({ allow_registration: previousValue });
};

export const unRegisterAction = () => {
  toggleRegistrationAction(false);
};

export const registerAction = () => {
  toggleRegistrationAction(true);
};

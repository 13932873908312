// @flow
import { action, computed, makeObservable } from 'mobx';

import { currentDistance } from '../../derivations';
import { RacersStore } from './racers';
import { TeamsStore } from './teams';

// Wrapper over teams and racers stores
class ResultableWrapper {
  racersStore: RacersStore;
  teamsStore: TeamsStore;

  constructor(racersStore: RacersStore, teamsStore: TeamsStore) {
    makeObservable(this);
    this.racersStore = racersStore;
    this.teamsStore = teamsStore;
  }

  @action
  delete(id: number) {
    if (currentDistance.isTeam.get()) {
      this.teamsStore.deleteTeam(id);
      return;
    }

    this.racersStore.deleteRacer(id);
  }

  @computed
  get selectOptions(): SelectOption[] {
    if (currentDistance.isTeam.get()) {
      return this.teamsStore.selectOptions;
    }

    return this.racersStore.selectOptions;
  }

  fetchName(id: number): string {
    let name = '';

    if (currentDistance.isTeam.get()) {
      const team = this.teamsStore.find(id);
      name = team?.value?.name || '';
    }

    if (currentDistance.isSingle.get()) {
      const racer = this.racersStore.find(id);
      name = racer?.value.full_name || '';
    }

    return name;
  }

  @action
  cleanValues() {
    this.racersStore.cleanValues();
    this.teamsStore.cleanValues();
  }
}

export { ResultableWrapper };

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';

import { TriggerProps } from 'components/SettingsMenu';

import { styles } from './styles';

function Trigger({ onClick, withText }: TriggerProps) {
  const classes = styles();
  return (
    <IconButton
      onClick={onClick}
      className={classes.trigger}
      aria-label='Settings'
      aria-controls='long-menu'
      aria-haspopup='true'
      size='large'
    >
      <MoreVertIcon />
    </IconButton>
  );
}

export { Trigger };

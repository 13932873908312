import { get } from 'lodash';
import * as React from 'react';

import { Show } from 'components/Condition';
import { TranslationField, InputWrapper } from 'components/Form';

import { getFormNameKey } from '../../utils';

export const SingleFields = ({
  localesToSelect,
  labels,
  selectedLocale,
  path,
  onTranslationChange,
  savedFields,
  prefilled,
}: {
  prefilled: { [k in string]?: string };
  localesToSelect: availableLocales[];
  selectedLocale: availableLocales;
  labels?: TranslationLabel;
  path: TranslationPath;
  savedFields: { [k in FieldName]?: boolean };
  onTranslationChange: Function;
}) => {
  const handleFieldBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.target.value;

    onTranslationChange({ name, value, blur: true });
  };

  return (
    <React.Fragment>
      {localesToSelect.map((locale) => (
        <Show if={selectedLocale === locale} key={locale}>
          <InputWrapper
            name={getFormNameKey(path, locale)}
            settings={{
              additional: {
                onBlur: handleFieldBlur,
                onInputChange: onTranslationChange,
              },
              label: get(labels, getFormNameKey(path), ''),

              hasCheckmark: savedFields[getFormNameKey(path, locale)],
              prefilledValue: prefilled[getFormNameKey(path, locale)],
            }}
            Component={TranslationField}
          />
        </Show>
      ))}
    </React.Fragment>
  );
};

// RESULTS
// Team Results

export type TeamCols =
  | 'place'
  | 'start_time'
  | 'finish_time'
  | 'time_result'
  | 'team.bib_number'
  | 'team.name'
  | 'team.class_id'
  | 'team.wave_id';

export const teamFields = {
  place: 'place',
  start_time: 'start_time',
  finish_time: 'finish_time',
  time_result: 'time_result',
  'team.bib_number': 'team.bib_number',
  'team.name': 'team.name',
  'team.class_id': 'team.class_id',
  'team.wave_id': 'team.wave_id',
};

const MAIN_COLUMNS = [
  teamFields.place,
  teamFields['team.name'],
  teamFields['team.bib_number'],
  teamFields['team.class_id'],
  teamFields['team.wave_id'],
  teamFields.start_time,
  teamFields.finish_time,
  teamFields.time_result,
];

const COLUMN_WIDTH: {
  [K in string]: number;
} = {
  [teamFields['racer.email']]: 200,
};

const TEAM_RESULTS_COLUMNS = {
  MAIN_COLUMNS,
  COLUMN_WIDTH,
};

export { TEAM_RESULTS_COLUMNS };

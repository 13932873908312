import Chip from '@mui/material/Chip';
import classNames from 'classnames';
import * as React from 'react';

import { filterFormatValue, filterFormatArray } from 'utils';

type Props = {
  value: FilterType;
  name: string;
  onClear: (...filterNames: Array<string>) => void;
  onOpen?: (e: React.SyntheticEvent, name: string) => void;
  className: string;
  beforeLabelText?: string;
};

const classes = {
  root: 'chip-card-root',
  label: 'chip-label',
  deleteIcon: 'chip-delete-icon',
};

const LabelForFilter = ({ name, onClear, onOpen, className = '', value, beforeLabelText = '' }: Props) => {
  if (!value) {
    return null;
  }

  let label = filterFormatValue(value);
  if (beforeLabelText) label = `${beforeLabelText} ${filterFormatValue(label)}`;
  return (
    <Chip
      className={classNames(className, 'chip-card')}
      classes={classes}
      onDelete={() => onClear(name)}
      onClick={(e: React.SyntheticEvent) => onOpen && onOpen(e, name)}
      label={label}
    />
  );
};

export { classes };

// TODO, must be removed after refactoring all filters
// hack to work with old filters
export { filterFormatArray as formatArray, filterFormatValue as formatValue, LabelForFilter };

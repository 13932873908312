import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GetApp from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

import { CONFIRM_POPUP_TYPES, EXPORT_DATA, EXPORT_DATA_SIZES } from 'src/constants';

import { Show } from 'components/Condition';
import { Icon } from 'components/Icon';

import { t } from 'utils';
import { appEnvControl } from 'utils/appEnvironment';

import confirmModalStore from 'stores/confirmationModal';
import locale from 'stores/locale';

import { resultsStubStore } from '../../stores';

type AllowedActions = {
  import: boolean;
  export: boolean;
  exportTemplate: boolean;
  edit: boolean;
  delete: boolean;
};

type ClickEvent = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

type Props = {
  actions: AllowedActions;
  onImportResults: ClickEvent;
  onExportResults: ClickEvent;
  onExportTemplate: ClickEvent;
  onEditDistance: ClickEvent;
  onDeleteDistance: ClickEvent;
};

const ActionsComponent = ({ actions, onExportResults, onImportResults, onExportTemplate, onEditDistance, onDeleteDistance }: Props) => {
  const confirmExport = () => {
    const {
      paginationMeta: { total = EXPORT_DATA.defaultMetaData },
    } = resultsStubStore.retrieveActiveStore();

    if (total <= EXPORT_DATA_SIZES[appEnvControl.currentEnvWithStub()]) return onExportResults();

    confirmModalStore.openModal({
      title: t.staticAsString('shared.exportConfirm.title') as any,
      body: t.staticAsString('shared.exportConfirm.body', {
        maxAmount: EXPORT_DATA_SIZES[appEnvControl.currentEnvWithStub()].toLocaleString(locale.value),
      }) as any,
      type: CONFIRM_POPUP_TYPES.confirm,
      onConfirm: () => onExportResults,
    });
  };

  return (
    <div className='buttons'>
      <div className='export-buttons'>
        <ul className='list-buttons'>
          <Show if={actions.exportTemplate}>
            <Button className='button-item' onClick={onExportTemplate}>
              <Tooltip
                disableInteractive
                title={t.staticAsString('results.actions.tooltip.template')}
                classes={{ tooltip: 'switch-popover' }}
              >
                <div>
                  <div className='button-icon'>
                    <Icon value='file-download' />
                  </div>
                  <div className='button-title'>{t.staticAsString('results.actions.template')}</div>
                </div>
              </Tooltip>
            </Button>
          </Show>
          <Show if={actions.import}>
            <Button className='button-item' onClick={onImportResults}>
              <Tooltip title={t.staticAsString('results.actions.tooltip.import') as any} classes={{ tooltip: 'switch-popover' }}>
                <div>
                  <div className='button-icon'>
                    <Icon value='import' />
                  </div>
                  <div className='button-title'>{t.staticAsString('results.actions.import')}</div>
                </div>
              </Tooltip>
            </Button>
          </Show>
          <Show if={actions.export}>
            <Button className='button-item' onClick={confirmExport}>
              <Tooltip
                disableInteractive
                title={t.staticAsString('results.actions.tooltip.export') as any}
                classes={{ tooltip: 'switch-popover' }}
              >
                <div>
                  <div className='button-icon'>
                    <GetApp />
                  </div>
                  <div className='button-title'>{t.staticAsString('results.actions.export')}</div>
                </div>
              </Tooltip>
            </Button>
          </Show>
          <Show if={actions.edit}>
            <Button className='button-item' onClick={onEditDistance}>
              <div>
                <div className='button-icon'>
                  <EditIcon />
                </div>
                <div className='button-title'>{t.staticAsString('racers.list.edit')}</div>
              </div>
            </Button>
          </Show>
          <Show if={actions.delete}>
            <Button className='button-item' onClick={onDeleteDistance}>
              <div>
                <div className='button-icon'>
                  <DeleteIcon />
                </div>
                <div className='button-title'>{t.staticAsString('racers.list.delete')}</div>
              </div>
            </Button>
          </Show>
        </ul>
      </div>
    </div>
  );
};

const Actions = React.memo<Props>(ActionsComponent);

export { Actions };

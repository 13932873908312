const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;
const DATA_URL_PATTERN =
  /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i;

const urlSanitizer = (url: string = 'about:blank'): string => {
  const trimmedUrl = url.trim();

  return trimmedUrl.match(SAFE_URL_PATTERN) || trimmedUrl.match(DATA_URL_PATTERN) ? trimmedUrl : 'invalid url';
};

export { urlSanitizer };

import { PERSIST_DISTANCE as action } from 'src/constants';

import { Validator } from 'utils';

import { helperDistancesStore, errorsStore } from 'stores';

import { generateDateValidation } from '../validations';

const constraints = (distance: DistanceType, isNotClassical: boolean) => () => ({
  registration_starts_at: generateDateValidation('registration_starts_at', distance, {
    required: true,
    allowRegisrationStartPastToday: true,
  }),
  registration_ends_at: generateDateValidation('registration_ends_at', distance, { required: false }),
  race_date: generateDateValidation('race_date', distance, { required: false }),

  ...(isNotClassical
    ? {
        ends_at: generateDateValidation('ends_at', distance, { required: false }),
      }
    : {}),
});

let validateRaceDate = () => {
  const distance = helperDistancesStore.modelSelected;

  if (!distance) {
    return;
  }

  const data = {
    registration_starts_at: distance.value.registration_starts_at,
    registration_ends_at: distance.value.registration_ends_at,
    race_date: distance.value.race_date,
    ends_at: distance.value.ends_at,
  };

  const isNotClassical = distance.isMode('virtual') || distance.isMode('cumulative');

  const errors = Validator.validate(data, constraints(distance.value, isNotClassical));

  if (!errors) {
    return;
  }

  errorsStore.addFrontendError(action, errors as any, data);
};

export { validateRaceDate };

import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { Button } from 'components/core';

import { t } from 'utils';

import { ResultsContext } from '../../context';

const styles = makeStyles({
  btnWrapper: {
    cursor: 'pointer',
  },
});

const tooltipStyles = makeStyles({
  tooltip: {
    fontSize: 12,
  },
});

function AddResult() {
  const context = React.useContext(ResultsContext);
  const classes = styles();
  const tooltipClasses = tooltipStyles();

  return (
    <Observer>
      {() => {
        const { newResultForm } = context.results.actions;
        const { currentDistance } = context.results.derivations;
        const { selectOptions } = context.results.stores.resultableWrapperStore;

        if (currentDistance.model.get()?.isMode('cumulative')) {
          return null;
        }

        const [isDisabled, reason] = ((): [boolean, TranslationLockedKeys] => {
          const { isNoResultable, isResultsPresented, currentDistance } = context.results.derivations;

          if (!!!currentDistance.model.get()?.isStatusFinished() && currentDistance.model.get()?.isMode('classic')) {
            return [true, 'results.messages.cannotAdd'];
          }

          if (isNoResultable.get() && isResultsPresented.get()) {
            if (currentDistance.isSingle.get()) {
              return [true, `results.errors.no_racers2`];
            } else {
              return [true, `results.errors.no_teams2`];
            }
          }

          if (isNoResultable.get()) {
            if (currentDistance.isSingle.get()) {
              return [true, `results.errors.no_racers1`];
            } else {
              return [true, `results.errors.no_teams1`];
            }
          }

          if (isEmpty(selectOptions)) {
            if (currentDistance.isSingle.get()) {
              return [true, `results.errors.allRacersHaveResults`];
            } else {
              return [true, `results.errors.allTeamsHaveResults`];
            }
          }

          return [false, '' as TranslationLockedKeys];
        })();

        const message = t.staticAsString(reason);

        return (
          <Tooltip classes={tooltipClasses} disableHoverListener={!isDisabled} title={message}>
            <span className={classes.btnWrapper}>
              <Button disabled={isDisabled} onClick={newResultForm.open} type='add'>
                {t.staticAsString('results.addResult')}
              </Button>
            </span>
          </Tooltip>
        );
      }}
    </Observer>
  );
}

export { AddResult };

import { toJS } from 'mobx';
import { generatePath, NavLink } from 'react-router-dom';

import { ROUTES } from 'src/constants/routes';

import { gender } from 'utils';

import { racersFields } from '../../../constants';

import { racerStatus, formatCountry } from '../../../utils';

import { RacersContextType } from '../../../context';
import { Racer } from '../../../types';

type RacerModel = {
  value: Racer;
};

const FIELD_SHOW = {
  [racersFields['distance.race_parent_id']]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return (
        <NavLink to={generatePath(ROUTES.raceRoute, { id: model.value.distance?.race.id })}>{model.value.distance?.race.name}</NavLink>
      );
    };
  },

  [racersFields['distance_id']]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return (
        <NavLink to={generatePath(ROUTES.distancesRacersRoute, { raceId: model.value.distance?.race.id, id: model.value.distance?.id })}>
          {model.value.distance?.name}
        </NavLink>
      );
    };
  },

  [racersFields.email]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return model.value.email;
    };
  },

  [racersFields.firstname]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return model.value.firstname;
    };
  },

  [racersFields.lastname]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return model.value.lastname;
    };
  },

  [racersFields.gender]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return gender.format(model.value.gender);
    };
  },

  [racersFields.telephone]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return model.value.telephone;
    };
  },

  [racersFields.country_id]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return formatCountry(model.value.country_id);
    };
  },

  [racersFields.city]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return model.value.city;
    };
  },

  [racersFields.address]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return model.value.address;
    };
  },

  [racersFields.post_code]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return model.value.post_code;
    };
  },

  [racersFields.union]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return model.value.union;
    };
  },

  [racersFields.birthday]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return model.value.birthday;
    };
  },

  [racersFields.team_name]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return model.value.team && model.value.team.name;
    };
  },

  [racersFields.status]: function (model: RacerModel, context: RacersContextType): Function {
    return () => {
      return racerStatus.formatStatus(model.value);
    };
  },
};

export { FIELD_SHOW };

import { forceSet, forceReset } from './forceSet';
import { init } from './init';

const apiOriginManager = {
  init,
  forceReset,
  forceSet,
};

/*
 * Via this simple tool, dev can change api origin dynamicly
 * without page reload or rebuilding
 */
window.apiOriginManager = apiOriginManager;

export { apiOriginManager };

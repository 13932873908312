import { omit } from 'lodash';
import { observable, action, makeObservable } from 'mobx';

class SearchSelect {
  @observable
  values: { [key in string]: string } = {};

  constructor() {
    makeObservable(this);
  }

  @action
  setSearchValue(name: string, value: string) {
    this.values[name] = value;
  }

  @action
  clear(name: string) {
    this.values = omit(this.values, name);
  }
}

export const searchSelectStore = new SearchSelect();

import * as React from 'react';

import { Add } from './Add';
import { Apply } from './Apply';
import { Cancel } from './Cancel';

export type Props = {
  onClick?: (e: React.SyntheticEvent) => any;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  classes?: any;
  color?: any;
  component?: React.ElementType;
  dense?: boolean;
  disableFocusRipple?: boolean;
  disableRipple?: boolean;
  fab?: boolean;
  href?: string;
  raised?: boolean;
};

type ButtonTypes = 'add' | 'cancel' | 'apply';

function Button(
  props: Props & {
    type: ButtonTypes;
  },
) {
  const { type, ...rest } = props;
  switch (type) {
    case 'add':
      return <Add {...rest} />;
    case 'apply':
      return <Apply {...rest} />;
    case 'cancel':
      return <Cancel {...rest} />;
    default:
      return null;
  }
}

export { Button };

import { localeStore } from 'stores';

class SportType {
  value: SportTypeType;
  constructor(value: SportTypeType) {
    this.value = value;
  }

  title(): nil | string {
    const locale = localeStore.value;
    let title = '';
    switch (locale) {
      case 'en':
        title = this.value.title_en;
        break;
      case 'sv':
        title = this.value.title;
        break;
      default:
        title = this.value.title_en;
    }
    return title;
  }
}

export { SportType };

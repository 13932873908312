import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';

import { CONFIRM_POPUP_TYPES } from 'src/constants';

import { t } from 'utils';

type Props = {
  title: string;
  body: string;
  onClose: React.EventHandler<React.MouseEvent>;
  onConfirm: React.EventHandler<React.MouseEvent>;
  open: boolean;
  btnLabel?: { confirm: string; cancel: string; ok: string; delete: string };
  type?: string;
};

const classes = {
  popup: {
    title: ['popup-title'],
    body: ['popup-body'],
    text: ['popup-text'],
  },
};

const DialogControls = ({ isShown, children }: { isShown: boolean; children: React.ReactNode }) => {
  if (!isShown) {
    return null;
  }

  return <DialogActions>{children}</DialogActions>;
};

let ConfirmationModal = ({
  title,
  body,
  onClose,
  onConfirm,
  open,
  btnLabel = {
    confirm: 'shared.confirmPopup.btnAction.confirm',
    cancel: 'shared.confirmPopup.btnAction.cancel',
    ok: 'shared.confirmPopup.btnAction.ok',
    delete: 'shared.confirmPopup.btnAction.delete',
  },

  type = CONFIRM_POPUP_TYPES.confirm,
}: Props) => {
  return (
    <Dialog maxWidth='lg' open={open} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description' onClose={onClose}>
      <DialogTitle id='alert-dialog-title' className={classNames(...classes.popup.title)}>
        {title}
      </DialogTitle>

      <DialogContent className={classNames(...classes.popup.body)}>
        <DialogContentText id='alert-dialog-description'>{body}</DialogContentText>
      </DialogContent>

      <DialogControls isShown={CONFIRM_POPUP_TYPES.confirm === type}>
        <Button onClick={onClose}>{t(btnLabel.cancel as TranslationLockedKeys)}</Button>
        <Button onClick={onConfirm} color='primary' autoFocus>
          {t(btnLabel.confirm as TranslationLockedKeys)}
        </Button>
      </DialogControls>

      <DialogControls isShown={CONFIRM_POPUP_TYPES.delete === type}>
        <Button onClick={onClose}>{t(btnLabel.cancel as TranslationLockedKeys)}</Button>
        <Button onClick={onConfirm} color='secondary' autoFocus>
          {t(btnLabel.delete as TranslationLockedKeys)}
        </Button>
      </DialogControls>

      <DialogControls isShown={CONFIRM_POPUP_TYPES.info === type}>
        <Button onClick={onConfirm} color='primary' autoFocus>
          {t(btnLabel.ok as TranslationLockedKeys)}
        </Button>
      </DialogControls>
    </Dialog>
  );
};

export { ConfirmationModal };

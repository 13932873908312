import { duplicateDataType } from 'modules/RaceDetails/types';

import { monitorJobId, t } from 'utils';

import { toastStore, form } from 'stores';

import { DUPLICATE_FORM } from '../constants';

import { distancesService } from '../services';

import { raceDetailsStore, distancesStore, duplicateModalStore } from '../stores';

export const duplicateDistanceAction = async (id: number) => {
  const data: duplicateDataType = form.fetchWithoutObservable(DUPLICATE_FORM);
  duplicateModalStore.set({ open: false });

  if (!id) {
    return;
  }

  const [status, response] = await distancesService.duplicateDistance(id, data);

  if (!status) {
    toastStore.show(t.staticAsString('distances.duplicate.errorMessage'));
    return;
  }

  const { id: jobId } = response?.data?.data;
  const [monitorStatus] = await monitorJobId(jobId);

  if (!monitorStatus) {
    toastStore.show(t.staticAsString('distances.duplicate.errorMessage'));
    return;
  }

  toastStore.show(t.staticAsString('distances.duplicate.successMessage'));

  const raceId = raceDetailsStore.raceDetails?.value.id;

  if (!raceId) {
    return;
  }

  distancesStore.setPage(1);
  await distancesService.loadDistances(raceId);
};

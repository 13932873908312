import { time } from 'utils';

function duration(startTime: string, diff: number) {
  const start = time.datetimems(startTime).parse();

  start.val.add(diff, 'milliseconds');

  return start.compose().val;
}

function date(value: nil | timeSpace.WorkDate) {
  if (!value) {
    return value;
  }

  return value.compose().val;
}

const prepareForUpdate = {
  duration,
  date,
};

export { prepareForUpdate };

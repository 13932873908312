import * as React from 'react';

import { t } from 'utils';

import { CountryOfResidence } from './CountryOfResidence';
import { DateRangeFilter } from './CreatedAt';
import { IdRange } from './IdRange';
import { TextFilter } from './TextFilter';

export interface FilterProps {
  name: Name;
  value: FilterType;
  values: FiltersType;
  label: string;
  applyFilter: Function;
  updateSearchHandlers: (
    arg0: {
      [K in string]: string;
    },
  ) => void;
}

type Name = 'id' | 'full_name' | 'email' | 'telephone' | 'post_code' | 'country_id' | 'created_at';

const filtersComponents = {
  id: IdRange,
  full_name: TextFilter,
  email: TextFilter,
  telephone: TextFilter,
  post_code: TextFilter,
  country_id: CountryOfResidence,
  created_at: DateRangeFilter,
};

const Filter = (props: FilterProps) => {
  const { name } = props;
  const Component = filtersComponents[name];
  return <Component {...(props as any)} label={t.staticAsString(`customers.list.table.${name}` as TranslationLockedKeys)} />;
};

Filter.types = Object.keys(filtersComponents);

export { Filter };

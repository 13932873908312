import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { CUSTOMERS_URL } from 'src/constants';

import { action, request } from 'utils';

import { GET_EMAILS_FOR_TASKS } from '../constants';

import { mapEmails } from '../utils';

import { targetEmailStore, sourceEmailStore } from '../stores';

class EmailsService {
  @request({ action: GET_EMAILS_FOR_TASKS })
  async getEmailsRequest(params: nil | FiltersType): Promise<any> {
    const path = generatePath(CUSTOMERS_URL);
    return axios.get(path, { params });
  }

  @action({ action: GET_EMAILS_FOR_TASKS })
  async getTargetEmails(params: nil | FiltersType): Promise<any> {
    const [status, response] = await this.getEmailsRequest(params);

    if (status) {
      targetEmailStore.set(mapEmails(response.data.data));
    }
  }

  @action({ action: GET_EMAILS_FOR_TASKS })
  async getSourceEmails(params: nil | FiltersType): Promise<any> {
    const [status, response] = await this.getEmailsRequest(params);

    if (status) {
      sourceEmailStore.set(mapEmails(response.data.data));
    }
  }
}

export const emailsService = new EmailsService();

import React from 'react';

import { InputFilter } from 'components/Filter';

import { FilterProps } from './index';

type Props = {
  value: nil | string | number;
} & FilterProps;

const TextFilter = (props: Props) => {
  return <InputFilter {...(props as any)} />;
};

export { TextFilter };

import { SmarLinkCouponType, SmartLinkBE } from 'modules/SmartLinks/shared/types';

export const getDiscountAmount = (smartLink: SmartLinkBE, currency?: availableCurrencies) => {
  const discountCurrency = currency || smartLink.race.currency?.iso_code;

  if (!smartLink.discount) {
    return 0;
  }

  if (smartLink.discount_type === SmarLinkCouponType.DiscountAmontType) {
    return `${smartLink.discount_sum} ${discountCurrency}`;
  } else {
    return `${smartLink.discount_sum} %`;
  }
};

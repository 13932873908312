/*
 * Add spaces between digits
 */
const integer = (value: nil | number, showZero = false): string => {
  if (!value) {
    return showZero ? '0' : '';
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

/*
Change BE price format (integer) to FE format (float)
*/
const toFrontEndPrice = (price: nil | number): number => {
  return +((price || 0) / 100).toFixed(2);
};

export const formatter = {
  integer,
  toFrontEndPrice,
};

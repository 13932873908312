import { computed, makeObservable } from 'mobx';

import { Organizer as OrganizerModel } from 'models';

import { Loadable } from './loadable';

class HelperOrganizer extends Loadable<OrganizerType, FiltersType> {
  declare filters: FiltersType;

  constructor() {
    super();

    makeObservable(this);
    this.filters = {
      limit: 10000,
      filter: 'id;full_name;username',
    };
  }

  @computed
  get modelValues(): Array<OrganizerModel> {
    return this.values.map((race) => new OrganizerModel(race));
  }

  @computed
  get modelSelected(): nil | OrganizerModel {
    if (this.selected) {
      return new OrganizerModel(this.selected);
    } else {
      return null;
    }
  }

  valuesForSelect(): Array<SelectOption> {
    return this.modelValues.map((value) => ({
      key: value.value.id,
      value: value.value.id,
      label: value.fullName() || '',
    }));
  }
}

export { HelperOrganizer };
export default new HelperOrganizer();

import shortId from 'shortid';

import { t } from 'utils';

import { COUPON_DISCOUNT_TYPE_AMOUNT, COUPON_DISCOUNT_TYPE_PERCENTAGE } from '../constants';

export const optionsCouponDiscountType = (): SelectOption[] => {
  return [
    {
      key: shortId(),
      label: t.staticAsString('supportTaskManager.form.couponAmount'),
      value: COUPON_DISCOUNT_TYPE_AMOUNT,
    },
    {
      key: shortId(),
      label: t.staticAsString('supportTaskManager.form.couponPercentages'),
      value: COUPON_DISCOUNT_TYPE_PERCENTAGE,
    },
  ];
};

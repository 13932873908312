import { observable, action, makeObservable } from 'mobx';

import { OrganizerModel } from '../models';

import { Organizer } from '../types';

export class NameFilters {
  defaultFilters = {
    filter: 'name;id',
    with: 'distances',
    limit: -1,
  };

  @observable
  paginationMeta: PaginationMeta = {};

  constructor() {
    makeObservable(this);
  }

  @action
  addRaceNamesPaginationMeta(meta: PaginationMeta) {
    this.paginationMeta = meta;
  }

  @observable
  raceNamesValues: Array<Organizer> = [];

  @action
  addRaceNames(values: Array<Organizer>) {
    this.raceNamesValues = values;
  }
  @observable
  distanceNamesValues: Array<Organizer> = [];

  @action
  addDistanceNames(values: Array<Organizer>) {
    this.distanceNamesValues = values;
  }

  @action
  clearAll() {
    this.raceNamesValues = [];
    this.distanceNamesValues = [];
  }

  getNameById(id: number, values: Array<Organizer>): string {
    const raceName = values.find((el) => {
      return +el.id === +id;
    });

    if (!raceName) {
      return '';
    }

    const race = new OrganizerModel(raceName);

    return race.value.full_name;
  }

  @action
  getRaceNameById(id: number): string {
    return this.getNameById(id, this.raceNamesValues);
  }

  @action
  getDistanceNameById(id: number): string {
    return this.getNameById(id, this.distanceNamesValues);
  }
}

const nameFilters = new NameFilters();
export { nameFilters };

import { isNil } from 'lodash';
import moment from 'moment';

function isDiffZero(startTime: moment.Moment | nil, finishTime: moment.Moment | nil): boolean {
  if (isNil(startTime) || isNil(finishTime)) {
    return false;
  }

  if (!startTime.isValid() || !finishTime.isValid()) {
    return false;
  }

  return finishTime.diff(startTime) < 1;
}

export { isDiffZero };

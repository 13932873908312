import { ArrowForward } from '@mui/icons-material';
import { TableRow, TableCell, Typography, Card, Box, Table, TableHead, TableBody } from '@mui/material';
import { toJS } from 'mobx';
import { emptyTx } from 'modules/Dashboard/common/empty-data';
import { dashboardTextStyles } from 'modules/Dashboard/common/text.styles';
import { Transaction } from 'modules/Reconciliation/Transactions/types/transaction.type';
import { formatCurrency } from 'modules/Reconciliation/shared/utils/formatCurrency';
import { NavLink } from 'react-router-dom';

import { history } from 'utils/history';
import { t } from 'utils/t';

type Props = {
  transactions: Partial<Transaction>[];
};

export default function DashboardTransactions({ transactions }: Props) {
  const textStyles = dashboardTextStyles;

  const renderTransactions = () => {
    if (transactions.length < 10) {
      while (transactions.length < 10) {
        transactions.push(emptyTx);
      }
    }
    return transactions.map((row, index) => (
      <TableRow sx={{ height: 52.2 }} key={index++} style={index % 2 ? { background: '#FAFAFA' } : { background: 'white' }}>
        <TableCell style={{ borderBottom: 'none' }} sx={{ cursor: 'pointer', textDecoration: 'underline' }} component='th' scope='row'>
          <NavLink to={`/payments/${row.id}`}>{row.id}</NavLink>
        </TableCell>
        <TableCell style={{ borderBottom: 'none' }}>{row.email}</TableCell>
        <TableCell sx={{ cursor: 'pointer', textDecoration: 'underline' }} style={{ borderBottom: 'none' }}>
          <NavLink to={`/races/${row.race_id}`}>
            <Typography noWrap sx={{ overflow: 'elipsis', maxWidth: 150 }} variant='body2'>
              {row.race_title}
            </Typography>
          </NavLink>
        </TableCell>
        <TableCell style={{ borderBottom: 'none' }} align='right'>
          {(row.total as any) === '' ? '' : formatCurrency(row.total! / 100, row.currency!)}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Card>
      <Box p={3}>
        <Box onClick={() => history.push('/payments')} sx={{ cursor: 'pointer' }} display='flex' justifyContent='space-between'>
          <Typography sx={textStyles.cardTitle}>{t('dashboard.recentTransactions')}</Typography>
          <ArrowForward />
        </Box>
        <Box mt={3}>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell sx={{ textTransform: 'uppercase' }}>{t('dashboard.customer')}</TableCell>
                <TableCell sx={{ textTransform: 'uppercase' }}>{t('dashboard.event')}</TableCell>
                <TableCell sx={{ textTransform: 'uppercase' }} align='right'>
                  {t('dashboard.total')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTransactions()}</TableBody>
          </Table>
        </Box>
      </Box>
    </Card>
  );
}

import { action, observable, computed, makeObservable, override } from 'mobx';

import { Team as TeamModel } from 'models';

import { Loadable } from 'stores';

// All teams for the distance to display
class MembersTeam extends Loadable<TeamType, FiltersType> {
  declare filters: FiltersType;

  @observable
  hasMore: boolean = true;

  static defaultFilters = {
    limit: 20,
    withCount: 'racers',
  };

  distanceId: number = 0;

  constructor() {
    super();

    makeObservable(this);
    this.filters = MembersTeam.defaultFilters;
  }

  @action
  updateCurrentDistance(distanceId: number) {
    this.distanceId = distanceId;
  }

  @computed
  get modelValues(): Array<TeamModel> {
    return (this.values || []).map<TeamModel>((value, index) => new TeamModel(value, index.toString()));
  }

  @action
  setHasMore(hasMore: boolean = true): void {
    this.hasMore = hasMore;
  }

  @action
  setFilters(filters: FiltersType, page: number): void {
    this.filters = {
      ...this.filters,
      ...filters,
    };
    this.page = page;
  }

  @override
  clearFilters() {
    super.clearFilters();
    this.filters = MembersTeam.defaultFilters;
    this.page = 1;
  }

  @override // clear everthing without filters
  clearAll() {
    this.clearData();
    this.updateCurrentDistance(0);
    this.hasMore = true;
  }

  findModelValue(id: number): nil | TeamModel {
    return this.modelValues.find((team) => team.value.id === id);
  }
}

export { MembersTeam, MembersTeam as MembersTeamStore };

import { isNaN } from 'lodash';
import moment from 'moment';
import Xregexp from 'xregexp';

import { TIME_PATTERN_WITH_MS } from 'src/constants';

import { formatDigits } from './formatDigits';
import { formatHours } from './formatHours';

/*
 * NOTE
 * Tools for generating time mask 00:00:00 - 99:59:59
 * And conversion between diff(millis) and time mask
 */

/* NOTE
 * Calc millis number from two moment dates
 * to - from
 */
function calcDiff(from: moment.Moment, to: moment.Moment): number {
  const fromTime = from.valueOf();
  const toTime = to.valueOf();

  return toTime - fromTime;
}

/* NOTE
 * Generate (00:00:00 - 99:59:59) from milliseconds number(diff)
 * Reverse to diff
 */
function time(diff: number, showMillis: boolean = false): string {
  const duration = moment.duration(diff);

  const hours = formatDigits(formatHours(duration.asHours()));
  const minutes = formatDigits(Math.abs(duration.minutes()).toString());
  const seconds = formatDigits(Math.abs(duration.seconds()).toString());

  const formattedMillis = Math.abs(duration.milliseconds());
  const millis = showMillis && formattedMillis ? `.${('000' + formattedMillis).slice(-3, -1)}` : '';
  const minusChar = diff < 0 ? '- ' : '';

  return `${minusChar}${hours}:${minutes}:${seconds}${millis}`;
}

/* NOTE
 * Parse timeDuration (00:00:00 - 99:59:59)
 * and generate milliseconds number(diff)
 * Reverse to time
 */
function diff(time: string): nil | number {
  // @ts-ignore
  const match:
    | nil
    | (Xregexp.MatchArray & {
        hours: nil | string;
        minutes: nil | string;
        seconds: nil | string;
        milliseconds: nil | string;
      }) = Xregexp.exec(time, TIME_PATTERN_WITH_MS);
  if (!match) {
    return null;
  }

  const { hours, seconds, minutes, milliseconds } = match;

  const hour = Number(hours);
  const minute = Number(minutes);
  const second = Number(seconds);
  const millisecond = Number(milliseconds === 'ss' ? 0 : milliseconds);

  if (isNaN(hour) || isNaN(minute) || isNaN(second) || isNaN(milliseconds)) {
    return null;
  }

  return ((hour * 60 + minute) * 60 + second) * 1000 + millisecond * 10;
}

export const duration = {
  calcDiff,
  diff,
  time,
};

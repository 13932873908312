import { createFilterOptions } from '@mui/material';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { Show } from 'components/Condition';
import { AutocompleteSelect, Form, InputWrapper, TextField, TimeDurationField } from 'components/Form';

import { t } from 'utils';

import { NEW_RESULT_FORM_ID } from '../../constants';

import { ResultsContext } from '../../context';

type Props = {
  onSubmit: Function;
};

const filterOptions = createFilterOptions<{ compareData: any }>({
  stringify: (el) => el.compareData,
});

function NewResultForm({ onSubmit }: Props) {
  const context = React.useContext(ResultsContext);
  const isClassicDistance = context.results.derivations.isClassicDistance.get();
  const isSingle = context.results.derivations.currentDistance.isSingle;

  const entityIdTitle = isSingle.get() ? 'results.newForm.racer_id' : 'results.newForm.team_id';

  return (
    <Observer>
      {() => {
        const options = context.results.stores.resultableWrapperStore.selectOptions;
        const { activityLinkCorrector } = context.results.actions;

        return (
          <Form id={NEW_RESULT_FORM_ID} onSubmit={onSubmit}>
            <InputWrapper
              Component={AutocompleteSelect}
              name='entity_id'
              settings={{
                label: t.staticAsString(entityIdTitle),
                additional: {
                  options,
                  filterOptions,
                },
                view: {
                  variant: 'outlined',
                  margin: 'normal',
                  fullWidth: true,
                  reservePlaceForHelperText: true,
                },
              }}
            />

            <InputWrapper
              Component={TimeDurationField}
              name='time_result'
              settings={{
                label: t.staticAsString('results.newForm.time_result'),
                view: {
                  variant: 'outlined',
                  margin: 'normal',
                  fullWidth: true,
                  reservePlaceForHelperText: true,
                },
                additional: {},
              }}
            />

            <Show if={!isClassicDistance}>
              <InputWrapper
                Component={TextField}
                name='activity_link'
                settings={{
                  label: t.staticAsString('results.newForm.activity_link'),
                  additional: {
                    multiline: true,
                    onBlur: activityLinkCorrector.fixInForm,
                  },
                  view: {
                    variant: 'outlined',
                    margin: 'normal',
                    fullWidth: true,
                    reservePlaceForHelperText: true,
                  },
                }}
              />
            </Show>
          </Form>
        );
      }}
    </Observer>
  );
}

export { NewResultForm };

import { Box, Button, ButtonGroup, Tab, Tabs, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import { ReportActions } from 'modules/Reconciliation/Report/actions/lifecycle.action';
import qs from 'query-string';
import React, { ReactElement, useEffect, useState } from 'react';
import shortid from 'shortid';

import { ROUTES } from 'src/constants/routes';

import { Show } from 'components/Condition';
import { Icon } from 'components/Icon';
import { NavToolbar } from 'components/NavToolbar';
import { Spinner } from 'components/Spinner';

import { history } from 'utils/history';
import { t } from 'utils/t';

import { progressStore } from 'stores';

import { ReportConstants } from '../constants/report.constants';

import { ReportStore } from '../stores/report.store';

import ReportFooter from './Footer';
import PaymentOverview from './PaymentOverview';
import RaceIdInvoice from './RaceIdInvoice';
import TransactionsList from './TransactionsList/TransactionsList';
import VatReport from './VatReport';

interface Props {}

const StyledTabs = withStyles({
  root: {
    backgroundColor: 'white',
    marginBottom: 10,
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
  },
  indicator: {
    backgroundColor: '#f9d026',
  },
})(Tabs);

const StyledButton = withStyles({
  root: {
    border: '#f0f1f2 1px solid',
  },
})(Button);

const params: { [k: string]: string } = {
  0: '?tab=report',
  1: '?tab=transactions_list',
  2: '?tab=vat_info',
};
const paramsMirror: { [k: string]: number } = {
  report: 0,
  transactions_list: 1,
  vat_info: 2,
};

function ReportWrapper({}: Props): ReactElement {
  const [currentTab, setCurrentTab] = useState(0);
  const [reportButton, setReportButton] = useState(0);

  useEffect(() => {
    const search = qs.parse(history.location.search);
    if ('tab' in search) {
      setCurrentTab(paramsMirror[(search.tab as string) ?? 'report']);
    }
    const init = async () => {
      await ReportActions.init();
    };
    init();
    return () => {
      ReportActions.unmount();
    };
  }, []);

  const makeNavItems = (): Array<NavToolbarItemType> => {
    return [
      { key: shortid(), path: ROUTES.reportsRoute, label: t.staticAsString('reconciliation.report.navItem.reports') },
      { key: shortid(), label: `${t.staticAsString('reconciliation.report.navItem.report')} #${ReportStore.report.value?.id}` },
    ];
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, value: any) => {
    setCurrentTab(value);
    history.replace(params[value]);
  };

  const handleChangeButton = (event: React.ChangeEvent<{}>, value: any) => {
    setReportButton(value);
  };

  const renderReconReport = () => {
    if (reportButton === 0) return <PaymentOverview />;
    if (reportButton === 1) return <RaceIdInvoice />;
    if (reportButton === 2) return <VatReport />;
  };

  const renderTab = () => {
    const report = ReportStore.report.value;

    switch (currentTab) {
      case 0: {
        return (
          <section>
            <ButtonGroup style={{ margin: '10px 0 50px 0' }} aria-label='outlined primary button group'>
              <StyledButton onClick={(e) => handleChangeButton(e, 0)} color={reportButton === 0 ? 'primary' : 'inherit'} size='large'>
                {t.staticAsString('reconciliation.report.paymentOverview')}
              </StyledButton>
              <Show if={!isEmpty(report?.invoice.invoice_statement)}>
                <StyledButton onClick={(e) => handleChangeButton(e, 1)} color={reportButton === 1 ? 'primary' : 'inherit'} size='large'>
                  {t.staticAsString('reconciliation.report.raceIdInvoice')}
                </StyledButton>
              </Show>
              <StyledButton onClick={(e) => handleChangeButton(e, 2)} color={reportButton === 2 ? 'primary' : 'inherit'} size='large'>
                {t.staticAsString('reconciliation.report.vatReport')}
              </StyledButton>
            </ButtonGroup>
            {renderReconReport()}
          </section>
        );
      }
      case 1: {
        return <TransactionsList />;
      }

      default: {
        return (
          <section>
            <Typography variant='h5'></Typography>
          </section>
        );
      }
    }
  };

  return (
    <div style={{ padding: 0, backgroundColor: 'white' }} className='content main-container-list races-list'>
      <Observer
        render={() => {
          if (ReportStore.report.value === null || progressStore.isLoading(ReportConstants.ReportPage)) {
            return <Spinner type={Spinner.types.default} />;
          }

          const report = ReportStore.report.value;

          return (
            <>
              <Box px={3} py={1}>
                <NavToolbar items={makeNavItems()} />
              </Box>
              <StyledTabs value={currentTab} onChange={handleChangeTab} aria-label='simple tabs example'>
                <Tab style={{ paddingLeft: 24, paddingRight: 24 }} label={t.staticAsString('reconciliation.report.reconciliationReport')} />
                <Tab style={{ paddingLeft: 24, paddingRight: 24 }} label={t.staticAsString('reconciliation.report.transactionList')} />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                  <a href={report.file.url} download style={{ margin: '0 24px 0 0', cursor: 'pointer' }}>
                    <Icon value='xls' />
                  </a>
                  {report.pdf?.url && (
                    <a href={report.pdf.url} target='_blank' style={{ margin: '0 24px 0 0', cursor: 'pointer' }}>
                      <Icon value='pdf' />
                    </a>
                  )}
                </Box>
              </StyledTabs>
              <Box px={3} py={2}>
                {renderTab()}
                <ReportFooter />
              </Box>
            </>
          );
        }}
      />
    </div>
  );
}

export { ReportWrapper };

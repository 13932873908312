import { action, computed, makeObservable, override } from 'mobx';

import { Result as ResultModel } from 'models';

import { Loadable } from 'stores';

import { sortStore, searchStore } from './resultsStub';

// Results
class TeamResults extends Loadable<ResultType, FiltersType> {
  declare filters: FiltersType;

  static defaultFilters = {
    with: 'team;splits',
    limit: 100,
    searchJoin: 'AND',
  };

  sort = sortStore;
  search = searchStore;
  extensions = [this.sort, this.search];

  distanceId: number = 0;

  constructor() {
    super();

    makeObservable(this);
    this.filters = TeamResults.defaultFilters;
  }

  @action
  updateCurrentDistance(distanceId: number) {
    this.distanceId = distanceId;
  }

  @computed
  get modelValues(): Array<ResultModel> {
    return (this.values || []).map<ResultModel>((value, index) => new ResultModel(value, index.toString()));
  }

  @override
  clearFilters() {
    super.clearFilters();
    this.filters = TeamResults.defaultFilters;
  }

  @override // clear everthing without filters
  clearAll() {
    this.clearData();
    this.removeSelectedValue();
  }
}

export { TeamResults, TeamResults as TeamResultsStore };

import { Box } from '@mui/material';
import { Observer } from 'mobx-react';
import * as React from 'react';
import { useState } from 'react';
import { matchPath } from 'react-router-dom';
import shortid from 'shortid';

import { ROUTES } from 'src/constants';
import { t } from 'src/utils';

import { Spinner } from 'components/Spinner';

import { history } from 'utils';

import { AccountInfo, Breadcrumbs, Payments, Reports, Commissions } from '../../components';

import { SystemUsersViewActions } from '../../actions/view.actions/systemUsers.view.actions';

import { SystemUserViewStore } from '../../stores/systemUserView.store';

type Props = {};

export const Detail: React.FC<Props> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);

  React.useEffect(() => {
    const match = matchPath(history.location.pathname, ROUTES.systemUserRoute);
    const id = match?.params?.id;
    if (!id) {
      return;
    }
    const load = async () => {
      try {
        await SystemUsersViewActions.initSystemUserProfile();
        await SystemUsersViewActions.loadSystemUserProfile(id);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    load();
    return () => {
      SystemUsersViewActions.unmount();
    };
  }, []);

  const breadcrumbs = () => {
    const { value } = SystemUserViewStore.profile;
    const label = value?.full_name as string;
    return [{ key: shortid(), label }];
  };

  const handleSelectTab = (value: number) => {
    switch (value) {
      case 0:
        break;

      default:
        break;
    }
    setSelectedTab(value);
  };

  const handleRenderSelectedTab = () => {
    switch (selectedTab) {
      case 0:
        return <Commissions />;
      case 1:
        return <Reports />;
      case 2:
        return <Payments />;
    }
  };

  return (
    <Observer>
      {() => {
        const { value } = SystemUserViewStore.profile;

        if (isLoading) {
          return (
            <div className='content main-distance-form distance-form-scroll'>
              <Spinner type={'cubesSpinner'} />
            </div>
          );
        }

        if (!value) {
          return (
            <Box display='flex' alignItems='center' justifyContent='center' mt={14}>
              {t.staticAsString('organizers.detail.userNotFound')}
            </Box>
          );
        }
        return (
          <div className='content main-container-detail'>
            <Breadcrumbs items={breadcrumbs()} />
            <AccountInfo value={value} />
            <div className='payments-wrapper'>
              <Box display='flex'>
                <Box mr={8}>
                  <h3 onClick={() => handleSelectTab(0)} className={`payments-title ${selectedTab === 0 ? 'selected' : ''}`}>
                    {t.staticAsString('organizers.detail.tabs.racesAndCommissions')}
                  </h3>
                </Box>
                <Box mr={8}>
                  <h3 onClick={() => handleSelectTab(1)} className={`payments-title ${selectedTab === 1 ? 'selected' : ''}`}>
                    {t.staticAsString('organizers.detail.tabs.reconReports')}
                  </h3>
                </Box>
                <Box mr={8}>
                  <h3 onClick={() => handleSelectTab(2)} className={`payments-title ${selectedTab === 2 ? 'selected' : ''}`}>
                    {t.staticAsString('reconciliation.transactions.transactions')}
                  </h3>
                </Box>
              </Box>
            </div>
            {handleRenderSelectedTab()}
          </div>
        );
      }}
    </Observer>
  );
};

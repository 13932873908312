import { forEach } from 'lodash';
import moment from 'moment';

import { dateCols } from '../types';

function findDate(
  type: 'earliest' | 'latest',
  dates: { [k in dateCols]: string | moment.Moment | nil },
): [dateCols | nil, moment.Moment | nil] {
  let matchedDate: moment.Moment | null = null;
  let matchedDateCol: dateCols | nil = null;

  forEach(dates, (value, col: dateCols) => {
    const date = moment(value || NaN);

    if (!date.isValid()) {
      return;
    }

    if (matchedDate && type === 'earliest' && date.isSameOrAfter(matchedDate)) {
      return;
    }

    if (matchedDate && type === 'latest' && date.isSameOrBefore(matchedDate)) {
      return;
    }

    matchedDateCol = col;
    matchedDate = date;
  });

  return [matchedDateCol || null, matchedDate];
}

function todayStart() {
  return moment().startOf('day');
}

function todayEnd() {
  return moment().endOf('day');
}

export { findDate, todayStart, todayEnd };

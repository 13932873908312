import { isEmpty } from 'lodash';
import { Observer } from 'mobx-react';
import React from 'react';

import { Show } from 'components/Condition';
import { Delayed } from 'components/Delayed';

import { progressStore } from 'stores';

import { ListPlaceholder, CampaignLinksList } from '../components/List';

import { LOAD_CAMPAIGN_LINKS } from '../constants';

import { mount, unmount } from '../actions';

import { hasOrganizerLinksStore } from '../stores';

export const List = () => {
  React.useEffect(() => {
    mount();
    return unmount;
  }, []);

  return (
    <Observer>
      {() => {
        const showList = hasOrganizerLinksStore.value;
        return (
          <>
            <Show if={showList}>
              <CampaignLinksList />
            </Show>
            <Show if={!showList && !progressStore.isLoading(LOAD_CAMPAIGN_LINKS)}>
              <Delayed waitBeforeShow={100}>
                <ListPlaceholder />
              </Delayed>
            </Show>
          </>
        );
      }}
    </Observer>
  );
};

import { reaction } from 'mobx';
import { isCalendarRaceType } from 'modules/Races/utils/isCalendarRaceType';

import { CALENDAR_RACE_TAB_OPTIONS } from '../constants';

import { distanceFormDisposers } from '../stores';

export const renderStepsIfCalendarRace = (onChange: AnyFunction) => {
  distanceFormDisposers.register(
    reaction(
      () => {
        isCalendarRaceType.get() && onChange({ tab_options: CALENDAR_RACE_TAB_OPTIONS }, 'editorSettings');
        return isCalendarRaceType.get();
      },
      (isCalendar) => {
        isCalendar && onChange({ tab_options: CALENDAR_RACE_TAB_OPTIONS }, 'editorSettings');
      },
    ),
  );
};

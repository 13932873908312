import { has, isEmpty } from 'lodash';
import { toJS } from 'mobx';
import missingPaymentDetailsStore from 'modules/MissingPaymentDetails/store';

import { form, toastStore, sessionStore } from 'src/stores';
import { history, t } from 'src/utils';

import { deleteCurrency, formToValidRole } from '../../utils';

import { SystemUserFormPageService } from '../../services/systemUserFormPage.service';

import { SystemUserFormPageMappers } from '../../mappers/systemUserFormPage.mappers';
import { SystemUserForm } from '../../types';

const updateSuccessfulToast = (name: string, id: number): void => {
  toastStore.show(t.staticAsString('systemUsers.updateSuccess', { name, id }));
};

export const updateSystemUserAction = async (id: number, systemUser: SystemUserForm): Promise<void> => {
  const data = SystemUserFormPageMappers.toBackend(systemUser);
  const listSelectedCurrencies = systemUser.selectedCurrencies;
  let listActions: Array<Promise<void>> = [];

  const [isOk, response] = await SystemUserFormPageService.update(data);

  if (!isOk) return;

  const userId = response?.data.data.id;
  const fullName = response?.data.data.full_name;

  const isLastPaymentForDelete = isEmpty(listSelectedCurrencies) && systemUser.payment_details.length === 1;

  if (isLastPaymentForDelete) {
    await deleteCurrency(id, systemUser.selectedCurrencies, systemUser.payment_details);
  }

  if (sessionStore.user?.user_id === userId) {
    sessionStore.setFullName(fullName);
  }

  if (
    !['organizer'].includes(formToValidRole(systemUser.role)) ||
    (formToValidRole(systemUser.role) === 'organizer' && isEmpty(listSelectedCurrencies))
  ) {
    updateSuccessfulToast(fullName, userId);
    missingPaymentDetailsStore.verifyPaymentDetails();
    history.goBack();
    return;
  }

  if (!isEmpty(listSelectedCurrencies)) {
    for await (const currency of listSelectedCurrencies) {
      const currencyObject = toJS(form.fetch<AnyObject>(currency?.label as string));

      const mappedPaymentDetails = SystemUserFormPageMappers.toBackendPaymentDetails(currencyObject, currency?.label as string);

      if (has(currencyObject, 'id') && currencyObject.id)
        listActions.push(SystemUserFormPageService.updatePaymentDetails(id, mappedPaymentDetails));
      else listActions.push(SystemUserFormPageService.createPaymentDetails(id, mappedPaymentDetails));
    }

    await Promise.all(listActions);
    await deleteCurrency(id, systemUser.selectedCurrencies, systemUser.payment_details);
    missingPaymentDetailsStore.verifyPaymentDetails();
    updateSuccessfulToast(fullName, userId);
    history.goBack();
  }
};

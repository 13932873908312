import {
  loadSystemUserCommissionsAction,
  createCommissionAction,
  updateCommissionAction,
  resetCommissionAction,
} from './commissions.action';
import { loadSystemUserAction, initSystemUserProfileAction, unmountSystemUserProfileAction } from './init.action';
import loadSystemUserPaymentsAction from './payments.action';
import loadSystemUserReportsAction from './reports.action';

export namespace SystemUsersViewActions {
  export async function initSystemUserProfile() {
    return await initSystemUserProfileAction();
  }
  export async function loadSystemUserProfile(id: number) {
    return await loadSystemUserAction(id);
  }

  export function loadSystemUserPayments(filters) {
    return loadSystemUserPaymentsAction(filters);
  }

  export function loadSystemUserCommissions(filters) {
    return loadSystemUserCommissionsAction(filters);
  }

  export function createCommission(value: AnyObject) {
    return createCommissionAction(value);
  }

  export function updateCommission(id: number, value: AnyObject) {
    return updateCommissionAction(id, value);
  }

  export function resetCommission(id: number) {
    return resetCommissionAction(id);
  }

  export function loadSystemUserReports(filters) {
    return loadSystemUserReportsAction(filters);
  }

  export function unmount() {
    return unmountSystemUserProfileAction();
  }
}

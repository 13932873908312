import moment from 'moment';
import * as React from 'react';

import { Show } from 'components/Condition';
import { Icon } from 'components/Icon';

type Props = {
  date: string;
  location: string;
};

function DistanceDetailsComponent({ date, location }: Props) {
  return (
    <div className='distance-details'>
      <div className='distance-info'>
        <div className='date'>{moment(date).format('DD MMM YYYY')}</div>
        <Show if={!!location}>
          <div className='location'>
            <Icon value='location' />
            {location || ''}
          </div>
        </Show>
      </div>
    </div>
  );
}

const DistanceDetails = React.memo<Props>(DistanceDetailsComponent);

export { DistanceDetails };

import MuiCheckbox from '@mui/material/Checkbox';
import * as React from 'react';

type Props = FieldBaseProps & AdditionalMaterialProps;

const Checkbox = (props: Props) => {
  const { id, name, value, disabled, defaultChecked, onChange } = props;

  return (
    <MuiCheckbox
      id={id}
      name={name}
      value={value}
      disabled={disabled}
      defaultChecked={defaultChecked}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        onChange(
          {
            name,
            value: e.currentTarget.checked,
          },
          e,
        )
      }
    />
  );
};

export { Checkbox, Checkbox as CheckboxField };

import { SystemUserForm } from '../types';

export namespace SystemUsersMapper {
  export function fromBackend(user: SystemUserForm) {
    return user;
  }

  export function toBackend(user: SystemUserForm) {
    return user;
  }
}

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { COUPON_URL, COUPON_ORDERS_URL } from 'src/constants/api';

import { action, request } from 'utils/core';

import { LOAD_COUPON_DETAILS, GET_COUPON_ORDERS } from '../constants';

import { couponStore, couponOrdersStore } from '../stores';

import { TransactionFilters } from '../types';

class CouponDetailsService {
  @request({ action: LOAD_COUPON_DETAILS })
  loadCouponRequest(id: number): any {
    const url = generatePath(COUPON_URL, { id });
    return axios.get(url, { params: { with: 'race.currency' } });
  }

  @action({ action: LOAD_COUPON_DETAILS })
  async loadCoupon(id: number): Promise<any> {
    const [isOk, response] = await this.loadCouponRequest(id);
    if (isOk) {
      couponStore.set(response.data.data);
    }
  }

  @request({ action: GET_COUPON_ORDERS })
  loadCouponOrdersRequest(id: number, filters?: TransactionFilters): any {
    const url = generatePath(COUPON_ORDERS_URL, { id });
    return axios.get(url, {
      params: {
        ...filters,
        per_page: 50,
      },
    });
  }

  @action({ action: GET_COUPON_ORDERS })
  async loadCouponOrders(id: number, filters?: TransactionFilters): Promise<any> {
    const [isOk, response] = await this.loadCouponOrdersRequest(id, filters);
    if (isOk) {
      couponOrdersStore.set(response.data);
    }
  }
}

export const couponDetailsService = new CouponDetailsService();

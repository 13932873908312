import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import { Observer } from 'mobx-react';
import React, { ReactElement } from 'react';

import { Loading } from 'components/Loading';
import { Button } from 'components/core';

import { t } from 'utils/t';

import { COUPON_FORM_STATES } from '../constants';

import { closeCouponForm, handlePrepareSubmit, onConfirmSubmit } from '../actions';

import { couponFormModalStore } from '../stores';

import { CouponsForm } from './CouponsForm';

function CouponsFormDialog(): ReactElement {
  const isEdit = () => couponFormModalStore.value?.isEdit;

  const handleClose = () => {
    closeCouponForm();
  };

  const onCheckCoupons = () => {
    const isShowConfirm = couponFormModalStore.value?.isShowConfirm;
    isShowConfirm ? onConfirmSubmit() : handlePrepareSubmit();
  };

  return (
    <Observer
      render={() => {
        if (!Boolean(couponFormModalStore.value?.open)) return null;
        return (
          <Dialog maxWidth='md' fullWidth onClose={closeCouponForm} open={Boolean(couponFormModalStore.value?.open)}>
            <Loading action={COUPON_FORM_STATES.INIT} />
            <Loading action={COUPON_FORM_STATES.SAVING} />
            <DialogTitle>{isEdit() ? t.staticAsString('coupons.edit') : t.staticAsString('coupons.addNewCoupon')}</DialogTitle>
            <DialogContent>
              <CouponsForm />
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button type='cancel' onClick={handleClose}>
                {t.staticAsString('races.new.cancel')}
              </Button>
              <Box pr={4} pl={2}>
                <Button type='apply' onClick={onCheckCoupons}>
                  {t.staticAsString('coupons.new.submit')}
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        );
      }}
    />
  );
}

export { CouponsFormDialog };

import { organizerService } from 'modules/Races/services/organizer';
import { organizerStore } from 'modules/Races/stores';

import { sessionStore } from 'stores';

const load = async () => {
  if (sessionStore.isOrganizer && sessionStore.user) {
    const organizerId = sessionStore.user.user_id;
    const organizer = await organizerService.loadOrganizer(organizerId);
    organizerStore.set(organizer);

    if (organizer?.verified) {
      sessionStore.setUserVerified();
    }
  }
};

export { load };

import { Box, Button, Typography } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { find } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { REFERRAL_PROGRAM_URL } from 'src/constants';

import BannerBase from 'components/Banners/BannerBase';
import { Show } from 'components/Condition';
import { Icon } from 'components/Icon';

import { t } from 'utils';

import { sessionStore } from 'stores';

const useStyles = makeStyles((theme) => ({
  body: {
    alignItems: 'center',
    padding: '0 16px',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '19px',
  },
  iconWrap: {
    minWidth: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: '#ffffff',
  },
  icon: {
    '& >div': {
      display: 'flex',
    },
  },
  bold: {
    fontWeight: 700,
  },
}));

const StyledButton = withStyles((theme) => ({
  root: {
    height: 36,
    padding: '10px 16px',
    border: `1px solid ${theme.palette.grey1}`,
    borderRadius: 2,
    whiteSpace: 'nowrap',
    color: `${theme.palette.grey1}`,
    '&:focus': {
      color: theme.palette.grey1,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey1,
      color: '#ffffff',
    },
  },
  label: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '16px',
    letterSpacing: '1.25px',
  },
}))(Button);

type Props = {};

const AMOUNT_OF_EARNINGS = '€100';
const LOCAL_STORAGE_KEY = 'referral-banner';

const ReferralProgramBanner = (props: Props) => {
  const classes = useStyles();
  const [isShowBanner, setIsShowBanner] = useState(true);

  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || 'false');

    if (storage) {
      const userReferralStorage = find(storage, { userId: sessionStore.user?.user_id });
      if (userReferralStorage) {
        const test = moment().isSameOrAfter(moment(userReferralStorage.comeUpAt));
        setIsShowBanner(test);
      }
    }
  }, []);

  const handleOnClose = () => {
    const storage = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || 'false');
    const comeUpAt = moment().add(1, 'months').format('YYYY-MM-DD');
    const userId = sessionStore.user?.user_id;
    let newStorageState;

    if (storage) {
      newStorageState = [...storage.filter((item) => item.userId !== userId), { userId, comeUpAt }];
    } else {
      newStorageState = [{ userId, comeUpAt }];
    }
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newStorageState));
  };

  return (
    <Show if={isShowBanner}>
      <BannerBase
        onClose={handleOnClose}
        content={
          <>
            <Box className={classes.iconWrap}>
              <Icon value='gift-box' className={classes.icon} />
            </Box>
            <Typography className={classes.body} variant='body2'>
              {t.staticAsString('banner.referralProgram.body.text1')}
              <span className={classes.bold}>&nbsp;{t('banner.referralProgram.body.price')}&nbsp;</span>
              {t.staticAsString('banner.referralProgram.body.text2')}
            </Typography>
            <StyledButton variant='outlined' href={REFERRAL_PROGRAM_URL} {...{ target: '_blank' }}>
              {t.staticAsString('banner.referralProgram.learnMore')}
            </StyledButton>
          </>
        }
      />
    </Show>
  );
};

export default ReferralProgramBanner;

import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { distanceStore } from '../../stores';

const isClassicDistance: IComputedValue<boolean> = computed((): boolean => {
  const distance = distanceStore.modelSelected;

  if (!distance) {
    return true;
  }

  return distance.isMode('classic');
});

const isNonClassicDistance: IComputedValue<boolean> = computed((): boolean => {
  return !isClassicDistance.get();
});

const isCumulativeDistance: IComputedValue<boolean> = computed((): boolean => {
  const distance = distanceStore.modelSelected;

  if (!distance) {
    return false;
  }

  return distance.isMode('cumulative');
});

const isVirtualDistance: IComputedValue<boolean> = computed((): boolean => {
  const distance = distanceStore.modelSelected;

  if (!distance) {
    return false;
  }

  return distance.isMode('virtual');
});

export { isClassicDistance, isNonClassicDistance, isCumulativeDistance, isVirtualDistance };

import { cloneDeep } from 'lodash';

import { BE_DATE_FORMAT } from 'src/constants';

import { SYNC_VALUES } from '../constants';

import { IntegrationType } from '../types';

export const mapToCreate = (data: AnyObject): IntegrationType => {
  const copy = cloneDeep(data);

  const is_sync_start_list = !!copy.is_sync.find((item) => item.key === SYNC_VALUES()[0].key);
  const is_sync_results = !!copy.is_sync.find((item) => item.key === SYNC_VALUES()[1].key);

  const distances = copy.distances.map((item) => item.key);
  const started_at = copy.date_range?.startDate ? copy.date_range.startDate.format(BE_DATE_FORMAT) : null;
  const finished_at = copy.date_range?.endDate ? copy.date_range.endDate.format(BE_DATE_FORMAT) : null;

  return {
    webhook_url: copy.webhook_url,
    provider: 'Http',
    method: 'POST',
    started_at,
    finished_at,
    send_start_list_results_objects: Boolean(data.send_start_list_results_objects),
    is_active: data.is_active,
    is_sync_start_list,
    is_sync_results,
    distances,
  };
};

import { EXPORT_DATA, EXPORT_DATA_SIZES } from 'src/constants';

import { handleExport } from 'utils';
import { appEnvControl } from 'utils/appEnvironment';

import { exportService } from '../services';

/**
 * @description
 * Perform pdf export of payment detail - (account info + list of items + transactions)
 */
export const generateOnExportPaymentDetail = (paymentId: number) => async () => {
  const [, response] = await exportService.exportPaymentDetail(paymentId);

  const { id } = response.data.data;

  // 1500 orders loaded per ~1 second
  const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
  let estimatedLoadingTime = 1 / EXPORT_DATA.metaDataLoadingRatio;
  if (1 > maxTotal) {
    estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
  }

  handleExport({
    id,
    estimatedLoadingTime,
    fileName: 'Payments.pdf',
    onRetry: () => generateOnExportPaymentDetail(paymentId),
  });
};

import TableBody from '@mui/material/TableBody';
import { isEmpty } from 'lodash';
import { observer, inject } from 'mobx-react';
import { DELETE_CUSTOMER, LOAD_CUSTOMERS } from 'modules/Customers/constants';
import * as React from 'react';

import { loadable, withProgressSpinner } from 'hocs';

import { Show } from 'components/Condition';
import { ExportToolbar } from 'components/ExportToolbar';
import { Table } from 'components/Table';
import { TableEmptyListNotification } from 'components/TableEmptyListNotification';

import { t } from 'utils';

import { confirmationModalStore, Customer as CustomerStore, Locale as LocalStore, Session as SessionStore } from 'stores';

import { CONFIRM_POPUP_TYPES } from '../../../../constants';

import { customersUnmount, mount } from '../../actions';
import { exportData } from '../../actions';

import { loadService } from '../../services/load';

import { FilterLabelToolbar } from './FilterLabelToolbar';
import { Item } from './Item';
import { Pagination } from './Pagination';
import { THead } from './THead';
import { Toolbar } from './Toolbar';

type Props = {
  customersStore: CustomerStore;
  localeStore: LocalStore;
  sessionStore: SessionStore;
};

type State = {
  customers: Array<CustomerType>;
};

const EXPORT_TYPES = ['csv', 'xlsx'];

@withProgressSpinner([LOAD_CUSTOMERS, DELETE_CUSTOMER])
@loadable({ service: loadService })
@inject('customersStore', 'localeStore', 'sessionStore')
@observer
class List extends React.Component<Props, State> {
  static defaultProps = {
    racesStore: null as any,
    localeStore: null as any,
    sessionStore: null as any,
    intl: null as any,
  };

  componentDidMount() {
    mount();
  }

  componentWillUnmount() {
    customersUnmount();
  }

  state = {
    customers: [],
  };

  items = () => {
    return (this.props.customersStore.modelValues || []).map((customer) => (
      <Item key={customer.value.id} item={customer} deleteCustomer={this.deleteCustomer} />
    ));
  };

  onExport = async (e: React.SyntheticEvent, type: string): Promise<any> => {
    exportData(type);
  };

  deleteCustomer = (customerId: number) => {
    confirmationModalStore.openModal({
      title: t.staticAsString('races.confirmPopup.mainTitle'),
      body: t.staticAsString('customers.confirmPopup.delete.body'),
      type: CONFIRM_POPUP_TYPES.confirm,
      onConfirm: () => {
        loadService.deleteUser(customerId);
      },
    });
  };

  render() {
    const { customersStore, sessionStore } = this.props;

    const customersList = customersStore.modelValues;
    const organizerVerified = sessionStore.isOrganizerVerified;

    return (
      <div className='content main-container-list customers-list'>
        <div className='toolbar-container'>
          <Toolbar />
          <ExportToolbar totalEntries={customersStore.paginationMeta.total || 0} types={EXPORT_TYPES} onExport={this.onExport} />
          <FilterLabelToolbar />
        </div>
        <div className='component-list'>
          <Show if={!isEmpty(customersList)}>
            <Table className='table-list'>
              <THead />
              <TableBody>{this.items()}</TableBody>
            </Table>
          </Show>
          <Show if={isEmpty(customersList)}>
            <TableEmptyListNotification text={t.staticAsString('customers.noRegisteredCustomers' as TranslationLockedKeys)} />
          </Show>
          <Pagination store={customersStore} swichedOnTop={organizerVerified} />
        </div>
      </div>
    );
  }
}

export { List };

import { t } from 'utils/t';

const presence = (messageKey: TranslationLockedKeys) => ({
  presence: {
    allowEmpty: false,
    message: t.staticAsString(messageKey),
  },
});

export const couponFormValidation = () => ({
  name: {
    ...presence('coupons.new.errors.name'),
    length: { minimum: 3, maximum: 255 },
  },
  code: {
    ...presence('coupons.new.errors.code'),
  },
  race_id: {
    ...presence('coupons.new.errors.race_id'),
  },
  distances: {
    ...presence('coupons.new.errors.distances' as any),
  },
  discount_amount: {
    ...presence('coupons.new.errors.discount_amount'),
  },
  qty: {
    ...presence('coupons.new.errors.qty'),
  },
  coupon_type: {
    ...presence('coupons.new.errors.coupon_type'),
  },
  discount_percentage: {
    ...presence('coupons.new.errors.coupon_type'),
  },
});

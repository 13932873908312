import moment from 'moment';
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import { DatePicker } from 'components/Fields/DatePicker';

type Props = {
  item: RacerType;
  field: string;
  isEdit: boolean;
  distancesField: DistanceType;
  changedFields: AnyObject;
  onChange: (value: any, changedValue: any) => void;
  currentField: AnyObject;
  saveChanges: () => void;
};

@onClickOutside
class CustomDatePicker extends React.Component<Props> {
  handleClickOutside = (e: React.MouseEvent<HTMLElement>) => {
    const { saveChanges } = this.props;
    const clickedOutside = !(e.target as any).closest('.react-datepicker-popper');

    if (clickedOutside) {
      saveChanges();
    }
  };

  onChange = (data: { name: string; value: string }): void => {
    const { onChange, item, distancesField } = this.props;
    const { value } = data;
    const currentField = item.fields?.find((el) => el.field_id === distancesField.id);
    let currentValue, changedValue;

    if (currentField) {
      currentValue = item.fields?.map((field) => {
        return field.id === currentField.id ? { ...currentField, value: value } : field;
      });
    } else {
      currentValue = [...(item.fields || []), { id: distancesField.id, field_id: distancesField.id, value: value }];
    }

    changedValue = this.getChangedValues(value);
    onChange(currentValue, changedValue);
  };

  getChangedValues = (value: string) => {
    const { distancesField, changedFields } = this.props;
    const changedValue = { id: distancesField.id, values: [value] };

    if ((changedFields.fields || []).find((field: AnyObject) => field.id === distancesField.id)) {
      return changedFields.fields.map((field: AnyObject) => (field.id === distancesField.id ? changedValue : field));
    }

    return [...(changedFields.fields || []), changedValue];
  };

  render() {
    const { distancesField, currentField } = this.props;
    const value = (currentField && moment(currentField.value).isValid() && currentField.value) || '';

    return <DatePicker value={value} onChange={this.onChange as AnyFunction} name={distancesField.name} />;
  }
}

export { CustomDatePicker };

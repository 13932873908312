import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const tabsStyles = {
  root: {
    border: '1px solid #e8e8e8',
  },
};

export const useGradientStyles = makeStyles((theme: Theme) => ({
  root: {
    opacity: 1,
    position: 'absolute',
    height: 50,
    zIndex: 1,
  },
  left: {
    left: 0,
    background: 'linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 60%, rgba(255, 255, 255, 0) 100%)',
  },
  right: {
    right: 0,
    background: 'linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 60%, rgba(255, 255, 255, 0) 100%)',
  },
}));

export const tabStyles = {
  root: {
    minWidth: 102,
    maxWidth: 102,
    borderRight: '1px solid rgb(240, 240, 240)',
    opacity: 0.5,
  },
  selected: {
    opacity: 1,
  },
};

export const useRootStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    // Strange behaviour when child container overflows parent with width set to 100%
    // width: 'calc(100% - 32px)',
  },
  translation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  relative: {
    position: 'relative',
  },
}));

export const useLocaleStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const useIconStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.green3,
  },
}));

export const useLabelStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 16,
  },
}));

import axios from 'axios';

import { CAMPAIGN_LINKS_URL } from 'src/constants';

import { action, request } from 'utils/core';

import { LOAD_CAMPAIGN_LINKS } from '../constants';

import { Filters } from '../../shared/types';

class CampaignLinksList {
  @request({ action: LOAD_CAMPAIGN_LINKS })
  loadLinksListRequest(filters?: Filters): any {
    return axios.get(CAMPAIGN_LINKS_URL, { params: filters });
  }

  @action({ action: LOAD_CAMPAIGN_LINKS })
  async loadLinksList(filters?: Filters): Promise<any> {
    const [isOk, response] = await this.loadLinksListRequest(filters);
    if (isOk) {
      return response.data;
    }
  }
}

export const campaignLinksService = new CampaignLinksList();

import { Box, Card, Grid, Typography } from '@mui/material';
import { CustomAgeTooltip } from 'modules/Dashboard/common/components/AgeTooltip';
import DashboardBreadcrumbs from 'modules/Dashboard/common/components/Breadcrumbs';
import SelectRangeButtons from 'modules/Dashboard/common/components/Interval';
import RaceSearch from 'modules/Dashboard/common/components/RaceSearch';
import { dashboardTextStyles } from 'modules/Dashboard/common/text.styles';
import { Age } from 'modules/Dashboard/types/age.type';
import { useState } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Icon } from 'components/Icon';

import { history } from 'utils/history';
import { t } from 'utils/t';

type Props = {
  data: Age | null;
};

export default function AgeChart({ data }: Props) {
  const [focusBar, setFocusBar] = useState<number | undefined>(undefined);
  const textStyles = dashboardTextStyles;
  const color = '252, 199, 93';
  return (
    <>
      <DashboardBreadcrumbs currentPage={t.staticAsString('dashboard.age')} />
      <Grid p={4} pt={3} container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ minHeight: '100%', p: 4 }}>
            <Box display='flex' justifyContent='space-between'>
              <Typography sx={textStyles.cardTitle}>{t('dashboard.age')}</Typography>
              <Icon onClick={() => history.push('/dashboard')} style={{ cursor: 'pointer' }} value='collapse' />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <SelectRangeButtons collection='age' />
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <Typography sx={{ fontSize: 14, mr: 1 }}>{t('dashboard.event')}:</Typography>
                <RaceSearch collection='age' />
              </Box>
            </Box>
            <ResponsiveContainer height={500} width='100%'>
              <BarChart
                onMouseMove={(state) => {
                  if (state.isTooltipActive) {
                    setFocusBar(state.activeTooltipIndex);
                  } else {
                    setFocusBar(undefined);
                  }
                }}
                style={{ left: '-10px', fontSize: 14, marginTop: 48 }}
                height={500}
                data={data?.data ?? []}
              >
                <CartesianGrid vertical={false} strokeDasharray='3 3' />
                <XAxis type='category' dataKey='label' />
                <YAxis unit='%' type='number' dataKey={'percentage'} />
                <Tooltip cursor={{ fill: 'rgba(0, 0, 0, 0)' }} content={CustomAgeTooltip} />
                <Bar background={{ fill: '#eee' }} barSize={16} dataKey='percentage'>
                  {data?.data.map((_, index) => {
                    const isFocused = focusBar === index;
                    const fill = isFocused ? `rgba(${color}, 1)` : `rgba(${color}, 0.8)`;
                    return <Cell key={`cell-${index}`} fill={fill} />;
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

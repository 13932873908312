import { helperOrganizersLoadService, loadCurrencyService } from 'services';

import { form, progressStore } from 'stores';

import { CommissionConstants } from '../constants/commissions.constants';

import { CommissionsService } from '../services/commissions.service';

import { CommissionsStore } from '../stores/commissions.store';

import { CommissionFilters } from '../types/filters.type';

export namespace CommissionsActions {
  export async function init() {
    await Promise.all([loadCurrencyService.loadResources()]);
    progressStore.log(CommissionConstants.Table, 'completed');
  }

  export async function load(filters: CommissionFilters) {
    const data = await CommissionsService.load(filters);
    CommissionsStore.commissions.set(data);
  }

  export async function unmount() {
    CommissionsStore.commissions.value = null;
  }
}

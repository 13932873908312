export interface Synchronization {
  id: number;
  is_enabled: boolean;
  race_id: number;
  race: { id: number; name: string };
  type: SynchronizationTypeEnum;
  created_at: Date;
  created_by: number;
  createdBy: { id: number; full_name: string };
}

export enum SynchronizationTypeEnum {
  EQTiming = 1,
  RaceResult = 2,
}

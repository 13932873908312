import { Observer } from 'mobx-react';

import { FilterAutocompleteWithSearch } from 'components/Filter/DefaultFilters';

import { countriesStore } from 'stores';

import { FilterProps } from './index';

type Props = FilterProps & {
  value: nil | string | number;
};
export const CountryOfResidence = (props: Props) => {
  const options = countriesStore.valuesForCountryDropdown().map((el) => {
    return {
      key: el.key,
      value: el.value.toString(),
      label: el.label,
    };
  });

  return (
    <Observer>
      {() => {
        return <FilterAutocompleteWithSearch {...(props as any)} options={options} />;
      }}
    </Observer>
  );
};

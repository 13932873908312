import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { Edit, New } from './Form';
import { SmartLinksDetails } from './SmartLinkDetails';
import { List } from './Table';

export const SmartLinks = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.smartLinks} component={List} />
      <Route path={ROUTES.createSmartLink} component={New} />
      <Route path={ROUTES.smartLinkEdit} component={Edit} />
      <Route path={ROUTES.smartLinkDetails} component={SmartLinksDetails} />
    </Switch>
  );
};

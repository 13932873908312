import { RACE_FORM_ID } from 'modules/Races/constants';
import * as React from 'react';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { SwitchWithLabel, InputWrapper } from 'components/Form';

import { t } from 'utils/t';

import { form } from 'stores';

import { formStyles } from '../../styles';

type Payload = {
  name: string;
  value: any;
};
type Props = {
  name: string;
  onStepToggle: ({ name, value }: Payload) => void;
  onRelationDelete?: Function;
  relationHasData?: boolean;
};

export const SingleSwitch = ({ name, onStepToggle, onRelationDelete = () => {}, relationHasData = false }: Props) => {
  const label = t.staticAsString(`races.steps.${name}` as TranslationLockedKeys) || name;
  const classes = formStyles();

  const onSwitchToggle = ({ value }) => {
    if (!value && relationHasData) {
      setDialogOpen(true);
    } else {
      toggleSwitch(value);
    }
  };
  const handleCancel = () => {
    setDialogOpen(false);
  };
  const handleConfirm = () => {
    onRelationDelete(name);
    toggleSwitch(false);
    setDialogOpen(false);
  };

  const toggleSwitch = (value: boolean) => {
    form.onChange(RACE_FORM_ID, name, value);
    onStepToggle({ name, value });
  };
  const [open, setDialogOpen] = React.useState(false);

  const view = { fullWidth: true, variant: 'outlined' };
  return (
    <div className={classes.wrapper}>
      <ConfirmationModal
        open={open}
        onConfirm={handleConfirm}
        onClose={handleCancel}
        title={t.staticAsString('distances.steps.tabOptionsForm.confirmPopup.mainTitle')}
        body={t.staticAsString('distances.steps.tabOptionsForm.confirmPopup.mainBody')}
      />
      <InputWrapper
        name={name}
        settings={{
          view,
          label,
          onChange: onSwitchToggle,
        }}
        Component={SwitchWithLabel}
      />
    </div>
  );
};

import { Observer } from 'mobx-react';
import { CommissionsActions } from 'modules/Reconciliation/Commissions/actions/lifecycle';
import { CommissionsTable } from 'modules/Reconciliation/Commissions/components/List/Table';
import React, { ReactElement, useEffect } from 'react';

import { t } from 'utils/t';

import { CommissionsStore } from '../../../stores/commissions.store';

import useFetchCommisions from '../../CommissionFetcher';
import { generateCommissionColumns } from '../Table/Columns';

interface Props {}

function CommissionsWrapper({}: Props): ReactElement {
  useEffect(() => {
    const init = async () => {
      await CommissionsActions.init();
    };
    init();
    return () => {
      CommissionsActions.unmount();
    };
  }, []);

  const fetchCommissions = useFetchCommisions();

  return (
    <div style={{ padding: 0, backgroundColor: 'white' }} className='content main-container-list races-list'>
      <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between' }}>
        <h1 style={{ paddingLeft: 24, paddingTop: 38, paddingBottom: 30 }} className='title'>
          {t.staticAsString('reconciliation.commissions.races&Commissions')}
        </h1>
      </div>
      <Observer
        render={() => {
          return (
            <CommissionsTable
              tableData={CommissionsStore.commissions.value?.data ?? []}
              tableMeta={CommissionsStore.commissions.value?.meta ?? {}}
              initialSizeOfPage={30}
              pageSizeOptions={[30, 50, 100, 300]}
              tableColumns={generateCommissionColumns()}
              fetchCommissions={fetchCommissions}
            />
          );
        }}
      />
    </div>
  );
}

export { CommissionsWrapper };

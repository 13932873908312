import * as React from 'react';

import { CurrencyFlag, Flag } from 'components/Flag';
import { formatLongLocale, formatShortLocale } from 'components/core';

import { useCurrencyStyle } from './styles';

type Props = {
  currency: availableCurrencies;
  short?: boolean;
  className?: string;
};

export const CurrencyItem = ({ currency, short, className }: Props) => {
  const classes = useCurrencyStyle();
  return (
    <React.Fragment>
      <CurrencyFlag className={classes.flagIcon} currency={currency} />
      <p className={`${classes.label} ${className}`}>{currency}</p>
    </React.Fragment>
  );
};

import { errorsStore } from 'stores';

import { SUPPORT_TASK_MANAGER_FORM } from '../constants';

import { bibDistancesService } from '../services';

export const loadDistances = async (raceId: number, text: string | null = null) => {
  errorsStore.clear(SUPPORT_TASK_MANAGER_FORM);
  await bibDistancesService.getDistances(raceId);
};

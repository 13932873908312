import { makeStyles } from '@mui/styles';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { TableHeaderItem } from 'components/Filter/TableHeaderItem';

import { t } from 'utils';

import { isActionColumn } from '../../derivations';

const styles = makeStyles({
  header: {
    position: 'sticky',
    left: 0,
  },
});

function ActionCol() {
  const classes = styles();

  return (
    <Observer>
      {() => {
        if (!isActionColumn.get()) {
          return null;
        }

        return (
          <TableHeaderItem
            key='action-col'
            name='action-col'
            width={50}
            label={t.staticAsString('results.fields.action')}
            className={classes.header}
          />
        );
      }}
    </Observer>
  );
}

export { ActionCol };

import { makeObservable } from 'mobx';

import { HelperRace } from 'stores';

class Race extends HelperRace {
  declare resourceParams: FiltersType;

  constructor() {
    super();

    makeObservable(this);
    this.resourceParams = {
      with: 'currency',
    };
  }
}

export { Race, Race as RaceStore };

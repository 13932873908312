import { getRaceId } from '../utils';

import editorSettingsService from '../services/editorSettings';

import { editorSettingsStore } from '../stores';

async function loadEditorSettings() {
  let [isOk, data] = await editorSettingsService.getEditorSettings(getRaceId());
  if (isOk) {
    editorSettingsStore.set(data);
  }
}

async function defaultEditorSettings() {
  const [isOk, data] = await editorSettingsService.getEditorSettings(getRaceId());
  if (!data) {
    editorSettingsService.createEditorSettings(getRaceId(), { tab_options: ['receiptSettings'] });
  }
}

export { loadEditorSettings, defaultEditorSettings };

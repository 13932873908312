import { Button } from '@mui/material';
import classnames from 'classnames';
import { cloneDeep, isEmpty } from 'lodash';
import get from 'lodash/get';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import { defaultEditorSettings } from 'modules/Races/actions/editorSettings';
import { CALENDAR_RACE_STEPS_ARRAY, LIVE_UPDATING, RACE_FORM_ID, RACE_TYPE } from 'modules/Races/constants';
import { receiptSettingsStore } from 'modules/Races/stores';
import { getAvailableSteps, initForm, raceFormData } from 'modules/Races/utils';
import { isCalendarRaceType } from 'modules/Races/utils/isCalendarRaceType';
import { getRaceValidationSchema } from 'modules/Races/validations';
import qs from 'query-string';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { CONFIRM_POPUP_TYPES, ROUTES } from 'src/constants';

import { Hide } from 'components/Condition';
import { Form } from 'components/Form';
import { Spinner } from 'components/Spinner';
import VerticalStepper from 'components/Stepper/VerticalStepper';

import { isFormValid, isOneFieldValid, newOneFieldValidation, t } from 'utils';

import { confirmationModalStore, errorsStore, form, helperRacesStore, progressStore, sessionStore } from 'stores';

import {
  handleDeleteContactEmail,
  handleDeleteImage,
  makeBreadcrumbs,
  mount,
  scrollToErrorField,
  unmount,
  updateField,
  updateOrganizerByReceipt,
} from '../../actions';

import { RACE_ERRORS_HASH, RACE_STEPS, RACE_STEPS_ARRAY, RACE_STEPS_COMPONENTS, raceStep } from '../Steps';
import { formStyles } from '../styles';
import { Toolbar } from './Toolbar';

interface Props {}

function UpdateRace(): ReactElement {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const classes = formStyles();
  const stepsArray = isCalendarRaceType.get() ? CALENDAR_RACE_STEPS_ARRAY : RACE_STEPS_ARRAY;
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState({ value: RACE_STEPS.raceDetails, index: 0 });
  const isVerified = sessionStore.isOrganizerVerified;

  useEffect(() => {
    const load = async () => {
      await defaultEditorSettings();
      await mount.update(initForm);
      setIsLoading(false);
      // force name field validation, if user changed default language and left/refreshed page
      validateOneField('name');
    };
    load();

    return () => {
      errorsStore.clear(RACE_FORM_ID);
      unmount();
    };
  }, []);

  useEffect(() => {
    const params = qs.parse(history.location?.search ?? '');
    const paramStep = get(params, 'step', RACE_STEPS.raceDetails) as raceStep;
    if (stepsArray.includes(paramStep)) {
      return setCurrentStep({
        value: paramStep,
        index: stepsArray.indexOf(paramStep),
      });
    }
    history.replace({
      pathname: history.location.pathname,
      search: '',
    });
    return () => {};
  }, []);

  useEffect(() => {
    history.replace({
      pathname: history.location.pathname,
      search:
        currentStep.value === RACE_STEPS.raceDetails
          ? qs.stringify({ ...(isCalendarRaceType.get() ? { type: RACE_TYPE.calendar } : {}) })
          : qs.stringify({ step: currentStep.value, ...(isCalendarRaceType.get() ? { type: RACE_TYPE.calendar } : {}) }),
    });
    return () => {};
  }, [currentStep.index]);

  const isLastStep = () => currentStep.index === getAvailableSteps().length - 1;
  const hasToSaveReceiptFieldsToOrganizer = () => form.fetch(RACE_FORM_ID, 'save_to_profile');

  const hasDistancesCreated = () => !isEmpty(helperRacesStore?.selected?.distances);

  const saveAndExit = () => {
    if (scrollToErrorField()) return;
    if (isCalendarRaceType.get() && !validateOneField('race_url')) {
      return;
    }
    history.push(generatePath(ROUTES.raceRoute, { id: helperRacesStore.selected!.id }));
  };

  const incrementStep = () => {
    if (scrollToErrorField()) return;

    if (isLastStep()) {
      if (isCalendarRaceType.get() && !validateOneField('race_url')) {
        return;
      }

      hasToSaveReceiptFieldsToOrganizer() && updateOrganizerByReceipt();

      if (!hasDistancesCreated()) {
        history.push(generatePath(ROUTES.newDistanceRoute, { raceId: helperRacesStore.selected!.id }));
        return;
      }

      history.push(generatePath(ROUTES.raceRoute, { id: helperRacesStore.selected!.id }));
      return;
    }
    const index = currentStep.index + 1;
    handleChangeStep(getAvailableSteps()[index]);
  };

  const decrementStep = () => {
    if (currentStep.index === 0) {
      history.push(generatePath(ROUTES.raceRoute, { id: helperRacesStore.selected!.id }));
      return;
    }
    const index = currentStep.index - 1;
    handleChangeStep(getAvailableSteps()[index]);
  };

  const handleChangeStep = (step: string) => {
    validateOneField('race_url');
    setCurrentStep({ value: step, index: getAvailableSteps().indexOf(step) });
    if (wrapperRef.current) wrapperRef.current.scrollTop = 0;
  };

  const validateOneField = (fieldName: keyof RaceFormData) => {
    let value = toJS(raceFormData(fieldName));
    const raceValidationSchema = getRaceValidationSchema(isCalendarRaceType.get());
    newOneFieldValidation(RACE_FORM_ID, { [fieldName]: value }, { [fieldName]: raceValidationSchema[fieldName] });
    return isOneFieldValid(RACE_FORM_ID, fieldName);
  };

  const handleLiveUpdate = async (fieldName: keyof RaceFormData) => {
    const isValid = validateOneField(fieldName);

    if (isValid) {
      progressStore.log(LIVE_UPDATING, 'progress');
      const isOk = await updateField(fieldName);
      progressStore.log(LIVE_UPDATING, 'completed');
      return isOk;
    }

    if (fieldName === 'languagesData') {
      validateOneField('name');
    }
  };

  const handleConfirmDeleteImage = () => {
    confirmationModalStore.openModal({
      title: t.staticAsString('races.confirmPopup.mainTitle'),
      body: t.staticAsString('races.confirmPopup.imageBody'),
      type: CONFIRM_POPUP_TYPES.confirm,
      onConfirm: handleDeleteImage,
    });
  };

  const computeErrors = () => {
    return stepsArray.filter((step) => RACE_ERRORS_HASH[step].some((k: string) => k in (errorsStore.getAll(RACE_FORM_ID) ?? {})));
  };

  const deleteContactEmail = (email) => {
    handleDeleteContactEmail(email);
    validateOneField('organizer_emails');
  };

  const renderStep = () => {
    const Step = RACE_STEPS_COMPONENTS[currentStep.value];
    return (
      <Step
        handleDeleteImage={handleConfirmDeleteImage}
        handleDeleteContactEmail={deleteContactEmail}
        liveUpdate={handleLiveUpdate}
        step={currentStep.value}
      />
    );
  };

  const getButtonText = () => {
    if (isLastStep()) {
      return hasDistancesCreated() ? t('shared.saveAndExit') : t('races.edit.continueToDistance');
    }
    return t('races.new.continue');
  };

  if (isLoading) {
    return (
      <div className='content main-distance-form'>
        <Spinner type={'cubesSpinner'} />
      </div>
    );
  }

  return (
    <div className={classnames('content', ' main-distance-form', { 'with-banner': !isVerified })}>
      <Toolbar breadcrumbs={makeBreadcrumbs()} />
      <div ref={wrapperRef} className='form-wrap'>
        <Observer
          render={() => {
            const stepsToShow = getAvailableSteps();
            // Simply check form validity, to update and re-render stepper errors state
            isFormValid(RACE_FORM_ID);
            return (
              <VerticalStepper
                stepModel='races'
                step={currentStep}
                steps={stepsToShow}
                onChange={handleChangeStep}
                errors={computeErrors() || []}
              />
            );
          }}
        />
        <Form disableAutofill id={RACE_FORM_ID} className='distance-form new-distance'>
          {renderStep()}
          <div className={classes.controls}>
            <div className={classes.leftButtonGroup}>
              <Hide if={isLastStep()}>
                <Button className='secondary' onClick={saveAndExit}>
                  {t('shared.saveAndExit')}
                </Button>
              </Hide>
            </div>
            <div className={classes.rightButtonGroup}>
              <Hide if={currentStep.index === 0}>
                <Button className='secondary' onClick={decrementStep}>
                  {t('races.new.back')}
                </Button>
              </Hide>
              <Observer>
                {() => {
                  return (
                    <Button
                      style={{ marginLeft: 21 }}
                      disableElevation
                      variant='contained'
                      color='primary'
                      className='submit'
                      onClick={incrementStep}
                    >
                      {getButtonText()}
                    </Button>
                  );
                }}
              </Observer>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export { UpdateRace };

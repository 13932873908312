import { createContext } from 'react';

import { mount, unmount } from '../actions';

import { copyRacersService } from '../services';

import { copyRacersStore } from '../stores';

export type CopyRacersContextType = {
  results: {
    actions: {
      mount: typeof mount;
      unmount: typeof unmount;
    };
    services: {
      copyRacersService: typeof copyRacersService;
    };
    stores: {
      racersStore: typeof copyRacersStore;
    };
  };
};

const copyRacersContextData: CopyRacersContextType = {
  results: {
    actions: {
      mount,
      unmount,
    },
    services: {
      copyRacersService: copyRacersService,
    },
    stores: {
      racersStore: copyRacersStore,
    },
  },
};
const CopyRacersContext = createContext<CopyRacersContextType>(copyRacersContextData);

export { CopyRacersContext, copyRacersContextData };

import { progressStore } from 'src/stores';

import { RR_MAPPING_INIT, RR_SETTINGS_INIT } from '../constants';

import { racesService, syncCustomFieldsService, raceCustomFieldsService } from '../services';

import { racesStore, racesParamsStore, racesHasMoreStore, stepStore } from '../stores';

import { syncStore, syncDistancesServices, raceDistancesService } from '../../Shared';

export const mount = () => {
  progressStore.log(RR_SETTINGS_INIT, 'progress');
  racesService.getRaces();
  progressStore.log(RR_SETTINGS_INIT, 'completed');
};

export const unmount = () => {
  racesStore.clear();
  racesParamsStore.clear();
  racesHasMoreStore.clear();
  stepStore.clear();
};

export const mountSync = async () => {
  progressStore.log(RR_MAPPING_INIT, 'progress');

  if (!syncStore.value?.id) {
    progressStore.log(RR_MAPPING_INIT, 'completed');
    return;
  }

  await raceDistancesService.getDistances(syncStore.value.race_id);

  await Promise.all([
    syncDistancesServices.getDistances(syncStore.value.id),
    syncCustomFieldsService.getCustomFields(syncStore.value.id),
    raceCustomFieldsService.getCustomFields(syncStore.value.race_id),
  ]);

  progressStore.log(RR_MAPPING_INIT, 'completed');
};

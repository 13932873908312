import React from 'react';
import styled from 'styled-components';

import { fontFamilyRoboto } from 'src/components/Form/Fields/FileUploader/style';
import { CONFIRM_POPUP_TYPES } from 'src/constants';
import { deleteGPX } from 'src/modules/Distances/components/shared/Steps/GPX/actions';

import { Icon } from 'components/Icon';

import { t } from 'utils';

import { confirmationModalStore } from 'stores';

const Wrapper = styled.div`
  width: calc(100% - 64px);
  margin: 32px auto 24px;
  background: #fafafa;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Text = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-left: 12px;
    display: inline-block;
    font-family: ${fontFamilyRoboto};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #262e35;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  display: inline-block;
`;

const StyledIcon2 = styled(Icon)`
  cursor: pointer;
  margin: -8px 0 0 0;
  display: inline-block;
`;

const FilePanel = () => {
  const openConfirmModal = () => {
    confirmationModalStore.openModal({
      title: t.staticAsString('races.confirmPopup.mainTitle'),
      body: t.staticAsString('distances.steps.helperText.gpx.confirmBody'),
      type: CONFIRM_POPUP_TYPES.confirm,
      onConfirm: deleteGPX,
    });
  };

  return (
    <Wrapper>
      <Text>
        <StyledIcon value='file-download' />
        <span>{t.staticAsString('distances.steps.helperText.gpx.uploadedFile')}</span>
      </Text>

      <StyledIcon2 value='CrossGray' onClick={openConfirmModal} />
    </Wrapper>
  );
};

export { FilePanel };

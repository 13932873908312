import * as React from 'react';

type Props = {
  onChange: (arg0: { name: string; value: nil | Blob }) => void;
  errors: Array<string>;
  name: string;
  View: any;
  label: string;
  value: Blob | string | null;
  fileName?: string;
  shouldRenderFileName?: boolean;
  disabled?: boolean;
};

export class ImageField extends React.Component<
  Props,
  {
    preview: string;
    value: Blob | null;
    imageSource: string;
  }
> {
  state = {
    preview: '',
    imageSource: '',
    value: null,
  };

  onChange = (e: React.ChangeEvent) => {
    e.preventDefault();
    const { name, onChange } = this.props;
    const target: any = e.target;
    const blob = target.files[0];
    onChange({ name, value: blob });
    target.value = null;
  };

  clearValue = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { name, onChange } = this.props;
    const blob = null;
    onChange({ name, value: blob });
  };

  generatePreview = (blob: Blob | null): string => {
    const source = blob as any;
    try {
      return URL.createObjectURL(source);
    } catch (e) {
      return '';
    }
  };

  componentDidUpdate(prevProps: Props) {
    const value = this.props.value as any;

    if (!value && this.state.value !== null) {
      this.setState({ value: null, imageSource: '', preview: '' });
    }

    if (typeof value === 'string' && this.state.imageSource !== value) {
      this.setState({ imageSource: value, preview: value, value: value as any });
    }

    if (typeof value === 'object' && this.state.value !== value) {
      this.setState({ imageSource: '', preview: this.generatePreview(value), value });
    }
  }

  getFileName = (): string | null => {
    const blob = this.state.value as any;
    if (!(typeof blob === 'object')) {
      return null;
    }
    return blob ? blob.name : null;
  };

  render() {
    const View = this.props.View as any;
    const { value, errors, fileName } = this.props;
    const { preview, imageSource } = this.state;

    return (
      <View
        {...this.props}
        onChange={this.onChange}
        clearValue={this.clearValue}
        value={value}
        preview={typeof value === 'string' ? value : typeof value === 'object' ? this.generatePreview(value) : ''}
        imageSource={imageSource}
        errors={errors}
        fileName={this.getFileName() || fileName}
      />
    );
  }
}

import { upsellService } from 'services';

import { sessionStore } from 'stores';

export async function init(): Promise<{ showUpsell: boolean; calendarRaces?: Array<RaceType> }> {
  if (!sessionStore.isOrganizer) {
    return { showUpsell: false };
  }
  const raceIdRaces = await upsellService.loadRaceIdRaces();
  if (raceIdRaces.length) {
    return { showUpsell: false };
  }

  const calendarRaces = await upsellService.loadCalendarRaces();
  return { showUpsell: true, calendarRaces };
}

import { isNull } from 'lodash';

import { duration } from 'utils';

import { PipeValue, Pipe } from '../mask';
import { maxHours, maxMinutes, maxSeconds } from './constants';

const maxValue = { HH: maxHours, MM: maxMinutes, SS: maxSeconds };
const formatOrder = ['HH', 'MM', 'SS'];

function createAutoCorrectTimeDurationPipe(dateFormat: string = 'HH:MM:SS'): Pipe {
  const dateFormatArray: Array<'HH' | 'MM' | 'SS'> = dateFormat
    .split(/[^HMS]+/)
    .sort((a, b) => formatOrder.indexOf(a) - formatOrder.indexOf(b)) as any;
  // NOTE, dateFormatArray = ['HH', 'MM','SS'];

  return function (conformedValue: string): PipeValue {
    const indexesOfPipedChars: Array<number> = [];
    const conformedValueArr = conformedValue.split('');

    // Autocorrect if value > max value e.g
    // data: '--', input: '9-' result - '09', inputed char: 9
    // data: '-7', input: '87' result - '08', inputed char: 8
    dateFormatArray.forEach((format: 'HH' | 'MM' | 'SS') => {
      const position = dateFormat.indexOf(format);
      const maxFirstDigit = parseInt(maxValue[format].toString().substr(0, 1), 10);

      if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
        conformedValueArr[position + 1] = conformedValueArr[position];
        conformedValueArr[position] = '0';
        indexesOfPipedChars.push(position);
      }
    });

    return {
      value: conformedValueArr.join(''),
      indexesOfPipedChars,
    };
  };
}

function parseTimeDurationToDiff(mask: string): nil | number {
  if (mask === '') {
    return null;
  }

  const diff = duration.diff(mask);

  if (isNull(diff)) {
    return undefined;
  }

  return diff;
}

export { createAutoCorrectTimeDurationPipe, parseTimeDurationToDiff };

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import * as React from 'react';

import { TriggerProps } from 'components/SettingsMenu';

import { styles } from './styles';

function Trigger({ onClick }: TriggerProps) {
  const classes = styles();
  return (
    <IconButton className={classes.trigger} aria-label='Settings' aria-controls='long-menu' aria-haspopup='true' size='large'>
      <MoreVertIcon onClick={onClick} />
    </IconButton>
  );
}

export { Trigger };

import CancelIcon from '@mui/icons-material/Cancel';
import * as React from 'react';

import { RACE_IMAGE_ASPECT_RATIO } from 'src/constants';

import ImageIcon from 'img/upload_image.png';

import { AspectRatioImage } from 'components/core';

import { t } from 'utils';

type ViewProps = {
  onChange: React.ChangeEventHandler;
  errors: Array<string>;
  preview: string;
  clearValue: () => void;
  value: Blob | string | null;
  imageSource: string;
  fileName?: string;
};

type ArgumentsType = { children: React.ReactElement; isPreviewAvailable: boolean };

const Preview = ({ children, isPreviewAvailable }: ArgumentsType): React.ReactElement | null => (isPreviewAvailable ? children : null);
const UploadForm = ({ children, isPreviewAvailable }: ArgumentsType): React.ReactElement | null => (!isPreviewAvailable ? children : null);

const View = ({ onChange, errors, preview, clearValue, imageSource }: ViewProps) => {
  const isPreviewAvailable = !!(preview || imageSource);

  return (
    <div className='icon-field'>
      <Preview isPreviewAvailable={isPreviewAvailable}>
        <label htmlFor='race-icon'>
          <AspectRatioImage src={preview || imageSource} ratio={RACE_IMAGE_ASPECT_RATIO.ratio}>
            <CancelIcon style={{ right: -2 }} className='cancel-icon' onClick={clearValue} />
          </AspectRatioImage>
        </label>
      </Preview>
      <UploadForm isPreviewAvailable={isPreviewAvailable}>
        <label htmlFor='race-icon' className='icon-label'>
          <img className='upload-icon' alt='upload icon' src={ImageIcon} />
          <p className='main-upload-image-title'>
            <span>{t('races.new.upload_title')}</span>
            <span className='sub-title'>{t('races.new.upload_sub_title')}</span>
          </p>
        </label>
      </UploadForm>
      <span className='errors'>{errors}</span>
      <input id='race-icon' className='icon-input' type='file' name='icon' onChange={onChange} accept='image/*' />
      <span className='file-name-label' />
    </div>
  );
};

export { View };

import { at, keys, compact, isNil, toString } from 'lodash';
import Xregexp from 'xregexp';

import { Checkpoint } from 'models';

import { ALPHABET } from '../constants';

import { importHeaders } from './importHeaders';

function formatErrorMessage(message: string, columnName: string): string {
  return message.toLowerCase().replace(/\d+\.(\w+)/g, columnName);
}

function generateComparators(stringForComparison: string[]): RegExp[] {
  return stringForComparison.map<RegExp>((value) => Xregexp(Xregexp.escape(value), 'i'));
}

function findX(col: string, comparators: RegExp[]): nil | string {
  const index = comparators.findIndex((comparator) => {
    return comparator.test(col);
  });

  if (index <= -1) {
    return;
  }

  const numOfChars = Math.max(0, Math.round(index / ALPHABET.length) - 1);

  const charIndexes = [...Array(numOfChars).keys()].map((index) => index.toString());
  const lastCharIndex = index % ALPHABET.length;

  const lastChar = ALPHABET[lastCharIndex];
  const chars = at(ALPHABET, charIndexes).join('');

  return `${chars}${lastChar}`;
}

function findY(rowIndex: string): nil | string {
  let index = Number(rowIndex);

  if (isNil(index)) {
    return;
  }
  index++; // Convert from 0 based count to 1 based count;
  index++; // Consider first row as headers

  return `${index}`;
}

function findCoorginates(col: string, rowNumber: string, comparators: RegExp[]): [nil | string, nil | string] {
  return [findX(col, comparators), findY(rowNumber)];
}

function formatError(x: nil | string, y: nil | string, messages: string[], columnName: string): string | nil {
  if (!x || !y) {
    return;
  }

  const formattedMessages = messages.map((message) => formatErrorMessage(message, columnName)).join('. ');

  return `${x.toUpperCase()}${y.toUpperCase()}, ${formattedMessages}`;
}

//-------

function generateTableBodyErrors(
  errors: {
    [K in string]: string[];
  },
  checkpoints: Checkpoint[],
): string[] {
  const isCheckpointsDisabled = !checkpoints.length;

  const headers = [
    importHeaders('bib'),

    /* If checkpoints enabled last checkpoint will play as result finish_time,
     * so there are no reason for 'Time result' column
     * Only if distance has no checkpoints, necessary to display 'Time result'
     */
    ...(isCheckpointsDisabled ? [importHeaders('time_result')] : []),
  ];

  const checkpointsIds = checkpoints.map((el) => toString(el.value.id));

  const comparators = generateComparators([...headers, ...checkpointsIds]);

  const errKeys = keys(errors);

  const formattedErrors = errKeys.map((errKey) => {
    const [rowNum, colId] = errKey.split('.');
    const [x, y] = findCoorginates(colId, rowNum, comparators);
    const checkpoint = checkpoints.find((checkpoint) => checkpoint.value.id === Number(colId));
    const colName = checkpoint ? checkpoint.value.name : colId;

    return formatError(x, y, errors[errKey], colName);
  });

  return compact(formattedErrors);
}

function generateHeaderErrors(errors: { [K in string]: string[] }) {
  return errors?.header || [];
}

function generateImportErrors(
  errors: {
    [K in string]: string[];
  },
  checkpoints: Checkpoint[],
): string[] {
  return [...generateHeaderErrors(errors), ...generateTableBodyErrors(errors, checkpoints)];
}

export { generateImportErrors };

import { isString } from 'lodash';
import moment from 'moment';

import { inputDate } from '../types';

function invalidDate(value: inputDate, type: BEDateFormat) {
  let val = '';

  if (isString(value)) {
    val = value;
  }

  if (moment.isMoment(value)) {
    val = `Moment object - ${value.toString()}`;
  }

  if (!value) {
    val = 'nil';
  }

  // eslint-disable-next-line
  throw `Invalid date (${val}) with parsing pattern ${type}`;
}

export { invalidDate };

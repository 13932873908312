import { t } from 'utils';

import { toastStore } from 'stores';

import { deleteResultService } from '../services';

import { distanceStore } from '../stores';

import { loadNewResultAutocomplete } from './loadNewResultAutocomplete';

async function deleteResult(racerId: number) {
  const distance = distanceStore.selected;

  if (!distance) {
    return;
  }

  const status = await deleteResultService.deleteResult(racerId);

  await loadNewResultAutocomplete();

  const message = status ? 'results.delete.result' : 'results.canNotDelete';

  toastStore.show(t(message));
}

export { deleteResult };

import React from 'react';

import { Show } from 'components/Condition';
import { Icon } from 'components/Icon';

type Props = {
  text: string;
};

const InfoComponent = ({ text }: Props) => {
  return (
    <Show if={text !== ''}>
      <div className='container-info'>
        <Icon value='info' className='icon' />
        <p className='info'>{text}</p>
      </div>
    </Show>
  );
};

const Info = React.memo<Props>(InfoComponent);

export { Info };

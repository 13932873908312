import { OrderStatus } from '../constants';

import { OrderType } from '../types';

class Order {
  value: OrderType;

  constructor(value: OrderType) {
    this.value = value;
  }

  refundProtect() {
    const { refund_protect_used } = this.value;

    return { refund_protect_used };
  }

  currency() {
    const { currency } = this.value;
    return currency || '';
  }

  totalSum(): string {
    const { total } = this.value;

    return `${this.currency()} ${+(total / 100).toFixed(2)}`;
  }

  priceUsed(): string {
    const { distance_original_price } = this.value;

    if (!distance_original_price) {
      return '';
    }

    return `${this.currency()} ${+(distance_original_price / 100).toFixed(2)}`;
  }

  getVat(): string {
    const { distance_vat_value } = this.value;

    if (!distance_vat_value) {
      return '';
    }

    return `${+(distance_vat_value / 100).toFixed(2)}%`;
  }

  discountedAmount(): string {
    const { discount } = this.value;

    return `${this.currency()} ${+(discount / 100).toFixed(2)}`;
  }

  amountForCustomFields(): string {
    const { custom_fields_sum } = this.value;

    if (!custom_fields_sum) {
      return '';
    }

    return `${this.currency()} ${+(custom_fields_sum / 100).toFixed(2)}`;
  }

  orderId(): string {
    const { id } = this.value;

    if (!id) {
      return '';
    }

    return `${id}`;
  }

  distanceName(): string {
    const { distance_title } = this.value;
    if (!distance_title) {
      return '';
    }

    return `${distance_title}`;
  }

  userFullName(): string {
    const { firstname, lastname } = this.value;

    if (!firstname && !lastname) {
      return '';
    }

    return `${firstname} ${lastname}`;
  }

  isPending(): boolean {
    return this.value.status === OrderStatus.pending;
  }

  isFailed(): boolean {
    return this.value.status === OrderStatus.failed;
  }

  isReady(): boolean {
    return this.value.status === OrderStatus.paid;
  }
}

export { Order };

import { errorsStore } from 'stores';

import { BIB_NUMBERS, COUPON, CREDIT_DISTANCE, CREDIT_SINGLE, MERGE, SUPPORT_TASK_MANAGER_FORM } from '../constants';

import { loadRaces, loadRefundDistances, setPrevAction } from '../actions';

import { modalStore } from '../stores';

import { loadOrders, loadTargetEmails, loadSourceEmails } from '.';

export const loadData = (selectedAction: string): void => {
  const prevAction: string = modalStore.value?.prevAction as any;

  if (selectedAction && prevAction !== selectedAction) {
    errorsStore.clear(SUPPORT_TASK_MANAGER_FORM);
    setPrevAction(selectedAction);

    switch (selectedAction) {
      case MERGE:
        loadSourceEmails();
        loadTargetEmails();
        break;

      case CREDIT_SINGLE:
        loadOrders();
        break;

      case CREDIT_DISTANCE:
        loadRefundDistances();
        break;

      case COUPON:
      case BIB_NUMBERS:
        loadRaces();
        break;
    }
  }
};

import { set } from 'lodash';
import { action, computed, observable, makeObservable, override } from 'mobx';

import { EXPORT_DATA } from 'src/constants';

import { Loadable, Search, Sort } from 'stores';

import { SystemUser as SystemUserModel } from '../models';

import { editIdStore } from '../stores';

import { filterDescriptors } from '../filters';
import { Roles as RolesType } from '../types';

const sortStore = new Sort(filterDescriptors);
const searchStore = new Search(filterDescriptors);

class SystemUsers extends Loadable<any, FiltersType> {
  @observable
  roles: RolesType = [];

  declare filters: FiltersType;

  static defaultFilters = {
    limit: 30,
    with: 'roles',
    searchJoin: 'AND',
    search: '',
    searchFields: '',
  };

  declare paginationMeta;

  resourceIdParams: FiltersType = {
    with: 'roles',
  };

  sort = sortStore;
  search = searchStore;
  extensions: Array<Extensionable> = [this.sort, this.search];

  constructor() {
    super();

    makeObservable(this);
    this.filters = SystemUsers.defaultFilters;
    this.paginationMeta = {
      total: EXPORT_DATA.defaultMetaData,
    };
  }

  @action
  addRoles(values: RolesType) {
    this.roles = values;
  }

  @action
  clearRoles() {
    this.roles = [];
  }

  @override
  setValueByKey(key: string, value: any) {
    set(this.values, key, value);
  }

  @override
  clearData() {
    this.values = [];
  }

  @override
  removeValue(id: number) {
    this.values = this.values.filter((value) => value.id !== id);
  }

  @computed
  get role(): string {
    const id = editIdStore.value;

    const systemUser = this.values.find((item) => item.id === id);

    if (!systemUser) {
      return '';
    }

    const result = systemUser.roles?.length && systemUser.roles[0]?.name;

    return result || '';
  }

  @computed
  get selectedUserName(): string {
    const id = editIdStore.value;

    const systemUser = this.values.find((item) => item.id === id);

    if (!systemUser) {
      return '';
    }
    return systemUser.full_name;
  }

  @computed
  get modelSelected(): SystemUserModel | nil {
    if (this.selected) {
      return new SystemUserModel(this.selected);
    } else {
      return null;
    }
  }

  @action
  resetFilters(): void {
    this.clearFilters();
  }

  @override
  clearAll() {
    this.clearRoles();
  }
}

export { SystemUsers };

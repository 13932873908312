import { t } from 'utils';

const base: AnyObject = {
  email: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    email: true,
  },
  full_name: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 3,
      maximum: 255,
    },
  },
  password: {
    length: {
      minimum: 6,
      maximum: 255,
    },
  },
  phone: {
    phoneNumber: {
      allowEmpty: true,
    },
  },
  post_code: {
    length: {
      maximum: 10,
    },
  },
};

const updateOrganizer = {
  ...base,
};

const createOrganizer = (objForValidate: AnyObject) => {
  let constraints = base;
  let emailAdditionalConstraints = {};
  let usernameAdditionalConstraints = {};
  let passwordAdditionalConstraints = {};

  if (objForValidate.email) {
    emailAdditionalConstraints = { email: true };
  }

  if (objForValidate.username) {
    usernameAdditionalConstraints = { username: true };
  }

  if (objForValidate.password) {
    passwordAdditionalConstraints = { password: true };
  }

  return {
    ...constraints,
    ...emailAdditionalConstraints,
    ...usernameAdditionalConstraints,
    ...passwordAdditionalConstraints,
  };
};

export { base, createOrganizer, updateOrganizer };

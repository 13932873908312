import { observer } from 'mobx-react';
import * as React from 'react';

import { withProgressSpinner, withSearch, withSorting, ListModule } from 'hocs';

import { FilterLabelToolbar } from 'components/Filter/FilterLabelToolbar';
// -------------
import { SubscribedPagination } from 'components/Pagination';

import { Breadcrumbs } from '../components/Breadcrumbs';
import { Nav } from '../components/Nav';

import { LOAD_RACE, LOAD_DISTANCE, LOAD_DISTANCES, LOAD_RACERS } from '../constants';

import { mountResults, unmountResults } from '../actions';

import { resultsService } from '../services';

import { ResultsContext } from '../context';
import { ImportErrorsModal } from './ImportErrorsModal';
import { NewResult } from './NewResult';
import { Table } from './Table';
import { Toolbar } from './Toolbar';

export const LOAD_RESULTS = 'RESULTS_LOAD_RESULTS';

// --------------

type Props = {};

@withProgressSpinner([LOAD_DISTANCE, LOAD_DISTANCES, LOAD_RACE, LOAD_RACERS])
@ListModule(resultsService, mountResults, unmountResults)
@withSorting(resultsService)
@withSearch(resultsService, 'results.fields')
@observer
class Results extends React.Component<Props> {
  static contextType = ResultsContext;
  declare context: React.ContextType<typeof ResultsContext>;

  render() {
    const { distanceStore, racesStore } = this.context.results.stores;
    const distanceModel = distanceStore.modelSelected;
    const raceModel = racesStore.modelSelected;

    if (!distanceModel || !raceModel) {
      return null;
    }

    const distanceId = distanceModel.value.id;
    const raceId = distanceModel.value.race_parent_id;

    return (
      <div className='details-start-lists'>
        <div className='content main-container-list start-list'>
          <div className='sticky-left'>
            <ImportErrorsModal />
            <NewResult />
            <Breadcrumbs distanceName={distanceModel.name()} raceName={raceModel.name()} raceId={raceId} />
            <div className='toolbar-container'>
              <Toolbar />
            </div>
            <Nav distanceId={distanceId} raceId={raceId} type={distanceModel.value.type} />
            <FilterLabelToolbar />
          </div>
          <Table />
          <SubscribedPagination />
        </div>
      </div>
    );
  }
}

export { Results };

import { isString, isEmpty } from 'lodash';

import { Result } from './result';

const columns = ['id', 'result_id', 'checkpoint_id', 'finish_time', 'place', 'created_at', 'updated_at'];

type StoreOption = {
  property: string;
  storeId: string;
};

class Split {
  value: SplitType;
  namespace: nil | string;
  isLast: boolean = false;

  result: nil | Result;

  constructor(value: SplitType, namespace?: string) {
    this.value = value;
    this.namespace = namespace;
  }

  setFieldIdPrefix(prefix: string) {
    this.namespace = prefix;
  }

  generateFieldId(columnName: string): string {
    return [this.namespace, columnName].join('.');
  }

  generateStoreOption(property: string): StoreOption {
    return { property, storeId: this.generateFieldId(property) };
  }

  generateStoreOptions(...properties: string[]): StoreOption[] {
    return properties.map((property) => this.generateStoreOption(property));
  }

  allStoreOptions(): StoreOption[] {
    return this.generateStoreOptions(...columns);
  }

  setResult(value: Result) {
    this.result = value;
  }

  setAsLast() {
    this.isLast = true;
  }

  // Created for FE purposes and is not stored on the BE yet
  isVirtual(): boolean {
    const { id, created_at } = this.value;
    return isString(id) && isEmpty(created_at);
  }
}

export { Split };

import { DISTANCE_TYPE, DISTANCE_STATUSES, DISTANCE_MODES } from 'src/constants';

import {
  Checkpoint as CheckpointModel,
  Class as ClassModel,
  Discipline as DisciplineModel,
  Race as RaceModel,
  Wave as WaveModel,
} from 'models';

import { localeStore } from 'stores';

class Distance {
  value: DistanceType;
  checkpoints: Array<CheckpointModel>;
  waves: Array<WaveModel>;
  classes: Array<ClassModel>;
  disciplines: Array<DisciplineModel>;
  // @ts-ignore
  race: RaceModel;

  constructor(value: DistanceType) {
    this.value = value;
    this.checkpoints = [];
    this.waves = [];
    this.classes = [];
    this.disciplines = [];

    this._initCheckpoints();
    this._initWaves();
    this._initRace();
    this._initClasses();
    this._initDisciplines();
  }

  raceLength(): nil | number {
    return this.value.race_length / 1000;
  }

  name() {
    return this.value.name;
  }

  // racers_count for single, teams_count for team distance
  entityCount(): number {
    const { value } = this;
    switch (value.type) {
      case DISTANCE_TYPE.single:
        return value.racers_count;
      case DISTANCE_TYPE.team:
        return value.teams_count;
      default:
        return value.racers_count;
    }
  }

  raceCurrency(): string {
    if (!this.race) {
      return '';
    }

    return this.race.currency();
  }

  _initCheckpoints() {
    const { checkpoints } = this.value;
    if (!!checkpoints) {
      this.checkpoints = checkpoints.map((distance) => new CheckpointModel(distance));
    }
  }

  _initWaves() {
    const { waves } = this.value;
    if (!!waves) {
      this.waves = waves.map((value) => new WaveModel(value));
    }
  }

  _initClasses() {
    const { classes } = this.value;
    if (!!classes) {
      this.classes = classes.map((value) => new ClassModel(value));
    }
  }

  _initDisciplines() {
    const { disciplines } = this.value;
    if (!!disciplines) {
      this.disciplines = disciplines.map((value) => new DisciplineModel(value));
    }
  }

  _initRace() {
    const { race } = this.value;

    if (!!race) {
      this.race = new RaceModel(race);
    }
  }

  isVisible(): boolean {
    const { value } = this;
    return value.is_visible;
  }

  relationForSelect(relation: 'waves' | 'classes' | 'disciplines'): Array<SelectOption> {
    // eslint-disable-next-line
    return (eval(`this.${relation}`) || []).map(
      // @ts-ignore
      (value) => value.forSelect(),
    );
  }

  findRelationModelValue(
    relation: 'waves' | 'classes' | 'disciplines',
    id: nil | number | string,
  ): nil | WaveModel | ClassModel | DisciplineModel {
    // eslint-disable-next-line
    return (eval(`this.${relation}`) || []).find(
      // @ts-ignore
      (el) => el.value.id === id,
    );
  }

  isStatus(value: distanceStatusType): boolean {
    return value === this.value.status;
  }

  isStatusStarted(): boolean {
    return this.isStatus(DISTANCE_STATUSES.started);
  }

  isStatusFinished(): boolean {
    return this.isStatus(DISTANCE_STATUSES.finished);
  }

  isMode(value: 'classic' | 'virtual' | 'cumulative') {
    const mode = this.value.distance_mode;

    switch (value) {
      case 'classic':
        return DISTANCE_MODES.CLASSIC === mode;
      case 'virtual':
        return DISTANCE_MODES.VIRTUAL === mode;
      case 'cumulative':
        return DISTANCE_MODES.CUMULATIVE === mode;
      default:
        return false;
    }
  }

  isSingle(): boolean {
    return this.value.type === 'single';
  }

  isTeam(): boolean {
    return this.value.type === 'team';
  }
}

export { Distance };

import React from 'react';

import { UploadTextSnap } from 'src/components/Form/Fields/FileUploader/style';

import { t } from 'utils';

type Props = {
  types?: Array<string>;
  minSize?: number;
  maxSize?: number;
};
/**
 * Draw the types and sizes restrictions for the uploading.
 * @param {Object} fileData file data types, minSize, maxSize
 * @returns JSX Element | null
 *
 * @internal
 */
export default function DrawTypes({ types, minSize, maxSize }: Props): null | JSX.Element {
  if (types) {
    const stringTypes = String(types).toLowerCase();
    let size = '';
    if (maxSize) size += t.staticAsString('distances.steps.helperText.gpx.sizeMax', { size: maxSize });
    if (minSize) size += t.staticAsString('distances.steps.helperText.gpx.sizeMin', { size: minSize });
    return (
      <UploadTextSnap title={t.staticAsString('distances.steps.helperText.gpx.types', { size, stringTypes })} className='file-types'>
        {t.staticAsString('distances.steps.helperText.gpx.formats', { stringTypes })}
      </UploadTextSnap>
    );
  }
  return null;
}

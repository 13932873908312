import { Autocomplete, TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import React from 'react';

import { useSearchSelectStyles } from '../styles';

type Props = {
  additional?: AutocompleteAdditionalProps;
  view?: BaseViewProps;
  value: number;
  onBlur?: Function;
  onInputChange: Function;
} & FieldBaseProps;

export function SearchSelect(props: Props) {
  const DEBOUNCE_TEXT_INPUT = 1000;
  const { name, label, value, error, onChange, onBlur, additional, onInputChange, view = {} } = props;
  const classes = useSearchSelectStyles();
  const inputClasses = additional?.classes && additional.classes();

  const { reservePlaceForHelperText, ...viewProps } = view;
  const helperText = error || (reservePlaceForHelperText && ' ');

  const withError = !!error;

  const debouncedInputChange = debounce(
    (event, newInputValue) => {
      onInputChange(event, newInputValue, name);
    },
    DEBOUNCE_TEXT_INPUT,
    { leading: false },
  );

  return (
    <Autocomplete
      {...(additional || ({} as any))}
      {...viewProps}
      id={name}
      multiple={false}
      classes={classes}
      getOptionLabel={(option: { label: string }) => option.label}
      value={value ?? null}
      onChange={onSelect(name, onChange)}
      onInputChange={(event, newInputValue) => {
        debouncedInputChange(event, newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...(params as any)}
          {...viewProps}
          error={withError}
          helperText={helperText}
          label={label}
          classes={inputClasses}
          onBlur={onBlur}
        />
      )}
    />
  );
}

const onSelect =
  (name: string, onChange: AnyFunction) =>
  (e: React.ChangeEvent<object>, value: AnyObject): void => {
    onChange({ name, value }, e);
  };

import { t } from 'utils';

import { toastStore } from 'stores';

import { distanceActionsService } from '../services';

import { distanceStore } from '../stores';

async function toogleResultsVisibility() {
  const distanceModel = distanceStore.modelSelected;
  if (!distanceModel) {
    return;
  }

  const isResultsShown = distanceModel.value.show_results;
  const message = isResultsShown ? 'races.detail.hidingResults.successMessage' : 'races.detail.showingResults.successMessage';

  const updateParams = { show_results: !isResultsShown };

  const status = await distanceActionsService.updateDistanceResults(distanceModel.value.id, updateParams);

  if (!status) {
    return;
  }

  toastStore.show(t(message));
}

export { toogleResultsVisibility };

import { has, isEqual } from 'lodash';
import { formatData } from 'modules/Races/mapper';
import editRaceService from 'modules/Races/services/editRace';
import editorSettingsService from 'modules/Races/services/editorSettings';
import receiptSettingsService from 'modules/Races/services/receiptSettings';
import { initForm, raceFormData } from 'modules/Races/utils';

import { isFormValid } from 'utils/core';

import { form, helperRacesStore } from 'stores';

import { RACE_FORM_ID, receiptSettingsFields, SHOW_TRANSLATIONS_WARNING, SHOW_TRANSLATIONS_WARNING_FIELDS } from '../constants';

import { getRaceId, getOrganizer } from '../utils';

import { editorSettingsStore, receiptSettingsStore } from '../stores';

import { mapOrganizerToContacts } from '../mapper';

const isReceiptSettingsField = (fieldName: string) => receiptSettingsFields.includes(fieldName);

export const updateField = async (fieldName: string): Promise<boolean> => {
  const data = formatData(fieldName);
  const raceId = getRaceId();

  if (has(data, 'image')) {
    editRaceService.uploadRaceImage({ raceId, value: data.image as Blob });
    return true;
  }

  if (fieldName === 'editorSettings') {
    const { editorSettings } = data;
    const tab_options = Object.keys(editorSettings).filter((key) => editorSettings[key]);
    editorSettingsStore.set(tab_options);
    editorSettingsService.createEditorSettings(raceId, { tab_options });
    return true;
  }

  if (fieldName === 'organizer_id') {
    const values = form.fetch<AnyObject>(RACE_FORM_ID);
    const organizerId = values.organizer_id.value;
    const organizer: OrganizerType | nil = getOrganizer(organizerId);
    const data = organizer
      ? {
          ['contacts']: mapOrganizerToContacts(organizer),
        }
      : {};

    const [isOk, race] = await editRaceService.updateField({ raceId, data });
    if (isOk) {
      helperRacesStore.addSelectedValue(race);
      initForm();
    }
  }

  if (isReceiptSettingsField(fieldName)) {
    const [isOk, receiptSettings] = await receiptSettingsService.updateReceiptSettings(raceId, data);
    if (isOk) {
      receiptSettingsStore.set(receiptSettings);
    }
    return isOk;
  }

  const [isOk, race] = await editRaceService.updateField({ raceId, data });
  if (isOk) {
    if (SHOW_TRANSLATIONS_WARNING_FIELDS.includes(fieldName)) {
      form.onChange(RACE_FORM_ID, SHOW_TRANSLATIONS_WARNING, true);
    }

    if (isFormValid(RACE_FORM_ID)) {
      helperRacesStore.addSelectedValue(race);
      initForm();
    }
  }

  return isOk;
};

export const handleDeleteContactEmail = async (email: string) => {
  form.onChange(
    RACE_FORM_ID,
    'organizer_emails',
    form.fetch<string[]>(RACE_FORM_ID, 'organizer_emails').filter((el) => el !== email),
  );
  const raceId = getRaceId();
  const data = formatData('organizer_emails');
  const [isOk, race] = await editRaceService.updateField({ raceId, data });
};

export const handleDeleteImage = async () => {
  const raceId = helperRacesStore.selected?.id ?? 0;
  await editRaceService.removeRaceImage({ raceId });
  form.onChange(RACE_FORM_ID, 'image', null);
};

export const handleDeleteImageExternal = async (raceId: number, formId: string) => {
  await editRaceService.removeRaceImage({ raceId });
  form.onChange(formId, 'image', null);
};

import { Box, Button, Chip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const GroupTitle = styled(Typography)({
  position: 'static',
  width: '173px',
  height: '16px',
  left: '0px',
  top: '0px',
  fontFamily: "'Roboto'",
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px',
  textAlign: 'center',
  letterSpacing: '0.254546px',
  textTransform: 'uppercase',
  color: '#262E35',
  flex: 'none',
  order: 0,
  flexGrow: 0,
  margin: '24px 0px',
});

export const SubTitle = styled(Typography)({
  position: 'static',
  height: '24px',
  left: '0px',
  top: '328px',
  fontFamily: "'Roboto'",
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  color: '#262E35',
  flex: 'none',
  order: 4,
  flexGrow: 0,
  margin: '24px 0px',
});

export const DisabledField = styled('div')(() => ({
  '& .Mui-disabled': {
    cursor: 'not-allowed',
  },
  '& .MuiSelect-select.Mui-disabled': {
    cursor: 'not-allowed',
  },
}));
export const NoteWrapper = styled('span')(() => ({
  a: {
    textDecoration: 'underline',
    color: 'inherit',
  },
}));
export const useSystemUsersFormStyles = makeStyles({
  buttonGroup: {
    padding: '0 16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  wrapper: {
    padding: '24px',
    backgroundColor: 'white',
    marginBottom: 24,
  },
  inputWrapper: {
    marginBottom: 16,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 582,
  },
  groupTitle: {
    position: 'static',
    width: '173px',
    height: '16px',
    left: '0px',
    top: '0px',
    fontFamily: "'Roboto'",
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.254546px',
    textTransform: 'uppercase',
    color: '#262E35',
    flex: 'none',
    order: 0,
    flexGrow: 0,
    margin: '24px 0px',
  },
  groupSubtitle: {
    position: 'static',
    height: '24px',
    left: '0px',
    top: '328px',
    fontFamily: "'Roboto'",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#262E35',
    flex: 'none',
    order: 4,
    flexGrow: 0,
    margin: '24px 0px',
  },
});

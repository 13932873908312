import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { isNull } from 'lodash';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { ColumnWithLooseAccessor } from 'react-table';

import { ROUTES } from 'src/constants/routes';

import { t } from 'utils/t';

import { CommissionService } from '../../services/commission.service';

import { CommissionStore } from '../../stores/commission.store';

import { Commission } from '../../types/commissions.type';

const COLUMNS = ['expander', 'races', 'commissions_amount', 'commissions', 'vat_percents', 'action'] as const;

const title = (prefix: string) => t.static(`reconciliation.commissions.${prefix}` as any);

const isEdit = (id: number) => CommissionStore.modifyCommission.value?.id === id;

const SelectCell = (props) => {
  const {
    value: initialValue,
    field,
    column: { id, selectProps },
  } = props;
  const options = selectProps.options() || [];
  const [value, setValue] = React.useState(initialValue);
  const onChange = (e) => {
    setValue(e.target.value);
    CommissionStore.modifyCommission.value = {
      ...CommissionStore.modifyCommission.value!,
      commission: {
        ...CommissionStore.modifyCommission.value?.commission!,
        [field]: e.target.value,
      },
    };
  };
  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Box minWidth='160px' p={0.5}>
      <TextField
        style={{ borderRadius: 0, maxWidth: 150, marginTop: 0, backgroundColor: 'white' }}
        variant='outlined'
        size='small'
        fullWidth
        select
        name={id}
        value={value ?? ''}
        onChange={onChange}
      >
        {options.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

const EditableCell = ({ value: initialValue, field }) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
    CommissionStore.modifyCommission.value = {
      ...CommissionStore.modifyCommission.value!,
      commission: {
        ...CommissionStore.modifyCommission.value?.commission!,
        [field]: e.target.value,
      },
    };
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextField
      style={{ borderRadius: 0, maxWidth: 150, marginTop: 0, backgroundColor: 'white' }}
      variant='outlined'
      size='small'
      value={value ?? ''}
      onChange={onChange}
    />
  );
};

const renderMargin = (ir?: boolean, id?: boolean) => {
  if (ir) return 0;
  if (id) return 3;
};

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<Commission.TableRow> & AnyObject } = {
  expander: {
    id: 'expander',
    filterable: false,
    sortable: false,
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => <span />,
    Cell: ({ row }) =>
      row.canExpand ? (
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              display: 'flex',
              alignItems: 'center',
            },
          })}
        >
          {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
        </span>
      ) : null,
  },
  races: {
    id: 'name',
    Header: title('organizerRace&Distances'),
    accessor: (entry) => entry.name,
    Cell: ({ row: { original } }) => {
      const getLink = () => {
        if (original.isRace) return generatePath(ROUTES.raceRoute, { id: original.id });
        if (original.isDistance) return generatePath(ROUTES.distancesRacersRoute, { raceId: original.raceId, id: original.id });
        return '/';
      };
      return (
        <Box sx={{ ml: renderMargin(original.isRace, original.isDistance), fontWeight: original.isRace ? 600 : 400 }}>
          <Link to={getLink()} style={{ color: 'black' }}>
            {original.name}
          </Link>
        </Box>
      );
    },
  },
  commissions_amount: {
    id: 'commission.amount',
    Header: title('commissionsFixed'),
    accessor: (entry) => {
      return entry.commission?.amount;
    },
    Cell: (props) => {
      const isFormRendering = isEdit(props.row.original.id ?? 0);
      if (isFormRendering) {
        return <EditableCell field='amount' {...props} />;
      }
      return <>{props.row.original.commission?.amount}</>;
    },
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
  },

  commissions: {
    id: 'commission.percent_value',
    Header: title('commissionsPercent'),
    accessor: (entry) => entry.commission?.percent_value,
    Cell: (props) => {
      const isFormRendering = isEdit(props.row.original.id ?? 0);
      if (isFormRendering) {
        return <EditableCell field='percent_value' {...props} />;
      }
      return <>{props.row.original.commission?.percent_value}</>;
    },
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
  },

  vat_percents: {
    id: 'commission.vat_percents',
    Header: title('vatPercent'),
    accessor: (entry) => entry.commission?.vat_percents,
    Cell: (props) => {
      const isFormRendering = isEdit(props.row.original.id ?? 0);
      if (isFormRendering) {
        return <SelectCell field='vat_percents' {...props} />;
      }
      return <>{props.row.original.commission ? `${props.row.original.commission.vat_percents}%` : ''}</>;
    },
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    selectProps: {
      options: () => [
        {
          key: '0',
          value: 0,
          label: '0%',
        },
        {
          key: '25',
          value: 25,
          label: '25%',
        },
      ],
    },
  },

  action: {
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    id: 'action',
    Header: title('action'),
    Cell: ({ row: { original } }) => {
      const renderButton = () => {
        const getButtonText = () => {
          if (isEdit(original.id)) return title('save');
          if (original.commission === null) return title('setCommission');
          return title('edit');
        };

        const handleClick = () => {
          if (isEdit(original.id)) return CommissionService.submit();
          return CommissionStore.modifyCommission.set(original);
        };

        const handleClose = () => {
          CommissionStore.modifyCommission.set(null);
        };
        const handleReset = () => {
          if (isNull(original.commission)) return;
          CommissionService.remove(original.commission.id);
        };
        return (
          <Box sx={{ display: 'flex' }}>
            <Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleClick}>
              {getButtonText()}
            </Typography>
            {isEdit(original.id) && (
              <Typography sx={{ ml: 2, textDecoration: 'underline', cursor: 'pointer' }} onClick={handleClose}>
                {title('close')}
              </Typography>
            )}
            {isEdit(original.id) && !isNull(original.commission) && (
              <Typography sx={{ ml: 2, textDecoration: 'underline', cursor: 'pointer' }} onClick={handleReset}>
                {t('systemUsers.commissions.reset')}
              </Typography>
            )}
          </Box>
        );
      };
      return renderButton();
    },
  },
};

export function generateCommissionColumns() {
  return COLUMNS.map((c) => RenderColumns[c]);
}

import { FormLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { InputWrapper, Switch, TextField, WrappedRadio, MultiCheckbox } from 'src/components/Form';
import { RADIO_PERCENTAGE, RADIO_AMOUNT, COUPON_CODE_TYPES } from 'src/modules/CouponsForm/constants';
import { form } from 'src/stores';

import { Show } from 'components/Condition';

import { t } from 'utils/t';

import { REGISTRATION_LINKS_FORM_ID } from '../constants';

import { distanceHasPayableFeatures } from '../utils';

import { Title } from './Styled';

const StyledFormLabel = withStyles({
  root: {
    marginBottom: 10,
  },
})(FormLabel);

const Wrapper = styled.div`
  width: 349px;
`;

export const CouponFields = () => {
  return (
    <Observer>
      {() => {
        const showCouponSection = Boolean(form.fetch(REGISTRATION_LINKS_FORM_ID, 'distance_id')) && Boolean(distanceHasPayableFeatures());
        const activated = Boolean(form.fetch(REGISTRATION_LINKS_FORM_ID, 'discount'));

        return (
          <Show if={showCouponSection}>
            <Wrapper>
              <Title>
                {t.staticAsString('smartLinks.createCouponCode')}
                <InputWrapper name='discount' Component={Switch} />
              </Title>
              <Show if={activated}>
                <StyledFormLabel>{t.staticAsString('coupons.new.discount_type')}</StyledFormLabel>
                <InputWrapper
                  name='discount_type'
                  settings={{
                    options: [RADIO_AMOUNT, RADIO_PERCENTAGE],
                  }}
                  Component={WrappedRadio}
                />
                <InputWrapper
                  name='discount_sum'
                  settings={{
                    label: t.staticAsString('coupons.detail.discountAmount'),
                    view: {
                      variant: 'outlined',
                      fullWidth: true,
                    },
                    additional: {
                      type: 'number',
                    },
                  }}
                  Component={TextField}
                />
                <InputWrapper
                  name='discount_sum_type'
                  settings={{
                    additional: {
                      options: COUPON_CODE_TYPES,
                      label: t.staticAsString('coupons.new.codeType'),
                    },
                  }}
                  Component={MultiCheckbox}
                />
              </Show>
            </Wrapper>
          </Show>
        );
      }}
    </Observer>
  );
};

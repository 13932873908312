import styled, { css } from 'styled-components';

import { fontFamilyRoboto } from 'src/components/Form/Fields/FileUploader/style';

export const WrapperOneCheckPoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  margin: 0 0 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  position: relative;
`;

export const WrapperCheckPointName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const WrapperCheckPointCoordinate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

export const CheckPointName = styled.div`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #262e35;
  margin: 0;
  width: 100%;
`;

export const CoordinateName = styled.div`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #a8aeb8;
  margin: 0 8px 0 0;
  display: inline-block;
`;

export const CoordinateValue = styled.div`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #262e35;
  margin: 0;
  display: inline-block;
`;

export const ActiveButton = styled.div`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ##262e35;
  text-transform: uppercase;
  letter-spacing: 0.5px;

  border: 2px solid #f9d026;
  background: #f9d026;
  border-radius: 2px;
  padding: 10px 22px;

  cursor: pointer;
`;

export const CloseButton = styled.div`
  position: absolute;
  width: 11px;
  height: 11px;
  display: block;
  cursor: pointer;
  border: 1px solid red;
`;

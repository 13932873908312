import { isUndefined } from 'lodash';
import * as React from 'react';

import { teamFields } from '../../../constants';

import { handleMissingStartTime } from '../../../actions';

import { prepareForEdit, isEmptyDate } from '../../../tools';
import { GenerateFieldEdit } from '../../../types';
import { generateField } from '../../Fields';

const FIELD_EDIT = {
  [teamFields.time_result]: function (model, _context, _intl) {
    return (onChange, updatedValue, onBlur, error) => {
      const { value } = model;
      const diff = prepareForEdit.duration(value.start_time, value.finish_time);

      const passedValue = isUndefined(updatedValue) ? diff : updatedValue;
      const fieldId = model.generateFieldId('time_result');

      if (isEmptyDate(value.start_time)) {
        return handleMissingStartTime(fieldId);
      }

      return generateField('duration', passedValue, onChange, onBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [teamFields.start_time]: function (model, _context, _intl) {
    return (onChange, updatedValue, onBlur, error) => {
      const { value } = model;
      const startTime = prepareForEdit.dateTime(value.start_time);
      const passedValue = isUndefined(updatedValue) ? startTime : updatedValue;
      const fieldId = model.generateFieldId('start_time');

      return generateField('datetime', passedValue, onChange, onBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [teamFields.finish_time]: function (model, _context, _intl) {
    return (onChange, value, onBlur, error) => {
      const finishTime = prepareForEdit.dateTime(model.value.finish_time);
      const passedValue = isUndefined(value) ? finishTime : value;
      const fieldId = model.generateFieldId('finish_time');

      return generateField('datetime', passedValue, onChange, onBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [teamFields['team.bib_number']]: function (model, _context, _intl) {
    return (onChange, changedValue, onBlur, error) => {
      const { team } = model;
      if (!team) {
        return null;
      }

      const value = team?.value?.bib_number;
      const passedValue = isUndefined(changedValue) ? value : changedValue;
      const fieldId = team.generateFieldId('bib_number') || '';

      return generateField('text', passedValue, onChange, onBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [teamFields['team.name']]: function (model, _context, _intl) {
    return (onChange, changedValue, onBlur, error) => {
      const { team } = model;
      if (!team) {
        return null;
      }

      const value = team?.value?.name;
      const passedValue = isUndefined(changedValue) ? value : changedValue;
      const fieldId = team.generateFieldId('name') || '';

      return generateField('text', passedValue, onChange, onBlur, fieldId, error);
    };
  } as GenerateFieldEdit,

  [teamFields['team.class_id']]: function (model, context, _intl) {
    return (onChange, changedValue, onBlur, error) => {
      const { team } = model;
      if (!team) {
        return null;
      }

      const distanceModel = context.results.stores.distanceStore.modelSelected;
      const options = (distanceModel && distanceModel.relationForSelect('classes')) || [];
      const selectedOption = team?.value?.class_id;
      const passedValue = isUndefined(changedValue) ? selectedOption : changedValue;
      const fieldId = team.generateFieldId('class_id') || '';

      return generateField('select', passedValue, onChange, onBlur, fieldId, error, options);
    };
  } as GenerateFieldEdit,

  [teamFields['team.wave_id']]: function (model, context, _intl) {
    return (onChange, changedValue, onBlur, error) => {
      const { team } = model;
      if (!team) {
        return null;
      }

      const distanceModel = context.results.stores.distanceStore.modelSelected;
      const options = (distanceModel && distanceModel.relationForSelect('waves')) || [];
      const selectedOption = team?.value?.wave_id;
      const passedValue = isUndefined(changedValue) ? selectedOption : changedValue;
      const fieldId = team.generateFieldId('wave_id') || '';

      return generateField('select', passedValue, onChange, onBlur, fieldId, error, options);
    };
  } as GenerateFieldEdit,
};

export { FIELD_EDIT };

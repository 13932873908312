import { DISTANCE_STEPS } from 'src/constants';

import { mapGoalFromBe, mapGoalForBe } from '../../../utils';

import * as CheckpointStep from './Checkpoints';
import * as ClassStep from './Classes';
import { ConfirmationEmailStep } from './ConfirmationEmail';
import * as CustomFieldStep from './CustomFields';
import * as DisciplinesStep from './Disciplines';
import { DistanceDetails } from './DistanceDetails';
import { GPX } from './GPX';
import * as MedicalAssistantsStep from './MedicalAssistants';
import { NameAndDescriptionStep } from './Name&Description';
import * as PriceStep from './Prices';
import * as RefundProtectStep from './RefundProtect';
import { RegistrationFields } from './RegistrationFields';
import * as SelfServicesStep from './SelfServices';
import { TabOptions } from './TabOptions';
import { Translations } from './Translations';
import * as WaveStep from './Waves';

const FIELDS_STEPS_INTERSECTION_TABLE: AnyObject = {
  [DISTANCE_STEPS.distanceDetails]: [
    '^race_date',
    '^ends_at',
    '^start_type',
    '^type',
    '^registration_type',
    '^registration_starts_at',
    '^registration_ends_at',
    '^race_length',
    '^max_members_quantity',
    '^min_members_quantity',
    '^race_qty',
    '^max_members_quantity',
    '^min_members_quantity',
    '^type',
    '^start_type',
    '^goal.*',
  ],
  [DISTANCE_STEPS.nameAndDescription]: ['^name', '^description'],
  [DISTANCE_STEPS.confirmationEmail]: ['^email_content.*'],
  [DISTANCE_STEPS.checkpoints]: ['^checkpoints.\\d*'],
  [DISTANCE_STEPS.classes]: ['^classes.\\d*'],
  [DISTANCE_STEPS.prices]: ['^prices.\\d*', '^vat_percents'],
  [DISTANCE_STEPS.refund_protect]: ['^refund_protect'],
  [DISTANCE_STEPS.waves]: ['^waves.\\d*'],
  [DISTANCE_STEPS.custom_fields]: ['^custom_fields.\\d*'],
  [DISTANCE_STEPS.selfServices]: ['^self_services.\\d*'],
  [DISTANCE_STEPS.disciplines]: ['^disciplines.\\d*'],
};

const STEP_TITLES: AnyObject = {
  [DISTANCE_STEPS.distanceDetails]: 'distances.steps.distanceDetails',
  [DISTANCE_STEPS.nameAndDescription]: 'distances.steps.nameAndDescription',
  [DISTANCE_STEPS.confirmationEmail]: 'distances.steps.confirmationEmail',
  [DISTANCE_STEPS.registrationFields]: 'distances.steps.titles.registrationFields',
  [DISTANCE_STEPS.tabOptions]: 'distances.steps.titles.additionalOptions',
  [DISTANCE_STEPS.checkpoints]: 'distances.steps.titles.checkpoints',
  [DISTANCE_STEPS.classes]: 'distances.steps.titles.classes',
  [DISTANCE_STEPS.prices]: 'distances.steps.titles.prices',
  [DISTANCE_STEPS.refund_protect]: 'distances.steps.titles.refund_protect',
  [DISTANCE_STEPS.waves]: 'distances.steps.titles.waves',
  [DISTANCE_STEPS.custom_fields]: 'distances.steps.titles.custom_fields',
  [DISTANCE_STEPS.selfServices]: 'distances.steps.titles.selfServices',
  [DISTANCE_STEPS.disciplines]: 'distances.steps.titles.disciplines',
  [DISTANCE_STEPS.medical_assistants]: 'distances.steps.titles.medicalAssistants',
};

const steps: AnyObject = {
  [DISTANCE_STEPS.nameAndDescription]: NameAndDescriptionStep,
  [DISTANCE_STEPS.distanceDetails]: DistanceDetails,
  [DISTANCE_STEPS.confirmationEmail]: ConfirmationEmailStep,
  [DISTANCE_STEPS.registrationFields]: RegistrationFields,
  [DISTANCE_STEPS.tabOptions]: TabOptions,
  [DISTANCE_STEPS.checkpoints]: CheckpointStep.Checkpoints,
  [DISTANCE_STEPS.classes]: ClassStep.Classes,
  [DISTANCE_STEPS.prices]: PriceStep.Prices,
  [DISTANCE_STEPS.refund_protect]: RefundProtectStep.RefundProtect,
  [DISTANCE_STEPS.waves]: WaveStep.Waves,
  [DISTANCE_STEPS.custom_fields]: CustomFieldStep.CustomFields,
  [DISTANCE_STEPS.selfServices]: SelfServicesStep.SelfServices,
  [DISTANCE_STEPS.disciplines]: DisciplinesStep.Disciplines,
  [DISTANCE_STEPS.medical_assistants]: MedicalAssistantsStep.MedicalAssistants,
  [DISTANCE_STEPS.translations]: Translations,
  [DISTANCE_STEPS.GPX]: GPX,
};

const DATA_NORMALIZER_FOR_BACKEND: AnyObject = {
  [DISTANCE_STEPS.checkpoints]: CheckpointStep.normalizeDataForBackend,
  [DISTANCE_STEPS.classes]: ClassStep.normalizeDataForBackend,
  [DISTANCE_STEPS.prices]: PriceStep.normalizeDataForBackend,
  [DISTANCE_STEPS.waves]: WaveStep.normalizeDataForBackend,
  [DISTANCE_STEPS.custom_fields]: CustomFieldStep.normalizeDataForBackend,
  [DISTANCE_STEPS.disciplines]: DisciplinesStep.normalizeDataForBackend,
  [DISTANCE_STEPS.medical_assistants]: MedicalAssistantsStep.normalizeDataForBackend,
  goal: mapGoalForBe,
  default: (relation: any) => relation,
};

const DATA_NORMALIZER_FROM_BACKEND = {
  [DISTANCE_STEPS.prices]: PriceStep.normalizeDataFromBackend,
  goal: mapGoalFromBe,
};

const DELETE_STEPS_CLEAN_UP: AnyObject = {
  [DISTANCE_STEPS.prices]: PriceStep.cleanStep,
  [DISTANCE_STEPS.custom_fields]: CustomFieldStep.cleanStep,
};

const DISTANCE_ERRORS_HASH = {
  [DISTANCE_STEPS.nameAndDescription]: DISTANCE_STEPS.nameAndDescription,
  [DISTANCE_STEPS.distanceDetails]: DISTANCE_STEPS.distanceDetails,
  [DISTANCE_STEPS.confirmationEmail]: DISTANCE_STEPS.confirmationEmail,
  [DISTANCE_STEPS.registrationFields]: DISTANCE_STEPS.registrationFields,
  [DISTANCE_STEPS.tabOptions]: DISTANCE_STEPS.tabOptions,
  [DISTANCE_STEPS.checkpoints]: DISTANCE_STEPS.checkpoints,
  [DISTANCE_STEPS.classes]: DISTANCE_STEPS.classes,
  [DISTANCE_STEPS.prices]: DISTANCE_STEPS.prices,
  [DISTANCE_STEPS.waves]: DISTANCE_STEPS.waves,
  [DISTANCE_STEPS.custom_fields]: DISTANCE_STEPS.custom_fields,
  [DISTANCE_STEPS.disciplines]: DISTANCE_STEPS.disciplines,
  [DISTANCE_STEPS.medical_assistants]: DISTANCE_STEPS.medical_assistants,
  [DISTANCE_STEPS.translations]: DISTANCE_STEPS.translations,
  [DISTANCE_STEPS.GPX]: DISTANCE_STEPS.GPX,
};

export { Checkpoints } from './Checkpoints';
export { Classes } from './Classes';
export { Disciplines } from './Disciplines';
export { CustomFields } from './CustomFields';
export { Prices } from './Prices';
export { generateRegistrationFields, generateRegistrationFieldsBasedOnData } from './RegistrationFields';
export { TabOptions, generateOptionsBasedOnData as generatedTabOptionsBasedOnData } from './TabOptions';
export { Waves } from './Waves';

export default steps;
export {
  FIELDS_STEPS_INTERSECTION_TABLE,
  STEP_TITLES,
  DATA_NORMALIZER_FOR_BACKEND,
  DATA_NORMALIZER_FROM_BACKEND,
  DELETE_STEPS_CLEAN_UP,
  DISTANCE_ERRORS_HASH,
};

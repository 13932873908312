import { Edit as EditIcon } from '@mui/icons-material';
import ApiIcon from '@mui/icons-material/Api';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import Axios from 'axios';
import * as React from 'react';
import { useState } from 'react';

import { time, t, history } from 'utils';

import { SystemUser } from '../../types';

type Props = {
  value: SystemUser;
};

export const AccountInfo = ({ value }: Props) => {
  const [apiLink, setApiLink] = useState('');
  const jsonValue = value;
  const createdAt = time.datetime(jsonValue.created_at).parse().format('date');
  const handleEdit = () => {
    history.push(`/system-users/${jsonValue.id}/edit`);
  };
  const handleGetToken = async () => {
    const { data } = await Axios.post(`/api/v1/admin/organizers/${jsonValue.id}/reset-password`);
    const link = data.link;
    if (link) {
      navigator.clipboard.writeText(link);
      setApiLink(link);
    }
  };
  return (
    <div className='account-info-wrapper'>
      <div className='account-info-title-wrapper'>
        <h3 className='account-info-title'>{t.staticAsString('organizers.detail.accountTitle')}</h3>
        <div>
          <EditIcon className='icon' onClick={handleEdit} />
          <ApiIcon titleAccess='Get outbound token' sx={{ ml: 2 }} className='icon' onClick={handleGetToken} />
        </div>
      </div>
      <Box sx={{ mb: '28px' }}>{apiLink && <Typography variant='body2'>{apiLink}</Typography>}</Box>

      <div className='account-info-body'>
        <div className='info-item'>
          <p className='info-label'>{t.staticAsString('organizers.detail.full_name')}</p>
          <p className='info-value'>{jsonValue.full_name}</p>
        </div>

        <div className='info-item'>
          <p className='info-label'>{t.staticAsString('organizers.detail.email')}</p>
          <p className='info-value'>{jsonValue.email}</p>
        </div>

        <div className='info-item'>
          <p className='info-label'>{t.staticAsString('organizers.detail.phone')}</p>
          <p className='info-value'>{jsonValue.phone}</p>
        </div>

        <div className='info-item'>
          <p className='info-label'>{t.staticAsString('organizers.detail.created_at')}</p>
          <p className='info-value'>{createdAt}</p>
        </div>
      </div>
    </div>
  );
};

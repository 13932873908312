import { reportedBy } from 'modules/SupportTaskManager/CopyRacers/filters/reportedBy';

import { FilterDescriptorWrapper } from 'models';

import { id } from './id';
import { created_at } from './reportedAt';
import { type } from './type';

type TasksDescriptors = 'id' | 'type' | 'reportedBy' | 'created_at';
type Descs = { [K in TasksDescriptors]: FilterListDescriptor };
type DescsWrapped = { [K in TasksDescriptors]: FilterDescriptorWrapper };

const descriptors: Descs = {
  id,
  type,
  reportedBy,
  created_at,
};

const descriptorsKeys = Object.keys(descriptors) as Array<TasksDescriptors>;

export const filterDescriptors: DescsWrapped = descriptorsKeys.reduce((acc, desc) => {
  return {
    ...acc,
    [desc]: new FilterDescriptorWrapper(desc, descriptors[desc]),
  };
}, {} as DescsWrapped);

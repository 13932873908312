import * as React from 'react';
import styled from 'styled-components';

import {
  WrapperOneCheckPoint,
  ActiveButton,
  CheckPointName,
  WrapperCheckPointName,
} from 'src/modules/Distances/components/shared/Steps/GPX/components/ManageCheckPointsModal/styled';
import { CheckPoint } from 'src/modules/Distances/components/shared/Steps/GPX/types';
import { setActiveCheckPoint } from 'src/modules/Distances/components/shared/Steps/GPX/utils';

import { t } from 'utils';

type Props = {
  checkpoint: CheckPoint;
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const OneCheckPointSet = (props: Props) => {
  const { checkpoint } = props;

  return (
    <WrapperOneCheckPoint>
      <WrapperCheckPointName>
        <CheckPointName>{checkpoint.name}</CheckPointName>
        <ActiveButton onClick={() => setActiveCheckPoint(checkpoint.id)}>
          {t.staticAsString('distances.steps.helperText.gpx.set')}
        </ActiveButton>
      </WrapperCheckPointName>
    </WrapperOneCheckPoint>
  );
};

const API_ORIGIN = {
  prod: 'https://api.raceid.com',
  stage: 'https://api.staging.raceid.com',
  dev: 'https://api.dev.raceid.com',
  loc: 'http://race.loc',
  sfe: 'http://3.127.68.119',
  sfe2: 'http://3.122.46.215',
};

function fetchApiOrigin(name) {
  return API_ORIGIN[name];
}

fetchApiOrigin.API_ORIGIN = API_ORIGIN;

export { fetchApiOrigin, API_ORIGIN };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACE_INTEGRATIONS, RACE_INTEGRATION } from 'src/constants';

import { action, request } from 'utils';

import { form } from 'stores';

import { CREATE_INTEGRATION, GET_INTEGRATION, INTEGRATION_FORM } from '../constants';

import { mapToForm } from '../utils';

import { integrationStore } from '../stores';

import { IntegrationType } from '../types';

class IntegrationService {
  @request({ action: INTEGRATION_FORM })
  async createIntegrationRequest(id: number, data: IntegrationType): Promise<any> {
    const url = generatePath(RACE_INTEGRATIONS, { id });
    return axios.post(url, data);
  }

  @action({ action: CREATE_INTEGRATION })
  async createIntegration(raceId: number, data: IntegrationType): Promise<boolean> {
    const [status] = await this.createIntegrationRequest(raceId, data);

    return status;
  }

  @request({ action: INTEGRATION_FORM })
  async editIntegrationRequest(raceId: number, id: number, data: IntegrationType): Promise<any> {
    const url = generatePath(RACE_INTEGRATION, { raceId, id });
    return axios.put(url, data);
  }

  @action({ action: CREATE_INTEGRATION })
  async editIntegration(raceId: number, id: number, data: IntegrationType): Promise<boolean> {
    const [status] = await this.editIntegrationRequest(raceId, id, data);

    return status;
  }

  @request({ action: GET_INTEGRATION })
  async getIntegrationRequest(raceId: number, id: number): Promise<any> {
    const url = generatePath(RACE_INTEGRATION, { raceId, id });
    return axios.get(url);
  }

  @action({ action: GET_INTEGRATION })
  async getIntegration(raceId: number, integrationId: number | null): Promise<any> {
    if (!integrationId) return;

    const [status, response] = await this.getIntegrationRequest(raceId, integrationId);

    if (status) {
      integrationStore.set(response.data.data);
      form.merge(INTEGRATION_FORM, mapToForm(response.data.data));
    }
  }
}

export const integrationService = new IntegrationService();

import classNames from 'classnames';
import * as React from 'react';

import { TableHeaderItem } from 'components/Filter/TableHeaderItem';

import { t } from 'utils';

import { RACERS_COLUMNS } from '../../constants';

import { HeadersType } from './';

const { MAIN_COLUMNS, COLUMN_WIDTH } = RACERS_COLUMNS;

const classes = {
  thead: {
    regular: ['start-list-table-header', 'filter-item'],
  },
};

const RacersHeadersComponent: React.FC<HeadersType> = function ({}: HeadersType) {
  return MAIN_COLUMNS.map((column) => {
    const width = COLUMN_WIDTH[column];
    const className = `cell-${column}`;
    const translation = `racers.fields.${column}`;

    return (
      <TableHeaderItem
        key={column}
        name={column}
        width={width}
        className={classNames(...classes.thead.regular, className)}
        label={t(translation as TranslationLockedKeys)}
      />
    );
  }) as any;
};

const RacersHeaders = React.memo<HeadersType>(RacersHeadersComponent);
export { RacersHeaders };

import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { List } from './List';

class Customers extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path={ROUTES.customersRoute} exact component={List} />
      </Switch>
    );
  }
}

export { Customers };

import { chosenDistance } from '../utils';

import { racesService, distancesService, wavesService } from '../services';

import { racesStore, racesParamsStore, wavesParamsStore, wavesStore } from '../stores';

export const loadMoreRaces = () => {
  racesService.getRaces();
};

export const searchRaces = (query: string) => {
  racesStore.clear();
  racesParamsStore.set({
    ...(racesParamsStore.value as any),
    page: 1,
    search: `name:${query}`,
    searchFields: 'name:like',
  });
  racesService.getRaces();
};

export const loadDistances = (raceId: number) => {
  distancesService.getDistances(raceId);
};

export const loadWaves = (distanceId: number) => {
  wavesService.getWaves(distanceId);
};

export const searchWaves = (query: string) => {
  const distance = chosenDistance();
  wavesStore.clear();
  wavesParamsStore.set({
    ...(wavesParamsStore.value as any),
    page: 1,
    search: `name:${query}`,
    searchFields: 'name:like',
  });
  distance && loadWaves(distance.id);
};

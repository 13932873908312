import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { withStyles } from '@mui/styles';
import * as React from 'react';

import { Loading } from 'components/Loading';

import { IntegrationsTableHead, IntegrationsTableBody, Pagination } from '../components';

import { GET_INTEGRATIONS } from '../constants';

type Props = {};

const StyledBox = withStyles({
  root: {
    position: 'relative',
  },
})(Box);

export class IntegrationsTable extends React.Component<Props> {
  render() {
    return (
      <StyledBox>
        <Loading action={GET_INTEGRATIONS} />
        <Table>
          <TableHead>
            <TableRow>
              <IntegrationsTableHead />
            </TableRow>
          </TableHead>
          <TableBody>
            <IntegrationsTableBody />
          </TableBody>
        </Table>
        <Pagination />
      </StyledBox>
    );
  }
}

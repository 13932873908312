import axios from 'axios';
import { duplicateDataType } from 'modules/RaceDetails/types';
import { generatePath } from 'react-router-dom';

import { RACE_DISTANCES_URL, RACE_DISTANCE_URL, DUPLICATE_DISTANCE_URL, RACE_DISTANCE_DND_URL } from 'src/constants';

import { request, action } from 'utils';

import { DELETE_DISTANCE, UPDATE_DISTANCE, DUPLICATE_DISTANCE, LOAD_MORE_DISTANCES } from '../constants';

import { raceDetailsStore, distancesStore } from '../stores';

class Distances {
  @request({ action: LOAD_MORE_DISTANCES })
  async loadDistancesRequest(raceId: number): Promise<any> {
    const url = generatePath(RACE_DISTANCES_URL, { raceId });

    const params = {
      ...distancesStore.defaultFilters,
      page: distancesStore.page,
    };

    return axios.get(url, { params });
  }

  @action({ action: LOAD_MORE_DISTANCES, minRequestTime: 500 })
  async loadDistances(id: number): Promise<any> {
    const [status, response] = await this.loadDistancesRequest(id);

    if (status) {
      distancesStore.setHasMore(response.data.meta.pagination.total_pages > distancesStore.page);
      distancesStore.appendDistances(response.data.data);
      distancesStore.setPage(distancesStore.page + 1);
    }
  }

  @request({ action: DELETE_DISTANCE })
  deleteDistanceRequest(id: number): Promise<any> {
    const raceId = raceDetailsStore.raceDetails?.value.id;
    const url = generatePath(RACE_DISTANCE_URL, { raceId, id });

    return axios.delete(url);
  }

  @action({ action: DELETE_DISTANCE })
  async deleteDistance(id: number): Promise<any> {
    const [status] = await this.deleteDistanceRequest(id);

    return status;
  }

  @request({ action: UPDATE_DISTANCE })
  updateDistanceRequest(id: number, params: FiltersType): Promise<any> {
    const url = generatePath(RACE_DISTANCE_DND_URL, { id });

    return axios.patch(url, params);
  }

  @action({ action: UPDATE_DISTANCE })
  async updateDistance(id: number, params: FiltersType): Promise<any> {
    const [status] = await this.updateDistanceRequest(id, params);

    return status;
  }
  @action({ action: UPDATE_DISTANCE })
  async updateDistanceIndex(id: number, index: number): Promise<any> {
    const params = { index };
    const [status] = await this.updateDistanceRequest(id, params);

    return status;
  }

  @request({ action: DUPLICATE_DISTANCE })
  duplicateDistanceRequest(distanceId: number, data: duplicateDataType): Promise<any> {
    const url = generatePath(DUPLICATE_DISTANCE_URL, { distanceId });
    return axios.post(url, data);
  }

  @action({ action: DUPLICATE_DISTANCE })
  async duplicateDistance(distanceId: number, data: duplicateDataType): Promise<Array<any>> {
    if (!distanceId) {
      return [];
    }

    const [status, response] = await this.duplicateDistanceRequest(distanceId, data);

    return [status, response];
  }
}

const distancesService = new Distances();

export { distancesService };

import isEmpty from 'lodash/isEmpty';
import { GET_DISTANCES } from 'modules/RaceDetails/constants';
import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

import { isMonitorJob, progressStore } from 'stores';

import { raceDetailsService, distancesService, integrationsService, participantsService } from '../services';

import { raceDetailsStore, distancesStore, integrationExistStore, raceTypeStore } from '../stores';

export const mount = async () => {
  const { pathname } = history.location;
  const { params: { id } = { id: null } } = matchPath(pathname, { path: ROUTES.raceRoute }) || {};

  if (!id) {
    return;
  }

  progressStore.log(GET_DISTANCES, 'progress');
  const [raceDetails] = await Promise.all([
    raceDetailsService.loadRaceDetails(Number(id)),
    distancesService.loadDistances(Number(id)),
    integrationsService.getIntegrations(Number(id)),
    participantsService.getParticipantsCount(Number(id)),
  ]);
  progressStore.log(GET_DISTANCES, 'completed');

  if (isEmpty(raceDetails)) {
    history.replace(ROUTES.notFound);
    return;
  }

  raceDetailsStore.setRaceDetails(raceDetails);
};

export const unmount = async () => {
  raceDetailsStore.clearData();
  distancesStore.clearData();
  integrationExistStore.clear();
  isMonitorJob.off();
  raceTypeStore.clearAll();
};

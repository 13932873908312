import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACE_PARTICIPANTS_COUNT } from 'src/constants';
import { request, action } from 'src/utils';

import { GET_PARTICIPANTS_COUNT } from '../constants';

import { participantsCountStore } from '../stores';

class Participants {
  @request({ action: GET_PARTICIPANTS_COUNT })
  async getParticipantsCountRequest(id: number): Promise<any> {
    const url = generatePath(RACE_PARTICIPANTS_COUNT, { id });
    return axios.get(url);
  }

  @action({ action: GET_PARTICIPANTS_COUNT })
  async getParticipantsCount(raceId: number) {
    const [status, response] = await this.getParticipantsCountRequest(raceId);

    if (status) {
      participantsCountStore.set({
        count: response.data.data.count,
        maxCount: response.data.data.max_count,
      });
    }
  }
}

export const participantsService = new Participants();

import { MenuItem, TextField } from '@mui/material';
import React from 'react';

import { t } from 'utils';

import { FilterWrapper } from './FilterWrapper';

export const SelectColumnFilter = (props) => {
  const {
    column: { filterValue, setFilter, id, selectProps },
    closeFilter = () => {},
  } = props;
  const options = selectProps.options() || [];

  return (
    <FilterWrapper>
      <TextField
        autoFocus
        variant='outlined'
        size='small'
        fullWidth
        select
        name={id}
        label='Select'
        value={filterValue ?? 'All'}
        onChange={(e) => {
          closeFilter();
          if (e.target.value === 'All') {
            return setFilter(undefined);
          }
          setFilter(e.target.value);
        }}
      >
        <MenuItem key='All' value='All'>
          {t.staticAsString('coupons.allDistances')}
        </MenuItem>
        {options.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FilterWrapper>
  );
};

import { RACE_STATUSES_INT } from 'src/constants';

import { sessionStore } from 'stores';

import { raceDetailsService } from '../services';

import { raceDetailsStore } from '../stores';

export const approveRace = async () => {
  const race = raceDetailsStore.raceDetails?.value;

  if (!race) {
    return [];
  }

  const previousValue = race.approve_status;

  const params = {
    approve_status: RACE_STATUSES_INT.approve,
  };

  raceDetailsStore.partialRaceUpdate(params);
  const [status] = await raceDetailsService.approveRace();

  // correct server response
  if (status) {
    return;
  }

  // bad server response, roll back state
  raceDetailsStore.partialRaceUpdate({ show_startlist: previousValue });
};

export const awaitApproveRace = async () => {
  const race = raceDetailsStore.raceDetails?.value;

  if (!race) {
    return [];
  }

  const previousValue = race.approve_status;

  const params = {
    approve_status: RACE_STATUSES_INT.awaiting,
  };

  raceDetailsStore.partialRaceUpdate(params);
  const [status] = await raceDetailsService.awaitApproveRace();

  // correct server response
  if (status) {
    return;
  }

  // bad server response, roll back state
  raceDetailsStore.partialRaceUpdate({ show_startlist: previousValue });
};

export const rejectRace = async (reason: string) => {
  const race = raceDetailsStore.raceDetails?.value;

  if (!race) {
    return [];
  }

  const previousValue = race.approve_status;

  const params = {
    approve_status: RACE_STATUSES_INT.reject,
  };

  raceDetailsStore.partialRaceUpdate(params);
  const [status, response] = await raceDetailsService.rejectRace(reason);

  // correct server response
  if (status) {
    const rejectionParams = {
      reject_reason: {
        admin: {
          full_name: sessionStore.fullName,
        },
        reason,
        updated_at: response.updated_at,
      },
    };

    raceDetailsStore.partialRaceUpdate(rejectionParams);

    return;
  }

  // bad server response, roll back state
  raceDetailsStore.partialRaceUpdate({ show_startlist: previousValue });
};

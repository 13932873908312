import { CURRENCIES_URL, CURRENCY } from 'src/constants';

import { action, request } from 'utils';

import { currenciesStore, Currency as CurrencyStore } from 'stores';

import { LoadableService } from './loadable';

class LoadCurrencyService extends LoadableService<CurrencyType, FiltersType, CurrencyStore> {
  constructor(store: CurrencyStore) {
    super({ store, resourcesUrl: CURRENCIES_URL, resource: CURRENCY });
  }

  @request({ action: `LOAD_${CURRENCY}S` })
  async loadResourcesRequest(params: FiltersType): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @action({ action: `LOAD_${CURRENCY}S` })
  async loadResources(params: FiltersType = {}, pageNum: number = 1): Promise<any> {
    if (!this.store.values.length) {
      return super.loadResources(params, pageNum);
    }
  }
}

const loadCurrencyService = new LoadCurrencyService(currenciesStore);
export { loadCurrencyService, LoadCurrencyService };

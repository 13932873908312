import { debounce } from 'lodash';
import { Observer } from 'mobx-react';
import { LIVE_UPDATING } from 'modules/Races/constants';
import React, { ReactElement } from 'react';

import { getContactEmail } from 'src/modules/Races/actions';

import { ImageFieldWithCrop, InputWrapper, TextEditor, TextField } from 'components/Form';

import { t } from 'utils/t';

import { progressStore } from 'stores';

import { formStyles } from '../../styles';
import { StepTitle } from '../Shared/StepTitle';
import { StepProps } from '../sharedProps';

type Props = StepProps & {
  handleDeleteContactEmail?: (id: number) => void;
  handleDeleteImage?: () => void;
};

// Shared input option.
const view = { fullWidth: true, variant: 'outlined' };

function BasicInformation({ liveUpdate, step, handleDeleteImage, handleDeleteContactEmail }: Props): ReactElement {
  const classes = formStyles();
  const debouncedUpdate = debounce((key: string) => liveUpdate(key), 3000);

  React.useEffect(() => {
    getContactEmail();
  }, []);

  return (
    <Observer
      render={() => {
        return (
          <>
            <StepTitle step={step} />
            <div className={classes.wrapper}>
              <InputWrapper
                settings={{
                  additional: {
                    liveUpdate,
                    handleDeleteImage,
                  },
                  view,
                  label: 'Image',
                }}
                Component={ImageFieldWithCrop}
                name='image'
              />
            </div>
            <div className={classes.wrapperFullWidth}>
              <InputWrapper
                name='name'
                settings={{
                  additional: {
                    disabled: progressStore.isLoading(LIVE_UPDATING),
                    onBlur: () => liveUpdate('name'),
                  },
                  view,
                  required: true,
                  label: t('races.new.name'),
                }}
                Component={TextField}
              />
            </div>
            <div className={classes.wrapperFullWidth}>
              <InputWrapper
                name='description'
                settings={{
                  additional: {
                    onBlur: () => liveUpdate('description'),
                  },
                  view,
                  label: t('races.new.race_desc'),
                }}
                Component={TextEditor}
              />
            </div>
          </>
        );
      }}
    />
  );
}

export { BasicInformation };

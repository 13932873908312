import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';
import * as React from 'react';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    height: 48,

    borderColor: theme.palette.greyE5,
    borderBottomWidth: 1,
    borderStyle: 'solid',

    paddingBottom: 19,
    marginBottom: 16,

    opacity: 0.5,
  },
}))(MenuItem);

type Props = {
  label: string | React.ReactNode;
  onClick: () => void;
};

export class DefaultItem extends React.Component<Props> {
  render() {
    const { label, onClick } = this.props;

    return (
      <StyledMenuItem onClick={onClick}>
        <ListItemText primary={label} />
        <ArrowDropUpIcon onClick={onClick} />
      </StyledMenuItem>
    );
  }
}

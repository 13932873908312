import { PaymentStatus } from 'modules/Payments/constants';

function isReady(racer: RacerType): boolean {
  if (!racer) {
    return false;
  }

  if (!racer.order_id) {
    return false;
  }

  const isPaymentReady = racer.payment_status === PaymentStatus.paid;

  return !!isPaymentReady;
}

function isReversed(racer: RacerType): boolean {
  if (!racer) {
    return false;
  }

  if (!racer.order_id) {
    return false;
  }

  const isPaymentReversed = racer.payment_status === PaymentStatus.refunded;

  return !!isPaymentReversed;
}

function isAwaiting(racer: RacerType): boolean {
  if (!racer) {
    return false;
  }

  const isPushPayment = racer.payment_required;

  if (!isPushPayment) {
    return false;
  }

  return racer.status === 'payment_required';
}

function isEmpty(racer: RacerType): boolean {
  if (!racer) {
    return true;
  }

  if (!racer.order_id) {
    return true;
  }

  return false;
}

function formatStatus(racer: RacerType): string {
  if (isAwaiting(racer)) {
    return 'Awaiting';
  }

  if (isReady(racer)) {
    return 'Ready';
  }

  if (isReversed(racer)) {
    return 'Reversed';
  }

  return '';
}

const racerStatus = {
  isReady,
  isAwaiting,
  isEmpty,
  formatStatus,
};

export { racerStatus };

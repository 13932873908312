export const mapErrorsBE = (errors: ErrorType) => {
  return Object.keys(errors).reduce((prev, current) => {
    return {
      ...prev,
      ...checkToChange(current, errors[current]),
    };
  }, {});
};

const checkToChange = (name: string, value: string[] | Error) => {
  switch (name) {
    case 'date_from':
    case 'date_to':
      return { date_range: value };

    default:
      return { [name]: value };
  }
};

import React from 'react';
import shortid from 'shortid';

import { ROUTES } from 'src/constants';

import { NavToolbar } from 'components/NavToolbar';

import { t } from 'utils';

function Toolbar() {
  const items = [
    { key: shortid(), path: ROUTES.racesRoute, label: t.staticAsString('races.detail.nav.allRaces') as any },
    { key: shortid(), label: 'Add new race' },
  ];
  return (
    <div className='toolbar'>
      <NavToolbar items={items} />
    </div>
  );
}

export { Toolbar };

import { isNil, omit, pickBy } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';

import { helperDistancesStore } from 'stores';

import { registrationFieldsTools } from '../utils';

export class RaceNew implements MapperMiddleware<AnyObject, AnyObject> {
  mapTo(value: AnyObject): AnyObject {
    let copy = cloneDeep(value);

    copy = registrationFieldsTools.omitNotSelectedFieldsValues(helperDistancesStore?.selected?.registration_fields || [], value);
    copy.team_id = copy.team_id?.key;

    if (copy.wave_id) {
      copy.wave_id = Number(copy.wave_id);
    }

    if (copy.class_id) {
      copy.class_id = Number(copy.class_id?.key);
    }

    if (copy.discipline_id) {
      copy.discipline_id = Number(copy.discipline_id?.key);
    }

    if (copy.country_id) {
      copy.country_id = copy.country_id.value;
    }

    if (copy.nationality_id) {
      copy.nationality_id = copy.nationality_id.value || copy.nationality_id.value;
    }

    if (copy.squad_id) {
      copy.squad_id = copy.squad_id.key || copy.squad_id.value;
    }

    if (!!copy.telephone?.phone) {
      copy.telephone = copy.telephone.phone;
    } else {
      copy = omit(copy, 'telephone');
    }

    if (!!copy.emergency_phone?.phone) {
      copy.emergency_phone = copy.emergency_phone.phone;
    } else {
      copy = omit(copy, 'emergency_phone');
    }

    if (!copy.bib_number) {
      copy = omit(copy, ['bib_number']);
    } else {
      copy.bib_number = Number(copy.bib_number);
    }

    if (!!copy.birthday) {
      copy.birthday = copy.birthday.compose().val;
    }

    return pickBy(copy, (value, key) => !isNil(value) && value !== '');
  }
}

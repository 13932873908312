import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';

import { COMMISSIONS_URL, COMMISSION_URL, COMMISSION_BY_ORGANIZER, DEFAULT_COMMISSIONS_URL } from 'src/constants/api';
import { progressStore } from 'src/stores';

import { SystemUserCommissionsConstants } from '../constants/systemUserCommissions.constants';

import { SystemUserViewStore } from '../stores/systemUserView.store';

import { CommissionMapper } from '../mappers/systemUsersCommissions.mapper';
import { SystemUsersFilters, TCommission } from '../types';

export namespace SystemUserCommissionsService {
  export async function loadDefaultCommissions() {
    try {
      const response = await axios.get(DEFAULT_COMMISSIONS_URL);
      return response.data.data;
    } catch (error) {
      console.error('failed to load default commissions', error);
      throw error;
    }
  }

  export async function load(filter?: SystemUsersFilters) {
    progressStore.log(SystemUserCommissionsConstants.Table, 'progress');

    const organizerId = SystemUserViewStore.profile.value?.id;

    try {
      const response = await axios.get(generatePath(COMMISSION_BY_ORGANIZER, { id: organizerId }), {
        params: {
          ...filter,
          searchJoin: 'AND',
          per_page: 30,
        },
      });
      return {
        data: CommissionMapper.fromBackend(response.data.data),
        meta: response.data.meta,
      };
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(SystemUserCommissionsConstants.Table, 'completed');
    }
  }

  export async function create<T, R = AxiosResponse<T>>(value: AnyObject): Promise<R> {
    progressStore.log(SystemUserCommissionsConstants.Table, 'progress');
    try {
      const response = await axios.post<R>(generatePath(COMMISSIONS_URL), { ...value });

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(SystemUserCommissionsConstants.Table, 'completed');
    }
  }

  export async function patch<T, R = AxiosResponse<T>>(id: number, value: AnyObject): Promise<R> {
    progressStore.log(SystemUserCommissionsConstants.Table, 'progress');

    try {
      const response = await axios.patch<R>(generatePath(COMMISSION_URL, { id }), { ...value });

      return response.data;
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(SystemUserCommissionsConstants.Table, 'completed');
    }
  }
  export async function remove(id: number): Promise<any> {
    progressStore.log(SystemUserCommissionsConstants.Table, 'progress');
    try {
      return await axios.delete(generatePath(COMMISSION_URL, { id }));
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(SystemUserCommissionsConstants.Table, 'completed');
    }
  }
}

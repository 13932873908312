import classNames from 'classnames';
import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { DraggableProvided, DraggableStateSnapshot, DraggableProps } from 'react-beautiful-dnd';
import Truncate from 'react-truncate';

import { Icon } from 'components/Icon';

type HeaderOptions = {
  className?: string;
  title: string | React.ReactNode;
  controls: React.ReactNode;
};

type Props = {
  id: string;
  isDraggingClass?: string;
  isDragDisabled?: boolean;
  className?: string;
  headerOptions?: HeaderOptions;
  index: number;
  blocked?: boolean;
  children: React.ReactNode;
  onMouseDown?: Function;
};

class DNDItem extends React.Component<Props> {
  renderWrapper = () => (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
    const { children, isDraggingClass, className, headerOptions, onMouseDown } = this.props;
    const { innerRef, draggableProps, dragHandleProps } = provided;
    const { isDragging } = snapshot;

    const classes = classNames(className, {
      [isDraggingClass || '']: isDragging,
    });

    const handlers = !!headerOptions ? null : dragHandleProps;

    const headerHandlers = {
      ...dragHandleProps,
      onMouseDown: (e: React.MouseEvent) => {
        if (!!dragHandleProps) {
          // @ts-ignore
          dragHandleProps.onMouseDown(e);
        }

        if (!!onMouseDown) {
          onMouseDown();
        }
      },
    };

    return (
      <div className={classes} ref={innerRef} {...draggableProps} {...handlers} style={draggableProps.style}>
        {!!headerOptions && this.renderHeader(headerHandlers as any)}
        {children}
      </div>
    );
  };

  renderHeader = (handleProps?: DraggableProps | null): React.ReactNode => {
    const { className, title, controls } = this.props.headerOptions || {};

    const classes = classNames('dnd-header', className);

    return (
      <div className={classes}>
        <div className='drag-icon' {...handleProps}>
          <Icon value='dragable-icon' />
        </div>
        <div className='title' {...handleProps}>
          <Truncate width={250}>{title}</Truncate>
        </div>
        <div className='controls-wrap'>{controls}</div>
      </div>
    );
  };

  render() {
    const { id, index, blocked, children, isDragDisabled } = this.props;

    if (blocked) {
      return <div>{children}</div>;
    }

    return (
      <Draggable key={id} draggableId={id} index={index} isDragDisabled={isDragDisabled}>
        {this.renderWrapper()}
      </Draggable>
    );
  }
}

export { DNDItem };

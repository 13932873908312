// Racers
export type RacersCols =
  | 'id'
  | 'distance.race_parent_id'
  | 'distance.name'
  | 'distance.race.name'
  | 'race_name'
  | 'distance_name'
  | 'distance_id'
  | 'email'
  | 'firstname'
  | 'lastname'
  | 'gender'
  | 'telephone'
  | 'country_id'
  | 'city'
  | 'address'
  | 'post_code'
  | 'union'
  | 'birthday'
  | 'team_name'
  | 'status';

export const racersFields = {
  'distance.race_parent_id': 'distance.race_parent_id',
  'distance.name': 'distance.name',
  'distance.race.name': 'distance.race.name',
  distance_id: 'distance_id',
  distance: 'distance',
  race_name: 'race_name',
  distance_name: 'distance_name',
  email: 'email',
  firstname: 'firstname',
  lastname: 'lastname',
  gender: 'gender',
  telephone: 'telephone',
  country_id: 'country_id',
  city: 'city',
  address: 'address',
  post_code: 'post_code',
  union: 'union',
  birthday: 'birthday',
  team_name: 'team_name',
  status: 'status',
};

const MAIN_COLUMNS = [
  racersFields['distance.race_parent_id'],
  racersFields['distance_id'],
  racersFields.email,
  racersFields.firstname,
  racersFields.lastname,
  racersFields.gender,
  racersFields.telephone,
  racersFields.country_id,
  racersFields.city,
  racersFields.address,
  racersFields.post_code,
  racersFields.union,
  racersFields.birthday,
  racersFields.team_name,
  racersFields.status,
];

const COLUMN_WIDTH: {
  [K in string]: number;
} = {};

const RACERS_COLUMNS = {
  MAIN_COLUMNS,
  COLUMN_WIDTH,
};

export { RACERS_COLUMNS };

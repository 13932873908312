import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACE_URL, RACES_URL } from 'src/constants';

import { action, request } from 'utils';

import { DASHBOARD_STATES } from '../constants/dashboard.constants';

import { mergeRaceForm } from '../utils/merge-race-form.utils';

import { dashboardRacesStore } from '../stores/races.store';

class DashboardRaceService {
  @request({ action: DASHBOARD_STATES.RACE_LOADING })
  async loadRaceRequest(id: number): Promise<any> {
    const url = generatePath(RACE_URL, { id });
    return axios.get(url, { params: { with: 'distances' } });
  }

  @action({ action: DASHBOARD_STATES.RACE_LOADING, minRequestTime: 500 })
  async loadRace(id: number): Promise<void> {
    const [isOk, response] = await this.loadRaceRequest(id);
    if (isOk) {
      dashboardRacesStore.selectRace(response.data.data);
    }
  }

  @request({ action: DASHBOARD_STATES.RACE_LOADING })
  async loadRacesRequest(): Promise<any> {
    const url = generatePath(RACES_URL);
    const params = dashboardRacesStore.params;
    return axios.get(url, { params });
  }

  @action({ action: DASHBOARD_STATES.RACE_LOADING, minRequestTime: 500 })
  async loadRaces(shouldSelectFirst?: boolean, page?: number): Promise<void> {
    const [isOk, response] = await this.loadRacesRequest();

    if (isOk) {
      dashboardRacesStore.setRaces(response.data.data);
      dashboardRacesStore.setHasMore(response.data.meta.pagination.total_pages > dashboardRacesStore.params.page);
      dashboardRacesStore.setParams({ page: page ?? dashboardRacesStore.params.page + 1 });
    }

    if (shouldSelectFirst) {
      const race = response.data.data[0];
      dashboardRacesStore.selectRace(race);
      mergeRaceForm();
    }
  }
}

export const dashboardRaceService = new DashboardRaceService();

import React from 'react';
import shortid from 'shortid';

import { ROUTES } from 'src/constants';

import { NavToolbar } from 'components/NavToolbar';

import { t } from 'utils';

function Toolbar({
  items,
}: {
  items: Array<{
    path?: string;
    label: string;
  }>;
}) {
  const breadcrumbs = [
    { key: shortid(), path: ROUTES.racesRoute, label: t.staticAsString('races.detail.nav.allRaces') as any },
    ...items.map((item) => ({ ...item, key: shortid() })),
  ];
  return (
    <div className='toolbar'>
      <NavToolbar items={breadcrumbs} />
    </div>
  );
}

export { Toolbar };

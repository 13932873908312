import { debounce } from 'lodash';

import { mount, unmount } from 'src/modules/Distances/components/shared/Steps/GPX/actions/lifecycle';
import { gpxService } from 'src/modules/Distances/components/shared/Steps/GPX/services';
import { getDistanceId } from 'src/modules/Distances/utils';

export const deleteGPX = async () => {
  const distanceId = getDistanceId() || 0;
  gpxService.deleteGPX(distanceId);

  unmount();
  pauseBeforeMount();
};

const pauseBeforeMount = debounce(() => mount(), 1000);

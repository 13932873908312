import { ArrowBack } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { debounce, get, isEmpty, omitBy } from 'lodash';
import * as React from 'react';

import { Show } from 'components/Condition';
import { Form } from 'components/Form';

import { parseHtml, t } from 'utils';

import { form } from 'stores';

import { flattenObjectTranslationsByNames, getGoogleTranslations, isLocaleFinished } from '../utils';

import { GoogleTranslateButton } from '../GoogleTranslate';
import { useStyles as useButtonStyles } from '../ModeChange/styles';
import { ScrollableTab } from '../ScrollableTab';
import { CustomFields, HTMLEditor, SingleFields } from './components';
import { useStyles } from './styles';

type Props = {
  translations: FilteredTranslations;
  locales: availableLocales[];
  defaultLocale: availableLocales;
  path: TranslationPath;
  onUpdate: Function;
  goBack: Function;
};

type TypingState = { isTyping: boolean; data: { value: string; name: string } };

const FORM_ID = 'abstract-translations-form';
let debouncedUpdate: any;

export const AbstractTranslation = ({ path, translations, locales, defaultLocale, onUpdate, goBack }: Props) => {
  const localesToSelect = locales.filter((l) => l !== defaultLocale);

  // State handler for tabs scroll component, locale select
  const [selectedLocale, setLocale] = React.useState(0);

  // State handler for prefilled values when hovering GoogleTranslate button
  const [prefilled, setPrefilled] = React.useState({});

  // State handler for google translations.
  // Those values will be used as prefilled when hovering GoogleTranslate button
  // Will be set as values when clicking GoogleTranslate button
  const [googleTranslations, setGoogleTranslations] = React.useState({});

  // State handler for fields saved state (green checkmark)
  const [saved, setSaved] = React.useState({});

  // State handler to keep track whenever user is typing or not and which field. Or blurs field.
  const initState: TypingState = {
    isTyping: false,
    data: { name: '', value: '' },
  };
  const [typing, setTyping] = React.useState(initState);

  const saveTranslation = (name: string) => {
    setSaved({ ...saved, [name]: true });
  };
  const resetSaveTranslation = (name: string) => {
    setSaved({ ...saved, [name]: false });
  };

  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const isCustomField = path[0] === 'customFields';
  const isHTMLField = path[1] === 'description' || path[1] === 'content';

  let flatFormNames = flattenObjectTranslationsByNames(defaultLocale, path, translations);

  const onChangeLocale = (event_, newValue: number) => {
    setLocale(newValue);
  };

  const textToTranslate = (): { text: string; isTooMuch: boolean } => {
    const entries = Object.entries(flatFormNames[defaultLocale] || {});

    const q = entries.map(([, value]) => value);

    const text = parseHtml(q[0]) || '';
    return { text, isTooMuch: text.length > 300 };
  };

  const [loadingGoogle, setLoadingGoogle] = React.useState(false);

  const onGoogleMouseEnter = async () => {
    const { isTooMuch } = textToTranslate();

    if (isTooMuch) return;

    // Do not perform additional translations requests, if already translated (simple caching)
    if (isEmpty(googleTranslations) || !Object.keys(googleTranslations).some((key) => isCurrentLocaleKey(key))) {
      setLoadingGoogle(true);
      const result = await getGoogleTranslations(defaultLocale, localesToSelect[selectedLocale], flatFormNames[defaultLocale]);
      // Cache google translations to the state
      setGoogleTranslations({ ...result, ...googleTranslations });
      setPrefilled(result);
      setLoadingGoogle(false);
      return;
    }

    // Use cached google translation
    setPrefilled(googleTranslations);
  };

  const onGoogleMouseLeave = async () => {
    setPrefilled({});
  };

  const isCurrentLocaleKey = (key) => {
    return key.indexOf(localesToSelect[selectedLocale]) === 0;
  };

  const openGoogleTranslatePage = () => {
    const { text } = textToTranslate();
    const newWindow = window.open(
      `https://translate.google.com/?sl=${defaultLocale}&tl=${localesToSelect[selectedLocale]}&text=${text}&op=translate`,
      '_blank',
      'noopener,noreferrer',
    );
    if (newWindow) newWindow.opener = null;
  };

  const onGoogleTranslateClick = () => {
    const { isTooMuch } = textToTranslate();

    if (isTooMuch) {
      openGoogleTranslatePage();

      return;
    }

    const formData = form.fetch(FORM_ID);
    const toSave = {};
    const toBackend: { [k: string]: string }[] = [];
    Object.keys(googleTranslations).forEach((name) => {
      if (!isCurrentLocaleKey(name)) {
        return;
      }

      if (!get(formData, name)) {
        const value = googleTranslations[name];
        form.onChange(FORM_ID, name, value);
        toSave[name] = true;
        toBackend.push({ name, value });
      }
    });

    setSaved({ ...saved, ...toSave });
    onUpdate(toBackend);
  };

  const onTranslationChange = ({ name, value, blur }: { name: string | string[]; value: string; blur: boolean }): void => {
    // Text editor generates array instead of string?
    if (Array.isArray(name)) {
      name = name.join('.');
    }
    // Main input handler:
    // 1. Update form storage
    // 2. Send update info to parent component (for future use)
    // 3. Turn of saved state (green checkmark).
    // 4. Save when user end's typing (1.5s ?) or on blur event.

    // On blur logic:
    // Emit change only if blur detected earlier then typing delay
    // Simple blur when user just clicking fields should not emit changes
    // Change event happened when typing.isTyping value changed to false
    if (!name) return;

    if (blur && typing.isTyping) {
      // Immediate change
      // Cancel debounced update if present
      resetSaveTranslation(name);
      setTyping({ isTyping: false, data: { name, value } });
      debouncedUpdate.cancel();
    }

    if (!blur) {
      // Wait for user input, then update store and save on backend
      resetSaveTranslation(name);
      setTyping({ isTyping: true, data: { name, value } });
      debouncedUpdate({ name, value });
    }
  };

  const onBackClick = () => {
    goBack();
  };

  React.useEffect(() => {
    const { name, value } = typing.data;
    if (!typing.isTyping && name && value != null && value !== undefined) {
      onUpdate([typing.data]);
      if (value !== '') {
        saveTranslation(typing.data.name);
      }
    }
  }, [typing]);

  React.useEffect(() => {
    // Update saved state (green checkmarks)

    const initSaved = Object.keys(omitBy(flatFormNames.other, (el) => !el)).reduce((acc, key) => {
      acc[key] = true;

      return acc;
    }, {});
    setSaved({ ...initSaved, ...saved });
  }, [path, selectedLocale]);

  React.useEffect(() => {
    // Register debounced function once, on init rendering
    debouncedUpdate = debounce((data) => {
      setTyping({ isTyping: false, data });
    }, 500);
  }, []);

  React.useEffect(() => {
    form.merge(FORM_ID, translations);
  }, [path]);

  if (!path.length) {
    return null;
  }

  const isLocaleTranslated = () => {
    return isLocaleFinished(saved, path, translations[defaultLocale]?.customFields)(locales[selectedLocale]);
    return Object.keys(saved).some((key) => isCurrentLocaleKey(key) && saved[key] === true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerButtonWrap}>
          <IconButton className={buttonClasses.globalButton} aria-label='back' onClick={onBackClick} size='large'>
            <ArrowBack className={buttonClasses.globalIcon} />
          </IconButton>
        </div>

        <div className={classes.headerTextWrap}>
          <Typography className={classes.headerText}>{t('translations.translationMode')}</Typography>
        </div>
      </div>

      <GoogleTranslateButton
        loading={loadingGoogle}
        disabled={isLocaleTranslated()}
        onClick={onGoogleTranslateClick}
        onMouseEnter={onGoogleMouseEnter}
        onMouseLeave={onGoogleMouseLeave}
        tooltip={textToTranslate().isTooMuch ? t.staticAsString('translations.tooMuchText') : ''}
      />

      <ScrollableTab
        locales={localesToSelect}
        isFinished={isLocaleFinished(saved, path, translations[defaultLocale]?.customFields)}
        onChange={onChangeLocale}
        value={selectedLocale}
      />
      <Form id={FORM_ID} disableAutofill withScroll>
        <Show if={isHTMLField}>
          <HTMLEditor
            prefilled={prefilled}
            path={path}
            savedFields={saved}
            localesToSelect={localesToSelect}
            defaultLocale={defaultLocale}
            labels={translations[defaultLocale]}
            selectedLocale={localesToSelect[selectedLocale]}
            onTranslationChange={onTranslationChange}
          />
        </Show>
        <Show if={isCustomField}>
          <CustomFields
            prefilled={prefilled}
            savedFields={saved}
            localesToSelect={localesToSelect}
            labels={translations[defaultLocale]}
            selectedLocale={localesToSelect[selectedLocale]}
            onTranslationChange={onTranslationChange}
          />
        </Show>

        <Show if={!isCustomField && !isHTMLField}>
          <SingleFields
            prefilled={prefilled}
            savedFields={saved}
            path={path}
            localesToSelect={localesToSelect}
            labels={translations[defaultLocale]}
            selectedLocale={localesToSelect[selectedLocale]}
            onTranslationChange={onTranslationChange}
          />
        </Show>
      </Form>
    </div>
  );
};

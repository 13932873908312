import { Distance } from 'models';

export const mapDistancesToCouponForm = (data: DistanceType[]): SelectOption[] =>
  data.map((item) => {
    const model = new Distance(item as any);
    return {
      key: item.id,
      value: item.id,
      label: model.name(),
    };
  });

import { helperOrganizersLoadService } from 'services';

import { sessionStore } from 'stores';

async function loadOrganizers() {
  if (sessionStore.isOrganizer) {
    return;
  }

  await helperOrganizersLoadService.loadResources();
}

export { loadOrganizers };

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles, withStyles } from '@mui/styles';
import * as React from 'react';

import { Icon } from 'components/Icon';

import { stringFormatter } from 'utils';

type Props = FieldBaseProps &
  AdditionalMaterialProps & {
    disabled?: boolean;
    tooltipText?: string;
  };

const FormControlLabelStyled = withStyles({
  label: {
    fontSize: '14px',
  },
})(FormControlLabel);

const useStyles = makeStyles(() => ({
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 5,
    marginLeft: 8,
  },
  icon: {
    '& div': {
      display: 'flex',
    },
  },
}));

export const WrappedCheckbox = (props: Props) => {
  const { name, value, disabled, onChange, label, error, tooltipText } = props;
  const classes = useStyles();
  return (
    <FormControl component='fieldset'>
      <FormControlLabelStyled
        disabled={disabled}
        control={
          <Checkbox
            checked={!!value}
            color='primary'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onChange({ name, value: e.target.checked });
              if (props.onAfterChange) {
                props.onAfterChange({ name, value: e.target.checked });
              }
            }}
            name={name}
          />
        }
        label={
          <Box display='flex' alignItems='center'>
            <span className='checkbox-label'>{label}</span>
            {tooltipText && (
              <Tooltip title={stringFormatter.withNewLine(tooltipText)} placement='right' className={classes.tooltip}>
                <span className='tooltip-icon'>
                  <Icon value='info' className={classes.icon} />
                </span>
              </Tooltip>
            )}
          </Box>
        }
      />
      <FormHelperText>
        <>{error}</>
      </FormHelperText>
    </FormControl>
  );
};

import { timingAssistants } from 'modules/Distances/stores';

export const populateCheckpointAssistants = (checkpoint: CheckpointType): CheckpointType => {
  let { assistants = [] } = checkpoint;

  checkpoint.assistants = assistants.map((assistant) => {
    if (checkpoint.id) {
      // TODO TS, missing timing field
      // @ts-ignore
      const id = assistant.organizer_id || assistant.id;
      const assistantData = timingAssistants.values.find((el) => el.id === id);

      return {
        ...assistantData,
        ...assistant,
        id,
      };
    }

    return assistant;
  });

  return checkpoint;
};

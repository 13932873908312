import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { SynchronizationsConstants } from 'modules/Synchronisation/Table/constants/synchronizations.constants';
import { SynchronizationTableFilters } from 'modules/Synchronisation/Table/types/filters.type';
import * as React from 'react';

import { LabelForFilter } from 'components/Filter/ChipFilterLabel';
import { ClearAllChipLabel } from 'components/Filter/ChipFilterLabel/ClearAll';
import { timeRange } from 'components/TimeRange';

import { t } from 'utils';

type Props = {
  filters: SynchronizationTableFilters[];
  setAllFilters: Function;
};

const FilterLabelToolbar: React.FC<Props> = observer(({ setAllFilters, filters }) => {
  const clearFilters = (): void => {
    setAllFilters([]);
  };

  const deleteFilter = (id: string) => {
    setAllFilters(filters.filter((item) => item.id !== id));
  };

  const processFilterValue = (name: string, value: any): any => {
    switch (name) {
      case 'created_at':
        if (!value) return null;
        return timeRange(value.startDate, value.endDate, 'datetime', 'shortDate');
      case 'is_enabled':
        return value ? t.staticAsString('categories.active') : t.staticAsString('categories.inactive');
      case 'type':
        return SynchronizationsConstants.SynchronizationType[value];
      default:
        return value;
    }
  };

  const renderLabels = (): Array<React.ReactNode> => {
    return filters.map(({ id, value }) => {
      const processedValue = processFilterValue(id, value);

      return <LabelForFilter onClear={deleteFilter} key={id} name={id} className='filter-item' value={processedValue} />;
    });
  };

  const renderClearAll = (): React.ReactNode => {
    return <ClearAllChipLabel onClear={clearFilters} key={'clear_all'} values={filters} className='filter-item' />;
  };

  return <div className={classNames('filters-toolbar', { empty: isEmpty(filters) })}>{[...renderLabels(), renderClearAll()]}</div>;
});

export { FilterLabelToolbar };

import React from 'react';

import { FilterAutocompleteWithSearch } from 'components/Filter/DefaultFilters';

import { t } from 'utils';

import { FilterProps } from './index';

type Props = {
  value: nil | string | number;
} & FilterProps;

const Statuses = (props: Props) => {
  const options = [
    {
      value: '1',
      label: t.staticAsString('categories.statusState.1'),
      key: 1,
    },
    {
      value: '0',
      label: t.staticAsString('categories.statusState.0'),
      key: 2,
    },
  ];
  return <FilterAutocompleteWithSearch {...(props as any)} options={options} />;
};

export { Statuses };

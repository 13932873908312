import { generatePath } from 'react-router-dom';

import { REPORTS_URL, ROUTES } from 'src/constants';
import { download } from 'src/utils';

import { exportJobService } from 'services';

import { hostsStore, isMonitorJob, loadProgress } from 'stores';

const checkInterval = 1000; // 1sec

export type JobStatus = 'failed' | 'queued' | 'executing' | 'finished';

export const monitorLoadProgressByJobId = (id: number, isReport = false): Promise<any> => {
  return new Promise((resolve, reject) => {
    const timerId = setInterval(async () => {
      const currentJob = loadProgress.loadingJobs[id];
      if (currentJob.isCancelled) {
        clearInterval(timerId);
        resolve([false, 'cancelled']);

        return;
      }
      const [status, response] = await exportJobService.checkJobId(id);

      // User can cancel request meanwhile awaiting response
      // Lets verify if status wasn't changed to cancelled
      if (currentJob.isCancelled) {
        clearInterval(timerId);
        resolve([false, 'cancelled']);

        return;
      }

      if (!status) {
        loadProgress.connectionInterrupt(id);
        clearInterval(timerId);
        resolve([false, response]);

        return;
      }

      const loadStatus: JobStatus = response?.data?.status;

      if (loadStatus === 'executing') {
        const progressDelta = checkInterval / 10 / loadProgress.loadingJobs[id].estimateSec;

        loadProgress.updateLoadingProgress(id, progressDelta);
      }

      if (loadStatus === 'failed') {
        loadProgress.errorLoading(id);
        clearInterval(timerId);
        resolve([false, response]);
      }

      if (loadStatus === 'finished') {
        loadProgress.finishLoadingProgress(id);
        clearInterval(timerId);

        if (isReport) {
          return resolve([true, response]);
        }

        // There is no url in response for import requests
        const uri = response.data?.data?.url;
        const url = uri && uri.includes('http') ? uri : hostsStore.admin + uri;

        resolve([true, url]);
      }
    }, checkInterval);
  });
};

export const monitorJobId = (id: number, onLoading?: () => void): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (!id) {
      resolve([false, null]);
      return;
    }
    isMonitorJob.on();
    const timerId = setInterval(async () => {
      const stopJobChecking = () => {
        clearInterval(timerId);
        isMonitorJob.off();
      };
      if (!isMonitorJob.value) {
        stopJobChecking();
        resolve([false, 'cancelled']);
        return;
      }

      const [status, response] = await exportJobService.checkJobId(id);

      if (!status) {
        stopJobChecking();
        resolve([false, response]);
        return;
      }

      const jobStatus: JobStatus = response?.data?.status;

      if (['queued', 'executing'].includes(jobStatus)) {
        onLoading && onLoading();
      }

      if (jobStatus === 'failed') {
        stopJobChecking();
        resolve([false, response]);
      }

      if (jobStatus === 'finished') {
        stopJobChecking();
        resolve([true, response]);
      }
    }, checkInterval);
  });
};

type ExportProps = {
  id: number;
  estimatedLoadingTime: number;
  fileName: string;
  onRetry: Function;
};

export const handleExport = async ({ id, estimatedLoadingTime, fileName, onRetry }: ExportProps): Promise<void> => {
  loadProgress.addLoadingJob(id, estimatedLoadingTime, {
    fileName,
    onRetry,
  });
  const [monitorStatus, url] = await monitorLoadProgressByJobId(id);

  if (monitorStatus) {
    download(`${url}`, fileName);
  }
};

export const handleExportReport = async ({ id, estimatedLoadingTime, fileName, onRetry }: ExportProps): Promise<void> => {
  loadProgress.addLoadingJob(id, estimatedLoadingTime, {
    fileName,
    onRetry,
  });
  const [monitorStatus, response] = await monitorLoadProgressByJobId(id, true);
  if (monitorStatus) {
    const url = generatePath(ROUTES.reportRoute, { reportId: response.data.data.report_id });
    window.open(url, '_blank');
  }
};

export const handleExportServiceFeeReport = async ({ id, estimatedLoadingTime, fileName, onRetry }: ExportProps): Promise<void> => {
  loadProgress.addLoadingJob(id, estimatedLoadingTime, {
    fileName,
    onRetry,
  });
  const [monitorStatus, response] = await monitorLoadProgressByJobId(id, true);
  if (monitorStatus) {
    download(`${response.data.data.url}`, fileName);
  }
};

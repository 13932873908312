import classNames from 'classnames';
import * as React from 'react';

import { Icon } from 'components/Icon';

import { SportType } from 'models';

type Props = {
  value: SportType;
  onClick: (id: number) => void;
  selectedId: number | null;
  onDelete: (id: number) => void;
};

let Item: any = (props: Props) => {
  const { value, onClick, selectedId, onDelete } = props;

  const { id } = value.value;

  const _onClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onClick(id);
  };

  const _onDelete = (e: React.SyntheticEvent) => {
    onDelete(id);
  };

  return (
    <li className={classNames('item', { active: id === selectedId })} onClick={_onClick}>
      {value.title()}
      <Icon value='delete-active' className='delete-icon' onClick={_onDelete} />
    </li>
  );
};

export { Item };

import { observer } from 'mobx-react';
import * as React from 'react';

import { SelectFilterWithSearch } from 'components/Filter/DefaultFilters';

import { helperDistancesStore } from 'stores';

type Props = {} & FilterProps;

@observer
class ClassIdComponent extends React.Component<Props> {
  options = () => {
    const distanceModel = helperDistancesStore.modelSelected;

    if (!distanceModel) {
      return [];
    }

    return distanceModel.relationForSelect('classes');
  };

  render() {
    const { props } = this;
    return <SelectFilterWithSearch {...props} options={this.options()} />;
  }
}

export const classId: FilterListDescriptor = {
  filter: {
    persistance: {
      handler: '=',

      calcValue: function (name, value) {
        return value;
      },

      prettify: function (name, value) {
        const distance = helperDistancesStore.modelSelected;

        if (!distance) {
          return null;
        }

        const classModel = distance.findRelationModelValue('classes', +value);
        return classModel && classModel.label();
      },
    },
  },
  frontend: {
    type: 'custom',
    component: ClassIdComponent,
  },
};

import { localeStore } from 'stores';

class Wave {
  value: WaveType;

  constructor(value: WaveType) {
    this.value = value;
  }

  forSelect(): SelectOption {
    const id = `${this.value.id || ''}`;

    return {
      label: this.value.name,
      value: id,
      key: id,
    };
  }

  label(): string {
    return this.value.name;
  }
}

export { Wave };

/*
 * Google tag manager
 */
import TagManager from 'react-gtm-module';

import { GTM_ID } from 'src/constants/env';

import { appEnvControl } from '../appEnvironment';

const initArgs = {
  gtmId: GTM_ID,
};

function init() {
  if (!appEnvControl.isEnv('production')) {
    return;
  }

  TagManager.initialize(initArgs);
}

export const gtm = {
  init,
};

export const getMappingValidation = (): AnyObject => {
  const presence = {
    allowEmpty: false,
    message: '^validation.required',
  };

  const validation = {
    _validation: {
      internal_id: {
        presence,
      },
      external_id: {
        presence,
      },
    },
  };

  return {
    distances: validation,
    classes: validation,
    waves: validation,
    custom_fields: validation,
  };
};

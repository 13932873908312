import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import classNames from 'classnames';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { CONFIRM_POPUP_TYPES } from 'src/constants';
import { t } from 'src/utils';

import { sessionStore, confirmationModalStore } from 'stores';

import { ConfirmationField } from './ConfirmationField';

const classes = {
  popup: {
    title: ['popup-title'],
    body: ['popup-body'],
    text: ['popup-text'],
  },
};

const DialogControls = ({ isShown, children }: { isShown: boolean; children: React.ReactNode }) => {
  if (!isShown) {
    return null;
  }

  return <DialogActions>{children}</DialogActions>;
};

const GlobalConfirmationModal = () => {
  return (
    <Observer>
      {() => {
        const currentColorOverlay = sessionStore.isLogged ? 'rgba(0, 0, 0, 0.5)' : 'rgba(101, 107, 111, .6)';

        const {
          handleClose,
          handleConfirm,
          props: { isOpen, title, body, btnLabel, type, confirmationValue },
        } = confirmationModalStore;
        if (!isOpen) return null;

        return (
          <Dialog
            maxWidth='sm'
            open={isOpen}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            onClose={handleClose}
            BackdropProps={{
              style: {
                backgroundColor: currentColorOverlay,
              },
            }}
          >
            <DialogTitle id='alert-dialog-title' className={classNames(...classes.popup.title)}>
              {title}
            </DialogTitle>

            <DialogContent className={classNames(...classes.popup.body)}>
              <DialogContentText id='alert-dialog-description'>{body}</DialogContentText>
              <ConfirmationField show={Boolean(confirmationValue)} />
            </DialogContent>

            <DialogControls isShown={CONFIRM_POPUP_TYPES.confirm === type}>
              {btnLabel?.cancel && <Button onClick={handleClose}>{t(btnLabel?.cancel as TranslationLockedKeys)}</Button>}
              {btnLabel?.confirm && (
                <Button onClick={handleConfirm} color='primary' autoFocus>
                  {t(btnLabel?.confirm as TranslationLockedKeys)}
                </Button>
              )}
            </DialogControls>

            <DialogControls isShown={CONFIRM_POPUP_TYPES.delete === type}>
              {btnLabel?.cancel && <Button onClick={handleClose}>{t(btnLabel?.cancel as TranslationLockedKeys)}</Button>}
              {btnLabel?.confirm && (
                <Button
                  sx={{ backgroundColor: 'red', color: 'white', ':hover': { backgroundColor: 'red', color: 'white' } }}
                  onClick={handleConfirm}
                  color='warning'
                  autoFocus
                >
                  {t(btnLabel?.confirm as TranslationLockedKeys)}
                </Button>
              )}
            </DialogControls>

            <DialogControls isShown={CONFIRM_POPUP_TYPES.info === type}>
              {btnLabel?.ok && (
                <Button onClick={handleConfirm} color='inherit' autoFocus>
                  {t(btnLabel?.ok as TranslationLockedKeys)}
                </Button>
              )}
            </DialogControls>
          </Dialog>
        );
      }}
    </Observer>
  );
};

export default GlobalConfirmationModal;

import { get } from 'lodash';
import { observer } from 'mobx-react';
import { Tooltip } from 'modules/Distances/components/shared/Fields/EmailContent/Tooltip';
import { getDistanceErrors } from 'modules/Distances/utils/errors';
import * as React from 'react';

import { DISTANCE_STEPS, PERSIST_DISTANCE as action } from 'src/constants';

import { TextEditor } from 'components/Form/Fields';

import { t } from 'utils';

import { Race as RaceModel } from 'models';

import { State as OriginalFormState } from '../../../../shared/stateHelpers';

type Props = {
  formData: OriginalFormState | any;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
  ) => void;
  helperData: {
    race: nil | RaceModel;
  };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

const FIELDS = {
  allow_no_profile_registration: 'allow_no_profile_registration',
  start_type: 'start_type',
  distance_mode: 'distance_mode',
};

const stepAction = `${action}_${DISTANCE_STEPS.confirmationEmail}`;

@observer
class ConfirmationEmailStep extends React.Component<Props> {
  tooltipRef = React.createRef<HTMLDivElement>();

  onChangeWithoutEvent = ({ name, value }: { name: string; value: any }) => {
    this.onChange({ name, value });
  };

  onChange = ({ name, value }: { name: string; value: any }, callback: Function = () => {}) => {
    const formattedValue = this._formatInput(name, value);
    const { formData, onChange, liveUpdate } = this.props;
    const distance = formData.distance;

    liveUpdate({ [name]: formattedValue }, true);
    onChange({ ...distance, [name]: formattedValue }, 'distance', () => {
      callback();
    });
  };

  defaultInputProps = (name: string) => {
    const { formData } = this.props;
    const value = get(formData.distance, name);
    const errors = getDistanceErrors(name);

    return {
      value: value,
      name,
      errors: errors,
      fullWidth: true,
    };
  };

  _formatInput = (name: string, value: any) => {
    if (!Boolean(value)) {
      return null;
    }

    if (name === FIELDS.allow_no_profile_registration) {
      return parseInt(value, 10);
    }

    return value;
  };

  render() {
    return (
      <div className='form-content-wrapper'>
        <TextEditor
          {...this.defaultInputProps('email_content.content')}
          className='tinymce-6-lines'
          label={t.staticAsString('distances.steps.baseStepForm.content')}
          additional={{
            placeholder: t.staticAsString('distances.steps.baseStepForm.placeholder'),
          }}
          onChange={this.onChangeWithoutEvent}
        />
        <div ref={this.tooltipRef as React.RefObject<HTMLDivElement>}>
          <Tooltip message={t.staticAsString('distances.steps.baseStepForm.tooltip.email_content')} />
        </div>
        <div className='separator' />
        {this.props.controls}
      </div>
    );
  }
}

export { ConfirmationEmailStep };

import * as React from 'react';

import { Show } from 'components/Condition';

import { time } from 'utils';

type Props = {
  from?: nil | string;
  to?: nil | string;
  parseFormat: BEDateFormat;
  format: CommonDateFormats;
};

function TimeRange(props: Props) {
  const { parseFormat, format } = props;

  const from = props.from && time(props.from, parseFormat).parse();
  const to = props.to && time(props.to, parseFormat).parse();

  let isOneDay = false;

  if (from && to) {
    isOneDay = from.val.isSame(to.val, 'day');
  }

  const formattedFrom: AnyFunction | any = (from && from.format(format)) || '';

  const formattedTo: AnyFunction | any = (to && to.format(format)) || '';

  /* eslint-disable */
  if (isOneDay) {
    return <>{formattedFrom}</>;
  }

  return (
    <>
      <Show if={!!from && !to}>
        <React.Fragment>{formattedFrom} -</React.Fragment>
      </Show>
      <Show if={!!to && !from}>
        <React.Fragment>- {formattedTo}</React.Fragment>
      </Show>
      <Show if={!!to && !!from}>
        <React.Fragment>
          {formattedFrom} - {formattedTo}
        </React.Fragment>
      </Show>
    </>
  );
}

TimeRange.defaultProps = {
  format: 'shortDate',
};

type RenderProps = {
  from?: nil | string;
  to?: nil | string;
  format?: CommonDateFormats;
};

type RenderArgs = [nil | string, nil | string, BEDateFormat, CommonDateFormats];

function DateRange(props: RenderProps) {
  return <TimeRange {...props} parseFormat='date' />;
}

function DatetimeRange(props: RenderProps) {
  return <TimeRange {...props} parseFormat='datetime' />;
}

function DatetimemsRange(props: RenderProps) {
  return <TimeRange {...props} parseFormat='datetimems' />;
}

function timeRange(...args: RenderArgs) {
  const [from, to, beFormat, format] = args;

  if (!from && !to) {
    return null;
  }

  return <TimeRange from={from} to={to} parseFormat={beFormat} format={format} />;
}

export { TimeRange, timeRange, DateRange, DatetimeRange, DatetimemsRange };

import * as React from 'react';

import { withoutSidebar, withoutFooter } from 'hocs';

import { t } from 'utils';

type Props = {};

@withoutSidebar
@withoutFooter
export class FrontErrorPage extends React.PureComponent<Props> {
  render() {
    return (
      <div className='error-page-container'>
        <div className='info-wrap'>
          <div className='number'>{t.staticAsString('errorPage.header')}</div>
          <div className='title'>{t.staticAsString('errorPage.title')}</div>
          <div className='text'>
            {t.staticAsString('errorPage.text')}
            <a href='mailto:support@raceid.com'> {t.staticAsString('errorPage.contactUs')}</a>
          </div>
        </div>
      </div>
    );
  }
}

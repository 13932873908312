import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Show } from 'components/Condition';
import { InputWrapper, Select, WrappedRadio, TextField } from 'components/Form';

import { t } from 'utils';

import { form } from 'stores';

import { SUPPORT_TASK_MANAGER_FORM, AMOUNT_TYPE_ORDER } from '../../constants';

import { optionsAmountTypes, optionsSingleRegistrations } from '../../utils';

import { loadOrders } from '../../actions';

import { FieldsDistanceRegistration, FieldsSingleRegistration } from '.';

type Props = {
  showSingle: boolean;
  showDistance: boolean;
};

@observer
export class FieldsRegistration extends React.Component<Props> {
  onSelectChangeOrder = (event, newInputValue) => {
    loadOrders(newInputValue);
  };

  onChangeAmountType = ({ name, value }: { name: string | string[]; value: any }) => {
    form.onChange(SUPPORT_TASK_MANAGER_FORM, name, value);
    form.onChange(SUPPORT_TASK_MANAGER_FORM, 'amount', 0);
  };

  render() {
    const { showSingle, showDistance } = this.props;
    const amountType: number = form.fetch<string>(SUPPORT_TASK_MANAGER_FORM, 'amountType') as any;
    const disabledAmount: boolean = Boolean(amountType === AMOUNT_TYPE_ORDER);

    return (
      <Show if={showSingle || showDistance}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FieldsSingleRegistration show={showSingle} />
            <FieldsDistanceRegistration show={showDistance} />
          </Grid>

          <Grid item xs={4}>
            <InputWrapper
              name='amountType'
              settings={{
                placeholder: t.staticAsString('supportTaskManager.form.type'),
                label: t.staticAsString('supportTaskManager.form.type'),
                options: optionsAmountTypes(),
                onChange: this.onChangeAmountType,
                additional: {
                  fullWidth: true,
                },
                view: {
                  variant: 'outlined',
                },
              }}
              Component={Select}
            />
          </Grid>

          <Grid item xs={4}>
            <InputWrapper
              name='amount'
              settings={{
                required: true,
                label: t.staticAsString('supportTaskManager.form.amount'),
                additional: {
                  disabled: disabledAmount,
                },
                view: {
                  variant: 'outlined',
                },
              }}
              Component={TextField}
            />
          </Grid>

          <Grid item xs={12}>
            <InputWrapper
              name='deleteParticipants'
              settings={{
                required: true,
                options: optionsSingleRegistrations(),
              }}
              Component={WrappedRadio}
            />
          </Grid>
        </Grid>
      </Show>
    );
  }
}

import { Box } from '@mui/material';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import { loadTurnover } from 'modules/Dashboard/actions/turnover.action';
import { DASHBOARD_STATES } from 'modules/Dashboard/constants/dashboard.constants';
import { DashboardStore } from 'modules/Dashboard/stores/dashboard.store';
import { chartRangePickerStore } from 'modules/Dashboard/stores/interval.store';
import { mergeRaceForm } from 'modules/Dashboard/utils/merge-race-form.utils';
import { useEffect, useState } from 'react';

import { Form } from 'components/Form';
import { Spinner } from 'components/Spinner';

import { form, progressStore } from 'stores';

import Turnover from './Turnover';

export default function MobXRegistrationsWrapper() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(DashboardStore.turnovers.value?.data ?? []);

  useEffect(() => {
    mergeRaceForm();
    if (data.length === 0) {
      setIsLoading(true);
      const load = async () => {
        try {
          await loadTurnover();
          setData(DashboardStore.turnovers.value?.data ?? []);
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      };
      load();
    }
    return () => {
      chartRangePickerStore.reset();
    };
  }, []);

  if (isLoading) {
    return (
      <Box>
        <Spinner type='cubesSpinner' />
      </Box>
    );
  }

  return (
    <Box sx={{ overflow: 'scroll', height: '100%', backgroundColor: '#FAFAFA' }}>
      <Form id={DASHBOARD_STATES.RACE_SEARCH_FORM}>
        <Observer
          render={() => {
            if (progressStore.isLoading(DASHBOARD_STATES.TURNOVER_LOADING) || progressStore.isLoading(DASHBOARD_STATES.LOADING)) {
              return (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'fixed',
                      height: '100%',
                      width: '100%',
                      backgroundColor: 'white',
                      opacity: '0.6',
                      zIndex: 5,
                    }}
                  >
                    <Spinner type='cubesSpinner' />
                  </Box>
                  <Turnover data={{ total: 0, trend: { current: 0, previous: 0, rate: null }, data: [] }} />;
                </>
              );
            }

            return <Turnover data={DashboardStore.turnovers.value} />;
          }}
        />
      </Form>
    </Box>
  );
}

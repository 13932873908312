import { IconNameType } from 'img/activity_links';

type Link = {
  contains?: string;
  name: string;
  icon: IconNameType;
};

type Links = Array<{
  contains: string;
  name: string;
  icon: IconNameType;
}>;

export const ACTIVITY_LINKS: Links = [
  {
    contains: 'strava',
    name: 'Strava',
    icon: 'strava',
  },
  {
    contains: 'connect.garmin.com',
    name: 'Garmin connect',
    icon: 'garmin',
  },
  {
    contains: 'flow.polar',
    name: 'Polar Flow',
    icon: 'polarFlow',
  },
  {
    contains: 'movescount',
    name: 'Movescount',
    icon: 'movescountSuunto',
  },
  {
    contains: 'suunto',
    name: 'Suunto',
    icon: 'movescountSuunto',
  },
  {
    contains: 'endomondo',
    name: 'Endomondo',
    icon: 'endomondo',
  },
  {
    contains: 'runkeeper',
    name: 'Runkeeper',
    icon: 'runkeeper',
  },
  {
    contains: 'sports-tracker',
    name: 'Sports Tracker',
    icon: 'sporsTracker',
  },
  {
    contains: 'wahoo',
    name: 'Wahoo',
    icon: 'wahoo',
  },
  {
    contains: 'raceone',
    name: 'RaceONE',
    icon: 'raceone',
  },
  {
    contains: 'zwift',
    name: 'Zwift',
    icon: 'zwift',
  },
];

export const DEFAULT_LINK: Link = {
  name: 'Activity Link',
  icon: 'common',
};

export const NO_SOURCE_LINK: Link = {
  name: 'No source',
  icon: 'noSource',
};

import { Grid } from '@mui/material';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { fontFamilyRoboto } from 'src/components/Form/Fields/FileUploader/style';
import { gpxStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { GpxRoute } from 'src/modules/Distances/components/shared/Steps/GPX/types';

import { t } from 'utils';

const LineTitle = styled.div`
  margin-top: 24px;
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;
const Label = styled.span`
  font-size: 16px;
  color: #a8aeb8;
  margin-right: 8px;
`;
const Value = styled.span`
  color: #262e35;
`;

const Wrapper = styled.div`
  width: 100%;
  font-size: 16px;
  padding: 0 32px;
`;

export const RouteInfo = (): ReactElement => {
  const { coordinates } = gpxStore.value as GpxRoute;
  const startPoint = coordinates[0];
  const endPoint = coordinates[coordinates.length - 1];

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <LineTitle>{t.staticAsString('distances.steps.helperText.gpx.startPoint')}</LineTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div>
            <Label>{t.staticAsString('distances.steps.helperText.gpx.lat')}</Label>
            <Value>{startPoint.lat}</Value>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div>
            <Label>{t.staticAsString('distances.steps.helperText.gpx.lng')}</Label>
            <Value>{startPoint.lng}</Value>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <LineTitle>{t.staticAsString('distances.steps.helperText.gpx.finishPoint')}</LineTitle>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <div>
            <Label>{t.staticAsString('distances.steps.helperText.gpx.lat')}</Label>
            <Value>{endPoint.lat}</Value>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <Label>{t.staticAsString('distances.steps.helperText.gpx.lng')}</Label>
            <Value>{endPoint.lng}</Value>
          </div>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

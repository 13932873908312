import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

import { loadCountryService } from 'services';

import { distancesService, racesService, resultsService } from '../services';

import { distanceStore, racesStore, singleResultsStore, teamResultsStore, fieldRegisterStore } from '../stores';

import { loadNewResultAutocomplete } from './loadNewResultAutocomplete';

async function load(pathname: string) {
  const match = matchPath(pathname, ROUTES.resultsRoute);

  if (!match) {
    return;
  }

  const { raceId, id } = match.params as { raceId?: number; id?: number };

  if (!raceId || !id) {
    return;
  }

  loadCountryService.loadResources();
  racesService.loadResource(+raceId);
  distancesService.loadResources(+raceId);
  await distancesService.loadResource(+id);
  loadNewResultAutocomplete();
  resultsService.loadResource(+id);
}

function clean() {
  distanceStore.clearAll();
  racesStore.clearAll();
  fieldRegisterStore.deactivateAllFields();
  singleResultsStore.clearAll();
  singleResultsStore.clearFilters();
  teamResultsStore.clearAll();
  teamResultsStore.clearFilters();
}

function mountResults() {
  load(history.location.pathname);
}

function unmountResults() {
  clean();
}

export { mountResults, unmountResults };

import { TableHead, TableRow } from '@mui/material';
import * as React from 'react';

import { TasksHeaders } from '../../components/Thead';
import { HeadersType } from '../../components/Thead';

type Props = {};

class THead extends React.Component<Props> {
  HeaderComponent = (): nil | React.ComponentType<HeadersType> => {
    return TasksHeaders;
  };

  render() {
    const HeaderComponent = this.HeaderComponent();

    return (
      <React.Fragment>
        <TableHead>
          <TableRow>{!HeaderComponent ? null : <HeaderComponent />}</TableRow>
        </TableHead>
      </React.Fragment>
    );
  }
}

export { THead };

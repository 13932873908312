export const MuiTablePagination = {
  styleOverrides: {
    root: {
      overflow: 'initial',
      position: 'sticky',
      bottom: 0,
      left: 0,
      backgroundColor: 'white',
    },
  },
};

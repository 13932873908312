import { ROUTES } from 'src/constants';
import { form, toastStore } from 'src/stores';

import { validate } from 'utils/core';
import { history } from 'utils/history';
import { t } from 'utils/t';

import { CAMPAIGN_LINKS_FORM_ID } from '../constants';

import { mapFormToBE } from '../utils';

import { linksService } from '../services';

import { campaignLinksFormValidation } from '../validations';

export const handleSubmitNew = async () => {
  const data = form.fetchWithoutObservable(CAMPAIGN_LINKS_FORM_ID) as any;

  const isValid = validate(CAMPAIGN_LINKS_FORM_ID, data, campaignLinksFormValidation() as any);
  if (!isValid) return;

  const mappedData = mapFormToBE(data);
  const isOk = await linksService.createCampaignLink(mappedData);
  if (isOk) {
    toastStore.show(t.staticAsString('campaignLinks.formCreated', { name: mappedData.name }));
    history.push(ROUTES.campaignLinks);
  }
};

export const handleCancelForm = () => {
  history.goBack();
};

import { Box, FormHelperText } from '@mui/material';
import { isEmpty, isNil, values } from 'lodash';
import { observer } from 'mobx-react';
import { validateOneStep } from 'modules/Distances/actions';
import { DISTANCE_FORM_ERRORS } from 'modules/Distances/constants';
import { clearNamespaceErrors, getDistanceErrors } from 'modules/Distances/utils/errors';
import moment from 'moment';
import * as React from 'react';

import {
  BACKEND_DATETIME_FORMATE,
  BACKEND_DATE_FORMATE,
  DISTANCE_MODES,
  DISTANCE_STEPS,
  DISTANCE_TYPE,
  GOAL_TYPE,
  PERSIST_DISTANCE as action,
  START_TYPE,
} from 'src/constants';

import { Show } from 'components/Condition';
import { SelectField, Switch, TextField } from 'components/Fields';
import { GooglePlacesAutocomplete, DatePicker, NumberField } from 'components/Form/Fields';

import { selectOptionGenerator, t } from 'utils';

import { Race as RaceModel } from 'models';

import { errorsStore } from 'stores';

import { isParticipantsPresentStore } from '../../../../stores';

import { deleteRelation, State as OriginalFormState } from '../../../../shared/stateHelpers';
import { DistanceMode } from '../../Fields';
import { generateRegistrationFields } from '../RegistrationFields';
import { addOption, removeOption } from '../TabOptions';
import { FieldWithTooltip } from './Tooltip';

type Props = {
  formData: OriginalFormState | any;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
    isDirty?: false,
  ) => void;
  helperData: {
    race: nil | RaceModel;
  };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

const FIELDS = {
  allow_no_profile_registration: 'allow_no_profile_registration',
  start_type: 'start_type',
  distance_mode: 'distance_mode',
};

const stepAction = `${action}_${DISTANCE_STEPS.distanceDetails}`;

@observer
class DistanceDetails extends React.Component<Props> {
  onlyNumberRegexp = /^\d*$/;

  componentDidMount() {
    this._addDefaultValues();
  }

  startTypeOptions() {
    return selectOptionGenerator(START_TYPE, 'shared.startType');
  }

  distanceTypeOptions() {
    return selectOptionGenerator(DISTANCE_TYPE, 'shared.distanceType');
  }

  registrationTypeOptions() {
    return selectOptionGenerator(['0', '1'], 'shared.registrationType');
  }

  goalTypeOptions() {
    return selectOptionGenerator(values(GOAL_TYPE), 'shared.goalType');
  }

  onChangeOnlyNumber = (e: React.ChangeEventHandler | any) => {
    // Remove leading zeros logic
    if (/^0\d+/.test(e.currentTarget.value)) {
      return this.onChange({
        name: e.target.name,
        value: +e.currentTarget.value.replace(/^(0+(?=\d))/, ''),
      });
    }

    if (this.onlyNumberRegexp.test(e.currentTarget.value.toString())) {
      this.onChangeWithEvent(e);
    }
  };

  setDefaultLengthValue = (e: React.ChangeEventHandler | any) => {
    const { name, value } = e.currentTarget;
    this.props.liveUpdate({ [name]: value ? +value : 0 });
    this.onChange({
      name,
      value: value ? +value : 0,
    });
  };

  onChangeWithEvent = (e: React.ChangeEventHandler | any) => {
    let value = null;
    let name = '';

    switch (e.type) {
      case 'click':
        value = e.target.value;
        name = e.target.name;
        break;
      case 'keydown':
        value = e.target.value;
        name = e.target.name;
        break;
      case 'change':
        value = e.currentTarget.value;
        name = e.currentTarget.name;
        break;
      default:
        value = e.currentTarget.value;
        name = e.currentTarget.name;
    }

    this.onChange({
      name,
      value,
    });
  };

  onChangeCheckbox = (e: React.ChangeEventHandler | any) => {
    const { name, checked } = e.target;
    let value = +checked;

    this.onChange({ name, value });
  };

  onChangeForBoolean = (e: React.ChangeEventHandler | any) => {
    const { name, checked } = e.target;

    this.onChange({ name, value: checked });
  };

  onChangeWithoutEvent = ({ name, value }: { name: string; value: any }) => {
    this.onChange({ name, value });
  };

  onSingleDateChange = ({ name, value }) => {
    const { formData, onChange, liveUpdate } = this.props;
    const distance = formData.distance;

    onChange(
      {
        ...distance,
        [name]: !isEmpty(value) ? value?.format(BACKEND_DATE_FORMATE) : null,
      },
      'distance',
      () => {
        this.afterOnChange({ name, value });
        clearNamespaceErrors(name);
        liveUpdate({ [name]: !isEmpty(value) ? value?.format(BACKEND_DATE_FORMATE) : null });
      },
      false,
    );
  };

  onRegistrationDateRangeChange = ({ name, value }) => {
    const { formData, onChange, liveUpdate } = this.props;
    const distance = formData.distance;
    let formatedDate;

    if (isEmpty(value)) value = null;
    if (name === 'registration_starts_at') {
      formatedDate = value?.startOf('day').format(BACKEND_DATETIME_FORMATE) ?? null;
    } else if (name === 'registration_ends_at') {
      formatedDate = value?.endOf('day').format(BACKEND_DATETIME_FORMATE) ?? null;
    }

    onChange(
      {
        ...distance,
        [name]: formatedDate,
      },
      'distance',
      () => {
        this.afterOnChange({ name, value });
        clearNamespaceErrors(name);
        liveUpdate({ [name]: formatedDate });
      },
      false,
    );
  };

  onDistanceDateRangeChange = ({ name, value }) => {
    const { formData, onChange, liveUpdate } = this.props;
    const distance = formData.distance;
    if (isEmpty(value)) value = null;

    onChange(
      {
        ...distance,
        [name]: value?.format(BACKEND_DATE_FORMATE) ?? null,
      },
      'distance',
      () => {
        this.afterOnChange({ name, value });
        clearNamespaceErrors(name);
        liveUpdate({
          [name]: value?.format(BACKEND_DATE_FORMATE) ?? null,
          ...(name === 'ends_at' && { distance_mode: distance.distance_mode }),
        });
      },
      false,
    );
  };

  onChangeEmailContent = ({ name, value }: { name: string; value: AnyObject }) => {
    const { formData, onChange } = this.props;
    onChange(value, 'email_content');
  };

  onChangeGoal = ({ name, value }: { name: string; value: string }) => {
    const { formData, onChange, liveUpdate } = this.props;
    const goalValue = formData.goal;
    liveUpdate({ goal: { ...goalValue, [name]: value }, distance_mode: DISTANCE_MODES.CUMULATIVE });
    onChange({ ...goalValue, [name]: value }, 'goal', () => {}, false);
  };

  onChange = ({ name, value }: { name: string; value: any }) => {
    const formattedValue = this._formatInput(name, value);
    const { formData, onChange, liveUpdate } = this.props;
    const { distance, editorSettings } = formData;
    clearNamespaceErrors(name);
    if (name === 'type' && value === 'team') {
      const fields = editorSettings.tab_options.filter((tab) => tab !== 'selfServices');
      liveUpdate({ [name]: formattedValue, editorSettings: { ...editorSettings, tab_options: fields } });
      return onChange(
        {
          ...formData,
          editorSettings: { ...editorSettings, tab_options: fields },
          distance: { ...distance, [name]: formattedValue },
        },
        '',
        () => {
          this.afterOnChange({ name, value });
          clearNamespaceErrors(name);
        },
        false,
      );
    }

    onChange(
      {
        ...distance,
        [name]: formattedValue,
      },
      'distance',
      () => {
        this.afterOnChange({ name, value });
      },
      false,
    );

    if (!['race_length', 'race_qty'].includes(name)) {
      liveUpdate({ [name]: formattedValue });
    }
  };

  defaultInputProps = (name: string) => {
    const { formData } = this.props;
    let value = formData.distance[name];

    if (['race_length', 'race_qty'].includes(name) && value === 0) {
      value = '';
    }

    return {
      value,
      name,
      errors: getDistanceErrors(name),
      fullWidth: true,
    };
  };

  defaultRaceDateRangeInputProps = (template: string) => {
    const { formData } = this.props;

    const registrationDate = formData.distance[template];

    const error = getDistanceErrors(template);

    return {
      value: registrationDate && moment.utc(registrationDate),
      error: error?.[0],
      name: template,
      fullWidth: true,
    };
  };

  defaultDistanceDateRangeInputProps = (template: string) => {
    const { formData } = this.props;

    const distanceDate = formData.distance[template];

    const error = getDistanceErrors(template);
    return {
      value: distanceDate && moment.utc(distanceDate),
      error: error?.[0],
      name: template,
      fullWidth: true,
    };
  };

  defaultSingleDateInputProps = (name: string) => {
    const { formData } = this.props;
    const value = formData.distance[name];
    const error = getDistanceErrors(name);

    return {
      value: value && moment.utc(value),
      error: error?.[0],
      name,
      fullWidth: true,
    };
  };

  afterOnChange = ({ name, value }: { name: string; value: any }) => {
    if (name === FIELDS.start_type) {
      if (value === START_TYPE.wave) {
        this._onAddWaveTabOption();
      } else {
        this._onRemoveWaveTabOption();
      }
    }

    if (name === FIELDS.distance_mode && value !== DISTANCE_MODES.CLASSIC) {
      this._onVirtualEnable(value);
    }
  };

  displayVirtualDistanceToggle = () => {
    const { formData } = this.props;
    return formData.distance.type !== DISTANCE_TYPE.team && !parseInt(formData.distance.allow_no_profile_registration, 10);
  };

  _addDefaultValues = () => {
    const { onChange, helperData } = this.props;
    const { registrationFields, distance } = this.props.formData;
    const { race } = helperData;
    const { location } = distance;

    if (!race) {
      return;
    }

    const dateChanges = this._setDefaultDate(race);

    onChange(
      {
        ...(!registrationFields.length ? { registrationFields: generateRegistrationFields() } : {}),
        distance: {
          ...distance,
          ...dateChanges,
          ...(location === '' || !location ? { location: race.location() } : {}),
        },
      },
      '',
      () => {},
      false,
    );
  };

  _formatInput = (name: string, value: any) => {
    if (value === undefined && value === null) {
      return null;
    }

    if (name === FIELDS.allow_no_profile_registration) {
      return parseInt(value, 10);
    }

    return value;
  };

  _setDefaultDate = (race: RaceModel) => {
    const { distance } = this.props.formData;
    const distanceDate = distance.race_date;
    const startDate = race.momentRaceDate();

    if (startDate && !distanceDate) {
      return {
        race_date: startDate.format(BACKEND_DATE_FORMATE),
      };
    }

    return {};
  };

  _onAddWaveTabOption = () => {
    const { tab_options } = this.props.formData.editorSettings;

    if (tab_options.includes(DISTANCE_STEPS.waves)) {
      return;
    }

    const newValues = addOption(tab_options, DISTANCE_STEPS.waves);
    this.props.liveUpdate({ editorSettings: { tab_options: newValues } });
    this.props.onChange({ tab_options: newValues }, 'editorSettings');
  };

  _onVirtualEnable = (value) => {
    const {
      distance,
      editorSettings: { tab_options },
    } = this.props.formData;
    this.props.onChange(
      {
        ...distance,
        type: 'single',
        allow_no_profile_registration: false,
      },
      'distance',
      () => {
        this._onRemoveCheckpointTabOption(this._onRemoveMedicalTabOption);

        const newCheckpoints = deleteRelation(distance.checkpoints);
        const newValues = removeOption(tab_options, [DISTANCE_STEPS.checkpoints, DISTANCE_STEPS.medical_assistants]);

        this.props.liveUpdate({
          type: 'single',
          allow_no_profile_registration: false,
          checkpoints: newCheckpoints,
          editorSettings: { tab_options: newValues },
        });
      },
    );
  };

  _onRemoveWaveTabOption = () => {
    const { formData, onChange } = this.props;
    const { tab_options } = this.props.formData.editorSettings;
    const newWaves = deleteRelation(formData.waves);
    const newValues = removeOption(tab_options, DISTANCE_STEPS.waves);
    this.props.liveUpdate({ waves: newWaves, editorSettings: { tab_options: newValues } });
    onChange({ waves: newWaves }, '', () => {
      onChange({ tab_options: newValues }, 'editorSettings');
    });
  };

  _onRemoveCheckpointTabOption = (callback?: Function) => {
    const { formData, onChange, liveUpdate } = this.props;
    const { tab_options } = this.props.formData.editorSettings;
    const newCheckpoints = deleteRelation(formData.checkpoints);
    const newValues = removeOption(tab_options, DISTANCE_STEPS.checkpoints);

    onChange({ checkpoints: newCheckpoints }, '', () => {
      onChange({ tab_options: newValues }, 'editorSettings', callback);
    });
  };

  _onRemoveMedicalTabOption = () => {
    const { onChange, liveUpdate } = this.props;
    const { tab_options } = this.props.formData.editorSettings;
    const newValues = removeOption(tab_options, DISTANCE_STEPS.medical_assistants);

    onChange({ tab_options: newValues }, 'editorSettings');
  };

  render() {
    const startTypeOptions = this.startTypeOptions();
    const distanceTypeOptions = this.distanceTypeOptions();
    const registrationTypeOptions = this.registrationTypeOptions();
    const goalTypeOptions = this.goalTypeOptions();
    const { distance, email_content, goal } = this.props.formData;

    const isEdit = Boolean(distance.id);
    const isDistanceTeam = distance.type === DISTANCE_TYPE.team;
    const allowNoProfile = isNil(distance.allow_no_profile_registration) ? null : (+distance.allow_no_profile_registration).toString();
    const selectProfileTooltip = t.staticAsString(`distances.steps.baseStepForm.profile_registration.tooltip${allowNoProfile}` as any);
    const isCumulative = distance.distance_mode === DISTANCE_MODES.CUMULATIVE;
    const isClassic = distance.distance_mode === DISTANCE_MODES.CLASSIC;
    const isVirtual = distance.distance_mode === DISTANCE_MODES.VIRTUAL;
    const isGoalAltitude = goal?.goal_type === GOAL_TYPE.ALTITUDE;

    return (
      <div className='form-content-wrapper'>
        <DistanceMode {...this.defaultInputProps('distance_mode')} onChange={this.onChangeWithoutEvent} />
        <Show if={isClassic}>
          <SelectField
            style={{ marginBottom: isParticipantsPresentStore.value ? 18 : 0 }}
            {...this.defaultInputProps('type')}
            options={distanceTypeOptions}
            label={t.staticAsString('distances.steps.baseStepForm.type')}
            onChange={this.onChangeWithEvent}
            disabled={isParticipantsPresentStore.value}
            helperText={isParticipantsPresentStore.value && t('distances.steps.baseStepForm.disabledTypeReasonWithParticipants')}
          />
        </Show>
        <SelectField
          {...this.defaultInputProps('start_type')}
          options={startTypeOptions}
          label={t.staticAsString('distances.steps.baseStepForm.start_type')}
          onChange={this.onChangeWithEvent}
        />
        {/*TODO, replace registration_type with actually column*/}
        {/* <Show if={!isEdit}>
          <TextField
            {...this.defaultInputProps('name')}
            label={t.staticAsString('distances.steps.baseStepForm.name')}
            onChange={this.onChangeWithEvent}
          />
        </Show> */}
        <Show if={isClassic}>
          <SelectField
            {...this.defaultInputProps('allow_no_profile_registration')}
            value={allowNoProfile!}
            options={registrationTypeOptions}
            label={t.staticAsString('distances.steps.baseStepForm.allow_no_profile_registration')}
            onChange={this.onChangeWithEvent}
            helperText={selectProfileTooltip}
          />
          &nbsp;
        </Show>
        <TextField
          {...this.defaultInputProps('race_length')}
          label={t.staticAsString('distances.steps.baseStepForm.race_length')}
          onChange={this.onChangeOnlyNumber}
          onBlur={this.setDefaultLengthValue}
        />
        <Show if={isDistanceTeam}>
          <FieldWithTooltip message={t('distances.steps.baseStepForm.min_members_quantity_title')}>
            <div className='field-group with-icon'>
              <TextField
                {...this.defaultInputProps('min_members_quantity')}
                label={t.staticAsString('distances.steps.baseStepForm.min_members_quantity')}
                onChange={this.onChangeOnlyNumber}
              />
              <TextField
                {...this.defaultInputProps('max_members_quantity')}
                label={t.staticAsString('distances.steps.baseStepForm.max_members_quantity')}
                onChange={this.onChangeOnlyNumber}
              />
            </div>
          </FieldWithTooltip>
        </Show>

        <TextField
          {...this.defaultInputProps('race_qty')}
          tooltipText={isDistanceTeam ? t.staticAsString('distances.steps.baseStepForm.race_qty.tooltip') : ''}
          label={t.staticAsString(`distances.steps.baseStepForm.race_qty.${isDistanceTeam ? 'teams' : 'notRequired'}`)}
          onChange={this.onChangeOnlyNumber}
          onBlur={this.setDefaultLengthValue}
        />

        <Show if={isClassic}>
          <DatePicker
            {...this.defaultSingleDateInputProps('race_date')}
            tooltip={{
              text: t.staticAsString('distances.steps.baseStepForm.tooltip.distance_dates_range_normal'),
              position: 'bottom',
            }}
            onChange={this.onSingleDateChange}
            label={`${t.staticAsString('distances.steps.baseStepForm.race_date_cvr')}`}
            additional={{
              isRange: false,
            }}
            /*{...generateDateRestrictions('normal_distance_date', this.props.formData.distance)}*/
          />
        </Show>

        <Show if={isVirtual || isCumulative}>
          <Box sx={{ display: 'flex', gap: '15px' }}>
            <DatePicker
              {...this.defaultDistanceDateRangeInputProps('race_date')}
              onChange={this.onDistanceDateRangeChange}
              label={`${t.staticAsString('distances.steps.baseStepForm.race_date_range_start')}`}
              additional={{ isRange: false }}
              /*{...generateDateRestrictions('cvr_distance_date_range', this.props.formData.distance)}*/
            />
            <DatePicker
              {...this.defaultDistanceDateRangeInputProps('ends_at')}
              onChange={this.onDistanceDateRangeChange}
              label={`${t.staticAsString('distances.steps.baseStepForm.race_date_range_end')}`}
              additional={{ isRange: false }}
              /*{...generateDateRestrictions('cvr_distance_date_range', this.props.formData.distance)}*/
            />
          </Box>
          <FormHelperText sx={{ pl: '10px' }}>
            {t.staticAsString('distances.steps.baseStepForm.tooltip.distance_dates_range_virtual')}
          </FormHelperText>
        </Show>
        <Box>
          <Box sx={{ display: 'flex', gap: '15px' }}>
            <DatePicker
              {...this.defaultRaceDateRangeInputProps('registration_starts_at')}
              onChange={this.onRegistrationDateRangeChange}
              label={t.staticAsString('distances.steps.baseStepForm.registration_starts_at')}
              additional={{ isRange: false }}
              /*{...generateDateRestrictions('registration_range', this.props.formData.distance)}*/
              // minDate={todayStart()}
            />
            <DatePicker
              {...this.defaultRaceDateRangeInputProps('registration_ends_at')}
              onChange={this.onRegistrationDateRangeChange}
              label={t.staticAsString('distances.steps.baseStepForm.registration_ends_at')}
              additional={{ isRange: false }}
              /*{...generateDateRestrictions('registration_range', this.props.formData.distance)}*/
              // minDate={todayStart()}
            />
          </Box>
          <FormHelperText sx={{ pl: '10px' }}>{t.staticAsString('distances.steps.baseStepForm.tooltip.registration_range')}</FormHelperText>
        </Box>

        <Show if={isCumulative}>
          <FieldWithTooltip message={t('distances.steps.baseStepForm.tooltip.goal')}>
            <SelectField
              name='goal_type'
              options={goalTypeOptions}
              value={goal?.goal_type || ''}
              onChange={(e: React.ChangeEventHandler | any) =>
                this.onChangeGoal({
                  name: 'goal_type',
                  value: e.target.value,
                })
              }
              errors={getDistanceErrors('goal.goal_type')}
              label={t.staticAsString('distances.steps.baseStepForm.goal_type')}
              fullWidth
            />
          </FieldWithTooltip>
          <NumberField
            name='goal_max_length'
            id='goal_max_length'
            value={goal?.goal_max_length || ''}
            onChange={this.onChangeGoal}
            label={t.staticAsString(
              isGoalAltitude ? 'distances.steps.baseStepForm.goal_max_altitude' : 'distances.steps.baseStepForm.goal_max_length',
            )}
            view={{ fullWidth: true }}
            error={getDistanceErrors('goal.goal_max_length')}
            maxWidth={10}
          />
        </Show>
        <GooglePlacesAutocomplete
          view={{ fullWidth: true }}
          {...this.defaultInputProps('location')}
          label={t.staticAsString('distances.steps.baseStepForm.distanceStartLocation')}
          onChangePlainAddress={this.onChangeWithoutEvent}
          searchOptions={{}}
          valueAsString
        />

        <Switch
          {...this.defaultInputProps('is_visible')}
          label={t.staticAsString('distances.steps.baseStepForm.is_visible')}
          tooltipText={t.staticAsString('distances.steps.baseStepForm.isVisibleTooltip')}
          onChange={this.onChangeForBoolean}
          searchOptions={{}}
        />
        <div className='separator' />
        {this.props.controls}
      </div>
    );
  }
}

export { DistanceDetails };

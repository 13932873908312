import { isNaN } from 'lodash';
import moment from 'moment';

function roundDiff(value: number): number {
  return Math.round(value / 10) * 10;
}

function roundMoment(value: moment.Moment) {
  const milliseconds = value.milliseconds();
  let millisToAdd = 0;

  if (milliseconds < 500) {
    millisToAdd = milliseconds * -1;
  } else {
    millisToAdd = 1000 - milliseconds;
  }

  const newMomentValue = moment(value);
  newMomentValue.add(millisToAdd, 'milliseconds');

  return newMomentValue;
}

function roundDate(value: Date): Date {
  if (isNaN(value.getTime())) {
    return value;
  }

  const milliseconds = value.getMilliseconds();
  if (milliseconds < 500) {
    return value;
  }

  const millisToAdd = 1000 - milliseconds;

  const newValue = new Date(value);
  newValue.setMilliseconds(milliseconds + millisToAdd);

  return newValue;
}

export { roundMoment, roundDiff, roundDate };

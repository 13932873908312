import { Paper, Typography } from '@mui/material';
import * as React from 'react';

import { t } from 'utils';

import { endStatusStyles } from '../styles';

export const LoadingCancelled = ({ upload }: { upload?: boolean }) => {
  const classes = endStatusStyles();
  return (
    <Paper elevation={3} className={classes.paper}>
      <Typography variant='h5' component='h2' className={classes.text}>
        {upload ? t.staticAsString('shared.loadPopup.uploadCancelled') : t.staticAsString('shared.loadPopup.loadCancelled')}
      </Typography>
    </Paper>
  );
};

import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

const getRaceId = () => {
  const match = matchPath(history.location.pathname, ROUTES.raceEditRoute);
  return match?.params?.id;
};

export { getRaceId };

import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { ORGANIZER_URL } from 'src/constants/api';
import { action, request } from 'src/utils';

class Organizer {
  @request({ action: shortid() })
  loadOrganizerRequest(id: number | string): Promise<any> {
    const path = generatePath(ORGANIZER_URL, { id });
    return axios.get(path, {
      params: {
        with: 'roles;commissions',
      },
    });
  }

  @action({ action: shortid() })
  async loadOrganizer(id: number | string): Promise<any> {
    const [isOk, response] = await this.loadOrganizerRequest(id);
    if (!isOk) {
      return;
    }
    return response.data.data;
  }

  @request({ action: shortid() })
  updateOrganizerRequest(organizerData: AnyObject, id: number): Promise<any> {
    const path = generatePath(ORGANIZER_URL, { id });
    return axios.patch(path, organizerData);
  }

  @action({ action: shortid() })
  async updateOrganizer(organizerData: AnyObject, id: number): Promise<any> {
    const [isOk, response] = await this.updateOrganizerRequest(organizerData, id);
    if (!isOk) {
      return;
    }
    return response.data.data;
  }
}

const organizerService = new Organizer();
export { Organizer, organizerService };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { PAYMENTS_URL } from 'src/constants';

import { action, request } from 'utils';

import { GET_ORDERS_OPTIONS_DATA } from '../constants';

import { mapOrders } from '../utils';

import { ordersStore } from '../stores';

class OrderService {
  @request({ action: GET_ORDERS_OPTIONS_DATA })
  async getOrdersRequest(params: nil | FiltersType): Promise<any> {
    const path = generatePath(PAYMENTS_URL);
    return axios.get(path, { params });
  }

  @action({ action: GET_ORDERS_OPTIONS_DATA })
  async getOrders(params: nil | FiltersType): Promise<any> {
    const [status, response] = await this.getOrdersRequest(params);

    if (status) {
      ordersStore.set(mapOrders(response.data.data));
    }
  }
}

export const orderService = new OrderService();

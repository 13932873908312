import { cloneDeep } from 'lodash';

import { PAYMENT_STATUSES } from 'src/constants';

import { formatter, t } from 'utils';

import { Distance } from './distance';
import { Racer } from './racer';

class Payment {
  value: PaymentType;
  distance: Distance | null;
  racer: Racer | null;

  constructor(value: PaymentType) {
    this.value = value;
    this.distance = null;
    this.racer = null;
    this._initDistance();
    this._initRacer();
  }

  payedItems() {
    const { value } = this;

    if (!value.options) {
      return [];
    }

    return cloneDeep(value.options)
      .filter((option) => !!Number(option.value.price))
      .reduce((result: Array<AnyObject>, current: AnyObject) => {
        const optionIndex = result.findIndex(
          (item) => item.custom_field_id === current.custom_field_id && item.custom_field_value_id === current.custom_field_value_id,
        );

        if (optionIndex !== -1) {
          result[optionIndex].count = (result[optionIndex].count || 1) + 1;
          return result;
        }

        return [...result, current];
      }, []);
  }

  refundProtect() {
    const { refund_protect_amount, refund_protect_used } = this.value;

    return { refund_protect_amount, refund_protect_used };
  }

  currency() {
    const { currency } = this.value;
    return currency || '';
  }

  totalSum(): string {
    const { amount } = this.value;

    return `${this.currency()} ${formatter.integer(amount as any)}`;
  }

  priceUsed(): string {
    const { price_used } = this.value;

    if (!price_used) {
      return '';
    }

    return `${this.currency()} ${formatter.integer(price_used as any)}`;
  }

  discountedAmount(): string {
    const { discounted_amount } = this.value;

    if (!discounted_amount) {
      return '';
    }

    return `${this.currency()} ${formatter.integer(discounted_amount as any)}`;
  }

  amountForCustomFields(): string {
    const { amount_for_custom_fields } = this.value;

    if (!amount_for_custom_fields) {
      return '';
    }

    return `${this.currency()} ${formatter.integer(amount_for_custom_fields as any)}`;
  }

  racerName(): string {
    const { racer } = this.value;

    if (!racer) {
      return '';
    }

    return `${racer.firstname} ${racer.lastname}`;
  }

  orderId(): string {
    const { id } = this.value;

    if (!id) {
      return '';
    }

    return `${id}`;
  }

  racersCounter(): string {
    const { racers_count = '' } = this.value;

    if (racers_count === 0) {
      return '';
    }

    return `${racers_count}`;
  }

  distanceName(): string {
    if (!this.distance) {
      return '';
    }

    return `${this.distance.value.name} ${formatter.integer(this.distance.raceLength())} km` || '';
  }

  userFullName(): string {
    const { user } = this.value;

    if (!user) {
      return '';
    }

    return `${user.firstname} ${user.lastname}`;
  }

  _initDistance() {
    const { value } = this;
    if (value.distance) {
      this.distance = new Distance(value.distance);
    }
  }

  _initRacer() {
    const { value } = this;
    if (value.racer) {
      this.racer = new Racer(value.racer);
    }
  }

  isPending(): boolean {
    return this.value.state === PAYMENT_STATUSES.Pending;
  }

  isAborted(): boolean {
    return this.value.state === PAYMENT_STATUSES.Aborted;
  }

  isFailed(): boolean {
    return this.value.state === PAYMENT_STATUSES.Failed;
  }

  isReady(): boolean {
    return this.value.state === PAYMENT_STATUSES.Ready;
  }
}

export { Payment };

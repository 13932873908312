import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import styled from 'styled-components';

import { DatePicker, InputWrapper } from 'src/components/Form';
import { form } from 'src/stores';

import { t } from 'utils/t';

import { REGISTRATION_LINKS_FORM_ID } from '../constants';

const DateWrapper = styled.div`
  display: flex;
`;

const StyledDatePicker = styled(DatePicker)`
  margin-right: 24px;
`;

export const FromTo = () => {
  return (
    <Observer>
      {() => {
        const distance = form.fetch<AnyObject | null>(REGISTRATION_LINKS_FORM_ID, 'distance_id');
        const disabled = !distance;

        return (
          <DateWrapper>
            <InputWrapper
              name='date_from'
              settings={{
                label: t.staticAsString('shared.validFromDate'),
                view: {
                  reservePlaceForHelperText: true,
                  required: true,
                },
                additional: {
                  disabled,
                  isRange: false,
                },
                minDate: moment(distance?.registration_starts_at),
                maxDate: moment(distance?.registration_ends_at),
              }}
              Component={StyledDatePicker}
            />
            <InputWrapper
              name='date_to'
              settings={{
                label: t.staticAsString('shared.validToDate'),
                view: {
                  reservePlaceForHelperText: true,
                  required: true,
                },
                additional: {
                  disabled,
                  isRange: false,
                },
                minDate: moment(distance?.registration_starts_at),
                maxDate: moment(distance?.registration_ends_at),
              }}
              Component={DatePicker}
            />
          </DateWrapper>
        );
      }}
    </Observer>
  );
};

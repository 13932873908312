import { fetchDefault } from './fetchDefault';
import { init } from './init';
import { update } from './update';

const locale = {
  fetchDefault,
  init,
  update,
};

export { locale };

import { ROUTES } from 'src/constants';

import { history } from 'utils';

import { translations } from 'actions/translations';

import { errorsStore, sessionStore, toastStore } from 'stores';

// @ts-ignore
type ResponseProcessorArgs = {
  action: string;
  response: AxiosResponse;
  root: string;
  writeError: boolean;
};

class ResponseProcessor {
  action: string;
  response: AxiosResponse;
  root: string;
  writeError: boolean;

  constructor(params: ResponseProcessorArgs) {
    const { action, response, root, writeError } = params;
    this.action = action;
    this.response = response;
    this.root = root;
    this.writeError = writeError;
  }

  async process() {
    if (!this.response) {
      console.error('Provided invalid response object, response processing will not be performed');
      return;
    }
    try {
      this.processByStatus();
      this.processNewToken();
      this.processTranslationsVerion();
    } catch (e) {
      console.error(e);
    }
  }

  generatePrefix() {
    const urlParts = this.response.config.url.split('/');
    return isNaN(Number(urlParts[urlParts.length - 1]))
      ? urlParts[urlParts.length - 1].charAt(0).toUpperCase() + urlParts[urlParts.length - 1].slice(1)
      : urlParts[urlParts.length - 2].charAt(0).toUpperCase() + urlParts[urlParts.length - 2].slice(1);
  }

  processNewToken() {
    const headers = this.response.headers as any;
    const token = headers && headers['x-authorization'];
    if (token) sessionStore.debouncedSetToken(token);
  }

  processTranslationsVerion() {
    translations.handleVersionChange(this.response as any);
  }

  stub() {
    history.push(ROUTES.serviceUnavailable);
  }

  processByStatus(): void {
    this.generatePrefix();
    switch (this.response.status) {
      case 422:
        this.handleValidationError();
        return;
      case 401:
        this.handleValidationError();
        this.handleUnauthorized();
        return;
      case 404:
        return;
      case 403:
        this.handleValidationError();
        return;
      case 500:
        console.error('Internal server error', this.response);
        toastStore.show(
          `Error while loading ${this.generatePrefix()}. Server is not responding to your request, please contact support@raceid.com`,
        );
        return;
      case 409:
        toastStore.show(this.response.data?.message, null, 4000, 'error');
        return;
      case 429:
        console.error('Too many requests', this.response);
        toastStore.show('Too many requests', null, 5000, 'error');
        return;
      case 502:
        console.error('Bad gateway', this.response);
        this.handleShowStub();
        return;
      case 503:
        console.error('Service unavailable', this.response);
        this.handleShowStub();
        return;
      default:
        return;
    }
  }

  handleShowStub() {
    this.stub();
  }

  handleUnauthorized() {
    sessionStore.logout();
  }

  handleValidationError() {
    if (this.writeError) {
      errorsStore.addApiError(this.action, this.response, this.root);
      this.response.data[this.root] && errorsStore.add(this.action, this.response.data[this.root]);
    }
  }
}

export { ResponseProcessor };

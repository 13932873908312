import { t } from 'utils';

import {
  COPY_RACERS_JOB,
  DELETE_ACCOUNT_JOB,
  DUPLICATE_DISTANCE_JOB,
  DUPLICATE_RACE_JOB,
  GENERATE_BIB_JOB,
  GENERATE_COUPON_JOB,
  MERGE_ACCOUNTS_JOB,
  REFUND_DISTANCE_JOB,
  REFUND_JOB,
  REFUND_ORDER_JOB,
  REFUND_PAYMENT_JOB,
  REFUND_RACE_JOB,
} from '../utils';

const keys: Object = {
  [DELETE_ACCOUNT_JOB]: 'delete',
  [COPY_RACERS_JOB]: 'copyRacers',
  [MERGE_ACCOUNTS_JOB]: 'mergeAccounts',
  [REFUND_DISTANCE_JOB]: 'creditDistanceRegistration',
  [REFUND_PAYMENT_JOB]: 'creditSingleRegistration',
  [REFUND_ORDER_JOB]: 'creditSingleRegistration',
  [REFUND_RACE_JOB]: 'creditSingleRegistration',
  [REFUND_JOB]: 'refundJob',
  [GENERATE_COUPON_JOB]: 'generateCoupon',
  [GENERATE_BIB_JOB]: 'bibNumbers',
  [DUPLICATE_DISTANCE_JOB]: 'duplicateDistance',
  [DUPLICATE_RACE_JOB]: 'duplicateRace',
};

const dictionary = (key: string): string => {
  const newKey: TranslationLockedKeys = ('supportTaskManager.list.actions.' + keys[key]) as TranslationLockedKeys;

  return t.staticAsString(newKey);
};

export { dictionary };

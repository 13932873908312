import { Organizer } from '../types';

export class OrganizerModel {
  value: Organizer;

  constructor(value: Organizer) {
    this.value = value;
  }

  forSelect(): SelectOption {
    const id = `${this.value.id || ''}`;
    return {
      label: this.value?.email,
      value: id,
      key: id,
    };
  }

  label(): string {
    return this.value.full_name;
  }
}

import { assign } from 'lodash';
import { CONDITION_QUANTITY_DISTANCE } from 'modules/StartList/types';
import React from 'react';

import { Show } from 'components/Condition';
import { SelectForDistance } from 'components/Fields/SelectForDistance';

import squads from 'stores/squads';

const ExistingDistanceList = ({
  distance,
  onChange,
}: {
  distance: DistanceType | AnyObject;
  onChange: (e: React.SyntheticEvent) => void;
}) => {
  const { value, race: distanceRace } = distance;

  const onChangeWithCleanUp = (e: React.SyntheticEvent) => {
    squads.clearAll();
    onChange(e);
  };

  const distanceValue = value;
  const isOneDistance = distanceRace?.distances.length <= CONDITION_QUANTITY_DISTANCE;

  const renderClassesForSelect = (): Array<SelectOption> => {
    return (distanceRace?.distances)
      .sort((a: AnyObject, b: AnyObject) => a.value.index - b.value.index)
      .map((item: AnyObject) => ({
        key: item.value.id,
        value: item.value.id,
        label: item.name(),
        labelOption: `${item.raceLength()} km`,
      }));
  };

  const renderOneDistance = (): string => {
    const {
      value: { name, race_length: raceLength },
    } = assign({}, ...distanceRace?.distances);

    return `${name} ${raceLength / 1000} km`;
  };

  return (
    <React.Fragment>
      <Show if={!isOneDistance}>
        <SelectForDistance
          name='distances'
          value={distanceValue?.id}
          onChange={(e) => onChangeWithCleanUp(e)}
          options={renderClassesForSelect()}
          errors={[]}
          fullWidth={false}
          label=''
        />
      </Show>
      <Show if={isOneDistance}>
        <div className='start-list-one-distance'>
          <span>{renderOneDistance()}</span>
        </div>
      </Show>
    </React.Fragment>
  );
};

export { ExistingDistanceList };

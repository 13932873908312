import { debounce, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { FORGOT_PASSWORD } from 'src/constants';

import { withErrorClean } from 'hocs';

import { Input } from 'components/Fields/Input';
import { AuthSubmit } from 'components/core/Buttons';

import { oneFieldValidation, t, validation } from 'utils';

import { ForgotPasswordService } from '../services/forgot';

import { Props, State } from '../types';
import { base as ValidateConstraints } from './validations';

const action = FORGOT_PASSWORD;

@withRouter
@withErrorClean(action)
@inject('errorsStore')
@observer
class ForgotPassword extends React.Component<Props, State> {
  state = { email: '' };

  service: ForgotPasswordService = new ForgotPasswordService();

  debouncedValidation = debounce((name: string) => {
    const constrainsField = { [name]: ValidateConstraints[name] };

    this.props.errorsStore.clearError(action);
    oneFieldValidation(action, this.state, constrainsField);
  }, 400);

  @validation({ action, constrains: ValidateConstraints })
  validate() {
    return this.state;
  }

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { email } = this.state;

    const isValid = this.validate();

    if (!isValid || !isEmpty(this.errors())) {
      return;
    }

    this.service.forgotPassword(email);
  };

  onChange = ({ target: { value, name } }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        [name as 'email']: value,
      },
      () => this.debouncedValidation(name),
    );
  };

  errors = () => {
    const {
      errorsStore: { errors },
    } = this.props;
    const err = errors.api[action];
    const validationErrors = errors.validation[action];

    if (err) return err.formatErrors();
    if (validationErrors) return validationErrors.formatErrors();
    else return {};
  };

  render() {
    const { email } = this.state;
    return (
      /* eslint-disable */
      <form onSubmit={this.onSubmit} className='forgot-password'>
        <h1 className='title'>{t.staticAsString('forgotPassword.restorePassword' as TranslationLockedKeys)}</h1>
        <p className='sub-title'>{t.staticAsString('forgotPassword.restoreTitle' as TranslationLockedKeys)}</p>
        <Input
          className='form-control forgot'
          label={`${t.staticAsString('forgotPassword.email' as TranslationLockedKeys)}*`}
          onChange={this.onChange}
          value={email}
          type='email'
          name='email'
          errors={this.errors()['email']}
        />
        <AuthSubmit label={t.staticAsString('forgotPassword.confirm')} />
        <NavLink to='/' className='sign-in'>
          {t.staticAsString('forgotPassword.logIn' as TranslationLockedKeys)}
        </NavLink>
      </form>
    );
  }
}

export { ForgotPassword };

import { find } from 'lodash';
import { currentStepStore } from 'modules/Distances/stores';
import { getStepErrors } from 'modules/Distances/utils';

export const scrollToErrorField = () => {
  const step = currentStepStore.value;
  if (!step) return;

  const stepErrors = getStepErrors(true);
  const firstError = find(stepErrors, step.value);
  const errorInputName = firstError?.[step.value];
  const formScroll = document.getElementsByName(errorInputName === 'goal' ? 'goal_type' : errorInputName)[0];

  formScroll?.scrollIntoView({ block: 'center', behavior: 'smooth' });

  return Boolean(formScroll);
};

import { t } from 'src/utils';

const length = {
  minimum: 3,
  maximum: 200,
};

export const settingsStepSchema = (): AnyObject => {
  const presence = {
    allowEmpty: false,
    message: t.staticAsString('validation.required'),
  };

  return {
    race_id: {
      presence,
    },
    event_id: {
      presence,
      length,
    },
  };
};

import axios from 'axios';

import { action } from 'utils';
import { MESSAGES_HEADER_KEY } from 'utils/messages/constants';

import { translationsUrl } from './translations.url';

const loadAction = 'LOAD_TRANSLATIONS';

function generateTranslationsService() {
  class Translations {
    fetchRequest(locale: availableLocales): any {
      return axios.get(translationsUrl() + locale + '.json', {
        headers: {
          'translation-version': localStorage.getItem(MESSAGES_HEADER_KEY) || 0,
        },
      });
    }

    @action({ action: loadAction })
    async fetch(locale: availableLocales): Promise<[number, null | ApiTranslations]> {
      const response = await this.fetchRequest(locale);
      const version = Number(localStorage.getItem(MESSAGES_HEADER_KEY));
      return [version, response.data];
    }
  }

  return new Translations();
}

export { generateTranslationsService };

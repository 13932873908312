import { makeStyles } from '@mui/styles';

export const useAutocompleteStyles = makeStyles({
  option: {
    fontSize: '14px',
  },
  noOptions: {
    fontSize: '14px',
  },
  input: {
    height: '24px',
  },
});

export const useAutocompleteCountryStyles = makeStyles({
  option: {
    fontSize: '14px',
    '&:nth-child(2)': { borderBottom: '1px solid gray' },
  },
  noOptions: {
    fontSize: '14px',
  },
  input: {
    height: '24px',
  },
});

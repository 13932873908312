import { isFunction, isString } from 'lodash';

import { dynamicTranslation, staticTranslation } from 'components/T';
import { processTextType } from 'components/T';

import { translate } from './translate';

/*
 * Tool for handling static and dynamic translations
 *
 * USAGE
 *
 * t('key.key');                                 | React.Node
 * t('key.key', { name: 'name' });               | React.Node, with formatted string via formatUnicorn
 * Same
 * t.static('key.key');                          | React.Node
 * t.static('key.key', { name: 'name' });        | React.Node, with formatted string via formatUnicorn
 *
 * Dynamic translations
 *
 * Note, that raceModel.name must be a arrow to bind correctly
 * t(raceModel.name);                            | React.Node
 *
 * In other case user:
 * t(() => raceModel.name();                     | React.Node
 *
 * It can be any that can be called without arguments
 * t(() => 'Message that will be rerendered');   | React.Node
 *
 * Format static translation as string
 * t.staticAsString('key.key')                   | string
 * t.staticAsString('key.key', { name: 'name' }) | string, with formatted string via formatUnicorn
 *
 */
function t(value: TranslationLockedKeys | Function, params?: AnyObject, processText?: processTextType) {
  if (isFunction(value)) {
    return dynamicTranslation(value);
  }

  if (isString(value)) {
    return staticTranslation(value, params, processText);
  }

  return null;
}

t.dynamic = dynamicTranslation;
t.static = staticTranslation;
t.staticAsString = translate;

export { t };

import { cloneDeep } from 'lodash';
import { Options } from 'modules/CouponsForm/stores';

import { Race } from 'models';

export const mapCouponRaces = (data: RaceType[]): Options[] => {
  const copy = cloneDeep(data);

  return copy.map((race) => {
    const model = new Race(race);
    return {
      id: race.id,
      key: race.id,
      label: model.name(),
    };
  });
};

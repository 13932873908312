import { Typography } from '@mui/material';
import Axios from 'axios';
import { AuthService } from 'modules/Auth';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Input } from 'components/Fields/Input';
import { AuthSubmit } from 'components/core/Buttons';

import { t } from 'utils/t';

type Props = {};

function OutbountToken({}: Props) {
  const [resetToken, setResetToken] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const location = useLocation();
  useEffect(() => {
    const { token } = qs.parse(location.search);
    if (token) setResetToken(token as string);
    return () => {};
  }, []);

  const onChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    setNewPassword(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const user = await Axios.post('/api/v1/admin/organizers/set-password', {
        token: resetToken,
        password: newPassword,
      });
      const authService = new AuthService();
      authService.login(user.data.data.email, newPassword);
    } catch (error) {
      console.log(error);
    }
  };

  const defaultInputProps = (name: string): any => ({
    name,
    onChange: onChange,
    id: 'new-password',
    value: newPassword,
    errors: '',
    label: `${t.staticAsString(`login.${name}` as TranslationLockedKeys)}*`,
  });

  return (
    <div>
      <Typography variant='h6' style={{ color: 'white', marginBottom: 24 }}>
        Welcome to the RaceID Super Admin! Set your password to access your race.
      </Typography>
      <form className='login' onSubmit={onSubmit}>
        <Input {...defaultInputProps('password')} type='password' />
        <AuthSubmit label={'Continue'} />
      </form>
    </div>
  );
}

export { OutbountToken };

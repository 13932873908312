import axios from 'axios';
import { generatePath } from 'react-router-dom';

import {
  ORGANIZERS_URL,
  SYSTEM_USER_EXPORT_URL,
  SYSTEM_USER_PAYMENT_EXPORT_URL,
  SYSTEM_USER_ROLES_URL,
  SYSTEM_USER_URL,
  SYSTEM_USERS_URL,
} from 'src/constants';

import { action, request } from 'utils';

import {
  DELETE_SYSTEM_USER,
  EXPORT_SYSTEM_USER,
  FAKE_LOAD_SYSTEM_USER,
  LOAD_ORGANIZERS,
  LOAD_SYSTEM_USER,
  LOAD_SYSTEM_USER_ROLES,
  LOAD_SYSTEM_USERS,
  PERSIST_SYSTEM_USER,
} from '../constants';

import { loadSystemUsers } from '../actions';

import { systemUsersStore } from '../stores';

import { SystemUser, SystemUser as SystemUserType } from '../types';

class SystemUsersService implements ExtensionableService<any>, ExtensionableSearchService, ExtensionableSortService {
  @request({ action: LOAD_SYSTEM_USER_ROLES })
  loadSystemUserRolesRequest(): any {
    const path = generatePath(SYSTEM_USER_ROLES_URL);
    return axios.get(path);
  }

  @action({ action: LOAD_SYSTEM_USER_ROLES })
  async loadSystemUserRoles() {
    const [status, response] = await this.loadSystemUserRolesRequest();

    return [status, response.data.data];
  }
  @request({ action: LOAD_ORGANIZERS })
  loadOrganizersRequest(params?: FiltersType): any {
    const path = generatePath(ORGANIZERS_URL);
    return axios.get(path, { params });
  }

  @action({ action: LOAD_ORGANIZERS })
  async loadOrganizers(params?: FiltersType) {
    const [status, response] = await this.loadOrganizersRequest(params);

    return [status, response.data.data];
  }

  @request({ action: LOAD_SYSTEM_USERS })
  loadSystemUsersRequest(params?: FiltersType): any {
    const path = generatePath(SYSTEM_USERS_URL);
    return axios.get(path, { params });
  }

  @action({ action: LOAD_SYSTEM_USERS })
  async loadSystemUsers(params?: FiltersType) {
    const [status, response] = await this.loadSystemUsersRequest(params);

    return [status, response.data];
  }

  @request({ action: LOAD_SYSTEM_USER })
  loadSystemUserRequest(id: number, params?: FiltersType): any {
    const url = generatePath(SYSTEM_USER_URL, { id });
    return axios.get(url, { params });
  }

  @action({ action: LOAD_SYSTEM_USER })
  async loadSystemUser(id: number, params?: FiltersType) {
    const [status, response] = await this.loadSystemUserRequest(id, params);
    return [status, response.data.data];
  }

  @request({ action: PERSIST_SYSTEM_USER })
  async createSysUserRequest(systemUser: AnyObject): Promise<any> {
    return axios.post(SYSTEM_USERS_URL, systemUser);
  }

  @action({ action: PERSIST_SYSTEM_USER })
  async createSysUser(systemUser: AnyObject) {
    const [status, response] = await this.createSysUserRequest(systemUser);

    return [status, response.data.data];
  }

  @request({ action: PERSIST_SYSTEM_USER })
  async updateSysUserRequest(systemUser: AnyObject): Promise<any> {
    const url = generatePath(SYSTEM_USER_URL, { id: systemUser.id });
    return axios.patch(url, systemUser);
  }

  @action({ action: PERSIST_SYSTEM_USER })
  async updateSysUser(systemUser: AnyObject) {
    const [status, response] = await this.updateSysUserRequest(systemUser);

    return [status, response.data.data];
  }

  @action({ action: FAKE_LOAD_SYSTEM_USER, minRequestTime: 800 })
  async fakeLoadResource(): Promise<any> {}

  @request({ action: DELETE_SYSTEM_USER })
  deleteValueRequest(id: number): any {
    const url = generatePath(SYSTEM_USER_URL, { id });
    return axios.delete(url);
  }

  @action({ action: DELETE_SYSTEM_USER })
  async deleteValue(id: number): Promise<any> {
    const [status] = await this.deleteValueRequest(id);
    return status;
  }

  @request({ action: EXPORT_SYSTEM_USER })
  exportDataRequest(params: FiltersType): Promise<any> {
    return axios.get(SYSTEM_USER_EXPORT_URL, {
      params,
    });
  }

  @action({ action: EXPORT_SYSTEM_USER })
  async exportData(params: FiltersType): Promise<string | nil> {
    const [status, response] = await this.exportDataRequest(params);

    if (status) {
      return response.data.url;
    }
  }

  @request({ action: EXPORT_SYSTEM_USER })
  exportPaymentRequest(organizerId: number, id: number): Promise<any> {
    const url = generatePath(SYSTEM_USER_PAYMENT_EXPORT_URL, {
      organizerId,
      id,
    });
    return axios.get(url);
  }

  @action({ action: EXPORT_SYSTEM_USER })
  async exportPayment(id: number): Promise<string | nil> {
    const { selected } = systemUsersStore;

    if (!selected) {
      return;
    }

    const organizerId = selected.id;
    const [status, response] = await this.exportPaymentRequest(organizerId, id);

    if (status) {
      return response.data.url;
    }
  }

  get store(): ILoadable<SystemUser, FiltersType> & any {
    return systemUsersStore;
  }

  @action({ action: LOAD_SYSTEM_USERS })
  async load(params?: { limit?: number; page?: number }): Promise<void> {
    await loadSystemUsers(params);
  }
}

const systemUsersService = new SystemUsersService();
export { SystemUsersService, systemUsersService };

import { ResultsContextType } from '../context';

function formatClassId(classId: nil | number | string, context: ResultsContextType): string | null {
  const distanceModel = context.results.stores.distanceStore.modelSelected;

  if (!distanceModel) {
    return null;
  }

  const classModel = distanceModel.findRelationModelValue('classes', +(classId as number));

  if (!classModel) {
    return null;
  }

  return classModel.label();
}

export { formatClassId };

import { mapToFormAttachToProfile } from 'modules/StartList/mapper';
import { attachToProfileService } from 'modules/StartList/services';

import { form } from 'stores';

import { ATTACH_TO_PROFILE_FORM } from '../constants';

import { confirmStore, startlistMembersStore, startlistRacersStore } from '../stores';

export const openConfirmModal = (): void => {
  confirmStore.set({
    ...(confirmStore.value as any),
    open: true,
  });
};

export const closeConfirmModal = (): void => {
  confirmStore.set({
    open: false,
    title: '',
    content: '',
  });
};

export const setConfirmTitle = (text: string): void => {
  confirmStore.set({
    ...(confirmStore.value as any),
    title: text,
  });
};

export const setConfirmContent = (text: string): void => {
  confirmStore.set({
    ...(confirmStore.value as any),
    content: text,
  });
};

export const onConfirmModal = async () => {
  const attachToProfileForm: AnyObject = form.fetchWithoutObservable(ATTACH_TO_PROFILE_FORM);
  await confirmAttachToProfile(attachToProfileForm);
};

const confirmAttachToProfile = async (actionForm: AnyObject) => {
  const params = mapToFormAttachToProfile(actionForm);
  const racerId = actionForm.sourceEmail?.key;
  const store = actionForm.isTeamMember ? startlistMembersStore : startlistRacersStore;
  const userId = await attachToProfileService.sendAttachToProfile(params, racerId);

  const racer = store.values.find((item) => item.id === racerId);
  if (userId && racer) {
    store.updateValue({ ...racer, user_id: userId });
  }
};

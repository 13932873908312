import { reaction } from 'mobx';

import { form } from 'src/stores';

import { REGISTRATION_LINKS_FORM_ID } from '../constants';

import { disposersStore } from '../stores';

export const observeWaveField = () => {
  const disposer = reaction(
    () => form.fetch(REGISTRATION_LINKS_FORM_ID, 'wave_id'),
    () => {
      form.onChange(REGISTRATION_LINKS_FORM_ID, 'max_usages', null);
    },
  );

  disposersStore.register(disposer);
};

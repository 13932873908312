import { defaultListFilters, t } from 'utils';

import { FilterDescriptorWrapper } from 'models';

import { state } from '../shared';
import { classId } from './classId';
import { countryId } from './countryId';
import { disciplineId } from './disciplineId';
import { gender } from './gender';
import { nationalityId } from './nationalityId';
import { squads } from './squads';
import { waveId } from './waveId';

// TEAMS
// Team Startist filters

type RacersDescriptors =
  | 'comment'
  | 'external_swimrun_id'
  | 'added_at'
  | 'added_by'
  | 'bib_number'
  | 'team.name'
  | 'firstname'
  | 'lastname'
  | 'email'
  | 'gender'
  | 'address'
  | 'country'
  | 'city'
  | 'wave_id'
  | 'class_id'
  | 'discipline_id'
  | 'country_id'
  | 'nationality_id'
  | 'post_code'
  | 'company'
  | 'union'
  | 'squad_id'
  | 'birthday'
  | 'telephone'
  | 'shoe_size'
  | 'shirt_size'
  | 'emergency_name'
  | 'emergency_phone'
  | 'order_id'
  | 'order_total'
  | 'order_refund_protect_amount'
  | 'order_coupon_codes'
  | 'order_created_at'
  | 'order_status';
type Cols = RacersDescriptors | 'order.amount_for_custom_fields';

export const racerFields: {
  [K in Cols]: string;
} = {
  bib_number: 'bib_number',
  'team.name': 'team.name',
  firstname: 'firstname',
  lastname: 'lastname',
  email: 'email',
  gender: 'gender',
  address: 'address',
  country: 'country',
  city: 'city',
  wave_id: 'wave_id',
  class_id: 'class_id',
  discipline_id: 'discipline_id',
  country_id: 'country_id',
  nationality_id: 'nationality_id',
  post_code: 'post_code',
  company: 'company',
  union: 'union',
  squad_id: 'squad_id',
  birthday: 'birthday',
  telephone: 'telephone',
  shoe_size: 'shoe_size',
  shirt_size: 'shirt_size',
  emergency_name: 'emergency_name',
  emergency_phone: 'emergency_phone',
  order_id: 'order_id',
  order_total: 'order_total',
  order_refund_protect_amount: 'order_refund_protect_amount',
  order_coupon_codes: 'order_coupon_codes',
  order_created_at: 'order_created_at',
  order_status: 'order_status',
  'order.amount_for_custom_fields': 'order.amount_for_custom_fields',
  added_at: 'added_at',
  added_by: 'added_by',
  comment: 'comment',
  external_swimrun_id: 'external_swimrun_id',
};

const descriptors: {
  [K in RacersDescriptors]: FilterListDescriptor;
} = {
  bib_number: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      default: true,
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  added_at: {
    filter: defaultListFilters.dateRange,
    frontend: { type: 'dateRange' },
    sort: true,
  },

  added_by: {
    filter: defaultListFilters.none,
  },

  'team.name': {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  email: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  firstname: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  lastname: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  gender: gender,

  address: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  country: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  city: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  wave_id: waveId,
  class_id: classId,
  discipline_id: disciplineId,
  country_id: countryId,
  nationality_id: nationalityId,

  post_code: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  company: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  union: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  squad_id: squads,

  birthday: {
    filter: defaultListFilters.dateRange,
    frontend: {
      type: 'dateRange',
      props: () => ({ withYearSelect: true, dateFormat: 'datetime' }),
    },
  },

  telephone: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  shoe_size: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  shirt_size: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  emergency_name: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  emergency_phone: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  order_id: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      calcOrderBy: () => 'id',
      reorderOnSearch: true,
    },
  },

  order_coupon_codes: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  order_created_at: {
    filter: defaultListFilters.dateRange,
    frontend: { type: 'dateRange' },
    sort: {
      calcOrderBy: () => 'order_created_at',
      reorderOnSearch: true,
    },
  },

  order_total: {
    filter: defaultListFilters.priceRange,
    frontend: { type: 'numberRange' },
    sort: {
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  order_status: state,

  comment: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  external_swimrun_id: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },
  order_refund_protect_amount: {
    filter: defaultListFilters.priceRange,
    frontend: { type: 'numberRange', props: () => ({ description: t.staticAsString('startlist.enterPriceRange') }) },
    sort: {
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },
};

export const racersFiltersDescriptors = Object.keys(descriptors).reduce((acc, desc) => {
  return { ...acc, [desc]: new FilterDescriptorWrapper(desc, descriptors[desc as RacersDescriptors]) };
}, {});

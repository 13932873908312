import { ROUTES } from 'src/constants';

import { t, history } from 'utils';

import { toastStore } from 'stores';

import { raceDetailsService } from '../services';

import { raceDetailsStore } from '../stores';

export const deleteRaceAction = async (id: number) => {
  const { raceDetails } = raceDetailsStore;

  if (!raceDetails) {
    return;
  }

  const status = await raceDetailsService.deleteRaceDetails();
  if (status) {
    raceDetailsStore.clearData();

    history.push(ROUTES.racesRoute);

    toastStore.show(t.staticAsString('races.deleteSuccess'));
  }
};

import { CheckpointStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores/CheckpointsStore';
import { CheckPoint, GpxGoogleMap, GpxRoute } from 'src/modules/Distances/components/shared/Steps/GPX/types';

import { BooleanStore, DummyStore } from 'stores';

export const gpxRouteLoadingStore = new BooleanStore();
export const openCheckPointsModalStore = new BooleanStore();
export const isCheckedStore = new BooleanStore();

export const gpxStore = new DummyStore<GpxRoute>();
export const gpxGoogleMapStore = new DummyStore<GpxGoogleMap>();
export const checkPointStore = new CheckpointStore<Array<CheckPoint>>([]);

export const markersStore = new DummyStore<Array<any>>([]);

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import * as React from 'react';

import { Show } from 'components/Condition';

import { t } from 'utils';

type Props = {
  onClear: AnyFunction;
  onApply: AnyFunction;

  displayClear: boolean;
  displayApply: boolean;
};

type State = {};

const BoxStyles = {
  display: 'flex',
  justifyContent: 'space-between',

  padding: '21px',
  paddingTop: 0,
};

const StyledButtonApply = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.textPrimary,
  },
}))(Button);

export class ButtonGroup extends React.Component<Props, State> {
  render() {
    const { onClear, onApply, displayApply, displayClear } = this.props;

    return (
      <Box sx={BoxStyles}>
        <Show if={displayClear}>
          <Button onClick={onClear}>{t('modal.clear')}</Button>
        </Show>

        <Show if={displayApply}>
          <StyledButtonApply onClick={onApply} color='primary'>
            Apply
          </StyledButtonApply>
        </Show>
      </Box>
    );
  }
}

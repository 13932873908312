import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem, TableCell, TableRow } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { couponListService } from 'modules/Coupons/services';
import * as React from 'react';
import { generatePath, Link } from 'react-router-dom';

import { ROUTES } from 'src/constants/routes';

import { DateRange } from 'components/TimeRange';

import { t } from 'utils';

import { Coupon as CouponModel } from 'models';

type Props = {
  item: CouponModel;
  editCoupon: (coupon: CouponType) => void;
  deleteCoupon: (couponId: number) => void;
};

const DeleteMenuItem = withStyles((theme) => ({
  root: {
    color: theme.palette.redCE,
  },
}))(MenuItem);

const EditMenuItem = withStyles(() => ({
  root: {
    background: 'white',
  },
}))(MenuItem);

class Item extends React.Component<Props> {
  state = {
    actionMenuAnchorEl: null,
  };

  handleOpenCouponActionMenu = (event: React.MouseEvent<SVGSVGElement>) => this.setState({ actionMenuAnchorEl: event.currentTarget });
  handleCloseCouponActionMenu = () => this.setState({ actionMenuAnchorEl: null });
  handleEditCoupon = () => {
    const {
      item: { value },
    } = this.props;
    this.handleCloseCouponActionMenu();
    this.props.editCoupon(value);
  };

  handleDeleteCoupon = () => {
    const {
      item: {
        value: { id },
      },
    } = this.props;
    this.handleCloseCouponActionMenu();
    this.props.deleteCoupon(id);
  };

  renderAvailabilityMenu = () => {
    const {
      item: { value },
    } = this.props;
    if (value.disabled) return t('coupons.list.makeAvailable');
    return t('coupons.list.makeUnavailable');
  };

  handleToggleAvailability = () => {
    const {
      item: { value },
    } = this.props;
    this.handleCloseCouponActionMenu();
    couponListService.updateCouponAvailability(value);
  };

  render() {
    const { actionMenuAnchorEl } = this.state;
    const { item } = this.props;
    const jsonData = item.value;

    const distanceNames = jsonData.type === 1 ? t.staticAsString('coupons.allDistances') : item.distanceNames();

    return (
      <TableRow className='table-row'>
        <TableCell className={classNames('cell')}>
          <div className='acions-for-list'>
            <MoreVert onClick={this.handleOpenCouponActionMenu} />
            <Menu
              id='simple-menu'
              anchorEl={actionMenuAnchorEl}
              keepMounted
              open={Boolean(actionMenuAnchorEl)}
              onClose={this.handleCloseCouponActionMenu}
            >
              <EditMenuItem onClick={this.handleEditCoupon}>{t.staticAsString('coupons.EditCouponCode')}</EditMenuItem>
              <DeleteMenuItem onClick={this.handleDeleteCoupon}>{t.staticAsString('coupons.deleteCouponCode')}</DeleteMenuItem>
              {/* //! Note, will have to be uncommented later, do not delete */}
              {/* <MenuItem onClick={this.handleToggleAvailability}>{this.renderAvailabilityMenu()}</MenuItem> */}
            </Menu>
          </div>
        </TableCell>
        <TableCell className={classNames('cell cell-nummeric')}>{jsonData.id}</TableCell>
        <TableCell className={classNames('cell')}>
          <Link className='label' to={generatePath(ROUTES.couponRoute, { id: jsonData.id })}>
            {jsonData.name}
          </Link>
        </TableCell>
        <TableCell className={classNames('cell')}>{jsonData.code}</TableCell>
        <TableCell className={classNames('cell cell-nummeric')}>{jsonData.qty}</TableCell>
        <TableCell className={classNames('cell cell-nummeric')}>{jsonData.left}</TableCell>
        <TableCell className={classNames('cell')}>{item.discountAmount()}</TableCell>
        <TableCell className={classNames('cell')}>{item.raceName()}</TableCell>
        <TableCell className={classNames('cell cell-distances')}>{distanceNames}</TableCell>
        <TableCell className={classNames('cell cell-description')}>
          <DateRange from={jsonData.date_from} to={jsonData.date_to} />
        </TableCell>
        <TableCell className={classNames('cell')}>{item.disabled()}</TableCell>
        <TableCell className={classNames('cell cell-description coupon-description')}>{jsonData.description}</TableCell>
      </TableRow>
    );
  }
}

export { Item };

import type { Assistant } from 'modules/Distances/types';

export const getAssistantLabel = (assistant: Assistant | MedicalAssistantType) => {
  const { firstname = '', lastname = '', email = '' } = assistant;

  // @ts-ignore
  const full_name = assistant.full_name || '';

  const fullname = full_name || `${firstname || ''} ${lastname || ''}`.trim();

  return fullname ? `${fullname} (${email || ''})` : `${email}`;
};

import React from 'react';

import { RangeFilter } from '../DefaultFilters';

type Props = {} & FilterProps;

const NumberRangeComponent = (props: Props) => {
  let value = props.value;

  if (!value) {
    value = [null, null];
  }

  return <RangeFilter {...props} value={value} type='number' />;
};

const NumberRange = NumberRangeComponent;

export { NumberRange };

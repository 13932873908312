import { currenciesStore } from 'stores';

import { ReportStore } from '../stores/report.store';

export function formatCurrency(amount: number | string): string {
  return `${amount} ${reportCurrency()}`;
}

export function reportCurrency() {
  return currenciesStore.currencyById(ReportStore.report.value?.race.currency_id ?? 0)?.iso_code;
}

import axios from 'axios';

import { SPORT_TYPE, SPORT_TYPES_URL, ROUTES } from 'src/constants';
import { formatData } from 'src/modules/SportTypes/utils';

import { request, action } from 'utils';
import { history, t } from 'utils';

import { SportType } from 'models';

import { sportTypesStore } from 'stores';
import { toastStore } from 'stores';

class NewSportType {
  @request({ action: `CREATE_${SPORT_TYPE}` })
  createRequest(data: FormData): any {
    return axios.post(SPORT_TYPES_URL, data);
  }

  @action({ action: `CREATE_${SPORT_TYPE}` })
  async create(json: {}): Promise<any> {
    const data = formatData(json);
    const [status, response] = await this.createRequest(data);
    if (status) {
      const value = response.data.data;
      const totalPages = sportTypesStore.paginationMeta.total_pages;
      if (sportTypesStore.page >= (totalPages || 0) && totalPages) sportTypesStore.addValue(value, 'end');

      const sportType = new SportType(value);

      toastStore.show(t.staticAsString('categories.successCreate', { title: sportType.title() }));

      history.push(`${ROUTES.sportTypesRoute}/${value.id}`);
    }
  }
}

export { NewSportType };
export const newSportTypeService = new NewSportType();

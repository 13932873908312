import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { EXPORT_IMPORT_START_LIST_TEMPLATE_URL } from 'src/constants';

import { action, request } from 'utils';

import { IMPORT_START_LIST } from '../constants';

class ImportService {
  @request({ action: IMPORT_START_LIST })
  importRequest(distanceId: number, data: FormData): any {
    const url = generatePath(EXPORT_IMPORT_START_LIST_TEMPLATE_URL, { distanceId });
    return axios.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  @action({ action: IMPORT_START_LIST })
  async import(distanceId: number, data: FormData): Promise<any> {
    const responseData = await this.importRequest(distanceId, data);

    return responseData;
  }
}

const importService = new ImportService();
export { importService, ImportService };

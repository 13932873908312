import { messagesStore } from 'stores/messages';

import { formatUnicorn } from './formatUnicorn';

/*
 * NOTE
 * fetch key from translations json
 * process message with params and formatUnicorn for replacing keys with values
 */

function translate(id: TranslationLockedKeys, params?: AnyObject): string {
  const translations = messagesStore.value;
  const message = translations && translations[id];

  if (!params) {
    return message || id || '';
  }

  return formatUnicorn(message || '', params) || id || '';
}

export { translate };

import Dialog from '@mui/material/Dialog';
import withStyles from '@mui/styles/withStyles';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Loading } from 'components/Loading';

import { t } from 'utils';

import { COUPON, SUPPORT_TASK_MANAGER_FORM_INIT } from '../../constants';

import { activeActionView } from '../../utils';

import { closeForm } from '../../actions';

import { modalStore } from '../../stores';

import { TitleModal, Fields, ModalBottom } from '.';

type Props = {};

const DialogStyled = withStyles({
  paper: {
    width: 770,
  },
})(Dialog);

@observer
export class TaskFormModal extends React.Component<Props> {
  render() {
    const open: boolean = modalStore.value?.open || false;
    {
      t.staticAsString('supportTaskManager.form.title');
    }

    const getModalTitle = (): string => {
      const key: string = activeActionView(COUPON) ? 'titleAddNewCoupon' : 'title';
      return t.staticAsString(`supportTaskManager.form.${key}` as TranslationLockedKeys);
    };

    return (
      <DialogStyled open={open} onClose={closeForm} maxWidth='md'>
        <Loading action={SUPPORT_TASK_MANAGER_FORM_INIT} />
        <TitleModal title={getModalTitle()} />
        <Fields />
        <ModalBottom />
      </DialogStyled>
    );
  }
}

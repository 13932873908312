import { computed, observable, action, makeObservable, override } from 'mobx';

import { SearchSerializer } from 'utils';

import { Customer as CustomerModel } from 'models';

import { Loadable } from './loadable';

class Customer extends Loadable<CustomerType, FiltersType> {
  defaultFilters: FiltersType = {
    with: 'country',
    limit: 30,
    searchJoin: 'AND',
    search: '',
    searchFields: '',
    orderBy: 'id',
    sortedBy: 'DESC',
  };

  declare filters: FiltersType;

  declare resourceParams: FiltersType;

  @observable
  handlers = {
    id: 'between',
    full_name: 'like',
    email: 'like',
    telephone: 'like',
    post_code: 'like',
    country_id: 'like',
    created_at: 'between',
  };

  static allowBlank: string[] = [];

  constructor() {
    super();

    makeObservable(this);
    this.filters = this.defaultFilters;
    this.resourceParams = {
      with: '',
    };
  }

  @computed
  get modelValues(): Array<CustomerModel> {
    return this.values.map((race) => new CustomerModel(race));
  }

  @computed
  get modelSelected(): nil | CustomerModel {
    if (this.selected) {
      return new CustomerModel(this.selected);
    } else {
      return null;
    }
  }

  @action
  updateSearch(search: string, searchFields: string): void {
    if (!search) {
      this.filters.search = null as any;
      this.filters.searchFields = null as any;
      delete this.filters.search;
      delete this.filters.searchFields;
    } else {
      this.filters.search = search;
      this.filters.searchFields = searchFields;
    }
  }

  @action
  updateSearchHandlers(
    newHandlers: {
      [K in string]: string;
    },
  ) {
    this.handlers = {
      ...this.handlers,
      ...newHandlers,
    };
  }

  @computed
  get searchDataModel(): SearchSerializer {
    const search = this.filters.search as any;
    return new SearchSerializer(search, this.handlers, Customer.allowBlank);
  }

  retrieveSearchDataModel(): SearchSerializer {
    return this.searchDataModel.clone();
  }

  @override
  clearAll() {
    super.clearAll();
    this.filters = this.defaultFilters;
  }
}

export { Customer };
export default new Customer();

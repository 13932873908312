import classNames from 'classnames';
import React, { CSSProperties } from 'react';

type spinnerType = 'cubesSpinner' | 'cubesSpinnerSmall';

const spinners = {
  cubesSpinner: (
    <div className='cubes-spinner'>
      <div className='cube1'></div>
      <div className='cube2'></div>
    </div>
  ),
  cubesSpinnerSmall: (
    <div className='cubes-spinner small'>
      <div className='cube1'></div>
      <div className='cube2'></div>
    </div>
  ),
};

const Spinner = ({ type, className, style }: { type: spinnerType; className?: string; style?: CSSProperties }) => {
  if (!spinners[type]) {
    console.warn(`Current spinner is not found - ${type}`);
  }
  return (
    <div className={classNames('spinner', className)} style={style}>
      {spinners[type]}
    </div>
  );
};

interface SpinnerTypes {
  [propName: string]: spinnerType;
}

const types: SpinnerTypes = {
  CUBES_SPINNER: 'cubesSpinner',
  default: 'cubesSpinner',
};

Spinner.types = types;

export { Spinner };

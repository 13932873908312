import { observer, inject } from 'mobx-react';
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import { START_LIST } from 'src/constants';

import { SelectField } from 'components/Fields/SelectField';

import { gender } from 'utils';

import { Country as CountryStore, squadsStore } from 'stores';
import { Errors as ErrorsStore } from 'stores';

import { membersTeamStore } from '../../stores';

type Props = {
  item: RacerType;
  field: string;
  errorsStore: ErrorsStore;
  distance: DistanceType;
  onChange: (name: string, value: any, callback: Function) => void;
  countriesStore: CountryStore;
  errors: ErrorsStore;
  saveRacer: (field: string) => void;
  loadMore?: Function;
  hasMore?: boolean;
  onRemove?: AnyFunction;
};

const action = `UPDATE_${START_LIST}`;

@inject('countriesStore', 'errorsStore')
@onClickOutside
@observer
class Select extends React.Component<Props> {
  id = 'startlist-select-field';
  closeTimeoutId: number | null = null;

  save = () => {
    const { saveRacer, field } = this.props;
    saveRacer(field);
  };

  saveTimeout = () => {
    this.closeTimeoutId = setTimeout(() => this.save(), 100);
  };

  stopSaveTimeout = () => {
    this.closeTimeoutId && clearTimeout(this.closeTimeoutId);
  };

  handleClickOutside = (evt: React.FocusEvent) => {
    const popoverContainer = document.getElementById(`menu-${this.id}`);

    if (!popoverContainer || !evt.target) {
      return this.save();
    }

    if (popoverContainer.contains(evt.target)) {
      return;
    }

    this.save();
  };

  onClose = () => {
    this.stopSaveTimeout();
    this.saveTimeout();
  };

  onChange = (e: any) => {
    const { onChange, field } = this.props;
    const value = e.target.value;

    this.stopSaveTimeout();

    onChange(field, value, () => {
      this.saveTimeout();
    });
  };

  convertForSelect = (options: Array<any> = [], label: string = 'value'): Array<any> => {
    return options.map((option) => ({
      key: `${this.props.field}_${option.id}`,
      value: option['id'],
      label: option[label === 'country' ? 'short_name' : label] || '',
    }));
  };

  errors = () => {
    const { errorsStore } = this.props;
    const errors = errorsStore.errors.api[action];
    const validationErrors = errorsStore.errors.validation[action];

    if (validationErrors) {
      return validationErrors.formatErrors();
    }
    if (errors) {
      return errors.formatErrors();
    }
    return {};
  };

  genderOptions = () => {
    return gender.options();
  };

  wave_idOptions = () => {
    const { waves } = this.props.distance;
    return this.convertForSelect(waves, 'name');
  };

  price_idOptions = () => {
    const { prices } = this.props.distance;
    return this.convertForSelect(prices, 'value');
  };

  class_idOptions = () => {
    const { classes } = this.props.distance;
    return this.convertForSelect(classes, 'title');
  };

  discipline_idOptions = () => {
    const { disciplines } = this.props.distance;
    return this.convertForSelect(disciplines, 'title');
  };

  team_idOptions = () => {
    return this.convertForSelect(membersTeamStore.values, 'name');
  };

  squad_idOptions = () => {
    return squadsStore.valuesForDropdown();
  };

  country_idOptions = () => {
    return this.props.countriesStore.valuesForCountryDropdown();
  };

  nationality_idOptions = () => {
    return this.props.countriesStore.valuesForNationalityDropdown();
  };

  getOptions = (field) => {
    const OPTIONS = {
      gender: this.genderOptions(),
      wave_id: this.wave_idOptions(),
      price_id: this.price_idOptions(),
      class_id: this.class_idOptions(),
      discipline_id: this.discipline_idOptions(),
      team_id: this.team_idOptions(),
      squad_id: this.squad_idOptions(),
      country_id: this.country_idOptions(),
      nationality_id: this.nationality_idOptions(),
    };
    return OPTIONS[field];
  };

  render() {
    const { item, field, loadMore, hasMore, onRemove } = this.props;

    const options = this.getOptions(field);
    const errors = this.errors();
    let fieldString = field;

    if (field === 'country') {
      fieldString = `${fieldString}_id`;
    }

    let value = (item as AnyObject)[fieldString];
    const className = field === 'country_id' || field === 'nationality_id' ? 'country-nationality-li' : '';

    return (
      <SelectField
        name={this.id}
        value={value}
        onChange={this.onChange}
        onClose={this.onClose}
        options={options}
        errors={errors[field]}
        fullWidth={true}
        classNameForItem={className}
        {...(loadMore ? { hasMore, loadMore } : {})}
        onRemove={onRemove}
      />
    );
  }
}

export { Select };

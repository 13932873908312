import DialogTitle from '@mui/material/DialogTitle';
import { withStyles } from '@mui/styles';
import * as React from 'react';

type Props = {
  title: string;
};

const DialogTitleStyled = withStyles({
  root: {
    fontSize: 20,
    borderBottom: '1px solid #e0e0e0',
    fontWeight: 500,
    marginBottom: 24,
  },
})(DialogTitle);

export class TitleModal extends React.Component<Props> {
  render() {
    const { title } = this.props;

    return <DialogTitleStyled>{title}</DialogTitleStyled>;
  }
}

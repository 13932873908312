import styled from 'styled-components';

import { Button } from 'src/components/core';

import { t } from 'utils/t';

import { handleCreateNew } from '../../actions';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 100%;
  height: 100%;

  background: ${(props) => props.theme.palette.greyF5};
`;

const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  padding: 20px 24px;

  font-size: 14px;
  color: ${(props) => props.theme.palette.text.secondary};
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 538px;
  padding: 43px 0;
  background: white;

  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  margin-bottom: 16px;
`;

const SubTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  margin-bottom: 48px;
`;

const StyledButton = styled(Button)`
  height: 48px;
`;

export const ListPlaceholder = () => {
  return (
    <Wrapper>
      <Header>{t.staticAsString('smartLinks.smartRegistrationLinks')}</Header>
      <Body>
        <Title>{t.staticAsString('smartLinks.createSmartLink')}</Title>
        <SubTitle>{t.staticAsString('smartLinks.noActiveLinks')}</SubTitle>
        <StyledButton type='add' onClick={handleCreateNew}>
          {t.staticAsString('smartLinks.createLink')}
        </StyledButton>
      </Body>
    </Wrapper>
  );
};

import SendIcon from '@mui/icons-material/Send';
import { Button, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import * as React from 'react';

import { Show } from 'components/Condition';
import { Icon } from 'components/Icon';

import { t } from 'utils';

type Props = {
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
  tip?: boolean;
  showNotification?: boolean;
  className?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  disabled: {
    color: theme.palette.grey10,
    background: theme.palette.grey9,
    width: '100%',
    height: '100%',
  },
  button: {
    flexDirection: 'column',
    gap: '15px',
    lineHeight: '16px',
    letterSpacing: 'unset',
  },
  redDot: {
    position: 'absolute',
    left: '-10px',
    top: '-10px',
  },
}));

const SendForApproveBtn = ({ disabled, onClick, tip, className, showNotification = false }: Props) => {
  const c = useStyles();

  if (!tip) {
    return (
      <Button disabled={disabled} onClick={onClick} variant='contained' className={classnames(className, c.button)}>
        <Show if={showNotification}>
          <Icon value={'red-dot'} className={c.redDot} />
        </Show>
        <SendIcon />
        {t.staticAsString('races.detail.approveSend.title')}
      </Button>
    );
  }

  return (
    <Tooltip title={t.staticAsString('races.detail.approveSend.tip')}>
      <span className={classnames(className, disabled ? c.disabled : '')}>
        <Button disabled={disabled} onClick={onClick} className={classnames(c.button, disabled ? c.disabled : '')}>
          <Show if={showNotification}>
            <Icon value={'red-dot'} className={c.redDot} />
          </Show>
          <SendIcon />
          {t.staticAsString('races.detail.approveSend.title')}
        </Button>
      </span>
    </Tooltip>
  );
};

export { SendForApproveBtn };

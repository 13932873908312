import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { Detail } from './Detail';
import { NewDistance } from './New';
import { UpdateDistance } from './Update';

class Distances extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path={ROUTES.newDistanceRoute} component={NewDistance} />
        <Route path={ROUTES.updateDistanceRoute} component={UpdateDistance} />
        <Route path={ROUTES.distanceRoute} component={Detail} />
      </Switch>
    );
  }
}

export { Distances };

import { Collapse } from '@mui/material';
import { observer } from 'mobx-react';
import { onChangeCheckbox, onToggleOrganizerInfo } from 'modules/Races/actions';
import { SettingsSwitch } from 'modules/Races/components/Steps/ReceiptSettings/SettingsSwitch';
import { RACE_FORM_ID } from 'modules/Races/constants';
import { receiptSettingsStore } from 'modules/Races/stores';
import React, { ReactElement } from 'react';

import { Checkbox } from 'components/Fields';
import { AutocompleteSelect, InputWrapper, SwitchWithLabel, TextField } from 'components/Form';

import { t } from 'utils/t';

import { countriesStore, form } from 'stores';

import { formStyles } from '../../styles';
import { StepHelperText, StepTitle } from '../Shared/StepTitle';
import { StepProps } from '../sharedProps';

type Props = StepProps & {};

// Sared input view option.
const view = { fullWidth: true, variant: 'outlined' };

const ReceiptSettings = observer(({ liveUpdate, step }: Props): ReactElement => {
  const classes = formStyles();
  const isReceiptSettingsEnabled = Boolean(form.fetch(RACE_FORM_ID, 'editorSettings.receiptSettings'));

  React.useEffect(() => {
    !receiptSettingsStore.value && onToggleOrganizerInfo({ name: 'toogle_organizer_info', value: true });
  }, []);

  return (
    <>
      <StepTitle step={step} />

      <div className={classes.wrapper}>
        <SettingsSwitch liveUpdate={liveUpdate} />
      </div>

      <div>
        <Collapse in={isReceiptSettingsEnabled}>
          <StepHelperText step={step} />
          <div className={classes.wrapper}>
            <InputWrapper
              name='toogle_organizer_info'
              settings={{
                view,
                label: t.staticAsString('races.new.toogle_organizer_info'),
                onChange: onToggleOrganizerInfo,
              }}
              Component={SwitchWithLabel}
            />
          </div>
          <div className={classes.wrapper}>
            <InputWrapper
              name='company_name'
              settings={{
                additional: {
                  onBlur: (e: React.FocusEvent<HTMLInputElement>) => liveUpdate(e.target.name),
                },
                view,
                label: t('races.new.company_name'),
              }}
              Component={TextField}
            />
          </div>
          <div className={classes.wrapper}>
            <InputWrapper
              name='vat_number'
              settings={{
                additional: {
                  onBlur: (e: React.FocusEvent<HTMLInputElement>) => liveUpdate(e.target.name),
                },
                view,
                label: t('races.new.vat_number'),
              }}
              Component={TextField}
            />
          </div>
          <div className={classes.wrapper}>
            <InputWrapper
              name='address'
              settings={{
                additional: {
                  onBlur: (e: React.FocusEvent<HTMLInputElement>) => liveUpdate(e.target.name),
                },
                view,
                label: t('races.new.company_address'),
              }}
              Component={TextField}
            />
          </div>
          <div className={classes.wrapper}>
            <InputWrapper
              name='post_code'
              settings={{
                additional: {
                  onBlur: (e: React.FocusEvent<HTMLInputElement>) => liveUpdate(e.target.name),
                },
                view,
                label: t('races.new.company_zipcode'),
              }}
              Component={TextField}
            />
          </div>
          <div className={classes.wrapper}>
            <InputWrapper
              name='city'
              settings={{
                additional: {
                  onBlur: (e: React.FocusEvent<HTMLInputElement>) => liveUpdate(e.target.name),
                },
                view,
                label: t('races.new.company_city'),
              }}
              Component={TextField}
            />
          </div>
          <div className={classes.wrapper}>
            <InputWrapper
              name='country_id'
              settings={{
                view: { ...view, reservePlaceForHelperText: true },
                additional: {
                  options: countriesStore.valuesForCountryDropdown(),
                  liveUpdate,
                },
                label: t('races.new.company_country'),
              }}
              Component={AutocompleteSelect}
            />
          </div>
          <div className={classes.wrapper}>
            <InputWrapper
              name='save_to_profile'
              settings={{
                label: t('races.new.saveToProfile'),
                onChange: onChangeCheckbox,
              }}
              Component={Checkbox}
            />
          </div>
        </Collapse>
      </div>
    </>
  );
});

export { ReceiptSettings };

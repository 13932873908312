import axios from 'axios';
import { generatePath } from 'react-router-dom';

import {
  AGE_DATA_URL,
  COUPONS_DATA_URL,
  GENDER_DATA_URL,
  PARTICIPANTS_DATA_URL,
  PAYMENTS_URL,
  REGISTRATIONS_DATA_URL,
  COUNTRIES_DATA_URL,
  TURNOVER_DATA_URL,
} from 'src/constants/api';

import { sessionStore } from 'stores';

import { DashboardStore } from '../stores/dashboard.store';
import { chartRangePickerStore } from '../stores/interval.store';
import { dashboardRacesStore } from '../stores/races.store';

import { mapAge } from '../mappers/age';
import { mapCountries } from '../mappers/countries';
import { mapCoupons } from '../mappers/coupons';
import { mapGender } from '../mappers/gender';
import { mapParticipants } from '../mappers/participants';
import { mapRegistrations } from '../mappers/registrations';
import { mapTurnovers } from '../mappers/turnovers';
import { RawAgeJSON } from '../types/age.type';
import { RawCouponsJSON } from '../types/coupons.type';
import { RawGenderJSON } from '../types/gender.type';
import { RawParticipantsJSON } from '../types/participants.type';
import { RawRegistrationsJSON } from '../types/registrations.type';
import { RawTurnoversJSON } from '../types/turnover.type';

export namespace DashboardService {
  export async function loadAgeData(range: number) {
    try {
      const response = await axios.post<{ data: RawAgeJSON.Data }>(generatePath(AGE_DATA_URL), {
        from: chartRangePickerStore.from,
        to: chartRangePickerStore.to,
        interval: chartRangePickerStore.chartInterval,
        range: range || 2,
        races: [dashboardRacesStore.raceId],
      });
      DashboardStore.age.set(mapAge(response.data.data, range));
    } catch (error) {
      throw error;
    } finally {
    }
  }

  export async function loadCountryData() {
    try {
      const response = await axios.post(generatePath(COUNTRIES_DATA_URL), {
        from: chartRangePickerStore.from,
        to: chartRangePickerStore.to,
        interval: chartRangePickerStore.chartInterval,
        races: [dashboardRacesStore.raceId],
      });
      DashboardStore.country.set(mapCountries(response.data.data));
    } catch (error) {
      throw error;
    } finally {
    }
  }

  export async function loadRegistrationsData() {
    try {
      const response = await axios.post<{ data: RawRegistrationsJSON.Data }>(generatePath(REGISTRATIONS_DATA_URL), {
        from: chartRangePickerStore.from,
        to: chartRangePickerStore.to,
        interval: chartRangePickerStore.chartInterval,
        races: [dashboardRacesStore.raceId],
      });
      DashboardStore.registrations.set(mapRegistrations(response.data.data));
    } catch (error) {
      throw error;
    } finally {
    }
  }

  export async function loadTurnoverData() {
    try {
      const response = await axios.post<{ data: RawTurnoversJSON.Data }>(generatePath(TURNOVER_DATA_URL), {
        from: chartRangePickerStore.from,
        to: chartRangePickerStore.to,
        interval: chartRangePickerStore.chartInterval,
        race: dashboardRacesStore.raceId,
      });
      DashboardStore.turnovers.set(mapTurnovers(response.data.data));
    } catch (error) {
      throw error;
    } finally {
    }
  }

  export async function loadParticipantsData() {
    try {
      const response = await axios.post<{ data: RawParticipantsJSON.Data }>(generatePath(PARTICIPANTS_DATA_URL), {
        from: chartRangePickerStore.from,
        to: chartRangePickerStore.to,
        interval: chartRangePickerStore.chartInterval,
        races: [dashboardRacesStore.raceId],
      });
      DashboardStore.participants.set(mapParticipants(response.data.data));
    } catch (error) {
      throw error;
    } finally {
    }
  }

  export async function loadCouponsData() {
    try {
      const response = await axios.post<{ data: RawCouponsJSON.Data }>(generatePath(COUPONS_DATA_URL), {
        from: chartRangePickerStore.from,
        to: chartRangePickerStore.to,
        interval: chartRangePickerStore.chartInterval,
        races: [dashboardRacesStore.raceId],
      });
      DashboardStore.coupons.set(mapCoupons(response.data.data));
    } catch (error) {
      throw error;
    } finally {
    }
  }

  export async function loadTransactions() {
    try {
      const response = await axios.get(generatePath(PAYMENTS_URL), {
        params: {
          organizer_id: sessionStore.user?.user_id,
          searchJoin: 'AND',
          per_page: 10,
          orderBy: 'created_at',
          sortedBy: 'desc',
        },
      });
      DashboardStore.transactions.set(response.data.data.slice(0, 10));
    } catch (error) {
      throw error;
    } finally {
    }
  }

  export async function loadGendersData() {
    try {
      const response = await axios.post<{ data: RawGenderJSON.Data }>(generatePath(GENDER_DATA_URL), {
        from: chartRangePickerStore.from,
        to: chartRangePickerStore.to,
        races: [dashboardRacesStore.raceId],
      });
      DashboardStore.genders.set(mapGender(response.data.data));
    } catch (error) {
      throw error;
    } finally {
    }
  }
}

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { withStyles } from '@mui/styles';
import * as React from 'react';

import { t } from 'utils';

import { closeIntegrationForm, onSave } from '../actions';

type Props = {};

const DialogActionsStyled = withStyles({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 24,
    paddingLeft: 24,
    borderTop: '1px solid #e0e0e0',
  },
  spacing: {
    '&>:not(:first-child)': {
      marginLeft: 16,
    },
  },
})(DialogActions);

const ButtonStyled = withStyles({
  root: {
    padding: '10px 28px',
    fontSize: 14,
    borderRadius: 2,
  },
  label: {
    height: 14,
  },
})(Button);

export class ModalBottom extends React.Component<Props> {
  render() {
    return (
      <DialogActionsStyled>
        <ButtonStyled onClick={closeIntegrationForm}>{t.staticAsString('integrations.form.cancel')}</ButtonStyled>
        <ButtonStyled variant='contained' color='primary' onClick={onSave} disableElevation>
          {t.staticAsString('integrations.form.save')}
        </ButtonStyled>
      </DialogActionsStyled>
    );
  }
}

import axios from 'axios';
import { mapCouponRaces } from 'modules/CouponsForm/utils';
import { generatePath } from 'react-router-dom';

import { EXPORT_DATA, EXPORT_DATA_SIZES, RACES_URL, REPORTS_URL, SERVICE_FEE_REPORT_URL } from 'src/constants';

import { appEnvControl } from 'utils/appEnvironment';
import { handleExport, handleExportReport, handleExportServiceFeeReport } from 'utils/monitorLoadingJob';

import { exportJobService } from 'services';

import { progressStore } from 'stores';

import { ReportsConstants } from '../constants/reports.constants';

import { filtersStore } from '../stores/filters.store';
import { reportRacesStore } from '../stores/races';

import { ReportsFilters } from '../types/filters.type';

export namespace ReportsService {
  export async function load<T>(filters?: ReportsFilters) {
    progressStore.log(ReportsConstants.ReportsForm, 'progress');
    try {
      const data = await axios.get<T>(REPORTS_URL, {
        params: {
          ...filters,
          with: 'race;organizer;file',
          per_page: 50,
        },
      });
      return data.data;
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(ReportsConstants.ReportsForm, 'completed');
    }
  }
  export async function exportReports(type: string) {
    progressStore.log(ReportsConstants.Table, 'progress');
    try {
      const [isOk, response] = await exportJobService.exportReports(type, filtersStore.getFilters());

      if (!isOk) {
        return;
      }
      const { id } = response.data.data;
      const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
      let estimatedLoadingTime = 6000 / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second
      if (6000 > maxTotal) {
        estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
      }

      handleExport({
        id,
        estimatedLoadingTime,
        fileName: `Reports.${type || 'xlsx'}`,
        onRetry: () => exportReports(type),
      });
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(ReportsConstants.Table, 'completed');
    }
  }

  export async function loadRacesRequest(): Promise<any> {
    const url = generatePath(RACES_URL);
    const params = reportRacesStore.params;
    return axios.get(url, { params });
  }
  export async function loadRaces(): Promise<void> {
    const response = await loadRacesRequest();
    if (response) {
      reportRacesStore.setRaces([...mapCouponRaces(response.data.data)]);
      reportRacesStore.setHasMore(response.data.meta.pagination.total_pages > reportRacesStore.params.page);
      reportRacesStore.setParams({ page: reportRacesStore.params.page + 1 });
    }
  }

  export async function generateReport(payload: any) {
    const response = await axios.post(REPORTS_URL, payload);
    handleExportReport({
      id: response.data.data.id,
      estimatedLoadingTime: 1000,
      fileName: `Reports.${'xlsx'}`,
      onRetry: () => generateReport(payload),
    });
  }

  export async function generateServiceFeeReport(payload: any) {
    const response = await axios.get(SERVICE_FEE_REPORT_URL, {
      params: payload,
    });
    handleExportServiceFeeReport({
      id: response.data.data.id,
      estimatedLoadingTime: 1000,
      fileName: `ServiceFee-${payload.date_from}-${payload.date_to}.${'xlsx'}`,
      onRetry: () => generateServiceFeeReport(payload),
    });
  }
}

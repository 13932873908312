import { Box, TextField } from '@mui/material';
import { ReportType } from 'modules/SystemUsers/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnWithLooseAccessor } from 'react-table';

import { currenciesStore } from 'src/stores';
import { t } from 'src/utils';

import { DatePicker } from 'components/Form';
import { Icon } from 'components/Icon';

const title = (prefix: string) => t.static(`systemUsers.reports.${prefix}` as any);

const COLUMNS = ['id', 'created_at', 'race_title', 'sum', 'action'] as const;

function NumberRangeFilter({ column: { filterValue = [], setFilter, preFilteredRows, id } }) {
  return (
    <Box display='flex' flexDirection='row'>
      <Box minWidth='80px' p={0.5}>
        <TextField
          type='number'
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => {
              return [val === '0' || val ? parseInt(val, 10) : undefined, old[1]];
            });
          }}
          value={filterValue[0] || ''}
          variant='outlined'
          size='small'
          label='From'
        />
      </Box>
      <Box minWidth='80px' p={0.5}>
        <TextField
          type='number'
          value={filterValue[1] || ''}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val === '0' || val ? parseInt(val, 10) : undefined]);
          }}
          variant='outlined'
          size='small'
          label='To'
        />
      </Box>
    </Box>
  );
}

function DateFilter(props) {
  const {
    column: { filterValue, setFilter, preFilteredRows, id },
  } = props;
  return (
    <Box minWidth='250px' p={0.5}>
      <DatePicker
        hideArrow
        view={{
          fullWidth: true,
          readOnly: true,
          variant: 'outlined',
          size: 'small',
        }}
        label=''
        name='created_at'
        value={filterValue}
        onChange={({ name, value }) => {
          setFilter(value);
        }}
        onClear={() => {
          if (id === 'created_at') {
            setFilter(null);
            setFilter(null);
          }
        }}
      />
    </Box>
  );
}

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<ReportType> & AnyObject } = {
  id: {
    id: 'id',
    Header: title('id'),
    accessor: (report) => report.id,
    Filter: NumberRangeFilter,
    filter: 'between',
    Cell: ({ row: { original } }) => (
      <Link to={`/reconciliation/reports/${original.id}`} style={{ color: 'black', textDecoration: 'underline' }}>
        {original.id}
      </Link>
    ),
  },
  created_at: {
    id: 'created_at',
    Header: title('create_at'),
    accessor: (report) => report.created_at,
    Filter: DateFilter,
  },
  race_title: {
    id: 'races.name',
    Header: title('raceName'),
    accessor: (report) => report.race.name,
  },
  sum: {
    id: 'sum',
    Header: title('sum'),
    accessor: (report) => `${report.sum} ${currenciesStore.currencyById(report.race.currency_id)?.iso_code}`,
    Filter: NumberRangeFilter,
    filter: 'between',
  },
  action: {
    id: 'report',
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    Header: title('report'),
    Cell: ({ row: { original } }) => (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <a href={original.file.url} download={original.file.name}>
          <Icon style={{ cursor: 'pointer' }} value='xls' />
        </a>
      </div>
    ),
  },
};

export function generateSystemUserColumns() {
  return COLUMNS.map((c) => RenderColumns[c]);
}

import { computed, IComputedValue } from 'mobx';

import { Distance } from 'models';

import { distanceStore } from '../stores';

const model: IComputedValue<Distance | null> = computed((): Distance | null => {
  return distanceStore.modelSelected || null;
});

const isEmpty: IComputedValue<boolean> = computed((): boolean => {
  return !!model.get();
});

function type(type: 'single' | 'team'): IComputedValue<boolean> {
  return computed(() => model.get()?.value?.type === type);
}

const currentDistance = {
  model,
  isEmpty,
  isSingle: type('single'),
  isTeam: type('team'),
};

export { currentDistance };

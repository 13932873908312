import axios, { AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';

import { TRANSACTION_URL } from 'src/constants';

import { progressStore } from 'stores';

import { TransactionConstants } from '../constants/transaction.constants';

import { transactionMapper } from '../mappers';
import { Transaction } from '../types/transaction.type';

export namespace TransactionService {
  export async function load(transactionId: number) {
    progressStore.log(TransactionConstants.TransactionForm, 'progress');
    try {
      const response = await axios.get<AxiosResponse<Transaction>>(generatePath(TRANSACTION_URL, { transactionId }), {
        params: {},
      });
      return transactionMapper(response.data.data);
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(TransactionConstants.TransactionForm, 'completed');
    }
  }
}

import { omit, cloneDeep } from 'lodash';

import { countriesStore } from 'stores';

import type { ReceiptSettingsType, ReceiptSettingsFrontendType } from '../types';

const mapToPatch = (value: ReceiptSettingsType, originalValue: ReceiptSettingsType): ReceiptSettingsType => {
  return Object.entries(value).reduce((acc, cur: any) => {
    let [key, val] = cur;
    if (originalValue[key] !== val) {
      acc[key] = val;

      if (key === 'country_id') {
        // selection component emits "undefined" when
        // trying to remove country, backend accepts null though
        acc[key] = val?.value || null;
      }
    }

    return acc;
  }, {});
};

const mapToPost = (value: ReceiptSettingsFrontendType): ReceiptSettingsType => {
  Object.keys(value).forEach((key) => {
    if (!value[key]) {
      value = omit(value, key);
      return;
    }

    if (key === 'country_id') {
      value.country_id = value.country_id?.value;
    }
  });

  return value as any;
};

const mapFrom = (value: any): ReceiptSettingsFrontendType => {
  const copy: ReceiptSettingsFrontendType = cloneDeep(value) as any;

  if (!copy) {
    return {};
  }
  const countries = countriesStore.valuesForCountryDropdown();
  // @ts-ignore
  copy.country_id = countries.find((country) => country.value === copy.country_id);

  return copy;
};

export const receiptMapper = {
  mapToPost,
  mapToPatch,
  mapFrom,
};

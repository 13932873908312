import { ExpandMore, Close, CheckCircle, Error } from '@mui/icons-material';
import { LinearProgress, Typography, IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { debounce } from 'lodash';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { Show } from 'components/Condition';

import { t } from 'utils';

import {
  linearProgressAccordionStyles,
  headerStyles,
  subheaderStyles,
  endStatusStyles,
  extraStyles,
  accordionStyles,
  accordionSummaryStyles,
  accordionDetailsStyles,
} from './styles';

type Props = {
  jobs: LoadingJobs;
  cancel: Function;
};

const debounced = debounce((x) => x, 800, { maxWait: 1000 });

export const ProgressAccordion = ({ jobs, cancel }: Props) => {
  const classes = extraStyles();
  const statusClasses = endStatusStyles();
  const accordionClasses = accordionStyles();
  const accordionSummaryClasses = accordionSummaryStyles();
  const accordionDetailsClasses = accordionDetailsStyles();
  const linearProgressClasses = linearProgressAccordionStyles();
  const headerClasses = headerStyles();
  const subheaderClasses = subheaderStyles();
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const active = Object.keys(jobs).filter((key) => !(jobs[key].isCancelled || jobs[key].isError || jobs[key].isFinished));

  const activeLoading = active.filter((key) => jobs[key].progress > 0);

  const totalProgress = active.reduce((acc, cur) => Math.floor(acc + jobs[cur].progress / active.length), 0);

  const totalRemainingTime = Math.floor(active.reduce((acc, cur) => acc + jobs[cur].remainingSec, 0));

  const remaining = () => {
    return debounced(totalRemainingTime) || totalRemainingTime;
  };

  const activeLoadingCount = activeLoading.length;

  return (
    <Observer>
      {() => {
        return (
          <div>
            <Accordion classes={accordionClasses} square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                classes={accordionSummaryClasses}
                aria-controls='panel1d-content'
                id='panel1d-header'
                expandIcon={<ExpandMore className={classes.grey} />}
              >
                <Typography className={`${headerClasses.root} ${classes.pb0}`}>
                  {t.staticAsString('shared.loadPopup.loadingMany', {
                    count: activeLoadingCount,
                    total: active.length,
                  })}
                </Typography>
                <Typography className={`${classes.grey} ${classes.font14}`}>
                  {t.staticAsString('shared.loadPopup.totalLeft', {
                    time: remaining(),
                  })}
                </Typography>
              </AccordionSummary>
              {Object.keys(jobs).map((jobKey, i) => {
                const { fileName, progress, isCancelled, isError, isFinished, upload } = jobs[jobKey];

                let statusMessage: any = '';
                let icon: React.ReactElement | nil = null;

                switch (true) {
                  case isCancelled:
                    statusMessage = upload
                      ? t.staticAsString('shared.loadPopup.uploadCancelled')
                      : t.staticAsString('shared.loadPopup.loadCancelled');

                    icon = null;
                    break;
                  case isError:
                    statusMessage = upload
                      ? t.staticAsString('shared.loadPopup.uploadError')
                      : t.staticAsString('shared.loadPopup.loadError');

                    icon = <Error className={`${classes.ml8} ${statusClasses.errorColor}`} />;
                    break;
                  case isFinished:
                    statusMessage = upload
                      ? t.staticAsString('shared.loadPopup.uploadComplete')
                      : t.staticAsString('shared.loadPopup.loadComplete');

                    icon = <CheckCircle className={`${classes.ml8} ${statusClasses.passColor}`} />;
                    break;
                  default:
                    const value = Math.floor(progress);
                    statusMessage = upload
                      ? t.staticAsString('shared.loadPopup.progressUpload', { value })
                      : t.staticAsString('shared.loadPopup.progressDownload', { value });
                    icon = (
                      <IconButton className={classes.icon} aria-label='cancel' onClick={() => cancel(jobKey)} size='large'>
                        <Close />
                      </IconButton>
                    );
                }

                return (
                  <React.Fragment>
                    <Show if={i === 0}>
                      <LinearProgress value={totalProgress} classes={linearProgressClasses} className={classes.mb4} variant='determinate' />
                    </Show>
                    <AccordionDetails classes={accordionDetailsClasses} className={classes.flexBox}>
                      <Typography className={`${classes.font16} ${classes.mr10} ${classes.primaryText}`}>{fileName}</Typography>
                      <div className={`${classes.flexBox} ${classes.grey}`}>
                        <Typography className={`${subheaderClasses.root} ${classes.grey} ${classes.pb0}`}>{statusMessage}</Typography>
                        {icon}
                      </div>
                    </AccordionDetails>
                  </React.Fragment>
                );
              })}
            </Accordion>
            <Show if={expanded !== 'panel1'}>
              <LinearProgress value={totalProgress} variant='determinate' className={`${classes.mb4} ${classes.mx1}`} />
            </Show>
          </div>
        );
      }}
    </Observer>
  );
};

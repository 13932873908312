import styled, { css } from 'styled-components';

const darkGray = '#666';
export const gray = '#A8AEB8';
export const fontFamilyRoboto = 'Roboto';

/**
 *
 * @internal
 */

const defaultStyle = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 322px;
  height: auto;
  border: 2px dashed #a8aeb8;
  box-sizing: border-box;
  padding: 12px;
  cursor: pointer;
  flex-grow: 0;
`;
export const UploaderWrapper = styled.label<any>`
  position: relative;
  ${(props) => (props.overRide ? '' : defaultStyle)};
  &.is-disabled {
    border: dashed 2px ${darkGray};
    cursor: no-drop;
    svg {
      fill: ${darkGray};
      color: ${darkGray};
      path {
        fill: ${darkGray};
        color: ${darkGray};
      }
    }
  }
  & > input {
    display: none;
  }
`;
/**
 *
 * @internal
 */
export const HoverMsg = styled.div`
  border-radius: 5px;
  background-color: rgba(249, 208, 38, 0.1);
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;
/**
 *
 * @internal
 */
export const DescriptionWrapper = styled.div<{ error: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  & > span {
    font-size: 14px;
    line-height: 20px;
    display: block;
    color: ${(props) => (props.error ? 'red' : darkGray)};
    &:first-child {
      margin-bottom: 8px;
    }
  }
  .file-types {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
  }
`;
/**
 *
 * @internal
 */
export const Description = styled.span`
  font-size: 14px;
  color: ${darkGray};
  span {
    text-decoration: underline;
    color: #dda100;
  }
`;

export const UploadTextDiv = styled.div`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${gray};
`;

export const UploadTextSnap = styled.span`
  font-family: ${fontFamilyRoboto};
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  color: ${gray} !important;
`;

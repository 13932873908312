import { observer } from 'mobx-react';
import * as React from 'react';

import { SelectFilterWithSearch } from 'components/Filter/DefaultFilters';

import { t } from 'utils';

import { systemUsersStore } from '../stores';

import type { Role } from '../types';

type Props = {
  value: string | number | nil;
} & FilterProps;

@observer
class RolesComponent extends React.Component<Props> {
  options = () => {
    return systemUsersStore.roles.map<SelectOption>((role: Role) => {
      return {
        key: role.name,
        value: role.name,
        label: t.staticAsString(`systemUsers.form.${role.name}` as TranslationLockedKeys),
      };
    });
  };

  render() {
    const { props } = this;
    return <SelectFilterWithSearch {...props} options={this.options()} />;
  }
}

export const role: FilterListDescriptor = {
  filter: {
    persistance: {
      handler: 'like',

      calcValue: function (name, value) {
        return value;
      },

      prettify: function (name, value) {
        if (!value) {
          return '';
        }
        return t.staticAsString(`systemUsers.form.${value}` as TranslationLockedKeys);
      },
    },
  },
  frontend: {
    type: 'custom',
    component: RolesComponent,
  },
};

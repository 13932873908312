import { Box } from '@mui/material';
import React, { FC } from 'react';

import { TransactionTypeEnum } from '../../../../../types/systemUserPayments-response.type';

type Props = {
  type: number;
};

export const TransactionTypeCell: FC<Props> = (props) => {
  let text = '';
  let color = '';
  switch (props.type) {
    case TransactionTypeEnum.AUTHORIZATION: {
      text = 'Authorization';
      color = '#65B7FF';
      break;
    }
    case TransactionTypeEnum.CAPTURE: {
      text = 'Capture';
      color = '#F9D026';
      break;
    }
    case TransactionTypeEnum.REFUNDED: {
      text = 'Refunded';
      color = '#A8AEB8';
      break;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Box
        sx={{
          height: 8,
          width: 8,
          backgroundColor: color,
          borderRadius: '50%',
          display: 'inline-block',
          mr: 1,
        }}
      ></Box>
      {text}
    </Box>
  );
};

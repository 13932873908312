import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { GENERATE_COUPONS } from 'src/constants';

import { action, request, t } from 'utils';

import { errorsStore, toastStore } from 'stores';

import { GENERATE_COUPON_CODE, SUPPORT_TASK_MANAGER_FORM } from '../constants';

import { mapErrorGenerateCoupons } from '../utils';

import { closeForm, mountIndex, unmountIndex } from '../actions';

class GenerateCouponsService {
  @request({ action: GENERATE_COUPON_CODE })
  private async generateRequest(params: nil | FiltersType): Promise<any> {
    return axios.post(generatePath(GENERATE_COUPONS), params);
  }

  @action({ action: GENERATE_COUPON_CODE })
  async generate(params: nil | FiltersType): Promise<any> {
    const [status, response] = await this.generateRequest(params);

    if (status) {
      closeForm();
      unmountIndex();
      mountIndex();
      toastStore.show(t.staticAsString('supportTaskManager.responseGenerateCouponOk'));
    } else {
      errorsStore.add(SUPPORT_TASK_MANAGER_FORM, mapErrorGenerateCoupons(response.data.errors));
    }
  }
}

export const generateCouponsService = new GenerateCouponsService();

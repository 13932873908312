import moment from 'moment';

import { parseDate, parseDateUtc, validateBeDate } from '../utils';

import { inputDate } from '../types/index';
import { WorkDate } from './workDate';

class InputDate<T extends inputDate> {
  value: T;
  defaultBeFormat: BEDateFormat;

  constructor(value: T, defaultBeFormat: BEDateFormat) {
    this.value = value;
    this.defaultBeFormat = defaultBeFormat;
  }

  /*
   * Parse date, from BE string, moment
   */
  parse(beFormatType?: BEDateFormat): WorkDate {
    const format = beFormatType || this.defaultBeFormat;
    const value = parseDate(this.value, format);

    const workDate = new WorkDate(value, format);

    return workDate;
  }

  /*
   * The same as parse
   * except setting local timezeon
   */
  parseFromBe(beFormatType?: BEDateFormat): WorkDate {
    const format = beFormatType || this.defaultBeFormat;
    const value = parseDateUtc(this.value, format);
    const workDate = new WorkDate(value, format);

    workDate.setLocal();

    return workDate;
  }

  /*
   * init empty now moment
   */
  now(): WorkDate {
    const value = moment();
    return new WorkDate(value, 'datetime');
  }

  isValid(beFormatType?: BEDateFormat): boolean {
    const format = beFormatType || this.defaultBeFormat;
    return validateBeDate(this.value, format);
  }

  ifIsValid(): InputDate<T> | null {
    if (!this.isValid()) {
      return null;
    }

    return this;
  }

  get val(): T {
    return this.value;
  }
}

export { InputDate };

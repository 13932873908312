import classNames from 'classnames';
import * as React from 'react';
import { ReactSVG } from 'react-svg';

function requireAll(r: any) {
  return r.keys().reduce((initial: AnyObject, acc: string | any) => {
    const label = (acc.match(/^.*\/(.*?)\..*/) || [])[1];
    return { ...{ [label]: r }, ...initial };
  }, {});
}

const constants = requireAll(require.context('img/svg/icons', true, /^.*svg$/));

// Load icons from assets/img/svg/icons dir, value == icon name without ext
const Icon = ({
  value,
  className = '',
  style = {},
  onClick = () => {},
}: {
  value: string;
  style?: any;
  className?: string;
  onClick?: React.MouseEventHandler;
}) => {
  const src = constants[value];

  if (!src) console.error(`Missing icon ${value} - ${src}`);

  const image = src && src(`./${value}.svg`);

  return <ReactSVG style={style} onClick={onClick} src={image} className={className} />;
};

type Props = {
  url: string;
  className?: string;
};

type State = {
  svg: nil | string;
  url: string;
};

// Load svg icon directly into dom, or loads as image if it is image
// TODO, replace loaded icon with new version of ReactSVG, that support async loading
class LoadedIcon extends React.Component<Props, State> {
  state: State = {
    svg: null,
    url: '',
  };

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.url !== this.state.url) this.loadImage();
  }

  loadImage = async () => {
    const { url } = this.props;

    if (!/\.svg$/.test(url)) {
      this.setState({ svg: undefined, url });
      return;
    }

    try {
      const response = await fetch(this.parseUrl());
      if (response.status !== 200) {
        return;
      }

      const plainSvg = await response.text();
      this.setState({
        ...this.state,
        svg: plainSvg,
        url,
      });
    } catch (e) {
      console.error(`Cannot load svg - ${url}`);
    }
  };

  parseUrl = (): string => {
    const { url } = this.props;
    const sourceUrl = url.match(/(?:https?:\/\/[\w.:\d]+)?(\/.*)/);
    return (sourceUrl && sourceUrl[1]) || '';
  };

  render() {
    const { svg } = this.state;
    const { className } = this.props;

    return (
      /* eslint-disable */
      <React.Fragment>
        {svg !== undefined && <div className={classNames(className)} dangerouslySetInnerHTML={{ __html: svg ? svg : '' }}></div>}
        {svg === undefined && (
          <div className={classNames(className)}>
            <img src={this.parseUrl()} alt='icon' />
          </div>
        )}
      </React.Fragment>
    );
  }
}
export { LoadedIcon, Icon };

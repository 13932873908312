import { Box, TextField } from '@mui/material';
import React from 'react';

const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter, id } }) => {
  const handleSetFilter = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setFilter(e.target.value || undefined);
  return (
    <Box p={0.5} mb={1.5}>
      <TextField fullWidth variant='outlined' size='small' value={filterValue || ''} onChange={handleSetFilter} sx={{ width: '250px' }} />
    </Box>
  );
};

export { DefaultColumnFilter };

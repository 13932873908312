import { localeStore } from 'stores';

class Checkpoint {
  value: CheckpointType;

  constructor(value: CheckpointType) {
    this.value = value;
  }
}

export { Checkpoint };

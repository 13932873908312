import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { DISTANCE_URL, RACE_DISTANCES_URL } from 'src/constants';
import { action, request } from 'src/utils';

import { DISTANCES_REG_LINKS_REQUESTS } from '../constants';

import { mapToDistanceOption } from '../utils';

import { distancesDataStore, distancesStore, distanceStore } from '../stores';

class Distances {
  @request({ action: DISTANCES_REG_LINKS_REQUESTS })
  async getDistancesRequest(raceId: number): Promise<any> {
    const url = generatePath(RACE_DISTANCES_URL, { raceId });
    const params = {
      limit: 1000,
      with: 'custom_fields.values;waves',
    };
    return axios.get(url, { params });
  }

  @action({ action: DISTANCES_REG_LINKS_REQUESTS })
  async getDistances(raceId: number) {
    const [status, response] = await this.getDistancesRequest(raceId);

    if (status) {
      distancesStore.set(mapToDistanceOption(response.data.data));
      distancesDataStore.set(response.data.data);
    }
  }

  @request({ action: shortid() })
  async getDistanceRequest(distanceId: number): Promise<any> {
    const url = generatePath(DISTANCE_URL, { distanceId });
    const params = {
      with: 'custom_fields.values;waves',
    };
    return axios.get(url, { params });
  }

  @action({ action: shortid() })
  async getDistance(distanceId: number) {
    const [isOk, response] = await this.getDistanceRequest(distanceId);

    if (isOk) {
      distanceStore.set(response.data.data);
      return mapToDistanceOption([response.data.data])[0];
    }
  }
}

export const distancesService = new Distances();

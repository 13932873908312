import { cloneDeep } from 'lodash';
import { AttachToProfileForm } from 'modules/StartList/types';

export const mapToFormAttachToProfile = (data: AnyObject): AnyObject => {
  const form: AttachToProfileForm = cloneDeep(data);

  return {
    user_id: form.targetEmail?.key,
  };
};

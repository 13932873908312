import * as React from 'react';

const withoutFooter = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  class WithoutFooterHOC extends React.Component<P> {
    componentDidMount() {
      const footer = document.getElementsByTagName('footer')[0];
      const main = document.getElementsByClassName('main-content')[0];

      if (!footer) {
        return;
      }

      footer.style.display = 'none';
      main.classList.add('without-footer');
    }

    componentWillUnmount() {
      const footer = document.getElementsByTagName('footer')[0];
      const main = document.getElementsByClassName('main-content')[0];

      if (!footer) {
        return;
      }

      footer.style.display = '';
      main.classList.remove('without-footer');
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  } as unknown as void;

export { withoutFooter };

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, TablePagination, TextField, Typography } from '@mui/material';
import { debounce, omit } from 'lodash';
import { Observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFilters, usePagination, useSortBy, useTable } from 'react-table';

import { progressStore } from 'src/stores';

import { Spinner } from 'components/Spinner';

import { t } from 'utils';

import { SystemUserReportsConstants } from '../../../../constants/systemUserReports.constants';

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter, id } }) {
  const handleSetFilter = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setFilter(e.target.value || undefined);
  return (
    <Box p={0.5} mb={1.5}>
      <TextField
        fullWidth
        variant='outlined'
        size='small'
        value={filterValue || ''}
        onChange={handleSetFilter}
        sx={{ minWidth: SystemUserReportsConstants.COLUMN_WIDTH[id] }}
      />
    </Box>
  );
}

const ReportsTable = ({ fetchReports, tableMeta, tableColumns, tableData, initialSizeOfPage, pageSizeOptions }): JSX.Element => {
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => tableData, [tableData]);

  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    [],
  );
  const {
    getTableProps,
    state: { sortBy, filters, pageIndex, pageSize },
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    setPageSize,
    pageOptions,
    gotoPage,
  } = useTable(
    {
      columns,
      defaultColumn,
      data,
      initialState: {
        pageSize: 30,
      },
      manualFilters: true,
      manualSortBy: true,
      manualPagination: true,
      pageCount: tableMeta.pagination?.total ?? 0,
      autoResetFilters: false,
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  const debouncedCallback = useCallback(
    debounce((props) => fetchReports(props), 500),
    [],
  );

  useEffect(() => {
    return debouncedCallback({ pageIndex, pageSize, filters, sortBy });
  }, [fetchReports, pageIndex, pageSize, filters, sortBy]);

  const buildTBody = () => {
    if (!progressStore.isLoading(SystemUserReportsConstants.Table) && rows.length === 0) {
      return (
        <tr>
          <td className='cell' colSpan={5}>
            <Typography style={{ marginBottom: 20 }} variant='h5'>
              {t.static('systemUsers.reports.noDataWithQuery')}
            </Typography>
          </td>
        </tr>
      );
    }

    return rows.map((row) => {
      prepareRow(row);
      return (
        <tr className='row' {...row.getRowProps()}>
          {row.cells.map((cell) => {
            return (
              <td className='cell' {...cell.getCellProps()}>
                {cell.render('Cell')}
              </td>
            );
          })}
        </tr>
      );
    });
  };
  return (
    <Observer
      render={() => {
        return (
          <>
            <div>
              <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                {progressStore.isLoading(SystemUserReportsConstants.Table) && (
                  <div
                    style={{
                      backgroundColor: 'white',
                      height: '100%',
                      width: '100%',
                      top: 0,
                      left: 0,
                      opacity: '0.5',
                      zIndex: 10,
                      position: 'absolute',
                    }}
                  >
                    <Spinner type={'cubesSpinner'} />
                  </div>
                )}
                <div className='standart-table simple' style={{ marginBottom: '50px' }}>
                  <table {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => {
                            return (
                              <th {...column.getHeaderProps()}>
                                <div {...omit(column.getHeaderProps(column.getSortByToggleProps()), 'title')}>
                                  <div style={{ cursor: 'pointer', display: 'flex', height: 28 }}>
                                    {column.render('Header')}
                                    <span>
                                      {column.isSorted ? (
                                        column.isSortedDesc ? (
                                          <ArrowDownwardIcon style={{ marginLeft: 10 }} />
                                        ) : (
                                          <ArrowUpwardIcon style={{ marginLeft: 10 }} />
                                        )
                                      ) : (
                                        <div style={{ width: 24, height: 24, marginLeft: 10 }} />
                                      )}
                                    </span>
                                  </div>
                                </div>
                                <div>{column.canFilter ? column.render('Filter') : null}</div>
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>{buildTBody()}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <TablePagination
              component='div'
              sx={{ position: 'fixed', bottom: '47px', right: '24px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
              count={pageOptions.length}
              page={pageIndex}
              rowsPerPageOptions={pageSizeOptions}
              onPageChange={(event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
                if (progressStore.isLoading(SystemUserReportsConstants.Table)) return;
                gotoPage(newPage);
              }}
              rowsPerPage={pageSize}
              onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                setPageSize(Number(event.target.value))
              }
            />
          </>
        );
      }}
    />
  );
};

export { ReportsTable };

import MaterialCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import classNames from 'classnames';
import * as React from 'react';

type Props = {
  classes?: {
    [K in string]: string;
  };
  name: string;
  onChange: React.ChangeEventHandler;
  value: string | boolean | number;
  type?: string;
  id?: string;
  label?: string | React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  disabled?: boolean;
};

const Checkbox = ({
  classes = {
    root: 'field-root',
    label: 'label',
    disabled: 'disabled',
  },

  name,
  onChange,
  value,
  type = 'text',
  id = '',
  label = '',
  className,
  disabled = false,
  fullWidth = false,
}: Props) => {
  return (
    <div className={classNames('checkbox-field', className)}>
      <FormControlLabel
        classes={classes}
        disabled={disabled}
        control={
          <MaterialCheckbox
            className={classes.root}
            disabled={disabled}
            name={name}
            checked={!!value}
            onChange={onChange}
            color='primary'
            classes={{ checked: 'checked' }}
            disableTouchRipple
          />
        }
        label={label}
      />
    </div>
  );
};

export { Checkbox };

import { AxiosResponse } from 'axios';
import { matchPath } from 'react-router-dom';

import { TRANSLATIONS_URL, TRANSLATIONS_VERSION } from 'src/constants';

import { messages as messagesUtil } from 'utils/messages';

import { messages as messagesActions } from 'actions/messages';

import { isInitCompleted } from './init';

async function handleVersionChange(response: AxiosResponse) {
  const url: string = response.config?.url || '';
  const rawVersion = response.headers && response.headers[TRANSLATIONS_VERSION];
  const newVersion = parseInt(rawVersion, 10);

  if (!isInitCompleted()) {
    return;
  }

  if (messagesUtil.version.compare(newVersion)) {
    return;
  }

  if (matchPath(TRANSLATIONS_URL, url)) {
    return;
  }

  messagesActions.reload();
}

export { handleVersionChange };

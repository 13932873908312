import { uniqBy } from 'lodash';
import { observable, action, computed, makeObservable } from 'mobx';

import { OrganizerModel } from '../models';

import { Organizer } from '../types';

export class OrganizersFilters {
  static racesNamesDefaultParams = {
    search: 'roles.name:admin',
    searchFields: 'roles.name:=',
    searchJoin: 'AND',
    with: 'roles',
    orderBy: 'id',
    sortedBy: 'desc',
    limit: 20,
    page: 1,
  };

  static relatedRacesParams = {
    search: '',
    searchFields: 'id:=',
    searchJoin: 'AND',
    orderBy: 'id',
    sortedBy: 'desc',
    limit: -1,
    page: 1,
  };

  @observable
  paginationMeta: PaginationMeta = {};

  @observable
  values: Array<Organizer> = [];

  @observable
  page = 1;

  @observable
  noMoreData = false;

  @observable
  selectionId: number | null = null;

  @observable
  filters: FiltersType = OrganizersFilters.racesNamesDefaultParams;

  relatedFilters: FiltersType = OrganizersFilters.relatedRacesParams;

  constructor() {
    makeObservable(this);
  }

  @action
  addPaginationMeta(meta: PaginationMeta) {
    this.paginationMeta = meta;
  }

  @action
  addValues(values: Array<Organizer>, page: number, filters: FiltersType) {
    this.values = values;
    this.page = page;
    this.filters = filters;

    this.noMoreData = !values.length;
  }

  @action
  appendValues(values: Array<Organizer>, page: number, filters: FiltersType) {
    this.values = uniqBy([...this.values, ...values], 'id');
    this.page = page;
    this.filters = filters;

    const { limit = 0 } = this.filters;

    this.noMoreData = values.length < limit;
  }

  @action
  clearAll() {
    this.values = [];
  }

  @action
  clearRelativeSelection() {
    this.selectionId = null;
  }

  getNameById(id: number, values: Array<Organizer>): string {
    const Organizer = values.find((el) => {
      return +el.id === +id;
    });

    if (!Organizer) {
      return '';
    }

    const race = new OrganizerModel(Organizer);

    return race.value.full_name;
  }

  @action
  getOrganizerById(id: number): string {
    return this.getNameById(id, this.values);
  }

  @action
  selectRaceId(id: number): void {
    this.selectionId = id;
  }

  @action
  filterRacesById(id: number): Array<Organizer> {
    return this.values.filter((el) => +el.id === id);
  }

  @computed
  get filteredValues(): Array<Organizer> {
    if (this.selectionId) {
      return this.values.filter((el) => +el.id === this.selectionId);
    }

    return this.values;
  }
}

const organizersFilters = new OrganizersFilters();
export { organizersFilters };

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { observer } from 'mobx-react';
import * as React from 'react';

import { t } from 'utils';

import { detailsStore } from '../../../stores';

import { TransactionsRow } from './Row';

type Props = {
  distanceTitle: string;
};

const Component = (props: Props) => {
  const currency = detailsStore.payment?.currency;

  const items = detailsStore.transactions
    ? detailsStore.transactions.map((item) => (
        <TransactionsRow key={`transaction:${item.id}`} item={item} currency={currency} distanceTitle={props.distanceTitle} />
      ))
    : null;

  return (
    <div className='account-info-list payed-items'>
      <div className='info-title'>
        <p>{t.staticAsString('payments.detail.transactions.title')}</p>
      </div>
      <div className='info-body'>
        <Table className='payed-items-table'>
          <TableHead classes={{ root: 'info-table-header' }}>
            <TableRow className='table-row'>
              <TableCell className='table-cell'>{t.staticAsString('payments.detail.transactions.id')}</TableCell>
              <TableCell className='table-cell cell-name'>{t.staticAsString('payments.detail.transactions.name')}</TableCell>
              <TableCell className='table-cell'>{t.staticAsString('payments.detail.transactions.date')}</TableCell>
              <TableCell className='table-cell'>{t.staticAsString('payments.detail.transactions.total')}</TableCell>
              <TableCell className='table-cell cell-type'>{t.staticAsString('payments.detail.transactions.type')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: 'info-table-body' }}>{items}</TableBody>
        </Table>
      </div>
    </div>
  );
};

const TransactionsTable = observer(Component);
export { TransactionsTable };

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@mui/styles';
import * as React from 'react';

import { t } from 'utils';

import { onEditIntegration, onDeleteIntegration } from '../actions';

type Props = {
  menuId: number;
};

type State = {
  anchorEl: Object | null;
};

const StyledIconButton = withStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    padding: 6,
  },
}))(IconButton);

const StyledMenuItem = withStyles({
  root: {
    paddingRight: 49,
  },
})(MenuItem);

const StyledMenuDelete = withStyles((theme) => ({
  root: {
    paddingRight: 49,
    color: theme.palette.redCE,
  },
}))(MenuItem);

export class ActionsMenu extends React.Component<Props, State> {
  state = {
    anchorEl: null,
  };

  changeAnchor = (trigger: boolean) => (e?: AnyObject) => {
    this.setState({
      anchorEl: trigger && !!e ? e.currentTarget : null,
    });
  };

  onClickEdit = () => {
    const { menuId } = this.props;
    onEditIntegration(menuId)();
    this.changeAnchor(false)();
  };

  onClickDelete = () => {
    const { menuId } = this.props;
    onDeleteIntegration(menuId)();
    this.changeAnchor(false)();
  };

  render() {
    const { menuId } = this.props;
    const { anchorEl } = this.state;
    const id = `actions-menu:${menuId}`;

    return (
      <React.Fragment>
        <StyledIconButton onClick={this.changeAnchor(true)}>
          <MoreVertIcon aria-controls={id} aria-haspopup />
        </StyledIconButton>
        <Menu id={id} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={this.changeAnchor(false)}>
          <StyledMenuItem onClick={this.onClickEdit}>{t.staticAsString('integrations.list.edit')}</StyledMenuItem>
          <StyledMenuDelete onClick={this.onClickDelete}>{t.staticAsString('integrations.list.delete')}</StyledMenuDelete>
        </Menu>
      </React.Fragment>
    );
  }
}

import { errorsStore } from 'stores';

import { SUPPORT_TASK_MANAGER_FORM } from '../constants';

import { emailsService } from '../services';

export const loadSourceEmails = async (text: string | null = null) => {
  errorsStore.clear(SUPPORT_TASK_MANAGER_FORM);
  await emailsService.getSourceEmails(getParams(text));
};

export const loadTargetEmails = async (text: string | null = null) => {
  errorsStore.clear(SUPPORT_TASK_MANAGER_FORM);
  await emailsService.getTargetEmails(getTargetParams(text));
};

const getParams = (text: string | null = null): FiltersType => {
  const params = {
    filter: 'id;email',
  };

  if (text === null) {
    return params;
  }

  return {
    ...params,
    search: `email:${text}`,
    searchFields: `email:like`,
    searchJoin: 'AND',
  };
};

const getTargetParams = (text: string | null = null): FiltersType => {
  const params = {
    filter: 'id;email',
  };

  if (text === null) {
    return params;
  }

  return {
    ...params,
    search: `email:${text}`,
    searchFields: `email:like`,
    searchJoin: 'AND',
  };
};

import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils';

import { loadCurrencyService } from 'services';

import { progressStore } from 'stores';

import { ReportConstants } from '../constants/report.constants';

import { ReportService } from '../services/report.service';

import { ReportStore } from '../stores/report.store';

export namespace ReportActions {
  export async function init() {
    progressStore.log(ReportConstants.ReportPage, 'progress');
    const match = matchPath(history.location.pathname, ROUTES.reportRoute);
    const { reportId } = match.params as any;
    const data = await ReportService.load(+reportId);
    await loadCurrencyService.loadResources();
    ReportStore.report.set(data);
    progressStore.log(ReportConstants.ReportPage, 'completed');
  }
  export async function unmount() {
    ReportStore.report.value = null;
  }
}

import { debounce } from 'lodash';
import { observable, action, computed, makeObservable } from 'mobx';

type Value = {
  width: number;
  height: number;
};

const SIDEBAR_WIDTH = 193;
const HEADER_HEIGHT = 80;
const FOOTER_HEIGTH = 47;

class WindowSize {
  @observable
  value: Value = { width: window.innerWidth, height: window.innerHeight };
  debouncedCalcNewValue = debounce(this.calcNewValue, 300);

  constructor() {
    makeObservable(this);
  }

  @action
  applyNewValue(width: number, height: number) {
    this.value = { ...this.value, width, height };
  }

  @action
  calcNewValue() {
    this.applyNewValue(window.innerWidth, window.innerHeight);
  }

  @computed
  get widthWithoutSidebar(): number {
    return this.value.width - SIDEBAR_WIDTH;
  }

  @computed
  get heigthWithoutHeader() {
    return this.value.height - HEADER_HEIGHT;
  }

  @computed
  get heigthWithoutFooter() {
    return this.value.height - FOOTER_HEIGTH;
  }

  @computed
  get heigthWithoutFooterAndHeader() {
    return this.value.height - FOOTER_HEIGTH - HEADER_HEIGHT;
  }

  listenWindowResize() {
    window.addEventListener('resize', (_event) => {
      this.debouncedCalcNewValue();
    });
  }
}

const windowSize = new WindowSize();
windowSize.listenWindowResize();

export { WindowSize, windowSize };

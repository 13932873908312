import { Chip } from '@mui/material';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import validate from 'validate.js';

import { RACE_STATUSES_INT } from 'src/constants';

import { formatValue, classes } from 'components/Filter/ChipFilterLabel';

import { t } from 'utils';

type Props = {
  value: FilterType;
  name: string;
  onClear: (filterNames: Array<string>) => void;
  className: string;
  values: FiltersType;
};

let ApproveStatusFilterLabel = ({ value, name, onClear, className }: Props) => {
  if (!value) {
    return null;
  }

  let setValue;

  switch (value) {
    case RACE_STATUSES_INT.awaiting:
      setValue = t.staticAsString('races.list.table.awaiting');
      break;
    case RACE_STATUSES_INT.reject:
      setValue = t.staticAsString('races.list.table.rejected');
      break;
    case RACE_STATUSES_INT.approve:
      setValue = t.staticAsString('races.list.table.approved');
      break;
    case RACE_STATUSES_INT.draft:
      setValue = t.staticAsString('races.list.table.draft');
      break;
    default:
      setValue = '';
  }

  return (
    <Chip
      className={classNames(className, 'chip-card')}
      classes={classes}
      onDelete={() => onClear([name])}
      label={validate.capitalize(validate.prettify(formatValue(setValue)) || '')}
    />
  );
};

ApproveStatusFilterLabel = observer(ApproveStatusFilterLabel);

export { ApproveStatusFilterLabel };

import { SmartLinkBE, SmartLinkFE } from 'modules/SmartLinks/shared/types';
import moment from 'moment';

import { DummyStore, DisposersStore } from 'src/stores';

const defaultParams = {
  limit: 20,
  page: 1,
};

type Params = {
  limit: number;
  page: number;
  search?: string;
  searchFields?: string;
};

export type DistanceOption = SelectOption & {
  registration_starts_at: moment.Moment;
  registration_ends_at: moment.Moment;
};

export type WaveOptions = SelectOption & {
  slots: number | nil;
};

type RaceOption = SelectOption & {
  organizer_id?: number;
};

export const racesStore = new DummyStore<RaceOption[]>([]);
export const racesHasMoreStore = new DummyStore<boolean>(true);
export const racesParamsStore = new DummyStore<Params>(defaultParams);

export const distancesStore = new DummyStore<DistanceOption[]>([]);
export const distancesDataStore = new DummyStore<DistanceType[]>([]);
export const distanceStore = new DummyStore<DistanceType>();

export const wavesStore = new DummyStore<WaveOptions[]>([]);
export const wavesHasMoreStore = new DummyStore<boolean>(true);
export const wavesParamsStore = new DummyStore<Params>(defaultParams);

export const linkStore = new DummyStore<SmartLinkFE>();
export const disposersStore = new DisposersStore();

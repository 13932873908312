import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants/routes';

import { history } from 'utils/history';

import { couponsStore } from 'stores';

import { couponDetailsService } from '../services/couponsDetails.service';

import { couponOrdersStore, couponStore } from '../stores';

export const couponsUnmount = async () => {
  couponsStore.clearAll();
};

export const mountCouponDetails = async () => {
  const { pathname } = history.location;
  const { params: { id } = { id: null } } = matchPath(pathname, { path: ROUTES.couponRoute }) || {};

  if (!id) {
    return;
  }

  await couponDetailsService.loadCoupon(id);
};

export const unmountCouponDetails = () => {
  couponStore.clear();
  couponOrdersStore.clear();
};

import { distancesService } from '../services';

import { distanceStore } from '../stores';

async function reloadDistance() {
  const distance = distanceStore.modelSelected;

  if (!distance) {
    return;
  }

  const id = distance.value.id;

  await distancesService.loadResource(id);
}

export { reloadDistance };

export const Info = (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
    <defs>
      <path
        id='a'
        d='M9 15h2V9H9v6zm1-15C4.475 0 0 4.475 0 10s4.475 10 10 10 10-4.475 10-10S15.525 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM9 7h2V5H9v2z'
      />
    </defs>
    <use fill='#A8AEB8' fillRule='nonzero' transform='translate(2 2)' xlinkHref='#a' />
  </svg>
);

import { t } from 'utils';

const MINIMUM_TEXT_DEFAULT = 3;
const MAXIMUM_TEXT_DEFAULT = 100;
const DEFAULT_LENGTH_PATTERN = `^$|(^.{${MINIMUM_TEXT_DEFAULT},${MAXIMUM_TEXT_DEFAULT}})$`;

const nameAndDescription = {
  _validation: {
    name: {
      presence: {
        allowEmpty: false,
        message: () => t.staticAsString('shared.errors.presence'),
      },
      format: {
        pattern: DEFAULT_LENGTH_PATTERN,
        message: () =>
          t.staticAsString('shared.errors.lengthWithFormat', {
            min: MINIMUM_TEXT_DEFAULT,
            max: MAXIMUM_TEXT_DEFAULT,
          }),
      },
    },
  },
};

export { nameAndDescription };

import { Box, Typography } from '@mui/material';
import { intersection } from 'lodash';
import { observer, Observer } from 'mobx-react';
import { CommissionActions } from 'modules/Reconciliation/Commission/actions/lifecycle';
import { CommissionTable } from 'modules/Reconciliation/Commission/components/Table';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { t } from 'src/utils';

import { Spinner } from 'components/Spinner';

import { currenciesStore } from 'stores';

import { CommissionStore } from '../../stores/commission.store';
import { organizerCurrenciesIds, organizerStore } from '../../stores/currencies.store';

import { CommissionFiltersMappers } from '../../mappers/filters.mapper';
import { ScrollableTab } from '../ScrollableTab';
import { generateCommissionColumns } from '../Table/Columns.expander';

interface Props {}

const CommissionWrapper = observer((props: Props): ReactElement => {
  const [loading, setLoading] = useState(true);
  const [currencies, setCurrencies] = useState<availableCurrencies[]>([]);
  const [currentTab, setCurrentTab] = useState(0);

  const currentTabToCurrencyId = (value: number) => {
    return currenciesStore.values.find((c) => c.iso_code === currencies[value])?.id ?? 0;
  };

  const handleChangeTab = (event: React.ChangeEvent<{}>, value: any) => {
    setCurrentTab(value);
    CommissionActions.load({
      page: 1,
      limit: 30,
      ...CommissionFiltersMappers.toBackend(currentTabToCurrencyId(value), []),
    });
  };

  const fetchCommissions = useCallback(
    ({ pageIndex = 0, pageSize, filters, sortBy }) => {
      let orderBy = 'created_at';
      let sortedBy = 'DESC';
      if (sortBy.length > 0) {
        const { id, desc } = sortBy[0];
        orderBy = id.includes('.') ? id.split('.').join('|') : id;
        sortedBy = desc ? 'DESC' : 'ASC';
      }

      CommissionActions.load({
        page: pageIndex + 1,
        limit: pageSize,
        orderBy,
        sortedBy,
        ...CommissionFiltersMappers.toBackend(currentTabToCurrencyId(currentTab), filters),
      });
    },
    [currencies.length],
  );

  useEffect(() => {
    const init = async () => {
      await CommissionActions.init();
      const inter = intersection(
        currenciesStore.values.map((c) => c.id),
        organizerCurrenciesIds.value,
      );
      setCurrencies(currenciesStore.values.filter((c) => inter.includes(c.id)).map((c) => c.iso_code) as availableCurrencies[]);
      setLoading(false);
    };
    init();
    return () => {
      CommissionActions.unmount();
    };
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 5 }}>
        <Spinner type={Spinner.types.default} />
      </Box>
    );
  }

  return (
    <div style={{ padding: 0, backgroundColor: 'white' }} className='content main-container-list races-list'>
      <Box sx={{ pl: 3, pt: 4.5, pb: 4, display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
        <Typography sx={{ fontSize: 20 }}>{t.staticAsString('reconciliation.commission.pageTitle')}</Typography>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: 14, color: '#A8AEB8', fontWeight: 500, mr: 1 }}>
            {t.staticAsString('reconciliation.commission.subPageTitle')}
          </Typography>
          <Typography sx={{ fontSize: 14, color: '#262E35', fontWeight: 500 }}>{organizerStore.value?.full_name}</Typography>
        </Box>
      </Box>
      {currencies.length === 0 ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', p: 3 }}>
          <Typography sx={{ fontSize: 18, color: '#A8AEB8', fontWeight: 500, mr: 1 }}>
            {t.staticAsString('reconciliation.commission.emptyCommissions')}
          </Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ m: 4 }}>
            <ScrollableTab onChange={handleChangeTab} value={currentTab} currencies={currencies} errors={[]} />
          </Box>
          <Observer
            render={() => {
              return (
                <CommissionTable
                  tableData={CommissionStore.commissions.value?.data ?? []}
                  tableMeta={CommissionStore.commissions.value?.meta ?? {}}
                  initialSizeOfPage={30}
                  selectedCurrencyId={currentTabToCurrencyId(currentTab)}
                  pageSizeOptions={[30, 50, 100, 300]}
                  tableColumns={generateCommissionColumns()}
                  fetchCommissions={fetchCommissions}
                />
              );
            }}
          />
        </>
      )}
    </div>
  );
});

export { CommissionWrapper };

import { observable, action, computed, makeObservable } from 'mobx';
import { raceTypeStore } from 'modules/RaceDetails/stores/raceTypeStore';

import { Race as RaceModel } from 'models';

import { RaceDetailsStoreType, RejectDataType } from '../types';
import { distancesStore } from './distancesStore';

class RaceDetails implements RaceDetailsStoreType {
  @observable
  race: RaceType | null = null;

  resourceParams: FiltersType = {
    with: 'organizer;location;route;currency;rejectReason.admin',
  };

  constructor() {
    makeObservable(this);
  }

  @action
  setRaceDetails(data: RaceType) {
    this.race = data;
    raceTypeStore.setSwitchValue(!Boolean(data.race_url));
  }

  @action
  clearData(): void {
    this.race = null;
  }

  @computed
  get raceDetails(): nil | RaceModel {
    if (!this.race) {
      return null;
    }

    return new RaceModel(this.race);
  }

  @computed
  get isAnyDistancesPresented(): boolean {
    return distancesStore.modelValues.some((distance) => distance.isVisible());
  }

  @computed
  get isAnyDistanceCreated(): boolean {
    return !!distancesStore.modelValues.length;
  }
  @computed
  get isSomeDistanceVirtualOrCumulative(): boolean {
    return distancesStore.modelValues.some((distance) => distance.isMode('virtual') || distance.isMode('cumulative'));
  }

  @computed
  get rejectData(): RejectDataType {
    const rejectionObj = this.race?.rejectReason;
    const raceName = this.raceDetails?.value.name;

    if (!rejectionObj || !raceName) {
      return {
        adminName: '',
        rejectDate: '',
        rejectReason: '',
        raceName: '',
      };
    }

    const {
      admin: { full_name: adminName },
      reason: rejectReason,
      created_at: rejectDate,
    } = rejectionObj;

    return {
      raceName,
      adminName,
      rejectDate,
      rejectReason,
    };
  }

  @action
  partialRaceUpdate(
    changes: {
      [K in string]: any;
    },
  ) {
    if (!this.race) {
      return;
    }

    this.race = {
      ...this.race,
      ...changes,
    };
  }
}

const raceDetailsStore = new RaceDetails();
export { raceDetailsStore };

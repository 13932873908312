import DialogActions from '@mui/material/DialogActions';
import { withStyles } from '@mui/styles';
import * as React from 'react';

import { Button } from 'components/core';

import { t } from 'utils';

import { closeConfirmModal, onConfirmModal } from '../../actions';

type Props = {};

const DialogActionsStyled = withStyles({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 24,
    paddingLeft: 24,
    borderTop: '1px solid #e0e0e0',
  },
  spacing: {
    '&>:not(:first-child)': {
      marginLeft: 16,
    },
  },
})(DialogActions);

export class Bottom extends React.Component<Props> {
  closeModal = () => {
    closeConfirmModal();
    onConfirmModal();
  };

  render() {
    return (
      <DialogActionsStyled>
        <Button type='cancel' onClick={closeConfirmModal}>
          {t.staticAsString('supportTaskManager.form.cancel' as TranslationLockedKeys)}
        </Button>

        <Button type='apply' onClick={this.closeModal}>
          {t.staticAsString('supportTaskManager.form.confirm' as TranslationLockedKeys)}
        </Button>
      </DialogActionsStyled>
    );
  }
}

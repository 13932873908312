import { countriesStore } from 'stores';

import { Racer as RacerType } from '../types';

export class Racer {
  value: RacerType;

  constructor(value: RacerType) {
    this.value = value;
  }

  get countryName(): string {
    const country = countriesStore.values.find((country) => country.id === this.value.country_id);
    return country?.short_name || '';
  }
}

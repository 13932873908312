import * as React from 'react';

import { Flag } from 'components/Flag';
import { formatLongLocale, formatShortLocale } from 'components/core';

import { useLocaleStyles } from '../styles';

type Props = {
  locale: availableLocales;
  short?: boolean;
  className?: string;
};

export const LocaleItem = ({ locale, short, className }: Props) => {
  const classes = useLocaleStyles();
  return (
    <React.Fragment>
      <Flag className={classes.flagIcon} locale={locale} />
      <p className={`${classes.label} ${className}`}>{short ? formatShortLocale(locale) : formatLongLocale(locale)}</p>
    </React.Fragment>
  );
};

class Racer {
  value: RacerType;
  namespace: nil | string;

  constructor(value: RacerType, namespace?: string) {
    this.value = value;
    this.namespace = namespace;
  }

  fullName(): string {
    const { value } = this;
    return `${value.firstname} ${value.lastname}`;
  }

  payedItems() {
    const { value } = this;
    if (!value.fields) {
      return [];
    }

    return value.fields.filter((field: AnyObject) => !!parseInt(`${field.price}`));
  }

  setFieldIdPrefix(prefix: string) {
    this.namespace = prefix;
  }

  generateFieldId(columnName: string): string {
    return [this.namespace, columnName].join('.');
  }

  generateStoreOption(property: string): {
    property: string;
    storeId: string;
  } {
    return { property, storeId: this.generateFieldId(property) };
  }

  generateStoreOptions(...properties: string[]): {
    property: string;
    storeId: string;
  }[] {
    return properties.map((property) => this.generateStoreOption(property));
  }

  forSelect(formatFun?: (val: RacerType) => string): SelectOption {
    const id = `${this.value.id || ''}`;

    return {
      label: formatFun ? formatFun(this.value) : this.fullName(),
      value: id,
      key: id,
    };
  }
}

export { Racer };

import { createContext } from 'react';

import { mount, unmount } from '../actions';

import { taskService } from '../services';

import { tasksStore } from '../stores';

export type TasksContextType = {
  results: {
    actions: {
      mount: typeof mount;
      unmount: typeof unmount;
    };
    services: {
      racersService: typeof taskService;
    };
    stores: {
      racersStore: typeof tasksStore;
    };
  };
};

const tasksContextData: TasksContextType = {
  results: {
    actions: {
      mount,
      unmount,
    },
    services: {
      racersService: taskService,
    },
    stores: {
      racersStore: tasksStore,
    },
  },
};
const TasksContext = createContext<TasksContextType>(tasksContextData);

export { TasksContext, tasksContextData };

import { observer } from 'mobx-react';
import * as React from 'react';

import { FilterAutocompleteAsyncLoad } from 'components/Filter/DefaultFilters';

import { RacerRace } from '../models';

import { loadDistancesNames, loadAndSelectRelatedRaceToDistance, loadAndSelectRelatedDistanceToRace } from '../actions';

import { distancesNamesFilters, racersStore, racesNamesFilters } from '../stores';

type Props = {} & FilterProps;

@observer
class DistanceNameComponent extends React.Component<Props> {
  options = () => {
    return distancesNamesFilters.filteredValues.map((el) => {
      const racerRace = new RacerRace(el);

      return racerRace.forSelect();
    });
  };

  loadMore = (): void => {
    if (distancesNamesFilters.selectionId) {
      loadAndSelectRelatedDistanceToRace(
        distancesNamesFilters.selectionId,
        Number(distancesNamesFilters?.paginationMeta?.current_page) + 1,
      );
    } else {
      loadDistancesNames({
        page: Number(distancesNamesFilters?.paginationMeta?.current_page) + 1,
      });
    }
  };

  searchFilter = (text: string) => {
    if (distancesNamesFilters.selectionId) {
      loadAndSelectRelatedDistanceToRace(distancesNamesFilters.selectionId, 1, text);
    } else {
      loadDistancesNames({
        page: 1,
        search: `name:${text}`,
      });
    }
  };

  render() {
    const { props } = this;

    return (
      <FilterAutocompleteAsyncLoad
        {...props}
        noMoreData={distancesNamesFilters.noMoreData}
        options={this.options()}
        loadMore={this.loadMore}
        search={this.searchFilter}
      />
    );
  }
}

export const distanceName: FilterListDescriptor = {
  filter: {
    persistance: {
      calcValue: function (name, value) {
        return value;
      },

      prettify: function (name, value) {
        const title = distancesNamesFilters.getDistanceNameById(+value);

        return title;
      },
    },
    behavior: {
      apply: async (name, value) => {
        racersStore.filterByName('distance', value);
        loadAndSelectRelatedRaceToDistance(value);
      },
      delete: async (name) => {
        racersStore.disableFilterByName('distance');
        racesNamesFilters.clearRelativeSelection();
      },
      reset: async (name) => {
        racersStore.disableFilterByName('distance');
        racesNamesFilters.clearRelativeSelection();
      },
    },
  },
  frontend: {
    type: 'custom',
    component: DistanceNameComponent,
  },
  sort: {
    default: false,
    reorderOnSearch: true,
    defaultBy: 'ASC',
    calcOrderBy: (name) => 'distances|id',
  },
};

import { cloneDeep } from 'lodash';

import { TaskForm } from '../types';

export const mapToFormMergeAccounts = (data: AnyObject): AnyObject => {
  const form: TaskForm = cloneDeep(data) as TaskForm;

  return {
    source_email: form.sourceEmail?.value,
    target_email: form.targetEmail?.value,
    delete_source_account: form.deleteSourceAccount ? form.deleteSourceAccount : false,
  };
};

import * as React from 'react';

import { Tasks as TasksContainer } from './containers';
import { TasksContext, tasksContextData } from './context';

export const SupportTaskManagerTasks = () => (
  <TasksContext.Provider value={tasksContextData}>
    <TasksContainer />
  </TasksContext.Provider>
);

import { distanceStore } from '../stores';

function isRegistrationFieldEnabled(name: registrationFields): boolean {
  const distance = distanceStore.modelSelected;
  const fields = distance?.value?.registration_fields;

  if (!fields) {
    return false;
  }

  const match = fields.find((field) => field.name === name);

  if (!match) {
    return false;
  }

  return !!match.selected;
}

export { isRegistrationFieldEnabled };

import { getMinAmountByCurrency } from 'modules/Distances/utils';

import { t } from 'utils';

import { helperRacesStore } from 'stores';

const prices = () => {
  const raceCurrency = helperRacesStore.selected?.currency?.iso_code;
  const minAmount = getMinAmountByCurrency(raceCurrency);
  return {
    _validation: {
      vat_percents: {
        presence: {
          message: () => {
            const error = t.staticAsString('distances.steps.customFieldsForm.vat_percents_required');

            return `^${error}`;
          },
          allowEmpty: false,
        },
      },
    },
    prices: {
      _validation: {
        value: {
          presence: {
            message: () => t.staticAsString('shared.errors.presence'),
            allowEmpty: false,
          },
          numericality: {
            greaterThanOrEqualTo: minAmount,
            message: () =>
              t.staticAsString('shared.errors.greaterThanMinAmount', {
                min: minAmount,
                currency: raceCurrency,
              }),
          },
        },
        date_from: {
          presence: {
            message: () => t.staticAsString('shared.errors.presence'),
            allowEmpty: false,
          },
        },
      },
    },
  };
};

export { prices };

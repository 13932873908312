import { Moment } from 'moment';

import { BE_DATETIME_FORMAT } from 'src/constants/time';

import { SystemUsersTableFilters } from '../types/filters.type';

const DateFields = ['created_at'];
const LikeFields = ['races.name'];
const BetweenFields = ['id', 'sum'];
export namespace ReportFiltersMappers {
  export function toBackend(filters: SystemUsersTableFilters[]) {
    const searchFilters = filters.reduce(
      (acc, { id, value }) => {
        if (DateFields.includes(id)) {
          acc.search += `${id}:${mapDaterange(value.startDate, value.endDate)};`;
          acc.searchFields += `${id}:between;`;
        }
        if (LikeFields.includes(id)) {
          if (id === 'races.name') {
            id = 'race.name';
          }
          acc.search += `${id}:${value};`;
          acc.searchFields += `${id}:like;`;
        }
        if (BetweenFields.includes(id)) {
          switch (id) {
            case 'sum':
            case 'id':
              let [start, end] = value;
              if (start === undefined) {
                if (id === 'sum') end = Number(end) * 100;
                acc.search += `${id}:${end};`;
                acc.searchFields += `${id}:<=;`;
                break;
              }
              if (end === undefined) {
                if (id === 'sum') start = Number(start) * 100;
                acc.search += `${id}:${start};`;
                acc.searchFields += `${id}:>=;`;
                break;
              }
            default:
              if (id === 'sum') value = value.map((item) => item * 100);
              acc.search += `${id}:${value.join(',')};`;
              acc.searchFields += `${id}:between;`;
          }
        }
        return acc;
      },
      {
        search: '',
        searchFields: '',
      },
    );

    searchFilters.search = searchFilters.search.substring(0, searchFilters.search.length - 1);
    searchFilters.searchFields = searchFilters.searchFields.substring(0, searchFilters.searchFields.length - 1);
    return searchFilters;
  }
}

const mapDaterange = (start: Moment, end: Moment): string => {
  return `${start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(BE_DATETIME_FORMAT)},${end
    .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    .format(BE_DATETIME_FORMAT)}`;
};

import { t } from 'src/utils';

const length = {
  minimum: 3,
  maximum: 200,
};

export const settingsStepSchema = (): AnyObject => {
  const presence = {
    allowEmpty: false,
    message: t.staticAsString('validation.required'),
  };

  const url = {
    message: t.staticAsString('races.new.errors.invalid_url'),
  };

  return {
    race_id: {
      presence,
    },
    event_id: {
      presence,
      length,
    },
    save_fields: {
      presence,
      length,
      url,
    },
    results: {
      presence,
      length,
      url,
    },
    contests: {
      presence,
      length,
      url,
    },
    fields: {
      presence,
      length,
      url,
    },
  };
};

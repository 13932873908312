import { Result } from 'models';

import { teamFields } from '../../../constants';

import { ResultsContextType } from '../../../context';
import { formatStartTime, formatFinishTime, formatDiff, formatClassId, formatWaveId, formatPlace } from '../../../presenters';

const FIELD_SHOW = {
  [teamFields.place]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      return formatPlace(model, context);
    };
  },

  [teamFields.start_time]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      const { value } = model;
      return formatStartTime(value.start_time, model, context);
    };
  },

  [teamFields.finish_time]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      const { value } = model;
      return formatFinishTime(value.finish_time, model, context);
    };
  },

  [teamFields.time_result]: function (model: Result, context: ResultsContextType): Function {
    return function (): string {
      const { value } = model;
      return formatDiff(value.start_time, value.finish_time, model, context);
    };
  },

  [teamFields['team.bib_number']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.team) {
        return null;
      }

      return model.team.value.bib_number;
    };
  },

  [teamFields['team.name']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.team) {
        return null;
      }

      return model.team.value.name;
    };
  },

  [teamFields['team.class_id']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.team) {
        return null;
      }
      return formatClassId(model.team.value.class_id, context);
    };
  },

  [teamFields['team.wave_id']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      if (!model.team) {
        return null;
      }
      return formatWaveId(model.team.value.wave_id, context);
    };
  },

  [teamFields['racer.email']]: function (model: Result, context: ResultsContextType): Function {
    return () => {
      return !model.racer ? null : model.racer.value.email;
    };
  },
};

export { FIELD_SHOW };

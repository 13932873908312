export const AWAITING = 'awaiting';

export const PAYMENT_STATUSES: {
  [K in paymentStatus]: paymentStatus;
} = {
  Ready: 'Ready',
  Pending: 'Pending',
  Failed: 'Failed',
  Aborted: 'Aborted',
  Reversed: 'Reversed',
};

export const DISTANCE_MODES: {
  [K in string]: distanceModes;
} = {
  CLASSIC: 1,
  VIRTUAL: 2,
  CUMULATIVE: 3,
};

export const GOAL_TYPE: {
  [K in string]: number;
} = {
  DISTANCE: 1,
  ALTITUDE: 2,
};

import * as React from 'react';

import { t } from 'utils';

function Headers() {
  return (
    <div className='row headers'>
      <p className='cell label'>{t.staticAsString('distances.steps.registrationFieldsForm.headers.title')}</p>
      <p className='cell selected'>{t.staticAsString('distances.steps.registrationFieldsForm.headers.selected')}</p>
      <p className='cell required'>{t.staticAsString('distances.steps.registrationFieldsForm.headers.required')}</p>
    </div>
  );
}

export { Headers };

import axios from 'axios';
import { RACE_TYPE } from 'modules/Races/constants';
import { isCalendarRaceType } from 'modules/Races/utils/isCalendarRaceType';
import moment from 'moment';
import { generatePath } from 'react-router-dom';

import { BACKEND_DATE_FORMATE, BACKEND_DATETIME_FORMATE, RACE, RACES_URL, ROUTES } from 'src/constants';

import { request, action, history, t } from 'utils';

import { helperImageUploadService } from 'services';

import { toastStore } from 'stores';

class NewRace {
  defaultImage: nil | string = null;

  @request({ action: `CREATE_${RACE}` })
  createRequest(data: Object): any {
    return axios.post(RACES_URL, data);
  }

  @action({ action: `CREATE_${RACE}` })
  async create({ image, data, onChangeCallback }: { image: nil | Blob; data: AnyObject; onChangeCallback: AnyFunction }): Promise<any> {
    // Image upload
    if (image instanceof Blob) {
      const respImgUrl = await helperImageUploadService.imageUpload(image);
      if (respImgUrl) {
        data.image_file_uuid = respImgUrl;
        // Change image field in state too, to avoid multiple image uploading
        onChangeCallback({ name: 'image', value: respImgUrl });
      } else {
        // Interupts on submit in case to show image upload errors
        return;
      }
    }

    data.race_date = moment(data.race_date, BACKEND_DATE_FORMATE).format(BACKEND_DATETIME_FORMATE);
    data.race_end_date = moment(data.race_end_date, BACKEND_DATE_FORMATE).format(BACKEND_DATETIME_FORMATE);

    const [isOk, response] = await this.createRequest(data);
    if (isOk) {
      const value = response.data.data;
      toastStore.show(t('races.new.successCreate', { name: value.name }));
      history.push(
        `${generatePath(ROUTES.raceEditRoute, { id: value.id })}?step=organizerInformation${
          isCalendarRaceType.get() ? '&type=' + RACE_TYPE.calendar : ''
        }`,
      );
      return value.id;
    }
  }
}

export { NewRace };
export default new NewRace();

import { DISTANCE_STEPS } from 'src/constants';

export const PERSIST_CHECKPOINT = 'PERSIST_CHECKPOINT';
export const FORM_ID = 'NEW_CHECKPOINT_FORM_ID';
export const TIMING_ASSISTANTS = 'assistants';
export const LOAD_UPDATE_FORM = 'DISTANCES_LOAD_UPDATE_FORM';
export const DISTANCE_UPDATE_FORM = 'PERSIST_DISTANCE';
export const DISTANCE_FORM_ERRORS = 'DISTANCE_FORM_ERRORS';
export const SAVE_CHECKPOINTS = 'SAVE_CHECKPOINTS';
export const enum ERRORS_PREFIXES {
  classes = 'classes',
  waves = 'waves',
  disciplines = 'disciplines',
  prices = 'prices',
  custom_fields = 'custom_fields',
  basic_info = 'basic_info',
  vat_percents = 'vat_percents',
  self_services = 'self_services',
}
export const MIN_AMOUNT_BY_CURRENCY = {
  USD: 0.6,
  AUD: 0.75,
  CAD: 0.65,
  NZD: 0.85,
  EUR: 0.5,
  SEK: 6,
  NOK: 5.5,
  PLN: 3,
  GBP: 0.42,
  DKK: 4,
};

export const CALENDAR_RACE_TAB_OPTIONS = [DISTANCE_STEPS.nameAndDescription, DISTANCE_STEPS.distanceDetails];

import { DummyStore } from 'stores';

export const modalStore = new DummyStore<{
  open: boolean;
  prevAction: string;
}>({
  open: false,
  prevAction: '',
});

export const sourceDistanceStore = new DummyStore<AnyObject[]>([]);
export const targetDistancesStore = new DummyStore<AnyObject[]>([]);
export const refundDistancesStore = new DummyStore<AnyObject[]>([]);

export const sourceEmailStore = new DummyStore<AnyObject[]>([]);
export const targetEmailStore = new DummyStore<AnyObject[]>([]);

export const ordersStore = new DummyStore<AnyObject[]>([]);

export const bibClassesStore = new DummyStore<AnyObject[]>([]);

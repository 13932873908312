import React from 'react';

import { t } from 'utils/t';

import { formStyles } from '../../styles';

export const StepTitle = ({ step }: { step: string }) => {
  const classes = formStyles();
  return (
    <h3 className={classes.stepTitle} style={{ marginBottom: 16 }}>
      {t.staticAsString(`distances.steps.${step}` as TranslationLockedKeys)}
    </h3>
  );
};

import { uniqBy } from 'lodash';
import { action, observable, makeObservable } from 'mobx';
import moment from 'moment';

import { BACKEND_DATE_FORMATE } from '../../../../constants';

export type Options = {
  id: number;
  key: number;
  label?: string | null;
};

const defaultValues = {
  params: {
    searchJoin: 'AND',
    page: 1,
    limit: 10,
    search: '',
    searchFields: '',
  },
};

export class Races {
  @observable races: Array<Options> = [];

  @observable params: any = defaultValues.params;
  isPast: boolean = false;
  @observable hasMore: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action
  setRaces(data: Array<Options>): void {
    this.races = uniqBy([...this.races, ...data], 'id');
  }

  @action
  setParams(params: Object): void {
    this.params = {
      ...this.params,
      ...params,
    };
  }

  @action
  setHasMore(hasMore: boolean = true): void {
    this.hasMore = hasMore;
  }

  @action
  clear(): void {
    this.races = [];
    this.params = defaultValues.params;
    this.hasMore = true;
  }
}

const reportRacesStore = new Races();
export { reportRacesStore };

import { TabScrollButton } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import { includes } from 'lodash';
import * as React from 'react';

import { CurrencyItem } from './CurrencyItem';
import { tabStyles, tabsStyles } from './styles';
import { useLabelStyles, useRootStyles, useContainerStyles, useGradientStyles } from './styles';

type Props = {
  currencies?: availableCurrencies[];
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
  value: number;
  errors: Array<string>;
};

const StyledTabs = withStyles(tabsStyles)(Tabs);
const StyledTab = withStyles(tabStyles)(Tab);

export const ScrollableTab = ({ currencies = [], onChange, value, errors = [] }: Props) => {
  const classes = useRootStyles();
  const labelClasses = useLabelStyles();
  const containerClasses = useContainerStyles();
  const gradientClasses = useGradientStyles();

  const ScrollButton = (props) => {
    return <TabScrollButton {...props} className={`${gradientClasses.root} ${gradientClasses[props.direction]}`} />;
  };

  return (
    <div className={classes.root}>
      <StyledTabs
        style={{ position: 'relative', marginBottom: '40px' }}
        value={value}
        onChange={onChange}
        indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        ScrollButtonComponent={ScrollButton}
        aria-label='scrollable auto tabs'
      >
        {currencies.map((currency) => (
          <StyledTab
            className={classnames({ [classes.errorTab]: includes(errors, currency) })}
            key={currency}
            label={
              <div className={containerClasses.root}>
                <CurrencyItem
                  currency={currency}
                  short
                  className={classnames([labelClasses.label, { [labelClasses.error]: includes(errors, currency) }])}
                />
              </div>
            }
          ></StyledTab>
        ))}
      </StyledTabs>
    </div>
  );
};

import { observable, makeObservable, action } from 'mobx';

type ProgressType = 'progress' | 'failed' | 'completed';

type TimeoutType = {
  requestStartTime: Date;
  minRequestTime: number;
};

class Progress {
  @observable
  progress: {
    [K in string]: {
      status: ProgressType;
    };
  } = {};

  constructor() {
    makeObservable(this);
  }

  @action
  log(action: string, status: ProgressType, timeout?: nil | TimeoutType) {
    const commit = () => {
      this.commitLog(action, status);
    };

    if (!timeout) {
      commit();
      return;
    }

    const { requestStartTime, minRequestTime } = timeout;
    const endTime = new Date();
    const requestTime = (+requestStartTime - +endTime) / 1000;

    if (requestTime > minRequestTime) {
      commit();
      return;
    }

    setTimeout(() => {
      commit();
    }, minRequestTime - requestTime);
  }

  // Same as log but with promise
  @action
  logWithPromise(action: string, status: ProgressType, timeout?: nil | TimeoutType): Promise<void> {
    return new Promise((res, _rej) => {
      if (!timeout) {
        this.commitLog(action, status);
        res();
        return;
      }

      const { requestStartTime, minRequestTime } = timeout;
      const endTime = new Date();
      const requestTime = (+requestStartTime - +endTime) / 1000;

      if (requestTime > minRequestTime) {
        this.commitLog(action, status);
        res();
        return;
      }

      setTimeout(() => {
        this.commitLog(action, status);
        res();
      }, minRequestTime - requestTime);
    });
  }

  @action
  commitLog(action: string, status: ProgressType) {
    this.progress[action] = { status: status };
  }

  isLoading(key: string): boolean {
    const progress = this.progress[key];
    return !!(progress && progress.status === 'progress');
  }

  isActionWasLogged(key: string): boolean {
    const progress = this.progress[key];
    return !!progress;
  }
}

export { Progress };
export default new Progress();

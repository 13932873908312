import { DeleteOutline, DragIndicator, ExpandLess, ExpandMore, InfoOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, Checkbox, FormControlLabel, FormGroup, TextField, Tooltip, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import { ERRORS_PREFIXES } from 'modules/Distances/constants';
import { clearFieldError, getRelationErrors } from 'modules/Distances/utils/errors';
import * as React from 'react';

import { CONFIRM_POPUP_TYPES, DISTANCE } from 'src/constants';

import { DNDItem } from 'components/DragNDrop';

import { t } from 'utils';
import numberWithSuffix from 'utils/numberSuffix';

import { helperRacesStore, progressStore } from 'stores';
import confirmModalStore from 'stores/confirmationModal';

import './Item.scss';

const FieldContainer = withStyles({
  root: {
    paddingBottom: 19,
  },
})(Box);

type Props = {
  onChange: (id: number | string, data: WaveType, callback?: Function) => void;
  onRemove: (id: number | string) => void;
  value: WaveType;
  index: number;
  isExpanded?: boolean;
  isDragDisabled?: boolean;
};

type State = {
  isExpanded: boolean;
};

@observer
class Item extends React.Component<Props, State> {
  state = {
    isExpanded: true,
  };

  onChange = (name: string, changedValue: unknown) => {
    const { value, onChange } = this.props;

    const changeData = { ...value, [name]: changedValue };

    const id = value.id || value.__id || '';
    onChange(id, changeData, () => {
      clearFieldError(this.props.index, ERRORS_PREFIXES.waves, name);
    });
  };

  onChangePlain = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.onChange(name, value);
  };

  onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    const value = /^0\d+/.test(e.currentTarget.value) ? e.currentTarget.value.replace(/^(0+(?=\d))/, '') : e.currentTarget.value;
    return this.onChange(name, +value);
  };

  onChangeCheckbox = (checked: boolean) => {
    this.onChange('is_visible', checked);
  };

  onChangeWithoutEvent = ({ name, value }: { name: string; value: any }) => {
    this.onChange(name, value);
  };

  defaultInputProps = (name: string) => {
    const value = (this.props.value as AnyObject)[name];
    return {
      value,
      name,
      errors: [],
      fullWidth: true,
    };
  };
  defaultCheckboxProps = (name: string) => {
    const value = (this.props.value as AnyObject)[name];
    return {
      checked: Boolean(value),
      name,
      errors: [],
      fullWidth: true,
    };
  };

  onRemove = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    const { onRemove, value } = this.props;
    const id = value.id || value.__id || '';
    confirmModalStore.openModal({
      title: t.staticAsString('distances.steps.wavesForm.confirmDeleteTitle'),
      body:
        value.name.length === 0
          ? t.staticAsString('distances.steps.wavesForm.confirmDeleteBodyEmpty')
          : t.staticAsString('distances.steps.wavesForm.confirmDeleteBody', { name: value.name }),
      type: CONFIRM_POPUP_TYPES.delete,
      btnLabel: {
        confirm: 'distances.steps.wavesForm.confirmDeleteOk',
        cancel: 'shared.confirmPopup.btnAction.cancel',
      },
      onConfirm: () => onRemove(id),
    });
  };

  render() {
    const { isExpanded } = this.state;
    const { index, value, isDragDisabled } = this.props;
    const { defaultLocale } = helperRacesStore.languagesData;
    const disabled = progressStore.isLoading(`UPDATE_${DISTANCE}`);
    if (value._delete) {
      return null;
    }

    return (
      <DNDItem className='wave-dnd-item' id={`${value.id || value.__id || ''}`} index={index} isDragDisabled={disabled}>
        <Card variant='outlined'>
          <Box
            style={{ backgroundColor: '#FAFAFA', cursor: 'pointer' }}
            p={2}
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}
          >
            <Box display='flex' alignItems='center'>
              <DragIndicator style={{ marginRight: 10 }} color='disabled' />
              {/* <Typography>{truncate(value.name ?? value.name_en, { length: 20 })}</Typography> */}
              <Typography>{numberWithSuffix(index + 1)} Wave</Typography>
            </Box>
            <Box>
              {index > 0 && <DeleteOutline style={{ marginRight: 10 }} onClick={this.onRemove} color='disabled' />}
              {isExpanded ? <ExpandLess color='disabled' /> : <ExpandMore color='disabled' />}
            </Box>
          </Box>
          <CardContent style={{ display: isExpanded ? 'block' : 'none' }}>
            <FieldContainer>
              <TextField
                {...this.defaultInputProps('name')}
                label={t.staticAsString('distances.steps.wavesForm.name')}
                error={getRelationErrors(index, ERRORS_PREFIXES.waves, 'name')?.[0]}
                helperText={getRelationErrors(index, ERRORS_PREFIXES.waves, 'name') ?? ''}
                onChange={this.onChangePlain}
                variant='outlined'
                disabled={disabled}
              />
            </FieldContainer>
            <FieldContainer>
              <TextField
                {...this.defaultInputProps('max_members')}
                label={t.staticAsString('distances.steps.wavesForm.max_members')}
                error={getRelationErrors(index, ERRORS_PREFIXES.waves, 'max_members')?.[0]}
                helperText={getRelationErrors(index, ERRORS_PREFIXES.waves, 'max_members') ?? ''}
                onChange={this.onChangeNumber}
                onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
                variant='outlined'
                type='number'
                InputProps={{ inputProps: { min: 1 } }}
                disabled={disabled}
              />
            </FieldContainer>

            <FormGroup>
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Box display='flex' alignItems='center'>
                  <FormControlLabel
                    disabled={disabled}
                    control={
                      <Checkbox
                        {...this.defaultCheckboxProps('is_visible')}
                        onChange={(e, checked) => this.onChangeCheckbox(checked)}
                        name='is_visible'
                        color='primary'
                      />
                    }
                    label={<Typography variant='body2'>{t('distances.steps.wavesForm.is_visible')}</Typography>}
                  />
                  <Tooltip title={<Typography variant='body2'>{t('distances.steps.wavesForm.is_visible_info')}</Typography>}>
                    <InfoOutlined color='disabled' />
                  </Tooltip>
                </Box>
              </Box>
            </FormGroup>
          </CardContent>
        </Card>
      </DNDItem>
    );
  }
}

export { Item };

import { omit } from 'lodash';
import { campaignLinkDetailsService } from 'modules/SmartCampaignLinks/CampaignLinkDetails/services/campaignLinksDetails.service';

import { ROUTES } from 'src/constants';
import { form, toastStore } from 'src/stores';

import { validate } from 'utils/core';
import { history } from 'utils/history';
import { t } from 'utils/t';

import { CAMPAIGN_LINKS_FORM_ID } from '../constants';

import { getToken, mapBEToForm, mapFormToBE } from '../utils';

import { linksService } from '../services';

import { linkStore } from '../stores';

import { campaignLinksFormValidation } from '../validations';

export const handleSubmitEditLink = async () => {
  const token = getToken();
  const data = form.fetchWithoutObservable(CAMPAIGN_LINKS_FORM_ID) as any;
  const isValid = validate(CAMPAIGN_LINKS_FORM_ID, data, campaignLinksFormValidation() as any);
  if (!isValid) return;

  const mappedData = mapFormToBE(data);
  const isOk = await linksService.updateCampaignLink(token, mappedData);
  if (isOk) {
    toastStore.show(t.staticAsString('campaignLinks.formUpdated', { name: mappedData.name }));
    history.push(ROUTES.campaignLinks);
  }
};

export const getLinkData = async () => {
  const token = getToken();
  const linkData = await campaignLinkDetailsService.loadCampaignLink(token);
  const formData = await mapBEToForm(linkData);

  form.onChange(CAMPAIGN_LINKS_FORM_ID, 'name', formData.name);
  form.onChange(CAMPAIGN_LINKS_FORM_ID, 'title', formData.title);
  form.onChange(CAMPAIGN_LINKS_FORM_ID, 'description', formData.description);
  for (let i = 0; i < formData.races.length; i++) {
    form.onChange(CAMPAIGN_LINKS_FORM_ID, `races-${i}`, formData.races[i]);
  }

  linkStore.set(formData);
};

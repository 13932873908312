import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import * as React from 'react';

import { CheckMark } from 'components/CheckMark';
import { Show } from 'components/Condition';

import { useFormControlStyles, useInputStyles, useLabelStyles } from './styles';

type Props = FieldBaseProps & {
  hasCheckmark?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  prefilledValue?: string;
  additional?: TextFieldAdditionalProps;
};

export const TranslationField = ({
  label = '',
  value = '',
  prefilledValue = '',
  name = '',
  onChange,
  hasCheckmark = false,
  additional,
}: Props) => {
  const formControlClasses = useFormControlStyles();
  const inputClasses = useInputStyles({
    isPrefilled: prefilledValue?.length > 0 && !value,
  });

  const labelClasses = useLabelStyles({
    isInputFilled: value?.length > 0 || prefilledValue.length > 0,
  });

  const onInputChange = (e) => {
    const value = e.currentTarget.value;
    onChange({ name, value });
    additional?.onInputChange?.({ name, value });
  };

  const handleBlur = (e) => {
    additional?.onBlur?.(e);
  };

  return (
    <FormControl className={formControlClasses.root}>
      <InputLabel classes={labelClasses} htmlFor='translation-field'>
        {label}
      </InputLabel>
      <Input
        disableUnderline
        className={inputClasses.root}
        id='translation-field'
        name={name}
        value={value || prefilledValue}
        onChange={onInputChange}
        onBlur={handleBlur}
        endAdornment={
          <Show if={hasCheckmark}>
            <InputAdornment position='end'>
              <CheckMark />
            </InputAdornment>
          </Show>
        }
      />
    </FormControl>
  );
};

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { DISTANCE_TEAMS_URL } from 'src/constants';

import { action, request } from 'utils';

import { LOAD_AUTOCOMPLETE } from '../constants';

import { TeamsStore } from '../stores';

class Teams {
  @request({ action: LOAD_AUTOCOMPLETE })
  request(distanceId: number, params: FiltersType): Promise<any> {
    const url = generatePath(DISTANCE_TEAMS_URL, { id: distanceId });
    return axios.get(url, { params });
  }

  @action({ action: LOAD_AUTOCOMPLETE })
  async load(distanceId: number): Promise<TeamType[]> {
    const params = TeamsStore.defaultFilters;
    const [status, response] = await this.request(distanceId, params);

    if (!status) {
      return [];
    }

    return response.data.data;
  }
}

export { Teams, Teams as TeamsService };

export const superTranslationsModelTypeMap = {
  'distanceData.name': 'App\\Distance',
  'distanceData.description': 'App\\Distance',
  'raceData.name': 'App\\Race',
  'raceData.description': 'App\\Race',
  'customFields.name': 'App\\DistanceCustomField',
  'customFields.helper_text': 'App\\DistanceCustomField',
  'customFields.value': 'App\\DistanceCustomFieldValue',
  'distanceClasses.title': 'App\\DistanceClass',
  'disciplines.title': 'App\\DistanceDiscipline',
  'checkpoints.name': 'App\\Checkpoint',
  'waves.name': 'App\\DistanceWaves',
  'emailContent.content': 'App\\Models\\DistanceEmailContent',
};

export const translationsModelIdMap = {
  'distanceData.name': 'distance_id',
  'distanceData.description': 'distance_id',
  'raceData.name': 'race_id',
  'raceData.description': 'race_id',
  'customFields.name': 'custom_field_id',
  'customFields.helper_text': 'custom_field_id',
  'customFields.value': 'field_value_id',
  'distanceClasses.title': 'distance_class_id',
  'disciplines.title': 'discipline_id',
  'checkpoints.name': 'checkpoint_id',
  'waves.name': 'distance_waves_id',
  'emailContent.content': 'content_id',
};

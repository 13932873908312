import { toastStore } from 'stores';

import { raceDetailsService } from '../services';

import { raceDetailsStore } from '../stores';

export const updateRaceExternalLinkAction = async (newValue: string | null) => {
  const race = raceDetailsStore.raceDetails?.value;

  if (!race) {
    return;
  }

  const { id } = race;

  const params = {
    race_url: newValue,
  };

  const [status] = await raceDetailsService.updateRaceExternalLink(id, params);

  if (status) {
    toastStore.show('Race external link has been changed');
    raceDetailsStore.partialRaceUpdate(params);
    return;
  }

  // bad server response, error message was shown before, roll back state
  const previousValue = !newValue;
  raceDetailsStore.partialRaceUpdate({ race_url: previousValue });
};

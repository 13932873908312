import { Box, Grid, Typography } from '@mui/material';
import { Gender } from 'modules/Dashboard/types/gender.type';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';

import { t } from 'utils/t';

type Props = {
  id: string;
  color: string;
  data: Gender;
};

export default function GenderChart({ data: { total, data, dominant, dominantGender } }: Props) {
  return (
    <Grid container>
      <Grid item sm={12} md={8}>
        <div style={{ width: '100%', height: 377 }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie paddingAngle={5} dataKey='value' data={data} fill='#8884d8' />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </Grid>
      <Grid item sm={12} md={4}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            height: '100%',
            width: '100%',
            paddingTop: 8,
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: 48,
              lineHeight: '56px',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {Number.isNaN(dominant) ? 'N/A' : `${dominant}% `}
            <Box
              component={'span'}
              sx={{
                fontWeight: 400,
                fontSize: 24,
                lineHeight: '24px',
                width: '100%',
                ml: 1,
              }}
              display={'inlined'}
            >
              {Number.isNaN(dominant) ? '' : t(`dashboard.${dominantGender}` as any)}
            </Box>
          </Typography>
          <Typography
            sx={{
              color: '#A8AEB8',
              fontWeight: 500,
              fontSize: 24,
              mt: 1,
              lineHeight: '24px',
              width: '100%',
            }}
          >
            {total} {t('dashboard.users')}
          </Typography>
          <Box sx={{ mt: 3, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: 48, height: 8, backgroundColor: '#FCC75D' }}></Box>
              <Typography sx={{ fontSize: 20, fontWeight: 400, ml: 1 }}>{t('dashboard.male')}</Typography>
            </Box>
            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: 48, height: 8, backgroundColor: '#7CCBA5' }}></Box>
              <Typography sx={{ fontSize: 20, fontWeight: 400, ml: 1 }}>{t('dashboard.female')}</Typography>
            </Box>
            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mt: 1, width: 48, height: 8, backgroundColor: '#5DA6FC' }}></Box>
              <Typography sx={{ fontSize: 20, fontWeight: 400, ml: 1 }}>{t('dashboard.other')}</Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MaterialInput from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import classNames from 'classnames';
import * as React from 'react';

import { Icon } from 'components/Icon';

type Props = {
  classes?: {
    [K in string]: string;
  };
  name: string;
  value?: string;
  errors: Array<string>;
  type?: string;
  id?: string;
  label?: string;
  className?: string;
  fullWidth?: boolean;
  withFieldIcon?: boolean;
  reservePlaceForHelperText?: boolean;
};

const Input = ({
  classes,
  name,
  onChange,
  value,
  errors,
  id,
  label,
  className,
  fullWidth,
  onClick,
  withFieldIcon,
  reservePlaceForHelperText = true,
  ...restProps
}: Props & {
  stateValue?: string;
  onClick?: React.EventHandler<React.MouseEvent>;
  onChange?: any;
}) => {
  const formName = id ? `${id}_${name}` : name;
  const isErr = errors.length > 0;

  const inputClasses = {
    ...{
      focused: 'focused',
      underline: value ? 'filled-underline' : 'underline',
    },
    ...classes,
  };
  const errorMessage = errors.join(' ');
  const helperText = errorMessage || (reservePlaceForHelperText && ' ');

  return (
    <div onClick={onClick} className='date-picker-wrapper'>
      {withFieldIcon && (
        <div className='datepicker-icon'>
          <Icon value='calendar' />
        </div>
      )}
      <FormControl
        fullWidth={fullWidth}
        className={classNames('form-control', { error: isErr })}
        error={isErr}
        aria-describedby='name-error-text'
      >
        <InputLabel htmlFor={formName} classes={{ root: 'form-label' }}>
          {label}
        </InputLabel>
        <MaterialInput
          {...restProps}
          value={value}
          classes={inputClasses}
          onChange={onChange}
          name={name}
          fullWidth={fullWidth}
          autoComplete='off'
        />
        <FormHelperText className='errors' id={`${formName}_error`}>
          {helperText}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export { Input };

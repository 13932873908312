import { loadCountryService } from 'services';

import { customersStore } from 'stores';

export const customersUnmount = async () => {
  customersStore.clearAll();
};

export const mount = async () => {
  await loadCountryService.loadResources();
};

import { Chip } from '@mui/material';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { classes } from 'components/Filter/ChipFilterLabel';

import { t } from 'utils';

type Props = {
  value: FilterType;
  name: string;
  onClear: (filterNames: Array<string>) => void;
  className: string;
  values: FiltersType;
};

let StatusFilterLabel = ({ value, name, onClear, className }: Props) => {
  if (!value && value !== 0) {
    return null;
  }

  const status = Number(value);

  return (
    <Chip
      className={classNames(className, 'chip-card')}
      classes={classes}
      onDelete={() => onClear([name])}
      label={t.staticAsString(`categories.statusState.${status}` as TranslationLockedKeys)}
    />
  );
};

StatusFilterLabel = observer(StatusFilterLabel);

export { StatusFilterLabel };

import { Button } from '@mui/material';
import * as React from 'react';

import { ROUTES } from 'src/constants';

import { withoutSidebar, withoutFooter } from 'hocs';

import { history, t } from 'utils';

type Props = {};

@withoutSidebar
@withoutFooter
export class NotFoundPage extends React.PureComponent<Props> {
  handleRedirectRaces = () => {
    history.push(ROUTES.racesRoute);
  };

  render() {
    return (
      <div className='not-found-container'>
        <div className='info-wrap'>
          <div className='info-block'>
            <div className='number'>404</div>
            <div className='text-wrap'>
              <div className='title'>{t.staticAsString('notFound.title')}</div>
              <div className='text-content'>{t.staticAsString('notFound.text')}</div>
              <Button className='btn-redirect' onClick={this.handleRedirectRaces}>
                {t.staticAsString('notFound.btnReturn')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import { pickBy, omit, pipe } from 'lodash/fp';
import { roundDiff } from 'modules/Results/tools';
import moment from 'moment';

import { BE_DURATION_MS_FORMAT } from 'src/constants';

import { Distance } from 'models';

import { NewResultForm, NewResult } from '../types';

class NewResultFormNewResult implements MapperMiddleware<NewResultForm, NewResult> {
  mapTo(data: NewResultForm, distance: Distance) {
    const timeResult = moment.utc(moment.duration(data.time_result || 0).asMilliseconds()).format(BE_DURATION_MS_FORMAT);
    const distanceLoggedAt = data.distance_logged_at && data.distance_logged_at.compose().val;

    const omitCols = [];

    if (!distance.isMode('cumulative')) {
      omitCols.push.apply(omitCols, ['distance_logged', 'distance_logged_at']);
    }

    if (distance.isMode('classic')) {
      omitCols.push.apply(omitCols, ['activity_link']);
    }

    const sourceData = {
      time_result: timeResult,
      activity_link: data.activity_link || null,
      distance_logged: Number(data.distance_logged) || null,
      distance_logged_at: distanceLoggedAt,
    };

    const result = pipe(
      pickBy((value) => !!value),
      omit(omitCols),
    )(sourceData);

    return result as NewResult;
  }
}

export const mapperNewResultFormNewResult = new NewResultFormNewResult();

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

export const useIconStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.green3,
  },
}));

export const CheckMark = () => {
  const iconClasses = useIconStyles();
  return <CheckCircleIcon className={iconClasses.icon} />;
};

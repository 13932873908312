import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SearchIcon from '@mui/icons-material/Search';
import classNames from 'classnames';
import * as React from 'react';

import { SORT_BY } from 'src/constants';

import { Show } from 'components/Condition';

type Props = {
  value: SortTypes;
  active: boolean;
  children: React.ReactNode;
  onChange: () => void;
  displaySearchIcon: boolean;
};

export function OrderableLabel({ value, active, children, onChange, displaySearchIcon }: Props) {
  const onClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    onChange();
  };

  return (
    <div className={classNames('label-wrapper', { active }, { reverse: value === SORT_BY.asc })}>
      <Show if={displaySearchIcon}>
        <SearchIcon className='filter-icon' />
      </Show>
      <span className='label'>{children}</span>
      <ArrowDownwardIcon onClick={onClick} className='icon' />
    </div>
  );
}

import { t } from 'utils';

export const mergeAccounts = (): any => {
  const presence = {
    allowEmpty: false,
    message: t.staticAsString('validation.required'),
  };

  return {
    sourceEmail: {
      presence,
    },
    targetEmail: {
      presence,
      equality: {
        attribute: 'sourceEmail',
        message: t.staticAsString('supportTaskManager.form.validation.cantBeSame'),
        comparator: function (targetEmail, sourceEmail) {
          return JSON.stringify(targetEmail.value) !== JSON.stringify(sourceEmail.value);
        },
      },
    },
    deleteSourceAccount: {
      presence: {
        allowEmpty: true,
        message: t.staticAsString('validation.required'),
      },
    },
  };
};

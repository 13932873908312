import { DummyStore, DisposersStore } from 'src/stores';

import { SETTINGS_STEP } from '../constants';

import { OptionWithParentId } from '../types';

export * from './races';

export const stepStore = new DummyStore(SETTINGS_STEP);
export const disposersStore = new DisposersStore();

export const syncClassesStore = new DummyStore<OptionWithParentId[]>([]);
export const syncWavesStore = new DummyStore<OptionWithParentId[]>([]);

export const raceClassesStore = new DummyStore<OptionWithParentId[]>([]);
export const raceWavesStore = new DummyStore<OptionWithParentId[]>([]);
export const syncCustomFieldsStore = new DummyStore<SelectOption[]>([]);
export const raceCustomFieldsStore = new DummyStore<OptionWithParentId[]>([]);

import { flatten, isArray, isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';

import { TimeRangeFilter } from 'components/Filter/DefaultFilters';

import { t } from 'utils';

import { paymentsStore } from '../stores';

type Props = {} & FilterProps;

@observer
class Field extends React.Component<Props> {
  render() {
    if (isEmpty(paymentsStore.search.values?.created_at)) {
      return <div className='tooltip-container'>{t.staticAsString('payments.list.table.created_timeDisabled')}</div>;
    }

    return <TimeRangeFilter {...this.props} />;
  }
}

export const created_time: FilterListDescriptor = {
  filter: {
    persistance: {
      advanced: {
        calcHandler: function (name, value) {
          if (!isArray(value)) {
            return;
          }
          const [start, end] = value;

          const fromPresented = !!start;
          const toPresented = !!end;

          if (fromPresented && !toPresented) {
            return '>=';
          }

          if (toPresented && !fromPresented) {
            return '<=';
          }

          return { created_time: 'between' };
        },

        calcValue: function (name, value) {
          if (!isArray(value)) {
            return;
          }
          const [start, end] = value;

          const fromPresented = !!start;
          const toPresented = !!end;
          if (fromPresented && !toPresented) {
            return start;
          }

          if (toPresented && !fromPresented) {
            return end;
          }

          return {
            created_time: [`${value[0]}:00`, `${value[1]}:59`],
          };
        },
      },
      prettify: (name, value) => {
        const [fromValue, toValue] = flatten([value]);
        if (!fromValue || !toValue) {
          return null;
        }

        return `${fromValue} - ${toValue}`;
      },
    },
  },
  frontend: {
    type: 'custom',
    component: Field,
  },
  sort: {
    default: true,
    reorderOnSearch: true,
    defaultBy: 'DESC',
    calcOrderBy: (name) => name,
  },
};

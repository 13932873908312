import axios from 'axios';
import { generatePath } from 'react-router-dom';

import {
  EXPORT_JOB_ID,
  EXPORT_PAYMENTS_URL,
  EXPORT_RESULTS_URL,
  EXPORT_TEMPLATE_URL,
  EXPORT_START_LIST_URL,
  EXPORT_TEAM_START_LIST_URL,
  EXPORT_ORGANIZERS_URL,
  EXPORT_CUSTOMERS_URL,
  EXPORT_ORGANIZER_PAYMENT_URL,
  EXPORT_COUPONS_URL,
  EXPORT_RACERS_URL,
  EXPORT_START_LIST_WAVES_URL,
  EXPORT_RACE_SUMMARY_URL,
  EXPORT_IMPORT_START_LIST_TEMPLATE_URL,
  EXPORT_REPORTS_URL,
  EXPORT_COUPON_URL,
  EXPORT_SMART_LINKS,
  EXPORT_SMART_LINK,
  EXPORT_CAMPAIGN_LINK,
  EXPORT_CAMPAIGN_LINKS,
} from 'src/constants';

import { request, action } from 'utils';
import { string } from 'utils/defaultListFilters/string';

class ExportJobService {
  @request({ action: 'CHECK_JOB_ID' })
  checkJobIdRequest(id: number): Promise<any> {
    return axios.get(generatePath(EXPORT_JOB_ID, { id }));
  }

  @action({ action: 'CHECK_JOB_ID' })
  async checkJobId(id: number): Promise<any> {
    return await this.checkJobIdRequest(id);
  }

  @request({ action: 'EXPORT_PAYMENTS' })
  exportPaymentsRequest(params: FiltersType): Promise<any> {
    return axios.get(EXPORT_PAYMENTS_URL, {
      params,
    });
  }

  @action({ action: 'EXPORT_PAYMENTS' })
  async exportPayments(type: string, params: FiltersType): Promise<any> {
    const updParams = {
      type,
      ...params,
    };
    const [status, response] = await this.exportPaymentsRequest(updParams);
    return [status, response];
  }

  @request({ action: 'EXPORT_REPORTS' })
  exportReportsRequest(params: FiltersType): Promise<any> {
    return axios.get(EXPORT_REPORTS_URL, {
      params,
    });
  }

  @action({ action: 'EXPORT_REPORTS' })
  async exportReports(type: string, params: FiltersType): Promise<any> {
    const updParams = {
      type,
      ...params,
    };
    const [status, response] = await this.exportReportsRequest(updParams);
    return [status, response];
  }

  @request({ action: 'EXPORT_COUPONS' })
  exportCouponsRequest(params: FiltersType): Promise<any> {
    return axios.get(EXPORT_COUPONS_URL, {
      params,
    });
  }

  @action({ action: 'EXPORT_COUPONS' })
  async exportCoupons(type: string, params: FiltersType): Promise<any> {
    const updParams = {
      type,
      ...params,
    };

    const [status, response] = await this.exportCouponsRequest(updParams);

    return [status, response];
  }

  @request({ action: 'EXPORT_COUPON' })
  exportCouponRequest(id: number): Promise<any> {
    return axios.get(generatePath(EXPORT_COUPON_URL, { id }));
  }

  @action({ action: 'EXPORT_COUPON' })
  async exportCoupon(id: number): Promise<any> {
    const [status, response] = await this.exportCouponRequest(id);
    return [status, response];
  }

  @request({ action: 'EXPORT_RESULTS' })
  exportResultsRequest(id: number, params: FiltersType): Promise<any> {
    return axios.get(generatePath(EXPORT_RESULTS_URL, { id }), {
      params,
    });
  }

  @action({ action: 'EXPORT_RESULTS' })
  async exportResults(id: number, params: FiltersType): Promise<any> {
    const [status, response] = await this.exportResultsRequest(id, params);

    return [status, response];
  }
  @request({ action: 'EXPORT_TEMPLATE' })
  exportTemplateRequest(distanceId: number): Promise<any> {
    return axios.get(generatePath(EXPORT_TEMPLATE_URL, { distanceId }));
  }

  @action({ action: 'EXPORT_TEMPLATE' })
  async exportTemplate(id: number): Promise<any> {
    const [status, response] = await this.exportTemplateRequest(id);

    return [status, response];
  }

  @request({ action: 'EXPORT_START_LIST' })
  exportStartListRequest(id: number, params: FiltersType): Promise<any> {
    return axios.get(generatePath(EXPORT_START_LIST_URL, { id }), {
      params,
    });
  }

  @request({ action: 'EXPORT_TEAM_START_LIST' })
  exportTeamStartListRequest(id: number, params: FiltersType): Promise<any> {
    return axios.get(generatePath(EXPORT_TEAM_START_LIST_URL, { id }), {
      params,
    });
  }

  @request({ action: 'EXPORT_START_LIST_TEMPLATE' })
  exportStartListTemplateRequest(distanceId: number): Promise<any> {
    return axios.get(generatePath(EXPORT_IMPORT_START_LIST_TEMPLATE_URL, { distanceId }));
  }

  @action({ action: 'EXPORT_START_LIST_TEMPLATE' })
  async exportStartListTemplate(id: number): Promise<any> {
    const [status, response] = await this.exportStartListTemplateRequest(id);

    return [status, response];
  }

  @request({ action: 'EXPORT_RACES_SUMMARY' })
  exportRacesSummaryRequest(id: number, params: FiltersType): Promise<any> {
    return axios.get(generatePath(EXPORT_RACE_SUMMARY_URL, { id }), {
      params,
    });
  }

  @request({ action: 'EXPORT_START_LIST' })
  exportStartListWithWavesRequest(id: number, params: FiltersType): Promise<any> {
    return axios.get(generatePath(EXPORT_START_LIST_WAVES_URL, { id }), {
      params,
    });
  }

  @action({ action: 'EXPORT_START_LIST' })
  async exportStartList(id: number, type: string, params: FiltersType): Promise<any> {
    const [status, response] = await this.exportStartListRequest(id, {
      ...params,
      type,
    });

    return [status, response];
  }

  @action({ action: 'EXPORT_TEAM_START_LIST' })
  async exportTeamStartList(id: number, type: string, params: FiltersType): Promise<any> {
    const [status, response] = await this.exportTeamStartListRequest(id, {
      ...params,
      type,
    });

    return [status, response];
  }

  @action({ action: 'EXPORT_START_LIST_WAVES' })
  async exportStartListWithWaves(id: number, type: string, params: FiltersType): Promise<any> {
    const [status, response] = await this.exportStartListWithWavesRequest(id, {
      ...params,
      type,
    });

    return [status, response];
  }

  @request({ action: 'EXPORT_ORGANIZERS' })
  exportOrganizersRequest(params: FiltersType): Promise<any> {
    return axios.get(generatePath(EXPORT_ORGANIZERS_URL), {
      params,
    });
  }

  @action({ action: 'EXPORT_ORGANIZERS' })
  async exportOrganizers(type: string, params: FiltersType): Promise<any> {
    const [status, response] = await this.exportOrganizersRequest({
      ...params,
      type,
    });

    return [status, response];
  }

  @request({ action: 'EXPORT_CUSTOMERS' })
  exportCustomersRequest(params: FiltersType): Promise<any> {
    return axios.get(generatePath(EXPORT_CUSTOMERS_URL), {
      params,
    });
  }

  @action({ action: 'EXPORT_CUSTOMERS' })
  async exportCustomers(type: string, params: FiltersType): Promise<any> {
    const [status, response] = await this.exportCustomersRequest({
      ...params,
      type,
    });

    return [status, response];
  }
  @request({ action: 'EXPORT_RACERS' })
  exportRacersRequest(params: FiltersType): Promise<any> {
    return axios.get(generatePath(EXPORT_RACERS_URL), {
      params,
    });
  }

  @action({ action: 'EXPORT_RACERS' })
  async exportRacers(type: string, params: FiltersType): Promise<any> {
    const [status, response] = await this.exportRacersRequest({
      ...params,
      type,
    });

    return [status, response];
  }

  @request({ action: 'EXPORT_ORGANIZER_PAYMENT' })
  exportOrganizerPaymentRequest(organizerId: number, paymentId: number, params: FiltersType): Promise<any> {
    return axios.get(generatePath(EXPORT_ORGANIZER_PAYMENT_URL, { organizerId, paymentId }), {
      params,
    });
  }

  @action({ action: 'EXPORT_ORGANIZER_PAYMENT' })
  async exportOrganizerPayment(organizerId: number, paymentId: number, params: FiltersType): Promise<any> {
    const [status, response] = await this.exportOrganizerPaymentRequest(organizerId, paymentId, params);

    return [status, response];
  }

  @request({ action: 'EXPORT_SMART_LINKS' })
  exportSmartLinksRequest(params: FiltersType): Promise<any> {
    return axios.get(EXPORT_SMART_LINKS, {
      params,
    });
  }

  @action({ action: 'EXPORT_SMART_LINKS' })
  async exportSmartLinks(type: string, params: FiltersType): Promise<any> {
    const updParams = {
      type,
      ...params,
    };

    const [status, response] = await this.exportSmartLinksRequest(updParams);

    return [status, response];
  }

  @request({ action: 'EXPORT_SMART_LINK' })
  exportSmartLinkRequest(token: string): Promise<any> {
    return axios.get(generatePath(EXPORT_SMART_LINK, { token }));
  }

  @action({ action: 'EXPORT_SMART_LINK' })
  async exportSmartLink(token: string): Promise<any> {
    const [status, response] = await this.exportSmartLinkRequest(token);
    return [status, response];
  }

  @request({ action: 'EXPORT_CAMPAIGN_LINK' })
  exportCampaignLinkRequest(token: string): Promise<any> {
    return axios.get(generatePath(EXPORT_CAMPAIGN_LINK, { token }));
  }

  @action({ action: 'EXPORT_CAMPAIGN_LINK' })
  async exportCampaignLink(token: string): Promise<any> {
    const [status, response] = await this.exportCampaignLinkRequest(token);
    return [status, response];
  }

  @request({ action: 'EXPORT_CAMPAIGN_LINKS' })
  exportCampaignLinksRequest(params: FiltersType): Promise<any> {
    return axios.get(EXPORT_CAMPAIGN_LINKS, {
      params,
    });
  }

  @action({ action: 'EXPORT_CAMPAIGN_LINKS' })
  async exportCampaignLinks(type: string, params: FiltersType): Promise<any> {
    const updParams = {
      type,
      ...params,
    };

    const [status, response] = await this.exportCampaignLinksRequest(updParams);

    return [status, response];
  }
}

const exportJobService = new ExportJobService();
export { exportJobService };

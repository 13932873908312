import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import React from 'react';

import { t } from 'utils';

const Toolbar = ({ onAddNewCoupon }: { onAddNewCoupon: (e: React.SyntheticEvent) => void }) => {
  return (
    <div className='toolbar'>
      <h1 className='title'>{t.staticAsString('coupons.list.header')}</h1>
      <Button onClick={onAddNewCoupon} variant='contained' className='add-button'>
        <AddIcon className='icon' />
        {t.staticAsString('coupons.list.new_coupon')}
      </Button>
    </div>
  );
};

export { Toolbar };

import { entries } from 'lodash';

export const GOOGLE_PLACES_KEYS = {
  // Also possible to add region
  street_number: ['street_number'],
  zip_code: ['postal_code'],
  street_address: ['street_address', 'route'],
  city: [
    'locality',
    'sublocality',
    'postal_town',
    'sublocality_level_1',
    'sublocality_level_2',
    'sublocality_level_3',
    'sublocality_level_4',
  ],
  country: ['country'],
};

export const GOOGLE_PLACES_KEYS_MAPPER = entries(GOOGLE_PLACES_KEYS).reduce((acc: any, el: any) => {
  const [key, placesKeys] = el;
  const reverted = placesKeys.reduce((acc: any, el: any) => ({ ...acc, [el]: key }), {});
  return { ...acc, ...reverted };
}, {});

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Paper, Typography } from '@mui/material';
import * as React from 'react';

import { t } from 'utils';

import { endStatusStyles } from '../styles';

export const LoadingFinished = ({ upload }: { upload?: boolean }) => {
  const classes = endStatusStyles();

  return (
    <Paper elevation={3} className={classes.paper}>
      <CheckCircleIcon className={`${classes.icon} ${classes.passColor}`} />
      <Typography variant='h5' component='h2' className={classes.text}>
        {upload ? t.staticAsString('shared.loadPopup.uploadComplete') : t.staticAsString('shared.loadPopup.loadComplete')}
      </Typography>
    </Paper>
  );
};

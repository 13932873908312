import { debounce } from 'lodash';

// NOTE Sort
// UI is calling methods of this service to change searching parameters
class Sort implements SortService {
  service: ExtensionableSortService;
  debouncedLoad = debounce(() => this.service.load(), 200);

  constructor(service: ExtensionableSortService) {
    this.service = service;
  }

  async apply(callback: Function, name: string, params?: nil | Object) {
    const { sort } = this.service.store;

    // @ts-ignore
    await sort.applyValue(name, params);
    callback && callback();
    this.debouncedLoad();
  }

  async applyBasedOnSearch(filters: Array<any>) {
    const col: string = (filters[0] || {}).name;
    const { sort } = this.service.store;

    if (!col) {
      return;
    }

    const sortDescriptor = this.service.store.sort.filterDescriptors[col];

    if (!sortDescriptor) {
      return;
    }

    if (!sortDescriptor.isNeccessaryToApplySortBySearch()) {
      return;
    }

    if (col === sort.value?.name) {
      return;
    }

    await sort.applyValue(col);
  }

  async reset() {
    const { sort } = this.service.store;
    await sort.reset();
    this.service.load();
  }
}

export { Sort, Sort as SortService };

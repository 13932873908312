import { storage } from './storage';

const SESSION_KEY = 'session';
class SessionService {
  storeSession(user: User | {} = {}, token: nil | string): boolean {
    return storage.set(SESSION_KEY, {
      user,
      token,
    });
  }

  retrieveSession(): SessionType {
    return {
      // @ts-ignore
      user: {},
      token: null,
      ...storage.get(SESSION_KEY),
    };
  }
}

const sessionService = new SessionService();
export { SessionService, sessionService };

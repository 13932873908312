import { cloneDeep } from 'lodash';
import { Observer } from 'mobx-react';
import { filtersToBackend } from 'modules/Coupons/mapper';
import { couponDetailsService } from 'modules/Coupons/services/couponsDetails.service';
import { couponOrdersStore } from 'modules/Coupons/stores';
import React, { useCallback } from 'react';

import { t } from 'utils/t';

import { TransactionsTable } from './Table';
import { generateTransactionColumns } from './Table/Columns';

interface Props {
  couponData: CouponType;
}

export const Transactions: React.FC<Props> = ({ couponData }) => {
  const couponId = couponData.id;

  const fetchTxs = useCallback(({ pageIndex = 0, pageSize, filters, sortBy }) => {
    let orderBy = 'created_at';
    let sortedBy = 'DESC';
    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      orderBy = id.includes('.') ? id.split('.').join('|') : id;
      sortedBy = desc ? 'DESC' : 'ASC';
    }

    couponDetailsService.loadCouponOrders(couponId, {
      page: pageIndex + 1,
      limit: pageSize,
      orderBy,
      sortedBy,
      ...filtersToBackend(filters),
    });
  }, []);

  return (
    <>
      <h1 style={{ paddingLeft: 24, paddingTop: 38, paddingBottom: 30, fontWeight: 700 }}>
        {t.staticAsString('coupons.detail.listOfRegistrations')}
      </h1>
      <div style={{ padding: 0, backgroundColor: 'white' }}>
        <Observer
          render={() => {
            const tableData = couponOrdersStore.value?.data.map((order) => {
              return order;
            });
            return (
              <TransactionsTable
                tableData={tableData ?? []}
                tableMeta={couponOrdersStore.value?.meta ?? {}}
                initialSizeOfPage={30}
                pageSizeOptions={[30, 50, 100, 300]}
                tableColumns={generateTransactionColumns()}
                fetchTxs={fetchTxs}
              />
            );
          }}
        />
      </div>
    </>
  );
};

import { Box, Button, Chip } from '@mui/material';
import { isEmpty, omit, get, cloneDeep } from 'lodash';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import { getAssistantLabel as getLabel } from 'modules/Distances/utils';
import { Breadcrumbs } from 'modules/SystemUsers/components/Breadcrumbs';
import { systemUserFormStore, systemUserRolesStore, organizersStore } from 'modules/SystemUsers/stores';
import { Role } from 'modules/SystemUsers/types';
import { formToValidRole, getName, isSystemUserNeedCreator, isOwnProfile } from 'modules/SystemUsers/utils';
import { useEffect, useState } from 'react';
import { matchPath } from 'react-router-dom';
import shortid from 'shortid';

import { withProgressSpinner } from 'src/components/hocs';
import { ROUTES } from 'src/constants/routes';
import { history, validate, t, htmlSanitizer } from 'src/utils';

import { Show } from 'components/Condition';
import { CurrencyFlag } from 'components/Flag';
import { Form, InputWrapper, MultiSelect, Select, TextField, WrappedCheckbox, AutocompleteSelect } from 'components/Form';
import { InformationNote } from 'components/InformationNote';
import { Spinner } from 'components/Spinner';

import { form, sessionStore } from 'stores';
import currencies from 'stores/currencies';

import { DisabledHelperText } from '../../components';

import { SystemUserFormPageConstants } from '../../constants/systemUserFormPage.constants';

import { SystemUserFormPageActions } from '../../actions/form-page.actions/systemUser.form-page.actions';

import PaymentDetails from './PaymentDetails/PaymentDetails';
import { GroupTitle, useSystemUsersFormStyles, DisabledField, NoteWrapper } from './styles';
import { paymentDetailsSchema, systemUserSchema, timingMedicalSchema } from './validations';

type Props = {};
const medical = 'Medical Assistant';
const timing = 'Timing Assistant';
const view = { fullWidth: true, variant: 'outlined' };
const mockOrganizerRole: Role = { name: 'organizer', title: 'Organizer' };
const label = (value: string) => t.staticAsString(`systemUsers.form.${value}` as any);

function SystemUserForm({}: Props) {
  const isEdit = Boolean(systemUserFormStore.selected);

  const [isUserLoading, setIsUserLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [listErrorTabs, setListErrorTabs] = useState<Array<string>>([]);

  const classes = useSystemUsersFormStyles();
  const breadcrumbs = () => {
    const { modelSelected } = systemUserFormStore;
    if (!modelSelected) return [];
    const label = getLabel(modelSelected);
    return [{ key: shortid(), label }];
  };

  const initForm = async () => {
    try {
      const { pathname } = history.location;
      const { params: { id } = { id: null } } = matchPath(pathname, { path: ROUTES.systemUserEditRoute }) || {};

      await SystemUserFormPageActions.initSystemUsersForm(id);

      form.merge(SystemUserFormPageConstants.systemUsersFormId, systemUserFormStore.selected!);
      setIsUserLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUserLoading(false);
    }
  };

  useEffect(() => {
    initForm();
    return () => {
      SystemUserFormPageActions.unmount();
    };
  }, []);

  const isSupportRole = (role: string) => role === medical || role === timing;

  const validatePaymentDetailsSchema = (label: string) => {
    const paymentObject = toJS(form.fetch<AnyObject>(label as string));

    let validationSchemaByCondition;

    const selectedCountry = get(paymentObject, 'country_id')?.value ?? false;
    const isVatExemption = get(paymentObject, 'vat_exemption') ?? false;

    if (selectedCountry === SystemUserFormPageConstants.swedenId) {
      validationSchemaByCondition = omit(paymentDetailsSchema, [
        'vat_exemption',
        'vat_number',
        'iban_number',
        'swift_big',
        'clearing_routing_bsb_code',
        'bank_name',
        'bank_address',
      ]);
    } else if (selectedCountry !== SystemUserFormPageConstants.swedenId) {
      validationSchemaByCondition = omit(paymentDetailsSchema, [
        'pg_bg',
        'account_number',
        'clearing_number',
        isVatExemption ? 'vat_number' : '',
      ]);
    } else {
      validationSchemaByCondition = paymentDetailsSchema;
    }

    return validate(label as string, paymentObject, validationSchemaByCondition as AnyObject);
  };

  const validateForm = (values: AnyObject) => {
    setListErrorTabs([]);
    let detailPaymentErrors: Array<string> = [];
    let omittedSystemUserSchema: AnyObject = {};

    const role = get(values, 'role');
    const medical = 'Medical Assistant';
    const timing = 'Timing Assistant';

    if (sessionStore.isAdmin && isSystemUserNeedCreator(formToValidRole(role))) {
      omittedSystemUserSchema = systemUserSchema;
    } else {
      omittedSystemUserSchema = omit(systemUserSchema, 'created_by');
    }

    if (role === medical || role === timing) {
      omittedSystemUserSchema = timingMedicalSchema;
    }

    const isValidPersonalInfo = validate(SystemUserFormPageConstants.systemUsersFormId, values, omittedSystemUserSchema as any);
    const listCurrencies = values?.selectedCurrencies || [];

    if (!isEmpty(listCurrencies)) {
      listCurrencies.forEach((currency: SelectOption) => {
        let isErrorTab = validatePaymentDetailsSchema(currency.label as string);
        if (!isErrorTab) {
          detailPaymentErrors.push(currency.label as string);
          setListErrorTabs((state) => [...state, currency.label as string]);
        }
      });
    }

    return !isValidPersonalInfo || !isEmpty(detailPaymentErrors);
  };

  const handleChangeTab = (event, value: number) => setCurrentTab(value);

  const handleSubmit = async () => {
    const values = toJS(form.fetch<AnyObject>(SystemUserFormPageConstants.systemUsersFormId));

    const hasInValidFields = validateForm(values);
    if (hasInValidFields) {
      return;
    }
    const { pathname } = history.location;
    const { params: { id } = { id: null } } = matchPath(pathname, { path: ROUTES.systemUserEditRoute }) || {};

    if (isEdit && id) {
      await SystemUserFormPageActions.updateUser(id, values as any);
    } else {
      await SystemUserFormPageActions.createUser(values as any);
    }
  };

  const roleOptions = (): Array<Role> => {
    const listRoles = cloneDeep(systemUserRolesStore.value) as Array<Role>;

    if (isOwnProfile()) {
      listRoles.push(mockOrganizerRole as Role);
    }

    return listRoles as Array<Role>;
  };

  const organizerOptions = () => {
    if (!sessionStore.isAdmin) return;
    return (
      [
        ...(organizersStore.value as []),
        {
          id: sessionStore.user?.user_id,
          full_name: sessionStore.user?.full_name,
          firstname: sessionStore.user?.full_name,
          lastname: sessionStore.user?.full_name,
          username: sessionStore.user?.full_name,
        },
      ] || []
    ).map<SelectOption>((organizer: AnyObject) => ({
      key: organizer.id,
      label: getName(organizer) || '',
      value: organizer.id,
    }));
  };

  return (
    <Observer
      render={() => {
        const selectedCurrencies = () =>
          form.fetch<SelectOption[]>(SystemUserFormPageConstants.systemUsersFormId, 'selectedCurrencies') || [];
        const selectedOrganizedRole = () => {
          const title = form.fetch<string>(SystemUserFormPageConstants.systemUsersFormId, 'role') || null;
          if (isOwnProfile() && title === 'Organizer') return 'organizer';
          return systemUserRolesStore.value?.find((el) => el.title === title)?.name;
        };

        if (isUserLoading) {
          return (
            <div className='content main-distance-form distance-form-scroll'>
              <Spinner type={'cubesSpinner'} />
            </div>
          );
        }

        return (
          <div className='content main-container-list'>
            <Breadcrumbs items={breadcrumbs()} />
            <Form disableAutofill id={SystemUserFormPageConstants.systemUsersFormId} onSubmit={handleSubmit} cleanErrorOnChange>
              <div className={classes.wrapper}>
                <Box margin='0 auto' maxWidth={582} display='flex' justifyContent='center'>
                  <InformationNote
                    isDissmissible
                    isInfo
                    title={t.staticAsString('systemUsers.form.noteTitle')}
                    body={
                      <>
                        <NoteWrapper
                          key={shortid()}
                          dangerouslySetInnerHTML={{
                            __html: htmlSanitizer(
                              t.staticAsString('systemUsers.form.helperNote', {
                                url: 'https://support.raceid.com/' as string,
                              }),
                            ),
                          }}
                        ></NoteWrapper>
                      </>
                    }
                  />
                </Box>
                <div className={classes.form}>
                  <GroupTitle>{t.static('systemUsers.form.personalInfo')}</GroupTitle>
                  <div className={classes.inputWrapper}>
                    <InputWrapper
                      settings={{
                        required: true,
                        label: label('email'),
                        view,
                      }}
                      Component={TextField}
                      name='email'
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputWrapper
                      settings={{
                        required: true,
                        label: label('password'),
                        type: 'password',
                        view: {
                          type: 'password',
                          ...view,
                        },
                      }}
                      Component={TextField}
                      name='password'
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <DisabledField>
                      <InputWrapper
                        settings={{
                          required: true,
                          label: label('role'),
                          options: roleOptions().map((role) => {
                            return {
                              label: role.title,
                              value: role.title,
                              key: shortid(),
                            } as SelectOption;
                          }),
                          view: {
                            ...view,
                            variant: !sessionStore.isAdmin ? 'filled' : view.variant,
                          },
                          additional: {
                            disabled: isOwnProfile(),
                          },
                        }}
                        Component={Select}
                        name='role'
                      />
                    </DisabledField>
                    <Show if={isOwnProfile()}>
                      <DisabledHelperText text={label('disableRoleHelperText')} />
                    </Show>
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputWrapper
                      settings={{
                        required: true,
                        label: label(`${sessionStore.isAdmin ? 'full_name' : 'raceAssistantFullName'}`),
                        view: {
                          ...view,
                        },
                      }}
                      Component={TextField}
                      name='full_name'
                    />
                  </div>
                  <Show if={sessionStore.isAdmin && (isSystemUserNeedCreator(selectedOrganizedRole()) as boolean)}>
                    <div className={classes.inputWrapper}>
                      <InputWrapper
                        name='created_by'
                        settings={{
                          label: label('created_by'),
                          view: { ...view },
                          additional: {
                            options: organizerOptions(),
                          },
                          onInputChange: () => {},
                        }}
                        Component={AutocompleteSelect}
                      />
                    </div>
                  </Show>
                  <div className={classes.inputWrapper}>
                    <InputWrapper
                      name='send_password_changed_email'
                      settings={{
                        view: { ...view },
                        label: label(
                          !isEdit
                            ? `send_password_changed_email${sessionStore.isOrganizer ? '.raceAssistant' : ''}`
                            : `send_password_changed_email_edit${sessionStore.isOrganizer ? '.raceAssistant' : ''}`,
                        ),
                      }}
                      Component={WrappedCheckbox}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.wrapper}>
                <div className={classes.form}>
                  <GroupTitle>{t.static('systemUsers.form.contactInfo')}</GroupTitle>
                  <div className={classes.inputWrapper}>
                    <InputWrapper
                      settings={{
                        label: label('firstname'),
                        view: {
                          ...view,
                        },
                        required: true,
                      }}
                      Component={TextField}
                      name='firstname'
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputWrapper
                      settings={{
                        label: label('lastname'),
                        view: {
                          ...view,
                        },
                        required: true,
                      }}
                      Component={TextField}
                      name='lastname'
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputWrapper
                      settings={{
                        label: label('companyOrOrganizationName') + (selectedOrganizedRole() !== 'organizer' ? '' : '*'),
                        view,
                        required: true,
                      }}
                      Component={TextField}
                      name='company'
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputWrapper
                      settings={{
                        label: label('organizationNumber') + (selectedOrganizedRole() !== 'organizer' ? '' : '*'),
                        view: {
                          ...view,
                        },
                        required: true,
                      }}
                      Component={TextField}
                      name='organization_number'
                    />
                  </div>

                  <div className={classes.inputWrapper}>
                    <InputWrapper
                      settings={{
                        label: label('phone') + (selectedOrganizedRole() !== 'organizer' ? '' : '*'),
                        view: {
                          ...view,
                        },
                        required: true,
                      }}
                      Component={TextField}
                      name='phone'
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputWrapper
                      settings={{
                        label: label('address') + (selectedOrganizedRole() !== 'organizer' ? '' : '*'),
                        view: {
                          ...view,
                        },
                        required: true,
                      }}
                      Component={TextField}
                      name='address'
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputWrapper
                      settings={{
                        label: label('city') + (selectedOrganizedRole() !== 'organizer' ? '' : '*'),
                        view: {
                          ...view,
                        },
                        required: true,
                      }}
                      Component={TextField}
                      name='city'
                    />
                  </div>
                  <div className={classes.inputWrapper}>
                    <InputWrapper
                      settings={{
                        label: label('post_code') + (selectedOrganizedRole() !== 'organizer' ? '' : '*'),
                        view: {
                          ...view,
                        },
                        required: true,
                      }}
                      Component={TextField}
                      name='post_code'
                    />
                  </div>
                  <Show if={selectedOrganizedRole() !== 'organizer'}>
                    <div className={classes.wrapper}>
                      <div className={classes.form}>
                        <div style={{ marginTop: 20 }} className={classes.buttonGroup}>
                          <Button className='cancel' onClick={() => history.goBack()}>
                            {label('cansel')}
                          </Button>
                          <Button
                            style={{ marginLeft: 21 }}
                            disableElevation
                            variant='contained'
                            color='primary'
                            className='submit'
                            onClick={handleSubmit}
                          >
                            {label('submit')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Show>
                </div>
              </div>
              <Show if={selectedOrganizedRole() === 'organizer'}>
                <div className={classes.wrapper}>
                  <div className={classes.form}>
                    <GroupTitle>{t.static('systemUsers.form.currencySettings')}</GroupTitle>
                    <div className={classes.inputWrapper}>
                      <InputWrapper
                        settings={{
                          label: t.static('systemUsers.form.selectCurrencies'),
                          view,
                          customRenderSelected: (options: any[]) => {
                            return (
                              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {options.map((option: any) => (
                                  <Chip
                                    style={{ marginRight: 10, marginTop: 5, marginBottom: 5, background: 'rgba(33, 33, 33, 0.08)' }}
                                    key={option.key}
                                    label={
                                      <Box display='flex'>
                                        <Box mx={1}>
                                          <CurrencyFlag currency={option.label} />
                                        </Box>
                                        <Box mr={1}>{option.label}</Box>
                                      </Box>
                                    }
                                  />
                                ))}
                              </div>
                            );
                          },
                          options: currencies.valuesForSelect,
                        }}
                        Component={MultiSelect}
                        name='selectedCurrencies'
                      />
                    </div>
                  </div>
                </div>
              </Show>
            </Form>
            <Show if={selectedOrganizedRole() === 'organizer'}>
              <PaymentDetails
                handleSubmit={handleSubmit}
                currentCurrency={selectedCurrencies()[currentTab]?.label?.toString() ?? ''}
                currentTab={currentTab}
                handleChangeTab={handleChangeTab}
                isCurrencySelected={selectedCurrencies()?.length > 0 ?? false}
                selectedCurrencies={selectedCurrencies().map((c) => c.label) as availableCurrencies[]}
                errors={listErrorTabs}
              />
            </Show>
          </div>
        );
      }}
    />
  );
}

const FormPage = withProgressSpinner(SystemUserFormPageConstants.systemUsersFormId)(SystemUserForm);

export default FormPage;

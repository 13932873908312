import { styled } from '@mui/styles';
import { isString } from 'lodash';
import * as React from 'react';

const Styled = styled('pre')({
  outline: '1px solid #ccc',
  overflowX: 'auto',
  borderRadius: '4px',
  padding: '5px',
  fontSize: '14px',
  fontFamily: 'monospace',

  '& .string': {
    color: 'green',
  },

  '& .number': {
    color: 'darkorange',
  },

  '& .boolean': {
    color: 'blue',
  },

  '& .null': {
    color: 'magenta',
  },

  '& .key': {
    color: 'red',
  },
});

type Props = {
  value: string | AnyObject;
  colored?: boolean;
  className?: string;
};

export const DisplayJSON: React.FC<Props> = (props) => {
  const { value, colored = true, className } = props;

  let json = value;

  if (!isString(json)) {
    json = JSON.stringify(json, undefined, 2);
  }

  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

  if (colored) {
    json = json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      (match) => {
        let cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return `<span class="${cls}">${match}</span>`;
      },
    );
  }

  return (
    <Styled className={className}>
      <div dangerouslySetInnerHTML={{ __html: json }} />
    </Styled>
  );
};

import { SynchronizationsStore } from 'modules/Synchronisation/Table/stores/synchronizations.store';
import { SynchronizationResponse } from 'modules/Synchronisation/Table/types/synchronization-response.type';

import { SynchronizationsService } from '../../services/synchronizations.service';

import { SynchronizationFilters } from '../../types/filters.type';

export namespace SynchronizationActions {
  export async function init() {
    SynchronizationActions.load({ orderBy: 'created_at', sortedBy: 'DESC', page: 1, limit: 30 });
  }

  export async function load(filters: SynchronizationFilters = { page: 1, limit: 30 }) {
    const data = await SynchronizationsService.load<SynchronizationResponse>(filters);
    SynchronizationsStore.synchronizations.set(data);
  }

  export async function unmount() {
    SynchronizationsStore.synchronizations.value = null;
  }
}

const DEFAULT_ROOT = 'errors';

export class ApiError implements IError {
  response: AxiosResponse;
  action: string;
  root: string;

  constructor(
    action: string,
    response: AxiosResponse = {
      data: {},
      status: 0,
      headers: {},
      config: {},
    },
    root: string = DEFAULT_ROOT,
  ) {
    this.response = response;
    this.action = action;
    this.root = root;
  }

  errors() {
    const errors = (this.response.data || {})[this.root];
    return errors || {};
  }

  formatErrors(): {
    [K in string]: Array<string>;
  } {
    const errors = this.errors();
    let formatedErrors: { [K in string]: any } = {};
    const fields = Object.keys(errors);
    fields.forEach((key) => {
      if (Array.isArray(errors[key])) formatedErrors[key] = errors[key];
    });
    return formatedErrors;
  }

  flatErrors(): Array<string> {
    const errors = this.formatErrors();
    let formatErrors: Array<string> = [];
    const fields = Object.keys(errors);
    fields.forEach((key) => {
      formatErrors = [...formatErrors, ...errors[key]];
    });
    return formatErrors;
  }

  firstError(): string {
    const errors = this.flatErrors();
    return errors[0] || '';
  }
}

export class ValidationError implements IError {
  action: string;
  errorsData: {
    [K in string]: Array<string>;
  };
  object:
    | {
        [K in any]: any;
      }
    | nil;

  constructor(
    action: string,
    errors: {
      [K in string]: Array<string>;
    },
    object?: {
      [K in any]: any;
    },
  ) {
    this.action = action;
    this.errorsData = errors;
    this.object = object;
  }

  errors() {
    return this.errorsData;
  }

  flatErrors() {
    const errors = this.formatErrors();
    let formatErrors: Array<string> = [];
    const fields = Object.keys(errors);
    fields.forEach((key) => {
      formatErrors = [...formatErrors, ...errors[key]];
    });
    return formatErrors;
  }

  firstError(): string {
    const errors = this.flatErrors();
    return errors[0] || '';
  }

  formatErrors() {
    return this.errors();
  }
}

export namespace CommissionConstants {
  export const Table = 'Table';

  export const Mappers = {
    grand_total: 'payment_info:id|amount',
  };

  export enum COLUMN_WIDTH {
    email = '250px',
  }
}

import { createNumberMask } from 'text-mask-addons';

import { generateMaskedInput } from '../mask';
import { placeholderChar } from './constants';

type Configs = {
  limit: nil | number;
};

const generateMask = (configs: Configs) =>
  createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol: ' ',
    integerLimit: configs.limit,
  });

const generateNumberMask = (configs: Configs) =>
  generateMaskedInput({
    mask: generateMask(configs),
    placeholderChar,
    guide: false,
    keepCharPositions: false,
  });

export * from './tools';
export { generateNumberMask };

import * as React from 'react';

import { AspectRatio } from '../AspectRatio';

type Props = {
  ratio: number;
  src: nil | string;
  onClick?: (e: React.SyntheticEvent) => void;
  children?: React.ReactNode;
};

export const AspectRatioImage = ({ ratio, src, children }: Props) => {
  if (!ratio) {
    return null;
  }

  return (
    <AspectRatio ratio={ratio}>
      <div className='inner-wrapper image' style={{ backgroundImage: `url(${src || ''})` }} />
      {children || null}
    </AspectRatio>
  );
};

import { DummyStore } from 'stores';

import { TableRow, CommissionResponse, TCommissionError, DefaultCommission } from '../types';

export namespace SystemUserCommissionsStore {
  export const commissions = new DummyStore<CommissionResponse>();
  export const defaultCommissions = new DummyStore<DefaultCommission[]>();
  export const editCommission = new DummyStore<TableRow | null>();
  export const currencies = new DummyStore<number[]>();
  export const error = new DummyStore<TCommissionError>({ amount: false, percent_value: false, is_absorb: false });
}

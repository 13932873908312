import { Tooltip } from '@mui/material';
import get from 'lodash/get';
import set from 'lodash/set';
import { toJS } from 'mobx';
import React, { ReactElement } from 'react';

import { superTranslationsModelTypeMap, translationsModelIdMap } from 'src/constants';

import { Show } from 'components/Condition';
import { ModeChangeButton } from 'components/Stepper/Step/ModeChange';

import { t } from 'utils';

import { contentTranslate } from 'actions';

import { fieldTranslations } from 'services';

import { assembleTranslations, formatTranslations, getModelData, makeLookUpHash } from '../utils';

import { AbstractTranslation } from '../AbstractTranslation';
import { GeneralTranslations } from '../GeneralTranslations';
import { TranslationExpansion } from './components/TranslationExpansion';
import { useWrapperStyles } from './styles';

interface TranslationStageProps {
  data: DataWithTranslations | nil;
  defaultLocale?: availableLocales;
  locales?: availableLocales[];
  reloadData: Function;
}

export default function TranslationStages({ data, defaultLocale = 'en', locales, reloadData }: TranslationStageProps): ReactElement {
  const classes = useWrapperStyles();

  if (!data || !locales?.length) {
    return <></>;
  }

  const lookUpHash = makeLookUpHash(data, locales);

  const {
    raceData = [],
    distanceData = [],
    customFields = [],
    disciplines = [],
    distanceClasses = [],
    checkpoints = [],
    waves = [],
    emailContent = [],
  } = assembleTranslations(lookUpHash, locales, defaultLocale);

  const handleClick = async (type: TranslationFieldType, path: [...TranslationPath, ...any]) => {
    const [field, index] = path;
    const pathArg: TranslationPath = [field, index];

    const getPath = ['distanceData', 'raceData', 'emailContent'].includes(type) ? [type] : [...pathArg];

    const translations = [defaultLocale, ...locales].reduce((acc, locale) => {
      const value = get(lookUpHash, [locale, ...getPath]);
      set(acc, [locale, type], value);

      return acc;
    }, {});

    const key = path.filter(isNaN).join('.');
    const payload = [defaultLocale, ...locales].map((locale) => ({
      locale,
      model: get(superTranslationsModelTypeMap, key),
      model_id: get(lookUpHash, [defaultLocale, ...getPath])[get(translationsModelIdMap, key)],
    }));
    await fieldTranslations.superTranslationsConfirm(payload);
    handleNavigate(pathArg, translations);
  };

  const onUpdate = async (data: { name: string; value: string }[]) => {
    const modelData = data.map(({ name, value }) => ({
      ...getModelData(name, translateData.path, lookUpHash[defaultLocale]),
      value,
    }));
    await contentTranslate(modelData);
  };

  const [translateData, setData] = React.useState<{
    path: TranslationPath;
    translations: FilteredTranslations;
  }>({
    path: [],
    translations: {},
  });

  const goBack = () => {
    setData({ ...translateData, path: [] });
    reloadData();
  };

  const handleNavigate = (path: TranslationPath, translations: FilteredTranslations) => {
    setData({ path, translations });
  };

  const [mode, setMode] = React.useState('stages');

  const switchMode = () => {
    setMode(mode === 'stages' ? 'general' : 'stages');
    reloadData();
  };

  const onHtmlEditNavigate = (name) => () => {
    const [type, fieldName] = name.split('.');
    handleClick(type, [type, fieldName]);
  };

  return (
    <>
      <Show if={translateData.path.length === 0}>
        <div className={classes.header}>
          <h1 className={classes.title}>
            {mode === 'stages' ? t('translations.translationsByStages') : t('translations.generalTranslations')}
          </h1>

          <Tooltip
            title={
              mode === 'stages'
                ? t.staticAsString('translations.switchToGeneralTranslations')
                : t.staticAsString('translations.switchToTranslationsByStages')
            }
          >
            <div>
              <ModeChangeButton onModeChange={switchMode} />
            </div>
          </Tooltip>
        </div>
        <Show if={mode === 'general'}>
          <GeneralTranslations
            translations={formatTranslations(lookUpHash, locales, defaultLocale)}
            defaultLocale={defaultLocale}
            locales={locales}
            onUpdate={onUpdate}
            onHtmlEditNavigate={onHtmlEditNavigate}
          />
        </Show>
        <Show if={mode === 'stages'}>
          <TranslationExpansion onClick={handleClick} title={t.staticAsString('translations.title.raceData')} data={raceData} />
          <TranslationExpansion onClick={handleClick} title={t.staticAsString('translations.title.distanceData')} data={distanceData} />
          <TranslationExpansion onClick={handleClick} title={t.staticAsString('translations.title.emailContent')} data={emailContent} />
          <TranslationExpansion onClick={handleClick} title={t.staticAsString('translations.title.customFields')} data={customFields} />
          <TranslationExpansion onClick={handleClick} title={t.staticAsString('translations.title.disciplines')} data={disciplines} />
          <TranslationExpansion
            onClick={handleClick}
            title={t.staticAsString('translations.title.distanceClasses')}
            data={distanceClasses}
          />
          <TranslationExpansion onClick={handleClick} title={t.staticAsString('translations.title.waves')} data={waves} />
          <TranslationExpansion onClick={handleClick} title={t.staticAsString('translations.title.checkpoints')} data={checkpoints} />
        </Show>
      </Show>
      <Show if={translateData.path.length > 0}>
        <AbstractTranslation
          path={translateData.path}
          translations={translateData.translations}
          locales={locales}
          defaultLocale={defaultLocale}
          onUpdate={onUpdate}
          goBack={goBack}
        />
      </Show>
    </>
  );
}

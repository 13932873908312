import { Observer } from 'mobx-react';
import * as React from 'react';

import { stepStore } from '../stores';

type Props = React.PropsWithChildren<{
  step: string;
}>;

export const Step: React.FC<Props> = (props) => {
  return (
    <Observer>
      {() => {
        if (stepStore.value !== props.step) return null;

        return <>{props.children}</>;
      }}
    </Observer>
  );
};

import { computed, makeObservable, override } from 'mobx';

import { SportType as SportTypeModel } from 'models';

import { Loadable } from './loadable';

class SportType extends Loadable<SportTypeType, FiltersType> {
  declare filters: FiltersType;

  constructor() {
    super();

    makeObservable(this);
    this.filters = { limit: 25 };
  }

  getElementById(index: number): SportTypeType | null {
    const value = this.values.find((e) => e.id === index);
    return value ? value : null;
  }

  addIndexValues(values: Array<SportTypeType>) {
    this.values = values.sort((e, e1) => e.index - e1.index);
  }

  @computed
  get modelValues(): Array<SportTypeModel> {
    return this.values.map((sportType) => {
      return new SportTypeModel(sportType);
    });
  }

  @computed
  get modelSelected(): nil | SportTypeModel {
    const value = this.selected;
    if (!value) {
      return null;
    }

    return new SportTypeModel(value);
  }

  @override
  clearAll() {
    super.clearAll();
    this.paginationMeta = {};
  }
}

export { SportType };
export default new SportType();

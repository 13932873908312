import { isEmpty } from 'lodash';

import { sessionStore } from 'stores';

import { loadService } from '../services/load';

export const mount = () => {
  const user = sessionStore.session.user;

  if (isEmpty(user)) {
    return;
  }

  loadService.loadResource(user.user_id);
};
export const unmount = () => {};

import * as React from 'react';

import { form, Form } from 'stores';

type Props = {
  children: React.ReactNode;
  id: string;
  structure?: Object;
  withScroll?: boolean;
  onSubmit?: Function;
  disableAutofill?: boolean;
  className?: string;
  cleanErrorOnChange?: boolean;
  enableManualClean?: boolean;
  renderAsDiv?: boolean;
};

type ContextValue = {
  form?: {
    store: Form;
    id: string;
    onChange: (changes: { name: string[] | string; value: any }) => void;
    fetch: <ExtectedValue>(name: string) => ExtectedValue;
    registerField: (name: string | string[]) => void;
    withScroll: boolean;
    disableAutofill: boolean;
    cleanErrorOnChange: boolean;
  };
};

const FormContext = React.createContext<ContextValue>({});

const FormWrapper: React.FC<Props> = (props) => {
  const {
    id,
    cleanErrorOnChange,
    children,
    onSubmit,
    className,
    disableAutofill = false,
    withScroll = false,
    enableManualClean = false,
    renderAsDiv = false,
  } = props;

  React.useEffect(() => {
    return () => {
      if (!enableManualClean) {
        form.clean(id);
      }
    };
  }, []);

  const getContextProps = () => {
    return {
      form: {
        store: form,
        id,
        fetch: fetch,
        onChange: onChange,
        registerField: registerField,
        withScroll,
        disableAutofill: Boolean(disableAutofill),
        cleanErrorOnChange: cleanErrorOnChange || false,
      },
    };
  };

  const onChange = ({ name, value }: { name: string | string[]; value: any }) => {
    form.onChange(id, name, value);
  };

  const registerField = (name: string | string[]) => {
    form.registerField(id, name);
  };

  const fetch = <ExpectedValue extends any>(name: string): ExpectedValue => {
    return form.fetch(id, name);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (onSubmit) {
      onSubmit();
    }
  };

  const generateAutoComplete = (): Object => {
    if (!disableAutofill) {
      return {};
    }

    return { autoComplete: 'off' };
  };

  const autoCompleteOptions = generateAutoComplete();

  const contextProps = getContextProps();

  const content = renderAsDiv ? (
    <div {...autoCompleteOptions} className={className}>
      {children}
    </div>
  ) : (
    <form onSubmit={handleSubmit} {...autoCompleteOptions} className={className}>
      {children}
    </form>
  );

  return <FormContext.Provider value={contextProps}>{content}</FormContext.Provider>;
};

export { ContextValue };
export { FormWrapper, FormWrapper as Form, FormContext };

import axios from 'axios';
import { cloneDeep, isString, values } from 'lodash';
import { toJS } from 'mobx';
import { generatePath } from 'react-router-dom';

import { RACER, DISTANCES_URL, RACERS_URL, RACERS_REMOVE_CF_URL } from 'src/constants';

import { t, request, action } from 'utils';

import { toastStore } from 'stores';

import { membersTeamService } from '../services';

import { startlistMembersStore } from '../stores';
import { Members as MembersStore } from '../stores';

import { processNewMember } from './dataProcessor';

class LoadMemberService {
  store: MembersStore;

  constructor(store: MembersStore) {
    this.store = store;
  }

  @request({ action: `LOAD_${RACER}S` })
  async loadResourcesRequest(id: number, params: FiltersType): Promise<any> {
    return axios.get(`${DISTANCES_URL}/${id}/racers`, {
      params,
    });
  }

  @action({ action: `LOAD_${RACER}S`, minRequestTime: 800 })
  async loadResources(distanceId: number, newParams: FiltersType = {}, pageNum: number = 1): Promise<any> {
    return await this.loadResourcesWithoutPreloader(distanceId, newParams, pageNum);
  }

  @action({ action: `LOAD_${RACER}S_WITHOUT_PRELOADER`, minRequestTime: 800 })
  async loadResourcesWithoutPreloader(distanceId: number, newParams: FiltersType = {}, pageNum: number = 1): Promise<any> {
    let { params, page, filters } = this.store || {};
    page = page !== pageNum && pageNum ? pageNum : page;

    const queryParams = {
      ...params,
      ...newParams,
      page,
    };

    const [status, response] = await this.loadResourcesRequest(distanceId, queryParams);

    if (status) {
      const values = response.data.data;
      this.store.updateCurrentDistance(distanceId);
      this.store.addValues(values, page, { ...filters, ...newParams });

      const paginationMeta = response.data.meta && response.data.meta.pagination;
      if (paginationMeta) {
        this.store.addPaginationMeta(paginationMeta);
      }
    }

    return response;
  }

  @request({ action: `CREATE_${RACER}` })
  async createResourceRequest(distance_id: number, racer: RacerType): Promise<any> {
    return axios.post(`${DISTANCES_URL}/${distance_id}/racers`, racer);
  }

  @action({ action: `CREATE_${RACER}` })
  async createResource(racer: RacerType, distance_id: number, closeModal: Function): Promise<any> {
    const [status] = await this.createResourceRequest(distance_id, processNewMember(racer) as any);

    if (status) {
      this.loadResources(distance_id);

      if (racer.team_id) {
        // Update teams
        await membersTeamService.updateFewTeamsForMembers(racer.team_id);
      }

      closeModal();
      toastStore.show(t.staticAsString('racers.createSuccess'));
    }
  }

  @request({ action: `UPDATE_${RACER}` })
  async updateResourceRequest(params: RacerType, racer_id: number, distance_id: number): Promise<any> {
    return axios.patch(`${RACERS_URL}/${racer_id}`, params);
  }

  @action({ action: `UPDATE_${RACER}` })
  async updateResource(racer: RacerType, item: RacerType): Promise<any> {
    const copy = cloneDeep(racer);
    if (isString(copy.gender)) {
      copy.gender = +copy.gender as genderValue;
    }

    const [status, response] = await this.updateResourceRequest(copy, item.id, Number(item.distance_id));

    if (status) {
      await this.checkTeamForUpdate(item.id, item.team_id);
      this.store.updateValue(item);
      toastStore.show(t.staticAsString('racers.updateSuccess'));
    } else {
      toastStore.show(values(response.data.errors).join(' '));
    }

    return status;
  }

  @request({ action: `UPDATE_${RACER}` })
  async updateCustomFieldRequest(params: RacerCustomFieldValue, racer_id: number): Promise<any> {
    return axios.patch(`${RACERS_URL}/${racer_id}/custom-field/${params.id}`, params);
  }

  @action({ action: `UPDATE_${RACER}` })
  async updateCustomField(value: RacerCustomFieldValue, racer: RacerType): Promise<any> {
    const [status, response] = await this.updateCustomFieldRequest(value, racer.id);

    if (status) {
      this.store.updateValue(racer);
      toastStore.show(t.staticAsString('racers.updateSuccess'));
    } else {
      toastStore.show(values(response.data.message).join(' '));
    }

    return status;
  }

  @request({ action: `DELETE_${RACER}` })
  deleteCustomFieldRequest(racerId: number, cfid: number): any {
    return axios.delete(generatePath(RACERS_REMOVE_CF_URL, { id: racerId, cfid }));
  }

  @action({ action: `DELETE_${RACER}` })
  async deleteCustomField(value: RacerCustomFieldValue, racer: RacerType) {
    const [status, response] = await this.deleteCustomFieldRequest(racer.id, value.id);

    if (status) {
      racer.fields = racer.fields!.filter((field) => field.field_id !== value.id);
      this.store.updateValue(racer);
      toastStore.show(t.staticAsString('racers.updateSuccess'));
    } else {
      toastStore.show('Error removing custom field');
    }
  }

  @request({ action: `DELETE_${RACER}` })
  deleteValueRequest(distance_id: number, id: number): any {
    return axios.delete(`${RACERS_URL}/${id}`);
  }

  @action({ action: `DELETE_${RACER}` })
  async deleteValue(value: RacerType) {
    if (!value) {
      return;
    }

    const distanceId = value.distance_id;
    const id = value.id;
    const teamId = value.team_id;

    const [status] = await this.deleteValueRequest(Number(distanceId), id);

    await membersTeamService.updateFewTeamsForMembers(teamId);

    if (status) {
      this.loadResources(Number(distanceId));
      toastStore.show(t.staticAsString('racers.deleteSuccess'));
    }
  }

  @request({ action: `EXPORT_${RACER}S` })
  async exportResourceRequest(distance_id: number, type: string): Promise<any> {
    const params = { type: type };
    return axios.get(`${DISTANCES_URL}/${distance_id}/export/startlist`, { params });
  }

  @action({ action: `EXPORT_${RACER}S` })
  async exportResource(distance_id: number, type: string): Promise<any> {
    const [status, response] = await this.exportResourceRequest(distance_id, type);

    if (status) {
      window.open(response.data.url);
    }
  }

  async load(): Promise<void> {
    const distanceId = this.store.distanceId;
    await this.loadResourcesWithoutPreloader(distanceId);
  }

  async checkTeamForUpdate(id: number, newTeamId: nil | number): Promise<void> {
    if (!newTeamId || !id) {
      return;
    }

    const racer = this.store.findValue(+id);

    if (!racer) {
      return;
    }

    if (racer.team_id === newTeamId) {
      return;
    }

    await membersTeamService.updateFewTeamsForMembers(Number(racer.team_id), newTeamId);
  }
}

const membersService = new LoadMemberService(startlistMembersStore);

export { LoadMemberService, membersService };
export default membersService;

export const mapOrganizerToContacts = (organizer: OrganizerType) => {
  const organizerEmail = [organizer.email];
  return {
    website: organizer?.website,
    instagram: organizer?.instagram,
    linkedin: organizer?.linkedin,
    facebook: organizer?.facebook,
    description: organizer?.description,
    name: organizer?.full_name,
    emails: organizerEmail,
  };
};

export const mapOrganizerFromContacts = (contacts: ContactsType) => {
  return {
    organizer_name: contacts?.name,
    organizer_website: contacts?.website,
    organizer_description: contacts?.description,
    organizer_instagram: contacts?.instagram,
    organizer_facebook: contacts?.facebook,
    organizer_linkedin: contacts?.linkedin,
    organizer_emails: contacts?.emails,
  };
};

import { loadOrganizers } from 'modules/SystemUsers/actions';
import { loadSystemUserRoles } from 'modules/SystemUsers/actions/loadRoles.action';
import { SystemUserCommissionsService } from 'modules/SystemUsers/services/systemUserCommissions.service';
import { SystemUserCommissionsStore } from 'modules/SystemUsers/stores/systemUserCommissions.store';
import { SystemUserForm } from 'modules/SystemUsers/types';

import { loadCountryService, loadCurrencyService } from 'src/services';
import { currenciesStore, form } from 'src/stores';

import { createSystemUserAction } from './createSystemUser.action';
import { loadSystemUser } from './loadSystemUser.action';
import { unmountSystemUserForm } from './unmountForm.action';
import { updateSystemUserAction } from './updateSystemUser.action';

export namespace SystemUserFormPageActions {
  export async function initSystemUsersForm(systemUserId: number) {
    await Promise.all([loadCurrencyService.loadResources(), loadOrganizers(), loadCountryService.loadResources(), loadSystemUserRoles()]);

    currenciesStore.valuesForSelect.forEach((currency) => form.merge(currency.label as string, {}));

    const defaultCommissions = await SystemUserCommissionsService.loadDefaultCommissions();
    SystemUserCommissionsStore.defaultCommissions.set(defaultCommissions);

    if (systemUserId) {
      await loadSystemUser(systemUserId);
    }
  }

  export async function createUser(systemUser: SystemUserForm) {
    await createSystemUserAction(systemUser);
  }
  export async function updateUser(id: number, systemUser: SystemUserForm) {
    await updateSystemUserAction(id, systemUser);
  }
  export function unmount() {
    unmountSystemUserForm();
  }
}

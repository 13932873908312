import isEmpty from 'lodash/isEmpty';
import { action, observable, computed, makeObservable } from 'mobx';
import * as React from 'react';
import shortid from 'shortid';

class Toast {
  @observable
  toast: ToastType | object = {};

  constructor() {
    makeObservable(this);
  }

  @action
  show(content: React.ReactNode, action?: React.ReactNode | unknown, duration?: number, variant?: string) {
    let toastContent = content;
    if (typeof content === 'string') {
      toastContent = <p className='toast-content'>{content}</p>;
    }

    this.toast = { id: shortid(), content: toastContent, action, duration, variant };
  }

  @action
  hide() {
    this.toast = {};
  }

  @computed
  get isOpen(): boolean {
    return !isEmpty(this.toast);
  }
}

export { Toast };
export default new Toast();

import { compact } from 'lodash';
import moment from 'moment';

import { SportType as SportTypeModel, Distance as DistanceModel } from 'models';

import { localeStore } from 'stores';

class Race {
  value: RaceType;
  // @ts-ignore
  sportType: SportTypeModel | null;
  distances: Array<DistanceModel>;
  // @ts-ignore
  totalPrice: number;

  constructor(value: RaceType) {
    this.value = value;
    if (value.sport) {
      this.sportType = new SportTypeModel(value.sport);
    }
    this.distances = [];
    if (!!value.distances) {
      this.distances = value.distances.map((distance) => new DistanceModel(distance));
    }
  }

  name() {
    return this.value.name || '';
  }

  email = (): nil | string => {
    const { organizer } = this.value;
    return organizer?.email;
  };

  sportTypeTitle = (): string => {
    if (this.sportType) {
      return this.sportType.title() || '';
    }
    return '';
  };

  organizerTitle() {
    const { organizer } = this.value;
    if (!organizer) {
      return '';
    }
    return organizer.email;
  }

  momentRaceDate(): nil | moment.Moment {
    const { race_date } = this.value;
    const momentDate = moment(race_date);
    if (momentDate.isValid()) {
      return momentDate;
    }
  }

  momentRaceEndDate(): nil | moment.Moment {
    const { race_end_date } = this.value;
    const momentDate = moment(race_end_date);
    if (momentDate.isValid()) {
      return momentDate;
    }
  }

  isOneDayRace(): boolean {
    const start = this.momentRaceDate();
    const end = this.momentRaceEndDate();

    if (!start || !end) {
      return false;
    }

    return start.isSame(end, 'day');
  }

  city(): string | nil {
    const { location } = this.value;

    if (!location) {
      return '';
    }

    return location.city;
  }

  approveStatus(): number | nil {
    const { approve_status } = this.value;

    if (!approve_status) {
      return null;
    }

    return approve_status;
  }

  rejectReason(): string {
    const { rejectReason } = this.value;

    if (!rejectReason) {
      return '';
    }

    return rejectReason.reason;
  }

  location(): string {
    const { location } = this.value;
    if (!location) {
      return '';
    }

    const { street_number, street_address, city, country, zip_code } = location;

    return compact([street_address, street_number, city, zip_code, country]).join(', ');
  }

  route(): string | nil {
    const { route } = this.value;
    if (!route?.lat || !route.lng) {
      return null;
    }

    return `${route.lat}, ${route.lng}`;
  }

  fullLocation() {
    const location = this.location();
    const route = this.route();

    if (!route) {
      return location;
    }

    if (!location) {
      return route;
    }

    return `${location} (${route})`;
  }

  isAnyLocation(): boolean {
    const { location, route } = this.value;

    if (location) {
      return Boolean(location.country || location.city || location.zip_code || location.street_address || location.street_number);
    }
    if (route) {
      return Boolean(route.lat && route.lng);
    }

    return false;
  }

  currency(): string {
    const { currency } = this.value;
    if (!currency) {
      return '';
    }

    return currency.iso_code;
  }

  contactEmails(): string {
    const { contact_emails } = this.value;

    if (!contact_emails) {
      return '';
    }

    const emails = contact_emails.map((value) => value.email);

    return emails.join('<br>');
  }

  isAnyDistancesPresented(): boolean {
    const { distances } = this;

    if (!distances.length) {
      return false;
    }

    return distances.some((value) => value.isVisible());
  }

  findDistance(id: number): nil | DistanceModel {
    return this.distances.find((value) => value.value.id === id);
  }
}

export { Race };

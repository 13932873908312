import { computed } from 'mobx';

import { form } from 'src/stores';

import { RACE_RESULTS_FORM_ID } from '../constants';

import { raceCustomFieldsStore, syncCustomFieldsStore } from '../stores';

import { maxDistances } from '../../Shared';

const maxCustomFields = (internalDistanceId) => {
  return Math.min(
    Number(raceCustomFieldsStore.value?.filter((item) => item.parentId === internalDistanceId).length),
    Number(syncCustomFieldsStore.value?.length),
  );
};

const hasDistances = computed(() => {
  const distances = form.fetch<AnyObject[]>(RACE_RESULTS_FORM_ID, 'distances') || [];

  return distances.some((distance) => distance.external_id && distance.internal_id);
});

export const showDistancePlus = computed(() => {
  const max = maxDistances.get();
  const distances = form.fetch<AnyObject[]>(RACE_RESULTS_FORM_ID, 'distances') || [];

  return distances.length < max;
});

export const showCustomFieldsPlus = (entity, internalDistanceId) => {
  const max = maxCustomFields(internalDistanceId);
  const fields = form.fetch<AnyObject[]>(RACE_RESULTS_FORM_ID, entity) || [];

  return fields.length < max && hasDistances.get();
};

export const showCustomFields = computed(() => {
  const selectedDistances = form.fetch<AnyObject[]>(RACE_RESULTS_FORM_ID, 'distances') || [];
  const isCFAvailable = Boolean(
    raceCustomFieldsStore.value?.filter((cf) =>
      selectedDistances.some((distance) => distance.external_id?.key && distance.internal_id?.key === cf.parentId),
    )?.length,
  );
  return isCFAvailable && hasDistances.get();
});

import cloneDeep from 'lodash/cloneDeep';

export class CouponMapper implements MapperMiddleware<Object, Object> {
  mapTo(value: AnyObject): Object {
    const copy = cloneDeep(value);
    copy.race_id = copy.race_id.key;

    if (!copy.distances.length) {
      delete copy.distances;
    }
    return copy;
  }
}

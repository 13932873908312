import { formatter } from 'utils';

const filterFormatArray = (value: Array<FilterType>): string => {
  const separator = value.length <= 2 ? ' - ' : ', ';
  return value.reduce((acc, val) => {
    const formattedValue = filterFormatValue(val);
    if (!acc) {
      return formattedValue;
    } else {
      return `${acc}${separator}${formattedValue}`;
    }
  }, '') as string;
};

const filterFormatValue = (value: FilterType): string => {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number') {
    return formatter.integer(value, true);
  } else if (value instanceof Array) {
    return filterFormatArray(value);
  } else {
    return (value as any) || '';
  }
};

export { filterFormatValue, filterFormatArray };

import { cloneDeep } from 'lodash';

import { time } from 'utils';

export const mapToConfirmBody = (data: AnyObject): AnyObject => {
  const form = cloneDeep(data);

  const startAt = form.dateRange && form.dateRange.startDate ? getDate(form.dateRange.startDate) : '';
  const endAt = form.dateRange && form.dateRange.endDate ? getDate(form.dateRange.endDate) : '';

  return {
    sourceDistance: form.sourceDistance?.value,
    targetDistance: form.targetDistance?.value,
    startAt,
    endAt,
  };
};

const getDate = (date: any): string => {
  return time.workDatetime(date).formatAsString('shortDate');
};

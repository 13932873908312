export const mapToEditRegistration = (data: AnyObject): AnyObject => {
  if (!data) {
    return {
      from: getDefaultDate(),
      to: getDefaultDate(),
    };
  }

  return {
    from: data.from === '' ? getDefaultDate() : data.from,
    to: data.to === '' ? getDefaultDate() : data.to,
  };
};

const getDefaultDate = () => null;

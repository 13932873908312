import * as React from 'react';

import { CopyRacers as CopyRacersContainer } from './containers';
import { CopyRacersContext, copyRacersContextData } from './context';

export const SupportTaskManagerCopyRacers = () => (
  <CopyRacersContext.Provider value={copyRacersContextData}>
    <CopyRacersContainer />
  </CopyRacersContext.Provider>
);

// TODO, DEPRECATED, remove!!!!!
import { TableCell } from '@mui/material';
import classNames from 'classnames';
import React from 'react';

import { t, formatter, time } from 'utils';

type Props = {
  label: string;
  value: FilterType;
  name: string;
  onFilterShow: (e: React.SyntheticEvent, filterName: nil | string) => void;
  className: string;
  values: FiltersType;
  disabled?: boolean;
};

const formatArray = (value: Array<FilterType>): string => {
  const separator = value.length <= 2 ? ' - ' : ', ';
  return value.reduce<string>((acc, val) => {
    const formattedValue = formatValue(val);
    if (!acc) {
      return formattedValue;
    } else {
      return `${acc}${separator}${formattedValue}`;
    }
  }, '');
};

const formatValue = (value: FilterType): string => {
  if (typeof value === 'string') {
    return value;
  } else if (typeof value === 'number') {
    return formatter.integer(value);
  } else if (value instanceof Date) {
    return time.date(value) as any;
  } else if (value instanceof Array) {
    return formatArray(value);
  } else if (typeof value === 'boolean') {
    return '';
  } else {
    return value || '';
  }
};

const ThTd = ({ label, name, onFilterShow, className, disabled = false }: Props) => {
  let onClick: AnyFunction = () => {};

  if (!disabled) {
    onClick = (e: React.SyntheticEvent) => onFilterShow(e, name);
  }

  return (
    <TableCell className={classNames('table-header', className, { filterable: !disabled }, { disabled: disabled })} onClick={onClick}>
      {label}
    </TableCell>
  );
};

export { formatArray, formatValue };

export { ThTd };

import { ExpandMore, Close, DragHandle } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography, IconButton, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import { cloneDeep } from 'lodash';
import { getAssistantLabel, populateCheckpointAssistants, Checkpoint as CheckpointModel } from 'modules/Distances/utils';
import * as React from 'react';

import { CONFIRM_POPUP_TYPES } from 'src/constants';

import { withConfirmModal } from 'hocs';

import { DNDContainer, DNDItem } from 'components/DragNDrop';
import { Icon } from 'components/Icon';

import { reorderUtil } from 'utils/reorder';

import { itemsStyles, itemMainPanelStyles, itemExpansionPanelSummaryStyles } from './styles';

type Props = {
  onChange: (values: Array<CheckpointType>, callback?: Function) => void;
  onRemove(id: number | string): void;
  onRemoveAssistant(id: number | string, assistantId?: number | string): void;
  onEdit(id: number | string): void;
  checkpoints: Array<CheckpointType>;
} & WithConfirmModalProps<'deleteCheckpointPopup'> &
  WithConfirmModalProps<'deleteAssistantPopup'> &
  WithConfirmModalProps<'disabledDeleteCheckpointPopup'>;

let Items: any = (props: Props) => {
  const classes = itemsStyles();
  const mainPanelClasses = itemMainPanelStyles();
  const summaryClasses = itemExpansionPanelSummaryStyles();
  const summaryClassesWithoutPadding = itemExpansionPanelSummaryStyles({
    noAssistants: true,
  });
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onCheckpointClick = (event: React.SyntheticEvent, id?: number | string) => {
    event.stopPropagation();

    props.onEdit(id || 0);
  };

  const onRemoveCheckpoint = (event: React.SyntheticEvent, id: number | string, name: string, checkpointLength: number) => {
    event.stopPropagation();
    if (checkpointLength <= 2) return props.disabledDeleteCheckpointPopup.open();
    props.deleteCheckpointPopup.open(
      () => {
        props.onRemove(id || 0);
      },
      {},
      { name },
    );
  };
  const onRemoveAssistant = (event: React.SyntheticEvent, id: number | string, assistantId: number | string, name: string) => {
    event.stopPropagation();

    props.deleteAssistantPopup.open(
      () => {
        props.onRemoveAssistant(id || 0, assistantId);
      },
      {},
      { name },
    );
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result?.destination) {
      return;
    }

    const items = reorderUtil.changePosition([...(props.checkpoints || [])], result.source.index, result.destination.index);
    props.onChange(items);
  };

  const onMouseDown = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={classes.root}>
      <DNDContainer droppableId={'checkpoints'} onDragEnd={onDragEnd}>
        {props.checkpoints.map((checkpoint, index) => {
          const populatedCheckpoint = populateCheckpointAssistants(checkpoint);
          const label = new CheckpointModel(populatedCheckpoint).name;
          const assistants = populatedCheckpoint?.assistants || [];
          return (
            <DNDItem key={index} id={`${checkpoint.id || checkpoint.__id || ''}`} index={index} onMouseDown={onMouseDown}>
              <Accordion
                key={label}
                expanded={assistants.length ? expanded === label : false}
                classes={mainPanelClasses}
                onChange={handleChange(label)}
              >
                <AccordionSummary
                  classes={assistants.length ? summaryClasses : summaryClassesWithoutPadding}
                  expandIcon={assistants.length ? <ExpandMore className={classes.expand} /> : null}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Typography
                    sx={{ display: 'flex', alignItems: 'center' }}
                    className={`${classes.heading} ${classes.truncated}`}
                    onClick={(e) => onCheckpointClick(e, populatedCheckpoint.id || populatedCheckpoint.__id)}
                  >
                    <Icon value='drag-icon' style={{ marginRight: 12 }} />
                    {label}
                  </Typography>
                  <Divider orientation='vertical' flexItem className={classes.divider} />
                  <IconButton
                    aria-label='delete'
                    className={classes.delete}
                    onClick={(e) =>
                      onRemoveCheckpoint(e, (populatedCheckpoint.id || populatedCheckpoint.__id) as any, label, props.checkpoints.length)
                    }
                    size='large'
                  >
                    <Icon value='delete-outline' />
                  </IconButton>
                </AccordionSummary>

                <AccordionDetails className={classes.details}>
                  {assistants.map((assistant, i) => {
                    return (
                      <Box className={classes.detailsBox} key={getAssistantLabel(assistant) + i}>
                        <Typography className={classes.secondaryHeading}>{getAssistantLabel(assistant)}</Typography>
                        <IconButton
                          aria-label='delete assistant'
                          className={classes.removeAssistant}
                          onClick={(e) =>
                            onRemoveAssistant(
                              e,
                              (populatedCheckpoint.id || populatedCheckpoint.__id) as any,
                              assistant.id,
                              getAssistantLabel(assistant),
                            )
                          }
                          size='large'
                        >
                          <Close fontSize='large' />
                        </IconButton>
                      </Box>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </DNDItem>
          );
        })}
      </DNDContainer>
    </div>
  );
};

Items = withConfirmModal('deleteCheckpointPopup', {
  i18Title: 'distances.steps.checkpointsForm.new.deleteCheckpoint.title',
  i18Body: 'distances.steps.checkpointsForm.new.deleteCheckpoint.body',
  type: CONFIRM_POPUP_TYPES.delete,
})(Items);

Items = withConfirmModal('deleteAssistantPopup', {
  i18Title: 'distances.steps.checkpointsForm.new.deleteAssistant.title',
  i18Body: 'distances.steps.checkpointsForm.new.deleteAssistant.body',
  type: CONFIRM_POPUP_TYPES.delete,
})(Items);

Items = withConfirmModal('disabledDeleteCheckpointPopup', {
  i18Title: 'distances.steps.checkpointsForm.new.disabledDeleteCheckpoint.title',
  i18Body: 'distances.steps.checkpointsForm.new.disabledDeleteCheckpoint.body',
  type: CONFIRM_POPUP_TYPES.info,
})(Items);

export { Items };

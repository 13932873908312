import { isArray, flatten } from 'lodash';

import { timeRange } from 'components/TimeRange';

export const dateRange: FilterDescriptor = {
  persistance: {
    calcHandler: function (name, value) {
      if (!isArray(value)) {
        return;
      }
      const [start, end] = value;

      const fromPresented = !!start;
      const toPresented = !!end;

      if (fromPresented && !toPresented) {
        return '>=';
      }

      if (toPresented && !fromPresented) {
        return '<=';
      }

      return 'between';
    },

    calcValue: function (name, value) {
      if (!isArray(value)) {
        return;
      }
      const [start, end] = value;

      const fromPresented = !!start;
      const toPresented = !!end;
      if (fromPresented && !toPresented) {
        return start;
      }

      if (toPresented && !fromPresented) {
        return end;
      }

      return value;
    },

    prettify: function (
      name,
      value,
      renderProps: {
        dateFormat: BEDateFormat;
      },
    ) {
      return formatDates(value, renderProps.dateFormat);
    },
  },
};

function formatDates(value: string | string[], dateFormat: BEDateFormat) {
  const [from, to] = flatten([value]);
  return timeRange(from, to, dateFormat, 'shortDate');
}

import { HELPER_ORGANIZER, ORGANIZERS_URL } from 'src/constants';

import { request, action } from 'utils';

import { helperOrganizersStore } from 'stores';
import { HelperOrganizer as HelperOrganizerStore } from 'stores';

import { LoadableService } from './loadable';

class HelperOrganizerService extends LoadableService<OrganizerType, FiltersType, HelperOrganizerStore> {
  constructor(store: HelperOrganizerStore) {
    super({ store, resourcesUrl: ORGANIZERS_URL, resource: HELPER_ORGANIZER });
  }

  @request({ action: `LOAD_${HELPER_ORGANIZER}S` })
  async loadResourcesRequest(...params: any): Promise<any> {
    // @ts-ignore
    return super.loadResourcesRequest(...params);
  }

  @action({ action: `LOAD_${HELPER_ORGANIZER}S` })
  async loadResources(...params: any): Promise<any> {
    params = [
      {
        search: 'roles.name:organizer,admin',
        searchFields: 'roles.name:in',
        searchJoin: 'AND',
      },
      ...params,
    ];

    if (!this.store.values.length) {
      return super.loadResources(...params);
    }
  }
}

const helperOrganizersLoadService = new HelperOrganizerService(helperOrganizersStore);
export { HelperOrganizerService, helperOrganizersLoadService };

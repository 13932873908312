import classNames from 'classnames';

import { t } from 'utils/t';

export const BadgeActive = () => {
  return (
    <span className={classNames('badge', 'active')}>
      <span className='dot' />
      <span>{t.staticAsString('coupons.detail.active')}</span>
    </span>
  );
};

export const BadgeInactive = () => {
  return (
    <span className={classNames('badge', 'inactive')}>
      <span className='dot' />
      <span>{t.staticAsString('coupons.detail.inActive')}</span>
    </span>
  );
};

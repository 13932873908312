import { makeAutoObservable } from 'mobx';

import { resize } from 'utils/size';

class SidebarStore {
  isCollapsed = false;
  constructor() {
    const localIsCollapsed = localStorage.getItem('isCollapsed');
    this.isCollapsed = localIsCollapsed ? JSON.parse(localIsCollapsed) : resize.isLowResolution();
    makeAutoObservable(this);
  }

  toggle() {
    localStorage.setItem('isCollapsed', JSON.stringify(!this.isCollapsed));
    this.isCollapsed = !this.isCollapsed;
  }
}

const sidebarStore = new SidebarStore();
export { sidebarStore };

import { handleExport } from 'src/utils';

import { exportJobService } from 'services';

export const exportCampaignLink = async (token: string, linkId: number): Promise<void> => {
  const [status, response] = await exportJobService.exportCampaignLink(token);

  if (!status) {
    return;
  }

  const { id } = response.data.data;

  handleExport({
    id,
    estimatedLoadingTime: 3,
    fileName: `CampaignLink.${id}`,
    onRetry: () => exportCampaignLink(token, linkId),
  });
};

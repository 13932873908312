import DOMPurify, { Config } from 'dompurify';

import { urlSanitizer } from './urlSanitizer';

const TEMPORARY_ATTRIBUTE = 'data-temp-href-target';
const defaultConfig: Config = {
  ADD_TAGS: ['iframe'],
};

DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
  if (node.tagName === 'A') {
    if (node.hasAttribute('target')) {
      node.setAttribute(TEMPORARY_ATTRIBUTE, node.getAttribute('target') || '_self');
    }
  }
});

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if (node.tagName === 'A' && node.hasAttribute(TEMPORARY_ATTRIBUTE)) {
    node.setAttribute('target', node.getAttribute(TEMPORARY_ATTRIBUTE)!);
    node.removeAttribute(TEMPORARY_ATTRIBUTE);
    if (node.getAttribute('target') === '_blank') {
      node.setAttribute('rel', 'noopener');
    }
  }
});

DOMPurify.addHook('uponSanitizeElement', (node: Element) => {
  if (node.tagName === 'IMG') {
    if (node.hasAttribute('src')) {
      node.setAttribute('src', urlSanitizer(node.getAttribute('src') as string));
    }
  }
});

const htmlSanitizer = (html: string, config: Config = {}): string => {
  return DOMPurify.sanitize(html, { ...defaultConfig, ...config }) as string;
};

export { htmlSanitizer };

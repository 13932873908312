import { t } from 'utils';

import { ProcessAgreementTable } from '../components/ProcessAgreementTable';
import { Section, Title, Paragraph } from '../components/Shared';

export const ProcessAgreement = () => {
  return (
    <>
      <Title t='processAgreement.header' />
      <p className='terms-description'> {t('processAgreement.description')}</p>
      <Section index={1}>
        <Title t='processAgreement.section1.title' />
        <Paragraph t='processAgreement.section1.1' />
        <Paragraph t='processAgreement.section1.2' />
        <Paragraph t='processAgreement.section1.3' />
      </Section>
      <Section index={2}>
        <Title t='processAgreement.section2.title' />
        <p className='terms-section--text'>{t.staticAsString('processAgreement.section2')}</p>
      </Section>
      <Section index={3}>
        <Title t='processAgreement.section3.title' />
        <Paragraph t='processAgreement.section3.1' />
        <p className='terms-section--text'>{t.staticAsString('processAgreement.section3.a')}</p>
        <p className='terms-section--text'>{t.staticAsString('processAgreement.section3.b')}</p>
        <p className='terms-section--text'>{t.staticAsString('processAgreement.section3.c')}</p>
        <p className='terms-section--text'>{t.staticAsString('processAgreement.section3.d')}</p>
        <p className='terms-section--text'>{t.staticAsString('processAgreement.section3.e')}</p>
        <p className='terms-section--text'>{t.staticAsString('processAgreement.section3.f')}</p>
      </Section>
      <Section index={4}>
        <Title t='processAgreement.section4.title' />
        <Paragraph t='processAgreement.section4.1' />
        <Paragraph t='processAgreement.section4.2' />
      </Section>
      <Section index={5}>
        <Title t='processAgreement.section5.title' />
        <Paragraph t='processAgreement.section5.1' />
        <Paragraph t='processAgreement.section5.2' />
        <Paragraph t='processAgreement.section5.3' />
        <Paragraph t='processAgreement.section5.4' />
      </Section>
      <Section index={6}>
        <Title t='processAgreement.section6.title' />
        <Paragraph t='processAgreement.section6.1' />
        <Paragraph t='processAgreement.section6.2' />
        <Paragraph t='processAgreement.section6.3' />
      </Section>
      <Section index={7}>
        <Title t='processAgreement.section7.title' />
        <Paragraph t='processAgreement.section7.1' />
        <Paragraph t='processAgreement.section7.2' />
        <Paragraph t='processAgreement.section7.3' />
      </Section>
      <Section index={8}>
        <Title t='processAgreement.section8.title' />
        <Paragraph t='processAgreement.section8.1' />
        <Paragraph t='processAgreement.section8.2' />
        <Paragraph t='processAgreement.section8.3' />
      </Section>
      <Section index={9}>
        <Title t='processAgreement.section9.title' />
        <Paragraph t='processAgreement.section9.1' />
        <Paragraph t='processAgreement.section9.2' />
      </Section>
      <p className='stars'>****</p>
      <p className='terms-section--text'>{t.staticAsString('processAgreement.description2')}</p>
      <Title t='processAgreement.appendix1' />
      <Title t='processAgreement.appendix1.heading' />
      <Section index={1}>
        <Title t='processAgreement.appendix.section1.title' />
        <p className='terms-section--text'>{t.staticAsString('processAgreement.appendix.section1.1')}</p>
      </Section>
      <Section index={2}>
        <Title t='processAgreement.appendix.section2.title' />
        <Paragraph className='bold' t='processAgreement.appendix.section2.1' />
        <p className='terms-section--text'>{t.staticAsString('processAgreement.appendix.section2.1.body')}</p>
        <Paragraph className='bold' t='processAgreement.appendix.section2.2' />
        <p className='terms-section--text'>{t.staticAsString('processAgreement.appendix.section2.2.body')}</p>
        <Paragraph className='bold' t='processAgreement.appendix.section2.3' />
        <p className='terms-section--text'>{t.staticAsString('processAgreement.appendix.section2.3.body')}</p>
      </Section>
      <Section index={3}>
        <Title t='processAgreement.appendix.section3.title' />
        <p className='terms-section--text'>{t.staticAsString('processAgreement.appendix.section3.1')}</p>
      </Section>
      <Section index={4}>
        <Title t='processAgreement.appendix.section4.title' />
        <p className='terms-section--text'>{t.staticAsString('processAgreement.appendix.section4.1')}</p>
      </Section>
      <Section index={5}>
        <Title t='processAgreement.appendix.section5.title' />
        <p className='terms-section--text'>{t.staticAsString('processAgreement.appendix.section5.1')}</p>
        <ProcessAgreementTable />
      </Section>
      <Section index={6}>
        <Title t='processAgreement.appendix.section6.title' />
        <p className='terms-section--text'>{t.staticAsString('processAgreement.appendix.section6.1')}</p>
      </Section>
      <Section index={7}>
        <Title t='processAgreement.appendix.section7.title' />
        <p className='terms-section--text'>{t.staticAsString('processAgreement.appendix.section7.1')}</p>
      </Section>
      <p className='stars'>****</p>
    </>
  );
};

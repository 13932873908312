import { progressStore, errorsStore } from 'stores';

/*
 * ANNOTATION
 * Wrapping request processors
 * Promise for resolve request actions
 * Tracking timing and progress of action,
 * Minimal request time for smooth loading
 */
export function action(reqArgs: { action: string; minRequestTime?: number }) {
  reqArgs = { ...reqArgs };

  return function (target: any, propertyKey: string, descriptor: any): any {
    var oldValue = descriptor.value;

    descriptor.value = async function (...args: unknown[]) {
      const action = reqArgs.action;

      progressStore.log(action, 'progress');
      errorsStore.clearError(action);

      const requestStartTime = new Date();

      const calcValue = await oldValue.call(this, ...args);

      let logStatus: any = 'completed';

      if (calcValue === false) {
        logStatus = !!calcValue ? 'completed' : 'failed';
      }

      let timeProgressParams: any = null;

      if (reqArgs.minRequestTime) {
        timeProgressParams = {
          requestStartTime,
          minRequestTime: reqArgs.minRequestTime,
        };
      }

      progressStore.log(action, logStatus, timeProgressParams);
      return calcValue;
    };

    return descriptor;
  };
}

import { matchPath } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { history } from 'utils/history';

import { campaignLinkDetailsService } from '../services/campaignLinksDetails.service';

import { campaignLinkStore } from '../stores';

export const getCampaignLinkData = async () => {
  const { pathname } = history.location;
  const { params: { token } = { token: null } } = matchPath(pathname, { path: ROUTES.campaignLinkDetails }) || {};

  if (!token) {
    return;
  }
  const linkData = await campaignLinkDetailsService.loadCampaignLink(token);
  campaignLinkStore.set(linkData);
};

import axios from 'axios';
import shortid from 'shortid';

import { FILES_URL } from 'src/constants';

import { request } from 'utils/core/request';

class FilesService {
  @request({ action: shortid() })
  private request(formData: FormData): any {
    return axios.post(FILES_URL, formData);
  }

  /**
   * @description
   * formData should contain {file: Blob}
   * */
  async upload(formData: FormData): Promise<{ isOk: boolean; url: string | null; uuid: string | null; message: string }> {
    const [isOk, response] = await this.request(formData);
    const url = response.data.data?.url || null;
    const uuid = response.data.data?.uuid || null;
    const message = response.message || '';

    return {
      message,
      url,
      uuid,
      isOk,
    };
  }
}

const filesService = new FilesService();
export { FilesService, filesService };

import axios from 'axios';
import { mapRacesForDropdown } from 'modules/Synchronisation/RaceResults/utils';

import { RACES_URL } from 'src/constants';
import { request, action } from 'src/utils';

import { RACES_EQ_REQUEST } from '../constants';

import { racesParamsStore, racesStore, racesHasMoreStore } from '../stores';

class RacesService {
  @request({ action: RACES_EQ_REQUEST })
  async getRacesRequest(): Promise<any> {
    const params = racesParamsStore.value;
    return axios.get(RACES_URL, { params });
  }

  @action({ action: RACES_EQ_REQUEST })
  async getRaces() {
    const [status, response] = await this.getRacesRequest();

    if (status) {
      racesHasMoreStore.set(response.data.meta.pagination.total_pages > (racesParamsStore.value?.page as number));
      racesParamsStore.set({
        ...(racesParamsStore.value as any),
        page: (racesParamsStore.value?.page as number) + 1,
      });
      racesStore.set([...(racesStore.value || []), ...mapRacesForDropdown(response.data.data)]);
    }
  }
}

export const racesService = new RacesService();

import { t } from 'utils';

const sportType = {
  title: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('categories.errorTitleSv'),
    },
  },

  title_en: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('categories.errorTitleEn'),
    },
  },

  max_speed: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('categories.errorMaxSpeed'),
    },
  },

  icon: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('categories.errorIconPresence'),
    },
    blobSvg: true,
  },
};

export { sportType };

import { isEmpty } from 'lodash';

import { smartLinksService } from '../services';

import { hasOrganizerLinksStore, smartLinksStore } from '../stores';

import { Filters, SmartLinkBE } from '../../shared/types';
import { mapLinkToFE } from '../mappers';

export async function loadLinks(filters?: Filters) {
  const { data, meta } = await smartLinksService.loadLinksList(filters);
  const mappedData = data.map((link: SmartLinkBE) => mapLinkToFE(link));
  smartLinksStore.set({ data: mappedData, meta: meta });
}

export async function hasOrganizerLinks() {
  const { data } = await smartLinksService.loadLinksList();
  !isEmpty(data) ? hasOrganizerLinksStore.on() : hasOrganizerLinksStore.off();
}

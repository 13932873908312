export const getAlign = (columns: any[], colIdx: number) => {
  if (colIdx === 0) return 'left';
  if (colIdx === columns.length - 1) return 'right';
  return 'center';
};

export const getStyle = (data: any[], columns: any[], rowIdx: number, colIdx: number) => {
  if (rowIdx + 1 === data.length) {
    return {
      borderBottom: 'none',
      paddingLeft: '16px',
    };
  }
  if (colIdx === 0) return { paddingLeft: '16px' };
  if (colIdx === columns.length - 1) return { paddingRight: '16px' };
  return {};
};

import ClearIcon from '@mui/icons-material/Clear';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';
import { useEffect, useRef } from 'react';

import { Show } from 'components/Condition';
import { Svg } from 'components/Icon';
import { LoadingDots } from 'components/LoadingDots';

import useIntersectionObserver from 'utils/hooks/useIntersectionObserver';

import { useSelectOptionStyles, useSelectStyles } from './styles';

type OptionProps = {
  label: string | React.ReactNode;
  lastOption?: boolean;
  loadMore?: Function;
  hasMore?: boolean;
};

const useStyles = makeStyles((theme) => ({
  loader: {
    marginTop: 12,
  },
}));

const Option = ({ label, lastOption = false, loadMore, hasMore }: OptionProps) => {
  const selectOptionClasses: { [Key in string]: any } = useSelectOptionStyles();
  const classes = useStyles();
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible) {
      loadMore && loadMore();
    }
  }, [isVisible]);

  return (
    <>
      {lastOption && hasMore && loadMore ? (
        <div ref={ref}>
          <Typography className={selectOptionClasses.option}>{label}</Typography>
          <LoadingDots className={classes.loader} />
        </div>
      ) : (
        <Typography className={selectOptionClasses.option}>{label}</Typography>
      )}
    </>
  );
};

type Props = {
  classes?: {
    [K in string]: string;
  };
  name: string;
  onChange: AnyFunction;
  onClose?: AnyFunction;
  value: string | number;
  errors: Array<string>;
  type?: string;
  id?: string;
  label?: string;
  style?: {};
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  className?: string;
  classNameForItem?: string;
  options: Array<SelectOption>;
  fullWidth?: boolean;
  reservePlaceForHelperText?: boolean;
  required?: boolean;
  disabled?: boolean;
  helperText?: React.ReactNode | nil;
  loadMore?: Function;
  hasMore?: boolean;
  onRemove?: AnyFunction;
};
const SelectField = ({
  classes,
  name,
  onChange,
  onClose,
  value,
  errors = [],
  id = '',
  label = '',
  variant = 'outlined',
  options = [],
  className,
  classNameForItem,
  fullWidth = false,
  reservePlaceForHelperText = true,
  required,
  disabled,
  helperText,
  style,
  hasMore,
  loadMore,
  onRemove,
}: Props) => {
  classes = classes || useSelectStyles();
  const selectOptionClasses: { [Key in string]: any } = useSelectOptionStyles();
  const formName = id ? `${id}_${name}` : name;
  const isErr = errors.length > 0;
  const errorMessage = errors.join(' ');
  const lastOptionIndex = options.length - 1;

  return (
    <FormControl
      style={style}
      fullWidth={fullWidth}
      className={classNames(className, { error: isErr }, classes.formControl)}
      error={isErr}
      variant={variant}
      aria-describedby='name-error-text'
      disabled={disabled}
      sx={{ marginTop: '7px' }}
    >
      <InputLabel variant={variant} shrink={!!value} htmlFor={formName} classes={{ root: 'form-label' }} required={required}>
        {label}
      </InputLabel>
      <Select
        variant={variant}
        label={label}
        disabled={disabled}
        fullWidth={fullWidth}
        value={value}
        error={isErr}
        onChange={onChange}
        onClose={onClose}
        classes={classes}
        inputProps={{
          name: name,
          id: formName,
        }}
        endAdornment={
          onRemove && (
            <IconButton onClick={onRemove} sx={{ display: value ? '' : 'none' }}>
              <ClearIcon />
            </IconButton>
          )
        }
        sx={{ '& .MuiSelect-iconOutlined': { display: value ? 'none' : '' } }}
      >
        {options.map((item, index) => (
          <MenuItem key={item.key} value={item.value} className={classNames(classNameForItem, selectOptionClasses.menuItem)}>
            <Option label={item.label} lastOption={lastOptionIndex === index} hasMore={hasMore} loadMore={loadMore} />
          </MenuItem>
        ))}
      </Select>
      <Show if={isErr}>
        <FormHelperText variant={variant} className='errors' id={`${formName}_error`}>
          {errorMessage || (reservePlaceForHelperText && ' ') || null}
        </FormHelperText>
      </Show>
      <Show if={Boolean(helperText) && !isErr}>
        <FormHelperText variant={variant}>{helperText || (reservePlaceForHelperText && ' ') || null}</FormHelperText>
      </Show>
      <Show if={reservePlaceForHelperText && !isErr && !helperText}>
        <FormHelperText> </FormHelperText>
      </Show>
    </FormControl>
  );
};
export { SelectField };

import { ResultsContextType } from '../context';

function formatWaveId(waveId: nil | number | string, context: ResultsContextType): string | null {
  const distanceModel = context.results.stores.distanceStore.modelSelected;

  if (!distanceModel) {
    return null;
  }

  const waveModel = distanceModel.findRelationModelValue('waves', +(waveId as number));

  if (!waveModel) {
    return null;
  }

  return waveModel.label();
}

export { formatWaveId };

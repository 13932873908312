import axios from 'axios';
import { values, cloneDeep, isString } from 'lodash';
import { toJS } from 'mobx';
import { generatePath } from 'react-router-dom';

import { RACER, DISTANCES_URL, RACERS_URL, RACERS_REMOVE_CF_URL } from 'src/constants';

import { t, request, action, copyTextToClipboard } from 'utils';

import { toastStore } from 'stores';

import { handleMessageErrors } from '../utils';

import { startlistRacersStore } from '../stores';
import { Racers as RacersStore } from '../stores';

import { processNewRacer } from './dataProcessor';

class LoadRacerService implements ExtensionableSearchService, ExtensionableSortService {
  store: RacersStore;

  constructor(store: RacersStore) {
    this.store = store;
  }

  @request({ action: `LOAD_${RACER}S` })
  async loadResourcesRequest(id: number, params: FiltersType): Promise<any> {
    return axios.get(`${DISTANCES_URL}/${id}/racers`, {
      params,
    });
  }

  @action({ action: `LOAD_${RACER}S`, minRequestTime: 800 })
  async loadResources(distanceId: number, newParams: FiltersType = {}, pageNum: number = 1): Promise<any> {
    let { page, params, filters } = this.store;
    page = page !== pageNum && pageNum ? pageNum : page;

    const queryParams = {
      ...params,
      ...newParams,
      page,
    };

    const [status, response] = await this.loadResourcesRequest(distanceId, queryParams);
    if (status) {
      const values = response.data.data;
      this.store.updateCurrentDistance(distanceId);
      this.store.addValues(values, page, { ...filters, ...newParams });

      const paginationMeta = response.data.meta && response.data.meta.pagination;
      if (paginationMeta) {
        this.store.addPaginationMeta(paginationMeta);
      }
    }

    return response;
  }

  @request({ action: `CREATE_${RACER}` })
  async createResourceRequest(distance_id: number, racer: RacerType): Promise<any> {
    return axios.post(`${DISTANCES_URL}/${distance_id}/racers`, racer);
  }

  @action({ action: `CREATE_${RACER}` })
  async createResource(racer: RacerType, distance_id: number, closeModal: Function): Promise<any> {
    const [status, response] = await this.createResourceRequest(distance_id, processNewRacer(racer) as any);

    if (status) {
      this.loadResources(distance_id);
      closeModal();
      toastStore.show(t.staticAsString('racers.createSuccess'));
    } else {
      handleMessageErrors(response.data.message);
    }
  }

  @request({ action: `UPDATE_${RACER}` })
  async updateResourceRequest(params: RacerType, racer_id: number, distance_id: number): Promise<any> {
    return axios.patch(`${RACERS_URL}/${racer_id}`, params);
  }

  @action({ action: `UPDATE_${RACER}` })
  async updateResource(racer: RacerType, item: RacerType): Promise<any> {
    const copy = cloneDeep(racer);
    if (isString(copy.gender)) {
      copy.gender = +copy.gender as genderValue;
    }

    const [status, response] = await this.updateResourceRequest(copy, item.id, Number(item.distance_id));

    if (status) {
      this.store.updateValue(item);
      toastStore.show(t.staticAsString('racers.updateSuccess'));
    } else {
      toastStore.show(values(response.data.errors).join(' '));
    }

    return status;
  }

  @request({ action: `UPDATE_${RACER}` })
  async updateCustomFieldRequest(params: RacerCustomFieldValue, racer_id: number): Promise<any> {
    return axios.patch(`${RACERS_URL}/${racer_id}/custom-field/${params.id}`, params);
  }

  @action({ action: `UPDATE_${RACER}` })
  async updateCustomField(value: RacerCustomFieldValue, racer: RacerType): Promise<any> {
    const [status, response] = await this.updateCustomFieldRequest(value, racer.id);

    if (status) {
      this.store.updateValue(racer);
      toastStore.show(t.staticAsString('racers.updateSuccess'));
    } else {
      toastStore.show(values(response.data.message).join(' '));
    }

    return status;
  }

  @request({ action: `DELETE_${RACER}` })
  deleteCustomFieldRequest(racerId: number, cfid: number): any {
    return axios.delete(generatePath(RACERS_REMOVE_CF_URL, { id: racerId, cfid }));
  }

  @action({ action: `DELETE_${RACER}` })
  async deleteCustomField(value: RacerCustomFieldValue, racer: RacerType) {
    const [status, response] = await this.deleteCustomFieldRequest(racer.id, value.id);

    if (status) {
      racer.fields = racer.fields!.filter((field) => field.field_id !== value.id);
      this.store.updateValue(racer);
      toastStore.show(t.staticAsString('racers.updateSuccess'));
    } else {
      toastStore.show('Error removing custom field');
    }
  }

  @request({ action: `DELETE_${RACER}` })
  deleteValueRequest(distance_id: number, id: number): any {
    return axios.delete(`${RACERS_URL}/${id}`);
  }

  @action({ action: `DELETE_${RACER}` })
  async deleteValue(distance_id: number, id: number) {
    const [status] = await this.deleteValueRequest(distance_id, id);

    if (status) {
      this.loadResources(distance_id);
      toastStore.show(t.staticAsString('racers.deleteSuccess'));
    }
  }

  @request({ action: `GET_LINK_${RACER}` })
  async getRegistrationLinkRequest(distanceId: number, racerId: number): Promise<any> {
    return axios.get(`${RACERS_URL}/${racerId}/registration-link`);
  }

  @action({ action: `GET_LINK_${RACER}` })
  async getRegistrationLink(distanceId: number, racerId: number): Promise<void> {
    const [status, response] = await this.getRegistrationLinkRequest(distanceId, racerId);

    if (status) {
      copyTextToClipboard(response.data.link, 'startlist.controls.copyMessage');
    }
  }

  @request({ action: `RESEND_${RACER}` })
  async resendInvitationRequest(distanceId: number, racerId: number): Promise<any> {
    return axios.post(`${RACERS_URL}/${racerId}/invite`);
  }

  @action({ action: `RESEND_${RACER}` })
  async resendInvitation(distanceId: number, racerId: number, isSuccesfulEmail: boolean): Promise<any> {
    const [status] = await this.resendInvitationRequest(distanceId, racerId);
    const toastText = `startlist.controls.resendMessage${isSuccesfulEmail ? '.successful' : ''}` as TranslationLockedKeys;
    if (status) {
      toastStore.show(t.staticAsString(toastText));
    }
  }

  @request({ action: `EXPORT_${RACER}S` })
  async exportResourceRequest(distance_id: number, type: string): Promise<any> {
    const params = { type: type };
    return axios.get(`${DISTANCES_URL}/${distance_id}/export/startlist`, { params });
  }

  @action({ action: `EXPORT_${RACER}S` })
  async exportResource(distance_id: number, type: string): Promise<any> {
    const [status, response] = await this.exportResourceRequest(distance_id, type);

    if (status) {
      window.open(response.data.url);
    }
  }

  async load(): Promise<void> {
    const distanceId = this.store.distanceId;
    await this.loadResources(distanceId);
  }
}

const racersService = new LoadRacerService(startlistRacersStore);
export { LoadRacerService, racersService };
export default racersService;

import { resultsToast } from '../utils';

import { raceDetailsService } from '../services';

import { raceDetailsStore } from '../stores';

const toggleResultsAction = async (newValue: number) => {
  const race = raceDetailsStore.raceDetails?.value;

  if (!race) {
    return [];
  }

  const { id } = race;
  const params = {
    show_results: newValue,
  };

  raceDetailsStore.partialRaceUpdate(params);
  const [status] = await raceDetailsService.toggleResultsVisibility(id, params);

  // correct server response, show success message
  resultsToast(status, newValue);

  if (status) {
    return;
  }

  // bad server response, error message was shown before, roll back state
  const previousValue = +!newValue;

  raceDetailsStore.partialRaceUpdate({ show_results: previousValue });
};

export const hideResultsAction = () => {
  toggleResultsAction(0);
};

export const showResultsAction = () => {
  toggleResultsAction(1);
};

import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { withStyles } from '@mui/styles';
import * as React from 'react';

type Props = {
  content: string;
};

const DialogContentTextStyled = withStyles({
  root: {
    marginTop: 12,
    color: '#262e35',
  },
})(DialogContentText);

export class Content extends React.Component<Props> {
  render() {
    const { content } = this.props;

    return (
      <DialogContent>
        <DialogContentTextStyled>{content}</DialogContentTextStyled>
      </DialogContent>
    );
  }
}

import axios from 'axios';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { RACES_URL, RACE_URL } from 'src/constants';
import { request, action } from 'src/utils';

import { RACES_REG_LINKS_REQUESTS } from '../constants';

import { mapToOptionsRaces } from '../utils';

import { racesParamsStore, racesStore, racesHasMoreStore } from '../stores';

class RacesService {
  @request({ action: RACES_REG_LINKS_REQUESTS })
  async getRacesRequest(): Promise<any> {
    const params = racesParamsStore.value;
    return axios.get(RACES_URL, { params });
  }

  @action({ action: RACES_REG_LINKS_REQUESTS })
  async getRaces() {
    const [status, response] = await this.getRacesRequest();

    if (status) {
      racesHasMoreStore.set(response.data.meta.pagination.total_pages > (racesParamsStore.value?.page as number));
      racesParamsStore.set({
        ...(racesParamsStore.value as any),
        page: (racesParamsStore.value?.page as number) + 1,
      });
      racesStore.set([...(racesStore.value || []), ...mapToOptionsRaces(response.data.data)]);
    }
  }

  @request({ action: shortid() })
  async getRaceRequest(id: number): Promise<any> {
    const path = generatePath(RACE_URL, { id: id });
    return axios.get(path);
  }

  @action({ action: shortid() })
  async getRace(id: number) {
    const [isOk, response] = await this.getRaceRequest(id);
    if (isOk) {
      return mapToOptionsRaces([response.data.data])[0];
    }
  }
}

export const racesService = new RacesService();

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { RACE_URL } from 'src/constants';

import { action, request } from 'utils';

import { GET_RACE } from '../constants';

import { raceStore } from '../stores';

class RaceService {
  @request({ action: GET_RACE })
  async getRaceRequest(id: number, params: AnyObject): Promise<any> {
    const url = generatePath(RACE_URL, { id });
    return axios.get(url, { params });
  }

  @action({ action: GET_RACE })
  async getRace(id: number): Promise<void> {
    const params = { withCount: 'distances' };

    const [status, response] = await this.getRaceRequest(id, params);

    if (status) {
      raceStore.set(response.data.data);
    }
  }
}

export const raceService = new RaceService();

import { t } from 'utils';

import { toastStore } from 'stores';

import { integrationsService } from '../services';

import { raceStore, confirmationDeleteStore } from '../stores';

export const onDeleteIntegration = (id: number) => async (): Promise<void> => {
  confirmationDeleteStore.set({
    open: true,
    id,
  });
};

export const onCancelDelete = () => {
  confirmationDeleteStore.clear();
};

export const onConfirmDelete = async (): Promise<void> => {
  const id = Number(confirmationDeleteStore.value?.id);
  const raceId = Number(raceStore.value?.id);

  confirmationDeleteStore.clear();

  const success = await integrationsService.deleteIntegration(raceId, id);

  if (success) {
    integrationsService.getIntegrations(raceId);
    toastStore.show(t.staticAsString('integrations.list.successfullyDeleted'));
  }
};

import GoogleMapReact from 'google-map-react';
import { Observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { GOOGLE_MAP_API_KEY } from 'src/constants';
import { gpxGoogleMapStore, markersStore } from 'src/modules/Distances/components/shared/Steps/GPX/stores';
import { setMarkersInStore, drawTrack, getCenter } from 'src/modules/Distances/components/shared/Steps/GPX/utils';
import { formStyles } from 'src/modules/Races/components/styles';

import { Show } from 'components/Condition';

import { appEnvControl, t } from 'utils';

const MapWrapper = styled.div<{ isModal: boolean }>`
  width: ${(props) => (props.isModal ? '822px' : '100%')};
  height: ${(props) => (props.isModal ? '612' : '360')}px;
  padding: ${(props) => (props.isModal ? '0' : '0 16px 32px')};
`;

const MapTitle = styled.div`
  margin: 0 0 24px;
  font-size: 16px;
`;

type Props = {
  isModal: boolean;
};

export const globalMap = {
  map: null,
  maps: null,
};

export const MapView = (props: Props): ReactElement => {
  const classes = formStyles();

  return (
    <Observer>
      {() => {
        const markers = markersStore.value;
        const { isModal } = props;

        const handleApiLoaded = (map, maps) => {
          gpxGoogleMapStore.set({ map, maps });
          globalMap.map = map;
          globalMap.maps = maps;
          window.requestAnimationFrame(() => {
            drawTrack();
            setMarkersInStore();
          });
        };

        const key = GOOGLE_MAP_API_KEY[appEnvControl.currentEnvWithDefaultDev()];

        return (
          <>
            <div className={isModal ? '' : classes.wrapperFullWidth}>
              <div>
                <MapWrapper isModal={isModal}>
                  <Show if={!isModal}>
                    <MapTitle>{t.staticAsString('distances.steps.helperText.mapTitle')}</MapTitle>
                  </Show>

                  <GoogleMapReact
                    bootstrapURLKeys={{
                      libraries: ['geometry', 'places', 'drawing', 'directions'],
                      key,
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    options={{
                      clickableIcons: false,
                    }}
                    center={getCenter()}
                    zoom={11}
                  ></GoogleMapReact>
                </MapWrapper>
              </div>
            </div>
          </>
        );
      }}
    </Observer>
  );
};

import { map } from 'lodash';
import { observer } from 'mobx-react';
import { convertForSelect } from 'modules/StartList/utils';
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import { SelectField } from 'components/Fields/SelectField';

type Props = {
  item: RacerType;
  isEdit: string | null;
  onChange: (name: string, value: any, callback: Function) => void;
  distancesField: AnyObject;
  distance: AnyObject;
  changedFields: AnyObject;
  saveChanges: () => void;
  currentField: AnyObject;
};

@onClickOutside
@observer
class Select extends React.Component<Props> {
  id = 'startlist-select-field';
  closeTimeoutId: null | number = null;

  save = () => {
    this.props.saveChanges();
  };

  saveTimeout = () => {
    this.closeTimeoutId = setTimeout(() => this.save(), 100);
  };

  stopSaveTimeout = () => {
    this.closeTimeoutId && clearTimeout(this.closeTimeoutId);
  };

  handleClickOutside = (evt: React.SyntheticEvent<HTMLElement>) => {
    const popoverContainer = document.getElementById(`menu-${this.id}`);

    if (!popoverContainer || !evt.target) {
      return this.save();
    }

    if (popoverContainer.contains(evt.target as Element)) {
      return;
    }

    this.save();
  };

  onClose = () => {
    this.stopSaveTimeout();
    this.saveTimeout();
  };

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange, item, distancesField } = this.props;
    const { value } = e.target;

    const currentField = distancesField.values.find((field: AnyObject) => field.id === value);
    let currentValue: any[], changedValue;

    if (item.fields?.find((el) => el.field_id === distancesField.id)) {
      currentValue = item.fields?.map((field) => {
        return field.field_id === currentField.field_id ? currentField : field;
      });
    } else {
      currentValue = [...(item.fields || []), currentField];
    }

    changedValue = this.getChangedValues(value);

    this.stopSaveTimeout();

    onChange(currentValue as any, changedValue, () => {
      this.saveTimeout();
    });
  };

  getChangedValues = (value: string) => {
    const { distancesField, changedFields } = this.props;
    const changedValue = { id: distancesField.id, values: [value] };

    if ((changedFields.fields || []).find((field: AnyObject) => field.id === distancesField.id)) {
      return changedFields.fields.map((field: AnyObject) => (field.id === distancesField.id ? changedValue : field));
    }

    return [...(changedFields.fields || []), changedValue];
  };

  render() {
    const { distancesField, distance, currentField } = this.props;

    const distancesFieldValues = map(distancesField.values, (fieldValue) => ({
      ...fieldValue,
      currency: distance?.race?.currency?.iso_code || '',
    }));
    const options = convertForSelect(distancesFieldValues);

    return (
      <SelectField
        name={this.id}
        value={currentField && currentField.id}
        onChange={this.onChange}
        onClose={this.onClose}
        options={options}
        errors={[]}
        fullWidth
      />
    );
  }
}

export { Select };

import axios from 'axios';
import { UPDATE_CAMPAIGN_LINK } from 'modules/SmartCampaignLinks/CampaignLinkDetails/constants';
import { generatePath } from 'react-router-dom';

import { CAMPAIGN_LINKS_URL, CAMPAIGN_LINK_URL } from 'src/constants';
import { request, action } from 'src/utils';

import { CREATE_MAGIC_LINK_REQUEST } from '../constants';

class Links {
  @request({ action: CREATE_MAGIC_LINK_REQUEST })
  async createCampaignLinkRequest(data: AnyObject): Promise<any> {
    return axios.post(CAMPAIGN_LINKS_URL, data);
  }

  @action({ action: CREATE_MAGIC_LINK_REQUEST })
  async createCampaignLink(data) {
    const [isOk] = await this.createCampaignLinkRequest(data);
    return isOk;
  }

  @request({ action: UPDATE_CAMPAIGN_LINK })
  updateCampaignLinkRequest(token: string, data: AnyObject): any {
    const url = generatePath(CAMPAIGN_LINK_URL, { token });
    return axios.patch(url, data);
  }

  @action({ action: UPDATE_CAMPAIGN_LINK })
  async updateCampaignLink(token: string, data: AnyObject): Promise<any> {
    const [isOk] = await this.updateCampaignLinkRequest(token, data);
    return isOk;
  }
}

export const linksService = new Links();

import moment from 'moment';
import * as React from 'react';
import ReactDatePicker from 'react-datepicker';

import { BACKEND_DATE_FORMATE } from 'src/constants';

import { commonDateFormats } from 'utils';

import { Input } from './Input';

type Props = {
  classes?: {
    [K in string]: string;
  };
  name: string;
  onChange: (arg0: { name: string; value: nil | string }) => void;
  value: nil | string;
  errors: Array<string>;
  type?: string;
  id?: string;
  label?: string;
  className?: string;
  fullWidth?: boolean;
  dateRange: nil | Date[];
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  withFieldIcon?: boolean;
  dateFormat?: string;
};

type State = {
  value: moment.MomentInput;
  localUpdateOnly: boolean;
};

type onChangeType = (...rest: any[]) => void;

class DatePicker extends React.Component<Props, State> {
  state = { value: '', localUpdateOnly: false };

  static defaultProps = {
    classes: {
      root: 'field-root',
      input: 'field',
    },
    errors: [],
    id: '',
    label: '',
    className: 'form-control',
    fullWidth: true,
    dateRange: [],
    withFieldIcon: false,
    dateFormat: BACKEND_DATE_FORMATE,
  };

  onChange: onChangeType = (value: moment.MomentInput) => {
    const momentValue = moment(value);
    const yearString = momentValue.year().toString();

    if (yearString.length > 4) {
      const newValue = momentValue.year(+yearString.substring(0, 4)).toDate();
      this.setState(
        {
          value: newValue,
          localUpdateOnly: true,
        },
        this.onChangeGlobal,
      );
      return;
    }

    this.setState({ value, localUpdateOnly: true }, this.onChangeGlobal);
  };

  onChangeGlobal() {
    const { onChange, dateFormat, name } = this.props;
    const momentValue = moment(this.state.value);
    const value = momentValue.isValid() ? momentValue.format(dateFormat) : null;

    this.setState({ localUpdateOnly: false }, () => {
      onChange({ name, value });
    });
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    const { dateFormat, value } = props;

    if (state.localUpdateOnly) {
      return null;
    }

    if (!value) {
      return { value: null };
    }

    const propsMomentValue = moment(value, dateFormat);
    return { value: propsMomentValue.toDate() };
  }

  restrictionRange = (): {
    minDate?: any;
    maxDate?: any;
  } => {
    const { dateRange } = this.props;
    const [minDate, maxDate] = dateRange || [];
    let restrictionRange = {};

    if ((dateRange || []).length === 2) {
      restrictionRange = {
        minDate,
        maxDate,
      };
    }

    if (minDate) {
      restrictionRange = {
        ...restrictionRange,
        minDate,
      };
    }

    if (maxDate) {
      restrictionRange = {
        ...restrictionRange,
        maxDate,
      };
    }

    return restrictionRange;
  };

  // Necessary to downcase Y|D letters for suitable for react-date-picker format
  format = (): string => {
    return commonDateFormats.shortDate().replace(/(Y|D)/g, (match: string) => {
      return match.toLowerCase();
    });
  };

  inputProps = () => {
    const { classes, name, errors, type, id, label, className, fullWidth, withFieldIcon } = this.props;
    return { classes, name, errors, type, id, label, className, fullWidth, withFieldIcon };
  };

  render() {
    const { value } = this.state;
    // Nice one
    const anyValue: any = value;

    return (
      <ReactDatePicker
        selected={anyValue}
        onChange={this.onChange}
        dateFormat={this.format()}
        customInput={<Input {...this.inputProps()} />}
        {...this.restrictionRange()}
      />
    );
  }
}

export { DatePicker };

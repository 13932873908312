import React from 'react';

import { Button } from 'components/core';

import { t } from 'utils';

import { openNewTaskForm } from '../../actions';

type Props = {};

const ActionsComponent = () => {
  const opeModal = () => {
    openNewTaskForm();
  };

  return (
    <Button type='add' onClick={opeModal}>
      {t.staticAsString('supportTaskManager.list.reportATask')}
    </Button>
  );
};

const Actions = React.memo<Props>(ActionsComponent);

export { Actions };

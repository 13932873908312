import { fontFamilyRoboto } from 'src/components/Form/Fields/FileUploader/style';
import { Marker } from 'src/modules/Distances/components/shared/Steps/GPX/types';

export const getMarker = (map, maps, markerType: Marker, isLabelRight: boolean) => {
  const { label, coordinate } = markerType;
  const { lng, lat } = coordinate;

  const labelOrigin = isLabelRight ? new google.maps.Point(3, 0) : new google.maps.Point(0, 0);

  const marker = new maps.Marker({
    position: coordinate,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 10,
      strokeColor: '#DDA100',
      strokeOpacity: 1.0,
      fillOpacity: 1.0,
      fillColor: '#FFFFFF',
      strokeWeight: 4,
      labelOrigin,
    },
    label: {
      text: label,
      color: '#252A2F',
      fontSize: '14px',
      fontFamily: fontFamilyRoboto,
    },
    draggable: false,
  });

  marker.setMap(lng === 0 && lat === 0 ? null : map);

  return marker;
};

import TableBody from '@mui/material/TableBody';
import { isEmpty } from 'lodash';
import { observer, inject } from 'mobx-react';
import { LOAD_RACES, RACE_TYPE } from 'modules/Races/constants';
import * as React from 'react';

import { ROUTES } from 'src/constants';

import { loadable, withProgressSpinner } from 'hocs';

import { Show } from 'components/Condition';
import { Table } from 'components/Table';
import { TableEmptyListNotification } from 'components/TableEmptyListNotification';

import { history, t } from 'utils';

import { sportTypeLoadServiceAuxiliary } from 'services';

import { Race as RaceStore, Locale as LocalStore, Session as SessionStore } from 'stores';

import loadService from '../../services/load';

import { FilterLabelToolbar } from './FilterLabelToolbar';
import { Item } from './Item';
import { Pagination } from './Pagination';
import { THead } from './THead';
import { Toolbar } from './Toolbar';

type Props = {
  racesStore: RaceStore;
  localeStore: LocalStore;
  sessionStore: SessionStore;
};

@withProgressSpinner([LOAD_RACES])
@loadable({ service: loadService })
@loadable({ service: sportTypeLoadServiceAuxiliary, cleanOnUnmount: true })
@inject('racesStore', 'localeStore', 'sessionStore')
@observer
class List extends React.Component<Props> {
  static defaultProps = {
    racesStore: null as any,
    localeStore: null as any,
    sessionStore: null as any,
  };

  componentWillUnmount() {}

  items = (): Array<React.ReactNode> => {
    return this.props.racesStore.modelValues.map((race) => <Item key={race.value.id} item={race} />);
  };

  onAddNewRace = (e: React.SyntheticEvent) => {
    history.push(ROUTES.newRace);
  };

  onAddNewCalendarRace = (e: React.SyntheticEvent) => {
    history.push(`${ROUTES.newRace}?type=${RACE_TYPE.calendar}`);
  };

  render() {
    const { racesStore, sessionStore } = this.props;

    const racesList = racesStore.modelValues;
    const organizerVerified = sessionStore.isOrganizerVerified;

    return (
      <div className='content main-container-list races-list'>
        <div className='toolbar-container'>
          <Toolbar onAddNewRace={this.onAddNewRace} onAddNewCalendarRace={this.onAddNewCalendarRace} />
          <FilterLabelToolbar />
        </div>
        <div className='component-list'>
          <Show if={!isEmpty(racesList)}>
            <Table className='table-list'>
              <THead />
              <TableBody>{this.items()}</TableBody>
            </Table>
          </Show>
          <Show if={isEmpty(racesList)}>
            <TableEmptyListNotification text={t.staticAsString('races.noCreatedRaces' as TranslationLockedKeys)} />
          </Show>
          <Pagination racesStore={racesStore} switchedOnTop={organizerVerified} />
        </div>
      </div>
    );
  }
}

export { List };

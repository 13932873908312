import { progressStore } from 'src/stores';

import { EQ_SETTINGS_INIT } from '../../constants';

import { racesService } from '../../services';

import { racesStore, racesParamsStore, racesHasMoreStore, stepStore, disposersStore } from '../../stores';

import { observeDistanceChange } from '../../reactions';

export const mount = async () => {
  progressStore.log(EQ_SETTINGS_INIT, 'progress');

  await racesService.getRaces();

  observeDistanceChange();

  progressStore.log(EQ_SETTINGS_INIT, 'completed');
};

export const unmount = () => {
  racesStore.clear();
  racesParamsStore.clear();
  racesHasMoreStore.clear();
  stepStore.clear();

  disposersStore.disposeAll();
};

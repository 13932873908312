import { unset, get } from 'lodash';
import { observable, makeObservable, action } from 'mobx';

import { errors as errorsUtil } from 'utils';

import { ApiError, ValidationError } from 'models';

class Errors {
  // @deprecated, since erroribus
  @observable
  errors: ErrorsType = {
    validation: {},
    api: {},
  };

  @observable
  erroribus: {
    [K in string]: AnyObject | null;
  } = {};

  constructor() {
    makeObservable(this);
  }

  // @deprecated, since erroribus
  @action
  addApiError(action: string, response: AxiosResponse, root: string): void {
    this.errors.api[action] = new ApiError(action, response, root);
  }

  // @deprecated, since erroribus
  @action
  addFrontendError(
    action: string,
    errors: {
      [K in string]: Array<string>;
    },
    object?: {
      [K in any]: any;
    },
  ): void {
    this.errors.validation[action] = new ValidationError(action, errors, object);
  }

  // @deprecated, since erroribus
  @action
  clearFrontendError(action: string) {
    if (this.errors.validation[action] !== undefined) {
      const validationErrs = { ...this.errors.validation };
      delete validationErrs[action];
      this.errors.validation = validationErrs;
    }
  }

  // @deprecated, since erroribus
  @action
  clearError(action: string): void {
    const apiErr = { ...this.errors.api };
    const validationErr = { ...this.errors.validation };
    delete apiErr[action];
    delete validationErr[action];
    this.errors.api = apiErr;
    this.errors.validation = validationErr;
  }

  // @deprecated, since erroribus
  getFormattedErrors(action: string):
    | {
        [K in string]: Array<string>;
      }
    | null {
    const errors = this.errors.api[action];
    const validationErrors = this.errors.validation[action];
    if (validationErrors) {
      return validationErrors.formatErrors();
    }
    if (errors) {
      return errors.formatErrors();
    }
    // @ts-ignore
    return;
  }

  @action
  add(action: string, values: RawError) {
    const oldValues = this.erroribus[action] || {};
    this.erroribus[action] = {
      ...errorsUtil.mergeValues(oldValues, errorsUtil.unfold(values)),
    };
  }

  @action
  clean(action: string, ...keys: Array<string>) {
    const values = this.erroribus[action];
    keys.forEach((key) => {
      unset(values, key);
    });

    this.erroribus[action] = values;
  }

  @action
  clear(action: string) {
    this.erroribus[action] = null;
  }

  get(action: string, key: string) {
    return get(this.erroribus[action] || {}, key);
  }

  getAll(formId: string) {
    return get(this.erroribus, formId);
  }

  getFirstErrorInputName(formId: string) {
    return Object.keys(get(this.erroribus, formId) || {})[0];
  }

  build(action: string): nil | ValidationError {
    const errs = this.erroribus[action];

    if (!errs) {
      return null;
    }

    return new ValidationError(action, errs);
  }
}

export { Errors };
export default new Errors();

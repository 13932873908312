import { progressStore } from 'stores';

import { DASHBOARD_STATES } from '../constants/dashboard.constants';

import { DashboardService } from '../services/dashboard.service';
import { dashboardRaceService } from '../services/races.service';

import { DashboardStore } from '../stores/dashboard.store';

export async function loadAge(range: number, forceLoad = false, skipRaces = false) {
  if (forceLoad || !DashboardStore.age.value) {
    if (!skipRaces) {
      await dashboardRaceService.loadRaces(true, 1);
    }
    try {
      progressStore.log(DASHBOARD_STATES.AGE_LOADING, 'progress');
      await DashboardService.loadAgeData(range);
    } catch (error) {
    } finally {
      progressStore.log(DASHBOARD_STATES.AGE_LOADING, 'completed');
    }
  }
}

import { DummyStore } from 'stores';

import { couponFormDistanceStore } from './distances';
import { couponFormModalStore } from './modal.store';
import { couponFormRacesStore } from './races';

export * from './distances';
export * from './races';
export * from './modal.store';
export * from './disposer.store';

export const couponFormSelectedRaceStore = new DummyStore<RaceType>(null);

export function clearAllCouponFormStores() {
  couponFormModalStore.clear();
  couponFormRacesStore.clear();
  couponFormSelectedRaceStore.clear();
  couponFormDistanceStore.clear();
}

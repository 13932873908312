import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import * as React from 'react';

type Props = {
  label: string;
  children: React.ReactNode;
  errors: Array<string>;
  classes?: { [Key in string]: string };
  focused?: boolean;
};

export function RadioGroup({ label, children, errors = [], classes = {}, focused }: Props) {
  const isError = !!errors.length;
  return (
    <FormControl required error={isError} component='fieldset' classes={{ root: classes.root }}>
      <FormLabel
        focused={focused}
        classes={{
          root: classes.label,
          error: classes.error,
        }}
      >
        {label}
      </FormLabel>
      {children}
      <FormHelperText>{errors.join('. ')}</FormHelperText>
    </FormControl>
  );
}

import { uniqBy } from 'lodash';
import { action, observable, makeObservable } from 'mobx';

const defaultValues = {
  params: {
    page: 1,
    limit: 10,
  },
};

export class Distances {
  @observable distances: Array<SelectOption> = [];

  @observable params: AnyObject = defaultValues.params;

  @observable hasMore: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action
  setDistances(data: Array<SelectOption>): void {
    this.distances = data;
  }

  @action
  appendDistances(data: Array<SelectOption>): void {
    this.distances = uniqBy([...this.distances, ...data], 'value');
  }

  @action
  setParams(params: Object): void {
    this.params = {
      ...this.params,
      ...params,
    };
  }

  @action
  setHasMore(hasMore: boolean = true): void {
    this.hasMore = hasMore;
  }

  @action
  clear(): void {
    this.distances = [];
    this.params = defaultValues.params;
    this.hasMore = true;
  }
}

const couponFormDistanceStore = new Distances();
export { couponFormDistanceStore };

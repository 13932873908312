import { GOAL_TYPE } from 'src/constants';

class Goal {
  value: GoalType;

  constructor(value: GoalType) {
    this.value = value;
  }

  isGoalType(type: 'distance' | 'altitude') {
    switch (type) {
      case 'distance':
        return this.value.goal_type === GOAL_TYPE.DISTANCE;
      case 'altitude':
        return this.value.goal_type === GOAL_TYPE.ALTITUDE;
      default:
        return false;
    }
  }
}

export { Goal, Goal as GoalModel };

import * as React from 'react';

import { defaultListFilters } from 'utils';

export const created_at: FilterListDescriptor = {
  filter: defaultListFilters.dateRange,
  frontend: { type: 'dateRange' },
  sort: {
    default: false,
    reorderOnSearch: true,
    defaultBy: 'ASC',
    calcOrderBy: () => 'id',
  },
};

import { Observer } from 'mobx-react';
import { generateOnExportReceipt } from 'modules/exportPayment/actions';
import * as React from 'react';

import { Payments } from '../components/Detail/Payments';

import { loadMore } from '../actions';

import { paymentsStore } from '../stores';

const onExportPayment = (_e: React.SyntheticEvent, id: number, url: string) => {
  generateOnExportReceipt(id, url)();
};

let PaymentsCont = () => {
  return (
    <Observer>
      {() => {
        return (
          <Payments
            paginationMeta={paymentsStore.paginationMeta}
            page={paymentsStore.page}
            values={paymentsStore.payments}
            onExport={onExportPayment}
            loadMore={loadMore}
          />
        );
      }}
    </Observer>
  );
};

export { PaymentsCont as Payments };

import { set } from 'lodash';

class App {
  init() {
    window.app = {};
  }

  reload() {
    window.location.reload();
  }

  stub() {}

  assign(path: string, value: any) {
    return set(window.app, path, value);
  }

  initDownloadTab() {
    window.downloadWindow = window.open();
  }
}

const app = new App();
export { App, app };

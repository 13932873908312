import { storeKey } from './constants';
import { init } from './init';

type Origins = 'loc' | 'dev' | 'stage' | 'prod' | 'sfe' | 'sfe2';

function forceSet(val: Origins) {
  localStorage.setItem(storeKey, val);
  init();
}

function forceReset() {
  localStorage.removeItem(storeKey);
  init();
}

export { forceSet, forceReset };

import { CrossGray } from './CrossGray';
import { Info } from './Info';
import { NavigateLeft } from './NavigateLeft';
import { NavigateRight } from './NavigateRight';
import { OutlinePlus } from './OutlinedPlus';
import { Trash } from './Trash';

export const Icons = {
  NavigateLeft,
  NavigateRight,
  CrossGray,
  Info,
  OutlinePlus,
  Trash,
};

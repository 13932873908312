import React from 'react';

import { t } from 'utils';

type OriginalProps = {};

type Props = OriginalProps & HOC.withIntl;

let TitleImpl = ({}: Props) => {
  return <h1 className='title'>{t.staticAsString('racers.list.header')}</h1>;
};
export const Title = TitleImpl as unknown as React.ComponentType<OriginalProps>;

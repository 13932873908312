import axios from 'axios';
import { duplicateDataType } from 'modules/RaceDetails/types';
import { generatePath } from 'react-router-dom';

import {
  RACE_URL,
  RACES_URL,
  APPROVE_RACE_URL,
  AWAITING_APPROVE_RACE_URL,
  REJECT_RACE_URL,
  PUBLISH_RACE_URL,
  UNPUBLISH_RACE_URL,
  DUPLICATE_RACE_URL,
} from 'src/constants';

import { request, action } from 'utils';

import {
  DELETE_RACE,
  REJECT_RACE,
  APPROVE_RACE,
  DUPLICATE_RACE,
  GET_RACE_DETAILS,
  HIDE_RESULTS_RACE,
  TOGGLE_PUBLISH_RACE,
  HIDE_START_LIST_RACE,
  AWAITING_APPROVE_RACE,
  TOGGLE_REGISTRATION_RACE,
  UPDATE_RACE_EXTERNAL_LINK,
} from '../constants';

import { raceDetailsStore } from '../stores';

class RaceDetails {
  @request({ action: GET_RACE_DETAILS })
  async loadRaceDetailsRequest(id: number): Promise<any> {
    const url = generatePath(RACE_URL, { id });

    const params = raceDetailsStore.resourceParams;

    return axios.get(url, { params });
  }

  @action({ action: GET_RACE_DETAILS })
  async loadRaceDetails(id: number): Promise<any> {
    const [status, response] = await this.loadRaceDetailsRequest(id);

    if (status) {
      return response.data.data;
    }
  }

  @request({ action: DELETE_RACE })
  deleteRaceDetailsRequest(id: number): Promise<any> {
    return axios.delete(`${RACES_URL}/${id}`);
  }

  @action({ action: DELETE_RACE })
  async deleteRaceDetails(): Promise<any> {
    const id = raceDetailsStore.raceDetails?.value.id;

    if (!id) {
      return;
    }

    const [status] = await this.deleteRaceDetailsRequest(id);

    return status;
  }

  @request({ action: TOGGLE_PUBLISH_RACE })
  async publishRaceRequest(id: number): Promise<any> {
    const url = generatePath(PUBLISH_RACE_URL, { id });

    return axios.post(url);
  }

  @request({ action: TOGGLE_PUBLISH_RACE })
  async unpublishRaceRequest(id: number): Promise<any> {
    const url = generatePath(UNPUBLISH_RACE_URL, { id });

    return axios.post(url);
  }

  @action({ action: TOGGLE_PUBLISH_RACE, minRequestTime: 1000 })
  async togglePublish(): Promise<Array<any>> {
    const id = raceDetailsStore.raceDetails?.value.id;
    const shouldPublish = raceDetailsStore.raceDetails?.value.published;

    if (!id) {
      return [];
    }

    let mobxResponse;
    if (shouldPublish) {
      mobxResponse = await this.publishRaceRequest(id);
    } else {
      mobxResponse = await this.unpublishRaceRequest(id);
    }

    const [status, response] = mobxResponse;

    return [status, response.data.published];
  }

  @request({ action: DUPLICATE_RACE })
  duplicateRaceRequest(id: number, data: duplicateDataType): Promise<any> {
    const url = generatePath(DUPLICATE_RACE_URL, { id });
    return axios.post(url, data);
  }

  @action({ action: DUPLICATE_RACE, minRequestTime: 1000 })
  async duplicateRace(data: duplicateDataType): Promise<any> {
    const id = raceDetailsStore.raceDetails?.value.id;

    if (!id) {
      return;
    }

    const [status, response] = await this.duplicateRaceRequest(id, data);
    if (status) {
      return response.data.data.id;
    }
  }

  @request({ action: HIDE_START_LIST_RACE })
  toggleStartListVisibilitytRequest(id: number, params: FiltersType): Promise<any> {
    return axios.patch(`${RACES_URL}/${id}`, params);
  }

  @action({ action: HIDE_START_LIST_RACE })
  async toggleStartListVisibility(id: number, params: FiltersType): Promise<Array<any>> {
    const [status, response] = await this.toggleStartListVisibilitytRequest(id, params);

    return [status, response.data.data.show_startlist];
  }

  @request({ action: HIDE_RESULTS_RACE })
  toggleResultsVisibilityRequest(id: number, params: FiltersType): Promise<any> {
    return axios.patch(`${RACES_URL}/${id}`, params);
  }

  @action({ action: HIDE_RESULTS_RACE })
  async toggleResultsVisibility(id: number, params: FiltersType): Promise<Array<any>> {
    const [status, response] = await this.toggleResultsVisibilityRequest(id, params);

    return [status, response.data.data.show_results];
  }

  @request({ action: TOGGLE_REGISTRATION_RACE })
  toggleRegistrationRequest(id: number, params: FiltersType): Promise<any> {
    const url = generatePath(RACE_URL, { id });

    return axios.patch(url, params);
  }

  @action({ action: TOGGLE_REGISTRATION_RACE })
  async toggleRegistration(id: number, params: FiltersType): Promise<Array<any>> {
    const [status, response] = await this.toggleRegistrationRequest(id, params);

    return [status, response.data.data.allow_registration];
  }

  @request({ action: UPDATE_RACE_EXTERNAL_LINK })
  updateRaceExternalLinkRequest(id: number, params: FiltersType): Promise<any> {
    const url = generatePath(RACE_URL, { id });

    return axios.patch(url, params);
  }

  @action({ action: UPDATE_RACE_EXTERNAL_LINK })
  async updateRaceExternalLink(id: number, params: FiltersType): Promise<Array<any>> {
    const [status, response] = await this.updateRaceExternalLinkRequest(id, params);

    return [status, response.data.data.race_url];
  }

  @request({ action: APPROVE_RACE })
  approveRaceRequest(id: number): Promise<any> {
    const url = generatePath(APPROVE_RACE_URL, { id });

    return axios.post(url);
  }

  @action({ action: APPROVE_RACE })
  async approveRace(): Promise<Array<any>> {
    const id = raceDetailsStore.raceDetails?.value.id;

    if (!id) {
      return [];
    }

    const [status] = await this.approveRaceRequest(id);

    return [status];
  }

  @request({ action: AWAITING_APPROVE_RACE })
  awaitApproveRaceRequest(id: number): Promise<any> {
    const url = generatePath(AWAITING_APPROVE_RACE_URL, { id });

    return axios.post(url);
  }

  @action({ action: AWAITING_APPROVE_RACE })
  async awaitApproveRace(): Promise<Array<any>> {
    const id = raceDetailsStore.raceDetails?.value.id;

    if (!id) {
      return [];
    }

    const [status] = await this.awaitApproveRaceRequest(id);

    return [status];
  }

  @request({ action: REJECT_RACE })
  rejectRaceRequest(id: number, reason: string): Promise<any> {
    const url = generatePath(REJECT_RACE_URL, { id });

    return axios.post(url, { reason });
  }

  @action({ action: REJECT_RACE })
  async rejectRace(reason: string): Promise<Array<any>> {
    const id = raceDetailsStore.raceDetails?.value.id;

    if (!id) {
      return [];
    }

    const [status, response] = await this.rejectRaceRequest(id, reason);

    return [status, response.data];
  }
}

const raceDetailsService = new RaceDetails();

export { raceDetailsService };

import { ReportConstants } from '../constants/report.constants';

import { InvoiceStatement, Report, VatStatementSummary } from '../types/report.type';

export namespace ReportMappers {
  export function fromBackend(report: Report) {
    report.sum = report.sum / 100;

    // Map payment_overview
    report.payment_overview.statement.amountTotal = report.payment_overview.statement.amountTotal / 100;
    report.payment_overview.statement.platformFeesAndDeductions = report.payment_overview.statement.platformFeesAndDeductions / 100;
    report.payment_overview.statement.refundsAmount = report.payment_overview.statement.refundsAmount / 100;
    report.payment_overview.statement.totalPayoutSum = report.payment_overview.statement.totalPayoutSum / 100;
    report.payment_overview.statement.transactionsAmount = report.payment_overview.statement.transactionsAmount / 100;
    report.payment_overview.transactions_total_sum = report.payment_overview.transactions_total_sum / 100;
    report.payment_overview.transactions_report.distanceItems.forEach((item) => {
      item.totalExcludingVAT = item.totalExcludingVAT / 100;
      item.totalIncludingVat = item.totalIncludingVat / 100;
      item.vatPercentage = item.vatPercentage / 100;
      item.vatSum = item.vatSum / 100;
    });

    if (!Array.isArray(report.invoice.platform_fees)) {
      report.invoice.platform_fees = [];
    }

    if (!Array.isArray(report.invoice.platform_fees_vat)) {
      report.invoice.platform_fees_vat = [];
    }

    // ! Temporarily set until further clarification
    if (Array.isArray(report.invoice.invoice_statement)) {
      report.invoice.invoice_statement = {} as InvoiceStatement;
    }

    // ! Temporarily set until further clarification
    if (Array.isArray(report.vat_report.vat_statement_summary)) {
      report.vat_report.vat_statement_summary = {} as VatStatementSummary;
    }

    report.invoice.platform_fees_total = report.invoice.platform_fees_total / 100;
    report.invoice.platform_fees.forEach((item) => {
      item.totalIncVat = (item.total + item.vatSum) / 100;
      item.amount = item.unit === ReportConstants.COMMISSION_UNIT ? item.amount / 100 : item.amount;
      item.total = item.total / 100;
      item.pricePerUnit = item.pricePerUnit / 100;
      item.vatSum = (item.vatSum || 0) / 100;
      item.vatPercent = (item.vatPercent || 0) / 100;
      // item.totalIncVat = (item.totalIncVat || 0) / 100;
    });

    report.invoice.platform_fees_vat.forEach((item) => {
      item.total = item.total / 100;
      item.total_excluding_vat = item.total_excluding_vat / 100;
      item.vat_amount = item.vat_amount / 100;
      item.vat_value = item.vat_value / 100;
    });

    report.transactions_list.transactions.forEach((item) => {
      item.transactionAmount = item.transactionAmount / 100;
      item.transactionVat = item.transactionVat / 100;
    });

    report.vat_report.platform_invoice_vat_summary.forEach((item) => {
      item.vatAmount = item.vatAmount / 100;
      item.grossAmount = item.grossAmount / 100;
      item.netAmount = item.netAmount / 100;
      item.vatValue = item.vatValue / 100;
    });

    report.vat_report.transactions_vat_summary.forEach((item) => {
      item.vatAmount = item.vatAmount / 100;
      item.grossAmount = item.grossAmount / 100;
      item.netAmount = item.netAmount / 100;
      item.vatValue = item.vatValue / 100;
    });

    report.vat_report.refunds_vat_summary.forEach((item) => {
      item.vatAmount = item.vatAmount / 100;
      item.grossAmount = item.grossAmount / 100;
      item.netAmount = item.netAmount / 100;
      item.vatValue = item.vatValue / 100;
    });

    report.payment_overview.transactions_report.raceIDExtrasItems.forEach((item) => {
      item.totalExcludingVAT = item.totalExcludingVAT / 100;
      item.totalIncludingVat = item.totalIncludingVat / 100;
      // ! Temporarily disable until further clarification
      item.vatPercentage = item.vatPercentage / 100;
      item.vatSum = item.vatSum / 100;
    });

    report.payment_overview.transactions_report.refundItems.forEach((item) => {
      item.totalExcludingVAT = item.totalExcludingVAT / 100;
      item.totalIncludingVat = item.totalIncludingVat / 100;
      item.vatPercentage = item.vatPercentage / 100;
      item.vatSum = item.vatSum / 100;
    });

    return report;
  }
}

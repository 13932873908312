import { Observer } from 'mobx-react';
import * as React from 'react';

import { translate } from 'utils';

export type processTextType = (value: string) => string;

type Props = {
  id: TranslationLockedKeys;
  params?: AnyObject;
  processText?: processTextType;
};

function StaticTranslationComponent({ id, params, processText }: Props) {
  return (
    <Observer>
      {() => {
        const message = translate(id, params) || id;
        const processedMessage = processText && processText(message);
        return <>{processedMessage || message}</>;
      }}
    </Observer>
  );
}

const StaticTranslation = React.memo<Props>(StaticTranslationComponent);

function staticTranslation(id: TranslationLockedKeys, params?: Object, processText?: processTextType) {
  return <StaticTranslation id={id} params={params} processText={processText} />;
}

export { StaticTranslation, staticTranslation };

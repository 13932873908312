import { t } from 'utils';

import { generateMomentRangeFor } from '../utils';

import { dateCols } from '../types';

type OptionsType = Options | any;

type Options = {
  required: boolean | any;
  allowRegisrationStartPastToday: boolean;
};

function messages(name: dateCols, fromField: dateCols | nil, toField: dateCols | nil) {
  return {
    fromField: t.staticAsString(`distances.validation.fieldname.${fromField || ''}` as TranslationLockedKeys) || fromField,
    toField: t.staticAsString(`distances.validation.fieldname.${toField || ''}` as TranslationLockedKeys) || toField,
    name: t.staticAsString(`distances.validation.fieldname.${name || ''}` as TranslationLockedKeys) || name,
    fromOverlapMessage: `distances.validation.errors.${name || ''}.from`,
    toOverlapMessage: `distances.validation.errors.${name || ''}.to`,
  };
}

function generateDateValidation(name: dateCols, object: DistanceType, options: OptionsType) {
  const { required } = options;
  const restrictionCols = generateMomentRangeFor(name, object);

  if (!restrictionCols) {
    return {};
  }

  const presenceContrains = required ? { presence: { allowEmpty: false } } : {};

  return {
    ...presenceContrains,
    rangeDateTime: {
      earlierOrEqualTo: restrictionCols.fromValue,
      laterOrEqualTo: restrictionCols.toValue,
      ...messages(name, restrictionCols.fromCol, restrictionCols.toCol),
    },
  };
}

export { generateDateValidation };

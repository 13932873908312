import axios from 'axios';

import { IMAGE_UPLOAD, FILES_URL } from 'src/constants';

import { request, action } from 'utils';

export class ImageUpload {
  @request({ action: IMAGE_UPLOAD })
  imageUploadRequest(value: FormData): Promise<any> {
    return axios.post(FILES_URL, value);
  }

  @action({ action: IMAGE_UPLOAD })
  async imageUpload(value: Blob): Promise<string> {
    const [status, response] = await this.imageUploadRequest(this.formatData(value));
    if (status) {
      return response.data.data?.uuid;
    } else {
      return '';
    }
  }

  formatData(value: Blob): FormData {
    const formData = new FormData();
    formData.append('file', value);
    return formData;
  }
}

export const helperImageUploadService = new ImageUpload();

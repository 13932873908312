import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactElement } from 'react';

import { CheckMark } from 'components/CheckMark';
import { Show } from 'components/Condition';
import { InputWrapper, TextEditor } from 'components/Form';

import { getFormNameKey } from '../../utils';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
  },
  flexItem: {
    flex: 1,
    position: 'relative',
    '>> body': {
      background: 'blue',
    },
  },
  checkMark: {
    position: 'absolute',
    top: 6,
    right: 6,
    zIndex: 1,
  },
}));

type Props = {
  prefilled: { [k in string]?: string };
  localesToSelect: availableLocales[];
  selectedLocale: availableLocales;
  defaultLocale: availableLocales;
  labels?: TranslationLabel;
  path: TranslationPath;
  savedFields: { [k in FieldName]?: boolean };
  onTranslationChange: Function;
};

export const HTMLEditor = ({
  localesToSelect,
  savedFields,
  labels,
  selectedLocale,
  path,
  onTranslationChange,
  prefilled,
  defaultLocale,
}: Props): ReactElement => {
  const classes = useStyles();

  const onCustomBlur = ({ name, value }: { name: string; value: string }) => {
    onTranslationChange({ name, value, blur: true });
  };

  return (
    <div className={classes.container}>
      <div className={classes.flexItem}>
        <InputWrapper
          name={getFormNameKey(path, defaultLocale)}
          settings={{
            additional: {
              previewMode: true,
            },
          }}
          Component={TextEditor}
        />
      </div>
      <div className={classes.flexItem}>
        {localesToSelect.map((locale) => (
          <Show if={selectedLocale === locale} key={locale}>
            <InputWrapper
              name={getFormNameKey(path, locale)}
              settings={{
                additional: {
                  onCustomBlur,
                  onInputChange: onTranslationChange,
                },
              }}
              Component={TextEditor}
            />
            <Show if={savedFields[getFormNameKey(path, locale)]}>
              <div className={classes.checkMark}>
                <CheckMark />
              </div>
            </Show>
          </Show>
        ))}
      </div>
    </div>
  );
};

import * as React from 'react';

const withoutHeader = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  class WithoutHeader extends React.Component<P> {
    componentDidMount() {
      const header = document.getElementsByTagName('header')[0];

      if (!header) {
        return;
      }

      header.style.display = 'none';
    }

    componentWillUnmount() {
      const header = document.getElementsByTagName('header')[0];

      if (!header) {
        return;
      }

      header.style.display = '';
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  } as unknown as void;

export { withoutHeader };

import { TextField } from '@mui/material';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';

import { FilterWrapper } from './FilterWrapper';

export const DefaultColumnFilter = ({ column: { filterValue, setFilter, render }, closeFilter = () => {} }) => {
  const [value, setValue] = React.useState(filterValue || '');

  const debounceSetFilter = useCallback(
    debounce((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFilter(e.target.value || undefined);
      closeFilter && closeFilter();
    }, 500),
    [],
  );
  const handleSetFilter = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setValue(e.target.value || undefined);
    debounceSetFilter(e);
  };

  return (
    <FilterWrapper>
      <TextField autoFocus fullWidth variant='outlined' size='small' value={value} label={render('Header')} onChange={handleSetFilter} />
    </FilterWrapper>
  );
};

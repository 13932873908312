import { isString } from 'lodash';
import moment from 'moment';

import { BE_FORMAT } from 'src/constants';

import { inputDate } from '../types';

function validateBeDate(value: inputDate, type: BEDateFormat): boolean {
  if (!value) {
    return false;
  }

  const dateFormat = BE_FORMAT[type];

  if (isString(value)) {
    return moment(value, dateFormat, true).isValid();
  }

  if (moment.isMoment(value)) {
    return value.isValid();
  }

  return false;
}

export { validateBeDate };

import classNames from 'classnames';
import { isNil } from 'lodash';
import * as React from 'react';

import { TextField } from '../TextField';
import { timeWithoutSecondsMask, formatMaskToTimeWithoutSeconds, formatTimeWithoutSecondsToMask } from '../core/mask';

type Props = FieldBaseProps & {
  additional?: TextFieldAdditionalProps;
  view?: BaseViewProps;
};

function TimeWithoutSeconds(props: Props) {
  const [localState, setState] = React.useState(formatTimeWithoutSecondsToMask(props.value));
  const [prevProps, setPrev] = React.useState(formatTimeWithoutSecondsToMask(null));

  if (prevProps !== props.value) {
    setPrev(props.value);
  }

  if (prevProps && isNil(props.value)) {
    setState(null as any);
  }

  return (
    <TextField
      {...props}
      additional={{
        InputProps: { inputComponent: timeWithoutSecondsMask },
        className: classNames('input-mask', props.className),
        ...(props.additional || {}),
      }}
      value={localState}
      onChange={({ name, value }, e) => {
        setState(value);
        props.onChange({ name, value: formatMaskToTimeWithoutSeconds(value) }, e);
      }}
    />
  );
}

export { TimeWithoutSeconds, TimeWithoutSeconds as TimeWithoutSecondsField };

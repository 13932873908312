import Box from '@mui/material/Box';
import { withStyles } from '@mui/styles';
import * as React from 'react';

import { withProgressSpinner } from 'hocs';

import { Breadcrumbs, ConfirmationProvider, Toolbar } from '../components';

import { INIT_INTEGRATIONS } from '../constants';

import { mount, unmount } from '../actions';

import { IntegrationsTable } from './IntegrationsTable';

const StyledBox = withStyles({
  root: {
    overflowY: 'auto',
    height: '100%',
  },
})(Box);

type Props = {};

@withProgressSpinner(INIT_INTEGRATIONS)
export class Integrations extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    mount();
  }

  componentWillUnmount(): void {
    unmount();
  }

  render() {
    return (
      <StyledBox>
        <ConfirmationProvider />
        <Breadcrumbs />
        <Toolbar />
        <IntegrationsTable />
      </StyledBox>
    );
  }
}

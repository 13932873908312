import { Moment } from 'moment';

import { BE_DATE_FORMAT } from 'src/constants/time';

import { currenciesStore } from 'stores';

import { CommissionTableFilters } from '../types/filters.type';

const currencies = {
  4: 'USD',
  1: 'SEK',
  8: 'NZD',
  3: 'NOK',
  7: 'GBP',
  2: 'EUR',
  6: 'CAD',
};

const IdFields = ['organizer.id'];
const DateFields = ['created_at', 'updated_at'];
const LikeFields = [
  'name',
  'is_failed',
  'commission_type',
  'provider_commission_id',
  'distance.translations.name',
  'full_name',
  'payment_info.racer.email',
  'payment_info.currency',
  'payment_info.racer.firstname',
  'payment_info.racer.lastname',
];
const BetweenFields = [
  'id',
  'created_time',
  'distance.race_parent_id',
  'vat_amount',
  'payment_info.discounted_amount',
  'payment_info.amount_for_custom_fields',
  'payment_info.amount',
  'payment_info.price_used',
];
export namespace CommissionFiltersMappers {
  export function toBackend(currentCurrencyTab: number, filters: CommissionTableFilters[]) {
    const searchFilters = filters.reduce(
      (acc, { id, value }) => {
        if (IdFields.includes(id)) {
          acc.search += `${id}:${value.value};`;
          acc.searchFields += `${id}:=;`;
        }
        if (DateFields.includes(id)) {
          acc.search += `${id}:${mapDaterange(value.startDate, value.endDate)};`;
          acc.searchFields += `${id}:between;`;
        }
        if (LikeFields.includes(id)) {
          if (id === 'payment_info.currency') value = currencies[Number(value)];
          acc.search += `${id}:${value};`;
          acc.searchFields += `${id}:like;`;
        }
        if (BetweenFields.includes(id)) {
          acc.search += `${id}:${value.join(',')};`;
          acc.searchFields += `${id}:between;`;
        }
        return acc;
      },
      {
        search: 'currency_id:' + currentCurrencyTab + ';',
        searchFields: 'currency_id:=;',
        searchJoin: 'AND',
      },
    );

    searchFilters.search = searchFilters.search.substring(0, searchFilters.search.length - 1);
    searchFilters.searchFields = searchFilters.searchFields.substring(0, searchFilters.searchFields.length - 1);
    return searchFilters;
  }
}

const mapDaterange = (start: Moment, end: Moment): string => {
  return `${start.format(BE_DATE_FORMAT)},${end.format(BE_DATE_FORMAT)}`;
};

import { isEmpty } from 'lodash';

import { errorsStore } from 'stores';

import { Validator } from '../validator';

/* DEPRECATED for future use
 * ANNOTATION
 * ASYNC validation
 * Writing frontend errors into store by action
 * reading object for validation from wrapped function
 */
export function validation(reqArgs: {
  action: string;
  constrains: {
    [K in any]: any;
  };
}) {
  reqArgs = { ...reqArgs };

  return function (target: any, propertyKey: string, descriptor: any): any {
    var oldValue = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      const action = reqArgs.action;
      const constrains = reqArgs.constrains;
      const object = oldValue.call(this, ...args);

      if (!constrains) {
        console.warn(`Empty validator object for action ${action}`);
      }

      const errors: any = Validator.validate(object, constrains);
      if (!!errors) {
        errorsStore.addFrontendError(action, errors, object);
      } else {
        errorsStore.clearFrontendError(action);
      }

      return !errors;
    };

    return descriptor;
  };
}

/* DEPRECATED for future use
 * ANNOTATION
 * SYNCHRONOUS validation
 * Writing frontend errors into store by action
 * reading object for validation from wrapped function
 */
export function synchronousValidation(reqArgs: {
  action: string;
  constrains: {
    [K in any]: any;
  };
}) {
  reqArgs = { ...reqArgs };

  return function (target: any, propertyKey: string, descriptor: any): any {
    var oldValue = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const action = reqArgs.action;
      const constrains = reqArgs.constrains;
      const object = oldValue.call(this, ...args);

      if (!constrains) {
        console.warn(`Empty validator object for action ${action}`);
      }

      const errors: any = Validator.validate(object, constrains);
      if (!!errors) {
        errorsStore.addFrontendError(action, errors, object);
      } else {
        errorsStore.clearFrontendError(action);
      }

      return !errors;
    };

    return descriptor;
  };
}

/* DEPRECATED for future use
 * ANNOTATION
 */
export function oneFieldValidation(
  action: string,
  object: {
    [K in any]: any;
  },
  constrains: {
    [K in any]: any;
  },
) {
  const errors: any = Validator.validate(object, constrains);

  if (!!errors) {
    errorsStore.addFrontendError(action, errors, object);
    return false;
  }

  errorsStore.clearFrontendError(action);
  return true;
}

export function newOneFieldValidation(action: string, object: { [k in any]: any }, constrains: { [k in any]: any }) {
  const errors: any = Validator.validate(object, constrains);

  if (!!errors) {
    errorsStore.add(action, errors);
    return false;
  }

  errorsStore.clean(action, ...Object.keys(constrains));
}

export function isOneFieldValid(action: string, fieldName: string) {
  return !Boolean(errorsStore.erroribus[action]?.[fieldName]);
}
export function isFormValid(action: string) {
  return isEmpty(errorsStore.erroribus[action]);
}

/*
 * Simple function for validation input data by scheme in some action
 */
export function validate(action: string, data: AnyObject, scheme: NestedValidationScheme): boolean {
  errorsStore.clear(action);
  const errors = Validator.validate(data, scheme);

  errors && errorsStore.add(action, errors as any);
  return isEmpty(errors);
}

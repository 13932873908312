import React from 'react';

import { Icon } from 'components/Icon';

type Props = {
  onClick: React.MouseEventHandler;
};

export const Tooltip = ({ onClick }: Props) => (
  <span className='draft-tooltip'>
    <Icon value='tooltip' className='icon' onClick={onClick} />
  </span>
);

export const RACE_RESULTS_FORM_ID = 'RACE_RESULTS_FORM_ID';

export const SETTINGS_STEP = 'SETTINGS_STEP';
export const SAVE_SYNC_STEP = 'SAVE_SYNC_STEP';

export const RACES_RR_REQUEST = 'RACES_RR_REQUEST';
export const CUSTOM_FIELDS_RR_REQUEST = 'CUSTOM_FIELDS_RR_REQUEST';
export const RR_MAPPING_INIT = 'RR_MAPPING_INIT';
export const RR_SETTINGS_INIT = 'RR_SETTINGS_INIT';
export const RACE_RESULTS_REQUEST = 'RACE_RESULTS_REQUEST';
export const RR_MAPPING_REQUEST = 'RR_MAPPING_REQUEST';
export const RR_MAPPING_LOAD = 'RR_MAPPING_LOAD';

import axios from 'axios';
import { DELETE_CUSTOMER, EXPORT_CUSTOMER, LOAD_CUSTOMER, LOAD_CUSTOMERS } from 'modules/Customers/constants';
import { generatePath } from 'react-router-dom';

import { CUSTOMERS_URL, CUSTOMER_EXPORT_URL, USER_URL } from 'src/constants';

import { request, action, t, monitorJobId } from 'utils';

import { LoadableService } from 'services';

import { customersStore, toastStore } from 'stores';
import { Customer as CustomerStore } from 'stores';

const DEFAULT_PAGE = 1;

class LoadService extends LoadableService<CustomerType, FiltersType, CustomerStore> {
  constructor(store: CustomerStore) {
    super({ store, resourcesUrl: CUSTOMERS_URL, resource: CUSTOMERS_URL });
  }

  @request({ action: LOAD_CUSTOMERS })
  async loadResourcesRequest(params: FiltersType): Promise<any> {
    return super.loadResourcesRequest(params);
  }

  @action({ action: LOAD_CUSTOMERS })
  async loadResources(params: FiltersType = {}, pageNum: number = 1): Promise<any> {
    return super.loadResources(params, pageNum);
  }

  @request({ action: LOAD_CUSTOMER })
  async loadResourceRequest(id: string, params: FiltersType): Promise<any> {
    return super.loadResourceRequest.bind(this)(id, params);
  }

  @action({ action: LOAD_CUSTOMER, minRequestTime: 800 })
  async loadResource(id: string, params?: FiltersType): Promise<any> {
    return super.loadResource.bind(this)(id, params);
  }

  @request({ action: DELETE_CUSTOMER })
  deleteUserRequest(id: number): Promise<any> {
    return axios.delete(generatePath(USER_URL, { id }));
  }

  @action({ action: DELETE_CUSTOMER })
  async deleteUser(id: number): Promise<any> {
    const [status, response] = await this.deleteUserRequest(id);

    if (!status) {
      toastStore.show(t.staticAsString('customers.delete.errorMessage'));
      return;
    }
    const { id: jobId } = response?.data?.data;
    const [monitorStatus] = await monitorJobId(jobId);

    if (!monitorStatus) {
      toastStore.show(t.staticAsString('customers.delete.errorMessage'));
      return;
    }

    this.loadResources();
    toastStore.show(t.staticAsString('customers.delete.successMessage'));
  }

  @request({ action: EXPORT_CUSTOMER })
  exportDataRequest(params: FiltersType): Promise<any> {
    return axios.get(CUSTOMER_EXPORT_URL, {
      params,
    });
  }

  @action({ action: EXPORT_CUSTOMER })
  async exportData(type: string): Promise<nil | Location> {
    const params = {
      ...this.store.filters,
      type,
    };
    const [status, response] = await this.exportDataRequest(params);

    if (status) {
      return response.data.url;
    }
  }

  async applySearch(callback: Function, ...searchParams: Array<FiltersType>) {
    const searchModel = this.store.retrieveSearchDataModel();

    searchParams.forEach((searchParam) => {
      searchModel.appendFilters(searchParam);
    });

    const paramsForStoring = searchModel.params() as any;
    searchModel.enableTrimEmptyArrayOption();
    const paramsForSending = searchModel.params();

    if (paramsForSending !== this.store.filters.search) {
      await this.loadResources(paramsForSending || { search: '', searchFields: '' }, DEFAULT_PAGE);
      callback();
    }

    if (paramsForStoring && paramsForStoring.search && paramsForStoring.searchFields) {
      this.store.updateSearch(paramsForStoring.search, paramsForStoring.searchFields);
    }
  }

  async deleteFilters(callback: Function, ...filterNames: Array<string>) {
    const searchModel = this.store.retrieveSearchDataModel();
    searchModel.deleteFilters(...filterNames);
    await this.loadResources(searchModel.params() || { search: '', searchFields: '' }, DEFAULT_PAGE);
  }

  clearFilters() {
    this.loadResources(
      {
        search: '',
        searchFields: '',
      },
      1,
    );
  }
}

export { LoadService };
export const loadService = new LoadService(customersStore);

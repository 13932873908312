import * as React from 'react';

type Props = {
  ratio: number;
  children: React.ReactNode;
  onClick?: (e: React.SyntheticEvent) => void;
};

export const AspectRatio = ({ ratio, children, onClick }: Props) => {
  const ratioCoefficient = `${(1 / ratio) * 100}%`;
  return (
    <div onClick={onClick} className='aspect-ratio' style={{ paddingBottom: ratioCoefficient }}>
      {children}
    </div>
  );
};

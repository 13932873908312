import { Integrations } from 'modules/Integrations';
import { RaceDetails } from 'modules/RaceDetails';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { UpdateRace } from './Edit';
import { List } from './List';
import { NewRace } from './New';

class Races extends React.Component<{}> {
  render() {
    return (
      <Switch>
        <Route path={ROUTES.newRace} component={NewRace} />
        <Route path={ROUTES.racesRoute} component={List} exact />
        <Route path={ROUTES.integrations} component={Integrations} />
        <Route path={ROUTES.raceEditRoute} component={UpdateRace} />
        <Route path={ROUTES.raceRoute} component={RaceDetails} />
      </Switch>
    );
  }
}

export { Races };

import { OrderStatus } from 'modules/Payments/constants';

import { errorsStore } from 'stores';

import { SUPPORT_TASK_MANAGER_FORM } from '../constants';

import { orderService } from '../services';

export const loadOrders = async (text: string | null = null) => {
  errorsStore.clear(SUPPORT_TASK_MANAGER_FORM);
  await orderService.getOrders(getParams(text));
};

const getParams = (text: string | null): FiltersType => {
  let search: string = `status:${OrderStatus.paid}`;

  if (text !== null) {
    search = `${search};id:${text}`;
  }

  return {
    filter: 'id;currency;created_at',
    search,
    searchFields: 'status:=;id:like',
    searchJoin: 'AND',
  };
};

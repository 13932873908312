import { Box, createFilterOptions } from '@mui/material';
import { Observer } from 'mobx-react';
import { loadAge } from 'modules/Dashboard/actions/age.action';
import { DashboardActions } from 'modules/Dashboard/actions/init';
import { loadParticipants } from 'modules/Dashboard/actions/participants.action';
import { loadRegistrations } from 'modules/Dashboard/actions/registrations.action';
import { loadTurnover } from 'modules/Dashboard/actions/turnover.action';
import { dashboardRaceService } from 'modules/Dashboard/services/races.service';
import { dashboardRacesStore } from 'modules/Dashboard/stores/races.store';

import { InputWrapper, AutocompleteSelectInfinityLoad, Form } from 'components/Form';

const filterOptions = createFilterOptions<any>({
  stringify: (el) => el.label,
});

type Props = {
  collection?: 'registrations' | 'turnover' | 'participants' | 'age';
};

export default function RaceSearch({ collection }: Props) {
  const handleAfterSelect = (race) => {
    dashboardRacesStore.selectRace(race);
    switch (collection) {
      case 'age':
        loadAge(2, true, true);
        break;
      case 'participants':
        loadParticipants(true, true);
        break;
      case 'turnover':
        loadTurnover(true, true);
        break;
      case 'registrations':
        loadRegistrations(true, true);
        break;
      default:
        DashboardActions.loadAll();
        break;
    }
  };

  return (
    <Observer
      render={() => {
        const loadMoreRaces = () => {
          dashboardRaceService.loadRaces();
        };
        const searchRaces = (text: string) => {
          // dashboardRacesStore.clear();
          dashboardRacesStore.setParams({
            page: 1,
            search: `name:${text}`,
            searchFields: `name:like`,
          });
          dashboardRaceService.loadRaces();
        };

        return (
          <Box sx={{ width: '400px' }}>
            <InputWrapper
              name='race_id'
              settings={{
                additional: {
                  fullWidth: true,
                  options: dashboardRacesStore.races.map((r) => ({ id: r.id, key: r.id, label: r.name })),
                  filterOptions,
                },
                view: {
                  required: true,
                  variant: 'outlined',
                },
                disableClearable: true,
                afterSelect: handleAfterSelect,
                hasMore: dashboardRacesStore.hasMore,
                loadMore: loadMoreRaces,
                onSearch: searchRaces,
              }}
              Component={AutocompleteSelectInfinityLoad}
            />
          </Box>
        );
      }}
    />
  );
}

import { observer } from 'mobx-react';
import * as React from 'react';

import { Settings as SettingsComponent } from '../../components/Settings';

import { resetResults, toogleResultsVisibility } from '../../actions';

import { ResultsContext } from '../../context';

@observer
class Settings extends React.Component<{}> {
  static contextType = ResultsContext;
  declare context: React.ContextType<typeof ResultsContext>;

  isResultsResetable(): boolean {
    const distanceModel = this.context.results.stores.distanceStore.modelSelected;
    if (!distanceModel) {
      return false;
    }

    const distanceType = distanceModel.value.type;

    const store = this.context.results[distanceType].store;
    return !!store.paginationMeta.total;
  }

  isResultsShown(): boolean {
    const distanceModel = this.context.results.stores.distanceStore.modelSelected;
    if (!distanceModel) {
      return false;
    }

    return !!distanceModel.value.show_results;
  }

  distanceTitle = (): string => {
    const distanceModel = this.context.results.stores.distanceStore.modelSelected;
    return (distanceModel && distanceModel.name()) || '';
  };

  isExternalRegistration = (): boolean => {
    const raceModel = this.context.results.stores.racesStore.selected;

    return !!raceModel?.race_url;
  };

  render() {
    return (
      <SettingsComponent
        isResultsResetable={this.isResultsResetable()}
        isResultsShown={this.isResultsShown()}
        onResetResults={resetResults}
        onToggleResults={toogleResultsVisibility}
        title={this.distanceTitle()}
        isToggleResultsShown={!this.isExternalRegistration()}
      />
    );
  }
}

export { Settings };

import { scrollToErrorField } from 'modules/Distances/actions';

import { errorsStore } from 'stores';

import { DISTANCE_FORM_ERRORS } from '../../constants';

function _addErrors(errors: AnyObject) {
  errorsStore.add(DISTANCE_FORM_ERRORS, errors);
  scrollToErrorField();
}

export { _addErrors };

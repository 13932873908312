import { Box, TextField } from '@mui/material';
import { formatCurrency } from 'modules/Reconciliation/shared/utils/formatCurrency';
import moment from 'moment';
import { ColumnWithLooseAccessor } from 'react-table';

import { DatePicker } from 'components/Form/Fields';

import { t } from 'utils/t';

import { Order } from '../../types';

const COLUMNS = ['totalSum', 'discountSum', 'firstName', 'lastName', 'email', 'createdAt', 'updatedAt'] as const;

const title = (prefix: string) => t.staticAsString(`reconciliation.transactions.${prefix}` as any);
const header = (prefix: string) => t.static(`coupons.detail.${prefix}` as any);

function NumberRangeFilter({ column: { filterValue = [], setFilter, id } }) {
  return (
    <Box display='flex' flexDirection='row'>
      <Box minWidth='80px' p={0.5}>
        <TextField
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [val === '0' || val ? parseInt(val, 10) : undefined, old[1]]);
          }}
          value={filterValue[0]}
          variant='outlined'
          size='small'
          label={title('from')}
        />
      </Box>
      <Box minWidth='80px' p={0.5}>
        <TextField
          value={filterValue[1]}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val === '0' || val ? parseInt(val, 10) : undefined]);
          }}
          variant='outlined'
          size='small'
          label={title('to')}
        />
      </Box>
    </Box>
  );
}

function DateFilter(props) {
  const {
    column: { filterValue, setFilter, preFilteredRows, id },
  } = props;
  return (
    <Box minWidth='250px' p={0.5}>
      <DatePicker
        hideArrow
        view={{
          fullWidth: true,
          readOnly: true,
          variant: 'outlined',
          size: 'small',
        }}
        label=''
        name='date_picker'
        value={filterValue}
        onChange={({ name, value }) => {
          setFilter(value);
        }}
        onClear={() => {
          if (id === 'createdAt' || 'updated_at') {
            setFilter(null);
          }
        }}
      />
    </Box>
  );
}

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<Order> & AnyObject } = {
  totalSum: {
    id: 'total',
    Header: header('totalSum'),
    accessor: (tx) => tx.total,
    Cell: ({ row: { original } }) => {
      const roundedTotal = original.total / 100;
      return <>{formatCurrency(roundedTotal, original.currency)}</>;
    },
    filter: 'between',
    Filter: NumberRangeFilter,
  },

  discountSum: {
    id: 'discount',
    Header: header('discountSum'),
    accessor: (tx) => tx.discount,
    filter: 'between',
    Filter: NumberRangeFilter,
    Cell: ({ row: { original } }) => <>{formatCurrency(original.discount / 100, original.currency) ?? '—'}</>,
  },

  firstName: {
    id: 'customer.firstname',
    Header: header('firstName'),
    accessor: (tx) => tx.customer.firstname ?? '—',
  },

  lastName: {
    id: 'customer.lastname',
    Header: header('lastName'),
    accessor: (tx) => tx.customer.lastname ?? '—',
  },

  email: {
    id: 'customer.email',
    Header: header('email'),
    accessor: (tx) => tx.customer.email ?? '—',
  },

  createdAt: {
    id: 'created_at',
    Header: header('createdAt'),
    Filter: DateFilter,
    accessor: (tx) => tx.created_at,
    Cell: ({ row: { original } }) => {
      return <>{moment(original.created_at).format('YYYY/MM/DD HH:mm')}</>;
    },
  },

  updatedAt: {
    id: 'updated_at',
    Header: header('updatedAt'),
    Filter: DateFilter,
    accessor: (tx) => tx.updated_at,
    Cell: ({ row: { original } }) => {
      return <>{moment(original.updated_at).format('YYYY/MM/DD HH:mm')}</>;
    },
  },
};

export function generateTransactionColumns() {
  return COLUMNS.map((c) => RenderColumns[c]);
}

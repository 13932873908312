import { reaction } from 'mobx';

import { form } from 'stores';

import { COUPONS_FORM_ID } from '../constants';

import { couponFormDisposers, couponFormDistanceStore } from '../stores';

export const registerCouponFormDistanceSelectReaction = () => {
  couponFormDisposers.register(
    reaction(
      () => form.fetch(COUPONS_FORM_ID, 'distances'),
      async (distances: SelectOption[] = []) => {
        form.onChange(
          COUPONS_FORM_ID,
          'isAllDistancesSelected',
          distances.length > 0 && distances.length === couponFormDistanceStore.distances?.length,
        );
      },
    ),
  );
};

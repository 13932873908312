import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';

import { SPORT_TYPE, SPORT_TYPES_URL, ROUTES } from 'src/constants';
import { formatData } from 'src/modules/SportTypes/utils';

import { request, action, reorderUtil } from 'utils';
import { history, t } from 'utils';

import { SportType } from 'models';

import { sportTypesStore } from 'stores';
import { toastStore } from 'stores';

class EditSportType {
  @request({ action: `UPDATE_${SPORT_TYPE}` })
  updateRequest(data: FormData, id: number): any {
    data.append('_method', 'patch');
    return axios.post(`${SPORT_TYPES_URL}/${id}`, data);
  }

  @action({ action: `UPDATE_${SPORT_TYPE}` })
  async update(json: {}): Promise<any> {
    const data = formatData(json);

    data.append('_method', 'patch');

    const id: number = (json as any).id;

    const [status, response] = await this.updateRequest(data, id);
    const sportType = response.data.data;

    if (status && sportType) {
      sportTypesStore.updateValue(sportType);

      if (sportTypesStore.selected && sportTypesStore.selected.id === sportType.id) {
        sportTypesStore.addSelectedValue(sportType);
      }

      const sport = new SportType(sportType);

      toastStore.show(t.staticAsString('categories.successUpdate', { title: sport.title() }));

      history.push(`${ROUTES.sportTypesRoute}/${sportType.id}`);
    }
  }

  @action({ action: `UPDATE_${SPORT_TYPE}_INDEX` })
  async updateIndex(data: { newIndex: number; oldIndex: number; id: number; newBEIndex: number }) {
    const copy = cloneDeep(sportTypesStore.values);
    const items = reorderUtil.changePosition(copy, data.oldIndex, data.newIndex);

    sportTypesStore.addIndexValues(items);
    const [status] = await this.updateRequest(
      formatData({
        id: data.id,
        index: data.newBEIndex,
      }),
      data.id,
    );
    if (status) {
      const results = await this.collectNewIndexResults();
      sportTypesStore.addIndexValues(results);
    }
  }

  @request({ action: `UPDATE_${SPORT_TYPE}_INDEX_RESULTS` })
  updateIndexResultsRequest(params: FiltersType): any {
    return axios.get(SPORT_TYPES_URL, {
      params: {
        ...params,
      },
    });
  }

  // NOTE, Loads all loaded pages
  async collectNewIndexResults() {
    const page = sportTypesStore.page;
    const params = sportTypesStore.filters;
    let results: any[] = [];
    for (let currentPage = 1; page >= currentPage; currentPage++) {
      const [status, response] = await this.updateIndexResultsRequest({ ...params, page: currentPage });
      if (status) {
        const newResults = response.data.data;
        results = [...results, ...newResults];
      }
    }

    return results;
  }
}

export { EditSportType };
export const editSportType = new EditSportType();

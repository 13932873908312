import TableCell from '@mui/material/TableCell';
import classNames from 'classnames';
import * as React from 'react';

type Props = {
  className: string;
  disabled: boolean;
  active: boolean;
  children: React.ReactNode;
  onClick: (e: React.SyntheticEvent) => void;
  style?: Object;
};

const FilterLabelWrapper = ({ onClick, className, disabled, active, children, style = {} }: Props) => {
  return (
    <TableCell
      style={style}
      className={classNames('table-header', className, { filterable: !disabled }, { disabled }, { active })}
      {...((!disabled && { onClick }) || {})}
    >
      {children}
    </TableCell>
  );
};

const LabelWrapper = ({ className, children, style = {} }: { className: string; children: React.ReactNode; style?: Object }) => {
  return (
    <TableCell style={style} className={classNames('table-header', className, { disabled: true })}>
      {children}
    </TableCell>
  );
};

export { LabelWrapper, FilterLabelWrapper };

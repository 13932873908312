import IconInfoOutlined from '@mui/icons-material/InfoOutlined';
import { InputAdornment, Tooltip, Box } from '@mui/material';
import { pick } from 'lodash';
import { toJS } from 'mobx';
import { handleDeleteImage } from 'modules/Races/actions';
import { LIVE_UPDATING, RACE_FORM_ID } from 'modules/Races/constants';
import { organizerStore } from 'modules/Races/stores';
import { isCalendarRaceType } from 'modules/Races/utils/isCalendarRaceType';
import React, { ReactElement } from 'react';

import { CONFIRM_POPUP_TYPES } from 'src/constants';

import { Show } from 'components/Condition';
import {
  GooglePlacesAutocomplete,
  AutocompleteSelect,
  DatePicker,
  InputWrapper,
  Select,
  TextField,
  ImageFieldWithCrop,
  TextEditor,
} from 'components/Form';
import { formatLongLocale } from 'components/core';

import { stringFormatter } from 'utils/stringFormat';
import { t } from 'utils/t';

import {
  availableLocalesStore,
  confirmationModalStore,
  currenciesStore,
  form,
  helperOrganizersStore,
  helperRacesStore,
  progressStore,
  sessionStore,
  sportTypesAuxiliaryStore,
} from 'stores';

import { formStyles } from '../../styles';
import { StepTitle } from '../Shared/StepTitle';
import { StepProps } from '../sharedProps';

type Props = StepProps;

// Sared input view option.
const view = { fullWidth: true, variant: 'outlined' };

function GeneralSettings({ step, liveUpdate }: Props): ReactElement {
  const classes = formStyles();

  const isEdit = Boolean(helperRacesStore.selected);
  const isCalendarRace = isCalendarRaceType.get();
  const sportTypeOptions = sportTypesAuxiliaryStore.valuesForSelect();
  const organizerOptions = helperOrganizersStore.valuesForSelect();
  let currenciesOptions = currenciesStore.valuesForSelect;
  const commissions = organizerStore.value?.commissions;
  if (commissions) {
    currenciesOptions = currenciesOptions.filter((currency) => commissions.some((c) => c.currency_id === currency.value));
  }

  const isRegistrationsOnRace =
    helperRacesStore.modelSelected?.value.completed_orders_count && helperRacesStore.modelSelected?.value.completed_orders_count > 0;

  const handleConfirmDeleteImage = () => {
    confirmationModalStore.openModal({
      title: t.staticAsString('races.confirmPopup.mainTitle'),
      body: t.staticAsString('races.confirmPopup.imageBody'),
      type: CONFIRM_POPUP_TYPES.confirm,
      onConfirm: handleDeleteImage,
    });
  };

  return (
    <>
      <StepTitle step={step} />

      <div className={classes.wrapper}>
        <InputWrapper
          name='name'
          settings={{
            additional: {
              disabled: progressStore.isLoading(LIVE_UPDATING),
              onBlur: () => liveUpdate('name'),
              required: true,
            },
            view,
            label: t('races.new.name'),
          }}
          Component={TextField}
        />
      </div>

      {isCalendarRace && (
        <div className={classes.wrapper}>
          <InputWrapper
            settings={{
              additional: {
                onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                  let formatted: string | null = stringFormatter.withHttpUrl(e.target.value);
                  if (formatted === '') formatted = null;
                  form.onChange(RACE_FORM_ID, 'race_url', formatted);
                  liveUpdate(e.target.name);
                },
                required: true,
              },
              view: {
                fullWidth: true,
                variant: 'outlined',
                InputProps: {
                  endAdornment: (
                    <Tooltip classes={pick(classes, 'tooltip')} title={t('races.new.raceUrl.info')!}>
                      <InputAdornment style={{ cursor: 'pointer' }} position='end'>
                        <IconInfoOutlined color='disabled' />
                      </InputAdornment>
                    </Tooltip>
                  ),
                },
              },
              label: t('races.new.race_url'),
            }}
            Component={TextField}
            name='race_url'
          />
        </div>
      )}

      <div className={classes.wrapper}>
        <InputWrapper
          settings={{
            additional: {
              liveUpdate,
              options: sportTypeOptions,
              disableClearable: true,
              required: true,
            },
            view: { ...view, reservePlaceForHelperText: true },
            label: t('races.new.sport_type'),
          }}
          Component={AutocompleteSelect}
          name='sport_id'
        />
      </div>

      <div className={classes.wrapper}>
        <Box sx={{ display: 'flex', gap: '15px' }}>
          <InputWrapper
            settings={{
              additional: {
                liveUpdate,
                isRange: false,
              },
              required: true,
              view,
              label: t.staticAsString('races.new.race_date'),
            }}
            Component={DatePicker}
            name='race_date'
          />
          <InputWrapper
            settings={{
              additional: { liveUpdate, isRange: false },
              required: true,
              view,
              label: t.staticAsString('races.new.race_end_date'),
            }}
            Component={DatePicker}
            name='race_end_date'
          />
        </Box>
      </div>

      {!isCalendarRace && !isEdit && (
        <div className={classes.wrapper}>
          <InputWrapper
            settings={{
              options: availableLocalesStore.value!.map((locale) => ({
                key: locale,
                value: locale,
                label: formatLongLocale(locale),
              })),
              additional: {
                liveUpdate,
                required: true,
              },
              view: {
                ...view,
                reservePlaceForHelperText: true,
                InputProps: {
                  endAdornment: (
                    <Tooltip classes={pick(classes, 'tooltip')} title={t('races.new.default_language_info')!}>
                      <InputAdornment style={{ cursor: 'pointer', marginRight: 20 }} position='end'>
                        <IconInfoOutlined color='disabled' />
                      </InputAdornment>
                    </Tooltip>
                  ),
                },
              },
              label: t('races.steps.languages.setDefault'),
            }}
            Component={Select}
            name='pref_lang_code'
          />
        </div>
      )}

      <div className={classes.wrapper}>
        <InputWrapper
          settings={{
            additional: {
              liveUpdate,
              disabled: isRegistrationsOnRace,
              required: true,
            },
            options: currenciesOptions,
            view: { ...view, helperText: isRegistrationsOnRace && t.staticAsString('races.steps.currencyDisabled') },
            label: t('races.new.currency_id'),
          }}
          Component={Select}
          name='currency_id'
        />
      </div>

      <Show if={sessionStore.isAdmin}>
        <div className={classes.wrapper}>
          <InputWrapper
            settings={{
              additional: {
                liveUpdate,
                options: organizerOptions,
                disableClearable: true,
                required: true,
              },
              view: { ...view, reservePlaceForHelperText: true },
              label: t('races.new.organizer'),
            }}
            Component={AutocompleteSelect}
            name='organizer_id'
          />
        </div>
      </Show>
      <div className={classes.wrapperDoublePadding}>
        <InputWrapper
          settings={{
            additional: {
              liveUpdate,
            },
            view,
            label: t('races.new.location'),
          }}
          Component={GooglePlacesAutocomplete}
          name='location'
        />
      </div>

      <div className={classes.wrapper}>
        <InputWrapper
          settings={{
            additional: {
              liveUpdate,
              handleDeleteImage: handleConfirmDeleteImage,
            },
            view,
            label: 'Image',
          }}
          Component={ImageFieldWithCrop}
          name='image'
        />
      </div>

      <div className={classes.wrapperFullWidth}>
        <InputWrapper
          name='description'
          settings={{
            additional: {
              onBlur: () => liveUpdate('description'),
            },
            view,
            label: t('races.new.race_desc'),
          }}
          Component={TextEditor}
        />
      </div>
    </>
  );
}

export { GeneralSettings };

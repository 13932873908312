import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { PaymentType } from 'modules/SystemUsers/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnWithLooseAccessor } from 'react-table';

import { currenciesStore } from 'src/stores';
import { t } from 'src/utils';

import { DatePicker } from 'components/Form';
import { Icon } from 'components/Icon';

import { TransactionTypeEnum, StatusTypeEnum } from '../../../../types/systemUserPayments-response.type';
import { ExternalCell, RefundDisplay, TransactionTypeCell, StatusCell } from './CustomCells';

const COLUMNS = [
  'id',
  'external_id',
  'created_at',
  'distance_title',
  'email',
  'first_name',
  'last_name',
  'status',
  'type',
  'discount',
  'total',
  'currency',
  'viewOrder',
] as const;

const label = (value: string) => t.static(`systemUsers.payments.${value}` as any);

function DateFilter(props) {
  const {
    column: { filterValue, setFilter, preFilteredRows, id },
  } = props;
  return (
    <Box minWidth='250px' p={0.5}>
      <DatePicker
        hideArrow
        view={{
          fullWidth: true,
          readOnly: true,
          variant: 'outlined',
          size: 'small',
        }}
        label=''
        name='created_at'
        value={filterValue}
        onChange={({ name, value }) => {
          setFilter(value);
        }}
        onClear={() => {
          if (id === 'created_at') {
            setFilter(null);
            setFilter(null);
          }
        }}
      />
    </Box>
  );
}
function SelectColumnFilter(props) {
  const {
    column: { filterValue, setFilter, preFilteredRows, id, selectProps },
  } = props;
  const options = selectProps.options() || [];

  return (
    <Box minWidth='160px' p={0.5}>
      <TextField
        variant='outlined'
        size='small'
        fullWidth
        select
        name={id}
        label='Select'
        value={filterValue ?? 'All'}
        onChange={(e) => {
          if (e.target.value === 'All') {
            return setFilter(undefined);
          }
          setFilter(e.target.value);
        }}
      >
        <MenuItem key='All' value='All'>
          All
        </MenuItem>
        {options.map((option: any) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}

function NumberRangeFilter({ column: { filterValue = [], setFilter, preFilteredRows, id } }) {
  return (
    <Box display='flex' flexDirection='row'>
      <Box minWidth='80px' p={0.5}>
        <TextField
          type='number'
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => {
              return [val === '0' || val ? parseInt(val, 10) : undefined, old[1]];
            });
          }}
          value={filterValue[0] || ''}
          variant='outlined'
          size='small'
          label='From'
        />
      </Box>
      <Box minWidth='80px' p={0.5}>
        <TextField
          type='number'
          value={filterValue[1] || ''}
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [old[0], val === '0' || val ? parseInt(val, 10) : undefined]);
          }}
          variant='outlined'
          size='small'
          label='To'
        />
      </Box>
    </Box>
  );
}

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<PaymentType> & AnyObject } = {
  id: {
    id: 'id',
    Header: label('id'),
    accessor: (payment) => payment.id,
    Filter: NumberRangeFilter,
    Cell: ({ row: { original } }) => (
      <Link to={`/reconciliation/transactions/${original.id}`} style={{ color: 'black', textDecoration: 'underline' }}>
        {original.id}
      </Link>
    ),
    filter: 'between',
  },
  external_id: {
    id: 'external_id',
    Header: label('externalId'),
    accessor: (payment) => payment.external_id,
    Cell: ({
      row: {
        original: { external_id },
      },
    }) => <ExternalCell external_id={external_id} />,
  },
  created_at: {
    id: 'created_at',
    Header: label('purchasedOn'),
    Filter: DateFilter,
    accessor: (payment) => `${payment.created_date} ${payment.created_time}`,
  },
  distance_title: {
    id: 'distance_title',
    Header: label('distanceName'),
    accessor: (payment) => payment.distance_title,
  },
  email: {
    id: 'email',
    Header: label('email'),
    accessor: (payment) => payment.email,
  },
  first_name: {
    id: 'firstname',
    Header: label('firstName'),
    accessor: (payment) => payment.firstname,
    Cell: ({ row: { original } }) => {
      return <span>{original.firstname ?? '-'}</span>;
    },
  },
  last_name: {
    id: 'lastname',
    Header: label('lastName'),
    accessor: (payment) => payment.lastname,
    Cell: ({ row: { original } }) => {
      return <span>{original.lastname ?? '-'}</span>;
    },
  },
  total: {
    id: 'total',
    Header: label('sum'),
    accessor: (payment) => `${payment.total} ${payment.currency}`,
    Filter: NumberRangeFilter,
    filter: 'between',
    Cell: ({ row: { original } }) => {
      if (original.type === TransactionTypeEnum.REFUNDED) return <RefundDisplay value={original.total} currency={original.currency} />;

      return <span>{`${original.total} ${original.currency}`}</span>;
    },
  },
  status: {
    id: 'status',
    Header: label('status'),
    accessor: (payment) => payment.status,
    Filter: SelectColumnFilter,
    filter: 'equals',
    selectProps: {
      options: () => [
        {
          key: 'pending',
          value: StatusTypeEnum.PENDING,
          label: label('status.pending'),
        },
        {
          key: 'completed',
          value: StatusTypeEnum.COMPLETED,
          label: label('status.completed'),
        },
        {
          key: 'failed',
          value: StatusTypeEnum.FAILED,
          label: label('status.failed'),
        },
      ],
    },
    Cell: ({ row: { original } }) => <StatusCell status={original.status} />,
  },
  type: {
    id: 'type',
    Header: label('transactionType'),
    Filter: SelectColumnFilter,
    filter: 'equals',
    selectProps: {
      options: () => [
        {
          key: TransactionTypeEnum.AUTHORIZATION,
          value: TransactionTypeEnum.AUTHORIZATION,
          label: label('transactionType.authorization'),
        },
        {
          key: TransactionTypeEnum.CAPTURE,
          value: TransactionTypeEnum.CAPTURE,
          label: label('transactionType.capture'),
        },
        {
          key: TransactionTypeEnum.REFUNDED,
          value: TransactionTypeEnum.REFUNDED,
          label: label('transactionType.refunded'),
        },
      ],
    },
    accessor: (tx) => tx.type,
    Cell: ({ row: { original } }) => <TransactionTypeCell type={original.type} />,
  },
  discount: {
    id: 'discount',
    Header: label('discount'),
    accessor: (payment) => payment.discount,
    Cell: ({ row: { original } }) => <span>{`${original.discount} ${original.currency}`}</span>,
    filter: 'between',
    Filter: NumberRangeFilter,
  },

  currency: {
    id: 'currency',
    Header: label('currency'),
    filter: 'equals',
    Filter: SelectColumnFilter,
    selectProps: {
      options: () => currenciesStore.valuesForSelect,
    },
    any: 'props',
    accessor: (payment) => payment.currency,
    Cell: ({ row: { original } }) => <>{original.currency ?? '—'}</>,
  },
  viewOrder: {
    id: 'viewOrder',
    Header: label('action'),
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
    accessor: (payment) => `${payment.total} ${payment.currency}`,
    Cell: ({ row: { original } }) => {
      const viewFile = (): void => {
        window.open(original?.receipt?.url, '_blank');
      };
      return (
        <>
          {Boolean(original?.receipt) ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={viewFile}>
              <Icon style={{ cursor: 'pointer' }} value='pdf' />
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span>-</span>
            </div>
          )}
        </>
      );
    },
  },
};

export function generateSystemUserColumns() {
  return COLUMNS.map((c) => RenderColumns[c]);
}

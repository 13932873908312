import { camelCase } from 'lodash';

import { t } from 'utils';

import { localeStore } from 'stores';

class Country {
  value: CountryType;

  constructor(value: CountryType) {
    this.value = value;
    return this;
  }

  name(): string {
    const locale = localeStore.value;
    let name = '';
    switch (locale) {
      case 'en':
        name = this.value.short_name_en || this.value.short_name;
        break;
      case 'sv':
        name = this.value.short_name || this.value.short_name_en;
        break;
      default:
        name = this.value.short_name_en;
    }
    return name || '';
  }

  nationalityName(): string {
    return t.staticAsString(`nationalities.${camelCase(this.value.short_name_en)}` as TranslationLockedKeys);
  }

  forSelect(): SelectOption {
    const id = `${this.value.id}`;

    return {
      label: this.name(),
      value: Number(id),
      key: Number(id),
    };
  }

  forNationalitySelect(): SelectOption {
    const id = `${this.value.id}`;

    return {
      label: this.nationalityName(),
      value: Number(id),
      key: Number(id),
    };
  }

  static nameLabel() {
    const locale = localeStore.value;

    switch (locale) {
      case 'en':
        return 'short_name_en';
      case 'sv':
        return 'short_name';
      default:
        return 'short_name_en';
    }
  }
}

export { Country };

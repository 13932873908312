import { Download } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import { Observer } from 'mobx-react';
import { TransactionsActions } from 'modules/Reconciliation/Transactions/actions/lifecycle';
import { TransactionsTable } from 'modules/Reconciliation/Transactions/components/Table';
import { TxFiltersMappers } from 'modules/Reconciliation/Transactions/mappers/filters.mapper';
import { TransactionsStore } from 'modules/Reconciliation/Transactions/stores/transactions.store';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';

import { t } from 'utils/t';

import { filtersStore } from '../../stores/filters.store';

import { generateTransactionColumns } from '../Table/Columns';

interface Props {}

function TransactionsWrapper({}: Props): ReactElement {
  useEffect(() => {
    const init = async () => {
      await TransactionsActions.init();
    };
    init();
    return () => {
      TransactionsActions.unmount();
    };
  }, []);

  const handleExport = (type: string) => () => {
    TransactionsActions.exportTransactions(type);
  };

  const fetchTxs = useCallback(({ pageIndex = 0, pageSize, filters, sortBy }) => {
    let orderBy = 'created_at';
    let sortedBy = 'DESC';
    if (sortBy.length > 0) {
      const { id, desc } = sortBy[0];
      orderBy = id.includes('.') ? id.split('.').join('|') : id;
      sortedBy = desc ? 'DESC' : 'ASC';
    }
    const mapped = { page: pageIndex + 1, limit: pageSize, orderBy, sortedBy, ...TxFiltersMappers.toBackend(filters) };
    filtersStore.setFilters(mapped);
    TransactionsActions.load(mapped);
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h1 style={{ paddingLeft: 24, paddingTop: 38, paddingBottom: 30 }} className='title'>
          {t.staticAsString('reconciliation.transactions.transactions')}
        </h1>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 4 }}>
          <Button
            className='export-item'
            sx={{ border: '2px solid #A8AEB8', px: 2, py: 1, mr: 2, color: '#DDA100', alignContent: 'center' }}
            onClick={handleExport('csv')}
            size='large'
            color='primary'
          >
            <Download sx={{ mr: 1, color: '#DDA100' }} />
            <Typography>CSV</Typography>
          </Button>
          <Button
            sx={{ border: '2px solid #A8AEB8', px: 2, py: 1, color: '#DDA100', alignContent: 'center' }}
            onClick={handleExport('xls')}
            size='large'
            color='primary'
          >
            <Download sx={{ mr: 1, color: '#DDA100' }} />
            <Typography>XLS</Typography>
          </Button>
        </Box>
      </Box>
      <div style={{ padding: 0, backgroundColor: 'white' }} className='content main-container-list races-list'>
        <Observer
          render={() => {
            return (
              <TransactionsTable
                tableData={TransactionsStore.txs.value?.data ?? []}
                tableMeta={TransactionsStore.txs.value?.meta ?? {}}
                initialSizeOfPage={30}
                pageSizeOptions={[30, 50, 100, 300]}
                tableColumns={generateTransactionColumns()}
                fetchTxs={fetchTxs}
              />
            );
          }}
        />
      </div>
    </>
  );
}

export { TransactionsWrapper };

import { History } from 'history';

import races from '../stores/races';

import { paymentsStore } from '../modules/Payments/stores';
import { systemUsersStore } from '../modules/SystemUsers/stores';

// Instantiated in routes/index.js componentDidMount.
class FilterResetter {
  /*
    Key of the config must correspond to the root route where functionality will be applied.
    exceptFrom: ['racers] => navigating from /races/-*-/racers will not drop filters.
    exceptTo: ['customers'] => navigating from e.g. /races/* to /customers will not drop filters.
    Every store in the config must implement resetFilters method.
  */
  config = {
    races: {
      exceptFrom: [],
      exceptTo: [],
      store: races,
    },
    'system-users': {
      exceptFrom: [],
      exceptTo: [],
      store: systemUsersStore,
    },
    payments: {
      exceptFrom: [],
      exceptTo: [],
      store: paymentsStore,
    },
  };

  currRoute = '';
  prevRoute = '';
  history: History;

  constructor(history: History) {
    this.history = history;
    this.prevRoute = this.history.location.pathname;
    this.currRoute = this.history.location.pathname;
    this.bindListeners();
  }

  bindListeners() {
    this.history.listen(({ pathname }) => {
      this.prevRoute = this.currRoute;
      this.currRoute = pathname;
      if (this.shouldReset) {
        try {
          this.config[this.rootPrevRoute].store.resetFilters();
        } catch (error) {}
      }
    });
  }

  get shouldReset() {
    const inConfig = this.rootPrevRoute in this.config;
    if (!inConfig) return false;
    const isNested = this.rootCurrRoute === this.rootPrevRoute;
    // Should reset filters if route is not nested and no exceptions apply.
    return !isNested && !this.isExceptFrom && !this.isExceptTo;
  }

  // "/races/111/distances/222/racers" => "races"
  get rootCurrRoute() {
    return this.currRoute.split('/')[1];
  }

  get rootPrevRoute() {
    return this.prevRoute.split('/')[1];
  }

  get isExceptFrom() {
    if (!Boolean(this.rootPrevRoute in this.config)) return false;
    return this.config[this.rootPrevRoute].exceptFrom.includes(this.prevRoute.split('/').pop());
  }

  get isExceptTo() {
    if (!Boolean(this.rootPrevRoute in this.config)) return false;
    return this.config[this.rootPrevRoute].exceptTo.includes(this.rootCurrRoute);
  }
}

export default FilterResetter;

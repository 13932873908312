import { RACE_FORM_ID } from 'src/modules/Races/constants';
import editRaceService from 'src/modules/Races/services/editRace';
import { getRaceId } from 'src/modules/Races/utils';

import { form } from 'stores';

export const getContactEmail = async () => {
  const response = await editRaceService.getContactEmail(getRaceId());

  if (response[1]) {
    form.onChange(RACE_FORM_ID, 'contact_emails', response[1]);
  }
};

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

import * as Icons from 'img/activity_links';
import { IconNameType } from 'img/activity_links';

import { urlSanitizer } from 'utils';

import { ActivityTooltip } from './Tooltip';

type Props = {
  icon: IconNameType;
  name: string;
  link: undefined | string;
  onEdit: ClickSpanEvent;
};

const useStyles = makeStyles({
  image: {
    borderRadius: '5px',
  },
});

function ActivityLink(props: Props) {
  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    if (!props.link) {
      e.preventDefault();
    }
  };

  const classes = useStyles();

  const noSource = !props.link;
  const src = Icons[props.icon];
  const src2x = Icons[`${props.icon}2x` as IconNameType];

  const srcSet = src2x ? `${src2x} 2x` : '';

  const link = props.link ? urlSanitizer(props.link) : '';

  // This div wrap should be here for a better behaviour of tooltip
  return (
    <ActivityTooltip link={props.link} onClick={props.onEdit}>
      <div>
        <Link underline={noSource ? 'none' : 'always'} onClick={onClick} href={link} color='textPrimary' target='_blank'>
          <Box display='flex' alignItems='center' justifyContent='flex-end' pr={3}>
            <Box mr={1.5}>{props.name}</Box>
            <img className={classes.image} src={src} srcSet={srcSet} alt='logo-img' />
          </Box>
        </Link>
      </div>
    </ActivityTooltip>
  );
}

export { ActivityLink };

import { Language } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

import { useStyles } from './styles';

export const ModeChangeButton = ({ onModeChange }: { onModeChange: Function }) => {
  const classes = useStyles();
  const handleClick = () => {
    onModeChange();
  };
  return (
    <IconButton className={classes.globalButton} aria-label='mode' onClick={handleClick} size='large'>
      <Language className={classes.globalIcon} />
    </IconButton>
  );
};

import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { difference } from 'lodash';
import { Observer } from 'mobx-react';
import { loadTiming } from 'modules/Distances/actions';
import { CheckpointForm } from 'modules/Distances/components/shared/Steps/Checkpoints/CheckpointForm';
import { FORM_ID, TIMING_ASSISTANTS } from 'modules/Distances/constants';
import { CheckpointModalStore, timingAssistants } from 'modules/Distances/stores';
import * as React from 'react';

import { t } from 'utils';

import { errorsStore, form } from 'stores';

type Props = {
  onSave: Function;
  checkpointsLength: number;
};

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.2,
  },
}));

const dialogStyles = makeStyles((theme) => ({
  paper: {
    overflow: 'visible',
  },
}));

const titleStyles = makeStyles((theme) => ({
  root: {
    height: 60,
    margin: 0,
    padding: '20px 15px 17px 15px',
  },
}));

const contentStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
}));

export const ConfigureCheckpointDialog = ({ onSave, checkpointsLength }: Props) => {
  const classes = useStyles();
  const dialogClasses = dialogStyles();
  const contentClasses = contentStyles();
  const titleClasses = titleStyles();

  const onAssistantsSelect = (values) => {
    const selected = form.fetch<any>(FORM_ID, TIMING_ASSISTANTS) || [];

    form.onChange(FORM_ID, TIMING_ASSISTANTS, values);

    // To hack stored "manage distance" state - track selection and reset checkboxes
    const selected2 = form.fetch<any>(FORM_ID, TIMING_ASSISTANTS) || [];

    const [newSelection] = difference<any>(selected2, selected);

    if (newSelection) {
      resetManage(newSelection.id);
    }

    // Clears only recently created assistant, which should be selected automatically
    // Clearing prevents infinite loop of selection
    timingAssistants.clearSelected();
  };

  const manageDistance = (id: number, stage: 'start' | 'finish', value: boolean) => {
    const selected = form.fetch<any>(FORM_ID, TIMING_ASSISTANTS) || [];
    const index = selected.findIndex((el) => el.id === id);

    if (index === -1) {
      return;
    }

    const assistantUpd = selected[index];
    if (stage === 'start') {
      assistantUpd.allow_start = value;
    }

    if (stage === 'finish') {
      assistantUpd.allow_finish = value;
    }

    const selectedUpd = [...selected];
    selectedUpd.splice(index, 1, assistantUpd);

    form.onChange(FORM_ID, TIMING_ASSISTANTS, selectedUpd);
  };

  const resetManage = (id: number) => {
    manageDistance(id, 'start', false);
    manageDistance(id, 'finish', false);
  };

  const allowStartToggle = (assistantId) => {
    const selected = form.fetch<any>(FORM_ID, TIMING_ASSISTANTS) || [];
    const index = selected.findIndex((el) => el.id === assistantId);

    if (index === -1) {
      return;
    }

    manageDistance(assistantId, 'start', !selected[index].allow_start);
  };

  const allowFinishToggle = (assistantId) => {
    const selected = form.fetch<any>(FORM_ID, TIMING_ASSISTANTS) || [];
    const index = selected.findIndex((el) => el.id === assistantId);

    if (index === -1) {
      return;
    }

    manageDistance(assistantId, 'finish', !selected[index].allow_finish);
  };

  const onSaveCheckpoint = () => {
    const checkpoint = form.value[FORM_ID];
    onSave(checkpoint);
  };

  React.useEffect(() => {
    loadTiming();
    form.registerField(FORM_ID, [TIMING_ASSISTANTS]);
    errorsStore.clear(FORM_ID);
  }, []);
  const onClose = () => {
    CheckpointModalStore.off();
    errorsStore.clear(FORM_ID);
  };
  return (
    <Observer>
      {() => {
        if (!CheckpointModalStore.value) return null;
        const selected = form.fetch<any>(FORM_ID, TIMING_ASSISTANTS) || [];

        const isEditMode = Boolean(
          // @ts-ignore
          form.value[FORM_ID]?.id || form.value[FORM_ID]?.__id,
        );

        const checkpoint = form.value[FORM_ID] as CheckpointType;
        const isStart = checkpoint?.index === 0 ?? -1;
        const isFinish = checkpoint?.index === checkpointsLength - 1 ?? -1;
        let dialogTitle: TranslationLockedKeys = 'distances.steps.checkpointsForm.configure';
        if (isStart) dialogTitle = 'distances.steps.checkpointsForm.configureFirst' as TranslationLockedKeys;
        if (isFinish) dialogTitle = 'distances.steps.checkpointsForm.configureLast' as TranslationLockedKeys;

        return (
          <Dialog maxWidth={'md'} fullWidth classes={dialogClasses} open={CheckpointModalStore.value} onClose={onClose}>
            <DialogTitle classes={titleClasses}>
              <Typography className={classes.heading}>{t.staticAsString(dialogTitle)}</Typography>
            </DialogTitle>
            <DialogContent classes={contentClasses} dividers>
              <CheckpointForm
                checkpointsLength={checkpointsLength}
                isEditMode={isEditMode}
                saveCheckpoint={onSaveCheckpoint}
                timingAssistantsList={timingAssistants.values}
                onAssistantsSelect={onAssistantsSelect}
                selectedAssistants={selected}
                allowStartToggle={allowStartToggle}
                allowFinishToggle={allowFinishToggle}
                recentlyCreatedAssistant={timingAssistants.selectedValues[0]}
                onClose={onClose}
              />
            </DialogContent>
          </Dialog>
        );
      }}
    </Observer>
  );
};

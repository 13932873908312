import { Theme } from '@mui/material';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteCloseReason } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { SxProps } from '@mui/system';
import { isEmpty, isUndefined } from 'lodash';
import * as React from 'react';

import { useAutocompleteCountryStyles, useAutocompleteStyles } from '../styles';

type Props = BaseViewProps & {
  name: string;
  label: string;
  inputProps?: AnyObject;
  inputSx?: AnyObject;
  textInput?: string;
  className?: string;
  disableClearable?: boolean;
  loading?: boolean;
  errors: Array<string>;
  options: Array<Object>;
  value: AnyObject | nil;
  onChange: AnyFunction;
  onTextInput?: AnyFunction;
  onClose?: (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => void;
  onOpen?: (event: React.ChangeEvent<{}>) => void;
  onInputChange?: AnyFunction;
  inputHeight?: number;
  required?: boolean;
  sx?: SxProps<Theme>;
};

export function AutocompleteSelect(props: Props) {
  const {
    name,
    options,
    label,
    errors = [],
    inputSx,
    disableClearable,
    onChange,
    onTextInput,
    className = 'select',
    variant = 'standard',
    size = 'medium',
    onClose,
    onOpen,
    onInputChange,
    loading,
    textInput,
    inputProps,
    required,
    sx,
  } = props;
  const classes = name === 'country_id' || name === 'nationality_id' ? useAutocompleteCountryStyles() : useAutocompleteStyles();
  const withError = !isEmpty(errors);

  let value = props.value;

  value = isUndefined(value) || isEmpty(value) ? null : value;

  return (
    <Autocomplete
      sx={sx}
      id={name}
      options={options}
      loading={loading}
      className={className}
      classes={classes}
      disableClearable={disableClearable}
      getOptionLabel={(option: any) => option.label || ''}
      isOptionEqualToValue={(option) => option.value === value?.value}
      value={value}
      onClose={onClose}
      onOpen={onOpen}
      onChange={onSelect(name, onChange)}
      onInputChange={onInputChange}
      renderInput={(params: any) => {
        const value = textInput === undefined ? params.inputProps.value : textInput;
        return (
          <TextField
            sx={{
              ...inputSx,
            }}
            {...params}
            inputProps={{
              ...params.inputProps,
              value,
              ...inputProps,
            }}
            variant={variant}
            size={size}
            onChange={onTextInput}
            error={withError}
            helperText={errors[0] || ''}
            label={label}
            fullWidth
            required={required}
          />
        );
      }}
    />
  );
}

type onChangeType = (
  event: React.ChangeEvent<{}>,
  value: any,
  reason: AutocompleteChangeReason,
  details?: AutocompleteChangeDetails<any>,
) => void;

const onSelect =
  (name: string, onChange: Function): onChangeType =>
  (e: React.ChangeEvent<{}>, value: Object): void => {
    onChange({ name, value }, e);
  };

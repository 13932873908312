import { DummyStore } from 'stores';

import { ExternalLinkCache, ParticipantsCount } from '../types';

export const integrationExistStore = new DummyStore<boolean>(false);
export const participantsCountStore = new DummyStore<ParticipantsCount>({});
export const externalLinkCacheStore = new DummyStore<ExternalLinkCache>({});

export const duplicateModalStore = new DummyStore<{
  open: boolean;
  type?: 'race' | 'distance' | null;
  distanceId?: number | null;
  participantsCount?: number | null;
}>({
  open: false,
});

export * from './raceDetailsStore';
export * from './distancesStore';
export * from './raceTypeStore';

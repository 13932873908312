import { observer, inject } from 'mobx-react';
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

import { START_LIST } from 'src/constants';

import { TextField } from 'components/Fields';

import { Errors as ErrorsStore } from 'stores';

type Props = {
  item: RacerType;
  field: string;
  errorsStore: ErrorsStore;
  distance: DistanceType;
  racersField: Object;
  saveRacer: (...args: any[]) => void;
  value: string;
  onChange: (name: string, value: any) => void;
};

const action = `UPDATE_${START_LIST}`;

@inject('errorsStore')
@onClickOutside
@observer
class Input extends React.Component<Props> {
  handleClickOutside = (evt: React.FocusEvent) => {
    const { saveRacer, field } = this.props;
    saveRacer(field);
  };

  onChange = (e: any) => {
    const { onChange, field } = this.props;
    const value = e.currentTarget.value;

    onChange(field, value);
  };

  errors = () => {
    const { errorsStore } = this.props;
    const errors = errorsStore.errors.api[action];
    const validationErrors = errorsStore.errors.validation[action];

    if (validationErrors) {
      return validationErrors.formatErrors();
    }
    if (errors) {
      return errors.formatErrors();
    }
    return {};
  };

  render() {
    const { field, value } = this.props;
    const errors = this.errors();

    return <TextField value={value} onChange={this.onChange} errors={errors && errors[field]} name={field} />;
  }
}

export { Input };

import { racesService } from '../services';

import {
  racesParamsStore,
  racesStore,
  racesHasMoreStore,
  disposersStore,
  wavesStore,
  distancesStore,
  wavesHasMoreStore,
  wavesParamsStore,
} from '../stores';

import { observeRaceField, observeWaveField, observeDistanceField } from '../reactions';
import { getLinkData } from './edit';

export const mount = async () => {
  racesService.getRaces();
  observeRaceField();
  observeDistanceField();
  observeWaveField();
};

export const unmount = () => {
  // Stores reset
  racesParamsStore.clear();
  racesStore.clear();
  racesHasMoreStore.clear();
  wavesStore.clear();
  distancesStore.clear();
  wavesHasMoreStore.clear();
  wavesParamsStore.clear();

  // Observers reset
  disposersStore.disposeAll();
};

export const mountEdit = () => {
  getLinkData();
};

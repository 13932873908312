import { CREDIT_SINGLE, MERGE, CREDIT_DISTANCE, COUPON, BIB_NUMBERS } from '../constants';

import { mergeAccounts, creditSingle, creditDistance, generateCoupon, action, generateBib } from '.';

export const DictionaryValidationRules = {
  [MERGE]: mergeAccounts,
  [CREDIT_SINGLE]: creditSingle,
  [CREDIT_DISTANCE]: creditDistance,
  [COUPON]: generateCoupon,
  [BIB_NUMBERS]: generateBib,
  undefined: action,
};

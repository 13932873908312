import * as React from 'react';

const withoutSidebar = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  class WithoutSidebarHOC extends React.Component<P> {
    componentDidMount() {
      const sidebar = document.getElementsByTagName('aside')[0];

      if (!sidebar) {
        return;
      }

      sidebar.style.display = 'none';
    }

    componentWillUnmount() {
      const sidebar = document.getElementsByTagName('aside')[0];

      if (!sidebar) {
        return;
      }

      sidebar.style.display = '';
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  } as unknown as void;

export { withoutSidebar };

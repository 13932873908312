import { computed } from 'mobx';
import { IComputedValue } from 'mobx';

import { Goal } from 'models';

import { distanceStore } from '../stores';

const currentGoal: IComputedValue<Goal | null> = computed(() => {
  const distance = distanceStore.modelSelected;
  const goal = distance?.value?.goal;

  if (!goal) {
    return null;
  }

  return new Goal(goal);
});

function isGoalType(type: 'altitude' | 'distance'): IComputedValue<boolean> {
  return computed(() => {
    const goal = currentGoal.get();

    if (!goal) {
      return false;
    }

    return goal.isGoalType(type);
  });
}

export { currentGoal, isGoalType };

import { TableCell, TableRow } from '@mui/material';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { squadValidation } from 'modules/StartList/validations';
import * as React from 'react';

import { START_LIST } from 'src/constants';

import { oneFieldValidation, t } from 'utils';

import { errorsStore } from 'stores';

import { Controls } from './Controls';
import { SquadField } from './SquadField';

type Props = {
  item: AnyObject;
  distance: DistanceType;
  errors?: Array<AnyObject>;
  onDeleteSquadWrapper: (squadId: number, name: string) => AnyObject | nil;
  onEditSquad: (squadId: number, name: string) => AnyObject | nil;
  isLastItem?: boolean;
  onVisible?: Function;
};

type State = {
  isEdit: string | null;
  squad: AnyObject;
  changedFields: AnyObject;
};

const action = `UPDATE_${START_LIST}`;

class Item extends React.Component<Props, State> {
  state: State = {
    isEdit: null,
    squad: {},
    changedFields: {},
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isEdit } = this.state;

    if (isEdit && !prevState.isEdit) {
      document.addEventListener('keyup', this.handlePressEnter as AnyFunction);
    }
  }

  handlePressEnter = (e: React.KeyboardEvent) => {
    const { isEdit } = this.state;

    if (e.keyCode === 13) {
      this.saveSquad(String(isEdit));
    }
  };

  onChange = (name: string, value: any, changedValue: any, callback: Function = () => {}): void => {
    if (!value) {
      return;
    }

    this.setState(
      {
        ...this.state,
        squad: {
          ...this.state.squad,
          label: value,
        },
        changedFields: {
          ...this.state.changedFields,
          label: changedValue,
        },
      },
      () => {
        const constrainsField = {
          [name]: squadValidation,
        };

        oneFieldValidation(action, { [name]: value }, constrainsField);
      },
    );

    callback && callback();
  };

  getSquadField = (): React.ReactNode => {
    const { distance, item, isLastItem, onVisible } = this.props;
    const { squad, isEdit } = this.state;

    const field = item.label;

    return (
      <TableCell className={classNames('cell')} key={`${field}${item.key}`}>
        <SquadField
          field={field}
          item={(isEdit ? squad : item) as any}
          isEdit={isEdit}
          onChange={this.onChange}
          saveSquad={this.saveSquad}
          distance={distance}
          isLastItem={isLastItem}
          onVisible={onVisible}
        />
      </TableCell>
    );
  };

  editSquad = (field: string) => {
    const { item } = this.props;

    this.setState({
      isEdit: field,
      squad: item,
      changedFields: {},
    });
  };

  cancelEdit = () => {
    this.setState({
      isEdit: null,
      squad: {},
      changedFields: {},
    });
  };

  saveSquad = async (field: string) => {
    const { squad, changedFields } = this.state;

    if (!field) {
      return this.cancelEdit();
    }

    const constrainsField = {
      [field]: squadValidation,
    };

    const isValidate = oneFieldValidation(action, { [field]: changedFields.label }, constrainsField);

    if (!isValidate || isEmpty(changedFields) || !squad.value) {
      return this.cancelEdit();
    }

    const status = await this.props.onEditSquad(squad.value, squad.label);

    if (status) {
      document.removeEventListener('keyup', this.handlePressEnter as AnyFunction);
      this.cancelEdit();
    }

    errorsStore.clearFrontendError(action);
  };

  render() {
    const { item, onDeleteSquadWrapper } = this.props;

    return (
      <TableRow key={`squad-${item.key}`} id={item.key as any}>
        {this.getSquadField()}

        <TableCell className={classNames('cell')}>
          <Controls handleEdit={() => this.editSquad(item.label)} handleDelete={onDeleteSquadWrapper(item.value, item.label) as any} />
        </TableCell>
      </TableRow>
    );
  }
}

export { Item };

import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ERRORS_PREFIXES } from 'modules/Distances/constants';
import { clearNamespaceErrors } from 'modules/Distances/utils/errors';
import * as React from 'react';
import shortid from 'shortid';

import { DISTANCE_STEPS, DRAG_IDS, PERSIST_DISTANCE as action } from 'src/constants';
import { ItemDnd } from 'src/modules/Distances/components/shared/Steps/Classes';

import { withErrorClean } from 'hocs';

import { DNDContainer } from 'components/DragNDrop';

import { reorderUtil, t } from 'utils';

import { Race as RaceModel } from 'models';

import { State as OriginalFormState } from '../../../../shared/stateHelpers';

type Props = {
  formData: OriginalFormState;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
    isDirty?: boolean,
  ) => void;
  helperData: {
    race: nil | RaceModel;
  };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

const stepAction = `${action}_${DISTANCE_STEPS.classes}`;

@withErrorClean(stepAction)
class Classes extends React.Component<Props> {
  componentDidMount() {
    this._addDefaultValues();
  }

  onChange = (values: Array<ClassType>, callback: Function = () => {}, isNeedLiveUpdate = true) => {
    const { onChange, liveUpdate } = this.props;

    isNeedLiveUpdate && liveUpdate({ classes: values }, true);
    onChange(
      {
        classes: values,
      },
      '',
      () => {
        callback();
      },
      true,
    );
  };

  onDragEnd = (result: AnyObject): void => {
    const { classes } = this.props.formData;
    if (!result?.destination) {
      return;
    }
    const items = reorderUtil.changePosition([...classes], result.source.index, result.destination.index);
    this.onChange(items);
  };

  onAddNew = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { classes } = this.props.formData;
    const values = [...classes, getDistanceClass(false, classes.length)];

    this.onChange(
      values,
      () => {
        clearNamespaceErrors(ERRORS_PREFIXES.classes);
      },
      false,
    );
  };

  onRemove = (id: number | string) => {
    const { classes } = this.props.formData;
    const values = classes
      .filter((cls) => !cls._delete)
      .reduce((acc: any, value: AnyFunction | any) => {
        if (value.id === id) {
          return [
            ...acc,
            {
              ...value,
              _delete: true,
            },
          ];
        } else if (value.__id === id) {
          return acc;
        } else {
          return [...acc, value];
        }
      }, []);

    this.onChange(values, () => {
      clearNamespaceErrors(ERRORS_PREFIXES.classes);
    });
  };

  onChangeDistanceClass = (id: number | string, changedDistanceClass: ClassType, callback: Function = () => {}) => {
    const { classes } = this.props.formData;

    const newData = classes.map((distanceClass) =>
      distanceClass.id === id || distanceClass.__id === id ? changedDistanceClass : distanceClass,
    );

    this.onChange(newData, callback);
  };

  // _renderDistanceClasses = (): React.ReactNode => {
  //   const { formData } = this.props;
  //   const { classes } = formData;
  //   const valuesForRender = filterDeleted(classes);
  //
  //   return valuesForRender.reduce((acc: any, distanceClass: AnyFunction | any, index: number) => {
  //     const separator = acc.length ? [<li className='separator' key={`separator-${index}`}></li>] : [];
  //     return [
  //       ...acc,
  //       ...separator,
  //       <Item
  //         key={distanceClass.id || distanceClass.__id}
  //         index={index}
  //         value={distanceClass}
  //         onChange={this.onChangeDistanceClass}
  //         onRemove={this.onRemove}
  //       />,
  //     ];
  //   }, []);
  // };

  _renderDistanceClasses = (): React.ReactNode => {
    const { formData } = this.props;
    const { classes } = formData;
    const valuesForRender = filterDeleted(classes);

    return valuesForRender.reduce((acc: any[], distanceClass: ClassType | any, index: number) => {
      const keyId = distanceClass.id || distanceClass.__id;
      return [
        ...acc,
        <ItemDnd key={keyId} index={index} value={distanceClass} onChange={this.onChangeDistanceClass} onRemove={this.onRemove} />,
      ];
    }, []);
  };

  _addDefaultValues = () => {
    const { classes } = this.props.formData;
    const { onChange } = this.props;

    if (!classes.filter((el) => !el._delete).length) {
      onChange({ classes: [getDistanceClass(true)] }, '', () => {});
    }
  };

  // render() {
  //   return (
  //     <div className='distance-class-fields-form form-content-wrapper'>
  //       <div className='add-new' onClick={this.onAddNew}>
  //         <Icon value='add-small' className='icon' />
  //         <Typography className='label'>{t.staticAsString('distances.steps.classesForm.add')}</Typography>
  //       </div>
  //       <ul className='distance-classes-list'>{this._renderDistanceClasses()}</ul>
  //       {this.props.controls}
  //     </div>
  //   );
  // }
  render() {
    return (
      <div className='distance-wave-fields-form form-content-wrapper'>
        <Box mb={4} style={{ cursor: 'pointer' }} display='flex' justifyContent='center' alignItems='center' onClick={this.onAddNew}>
          <AddCircleOutline style={{ marginRight: 10 }} color='primary' className='icon' />
          <Typography color='primary'>{t.staticAsString('distances.steps.classesForm.add')}</Typography>
        </Box>
        <DNDContainer droppableId={DRAG_IDS.CLASSES} className='custom-field-list' onDragEnd={this.onDragEnd}>
          {this._renderDistanceClasses()}
        </DNDContainer>
        {this.props.controls}
      </div>
    );
  }
}

const getDistanceClass = (isFirst: boolean = false, classesLength?: number): ClassType | any => {
  return {
    index: classesLength ?? 0,
    __id: shortid(),
    title: '',
    _new: isFirst,
  };
};

const filterDeleted = (values: Array<ClassType>): Array<ClassType> => {
  return values.filter((el) => !el._delete);
};

const normalizeDataForBackend = (
  values: ClassType[],
): Array<
  | {
      _delete?: boolean;
      id?: number;
    }
  | ClassType
> => {
  return values.map((el) => {
    if (!!el._delete) {
      return { id: el.id, _delete: true };
    }

    return { ...el, _delete: false };
  });
};

export { Classes, getDistanceClass, normalizeDataForBackend };

import { isEmpty } from 'lodash';
import { toJS } from 'mobx';

import { form, toastStore } from 'src/stores';
import { history, t } from 'src/utils';

import { formToValidRole } from '../../utils';

import { SystemUserFormPageService } from '../../services/systemUserFormPage.service';

import { SystemUserFormPageMappers } from '../../mappers/systemUserFormPage.mappers';
import { SystemUserForm } from '../../types';

const createSuccessfulToast = (name: string): void => {
  toastStore.show(t.staticAsString('systemUsers.createSuccess', { name }));
};

export const createSystemUserAction = async (systemUser: SystemUserForm): Promise<void> => {
  const data = SystemUserFormPageMappers.toBackend(systemUser);
  const listSelectedCurrencies = systemUser.selectedCurrencies;
  let listActions: Array<Promise<void>> = [];

  const [isOk, response] = await SystemUserFormPageService.create(data);

  if (!isOk) return;

  const createdUserId = response?.data.data.id;
  const fullName = response?.data.data.full_name;

  if (!['organizer'].includes(formToValidRole(systemUser.role))) {
    createSuccessfulToast(fullName);
    history.goBack();

    return;
  }

  if (!isEmpty(listSelectedCurrencies)) {
    for await (const currency of listSelectedCurrencies) {
      const currencyObject = toJS(form.fetch<AnyObject>(currency?.label as string));
      const mappedPaymentDetails = SystemUserFormPageMappers.toBackendPaymentDetails(currencyObject, currency?.label as string);

      listActions.push(SystemUserFormPageService.createPaymentDetails(createdUserId, mappedPaymentDetails));
    }

    await Promise.all(listActions);

    createSuccessfulToast(fullName);
    history.goBack();
  } else {
    createSuccessfulToast(fullName);
    history.goBack();
  }
};

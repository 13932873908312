import { flattenDeep, isEmpty } from 'lodash';

import { form } from 'stores';

import { CAMPAIGN_LINKS_FORM_ID } from '../constants';

import { distancesDataStore, distanceStore, wavesStore } from '../stores';

export const chosenDistance = () => {
  const distance = form.fetch(CAMPAIGN_LINKS_FORM_ID, 'distance_id') as any;
  if (!distance) {
    return null;
  }
  const distanceID = distance.value;

  return !isEmpty(distancesDataStore.value)
    ? distancesDataStore.value?.find((distance) => distance.id === distanceID)
    : distanceStore.value;
};

export const distanceHasPayableFeatures = () => {
  const distance = chosenDistance();
  const isPayableDistance = Boolean(distance?.price);
  const CFValuesPrices = flattenDeep(distance?.custom_fields?.map((cf) => cf.values.map((cfValues: AnyObject) => cfValues.price)));
  const hasPayableCF = CFValuesPrices?.some((price) => Boolean(Number(price)));

  return isPayableDistance || hasPayableCF;
};

export const waveMaxMembers = () => {
  const wave = form.fetch(CAMPAIGN_LINKS_FORM_ID, 'wave_id') as any;
  if (!wave) {
    return;
  }
  const currentWave = wavesStore.value?.find((waveEl) => waveEl.value === wave.value);
  return currentWave?.slots;
};

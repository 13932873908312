import shortId from 'shortid';

import { t } from 'utils';

import { AMOUNT_TYPE_FIXED, AMOUNT_TYPE_ORDER, AMOUNT_TYPE_PERCENTAGE } from '../constants';

export const optionsAmountTypes = (): SelectOption[] => {
  return [
    {
      key: shortId(),
      label: t.staticAsString('supportTaskManager.form.fullOrderAmount'),
      value: AMOUNT_TYPE_ORDER,
    },
    {
      key: shortId(),
      label: t.staticAsString('supportTaskManager.form.fixed'),
      value: AMOUNT_TYPE_FIXED,
    },
    {
      key: shortId(),
      label: t.staticAsString('supportTaskManager.form.percentage'),
      value: AMOUNT_TYPE_PERCENTAGE,
    },
  ];
};

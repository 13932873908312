import isEmpty from 'lodash/isEmpty';
import * as React from 'react';

import { NavElement } from './NavElement';
import { Separator } from './Separator';

type Props = {
  items: Array<NavToolbarItemType>;
};

const generateItems = (items: Array<NavToolbarItemType>) => {
  return items.reduce((acc: any[], navItem: NavToolbarItemType) => {
    if (isEmpty(acc)) {
      return [<NavElement key={navItem.key} item={navItem} />];
    } else {
      return [...acc, <Separator key={`${navItem.key || ''}_separator`} />, <NavElement key={navItem.key} item={navItem} />];
    }
  }, []);
};

const NavToolbar = ({ items }: Props) => {
  return <div className='nav-toolbar'>{generateItems(items)}</div>;
};

export { NavToolbar };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { ORGANIZER_RACERS_URL, ORGANIZER_RACES_URL, ORGANIZER_DISTANCES_URL } from 'src/constants';

import { request, action } from 'utils';

import { LOAD_RACERS, LOAD_RACES_NAMES, LOAD_DISTANCES_NAMES } from '../constants';

import { loadRacers as loadRacersAction } from '../actions';

import { racersStore } from '../stores';

export class RacersService implements ExtensionableService<unknown>, ExtensionableSearchService, ExtensionableSortService {
  @request({ action: LOAD_RACERS })
  loadRacersRequest(params: nil | FiltersType): any {
    const path = generatePath(ORGANIZER_RACERS_URL);
    return axios.get(path, { params });
  }

  @action({ action: LOAD_RACERS })
  async loadRacers(params: nil | FiltersType) {
    const [status, response] = await this.loadRacersRequest(params);

    return [status, response.data];
  }

  get store() {
    return racersStore;
  }

  @action({ action: LOAD_RACERS })
  async load(params?: { limit?: number; page?: number }): Promise<void> {
    await loadRacersAction(params);
  }

  @request({ action: LOAD_DISTANCES_NAMES })
  loadDistancesNamesRequest(params: nil | FiltersType): any {
    const path = generatePath(ORGANIZER_DISTANCES_URL);
    return axios.get(path, { params });
  }

  @action({ action: LOAD_DISTANCES_NAMES })
  async loadDistancesNames(params: nil | FiltersType) {
    const [status, response] = await this.loadDistancesNamesRequest(params);

    return [status, response.data];
  }
  @request({ action: LOAD_RACES_NAMES })
  loadRacesNamesRequest(params: nil | FiltersType): any {
    const path = generatePath(ORGANIZER_RACES_URL);
    return axios.get(path, { params });
  }

  @action({ action: LOAD_RACES_NAMES })
  async loadRacesNames(params: nil | FiltersType) {
    const [status, response] = await this.loadRacesNamesRequest(params);

    return [status, response.data];
  }
}

const racersService = new RacersService();

export { racersService };

import { Edit as EditIcon } from '@mui/icons-material';
import * as React from 'react';

import { time, t, history } from 'utils';

import { Organizer as OrganizerModel } from 'models';

type Props = {
  value: OrganizerModel;
  onEdit: () => void;
};

export function AccountInfo({ value, onEdit }: Props) {
  const jsonValue = value.value;
  const createdAt = time.datetime(jsonValue.created_at).parse().format('date');

  const handleEdit = () => {
    history.push(`/system-users/${jsonValue.id}/edit`);
  };

  return (
    <div className='account-info-wrapper'>
      <div className='account-info-title-wrapper'>
        <h3 className='account-info-title'>{t.staticAsString('organizers.detail.accountTitle')}</h3>
        <EditIcon className='icon' onClick={handleEdit} />
      </div>
      <div className='account-info-body'>
        <div className='info-item'>
          <p className='info-label'>{t.staticAsString('organizers.detail.full_name')}</p>
          <p className='info-value'>{jsonValue.full_name}</p>
        </div>

        <div className='info-item'>
          <p className='info-label'>{t.staticAsString('organizers.detail.email')}</p>
          <p className='info-value'>{jsonValue.email}</p>
        </div>

        <div className='info-item'>
          <p className='info-label'>{t.staticAsString('organizers.detail.phone')}</p>
          <p className='info-value'>{jsonValue.phone}</p>
        </div>

        <div className='info-item'>
          <p className='info-label'>{t.staticAsString('organizers.detail.created_at')}</p>
          <p className='info-value'>{createdAt}</p>
        </div>
      </div>
    </div>
  );
}

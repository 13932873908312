import { observable, action, makeObservable } from 'mobx';

import { RacerRace } from '../models';

import { RaceName } from '../types';

export class NameFilters {
  defaultFilters = {
    filter: 'name;id',
    with: 'distances',
    limit: -1,
  };

  @observable
  paginationMeta: PaginationMeta = {};

  constructor() {
    makeObservable(this);
  }

  @action
  addRaceNamesPaginationMeta(meta: PaginationMeta) {
    this.paginationMeta = meta;
  }

  @observable
  raceNamesValues: Array<RaceName> = [];

  @action
  addRaceNames(values: Array<RaceName>) {
    this.raceNamesValues = values;
  }
  @observable
  distanceNamesValues: Array<RaceName> = [];

  @action
  addDistanceNames(values: Array<RaceName>) {
    this.distanceNamesValues = values;
  }

  @action
  clearAll() {
    this.raceNamesValues = [];
    this.distanceNamesValues = [];
  }

  getNameById(id: number, values: Array<RaceName>): string {
    const raceName = values.find((el) => {
      return +el.id === +id;
    });

    if (!raceName) {
      return '';
    }

    const race = new RacerRace(raceName);

    return race.value.name;
  }

  @action
  getRaceNameById(id: number): string {
    return this.getNameById(id, this.raceNamesValues);
  }

  @action
  getDistanceNameById(id: number): string {
    return this.getNameById(id, this.distanceNamesValues);
  }

  @action
  filterDistancesByRaceId(id: number) {
    this.distanceNamesValues = this.distanceNamesValues.filter((distance) => distance.race_parent_id === id);
  }

  @action
  filterRacesByDistanceId(id: number) {
    const distance = this.distanceNamesValues.find((distance) => +distance.id === +id);

    if (!distance) return;

    this.raceNamesValues = this.raceNamesValues.filter((race) => race.id === distance.race_parent_id);
  }
}

const nameFilters = new NameFilters();
export { nameFilters };

import { time } from 'utils';

function formatDistanceLoggedAt(value: string | nil) {
  if (!value) {
    return null;
  }

  return time(value, 'date').ifIsValid()?.parse().format('shortDate') || null;
}

export { formatDistanceLoggedAt };

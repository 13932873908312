import { Collapse } from '@mui/material';
import { Observer } from 'mobx-react-lite';
import * as React from 'react';
import { FC } from 'react';

import { InputWrapper, AutocompleteSelectInfinityLoad } from 'src/components/Form';
import { form } from 'src/stores';
import { t } from 'src/utils';

import { EQ_FORM_ID } from '../../constants';

import { handleAddItem, handleDeleteItem } from '../../actions';

import { SyncContainer, SyncItem } from '../../../Shared';
import { showWavePlus, getSyncWavesOptions, getRaceWavesOptions, showWave } from '../../selectors';
import { MapItemType } from '../../types';

type Props = {
  distanceIndex: number;
};

export const Waves: FC<Props> = ({ distanceIndex }) => {
  const prefix = `distances.${distanceIndex}`;
  return (
    <Observer>
      {() => {
        const data = form.fetch<MapItemType[]>(EQ_FORM_ID, `${prefix}.waves`) || [];
        const distance = form.fetch<MapItemType>(EQ_FORM_ID, prefix);
        if (!distance) return null;
        return (
          <SyncContainer
            title={t.staticAsString('translations.title.waves')}
            addText={t.staticAsString('sync.add_wave')}
            onAdd={handleAddItem(`${prefix}.waves`)}
            showPlus={showWavePlus(`${prefix}.waves`, Number(distance.internal_id?.key), Number(distance.external_id?.key))}
            showBlock={showWave.get()}
          >
            {data.map((item, index) => {
              return (
                <Collapse key={item.id}>
                  <SyncItem onDelete={handleDeleteItem(`${prefix}.waves`, item.id)}>
                    <InputWrapper
                      name={`${prefix}.waves.${index}.internal_id`}
                      settings={{
                        label: t.staticAsString('sync.wave.raceid.title'),
                        additional: {
                          options: getRaceWavesOptions(Number(distance.internal_id?.key), `${prefix}.waves`)(),
                        },
                        view: {
                          reservePlaceForHelperText: true,
                        },
                      }}
                      Component={AutocompleteSelectInfinityLoad}
                    />
                    <InputWrapper
                      key={item.id}
                      name={`${prefix}.waves.${index}.external_id`}
                      settings={{
                        label: t.staticAsString('sync.waves.external_title'),
                        additional: {
                          options: getSyncWavesOptions(Number(distance.external_id?.key)),
                        },
                        view: {
                          reservePlaceForHelperText: true,
                        },
                      }}
                      Component={AutocompleteSelectInfinityLoad}
                    />
                  </SyncItem>
                </Collapse>
              );
            })}
          </SyncContainer>
        );
      }}
    </Observer>
  );
};

import { filterFormatValue } from '../filterLabelFormatters';

export const string: FilterDescriptor = {
  persistance: {
    handler: 'like',
    calcValue: function (name, value) {
      return value;
    },

    prettify: function (name, value) {
      return filterFormatValue(value);
    },
  },
};

class Team {
  value: TeamType;
  namespace: nil | string;

  constructor(value: TeamType, namespace?: string) {
    this.value = value;
    this.namespace = namespace;
  }

  setFieldIdPrefix(prefix: string) {
    this.namespace = prefix;
  }

  labelWithCounter(maxMembersInTeam: number): string {
    const { value } = this;
    return `${value.name}(${value.racers_count || 0}/${maxMembersInTeam})`;
  }

  generateFieldId(columnName: string): string {
    return [this.namespace, columnName].join('.');
  }

  generateStoreOption(property: string): {
    property: string;
    storeId: string;
  } {
    return { property, storeId: this.generateFieldId(property) };
  }

  generateStoreOptions(...properties: string[]): {
    property: string;
    storeId: string;
  }[] {
    return properties.map((property) => this.generateStoreOption(property));
  }

  forSelect(formatFun: (fal: TeamType) => string): SelectOption {
    const label = formatFun ? formatFun(this.value) : this.value.name;

    return {
      label,
      value: this.value.id,
      key: this.value.id,
    };
  }
}

export { Team };

import { Moment } from 'moment';

import { BE_DATETIME_FORMAT } from 'src/constants/time';

import { TransactionTableFilters } from '../types';

const DateFields = ['created_at', 'updated_at'];
const LikeFields = ['participant.email', 'participant.firstname', 'participant.lastname', 'title'];
const BetweenFields = ['id', 'order.total', 'discount_amount'];

export function filtersToBackend(filters: TransactionTableFilters[]) {
  const searchFilters = filters.reduce(
    (acc, { id, value }) => {
      if (DateFields.includes(id)) {
        acc.search += `${id}:${mapDaterange(value.startDate, value.endDate)};`;
        acc.searchFields += `${id}:between;`;
      }
      if (LikeFields.includes(id)) {
        acc.search += `${id}:${value};`;
        acc.searchFields += `${id}:like;`;
      }
      if (BetweenFields.includes(id)) {
        const [start, end] = value;
        switch (id) {
          case 'order.total':
          case 'discount_amount':
            if (start === undefined) {
              acc.search += `${id}:${end * 100};`;
              acc.searchFields += `${id}:<=;`;
              break;
            }
            if (end === undefined) {
              acc.search += `${id}:${start * 100};`;
              acc.searchFields += `${id}:>=;`;
              break;
            }
            acc.search += `${id}:${value.map((el) => el * 100).join(',')};`;
            acc.searchFields += `${id}:between;`;
            break;
          case 'id':
            if (start === undefined) {
              acc.search += `${id}:${end};`;
              acc.searchFields += `${id}:<=;`;
              break;
            }
            if (end === undefined) {
              acc.search += `${id}:${start};`;
              acc.searchFields += `${id}:>=;`;
              break;
            }
          default:
            acc.search += `${id}:${value.join(',')};`;
            acc.searchFields += `${id}:between;`;
        }
      }
      return acc;
    },
    {
      search: '',
      searchFields: '',
    },
  );

  searchFilters.search = searchFilters.search.substring(0, searchFilters.search.length - 1);
  searchFilters.searchFields = searchFilters.searchFields.substring(0, searchFilters.searchFields.length - 1);
  return searchFilters;
}

const mapDaterange = (start: Moment, end: Moment): string => {
  return `${start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format(BE_DATETIME_FORMAT)},${end
    .set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
    .format(BE_DATETIME_FORMAT)}`;
};

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { API_WAVES_SYNC, RACE_WAVES_URL } from 'src/constants';
import { request, action } from 'src/utils';

import { WAVES_EQ_REQUEST } from '../constants';

import { mapOptions, optionsWithParentMap } from '../utils';

import { syncWavesStore, raceWavesStore } from '../stores';

import { raceDistancesStore, syncDistancesStore } from '../../Shared';

class SyncWaves {
  @request({ action: WAVES_EQ_REQUEST })
  async getWavesRequest(id: number): Promise<any> {
    const url = generatePath(API_WAVES_SYNC, { id });
    return axios.get(url);
  }

  @action({ action: WAVES_EQ_REQUEST })
  async getWaves(id: number) {
    const [status, response] = await this.getWavesRequest(id);

    if (status) {
      syncWavesStore.set(optionsWithParentMap(response.data.data, syncDistancesStore.value as SelectOption[]));
    }
  }
}

class RaceWaves {
  @request({ action: WAVES_EQ_REQUEST })
  async getWavesRequest(id: number): Promise<any> {
    const url = generatePath(RACE_WAVES_URL, { id });
    return axios.get(url);
  }

  @action({ action: WAVES_EQ_REQUEST })
  async getWaves(id: number) {
    const [status, response] = await this.getWavesRequest(id);

    if (status) {
      raceWavesStore.set(mapOptions(response.data.data, raceDistancesStore.value || [], 'name'));
    }
  }
}

export const syncWavesService = new SyncWaves();
export const raceWavesService = new RaceWaves();

import { action, makeObservable, override } from 'mobx';

import { Loadable, Sort, Search } from 'stores';

import { teamFiltersDescriptors } from '../Filters';

class Teams extends Loadable<TeamType, FiltersType> {
  declare filters: FiltersType;

  static defaultFilters = {
    limit: 200,
    with: 'class;owner.order',
    searchJoin: 'AND',
  };

  distanceId: number = 0;

  sort = new Sort(teamFiltersDescriptors);
  search = new Search(teamFiltersDescriptors);
  extensions = [this.sort, this.search];

  constructor() {
    super();

    makeObservable(this);

    this.filters = Teams.defaultFilters;
  }

  @action
  updateCurrentDistance(distanceId: number) {
    this.distanceId = distanceId;
  }

  @override
  clearFilters() {
    super.clearFilters();
    this.filters = Teams.defaultFilters;
  }
}

export { Teams, Teams as TeamsStore };

import React from 'react';

import { RangeFilter } from 'components/Filter';

import { FilterProps } from './index';

type Props = {
  value: [number | string | null, number | string | null];
} & FilterProps;

const IdRange = (props: Props) => {
  let value = props.value;

  if (!value) {
    value = [null, null];
  }

  return <RangeFilter {...(props as any)} value={value} type={'number'} />;
};

export { IdRange };

import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ROUTES } from 'src/constants';

import { EQForm } from './EQTiming';
import { RaceResultsForm } from './RaceResults';
import { SynchronisationTableWrapper } from './Table';

export const Synchronisation = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.sync} component={SynchronisationTableWrapper} />
      <Route path={ROUTES.createRRSync} component={RaceResultsForm} />
      <Route path={ROUTES.createEQSync} component={EQForm} />
    </Switch>
  );
};

import { Box } from '@mui/material';
import { Commission } from 'modules/Reconciliation/Commissions/types/commissions.type';
import { Link } from 'react-router-dom';
import { ColumnWithLooseAccessor } from 'react-table';

import { t } from 'src/utils';

const COLUMNS = ['email', 'link'] as const;

const title = (prefix: string) => t.static(`reconciliation.commissions.races.${prefix}` as any);

const RenderColumns: { [k in typeof COLUMNS[number]]: ColumnWithLooseAccessor<Commission.TableRow> & AnyObject } = {
  email: {
    id: 'email',
    Header: title('email'),
    accessor: (commission) => commission.email,
    Cell: ({ row: { original } }) => <>{original?.email?.toLocaleUpperCase()}</>,
    filterable: true,
  },
  link: {
    id: 'link',
    Header: title('commissionDetails'),
    Cell: ({ row: { original } }) => {
      return (
        <Link to={`/reconciliation/commissions/${original.id}`}>
          <Box sx={{ color: 'black', textDecoration: 'underline' }}>{title('linkLabel')}</Box>
        </Link>
      );
    },
    filterable: false,
    sortable: false,
    disableFilters: true,
    disableSortBy: true,
  },
};

export function generateCommissionColumns() {
  return COLUMNS.map((c) => RenderColumns[c]);
}

import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import styled from 'styled-components';

import { SPORT_TYPE } from 'src/constants';

import { withErrorClean } from 'hocs';

import { Input } from 'components/Fields/Input';
import { SelectField } from 'components/Fields/SelectField';

import { t, validation } from 'utils';

import { Errors as ErrorsStore, Locale as LocaleStore, Progress as ProgressStore, SportType as SportTypeStore } from 'stores';

import { editSportType } from '../../services/editSportType';

import { sportType as sportTypeConstrains } from '../../validations';
import { Props, SportTypeForm, State } from '../Form';
import { IconField } from '../Form/IconField';

type EditProps = {
  sportTypesStore: SportTypeStore;
  progressStore: ProgressStore;
  errorsStore: ErrorsStore;
  localeStore: LocaleStore;
} & Props;

type EditState = {
  category: {
    icon: string | Blob | null;
    title: string;
    title_en: string;
    id: number;
    active: number;
  };
} & State;

export const WrapperForm = styled.div`
  .MuiFormControl-root {
    margin: 0 0 40px 0;
  }
`;

export const WrapperSpeed = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const IconTooltipWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin: -22px 0 0 18px;

  .MuiSvgIcon-root {
    margin: 0;
  }
`;

export const StyledInfoOutlined: AnyFunction | any = withStyles((theme) => ({
  root: {
    cursor: 'pointer',
    color: theme.palette.grey6,
    marginLeft: 7,
  },
}))(InfoOutlined);

@withErrorClean(`UPDATE_${SPORT_TYPE}`)
@inject('sportTypesStore', 'errorsStore', 'localeStore', 'progressStore')
@observer
class EditSportType extends SportTypeForm<EditProps, EditState> {
  state = {
    category: {
      id: 0,
      title: '',
      title_en: '',
      max_speed: '',
      icon: null,
      active: 1,
    },
  };

  action = `UPDATE_${SPORT_TYPE}`;

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.validate();

    const validationErrors = this.props.errorsStore.errors.validation[this.action];
    if (validationErrors && validationErrors.flatErrors().length > 0) return;

    const sportType: { icon: string } | any = { ...this.state.category };
    if (typeof sportType.icon !== 'object') {
      delete sportType.icon;
    }

    editSportType.update(sportType);
  };

  componentDidUpdate(
    prevProps: Props & {
      sportTypesStore: SportTypeStore;
    },
  ) {
    const { errorsStore, sportTypesStore } = this.props;

    const category = sportTypesStore.selected;
    if (this.state.category.id !== (category && category.id) && category) {
      errorsStore.clearFrontendError(this.action);
      this.setState({
        category: {
          ...category,
        },
      });
    }
  }

  errors = () => {
    const { errorsStore } = this.props;
    const errors = errorsStore.errors.api[this.action];
    const validationErrors = errorsStore.errors.validation[this.action];
    if (validationErrors) {
      return validationErrors.formatErrors();
    }
    if (errors) {
      return errors.formatErrors();
    }
    return {};
  };

  title = (): string | any => {
    const { sportTypesStore } = this.props;
    const modelValue = sportTypesStore.modelSelected;

    if (!modelValue) {
      return '';
    }

    return modelValue.title();
  };

  @validation({ action: `UPDATE_${SPORT_TYPE}`, constrains: sportTypeConstrains })
  validate() {
    return this.state.category;
  }

  renderForm = () => {
    const { category } = this.state;
    const { sportTypesStore, progressStore } = this.props;
    const formId = sportTypesStore.selected && sportTypesStore.selected.id;
    const errors = this.errors();
    const disabled = progressStore.isLoading(this.action);

    const title = this.title();

    return (
      <form className='sport-type-form edit-form' id={`sport-type${formId || ''}`} onSubmit={this.onSubmit}>
        <WrapperForm className='form-content-wrapper'>
          <Input
            id={this.id}
            onChange={this.onChange}
            value={category.title}
            name='title'
            errors={errors.title}
            label={t.staticAsString('categories.name') + ' (SV)'}
          />
          <Input
            id={this.id}
            onChange={this.onChange}
            value={category.title_en}
            name='title_en'
            errors={errors.title_en}
            label={t.staticAsString('categories.name') + ' (EN)'}
          />
          <SelectField
            id={this.id}
            onChange={this.onChange}
            value={category.active.toString()}
            name='active'
            errors={errors.status}
            label={t.staticAsString('categories.status')}
            options={this.statusOptions()}
            fullWidth
          />
          <WrapperSpeed>
            <Input
              id={this.id}
              onChange={this.onChange}
              value={category.max_speed}
              name='max_speed'
              errors={errors.max_speed}
              required
              label={t.staticAsString('categories.maxSpeed')}
            />

            <IconTooltipWrapper>
              <Tooltip title={t.staticAsString('categories.maxSpeed.tooltip')}>
                <StyledInfoOutlined />
              </Tooltip>
            </IconTooltipWrapper>
          </WrapperSpeed>

          <IconField name='icon' fileName={title} onChange={this.onFileChange} errors={errors.icon} value={category.icon} />
          <Button className='submit-button' type='submit' disabled={disabled}>
            {t('categories.save')}
          </Button>
        </WrapperForm>
      </form>
    );
  };

  render() {
    const title = this.title();

    return (
      <div className='main-sport-type'>
        <div className='toolbar'>
          <h1 className='sport-type-title'>{title}</h1>
        </div>
        <div className='list-wrapper'>{this.renderForm()}</div>
      </div>
    );
  }
}

export { EditSportType };

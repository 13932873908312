import * as React from 'react';

import { Actions } from '../../components/Toolbar';

import { RacersContext } from '../../context';
import { RacersContextType } from '../../context';

const ToolbarContainer = () => {
  const {
    results: {
      actions: { exportData },
    },
  } = React.useContext<RacersContextType>(RacersContext);

  return (
    <div className='toolbar racer-toolbar'>
      <div className='buttons'>
        <Actions onExportRacers={(e, type) => exportData(type)} />
      </div>
    </div>
  );
};

export { ToolbarContainer as Toolbar };

import axios from 'axios';
import { isNull, isUndefined, remove, sortBy, omit } from 'lodash';
import { DATA_NORMALIZER_FOR_BACKEND } from 'modules/Distances/components/shared/Steps';
import { isCalendarRaceType } from 'modules/Races/utils/isCalendarRaceType';
import { generatePath } from 'react-router-dom';

import { DISTANCE, DISTANCE_MODES, DISTANCE_STEPS, DISTANCE_URL, PERSIST_DISTANCE as createAction } from 'src/constants';

import { request, action, t, objectsDifference, prepareFile } from 'utils';

import { filesService } from 'services';

import { helperDistancesStore, toastStore } from 'stores';

import { formatData, redirectAfterUpdate, redirectToGPX, redirectToTranslations } from '../utils';
import { catchErrors } from '../utils/errors';

import { State as UserInputData } from '../shared/stateHelpers';
import { stateRelations } from '../shared/stateHelpers';

class UpdateDistance {
  @request({ action: createAction })
  updateRequest(url: string, data: Object, params?: any): any {
    return axios.patch(url, data, { params });
  }

  @action({ action: `UPDATE_${DISTANCE}` })
  async updateField(distanceId: number, data: AnyObject, params: any): Promise<[boolean, DistanceType]> {
    const url = generatePath(DISTANCE_URL, { distanceId });
    const [isOk, response] = await this.updateRequest(url, data, params);

    if (!isOk) {
      catchErrors(response.data.errors);
      return [isOk, response.data.errors];
    }
    return [isOk, response.data.data];
  }

  // Update logic
  @action({ action: createAction })
  async update(
    data: AnyObject,
    url: string,
    shouldRedirectToTranslations: boolean = false,
    shouldRedirectToGPX: boolean = false,
  ): Promise<any> {
    const [isOk, response] = await this.updateRequest(url, data);
    if (isOk) {
      const value: DistanceType = response.data.data as any;
      toastStore.show(t.staticAsString('distances.new.successUpdate', { name: value.name, id: value.id }));
      if (shouldRedirectToTranslations) {
        return redirectToTranslations(value.race_parent_id, value.id);
      }
      if (shouldRedirectToGPX) {
        return redirectToGPX(value.race_parent_id, value.id);
      }
      redirectAfterUpdate(value.race_parent_id);
    } else {
      catchErrors(response.data.errors);
    }
  }

  // Logic for user input data management
  async submitForDistanceUpdate(
    data: UserInputData,
    race: nil | RaceType,
    shouldRedirectToTranslations: boolean,
    shouldRedirectToGPX: boolean,
  ): Promise<any> {
    const defaultLocale = race!.pref_lang_code;
    const userInput = { ...data };
    let dataToSend: AnyObject = { ...userInput.distance };
    const isEmptyCustomFields = !(userInput.custom_fields || []).some((form) => !form._delete);
    const distanceMode = dataToSend.distance_mode || helperDistancesStore?.selected?.distance_mode;

    dataToSend = objectsDifference(dataToSend, helperDistancesStore.selected || {});

    const relationKeys = Object.keys(stateRelations);

    relationKeys.forEach((relation: string | any) => {
      dataToSend = {
        ...dataToSend,
        ...{
          [stateRelations[relation]]: formatData(relation, data),
        },
      };
    });

    const withoutPricesStep = !dataToSend.editor_settings.tab_options.some((option: string | any) => option === DISTANCE_STEPS.prices);

    if (isEmptyCustomFields) {
      remove(dataToSend.editor_settings.tab_options, (option) => option === DISTANCE_STEPS.custom_fields);
    } else {
      for (const cf of dataToSend.custom_fields) {
        if (cf.image?.size) {
          const submitData = prepareFile(cf.image);
          const resp = await filesService.upload(submitData);

          if (resp.isOk) {
            cf.image_uuid = resp.uuid;
            delete cf['image'];
          }
        }
        if (cf.image?.uuid) {
          cf.image_uuid = cf.image.uuid;
          delete cf['image'];
        }
        if (cf.image_uuid === null) delete cf['image_uuid'];
      }
    }

    if (isNull(dataToSend.vat_percents) || (!isCalendarRaceType && withoutPricesStep)) {
      dataToSend.vat_percents = null;
    }

    if (dataToSend.registration_fields) {
      dataToSend.registration_fields = dataToSend.registration_fields.map((item) => ({
        ...omit(item, ['distance']),
        selected: Number(item.selected),
      }));
    }

    if (distanceMode !== DISTANCE_MODES.CUMULATIVE) {
      delete dataToSend.goal;
    }

    if (dataToSend.distance_mode === DISTANCE_MODES.VIRTUAL || dataToSend.distance_mode === DISTANCE_MODES.CLASSIC) {
      dataToSend.goal = { _delete: true };
    }

    if (distanceMode === DISTANCE_MODES.CUMULATIVE) {
      dataToSend.goal = { ...(dataToSend.goal || {}), _delete: false };
    }

    if (dataToSend.distance_mode === DISTANCE_MODES.CLASSIC) {
      delete dataToSend.ends_at;
    }

    if (distanceMode !== DISTANCE_MODES.CLASSIC) {
      dataToSend.medical_assistants = [];
      dataToSend.editor_settings.tab_options = dataToSend.editor_settings.tab_options.filter(
        (option) => option !== DISTANCE_STEPS.medical_assistants,
      );
    }

    // ------------------------

    if (!isUndefined(dataToSend.allow_no_profile_registration)) {
      dataToSend.allow_no_profile_registration = Boolean(dataToSend.allow_no_profile_registration);
    }

    dataToSend[defaultLocale] = {};

    if (dataToSend.name) {
      dataToSend[defaultLocale] = {
        ...dataToSend[defaultLocale],
        name: dataToSend.name,
      };
    }
    delete dataToSend.name;

    dataToSend[defaultLocale] = {
      ...dataToSend[defaultLocale],
      description: dataToSend.description,
    };

    delete dataToSend.description;

    dataToSend.email_content = {
      [defaultLocale]: { content: dataToSend['email_content.content'] },
    };

    if (dataToSend.checkpoints?.length) {
      dataToSend.checkpoints = sortBy(dataToSend.checkpoints, (item) => Boolean(item._delete)).map((checkpoint) => ({
        [defaultLocale]: { name: checkpoint.name },
        ...omit(checkpoint, ['name']),
      }));
    }

    if (dataToSend.waves?.length) {
      dataToSend.waves = sortBy(dataToSend.waves, (item) => Boolean(item._delete)).map((wave) => ({
        [defaultLocale]: { name: wave.name },
        ...omit(wave, ['name']),
      }));
    }

    if (dataToSend.classes?.length) {
      dataToSend.classes = sortBy(dataToSend.classes, (item) => Boolean(item._delete)).map((cls) => ({
        [defaultLocale]: { title: cls.title },
        ...omit(cls, ['title']),
      }));
    }

    if (dataToSend.disciplines?.length) {
      dataToSend.disciplines = sortBy(dataToSend.disciplines, (item) => Boolean(item._delete)).map((disc) => ({
        [defaultLocale]: { title: disc.title },
        ...omit(disc, ['title']),
      }));
    }

    if (dataToSend.custom_fields?.length) {
      dataToSend.custom_fields = sortBy(dataToSend.custom_fields, (item) => Boolean(item._delete)).map((cf) => {
        let field = {
          ...omit(cf, ['name', 'helper_text']),
          [defaultLocale]: { name: cf.name, helper_text: cf.helper_text },
          is_required: Boolean(cf.is_required),
        };
        if (cf.values !== undefined) {
          field = {
            ...field,
            is_required: Boolean(cf.is_required),
            values: sortBy(cf.values, (item) => Boolean(item._delete)).map((cfv) => ({
              [defaultLocale]: { value: cfv.value },
              ...omit(cfv, ['value']),
              is_required: Boolean(cfv.is_required),
              price: cfv.price || 0,
            })),
          };
        }
        return field;
      });
    }

    if (dataToSend.race_qty !== undefined) {
      dataToSend.race_qty === 0 ? (dataToSend.race_qty = null) : (dataToSend.race_qty = Number(dataToSend.race_qty));
    }

    const distanceId = (data.distance && data.distance.id) || '';
    const url = generatePath(DISTANCE_URL, { distanceId });

    return await this.update(dataToSend, url, shouldRedirectToTranslations, shouldRedirectToGPX);
  }

  formatData(relation: string, data: UserInputData | any): any {
    const normalize = DATA_NORMALIZER_FOR_BACKEND[relation] || DATA_NORMALIZER_FOR_BACKEND.default;

    return normalize(data[relation]);
  }
}

export { UpdateDistance };
export default new UpdateDistance();

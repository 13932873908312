export const mapToTransferRegistration = (data: AnyObject): AnyObject => {
  if (!data) {
    return {
      from: getDefaultDate(),
      to: getDefaultDate(),
      price: getDefaultPrice(),
    };
  }

  return {
    from: data.from === '' ? getDefaultDate() : data.from,
    to: data.to === '' ? getDefaultDate() : data.to,
    price: data.price ? data.price : getDefaultPrice(),
  };
};

const getDefaultDate = () => null;
const getDefaultPrice = () => 0;

import { sessionStore } from 'src/stores';

import { organizerService } from '../services/organizer';

import { organizerStore } from '../stores';

async function loadOrganizer() {
  if (sessionStore.isOrganizer && sessionStore.user) {
    const organizerId = sessionStore.user.user_id;
    const organizer = await organizerService.loadOrganizer(organizerId);
    organizerStore.set(organizer);
  }
}

export { loadOrganizer };

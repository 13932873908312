import { observable, action, computed, makeObservable } from 'mobx';

import { DEFAULT_LOCALE } from 'src/constants';

class Locale {
  @observable
  value: availableLocales = DEFAULT_LOCALE;

  constructor() {
    makeObservable(this);
  }

  @action
  setLocale(value: availableLocales): void {
    this.value = value;
  }

  @computed
  get locale(): availableLocales {
    return this.value;
  }
}

export { Locale };
export default new Locale();

import flat from 'flat';
import { keys, merge, compact } from 'lodash';
import { FIELDS_STEPS_INTERSECTION_TABLE } from 'modules/Distances/components/shared/Steps';
import { DISTANCE_FORM_ERRORS } from 'modules/Distances/constants';

import { errorsStore } from 'stores';

const getRelationErrors = (index: number, errorPrefix: string, field?: string) => {
  return errorsStore.get(DISTANCE_FORM_ERRORS, compact([errorPrefix, index.toString(), field]).join('.')) ?? [];
};

const getDistanceErrors = (field: string) => {
  return errorsStore.get(DISTANCE_FORM_ERRORS, field);
};

const clearFieldError = (index: number, errorPrefix: string, field: string) => {
  return errorsStore.clean(DISTANCE_FORM_ERRORS, [errorPrefix, index.toString(), field].join('.'));
};

const clearNamespaceErrors = (errorPrefix: string) => {
  return errorsStore.clean(DISTANCE_FORM_ERRORS, errorPrefix);
};

const clearFieldsErrors = (fields: string[]) => {
  return errorsStore.clean(DISTANCE_FORM_ERRORS, ...fields);
};

const getStepErrors = (withFieldsNames = false) => {
  function multipleCheckers(checkers: string[], value: string): boolean {
    return checkers.some((checker) => RegExp(checker).test(value));
  }

  let errors = errorsStore.getAll(DISTANCE_FORM_ERRORS);
  if (!errors) {
    return [];
  }

  errors = flat(errors);
  const errorsKeys = keys(errors!);

  const stepNames = keys(FIELDS_STEPS_INTERSECTION_TABLE);

  const matchedStepErrors: string[] = [];
  const matchedStepErrorsWithFieldsName: {
    [key in string]: string;
  }[] = [];

  stepNames.forEach((step) => {
    const checkers = FIELDS_STEPS_INTERSECTION_TABLE[step];

    errorsKeys.forEach((errorKey) => {
      if (multipleCheckers(checkers, errorKey)) {
        if (!errors![errorKey]?.length) {
          return;
        }
        if (withFieldsNames) {
          matchedStepErrorsWithFieldsName.push({ [step]: errorKey.split('.')[0] });
        }
        matchedStepErrors.push(step);
      }
    });
  });

  return withFieldsNames ? matchedStepErrorsWithFieldsName : matchedStepErrors;
};

export { getDistanceErrors, clearFieldError, clearNamespaceErrors, getRelationErrors, getStepErrors, clearFieldsErrors };

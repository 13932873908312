import { observer } from 'mobx-react';
import * as React from 'react';

import { SelectFilterWithSearch } from 'components/Filter/DefaultFilters';

import { countriesStore } from 'stores';

type Props = {} & FilterProps;

@observer
class NationalityIdComponent extends React.Component<Props> {
  render() {
    const { props } = this;
    return <SelectFilterWithSearch {...props} options={countriesStore.valuesForNationalityDropdown()} />;
  }
}

export const nationalityId: FilterListDescriptor = {
  filter: {
    persistance: {
      handler: '=',

      calcValue: function (name, value) {
        return value;
      },

      prettify: function (name, value) {
        const countryModel = countriesStore.findModelValue(+value);
        return countryModel && countryModel.nationalityName();
      },
    },
  },

  frontend: {
    type: 'custom',
    component: NationalityIdComponent,
  },
};

import { styled } from '@mui/styles';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { DisplayJSON } from 'src/components/JSON';

import { taskStore } from '../stores';

const Title = styled('div')({
  fontSize: '16px',
  marginBottom: '8px',
});

const StyledDisplayJSON = styled(DisplayJSON)({
  marginBottom: '16px',
});

export const Info = () => {
  return (
    <Observer>
      {() => {
        const exceptions = taskStore.value?.output?.runtime_exceptions || [];
        return exceptions.map((item) => (
          <>
            <Title>Order ID: {item.order}</Title>
            <StyledDisplayJSON key={item.order} value={item.response} />
          </>
        ));
      }}
    </Observer>
  );
};

type Props = {};

type Args = [];

function stub(...args: Args): Props {
  const [] = args;

  return {};
}

stub.generate = function (...args: Args): Function {
  return () => stub(...args);
};

stub.default = function () {
  return stub();
};

export { stub };

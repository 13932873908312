import { Observer } from 'mobx-react';
import React from 'react';

import { Toolbar, Header, Transactions, SmartLinksDetailsWrapper, TopSection } from '../components';

import { mount, unmount } from '../actions';

import { smartLinkStore } from '../stores';

export const SmartLinksDetails = () => {
  React.useEffect(() => {
    mount();
    return () => {
      unmount();
    };
  }, []);

  return (
    <Observer>
      {() => {
        const smartLinkData = smartLinkStore.value;
        return !smartLinkData ? null : (
          <SmartLinksDetailsWrapper>
            <TopSection>
              <Toolbar />
              <Header smartLinkData={smartLinkData} />
            </TopSection>
            <Transactions token={smartLinkData.token} />
          </SmartLinksDetailsWrapper>
        );
      }}
    </Observer>
  );
};

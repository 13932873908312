import { set } from 'lodash';
import { action, makeObservable, override } from 'mobx';

import { Loadable, Sort, Search } from 'stores';

import { filterDescriptors } from '../filters';
import { CopyRacersTable as RacerType } from '../types';

const sortStore = new Sort(filterDescriptors);
const searchStore = new Search(filterDescriptors);

export class CopyRacers extends Loadable<RacerType, FiltersType> {
  declare values: Array<RacerType>;

  declare page;

  declare paginationMeta: PaginationMeta;

  declare filters: FiltersType;

  static defaultFilters = {
    orderBy: 'created_at',
    sortedBy: 'DESC',
    limit: 30,
    searchJoin: 'AND',
    search: '',
    searchFields: '',
  };

  sort = sortStore;
  search = searchStore;
  extensions = [this.sort, this.search];

  constructor() {
    super();

    makeObservable(this);
    this.filters = CopyRacers.defaultFilters;
    this.values = [];
    this.page = 1;
    this.paginationMeta = {};
  }

  @override
  addValues(values: Array<RacerType>, page: number, filters: FiltersType) {
    this.values = values;
    this.page = page;
    this.filters = filters;
  }

  @override
  appendValues(values: Array<RacerType>, page: number, filters: FiltersType) {
    this.values = page !== 1 ? (this.values = [...this.values, ...values]) : (this.values = [...values]);
    this.page = page;
    this.filters = filters;
  }

  @override
  addPaginationMeta(meta: PaginationMeta) {
    this.paginationMeta = meta;
  }

  @override
  addValue(value: RacerType, direction?: 'start' | 'end') {
    switch (direction) {
      case 'start':
        this.values = [value, ...this.values];
        return;
      case 'end':
        this.values = [...this.values, value];
        return;
      default:
        this.values = [...this.values, value];
    }
  }

  @override
  updateValue(value: RacerType) {
    const index = this.values.findIndex((e) => e.id === value.id);
    if (index !== -1) {
      this.values.splice(index, 1, value);
    }
  }

  @override
  updateValues(...values: RacerType[]) {
    values.forEach((el) => {
      this.updateValue(el);
    });
  }

  @override
  setValueByKey(key: string, value: any) {
    set(this.values, key, value);
  }

  @override
  clearData() {
    this.values = [];
  }

  @override
  removeValue(id: number) {
    this.values = this.values.filter((value) => value.id !== id);
  }

  // All merged params (extensions + builtin filters)
  @override
  get params(): FiltersType {
    const { filters, extensions } = this;

    return (extensions || []).reduce(
      (acc, ext) => {
        return { ...acc, ...ext.params };
      },
      { ...filters },
    );
  }

  @override
  clearAll() {
    super.clearAll();
    this.clearData();
    this.clearFilters();
    this.filters = CopyRacers.defaultFilters;
  }

  // READY ORDER FILTER
  @action
  async enableReadyOrder() {
    this.filters['has'] = 'readyOrder';
  }

  @action
  async disableReadyOrder() {
    this.filters['has'] = null;
    delete this.filters['has'];
  }

  @action
  async filterByName(name: string, value: number) {
    this.filters[name] = value;
  }

  @action
  async disableFilterByName(name: string) {
    this.filters[name] = null;
    delete this.filters[name];
  }
}

const copyRacersStore = new CopyRacers();
export { copyRacersStore };

import { isFunction } from 'lodash';
import * as React from 'react';

import { BACKEND_TIME_FORMAT } from 'src/constants';

import { FormattedDate } from 'components/FormattedDate';

import { commonDateFormats } from 'utils';

import { CustomDatePicker } from './custom/DatePicker';
import { CustomInput } from './custom/Input';
import { CustomMultiSelect } from './custom/MultiSelect';
import { Select } from './custom/Select';
import { CustomTimeInput } from './custom/Time';

type OriginalProps = {
  item: RacerType;
  distancesField: AnyObject;
  saveChanges: () => void;
  onChange: (name: string, value: any, changedValue: any, callback: Function) => void;
  isEdit: string | null;
  changedFields: AnyObject;
  distance: AnyObject;
};

type Props = OriginalProps & HOC.withIntl;

const CUSTOM_TYPE: AnyObject = {
  checkbox: CustomMultiSelect,
  textfield: CustomInput,
  drop_down: Select,
  radio: Select,
  multiple: CustomMultiSelect,
  date: CustomDatePicker,
  time: CustomTimeInput,
  extra: Select,
};

const CUSTOM_DATA: AnyObject = {
  checkbox: 'multipleData',
  textfield: 'inputData',
  drop_down: 'inputData',
  radio: 'inputData',
  multiple: 'multipleData',
  date: 'dateData',
  time: 'timeData',
  extra: 'inputData',
};

class CustomFieldsImpl extends React.Component<Props> {
  onChange = (value: any, changedValue: any, callback: Function = () => {}) => {
    const { onChange } = this.props;
    onChange('fields', value, changedValue, callback);
  };

  multipleData = (): Array<any> => {
    const { item, distancesField } = this.props;
    let currentField, value;
    const currency = item?.order_currency || '';
    currentField = item.fields?.filter((itemField) => itemField.field_id === distancesField.id);
    value = currentField?.map((item) => `${item.value}${this.renderPrice(item.price, currency)}`).join(', ');
    return [currentField, value];
  };

  dateData = (): Array<any> => {
    const { item, distancesField } = this.props;
    let currentField, value;

    currentField = item.fields?.find((itemField) => itemField.field_id === distancesField.id);
    value = (currentField && currentField.value) || '';

    return [currentField, <FormattedDate value={value} format={commonDateFormats.shortDate} />];
  };

  timeData = (): Array<any> => {
    const { item, distancesField } = this.props;
    let currentField, value;

    currentField = item.fields?.find((itemField) => itemField.field_id === distancesField.id);
    value = (currentField && currentField.value) || '';

    return [currentField, <FormattedDate value={value} format={commonDateFormats.timeWithSeconds} parseFormat={BACKEND_TIME_FORMAT} />];
  };

  inputData = (): Array<any> => {
    const { item, distancesField } = this.props;
    const currency = item?.order_currency || '';
    let currentField, value;

    currentField = item.fields?.find((itemField) => itemField.field_id === distancesField.id);
    const fieldValue = (currentField && currentField.value) || '';
    const price = (currentField && currentField.price) || '';
    value = `${fieldValue}${this.renderPrice(price, currency)}`;
    return [currentField, value];
  };

  renderPrice = (price: string | number, currency: nil | string) => {
    if (!parseFloat(price as string)) {
      return '';
    }

    return `(+ ${price} ${currency || ' '})`;
  };

  getContent = () => {
    const { isEdit, item, distancesField, distance, changedFields, saveChanges } = this.props;
    const Field = CUSTOM_TYPE[distancesField.type];
    // eslint-disable-next-line
    const callFunc = eval(`this.${CUSTOM_DATA[distancesField.type]}`);

    if (!isFunction(callFunc)) {
      return null;
    }

    const [currentField, value] = callFunc.bind(this)();

    if (isEdit === distancesField.name) {
      return (
        <Field
          item={item}
          onChange={this.onChange}
          isEdit={isEdit}
          distancesField={distancesField}
          distance={distance}
          changedFields={changedFields}
          saveChanges={saveChanges}
          currentField={currentField}
          value={value}
        />
      );
    }

    return value;
  };

  render() {
    const content = this.getContent();

    return content || null;
  }
}

export const CustomFields = CustomFieldsImpl as unknown as React.ComponentType<OriginalProps>;

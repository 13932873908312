import { DummyStore } from 'stores';

import { Members } from './members';
import { MembersTeam } from './membersTeams';
import { Racers } from './racers';
import { Teams } from './teams';

const membersTeamStore = new MembersTeam();
const modalStore = new DummyStore<{ open: boolean }>({ open: false });
const teamsStore = new Teams();
const startlistRacersStore = new Racers();
const startlistMembersStore = new Members();

export { membersTeamStore, modalStore, teamsStore, startlistRacersStore, startlistMembersStore };
export * from './membersTeams';
export * from './teams';
export * from './racers';
export * from './members';
export * from './confirm';

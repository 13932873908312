import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > *': {
      padding: 8,
    },
  },
  formElement: {
    marginBottom: 16,
  },
  header: {
    display: 'flex',
  },
  headerButtonWrap: {
    display: 'flex',
    width: 70,
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  headerTextWrap: {
    flex: 1,
    marginLeft: -70,
  },
  headerText: {
    color: theme.palette.grey1,
    fontSize: 14,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 500,
    paddingTop: 32,
    paddingBottom: 8,
  },
  optionsText: {
    color: theme.palette.grey1,
    fontSize: 16,
    textAlign: 'start',
    fontWeight: 'normal',
    marginTop: 24,
  },
}));

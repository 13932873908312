import { Box } from '@mui/material';
import { Observer } from 'mobx-react';
import { TransactionActions } from 'modules/Reconciliation/Transaction/actions/lifecycle';
import { TransactionConstants } from 'modules/Reconciliation/Transaction/constants/transaction.constants';
import { TransactionStore } from 'modules/Reconciliation/Transaction/stores/transaction.store';
import { ReactElement, useEffect } from 'react';
import shortid from 'shortid';

import { ROUTES } from 'src/constants/routes';

import { NavToolbar } from 'components/NavToolbar';
import { Spinner } from 'components/Spinner';

import { t } from 'utils/t';

import { progressStore } from 'stores';

import TransactionInfo from './TransactionInfo';

interface Props {}

function TransactionWrapper({}: Props): ReactElement {
  useEffect(() => {
    const init = async () => {
      await TransactionActions.init();
    };
    init();
  }, []);

  const makeNavItems = (): Array<NavToolbarItemType> => {
    return [
      { key: shortid(), path: ROUTES.transactionsRoute, label: t.staticAsString('reconciliation.transaction.transaction') },
      { key: shortid(), label: `${t.staticAsString('reconciliation.transaction.transaction')} #${TransactionStore.transaction.value?.id}` },
    ];
  };

  return (
    <div style={{ padding: 0, backgroundColor: 'white' }} className='content main-container-list races-list'>
      <Observer
        render={() => {
          if (progressStore.isLoading(TransactionConstants.TransactionForm || !TransactionStore.transaction.value)) {
            return <Spinner type={Spinner.types.default} />;
          }
          return (
            <>
              <Box px={3} py={1}>
                <NavToolbar items={makeNavItems()} />
              </Box>
              <Box px={3} py={1}>
                <TransactionInfo />
              </Box>
            </>
          );
        }}
      />
    </div>
  );
}

export { TransactionWrapper };

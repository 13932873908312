import { Delete } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { START_LIST_ORDER_STATUSES } from 'modules/Payments/constants';
import * as React from 'react';

import { START_LIST } from 'src/constants';

import { FormattedDate } from 'components/FormattedDate';

import { oneFieldValidation, commonDateFormats, t, formatter } from 'utils';

import { errorsStore } from 'stores';

import teamService from '../../../services/teamsService';

import { teamFields } from '../../../Filters';
import { teamValidation } from '../../../validations';
import { PersonalFields } from '../../Fields/PersonalFields';
import { MAIN_COLUMNS } from './THead/columns';

type Props = {
  item: TeamType;
  distance: DistanceType;
  deleteTeam: (racerId: number) => void;
};

type State = {
  changedFields: Object;
  team: TeamType | Object;
  isEdit: string | null;
};

const WAVE_TYPE = 'wave';
const action = `UPDATE_${START_LIST}`;
const VALIDATE_FIELDS: { [K in string]: any } = {
  bib_number: /^[0-9]+$/,
};

@observer
class Item extends React.Component<Props, State> {
  state = {
    team: {},
    changedFields: {},
    isEdit: null,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isEdit } = this.state;

    if (isEdit && !prevState.isEdit) {
      document.addEventListener('keyup', this.handlePressEnter);
    }
  }

  handlePressEnter = (e: KeyboardEvent) => {
    const { isEdit } = this.state;

    if (e.keyCode === 13) {
      this.saveTeam(isEdit as any);
    }
  };

  renderPersonalInfo = (fields: Array<any>): React.ReactNode => {
    const { item, distance } = this.props;
    const { isEdit, team, changedFields } = this.state;
    const fieldsToRender = fields.filter((field) => this._isFieldShown(field));

    return fieldsToRender.map((field) => (
      <TableCell
        onDoubleClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
          this.editTeam(field);
        }}
        className={classNames('cell')}
        key={`${field}${item.id}`}
      >
        {/*@ts-ignore*/}
        <PersonalFields
          field={field}
          item={isEdit ? team : item}
          isEdit={isEdit}
          onChange={this.onChange}
          saveRacer={this.saveTeam}
          distance={distance}
        />
      </TableCell>
    ));
  };

  editTeam = (field: string): void => {
    const { isEdit } = this.state;

    if (isEdit === field) {
      return;
    }

    this.setState({
      ...this.state,
      team: this.props.item,
      isEdit: field,
      changedFields: {},
    });
  };

  cancelEdit = () => {
    this.setState({
      isEdit: null,
      team: {},
      changedFields: {},
    });
  };

  mapData = (data: AnyObject) => {
    return Object.keys(data).reduce((result, current) => {
      if (current === 'bib_number')
        return {
          ...result,
          [current]: Number(data[current]),
        };

      return {
        ...result,
        [current]: data[current],
      };
    }, {});
  };

  saveTeam = async (field: string): Promise<any> => {
    const { team, changedFields } = this.state;
    // @ts-ignore
    const constrainsField = { [field]: teamValidation[field] };
    const isValidate = oneFieldValidation(action, this.state.changedFields, constrainsField);

    const value = this.mapData(this.state.changedFields);

    if (isValidate && !isEmpty(changedFields)) {
      const status = await teamService.updateResource(value, team);

      if (status) {
        document.removeEventListener('keyup', this.handlePressEnter);
      }
    } else {
      this.cancelEdit();
    }

    errorsStore.clearFrontendError(action);
  };

  onChange = (name: string, value: any, changedValue: any, callback: Function = () => {}): void => {
    if (VALIDATE_FIELDS[name] && value.length && !value.match(VALIDATE_FIELDS[name])) {
      return;
    }

    this.setState(
      {
        ...this.state,
        team: {
          ...this.state.team,
          [name]: value,
        },
        changedFields: {
          ...this.state.changedFields,
          [name]: changedValue,
        },
      },
      () => {
        // @ts-ignore
        const constrainsField: any = { [name]: teamValidation[name] } as any;

        oneFieldValidation(action, this.state.team, constrainsField);

        callback && callback();
      },
    );
  };

  onDeleteTeam = (id: number) => {
    this.props.deleteTeam(id);
  };

  priceColumns = (item: TeamType) => {
    const { distance } = this.props;
    const hasPrices = (distance.prices || []).length > 0;

    if (!hasPrices) {
      return [];
    }

    const payment: any = item.owner?.order || {};
    const paymentAmount = formatter.toFrontEndPrice(payment.total);
    const status = START_LIST_ORDER_STATUSES.find((status) => status.key === payment.status)?.value || '';

    return [
      <TableCell key='payment-coupon-code' className={classNames('cell')}>
        {payment.coupon_codes?.join(', ')}
      </TableCell>,
      <TableCell key='payment-id' className={classNames('cell')}>
        {payment.id}
      </TableCell>,
      <TableCell key='payment-created_at' className={classNames('cell')}>
        <FormattedDate value={payment?.created_at} format={commonDateFormats.shortDateWithTime} />
      </TableCell>,
      <TableCell key='payment-stat' className={classNames('cell')}>
        {status}
      </TableCell>,
      <TableCell key='payment-price' className={classNames('cell')}>
        {paymentAmount || null} {paymentAmount ? payment.currency : null}
      </TableCell>,
    ];
  };

  _isFieldShown = (field: string) => {
    const { distance } = this.props;
    const hasPrices = (distance.prices || []).length > 0;

    switch (field) {
      case teamFields.wave_id:
        return distance.start_type === WAVE_TYPE;
      case teamFields.class_id:
        return (distance?.classes?.length || 0) > 0;
      case teamFields['owner.order.id']:
      case teamFields['owner.order.created_at']:
      case teamFields['owner.order.coupon_codes']:
      case teamFields['owner.order.amount']:
      case teamFields['owner.order.currency']:
      case teamFields['owner.order.state']:
        return hasPrices;
      default:
        return true;
    }
  };

  render() {
    const { item } = this.props;
    return (
      <TableRow key={`teamId${item.id}`}>
        <TableCell className={classNames('cell')}>
          <div className='controls-block'>
            <Delete className='primary-icon' onClick={() => this.onDeleteTeam(item.id)} />
          </div>
        </TableCell>
        {this.renderPersonalInfo(MAIN_COLUMNS)}
        {this.priceColumns(item)}
      </TableRow>
    );
  }
}

export { Item };

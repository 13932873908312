import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { DISTANCE_WAVES_URL } from 'src/constants';
import { request, action } from 'src/utils';

import { WAVES_REG_LINKS_REQUESTS } from '../constants';

import { mapToOptionsWaves } from '../utils';

import { wavesHasMoreStore, wavesParamsStore, wavesStore } from '../stores';

class Waves {
  @request({ action: WAVES_REG_LINKS_REQUESTS })
  async getWavesRequest(id: number): Promise<any> {
    const params = wavesParamsStore.value;
    const url = generatePath(DISTANCE_WAVES_URL, { id });
    return axios.get(url, { params });
  }

  @action({ action: WAVES_REG_LINKS_REQUESTS })
  async getWaves(distanceId: number) {
    const [status, response] = await this.getWavesRequest(distanceId);

    if (status) {
      wavesHasMoreStore.set(response.data.meta.pagination.total_pages > (wavesParamsStore.value?.page as number));
      wavesParamsStore.set({
        ...(wavesParamsStore.value as any),
        page: (wavesParamsStore.value?.page as number) + 1,
      });
      wavesStore.set([...(wavesStore.value || []), ...mapToOptionsWaves(response.data.data)]);
    }
  }
}

export const wavesService = new Waves();

import { computed, action, makeObservable } from 'mobx';
import moment from 'moment';

import { BACKEND_DATE_FORMATE } from 'src/constants';

import { Race as RaceModel } from 'models';

import { Loadable } from './loadable';

class HelperRace extends Loadable<RaceType, FiltersType> {
  declare resourceParams: FiltersType;

  declare filters;

  constructor() {
    super();

    makeObservable(this);
    this.resourceParams = {
      with: 'organizer;location;contact_emails;currency;contacts;distances',
      withCount: 'completedOrders',
    };
    this.filters = {
      limit: -1,
      search: `published:1;status:finished;race_end_date:${moment().format(BACKEND_DATE_FORMATE)}`,
      searchFields: 'published:=;status:!=;race_end_date:>=',
      searchJoin: 'AND',
      with: 'currency',
    };
  }

  @computed
  get modelSelected(): nil | RaceModel {
    if (this.selected) {
      return new RaceModel(this.selected);
    } else {
      return null;
    }
  }

  @computed
  get languagesData(): { selectedLanguages: availableLocales[]; defaultLocale: availableLocales } {
    const defaultLocale = this.selected!.pref_lang_code;
    const selectedLanguages =
      this.selected!.selected_lang_codes && this.selected!.selected_lang_codes.filter((locale) => locale !== defaultLocale);
    return {
      defaultLocale,
      selectedLanguages,
    };
  }

  @computed
  get modelValues(): Array<RaceModel> {
    return this.values.map((race) => new RaceModel(race));
  }

  findModelValue(id: number): nil | RaceModel {
    const { modelValues } = this;
    return modelValues.find((el) => el.value.id === id);
  }

  valuesForSelect(): Array<SelectOption> {
    return this.modelValues.map((value) => ({
      key: value.value.id,
      value: value.value.id,
      label: value.value.name || '',
    }));
  }

  @action
  cleanSelected() {
    this.selected = null;
  }
}

const helperRacesStore = new HelperRace();
export { HelperRace, helperRacesStore };

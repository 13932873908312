import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const BoxShadowValues = [
  'none',
  '0 2px 4px rgba(0, 0, 0, 0.05)',
  '0px 4px 8px rgba(0, 0, 0, 0.16)',
  '0px 8px 16px rgba(0, 0, 0, 0.12)',
  '0px 16px 24px rgba(0, 0, 0, 0.12)',
  '0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802)',
];

type Props = {
  level?: 0 | 1 | 2 | 3 | 4 | 5;
  children: JSX.Element;
  className?: string;
};
type StyledHardElevationProps = {
  boxShadow: string;
};

export const HardElevationStyles = makeStyles<Theme, StyledHardElevationProps>(() => ({
  root: {
    boxShadow: ({ boxShadow }) => boxShadow,
  },
}));

export const HardElevation = ({ level = 0, children }: Props) => {
  const classes = HardElevationStyles({ boxShadow: BoxShadowValues[level] || BoxShadowValues[1] });
  return <div className={classes.root}>{children}</div>;
};

import { observer } from 'mobx-react';
import { Item } from 'modules/SportTypes/components/List/Item';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import { sportTypesStore } from 'stores';

type Props = {
  sportTypeRouterId: string | null;
  onItemClick: (id: string) => void;
  onDeleteValue: (id: number) => void;
};

const ListItems: React.FC<Props> = observer(({ sportTypeRouterId, onItemClick, onDeleteValue }) => {
  const selectedId = sportTypesStore.selected && sportTypesStore.selected.id;

  const sportItems = sportTypesStore.modelValues;
  return (
    <Droppable droppableId='droppable'>
      {(provided) => (
        <ul {...provided.droppableProps} className='list' ref={provided.innerRef}>
          {sportItems.map<React.ReactNode>((sportType, index) => (
            <Draggable key={`sport-type:${sportType.value.id}`} draggableId={`${sportType.value.id}`} index={index}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <Item
                    key={`sport-type:${sportType.value.id}`}
                    selectedId={!!sportTypeRouterId && selectedId}
                    value={sportType}
                    onClick={onItemClick}
                    onDelete={onDeleteValue}
                    index={sportType.value.index}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
});

export default ListItems;

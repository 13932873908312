import axios from 'axios';
import { makeAutoObservable, toJS } from 'mobx';
import { generatePath } from 'react-router-dom';

import { MISSING_PAYMENT_DETAILS } from 'src/constants/api';

import { sessionStore } from 'stores';

class MissingPaymentDetailsStore {
  public isPaymentDetailsMissing = false;
  constructor() {
    makeAutoObservable(this);
    this.verifyPaymentDetails();
  }

  async verifyPaymentDetails() {
    const organizerID = sessionStore.session?.user?.user_id;
    if (!organizerID) return;
    const { data } = await axios.get(generatePath(MISSING_PAYMENT_DETAILS, { organizerID }));
    this.isPaymentDetailsMissing = data.data.length > 0;
  }
}

const missingPaymentDetailsStore = new MissingPaymentDetailsStore();
export default missingPaymentDetailsStore;

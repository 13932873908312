import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { OrderDetails } from 'modules/Payments/models';
import * as React from 'react';

import { Hide } from 'components/Condition';

import { t, formatter } from 'utils';

type Props = {
  value: OrderDetails;
};

type ItemProps = {
  value: string | React.ReactElement;
  price: string | number;
  discount?: string | number;
  total: string | number;
  count?: number | null;
  currency: string;
  racersCount?: string | number;
};

const formatNumber = (value: string | number, currency: string) => {
  return (
    <React.Fragment>
      <span className='price-value'>{formatter.integer(value as number, true)}</span>
      &nbsp;
      <span className='price-currency'>{currency}</span>
    </React.Fragment>
  );
};

const Item = ({ value, racersCount, price, discount = '', total, currency }: ItemProps) => {
  return (
    <TableRow className='table-row'>
      <TableCell className='table-cell cell-product' title={value as any}>
        {value}
      </TableCell>
      <TableCell className='table-cell'>
        {formatNumber(price, currency)} &nbsp; {Number(racersCount) > 0 ? `(x${racersCount})` : ''}
      </TableCell>
      <TableCell className='table-cell'>{formatNumber(discount, currency)}</TableCell>
      <TableCell className='table-cell'>{formatNumber(total, currency)}</TableCell>
    </TableRow>
  );
};

const PayedItem = ({ value, price, discount, total, currency, count }: ItemProps) => {
  const countMultiplier = count ? Number(count) : 1;
  const countString = count ? `(x${count})` : '';

  return (
    <TableRow className='table-row'>
      <TableCell className='table-cell cell-product'>{value}</TableCell>
      <TableCell className='table-cell'>
        {formatNumber(price, currency)} {countString}
      </TableCell>
      <TableCell className='table-cell'>{formatNumber(discount as number, currency)}</TableCell>
      <TableCell className='table-cell'>{formatNumber((total as number) * countMultiplier, currency)}</TableCell>
    </TableRow>
  );
};

export function PayedItems({ value }: Props) {
  const racersCount = value.racersCounter();
  const currency = value.currency();

  const payedItems = value.payedItems().map((item: any) => {
    const fieldName = <span className='custom-field-title'>{item.title} &nbsp;</span>;
    const fieldAnswer = <span className='custom-field-name'>{item.subtitle}</span>;
    const originalPrice = formatter.toFrontEndPrice(item.original_price);
    const price = formatter.toFrontEndPrice(item.price);
    const count = item.count ? ` (x${item.count})` : '';
    const discount = formatter.toFrontEndPrice(item.discount_amount);
    const field_name = (
      <>
        {fieldName}
        {fieldAnswer}
        {count}
      </>
    );

    return (
      <PayedItem
        currency={currency}
        value={field_name}
        count={item.count}
        price={originalPrice}
        total={price}
        key={item.id}
        discount={discount}
      />
    );
  });

  const refundItem = () => {
    const { refund_protect_amount, refund_protect_used } = value.refundProtect();
    const fieldName = <span className='custom-field-title'>{t.staticAsString('payments.detail.payedItemsInfo.refundProtect')} &nbsp;</span>;
    const price = refund_protect_amount ?? 0;
    const count = '';

    const field_name = (
      <>
        {fieldName}
        {count}
      </>
    );

    if (refund_protect_used && price) {
      return <PayedItem currency={currency} value={field_name} count={null} price={price} total={price} key={'refund_protect'} />;
    }

    return null;
  };

  const discount = value.distanceDiscountAmount();
  const distanceOriginalPrice = value.distanceOriginalPrice();
  const price = racersCount ? Number(distanceOriginalPrice) * Number(racersCount) : distanceOriginalPrice;
  const totalSum = discount ? Number(price) - Number(discount) : price;
  const distanceItem = value.distanceName() ? (
    <Item
      currency={currency}
      racersCount={racersCount}
      value={value.distanceName()}
      price={distanceOriginalPrice || ''}
      discount={discount || ''}
      total={totalSum || '0'}
    />
  ) : null;

  return (
    <div className='account-info-list payed-items'>
      <div className='info-title'>
        <p>{t.staticAsString('payments.detail.payedItemsInfo.title')}</p>
      </div>
      <div className='info-body'>
        <Table className='payed-items-table'>
          <TableHead classes={{ root: 'info-table-header' }}>
            <TableRow className='table-row'>
              <TableCell className='table-cell cell-product'>{t.staticAsString('payments.detail.payedItemsInfo.product')}</TableCell>
              <TableCell className='table-cell'>{t.staticAsString('payments.detail.payedItemsInfo.price')}</TableCell>
              <TableCell className='table-cell'>{t.staticAsString('payments.detail.payedItemsInfo.discount')}</TableCell>
              <TableCell className='table-cell'>{t.staticAsString('payments.detail.payedItemsInfo.total')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody classes={{ root: 'info-table-body' }}>
            <Hide if={value.isTransferRegistration()}>{distanceItem}</Hide>
            {payedItems}
            {refundItem()}
          </TableBody>
        </Table>
        <p className='total-price'>{formatNumber(value.totalPrice(), currency)}</p>
      </div>
    </div>
  );
}

import { loadCurrencyService } from 'src/services';
import { currenciesStore } from 'src/stores';

import { SystemUserViewService } from '../../services/systemUserView.service';

import { SystemUserCommissionsStore } from '../../stores/systemUserCommissions.store';
import { SystemUserPaymentsStore } from '../../stores/systemUserPayments.store';
import { SystemUserReportsStore } from '../../stores/systemUserReports.store';
import { SystemUserViewStore } from '../../stores/systemUserView.store';

import { SystemUser } from '../../types';
import { loadRoles } from '../loadRoles';

export async function loadSystemUserAction(id: number) {
  const value = await SystemUserViewService.load<SystemUser>(id);

  SystemUserViewStore.profile.set(value.data);
  SystemUserCommissionsStore.currencies.value = value.data.active_commissions.map(({ currency_id }) => currency_id);
}

export async function initSystemUserProfileAction() {
  await Promise.all([loadRoles(), loadCurrencyService.loadResources()]);
}

export async function unmountSystemUserProfileAction() {
  SystemUserViewStore.profile.value = null;
  SystemUserCommissionsStore.commissions.value = null;
  SystemUserCommissionsStore.editCommission.value = null;
  SystemUserReportsStore.reports.value = null;
  SystemUserPaymentsStore.payments.value = null;
  currenciesStore.values = [];
}

import { copiedLinkStore, hasOrganizerLinksStore, campaignLinksFiltersStore, campaignLinksStore } from '../stores';

import { hasOrganizerLinks } from './loadLinks';

export const unmount = () => {
  campaignLinksStore.clear();
  copiedLinkStore.clear();
  campaignLinksFiltersStore.clear();
  hasOrganizerLinksStore.off();
};

export const mount = () => {
  hasOrganizerLinks();
};

import styled from 'styled-components';

export const CampaignLinksDetailsWrapper = styled.div`
  color: ${(props) => props.theme.palette.grey1};
  overflow: auto;
`;

export const TopSection = styled.div`
  margin: 4px 20px 0;
`;

import { countriesStore } from 'stores';

function formatNationality(id: number | null) {
  const model = countriesStore.findModelValue(Number(id) || 0);

  if (!model) {
    return '';
  }

  return model.nationalityName();
}

export { formatNationality };

import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { DISTANCE, EDIT_REGISTRATION_URL } from 'src/constants';

import { request, action, t } from 'utils';

class EditRegistrationService {
  @request({ action: `UPDATE_${DISTANCE}` })
  private requestUpdate(distanceId: number, params: AnyObject, isEdit: boolean): any {
    const url = generatePath(EDIT_REGISTRATION_URL, { id: distanceId });
    const requestParams = { from: params.from, to: params.to };

    return isEdit ? axios.patch(url, requestParams) : axios.post(url, requestParams);
  }

  @request({ action: `UPDATE_${DISTANCE}` })
  private requestDelete(distanceId: number): any {
    const url = generatePath(EDIT_REGISTRATION_URL, { id: distanceId });

    return axios.delete(url);
  }

  @request({ action: `UPDATE_${DISTANCE}` })
  private requestLoad(distanceId: number): any {
    const url = generatePath(EDIT_REGISTRATION_URL, { id: distanceId });
    return axios.get(url);
  }

  @action({ action: `UPDATE_${DISTANCE}` })
  async save(distanceId: number, params: AnyObject, isEdit: boolean) {
    const [isOk, response] = await this.requestUpdate(distanceId, params, isEdit);

    return [isOk, response];
  }

  @action({ action: `UPDATE_${DISTANCE}` })
  async delete(distanceId: number) {
    const [isOk] = await this.requestDelete(distanceId);

    return isOk;
  }

  @action({ action: `UPDATE_${DISTANCE}` })
  async load(distanceId: number, callBack: Function) {
    const [isOk, response] = await this.requestLoad(distanceId);

    if (isOk && response && response.data && response.data.data && response.data.data.id) {
      callBack(response.data.data);
    }
  }
}

export { EditRegistrationService };

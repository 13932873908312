import GetApp from '@mui/icons-material/GetApp';
import { Button } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import * as React from 'react';
import shortId from 'shortid';

import { t } from 'utils/t';

const classes = {
  toolbar: {
    menu: ['menu-card'],
    text: ['menu-card-text'],
  },
};

type Props = {
  onExportXlsx: () => void;
  onExportWaves: () => void;
  exportListKey: string;
  exportWavesKey: string;
};

export const ExportMenu: React.FC<Props> = (props) => {
  const { onExportXlsx, onExportWaves, exportListKey, exportWavesKey } = props;
  const [id] = React.useState(shortId());

  const withHandleClose = (handler: AnyFunction, close: AnyFunction) => () => {
    handler();
    close();
  };

  return (
    <PopupState variant='popover' popupId={id}>
      {(popupState: any) => (
        <Button className='button-item'>
          <div onClick={bindTrigger(popupState).onClick}>
            <div className='button-icon'>
              <GetApp />
            </div>
            <div className='button-title'>{t.staticAsString('racers.list.xlsx')}</div>
          </div>
          <Menu {...bindMenu(popupState)} className={classNames(...classes.toolbar.menu)} keepMounted id='settings-menu'>
            <MenuItem className={'menu-card-item'} onClick={withHandleClose(onExportWaves, popupState.close)}>
              <p>{t(exportWavesKey as TranslationLockedKeys)}</p>
            </MenuItem>
            <MenuItem className={'menu-card-item'} onClick={withHandleClose(onExportXlsx, popupState.close)}>
              <p>{t(exportListKey as TranslationLockedKeys)}</p>
            </MenuItem>
          </Menu>
        </Button>
      )}
    </PopupState>
  );
};

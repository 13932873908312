import axios from 'axios';
import { values } from 'lodash';
import { CREATE_OR_UPDATE_COUPON, DELETE_COUPON, LOAD_COUPON, LOAD_COUPONS, PERSIST_COUPON } from 'modules/Coupons/constants';
import { generatePath } from 'react-router-dom';

import { COUPON, COUPONS_URL, COUPON_URL } from 'src/constants';

import { action, request, t } from 'utils';

import { LoadableService } from 'services';

import { Coupon as CouponStore, couponsStore, toastStore } from 'stores';

const DEFAULT_PAGE = 1;

class LoadService extends LoadableService<CouponType, FiltersType, CouponStore> {
  constructor(store: CouponStore) {
    super({ store, resourcesUrl: COUPONS_URL, resource: COUPON });
  }

  @request({ action: LOAD_COUPONS })
  async loadResourcesRequest(params: FiltersType): Promise<AnyObject> {
    return super.loadResourcesRequest(params);
  }

  @action({ action: LOAD_COUPONS })
  async loadResources(params: FiltersType = {}, pageNum: number = 1): Promise<AnyObject> {
    return super.loadResources(params, pageNum);
  }

  @request({ action: PERSIST_COUPON })
  async createResourceRequest(coupon: CouponType): Promise<AnyObject> {
    return axios.post(COUPONS_URL, coupon);
  }

  @request({ action: PERSIST_COUPON })
  async updateResourceRequest(coupon: CouponType): Promise<AnyObject> {
    return axios.patch(generatePath(COUPON_URL, { id: coupon.id }), coupon);
  }

  @request({ action: LOAD_COUPON })
  async loadResourceRequest(id: string, params: FiltersType): Promise<AnyObject> {
    return super.loadResourceRequest.bind(this)(id, params);
  }

  @action({ action: LOAD_COUPON, minRequestTime: 200 })
  async loadResource(id: string, params: FiltersType = { with: 'distances' }): Promise<AnyObject> {
    return super.loadResource.bind(this)(id, params);
  }

  @action({ action: CREATE_OR_UPDATE_COUPON, minRequestTime: 800 })
  async createOrUpdateResource(coupon: any, closeModal: Function): Promise<void> {
    let messageId, status, response;

    if (coupon.id) {
      [status, response] = await (this.updateResourceRequest(coupon) as Promise<AnyObject[]>);
      messageId = 'coupons.updateSuccess';
    } else {
      [status, response] = await (this.createResourceRequest(coupon) as Promise<AnyObject[]>);
      messageId = 'coupons.createSuccess';
    }

    if (status) {
      this.loadResources();
      closeModal();
      toastStore.show(t(messageId));
    } else {
      toastStore.show(values(response.data.errors).join(' '));
    }
  }

  @request({ action: DELETE_COUPON })
  deleteValueRequest(id: number): any {
    return axios.delete(generatePath(COUPON_URL, { id }));
  }

  @action({ action: DELETE_COUPON })
  async deleteValue(id: number) {
    const [status] = await this.deleteValueRequest(id);

    if (status) {
      this.loadResources();
      toastStore.show(t.staticAsString('coupons.deleteSuccess'));
    }
  }

  async applySearch(callback: Function, ...searchParams: Array<FiltersType>) {
    const searchModel = this.store.retrieveSearchDataModel();

    searchParams.forEach((searchParam) => {
      searchModel.appendFilters(searchParam);
    });

    const paramsForStoring = searchModel.params() as any;
    searchModel.enableTrimEmptyArrayOption();
    const paramsForSending = searchModel.params();

    if (paramsForSending !== this.store.filters.search) {
      await this.loadResources(paramsForSending || { search: '', searchFields: '' }, DEFAULT_PAGE);
      callback();
    }

    if (paramsForStoring && paramsForStoring.search && paramsForStoring.searchFields) {
      this.store.updateSearch(paramsForStoring.search, paramsForStoring.searchFields);
    }
  }

  async deleteFilters(callback: Function, ...filterNames: Array<string>) {
    const searchModel = this.store.retrieveSearchDataModel();
    searchModel.deleteFilters(...filterNames);
    await this.loadResources(searchModel.params() || { search: '', searchFields: '' }, DEFAULT_PAGE);
  }

  clearFilters() {
    this.loadResources(
      {
        search: '',
        searchFields: '',
      },
      1,
    );
  }
}

export { LoadService };
export default new LoadService(couponsStore);

import { MoreVert } from '@mui/icons-material';
import { Box, Menu, MenuItem } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import * as React from 'react';

import { t, time } from 'utils';

import { Customer as CustomerType } from 'models';

type Props = {
  item: CustomerType;
  deleteCustomer: (customerId: number) => void;
};

const DeleteMenuItem = withStyles((theme) => ({
  root: {
    color: theme.palette.redCE,
  },
}))(MenuItem);

const Actions = withStyles((theme) => ({
  root: {
    width: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    height: ' 30px',
  },
}))(Box);

class Item extends React.Component<Props> {
  state = {
    actionMenuAnchorEl: null,
  };
  handleOpenActionMenu = (event: React.MouseEvent<SVGSVGElement>) => this.setState({ actionMenuAnchorEl: event.currentTarget });
  handleCloseActionMenu = () => this.setState({ actionMenuAnchorEl: null });

  handleDeleteCoupon = () => {
    const {
      item: {
        value: { id },
      },
    } = this.props;
    this.handleCloseActionMenu();
    this.props.deleteCustomer(id);
  };
  render() {
    const { item } = this.props;
    const jsonItem = item.value;
    const { actionMenuAnchorEl } = this.state;
    return (
      <TableRow>
        <TableCell className={classNames('cell')} sx={{ width: '50px' }}>
          <Actions>
            <MoreVert
              onClick={this.handleOpenActionMenu}
              sx={{
                cursor: 'pointer',
                color: '#a2abb2',
                width: '24px',
                height: '24px',
              }}
            />
            <Menu
              id='simple-menu'
              anchorEl={actionMenuAnchorEl}
              keepMounted
              open={Boolean(actionMenuAnchorEl)}
              onClose={this.handleCloseActionMenu}
            >
              <DeleteMenuItem onClick={this.handleDeleteCoupon}>{t.staticAsString('customers.deleteCustomer')}</DeleteMenuItem>
            </Menu>
          </Actions>
        </TableCell>
        <TableCell className={classNames('cell')}>{jsonItem.id}</TableCell>
        <TableCell className={classNames('cell')}>{item.fullName()}</TableCell>
        <TableCell className={classNames('cell')}>{jsonItem.email}</TableCell>
        <TableCell className={classNames('cell')}>{jsonItem.telephone}</TableCell>
        <TableCell className={classNames('cell')}>{jsonItem.post_code}</TableCell>
        <TableCell className={classNames('cell')}>{item.countryName()}</TableCell>
        <TableCell className={classNames('cell')}>{time.datetime(jsonItem.created_at).parse().format('shortDate')}</TableCell>
      </TableRow>
    );
  }
}

export { Item };

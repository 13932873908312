import { TableCell } from '@mui/material';
import classNames from 'classnames';
import { isFunction } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';

import { progressStore, errorsStore } from 'stores';

import { ResultsContext } from '../../context';
import { FieldUpdateFun, RenderFieldEdit } from '../../types';

type Props = {
  show: Function;
  edit: nil | RenderFieldEdit;
  update: nil | FieldUpdateFun;
  fieldId: string;
  onEdit: Function;
};

type State = {
  fieldValue: any;
  isEdit: boolean;
  isCleared: boolean;
};

@observer
class EditableCell extends React.Component<Props, State> {
  static contextType = ResultsContext;
  declare context: React.ContextType<typeof ResultsContext>;

  state = { fieldValue: undefined, isEdit: false, isCleared: false };

  componentDidUpdate() {
    if (!this.isEdit() && !this.state.isCleared) {
      return this.setState({ fieldValue: undefined, isCleared: true });
    }

    if (this.isEdit() && this.state.isCleared) {
      return this.setState({ isCleared: false });
    }
  }

  onEditMode = () => {
    const { onEdit } = this.props;

    if (!this.props.edit) {
      return;
    }
    onEdit();
  };

  isEdit(): boolean {
    const { fieldId } = this.props;
    return this.context.results.stores.fieldRegisterStore.isActive(fieldId);
  }

  onChangeFieldValue: FieldOnChange = ({ name, value }, e, callback) => {
    this.setState({ fieldValue: value }, callback);
  };

  onBlur = () => {
    const { update } = this.props;
    const { fieldValue } = this.state;

    update && update(fieldValue);
  };

  errors = () => {
    const { fieldId } = this.props;
    const errorModel = errorsStore.build(fieldId);

    if (!errorModel) {
      return '';
    }

    return errorModel.firstError();
  };

  render() {
    const { show, edit, fieldId } = this.props;
    const { fieldValue } = this.state;

    let renderedValue: React.ReactNode | null = null;
    const isEdit = this.isEdit();

    if (isEdit) {
      renderedValue = isFunction(edit) && edit(this.onChangeFieldValue, fieldValue, this.onBlur, this.errors());
    }

    if (!isEdit || !renderedValue) {
      renderedValue = isFunction(show) && show();
    }

    const isLoading = progressStore.isLoading(fieldId);

    return (
      <TableCell className={classNames('editable-cell cell', { loading: isLoading })} onDoubleClick={() => this.onEditMode()}>
        {renderedValue || null}
      </TableCell>
    );
  }
}

export { EditableCell };

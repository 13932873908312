import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import { intersection } from 'lodash';
import * as React from 'react';
import shortid from 'shortid';

import { ORDER_REGISTRATION_FIELDS } from 'src/constants';

import { TableHeaderItem } from 'components/Filter/TableHeaderItem';

import { t } from 'utils';

import { memberFields } from '../../../../Filters';
import { MAIN_COLUMNS, PAYMENT_COLUMNS, COLUMN_WIDTH, CUSTOM_FIELD_WIDTH } from './columns';

type Props = {
  distance: DistanceType;
  checkedRequiredFields: AnyObject;
};

const classes = {
  thead: {
    custom: ['start-list-table-header', 'table-header', 'cell-custom'],
    regular: ['start-list-table-header', 'filter-item'],
  },
};

class THead extends React.Component<Props> {
  getRequiredFields = (): any => {
    const { checkedRequiredFields } = this.props;

    const registrationFields = Object.keys(checkedRequiredFields).filter((filter) => !!checkedRequiredFields[filter]);

    return intersection(ORDER_REGISTRATION_FIELDS, registrationFields);
  };

  customFields = (): any => {
    const { distance } = this.props;

    const style = { width: CUSTOM_FIELD_WIDTH };

    return (distance.custom_fields || []).map((field) => (
      <TableCell className={classNames(...classes.thead.custom)} key={shortid.generate()} style={style}>
        {field.name}
      </TableCell>
    ));
  };

  _renderThLabels = (cols: Array<string>): React.ReactNode => {
    return cols.map((field) => {
      if (!this._isFieldShown(field)) {
        return null;
      }

      const width = COLUMN_WIDTH[field];
      const className = `cell-${field}`;
      const translation = `racers.list.table.${field}`;

      return (
        <TableHeaderItem
          key={field}
          name={field}
          width={width}
          className={classNames(...classes.thead.regular, className)}
          label={t(translation as TranslationLockedKeys) as any}
        />
      );
    });
  };

  _additionalFields = () => {
    const { checkedRequiredFields } = this.props;
    const registrationFields: Array<string> = Object.keys(checkedRequiredFields).filter(
      (filter) => !!checkedRequiredFields[filter] && this._isFieldShown(filter),
    );

    return intersection<string>(ORDER_REGISTRATION_FIELDS, registrationFields);
  };

  _isFieldShown = (field: string) => {
    const { distance, checkedRequiredFields } = this.props;
    const hasPrices = (distance.prices || []).length > 0;

    switch (field) {
      case memberFields['class_id']:
        return !!distance.classes?.length;
      case memberFields['discipline_id']:
        return !!distance.disciplines?.length;
      case memberFields['country_id']:
        return !!checkedRequiredFields['country_id'];
      case memberFields['nationality_id']:
        return !!checkedRequiredFields['nationality_id'];
      case memberFields['city']:
        return !!checkedRequiredFields['city'];
      case memberFields['external_swimrun_id']:
        return !!checkedRequiredFields['external_swimrun_id'];
      case memberFields['order_refund_protect_amount']:
        return !!distance.refund_protect_enabled;
      case memberFields['order_id']:
      case memberFields['order_created_at']:
      case memberFields['order_total']:
      case memberFields['order_status']:
        return !!hasPrices;
      default:
        return true;
    }
  };

  render() {
    return (
      <React.Fragment>
        <TableHead>
          <TableRow>
            {this._renderThLabels(['action'])}
            {this._renderThLabels([...MAIN_COLUMNS, ...this.getRequiredFields()])}
            {this.customFields()}
            {this._renderThLabels([memberFields['order_refund_protect_amount']])}
            {this._renderThLabels([memberFields['external_swimrun_id']])}
            {this._renderThLabels([memberFields['added_at']])}
            {this._renderThLabels([memberFields['added_by']])}
            {this._renderThLabels(PAYMENT_COLUMNS)}
            {this._renderThLabels([memberFields['comment']])}
          </TableRow>
        </TableHead>
      </React.Fragment>
    );
  }
}

export { THead };

// Current util should work as an adapter helper between google places api
// to map google response format into our format and serialize our format into
// google formatted string
// [ Google places response format:
//   { long_name: string, short_name: string, types: string[] }...
// ]
//
// Our format: { city: string, country: string, lan: number lat: number....
// Our/Google street formmated string House# Steet City Country
import { GOOGLE_PLACES_KEYS_MAPPER, GOOGLE_PLACES_KEYS } from 'src/constants';

function parsePlaces(value: GooglePlacesResponse): ParsedGooglePlacesResponse {
  const parsedData: ParsedGooglePlacesResponse | any = {};
  const keys = Object.keys(GOOGLE_PLACES_KEYS);

  value.reverse().forEach((pieceOfAddress) => {
    const matchedKey = pieceOfAddress.types.find((type) => !!GOOGLE_PLACES_KEYS_MAPPER[type]);

    if (!matchedKey) {
      return;
    }

    parsedData[GOOGLE_PLACES_KEYS_MAPPER[matchedKey]] = pieceOfAddress.long_name;

    if (matchedKey === 'country') {
      parsedData.country_code = pieceOfAddress.short_name;
    }
  });

  keys.forEach((key) => {
    if (!parsedData[key]) {
      parsedData[key] = null;
    }
  });

  return parsedData;
}

function formatAddress(address: nil | LocationType): string {
  if (!address) {
    return '';
  }

  const { street_number, street_address, city, country } = address;

  return [[street_number, street_address].join(' '), city, country].join(' ').trim();
}

export { parsePlaces, formatAddress };

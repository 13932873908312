import * as React from 'react';

import { errorsStore } from 'stores';

const withErrorClean =
  (...actions: string[]) =>
  <P extends object>(Component: React.ComponentType<P>) =>
    class WithErrorCleanHOC extends React.Component<P> {
      componentWillUnmount() {
        actions.forEach((action) => {
          errorsStore.clearError(action);
          errorsStore.clear(action);
        });
      }
      render() {
        return <Component {...(this.props as P)} />;
      }
    } as unknown as void;

export { withErrorClean };

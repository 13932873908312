import { t } from 'utils';

const createCoupon = {
  name: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('coupons.new.errors.name'),
    },
    length: {
      minimum: 3,
      maximum: 255,
    },
  },
  description: {
    length: {
      maximum: 255,
    },
  },
  code: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('coupons.new.errors.code'),
    },
    length: {
      minimum: 3,
      maximum: 255,
    },
  },

  race_id: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('coupons.new.errors.race_id'),
    },
  },
  discount_amount: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('coupons.new.errors.discount_amount'),
    },
  },
  qty: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('coupons.new.errors.qty'),
    },
  },
};

export { createCoupon };

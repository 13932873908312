import axios from 'axios';
import { omit } from 'lodash';
import { generatePath } from 'react-router-dom';

import { ORGANIZER, ORGANIZER_PAYMENT_EXPORT_URL, ORGANIZERS_URL, PAYMENTS_URL, PROFILE_URL } from 'src/constants';

import { action, request, t } from 'utils';

import { LoadableService } from 'services';

import { Organizer as OrganizerStore, organizersStore, sessionStore, toastStore } from 'stores';

import { LOAD_ORGANIZER, LOAD_PAYMENTS, PERSIST_ORGANIZER } from '../constants';

class LoadService extends LoadableService<OrganizerType, FiltersType, OrganizerStore> {
  constructor(store: OrganizerStore) {
    super({ store, resourcesUrl: ORGANIZERS_URL, resource: ORGANIZERS_URL });
  }

  @request({ action: LOAD_PAYMENTS })
  async loadPaymentsRequest(params: FiltersType): Promise<any> {
    return axios.get(PAYMENTS_URL, { params });
  }

  @action({ action: LOAD_PAYMENTS })
  async loadPayments(params: AnyObject): Promise<any> {
    const [status, response] = await this.loadPaymentsRequest(params);

    if (status) {
      return response.data;
    }
  }

  @request({ action: PERSIST_ORGANIZER })
  async updateResourceRequest(organizer: OrganizerType): Promise<any> {
    const url = generatePath(PROFILE_URL, { id: organizer.id });
    return axios.patch(url, organizer);
  }

  @request({ action: LOAD_ORGANIZER })
  async loadResourceRequest(...args: any[]): Promise<any> {
    const params: FiltersType = args[0];
    return axios.get(PROFILE_URL, {
      params: {
        ...params,
      },
    });
  }

  @action({ action: LOAD_ORGANIZER, minRequestTime: 800 })
  async loadResource(...args: any[]): Promise<any> {
    const params: FiltersType = args[0] || {};

    const storeParams = this.store.resourceParams;
    const newParams = {
      ...storeParams,
      ...params,
    };

    const [status, response] = await this.loadResourceRequest(newParams);

    if (status) {
      const value = response.data.data;

      if (value.verified) {
        sessionStore.setUserVerified();
      }

      if (this.store.selected && Array.isArray(this.store.selected.payments)) {
        // Prevent loading big amount of payments
        // Local caching for performance issues
        const payments = [...this.store.selected.payments];

        return this.store.addSelectedValue({ ...value, payments });
      }

      this.store.addSelectedValue(value);
    }
  }

  @action({ action: PERSIST_ORGANIZER })
  async updateResource(id: number, organizer: OrganizerType, closeModal: Function): Promise<any> {
    const orgData = omit(organizer, 'payments');
    const [status] = await this.updateResourceRequest(orgData);

    if (status) {
      this.loadResource({ with: '' });

      closeModal(orgData);
      toastStore.show(t.staticAsString('profile.successUpdate'));
    }
  }

  @request({ action: `EXPORT_${ORGANIZER}` })
  exportPaymentRequest(organizerId: number, id: number): Promise<any> {
    const url = generatePath(ORGANIZER_PAYMENT_EXPORT_URL, { organizerId, id });
    return axios.get(url);
  }

  @action({ action: `EXPORT_${ORGANIZER}` })
  async exportPayment(id: number): Promise<nil | Location> {
    const { selected } = this.store;

    if (!selected) {
      return;
    }

    const organizerId = selected.id;
    const [status, response] = await this.exportPaymentRequest(organizerId, id);

    if (status) {
      return response.data.url;
    }
  }
}

export { LoadService };
export const loadService = new LoadService(organizersStore);

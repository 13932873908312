import axios from 'axios';
import { generatePath, matchPath } from 'react-router-dom';

import { COMMISSION_URL, COMMISSIONS_URL, COMMISSION_BY_ORGANIZER, ROUTES, ORGANIZER_URL } from 'src/constants';

import { history } from 'utils';

import { progressStore } from 'stores';

import { CommissionConstants } from '../constants/commission.constants';

import { CommissionStore } from '../stores/commission.store';
import { organizerCurrenciesIds, organizerStore } from '../stores/currencies.store';

import { CommissionTransformMapper } from '../mappers/transform.mapper';
import { CommissionFilters } from '../types/filters.type';

export namespace CommissionService {
  export async function loadOrganizer() {
    const match = matchPath(history.location.pathname, ROUTES.commissionRoute);
    const { id } = match.params as any;
    const { data } = await axios.get(generatePath(ORGANIZER_URL, { id }), {
      params: {
        with: 'paymentDetails;roles;activeCommissions',
      },
    });
    organizerStore.set(data.data);
    organizerCurrenciesIds.set(data.data.active_commissions.map((pd) => pd.currency_id));
    return data;
  }

  export async function load(filters?: CommissionFilters) {
    const match = matchPath(history.location.pathname, ROUTES.commissionRoute);
    const { id } = match.params as any;
    progressStore.log(CommissionConstants.Table, 'progress');
    try {
      const response = await axios.get(generatePath(COMMISSION_BY_ORGANIZER, { id }), {
        params: {
          ...filters,
          per_page: 50,
        },
      });
      return {
        data: CommissionTransformMapper.fromBackend(response.data.data),
        meta: response.data.meta,
      };
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(CommissionConstants.Table, 'completed');
    }
  }

  export async function submit() {
    const isEdit = Boolean(CommissionStore.modifyCommission.value?.commission?.id);
    const data = CommissionStore.modifyCommission.value;
    const commission = data?.commission!;
    const { id, amount, percent_value, vat_percents } = commission;

    const commonPayload = {
      amount: Number(amount) * 100,
      percent_value: Number(percent_value) * 100,
      vat_percents: Number(vat_percents) * 100,
    };

    if (isEdit) {
      try {
        await axios.patch(generatePath(COMMISSION_URL, { id }), {
          ...commonPayload,
        });
        CommissionStore.commissions.value!.data = CommissionStore.commissions.value!.data?.map((entry) => {
          if (entry.commission?.id === id) {
            return {
              ...entry,
              commission: CommissionStore.modifyCommission.value?.commission!,
            };
          }
          entry.subRows = entry.subRows?.map((subEntry) => {
            if (subEntry.commission?.id === id) {
              return {
                ...subEntry,
                commission: CommissionStore.modifyCommission.value?.commission!,
              };
            }
            return subEntry;
          });
          return entry;
        });
        CommissionStore.modifyCommission.set(null);
      } catch (error) {
        throw error;
      } finally {
        progressStore.log(CommissionConstants.Table, 'completed');
      }
    } else {
      try {
        const response = await axios.post(generatePath(COMMISSIONS_URL), {
          amount: Number(amount) * 100,
          percent_value: Number(percent_value) * 100,
          vat_percents: Number(vat_percents) * 100,
          commissionable_type: data?.isRace ? 'App\\Race' : 'App\\Distance',
          commissionable_id: data?.id,
          currency_id: data?.currency.id,
        });
        CommissionStore.commissions.value!.data = CommissionStore.commissions.value!.data?.map((entry) => {
          if (entry.id === data!.id) {
            return {
              ...entry,
              commission: {
                ...commonPayload,
                amount: response.data.data.amount / 100,
                percent_value: response.data.data.percent_value / 100,
                vat_percents: response.data.data.vat_percents / 100,
                id: response.data.data.id,
                currency_id: entry.currency.id,
              },
            };
          }
          entry.subRows = entry.subRows?.map((subEntry) => {
            if (subEntry.id === data!.id) {
              return {
                ...subEntry,
                commission: {
                  amount: response.data.data.amount / 100,
                  percent_value: response.data.data.percent_value / 100,
                  vat_percents: response.data.data.vat_percents / 100,
                  id: response.data.data.id,
                  currency_id: subEntry.currency.id,
                },
              };
            }
            return subEntry;
          });
          return entry;
        });
        CommissionStore.modifyCommission.set(null);
      } catch (error) {
        throw error;
      } finally {
        progressStore.log(CommissionConstants.Table, 'completed');
      }
    }
  }

  export async function remove(id: number): Promise<any> {
    progressStore.log(CommissionConstants.Table, 'progress');
    try {
      await axios.delete(generatePath(COMMISSION_URL, { id }));

      CommissionStore.commissions.value!.data = CommissionStore.commissions.value?.data.map((race) => {
        if (race.commission?.id === id) {
          return {
            ...race,
            commission: null,
          };
        }
        race.subRows = race.subRows.map((distance) => {
          if (distance.commission?.id === id) {
            return {
              ...distance,
              commission: null,
            };
          }
          return distance;
        });
        return race;
      }) as any;
      CommissionStore.modifyCommission.value = null;
    } catch (error) {
      throw error;
    } finally {
      progressStore.log(CommissionConstants.Table, 'completed');
    }
  }
}

import { ThemeProvider as ThemeProviderMUI, createTheme, adaptV4Theme } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import * as React from 'react';
import { ThemeProvider as ThemeProviderStyled } from 'styled-components';

import * as overrides from './overrides';

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
  interface Theme {
    icon: {
      baseIconButton: {
        fill: string;
        fontSize: number;
      };
    };
  }

  interface Palette {
    labelGrey: string;
    textPrimary: string;
    progressActive: string;
    progressFade: string;
    main: string;
    white2: string;
    white3: string;
    white4: string;
    orange1: string;
    yellow1: string;
    yellow5: string;
    yellow6: string;
    grey4: string;
    grey5: string;
    grey6: string;
    grey7: string;
    grey1: string;
    greyE5: string;
    greyF5: string;
    greyFA: string;
    redCE: string;
    red5: string;
    grey8: string;
    grey10: string;
    grey11: string;
    grey9: string;
    green3: string;
    blue1: string;
    grey700: string;
    grey800: string;
  }
  interface PaletteOptions {
    labelGrey: string;
    textPrimary: string;
    progressActive: string;
    progressFade: string;
    main: string;
    white2: string;
    white3: string;
    white4: string;
    orange1: string;
    yellow1: string;
    yellow5: string;
    yellow6: string;
    grey4: string;
    grey5: string;
    grey6: string;
    grey7: string;
    grey1: string;
    greyE5: string;
    greyF5: string;
    greyFA: string;
    redCE: string;
    red5: string;
    grey8: string;
    grey10: string;
    grey11: string;
    grey9: string;
    green3: string;
    blue1: string;
    grey700: string;
    grey800: string;
  }

  interface ThemeOptions {
    icon: {
      baseIconButton: {
        fill: string;
        fontSize: number;
      };
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#f9d026',
    },
    text: {
      secondary: '#a8aeb8',
    },
    secondary: {
      main: '#d0021b',
    },

    labelGrey: '#a8aeb8',
    textPrimary: '#262e35',
    progressActive: '$yellow-1',
    progressFade: '$yellow-7',
    main: '#f9d026',
    white2: '#fafafa',
    white3: '#ededed',
    white4: '#fcfcfc',
    orange1: '#dda101',
    yellow1: '#f9d026',
    yellow5: '#fdf5e0',
    yellow6: '#fef9e7',
    grey4: '#c4c4c4',
    grey5: '#898f9a',
    grey6: '#a8aeb8',
    grey7: '#2b363d',
    grey1: '#262e35',
    greyE5: '#e5e6e7',
    greyF5: '#f5f5f5',
    greyFA: '#fafafa',

    redCE: '#ce5659',
    red5: '#ff8a65',
    grey8: '#e0e0e0',
    grey10: '#d3d4d6',
    grey11: '#f7f7f7',
    grey9: '#e7e8e9',
    green3: '#66bb6a',
    blue1: '#b3d7fe',
    grey700: '#36404E',
    grey800: '#1F2835',
  },
  components: overrides as any,

  icon: {
    baseIconButton: {
      fill: '#dda100',
      fontSize: 24,
    },
  },
});

type Props = {
  children: React.ReactNode;
};

export function ThemeProvider(props: Props) {
  const { children } = props;

  return (
    <ThemeProviderMUI theme={theme}>
      <ThemeProviderStyled theme={theme}>{children}</ThemeProviderStyled>
    </ThemeProviderMUI>
  );
}

import { Observer } from 'mobx-react';
import * as React from 'react';

import { ImportErrorsModal as ImportErrorsModalComponent } from '../../components/ImportErrorsModal';

import { ResultsContext } from '../../context';
import { ResultsContextType } from '../../context';

function ImportErrorsModal() {
  const { results } = React.useContext<ResultsContextType>(ResultsContext);

  return (
    <Observer>
      {() => {
        const errors = results.stores.importErrors.values;
        const isOpen = results.stores.importErrors.isModalOpen;
        const onClose = results.actions.closeImportErrorsModal;

        return <ImportErrorsModalComponent isOpen={isOpen} errors={errors} onClose={onClose} />;
      }}
    </Observer>
  );
}

export { ImportErrorsModal };

import { smartLinkOrderStore, smartLinkStore } from '../stores';

import { getSmartLinkData } from './fetchData';

export const mount = () => {
  getSmartLinkData();
};

export const unmount = () => {
  smartLinkOrderStore.clear();
  smartLinkStore.clear();
};

import axios from 'axios';

import { PAYMENTS_URL } from 'src/constants';

import { request, action } from 'utils';

import { LOAD_PAYMENTS } from '../constants';

import { paymentsStore } from '../stores';
import { Payment as PaymentStoreType } from '../stores';

export class PaymentsService implements ExtensionableService<any>, ExtensionableSearchService, ExtensionableSortService {
  @request({ action: LOAD_PAYMENTS })
  async loadPaymentsRequest(params: nil | FiltersType): Promise<any> {
    return axios.get(PAYMENTS_URL, { params });
  }

  @action({ action: LOAD_PAYMENTS })
  async load(
    params: {
      limit?: number;
      page?: number;
    } = {},
  ): Promise<void> {
    let { page: storePage, params: storeParams, filters: storeFilters } = paymentsStore;

    let { limit = storeFilters.limit, page = storePage } = params;

    if (params.limit) {
      page = 1;
    }

    const queryParams = {
      ...storeParams,
      page,
      limit,
    };

    const [status, response] = await this.loadPaymentsRequest(queryParams);

    if (status) {
      const body = response.data;
      const paginationMeta = body.meta?.pagination;

      paymentsStore.addValues(body.data, page, { ...storeFilters, limit });

      if (paginationMeta) {
        paymentsStore.addPaginationMeta(paginationMeta);
      }
    }
  }

  get store() {
    return paymentsStore as any;
  }
}

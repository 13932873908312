import SearchIcon from '@mui/icons-material/Search';
import * as React from 'react';

import { Show } from 'components/Condition';

type Props = {
  children: React.ReactNode;
  displaySearchIcon: boolean;
};

export function PlainLabel({ children, displaySearchIcon }: Props) {
  return (
    <div className='label-wrapper'>
      <Show if={displaySearchIcon}>
        <SearchIcon className='filter-icon' />
      </Show>
      <span className='label'>{children || null}</span>
    </div>
  );
}

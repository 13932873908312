import { progressStore } from 'stores';

import { DASHBOARD_STATES } from '../constants/dashboard.constants';

import { DashboardService } from '../services/dashboard.service';
import { dashboardRaceService } from '../services/races.service';

import { dashboardRacesStore } from '../stores/races.store';

import { resetDashboardStores } from './resert-dashboard.action';

export namespace DashboardActions {
  export async function init() {
    if (dashboardRacesStore.races.length === 0) {
      await dashboardRaceService.loadRaces(true, 1);
    }
    await loadAll();
  }

  export function deinit() {
    dashboardRacesStore.clear();
  }

  export async function loadAll() {
    progressStore.log(DASHBOARD_STATES.LOADING, 'progress');
    resetDashboardStores();
    try {
      await Promise.allSettled([
        DashboardService.loadAgeData(1),
        DashboardService.loadRegistrationsData(),
        DashboardService.loadTurnoverData(),
        DashboardService.loadParticipantsData(),
        DashboardService.loadCouponsData(),
        DashboardService.loadGendersData(),
        DashboardService.loadTransactions(),
        DashboardService.loadCountryData(),
      ]);
    } catch (error) {
      resetDashboardStores();
    } finally {
      progressStore.log(DASHBOARD_STATES.LOADING, 'completed');
      progressStore.log('dashboard', 'completed');
    }
  }
}

import { t } from 'utils';

import { errorsStore } from 'stores';

import { SUPPORT_TASK_MANAGER_FORM } from '../constants';

import { bibClassesStore } from '../stores';

import { BIB_MAX, BIB_MIN } from '.';
import { TaskForm } from '../types';

export const dynamicValidation = (taskForm: TaskForm): boolean => {
  let errors: AnyObject = [];
  let hasErrors: boolean = false;

  bibClassesStore.value?.forEach((item) => {
    const classId = item.value;
    const formFieldName = 'bib' + classId;

    if (
      taskForm.bibDistanceClassStarting &&
      taskForm.bibDistanceClassStarting.hasOwnProperty(classId) &&
      taskForm.bibDistanceClassStarting[classId]
    ) {
      const bib = taskForm.bibDistanceClassStarting[classId];

      if (bib > BIB_MAX || bib < BIB_MIN) {
        hasErrors = true;
        errors[formFieldName] = [t.staticAsString('supportTaskManager.form.validation.bibNumber')];
      }
    } else {
      hasErrors = true;
      errors[formFieldName] = [t.staticAsString('validation.required')];
    }
  });

  if (hasErrors) {
    errorsStore.add(SUPPORT_TASK_MANAGER_FORM, errors);
    return false;
  }

  return true;
};

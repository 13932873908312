import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

import { TranslationField } from 'components/Form/Fields/TranslationField';

import { googleTranslate } from 'actions';

import { ScrollableTab } from '../ScrollableTab';
import { GoogleTranslateButton } from './GoogleTranslateButton';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '5%',
    display: 'flex',
    width: 800,
    flexFlow: 'row',
    flexWrap: 'wrap',
    '& > div': {
      margin: 20,
    },
    '& > button': {
      margin: 20,
    },
  },
  padded: {
    padding: 40,
  },
}));

const locales: availableLocales[] = ['en', 'sv', 'fi', 'fr', 'no', 'es'];

export const GoogleTranslateContainer = () => {
  const classes = useStyles();
  const [name, setName] = React.useState('');
  const [prefilled, setPrefilled] = React.useState('');
  const [label, setLabel] = React.useState('');

  const onClick = () => {
    setName(prefilled);
    setPrefilled('');
  };
  const onMouseEnter = () => {
    requestAnimationFrame(async () => {
      const result = await googleTranslate({
        q: label,
        from: locales[defaultValue],
        to: locales[value],
      });
      //   setPrefilled(result);
    });
  };
  const onMouseLeave = async () => {
    setPrefilled('');
  };

  const handleChange = ({ name, value }) => {
    setName(value);
  };
  const inputLabel = (event) => {
    setLabel(event.target.value);
  };

  const [defaultValue, setDefaultValue] = React.useState(0);

  const onDefaultChange = (event, newValue) => {
    setDefaultValue(newValue);
  };
  const [value, setValue] = React.useState(0);

  const onChange = (event, newValue) => {
    setValue(newValue);
  };

  const isFinished = (locale) => {
    switch (locale) {
      case 'en':
        return false;
      case 'sv':
        return true;
      default:
        return false;
    }
  };

  return (
    <div className={classes.root}>
      <TextField label='Default language text' value={label} onInput={inputLabel} />

      <div className={classes.padded}>
        Default language
        <ScrollableTab locales={locales} onChange={onDefaultChange} value={defaultValue} isFinished={() => false} />
      </div>
      <div className={classes.padded}>
        What user will actuatlly use
        <ScrollableTab locales={locales} onChange={onChange} value={value} isFinished={isFinished} />
      </div>
      <GoogleTranslateButton onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} />
      <TranslationField
        name='1'
        label={label}
        onChange={handleChange}
        value={name}
        prefilledValue={prefilled}
        hasCheckmark={name.length > 5}
      />
    </div>
  );
};

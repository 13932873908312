import { Button } from '@mui/material';
import React, { ReactElement } from 'react';
import styled from 'styled-components';

import { openModal } from 'src/modules/Distances/components/shared/Steps/GPX/actions';

import { t } from 'utils';

const Wrapper = styled.div`
  width: 100%;
  padding: 0 32px;
  margin: 32px 0;

  .button-manage-checkpoint {
    width: 100%;
    margin: 0;
    padding: 10px 16px;
    border: 1px solid #a8aeb8;
    font-size: 14px;
    color: #dda100;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
  .button-manage-checkpoint:hover,
  button-manage-checkpoint:active {
    background: rgba(249, 208, 38, 0.12);
  }
`;

export const ButtonManageCheckPoints = (): ReactElement => {
  return (
    <Wrapper>
      <Button className='button-manage-checkpoint' onClick={openModal}>
        {t.staticAsString('distances.steps.helperText.gpx.manageCheckPoint')}
      </Button>
    </Wrapper>
  );
};

import { Box, IconButton } from '@mui/material';
import { Observer } from 'mobx-react-lite';
import { loadMoreRaces, searchRaces } from 'modules/SmartCampaignLinks/Form/actions';

import { AutocompleteSelectInfinityLoad, InputWrapper } from 'src/components/Form';
import { Svg } from 'src/components/Icon';

import { t } from 'utils/t';

import { racesHasMoreStore, racesStore } from '../stores';

type Props = {
  formKey: string;
  handleRemove: (formKey: string) => void;
};
export const RaceField = ({ handleRemove, formKey }: Props) => {
  return (
    <Observer>
      {() => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <InputWrapper
              name={formKey}
              settings={{
                label: t.staticAsString('dashboard.event'),
                additional: {
                  options: racesStore.value || [],
                },
                view: {
                  reservePlaceForHelperText: true,
                  required: true,
                  fullWidth: true,
                },
                hasMore: racesHasMoreStore.value,
                loadMore: loadMoreRaces,
                onSearch: searchRaces,
              }}
              Component={AutocompleteSelectInfinityLoad}
            />
            <IconButton sx={{ mb: 1.5, ml: 1 }}>
              <Svg name='Trash' size={24} onClick={() => handleRemove(formKey)} />
            </IconButton>
          </Box>
        );
      }}
    </Observer>
  );
};

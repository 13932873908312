import { omit } from 'lodash';
import { toJS } from 'mobx';

import { countriesStore, currenciesStore, form, sessionStore } from 'src/stores';

import { SystemUserFormPageConstants } from '../constants/systemUserFormPage.constants';

import { formToValidRole, predefineCreateBy, predefineRole, setCreateById, isOwnProfile } from '../utils';

import { SystemUserCommissionsStore } from '../stores/systemUserCommissions.store';

import { SystemUserForm } from '../types';

export namespace SystemUserFormPageMappers {
  export function fromBackend(systemUser: SystemUserForm): SystemUserForm {
    systemUser.selectedCurrencies = systemUser.payment_details.map((payment) => {
      const commissionByPayment = SystemUserCommissionsStore.defaultCommissions.value?.find(
        ({ currency_id }) => currency_id === payment.currency_id,
      );
      const currency = currenciesStore.values.find((currency) => currency.id === payment.currency_id);

      if (!commissionByPayment) {
        throw new Error('Commission not found');
      }
      const commission = {
        refund_fee: commissionByPayment!.refund_fee / 100,
        amount: commissionByPayment!.amount / 100,
        percent_value: commissionByPayment!.percent_value / 100,
        vat_percents: `${commissionByPayment!.vat_percents / 100}`,
      };

      form.merge(currency?.iso_code ?? 'EUR', {
        ...payment,
        ...commission,
        country_id: {
          value: payment.country_id,
          key: payment.country_id,
          label: countriesStore.values.find((c) => c.id === payment.country_id)?.short_name_en,
        },
      });
      return {
        key: payment.currency_id,
        value: payment.currency_id,
        label: currency?.iso_code,
      };
    });

    systemUser.role = predefineRole(systemUser);
    systemUser.created_by = predefineCreateBy(systemUser) as number;

    return systemUser;
  }

  export function toBackend(systemUser: SystemUserForm): AnyObject {
    const omits = [
      'updated_at',
      'created_at',
      'roles',
      'payment_details',
      'selectedCurrencies',
      'device_token',
      'description',
      'facebook',
      'instagram',
      'linkedin',
      'website',
      'deleted_at',
      'verified',
      'commissions',
      'username',
      'country_id',
      isOwnProfile() ? 'role' : '',
    ];

    const omittedSystemUserValue = omit(systemUser, omits);

    return {
      ...omittedSystemUserValue,
      ...(!isOwnProfile() && { role: formToValidRole(systemUser.role) }),
      created_by: setCreateById(systemUser),
    };
  }

  export function toBackendPaymentDetails(paymentDetail: AnyObject, currencyLabel: string): any {
    const currencyId = currenciesStore.currencyByLabel(currencyLabel)?.id;
    let omittedPaymentDetails;

    const paymentDetailsOmitKeys = ['created_at', 'updated_at', 'deleted_at', 'amount', 'percent_value', 'refund_fee', 'vat_percents'];

    if (paymentDetail?.country_id?.value === SystemUserFormPageConstants.swedenId) {
      omittedPaymentDetails = omit(paymentDetail, [
        ...paymentDetailsOmitKeys,
        'vat_number',
        'vat_exemption',
        'swift_big',
        'iban_number',
        'clearing_routing_bsb_code',
        'bank_name',
        'bank_address',
      ]);
    } else {
      omittedPaymentDetails = omit(
        paymentDetail,
        [...paymentDetailsOmitKeys],
        'pg_bg',
        'clearing_number',
        'account_number',
        paymentDetail?.vat_exemption ? 'vat_number' : '',
      );
    }

    return {
      currency_id: currencyId,
      payment_details: {
        ...omittedPaymentDetails,
        country_id: paymentDetail?.country_id?.value,
        receive_payments: paymentDetail?.receive_payments ?? false,
      },
      ...(sessionStore.isAdmin
        ? {
            commission: {
              amount: Number(paymentDetail?.amount) * 100,
              percent_value: Number(paymentDetail?.percent_value) * 100,
              refund_fee: Number(paymentDetail?.refund_fee) * 100,
              vat_percents: Number(paymentDetail?.vat_percents) * 100,
            },
          }
        : {}),
    };
  }
}

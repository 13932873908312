import { Box } from '@mui/material';
import { Observer } from 'mobx-react';
import { reloadRace } from 'modules/Races/actions';
import { RACE_FORM_ID, SHOW_TRANSLATIONS_WARNING } from 'modules/Races/constants';
import React, { ReactElement, useState, useEffect } from 'react';

import { Show } from 'components/Condition';
import { InformationNote } from 'components/InformationNote';
import TranslationStages from 'components/Stepper/Step/TranslationStages';

import { t } from 'utils/t';

import { form, helperRacesStore } from 'stores';

import { formStyles } from '../../styles';
import { StepTitle } from '../Shared/StepTitle';
import { StepProps } from '../sharedProps';

type Props = StepProps & {};

function Translations({ step }: Props): ReactElement {
  const classes = formStyles();
  const languagesData = form.fetch<RaceFormData>(RACE_FORM_ID)?.languagesData;
  const [isNoteVisible, setIsNoteVisible] = useState(form.fetch<boolean>(RACE_FORM_ID, SHOW_TRANSLATIONS_WARNING) ?? false);

  const handleOnDismiss = () => {
    form.onChange(RACE_FORM_ID, SHOW_TRANSLATIONS_WARNING, false);
    setTimeout(() => {
      setIsNoteVisible(false);
    }, 300);
  };

  useEffect(() => {
    return () => {
      reloadRace();
    };
  }, []);

  return (
    <>
      <StepTitle step={step} />
      <Show if={isNoteVisible}>
        <Box padding={'0 16px'} width={'100%'} maxWidth={475}>
          <InformationNote
            onDissmiss={handleOnDismiss}
            isDissmissible
            title={t.staticAsString('races.steps.languages.note')}
            body={`You have updated some of the stages, please check if you need to update the related translations. In case you leave some sections blank for a language translation, these sections will be displayed in your race’s default language. `}
          />
        </Box>
      </Show>
      <div className={classes.wrapperFullWidth}>
        <Observer
          render={() => {
            return (
              <TranslationStages
                data={helperRacesStore.selected as DataWithTranslations}
                locales={languagesData?.selectedLanguages}
                defaultLocale={languagesData?.defaultLocale}
                reloadData={reloadRace}
              />
            );
          }}
        ></Observer>
      </div>
    </>
  );
}

export { Translations };

import classNames from 'classnames';
import * as React from 'react';

import { TableHeaderItem } from 'components/Filter/TableHeaderItem';

import { t } from 'utils';

import { TEAM_RESULTS_COLUMNS } from '../../constants';

import { isColumnVisibleTeam } from '../../tools/isColumnVisible';
import { HeadersType } from './';

const { MAIN_COLUMNS, COLUMN_WIDTH } = TEAM_RESULTS_COLUMNS;

const classes = {
  thead: {
    regular: ['start-list-table-header', 'filter-item'],
  },
};

function TeamHeadersComponent({}: HeadersType) {
  return MAIN_COLUMNS.map<React.ReactNode>((column) => {
    if (!isColumnVisibleTeam(column)) {
      return null;
    }

    const width = COLUMN_WIDTH[column];
    const className = `cell-${column}`;
    const translation = `results.fields.${column}`;

    return (
      <TableHeaderItem
        key={column}
        name={column}
        width={width}
        className={classNames(...classes.thead.regular, className)}
        label={t(translation as TranslationLockedKeys) as any}
      />
    );
  }) as any;
}

const TeamHeaders = React.memo<HeadersType>(TeamHeadersComponent);
export { TeamHeaders };

import { EXPORT_DATA, EXPORT_DATA_SIZES } from 'src/constants';

import { appEnvControl } from 'utils/appEnvironment';
import { handleExport } from 'utils/monitorLoadingJob';
import { t } from 'utils/t';

import { exportJobService } from 'services';

import { toastStore } from 'stores';

import { smartLinksFiltersStore, smartLinksStore } from '../stores';

export const onExportData = async (e: React.SyntheticEvent<any>, type: string): Promise<any> => {
  const { total = EXPORT_DATA.defaultMetaData } = smartLinksStore.value?.meta.pagination!;
  const params = smartLinksFiltersStore.value!;

  if (total > EXPORT_DATA.metaDataLimit) {
    toastStore.show(t.staticAsString('shared.loadPopup.tooMuchData'));
    return;
  }

  const [status, response] = await exportJobService.exportSmartLinks(type, params);

  if (!status) {
    return;
  }

  const { id } = response.data.data;
  const maxTotal = EXPORT_DATA_SIZES[appEnvControl.currentEnv() || 'production'];
  let estimatedLoadingTime = total / EXPORT_DATA.metaDataLoadingRatio; // 1500 orders loaded per ~1 second
  if (total > maxTotal) {
    estimatedLoadingTime = maxTotal / EXPORT_DATA.metaDataLoadingRatio;
  }

  handleExport({
    id,
    estimatedLoadingTime,
    fileName: `SmartLinks.${type}`,
    onRetry: () => onExportData(e, type),
  });
};

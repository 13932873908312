import Sync from '@mui/icons-material/Sync';
import { Box, Card, Grid, Typography, IconButton } from '@mui/material';
import cn from 'classnames';
import { Observer, observer } from 'mobx-react';
import RaceSearch from 'modules/Dashboard/common/components/RaceSearch';
import { emptyData, emptyGenderData, emptyTransationsData } from 'modules/Dashboard/common/empty-data';
import { dashboardRacesStore } from 'modules/Dashboard/stores/races.store';
import { mergeRaceForm } from 'modules/Dashboard/utils/merge-race-form.utils';
import { useEffect, useState } from 'react';

import { Form } from 'components/Form';
import { Icon } from 'components/Icon';
import { Spinner } from 'components/Spinner';

import { history } from 'utils/history';
import { t } from 'utils/t';

import { progressStore } from 'stores';

import { DASHBOARD_STATES } from '../constants/dashboard.constants';

import { DashboardActions } from '../actions/init';

import { DashboardStore } from '../stores/dashboard.store';

import { dashboardTextStyles } from '../common/text.styles';
import DashboardCard from './components/Card';
import DashboardTransactions from './components/Transactions';
import AgeBarChartSmall from './graphs/AgeBarChartSmall';
import Gender from './graphs/Gender';
import Participants from './graphs/Participants';

const Dashboard = observer(() => {
  // const paymentsRef = useRef<HTMLSpanElement>(null);
  // const turnoverRef = useRef<HTMLSpanElement>(null);
  // const coupounsRef = useRef<HTMLSpanElement>(null);

  const [isLoading, setIsLoading] = useState(!dashboardRacesStore.selectedRace);
  const textStyles = dashboardTextStyles;

  useEffect(() => {
    mergeRaceForm();
    const load = async () => {
      try {
        await DashboardActions.init();
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    };
    load();
  }, []);

  const handleNavigate = (id: string) => {
    switch (id) {
      case 'registrations':
        history.push('/dashboard/registrations');
        break;
      case 'turnover':
        history.push('/dashboard/turnover');
        break;
      case 'participants':
        history.push('/dashboard/participants');
        break;
      case 'age':
        history.push('/dashboard/age');
      default:
        break;
    }
  };

  if (isLoading || progressStore.isLoading('dashboard')) {
    return (
      <Box>
        <Spinner type='cubesSpinner' />
      </Box>
    );
  }

  return (
    <Observer
      render={() => {
        return (
          <Box style={{ overflow: 'scroll', height: '100%', backgroundColor: '#FAFAFA' }} p={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
              <Form id={DASHBOARD_STATES.RACE_SEARCH_FORM}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ mr: 2 }}>{t('dashboard.showAnalytics')}:</Typography> <RaceSearch />
                </Box>
              </Form>
              <IconButton disabled={progressStore.isLoading(DASHBOARD_STATES.LOADING)} onClick={() => DashboardActions.init()}>
                <Sync
                  className={cn({
                    'rotate-360': progressStore.isLoading(DASHBOARD_STATES.LOADING),
                  })}
                />
              </IconButton>
            </Box>
            <Grid container spacing={2}>
              <Grid item md={12} lg={5}>
                <DashboardCard collection='payments' />
              </Grid>
              <Grid item md={12} lg={5}>
                <DashboardCard collection='turnover' />
              </Grid>
              <Grid item md={12} lg={2}>
                <DashboardCard collection='coupons' />
              </Grid>
            </Grid>

            <Grid style={{ marginTop: 24 }} container spacing={2}>
              <Grid item md={12} lg={7}>
                <DashboardTransactions transactions={DashboardStore.transactions.value || emptyTransationsData} />
              </Grid>

              <Grid item md={12} lg={5}>
                <Card sx={{ height: '100%' }} onClick={() => handleNavigate('participants')}>
                  <Box p={3}>
                    <Box sx={{ cursor: 'pointer' }} display='flex' justifyContent='space-between'>
                      <Typography sx={textStyles.cardTitle}>{t('dashboard.participants')}</Typography>
                      <Icon value='expand' />
                    </Box>
                    <Box mt={3}>
                      <Participants data={DashboardStore.participants.value?.data ?? emptyData} />
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>

            <Grid style={{ marginTop: 24 }} container spacing={2}>
              <Grid item md={12} lg={6}>
                <Card onClick={() => handleNavigate('age')}>
                  <Box p={3}>
                    <Box sx={{ cursor: 'pointer' }} display='flex' justifyContent='space-between'>
                      <Typography sx={textStyles.cardTitle}>{t('dashboard.age')}</Typography>
                      <Icon value='expand' />
                    </Box>
                    <Box mt={3}>
                      <AgeBarChartSmall
                        width={570}
                        height={360}
                        id='age'
                        data={DashboardStore.age.value?.data ?? emptyData}
                        color='252, 199, 93'
                      />
                    </Box>
                  </Box>
                </Card>
              </Grid>

              <Grid item md={12} lg={6}>
                <Card>
                  <Box p={3}>
                    <Box display='flex' justifyContent='space-between'>
                      <Typography sx={textStyles.cardTitle}>{t('dashboard.gender')}</Typography>
                    </Box>
                    <Box mt={2}>
                      <Gender id='age' data={DashboardStore.genders.value ?? emptyGenderData} color='252, 199, 93' />
                    </Box>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    />
  );
});
export { Dashboard };

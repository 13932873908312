import 'flag-icon-css/css/flag-icon.css';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import 'regenerator-runtime/runtime';

import { app, appEnvControl, tags, apiOriginManager, setupUserPlatform } from 'utils';

import { App } from './App';

app.init();
setupUserPlatform();
appEnvControl.setCurrentEnvForApp();
tags.gtm.init();

const container = document.getElementById('root');

if (container !== null) {
  const root = createRoot(container);
  root.render(<App />);
}

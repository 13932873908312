import { debounce, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import shortid from 'shortid';

import { LOGIN, ROUTES } from 'src/constants';

import { withErrorClean } from 'hocs';

import { Input } from 'components/Fields/Input';
import { AuthSubmit } from 'components/core/Buttons';

import { intercomUtil, t, validation, oneFieldValidation } from 'utils';

import { AuthService } from '../services/auth';

import { Props, SignInState } from '../types';
import { base as ValidateConstraints } from './validations';

const action = LOGIN;
@withRouter
@withErrorClean(action)
@inject('sessionStore')
@inject('errorsStore')
@observer
class SignIn extends React.Component<Props, SignInState> {
  service: AuthService;
  id: string;

  constructor(props: Props) {
    super(props);
    this.service = new AuthService();
    this.id = shortid();
  }

  componentDidMount() {
    intercomUtil.shutdown();
  }

  componentWillUnmount() {
    intercomUtil.init();
  }

  state = { email: '', password: '' };

  @validation({ action, constrains: ValidateConstraints })
  validate() {
    return this.state;
  }

  debouncedValidation = debounce((name: string) => {
    const constrainsField = { [name]: ValidateConstraints[name] };

    this.props.errorsStore.clearError(action);
    oneFieldValidation(action, this.state, constrainsField);
  }, 400);

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { email, password } = this.state;

    const isValid = this.validate();

    if (!isValid || !isEmpty(this.errors())) {
      return;
    }

    this.service.login(email, password);
  };

  onChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;

    const name = target.name;
    const value = target.value;

    this.setState(
      {
        ...this.state,
        [name]: value,
      },
      () => this.debouncedValidation(name),
    );
  };

  errors = (): {
    [K in string]: Array<string>;
  } => {
    const { errorsStore } = this.props;
    const errors = errorsStore.errors.api[action];
    const validationErrors = errorsStore.errors.validation[action];

    if (validationErrors) {
      return validationErrors.formatErrors();
    }
    if (errors) {
      const message = t.staticAsString('login.invalidUserName');
      return { email: [message], password: [message] };
    }
    return {};
  };

  defaultInputProps = (name: string): any => ({
    name,
    onChange: this.onChange,
    id: this.id,
    value: this.state[name],
    errors: this.errors()[name],
    label: `${t.staticAsString(`login.${name}` as TranslationLockedKeys)}*`,
  });

  render() {
    return (
      <form className='login' onSubmit={this.onSubmit}>
        <Input {...this.defaultInputProps('email')} type='email' />
        <Input {...this.defaultInputProps('password')} type='password' />
        <NavLink to={ROUTES.forgotPassword} className='forgot-password'>
          {t.staticAsString('login.forgotPassword')}
        </NavLink>
        <AuthSubmit label={t.staticAsString('login.login')} />
        <div className='login-signup-box'>
          <span className='login-signup-box--text'>{t.staticAsString('login.haveAccount.text')}</span>&nbsp;
          <NavLink to={ROUTES.signUp} className='login-signup-box--link'>
            {t.staticAsString('login.signup.link')}
          </NavLink>
        </div>
      </form>
    );
  }
}

export { SignIn };

import { t } from 'utils';

export const action = (): any => ({
  action: {
    presence: {
      allowEmpty: false,
      message: t.staticAsString('validation.required'),
    },
  },
});

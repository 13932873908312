import * as React from 'react';

import { TimeDuration, TextField, Select, DateTime } from 'components/Form/Fields';

type FieldType = 'text' | 'select' | 'datetime' | 'duration';

function generateField(
  type: FieldType,
  value: any,
  onChange: FieldOnChange,
  onBlur: AnyFunction,
  fieldId: string,
  error: string | nil,
  options: SelectOption[] = [],
) {
  switch (type) {
    case 'text':
      return (
        <TextField
          value={value}
          onChange={onChange}
          name={fieldId}
          id={fieldId}
          label=''
          error={error}
          additional={{ onBlur: onBlur, autoFocus: true }}
        />
      );
    case 'select':
      return (
        <Select
          value={value}
          onChange={onChange}
          name={fieldId}
          id={fieldId}
          label=''
          error={error}
          options={options}
          view={{ fullWidth: true }}
          additional={{ onBlur: onBlur, autoFocus: true }}
        />
      );
    case 'datetime':
      return (
        <DateTime
          value={value}
          onChange={onChange}
          name={fieldId}
          id={fieldId}
          label=''
          error={error}
          additional={{ onBlur: onBlur, autoFocus: true }}
        />
      );
    case 'duration':
      return (
        <TimeDuration
          value={value}
          onChange={onChange}
          name={fieldId}
          id={fieldId}
          label=''
          error={error}
          additional={{ onBlur: onBlur, autoFocus: true }}
        />
      );

    default:
      return null;
  }
}

export { generateField };

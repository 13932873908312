import axios from 'axios';

import { AVAILABLE_LOCALES_URL } from 'src/constants';

import { action } from 'utils/core/action';
import { request } from 'utils/core/request';

class LoadAvailableLocalesService {
  @request({ action: `LOAD_LOCALES` })
  async loadLocalesRequest(): Promise<any> {
    return axios.get(AVAILABLE_LOCALES_URL);
  }

  @action({ action: `LOAD_LOCALES` })
  async loadLocales(): Promise<any> {
    const [status, response] = await this.loadLocalesRequest();
    if (status) {
      return response.data.data;
    } else {
      return [];
    }
  }
}

const loadAvailableLocalesService = new LoadAvailableLocalesService();
export { loadAvailableLocalesService, LoadAvailableLocalesService };

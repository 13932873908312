import { intersection } from 'lodash';
import { SystemUserCommissionsStore } from 'modules/SystemUsers/stores/systemUserCommissions.store';
import * as React from 'react';

import { currenciesStore } from 'stores';

import { CommissionsTableWrapper } from '../Detail/CommissionsTable';

export const Commissions = () => {
  const inter = intersection(
    currenciesStore.values.map(({ id }) => id),
    SystemUserCommissionsStore.currencies.value,
  );
  const availableCurrencies = currenciesStore.values.filter((c) => inter.includes(c.id)).map((c) => c.iso_code);

  return <CommissionsTableWrapper currencies={availableCurrencies} />;
};

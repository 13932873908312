import { Observer } from 'mobx-react';
import React from 'react';

import { Toolbar, Header, Transactions, CampaignLinksDetailsWrapper, TopSection } from '../components';

import { mount, unmount } from '../actions';

import { campaignLinkStore } from '../stores';

export const CampaignLinksDetails = () => {
  React.useEffect(() => {
    mount();
    return () => {
      unmount();
    };
  }, []);

  return (
    <Observer>
      {() => {
        const campaignLinkData = campaignLinkStore.value;
        return !campaignLinkData ? null : (
          <CampaignLinksDetailsWrapper>
            <TopSection>
              <Toolbar />
              <Header campaignLinkData={campaignLinkData} />
            </TopSection>
            <Transactions token={campaignLinkData.token} />
          </CampaignLinksDetailsWrapper>
        );
      }}
    </Observer>
  );
};

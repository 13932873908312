import FormHelperText from '@mui/material/FormHelperText';
import MaterialTooltip from '@mui/material/Tooltip/Tooltip';
import * as React from 'react';

import { Icon } from 'components/Icon';

import { stringFormatter } from 'utils';

function Tooltip({ text, position, error }: InputTooltip | any) {
  switch (position) {
    case 'bottom':
      if (!!error || !text) return null;
      return !error && text && <FormHelperText className='tooltip-text'>{text}</FormHelperText>;

    case 'icon':
      return (
        !!text && (
          <MaterialTooltip title={stringFormatter.withNewLine(text)} classes={{ tooltip: 'switch-popover' }}>
            <span>
              <Icon className='info-helper' value='info' />
            </span>
          </MaterialTooltip>
        )
      );

    default:
      return null;
  }
}

export { Tooltip };

import Button from '@mui/material/Button';
import * as React from 'react';

import { Props } from './';

function Apply({ children, onClick, ...rest }: Props) {
  return (
    <Button size='medium' variant='contained' color='primary' disableElevation onClick={onClick} {...rest}>
      {children || null}
    </Button>
  );
}

export { Apply };

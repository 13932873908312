import * as React from 'react';
import { withRouter } from 'react-router-dom';
import shortid from 'shortid';

import { ROUTES } from 'src/constants';

import { NavToolbar } from 'components/NavToolbar';

import { t } from 'utils';

type Props = {
  distance: DistanceType;
  match?: Object;
};

@withRouter
export class Breadcrumbs extends React.Component<Props> {
  items = (): Array<NavToolbarItemType> => {
    const { distance } = this.props;
    const race = distance.race;
    return [
      { key: shortid(), path: ROUTES.racesRoute, label: t.staticAsString('races.detail.nav.races' as TranslationLockedKeys) as any },
      // @ts-ignore
      { key: shortid(), path: `${ROUTES.racesRoute}/${race.value.id}`, label: race.value.name },
      // @ts-ignore
      { key: shortid(), label: distance.name() },
    ];
  };

  render() {
    return <NavToolbar items={this.items()} />;
  }
}

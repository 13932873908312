import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { ORGANIZERS_UTILS_URL, TASKS_URL } from 'src/constants';

import { request, action } from 'utils';

import { LOAD_ORGANIZERS, LOAD_TASKS } from '../constants';

import { loadTasks as loadRacersAction } from '../actions';

import { tasksStore } from '../stores';

export class TaskService implements ExtensionableService<unknown>, ExtensionableSearchService, ExtensionableSortService {
  @request({ action: LOAD_TASKS })
  loadRacersRequest(params: nil | FiltersType): any {
    const path = generatePath(TASKS_URL);
    return axios.get(path, { params });
  }

  @request({ action: LOAD_ORGANIZERS })
  private loadOrganizersRequest(params: nil | FiltersType): any {
    const path = generatePath(ORGANIZERS_UTILS_URL);
    return axios.get(path, { params });
  }

  @action({ action: LOAD_TASKS })
  async loadRacers(params: nil | FiltersType) {
    const [status, response] = await this.loadRacersRequest(params);

    return [status, response.data];
  }

  get store() {
    return tasksStore;
  }

  @action({ action: LOAD_TASKS })
  async load(params?: { limit?: number; page?: number }): Promise<void> {
    await loadRacersAction(params);
  }

  @action({ action: LOAD_ORGANIZERS })
  async loadOrganizers(params: nil | FiltersType) {
    const [status, response] = await this.loadOrganizersRequest(params);

    return [status, response.data];
  }
}

const taskService = new TaskService();

export { taskService };

import { t } from 'src/utils';

const systemUserSchema = {
  email: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    email: true,
  },
  full_name: {
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
  password: {
    length: {
      minimum: 6,
      maximum: 255,
    },
  },
  role: {
    presence: {
      allowEmpty: false,
    },
  },
  created_by: {
    presence: {
      allowEmpty: false,
    },
  },

  // Contact info
  company: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
  organization_number: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
  firstname: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
  lastname: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
  address: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
  phone: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
  city: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
  post_code: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
};

const timingMedicalSchema = {
  email: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    email: true,
  },
  password: {
    length: {
      minimum: 6,
      maximum: 255,
    },
  },
  role: {
    presence: {
      allowEmpty: false,
    },
  },

  firstname: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
  lastname: {
    presence: {
      allowEmpty: false,
      message: () => t.staticAsString('shared.errors.presence'),
    },
    length: {
      minimum: 1,
      maximum: 200,
    },
  },
};

const paymentDetailsSchema = {
  country_id: {
    presence: {
      allowEmpty: true,
      message: () => t.staticAsString('shared.errors.presence'),
    },
  },
  // vat_exemption: {
  //   presence: (_value: number | null, object: AnyObject) => {
  //     if (object['vat_exemption'] === undefined) {
  //       return {
  //         message: () => {
  //           return t.staticAsString('shared.errors.presence');
  //         },
  //         allowEmpty: true,
  //       };
  //     }

  //     return null;
  //   },
  // },
  // vat_number: {
  //   presence: {
  //     allowEmpty: true,
  //     message: () => t.staticAsString('shared.errors.presence'),
  //   },
  //   length: {
  //     minimum: 3,
  //   },
  // },
  // pg_bg: {
  //   presence: {
  //     allowEmpty: true,
  //     message: () => t.staticAsString('shared.errors.presence'),
  //   },
  //   length: {
  //     minimum: 3,
  //   },
  // },
  // account_number: {
  //   presence: {
  //     allowEmpty: true,
  //     message: () => t.staticAsString('shared.errors.presence'),
  //   },
  //   length: {
  //     minimum: 3,
  //   },
  // },
  // clearing_number: {
  //   presence: {
  //     allowEmpty: true,
  //     message: () => t.staticAsString('shared.errors.presence'),
  //   },
  //   length: {
  //     minimum: 3,
  //   },
  // },
  // iban_number: {
  //   presence: {
  //     allowEmpty: true,
  //     message: () => t.staticAsString('shared.errors.presence'),
  //   },
  //   length: {
  //     minimum: 3,
  //   },
  // },
  // swift_big: {
  //   presence: {
  //     allowEmpty: true,
  //     message: () => t.staticAsString('shared.errors.presence'),
  //   },
  //   length: {
  //     minimum: 3,
  //   },
  // },
  // clearing_routing_bsb_code: {
  //   presence: {
  //     allowEmpty: true,
  //     message: () => t.staticAsString('shared.errors.presence'),
  //   },
  //   length: {
  //     minimum: 3,
  //   },
  // },
  // bank_name: {
  //   presence: {
  //     allowEmpty: true,
  //     message: () => t.staticAsString('shared.errors.presence'),
  //   },
  //   length: {
  //     minimum: 3,
  //   },
  // },
  // bank_address: {
  //   presence: {
  //     allowEmpty: true,
  //     message: () => t.staticAsString('shared.errors.presence'),
  //   },
  //   length: {
  //     minimum: 3,
  //   },
  // },
};

export { systemUserSchema, paymentDetailsSchema, timingMedicalSchema };

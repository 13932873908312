import { t } from 'utils/t';

const presence = (messageKey?: TranslationLockedKeys) => {
  return {
    presence: {
      allowEmpty: false,
      message: t.staticAsString(messageKey ?? 'shared.errors.presence'),
    },
  };
};

export const campaignLinksFormValidation = () => {
  const validationWithoutDiscount = {
    name: {
      ...presence(),
      length: { minimum: 3, maximum: 255 },
    },
    title: {
      ...presence(),
      length: { minimum: 3, maximum: 255 },
    },
    description: {
      length: { minimum: 3, maximum: 255 },
    },
  };

  return validationWithoutDiscount;
};

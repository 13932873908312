import { TableCell } from '@mui/material';
import classNames from 'classnames';
import * as React from 'react';
import shortid from 'shortid';

import { RACERS_COLUMNS } from '../../../constants';

import { Racer as RacerModel } from '../../../models';

import { RowType } from '../';
import { RacersContext } from '../../../context';
import { FIELD_SHOW } from './show';

const { MAIN_COLUMNS } = RACERS_COLUMNS;

function RacersCellsComponent({ value }: RowType) {
  return (
    <RacersContext.Consumer>
      {(context) => {
        return MAIN_COLUMNS.map((column) => {
          const generateShowFun = FIELD_SHOW[column as string];

          const show = generateShowFun && generateShowFun(new RacerModel(value), context);
          const renderedValue = show();

          return (
            <TableCell className={classNames('editable-cell cell')} key={shortid()}>
              {renderedValue || null}
            </TableCell>
          );
        });
      }}
    </RacersContext.Consumer>
  );
}

const RacersCells = React.memo<RowType>(RacersCellsComponent);
export { RacersCells };

import TableBody from '@mui/material/TableBody';
import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import * as React from 'react';

import { Show } from 'components/Condition';
import { Spinner } from 'components/Spinner';
import { Table as TableComponent } from 'components/Table';
import { TableEmptyListNotification } from 'components/TableEmptyListNotification';

import { t } from 'utils';

import { progressStore } from 'stores';

import { LOAD_RACERS } from '../../constants';

import { RacersContext } from '../../context';
import { RacersContextType } from '../../context';
import { Racer as RacerType } from '../../types';
import { THead } from '../THead';
import { Row } from './Row';

type Props = {};

@observer
class Table extends React.Component<Props> {
  static contextType = RacersContext;
  declare context: React.ContextType<typeof RacersContext>;

  render() {
    const racersList: Array<RacerType> = this.context.results.stores.racersStore.values;

    return (
      <div className='component-list racers-list non-sticky'>
        <Show if={!isEmpty(racersList)}>
          <TableComponent>
            <THead />
            {!progressStore.isLoading(LOAD_RACERS) && (
              <TableBody>
                {racersList.map<React.ReactNode>((el) => (
                  <Row item={el} key={el.id} />
                ))}
              </TableBody>
            )}
          </TableComponent>
        </Show>
        <Show if={isEmpty(racersList)}>
          <TableEmptyListNotification text={t.staticAsString('racers.noData' as TranslationLockedKeys)} />
        </Show>
        {progressStore.isLoading(LOAD_RACERS) && <Spinner type={Spinner.types.default} />}
      </div>
    );
  }
}

export { Table };

import { systemUsersService } from '../services';

import { systemUsersStore } from '../stores';

async function loadRoles() {
  const [status, roles] = await systemUsersService.loadSystemUserRoles();

  if (!status) {
    return;
  }

  systemUsersStore.addRoles(roles);
}

export { loadRoles };

import { observer } from 'mobx-react';
import * as React from 'react';

import { Show } from 'components/Condition';
import { SearchSelect, InputWrapper } from 'components/Form';

import { t } from 'utils';

import { loadRefundDistances } from '../../actions';

import { refundDistancesStore } from '../../stores';

type Props = {
  show: boolean;
};

@observer
export class FieldsDistanceRegistration extends React.Component<Props> {
  onSelectChangeDistance = (event, newInputValue) => {
    loadRefundDistances(newInputValue);
  };

  render() {
    const { show } = this.props;

    return (
      <Show if={show}>
        <InputWrapper
          name='refundDistance'
          settings={{
            required: true,
            label: t.staticAsString('supportTaskManager.form.distanceId'),
            additional: {
              options: refundDistancesStore.value,
            },
            view: {
              variant: 'outlined',
            },
            onInputChange: this.onSelectChangeDistance,
          }}
          Component={SearchSelect}
        />
      </Show>
    );
  }
}

import { getMinAmountByCurrency } from 'modules/Distances/utils';

import { t } from 'utils';

import { helperRacesStore } from 'stores';

const selfServices = () => {
  const raceCurrency = helperRacesStore.selected?.currency?.iso_code;
  const minAmount = getMinAmountByCurrency(raceCurrency);
  return {
    _validation: {
      price: {
        numericality: (_value) => {
          return {
            greaterThanOrEqualTo: Boolean(+_value) ? minAmount : 0,
            message: () =>
              t.staticAsString('shared.errors.greaterThanMinAmount', {
                min: minAmount,
                currency: raceCurrency,
              }),
          };
        },
      },
    },
  };
};

export { selfServices };

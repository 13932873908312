import { Observer } from 'mobx-react';
import * as React from 'react';

import { TextField } from 'src/components/Form';
import { confirmationModalStore } from 'src/stores';
import { t } from 'src/utils';

type Props = {
  show: boolean;
};

export const ConfirmationField: React.FC<Props> = (props) => {
  const { show } = props;

  if (!show) return null;

  const handleChange = ({ value }) => {
    confirmationModalStore.changeConfirm(value);
  };

  return (
    <Observer>
      {() => {
        return (
          <TextField
            name='confirm'
            label={confirmationModalStore.props.confirmationLabel || ''}
            error={confirmationModalStore.confirmError}
            value={confirmationModalStore.confirmValue}
            onChange={handleChange}
            view={{
              variant: 'outlined',
              fullWidth: true,
            }}
          />
        );
      }}
    </Observer>
  );
};

import shortid from 'shortid';

import { ROUTES } from 'src/constants';

import { NavToolbar } from 'components/NavToolbar';

import { t } from 'utils';

function Toolbar() {
  const items = [
    { key: shortid(), path: ROUTES.smartLinks, label: t.staticAsString('smartLinks.smartRegistrationLink') },
    { key: shortid(), label: t.staticAsString('smartLinks.details') },
  ];
  return (
    <div className='toolbar'>
      <NavToolbar items={items} />
    </div>
  );
}

export { Toolbar };

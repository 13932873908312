import { matchPath } from 'react-router-dom';

type routeType = {
  path?: string;
  exact?: boolean;
  strict?: boolean;
  sensitive?: boolean;
};

function match(path: string, route: routeType): boolean {
  return !!matchPath(path, route);
}

export { match };

export class Squads {
  value: SquadsType;

  constructor(value: SquadsType) {
    this.value = value;
  }

  name(): string {
    return this.value.name;
  }

  forSelect(): SelectOption {
    const id = `${this.value.id}`;

    return {
      label: this.name(),
      value: id,
      key: id,
    };
  }
}

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import * as React from 'react';

import { INTEGRATION_TABLE_HEAD } from '../../constants';

import { displayInfoTable } from '../../utils';

import { integrationsStore } from '../../stores';

type Props = {};

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.greyF5,
    },
  },
}))(TableRow);
const StyledCell = withStyles((theme) => ({
  root: {
    color: theme.palette.textPrimary,
    border: 0,

    '&:first-child': {
      padding: 10,
    },
  },
}))(TableCell);

@observer
export class IntegrationsTableBody extends React.Component<Props> {
  renderCell = (integration: AnyObject): React.ReactNode[] => {
    return INTEGRATION_TABLE_HEAD.map((head) => {
      return (
        <StyledCell style={head.style} key={`${head.name}:${integration.id}`}>
          {displayInfoTable(head.name, integration[head.name], integration.id)}
        </StyledCell>
      );
    });
  };

  render(): React.ReactNode[] {
    return (integrationsStore.value || []).map((integration) => {
      return <StyledTableRow key={integration.id}>{this.renderCell(integration)}</StyledTableRow>;
    });
  }
}

import { fields } from '../../../constants';

import { CopyRacersTable } from '../../../types';

type CopyRacerModel = {
  value: CopyRacersTable;
  organizer: Function;
  created_at: Function;
  sourceDistance: Function;
  targetDistance: Function;
  startAt: Function;
  endAt: Function;
};

const FIELD_SHOW = {
  [fields.id]: function (model: CopyRacerModel): Function {
    return () => {
      return model.value.id;
    };
  },

  [fields.organizer]: function (model: CopyRacerModel): Function {
    return () => {
      return model.organizer();
    };
  },

  [fields.created_at]: function (model: CopyRacerModel): Function {
    return () => {
      return model.created_at();
    };
  },

  [fields.sourceDistance]: function (model: CopyRacerModel): Function {
    return () => {
      return model.sourceDistance();
    };
  },

  [fields.targetDistance]: function (model: CopyRacerModel): Function {
    return () => {
      return model.targetDistance();
    };
  },

  [fields.startAt]: function (model: CopyRacerModel): Function {
    return () => {
      return model.startAt();
    };
  },

  [fields.endAt]: function (model: CopyRacerModel): Function {
    return () => {
      return model.endAt();
    };
  },
};

export { FIELD_SHOW };
